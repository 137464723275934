import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ClearingFromThisLoan,
	CreditCardType,
	FinancialInstitution,
	PaymentType,
	RepaymentFrequency,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { CreditCardDTO } from '@app/modules/typings/api';
import { calculateMonthly, getNullableBoolean, getNullableYesNo, repaymentToFrequencyFull } from '../helper/util';
import { ShortApplicant } from './applicant.model';

export class LiabilityCreditCardTransformer {
	static toPayload(applicationId: number, creditCard: CreditCardModel, applicants: ShortApplicant[]): CreditCardDTO {
		const applicantEntityEnum = applicants.find(
			(x) => x.id === creditCard.details.creditCardDetails.ownershipId
		)?.applicantEntityType;
		return {
			id: creditCard.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			description: '',
			issuer: creditCard.details.creditCardDetails.financialInstitution,
			outstandingBalance: creditCard.details.creditCardDetails.outstandingBalance,
			interestRate: creditCard.details.creditCardDetails.annualInterestRate,
			isShared: getNullableBoolean(creditCard.details.creditCardDetails.isShared),
			creditCardType: creditCard.details.creditCardDetails.creditCardType,
			applicantId: creditCard.details.creditCardDetails.ownershipId,
			creditLimit: creditCard.details.creditCardDetails.creditLimit,
			newLimit: creditCard.details.creditCardDetails.newLimit,
			applicantEntityTypeEnum: applicantEntityEnum,
			arrearsAmount: creditCard.details.creditCardDetails.arrearsAmount,
			hasArrears: getNullableBoolean(creditCard.details.creditCardDetails.hasArrears),
			arrearsNumberOfMissedPayments: creditCard.details.creditCardDetails.numberOfMissedPayments,
			isTaxDeductible: getNullableBoolean(creditCard.details.creditCardDetails.isTaxDeductible),
			paymentType: creditCard.details.creditCardDetails.paymentType,
			repayments: creditCard.details.creditCardDetails.repayments?.amount,
			repaymentFrequency: creditCard.details.creditCardDetails.repayments?.frequency,
			clearingBalanceOnSettlement: getNullableBoolean(creditCard.details.creditCardDetails.clearingBalanceOnSettlement),
			clearingFromThisLoan: getNullableBoolean(creditCard.details.creditCardDetails.clearingFromThisLoan),
			clearingThisLiability: creditCard.details.creditCardDetails.clearingThisLiability,
			refinanceAmount: creditCard.details.creditCardDetails.disbursementAmount,
			refinanceCosts: creditCard.details.creditCardDetails.refinanceCost,
			bsb: creditCard.details.creditCardDetails.bsb,
			accountNumber: creditCard.details.creditCardDetails.accountNumber,
			accountName: creditCard.details.creditCardDetails.accountName,
			otherFinancialInstitutionName: creditCard.details.creditCardDetails?.otherFinancialInstitutionName,
			isForeign: creditCard.details.creditCardDetails.isForeign,
			paidInFull: creditCard.details.creditCardDetails.paidInFull
		};
	}

	static fromPayload(creditCard: CreditCardDTO): CreditCardModel {
		const monthlyRepaymentAmount = calculateMonthly(
			creditCard.repayments,
			repaymentToFrequencyFull(creditCard.repaymentFrequency as RepaymentFrequency)
		);
		const creditCardModel: CreditCardModel = {
			id: creditCard.id,
			applicationId: creditCard.applicationId,
			financialInstitution: creditCard.issuer,
			amountEntry: creditCard.outstandingBalance,
			repaymentAmount: creditCard.creditLimit,
			summaryAmount: Math.max(...[creditCard.outstandingBalance || 0].filter(Number.isFinite)),
			outstandingBalance: creditCard.outstandingBalance ?? 0, // [TAMA5-9520] When outstanding balance is null it should 0 as per the requirement
			creditLimit: creditCard.creditLimit ?? 0, // [TAMA5-9520] When credit limit is null it should 0 as per the requirement
			monthlyRepayment: monthlyRepaymentAmount,
			details: {
				creditCardDetails: {
					annualInterestRate: creditCard.interestRate,
					clearingBalanceOnSettlement: getNullableYesNo(creditCard.clearingBalanceOnSettlement),
					financialInstitution: creditCard.issuer,
					outstandingBalance: creditCard.outstandingBalance,
					isShared: getNullableYesNo(creditCard.isShared),
					creditCardType: creditCard.creditCardType,
					ownershipId: creditCard.applicantId,
					creditLimit: creditCard.creditLimit,
					newLimit: creditCard.newLimit || creditCard.creditLimit,
					arrearsAmount: creditCard.arrearsAmount,
					hasArrears: getNullableYesNo(creditCard.hasArrears),
					numberOfMissedPayments: creditCard.arrearsNumberOfMissedPayments ?? undefined,
					paymentType: creditCard.paymentType,
					repayments: {
						amount: creditCard.repayments as number,
						frequency: creditCard.repaymentFrequency as RepaymentFrequency
					},
					isTaxDeductible: getNullableYesNo(creditCard.isTaxDeductible),
					clearingFromThisLoan: getNullableYesNo(creditCard.clearingFromThisLoan),
					clearingThisLiability: creditCard.clearingThisLiability,
					disbursementAmount: creditCard.refinanceAmount,
					refinanceCost: creditCard.refinanceCosts,
					bsb: creditCard.bsb,
					accountNumber: creditCard.accountNumber,
					accountName: creditCard.accountName,
					otherFinancialInstitutionName: creditCard?.otherFinancialInstitutionName,
					isForeign: creditCard.isForeign,
					paidInFull: creditCard.paidInFull
				},
				extract: `FinancialInstitution.${creditCard.issuer}`
			}
		};
		return creditCardModel;
	}
}

export interface CreditCardModel {
	financialInstitution: FinancialInstitution;
	applicationId: number;
	id?: number;
	details: { creditCardDetails: CreditCardDetails; extract: string };
	repaymentAmount?: number;
	outstandingBalance?: number;
	creditLimit?: number;
	monthlyRepayment?: number;
	// Used in Company & Trust Financial Position
	amountEntry?: number;
	summaryAmount?: number;
}

interface CreditCardDetails {
	financialInstitution: FinancialInstitution;
	outstandingBalance: number;
	otherFinancialInstitutionName?: string;
	annualInterestRate: number;
	isShared?: YesNo;
	creditCardType: CreditCardType;
	ownershipId: number;
	creditLimit: number;
	newLimit: number;
	hasArrears?: YesNo;
	numberOfMissedPayments: number;
	arrearsAmount: number;
	repayments: AmountSelect;
	paymentType?: PaymentType;
	isTaxDeductible?: YesNo;
	refinanceCost?: number;
	disbursementAmount?: number;
	clearingBalanceOnSettlement?: YesNo;
	clearingFromThisLoan?: YesNo;
	clearingThisLiability?: ClearingFromThisLoan;
	accountName?: string;
	bsb?: string;
	accountNumber?: number;
	isForeign: boolean;
	paidInFull?: boolean;
}
