import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ENV_CONFIG } from '@app/env-config';
import { FormMetaDataQuery } from '@app/modules/shared/store/form-metadata/form-metadata.query';
import { FormMetaDataService } from '@app/modules/shared/store/form-metadata/form-metadata.service';
import { FormAreas } from '@app/modules/shared/typings/form-areas.types';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { BrandingService } from '@simpology/authentication';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'denied',
	templateUrl: './denied.component.html',
	styleUrls: ['./denied.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeniedComponent implements OnDestroy {
	brandName = '';
	content = '';
	private destroy$: Subject<void> = new Subject();

	constructor(
		private brandingService: BrandingService,
		private formMetaDataService: FormMetaDataService,
		private formMetaDataQuery: FormMetaDataQuery,
		private cdr: ChangeDetectorRef
	) {
		this.brandingService.brandData.pipe(takeUntil(this.destroy$)).subscribe((branding) => {
			if (branding) {
				this.brandName = branding.brandUrl;
			} else {
				this.brandingService.setBranding(ENV_CONFIG);
			}
		});

		this.formMetaDataService.fetch(FormAreas.accreditationCheck);
		this.formMetaDataQuery
			.selectArea$(FormAreas.accreditationCheck)
			.pipe(takeUntil(this.destroy$))
			.subscribe((formFields) => {
				if (formFields) {
					const field = getFormField(formFields, 'accreditationCheck.content')?.template;
					if (field) {
						this.content = field;
						this.cdr.markForCheck();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
