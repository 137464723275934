import { RelatedCompanyModel } from '@app/modules/applicants/models/related-company.model';
import {
	AddbackType,
	BusinessStructureFull,
	EmploymentStatus,
	IncomeDocumentationType,
	IncomeType,
	NonRecurringIncomeType,
	TargetType
} from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { getLatestFinancialYear, getMonthAndYearDifference } from '@app/modules/shared/helper/util';
import {
	RelatedCompanyFinancialModal,
	RelatedCompanyFinancialsTransformer
} from '@app/modules/shared/model/related-company-financial.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Amount, AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import {
	AddBackDTO,
	NonRecurringIncomes,
	SelfEmployedApplicantIncomeStatementsDTO,
	SelfEmploymentDTO
} from '@app/modules/typings/api';
import { CurrencyHelper, EnumObject } from '@simpology/client-components/utils';
import { isEmpty } from 'lodash-es';

import { ApplicantEmploymentModel } from './applicant-employment.model';
import { EmploymentModelTransformer, TypeOfIncome } from './employment.model';
import { ApplicantIncome, SelfEmploymentModel } from './self-employment.model';

export class ApplicantSelfEmploymentModelTransformer {
	static toPayload(
		employmentModel: ApplicantEmploymentModel,
		applicationId: number,
		formEnumsQuery: FormEnumsQuery
	): SelfEmploymentDTO {
		const selfEmployment = employmentModel.employmentDetails.selfEmployment!;
		return {
			id: employmentModel.id,
			applicationId: applicationId,
			applicantId: selfEmployment.ownership,
			status: selfEmployment.status ?? EmploymentStatus.Previous,
			basis: selfEmployment.basis,
			dateStarted: selfEmployment.dateStarted,
			dateEnded: selfEmployment.dateEnded,
			profitsBeforeTax: [
				selfEmployment.latestIncome,
				selfEmployment.secondLatestIncome,
				selfEmployment.thirdLatestIncome
			]
				.filter((income) => income?.amount !== undefined && income?.amount !== null)
				.map((income) => ({
					amount: CurrencyHelper.unformatAmount(income?.amount as string),
					year: { id: income?.frequency?.id, name: income?.frequency?.label }
				})),
			profitsAfterTax: [
				selfEmployment.firstNetProfitAfterTax,
				selfEmployment.secondNetProfitAfterTax,
				selfEmployment.thirdNetProfitAfterTax
			]
				.filter((prof) => prof?.amount !== undefined && prof?.amount !== null)
				.map((prof) => ({
					amount: CurrencyHelper.unformatAmount(prof?.amount as string),
					year: { id: prof?.frequency?.id, name: prof?.frequency?.label }
				})),
			selfEmployedAtoIncomeStatements: [
				{
					grossIncome: selfEmployment.latestAtoGrossIncomeStatement,
					startDate: selfEmployment.latestAtoGrossIncomeStatementStart,
					endDate: selfEmployment.latestAtoGrossIncomeStatementEnd,
					financialYearId: selfEmployment.latestAtoGrossIncomeFinancialYear
				},
				{
					grossIncome: selfEmployment.previousAtoGrossIncomeStatement,
					startDate: selfEmployment.previousAtoGrossIncomeStatementStart,
					endDate: selfEmployment.previousAtoGrossIncomeStatementEnd,
					financialYearId: selfEmployment.previousAtoGrossIncomeFinancialYear
				}
			],
			anzscoOccupationCodeId: selfEmployment.anzscoOccupationCodeId,
			occupationCodeId: selfEmployment.occupationCodeId,
			addbacks: this.getAddBacks(selfEmployment, formEnumsQuery),
			employerId: selfEmployment.businessDetailsSelect?.id,
			grossSalaryAmount: selfEmployment.grossSalary?.amount,
			grossSalaryFrequency: selfEmployment.grossSalary?.frequency,
			declaredIncomeIncomeAmount: selfEmployment?.declaredIncomeIncome,
			declaredNetIncomeAmount: selfEmployment.declaredNetIncome,
			businessMainBusinessActivity: selfEmployment.mainBusinessActivity,
			businessStartDate: selfEmployment.businessStartDate,
			industryDivisionId: selfEmployment.industryCategory,
			industrySubdivisionId: selfEmployment.industrySubCategory,
			industryCodeId: selfEmployment.anzsicIndustry,
			incomeDocumentationType:
				employmentModel.employmentDetails.typeOfIncome.incomeDocumentationTypeId ??
				(selfEmployment.latestAtoGrossIncomeStatement ? IncomeDocumentationType.ATOIncomeStatement : undefined),
			selfEmployedApplicantIncome: this.getApplicantIncomeDTO(selfEmployment.applicantIncome),
			selfEmployedCompanyFinancials:
				selfEmployment.businessFinancials?.modal?.financialYear?.map((financial) => {
					return RelatedCompanyFinancialsTransformer.toPayload(financial);
				}) ?? [],
			employerPartyId: selfEmployment.businessDetailsSelect?.id,
			employerTargetType: selfEmployment.businessDetailsSelect?.type,
			accountantId: selfEmployment.accountantName?.id,
			hasForeignSourcedIncome: selfEmployment.hasForeignSourcedIncome,
			nonRecurringIncomes:
				selfEmployment.nonRecurringIncome?.amount || selfEmployment.secondNonRecurringIncome?.amount
					? this.setNonRecurringIncomes(selfEmployment)
					: []
		};
	}

	static getAddBacks(model: ApplicantSelfEmploymentModel, formEnumsQuery: FormEnumsQuery): AddBackDTO[] {
		const currentYear = formEnumsQuery.getOptions('FinancialYear')[0].id;
		const data: AddBackDTO[] = [];
		if (model.addBacks) {
			Object.keys(model.addBacks).forEach((key: string, index: number) => {
				const row = model.addBacks![index];
				const addBackType = row.type;
				const keysToFetch = ['fyCurrent'];
				if (model.secondLatestIncome?.frequency) {
					keysToFetch.push('fyPrevious');
				}

				if (model.thirdLatestIncome?.frequency) {
					keysToFetch.push('fySecondPrevious');
				}

				Object.keys(row).forEach((rowKey: string, rowIndex: number) => {
					//typescript error to use row[key] hence using the long method
					let fyamount: number | undefined = undefined;
					let yearId: number | undefined = undefined;
					if (rowKey === 'fyCurrent') {
						fyamount = row.fyCurrent!;
						yearId = currentYear;
					}
					if (rowKey === 'fyPrevious') {
						fyamount = row.fyPrevious!;
						yearId = currentYear - 1;
					} else if (rowKey === 'fySecondPrevious') {
						fyamount = row.fySecondPrevious!;
						yearId = currentYear - 2;
					}

					if (fyamount && yearId) {
						const addBackAmount = String(fyamount);
						const addBackUnformattedAmount = CurrencyHelper.unformatAmount(addBackAmount);

						const dataRow: AddBackDTO = {
							amount: addBackUnformattedAmount,
							financialYearId: yearId,
							type: addBackType!
						};
						data.push(dataRow);
					}
				});
			});
		}

		return data;
	}

	static fromPayload(
		selfEmploymentDTO: SelfEmploymentDTO,
		formEnumsQuery: FormEnumsQuery,
		businessDetails?: RelatedCompanyModel
	): ApplicantEmploymentModel {
		const financialYearEnum = formEnumsQuery.getOptions('FinancialYear');
		const profitsAfterTax = selfEmploymentDTO.profitsAfterTax?.filter((profit) =>
			financialYearEnum.some((year) => year.id === profit.year.id)
		);
		const profitsBeforeTax = selfEmploymentDTO.profitsBeforeTax?.filter((profit) =>
			financialYearEnum.some((year) => year.id === profit.year.id)
		);
		const latestFinancialYear = getLatestFinancialYear(financialYearEnum);
		const selfEmploymentModel: ApplicantSelfEmploymentModel = {
			status: selfEmploymentDTO.status,
			basis: selfEmploymentDTO.basis,
			dateStarted: selfEmploymentDTO.dateStarted,
			dateEnded: selfEmploymentDTO.dateEnded,
			duration: getMonthAndYearDifference(selfEmploymentDTO.dateStarted!, selfEmploymentDTO.dateEnded!),
			ownership: selfEmploymentDTO.applicantId,
			anzscoOccupationCodeId: selfEmploymentDTO.anzscoOccupationCodeId,
			occupationCodeId: selfEmploymentDTO.occupationCodeId,
			accountantName: formEnumsQuery.getExistingPartiesById({
				type: TargetType.RelatedCompany,
				id: selfEmploymentDTO.accountantId as number
			}),
			addBacks: this.getAddBackModel(selfEmploymentDTO.addbacks!, latestFinancialYear),
			latestIncome: this.getYearlyIncome(profitsBeforeTax, 0),
			secondLatestIncome: this.getYearlyIncome(profitsBeforeTax, 1),
			thirdLatestIncome: this.getYearlyIncome(profitsBeforeTax, 2),
			typeOfIncome: {
				type: IncomeType.selfEmployed,
				currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
					selfEmploymentDTO.dateEnded,
					selfEmploymentDTO.status
				)
			},
			businessDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: selfEmploymentDTO.employerTargetType as TargetType,
				id: selfEmploymentDTO.employerPartyId!
			}),
			grossSalary: {
				amount: selfEmploymentDTO.grossSalaryAmount as number,
				frequency: selfEmploymentDTO.grossSalaryFrequency as number
			},
			declaredIncomeIncome: selfEmploymentDTO?.declaredIncomeIncomeAmount,
			declaredNetIncome: selfEmploymentDTO.declaredNetIncomeAmount,
			firstNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 0),
			secondNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 1),
			thirdNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 2),
			latestAtoGrossIncomeFinancialYear:
				selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[0]?.financialYearId ?? financialYearEnum[0].id,
			latestAtoGrossIncomeStatement: selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[0]?.grossIncome,
			latestAtoGrossIncomeStatementStart: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[0]?.startDate,
			latestAtoGrossIncomeStatementEnd: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[0]?.endDate,
			previousAtoGrossIncomeFinancialYear:
				selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[1]?.financialYearId ?? financialYearEnum[1].id,
			previousAtoGrossIncomeStatement: selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[1]?.grossIncome,
			previousAtoGrossIncomeStatementStart: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[1]?.startDate,
			previousAtoGrossIncomeStatementEnd: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[1]?.endDate,
			mainBusinessActivity: selfEmploymentDTO.businessMainBusinessActivity,
			businessStartDate: selfEmploymentDTO.businessStartDate,
			industryCategory: selfEmploymentDTO.industryDivisionId,
			industrySubCategory: selfEmploymentDTO.industrySubdivisionId,
			anzsicIndustry: selfEmploymentDTO.industryCodeId,
			businessStructureId: selfEmploymentDTO.businessStructureId,
			applicantIncome: this.getApplicantIncomeModel(selfEmploymentDTO.selfEmployedApplicantIncome ?? [{}]),
			businessFinancials: {
				modal: {
					financialYear: businessDetails?.companyFinancials?.length
						? businessDetails?.companyFinancials
						: RelatedCompanyFinancialsTransformer.getDefaultFinancialYears(financialYearEnum)
				},
				extract: businessDetails?.companyFinancials?.length ? 'Business financials' : ''
			},
			employerPartyId: selfEmploymentDTO.employerPartyId,
			employerTargetType: selfEmploymentDTO.employerTargetType,
			hasForeignSourcedIncome: selfEmploymentDTO.hasForeignSourcedIncome,
			nonRecurringIncome: {
				amount: selfEmploymentDTO.nonRecurringIncomes?.[0]?.amount as number,
				frequency: selfEmploymentDTO.nonRecurringIncomes?.[0]?.year?.id as number
			},
			nonRecurringIncomeType: selfEmploymentDTO.nonRecurringIncomes?.[0]?.nonRecurringIncomeType,
			secondNonRecurringIncome: {
				amount: selfEmploymentDTO.nonRecurringIncomes?.[1]?.amount as number,
				frequency: selfEmploymentDTO.nonRecurringIncomes?.[1]?.year?.id as number
			},
			secondNonRecurringIncomeType: selfEmploymentDTO.nonRecurringIncomes?.[1]?.nonRecurringIncomeType
		};
		const employmentModel: ApplicantEmploymentModel = {
			employmentDetails: {
				applicantId: selfEmploymentDTO.applicantId,
				extract: `Self-employed`,
				typeOfIncome: {
					type: IncomeType.selfEmployed,
					currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
						selfEmploymentDTO.dateEnded,
						selfEmploymentDTO.status
					),
					incomeDocumentationTypeId: selfEmploymentDTO?.incomeDocumentationType
				},
				payg: {},
				selfEmployment: selfEmploymentModel,
				notEmployed: {},
				foreignEmployment: {}
			},
			id: selfEmploymentDTO.id,
			dateStarted: selfEmploymentDTO.dateStarted,
			dateEnded: selfEmploymentDTO.dateEnded
		};
		return employmentModel;
	}

	static getApplicantIncomeModel(
		selfEmployedApplicantIncomeStatement: SelfEmployedApplicantIncomeStatementsDTO[]
	): ApplicantIncome {
		if (!selfEmployedApplicantIncomeStatement.length) {
			return {};
		}
		return {
			grossSalary: selfEmployedApplicantIncomeStatement[0].salary,
			dividends: selfEmployedApplicantIncomeStatement[0].dividends,
			distributionFromPartnership: selfEmployedApplicantIncomeStatement[0].distributionFromPartnership,
			distributionFromTrust: selfEmployedApplicantIncomeStatement[0].distributionFromTrust,
			netIncome: selfEmployedApplicantIncomeStatement[0].netIncome
		};
	}

	static getApplicantIncomeDTO(income?: ApplicantIncome): SelfEmployedApplicantIncomeStatementsDTO[] {
		return isEmpty(income)
			? []
			: [
					{
						salary: income?.grossSalary,
						dividends: income?.dividends,
						distributionFromPartnership: income?.distributionFromPartnership,
						distributionFromTrust: income?.distributionFromTrust,
						netIncome: income?.netIncome
					}
			  ];
	}

	static getAddBackModel(addBackData: AddBackDTO[], currentYear: number | undefined): AddBackRow[] | undefined {
		const addBackModel: AddBackRow[] = [];
		if (addBackData) {
			currentYear =
				currentYear ??
				addBackData.reduce(
					(max, addBackItem) => (max = max > addBackItem.financialYearId! ? max : addBackItem.financialYearId!),
					0
				);
			const prevYear = currentYear - 1;
			const secondPrevYear = currentYear - 2;

			const addBackTypes = [...new Set(addBackData.map((item) => item.type))];
			if (addBackTypes) {
				Object.keys(addBackTypes).forEach((key, index: number) => {
					const addBackType = addBackTypes[index];
					const rowData = addBackData?.filter((x) => x.type === addBackType);
					if (rowData) {
						const addBackRow: AddBackRow = {
							fyCurrent: rowData.find((x) => x.financialYearId === currentYear)?.amount,
							fyPrevious: rowData.find((x) => x.financialYearId === prevYear)?.amount,
							fySecondPrevious: rowData.find((x) => x.financialYearId === secondPrevYear)?.amount,
							type: addBackType
						};
						addBackModel.push(addBackRow);
					}
				});
			}
		}
		return addBackModel;
	}

	static getYearlyIncome(profits: Profit[] | undefined, yearIndex: number): Amount {
		if (profits && profits[yearIndex]) {
			return {
				amount: profits[yearIndex].amount.toString(),
				frequency: {
					id: profits[yearIndex].year.id!,
					label: profits[yearIndex].year.name!
				}
			};
		} else {
			return {} as Amount;
		}
	}

	static setNonRecurringIncomes(selfEmployment: SelfEmploymentModel): NonRecurringIncomes[] {
		const incomes: NonRecurringIncomes[] = [];

		incomes.push({
			amount: selfEmployment.nonRecurringIncome?.amount,
			year: {
				id: (selfEmployment.nonRecurringIncome?.frequency as unknown as EnumObject).id,
				name: (selfEmployment.nonRecurringIncome?.frequency as unknown as EnumObject).label
			},
			nonRecurringIncomeType: selfEmployment.nonRecurringIncomeType
		});

		incomes.push({
			amount: selfEmployment.secondNonRecurringIncome?.amount,
			year: {
				id: (selfEmployment.secondNonRecurringIncome?.frequency as unknown as EnumObject).id,
				name: (selfEmployment.secondNonRecurringIncome?.frequency as unknown as EnumObject).label
			},
			nonRecurringIncomeType: selfEmployment.secondNonRecurringIncomeType
		});

		return incomes;
	}
}

export class ApplicantSelfEmploymentModel {
	status?: number;
	ownership?: number;
	basis?: number;
	dateStarted?: string;
	dateEnded?: string;
	duration?: string;
	anzscoOccupationCodeId?: number;
	occupationCodeId?: number;
	latestIncome?: Partial<Amount>;
	secondLatestIncome?: Partial<Amount>;
	thirdLatestIncome?: Partial<Amount>;
	addBacks?: AddBackRow[];
	accountantName?: ApplicantEnumObject;
	hideSecondAddback? = false;
	hideThirdAddback? = false;
	typeOfIncome?: TypeOfIncome;
	businessDetailsSelect?: ApplicantEnumObject;
	grossSalary?: AmountSelect;
	declaredIncomeIncome?: number;
	declaredNetIncome?: number;
	firstNetProfitAfterTax?: Partial<Amount>;
	secondNetProfitAfterTax?: Partial<Amount>;
	thirdNetProfitAfterTax?: Partial<Amount>;
	firstAtoGrossIncome?: Partial<Amount>;
	secondAtoGrossIncome?: Partial<Amount>;
	thirdAtoGrossIncome?: Partial<Amount>;
	latestAtoGrossIncomeFinancialYear?: number;
	latestAtoGrossIncomeStatement?: Partial<number>;
	latestAtoGrossIncomeStatementStart?: string;
	latestAtoGrossIncomeStatementEnd?: string;
	previousAtoGrossIncomeStatement?: Partial<number>;
	previousAtoGrossIncomeFinancialYear?: number;
	previousAtoGrossIncomeStatementStart?: string;
	previousAtoGrossIncomeStatementEnd?: string;
	mainBusinessActivity?: string;
	businessStartDate?: string;
	industryCategory?: number;
	industrySubCategory?: number;
	anzsicIndustry?: number;
	businessStructureId?: BusinessStructureFull;
	applicantIncome?: ApplicantIncome;
	businessFinancials?: RelatedCompanyFinancialModal;
	employerPartyId?: number;
	employerTargetType?: TargetType;
	hasForeignSourcedIncome?: boolean;
	nonRecurringIncome?: AmountSelect;
	nonRecurringIncomeType?: NonRecurringIncomeType;
	secondNonRecurringIncome?: AmountSelect;
	secondNonRecurringIncomeType?: NonRecurringIncomeType;
}

interface AddBackRow {
	type?: AddbackType;
	fySecondPrevious?: number;
	fyPrevious?: number;
	fyCurrent?: number;
}

interface Profit {
	amount: number;
	year: {
		id?: number;
		name?: string;
	};
}
