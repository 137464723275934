import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import { RefiHomeLoanDTO, RefiLoanRequirementsDTO, RefiPropertyAssetsDTO } from '@app/modules/typings/api';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';

import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiLoanRequirementModel, RefiLoanRequirementTransformer } from '../../models/refi-loan-requirements';
import { RefiAssetsService } from '../assets/refi-assets.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyLoanRequirementService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private refiAssetsService: RefiAssetsService,
		private stepStatusQuery: StepStatusQuery,
		private formDataService: FormDataService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState() {
		this.formDataService.upsertStateWithAsyncData(RefiStore.LoanRequirements, this.fetchLoanInformation());
	}

	fetchLoanInformation(): Observable<RefiLoanRequirementModel[]> {
		return forkJoin([
			this.getCustom(`LoanRequirements/${this.applicationDataQuery.applicationId()}`),
			this.refiAssetsService.getPrimaryProperty()
		]).pipe(
			map(([loan, propertyDetails]: [RefiLoanRequirementsDTO, RefiPropertyAssetsDTO | undefined]) => {
				return [
					RefiLoanRequirementTransformer.fromPayload(
						loan,
						this.applicationDataQuery.applicationId(),
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiLoanRequirements),
						propertyDetails
					)
				];
			}),
			catchError(() => of([{}] as RefiLoanRequirementModel[]))
		);
	}

	saveLoanRequirement(payload: RefiLoanRequirementsDTO): Observable<number> {
		return <Observable<number>>this.postCustom('LoanRequirements', payload);
	}

	saveMainLoanRequirements(payload: RefiHomeLoanDTO): Observable<any> {
		return <Observable<number>>this.postCustom('LoanRequirements/MainLoan', payload);
	}
}
