import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { Observable } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { SelectOptionsPipe } from '../../pipes/formly-select-options.pipe';

@Component({
	selector: 'formly-radio-button-group',
	templateUrl: './formly-radio-button-group.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRadioButtonGroupComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';

	constructor(private selectOptionsPipe: SelectOptionsPipe) {
		super();
	}
	get items(): Observable<EnumObject[]> {
		return this.selectOptionsPipe.transform(this.to.options as string | EnumObject[]);
	}

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
	}
}
