<div class="custom-input form-group" metadataKey [formlyField]="field">
	<div *ngIf="!to.hideLabel" [ngClass]="{ 'custom-input__label': !!to.label }">
		<label class="simp-label" [for]="id!">{{ to.label }}</label>
		<simp-info-icon *ngIf="to.tooltip" class="custom-input__tooltip" [tooltipHtmlContent]="to.tooltip"></simp-info-icon>
		<simp-info-icon *ngIf="to.tooltipAsModal" [asModal]="true" (modalClick)="tooltipClick(field)"></simp-info-icon>
	</div>
	<div
		class="custom-input-block form-control simp-form-control"
		[ngClass]="{ 'custom-input-block--invalid': formControl.invalid && formControl.touched }"
	>
		<span
			#buttonInputText
			class="simp-text custom-input-block__text"
			[ngbTooltip]="extract$ | enumLabel | async"
			[disableTooltip]="!isEllipsis"
			>{{ extract$ | enumLabel | async }}</span
		>

		<div class="custom-input-actions">
			<button
				*ngIf="to.clearable"
				[id]="uniqueId + '_clear_btn'"
				class="btn icon-btn custom-clear-btn"
				title="Clear all"
				type="button"
				[disabled]="to.disabled || to.readonly"
				(click)="clearClick(field)"
			>
				<span aria-hidden="true">×</span>
			</button>
			<button [id]="uniqueId" #buttonInput class="btn icon-btn" type="button" (click)="onClick($event)">
				<simp-icon [name]="to.icon || 'fal fa-ellipsis-h'" class="custom-input-block__icon"></simp-icon>
			</button>
		</div>
	</div>
	<div *ngIf="formControl.touched && formControl.invalid && !formControl.errors">
		<div class="simp-field-error simp-text-small simp-text--red">
			{{ field.validation?.messages?.invalid || 'Invalid field' }}
		</div>
	</div>
	<formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
</div>
