import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { CreditCardType, FinancialInstitution, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Action } from '@app/modules/simp-formly/components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import { PersonApplicantDTO, RefiCreditCardDTO } from '@app/modules/typings/api';

export class RefiCreditCardTransformer {
	static toPayload(creditCard: RefiCreditCardModel, applicationId: number): RefiCreditCardDTO {
		return {
			id: creditCard.creditCardsModal.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			closingOnSettlement: getNullableBoolean(creditCard.creditCardsModal?.cancelBeforeSettlement),
			creditLimit: creditCard.creditCardsModal?.cardLimit,
			isShared: getNullableBoolean(creditCard.creditCardsModal?.isShared),
			issuer: creditCard.creditCardsModal?.financialInstitution,
			primaryApplicantId: creditCard.creditCardsModal?.ownership,
			cardType: creditCard.creditCardsModal.cardType,
			cardNumber: creditCard.creditCardsModal.cardNumber
		};
	}

	static fromPayload(
		creditCard: RefiCreditCardDTO,
		formEnumQuery: FormEnumsQuery,
		applicants: PersonApplicantDTO[]
	): RefiCreditCardModel {
		const summary = `${
			applicants.length > 1
				? applicants.find((x) => x.id === creditCard.primaryApplicantId)?.firstName.concat('<br/>')
				: ''
		} ${formatCurrency(creditCard?.creditLimit || 0, 'en-AU', '$')}, ${formEnumQuery.getOptionLabel(
			'FinancialInstitution',
			creditCard.issuer as number
		)}`;

		const creditCardModel: RefiCreditCardModel = {
			summary: summary,
			reconcileRequired: creditCard.reconcileRequired ?? false,
			creditCardsModal: {
				id: creditCard.id,
				cardLimit: creditCard.creditLimit,
				financialInstitution: creditCard.issuer,
				cancelBeforeSettlement: getNullableYesNo(creditCard.closingOnSettlement),
				isShared: getNullableYesNo(creditCard.isShared),
				ownership: creditCard.primaryApplicantId,
				cardType: creditCard.cardType,
				readonly: creditCard.readonly,
				reconcileRequired: creditCard.reconcileRequired ?? false,
				summaryItemArray: [
					{
						summary: {
							items: {
								id: creditCard.id,
								type: 'Credit card',
								cardLimit: creditCard.creditLimit,
								financialInstitution: creditCard.issuer,
								cardType: creditCard.cardType,
								ownership: creditCard.primaryApplicantId
							}
						}
					}
				],
				suggestedMatchId: creditCard.suggestedMatchId,
				isUserDeclared: creditCard.isUserDeclared,
				cardNumber: creditCard.cardNumber
			}
		};
		return creditCardModel;
	}
}

export interface RefiCreditCardModel {
	creditCardsModal: RefiCreditCardDetails;
	summary?: string;
	reconcileRequired?: boolean;
}

export interface RefiCreditCardDetails {
	id?: number;
	financialInstitution?: FinancialInstitution;
	isShared?: YesNo;
	cardLimit?: number;
	cardType?: CreditCardType;
	cancelBeforeSettlement?: YesNo;
	ownership?: number;
	readonly?: boolean;
	reconcileRequired?: boolean;
	summaryItemArray?: CreditCardSummary[];
	reconcileAction?: Action;
	suggestedMatchId?: number;
	isUserDeclared?: boolean;
	cardNumber?: string;
}

export interface CreditCardSummary {
	summary?: CreditCardSummaryItems;
}

export interface CreditCardSummaryItems {
	items?: CreditCardSummaryItemsList;
}

export interface CreditCardSummaryItemsList {
	id?: number;
	type?: string;
	cardLimit?: number;
	financialInstitution?: FinancialInstitution;
	cardType?: CreditCardType;
	ownership?: number;
}
