import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URL_CONSTANTS } from '../constants/api.constants';
import { JourneyConfiguration } from '../model/journey-configuration';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class MetaJourneyConfigurationService extends BaseJourneyService<JourneyConfiguration> {
	private journeyConfiguration$?: Observable<JourneyConfiguration>;

	constructor() {
		super();
		this.setMetaRoute(URL_CONSTANTS.META_JOURNEY_CONFIGURATION);
	}

	getJourneyConfiguration(journeyId: number): Observable<JourneyConfiguration> {
		this.journeyConfiguration$ = this.journeyConfiguration$ ?? this.getCustom(`${journeyId}`);
		return this.journeyConfiguration$;
	}
}
