import { Injectable } from '@angular/core';
import { PercentOwned } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash-es';
import { applicantRoleToApplicantType } from '../helper/util';
import { ShortApplicant } from '../model/applicant.model';
import { PercentageOwned } from '../model/percentage-owned.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';

const ERROR_MIN = 'min';
const ERROR_MAX = 'max';
const ERROR_INVALID = 'invalid';
@Injectable({ providedIn: 'root' })
export class PercentOwnedService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery
	) {}

	public getPersonsDefaultSharePercents(): PercentageOwned[] {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		return this.getDefaultSharePercents(applicants);
	}

	public getAllDefaultSharePercents(): PercentageOwned[] {
		const applicants = this.applicationDataQuery.getApplicants();
		return this.getDefaultSharePercents(applicants);
	}

	public getDefaultSharePercents(applicants: ShortApplicant[]): PercentageOwned[] {
		if (!applicants) {
			return [];
		}
		const defaultPercentageOwned = applicants.map((a) => ({
			applicantId: a.id,
			name: a.name,
			percent: applicants.length > 1 ? 0 : 100,
			applicantEntityTypeEnum: a.applicantEntityType
		}));
		return defaultPercentageOwned;
	}

	public addEqualSharePercent(field: FormlyFieldConfig | undefined): void {
		const sharePercents = field?.formControl?.value as PercentageOwned[];
		if (sharePercents && sharePercents.length > 0) {
			const percentValue = 100 / sharePercents.length;
			sharePercents.forEach((sharePercent) => {
				sharePercent.percent = percentValue;
			});
			field?.formControl?.setValue(sharePercents);
		}
	}

	public getAppRole(field: FormlyFieldConfig): string | undefined {
		const percentsOwned = get(field, 'parent.formControl.value') as PercentOwned;

		const setUpApplicant = this.formDataService.getSetupApplicants().find((x) => {
			return x.id === percentsOwned.applicantId;
		});

		let appRole = '';
		if (setUpApplicant) {
			const id = applicantRoleToApplicantType(setUpApplicant.applicantRole!);
			appRole = this.formEnumsQuery.getOptionLabel('ApplicantType', id);
		}
		return appRole;
	}
}
