import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/modules/shared/service/authentication.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public submitted = false;

	constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService) {}

	public ngOnInit(): void {
		this.authenticationService.setReturnUrl(this.route.snapshot.queryParams.returnUrl);
		this.authenticationService.logIn();
	}
}
