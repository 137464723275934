import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { AssociatedProductsDTO } from '@app/modules/typings/api';

export class ComplianceCreditCardTransformer {
	static fromPayload(
		associatedProducts: AssociatedProductsDTO,
		creditCardSelectedInLoan: boolean
	): ComplianceCreditCardModel {
		return {
			isCreditCardRequired: getNullableYesNo(associatedProducts?.isCreditCardRequired),
			creditCardExplained: associatedProducts?.creditCardExplained,
			creditCardSelectedInLoan
		};
	}
}

export interface ComplianceCreditCardModel {
	isCreditCardRequired?: YesNo;
	creditCardExplained?: boolean;
	creditCardSelectedInLoan?: boolean;
}
