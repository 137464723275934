import { TrustDeedVariationDTO } from '@app/modules/typings/api';

export class TrustDeedVariationTransformer {
	static toPayload(
		applicationId: number,
		applicantId: number,
		trustDeedModel: TrustDeedVariationModel
	): TrustDeedVariationDTO {
		return {
			id: trustDeedModel.id || 0,
			applicationId: applicationId,
			trustApplicantId: applicantId,
			variationDate: trustDeedModel.variationDate,
			description: trustDeedModel.description
		};
	}

	static fromPayload(trustDeedVariation: TrustDeedVariationDTO): TrustDeedVariationModel {
		return {
			id: trustDeedVariation.id,
			description: trustDeedVariation.description,
			variationDate: trustDeedVariation.variationDate
		};
	}
}

export interface TrustDeedVariationModel {
	id: number;
	description: string;
	variationDate: string;
}
