<div class="form-group" [ngClass]="to.customClass">
	<simp-select
		#select
		class="simp-select simp-form-control {{ to.customClass }}"
		[dropdownPosition]="to.dropdownPosition ?? 'auto'"
		[id]="uniqueId"
		labelForId="{{ uniqueId }}-ctrl"
		groupBy="groupBy"
		[editable]="to.editable"
		[placeholder]="to.placeholder!"
		[items]="(items | async | dynamicOptions: to.dynamicOptions) ?? []"
		[multiple]="to.multiple || false"
		[searchable]="to.searchable ?? true"
		[clearable]="to.clearable ?? true"
		[bindValueToObject]="to.bindValueToObject"
		[formControl]="$any(formControl)"
		[formlyAttributes]="field"
		[tooltipContent]="isObservable(to.tooltip) ? (to.tooltip | async) : to.tooltip"
		[tooltipAsModal]="to.tooltipAsModal"
		[readonly]="!!to.readonly"
		(enterPressed)="enterKeydown()"
		[searchFn]="enum ? null : searchFn"
		(changeEvent)="onChange($event)"
		(tooltipClick)="tooltipClick(field)"
		(editClick)="handleEditClick(field)"
		[validationErrors]="field.validation?.messages!"
		[formlyField]="field"
		metadataKey
	>
		{{ to.label }}
		<ng-template ng-label-tmp let-item="item">
			<span
				*ngIf="!item.click"
				[innerHTML]="
					(infoWithEnum ? item.info + ' - ' : '') +
					(item.selectedLabel ?? item.label) +
					(enumWithInfo ? ' - ' + item.info : '')
				"
				class="item-wrapper {{ item.selectedClass }}"
			></span>

			<button
				*ngIf="item.click"
				type="button"
				class="btn simp-button p-0 text-start simp-link"
				(click)="onItemClick($event, item)"
				[innerHTML]="
					(infoWithEnum ? item.info + ' - ' : '') +
					(item.selectedLabel ?? item.label) +
					(enumWithInfo ? ' - ' + item.info : '')
				"
				class="item-wrapper {{ item.selectedClass }}"
			></button>
		</ng-template>
		<ng-template ng-option-tmp let-item="item">
			<span
				[innerHTML]="(infoWithEnum ? item.info + ' - ' : '') + item.label + (enumWithInfo ? ' - ' + item.info : '')"
				class="label-wrapper {{ item.class }}"
			></span>
		</ng-template>
		<ng-template ng-optgroup-tmp let-item="item" let-index="index">
			<span [innerHTML]="item.groupBy"></span>
		</ng-template>
	</simp-select>
</div>
