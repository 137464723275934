import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { AbnLookupDto } from './../typings/abn-lookup.d';

export interface AbnLookupDetailsModel {
	abn?: string;
	asicNumber?: string;
	entityName?: string;
	businessNames?: string[];
	tradingNames?: string[];
	status?: string;
	statusCode?: string;
	entityType?: string;
	location?: string;
	gstRegistration?: string;
	originalAbn?: AbnLookupDto;
}

export class AbnLookupDetailsModelTransfer {
	static fromPayload(abnLookupDto: AbnLookupDto, aggregateFormatter: AggregateFormatterService): AbnLookupDetailsModel {
		const registrationDate = aggregateFormatter.formatDate(abnLookupDto?.registrationDate, 'extendedDate');
		const gstDate = aggregateFormatter.formatDate(abnLookupDto?.gstRegistrationDate, 'extendedDate');
		const businessNames = AbnLookupDetailsModelTransfer.stringArrayValue(abnLookupDto?.businessNames) ?? [
			this.getABNCompanyName(abnLookupDto)
		];
		abnLookupDto.businessNames = businessNames;
		return {
			abn: abnLookupDto?.businessNumber,
			asicNumber: abnLookupDto?.asicNumber,
			entityName: abnLookupDto?.entityName,
			businessNames,
			tradingNames: AbnLookupDetailsModelTransfer.stringArrayValue(abnLookupDto?.tradingNames),
			statusCode: abnLookupDto?.entityStatusCode,
			status: AbnLookupDetailsModelTransfer.getStatus(abnLookupDto?.entityStatusCode, registrationDate),
			entityType: abnLookupDto?.entityType,
			location: AbnLookupDetailsModelTransfer.getLocation(
				abnLookupDto?.state,
				abnLookupDto?.postcode,
				aggregateFormatter.formatDate(abnLookupDto?.addressRegistrationDate, 'extendedDate')
			),
			gstRegistration: gstDate ? `Registered from ${gstDate}` : 'Not currently registered for GST',
			originalAbn: abnLookupDto
		} as AbnLookupDetailsModel;
	}

	static getStatus(statusCode?: string, registeredDate?: string): string {
		const dateText = registeredDate ? ` from ${registeredDate}` : '';
		return `${statusCode}${dateText}`;
	}

	static getLocation(state?: string, postcode?: string, date?: string): string {
		let location = state || '';
		if (postcode) {
			location = location ? `${location} ${postcode}` : postcode;
		}
		if (date) {
			location = location ? `${location}, Registered from ${date}` : `Registered from ${date}`;
		}
		return location;
	}

	static stringArrayValue(array?: string[]): string[] | undefined {
		return array && array?.length > 0 ? array : undefined;
	}

	static getABNCompanyName(abnDetails?: AbnLookupDto): string {
		if (abnDetails?.entityName) {
			return abnDetails.entityName;
		} else if (abnDetails?.businessNames?.[0]) {
			return abnDetails.businessNames[0];
		} else if (abnDetails?.tradingNames?.[0]) {
			return abnDetails.tradingNames[0];
		}
		return '';
	}
}
