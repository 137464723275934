import { Injectable } from '@angular/core';
import { ApplicationDetailsDto } from '@app/modules/setup/typings/setup';
import { ApplicationPreCheck } from '@app/modules/shared/enums/app.enums';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreCheckWrapperComponent } from '../components/pre-check-wrapper/pre-check-wrapper.component';

@Injectable({ providedIn: 'root' })
export class PreCheckModalService {
	constructor(
		private modalService: NgbModal,
		private channelSettingQuery: ChannelSettingQuery,
		private applicationDataService: ApplicationDataService
	) {}

	openModal(applicationDetailsAPI?: ApplicationDetailsDto): void {
		const modalRef = this.modalService.open(PreCheckWrapperComponent, {
			backdrop: 'static',
			centered: true,
			keyboard: false,
			size: 'xl',
			animation: true
		});
		(modalRef.componentInstance as PreCheckWrapperComponent).applicationDetailsAPI = applicationDetailsAPI;
	}

	checkForPrecheck(applicationDetails?: ApplicationDetailsDto): void {
		const skipPreCheck = !this.channelSettingQuery.getRequireApplicationPreCheck() ?? false;
		this.applicationDataService.updatePreCheckStatusFlag(
			skipPreCheck ? ApplicationPreCheck.Unknown : applicationDetails?.preCheck ?? ApplicationPreCheck.Unknown
		);

		if (
			!skipPreCheck &&
			(!applicationDetails || (applicationDetails && applicationDetails.preCheck === ApplicationPreCheck.Unknown))
		) {
			// NOTE: sometimes the setup component will init twice when reload the page
			// Need to ensure open Precheck modal once only
			if (!this.hasOpenModals()) {
				this.openModal(applicationDetails);
			}
		}
	}

	hasOpenModals(): boolean {
		return this.modalService.hasOpenModals();
	}
}
