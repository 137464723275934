import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { DocumentDeliveryMethodDTO } from '../typings/setup';

export interface DocumentDeliveryMethod {
	id?: number;
	methodId?: number;
}

export class DocumentDeliveryMethodModelTransformer {
	static fromPayload(documentDeliveryMethodDTO: DocumentDeliveryMethodDTO): DocumentDeliveryMethod {
		return {
			id: documentDeliveryMethodDTO.id,
			methodId: documentDeliveryMethodDTO.methodId
		} as DocumentDeliveryMethod;
	}

	static toPayload(documentDeliveryMethod: DocumentDeliveryMethod): DocumentDeliveryMethodDTO {
		return {
			id: documentDeliveryMethod.id ?? CONSTANTS.NEW_ID,
			methodId: documentDeliveryMethod.methodId
		} as DocumentDeliveryMethodDTO;
	}
}
