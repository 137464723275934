import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { LoanPurposeConflictDTO } from '@app/modules/typings/api';

export class LoanPurposeConflictsTransformer {
	static toPayload(model: LoanPurposeConflictsModel): LoanPurposeConflictDTO {
		return {
			conflictDetails: model.customerConflictsDetails,
			hasConflicts: getNullableBoolean(model.customerConflictYesNo)
		};
	}
	static fromPayload(data: LoanPurposeConflictDTO): LoanPurposeConflictsModel {
		return {
			customerConflictsDetails: data.conflictDetails,
			customerConflictYesNo: getNullableYesNo(data.hasConflicts)
		};
	}
}

export interface LoanPurposeConflictsModel {
	customerConflictYesNo?: YesNo;
	customerConflictsDetails?: string;
}
