import { Injectable } from '@angular/core';
import { distinctUntilArrayItemChanged, select } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { minDate, yearMonthDayFromToday } from '../../helper/date.helper';

import { FormStateStore } from './form-state.store';

@Injectable({ providedIn: 'root' })
export class FormStateQuery {
	simpFormState$ = this.formStateStore.store
		.asObservable()
		.pipe(map((simpFormState) => ({ ...cloneDeep(simpFormState), ...this.stateFunctions() })));
	variationsUpdated$ = this.formStateStore.store.pipe(
		select((state) => state.variations),
		distinctUntilArrayItemChanged()
	);

	isHLIGManuallyCompleted$ = this.formStateStore.store.pipe(
		select((state) => state.isHLIGManuallyCompleted),
		distinctUntilChanged()
	);

	constructor(protected formStateStore: FormStateStore) {}
	skipServiceability() {
		const storeValue = this.formStateStore.store.value;
		return storeValue.hasCompanyOrTrustApplicants && storeValue.channelSetting?.skipServiceabilitySubmitWorkflow;
	}

	// keep this function light weight by extracting the logic to helper classes
	private stateFunctions() {
		return {
			yearMonthDayFromToday,
			get minDate() {
				return minDate();
			}
		};
	}
}
