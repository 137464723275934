export enum FormlyFieldTypes {
	Address = 'address',
	AddressSelect = 'address-select',
	Amount = 'amount',
	AmountTotal = 'amount-total',
	AmountSelectInput = 'amount-select-input',
	Array = 'array',
	ButtonGroup = 'button-group',
	ButtonInput = 'button-input',
	Checkbox = 'checkbox',
	RadioButton = 'radio-button',
	RadioButtonGroup = 'radio-button-group',
	DatePicker = 'datepicker',
	DateRangePicker = 'date-range-picker',
	Input = 'input',
	Number = 'number',
	Percentage = 'percentage',
	Object = 'object',
	Section = 'section',
	MobileStep = 'mobile-step',
	Select = 'select',
	SubSection = 'sub-section',
	MobileSubSection = 'mobile-sub-section',
	Area = 'area',
	Button = 'button',
	NumberSelectInput = 'number-select-input',
	TickBox = 'tick-box',
	Table = 'table',
	MultiBadgeSelect = 'multi-badge-select',
	Label = 'label',
	Textarea = 'textarea',
	ToggleSwitch = 'toggle-switch',
	MultiCheckboxSelect = 'multi-checkbox-select',
	Link = 'link',
	FileUpload = 'file-upload',
	FileImage = 'file-image',
	Spinner = 'spinner',
	TelInput = 'tel-input',
	Duration = 'duration',
	Alert = 'alert'
}
