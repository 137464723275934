import { PrimaryPurposeRealEstateAsset, ResidentialType, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { Address, RefiPropertyAssetsDTO } from '@app/modules/typings/api';

export class RefiPropertyDetailsTransformer {
	static fromPayload(data: RefiPropertyAssetsDTO, isRefi: boolean): RefiPropertyDetails {
		return {
			foundProperty: getNullableYesNo(data.foundProperty),
			primaryPurpose: data.primaryPurpose,
			firstHomeBuyer: getNullableYesNo(data.firstHomeBuyer),
			propertyType: data.propertyType,
			address: data.foundProperty || isRefi ? data.address : undefined,
			postcode: !data.foundProperty ? data.address : undefined,
			estimatedValue: data.estimatedValue,
			realEstateAssetId: data.realEstateAssetId,
			applicationId: data.applicationId
		};
	}

	static toPayload(model: RefiPropertyDetails, applicationId: number): RefiPropertyAssetsDTO {
		return {
			applicationId: applicationId,
			foundProperty: getNullableBoolean(model.foundProperty),
			realEstateAssetId: model.realEstateAssetId,
			primaryPurpose: model.primaryPurpose,
			address: getNullableBoolean(model.foundProperty) !== false ? model.address : model.postcode,
			estimatedValue: model.estimatedValue,
			propertyType: model.propertyType,
			firstHomeBuyer: getNullableBoolean(model.firstHomeBuyer),
			toBeUsedAsSecurity: true
		};
	}
}

export interface RefiPropertyDetails {
	applicationId?: number;
	postcode?: Address;
	foundProperty?: YesNo;
	primaryPurpose?: PrimaryPurposeRealEstateAsset;
	firstHomeBuyer?: YesNo;
	propertyType?: ResidentialType;
	address?: Address;
	estimatedValue?: number;
	realEstateAssetId?: number;
}
