export interface FormStore<T = unknown> {
	draft?: boolean;
	data: T[];
	loading?: boolean;
	placeHolderTemplate?: string;
	patch?: T[];
	header?: any;
}

export const formStoreCast = <T>(nextState: unknown) => (nextState as FormStore<T>).data;
