import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FinancialInstitution, LoanTermUnits, RepaymentFrequency } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Action } from '@app/modules/simp-formly/components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, PersonApplicantDTO, RefiPersonalLoanDTO } from '@app/modules/typings/api';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiPersonalLoanTransformer {
	static toPayload(
		personalLoan: RefiPersonalLoanModel,
		applicationId: number,
		formEnumQuery: FormEnumsQuery
	): RefiPersonalLoanDTO {
		return {
			liabilityId: personalLoan.personalLoansModal.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			currentLender: personalLoan.personalLoansModal.currentLender,
			currentLoanBalance: personalLoan.personalLoansModal.currentBalance,
			repaymentAmount: personalLoan.personalLoansModal.repayment.amount,
			repaymentFrequency: personalLoan.personalLoansModal.repayment.frequency,
			percentsOwned: RefiTransformerHelper.setPercentsOwnedWithJointSelection(
				formEnumQuery,
				personalLoan.personalLoansModal.ownership
			),
			remainingTermDuration: personalLoan.personalLoansModal.remainingYears,
			remainingTermUnits: LoanTermUnits.Years,
			accountNumber: personalLoan.personalLoansModal.accountNumber,
			creditLimit: personalLoan.personalLoansModal.creditLimit
		};
	}

	static fromPayload(
		personalLoan: RefiPersonalLoanDTO,
		formEnumQuery: FormEnumsQuery,
		applicants: PersonApplicantDTO[]
	): RefiPersonalLoanModel {
		const summary = `${RefiTransformerHelper.getJointApplicantNames(
			applicants,
			personalLoan.percentsOwned
		)} ${formatCurrency(personalLoan.currentLoanBalance || 0, 'en-AU', '$')}, ${formEnumQuery.getOptionLabel(
			'FinancialInstitution',
			personalLoan.currentLender as number
		)}`;
		let applicantId = 0;
		if (personalLoan.percentsOwned && personalLoan.percentsOwned.length > 0) {
			applicantId = personalLoan.percentsOwned[0].applicantId || 0;
		}

		const personalLoanModel: RefiPersonalLoanModel = {
			summary: summary,
			reconcileRequired: personalLoan.reconcileRequired ?? false,
			personalLoansModal: {
				id: personalLoan.liabilityId,
				currentLender: personalLoan.currentLender,
				currentBalance: personalLoan.currentLoanBalance as number,
				ownership: RefiTransformerHelper.getOwnership(personalLoan.percentsOwned),
				percentsOwned: personalLoan.percentsOwned,
				repayment: {
					amount: personalLoan.repaymentAmount as number,
					frequency: personalLoan.repaymentFrequency as RepaymentFrequency
				},
				remainingYears: personalLoan.remainingTermDuration,
				readonly: personalLoan.readonly,
				reconcileRequired: personalLoan.reconcileRequired ?? false,
				summaryItemArray: [
					{
						summary: {
							items: {
								id: personalLoan.liabilityId,
								type: 'Personal loan',
								currentBalance: personalLoan.currentLoanBalance
							}
						}
					}
				],
				suggestedMatchId: personalLoan.suggestedMatchId,
				isUserDeclared: personalLoan.isUserDeclared,
				accountNumber: personalLoan.accountNumber,
				creditLimit: personalLoan.creditLimit
			}
		};
		return personalLoanModel;
	}
}

export interface RefiPersonalLoanModel {
	personalLoansModal: RefiPersonalLoanDetails;
	summary?: string;
	reconcileRequired?: boolean;
}

export interface RefiPersonalLoanDetails {
	id?: number;
	currentLender?: FinancialInstitution;
	currentBalance: number;
	ownership?: number;
	repayment: AmountSelect;
	percentsOwned?: PercentOwned[];
	remainingYears?: number;
	readonly?: boolean;
	reconcileRequired?: boolean;
	summaryItemArray?: PersonalLoanSummary[];
	reconcileAction?: Action;
	suggestedMatchId?: number;
	isUserDeclared?: boolean;
	accountNumber?: string;
	creditLimit?: number;
}

export interface PersonalLoanSummary {
	summary?: PersonalLoanSummaryItems;
}

export interface PersonalLoanSummaryItems {
	items?: PersonalLoanSummaryItemsList;
}

export interface PersonalLoanSummaryItemsList {
	id?: number;
	type?: string;
	currentBalance?: number;
}
