import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyLabel = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'label',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-3')}`,
			templateOptions: {
				...property.templateOptions,
				formatter: property.templateOptions?.formatter,
				options: property.options
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			defaultValue: property.templateOptions?.defaultValue
		}
	}
});
