import { FaceToFaceType, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorInterviewTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorInterviewModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			faceToFace: payload.interviewIsFaceToFace,
			faceToFaceType: payload.faceToFaceType,
			readWithoutDifficulty: getNullableYesNo(payload.readWithoutDifficulty),
			readWithoutDifficultyVideo: getNullableYesNo(payload.readWithoutDifficultyVideo),
			onlyPersonInRoom: payload.onlyPersonInRoom,
			onlyPersonPresent: payload.interviewIsOnlyPersonPresent,
			seekIndependentAdvice: payload.advisedToSeekIndependentAdvice
		};
	}
}

export interface GuarantorInterviewModel {
	id?: number;
	lendingGuaranteeId?: number;
	faceToFace?: boolean;
	faceToFaceType?: FaceToFaceType;
	readWithoutDifficulty?: YesNo;
	readWithoutDifficultyVideo?: YesNo;
	onlyPersonPresent?: boolean;
	onlyPersonInRoom?: boolean;
	seekIndependentAdvice?: boolean;
}
