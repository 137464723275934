import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { CONSTANTS } from '../constants/constants';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';

@Injectable({ providedIn: 'root' })
export class ApplicationGuard implements CanActivate {
	constructor(private router: Router, private applicationDataQuery: ApplicationDataQuery) {}

	public canActivate(): boolean {
		const initialApplicationId = this.applicationDataQuery.applicationId();
		if (initialApplicationId > 0) {
			return true;
		} else {
			void this.router.navigateByUrl(CONSTANTS.LOANAPP_CLIENT_LANDING_URL, {
				state: { redirectedFromAppChange: true }
			});
			return false;
		}
	}
}
