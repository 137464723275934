import { Importance, RedrawImportantOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { LoanPreferenceRedrawDTO } from '@app/modules/typings/api';

export class RedrawTransformer {
	static toPayload(applicationId: number, details: RedrawModel): LoanPreferenceRedrawDTO {
		const options = details.redrawExtras?.redrawImportantOptions?.options ?? [];

		return {
			applicationId: applicationId,
			importance: details.redrawImportanceId,
			risksExplained: details.redrawRisksExplained === true,
			reasonDescription: details.redrawExtras?.redrawImportantOptions?.otherReasonInput,
			reasonFlexibilityToAccessPrepaidFundsIfNeeded: options?.includes(
				RedrawImportantOptions.ReasonFlexibilityToAccessPrepaidFundsIfNeeded
			),
			reasonOther: !!details.redrawExtras?.redrawImportantOptions?.otherReasonInput
		};
	}
	static fromPayload(data: LoanPreferenceRedrawDTO): RedrawModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);

		const details: RedrawModel = {
			redrawExtras: {
				redrawImportantOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				}
			},
			redrawImportanceId: data.importance as Importance,
			redrawRisksExplained: data.risksExplained as boolean
		};
		return details;
	}

	private static getSelectedIds(data: LoanPreferenceRedrawDTO, selectedIds: number[]) {
		if (data.reasonFlexibilityToAccessPrepaidFundsIfNeeded) {
			selectedIds.push(RedrawImportantOptions.ReasonFlexibilityToAccessPrepaidFundsIfNeeded);
		}
	}
}

export interface RedrawModel {
	redrawImportanceId: Importance;
	redrawRisksExplained: boolean;
	redrawExtras: {
		redrawImportantOptions: MultiCheckboxModel;
	};
}

export interface RedrawStoredModel {
	redrawContainer: RedrawModel;
}
