import { Injectable } from '@angular/core';
import { DigitalWidgetSSIServiceType } from '@app/modules/digital-widgets/enums/digital-widgets-configuration.enums';
import { Observable } from 'rxjs';
import { URL_CONSTANTS } from '../../constants/api.constants';
import { BaseJourneyService } from '../../service/base-journey.service';

@Injectable({ providedIn: 'root' })
export class ChannelService extends BaseJourneyService<any> {
	constructor() {
		super();
		this.setRoute(`Loanapp/${URL_CONSTANTS.CHANNEL}`);
	}

	isServiceActive(serviceType: DigitalWidgetSSIServiceType): Observable<boolean> {
		return <Observable<boolean>>this.get(`IsActive/${serviceType}`);
	}
}
