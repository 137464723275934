import { PreferredContactPerson } from '@app/modules/shared/enums/app.enums';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import {
	ForeignTaxAssociationDetailsModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { Address, RelatedPersonDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export interface RelatedPersonModel {
	id: number;
	applicationId: number;
	nameTitleId?: number;
	firstName?: string;
	middleNames?: string;
	lastName?: string;
	mobileNumber?: PhoneDetails;
	address?: Address;
	dateOfBirth?: string;
	email?: string;
	preferredContact?: PreferredContactPerson;
	foreignTaxAssociationId?: number;
	foreignTaxAssociationDetails: ForeignTaxAssociationDetailsModel[];
}

export class RelatedPersonModelTransformer {
	static toPayload(relatedPerson: RelatedPersonModel, applicationId: number): RelatedPersonDTO {
		if (relatedPerson.address) {
			relatedPerson.address.applicationId = applicationId;
		}
		return {
			id: relatedPerson?.id ?? 0,
			applicationId,
			firstName: relatedPerson?.firstName || '',
			middleNames: relatedPerson?.middleNames,
			lastName: relatedPerson?.lastName || '',
			nameTitleId: relatedPerson?.nameTitleId,
			mobile: PhoneNumberHelper.toPayLoad(relatedPerson?.mobileNumber),
			email: relatedPerson?.email,
			dateOfBirth: relatedPerson?.dateOfBirth,
			preferredContact: relatedPerson?.preferredContact,
			address: relatedPerson?.address,
			foreignTaxAssociationId: relatedPerson?.foreignTaxAssociationId
		};
	}

	static fromPayload(relatedPersonDto: RelatedPersonDTO, applicationId: number): RelatedPersonModel {
		return {
			id: relatedPersonDto.id,
			applicationId,
			nameTitleId: relatedPersonDto.nameTitleId,
			firstName: relatedPersonDto.firstName,
			middleNames: relatedPersonDto.middleNames,
			lastName: relatedPersonDto.lastName,
			mobileNumber: PhoneNumberHelper.fromPayload(relatedPersonDto.mobile),
			address: relatedPersonDto.address,
			dateOfBirth: relatedPersonDto.dateOfBirth,
			email: relatedPersonDto.email,
			preferredContact: relatedPersonDto.preferredContact,
			foreignTaxAssociationId: relatedPersonDto.foreignTaxAssociationId,
			foreignTaxAssociationDetails: relatedPersonDto.foreignTaxAssociationDTO
				? ForeignTaxAssociationTransformer.fromPayload(relatedPersonDto.foreignTaxAssociationDTO, relatedPersonDto)
						?.foreignTaxAssociationDetails ?? []
				: []
		};
	}
}
