import { Pipe, PipeTransform } from '@angular/core';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import { EnumObject } from '@simpology/client-components/utils';
import { isObservable, Observable, of } from 'rxjs';

@Pipe({
	name: 'selectOptions'
})
export class SelectOptionsPipe implements PipeTransform {
	constructor(private formEnumsService: FormEnumsService) {}

	/**
	 * Resolves the options keyword from API or Store
	 * @param options as keyword for accessing value from store or api
	 * options - as list of EnumObject[] returned as Observable
	 */
	transform(options: string | EnumObject[]): Observable<EnumObject[]> {
		if (options === undefined || options === null || !options) {
			return of([]);
		} else if (typeof options === 'string') {
			return this.formEnumsService.fetch(options);
		} else if (isObservable(options)) {
			return options as Observable<EnumObject[]>;
		} else {
			return of(options);
		}
	}
}
