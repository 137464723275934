import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { hasInvalidField, isNullOrUndefined, parseArea } from '@app/modules/shared/helper/util';
import { NavigationService } from '@app/modules/shared/service/navigation.service';
import { RemoteValidationService } from '@app/modules/shared/service/remote-validation.service';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { FormValidationsQuery } from '@app/modules/shared/store/form-validations/form-validations.query';
import {
	FormValidationsService,
	ValidationParams
} from '@app/modules/shared/store/form-validations/form-validations.service';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { SimpSpinnerService } from '@simpology/client-components';
import { get, isEmpty } from 'lodash-es';
import isEqual from 'lodash-es/isEqual';
import { Observable, Subject, Subscription, isObservable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { IncomeHeaderSummary, LiabilitiesHeaderSummary } from '@app/modules/shared/model/header-summary.model';
import { FormAreaPath } from '@app/modules/shared/typings/form-areas.types';
import { formlyGetLabelsObject } from '../../helpers/simp-formly.helper';
import { FormlyLabels } from '../../helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '../../services/simp-formly-handler.service';

@Component({
	selector: 'formly-sub-section',
	templateUrl: './formly-sub-section.component.html',
	styleUrls: ['./formly-sub-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySubSectionComponent extends FieldArrayType implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('subSection') subSection?: ElementRef;
	loading$ = of(true);
	placeHolderTemplate$: Observable<string | undefined> = of(undefined);
	subSectionData$: Observable<unknown[]> = of([]);
	remoteErrors$?: Observable<string[] | undefined>;
	validated = false;
	subSectionId = '';
	showSectionSeparator = true;
	headerField: FormlyFieldConfig | undefined = undefined;
	headerForm = new UntypedFormGroup({});
	headerData$: Observable<IncomeHeaderSummary | LiabilitiesHeaderSummary> = of();
	private hasRemoteError = false;
	private valueChangeSubject: Subject<any> = new Subject();
	private destroy$: Subject<void> = new Subject();
	private subscriptions: Subscription[] = [];
	subsectionLoaderText: FormlyLabels = {};

	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private cdr: ChangeDetectorRef,
		private simpSpinnerService: SimpSpinnerService,
		private formValidationsService: FormValidationsService,
		private formValidationsQuery: FormValidationsQuery,
		private router: Router,
		private navigationService: NavigationService,
		private remoteValidationService: RemoteValidationService,
		private applicationDataService: ApplicationDataService
	) {
		super();
	}

	get showHeader(): boolean {
		return isNullOrUndefined(this.field?.props?.hideHeader)
			? !this.field.props.expanded
			: !this.field?.props?.hideHeader;
	}

	ngOnInit(): void {
		this.to.remove = this.remove.bind(this);
		this.to.add = this.add.bind(this);
		this.field.resetOnHide = false;
		const key = this.getKey();
		this.showSectionSeparator = !!(this.to?.label || this.to?.collapsible);

		if (this.to.header) {
			this.headerField = this.to.header as FormlyFieldConfig;
			this.headerData$ = this.simpFormlyHandlerService.mapToSubSectionHeader(key);
		}

		if (this.to.loaderText) {
			const applicantsLoaderText = this.to.loaderText as FormlyFieldConfig;
			this.subsectionLoaderText = formlyGetLabelsObject(applicantsLoaderText);
		}

		/**
		 * Watch for changes in each control inside sub section
		 */
		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;

		this.loading$ =
			this.to.handler !== CONSTANTS.SKIP_MAP_TO_STATE
				? this.simpFormlyHandlerService.mapToLoading(key) || of(false)
				: of(false);
		this.simpSpinnerService.loading$ = of(true);

		this.placeHolderTemplate$ =
			this.to.handler !== CONSTANTS.SKIP_MAP_TO_STATE
				? this.simpFormlyHandlerService.mapToPlaceHolderTemplate(key) || of(undefined)
				: of(undefined);

		const data$ =
			this.to.handler !== CONSTANTS.SKIP_MAP_TO_STATE ? this.simpFormlyHandlerService.mapToStateData(key) : null;
		this.subSectionData$ = data$ || of([]);

		if (data$) {
			// update form control when store gets updated. added delay to let sub-section be destroyed if modal is empty array
			data$
				.pipe(takeUntil(this.destroy$), withLatestFrom(this.simpFormlyHandlerService.mapToPatchData(key)))
				.subscribe(([data, patches]) => {
					const formControlLength = (this.model as Object[]).length ?? 0;
					const noOfDifItems = data.length - formControlLength ?? 0;
					if (formControlLength !== data.length) {
						if (noOfDifItems > 0) {
							for (let index = 0; index < noOfDifItems; index++) {
								const subSectionData = data[formControlLength + index];
								this.add(formControlLength + index, subSectionData);
							}
						} else if ((this.model as unknown[]).length) {
							for (let index = 0; index < -noOfDifItems; index++) {
								//INSTEAD OF this.remove(0) that removes from first index, we do below to remove tailing part of the array starting from the separation point at n, this.remove(n)
								this.remove(formControlLength + noOfDifItems);
							}
						}
					}

					// if there are patches to be applied to form, then only update for with patch data, otherwise patch whole data
					if (patches?.length) {
						this.applyPatches(patches, data);
					} else {
						this.updateModelWithoutPatches(data);
					}
					this.markFormControlAsPristine();
				});
		} else if (this.to.handler === CONSTANTS.SKIP_MAP_TO_STATE) {
			if (this.formControl?.controls.length !== (this.model as unknown[])?.length) {
				this.add(0);
				this.remove(0);
			}
		} else {
			console.warn(`Store not found for sub-section - ${key}`);
		}

		// remove the default one added and let the state drive the modal
		if (get(this.field, 'parent.wrappers[0]') === 'modal' && get(this.model, '[0].default', false)) {
			this.remove(0);
		}

		this.formValidationsService.triggerValidations$
			.pipe(takeUntil(this.destroy$), startWith())
			.subscribe((validationParams: ValidationParams) => {
				if (!validationParams || !validationParams.key || validationParams.key === this.key) {
					this.validate();
				}
			});
		this.subSectionId = this.simpFormlyHandlerService.getKey(key);
		if (this.to.handler !== CONSTANTS.SKIP_MAP_TO_STATE) {
			this.remoteErrors$ = this.formValidationsQuery.mapToSubSectionRemoteError(this.subSectionId).pipe(
				tap((remoteErrors) => {
					if (remoteErrors && !this.to.expanded) {
						this.to.expanded = true;
					}
					this.hasRemoteError = !!remoteErrors && remoteErrors.length > 0;
				})
			);
		}

		this.navigationService.scrolledToSubSection$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (this.subSectionId === id) {
				this.scrollIntoView();
			}
		});
	}

	ngAfterViewInit(): void {
		this.formControl.valueChanges
			.pipe(
				startWith(this.formControl.value),
				takeUntil(this.destroy$),
				distinctUntilChanged((a: unknown[], b: unknown[]) => a?.length === b?.length)
			)
			.subscribe(() => {
				this.subscriptions.forEach((s) => {
					s.unsubscribe();
				});
				this.subscriptions = this.setupChangeDetection();
			});
		if (this.navigationService.navigatedTo(this.subSectionId)) {
			this.scrollIntoView();
		}
	}

	onElementView(insideView: boolean, field: FieldArrayTypeConfig): void {
		if (insideView) {
			this.applicationDataService.toggleSideNavItem(get(field, 'parent.index', 0), true);
		}
	}

	ngOnDestroy(): void {
		this.valueChangeSubject.complete();
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleButtonClick() {
		if (this.to.click) {
			this.to.click(this.field);
		} else {
			this.add();
		}
	}

	isObservable(value: string | Observable<string>): boolean {
		return isObservable(value);
	}

	trackByFn(index: number, item: FormlyFieldConfig): string | undefined {
		return item.id; // Assuming `id` is the unique identifier property
	}

	private markFormControlAsPristine() {
		setTimeout(() => {
			if (this.hasRemoteError && this.formControl.dirty) {
				const formAreaPath = parseArea(this.router.url.replace('/', '')) as FormAreaPath;
				this.remoteValidationService.fetchRemoteValidations(formAreaPath);
			}
			this.formControl.markAsPristine();
		});
	}

	private updateModelWithoutPatches(data: unknown[]) {
		this.formControl?.patchValue(data);

		if (this.model && this.field.options?.checkExpressions) {
			Object.assign(this.model, data);
			// check expression to repopulate the controls hidden from DOM because of hideExpression.
			this.field.options.checkExpressions(this.field);
			this.cdr.markForCheck();
		}
	}
	private applyPatches(patches: unknown[], data: unknown[]) {
		let updateModel = false;
		patches.forEach((patch, index) => {
			if (patch && !isEmpty(patch)) {
				updateModel = true;
				this.formControl?.at(index)?.patchValue(patch, { emitEvent: false });
			}
		});
		if (updateModel && this.model) {
			Object.assign(this.model, data);
		}
	}
	private scrollIntoView(): void {
		setTimeout(() => {
			if (this.subSection?.nativeElement) {
				(this.subSection.nativeElement as Element).scrollIntoView({ behavior: 'smooth' });
			}
			this.to.expanded = true;
			this.cdr.detectChanges();
		});
	}

	private validate(): void {
		const subsectionIsNotRequired = this.field.templateOptions?.required === false;
		if (!this.field.validation?.show && subsectionIsNotRequired) {
			return;
		}

		if (this.field.validation) {
			this.field.validation.show = false;
			this.field.validation.show = true;
		}

		this.formControl.markAllAsTouched();

		this.simpFormlyHandlerService.updateDraftFlag(this.getKey(), this.formControl.invalid);
		this.props.expanded = this.props.collapsible ? this.formControl.invalid : (this.props.expanded as boolean);
		this.validated = true;
	}

	private setupChangeDetection() {
		return this.formControl.controls.map((control, index) => {
			return control.valueChanges
				.pipe(
					debounceTime(this.to.debounceTime === undefined ? 1000 : this.to.debounceTime),
					distinctUntilChanged((a: unknown, b: unknown) => isEqual(a, b)),
					takeUntil(this.destroy$),
					filter(() => {
						return (
							(control.valid ||
								((this.to.saveInvalid as boolean) &&
									!hasInvalidField(this.formControl.controls[0] as UntypedFormArray))) &&
							control.dirty
						);
					})
				)
				.subscribe((data: unknown) => {
					if (this.formControl.errors) {
						return;
					}
					this.valueChangeSubject.next({ data, index });
				});
		});
	}

	private getKey(): string {
		let key = this.field.key as string;
		if (
			key !== 'household' &&
			key !== 'relationship' &&
			key !== 'relatedPerson' &&
			key !== 'relatedCompany' &&
			key !== 'relatedCompanyPartner' &&
			this.router.url === '/applicants'
		) {
			const parentKey = this.field.parent?.key as string;
			const index = parentKey.substring(parentKey.lastIndexOf('-') + 1);
			key += `-${index}`;
		}
		return key;
	}
}
