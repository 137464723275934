<simp-field-error
	*ngIf="(formControl.dirty || formControl.touched) && formControl.errors"
	[errors]="formControl.errors"
	[validationErrors]="field.validation?.messages!"
>
</simp-field-error>
<div class="d-inline" [ngSwitch]="to.arrayType">
	<ng-container *ngSwitchDefault>
		<div *ngFor="let fieldItem of field.fieldGroup; let i = index" class="simp-row" [class.hide-label]="i > 0">
			<div class="col-sm-10">
				<formly-field [field]="fieldItem"></formly-field>
			</div>
			<div class="col-sm-2 d-flex" *ngIf="!to.static">
				<button [disabled]="to.readonly" class="btn icon-btn" type="button" (click)="remove(i)">
					<i class="col-1 far fa-trash"></i>
				</button>
			</div>
		</div>
		<button
			*ngIf="!to.static"
			class="simp-row"
			(click)="add()"
			[disabled]="formControl.invalid || to.readonly"
			type="button"
			class="btn btn-sm simp-button simp-margin-top-small loanapp-area__add-button"
		>
			{{ to.subText ? to.subText : 'Add more' }} <i lass="fal fa-plus simp-margin-left-base"></i>
		</button>
	</ng-container>

	<ng-container *ngSwitchCase="'alternate'">
		<div
			*ngFor="let fieldItem of field.fieldGroup; let i = index"
			class="simp-row"
			[class.hide-label]="i > 0 && !to.displayLabel && !to.showAllLabels"
			[ngClass]="to.customClass"
		>
			<div class="col-12">
				<formly-field [field]="fieldItem"></formly-field>
			</div>
		</div>
		<!-- Don't disable if error is just required -->
		<button
			class="simp-row"
			(click)="add()"
			[disabled]="
				(formControl.invalid &&
					(((formControl.errors | keyvalue) ?? []).length > 1 || !formControl.errors?.required)) ||
				to.readonly
			"
			type="button"
			class="btn btn-sm simp-button simp-margin-top-small loanapp-area__add-button"
			*ngIf="!to.static && (to.maxLength ? field.fieldGroup?.length! < to.maxLength : true)"
		>
			{{ to.subText ? to.subText : 'Add more' }}<i class="fal fa-plus simp-margin-left-base"></i>
		</button>
	</ng-container>

	<ng-container *ngSwitchCase="'plain'">
		<div [ngClass]="field.className || ''">
			<formly-field
				[ngClass]="to.customClass"
				*ngFor="let fieldItem of field.fieldGroup; let i = index"
				[field]="fieldItem"
			></formly-field>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'sideBySide'">
		<div class="simp-row">
			<formly-field
				[ngClass]="to.customClass"
				*ngFor="let fieldItem of field.fieldGroup; let i = index"
				[field]="fieldItem"
			></formly-field>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'inline'">
		<div
			*ngFor="let fieldItem of field.fieldGroup; let i = index"
			class="simp-row"
			[ngClass]="to.displayLabel ? 'simp-padding-top-small' : ''"
			[class.hide-label]="i > 0 && !to.displayLabel && !to.showAllLabels"
		>
			<div [ngClass]="to.static ? 'col-12' : 'col-11'">
				<formly-field [field]="fieldItem"></formly-field>
			</div>
			<div
				[ngClass]="to.displayLabel ? 'simp-padding-top-medium' : 'd-flex'"
				class="col-1"
				*ngIf="!to.static && (!to.minLength || i >= to.minLength)"
			>
				<button [disabled]="to.readonly" class="btn icon-btn" type="button" (click)="remove(i)">
					<i class="col-1 far fa-trash"></i>
				</button>
			</div>
		</div>
		<button
			*ngIf="!to.static"
			(click)="add()"
			[disabled]="formControl.invalid || to.readonly"
			type="button"
			[ngClass]="to.displayLabel ? 'simp-margin-top-base' : 'simp-margin-top-small'"
			class="row btn btn-sm simp-button loanapp-area__add-button"
		>
			<span
				>{{ field.fieldGroup!.length > 0 ? 'Add more' : 'Add' }}<i class="fal fa-plus simp-margin-left-base"></i
			></span>
		</button>
	</ng-container>
</div>
