import { RefiConfirmDetailsDTO } from '@app/modules/typings/api';

export class RefiConfirmDetailsTransformer {
	static toPayload(model: RefiConfirmDetailsModel, applicationId: number): RefiConfirmDetailsDTO {
		return {
			id: model.id,
			applicationId: applicationId,
			fullName: model.fullName,
			email: model.email,
			mobilePhoneNumber: model.mobilePhoneNumber,
			australianPostCode: model.australianPostCode
		};
	}

	static fromPayload(data: RefiConfirmDetailsDTO, applicationId: number): RefiConfirmDetailsModel {
		return {
			id: data.id,
			applicationId: applicationId,
			fullName: data.fullName,
			email: data.email,
			mobilePhoneNumber: data.mobilePhoneNumber,
			australianPostCode: data.australianPostCode,
			status: !!data.status
		};
	}
}

export interface RefiConfirmDetailsModel {
	id: number;
	applicationId: number;
	fullName: string;
	email: string;
	mobilePhoneNumber: string;
	australianPostCode: string;
	status: boolean;
}
