import { DocumentationDeliveryMethod } from '@app/modules/shared/enums/app.enums';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorDeliveryMethodTransfomer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorsDeliveryMethodModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			deliveryMethod: payload.documentationDeliveryMethodId
		};
	}
}
export interface GuarantorsDeliveryMethodModel {
	id?: number;
	lendingGuaranteeId?: number;
	deliveryMethod?: DocumentationDeliveryMethod;
}
