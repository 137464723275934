<hr class="simp-margin-top-base" aria-hidden="true" />
<div [id]="id">
	<div class="d-flex justify-content-between sub-section__header">
		<h5
			class="simp-h5 simp-text--secondary {{ to.labelClass }}"
			[class.loanapp-area__sub-section-title]="to.collapsible"
			(click)="to.expanded = !to.expanded"
		>
			{{ to.label }}
		</h5>

		<div>
			<button
				*ngIf="!to.readonly"
				type="button"
				(click)="navigateTo(to.route)"
				class="{{
					to.btnClass ? to.btnClass : 'btn btn-sm simp-button loanapp-area__add-button simp-margin-right-small'
				}}"
			>
				Edit
				<i class="fas fa-pencil simp-margin-left-base" *ngIf="!to.btnClass"></i>
				<span class="visually-hidden">{{ to.label }}</span>
			</button>
			<i
				*ngIf="to.collapsible"
				class="fas loanapp-area__up-down-chevron"
				[ngClass]="to.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
				(click)="to.expanded = !to.expanded"
			></i>
		</div>
	</div>
	<div class="simp-margin-top-small" [hidden]="!to.expanded && to.collapsible">
		<formly-field *ngFor="let f of field.fieldGroup; trackBy: trackByFn" [field]="f"></formly-field>
	</div>
</div>
