import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ApplicantEntityType,
	ApplicantRole,
	BenefitEnquiryType,
	CoApplicantBenefitType,
	TargetType,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { CoApplicantInterviewDTO, CoApplicantInterviewDetailsDTO } from '@app/modules/typings/api';

export class CoApplicantInterviewTransformer {
	static toPayload(applicationId: number, model: CoApplicantInterviewDetails): CoApplicantInterviewDTO {
		return {
			applicationId: applicationId,
			coApplicantInterviewApplicants: this.getCoApplicantInterviewDetails(model)
		};
	}
	static getCoApplicantInterviewDetails(
		details: CoApplicantInterviewDetails
	): CoApplicantInterviewDetailsDTO[] | undefined {
		const coApplicantsInterviewDetailDTOs: CoApplicantInterviewDetailsDTO[] = [];
		details.applicants?.applicantsList?.forEach((element, index) => {
			const coApplicantsInterviewDetailsDTO: CoApplicantInterviewDetailsDTO = {
				id: details.ids?.[index] ? details.ids[index] : CONSTANTS.NEW_ID,
				sequenceNumber: index,
				applicantId: details.applicants?.applicantsList?.[index].applicantId,
				applicantType: TargetType.PersonApplicant,
				coApplicantBenefitId: details.coApplicantBenefits?.coApplicantBenefitsList?.[index].coApplicantBenefit,
				benefitEnquiriesId: details.benefitEnquiries?.benefitEnquiriesList?.[index].benefitEnquiry,
				benefitEnquiriesDescription:
					details.benefitEnquiriesDescriptions?.benefitEnquiriesDescriptionsList?.[index].benefitEnquiriesDescription,
				coApplicantReason: details.coApplicantReasons?.coApplicantReasonsList?.[index].coApplicantReason,
				proportionateInterestInAssets: getNullableBoolean(details.proportionateInterestInAssets?.yesNo?.[index].answer),
				repayDebtsOrOtherObligations: getNullableBoolean(details.repayDebtsOrOtherObligations?.yesNo?.[index].answer),
				otherBenefits: getNullableBoolean(details.otherBenefits?.yesNo?.[index].answer),
				understandsCoApplicantObligations: getNullableBoolean(
					details.understandsCoApplicantObligations?.yesNo?.[index].answer
				),
				suspectDifficultyUnderstandingObligations: getNullableBoolean(
					details.suspectDifficultyUnderstandingObligations?.yesNo?.[index].answer
				),
				suspectExperiencingFinancialAbuse: getNullableBoolean(
					details.suspectExperiencingFinancialAbuse?.yesNo?.[index].answer
				),
				suspectUnderPressure: getNullableBoolean(details.suspectUnderPressure?.yesNo?.[index].answer),
				suspectUnfairConduct: getNullableBoolean(details.suspectUnfairConduct?.yesNo?.[index].answer)
			};
			coApplicantsInterviewDetailDTOs.push(coApplicantsInterviewDetailsDTO);
		});
		return coApplicantsInterviewDetailDTOs;
	}

	static fromPayload(
		coApplicantInterViewData: CoApplicantInterviewDTO,
		applicants: ShortApplicant[],
		complianceCoApplicantFilterTypes: number[],
		applicationId?: number
	): CoApplicantInterviewDetails[] {
		applicants = applicants.filter(
			(applicant) =>
				complianceCoApplicantFilterTypes.includes(applicant.applicantRole as number) &&
				applicant.applicantEntityType === ApplicantEntityType.PersonApplicant
		);

		// there should be at least one non primary borrower to make the section visible
		const isCoBorrowerPresent = applicants.some(
			(applicant) => applicant.applicantRole !== ApplicantRole.PrimaryBorrower
		);

		if (!isCoBorrowerPresent) {
			return [
				{
					isCoBorrowerPresent: isCoBorrowerPresent
				} as CoApplicantInterviewDetails
			];
		}

		// create initial data if not exist
		if (
			!(
				coApplicantInterViewData.coApplicantInterviewApplicants &&
				coApplicantInterViewData.coApplicantInterviewApplicants.length
			)
		) {
			coApplicantInterViewData = {
				coApplicantInterviewApplicants: applicants.map((applicant) => {
					return {
						applicantId: applicant.id
					};
				}),
				applicationId: applicationId
			} as CoApplicantInterviewDTO;
		} else if (
			coApplicantInterViewData.coApplicantInterviewApplicants &&
			coApplicantInterViewData.coApplicantInterviewApplicants.length === 1
		) {
			// reset other interview data for primary applicant when section is visible again
			coApplicantInterViewData.coApplicantInterviewApplicants =
				coApplicantInterViewData.coApplicantInterviewApplicants?.map((coApplicantInterviewApplicant) => {
					return {
						applicantId: coApplicantInterviewApplicant.applicantId,
						id: coApplicantInterviewApplicant.id
					};
				});
		}

		const coApplicantsInterviewDetails: CoApplicantInterviewDetails = {
			isCoBorrowerPresent: isCoBorrowerPresent,
			applicants: {
				applicantsList:
					applicants?.map((applicant, index) => {
						return {
							applicantId: applicant.id,
							applicant: applicant.name,
							currentIndex: index
						};
					}) ?? null
			},
			coApplicantBenefits: {
				coApplicantBenefitsList: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						coApplicantBenefit: applicantData?.coApplicantBenefitId,
						currentIndex: index
					};
				})
			},
			benefitEnquiries: {
				benefitEnquiriesList: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						benefitEnquiry: applicantData?.benefitEnquiriesId,
						currentIndex: index
					};
				})
			},
			benefitEnquiriesDescriptions: {
				benefitEnquiriesDescriptionsList: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						benefitEnquiriesDescription: applicantData?.benefitEnquiriesDescription,
						currentIndex: index
					};
				})
			},
			coApplicantReasons: {
				coApplicantReasonsList: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						coApplicantReason: applicantData?.coApplicantReason,
						currentIndex: index
					};
				})
			},
			proportionateInterestInAssets: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.proportionateInterestInAssets),
						currentIndex: index
					};
				})
			},
			repayDebtsOrOtherObligations: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.repayDebtsOrOtherObligations),
						currentIndex: index
					};
				})
			},
			otherBenefits: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.otherBenefits),
						currentIndex: index
					};
				})
			},
			understandsCoApplicantObligations: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.understandsCoApplicantObligations),
						currentIndex: index
					};
				})
			},
			suspectDifficultyUnderstandingObligations: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.suspectDifficultyUnderstandingObligations),
						currentIndex: index
					};
				})
			},
			suspectExperiencingFinancialAbuse: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.suspectExperiencingFinancialAbuse),
						currentIndex: index
					};
				})
			},
			suspectUnderPressure: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.suspectUnderPressure),
						currentIndex: index
					};
				})
			},
			suspectUnfairConduct: {
				yesNo: applicants.map((x, index) => {
					const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
						(y) => y.applicantId == x.id
					) as CoApplicantInterviewDetailsDTO;
					return {
						answer: getNullableYesNo(applicantData?.suspectUnfairConduct),
						currentIndex: index
					};
				})
			},
			ids: applicants.map((x) => {
				const applicantData = coApplicantInterViewData.coApplicantInterviewApplicants?.find(
					(y) => y.applicantId == x.id
				) as CoApplicantInterviewDetailsDTO;
				return applicantData?.id ? applicantData?.id : CONSTANTS.NEW_ID;
			})
		};
		return [coApplicantsInterviewDetails];
	}
}

export class CoApplicantInterviewDetails {
	isCoBorrowerPresent?: boolean;
	ids?: number[];
	applicants?: { applicantsList?: { applicantId: number; applicant: string; currentIndex?: number }[] };
	coApplicantBenefits?: {
		coApplicantBenefitsList?: { coApplicantBenefit?: CoApplicantBenefitType; currentIndex?: number }[];
	};
	benefitEnquiries?: { benefitEnquiriesList?: { benefitEnquiry?: BenefitEnquiryType; currentIndex?: number }[] };
	benefitEnquiriesDescriptions?: {
		benefitEnquiriesDescriptionsList?: { benefitEnquiriesDescription?: string; currentIndex?: number }[];
	};
	coApplicantReasons?: { coApplicantReasonsList?: { coApplicantReason?: string; currentIndex?: number }[] };
	proportionateInterestInAssets?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	repayDebtsOrOtherObligations?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	otherBenefits?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	understandsCoApplicantObligations?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	suspectDifficultyUnderstandingObligations?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	suspectExperiencingFinancialAbuse?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	suspectUnderPressure?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
	suspectUnfairConduct?: { yesNo: { answer?: YesNo; currentIndex?: number }[] };
}
