import { Injectable } from '@angular/core';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { CustomNgbModalRef, SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class CustomerSearchTransformerService {
	customerSearchModalRef: CustomNgbModalRef | undefined;
	applicantEntityType: ApplicantEntityType | undefined;
	clientId: string | undefined;

	constructor(private formlyModalService: SimpFormlyModalService) {}

	openCustomerSearchModal(
		field: FormlyFieldConfig | undefined,
		modalPath: string | undefined,
		applicantEntityType: ApplicantEntityType,
		clientId?: string | undefined
	): CustomNgbModalRef | undefined {
		this.customerSearchModalRef = undefined;
		this.applicantEntityType = applicantEntityType;
		this.clientId = clientId;
		const modalField = modalPath ? getFormField(field?.fieldGroup, modalPath) : field;
		if (modalField) {
			this.customerSearchModalRef = this.formlyModalService.openModal(modalField, undefined, { backdrop: 'static' });
		}
		return this.customerSearchModalRef;
	}
}
