import { JsonSchemaProperty } from '../../typings/formly-app';
import {
	parseExpressionProperties,
	parseExpressionString,
	parseNestedExpressionProperties
} from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyAmountSelectInput = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,

	widget: {
		formlyConfig: {
			type: 'amount-select-input',
			className: `${property.templateOptions?.class ?? (property.class || 'col-4')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			defaultValue: { frequency: property.templateOptions?.defaultValue }
		}
	},
	properties: {
		amount: {
			type: 'amount',
			widget: {
				formlyConfig: {
					templateOptions: {
						minLength: (property.templateOptions?.minLength as number) || undefined,
						maxLength: (property.templateOptions?.maxLength as number) || undefined,
						minValue: (property.templateOptions?.min as number) || undefined,
						maxValue: (property.templateOptions?.max as number) || undefined,
						required: property.templateOptions?.required as boolean
					},
					expressionProperties: parseNestedExpressionProperties(property),
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		frequency: {
			type: 'select',
			widget: {
				formlyConfig: {
					templateOptions: {
						searchable: property.templateOptions?.searchable,
						options: property.options,
						bindValueToObject: property.templateOptions?.bindValueToObject ?? false,
						disabled: !!property.templateOptions?.disabled || !!property.templateOptions?.disableFrequency,
						required: property.templateOptions?.required as boolean
					},
					expressionProperties: parseNestedExpressionProperties(property),
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		}
	}
});
