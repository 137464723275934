import { Injectable } from '@angular/core';
import { LoanServiceabilitySummaryModelTransformer } from '@app/modules/loan-serviceability/model/loan-serviceability-summary.model';
import {
	IndicatorMetric,
	ServiceabilityCalculationResult,
	ServiceabilityResult
} from '@app/modules/loan-serviceability/model/serviceability-calculation-result.model';
import { ServiceabilityCalculationService } from '@app/modules/loan-serviceability/serviceability-calculation.service';
import { PropertyService } from '@app/modules/property/services/property.service';
import { formlyExtendExpressionProperties, getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { SimpFormlyHandlerService } from './../../../simp-formly/services/simp-formly-handler.service';
import { SummaryBaseTransformerService } from './summary-base-transformer.service';

@Injectable({ providedIn: 'root' })
export class LoanServiceabilitySummaryTransformerService extends SummaryBaseTransformerService {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private serviceabilityCalculationService: ServiceabilityCalculationService,
		private propertyService: PropertyService
	) {
		super();
	}

	transform(formFields: FormlyFieldConfig | undefined): Observable<FormlyFieldConfig | undefined> {
		if (!formFields) {
			return of(formFields);
		}

		formlyExtendExpressionProperties(formFields.fieldGroup, 'loanRequirements.splitTitle', {
			'templateOptions.label': (model: any, state: unknown, field: FormlyFieldConfig) => {
				return (field?.parent?.parent?.fieldGroup || []).length > 1
					? field?.parent?.key
						? `Split - ${Number(field?.parent?.key) + 1}`
						: 'Split'
					: '';
			}
		});

		return forkJoin({
			purchaseDetails: this.transformPurchaseDetails(formFields),
			serviceability: this.transformServiceability(formFields)
		}).pipe(
			map(() => {
				return formFields;
			})
		);
	}

	private transformPurchaseDetails(formFields: FormlyFieldConfig) {
		const purchaseDetails$ = this.propertyService.fetchSecurityProperties();
		const purchaseDetailsConfig = getFormField(formFields?.fieldGroup, 'purchaseDetails');
		return purchaseDetails$.pipe(
			map((purchaseDetails) => {
				if (purchaseDetailsConfig) {
					this.transformFieldConfig(purchaseDetailsConfig, purchaseDetails, 'purchaseDetails');
				}
			})
		);
	}

	private transformServiceability(formFields: FormlyFieldConfig) {
		const serviceability$ = this.serviceabilityCalculationService.calculateServiceability().pipe(
			tap((calculatedServiceability) => {
				this.simpFormlyHandlerService.upsertToStateWithData('loanServiceabilitySummary', [calculatedServiceability]);
			})
		);

		const serviceabilityConfig = getFormField(formFields?.fieldGroup, 'serviceability');
		return serviceability$.pipe(
			map((serviceability: ServiceabilityResult) => {
				if (serviceabilityConfig) {
					if (!serviceability || (serviceability.calculationResults?.length ?? 0) === 0) {
						// If no serviceability hide other attributes
						this.hideAttributes(serviceabilityConfig, false);
					} else if (serviceability && serviceability.calculationResults.length === 1) {
						// Create dynamic metrics
						if (serviceability.calculationResults[0].isSkipped) {
							this.hideAttributes(serviceabilityConfig, true);
						} else {
							this.createMetricsValues(serviceabilityConfig, serviceability.calculationResults);
						}
					}
				}

				return serviceabilityConfig;
			})
		);
	}

	private hideAttributes(serviceabilityConfig: FormlyFieldConfig, isSkipped: boolean): FormlyFieldConfig {
		const fieldArray = serviceabilityConfig?.fieldArray as FormlyFieldConfig;
		const primaryDetails = getFormField(fieldArray.fieldGroup, 'primaryDetails');
		if (primaryDetails) {
			Object.assign(primaryDetails, { ...primaryDetails, className: 'hidden' });
		}
		const secondaryDetails = getFormField(fieldArray.fieldGroup, 'secondaryDetails');
		if (secondaryDetails) {
			Object.assign(secondaryDetails, { ...secondaryDetails, className: 'hidden' });
		}
		if (isSkipped) {
			const labels = getFormField(fieldArray.fieldGroup, 'serviceabilitySkipLabels') as FormlyFieldConfig;
			Object.assign(labels, { ...labels, hideExpression: false });
		} else {
			const labels = getFormField(fieldArray.fieldGroup, 'serviceabilityLabels') as FormlyFieldConfig;
			Object.assign(labels, { ...labels, hideExpression: false });
		}

		return serviceabilityConfig;
	}

	private createMetricsValues(
		serviceabilityConfig: FormlyFieldConfig,
		serviceability: ServiceabilityCalculationResult[]
	): FormlyFieldConfig {
		const secondaryDetails = getFormField(
			(serviceabilityConfig?.fieldArray as FormlyFieldConfig)?.fieldGroup,
			'secondaryDetails'
		);
		if (secondaryDetails) {
			const sampleFormField = getFormField(
				(serviceabilityConfig?.fieldArray as FormlyFieldConfig)?.fieldGroup,
				'secondaryDetails.sampleMetric'
			);
			secondaryDetails.fieldGroup = [];
			serviceability[0].metrics.forEach((metric: IndicatorMetric) => {
				const field = cloneDeep(sampleFormField);
				if (field) {
					Object.assign(field, {
						...field,
						key: LoanServiceabilitySummaryModelTransformer.getAttributeKey(metric),
						templateOptions: { ...field.templateOptions, label: metric.shortName }
					});
					secondaryDetails.fieldGroup?.push(field);
				}
			});
		}
		return serviceabilityConfig;
	}
}
