<label class="simp-label">{{ to.label }}</label>

<div
	class="drop-zone"
	(dragover)="onDragOver($event)"
	(dragleave)="onDragLeave($event)"
	(drop)="onDrop($event)"
	[ngClass]="showSolidBorder ? 'drop-zone-solid-border' : 'drop-zone-dashed-border'"
>
	<button type="button" class="btn btn-primary simp-button simp-margin-right-small" (click)="uploadDoc()">
		Browse...
	</button>
	<input #fileUploader type="file" style="display: none" (change)="fileChange($event)" [accept]="acceptedFileTypes" />
	<span class="simp-text--grey90">Select or drops files here</span>
</div>

<div
	class="simp-text-small simp-margin-top-micro simp-text--red"
	*ngIf="listErrors.length > 0 || listWarnings.length > 0"
>
	<div class="simp-margin-top-micro" *ngFor="let warning of listWarnings">{{ warning.message }}</div>
	<div class="simp-margin-top-micro" *ngFor="let error of listErrors">{{ error.message }}</div>
</div>
