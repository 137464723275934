import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FileType } from '../model/file-type.model';

export class StringHelper {
	public static DoesContainMatchedString = (originalString: string, searchString: string): boolean =>
		originalString.search(new RegExp(searchString, 'i')) > -1;

	public static isAcceptedFileExtension(extensions: Array<string>, fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return extensions.includes(ext);
	}

	public static isCommonFileExtension(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return CONSTANTS.commonFileExtensions.includes(ext);
	}

	public static isWordFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'doc' || ext === 'docx';
	}

	public static isExcelFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'xls' || ext === 'xlsx';
	}

	public static isImageFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'bmp';
	}

	public static isPdfFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'pdf';
	}

	public static isXmlFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'xml';
	}

	public static isMosFile(fileName: string): boolean {
		const ext = this.getFileExtension(fileName);
		return ext === 'mos';
	}

	public static getFileType(fileName: string): FileType {
		const ext = this.getFileExtension(fileName);
		if (ext === 'pdf') {
			return FileType.Pdf;
		}

		if (ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'bmp') {
			return FileType.Image;
		}

		if (ext === 'doc' || ext === 'docx') {
			return FileType.Word;
		}

		if (ext === 'xls' || ext === 'xlsx') {
			return FileType.Excel;
		}

		return FileType.Other;
	}

	public static getFileExtension(fileName: string): string {
		if (!fileName) {
			return '';
		}

		const fileNameParts = fileName.split('.');
		if (fileNameParts.length < 2) {
			return '';
		}

		return fileNameParts[fileNameParts.length - 1].toLowerCase();
	}

	public static isPdfFileLink(fileLink: string): boolean {
		if (!fileLink) {
			return false;
		}

		const fileNameParts = fileLink.split('.');
		if (fileNameParts.length < 2) {
			return false;
		}
		const ext = fileNameParts[fileNameParts.length - 1].split('?')[0].toLowerCase();
		return ext === 'pdf';
	}
}
