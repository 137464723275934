import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ENV_CONFIG } from '@app/env-config';
import { AnalyticsEventType, RefiAnalyticsService } from '@app/modules/refi-journey/services/refi-analytics.service';
import { RefiLogout } from '@app/modules/refi-journey/typings/refi-logout';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { getFromLocalStorage } from '@app/modules/shared/helper/local-storage.helper';
import { BrandingService } from '@simpology/authentication';
import { keysIn } from 'lodash-es';

@Component({
	selector: 'refi-logout',
	templateUrl: './refi-logout.component.html',
	styleUrls: ['./refi-logout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefiLogoutComponent implements OnInit {
	labels = {
		discardedTitle: '',
		discardedIcon: '',
		discardedMessage1: '',
		discardedMessage2: '',
		savedTitle: '',
		savedIcon: '',
		savedMessage1: '',
		savedMessage2: '',
		secondaryButton: '',
		secondaryButtonLink: ''
	} as RefiLogout;

	applicationDiscarded = false;

	constructor(
		private brandingService: BrandingService,
		private cdr: ChangeDetectorRef,
		private title: Title,
		private router: Router,
		private refiAnalyticsService: RefiAnalyticsService
	) {}

	ngOnInit(): void {
		this.title.setTitle(
			this.router.url.includes(CONSTANTS.REFI_LOGOUT_PATH) ? `Refinance application` : `Home loan application`
		);

		this.brandingService.setBranding(ENV_CONFIG, true);
		this.applicationDiscarded = getFromLocalStorage(`${CONSTANTS.REFI_LOGOUT_DISCARD_APPLICATION}`) === 'true';
		keysIn(this.labels).forEach((key) => {
			this.labels[key as keyof RefiLogout] =
				getFromLocalStorage(
					`${
						this.router.url.includes(CONSTANTS.REFI_LOGOUT_PATH)
							? CONSTANTS.REFI_LOGOUT_PATH
							: CONSTANTS.PURCHASE_LOGOUT_PATH
					}-${key}` as keyof RefiLogout
				) || '';
		});
		this.refiAnalyticsService.initialiseAnalytics();
		setTimeout(() => {
			if (this.applicationDiscarded) {
				this.refiAnalyticsService.trackEvent(AnalyticsEventType.PageView, `${this.router.url}#discarded`);
			} else {
				this.refiAnalyticsService.trackEvent(AnalyticsEventType.PageView, `${this.router.url}#saved`);
			}
		}, 500);
		this.cdr.markForCheck();
	}

	handleButtonClick(): void {
		if (this.applicationDiscarded) {
			this.refiAnalyticsService.trackEvent(AnalyticsEventType.SecondaryButtonClick, `${this.router.url}#discarded`);
		} else {
			this.refiAnalyticsService.trackEvent(AnalyticsEventType.SecondaryButtonClick, `${this.router.url}#saved`);
		}
	}
}
