import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FrequencyShort } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import { EnumLabelPipe } from '../../pipes/formly-enum-label.pipe';
import { AmountSelect } from '../../typings/formly-app';

@Component({
	selector: 'formly-amount-total',
	templateUrl: './formly-amount-total.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAmountTotalComponent extends FieldType<FieldTypeConfig> {
	constructor(private enumLabelPipe: EnumLabelPipe) {
		super();
	}

	get data() {
		const amountSelect = get<AmountSelect>(this.model, this.key as number);
		let frequencyText = 'month';

		switch (amountSelect.frequency) {
			case FrequencyShort.Monthly:
				frequencyText = 'month';
				break;
			case FrequencyShort.Fortnightly:
				frequencyText = 'fortnight';
				break;
			case FrequencyShort.Weekly:
				frequencyText = 'week';
				break;
			case FrequencyShort.Yearly:
				frequencyText = 'year';
				break;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return {
			amount: amountSelect.amount,
			frequency: frequencyText
		};
	}
}
