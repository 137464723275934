import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-area',
	templateUrl: './formly-area.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAreaComponent extends FieldArrayType {
	trackByFn(index: number, item: FormlyFieldConfig): string | undefined {
		return item.id; // Assuming `id` is the unique identifier property
	}
}
