import { Injectable } from '@angular/core';
import { LinkedApplicationDetails } from '@app/modules/setup/typings/setup';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LinkedApplicationDetailsService extends BaseJourneyService<LinkedApplicationDetails> {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.LINKED_APPLICATION_DETAILS);
	}

	getLinkedApplications(): Observable<LinkedApplicationDetails[]> {
		return this.getAllByParameter(`${this.applicationDataQuery.applicationId()}`);
	}
}
