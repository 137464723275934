<div class="mobile-section">
	<div class="mobile-section__container">
		<div class="mobile-section__section-title simp-margin-top-base col-md-6">
			<simp-icon [name]="to.iconClass" class="simp-margin-bottom-small" *ngIf="to.iconClass"></simp-icon>
			<ng-container *ngIf="to.label">
				<h1 class="simp-h1 simp-text--primary simp-text--bold mobile-section__title--mobile">
					{{ label$ | async }}
				</h1>
				<h1 class="simp-h1 simp-text--primary simp-text--bold mobile-section__title--desktop">{{ label$ | async }}</h1>
			</ng-container>
			<ng-container *ngIf="to.titleImage">
				<img src="{{ to.titleImage }}" alt="" class="w-100" />
			</ng-container>

			<div
				*ngIf="to.infoText && (loading$ | async) === false"
				class="refi-info-box d-flex simp-margin-top-small simp-text--primary"
			>
				<simp-icon name="fas fa-circle-info" class="refi-info-icon"></simp-icon>
				<div [innerHTML]="to.infoText"></div>
			</div>
			<div
				class="simp-text simp-margin-top-medium sub-text"
				*ngIf="to.subText && (loading$ | async) === false"
				[innerHTML]="to.subText"
			></div>
		</div>

		<div class="mobile-section__form-container col-md-6 simp-margin-top-small">
			<div class="mobile-section__form">
				<ng-container *ngIf="(loading$ | async) === false">
					<formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
				</ng-container>
				<div class="mobile-section__spinner" *ngIf="(loading$ | async) === true">
					<simp-spinner placement="inline"></simp-spinner>
				</div>
				<div
					*ngIf="(loading$ | async) === false && (to.primaryButtonText || to.secondaryButtonText)"
					class="mobile-section__buttons-container simp-row"
					[class.flex-column]="to.verticalButtons"
				>
					<div
						[ngClass]="[
							to.verticalButtons ? 'col-12' : 'col-6 order-2',
							to.verticalButtons && to.secondaryButtonText ? 'simp-margin-bottom-medium' : ''
						]"
						*ngIf="to.primaryButtonText"
					>
						<button
							type="button"
							class="btn btn-primary btn-lg mobile-section__button simp-button col-md-12"
							[class.loading]="to.loading"
							(click)="primaryCallBack(field)"
							[disabled]="to.disablePrimaryButton || to.loading"
							id="primary-button"
						>
							<ng-container>
								<span *ngIf="!to.loading">{{ to.primaryButtonText }}</span>
								<simp-spinner
									placement="inline"
									indicatorSize="40px"
									[useAlternateColor]="true"
									*ngIf="to.loading"
									class="mt-1"
								></simp-spinner>
							</ng-container>
						</button>
					</div>
					<div [ngClass]="to.verticalButtons ? 'col-12' : 'col-6 order-1'" *ngIf="to.secondaryButtonText">
						<button
							type="button"
							class="btn btn-secondary btn-lg mobile-section__button simp-button col-md-12"
							(click)="secondaryCallBack(field)"
							id="secondary-button"
						>
							{{ to.secondaryButtonText || 'Cancel' }}
						</button>
					</div>

					<div *ngIf="to.footer" class="col-12 order-3 mobile-section__footer-margin simp-text--primary">
						{{ to.footer }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
