import { BorrowingInfo } from '@app/modules/loan-serviceability/model/borrowing.model';
import { LoanInformation } from '@app/modules/loan-serviceability/model/loan-information.model';
import { Product } from '@app/modules/loan-serviceability/model/product.model';
import { PaymentType } from '@app/modules/shared/enums/app.enums';
import { getYearsAndMonthsText } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ProductSelectionComplianceDTO } from '@app/modules/typings/api';
import { LoanPurposePreferredFeatureTransformer } from './loan-purpose-preferred-feature';

export class ProductSelectionTransformer {
	static fromPayload(
		data: LoanInformation,
		borrowings: BorrowingInfo[],
		products: Product[],
		productNames: ProductInfo[],
		selectedProducts: ProductSelectionComplianceDTO[],
		formEnumsQuery: FormEnumsQuery
	): SelectedProductModel {
		const mappedSelectedProducts: SelectedProduct[] = [];
		selectedProducts.forEach((x) => {
			const mappedProductIndex = mappedSelectedProducts.findIndex(
				(product) => product.selectedProductId === x?.selectedProductId
			);
			if (mappedProductIndex === -1) {
				mappedSelectedProducts.push({
					selectedProductTitle: x?.selectedProductTitle,
					selectedProductExplained: x?.selectedProductExplained,
					selectedProductSubText: x?.selectedProductSubtext,
					selectedProductId: x?.selectedProductId,
					selectedProductVariationIds: [x?.selectedProductVariationId]
				});
			} else {
				mappedSelectedProducts[mappedProductIndex].selectedProductVariationIds?.push(x?.selectedProductVariationId);
			}
		});

		return {
			loanSplitsArray: borrowings.map((borrowing) => {
				const loanProduct = products.find((product) => product.loanId === borrowing.loanId);
				return this.getLoanSplits(borrowing, loanProduct, productNames, formEnumsQuery);
			}),
			totalItem: { totalAmount: data.amountRequested },
			selectedProductsArray: mappedSelectedProducts
		};
	}
	static getLoanSplits(
		borrowing: BorrowingInfo,
		product: Product | undefined,
		productNames: ProductInfo[],
		formEnumsQuery: FormEnumsQuery
	): LoanSplit {
		const productInfo = productNames.find((info) => info.loanId === borrowing.loanId);

		return {
			loanSplits: {
				borrowingAmount: borrowing.amountRequested,
				loanTerm: LoanPurposePreferredFeatureTransformer.getLoanTermText(product),
				repaymentType: this.getPaymentType(product, formEnumsQuery),
				product: productInfo?.productName ?? ''
			}
		};
	}
	static getPaymentType(product: Product | undefined, formEnumsQuery: FormEnumsQuery): string {
		const showTerm = product?.paymentType !== PaymentType.PrincipalandInterest;
		const months = product?.interestOnlyPeriod;
		const termText = showTerm && months ? `(${getYearsAndMonthsText(months ?? 0)})` : '';
		return product?.paymentType
			? `${formEnumsQuery.getOptionLabel('PaymentType', product.paymentType)} ${termText} `
			: '';
	}
	static toPayload(model: SelectedProductModel): ProductSelectionComplianceDTO[] {
		return model.selectedProductsArray.flatMap((product) => {
			return product?.selectedProductVariationIds?.map((variationId) => ({
				selectedProductExplained: product.selectedProductExplained,
				selectedProductId: product.selectedProductId,
				selectedProductSubtext: product.selectedProductSubText,
				selectedProductTitle: product.selectedProductTitle,
				selectedProductVariationId: variationId
			}));
		}) as ProductSelectionComplianceDTO[];
	}
}

export interface SelectedProductModel {
	loanSplitsArray: LoanSplit[];
	totalItem: { totalAmount?: number };
	selectedProductsArray: SelectedProduct[];
}

interface LoanSplit {
	loanSplits: {
		product?: string;
		borrowingAmount?: number;
		loanTerm: string;
		repaymentType: string;
	};
}
export interface SelectedProduct {
	selectedProductTitle: string;
	selectedProductSubText: string;
	selectedProductExplained?: boolean;
	selectedProductId?: number;
	selectedProductVariationIds?: number[];
}
export interface ProductInfo {
	loanId: number;
	productName: string;
	productId: number;
	productVariationId: number;
}
