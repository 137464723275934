import { RefiSecondBorrowerDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class RefiSecondBorrowerTransformer {
	static toPayload(model: RefiSecondBorrowerModel, applicationId: number): RefiSecondBorrowerDTO {
		return {
			applicationId,
			firstName: model.firstName,
			middleName: model.middleName,
			lastName: model.lastName,
			email: model.email,
			mobilePhoneNumber: `${model.mobilePhoneNumber.dialCode}${model.mobilePhoneNumber.nationalNumber}`,
			dateOfBirth: model.dateOfBirth,
			australianPostCode: model.australianPostCode
		};
	}
}

export interface RefiSecondBorrowerModel {
	applicationId: number;
	firstName: string;
	middleName: string;
	lastName: string;
	email: string;
	mobilePhoneNumber: PhoneDetails;
	dateOfBirth: string;
	australianPostCode: number;
	foundCoBorrower: boolean;
	addCoBorrower: boolean;
	keepCoBorrower: boolean;
}
