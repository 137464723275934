import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { JourneyType, PaymentType, ResidentialType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsStore } from '@app/modules/shared/store/form-enums/form-enums.store';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import {
	disableFutureDates,
	formlyExtendExpressionProperties,
	formlyOnChange,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { PercentOwned, RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { RefiApplicationSummaryService } from '../../components/refi-form/application-summary/application-summary.service';
import { RefiStepToPath, RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiLoanRequirementModel, RefiLoanRequirementTransformer } from '../../models/refi-loan-requirements';
import { RefiProductSelectionApiService } from '../product-selection/refi-product-selection-api.service';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiJourneyLoanRequirementService } from './refi-loan-requirements.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyLoanRequirementsTransformerService {
	constructor(
		private refiJourneyService: RefiJourneyService,
		private loanRequirementService: RefiJourneyLoanRequirementService,
		private formEnumQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private journeyStepService: JourneyStepService,
		private applicationDataQuery: ApplicationDataQuery,
		private refiModalService: RefiModalService,
		private stepStatusQuery: StepStatusQuery,
		private productSelectionService: RefiProductSelectionApiService,
		private applicationSummaryService: RefiApplicationSummaryService,
		private formEnumStore: FormEnumsStore,
		private sharedFlagsService: SharedFlagsService
	) {}

	public loanRequirementsTransform(formFields: FormlyFieldConfig[]) {
		formlyExtendExpressionProperties(formFields, `loanRequirements.details.depositPaid`, {
			'templateOptions.tooltipAsModal': (model, formState, field) => {
				if (field && field?.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
				return true;
			}
		});

		formlyOnClick(formFields, 'loanRequirements.details.depositPaid', (field, clickType: { type: string }) => {
			if (clickType?.type === 'tooltipClick') {
				this.openInfoModal(formFields, RefiModalName.lowDepositOptions).subscribe();
			}
		});

		formlyOnClick(
			formFields,
			'loanRequirements.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				if (clickType.type === 'primary') {
					if (this.refiJourneyService.isAdminUser) {
						this.refiJourneyService.navigateToNextStep(field);
						return;
					}

					if (!clickType.invalid) {
						if (field.templateOptions) {
							field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
						}

						const model = field.model as RefiLoanRequirementModel[];
						if (!clickType.changed) {
							this.refiJourneyService.navigateToNextStep(field);
							return;
						}

						this.simpFormlyHandlerService.deleteFromFullStatePath(RefiStore.ProductSelection, 0);

						const payload = RefiLoanRequirementTransformer.toPayload(
							model[0],
							this.applicationDataQuery.applicationId(),
							this.applicationDataQuery.getRefiApplicants()
						);
						this.loanRequirementService.saveLoanRequirement(payload).subscribe((res) => {
							if (res) {
								this.simpFormlyHandlerService.updateToState(
									'loanRequirements',
									RefiLoanRequirementTransformer.fromPayload(
										payload,
										this.applicationDataQuery.applicationId(),
										this.stepStatusQuery.getStepStatus(RefiStepType.RefiLoanRequirements)
									),
									0
								);
							}
							this.journeyStepService
								.updateStep(RefiStepType.RefiLoanRequirements, StepStatus.Complete)
								.subscribe(() => {
									this.refiJourneyService.navigateToNextStep(field);
								});
							if (this.stepStatusQuery.isCompleted(RefiStepType.RefiSelectProduct)) {
								this.productSelectionService.checkProductSelection();
							}
						});
					}
				}
			}
		);

		formlyOnClick(formFields, 'loanRequirements.details.offsetFacilityInfo', () => {
			this.openInfoModal(formFields, RefiModalName.offsetFacilityInfo).subscribe();
		});
		formlyOnClick(formFields, 'loanRequirements.details.noOngoingFeesInfo', () => {
			this.openInfoModal(formFields, RefiModalName.noOngoingFeesInfo).subscribe();
		});
		formlyOnClick(formFields, 'loanRequirements.details.noOngoingFeesFacilityInfo', () => {
			this.openInfoModal(formFields, RefiModalName.feesInfo).subscribe();
		});
		formlyOnClick(formFields, 'loanRequirements.details.redrawInfo', () => {
			this.openInfoModal(formFields, RefiModalName.redrawPayments).subscribe();
		});

		disableFutureDates(formFields, 'loanRequirements.details.loanEstablishmentDate');

		const loanAmountField = getFormField(formFields, 'loanRequirements.details.loanAmount');
		this.simpFormlyHandlerService
			.mapToFullStateDataPath<RefiApplicationSummaryDTO[]>(RefiStore.ApplicationSummary)
			?.subscribe((summary) => {
				if (loanAmountField?.templateOptions?.subText) {
					const subText = loanAmountField.templateOptions.subText as string;
					loanAmountField.templateOptions.subText = subText.replace(
						'amount',
						`${formatCurrency(summary[0]?.loanBalance, 'en-AU', '$')}`
					);
				}
			});

		formlyRegisterHooks(formFields, 'loanRequirements.details.isTaxDeductible', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(formFields, `loanRequirements.details.isTaxDeductible`, (field, clickType: { type: string }) => {
			if (clickType?.type === 'tooltipClick') {
				this.openInfoModal(formFields, RefiModalName.isTaxDeductible);
			}
		});

		formlyOnClick(formFields, `loanRequirements.details.loanAmount`, (field, clickType: { type: string }) => {
			if (clickType?.type === 'tooltipClick') {
				this.openInfoModal(
					formFields,
					this.sharedFlagsService.currentJourney === JourneyType.Refi
						? RefiModalName.borrowAdditionalMoney
						: RefiModalName.currentLoanBalance
				);
			}
		});

		formlyRegisterHooks(formFields, 'loanRequirements.details.interestRate', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					if (field.templateOptions.options) {
						const options = this.formEnumQuery.getOptions(field.templateOptions.options as unknown as string);
						const isNotSureOptionInOptions = options.find((x) => x.id === -1);
						if (!isNotSureOptionInOptions) {
							options.push({ id: -1, label: 'Split' });
						}
					}
				}
			}
		});

		const percentArray = getFormField(formFields, 'loanRequirements.details.percentsOwned');
		const numberOfApplicants = this.applicationDataQuery.getRefiApplicants();
		if (percentArray) {
			percentArray.defaultValue = numberOfApplicants.sort((a) => (a.isCurrentUser ? 0 : 1));
		}

		formlyExtendExpressionProperties(formFields, 'loanRequirements.details.percentsOwned.percent', {
			'templateOptions.label': (model: PercentOwned, formState, field: FormlyFieldConfig) => {
				const refiApplicants = this.applicationDataQuery.getRefiApplicants();
				let firstName = refiApplicants.find((x) => x.isCurrentUser) ? 'Your' : '';
				if (field?.parent?.key && field.templateOptions?.label) {
					if (field.parent.key !== '0') {
						firstName = `${
							(refiApplicants.find((x) => x.id === model.applicantId)?.firstName || 'Co-borrower') + "'s"
						}`;
					}
				}
				return field?.templateOptions?.label?.replace('Applicant', firstName);
			}
		});

		formlyRegisterHooks(formFields, 'loanRequirements.details', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const applicants = this.applicationDataQuery.getRefiApplicants();
					if (applicants.find((app) => !app.firstName)) {
						field.templateOptions.nextRoute = 'secondBorrower/details';
					} else {
						field.templateOptions.nextRoute = RefiStepToPath.get(RefiStepType.RefiIncome);
					}
				}
			}
		});

		formlyRegisterHooks(formFields, 'loanRequirements.details.rbaLendingPurpose', {
			onInit: (field) => {
				if (field && field.templateOptions?.options) {
					const residentialType = get(field.model, 'residentialType') as ResidentialType;
					if (residentialType === ResidentialType.FullyDetachedHouse) {
						field.templateOptions.options = this.formEnumQuery.getOptions('HouseRbaLendingPurpose');
					} else if (residentialType === ResidentialType.VacantLand) {
						field.templateOptions.options = this.formEnumQuery.getOptions('VacantLandRbaLendingPurpose');
					} else {
						field.templateOptions.options = this.formEnumQuery.getOptions('OtherPropertyRbaLendingPurpose');
					}
				}
			}
		});

		this.updateLoanTermYears(1, 30);

		formlyOnChange(formFields, 'loanRequirements.details.interestOnlyPeriod', (field) => {
			const ioPeriodYears = field.formControl?.value as number;
			this.updateLoanTermYears(ioPeriodYears + 5, 30);
			const loanTermField = field.parent?.fieldGroup?.find((x) => x.key === 'loanTerm');
			const selectedLoanTerm = loanTermField?.formControl?.value as number;
			if (selectedLoanTerm < ioPeriodYears + 5) {
				loanTermField?.formControl?.setValue(null);
			}
		});

		formlyRegisterHooks(formFields, 'loanRequirements.details.loanAmount', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyRegisterHooks(formFields, 'loanRequirements.details.repaymentType', {
			onInit: (field) => {
				if (
					this.sharedFlagsService.currentJourney === JourneyType.Purchase &&
					field &&
					field.templateOptions &&
					field.templateOptions.disabled
				) {
					field.formControl?.setValue(PaymentType.PrincipalandInterest);
				}
				return of();
			}
		});
	}

	private openInfoModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): Observable<string> {
		const refiModalField = getFormField(formFields, 'loanRequirements.info-modals');
		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}

	private updateLoanTermYears(min: number, max: number): void {
		const years = [];
		for (let i = max; i >= min; i--) {
			years.push({ id: i, label: `${i} years` });
		}
		this.formEnumStore.update({ LoanTermYears: years });
	}
}
