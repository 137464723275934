import { getBoolean, parseYesNo } from '@app/modules/shared/helper/util';
import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyButtonGroup = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'button-group',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-3')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options,
				defaultValue: parsedDefaultValue(property)
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			resetOnHide: !!property.templateOptions?.resetOnHide,
			defaultValue: parsedDefaultValue(property)
		}
	}
});

const parsedDefaultValue = (property: FormlyApiProperty) => {
	const parsed =
		(property.options as unknown) === 'YesNo'
			? parseYesNo(property.templateOptions?.defaultValue)
			: getBoolean(property.templateOptions?.defaultValue as string | boolean | number);
	return parsed;
};
