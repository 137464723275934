import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-link',
	templateUrl: './formly-link.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyLinkComponent extends FieldType<FieldTypeConfig> {
	constructor() {
		super();
	}

	onClick(event: Event): void {
		event.stopPropagation();
		if (this.to.click) {
			this.to.click(this.field);
		}
	}
}
