<header class="app-header">
	<div class="app-header__contents simp-padding-right-small">
		<div class="d-flex align-items-center simp-padding-left-small">
			<img
				class="app-header__brand-logo simp-margin-right-small"
				*ngIf="brandUrl$"
				[src]="brandUrl$ | async"
				[alt]="brandName"
			/>
			<div class="d-flex flex-column">
				<p class="simp-h6 simp-text--bold">{{ appTitle }}</p>
				<p *ngIf="steps && currentStep && showSteps" class="app-header__step-counter">
					Step {{ currentStep.stepId }} of {{ steps.length }}
				</p>
			</div>
		</div>
		<button type="button" class="d-flex align-items-center ms-auto btn" (click)="logOut()" *ngIf="showLogoutButton">
			<div class="app-header__back-button app-header__save-button">{{ saveCloseTitle }}</div>
			<simp-icon name="far fa-times" class="app-header__close-button"></simp-icon>
		</button>
	</div>
</header>
