import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import {
	ApplicantEntityType,
	ApplicantRole,
	ApplicantType,
	ExternalAction,
	ExternalActionStatus
} from '@app/modules/shared/enums/app.enums';
import {
	applicantRoleToApplicantPrimary,
	applicantRoleToApplicantType,
	getApplicantRole
} from '@app/modules/shared/helper/util';

export class CompanyApplicantTransformer {
	static fromPayload(setUpApplicant: CompanyApplicantDTO, subText: string): SetUpApplicant {
		return {
			id: setUpApplicant.id,
			primaryApplicant: setUpApplicant.primaryApplicant,
			clientId: setUpApplicant.clientId,
			companyName: setUpApplicant.companyName,
			applicantTypeModal: { type: ApplicantEntityType.CompanyApplicant },
			receivesAllNotification: !!setUpApplicant.receiveInfoRequests,
			applicantType: setUpApplicant.applicantType,
			applicantRole: getApplicantRole(setUpApplicant.primaryApplicant, setUpApplicant.applicantType),
			foreignTaxAssociationId: setUpApplicant.foreignTaxAssociationId,
			subText,
			externalAction: setUpApplicant.externalAction,
			externalActionStatus: setUpApplicant.externalActionStatus
		};
	}

	static toPayload(setUpApplicant: SetUpApplicant, applicationId: number): Partial<CompanyApplicantDTO> {
		return {
			id: setUpApplicant.id,
			clientId: setUpApplicant.clientId,
			primaryApplicant: applicantRoleToApplicantPrimary(setUpApplicant.applicantRole || ApplicantRole.PrimaryBorrower),
			companyName: setUpApplicant.companyName || '',
			applicantType: applicantRoleToApplicantType(setUpApplicant.applicantRole || ApplicantRole.PrimaryBorrower),
			applicationId,
			receiveInfoRequests: !!setUpApplicant.receivesAllNotification,
			foreignTaxAssociationId: setUpApplicant.foreignTaxAssociationId,
			externalAction: setUpApplicant.externalAction,
			externalActionStatus: setUpApplicant.externalActionStatus
		};
	}
}

export interface CompanyApplicantDTO {
	id: number;
	applicationId: number;
	clientId?: string;
	applicantType: ApplicantType;
	companyName: string;
	primaryApplicant: boolean;
	receiveInfoRequests: boolean;
	foreignTaxAssociationId?: number;
	externalAction: ExternalAction;
	externalActionStatus: ExternalActionStatus;
}
