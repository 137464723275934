import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { JourneyType } from '@app/modules/shared/enums/app.enums';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';

@Injectable({ providedIn: 'root' })
export class SimpFormlyNavigationService {
	constructor(private sharedFlagService: SharedFlagsService, private router: Router) {}

	navigateToStep(nextRoute = ''): void {
		switch (this.sharedFlagService.currentJourney) {
			case JourneyType.Purchase:
				void this.router.navigateByUrl(`${CONSTANTS.PURCHASE_PATH}/${nextRoute}`);
				break;
			case JourneyType.Refi:
				void this.router.navigateByUrl(`${CONSTANTS.REFI_PATH}/${nextRoute}`);
				break;
			case JourneyType.ApprovalInPrinciple:
				void this.router.navigateByUrl(`${CONSTANTS.AIP_LANDING_PATH}${nextRoute ? '/' : ''}${nextRoute}`);
				break;
			default:
				break;
		}
	}
}
