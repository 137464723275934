import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import {
	ApplicantEntityType,
	ApplicantRole,
	ApplicantType,
	ExternalAction,
	ExternalActionStatus
} from '@app/modules/shared/enums/app.enums';
import {
	applicantRoleToApplicantPrimary,
	applicantRoleToApplicantType,
	getApplicantRole
} from '@app/modules/shared/helper/util';

export class TrustApplicantTransformer {
	static fromPayload(setUpApplicant: TrustApplicantDTO, subText: string): SetUpApplicant {
		return {
			id: setUpApplicant.id,
			primaryApplicant: setUpApplicant.primaryApplicant,
			trustName: setUpApplicant.trustName,
			clientId: setUpApplicant.clientId,
			applicantTypeModal: { type: ApplicantEntityType.TrustApplicant },
			receivesAllNotification: false,
			applicantType: setUpApplicant.applicantType,
			applicantRole: getApplicantRole(setUpApplicant.primaryApplicant, setUpApplicant.applicantType),
			foreignTaxAssociationId: setUpApplicant.foreignTaxAssociationId,
			subText,
			externalAction: setUpApplicant.externalAction,
			externalActionStatus: setUpApplicant.externalActionStatus
		};
	}

	static toPayload(setUpApplicant: SetUpApplicant, applicationId: number): Partial<TrustApplicantDTO> {
		return {
			id: setUpApplicant.id,
			clientId: setUpApplicant.clientId,
			primaryApplicant: applicantRoleToApplicantPrimary(setUpApplicant.applicantRole || ApplicantRole.PrimaryBorrower),
			trustName: setUpApplicant.trustName || '',
			applicantType: applicantRoleToApplicantType(setUpApplicant.applicantRole || ApplicantRole.PrimaryBorrower),
			foreignTaxAssociationId: setUpApplicant.foreignTaxAssociationId,
			applicationId,
			externalAction: setUpApplicant.externalAction,
			externalActionStatus: setUpApplicant.externalActionStatus
		};
	}
}

export interface TrustApplicantDTO {
	id: number;
	applicationId: number;
	clientId: string;
	applicantType: ApplicantType;
	trustName: string;
	primaryApplicant: boolean;
	foreignTaxAssociationId?: number;
	externalAction?: ExternalAction;
	externalActionStatus?: ExternalActionStatus;
}
