import { AsyncPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';

import { SharedModule } from '../shared/shared.module';
import { FormlyAddressSelectComponent } from './components/formly-address-select/formly-address-select.component';
import { FormlyAddressComponent } from './components/formly-address/formly-address.component';
import { FormlyAlertComponent } from './components/formly-alert/formly-alert.component';
import { FormlyAmountInputComponent } from './components/formly-amount-select-input/formly-amount-select-input.component';
import { FormlyAmountTotalComponent } from './components/formly-amount-total/formly-amount-total.component';
import { FormlyAmountComponent } from './components/formly-amount/formly-amount.component';
import { FormlyAreaComponent } from './components/formly-area/formly-area.component';
import { FormlyArrayComponent } from './components/formly-array/formly-array.component';
import { FormlyButtonGroupComponent } from './components/formly-button-group/formly-button-group.component';
import { FormlyButtonInputComponent } from './components/formly-button-input/formly-button-input.component';
import { FormlyButtonComponent } from './components/formly-button/formly-button.component';
import { FormlyCheckboxComponent } from './components/formly-checkbox/formly-checkbox.component';
import { FormlyDateRangePickerComponent } from './components/formly-date-range-picker/formly-date-range-picker.component';
import { FormlyDatepickerComponent } from './components/formly-datepicker/formly-datepicker.component';
import { FormlyDurationComponent } from './components/formly-duration/formly-duration.component';
import { FormlyFileImageComponent } from './components/formly-file-image/formly-file-image.component';
import { FormlyFileUploadComponent } from './components/formly-file-upload/formly-file-upload.component';
import { FormlyHeaderComponent } from './components/formly-header/formly-header.component';
import { FormlyInputComponent } from './components/formly-input/formly-input.component';
import { FormlyLabelComponent } from './components/formly-label/formly-label.component';
import { FormlyLinkComponent } from './components/formly-link/formly-link.component';
import { FormlyMobileModalWrapperComponent } from './components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import { FormlyMobileStepComponent } from './components/formly-mobile-step/formly-mobile-step.component';
import { FormlyMobileSubSectionComponent } from './components/formly-mobile-sub-section/formly-mobile-sub-section.component';
import { FormlyModalWrapperComponent } from './components/formly-modal-wrapper/formly-modal-wrapper.component';
import { FormlyMultiBadgeSelectComponent } from './components/formly-multi-badge-select/formly-multi-badge-select.component';
import { FormlyMultiCheckboxSelectComponent } from './components/formly-multi-select-checkbox/formly-multi-checkbox-select.component';
import { FormlyNestedSectionWrapperComponent } from './components/formly-nested-section-wrapper/formly-nested-section-wrapper.component';
import { FormlyNumberSelectComponent } from './components/formly-number-select-input/formly-number-select-input.component';
import { FormlyNumberComponent } from './components/formly-number/formly-number.component';
import { FormlyObjectComponent } from './components/formly-object/formly-object.component';
import { FormlyPercentageComponent } from './components/formly-percentage/formly-percentage.component';
import { FormlyRadioButtonGroupComponent } from './components/formly-radio-button-group/formly-radio-button-group.component';
import { FormlyRadioButtonComponent } from './components/formly-radio-button/formly-radio-button.component';
import { FormlyRendererComponent } from './components/formly-renderer/formly-renderer.component';
import { FormlySectionComponent } from './components/formly-section/formly-section.component';
import { DynamicOptionsPipe } from './components/formly-select/dynamic-options.pipe';
import { FormlySelectComponent } from './components/formly-select/formly-select.component';
import { FormlySpinnerComponent } from './components/formly-spinner/formly-spinner.component';
import { FormlySubSectionComponent } from './components/formly-sub-section/formly-sub-section.component';
import { FormlySummaryWrapperComponent } from './components/formly-summary-wrapper/formly-summary-wrapper.component';
import { FormlyTableComponent } from './components/formly-table/formly-table.component';
import { FormlyTelInputComponent } from './components/formly-tel-input/formly-tel-input.component';
import { FormlyTemplatesComponent } from './components/formly-templates/formly-templates.component';
import { FormlyTextAreaComponent } from './components/formly-textarea/formly-textarea.component';
import { FormlyTickBoxComponent } from './components/formly-tick-box/formly-tick-box.component';
import { FormlyToggleSwitchComponent } from './components/formly-toggle-switch/formly-toggle-switch.component';
import { InsideViewportDirective } from './directives/inside-viewport.directive';
import { MetadataKeyDirective } from './directives/metadata-key.directive';
import { FormlyFieldTypes } from './enums/formly-field-types';
import { EnumLabelPipe } from './pipes/formly-enum-label.pipe';
import { SelectOptionsPipe } from './pipes/formly-select-options.pipe';

@NgModule({
	declarations: [
		SelectOptionsPipe,
		EnumLabelPipe,
		DynamicOptionsPipe,
		FormlyInputComponent,
		FormlyAmountInputComponent,
		FormlySubSectionComponent,
		FormlySelectComponent,
		FormlyObjectComponent,
		FormlySectionComponent,
		FormlyButtonGroupComponent,
		FormlyDatepickerComponent,
		FormlyDateRangePickerComponent,
		FormlyAddressComponent,
		FormlyTemplatesComponent,
		FormlyNumberComponent,
		FormlyButtonInputComponent,
		FormlyArrayComponent,
		FormlyCheckboxComponent,
		FormlyRadioButtonComponent,
		FormlyRadioButtonGroupComponent,
		FormlyAmountComponent,
		FormlyModalWrapperComponent,
		FormlyNestedSectionWrapperComponent,
		FormlyAreaComponent,
		FormlyButtonComponent,
		FormlyPercentageComponent,
		FormlyNumberSelectComponent,
		FormlyTickBoxComponent,
		FormlyTableComponent,
		FormlyMultiBadgeSelectComponent,
		FormlyTextAreaComponent,
		FormlyToggleSwitchComponent,
		FormlySummaryWrapperComponent,
		FormlyLabelComponent,
		FormlyMultiCheckboxSelectComponent,
		FormlyMobileStepComponent,
		FormlyMobileModalWrapperComponent,
		FormlyLinkComponent,
		FormlyMobileSubSectionComponent,
		FormlyAmountTotalComponent,
		FormlyFileUploadComponent,
		FormlyFileImageComponent,
		InsideViewportDirective,
		FormlyAddressSelectComponent,
		FormlySpinnerComponent,
		FormlyTelInputComponent,
		MetadataKeyDirective,
		FormlyHeaderComponent,
		FormlyDurationComponent,
		FormlyRendererComponent,
		FormlyAlertComponent
	],
	imports: [
		SharedModule,
		FormlyModule.forRoot({
			extras: {
				resetFieldOnHide: false,
				showError: () => true
			},
			validationMessages: [{ name: 'required', message: 'This field is required' }],
			types: [
				{ name: FormlyFieldTypes.Address, component: FormlyAddressComponent, wrappers: [] },
				{ name: FormlyFieldTypes.AddressSelect, component: FormlyAddressSelectComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Amount, component: FormlyAmountComponent, wrappers: [] },
				{ name: FormlyFieldTypes.AmountTotal, component: FormlyAmountTotalComponent, wrappers: [] },
				{ name: FormlyFieldTypes.AmountSelectInput, component: FormlyAmountInputComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Array, component: FormlyArrayComponent },
				{ name: FormlyFieldTypes.ButtonGroup, component: FormlyButtonGroupComponent, wrappers: [] },
				{ name: FormlyFieldTypes.ButtonInput, component: FormlyButtonInputComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Checkbox, component: FormlyCheckboxComponent, wrappers: [] },
				{ name: FormlyFieldTypes.RadioButton, component: FormlyRadioButtonComponent, wrappers: [] },
				{ name: FormlyFieldTypes.RadioButtonGroup, component: FormlyRadioButtonGroupComponent, wrappers: [] },
				{ name: FormlyFieldTypes.DatePicker, component: FormlyDatepickerComponent, wrappers: [] },
				{ name: FormlyFieldTypes.DateRangePicker, component: FormlyDateRangePickerComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Input, component: FormlyInputComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Number, component: FormlyNumberComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Percentage, component: FormlyPercentageComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Object, component: FormlyObjectComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Section, component: FormlySectionComponent },
				{ name: FormlyFieldTypes.MobileStep, component: FormlyMobileStepComponent },
				{ name: FormlyFieldTypes.Select, component: FormlySelectComponent, wrappers: [] },
				{ name: FormlyFieldTypes.SubSection, component: FormlySubSectionComponent },
				{ name: FormlyFieldTypes.MobileSubSection, component: FormlyMobileSubSectionComponent, wrappers: [] },
				{ name: FormlyFieldTypes.Area, component: FormlyAreaComponent },
				{ name: FormlyFieldTypes.Button, component: FormlyButtonComponent },
				{ name: FormlyFieldTypes.NumberSelectInput, component: FormlyNumberSelectComponent },
				{ name: FormlyFieldTypes.TickBox, component: FormlyTickBoxComponent },
				{ name: FormlyFieldTypes.Table, component: FormlyTableComponent },
				{ name: FormlyFieldTypes.MultiBadgeSelect, component: FormlyMultiBadgeSelectComponent },
				{ name: FormlyFieldTypes.Label, component: FormlyLabelComponent },
				{ name: FormlyFieldTypes.Textarea, component: FormlyTextAreaComponent, wrappers: [] },
				{ name: FormlyFieldTypes.ToggleSwitch, component: FormlyToggleSwitchComponent },
				{ name: FormlyFieldTypes.MultiCheckboxSelect, component: FormlyMultiCheckboxSelectComponent },
				{ name: FormlyFieldTypes.Link, component: FormlyLinkComponent },
				{ name: FormlyFieldTypes.FileUpload, component: FormlyFileUploadComponent },
				{ name: FormlyFieldTypes.FileImage, component: FormlyFileImageComponent },
				{ name: FormlyFieldTypes.Spinner, component: FormlySpinnerComponent },
				{ name: FormlyFieldTypes.TelInput, component: FormlyTelInputComponent },
				{ name: FormlyFieldTypes.Duration, component: FormlyDurationComponent },
				{ name: FormlyFieldTypes.Alert, component: FormlyAlertComponent }
			],
			wrappers: [
				{ name: 'modal', component: FormlyModalWrapperComponent },
				{ name: 'mobile-modal', component: FormlyMobileModalWrapperComponent },
				{ name: 'nested-section', component: FormlyNestedSectionWrapperComponent },
				{ name: 'summary', component: FormlySummaryWrapperComponent }
			]
		}),
		FormlyBootstrapModule,
		NgbModalModule
	],
	exports: [FormlyModule, ReactiveFormsModule, SelectOptionsPipe, EnumLabelPipe, DynamicOptionsPipe],
	providers: [EnumLabelPipe, AsyncPipe, SelectOptionsPipe, DynamicOptionsPipe]
})
export class SimpFormlyModule {}
