import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { Household } from '@app/modules/typings/api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { FormEnumsService } from '../../shared/store/form-enums/form-enums.service';

@Injectable({ providedIn: 'root' })
export class HouseholdService extends BaseJourneyService<any> {
	constructor(private applicationDataQuery: ApplicationDataQuery, private formEnumsService: FormEnumsService) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.HOUSEHOLD);
	}

	saveHousehold(household: Household): Observable<Household> {
		return this.postCustom(``, household).pipe(
			tap((id: number) => {
				this.formEnumsService.addToHouseholdsEnum(id, household.name ?? '');
			}),
			map((id: number) => {
				household.id = id;
				return household;
			})
		);
	}

	fetchHouseholds(): Observable<Household[]> {
		return this.get(`${this.applicationDataQuery.applicationId()}`).pipe(
			tap((households: Household[]) => {
				this.formEnumsService.updateFormEnums(
					'Households',
					households.map((household) => ({ id: household.id, label: household.name as string }))
				);
			})
		);
	}

	getHouseholds(): void {
		this.fetchHouseholds().subscribe();
	}

	deleteHousehold(HouseholdId: number): Observable<boolean> {
		return this.delete(`${this.applicationDataQuery.applicationId()}/${HouseholdId}`).pipe(map(() => true));
	}
}
