import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlySelect = (property: FormlyApiProperty): JsonSchemaProperty => {
	// If the default value (string) is a number, convert it to type number
	if (property.templateOptions?.defaultValue && !isNaN(+property.templateOptions.defaultValue)) {
		property.templateOptions.defaultValue = +property.templateOptions.defaultValue;
	}
	return {
		type: 'select',
		title: property.title,
		widget: {
			formlyConfig: {
				className: `${property.templateOptions?.class ?? (property.class || 'col-3')}`,
				templateOptions: {
					...property.templateOptions,
					options: property.options
				},
				validation: {
					messages: {
						required: 'This field is mandatory',
						...(property.errorMessages || {})
					}
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property),
				resetOnHide: !!property.templateOptions?.resetOnHide,
				defaultValue: property.templateOptions?.defaultValue
			}
		}
	};
};
