import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NotEmployedIncomeService {
	public getDuration(startedDate: string, endedDate: string) {
		const from = new Date(startedDate);
		const end = new Date(endedDate);

		const diff = new Date(end.getTime() - from.getTime());

		const years = diff.getUTCFullYear() - 1970;
		const months = diff.getUTCMonth();

		if (years > 1) {
			if (months <= 1) {
				return `Duration:${years} years, ${months} month`;
			}
			return `Duration: ${years} years, ${months} months`;
		} else if (years === 1) {
			if (months <= 1) {
				return `Duration: ${years} year ${months} month`;
			} else if (months > 1) {
				return `Duration: ${years} year ${months} months`;
			}
		} else if (years === 0) {
			if (months === 1) {
				return `Duration: ${1} month`;
			} else if (months > 1) {
				return `Duration: ${months} months`;
			} else {
				return `Duration: Less than a month`;
			}
		}
		return `Duration: ${0} year, ${0} month`;
	}
}
