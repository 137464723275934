import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { SimpMultiBadgeSelectItem } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { isObservable, Subject, takeUntil } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-multi-badge-select',
	templateUrl: './formly-multi-badge-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiBadgeSelectComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';

	private destroy$ = new Subject<void>();

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (this.to?.options && isObservable(this.to?.options)) {
			// Remove values if not available in options
			this.to?.options.pipe(takeUntil(this.destroy$)).subscribe((options: EnumObject[]) => {
				const selectedOptions = (options ?? []).filter((option) =>
					(this.field.formControl.value as EnumObject[])?.some((value) => option.id === value.id)
				);
				this.field.formControl.setValue(selectedOptions);
			});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	onSelectionChange(item: SimpMultiBadgeSelectItem): void {
		if (this.to.change) {
			this.to.change(this.field, item);
		}
	}
}
