import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { PercentOwned, PersonApplicantDTO } from '@app/modules/typings/api';

export class RefiTransformerHelper {
	static getOwnership(percentsOwned?: PercentOwned[]): number | undefined {
		if (percentsOwned && percentsOwned.length > 1) {
			return 1;
		} else if (percentsOwned && percentsOwned.length == 1) {
			return percentsOwned[0].applicantId!;
		} else {
			return undefined;
		}
	}

	static setPercentsOwnedWithJointSelection(
		formEnumQuery: FormEnumsQuery,
		ownershipSelection?: number
	): PercentOwned[] {
		const applicants = formEnumQuery.getOptions('AllApplicants');
		if (ownershipSelection == CONSTANTS.JointOwnership) {
			return applicants
				?.map((x) => {
					return {
						applicantId: x.id,
						percent: 100 / applicants.filter((y) => y.id !== CONSTANTS.JointOwnership).length,
						applicantEntityTypeEnum: ApplicantEntityType.PersonApplicant
					} as PercentOwned;
				})
				.filter((x) => x.applicantId !== CONSTANTS.JointOwnership);
		} else if (ownershipSelection) {
			return [
				{
					applicantId: ownershipSelection,
					percent: 100,
					applicantEntityTypeEnum: ApplicantEntityType.PersonApplicant
				} as PercentOwned
			];
		} else {
			return [];
		}
	}

	static setPercentsOwned(
		applicants: PersonApplicantDTO[],
		equalShare = false,
		percentsOwned?: number[]
	): PercentOwned[] {
		return applicants?.map((x, index) => {
			return {
				applicantId: x.id,
				percent:
					applicants.length > 1
						? equalShare
							? 100 / applicants.length
							: percentsOwned
							? percentsOwned[index]
							: 50
						: 100,
				applicantEntityTypeEnum: ApplicantEntityType.PersonApplicant
			} as PercentOwned;
		});
	}

	static getPercentsOwned(applicants: PersonApplicantDTO[], percentsOwned?: PercentOwned[]): PercentOwned[] {
		return applicants.map((applicant) => {
			return {
				applicantId: applicant.id,
				percent: percentsOwned?.find((po) => po.applicantId === applicant.id)?.percent || 0
			} as PercentOwned;
		});
	}

	static getJointApplicantNames(applicants: PersonApplicantDTO[], percentsOwned?: PercentOwned[]): string {
		let names = '';
		if (applicants.length > 1) {
			const majorOwner = percentsOwned?.length == 1 ? percentsOwned[0] : percentsOwned?.find((x) => x.percent === 100);
			const noShare = percentsOwned?.find((x) => x.percent === 0);
			if (majorOwner) {
				names = applicants.find((x) => x.id === majorOwner.applicantId)?.firstName || '';
			} else {
				names = applicants
					.filter((x) => x.id !== noShare?.applicantId)
					.map((a) => a.firstName)
					.join(' & ');
			}
			return names.concat('<br/>');
		} else {
			return names;
		}
	}
}
