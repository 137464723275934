import { Injectable } from '@angular/core';
import { ForeignEmploymentModelTransformer } from '@app/modules/financial-position/model/foreign-employment.model';
import { PropertyService } from '@app/modules/property/services/property.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FrequencyShort } from '@simpology/client-components/utils';
import { sortBy, sumBy } from 'lodash-es';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { IncomeType } from './../shared/enums/app.enums';

import { EmploymentIncomeModelTransformer } from '@app/modules/financial-position/model/employment-income.model';
import { AccessSeekerLiabilitiesModel } from '@app/modules/shared/model/access-seeker.model';
import { EmploymentTransformer } from '../applicants/models/employment.model';
import { ApplicantsService } from '../applicants/services/applicants.service';
import { CONSTANTS } from '../shared/constants/constants';
import { NonRealEstateAssetType, TargetType } from '../shared/enums/app.enums';
import {
	addDaysToDate,
	formatPlural,
	getIncomeHeader,
	getLiabilitiesHeader,
	savedSuccessfullyMessage
} from '../shared/helper/util';
import { CreditIssueModel, CreditIssueTransformer } from '../shared/model/credit-issue.model';
import { CreditCardModel } from '../shared/model/creditcard.model';
import { IncomeHeaderSummary, LiabilitiesHeaderSummary } from '../shared/model/header-summary.model';
import { OtherAssetModel } from '../shared/model/otherasset.model';
import { OtherLiabilityModel } from '../shared/model/otherliabilites.model';
import { PersonalLoanModel } from '../shared/model/personalloan.model';
import { SavingModel } from '../shared/model/saving.model';
import { WarningIssueModel } from '../shared/model/warning-issue-model';
import { AssetsAndLiabilitiesService } from '../shared/service/assets-liabilities.service';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { EmploymentService } from '../shared/service/employment.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { FormDataService } from '../shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '../shared/store/form-enums/form-enums.query';
import { FormStateService } from '../shared/store/form-state/form-state.service';
import { findPropertyFromSchema } from '../simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '../simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import {
	CreditCardDTO,
	EmploymentDTO,
	EmploymentIncomeDTO,
	LendingGuaranteeDTO,
	NotEmployedDTO,
	SelfEmploymentDTO,
	StatementOfPositionDTO,
	WarningIssueDTO
} from '../typings/api';
import { AggregateFormatterService } from './../shared/service/aggregate-formatter.service';
import { PersonsCompaniesEnumService } from './../shared/service/persons-companies-enum.service';
import { ForeignEmploymentDTO } from './../typings/api.d';
import { FinancialPositionExpensesService } from './financial-position-expenses.service';
import { ApplicantEmploymentModel, ApplicantEmploymentModelTransformer } from './model/applicant-employment.model';
import { ApplicantForeignEmploymentModelTransformer } from './model/applicant-foreign-employment.model';
import {
	ApplicantNotEmployedIncomeModel,
	ApplicantNotEmployedModelTransformer
} from './model/applicant-notemployed-income.model';
import { ApplicantSelfEmploymentModelTransformer } from './model/applicant-self-employment.model';
import { EmploymentModel, EmploymentModelTransformer } from './model/employment.model';
import { HomeLoanModel } from './model/homeloan.model';
import { BasicIncomeBreakdown, IncomeBreakdownForSummary } from './model/incomeBreakdown.model';
import { NotEmployedModelTransformer } from './model/notemployed-income.model';
import { OtherIncomeModel, OtherIncomeTransformer } from './model/otherincome.model';
import { RealEstateModel } from './model/property.model';
import { RentalModel } from './model/rental.model';
import { IncomeCalculationType, SelfEmploymentModelTransformer } from './model/self-employment.model';

@Injectable({ providedIn: 'root' })
export class FinancialPositionService extends BaseJourneyService {
	switchArea$: Subject<void> = new Subject();
	statePrefix = 'financial-position';

	constructor(
		private applicantService: ApplicantsService,
		private applicationDataQuery: ApplicationDataQuery,
		private employmentService: EmploymentService,
		private formEnumsQuery: FormEnumsQuery,
		private formStateService: FormStateService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formDataService: FormDataService,
		private financialPositionExpensesService: FinancialPositionExpensesService,
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private aggregateFormatterService: AggregateFormatterService,
		private propertyService: PropertyService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(financialSchema?: FormlyApiProperty) {
		this.assetsAndLiabilitiesService.updateAssetsLiabilities();
		return forkJoin([
			this.fetchEmployments(financialSchema),
			this.fetchCreditStatuses(),
			this.getStatements(),
			this.assetsAndLiabilitiesService.fetchOtherIncome(),
			this.financialPositionExpensesService.fetchAllExpenses(),
			this.assetsAndLiabilitiesService.fetchAccessSeeker(),
			this.assetsAndLiabilitiesService.fetchCreditCards(),
			this.propertyService.syncPropertiesAndSecurities$(),
			// This is required for determining whether to show income
			this.applicantService.fetchPersonApplicants().pipe(
				withLatestFrom(this.applicantService.getHouseholds()),
				switchMap(([personApplicants, households]) => {
					const transFormedHouseholds = this.applicantService.transformHousehold(
						personApplicants,
						households
					).household;
					return forkJoin(
						personApplicants.map((personApplicant) => {
							return this.applicantService.getLendingGuaranteeDetailsById(personApplicant.id).pipe(
								catchError(() => of({} as LendingGuaranteeDTO)),
								map((guarantee) => ({
									...personApplicant,
									lendingGuaranteeType: guarantee,
									householdSize: transFormedHouseholds.find(
										(household) => household.householdId === personApplicant.householdId
									)?.whoLivesHere?.length
								}))
							);
						})
					).pipe(
						tap((applicants) => {
							this.formStateService.updateFormState({ applicants });
						})
					);
				})
			)
		]).pipe(
			map(
				([
					employments,
					creditStatus,
					statements,
					otherIncome,
					expenses,
					accessSeekerLiabilities,
					creditCards,
					persons
				]) => {
					this.simpFormlyHandlerService.upsertToStateWithData(`employments`, employments);
					this.simpFormlyHandlerService.upsertToStateWithData(`creditStatus`, creditStatus);
					this.simpFormlyHandlerService.upsertToStateWithData(`statements`, statements);
					this.simpFormlyHandlerService.upsertToStateWithData(`otherIncome`, otherIncome);
					this.simpFormlyHandlerService.upsertToStateWithData(`accessSeekerLiabilities`, accessSeekerLiabilities);
					this.simpFormlyHandlerService.upsertToStateWithData(`creditCards`, creditCards);
				}
			)
		);
	}

	getSummaryData() {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();

		return forkJoin({
			statements: this.getApplicationStatements().pipe(
				map((statements) => {
					return {
						applicants: statements
							.map((statement) =>
								statement.applicants?.length
									? statement.applicants
											.filter((applicant) => !!applicant.isJoint)
											.map((applicant) => {
												return { applicantName: applicant.applicantName };
											})
									: []
							)
							.flat(),
						references: statements.map((statement) => ({ reference: statement.reference }))
					};
				})
			),
			creditStatus: this.fetchCreditStatuses(),
			income: forkJoin({
				employments: this.fetchEmployments(),
				rentals: this.propertyService.fetchRentals(),
				otherIncome: this.assetsAndLiabilitiesService.fetchOtherIncome()
			}).pipe(
				map((res) => {
					const employments = res.employments.filter(
						(e) => e.employmentDetails.typeOfIncome.type !== IncomeType.NotEmployed
					);
					let incomeTotal = sumBy(employments, (value) => value.grossAmount || 0);
					incomeTotal += sumBy(res.rentals, (value) => value.grossAmount || 0);
					incomeTotal += sumBy(res.otherIncome, (value) => value.grossAmount || 0);

					const incomeItems = [employments.length ? `${employments.length} employment` : ''];
					incomeItems.push(res.rentals.length ? `${res.rentals.length} rental` : '');
					incomeItems.push(res.otherIncome.length ? `${res.otherIncome.length} other` : '');

					return {
						incomeTotal: incomeTotal ?? CONSTANTS.DEFAULT_STRING,
						incomeItems: incomeItems.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
					};
				})
			),
			incomesDetailView: forkJoin({
				employments: this.fetchEmployments(),
				rentals: this.propertyService.fetchRentals(),
				otherIncome: this.assetsAndLiabilitiesService.fetchOtherIncome(),
				realEstates: this.propertyService.fetchProperties()
			}).pipe(map((data) => this.getIncomesSummaryBreakdown(data))),
			expenses: this.financialPositionExpensesService
				.fetchExpensesTotal()
				.pipe(
					map((expenses) =>
						expenses.filter((expense) => expense.expenseCategory !== null && expense.householdId === null)
					)
				),
			assets: forkJoin({
				realEstate: this.propertyService.fetchProperties(),
				savings: this.assetsAndLiabilitiesService.fetchSavings(applicants),
				otherAssets: this.assetsAndLiabilitiesService.fetchOtherAssets(applicants)
			}).pipe(
				map((res) => {
					let totalPool = sumBy(res.realEstate, (value) => value.summaryAmount || 0);
					totalPool += sumBy(res.savings, (value) => value.summaryAmount || 0);
					totalPool += sumBy(res.otherAssets, (value) => value.summaryAmount || 0);

					const itemsEntered = [
						res.realEstate.length
							? `${res.realEstate.length} ${formatPlural(res.realEstate.length, 'property', 'properties')}`
							: ''
					];
					itemsEntered.push(
						res.savings.length ? `${res.savings.length} ${formatPlural(res.savings.length, 'saving')}` : ''
					);
					itemsEntered.push(
						res.otherAssets.length
							? `${res.otherAssets.length} ${formatPlural(res.otherAssets.length, 'other asset')}`
							: ''
					);

					return {
						totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
						itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
					};
				})
			),
			assetsDetailView: forkJoin({
				realEstates: this.propertyService.fetchProperties(),
				savings: this.assetsAndLiabilitiesService.fetchSavings(applicants),
				otherAssets: this.assetsAndLiabilitiesService.fetchOtherAssets(applicants)
			}).pipe(map((data) => this.getAssetsDetailView(data))),
			liabilities: forkJoin({
				homeloans: this.propertyService.fetchHomeLoans(),
				accessSeeker: this.assetsAndLiabilitiesService.fetchAccessSeeker(),
				creditCards: this.assetsAndLiabilitiesService.fetchCreditCards(),
				personalLoans: this.assetsAndLiabilitiesService.fetchPersonalLoans(),
				otherLiabilities: this.assetsAndLiabilitiesService.fetchOtherLiabilities()
			}).pipe(
				map((res) => {
					let totalPool = sumBy(res.homeloans, (value) => value.monthlyRepayment || 0);
					totalPool += sumBy(res.creditCards, (value) => value.creditLimit || 0);
					totalPool += sumBy(res.personalLoans, (value) => value.monthlyRepayment || 0);
					totalPool += sumBy(res.otherLiabilities, (value) => value.repaymentAmount || 0);

					const itemsEntered = [
						res.homeloans.length ? `${res.homeloans.length} ${formatPlural(res.homeloans.length, 'home loan')}` : ''
					];
					itemsEntered.push(
						res.creditCards.length
							? `${res.creditCards.length} ${formatPlural(res.creditCards.length, 'credit card')}`
							: ''
					);
					itemsEntered.push(
						res.personalLoans.length
							? `${res.personalLoans.length} ${formatPlural(res.personalLoans.length, 'personal loan')}`
							: ''
					);
					itemsEntered.push(
						res.otherLiabilities.length
							? `${res.otherLiabilities.length} ${formatPlural(
									res.otherLiabilities.length,
									'other liability',
									'other liabilities'
							  )}`
							: ''
					);

					return {
						totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
						itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
					};
				})
			),
			liabilitiesDetailView: forkJoin({
				homeloans: this.propertyService.fetchHomeLoans(),
				accessSeeker: this.assetsAndLiabilitiesService.fetchAccessSeeker(),
				creditCards: this.assetsAndLiabilitiesService.fetchCreditCards(),
				personalLoans: this.assetsAndLiabilitiesService.fetchPersonalLoans(),
				otherLiabilities: this.assetsAndLiabilitiesService.fetchOtherLiabilities()
			}).pipe(map((data) => this.getLiabilitiesDetailView(data)))
		}).pipe(
			map((personApplicant) => {
				return { personApplicant: [personApplicant] };
			})
		);
	}

	getIncomesSummaryBreakdown(data: {
		employments: EmploymentModel[];
		rentals: RentalModel[];
		otherIncome: OtherIncomeModel[];
		realEstates: RealEstateModel[];
	}): IncomeBreakdownForSummary[] {
		const applicants = this.applicationDataQuery.getApplicants();
		const incomeBreakdown: IncomeBreakdownForSummary[] = [];
		const allIncomes = this.getAllIncomeInfoSummaryBreakdown(data);

		applicants.map((applicant) => {
			const filteredIncome = allIncomes.filter((income) => income.applicantId === applicant.id);
			incomeBreakdown.push({
				applicantId: applicant.id,
				applicantName: `${applicant.name} - Income`,
				incomes: filteredIncome
			});
		});

		return incomeBreakdown;
	}

	getAllIncomeInfoSummaryBreakdown(data: {
		employments: EmploymentModel[];
		rentals: RentalModel[];
		otherIncome: OtherIncomeModel[];
		realEstates: RealEstateModel[];
	}): BasicIncomeBreakdown[] {
		const employmentIncome = data.employments.map((re) => this.mapEmploymentIncomesDetail(re));
		const otherIncome: BasicIncomeBreakdown[] = [];
		const rentalIncome: BasicIncomeBreakdown[] = [];

		data.otherIncome.map((re) => {
			const incomes = this.mapOtherIncomesDetail(re);
			incomes.map((income) => otherIncome.push(income));
		});
		data.rentals.map((re) => {
			const rentals = this.mapRentalIncomeDetails(re, data.realEstates);
			rentals.map((rent) => rentalIncome.push(rent));
		});

		return employmentIncome.concat(otherIncome, rentalIncome);
	}

	mapOtherIncomesDetail(otherIncomeData: OtherIncomeModel): BasicIncomeBreakdown[] {
		const otherIncomeReturnData: BasicIncomeBreakdown[] = [];
		const description = otherIncomeData.details.otherIncomeDetails.description || '';
		const amount = this.aggregateFormatterService.formatAmount(otherIncomeData.grossAmount as number);
		const incomeType = this.formEnumsQuery.getOptionLabel(
			'OtherIncomeType',
			otherIncomeData.details.otherIncomeDetails.typeOfIncome
		);

		otherIncomeData?.details?.otherIncomeDetails?.percentsOwned?.forEach((percentOwned) => {
			if (percentOwned.percent !== 0) {
				otherIncomeReturnData.push({
					incomeType: incomeType,
					amount: `${amount} - ${percentOwned.percent}%`,
					description: description,
					applicantId: percentOwned.applicantId as number,
					percentage: percentOwned.percent as number
				});
			}
		});

		return otherIncomeReturnData;
	}

	mapRentalIncomeDetails(rentalIncomeData: RentalModel, realEstates: RealEstateModel[]): BasicIncomeBreakdown[] {
		const rentalIncomeReturnData: BasicIncomeBreakdown[] = [];
		const description = rentalIncomeData.details.extract || '';
		const amount = this.aggregateFormatterService.formatAmount(rentalIncomeData.grossAmount as number);
		const property = realEstates.find(
			(realEstate) => realEstate.id === rentalIncomeData.details.detailsModel.realEstateAssetId
		);

		if (!property) {
			return rentalIncomeReturnData;
		}

		property?.details?.propertyDetails?.propertyAssetDetails[0]?.percentsOwned?.forEach((percentOwned) => {
			if (percentOwned.percent !== 0) {
				rentalIncomeReturnData.push({
					incomeType: 'Rental income',
					amount: `${amount} - ${percentOwned.percent}%`,
					description: description,
					applicantId: percentOwned.applicantId as number,
					percentage: percentOwned.percent as number
				});
			}
		});

		return rentalIncomeReturnData;
	}

	mapEmploymentIncomesDetail(employmentData: EmploymentModel): BasicIncomeBreakdown {
		const incomeType = this.formEnumsQuery.getOptionLabel(
			'IncomeType',
			employmentData.employmentDetails.typeOfIncome.type
		);
		const description = EmploymentModelTransformer.getEmployerNameForSummary(employmentData) || '';
		const applicantId = employmentData.applicantId as number;

		return {
			incomeType: incomeType,
			amount: this.aggregateFormatterService.formatAmount(employmentData.grossAmount as number),
			description: description,
			applicantId: applicantId,
			percentage: 100
		};
	}

	getAssetsDetailView(data: {
		realEstates: RealEstateModel[];
		savings: SavingModel[];
		otherAssets: OtherAssetModel[];
	}): {
		assetType: string;
		amount: number | undefined;
		description: string | undefined;
	}[] {
		const allAssets = [
			...data.realEstates.map((re) => this.mapAssetsDetail(re, 'PropertyAsset')),
			...data.savings.map((re) => this.mapAssetsDetail(re, 'FinancialAsset')),
			...data.otherAssets.map((re) => this.mapAssetsDetail(re, 'OtherAsset'))
		];
		return allAssets;
	}

	mapAssetsDetail(data: RealEstateModel | SavingModel | OtherAssetModel, type: string) {
		let realEstateData: RealEstateModel;
		let savingData: SavingModel;
		let otherAssetData: OtherAssetModel;
		let financialInstitution;
		let assetType = '';
		let description = '';
		switch (type) {
			case 'PropertyAsset':
				realEstateData = data as RealEstateModel;
				assetType = 'Property Asset';
				description = realEstateData.details.extract;
				break;

			case 'FinancialAsset':
				savingData = data as SavingModel;
				assetType = this.formEnumsQuery.getOptionLabel(
					'FinancialAssetType',
					savingData.details.savingDetails.financialAssetType
				);

				financialInstitution = this.formEnumsQuery.getOptionLabel(
					'FinancialInstitution',
					savingData.details.savingDetails.financialInstitution
				);

				description = financialInstitution || savingData.details.savingDetails.description || '';
				break;
			case 'OtherAsset':
				otherAssetData = data as OtherAssetModel;
				assetType = this.formEnumsQuery.getOptionLabel(
					'NonRealEstateAssetType',
					otherAssetData.details.otherAssetDetails.assetType
				);
				description = this.getOtherAssetDescription(otherAssetData);
				break;
		}

		return {
			assetType: assetType,
			amount: data.summaryAmount,
			description: description
		};
	}

	getOtherAssetDescription(otherAssetData: OtherAssetModel): string {
		switch (otherAssetData.details.otherAssetDetails.assetType) {
			case NonRealEstateAssetType.Business:
				return otherAssetData.details.otherAssetDetails.businessName || '';
			case NonRealEstateAssetType.MotorVehicleandTransport:
				return otherAssetData.details.otherAssetDetails.vehicleType
					? this.formEnumsQuery.getOptionLabel('VehicleType', otherAssetData.details.otherAssetDetails.vehicleType)
					: '';
			case NonRealEstateAssetType.Other:
				return otherAssetData.details.otherAssetDetails.otherAssetType
					? this.formEnumsQuery.getOptionLabel(
							'OtherAssetType',
							otherAssetData.details.otherAssetDetails.otherAssetType
					  )
					: '';
			default:
				return otherAssetData.details.otherAssetDetails.description;
		}
	}

	getLiabilitiesDetailView(data: {
		homeloans: HomeLoanModel[];
		accessSeeker: AccessSeekerLiabilitiesModel[];
		creditCards: CreditCardModel[];
		personalLoans: PersonalLoanModel[];
		otherLiabilities: OtherLiabilityModel[];
	}): {
		liabilityType: string;
		amount: number | undefined;
		financialInstitution: string | undefined;
	}[] {
		const allLiabilities = [
			...data.accessSeeker.map((re) => this.mapLiabilitiesDetail(re, 'accessSeekerLiabilities')),
			...data.creditCards.map((re) => this.mapLiabilitiesDetail(re, 'creditCard')),
			...data.homeloans.map((re) => this.mapLiabilitiesDetail(re, 'homeLoan')),
			...data.personalLoans.map((re) => this.mapLiabilitiesDetail(re, 'personalLoan')),
			...data.otherLiabilities.map((re) => this.mapLiabilitiesDetail(re, 'otherLiability'))
		];
		return allLiabilities;
	}

	mapLiabilitiesDetail(
		data: HomeLoanModel | AccessSeekerLiabilitiesModel | CreditCardModel | PersonalLoanModel | OtherLiabilityModel,
		type: string
	) {
		let liabilityType = '';
		let financialInstitution = '';

		let otherLiability: OtherLiabilityModel;
		let creditCard: CreditCardModel;
		let homeLoan: HomeLoanModel;
		let personalLoan: PersonalLoanModel;

		switch (type) {
			case 'creditCard':
				creditCard = data as CreditCardModel;
				liabilityType = 'Credit card';
				financialInstitution =
					creditCard.financialInstitution !== 1252
						? this.formEnumsQuery.getOptionLabel('FinancialInstitution', creditCard.financialInstitution as number)
						: (creditCard.details.creditCardDetails.otherFinancialInstitutionName as string);
				break;
			case 'homeLoan':
				homeLoan = data as HomeLoanModel;
				liabilityType = 'Mortgage liability';
				financialInstitution =
					homeLoan.financialInstitution !== 1252
						? this.formEnumsQuery.getOptionLabel('FinancialInstitution', homeLoan.financialInstitution as number)
						: (homeLoan.details.detailsModel.otherFIName as string);
				break;
			case 'personalLoan':
				personalLoan = data as PersonalLoanModel;
				liabilityType = 'Personal loan';
				financialInstitution =
					personalLoan.financialInstitution !== 1252
						? this.formEnumsQuery.getOptionLabel('FinancialInstitution', personalLoan.financialInstitution as number)
						: (personalLoan.details.personalLoanDetails.otherFinancialInstitutionName as string);
				break;
			case 'otherLiability':
				otherLiability = data as OtherLiabilityModel;
				financialInstitution =
					otherLiability.details.otherLiabilityDetails.financialInstitution !== 1252
						? this.formEnumsQuery.getOptionLabel(
								'FinancialInstitution',
								otherLiability.details.otherLiabilityDetails.financialInstitution as number
						  )
						: (otherLiability.details.otherLiabilityDetails.otherFinancialInstitutionName as string);
				liabilityType = this.formEnumsQuery.getOptionLabel('LiabilityType', otherLiability.type as number);
				break;
		}

		return {
			liabilityType: liabilityType,
			amount: data.outstandingBalance,
			financialInstitution: financialInstitution
		};
	}

	setSectionHeaders(fields: FormlyFieldConfig[] | undefined) {
		if (fields) {
			const fieldArray = fields[0]?.fieldArray as FormlyFieldConfig;

			const incomeSubSectionNames = fieldArray?.fieldGroup
				?.find((e) => e.key === 'income')
				?.fieldGroup?.filter((e) => e.type === 'sub-section')
				.map((e) => e.key) as string[];
			this.setIncomeSectionHeaders(incomeSubSectionNames);

			const liabilitiesSubSectionNames = fieldArray?.fieldGroup
				?.find((e) => e.key === 'liabilities')
				?.fieldGroup?.filter((e) => e.type === 'sub-section')
				.map((e) => e.key) as string[];
			this.setLiabilitiesSectionHeaders(liabilitiesSubSectionNames);
		}
	}

	setIncomeSectionHeaders(incomeSubSectionNames: string[]) {
		combineLatest(
			incomeSubSectionNames?.map((name: string) => this.simpFormlyHandlerService.mapToSubSectionHeader(name))
		)
			.pipe(takeUntil(this.switchArea$))
			.subscribe((data) => {
				if (data.length > 0) {
					const headerModel = getIncomeHeader(data as unknown as IncomeHeaderSummary[], true);
					this.simpFormlyHandlerService.upsertToSectionHeader('income', headerModel);
				}
			});
	}

	setLiabilitiesSectionHeaders(liabilitiesSubSectionNames: string[]) {
		combineLatest(
			liabilitiesSubSectionNames?.map((name: string) => this.simpFormlyHandlerService.mapToSubSectionHeader(name))
		)
			.pipe(takeUntil(this.switchArea$))
			.subscribe((data) => {
				if (data.length > 0) {
					const headerModel = getLiabilitiesHeader(data as unknown as LiabilitiesHeaderSummary[]);
					this.simpFormlyHandlerService.upsertToSectionHeader('liabilities', headerModel);
				}
			});
	}

	saveForeignEmployment(index: number, employmentModel: EmploymentModel): Observable<EmploymentModel> {
		const payload = ForeignEmploymentModelTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			employmentModel
		);

		return this.postCustom(`ForeignEmployed`, payload).pipe(
			map((id: number) => {
				employmentModel.id = id;
				EmploymentModelTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);
				const incomes = EmploymentModelTransformer.getNetAndGrossAmountForSummary(
					employmentModel.employmentDetails.foreignEmployment?.income
				);

				employmentModel.grossAmount = incomes.grossAmount;
				employmentModel.netAmount = incomes.netAmount;
				employmentModel.frequency = FrequencyShort.Monthly;

				this.simpFormlyHandlerService.updateToState('employments', employmentModel, index);
				this.toastr.success(savedSuccessfullyMessage('Employer'));
				const empData = this.simpFormlyHandlerService.getStateData<EmploymentModel>('employments');
				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(empData, 'employments');

				return employmentModel;
			})
		);
	}

	savePaygEmployment(index: number, employmentModel: EmploymentModel): Observable<any> {
		const payload = EmploymentModelTransformer.toPayload(employmentModel, this.applicationDataQuery.applicationId());
		return this.postCustom(`Employment`, payload).pipe(
			tap((id: number) => (employmentModel.id = id)),
			switchMap((id: number) =>
				this.getEmploymentIncomes(id).pipe(
					tap((employmentIncomeDTO: EmploymentIncomeDTO[]) => {
						if (employmentModel.employmentDetails.payg) {
							employmentModel.employmentDetails.payg.income =
								EmploymentIncomeModelTransformer.fromPayload(employmentIncomeDTO);
						}
					})
				)
			),
			tap(() => {
				EmploymentModelTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);
				const endDate = addDaysToDate(employmentModel.employmentDetails.payg?.incomePeriodModal?.endDate as string, 1); //TAMA5-10520 add a day to YTD date for correct calculation
				const incomes = EmploymentModelTransformer.getNetAndGrossAmountForSummary(
					employmentModel.employmentDetails.payg?.income,
					employmentModel.employmentDetails.payg?.incomePeriodModal?.startDate,
					endDate
				);
				employmentModel.grossAmount = incomes.grossAmount;
				employmentModel.netAmount = incomes.netAmount;
				employmentModel.frequency = FrequencyShort.Monthly;

				this.simpFormlyHandlerService.updateToState('employments', employmentModel, index);
				this.toastr.success(savedSuccessfullyMessage('Employment'));
				const empData = this.simpFormlyHandlerService.getStateData<EmploymentModel>('employments');
				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(empData, 'employments');
			})
		);
	}

	saveNotEmployed(index: number, employmentModel: EmploymentModel): Observable<any> {
		const payload = NotEmployedModelTransformer.toPayload(employmentModel, this.applicationDataQuery.applicationId());
		return this.postCustom(`NotEmployed`, payload).pipe(
			tap((id: number) => {
				employmentModel.id = id;
				EmploymentModelTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);
				const incomes = NotEmployedModelTransformer.notEmployedModelToUI(
					employmentModel.employmentDetails.notEmployed?.income
				);
				employmentModel.grossAmount = incomes.grossAmount;
				employmentModel.netAmount = incomes.netAmount;
				employmentModel.frequency = FrequencyShort.Monthly;

				this.simpFormlyHandlerService.updateToState('employments', employmentModel, index);
				this.toastr.success(savedSuccessfullyMessage('Employment'));

				const empData = this.simpFormlyHandlerService.getStateData<EmploymentModel>('employments');
				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(empData, 'employments');
			})
		);
	}

	saveSelfEmployment(index: number, employmentModel: EmploymentModel): Observable<any> {
		const payload = SelfEmploymentModelTransformer.toPayload(
			employmentModel,
			this.applicationDataQuery.applicationId(),
			this.formEnumsQuery
		);

		return this.postCustom(`SelfEmployedIncome`, payload).pipe(
			tap((id: number) => {
				employmentModel.id = id;
				EmploymentModelTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);

				const selfEmployedModel = SelfEmploymentModelTransformer.calculateTotal(
					employmentModel.employmentDetails.selfEmployment!,
					employmentModel.employmentDetails.typeOfIncome.incomeDocumentationTypeId
				);

				employmentModel.grossAmount = selfEmployedModel.grossAmount;
				employmentModel.netAmount = selfEmployedModel.netAmount;
				employmentModel.frequency = FrequencyShort.Monthly;

				this.simpFormlyHandlerService.updateToState('employments', employmentModel, index);
				this.toastr.success(savedSuccessfullyMessage('Employment'));

				const empData = this.simpFormlyHandlerService.getStateData<EmploymentModel>('employments');
				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(empData, 'employments');
			})
		);
	}

	saveApplicantPaygEmployment(
		employmentModel: ApplicantEmploymentModel,
		startDate: string,
		endDate: string
	): Observable<ApplicantEmploymentModel> {
		const payload = ApplicantEmploymentModelTransformer.toPayload(
			employmentModel,
			this.applicationDataQuery.applicationId(),
			startDate,
			endDate
		);

		return this.postCustom(`Employment`, payload).pipe(
			tap((id: number) => (employmentModel.id = id)),
			switchMap((id: number) =>
				this.getEmploymentIncomes(id).pipe(
					tap((employmentIncomeDTO: EmploymentIncomeDTO[]) => {
						if (employmentModel.employmentDetails.payg) {
							employmentModel.employmentDetails.payg.income =
								EmploymentIncomeModelTransformer.fromPayload(employmentIncomeDTO);
						}
					})
				)
			),
			map(() => {
				EmploymentTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);

				employmentModel.dateStarted = employmentModel.employmentDetails.payg?.dateStarted;
				employmentModel.dateEnded = employmentModel.employmentDetails.payg?.dateEnded;
				this.toastr.success(savedSuccessfullyMessage('Employment'));
				return employmentModel;
			})
		);
	}

	getEmploymentIncomes(employmentId: number) {
		return <Observable<EmploymentIncomeDTO[]>>(
			this.getCustom(`Employment/${this.applicationDataQuery.applicationId()}/${employmentId}/incomes`)
		);
	}

	saveApplicantNotEmployed(
		employmentModel: ApplicantNotEmployedIncomeModel
	): Observable<ApplicantNotEmployedIncomeModel> {
		const payload = ApplicantNotEmployedModelTransformer.toPayload(
			employmentModel,
			this.applicationDataQuery.applicationId()
		);

		return this.postCustom(`NotEmployed`, payload).pipe(
			map((id: number) => {
				employmentModel.id = id;
				employmentModel.employmentDetails.extract = '';
				EmploymentTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);

				employmentModel.dateStarted = employmentModel.employmentDetails.notEmployed?.dateStarted;
				employmentModel.dateEnded = employmentModel.employmentDetails.notEmployed?.dateEnded;
				this.toastr.success(savedSuccessfullyMessage('Employment'));
				return employmentModel;
			})
		);
	}

	saveApplicantSelfEmployment(employmentModel: ApplicantEmploymentModel): Observable<ApplicantEmploymentModel> {
		const payload = ApplicantSelfEmploymentModelTransformer.toPayload(
			employmentModel,
			this.applicationDataQuery.applicationId(),
			this.formEnumsQuery
		);

		return this.postCustom(`SelfEmployedIncome`, payload).pipe(
			map((id: number) => {
				employmentModel.id = id;
				const extractString = this.formEnumsQuery.getOptionLabel(
					'SelfEmployedBasis',
					employmentModel.employmentDetails.selfEmployment!.basis!
				);
				employmentModel.employmentDetails.extract = extractString
					? `Self employed - ${extractString}`
					: 'Self employed';

				employmentModel.dateStarted = employmentModel.employmentDetails.selfEmployment?.dateStarted;
				employmentModel.dateEnded = employmentModel.employmentDetails.selfEmployment?.dateEnded;
				this.toastr.success(savedSuccessfullyMessage('Employment'));
				return employmentModel;
			})
		);
	}

	saveApplicantForeignEmployment(employmentModel: ApplicantEmploymentModel): Observable<ApplicantEmploymentModel> {
		const payload: ForeignEmploymentDTO = ApplicantForeignEmploymentModelTransformer.toPayload(
			employmentModel,
			this.applicationDataQuery.applicationId()
		);

		return this.postCustom(`ForeignEmployed`, payload).pipe(
			map((id: number) => {
				employmentModel.id = id;
				employmentModel.employmentDetails.extract =
					employmentModel.employmentDetails.foreignEmployment?.employerDetailsSelect?.companyName || 'Foreign employed';
				EmploymentTransformer.setEmploymentExtract(this.formEnumsQuery, employmentModel, []);

				employmentModel.dateStarted = employmentModel.employmentDetails.foreignEmployment?.dateStarted;
				employmentModel.dateEnded = employmentModel.employmentDetails.foreignEmployment?.dateEnded;
				this.toastr.success(savedSuccessfullyMessage('Employment'));
				return employmentModel;
			})
		);
	}

	saveApplicationStatements(payload: StatementOfPositionDTO[]): Observable<StatementOfPositionDTO[]> {
		return this.postCustom(`StatementOfPosition`, payload).pipe(
			map((statements: StatementOfPositionDTO[]) => {
				return statements;
			})
		);
	}

	saveWarningIssue(model: WarningIssueDTO, applicantID: number): Observable<number> {
		return <Observable<number>>this.postCustom(`PersonApplicant/CreditStatusIssue/${applicantID}`, model);
	}

	fetchNotEmployedForApplication(): Observable<EmploymentModel[]> {
		return this.getCustom(`NotEmployed/application/${this.applicationDataQuery.applicationId()}`).pipe(
			map((notEmployedDTO: NotEmployedDTO[] = []) => {
				const employmentModels = notEmployedDTO.map((notEmployed) =>
					NotEmployedModelTransformer.fromPayload(notEmployed)
				);
				return employmentModels.filter((e) => e.currentEmployment);
			})
		);
	}

	fetchForeignEmployedForApplication(): Observable<EmploymentModel[]> {
		return this.getCustom(`ForeignEmployed/application/${this.applicationDataQuery.applicationId()}`).pipe(
			map((foreignEmploymentDTOS: ForeignEmploymentDTO[] = []) =>
				foreignEmploymentDTOS
					.map((foreignEmploymentDTO: ForeignEmploymentDTO) =>
						ForeignEmploymentModelTransformer.fromPayload(foreignEmploymentDTO, this.formEnumsQuery)
					)
					.filter((e) => e.currentEmployment)
			)
		);
	}

	getApplicantFiles(applicantid: number): Observable<any> {
		return this.getAsArrayBuffer(`StatementOfPosition/Download/${applicantid}`);
	}

	getApplicationStatements(): Observable<StatementOfPositionDTO[]> {
		return this.getCustom(`StatementOfPosition/${this.applicationDataQuery.applicationId()}`).pipe(
			map((statements: StatementOfPositionDTO[]) => {
				return statements;
			})
		);
	}

	updateStatementOfPositionStore(statements: StatementOfPositionDTO[]): void {
		this.formDataService.upsertStateWithAsyncData('statements-of-position', of(statements.length));
	}

	setApplicantCreditStatus(index: number, applicantId: number, model: CreditIssueModel): void {
		const payload = CreditIssueTransformer.toPayload(model, this.applicationDataQuery.applicationId(), applicantId);

		this.patch(`PersonApplicant/CreditStatus/${applicantId}/${model.creditStatusContainer.status}`, {}).subscribe(
			() => {
				const creditStatusModel = CreditIssueTransformer.fromPayload(
					payload,
					this.applicationDataQuery.getPersonShortApplicants()[index]
				);

				this.simpFormlyHandlerService.updateToState('creditStatus', creditStatusModel, index);
				this.toastr.success(savedSuccessfullyMessage('Credit status'));
			}
		);
	}

	resetApplicantCreditStatus(index: number, applicantId: number, model: CreditIssueModel): void {
		const payload = CreditIssueTransformer.toPayload(model, this.applicationDataQuery.applicationId(), applicantId);

		this.patch(`PersonApplicant/ResetCreditStatus/${applicantId}`, {}).subscribe(() => {
			const creditStatusModel = CreditIssueTransformer.fromPayload(
				payload,
				this.applicationDataQuery.getPersonShortApplicants()[index]
			);

			this.simpFormlyHandlerService.updateToState('creditStatus', creditStatusModel, index);
			this.toastr.success(savedSuccessfullyMessage('Credit status'));
		});
	}

	saveOtherIncome(index: number, otherIncome: OtherIncomeModel): Observable<any> {
		const payload = OtherIncomeTransformer.toPayload(this.applicationDataQuery.applicationId(), otherIncome);
		return this.postCustom(`OtherIncome`, payload).pipe(
			map((incomeId: number) => {
				payload.id = incomeId;
				const otherIncomeModel = OtherIncomeTransformer.fromPayload(
					payload,
					this.applicationDataQuery.getPersonShortApplicants()
				);
				this.simpFormlyHandlerService.updateToState('otherIncome', otherIncomeModel, index);
				this.toastr.success(savedSuccessfullyMessage('Other income'));

				const otherIncomeData = this.simpFormlyHandlerService.getStateData<OtherIncomeModel>('otherIncome');
				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(otherIncomeData, 'otherIncome');
				return incomeId;
			})
		);
	}

	deleteWarningIssues(model: WarningIssueModel, applicantId: any): Observable<boolean> {
		if (model?.id) {
			return this.delete(`PersonApplicant/CreditStatusIssue/${applicantId}/${model.id}`).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchEmployments(financialSchema?: FormlyApiProperty): Observable<EmploymentModel[]> {
		return forkJoin([
			this.getCustom(`Employment/application/${this.applicationDataQuery.applicationId()}`).pipe(
				map((employments: EmploymentDTO[] = []) => {
					const employmentModels = employments.map((employment) =>
						EmploymentModelTransformer.fromPayload(employment, this.formEnumsQuery)
					);
					return employmentModels.filter((e) => e.currentEmployment);
				})
			),
			//TODO wireup employments
			this.fetchSelfEmploymentForFinancialPosition(financialSchema),
			this.fetchNotEmployedForApplication(),
			this.fetchForeignEmployedForApplication(),
			this.employmentService.fetchEmployer()
		]).pipe(
			map(([paygEmploymentModel, selfEmploymentModel, notEmployedModel, foreignEmployed, employers]) => {
				const combined = paygEmploymentModel
					.concat(selfEmploymentModel)
					.concat(notEmployedModel)
					.concat(foreignEmployed);
				combined.forEach((employment) => {
					EmploymentModelTransformer.setEmploymentExtract(this.formEnumsQuery, employment, employers);
				});

				this.assetsAndLiabilitiesService.upsertIncomeSectionSubHeader(combined, 'employments');
				return combined;
			})
		);
	}

	fetchSelfEmploymentForFinancialPosition(financialSchema?: FormlyApiProperty) {
		// Thia is used to determine which field to use for total https://simpology.atlassian.net/browse/TAMA5-8844
		const incomeCalculationType = findPropertyFromSchema(
			financialSchema,
			'income.employments.employmentDetails.selfEmployment.incomeCalculationType'
		);
		return this.getCustom(`SelfEmployedIncome/application/${this.applicationDataQuery.applicationId()}`).pipe(
			switchMap((selfEmploymentDTO: SelfEmploymentDTO[] = []) => {
				if (!selfEmploymentDTO?.length) {
					return of([]);
				}
				return forkJoin(
					selfEmploymentDTO.map((selfEmployment) =>
						selfEmployment.employerPartyId && selfEmployment.employerTargetType === TargetType.RelatedCompany
							? this.personsCompaniesEnumService.getRelatedCompany(selfEmployment.employerPartyId)
							: of(null)
					)
				).pipe(
					map((relatedCompanies) => {
						const employmentModels = selfEmploymentDTO.map((employment) =>
							SelfEmploymentModelTransformer.fromPayload(
								employment,
								this.formEnumsQuery,
								relatedCompanies?.filter((company) => company?.id === employment.employerPartyId)?.[0] ?? undefined,
								incomeCalculationType?.templateOptions?.defaultValue as IncomeCalculationType
							)
						);
						return employmentModels.filter((e) => e.currentEmployment);
					})
				);
			})
		);
	}

	getStatements() {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		const mappedApplicants = sortBy(applicants, 'isPrimary').map((applicant, index) => {
			return {
				applicant: `${index} - ${applicant.name}`
			};
		});

		return of([
			{
				applicants: mappedApplicants
			}
		]);
	}

	fetchCreditStatuses(): Observable<CreditIssueModel[]> {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		return forkJoin(
			applicants.map((applicant) => {
				return this.getCustom(`PersonApplicant/CreditStatus/${applicant.id}`).pipe(
					map((data: CreditCardDTO) => {
						return CreditIssueTransformer.fromPayload(data, applicant);
					})
				);
			})
		);
	}
}
