import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { AssociatedProductsDTO } from '@app/modules/typings/api';

export class HomeLandlordInsuranceTransformer {
	static fromPayload(associatedProducts: AssociatedProductsDTO): HomeLandlordInsuranceModel {
		return {
			isInsuranceRequired: getNullableYesNo(associatedProducts?.isInsuranceRequired),
			insuranceExplained: associatedProducts?.insuranceExplained,
			noInsuranceConfirmed: associatedProducts?.noInsuranceConfirmed,
			noInsuranceExplained: associatedProducts?.noInsuranceExplained
		};
	}
}

export interface HomeLandlordInsuranceModel {
	isInsuranceRequired?: YesNo;
	insuranceExplained?: boolean;
	noInsuranceExplained?: boolean;
	noInsuranceConfirmed?: boolean;
}
