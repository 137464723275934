import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, Subject, tap } from 'rxjs';

import { URL_CONSTANTS } from '../constants/api.constants';
import { OverriddenRule, OverriddenRuleDTO, OverriddenRuleTransformer } from '../model/policy-rule.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class LoanappRuleService extends BaseJourneyService<any> {
	ruleIgnoredSubject: Subject<string> = new Subject();
	ruleIgnored$ = this.ruleIgnoredSubject.asObservable();

	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setRoute(URL_CONSTANTS.LOAN_APP_RULE);
	}

	ignoreRule(payload: IgnoreRulePayload): Observable<void> {
		return this.postCustom(`IgnoreValidationRule/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			tap(() => this.ruleIgnoredSubject.next(payload.ruleCode))
		);
	}

	getOverriddenRules(): Observable<OverriddenRule[]> {
		return this.get(`OverriddenRules/${this.applicationDataQuery.applicationId()}`).pipe(
			map((overriddenRulesDTO) => OverriddenRuleTransformer.fromPayload(overriddenRulesDTO as OverriddenRuleDTO[])),
			catchError(() => of([]))
		);
	}
}

export interface IgnoreRulePayload {
	ruleCode: string;
	reason: string;
	unmetMessage: string;
}
