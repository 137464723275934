import { SalesChannelType } from '@app/modules/shared/enums/app.enums';
import { LenderSalesChannelDTO } from '@app/modules/typings/api';
import { Interview } from './interview.model';

export class LenderSalesChannelModelTransformer {
	static toPayload(lenderSalesChannel: LenderSalesChannel, applicationId: number): LenderSalesChannelDTO {
		const payload = {
			applicationId,
			isHLIGManuallyCompleted: lenderSalesChannel.isHLIGManuallyCompleted
		} as LenderSalesChannelDTO;

		if (!payload.isHLIGManuallyCompleted) {
			payload.salesChannelTypeId = lenderSalesChannel.salesChannel;
			payload.interviewDate = lenderSalesChannel.interviewDate;
		}
		return payload;
	}

	static fromPayload(interview: Interview): LenderSalesChannel {
		return {
			id: interview.id,
			applicationId: interview.applicationId,
			isHLIGManuallyCompleted: interview.isHLIGManuallyCompleted,
			salesChannel: interview.salesChannel,
			interviewDate: interview.interviewDate
		};
	}
}

export interface LenderSalesChannel {
	id?: number;
	applicationId?: number;
	isHLIGManuallyCompleted?: boolean;
	salesChannel?: SalesChannelType;
	interviewDate?: string;
}
