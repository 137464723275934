import { Injectable } from '@angular/core';
import { DigitalWidgetSSIServiceType } from '@app/modules/digital-widgets/enums/digital-widgets-configuration.enums';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { ChannelService } from '@app/modules/shared/store/channel-setting/channel.service';
import { map, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FingerprintCheck } from '../typings/fingerprint-check';

@Injectable({
	providedIn: 'root'
})
export class FingerprintService extends BaseJourneyService<any> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private channelService: ChannelService,
		private channelSettingQuery: ChannelSettingQuery
	) {
		super();
		this.setRoute(URL_CONSTANTS.FINGER_PRINT);
	}

	registerCurrentApplicationForFingerprintCheck(): Observable<FingerprintCheck> {
		return this.channelService.isServiceActive(DigitalWidgetSSIServiceType.Fingerprint).pipe(
			switchMap((isServiceActive) => {
				if (isServiceActive) {
					return this.getCustom(
						`${this.applicationDataQuery.applicationId()}/${this.applicationDataQuery.applicationId()}-application`
					).pipe(
						map((applicationHasNotBeenChanged: boolean) => {
							return { isServiceActive: true, applicationHasNotBeenChanged: applicationHasNotBeenChanged };
						}),
						catchError(() => {
							return of({ isServiceActive: false, applicationHasNotBeenChanged: false });
						})
					);
				} else {
					return of({ isServiceActive: false, applicationHasNotBeenChanged: undefined });
				}
			})
		);
	}

	checkLmiFingerprint(): Observable<FingerprintCheck> {
		// TO UPDATE ENDPOINTS
		if (this.channelSettingQuery.getLmiFingerprintEnabled()) {
			return this.checkFingerprint(`${this.applicationDataQuery.applicationId()}-lmi`);
		}

		return of({ isServiceActive: false, applicationHasNotBeenChanged: false });
	}

	checkPropertyFingerprint(propertyId: number, digitalWidgetType: string) {
		const code = `${digitalWidgetType.toLowerCase()}`;
		return this.getCustom(`${this.applicationDataQuery.applicationId()}/${propertyId}/${code}`).pipe(
			map((applicationHasNotBeenChanged: boolean) => {
				return { isServiceActive: true, applicationHasNotBeenChanged: applicationHasNotBeenChanged };
			}),
			catchError(() => {
				return of({ isServiceActive: false, applicationHasNotBeenChanged: false });
			})
		);
	}

	checkDigitalWidgetFingerprintApplicant(applicantId: number, digitalWidgetType: string): Observable<FingerprintCheck> {
		return this.checkApplicantFingerprint(applicantId, `${digitalWidgetType.toLowerCase()}`);
	}

	private checkApplicantFingerprint(applicantId: number, code: string) {
		return this.getCustom(`${this.applicationDataQuery.applicationId()}/${applicantId}/${code}`).pipe(
			map((applicationHasNotBeenChanged: boolean) => {
				return { isServiceActive: true, applicationHasNotBeenChanged: applicationHasNotBeenChanged };
			}),
			catchError(() => {
				return of({ isServiceActive: false, applicationHasNotBeenChanged: false });
			})
		);
	}

	private checkFingerprint(code: string) {
		return this.getCustom(`${this.applicationDataQuery.applicationId()}/${code}`).pipe(
			map((applicationHasNotBeenChanged: boolean) => {
				return { isServiceActive: true, applicationHasNotBeenChanged: applicationHasNotBeenChanged };
			}),
			catchError(() => {
				return of({ isServiceActive: false, applicationHasNotBeenChanged: false });
			})
		);
	}
}
