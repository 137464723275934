import { Injectable } from '@angular/core';
import { FinancialPositionService } from '@app/modules/financial-position/financial-position.service';
import { EmploymentModel } from '@app/modules/financial-position/model/employment.model';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { merge } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class FinancialPositionEmploymentTransformerService {
	constructor(
		private simpFormlyModalService: SimpFormlyModalService,
		private financialPositionService: FinancialPositionService
	) {}

	openSelfEmploymentModal(field: FormlyFieldConfig): void {
		const selfEmploymentModalRef = this.simpFormlyModalService.openModal(field, 'selfEmployment', {
			backdrop: 'static'
		});

		const subscription = selfEmploymentModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				subscription.unsubscribe();
				selfEmploymentModalRef.close();
				return;
			}
			const model = merge({}, field.parent?.model, field.form?.getRawValue()) as EmploymentModel;
			const key = Number(field.parent?.key);

			this.financialPositionService.saveSelfEmployment(key, model).subscribe(() => {
				selfEmploymentModalRef.close();
				subscription.unsubscribe();
			});
		});
	}
}
