import { Injectable } from '@angular/core';
import { ENV_CONFIG } from '@app/env-config';

@Injectable({
	providedIn: 'root'
})
export class FeatureFlagService {
	featureFlags?: FeatureFlag[];

	static isFlagEnabled(key: string): boolean {
		const flags = this.prototype.getAllFlags();
		return flags.find((flag) => flag.name === key)?.selected ?? false;
	}

	reset() {
		localStorage.removeItem('featureFlags');
		this.upsert();
	}

	upsert(featureFlags?: FeatureFlag[]): FeatureFlag[] {
		this.featureFlags = featureFlags ?? this.fromEnvironment();
		localStorage.setItem('featureFlags', JSON.stringify(this.featureFlags));
		return this.featureFlags || [];
	}

	getAllFlags(): FeatureFlag[] {
		if (this.featureFlags) {
			return this.featureFlags;
		}
		const featureFlags = localStorage.getItem('featureFlags');
		if (featureFlags) {
			const featureFlagsFromLocalStorage = JSON.parse(featureFlags) as FeatureFlag[];
			const featureFlagsFromEnvironment = this.fromEnvironment();

			let newFlagsAddedFromEnv = false;
			featureFlagsFromEnvironment.forEach((flag) => {
				if (!featureFlagsFromLocalStorage.some((localFlag) => localFlag.name === flag.name)) {
					featureFlagsFromLocalStorage.push(flag);
					newFlagsAddedFromEnv = true;
				}
			});
			if (newFlagsAddedFromEnv) {
				localStorage.setItem('featureFlags', JSON.stringify(featureFlagsFromLocalStorage));
			}
			return featureFlagsFromLocalStorage;
		}
		return this.upsert();
	}

	/**
	 * Reads the feature flag from local storage
	 * @param key
	 * @returns
	 */
	getFlag(key: string): boolean {
		const flags = this.getAllFlags();
		return flags.find((flag) => flag.name === key)?.selected ?? false;
	}

	private fromEnvironment(): FeatureFlag[] {
		return Object.entries(ENV_CONFIG.featureFlags || {}).map(([name, selected], id) => ({
			selected,
			name,
			id
		}));
	}
}

export interface FeatureFlag {
	selected: boolean;
	name: string;
	id: number;
}
