import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { isNil } from 'lodash-es';

import { Subscription } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Sample Button group
 * {
		className: 'col-2',
		type: 'button-group',
		key: 'applicantType',
		validation: {
			messages: { required: 'Select applicant type' }
		},
		templateOptions: {
			label: 'What type of applicant?',
			required: true,
			disabled: true
		}
	},
 */
@Component({
	selector: 'formly-button-group',
	templateUrl: './formly-button-group.component.html',
	styleUrls: ['./formly-button-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyButtonGroupComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';
	private subscription: Subscription = Subscription.EMPTY;
	constructor(private cdr: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}

		if (this.to.disabled) {
			this.formControl.disable();
		}

		this.subscription = this.formControl.valueChanges.subscribe(() => this.cdr.markForCheck());
	}

	onClick(event: Event): void {
		if (this.to.readonly) {
			return;
		}
		this.formControl.markAsDirty();
		event.stopPropagation();
		if (this.to.click) {
			this.to.click(this.field);
		}
		if (this.to.change) {
			this.to.change(this.field);
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}
}
