import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { DigitalServiceItemStatus } from '@app/modules/shared/enums/digital-service-item-status.enum';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccessSeekerDetails } from '../typings/financial-position-app';

@Injectable({
	providedIn: 'root'
})
export class AccessSeekerService extends BaseJourneyService<AccessSeekerDetails> {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery
	) {
		super();
		this.setRoute(URL_CONSTANTS.ACCESS_SEEKER);
	}

	getAccessSeekerApplicantState(key: string): Observable<AccessSeekerDetails> {
		return this.getAccessSeekerDetails().pipe(
			tap((data) => {
				if (data) {
					data.anyCancelled = data.accessSeekerApplicants.some(
						(applicant) => applicant.status === DigitalServiceItemStatus.Cancelled
					);
					this.simpFormlyHandlerService.upsertToStateWithData(key, [data]);
				}
			})
		);
	}

	getAccessSeekerDetails() {
		return this.get(`${this.applicationDataQuery.applicationId()}`);
	}

	resubmitRequest(applicantId: number, message?: string): Observable<void> {
		return <Observable<void>>this.postCustom(`ResubmitRequest`, {
			ladmApplicationId: this.applicationDataQuery.applicationId(),
			secureApplicantId: applicantId,
			message
		});
	}
}
