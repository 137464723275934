import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { RefiApplicationSummaryService } from '../../components/refi-form/application-summary/application-summary.service';
import { RefiStepToPath, RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiSecondBorrowerModel, RefiSecondBorrowerTransformer } from '../../models/refi-second-borrower.model';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiSecondBorrowerService } from './refi-second-borrower.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneySecondBorrowerTransformerService {
	constructor(
		private applicationSummaryService: RefiApplicationSummaryService,
		private formDataService: FormDataService,
		private refiModalService: RefiModalService,
		private refiJourneyService: RefiJourneyService,
		private applicationDataQuery: ApplicationDataQuery,
		private secondBorrowerService: RefiSecondBorrowerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private router: Router
	) {}

	public secondBorrowerTransform(formFields: FormlyFieldConfig[]) {
		formlyOnClick(
			formFields,
			'secondBorrower.details',
			(field, clickType: { type: string; invalid: boolean; value?: string }) => {
				if (clickType.type === 'primary' && !clickType.invalid) {
					this.applicationSummaryService.renewLock().subscribe();

					const modelToSave = field.model as RefiSecondBorrowerModel[];
					const model = modelToSave[0];
					if (field.templateOptions) {
						field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
					}
					this.refiJourneyService
						.hasPreviousAppSyncCompletedSuccessfully()
						.pipe(
							switchMap((appSyncResult) => {
								if (appSyncResult.status) {
									return this.secondBorrowerService.saveSecondBorrowerDetails(
										RefiSecondBorrowerTransformer.toPayload(model, this.applicationDataQuery.applicationId())
									);
								} else {
									this.refiJourneyService.displayErrorScreen();
									return of();
								}
							}),
							switchMap((secondBorrowerResult) => {
								if (secondBorrowerResult.status) {
									return this.refiJourneyService.getApplicants();
								} else {
									this.refiJourneyService.displayErrorScreen();
									return of();
								}
							})
						)
						.pipe(
							switchMap((res) => {
								if (res.length) {
									const textBlockField = getFormField(
										formFields,
										`secondBorrower.refi-modals.inviteSentLabels.textBlock1`
									) as FormlyFieldConfig;
									if (textBlockField.templateOptions?.label) {
										textBlockField.templateOptions.label = textBlockField.templateOptions.label.replace(
											/CoApplicantName/g,
											`${modelToSave[0].firstName} ${modelToSave[0].lastName}`
										);
									}
									return this.formDataService
										.select$(RefiStore.Modals)
										.pipe(
											take(1),
											switchMap((commonModalFormFields) =>
												this.refiModalService.openRefiModal(
													commonModalFormFields[0] as FormlyFieldConfig,
													RefiModalName.coborrowerInformation
												)
											)
										)
										.pipe(
											switchMap(() =>
												this.openRefiModal(formFields, RefiModalName.inviteSent).pipe(
													tap(() => this.refiJourneyService.navigateToNextStep(field))
												)
											)
										);
								}
								return of({});
							})
						)
						.subscribe();
				}

				if (clickType.type === 'secondary' && !clickType.invalid) {
					void this.router.navigateByUrl(`${this.refiJourneyService.getRefiJourneyPath()}/eligibility/details`);
				}

				if (clickType.type === 'subText' && !clickType.invalid) {
					if (clickType.value === 'termsAndConditions') {
						this.openTermsAndConditions(field);
					} else if (clickType.value === 'coBorrowerGuide') {
						this.openCoBorrowerGuide(field);
					}
				}
			}
		);

		formlyExtendExpressionProperties(formFields, `secondBorrower.details.dateOfBirth`, {
			'templateOptions.maxDate': () => {
				const current = new Date();
				current.setFullYear(current.getFullYear() - 18);
				return {
					year: current.getFullYear(),
					month: current.getMonth() + 1,
					day: current.getDate()
				};
			}
		});

		formlyExtendExpressionProperties(formFields, `secondBorrower.details`, {
			'templateOptions.nextRoute': () => {
				return RefiStepToPath.get(RefiStepType.RefiIncome);
			}
		});
	}

	private openTermsAndConditions(field: FormlyFieldConfig): void {
		const modalField = field.fieldGroup![0];
		if (modalField) {
			const modalRef = this.simpFormlyModalService.openModal(
				modalField,
				'termsConditionsModal',
				{
					windowClass: 'mobile-modal-class',
					animation: false,
					backdrop: false
				},
				true
			);

			const subscription = modalRef.action.subscribe(() => {
				modalRef.close();
				subscription.unsubscribe();
			});
		}
	}

	private openCoBorrowerGuide(field: FormlyFieldConfig): void {
		const modalField = field.fieldGroup![0];

		if (modalField) {
			const modalRef = this.simpFormlyModalService.openModal(
				modalField,
				'coBorrowerGuideModal',
				{
					windowClass: 'mobile-modal-class',
					animation: false,
					backdrop: false
				},
				true
			);

			const subscription = modalRef.action.subscribe(() => {
				modalRef.close();
				subscription.unsubscribe();
			});
		}
	}

	private openRefiModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): Observable<string> {
		const refiModalField = getFormField(formFields, 'secondBorrower.refi-modals');

		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}
}
