import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PropertyAssetModel } from '@app/modules/loan-serviceability/model/property-asset.model';
import { PropertyService } from '@app/modules/property/services/property.service';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FingerprintService } from '@app/modules/summary-lodgement/services/finger-print.service';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { catchError, forkJoin, map, Observable, of, Subject, switchMap, withLatestFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
	ConfigurableDigitalServiceHeaderStatus,
	ConfigurableDigitalServiceStatus,
	ConfigurableDigitalWidgetDetailsTypes,
	DigitalServiceActionTypes,
	DigitalServiceType,
	DigitalWidgetsAppType,
	DigitalWidgetTriggerLevel,
	DigitalWidgetTriggerType,
	ItemStatusToHeaderStatus
} from '../enums/digital-widgets-configuration.enums';
import {
	ConfigurableDigitalWidgetDetails,
	ConfigurableDigitalWidgetStatus,
	DigitalWidget,
	DigitalWidgetApiModel,
	DigitalWidgetExecuteResponse,
	DigitalWidgetHeader,
	DigitalWidgetPermissions,
	DigitalWidgetsConfigurationState
} from '../typings/digital-widgets-configuration';

const createInitialState = (): DigitalWidgetsConfigurationState => {
	return {
		widgets: []
	};
};

@Injectable({ providedIn: 'root' })
export class DigitalWidgetsConfigurationRepository extends BaseJourneyService<unknown> {
	store = createStore({ name: 'digital-widgets' }, withProps<DigitalWidgetsConfigurationState>(createInitialState()));
	applicationType = DigitalWidgetsAppType.LoanApp;
	detectRefDigitalWidgetComponent: Subject<void> = new Subject();

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private datePipe: DatePipe,
		private propertyService: PropertyService,
		private fingerprintService: FingerprintService
	) {
		super();
		this.setLadmRoute(URL_CONSTANTS.DIGITAL_SERVICE_FLOW);
	}

	selectWidget$ = (serviceType?: DigitalServiceType): Observable<DigitalWidget | undefined> => {
		return this.store.pipe(select((state) => state.widgets.find((widget) => widget.serviceType === serviceType)));
	};

	selectWIdgetTypes$ = (): Observable<DigitalServiceType[]> => {
		return this.store.pipe(
			select((state) => state.widgets),
			map((widgets) => (widgets ?? []).map((filteredWidget) => filteredWidget.serviceType))
		);
	};

	/**
	 * Get a summary of all enabled digital services configurations for an application of LoanApp:
	 * @returns
	 */
	fetchConfigurations() {
		const url = `configurationByJourneyId/${
			this.applicationType
		}/${this.applicationDataQuery.applicationId()}/${this.applicationDataQuery.getApplicationJourneyId()}`;
		return this.getCustom(url).pipe(
			switchMap((widgets: DigitalWidgetApiModel[]) => {
				this.store.update((state) => {
					state.widgets = widgets as DigitalWidget[];
					return state;
				});
				return this.fetchAllStatus();
			}),
			catchError(() => of([]))
		);
	}

	/**
	 * Get the status of all auto and enabled digital widget
	 * @returns
	 */
	fetchAllStatus(applicantOrPropertyId?: number, serviceType?: DigitalServiceType, errorMessages?: string[]) {
		const url = `status/${this.applicationDataQuery.applicationId()}/${this.applicationType}`;
		return this.getCustom(url).pipe(
			withLatestFrom(
				this.store.value.widgets.some((widget) =>
					[DigitalWidgetTriggerLevel.Property, DigitalWidgetTriggerLevel.Application].includes(widget.triggerLevel)
				)
					? this.propertyService.fetchSecurityProperties()
					: of([])
			),
			map(([apiWidgets, properties]: [ConfigurableDigitalWidgetStatus[], PropertyAssetModel[]]) => {
				this.store.update((state) => {
					state.widgets.forEach((storeWidget) => {
						const widget = apiWidgets
							.filter((apiWidget) => apiWidget.serviceType === storeWidget.serviceType)
							.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));

						const subItems = widget.map((apiWidget) => {
							const errorMessage =
								(!applicantOrPropertyId ||
									[apiWidget.applicantId, apiWidget.propertyId].includes(applicantOrPropertyId)) &&
								apiWidget.serviceType === serviceType
									? errorMessages?.[0]
									: undefined;
							if (
								errorMessage &&
								[ConfigurableDigitalServiceStatus.Error, ConfigurableDigitalServiceStatus.Quoted].includes(
									apiWidget.status
								)
							) {
								this.toastr.error(errorMessage);
							}
							return this.transformSubItem(apiWidget, storeWidget, !!properties?.length, errorMessage);
						});
						storeWidget.subItems = subItems;
						this.transformWidget(storeWidget, properties);
						storeWidget.decision = subItems.find((item) => item.decision)?.decision;
					});
					return state;
				});
			}),
			catchError(() => of([]))
		);
	}

	transformWidget(storeWidget: DigitalWidget, properties: PropertyAssetModel[]) {
		let headerStatus: ConfigurableDigitalServiceHeaderStatus;
		if (storeWidget.subItems?.some((item) => [ConfigurableDigitalServiceStatus.Error].includes(item.status))) {
			headerStatus = ConfigurableDigitalServiceHeaderStatus.Error;
		} else if (
			storeWidget.subItems?.some((item) => [ConfigurableDigitalServiceStatus.Declined].includes(item.status))
		) {
			headerStatus = ConfigurableDigitalServiceHeaderStatus.Declined;
		} else if (
			storeWidget.subItems?.some((item) =>
				[
					ConfigurableDigitalServiceStatus.Ready,
					ConfigurableDigitalServiceStatus.Cancelled,
					ConfigurableDigitalServiceStatus.ValidationError,
					ConfigurableDigitalServiceStatus.Expired
				].includes(item.status)
			)
		) {
			headerStatus = ConfigurableDigitalServiceHeaderStatus.Ready;
		} else if (storeWidget.subItems?.some((item) => item.status === ConfigurableDigitalServiceStatus.Pending)) {
			headerStatus = ConfigurableDigitalServiceHeaderStatus.Pending;
		} else {
			headerStatus = storeWidget.subItems?.[0]?.headerStatus ?? ConfigurableDigitalServiceHeaderStatus.Pending;
		}

		const statusLabel = ConfigurableDigitalServiceHeaderStatus[headerStatus];

		storeWidget.widgetStatus = {
			...storeWidget.widgetStatus,
			status: headerStatus,
			statusLabel: statusLabel.toLowerCase(),
			statusCounts: {
				statusCount: storeWidget.subItems.filter((item) => ItemStatusToHeaderStatus.get(item.status) === headerStatus)
					.length,

				totalCount: storeWidget.subItems.length,
				hidden: storeWidget.widgetStatus?.statusCounts?.hidden ?? false
			}
		};

		storeWidget.subItems.forEach((subItem) => {
			subItem.statusLabel = ConfigurableDigitalServiceStatus[subItem.status].toLowerCase();
			subItem.title = this.getDetailTitle(subItem, properties);
		});
		storeWidget.widgetStatus.statusBadge = this.getStatusBadge(storeWidget.widgetStatus.status);
		storeWidget.widgetStatus.showStatusCounts = this.getShouldShowStatusCounts(storeWidget.widgetStatus);
	}

	/**
	 * Trigger all auto and enabled digital services for an application of LoanApp when area changes
	 * @returns
	 */
	triggerAutoWidgets() {
		const url = `execute/${this.applicationDataQuery.applicationId()}/${this.applicationType}`;
		return this.postCustom(url, {}).pipe(catchError(() => of([])));
	}

	triggerAllActions(
		applicantIds: number[],
		serviceType: DigitalServiceType,
		actionType: DigitalServiceActionTypes,
		message?: string
	) {
		return forkJoin(
			applicantIds.map((id) => this.triggerAction(serviceType, { applicantId: id, message }, actionType))
		);
	}

	triggerAction(
		serviceType?: DigitalServiceType,
		executeBody?: Object,
		actionType?: DigitalServiceActionTypes
	): Observable<DigitalWidgetExecuteResponse> {
		let url = `execute/${this.applicationDataQuery.applicationId()}/${serviceType}/${this.applicationType}`;
		url = actionType ? `${url}/${actionType}` : url;
		return this.postCustom(url, executeBody) as Observable<DigitalWidgetExecuteResponse>;
	}

	refreshDigitalWidgetForSecurePerson(applicantId?: number, securePersonId?: number) {
		if (!securePersonId) {
			this.fetchAllStatus().subscribe();
			return;
		}

		this.refreshDigitalWidgetForApplicant(applicantId);
	}

	refreshDigitalWidgetForApplicant(applicantId?: number) {
		if (!applicantId) {
			return;
		}

		const $refreshWithFingerprintCheck = [
			DigitalServiceType.IdentityVerification,
			DigitalServiceType.IdentityDocumentVerification,
			DigitalServiceType.EsignConsent,
			DigitalServiceType.IncomeVerification
		].map((dwType) =>
			this.fingerprintService.checkDigitalWidgetFingerprintApplicant(applicantId, DigitalServiceType[dwType]).pipe(
				map((result) => {
					return { applicantId: applicantId, dwType: dwType, fingerprintCheck: result };
				})
			)
		);

		forkJoin($refreshWithFingerprintCheck)
			.pipe(
				switchMap((result) => {
					const $resetDWStatusObs = result
						.filter((element) => element.fingerprintCheck.applicationHasNotBeenChanged === false)
						.map((element) => {
							return this.resetDigitalServiceStatus(element.applicantId, element.dwType);
						});

					if ($resetDWStatusObs.length === 0) {
						return this.fetchAllStatus();
					} else {
						return forkJoin($resetDWStatusObs).pipe(switchMap(() => this.fetchAllStatus()));
					}
				}),
				tap(() => this.detectRefDigitalWidgetComponent.next())
			)
			.subscribe();
	}

	refreshDigitalWidgetForProperty(propertyId?: number, forceReset = false) {
		if (!propertyId) {
			this.fetchAllStatus();
			return;
		}

		this.selectWIdgetTypes$().subscribe((availableWidgetsTypes: DigitalServiceType[]) => {
			const enabledFingerPrintEligibleWidgetTypes = availableWidgetsTypes.filter((widget) =>
				[DigitalServiceType.PropertyTitleSearch, DigitalServiceType.RentalIncomeVerification].includes(widget)
			);
			this.refreshDigitalWidgetForPropertyByServiceTypes(propertyId, enabledFingerPrintEligibleWidgetTypes, forceReset);
		});
	}

	refreshDigitalWidgetForPropertyByServiceTypes(
		propertyId: number,
		digitalServiceTypes: DigitalServiceType[],
		forceReset = false
	) {
		const $refreshWithFingerprintCheck = digitalServiceTypes.map((dwType) => {
			if (forceReset) {
				return of({
					propertyId: propertyId,
					dwType: dwType,
					fingerprintCheck: { applicationHasNotBeenChanged: false }
				});
			}

			return this.fingerprintService.checkPropertyFingerprint(propertyId, DigitalServiceType[dwType]).pipe(
				map((result) => {
					return { propertyId: propertyId, dwType: dwType, fingerprintCheck: result };
				})
			);
		});

		forkJoin($refreshWithFingerprintCheck)
			.pipe(
				switchMap((result) => {
					const $resetDWStatusObs = result
						.filter((element) => element.fingerprintCheck.applicationHasNotBeenChanged === false)
						.map((element) => {
							return this.resetDigitalServiceStatusForProperty(element.propertyId, element.dwType);
						});

					if ($resetDWStatusObs.length === 0) {
						return this.fetchAllStatus();
					} else {
						return forkJoin($resetDWStatusObs).pipe(switchMap(() => this.fetchAllStatus()));
					}
				}),
				tap(() => this.detectRefDigitalWidgetComponent.next())
			)
			.subscribe();
	}

	resetDigitalServiceStatus(ladmApplicantId: number, serviceType: DigitalServiceType) {
		return this.postCustom(
			`ResetDigitalServiceStatus/${this.applicationDataQuery.applicationId()}/${ladmApplicantId}/${serviceType}`,
			{}
		);
	}

	resetDigitalServiceStatusForProperty(propertyId: number, serviceType: DigitalServiceType) {
		return this.postCustom(
			`ResetDigitalServiceStatusForProperty/${this.applicationDataQuery.applicationId()}/${propertyId}/${serviceType}`,
			{}
		);
	}

	getOverallStatus(details?: ConfigurableDigitalWidgetDetails[]): 'NONE' | 'FAILED' | 'PASS' {
		const overall = details?.find((detail) => detail.type === ConfigurableDigitalWidgetDetailsTypes.Overall);
		if (overall === undefined) {
			return 'NONE';
		}
		return overall.value === 'false' ? 'FAILED' : 'PASS';
	}

	getDigitalWidget(serviceType: DigitalServiceType): DigitalWidget | undefined {
		return this.store.getValue().widgets.find((widget) => widget.serviceType === serviceType);
	}

	setSecondaryHeaderBadge(type: DigitalServiceType, hideStatusCounts: boolean, label?: string, icon?: string) {
		this.store.update((state) => {
			const draft = cloneDeep(state);
			const widget = draft.widgets.find((w) => w.serviceType === type);

			if (widget) {
				widget.widgetStatus.secondaryBadgeLabel = label;
				widget.widgetStatus.secondaryBadgeIcon = icon;
				if (widget.widgetStatus.statusCounts) {
					widget.widgetStatus.statusCounts.hidden = hideStatusCounts;
					widget.widgetStatus.showStatusCounts = this.getShouldShowStatusCounts(widget.widgetStatus);
				}
			}

			return draft;
		});
	}

	setIsActionsHidden(type: DigitalServiceType, hidden: boolean) {
		this.store.update((state) => {
			const draft = cloneDeep(state);
			const widget = draft.widgets.find((w) => w.serviceType === type);

			if (widget) {
				widget.isActionsHidden = hidden;
			}

			return draft;
		});
	}

	private setupPermissions(
		serviceType: DigitalServiceType,
		currentStatus: ConfigurableDigitalServiceStatus,
		details?: ConfigurableDigitalWidgetDetails[]
	): DigitalWidgetPermissions | null {
		const widgetData = this.getDigitalWidget(serviceType);
		if (widgetData) {
			return {
				canRemind:
					[ConfigurableDigitalServiceStatus.Resubmitted, ConfigurableDigitalServiceStatus.Requested].includes(
						currentStatus
					) &&
					widgetData.triggerType === DigitalWidgetTriggerType.Manual &&
					widgetData.actionTypes.includes(DigitalServiceActionTypes.Remind),
				canRequest:
					[ConfigurableDigitalServiceStatus.Ready, ConfigurableDigitalServiceStatus.Skipped].includes(currentStatus) &&
					widgetData.triggerType === DigitalWidgetTriggerType.Manual &&
					widgetData.actionTypes.includes(DigitalServiceActionTypes.Request),
				canRemove:
					[ConfigurableDigitalServiceStatus.Requested, ConfigurableDigitalServiceStatus.Resubmitted].includes(
						currentStatus
					) && widgetData.actionTypes.includes(DigitalServiceActionTypes.Revoke),
				canApply:
					currentStatus === ConfigurableDigitalServiceStatus.Quoted &&
					widgetData.actionTypes.includes(DigitalServiceActionTypes.Apply),
				canResubmit:
					![
						ConfigurableDigitalServiceStatus.Ready,
						ConfigurableDigitalServiceStatus.Pending,
						ConfigurableDigitalServiceStatus.Skipped,
						ConfigurableDigitalServiceStatus.Resubmitted,
						ConfigurableDigitalServiceStatus.Requested
					].includes(currentStatus) && widgetData.actionTypes.includes(DigitalServiceActionTypes.Resubmit),
				canSkip: this.isCompletedAndOverallFailedWithSkip(widgetData.actionTypes, currentStatus, details)
					? true
					: this.isOtherStatusWithSkip(widgetData.actionTypes, currentStatus),
				canDelete:
					currentStatus === ConfigurableDigitalServiceStatus.Completed &&
					widgetData.actionTypes.includes(DigitalServiceActionTypes.DeleteRequest)
			};
		}
		return null;
	}

	private isCompletedAndOverallFailedWithSkip(
		actionTypes: DigitalServiceActionTypes[],
		currentStatus: ConfigurableDigitalServiceStatus,
		details?: ConfigurableDigitalWidgetDetails[]
	) {
		return (
			actionTypes.includes(DigitalServiceActionTypes.Skip) &&
			currentStatus === ConfigurableDigitalServiceStatus.Completed &&
			this.getOverallStatus(details) === 'FAILED'
		);
	}

	private isOtherStatusWithSkip(
		actionTypes: DigitalServiceActionTypes[],
		currentStatus: ConfigurableDigitalServiceStatus
	) {
		const otherStatuses = [
			ConfigurableDigitalServiceStatus.Ready,
			ConfigurableDigitalServiceStatus.Quoted,
			ConfigurableDigitalServiceStatus.Error,
			ConfigurableDigitalServiceStatus.ValidationError,
			ConfigurableDigitalServiceStatus.Declined,
			ConfigurableDigitalServiceStatus.Cancelled,
			ConfigurableDigitalServiceStatus.Pending
		];
		return otherStatuses.includes(currentStatus) && actionTypes.includes(DigitalServiceActionTypes.Skip);
	}

	private transformSubItem(
		widgetItem: ConfigurableDigitalWidgetStatus,
		widgetDetails: DigitalWidget,
		hasProperties: boolean,
		errorMessage?: string
	) {
		const latestTimeStamp = this.getLatestUpdatedTimestamp(widgetItem);
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		if (latestTimeStamp) {
			widgetItem.latestTimeStamp = this.datePipe.transform(latestTimeStamp, 'd/MM/yyyy, h:mm a');
		}
		if (widgetItem.firstName && widgetItem.lastName) {
			widgetItem.applicantName = `${widgetItem.firstName} ${widgetItem.lastName}`;
		}
		if (
			widgetItem.status === ConfigurableDigitalServiceStatus.Pending &&
			(!widgetDetails.triggerLevel ||
				// Applicant level
				(widgetDetails.triggerLevel === DigitalWidgetTriggerLevel.Applicant &&
					!!applicants.find((applicant) => applicant.id === widgetItem.applicantId)?.securePersonId) ||
				// Property level or Application level (LMI)
				([DigitalWidgetTriggerLevel.Property, DigitalWidgetTriggerLevel.Application].includes(
					widgetDetails.triggerLevel
				) &&
					hasProperties))
		) {
			widgetItem.status = ConfigurableDigitalServiceStatus.Ready;
		}

		if (
			widgetItem.status === ConfigurableDigitalServiceStatus.Pending &&
			!hasProperties &&
			widgetItem.serviceType === DigitalServiceType.RentalIncomeVerification
		) {
			widgetItem.status = ConfigurableDigitalServiceStatus.Ready;
		}

		widgetItem.errorMessage = errorMessage;
		Object.assign(widgetItem, this.setupPermissions(widgetItem.serviceType, widgetItem.status, widgetItem.details));

		return widgetItem;
	}

	private getLatestUpdatedTimestamp(widgetItem: ConfigurableDigitalWidgetStatus) {
		switch (widgetItem.status) {
			case ConfigurableDigitalServiceStatus.Completed:
			case ConfigurableDigitalServiceStatus.Declined:
			case ConfigurableDigitalServiceStatus.Quoted:
				return widgetItem.responseDate;
			case ConfigurableDigitalServiceStatus.Resubmitted:
				return widgetItem.resubmittedDate;
			case ConfigurableDigitalServiceStatus.Skipped:
				return widgetItem.skippedDate;
			case ConfigurableDigitalServiceStatus.Cancelled:
				return widgetItem.cancelledDate || widgetItem.responseDate;
			case ConfigurableDigitalServiceStatus.Expired:
				return widgetItem.expiredDate;
			default:
				return widgetItem.requestedDate;
		}
	}

	private getStatusBadge(status?: ConfigurableDigitalServiceHeaderStatus): string {
		switch (status) {
			case ConfigurableDigitalServiceHeaderStatus.Ready:
				return 'fa-play-circle icon-badge simp-text--grey70';
			case ConfigurableDigitalServiceHeaderStatus.Requested:
				return 'fa-check-circle icon-badge badge-blue';
			case ConfigurableDigitalServiceHeaderStatus.Completed:
			case ConfigurableDigitalServiceHeaderStatus.Quoted:
				return 'fa-check-circle icon-badge badge-green';
			case ConfigurableDigitalServiceHeaderStatus.Failed:
			case ConfigurableDigitalServiceHeaderStatus.Error:
			case ConfigurableDigitalServiceHeaderStatus.Declined:
				return 'fa-times-circle icon-badge badge-red';
			case ConfigurableDigitalServiceHeaderStatus.Skipped:
				return 'fa-forward icon-badge simp-text--orange';
			case ConfigurableDigitalServiceHeaderStatus.Pending:
				return 'fa-hourglass icon-badge simp-text--grey70';
			default:
				return '';
		}
	}

	private getShouldShowStatusCounts(widgetHeader?: DigitalWidgetHeader) {
		return (
			!widgetHeader?.statusCounts?.hidden &&
			(widgetHeader?.status !== ConfigurableDigitalServiceHeaderStatus.Pending ||
				widgetHeader?.statusCounts?.statusCount !== widgetHeader?.statusCounts?.totalCount)
		);
	}

	private getDetailTitle(
		widgetStatus: ConfigurableDigitalWidgetStatus,
		properties: PropertyAssetModel[]
	): string | undefined {
		const isSecurityProperty = properties.some((p) => p.id === widgetStatus.propertyId);

		switch (widgetStatus.serviceType) {
			case DigitalServiceType.IdentityVerification:
			case DigitalServiceType.CreditCheck:
			case DigitalServiceType.AccessSeeker:
			case DigitalServiceType.Documents:
			case DigitalServiceType.BankingStatement:
			case DigitalServiceType.IdentityDocumentVerification:
			case DigitalServiceType.EsignConsent:
			case DigitalServiceType.IncomeVerification:
				return widgetStatus.applicantName;
			case DigitalServiceType.LMI:
			case DigitalServiceType.PropertyValuation:
			case DigitalServiceType.PropertyTitleSearch:
				return widgetStatus.propertyAddress || undefined;
			case DigitalServiceType.RentalIncomeVerification:
				return (widgetStatus.propertyAddress ?? '') + (isSecurityProperty ? ' (security property)' : '');
			default:
				return;
		}
	}
}
