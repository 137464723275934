import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { URL_CONSTANTS } from '../constants/api.constants';
import { JourneySetting } from '../model/journey-setting.model';
import { SubJourneys } from '../store/application-data/typings/application-data';
import { SharedFlagsService } from '../store/shared-flags/shared-flags.service';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class SubJourneyService extends BaseJourneyService<JourneySetting> {
	constructor(private sharedFlagsService: SharedFlagsService, private toastrService: ToastrService) {
		super();
		this.setMetaRoute(URL_CONSTANTS.JOURNEY);
	}

	getSubJourneys(): Observable<SubJourneys[]> {
		return this.getCustom('') as Observable<SubJourneys[]>;
	}

	validateJourney$(journeyType: number) {
		return this.getSubJourneys().pipe(
			map((res) => {
				this.sharedFlagsService.subJourneys = res;
				return res.some((journey) => journey.baseJourneyTypeEnum === journeyType);
			}),
			tap((hasAccess) => {
				if (!hasAccess) {
					this.toastrService.error('AIP Journey not configured for this channel!');
				}
			})
		);
	}
}
