export enum ConvertApplicationType {
	ConvertToApplication = 1000,
	ViewServicability = 1001,
	ViewFullApplication = 1002
}

export enum BuilderType {
	LicencedBuilder = 1000,
	OwnerBuilder = 1001,
	UnlicencedBuilder = 1002
}

export enum DocumentationDeliveryMethod {
	CollectFromBranch = 1000,
	Email = 1001,
	Post = 1002
}

export enum FaceToFaceType {
	InPerson = 1000,
	VideoConference = 1001
}

export enum IncomeType {
	payg = 1000,
	selfEmployed = 1001,
	NotEmployed = 1002,
	ForeignEmployed = 1003
}

export enum IncomeDocumentationType {
	ATOIncomeStatement = 1000,
	CompanyFinancials = 1001
}

export enum IndependentAdviceTypeRequired {
	Financial = 1000,
	FinancialAndLegal = 1001,
	Legal = 1002
}

export enum ApplicationDocType {
	FullDoc = 1000,
	LoDoc = 1001,
	NoDoc = 1002
}

export enum Proportions {
	Equal = 1000,
	NotSpecified = 1001,
	Specified = 1002
}

export enum Visible {
	Visible = 'Visible',
	Hidden = 'Hidden',
	Rule = 'Rule'
}

export enum VisitContactType {
	Applicant = 1000,
	Builder = 1001,
	PropertyAgent = 1002,
	Tenant = 1003,
	Vendor = 1005
}

export enum Enabled {
	Enabled = 'Enabled',
	Disabled = 'Disabled',
	Rule = 'Rule'
}

export enum MemberTypes {
	Constructor = 1,
	Event = 2,
	Field = 4,
	Method = 8,
	Property = 16,
	TypeInfo = 32,
	Custom = 64,
	NestedType = 128,
	All = 191
}

export enum ParameterAttributes {
	None = 0,
	In = 1,
	Out = 2,
	Lcid = 4,
	Retval = 8,
	Optional = 16,
	HasDefault = 4096,
	HasFieldMarshal = 8192,
	Reserved3 = 16384,
	Reserved4 = 32768,
	ReservedMask = 61440
}

export enum MethodImplAttributes {
	IL = 0,
	Managed = 0,
	Native = 1,
	OPTIL = 2,
	Runtime = 3,
	CodeTypeMask = 3,
	Unmanaged = 4,
	ManagedMask = 4,
	NoInlining = 8,
	ForwardRef = 16,
	Synchronized = 32,
	NoOptimization = 64,
	PreserveSig = 128,
	AggressiveInlining = 256,
	AggressiveOptimization = 512,
	InternalCall = 4096,
	MaxMethodImplVal = 65535
}

export enum CallingConventions {
	Standard = 1,
	VarArgs = 2,
	Any = 3,
	HasThis = 32,
	ExplicitThis = 64
}

export enum SecurityRuleSet {
	None = 0,
	Level1 = 1,
	Level2 = 2
}

export enum RegexOptions {
	None = 0,
	IgnoreCase = 1,
	Multiline = 2,
	ExplicitCapture = 4,
	Compiled = 8,
	Singleline = 16,
	IgnorePatternWhitespace = 32,
	RightToLeft = 64,
	ECMAScript = 256,
	CultureInvariant = 512
}

export enum ExpressionType {
	Add = 0,
	AddChecked = 1,
	And = 2,
	AndAlso = 3,
	ArrayLength = 4,
	ArrayIndex = 5,
	Call = 6,
	Coalesce = 7,
	Conditional = 8,
	Constant = 9,
	Convert = 10,
	ConvertChecked = 11,
	Divide = 12,
	Equal = 13,
	ExclusiveOr = 14,
	GreaterThan = 15,
	GreaterThanOrEqual = 16,
	Invoke = 17,
	Lambda = 18,
	LeftShift = 19,
	LessThan = 20,
	LessThanOrEqual = 21,
	ListInit = 22,
	MemberAccess = 23,
	MemberInit = 24,
	Modulo = 25,
	Multiply = 26,
	MultiplyChecked = 27,
	Negate = 28,
	UnaryPlus = 29,
	NegateChecked = 30,
	New = 31,
	NewArrayInit = 32,
	NewArrayBounds = 33,
	Not = 34,
	NotEqual = 35,
	Or = 36,
	OrElse = 37,
	Parameter = 38,
	Power = 39,
	Quote = 40,
	RightShift = 41,
	Subtract = 42,
	SubtractChecked = 43,
	TypeAs = 44,
	TypeIs = 45,
	Assign = 46,
	Block = 47,
	DebugInfo = 48,
	Decrement = 49,
	Dynamic = 50,
	Default = 51,
	Extension = 52,
	Goto = 53,
	Increment = 54,
	Index = 55,
	Label = 56,
	RuntimeVariables = 57,
	Loop = 58,
	Switch = 59,
	Throw = 60,
	Try = 61,
	Unbox = 62,
	AddAssign = 63,
	AndAssign = 64,
	DivideAssign = 65,
	ExclusiveOrAssign = 66,
	LeftShiftAssign = 67,
	ModuloAssign = 68,
	MultiplyAssign = 69,
	OrAssign = 70,
	PowerAssign = 71,
	RightShiftAssign = 72,
	SubtractAssign = 73,
	AddAssignChecked = 74,
	MultiplyAssignChecked = 75,
	SubtractAssignChecked = 76,
	PreIncrementAssign = 77,
	PreDecrementAssign = 78,
	PostIncrementAssign = 79,
	PostDecrementAssign = 80,
	TypeEqual = 81,
	OnesComplement = 82,
	IsTrue = 83,
	IsFalse = 84
}

export enum ModelValidationState {
	Unvalidated = 0,
	Invalid = 1,
	Valid = 2,
	Skipped = 3
}

export enum Mandatory {
	Mandatory = 'Mandatory',
	Optional = 'Optional',
	Rule = 'Rule'
}

export enum FieldType {
	Number = 'Number',
	Money = 'Money',
	Percent = 'Percent',
	String = 'String',
	Date = 'Date',
	Selection = 'Selection',
	Boolean = 'Boolean',
	Address = 'Address',
	DataSelection = 'DataSelection'
}

export enum PickType {
	List = 'List',
	ButtonGroup = 'ButtonGroup',
	Combo = 'Combo',
	RadioButton = 'RadioButton'
}

export enum LiabilityTypeLimited {
	BridgingFinance = 1000,
	CarLoan = 1001,
	CommercialBill = 1002,
	ContingentLiability = 1003,
	HECSHELP = 1005,
	HirePurchase = 1006,
	InvoiceFinancingLoan = 1007,
	Lease = 1008,
	LineofCredit = 1009,
	LoanasGuarantor = 1010,
	MarginLoan = 1011,
	Other = 1013,
	OtherLoan = 1014,
	OutstandingTaxation = 1015,
	Overdraft = 1016,
	StoreCard = 1018,
	TermLoan = 1019,
	TradeFinanceLoan = 1020
}

export enum JourneyType {
	ApprovalInPrinciple = 7,
	FactFind = 2,
	StatementOfPosition = 3,
	FullApplication = 4,
	Refi = 11,
	Purchase = 51
}

export enum SubJourney {
	ApprovalInPrinciple = 1
}

export enum JourneyStatus {
	NotStarted = 1,
	DataEntry = 2,
	Processing = 3,
	Approved = 4,
	Declined = 5,
	Abandoned = 6,
	Deleted = -1
}

export enum StepStatus {
	Incomplete = 1,
	Complete = 2,
	Deleted = -1
}

export enum AddressType {
	DXBox = 1000,
	NonStandard = 1001,
	POBoxorBag = 1002,
	Standard = 1003
}

export enum StreetType {
	Access = 1000,
	Alley = 1001,
	Alleyway = 1002,
	Amble = 1003,
	Anchorage = 1004,
	Approach = 1005,
	Arcade = 1006,
	Arterial = 1007,
	Artery = 1008,
	Avenue = 1009,
	Banan = 1010,
	Bank = 1011,
	Basin = 1012,
	Bay = 1013,
	Beach = 1014,
	Bend = 1015,
	Block = 1016,
	Boardwalk = 1017,
	Boulevard = 1018,
	Boulevarde = 1019,
	Bowl = 1020,
	Brace = 1021,
	Brae = 1022,
	Break = 1023,
	Bridge = 1024,
	Broadway = 1025,
	Brow = 1026,
	Bypass = 1027,
	Byway = 1028,
	Canal = 1029,
	Causeway = 1030,
	Centre = 1031,
	Centreway = 1032,
	Chase = 1033,
	Circle = 1034,
	Circlet = 1035,
	Circuit = 1036,
	Circus = 1037,
	Close = 1038,
	Cluster = 1039,
	Colonnade = 1040,
	Common = 1041,
	Concourse = 1042,
	Connection = 1043,
	Copse = 1044,
	Corner = 1045,
	Corso = 1046,
	Course = 1047,
	Court = 1048,
	Courtyard = 1049,
	Cove = 1050,
	Crescent = 1051,
	Crest = 1052,
	Cross = 1053,
	Crossing = 1054,
	Crossroad = 1055,
	Crossway = 1056,
	Cruiseway = 1057,
	CulDeSac = 1058,
	Cutting = 1059,
	Dale = 1060,
	Dell = 1061,
	Dene = 1062,
	Deviation = 1063,
	Dip = 1064,
	Distributor = 1065,
	Divide = 1066,
	Dock = 1067,
	Domain = 1068,
	Drive = 1069,
	Driveway = 1070,
	Edge = 1071,
	Elbow = 1072,
	End = 1073,
	Entrance = 1074,
	Esplanade = 1075,
	Estate = 1076,
	Estuary = 1077,
	Expressway = 1078,
	Extension = 1079,
	Fairway = 1080,
	FireTrack = 1081,
	Firebreak = 1082,
	Fireline = 1083,
	Firetrack = 1084,
	Firetrail = 1085,
	Flat = 1086,
	Follow = 1087,
	Footway = 1088,
	Ford = 1089,
	Foreshore = 1090,
	Formation = 1091,
	Freeway = 1092,
	Front = 1093,
	Frontage = 1094,
	Gap = 1095,
	Garden = 1096,
	Gardens = 1097,
	Gate = 1098,
	Gates = 1099,
	Gateway = 1100,
	Glade = 1101,
	Glen = 1102,
	Grange = 1103,
	Green = 1104,
	Ground = 1105,
	Grove = 1106,
	Gully = 1107,
	Harbour = 1108,
	Heath = 1109,
	Heights = 1110,
	Highroad = 1111,
	Highway = 1112,
	Hill = 1113,
	Hollow = 1114,
	Hub = 1115,
	Interchange = 1116,
	Intersection = 1117,
	Junction = 1118,
	Key = 1119,
	Keys = 1120,
	Landing = 1121,
	Lane = 1122,
	Laneway = 1123,
	Lees = 1124,
	Line = 1125,
	Link = 1126,
	Little = 1127,
	Lookout = 1128,
	Loop = 1129,
	Lower = 1130,
	Mall = 1131,
	Manor = 1132,
	Meander = 1133,
	Mears = 1134,
	Mew = 1135,
	Mews = 1136,
	Mile = 1137,
	Motorway = 1138,
	Mount = 1139,
	Nook = 1140,
	Outlet = 1141,
	Outlook = 1142,
	Parade = 1143,
	Park = 1144,
	Parklands = 1145,
	Parkway = 1146,
	Part = 1147,
	Pass = 1148,
	Path = 1149,
	Pathway = 1150,
	Piazza = 1151,
	Pier = 1152,
	Place = 1153,
	Plateau = 1154,
	Plaza = 1155,
	Pocket = 1156,
	Point = 1157,
	Port = 1158,
	Portal = 1159,
	Promenade = 1160,
	Pursuit = 1161,
	Quad = 1162,
	Quadrangle = 1163,
	Quadrant = 1164,
	Quay = 1165,
	Quays = 1166,
	Ramble = 1167,
	Ramp = 1168,
	Range = 1169,
	Reach = 1170,
	Reserve = 1171,
	Rest = 1172,
	Retreat = 1173,
	Return = 1174,
	Ride = 1175,
	Ridge = 1176,
	Ridgeway = 1177,
	RightOfWay = 1178,
	Ring = 1179,
	Rise = 1180,
	Rising = 1181,
	River = 1182,
	Riverway = 1183,
	Riviera = 1184,
	Road = 1185,
	Roads = 1186,
	Roadside = 1187,
	Roadway = 1188,
	Ronde = 1189,
	Rosebowl = 1190,
	Rotary = 1191,
	Round = 1192,
	Route = 1193,
	Row = 1194,
	Rue = 1195,
	Run = 1196,
	ServiceWay = 1197,
	Shunt = 1198,
	Siding = 1199,
	Slope = 1200,
	Sound = 1201,
	Spur = 1202,
	Square = 1203,
	Stairs = 1204,
	StateHighway = 1205,
	Steps = 1206,
	Strand = 1207,
	Street = 1208,
	Streets = 1209,
	Strip = 1210,
	Subway = 1211,
	Tarn = 1212,
	TarniceWay = 1213,
	Terrace = 1214,
	Thoroughfare = 1215,
	Throughway = 1216,
	Tollway = 1217,
	Top = 1218,
	Tor = 1219,
	Towers = 1220,
	Track = 1221,
	Trail = 1222,
	Trailer = 1223,
	Triangle = 1224,
	Trunkway = 1225,
	Turn = 1226,
	Twist = 1227,
	Underpass = 1228,
	Upper = 1229,
	Vale = 1230,
	Viaduct = 1231,
	View = 1232,
	Villas = 1233,
	Vista = 1234,
	Wade = 1235,
	Walk = 1236,
	Walkway = 1237,
	Waterway = 1238,
	Way = 1239,
	Wharf = 1240,
	Woods = 1241,
	Wynd = 1242,
	Yard = 1243
}

export enum CountryCodeLimited {
	AU = 1012,
	NZ = 1170
}

export enum AuState {
	ACT = 1000,
	NSW = 1001,
	NT = 1002,
	QLD = 1003,
	SA = 1004,
	TAS = 1005,
	VIC = 1006,
	WA = 1007
}

export enum Branding {
	CurrentCoordinator = 1,
	Channel = 2,
	Broker = 3
}

export enum FinancialInstitution {
	E_86400 = 1000,
	AdelaideBank = 1004,
	AFGHomeLoans = 1006,
	AMP = 1015,
	ANZBank = 1017,
	ArabBankofAustralia = 1021,
	AthenaHomeLoans = 1024,
	AussieHomeLoans = 1025,
	BankAustralia = 1044,
	BankofMelbourne = 1047,
	BankSA = 1050,
	Bankwest = 1052,
	BendigoBank = 1057,
	BeyondBankAustralia = 1061,
	Bluestone = 1063,
	BOQ = 1065,
	CBA = 1077,
	CUA = 1113,
	GreaterBank = 1171,
	HSBC = 1182,
	IMBLtd = 1188,
	ING = 1190,
	Liberty = 1203,
	Macquarie = 1212,
	MEBank = 1216,
	MortgageEzy = 1232,
	MortgageHouse = 1233,
	MortgageMart = 1234,
	NAB = 1238,
	Other = 1252,
	PeoplesChoiceCreditUnion = 1257,
	Pepper = 1258,
	QudosBank = 1275,
	StGeorge = 1307,
	Suncorp = 1314,
	TeachersMutual = 1320,
	TictocOnline = 1324,
	UBank = 1330,
	WestpacBank = 1342,
	Wizard = 1345
}

export enum ApplicantType {
	Borrower = 1000,
	BorrowerandGuarantor = 1001,
	Guarantor = 1002
}

export enum BankingDataCategorizationStatus {
	Pending = 1000,
	Requested = 1001,
	Completed = 1002,
	Declined = 1003,
	Cancelled = 1005,
	None = 1006,
	Error = 1009
}

export enum ContributionFundsType {
	Cash = 1000,
	DepositPaid = 1001,
	ExistingEquity = 1002,
	FirstHomeOwnerGrant = 1003,
	GenuineSavings = 1004,
	Gift = 1005,
	Inheritance = 1006,
	NetProceedsfromSaleofProperty = 1007,
	Other = 1008,
	Redraw = 1009,
	SaleofNonRealEstateAsset = 1010,
	SecuredVISA = 1011,
	Superannuation = 1012,
	TermDeposit = 1013,
	UnsecuredBorrowing = 1014
}

export enum CreditCardType {
	AmericanExpress = 1000,
	BankCard = 1001,
	DinersClub = 1002,
	MasterCard = 1003,
	OtherCreditCard = 1004,
	Visa = 1005
}

export enum HousingStatus {
	Boarding = 1000,
	Caravan = 1001,
	Other = 1002,
	OwnHome = 1003,
	OwnHomeMortgage = 1004,
	Renting = 1005,
	SuppliedbyEmployer = 1006,
	WithParents = 1007
}

export enum EmploymentStatus {
	Previous = 1000,
	Primary = 1001,
	Secondary = 1002
}

export enum NotEmployedType {
	HomeDuties = 1000,
	OnBenefits = 1001,
	Retired = 1002,
	Student = 1003
}

export enum PaygBasis {
	Casual = 1000,
	CommissionOnly = 1001,
	Contract = 1002,
	FullTime = 1003,
	PartTime = 1004,
	Seasonal = 1005,
	Temporary = 1006
}

export enum SelfEmployedBasis {
	Casual = 1000,
	Contract = 1001,
	FullTime = 1002,
	PartTime = 1003,
	Temporary = 1004
}

export enum ForeignEmployedBasis {
	Casual = 1000,
	CommissionOnly = 1001,
	Contract = 1002,
	FullTime = 1003,
	PartTime = 1004,
	Seasonal = 1005,
	Temporary = 1006
}

export enum EmployerType {
	Private = 1000,
	Public = 1001
}

export enum EmploymentIncomeType {
	Salary = 1000,
	RegularOvertime = 1001,
	Bonus = 1002,
	Commission = 1003,
	CarAllowance = 1004,
	WorkAllowance = 1005,
	WorkersCompensation = 1006,
	Other = 1007
}

export enum NotEmployedIncomeType {
	GovernmentBenefits = 1000,
	NewStartAllowance = 1001,
	PrivatePension = 1002,
	Superannuation = 1003,
	Other = 1004
}

export enum ExpenseCategory {
	BodyCorporateFeesAndTax = 1000,
	Childcare = 1001,
	ClothingAndPersonalCare = 1002,
	GeneralBasicInsurances = 1004,
	Groceries = 1005,
	HealthInsurance = 1006,
	HigherEducationVocationalTrainingAndProfessionalFees = 1007,
	InvestmentPropertyRunningCosts = 1009,
	MedicalAndHealth = 1011,
	Other = 1012,
	PetCare = 1014,
	PrimaryResidenceRunningCosts = 1015,
	PrivateSchoolingAndTuition = 1016,
	PublicOrGovernmentPrimaryandSecondaryEducation = 1017,
	RecreationAndEntertainment = 1018,
	SecondaryResidenceRunningCosts = 1019,
	SicknessandPersonalAccidentInsuranceLifeInsurance = 1020,
	TelephoneInternetPayTV = 1021,
	Transport = 1022,
	Board = 3000,
	ChildAndSpousalMaintenance = 3001,
	OtherCommitmentOther = 3003,
	Rent = 3004
}

export enum ExpenseClassificationExpenseClass {
	Children = 1,
	Education = 2,
	Groceries = 3,
	HomeAndUtilities = 4,
	InsuranceAndSuper = 5,
	Other = 6,
	PersonalAndMedical = 7,
	Pets = 8,
	RecreationAndEntertainment = 9,
	TransportAndVehicles = 10,
	OngoingRent = 11
}

export enum RepaymentFrequency {
	Daily = 1000,
	Fortnightly = 1001,
	HalfYearly = 1002,
	Monthly = 1003,
	OneOff = 1004,
	Quarterly = 1005,
	Seasonal = 1006,
	Weekly = 1007,
	Yearly = 1008
}

export enum PrimaryPurposeLoanPurpose {
	Business = 1000,
	InvestmentNonResidential = 1001,
	InvestmentResidential = 1002,
	OwnerOccupied = 1003,
	Personal = 1004
}

export enum BorrowingReason {
	Purchase = 1,
	Refinance = 2,
	Bridging = 3
}

export enum PaymentType {
	InterestCapitalised = 1000,
	InterestOnly = 1001,
	PrepaidInterest = 1002,
	PrincipalandInterest = 1003,
	PrincipalInterestFees = 1004
}

export enum InterestType {
	FixedRate = 1000,
	Variable = 1001
}

export enum RbaLendingPurpose {
	Construction = 1000,
	FurtherAdvance = 1001,
	Other = 1002,
	PurchaseExistingDwelling = 1003,
	PurchaseNewDwelling = 1004,
	PurchaseOther = 1005,
	Refinance = 1006
}

export enum LoanType {
	BridgingFinance = 1000,
	ChattelMortgage = 1001,
	CommercialBill = 1002,
	CommercialHirePurchase = 1003,
	CreditCard = 1004,
	FinanceLease = 1005,
	InvoiceFinancingLoan = 1006,
	Lease = 1007,
	LineofCredit = 1008,
	MarginLoan = 1009,
	MortgageLoan = 1010,
	OperatingLease = 1011,
	OtherLoan = 1012,
	Overdraft = 1013,
	PersonalLoan = 1014,
	ReverseMortgage = 1015,
	TermLoan = 1016,
	TradeFinanceLoan = 1017
}

export enum LiabilityType {
	BridgingFinance = 1000,
	CarLoan = 1001,
	CommercialBill = 1002,
	ContingentLiability = 1003,
	CreditCard = 1004,
	HECSHELP = 1005,
	HirePurchase = 1006,
	InvoiceFinancingLoan = 1007,
	Lease = 1008,
	LineofCredit = 1009,
	LoanasGuarantor = 1010,
	MarginLoan = 1011,
	MortgageLoan = 1012,
	Other = 1013,
	OtherLoan = 1014,
	OutstandingTaxation = 1015,
	Overdraft = 1016,
	PersonalLoan = 1017,
	StoreCard = 1018,
	TermLoan = 1019,
	TradeFinanceLoan = 1020
}

export enum NameTitle {
	Dr = 1000,
	Hon = 1001,
	Lady = 1002,
	Miss = 1003,
	Mr = 1004,
	Mrs = 1005,
	Ms = 1006,
	Mx = 5000,
	Other = 1007,
	Prof = 1008,
	RHon = 1009,
	Rev = 1010,
	Sir = 1011
}

export enum Gender {
	Female = 1000,
	Male = 1001,
	Undisclosed = 1002
}

export enum PreferredContactPerson {
	Email = 1000,
	HomePhone = 1001,
	Mobile = 1002,
	WorkPhone = 1003
}

export enum MaritalStatus {
	DeFacto = 1000,
	Divorced = 1001,
	Married = 1002,
	Separated = 1003,
	Single = 1004,
	Unknown = 1005,
	Widowed = 1006
}

export enum CountryCode {
	AD = 1000,
	AE = 1001,
	AF = 1002,
	AG = 1003,
	AI = 1004,
	AL = 1005,
	AM = 1006,
	AO = 1007,
	AQ = 1008,
	AR = 1009,
	AS = 1010,
	AT = 1011,
	AU = 1012,
	AW = 1013,
	AX = 1014,
	AZ = 1015,
	BA = 1016,
	BB = 1017,
	BD = 1018,
	BE = 1019,
	BF = 1020,
	BG = 1021,
	BH = 1022,
	BI = 1023,
	BJ = 1024,
	BL = 1025,
	BM = 1026,
	BN = 1027,
	BO = 1028,
	BQ = 1029,
	BR = 1030,
	BS = 1031,
	BT = 1032,
	BV = 1033,
	BW = 1034,
	BY = 1035,
	BZ = 1036,
	CA = 1037,
	CC = 1038,
	CD = 1039,
	CF = 1040,
	CG = 1041,
	CH = 1042,
	CI = 1043,
	CK = 1044,
	CL = 1045,
	CM = 1046,
	CN = 1047,
	CO = 1048,
	CR = 1049,
	CU = 1050,
	CV = 1051,
	CW = 1052,
	CX = 1053,
	CY = 1054,
	CZ = 1055,
	DE = 1056,
	DJ = 1057,
	DK = 1058,
	DM = 1059,
	DO = 1060,
	DZ = 1061,
	EC = 1062,
	EE = 1063,
	EG = 1064,
	EH = 1065,
	ER = 1066,
	ES = 1067,
	ET = 1068,
	FI = 1069,
	FJ = 1070,
	FK = 1071,
	FM = 1072,
	FO = 1073,
	FR = 1074,
	GA = 1075,
	GB = 1076,
	GD = 1077,
	GE = 1078,
	GF = 1079,
	GG = 1080,
	GH = 1081,
	GI = 1082,
	GL = 1083,
	GM = 1084,
	GN = 1085,
	GP = 1086,
	GQ = 1087,
	GR = 1088,
	GS = 1089,
	GT = 1090,
	GU = 1091,
	GW = 1092,
	GY = 1093,
	HK = 1094,
	HM = 1095,
	HN = 1096,
	HR = 1097,
	HT = 1098,
	HU = 1099,
	ID = 1100,
	IE = 1101,
	IL = 1102,
	IM = 1103,
	IN = 1104,
	IO = 1105,
	IQ = 1106,
	IR = 1107,
	IS = 1108,
	IT = 1109,
	JE = 1110,
	JM = 1111,
	JO = 1112,
	JP = 1113,
	KE = 1114,
	KG = 1115,
	KH = 1116,
	KI = 1117,
	KM = 1118,
	KN = 1119,
	KP = 1120,
	KR = 1121,
	KW = 1122,
	KY = 1123,
	KZ = 1124,
	LA = 1125,
	LB = 1126,
	LC = 1127,
	LI = 1128,
	LK = 1129,
	LR = 1130,
	LS = 1131,
	LT = 1132,
	LU = 1133,
	LV = 1134,
	LY = 1135,
	MA = 1136,
	MC = 1137,
	MD = 1138,
	ME = 1139,
	MF = 1140,
	MG = 1141,
	MH = 1142,
	MK = 1143,
	ML = 1144,
	MM = 1145,
	MN = 1146,
	MO = 1147,
	MP = 1148,
	MQ = 1149,
	MR = 1150,
	MS = 1151,
	MT = 1152,
	MU = 1153,
	MV = 1154,
	MW = 1155,
	MX = 1156,
	MY = 1157,
	MZ = 1158,
	NA = 1159,
	NC = 1160,
	NE = 1161,
	NF = 1162,
	NG = 1163,
	NI = 1164,
	NL = 1165,
	NO = 1166,
	NP = 1167,
	NR = 1168,
	NU = 1169,
	NZ = 1170,
	OM = 1171,
	PA = 1172,
	PE = 1173,
	PF = 1174,
	PG = 1175,
	PH = 1176,
	PK = 1177,
	PL = 1178,
	PM = 1179,
	PN = 1180,
	PR = 1181,
	PS = 1182,
	PT = 1183,
	PW = 1184,
	PY = 1185,
	QA = 1186,
	RE = 1187,
	RO = 1188,
	RS = 1189,
	RU = 1190,
	RW = 1191,
	SA = 1192,
	SB = 1193,
	SC = 1194,
	SD = 1195,
	SE = 1196,
	SG = 1197,
	SH = 1198,
	SI = 1199,
	SJ = 1200,
	SK = 1201,
	SL = 1202,
	SM = 1203,
	SN = 1204,
	SO = 1205,
	SR = 1206,
	SS = 1207,
	ST = 1208,
	SV = 1209,
	SX = 1210,
	SY = 1211,
	SZ = 1212,
	TC = 1213,
	TD = 1214,
	TF = 1215,
	TG = 1216,
	TH = 1217,
	TJ = 1218,
	TK = 1219,
	TL = 1220,
	TM = 1221,
	TN = 1222,
	TO = 1223,
	TR = 1224,
	TT = 1225,
	TV = 1226,
	TW = 1227,
	TZ = 1228,
	UA = 1229,
	UG = 1230,
	UM = 1231,
	US = 1232,
	UY = 1233,
	UZ = 1234,
	VA = 1235,
	VC = 1236,
	VE = 1237,
	VG = 1238,
	VI = 1239,
	VN = 1240,
	VU = 1241,
	WF = 1242,
	WS = 1243,
	YE = 1244,
	YT = 1245,
	ZA = 1246,
	ZM = 1247,
	ZW = 1248
}

export enum NonRealEstateAssetType {
	Agricultural = 1000,
	Aircraft = 1001,
	Business = 1002,
	Cleaning = 1003,
	EarthMovingMiningAndConstruction = 1004,
	FinancialAsset = 1005,
	HospitalityandLeisure = 1006,
	ITandAVEquipment = 1007,
	MaterialsHandlingandLifting = 1008,
	Medical = 1009,
	MobileComputing = 1010,
	MotorVehicleandTransport = 1011,
	OfficeEquipment = 1012,
	Other = 1013,
	PlantEquipmentandIndustrial = 1014,
	ToolsofTrade = 1015,
	Watercraft = 1016
}

export enum OtherIncomeType {
	Annuities = 1000,
	ChildMaintenance = 1001,
	Dividends = 1002,
	ForeignSourced = 1003,
	GovernmentBenefits = 1004,
	InterestIncome = 1005,
	ManagedInvestment = 1006,
	OtherIncome = 1007,
	PrivatePension = 1008,
	SalarySacrificeContribution = 1009,
	SuperConcessionalContribution = 1010,
	SuperExcessConcessionalContribution = 1011,
	SuperNonConcessionalContribution = 1012,
	Superannuation = 1013,
	WorkersCompensation = 1014
}

export enum ResidencyStatus {
	NonResident = 1000,
	PermanentlyInAustralia = 1001,
	TemporarilyInAustralia = 1002
}

export enum EstimateBasisRealEstateAsset {
	ApplicantEstimate = 1000,
	BankAssessment = 1001,
	CertifiedValuation = 1002,
	ContractofSale = 1003,
	ElectronicValuation = 1004,
	RatesNotice = 1005,
	RPData = 1006
}

export enum RealEstateAssetStatus {
	Established = 1000,
	NewBuilding = 1001,
	OffthePlan = 1002,
	VacantLand = 1003
}

export enum PrimaryUsage {
	Commercial = 1000,
	Industrial = 1001,
	Residential = 1002,
	Rural = 1003
}

export enum RuralUsage {
	IncomeProducing = 1000,
	RuralLife = 1001,
	RuralResidential = 1002,
	Other = 5000
}

export enum PredominantLoanPurpose {
	PurchaseRefinanceOrRenovations = 1000,
	Other = 1001,
	NoAdditionalFunds = 1002
}

export enum PrimaryPurposeRealEstateAsset {
	Business = 1000,
	Investment = 1001,
	OwnerOccupied = 1002
}

export enum Holding {
	CompanyShare = 1000,
	JointTenants = 1001,
	Sole = 1002,
	TennantCommoninEqualShares = 1003,
	TennantCommoninSharesof = 1004,
	TimeShare = 1005
}

export enum ResidentialType {
	AgedCareUnit = 1000,
	ApartmentUnitFlat = 1001,
	BedsitterBachelor = 1002,
	CompanyTitleUnit = 1003,
	ConvertedCommercialProperty = 1004,
	ConvertedIndustrialProperty = 1005,
	ConvertedMotelUnits = 1006,
	DisplayHouse = 1007,
	Duplex = 1008,
	FullyDetachedHouse = 1009,
	HighDensityApartment = 1010,
	HolidayHome = 1011,
	HolidayRental = 1012,
	ImprovedLand = 1013,
	KitHome = 1014,
	LowDensityApartment = 1015,
	LuxuryHouse = 1016,
	LuxuryOther = 1017,
	MediumDensityApartment = 1018,
	PropertyDevelopment = 1019,
	RelocatableHome = 1020,
	ResortUnit = 1021,
	SemiDetachedHouse = 1022,
	ServicedApartment = 1023,
	StrataHotelUnit = 1024,
	StrataTitleUnit = 1025,
	StudioWarehouseApartment = 1026,
	Terrace = 1027,
	Townhouse = 1028,
	UnitStudentAccommodation = 1029,
	VacantLand = 1030,
	Villa = 1031
}

export enum TitleSystem {
	Company = 1000,
	OldLaw = 1001,
	Qualified = 1002,
	Torrens = 1003,
	Unregistered = 1004
}

export enum TenureType {
	CommunityTitle = 1000,
	CompanyTitle = 1001,
	CrownLand = 1002,
	CrownLease = 1003,
	Freehold = 1004,
	GroupTitlesPlan = 1005,
	Leasehold = 1006,
	MoietyTitle = 1007,
	None = 1008,
	OtherTitle = 1009,
	PurpleTitle = 1010,
	StrataTitle = 1011,
	StratumTitle = 1012,
	SurveyStrataTitle = 1013,
	UnitTitle = 1014
}

export enum AssetTransaction {
	Owns = 1000,
	OwnsExistingMortgage = 1001,
	Purchasing = 1002,
	Sold = 1003
}

export enum SecurityPriority {
	FirstMortgage = 1000,
	RegisteredSecurity = 1001,
	SecondafterDSH = 1002,
	SecondMortgage = 1003,
	ThirdMortgage = 1004,
	UnregisteredMortgage = 1005
}

export enum RuralType {
	EightHectaresOrLess = 1000,
	Over40Hectares = 1001,
	Over8LessThan40Hectares = 1002
}

export enum CommercialType {
	BlockofUnitsorFlats = 1000,
	Factory = 1001,
	MultipleDwellingDevelopment = 1002,
	NonSpecialisedCommercial = 1003,
	Offices = 1004,
	Other = 1005,
	ProfessionalChambers = 1006,
	ResidentialCommercial = 1007,
	RetirementVillage = 1008,
	VacantLand = 1009,
	Warehouse = 1010
}

export enum IndustrialType {
	LightIndustrial = 1000,
	NonSpecialisedIndustrial = 1001,
	Other = 1002
}

export enum FrequencyFull {
	Fortnightly = 1000,
	Monthly = 1001,
	Weekly = 1002,
	Yearly = 1003,
	OneOff = 1004,
	Quarterly = 1005,
	Seasonal = 1006,
	Daily = 1007,
	HalfYearly = 1008
}

export enum FinancialAssetType {
	Cash = 1000,
	Investment = 1001,
	ManagedFunds = 1002,
	Other = 1003,
	PensionAccount = 1004,
	Receivables = 1005,
	SavingsAccount = 1006,
	SharesandDebentures = 1007,
	Superannuation = 1008,
	TermDeposit = 1009,
	TransactionAccount = 1010,
	TrustBeneficialInterest = 1011
}

export enum ExpenseClassificationExpenseClass2 {
	Children = 1,
	Education = 2,
	Groceries = 3,
	HomeAndUtilities = 4,
	InsuranceAndSuper = 5,
	Other = 6,
	PersonalAndMedical = 7,
	Pets = 8,
	RecreationAndEntertainment = 9,
	TransportAndVehicles = 10,
	OngoingRent = 11
}

export enum NumberOfApplicants {
	Single = 1,
	Couple = 2,
	More = 3
}

export enum YesNo {
	Yes = 1,
	No = 0
}

export enum YesNoNotApplicable {
	Yes = 1,
	No = 0,
	NotApplicable = 2
}

export enum YesNone {
	Yes = 1000,
	None = 1001
}

export enum YesNoIntent {
	FutureIntent = 1000,
	No = 1001,
	Yes = 1002
}

export enum SecurityUsage {
	Primary = 1000,
	Secondary = 1001,
	No = 1002
}

export enum LoanTermUnits {
	Days = 1000,
	Months = 1001,
	Weeks = 1002,
	Years = 1003
}

export enum ApplicationPreCheck {
	Eligible = 1,
	Ineligible = 2,
	Unknown = 3
}

export enum PreferredContactCompanyTrust {
	Email = 1000,
	OfficePhone = 1001
}

export enum PropertyEvaluatorType {
	Declared = 1,
	PropTrack = 2,
	Valex = 3
}

export enum PropertyEvaluationType {
	ApplicantEstimate = 1,
	BankAssessment = 2,
	CertifiedValuation = 3,
	ContractofSale = 4,
	ElectronicValuation = 5,
	RatesNotice = 6,
	RPDataPropTrack = 7,
	RPDataValex = 8,
	RPDataOther = 9
}

export enum RentalTerm {
	LongTerm = 1001,
	ShortTerm = 1000
}

export enum GovernmentBenefitsType {
	Abstudy = 1000,
	AgePension = 1001,
	Austudy = 1002,
	CarerPayment = 1003,
	ChildSupport = 1004,
	CrisisPayment = 1005,
	DisabilitySupportPension = 1006,
	FamilyAllowance = 1007,
	MobilityAllowance = 1008,
	Other = 1009,
	ParentingPayment = 1010,
	WidowsAllowance = 1011,
	YouthAllowance = 1012
}

export enum JobLevel {
	NonManagement = 1000,
	Management = 1001,
	SkilledTrades = 1002,
	ProfessionalTrade = 1003,
	Executive = 1004
}

export enum CreditStatus {
	Clean = 1000,
	Unknown = 1001,
	Warning = 1002
}

export enum CreditHistoryIssue {
	ApplicationForHardshipWithExistingLender = 1000,
	Arrears = 1001,
	Bankruptcy = 1002,
	Default = 1003,
	FinancialStressFromCurrentCommitments = 1004,
	Insolvency = 1005,
	Judgement = 1006,
	LegalProceeding = 1007,
	Other = 1008,
	SchemeOfArrangement = 1009,
	Writ = 1010
}

export enum AddbackType {
	Allowances = 10,
	AmortisationOfGoodwill = 8,
	Bonus = 11,
	CarExpense = 12,
	CarryForwardLosses = 7,
	Depreciation = 1,
	Interest = 2,
	Lease = 3,
	NonCashBenefits = 4,
	NonRecurringExpenses = 5,
	Salary = 9,
	SuperannuationExcess = 6
}

export enum GrossProfit {
	GrossProfitAfterTax = 1001,
	GrossProfitBeforeTax = 1000
}

export enum MeetingLocation {
	InPerson = 1,
	Phone = 3,
	VideoConference = 2
}

export enum Importance {
	DontWant = 1000,
	Important = 1001,
	NotImportant = 1002
}

// TODO: Remove after all widgets migrated
export enum DigitalWidgetStatusType {
	Assigned = 'assigned',
	Pending = 'pending',
	Requested = 'requested',
	Completed = 'completed',
	Failed = 'failed',
	Error = 'error'
}

export enum ConfirmationDialogHeaderType {
	Success = 1,
	Warning = 2,
	Danger = 3
}

export enum BankAccountType {
	BankAccount = 1000,
	CreditCard = 1001,
	ForeignBankAccount = 1002
}

export enum ExpensesType {
	Both = 'both',
	DigitalOnly = 'digitalOnly',
	DeclaredOnly = 'declaredOnly'
}

export enum SpinnerType {
	FilterProduct = 'FilterProduct',
	LoadingFeatures = 'LoadingFeatures'
}

export enum ProofCodeNotEmployed {
	LetterfromAppropriateBody = 1000,
	LetterfromGovernment = 1001,
	LetterfromSuperFund = 1002,
	TaxReturn = 1003
}

export enum ChannelSettingsExpenseMode {
	Declared = 1,
	Digital = 2,
	Both = 3
}

export enum ExpenseSource {
	Declared = 1000,
	Digital = 1001
}

export enum ApplicantEntityType {
	PersonApplicant = 1000,
	CompanyApplicant = 1001,
	TrustApplicant = 1002
}

export enum ApplicantKeyType {
	PersonApplicant = 'personApplicant',
	CompanyApplicant = 'companyApplicant',
	TrustApplicant = 'trustApplicant'
}

export enum TargetType {
	PersonApplicant = 1,
	RelatedPerson = 2,
	CompanyApplicant = 3,
	RelatedCompany = 4,
	TrustApplicant = 5
}

export enum AddNewPersonCompany {
	NewPerson = -1,
	NewCompany = -2
}

export enum ForeignTaxAssociationStatus {
	Provided = 1000,
	None = 1001,
	NotProvided = 1002
}

export enum TinNotProvidedReason {
	CountryOfTaxDoesNotIssueTIN = 1000,
	CountryOfTaxDoesNotRequireTINToBeDisclosed = 1001,
	UnableToObtainTIN = 1002
}

export enum VariableInterestImportantOptions {
	ReasonPotentialRateDecreases = 1000,
	ReasonFlexibility = 1001,
	ReasonOther = 1002
}

export enum RedrawImportantOptions {
	ReasonFlexibilityToAccessPrepaidFundsIfNeeded = 1000,
	ReasonOther = 1001
}

export enum PrincipleAndInterestImportantOptions {
	ReasonMinimiseInterestPaidOverLifeOfLoan = 1000,
	ReasonHigherLendingLimit = 1001,
	ReasonLowerDepositRequired = 1002,
	ReasonBuildUpEquityFromTheStart = 1003,
	ReasonOther = 1004
}

export enum InterestInAdvanceImportantOptions {
	ReasonForTaxPurposes = 1000,
	ReasonDiscountsOnInterestRate = 1001,
	ReasonToAssistWithCashFlowAndBudgeting = 1002,
	ReasonOther = 1003
}

export enum InterestOnlyImportantOptions {
	ReasonAccommodateAnticipatedNonRecurringExpenseItem = 1000,
	ReasonAccommodateTemporaryReductionInIncome = 1001,
	ReasonMaximiseCashFlow = 1002,
	ReasonMinimiseCashFlowImpactDuringConstruction = 1003,
	ReasonMinimiseCashFlowImpactDuringTheBridgingPeriod = 1004,
	ReasonPlanToConvertToInvestmentProperty = 1005,
	ReasonPrincipalReductionsInAnOffsetFacility = 1006,
	ReasonRecommendation = 1007,
	ReasonTaxationOrAccountingReasons = 1008,
	ReasonVariableAndUnpredictableIncome = 1009,
	ReasonOther = 1010
}

export enum OffsetOptions {
	ReasonAllowsPayingOffLoanSooner = 1000,
	ReasonAllowsAccessToFunds = 1001,
	ReasonForTaxPurposes = 1002,
	ReasonOther = 1003
}

export enum PropertyPartType {
	CarSpace = 1000,
	Garage = 1001,
	GrannyFlat = 1002,
	RoomWithinMainResidence = 1003
}

export enum TrustStructure {
	Discretionary = 1000,
	Fixed = 1001,
	Hybrid = 1002,
	Simple = 1003,
	Unit = 1004
}

export enum TrustPurpose {
	FamilyTrust = 1000,
	Protective = 1001,
	SecurityTrust = 1002,
	SMSF = 1003,
	TradingTrust = 1004
}

export enum KinRelationship {
	Child = 1000,
	Friend = 1001,
	Grandparent = 1002,
	OtherRelative = 1003,
	Parent = 1004,
	Sibling = 1005,
	Spouse = 1006
}

export enum BusinessStructureFull {
	Company = 1000,
	Partnership = 1001,
	SoleTrader = 1002,
	Trust = 1003
}

export enum RelatedPartyType {
	PoaHolder = 1000,
	Nominee = 1001,
	Solicitor = 1002,
	Accountant = 1003
}

export enum PowerOfAttorneyType {
	Company = 1000,
	Enduring = 1001,
	General = 1002,
	Other = 1003
}

export enum InsuranceType {
	BodyCorporate = 1000,
	BuildingInsurance = 1001,
	BuildingReplacement = 1002,
	ConsumerCreditInsurance = 1003,
	Disablement = 1004,
	HomeContentsInsurance = 1005,
	IncomeProtection = 1006,
	InvoluntaryUnemployment = 1007,
	LifeInsurance = 1008,
	LMI = 1009,
	Other = 1010
}

export enum VehicleType {
	Bicycle = 1000,
	Bodies = 1001,
	BusCoachorSpecialPurpose = 1002,
	Car = 1003,
	Caravan = 1004,
	LightCommercial = 1005,
	Locomotive = 1006,
	Motorcycle = 1007,
	Other = 1008,
	RailCars = 1009,
	Trailer = 1010,
	Truck = 1011,
	Watercraft = 1012
}

export enum OtherAssetType {
	ArtWorks = 1000,
	GoodWillofBusiness = 1001,
	HomeContents = 1002,
	IntellectualProperty = 1003,
	StockandInventory = 1005,
	Other = 1004
}

export enum InterestOnlyReason {
	CreateFundsForInvestmentPurposes = 1000,
	LargeNonRecurringExpenses = 1001,
	MaximizeCashFlow = 1002,
	MinimizeCashFlowImpactDuringConstruction = 1003,
	MinimizeCashFlowImpactDuringTheBridgingPeriod = 1004,
	PlanToConvertToInvestmentProperty = 1006,
	PrincipalReductionsInAnOffsetFacility = 1007,
	RecommendationFromIndependentFinancialAdviser = 1008,
	TaxationFinancialOrAccountingReasons = 1009,
	TemporaryReductionInIncome = 1010,
	VariableIncome = 1011,
	OtherReason = 1005
}

export enum ExemptStatus {
	Exempt = 1000,
	NonExempt = 1001
}

export enum OECDCRSStatus {
	Active = 1000,
	Passive = 1001
}

export enum ClearingFromThisLoan {
	No = 1000,
	Partial = 1001,
	Yes = 1002
}

export enum MortgageLiabilityType {
	TermLoan = 1019,
	LineOfCredit = 1009
}

export enum ApplicantRole {
	PrimaryBorrower = 1000,
	Coborrower = 1001,
	Guarantor = 1002,
	GuarantorSecurityOnly = 1003,
	GuarantorIncomeOnly = 1004,
	GuarantorIncomeAndSecurity = 1005
}

export enum EstimateBasisNonRealEstateAsset {
	ApplicantEstimate = 1000,
	CertifiedValuation = 1001
}

export enum MetricFormat {
	Number = 1,
	CurrencyWithFrequency = 2,
	Percentage = 3,
	Currency = 4
}

export enum ValidationStatus {
	Pass = 1,
	Warning = 2,
	Fail = 3
}

export enum AbnLookupSearchTypes {
	ByName = 0,
	ByBusinessNumber = 1
}

export enum LendingGuaranteeType {
	IncomeAndSecurity = 1000,
	IncomeOnly = 1001,
	SecurityOnly = 1002
}

export enum LoanFeature {
	Redraw = 11014,
	DebitCard = 11011,
	Offset = 11007,
	CreditCard = 11010,
	RateLock = 11009
}

export enum FeeSubsection {
	PaidOnOrBefore = 1000,
	PaidThroughoutLoan = 1001
}

export enum FeeType {
	AcceptanceFee = 1000,
	AdditionalSecurityFee = 1001,
	AnnualFee = 1002,
	ApplicationFee = 1003,
	BrokerageFee = 1004,
	CashBenefitInsurance = 1005,
	ConstructionProgressInspectionFee = 1006,
	ConsumerCreditInsurance = 1007,
	DocumentationFee = 1008,
	EarlyRepaymentAdminFee = 1009,
	EstablishmentFee = 1010,
	ExtendedWarrantyInsurance = 1011,
	GuaranteeAssetProtectionInsurance = 1012,
	InspectionFee = 1013,
	LandTitlesOfficeFinalSearchFee = 1014,
	LandTitlesOfficeSearchFee = 1015,
	LegalFee = 1016,
	LMI = 1017,
	LoanFee = 1018,
	MortgageRegistration = 1019,
	MotorVehicleInsurance = 1020,
	PackageFee = 1022,
	PPSRFee = 1023,
	RateLockFee = 1024,
	ReDocumentationFee = 1025,
	ReducedEquityFee = 1026,
	RiskFee = 1027,
	RolloverFee = 1028,
	SecurityGuaranteeFee = 1029,
	SecurityReleaseFee = 1030,
	ServicePlan = 1031,
	SettlementFee = 1032,
	StampDutyonMortgage = 1033,
	StampDutyonTransferofProperty = 1034,
	SubstituteSecurityFee = 1035,
	SwitchFee = 1036,
	ThirdPartyDocumentProductionFee = 1037,
	TitleTransferFee = 1038,
	TyreandRimInsurance = 1039,
	UnusedFacilityFee = 1040,
	ValuationFee = 1041,
	WithdrawalofCaveatFee = 1042,
	WithdrawalofWritFee = 1043,
	Other = 1021
}

export enum RequirementItemType {
	Simple = 1,
	Complex = 2
}

export enum RequirementCondition {
	All = 1,
	Any = 2,
	AtLeast = 3
}

export enum ChecklistVerificationResult {
	Pass = 1,
	Fail = 2,
	Reject = 3,
	AllowException = 4
}

export enum InfoRequestStatus {
	New = 1,
	Open = 2,
	Replied = 3,
	Declined = 4,
	Complete = 5,
	Cancelled = 6,
	Suspended = 7
}

export enum PrimaryDataSourceOption {
	DefaultOption = 0,
	FromChecklistTemplate = 1,
	FromUploadDoc = 2,
	FromLoanApp = 3
}

export enum FileType {
	Image,
	Pdf,
	Word,
	Excel,
	Other
}

export enum OriginationSource {
	LoanApp = 1000,
	ApprovalInPrinciple = 1001,
	FullApplication = 1002,
	Refi = 1003,
	ServiceabilityCheck = 1004
}

export enum SalesChannelType {
	BranchNetwork = 1000,
	CallCentre = 1001,
	MortgageBroker = 1002,
	MortgageManager = 1003,
	MortgageOriginator = 1004,
	OnlineChannel = 1005,
	MobileLending = 1006
}

export enum FeePayableBy {
	Applicant = 1000,
	MortgageManager = 1001,
	Broker = 1002,
	Lender = 1003
}

export enum FeePaymentTiming {
	BeforeCreditDrawdown = 1000,
	OnCreditDrawdown = 1001,
	OnEventOccurrence = 1002,
	RegularRecurring = 1003
}

export enum TemplateDocumentTemplateType {
	Internal = 1,
	External = 2,
	Linked = 3,
	DynamicPdf = 4
}

export enum InputActionType {
	SEARCH = 'Search',
	CLEAR = 'Clear',
	EDIT = 'Edit'
}

export enum DigitalServiceDetailStatus {
	Pending = 1000,
	Requested = 1001,
	Completed = 1002,
	Declined = 1003,
	Resubmitted = 1004,
	Cancelled = 1005,
	None = 1006
}

export enum ApplicationDocumentsStatus {
	Active = 1,
	Categorized = 2,
	Archived = 3,
	Rejected = 4,
	Deleted = 5
}

export enum ApplicationDocumentsAdobeIntegrationStatus {
	InProgress = 1,
	Failed = 2,
	Completed = 3
}

export enum ApplicationDocumentsGenericStatus {
	None = 1,
	Pending = 2,
	Completed = 3,
	Locked = 4,
	EmailBounced = 5,
	Expired = 6,
	Declined = 7,
	Error = 8,
	Requesting = 9,
	Cancelling = 10,
	AdobeIntegrationFailed = 11
}

export enum ApplicationSignatureStatus {
	Requested = 1,
	Completed = 2,
	Locked = 3,
	EmailBounced = 4,
	Expired = 5,
	Archived = 6,
	Declined = 7,
	Pending = 8,
	Requesting = 9,
	Error = 10,
	Cancelling = 11
}

export enum CommentLinkCommentType {
	Address = 1,
	Application = 2,
	CompanyApplicant = 3,
	ContributionFunds = 4,
	DepositAccount = 5,
	DepositAccountDetails = 6,
	ExtraFeature = 7,
	Identifier = 8,
	Insurance = 9,
	LendingGuarantee = 10,
	LoanDetails = 11,
	NeedsAnalysis = 12,
	NonRealEstateAsset = 13,
	OtherCommitment = 14,
	OtherIncome = 15,
	ProductPackage = 16,
	ProofOfIdentity = 17,
	RealEstateAsset = 18,
	RelatedCompany = 19,
	RelatedPerson = 20,
	Security = 21,
	SelfEmployed = 22,
	ServiceabilityResults = 23,
	StatementOfPosition = 24,
	TrustApplicant = 25,
	Payg = 26,
	NotEmployed = 27
}

export enum RefinanceExistingLoanOptions {
	ReducedRepayments = 1000,
	LowerInterestRate = 1001,
	GreaterFlexibility = 1002,
	ReduceNumberOfLenders = 1003,
	DissatisfiedWithOtherFinancialInstitution = 1004,
	Other = 1005
}

export enum InterestOnlyLoanOptions {
	AccommodateTemporaryReductionInIncome = 1000,
	AccommodateAnticipatedNonRecurringExpenseItem = 1001,
	ReleaseFundsForInvestmentPurposes = 1002,
	AdditionalFundsInAnOffsetFacilityForWealthCreation = 1003,
	RecommendationProvidedByIndependentFinancialAdvisor = 1004,
	TaxationOrAccountingReasons = 1005,
	PlanToConvertAnInvestmentPropertyInFuture = 1006,
	Other = 1007
}

export enum LandAreaUnits {
	Acres = 1000,
	Hectares = 1001,
	SquareMetres = 1002
}

export enum NRASConsortium {
	WallsLtd = 1000,
	AcceleratedWealthSystems = 1001,
	AffordableHousingConsultingPtyLtd = 1002,
	AffordableManagementCorporationPtyLtd = 1003,
	AspireHousingGroup = 1004,
	BrisbaneHousingCompanyLtd = 1005,
	CommunityHousingCanberraLtd = 1006,
	EthanAffordableHousing = 1007,
	ProvidenceHousingPtyLtd = 1008,
	QuantumHousingGroup = 1009,
	QueenslandAffordableHousingConsortium = 1010,
	QuestusFundsManagementPtyLtd = 1011,
	UrbanAffordableHousingCorporation = 1012,
	YaranResidentialInvestmentsPtyLtd = 1013
}

export enum PropertyModalOpeningSection {
	PROPERTY = 'Property',
	RENTAL = 'Rental',
	HOME_LOAN = 'HomeLoan'
}

export enum RefiApplicationSection {
	LoanRequirements = 1,
	Income = 2,
	Expenses = 3,
	Assets = 4,
	Liabilities = 5,
	ProductSelection = 6,
	Review = 7
}

export enum SendDocumentsTo {
	Accountant = 1000,
	All = 1001,
	Applicant = 1002,
	Branch = 1003,
	LoanWriter = 1004,
	Other = 1005,
	Solicitor = 1006,
	BankInternalTeam = 2001
}

export enum DocumentationInstructionsMethod {
	Email = 1000,
	eSign = 1001,
	Post = 1002
}

export enum ConstructionType {
	FullConstruction = 1000,
	HomeExtension = 1001,
	HouseLandPackage = 1002,
	MinorNonStructuralRepairs = 1003,
	Renovation = 1004
}

export enum LandValueEstimateBasis {
	EstimatedMarketValue = 1000,
	PurchasePrice = 1001
}

export enum TaxResidency {
	AustraliaOnly = 'No, Australia only',
	Others = 'Yes'
}

export enum TaxResidencyOptions {
	AustraliaOnly = 1000,
	Yes = 1001
}

export enum ApplicationEntityType {
	StandardResidential = 1000,
	SimpleRefi = 1001,
	SMSF = 1002,
	CommercialLoan = 1003
}

export enum ApplicationType {
	DepositAccount = 1000,
	FacilityDrawdown = 1001,
	Lease = 1002,
	Loan = 1003,
	LoanVariation = 1004,
	Overdraft = 1005,
	Prequalification = 1006,
	Unsecured = 1007
}

export enum RelatedCompanyType {
	Payg = 1,
	SelfEmployed = 2,
	Foreign = 3,
	Other = 4
}

export enum ParticipantType {
	Administrator = 1,
	Broker = 2,
	TeamLead = 3,
	CreditAssessor = 4,
	Consumer = 5,
	LoanOfficer = 6,
	MobileLender = 7,
	Manager = 8,
	SeniorAssessor = 9,
	Lawyer = 10,
	Accountant = 11,
	Advisor = 12,
	Assessor = 13,
	Valuer = 14,
	ProductManager = 15,
	SalesAssessmentOfficer = 16,
	PropertyDeveloper = 17,
	MortgageManager = 18,
	Settlements = 19,
	BusinessDevelopmentManager = 20,
	LoanWriter = 57
}

export enum TemplateDocumentStandardUse {
	ApplicationForm = 1,
	ServiceabilityOutcome = 2,
	LoanSummary = 3,
	StatementOfPosition = 4,
	Product = 5,
	Consent = 6,
	TermAndConditions = 7,
	Standard = 8,
	BrokerForm = 9,
	BrokerList = 10,
	LoanOffer = 11,
	BorrowingPower = 12,
	BrokerInterview = 13,
	SmartStatements = 14,
	LoanInsuranceCalculationQuote = 15,
	Schedule4LoanInstruction = 16,
	Compliance = 17,
	ServiceabilityCalculatorResult = 18
}

export enum DiscountCalculationMode {
	AddDiscount = 1000,
	SetTotal = 1001
}

export enum LoanSubSection {
	BORROWING = 'borrowing',
	PRODUCT = 'product',
	DISCOUNT = 'discount',
	COMMISSION = 'commission',
	RATE_ADJUSTMENTS = 'rateAdjustments',
	RATE_TO_BORROWER = 'rateToBorrower',
	LOAN_FEATURES = 'loanFeatures'
}

export enum TermType {
	InitialTerm = 1000,
	SubsequentTerm = 1001
}

export enum APIEnumList {
	TinNotProvidedReasonList = 1180,
	CountryList = 1039
}

export enum BenefitEnquiryType {
	Other = 1000,
	ProportionInAssetsPurchased = 1001,
	RepayDebtsOrOtherObligations = 1002
}

export enum CoApplicantBenefitType {
	NoSubstantialBenefit = 1000,
	NoSubstantialBenefitAndProceed = 1001,
	SubstantialBenefit = 1002
}

export enum EnumMitigantFactor {
	ApplicationReflectsChanges = 1000,
	CoApplicantsIncome = 1001,
	DownsizeOfPrincipalResidence = 1002,
	Other = 1003,
	ReducingExpenditure = 1004,
	SaleOfAssets = 1005,
	SavingsOrSuperannuation = 1006,
	SecuringAdditionalIncome = 1007,
	UsingSavings = 1008
}

export enum EnumSignificantChange {
	AnticipatedLargeExpenditure = 1000,
	EndOfContractorLossOfEmployment = 1001,
	ExtendedUnpaidLeave = 1002,
	IncreasedDebtRepaymentsForAnExistingLoan = 1003,
	LeavingEmployment = 1004,
	MedicalTreatmentOrIllness = 1005,
	Other = 1006,
	PermanentDecreaseInIncome = 1007,
	PermanentIncreaseInExpense = 1008,
	Retirement = 1009,
	TemporaryDecreaseInIncome = 1010,
	TemporaryIncreaseInExpense = 1011
}

export enum AssetToBeUsedAsType {
	Security = 1000,
	Deposit = 1001,
	None = 1002
}

export enum NonRecurringIncomeType {
	AtoCashBoosts = 1000,
	OneOffGovernmentSupplement = 1001,
	Other = 1002,
	SaleOfBusinessAsset = 1003
}

export enum ScenarioLeverIncomeType {
	Payg = 1000,
	SelfEmployed = 1001,
	Rental = 1002
}

export enum CustomerSearchCriteriaType {
	Details = 1000,
	ClientID = 1001,
	LeadID = 1002,
	AccountNo = 1003
}

export enum AccountVariations {
	AddBorrower = 1000,
	AddGuarantee = 1001,
	AddGuaranteeSecurity = 1002,
	AddGuarantor = 1003,
	AddSecurity = 1004,
	BalanceIncrease = 1005,
	ChangeAuthoritytoOperate = 1006,
	ChangeLoanTerm = 1007,
	ChangeRepaymentAccount = 1008,
	ChangeRepaymentAmount = 1009,
	ChangeRepaymentDate = 1010,
	ChangeRepaymentFrequency = 1011,
	ChangeRepaymentType = 1012,
	CloseAccount = 1013,
	InterestRateDiscount = 1014,
	LendingGuaranteeLimitDecrease = 1015,
	LendingGuaranteeLimitIncrease = 1016,
	LimitIncrease = 1017,
	ReduceBalance = 1018,
	ReduceLimit = 1019,
	ReleaseGuarantee = 1020,
	ReleaseGuaranteeSecurity = 1021,
	ReleaseGuarantor = 1022,
	ReleaseSecurity = 1023,
	RemoveBorrower = 1024,
	SplitAccount = 1025,
	SubstituteGuaranteeSecurity = 1026,
	SubstituteSecurity = 1027,
	SwitchProduct = 1028,
	UpdatePartyDetails = 1029
}

export enum PersonalisedPricing {
	Seasoned = 1000,
	StartingOut = 1001
}
// TODO
export enum OffsetAccountType {}
export enum SourceOfFundsEnum {}
export enum AccountHoldingEnum {}
export enum LiabilityTypeEnum {
	PersonalLoan = 1,
	OtherLiability = 2
}
export enum AssetTypeEnum {
	Savings = 1,
	OtherAsset = 2
}

export enum ExternalAction {
	None = 0,
	PushedToExternal = 1,
	PulledFromExternal = 2,
	NeverUpdate = 3
}
export enum ExternalActionStatus {
	Pending = 0,
	Completed = 1,
	Failed = 2
}

export enum SourceOfWealth {
	BusinessOwnership = 1000,
	BusinessSourcedIncome = 1001,
	CompanySaleorEarnings = 1002,
	Directorship = 1003,
	DivorceSettlement = 1004,
	EmploymentIncome = 1005,
	Family = 5000,
	Gift = 1006,
	GovernmentBenefit = 1007,
	Grant = 1008,
	Inheritance = 1009,
	InsuranceSettlementProceeds = 1010,
	InvestmentIncome = 1011,
	LoanInterestIncome = 5001,
	Lottery = 1012,
	Other = 1013,
	OwnerFund = 5002,
	ParentalSupport = 1014,
	Payroll = 1015,
	Pension = 1016,
	PersonalSavings = 1017,
	RentalIncome = 1018,
	Royalties = 1019,
	SaleofAsset = 1020,
	SaleofRealEstateAsset = 1021,
	Scholarship = 1022,
	SignificantOther = 1023,
	TaxRefund = 5003,
	ThirdParty = 5004,
	TopExecutive = 1024,
	TrustFund = 1025
}
