import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { DeclaredExpenseDTO, Household } from '@app/modules/typings/api';
import { EnumObject, FrequencyShort } from '@simpology/client-components/utils';
import { HEMRangeResponse, HEMRangeSummary } from '../services/expenses/refi-expenses.service';

export class RefiExpensesTransformer {
	static fromPayload(
		data: DeclaredExpenseDTO[],
		household: Household,
		expenseCategories: EnumObject[],
		ownerOccupied: boolean,
		insuranceRequired: boolean,
		hemRangeSummary: HEMRangeSummary
	): RefiExpensesModel[] {
		const values: RefiExpensesModel = {
			household: {
				id: household.id,
				name: household.name,
				applicationId: household.applicationId,
				numberOfAdults: household.numberOfAdults,
				numberOfDependants: household.numberOfDependants,
				hasSchoolAgeDependants: getNullableYesNo(household.hasSchoolAgeDependants)
			},
			summaryAmount: '',
			ownerOccupied: ownerOccupied,
			insuranceRequired: insuranceRequired,
			HEMRanges: hemRangeSummary.expenseRangesByCategory,
			totalHEMCeiling: hemRangeSummary.totalCeiling,
			totalHEMFloor: hemRangeSummary.totalFloor
		};
		expenseCategories.forEach((expenseCategory) => {
			const expense = {
				[`expenseCategory-${expenseCategory.id}`]: {
					amount: data.find((x) => x.expenseCategory === expenseCategory.id)?.amount,
					frequency: data.find((x) => x.expenseCategory === expenseCategory.id)?.frequency || FrequencyShort.Monthly,
					id: data.find((x) => x.expenseCategory === expenseCategory.id)?.id || CONSTANTS.NEW_ID,
					floorAmount: hemRangeSummary.expenseRangesByCategory.find((item) => item.categoryId === expenseCategory.id)
						?.floorAmount,
					ceilingAmount: hemRangeSummary.expenseRangesByCategory.find((item) => item.categoryId === expenseCategory.id)
						?.ceilingAmount
				}
			};
			Object.assign(values, expense);
		});
		return [values];
	}

	static toHouseholdPayload(household: RefiHousehold): Household {
		return {
			id: household.id,
			name: household.name,
			applicationId: household.applicationId,
			numberOfAdults: household.numberOfAdults,
			numberOfDependants: household.numberOfDependants,
			hasSchoolAgeDependants: getNullableBoolean(household.hasSchoolAgeDependants)
		};
	}
}

export interface RefiExpensesModel {
	[key: string]: RefiExpenseCategory | RefiHousehold | string | boolean | HEMRangeResponse[] | number;
}

export interface RefiHousehold {
	id: number;
	name?: string;
	applicationId?: number;
	numberOfAdults?: number;
	numberOfDependants?: number;
	hasSchoolAgeDependants?: YesNo;
}

export interface RefiExpenseCategory extends AmountSelect {
	id: number;
}
