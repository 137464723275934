import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'formly-nested-section-wrapper',
	templateUrl: './formly-nested-section-wrapper.component.html',
	styleUrls: ['./formly-nested-section-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyNestedSectionWrapperComponent extends FieldWrapper {
	constructor(private cdr: ChangeDetectorRef) {
		super();
		setTimeout(() => {
			this.cdr.detectChanges();
		});
	}

	toggleExpanded(event: Event): void {
		event.stopPropagation();
		this.to.expanded = !this.to.expanded;
	}
}
