import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { PrimaryPurposeRealEstateAsset, ResidentialType, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean } from '@app/modules/shared/helper/util';
import { Address, PercentOwned, PersonApplicantDTO, RefiPropertyAssetsDTO } from '@app/modules/typings/api';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiPropertyAssetsTransformer {
	static toPayload(
		property: PropertyAssetsDetails,
		applicationId: number,
		applicants: PersonApplicantDTO[]
	): RefiPropertyAssetsDTO {
		return {
			realEstateAssetId: property?.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			primaryPurpose: property.primaryPurpose,
			address: property?.address,
			estimatedValue: property?.worth,
			propertyType: property?.type,
			percentsOwned: RefiTransformerHelper.setPercentsOwned(
				applicants,
				getNullableBoolean(property.equalOwnership),
				property.percentsOwned?.flatMap((x) => x.percent || 0)
			),
			toBeUsedAsSecurity: property.toBeUsedAsSecurity
		};
	}

	static fromPayload(payload: RefiPropertyAssetsDTO, applicants: PersonApplicantDTO[]): PropertyModel {
		return {
			propertyModal: {
				id: payload.realEstateAssetId,
				owner: RefiTransformerHelper.getOwnership(payload.percentsOwned),
				primaryPurpose: payload.primaryPurpose,
				address: payload.address,
				type: payload.propertyType,
				worth: payload.estimatedValue,
				realEstateAssetId: payload.realEstateAssetId,
				toBeUsedAsSecurity: payload.toBeUsedAsSecurity,
				percentsOwned: RefiTransformerHelper.getPercentsOwned(applicants, payload.percentsOwned) || 0,
				equalOwnership:
					payload.percentsOwned && payload.percentsOwned.length > 1
						? payload.percentsOwned.some((x) => x.percent !== 100 / applicants.length)
							? YesNo.No
							: YesNo.Yes
						: payload.percentsOwned && applicants.length > 1
						? YesNo.No
						: undefined,
				readonly: payload.readonly
			},
			summary: `${RefiTransformerHelper.getJointApplicantNames(applicants, payload.percentsOwned)} ${formatCurrency(
				payload.estimatedValue || 0,
				'en-AU',
				'$'
			)}, ${payload.address?.unformattedAddress}`
		};
	}
}

export interface PropertyAssetsDetails {
	id?: number;
	owner?: number;
	primaryPurpose?: PrimaryPurposeRealEstateAsset;
	type?: ResidentialType;
	address?: Address;
	worth?: number;
	realEstateAssetId?: number;
	toBeUsedAsSecurity: boolean;
	equalOwnership?: YesNo;
	percentsOwned?: PercentOwned[];
	readonly?: boolean;
}

export interface PropertyModel {
	propertyModal: PropertyAssetsDetails;
	summary: string;
}
