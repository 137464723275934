import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { FormDataService } from '../store/form-data/form-data.service';
import { FormAreaPath } from '../typings/form-areas.types';

@Injectable({ providedIn: 'root' })
export class ApplicantsGuard implements CanActivate {
	constructor(private router: Router, private formDataQuery: FormDataService) {}

	public canActivate(): boolean {
		if (this.formDataQuery.hasApplicants()) {
			return true;
		} else {
			void this.router.navigateByUrl(FormAreaPath.setup);
			return false;
		}
	}
}
