import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormMetaDataQuery } from '@app/modules/shared/store/form-metadata/form-metadata.query';
import { FormMetaDataService } from '@app/modules/shared/store/form-metadata/form-metadata.service';
import { FormAreas } from '@app/modules/shared/typings/form-areas.types';
import { formlyGenerateLabels } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyLabels } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicantBasicInfo } from '../../models/applicant-basic-info.model';

@Component({
	selector: 'confirm-consent-initiation',
	templateUrl: './confirm-consent-initiation.component.html',
	styleUrls: ['./confirm-consent-initiation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmConsentInitiationComponent implements OnInit, OnDestroy {
	labels: FormlyLabels = {};
	confirmConsentForm: UntypedFormGroup;
	applicants: ApplicantBasicInfo[] = [];

	private destroy$: Subject<void> = new Subject();

	constructor(
		private formMetaDataService: FormMetaDataService,
		private formDataService: FormDataService,
		private formMetaDataQuery: FormMetaDataQuery,
		public activeModal: NgbActiveModal,
		private cdr: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder
	) {
		this.confirmConsentForm = this.formBuilder.group({
			confirmDetails: null
		});
	}

	public get confirmDetails(): UntypedFormControl {
		return this.confirmConsentForm.get('confirmDetails') as UntypedFormControl;
	}

	ngOnInit(): void {
		this.applicants = this.formDataService.getConsentNonInitiatedApplicants();
		this.formMetaDataService.fetch(FormAreas.consentInitiation);
		this.formMetaDataQuery
			.selectArea$(FormAreas.consentInitiation)
			.pipe(takeUntil(this.destroy$))
			.subscribe((formFields) => {
				if (formFields?.length) {
					this.labels = formlyGenerateLabels(formFields[0], 'consentInitiatiationLabels');
				}
				this.cdr.detectChanges();

				if (this.applicants.length === 0) {
					this.activeModal.close(false);
				}
			});
	}

	close(): void {
		this.activeModal.close(false);
	}

	submit(): void {
		this.activeModal.close(true);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
