import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { get } from 'lodash-es';
import { Observable, switchMap } from 'rxjs';
import { JourneyType } from '../enums/app.enums';
import { LoanappXAuthenticationService } from '../service/loanappx-authentication.service';
import { SubJourneyService } from '../service/sub-journeys.service';

@Injectable({ providedIn: 'root' })
export class AnonymousAuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private loanappXAuthenticationService: LoanappXAuthenticationService,
		private subJourneyService: SubJourneyService
	) {}

	public canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
		const journeyType = get(snapshot, 'data.journeyType') as unknown as JourneyType;
		if (this.authService.isAuthenticated()) {
			return this.subJourneyService.validateJourney$(journeyType);
		} else {
			return this.loanappXAuthenticationService
				.getAnonymousAuthToken()
				.pipe(switchMap(() => this.subJourneyService.validateJourney$(journeyType)));
		}
	}
}
