<div class="loanapp-x">
	<div class="loanapp-x__header">
		<header class="app-header">
			<div class="app-header__contents simp-padding-right-small">
				<div class="d-flex align-items-center simp-padding-left-small">
					<img class="app-header__brand-logo simp-margin-right-small" [src]="brandUrl$ | async" [alt]="brandName" />
					<p class="simp-h6 simp-text--bold">Home loan application</p>
				</div>
			</div>
		</header>
	</div>
	<main class="loanapp-x__body">
		<div class="mobile-section">
			<div class="mobile-section__container">
				<div class="mobile-section__section-title col-md-6">
					<div class="d-flex align-items-center">
						<img src="assets/brands/cba/images/error.svg" alt="" class="w-100" />
					</div>
				</div>

				<div class="mobile-section__form-container col-md-6 simp-margin-top-small">
					<div class="mobile-section__form">
						<h3 class="simp-h3 simp-text--primary simp-text--bold mobile-section__title--mobile">
							Something's gone wrong
						</h3>
						<h1 class="simp-h1 simp-text--primary simp-text--bold mobile-section__title--desktop">
							Something's gone wrong
						</h1>
						<p class="simp-margin-top-large">You were trying to reach a page that does not exist.</p>
						<p class="simp-margin-top-small simp-margin-bottom-large">
							Try logging on to NetBank, and click Offers & apply.
						</p>
						<button type="button" class="btn btn-lg simp-button btn-secondary col-12" (click)="launchNetBank()">
							Back to NetBank
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>
