import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { getBoolean } from '@app/modules/shared/helper/util';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-checkbox',
	templateUrl: './formly-checkbox.component.html',
	styleUrls: ['./formly-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyCheckboxComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);

		if (!isNil(this.to.defaultValue)) {
			if (isNil(this.formControl.value) || this.to.disabled) {
				this.formControl.setValue(getBoolean(this.to.defaultValue as string | boolean | number));
			}
		}
		if (this.to.required) {
			this.formControl.setValidators(Validators.requiredTrue);
		}
	}

	checkboxChange() {
		if (this.to?.click) {
			this.to?.click(this.field);
		}
	}
}
