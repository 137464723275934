import { UntypedFormArray } from '@angular/forms';
import { SimpProgressBarStatus } from '@simpology/client-components';
import {
	CalculationHelper,
	EnumObject,
	FrequencyFull,
	FrequencyFullLabel,
	FrequencyShort
} from '@simpology/client-components/utils';
import { floor, get, round } from 'lodash-es';
import { CONSTANTS } from '../constants/constants';
import {
	ApplicantRole,
	ApplicantType,
	LendingGuaranteeType,
	RepaymentFrequency,
	StepStatus,
	YesNo,
	YesNoNotApplicable
} from '../enums/app.enums';
import { IncomeHeaderSummary, LiabilitiesHeaderSummary } from '../model/header-summary.model';
import { FormAreaLabel, FormAreaPath, FormAreas, FormSubAreas } from '../typings/form-areas.types';

export const calculateAgeFromDateOfBirth = (dob: string) => {
	const currentDate = new Date();
	const dobDate = new Date(dob);
	return Math.floor((+currentDate - +dobDate) / (1000 * 60 * 60 * 24 * 365.2425));
};

export const getMonthAndYearDifference = (start: string, end: string): string => {
	const from = new Date(start);
	const to = end ? new Date(end) : new Date();
	const diff = new Date(to.getTime() - from.getTime());

	const years = diff.getUTCFullYear() - 1970;
	const months = diff.getUTCMonth();
	let result = '';
	if (years) {
		result += years == 1 ? `${years} year ` : `${years} years `;
	}
	if (months) {
		result += months == 1 ? `${months} month` : `${months} months`;
	}
	if (!years && !months) {
		result += 'Less than a month';
	}
	return result;
};
export const frequencyToEnumObject = (id?: number): EnumObject | null => {
	const label = id ? FrequencyFullLabel.get(id) || '' : '';
	return id ? { id, label } : null;
};

export const isFutureDate = (date?: string): boolean => {
	return !date || new Date(date) > new Date();
};

export const isValidDateFormat = (dateString: string): boolean => {
	//check if dateString is in the format of DD/MM/YYYY
	const pattern = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(\d{4})$/;
	return pattern.test(dateString);
};

export const isNullOrUndefined = (prop: unknown): boolean => {
	return prop === null || prop === undefined;
};

export const hasValue = (prop: unknown): boolean => {
	return !isNullOrUndefined(prop);
};

// calculate with precision
export const calculateMonthly = (
	amount: number | undefined,
	frequency: FrequencyShort | FrequencyFull | undefined,
	precision = 2,
	startDate?: string,
	endDate?: string
): number => {
	if (amount && frequency) {
		let monthly =
			frequency !== FrequencyShort.Monthly ? CalculationHelper.CalculateMonthlyAmount(amount, frequency) : amount;
		if (frequency === FrequencyShort.YearToDate && startDate && endDate) {
			const days = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000 / 60 / 60 / 24); // Need to round down to the nearest day TAMA5-10512
			monthly = ((amount / days) * 365.25) / 12; // see comment in https://simpology.atlassian.net/browse/TAMA5-8720 for calculation
		}

		return floor(monthly, precision);
	}
	return 0;
};

/**
 * Converts number and frequency to total number of months
 * @param number
 * @param frequency
 * @returns
 */
export const getTotalMonths = (number?: number, frequency?: FrequencyFull | FrequencyShort): number => {
	if (!number) {
		return 0;
	}
	switch (frequency) {
		case FrequencyFull.Fortnightly:
			return Math.floor(number / 2); // Assuming there are 2 fortnights in a month
		case FrequencyFull.Monthly:
			return number;
		case FrequencyFull.Weekly:
			return Math.floor(number * 4.34524); // Assuming 4.34524 weeks in a month
		case FrequencyFull.Yearly:
			return number * 12;
		case FrequencyFull.Quarterly:
			return number * 3;
		case FrequencyFull.HalfYearly:
			return number * 6;
		default:
			throw new Error('Invalid FrequencyFull');
	}
};

export const addDaysToDate = (date: string, days: number): string => {
	if (!date) {
		return '';
	}
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result.toLocaleDateString('sv-SE');
};

export const calculateYearly = (
	amount: number | undefined,
	frequency: FrequencyShort | FrequencyFull | undefined,
	precision = 2
): number => {
	if (amount && frequency) {
		const yearly =
			frequency !== FrequencyShort.Yearly ? CalculationHelper.CalculateAnnualAmount(amount, frequency) : amount;
		return floor(yearly, precision);
	}
	return 0;
};

export const repaymentToFrequencyFull = (frequency: RepaymentFrequency): FrequencyFull => {
	const enumString = RepaymentFrequency[frequency];
	return get(FrequencyFull, enumString) as FrequencyFull;
};

export const parseArea = (area: string): FormAreaPath | null => {
	if (!area) {
		return null;
	}
	const urlPath = area.split('?')[0];
	const options: string[] = Object.values(FormAreaPath).map((key) => key.toLowerCase());
	return options.includes(urlPath) ? (urlPath as FormAreaPath) : null;
};

/**
 * parse boolean or null to YesNo
 * Used in transforming payload to model
 * @param yes
 * @returns YesNo | undefined
 */
export const getNullableYesNo = (yes: boolean | undefined): YesNo | undefined => {
	if (isNullOrUndefined(yes)) {
		return undefined;
	}
	return yes ? YesNo.Yes : YesNo.No;
};

/**
 * parse boolean or null to YesNoNotApplicable
 * Used in transforming payload to model
 * @param yesOrNo
 * @returns YesNoNotApplicable | undefined
 */
export const getNullableYesNoNotApplicable = (yesOrNo: boolean | undefined): YesNoNotApplicable | undefined => {
	switch (yesOrNo) {
		case false:
			return YesNoNotApplicable.No;
		case true:
			return YesNoNotApplicable.Yes;
		case null:
			return YesNoNotApplicable.NotApplicable;
		default:
			return undefined;
	}
};

export const getNotNullBoolean = (yesNoEnum: YesNo | undefined): boolean => {
	return yesNoEnum === YesNo.Yes;
};

export const getNullableBoolean = (
	yesNoEnum: YesNo | YesNoNotApplicable | boolean | undefined
): boolean | undefined => {
	if (isNullOrUndefined(yesNoEnum)) {
		return undefined;
	}
	switch (yesNoEnum) {
		case YesNo.No:
		case YesNoNotApplicable.No:
			return false;
		case YesNo.Yes:
		case YesNoNotApplicable.Yes:
			return true;
		default:
			return undefined;
	}
};

export const getNullableNumber = (value: number | string | undefined) => {
	if (isNullOrUndefined(value) || value === '') {
		return undefined;
	}
	return Number(value);
};

export const convertZeroToUndefined = (value: unknown) => {
	if (value === 0 || value === '0' || value === 0.0) {
		return undefined;
	}
	return value;
};

export const parseYesNo = (input: unknown): YesNo | undefined => {
	if (typeof input === 'string') {
		input = input.toLowerCase();
	}
	switch (input) {
		case null:
		case undefined:
			return undefined;
		case 'yes':
		case 'true':
		case true:
		case 1:
		case '1':
			return YesNo.Yes;
		case 'no':
		case 'false':
		case false:
		case 0:
		case '0':
			return YesNo.No;

		default:
			return undefined;
	}
};

export const getBoolean = (value: string | boolean | number) => {
	switch (value) {
		case true:
		case 'true':
		case 'True':
			return true;
		case false:
		case 'false':
		case 'False':
			return false;
		default:
			return isNaN(value as unknown as number) ? value : Number(value);
	}
};

export const extractSubSection = (key: string): string => {
	const pathValue = Object.values(FormAreaPath).find((path) => checkKeyIncludePath(key, path));
	if (pathValue) {
		const area = formAreaPathToArea(pathValue);
		const regex = new RegExp(`${area}-`, 'i');
		const result = key.replace(regex, '');
		return result;
	}

	return '';
};

const checkKeyIncludePath = (key: string, path: string) => {
	const area = getStandardizedAreaPath(path);
	return key.toLowerCase().startsWith(`${area}-`);
};

export const formatPlural = (number: number, singularString: string, pluralString?: string) => {
	return `${number === 1 ? singularString : pluralString ? pluralString : `${singularString}s`}`;
};

export const roundNumber = (input?: number): number => round(input || 0, 2);
export const truncateDecimalsAndFormat = (value: number, decimals: number): string =>
	(Math.trunc(value * 100) / 100).toFixed(decimals);

const getStandardizedAreaPath = (formAreaPath: string): string => {
	return formAreaPath.replace(/\//g, '').toLowerCase();
};

export const formAreaPathToArea = (area: FormAreaPath): FormAreas | FormSubAreas | null => {
	switch (area) {
		case FormAreaPath.setup:
			return FormAreas.setup;
		case FormAreaPath.applicants:
			return FormAreas.applicants;
		case FormAreaPath.companyApplicants:
			return FormAreas.companyApplicants;
		case FormAreaPath.trustApplicants:
			return FormAreas.trustApplicants;
		case FormAreaPath.financialPosition:
			return FormAreas.financialPosition;
		case FormAreaPath.trustFinancialPosition:
			return FormAreas.trustFinancialPosition;
		case FormAreaPath.companyFinancialPosition:
			return FormAreas.companyFinancialPosition;
		case FormAreaPath.loanServiceability:
			return FormAreas.loanServiceability;
		case FormAreaPath.serviceability:
			return FormSubAreas.serviceability;
		case FormAreaPath.compliance:
			return FormAreas.compliance;
		case FormAreaPath.complianceGuarantor:
			return FormAreas.complianceGuarantor;
		case FormAreaPath.summaryLodgement:
			return FormAreas.summaryLodgement;
		default:
			return null;
	}
};

export const formAreaToAreaPath = (area: FormAreas): FormAreaPath | null => {
	switch (area) {
		case FormAreas.setup:
			return null;
		case FormAreas.applicants:
			return FormAreaPath.applicants;
		case FormAreas.financialPosition:
			return FormAreaPath.financialPosition;
		case FormAreas.loanServiceability:
			return FormAreaPath.loanServiceability;
		case FormAreas.serviceabilityScenarios:
			return FormAreaPath.serviceabilityScenarios;
		case FormAreas.compliance:
			return FormAreaPath.compliance;
		case FormAreas.complianceGuarantor:
			return FormAreaPath.complianceGuarantor;
		case FormAreas.summaryLodgement:
			return FormAreaPath.summaryLodgement;
		default:
			return null;
	}
};

export const formAreaToLabel = (area: FormAreas): FormAreaLabel | null => {
	switch (area) {
		case FormAreas.setup:
			return FormAreaLabel.setup;
		case FormAreas.applicants:
			return FormAreaLabel.applicants;
		case FormAreas.financialPosition:
			return FormAreaLabel.financialPosition;
		case FormAreas.loanServiceability:
			return FormAreaLabel.loanServiceability;
		case FormAreas.serviceabilityScenarios:
			return FormAreaLabel.serviceabilityScenarios;
		case FormAreas.compliance:
			return FormAreaLabel.compliance;
		case FormAreas.complianceGuarantor:
			return FormAreaLabel.complianceGuarantor;
		case FormAreas.summaryLodgement:
			return FormAreaLabel.summaryLodgement;
		default:
			return null;
	}
};

export const defaultArray = <T = unknown>(data: T[]) => (data?.length ? data : [{}]);

export const savedSuccessfullyMessage = (item: string): string => {
	return `${item} saved successfully`;
};

export const deletedSuccessfullyMessage = (item: string): string => {
	return `${item} deleted successfully`;
};

export const hasDuplicates = (items: (number | undefined)[]) => {
	if (items.length === 0) return false;
	items = items.filter((item) => item !== undefined);
	return new Set(items.filter((item) => item !== undefined)).size !== items.length;
};

// Get the applicant role by primary status and applicant type
export const getApplicantRole = (
	primaryApplicant: boolean,
	applicantType: ApplicantType,
	lendingGuaranteeType?: LendingGuaranteeType,
	applicantTypeOptions?: EnumObject[]
): number => {
	if (primaryApplicant && applicantType === ApplicantType.Borrower) {
		return ApplicantRole.PrimaryBorrower;
	} else if (
		!primaryApplicant &&
		[ApplicantType.Borrower, ApplicantType.BorrowerandGuarantor].includes(applicantType)
	) {
		return ApplicantRole.Coborrower;
	} else if (applicantType === ApplicantType.Guarantor) {
		let lendingSpecificRoleId: ApplicantRole | undefined;
		switch (lendingGuaranteeType) {
			case LendingGuaranteeType.IncomeAndSecurity:
				lendingSpecificRoleId = ApplicantRole.GuarantorIncomeAndSecurity;
				break;
			case LendingGuaranteeType.IncomeOnly:
				lendingSpecificRoleId = ApplicantRole.GuarantorIncomeOnly;
				break;
			case LendingGuaranteeType.SecurityOnly:
				lendingSpecificRoleId = ApplicantRole.GuarantorSecurityOnly;
				break;
		}
		if (lendingSpecificRoleId && applicantTypeOptions?.find((option) => option.id === lendingSpecificRoleId)) {
			return lendingSpecificRoleId;
		}
		return ApplicantRole.Guarantor;
	} else {
		return ApplicantRole.PrimaryBorrower;
	}
};

export const applicantRoleToApplicantType = (role: number): number => {
	if (role === ApplicantRole.PrimaryBorrower || role === ApplicantRole.Coborrower) {
		return ApplicantType.Borrower;
	} else if (
		[
			ApplicantRole.Guarantor,
			ApplicantRole.GuarantorIncomeAndSecurity,
			ApplicantRole.GuarantorIncomeOnly,
			ApplicantRole.GuarantorSecurityOnly
		].includes(role)
	) {
		return ApplicantType.Guarantor;
	} else {
		return ApplicantType.Borrower;
	}
};

export const applicantRoleToApplicantPrimary = (role: number): boolean => {
	if (role === ApplicantRole.PrimaryBorrower) {
		return true;
	} else {
		return false;
	}
};

export const calculateDays = (startDate: string, endDate: string): number => {
	const toStartDate = new Date(startDate);
	const toEndDate = new Date(endDate);
	return (toEndDate.getTime() - toStartDate.getTime()) / (1000 * 3600 * 24);
};

//Calculate human readable file size
export const humanReadableFileSize = (bytes: number): string => {
	if (bytes < 1024) return `${bytes} B`;
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	let num = bytes / Math.pow(1024, i);
	const roundNum = Math.round(num);
	num = roundNum < 10 ? parseFloat(num.toFixed(2)) : roundNum < 100 ? parseFloat(num.toFixed(1)) : roundNum;
	return `${num} ${'KMGTPEZY'[i - 1]}B`;
};

export const getFinancialYear = (previous?: boolean): string => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth() + 1;
	let currentYear = parseInt(currentDate.getFullYear().toString().substring(2, 4));
	let previousYear = currentYear - 1;
	let nextYear = currentYear + 1;
	if (previous) {
		previousYear = previousYear - 1;
		currentYear = currentYear - 1;
		nextYear = nextYear - 1;
	}
	if (currentMonth <= 6) {
		return `${previousYear}/${currentYear}`;
	} else {
		return `${currentYear}/${nextYear}`;
	}
};
export const getFinancialYearStartDate = (financialYear: number): string => {
	if (financialYear < 1) {
		return '';
	}
	return `${financialYear}-07-01`;
};

export const getFinancialYearEndDate = (financialYear: number): string => {
	if (financialYear < 1) {
		return '';
	}
	return `${financialYear + 1}-06-30`;
};

export const hasInvalidField = (formGroup: UntypedFormArray) => {
	return !!Object.values(formGroup.controls)
		.map((control) => {
			const errors = Object.keys(control.errors ?? {});
			return errors;
		})
		.filter((errors) => errors.filter((error) => error !== 'required').length).length;
};

export const getIncomeHeader = (incomes: IncomeHeaderSummary[], enableTooltip?: boolean) => {
	let netIncome = 0;
	let grossIncome = 0;

	incomes.forEach((header) => {
		netIncome += header?.totalNetAmount ?? 0;
		grossIncome += header?.totalGrossAmount ?? 0;
	});

	return {
		totalNetAmount: netIncome,
		totalGrossAmount: grossIncome,
		showTooltip: enableTooltip && netIncome <= 0 ? true : false
	};
};

export const getLiabilitiesHeader = (liabilities: LiabilitiesHeaderSummary[]) => {
	let totalOutstandingBalance = 0;
	let totalCreditLimit = 0;
	let totalRepayment = 0;

	liabilities.forEach((header) => {
		totalOutstandingBalance += header?.totalOutstandingBalance ?? 0;
		totalCreditLimit += header?.totalCreditLimit ?? 0;
		totalRepayment += header?.totalRepayment ?? 0;
	});

	return {
		totalOutstandingBalance: totalOutstandingBalance,
		totalCreditLimit: totalCreditLimit,
		totalRepayment: totalRepayment
	};
};

export const camelCaseToWords = (s: string) => {
	const result = s.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
	return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const camelCaseToHyphen = (s: string) => {
	return s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const toLowercaseFirstChar = (s: string) => {
	return s.charAt(0).toLowerCase() + s.slice(1);
};

export const hyphenToCamelCase = (s: string) => {
	return s.replace(/-([a-z])/g, (match, group) => (group as string).toUpperCase());
};

export const getCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop()!.split(';').shift();
	}
	return undefined;
};

export const passDurationAndFrequency = (
	months?: number
): { amount: number; frequency: FrequencyShort } | undefined => {
	if (months === undefined || months === null || months < 0) {
		return;
	}
	const output = {
		amount: months,
		frequency: FrequencyShort.Monthly
	};

	if (months % 12 === 0) {
		output.amount = months / 12;
		output.frequency = FrequencyShort.Yearly;
	}
	return output;
};

export const getYearsAndMonthsText = (months: number): string => {
	const convertedYears = Math.floor(months / 12);
	const convertedMonths = months % 12;

	const yearText = convertedYears > 0 ? `${convertedYears} ${formatPlural(convertedYears, 'year')}` : '';
	const monthText = convertedMonths > 0 ? `${convertedMonths} ${formatPlural(convertedMonths, 'month')}` : '';

	return `${yearText} ${yearText !== '' && monthText !== '' ? 'and' : ''} ${monthText}`;
};

export const stepToSimpProgress = (status?: StepStatus): SimpProgressBarStatus | undefined => {
	switch (status) {
		case StepStatus.Complete:
			return SimpProgressBarStatus.Complete;
		case StepStatus.Incomplete:
			return SimpProgressBarStatus.NotStarted;
		default:
			return undefined;
	}
};

export const getSharePercentValidationError = (model: { percent?: number }[], min?: number, max?: number): string => {
	let errorKey = '';

	const total = model.map((percent) => (percent?.percent ? percent.percent : 0)).reduce((prev, next) => prev + next, 0);

	if (min && max && min === max && total !== max) {
		errorKey = CONSTANTS.ERROR_INVALID;
	}
	if (!errorKey && min != undefined && ((min > 0 && total < min) || (min === 0 && total <= 0))) {
		errorKey = CONSTANTS.ERROR_MIN;
	}

	const isGreater = max && total > max;

	if (!errorKey && max && isGreater) {
		errorKey = CONSTANTS.ERROR_MAX;
	}

	return errorKey;
};
export const isTotalEqualShared = (participants: number, total: number): boolean => {
	const percentageEqualPart = getNumberWithFirstTwoDecimal(100 / participants);

	return getNumberWithFirstTwoDecimal(percentageEqualPart * participants) === total ? true : false;
};

export const getNumberWithFirstTwoDecimal = (number: number): number => {
	return Math.trunc(number * 100) / 100;
};
export const getLatestFinancialYear = (financialYears?: EnumObject[]): number => {
	return !financialYears?.length ? 0 : Math.max(...financialYears.map((x) => x.id));
};
