import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import {
	ApplicationDocumentsAdobeIntegrationStatus,
	ApplicationDocumentsStatus,
	TemplateDocumentStandardUse
} from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import {
	IndividualDocument,
	TemplateDocumentDetail
} from '@app/modules/summary-lodgement/models/template-document.model';
import { DocumentFileModel } from '@app/modules/typings/document-checklist-template-details-dto';
import { FingerprintService } from './finger-print.service';

@Injectable({
	providedIn: 'root'
})
export class ApplicationNonLinkedDocumentsService extends BaseJourneyService<any> {
	constructor(private applicationDataQuery: ApplicationDataQuery, private fingerprintService: FingerprintService) {
		super();
		this.setRoute(``);
	}

	generateAndDownloadDocument(templateDocument: TemplateDocumentDetail): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/${URL_CONSTANTS.DOCUMENT_GENERATION}/GenerateAndDownload/${this.applicationDataQuery.applicationId()}/${
				templateDocument.id
			}`
		);
	}

	generateAndDownloadByStandardUse(standardUse: TemplateDocumentStandardUse): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/${
				URL_CONSTANTS.DOCUMENT_GENERATION
			}/GenerateAndDownloadByStandardUse/${this.applicationDataQuery.applicationId()}/${standardUse}`
		);
	}

	getCurrentStatus(templateDocument: TemplateDocumentDetail): Observable<{
		status: ApplicationDocumentsStatus | undefined;
		adobeIntegrationStatus?: ApplicationDocumentsAdobeIntegrationStatus;
		createdDate?: Date;
	}> {
		return <
			Observable<{
				status: ApplicationDocumentsStatus | undefined;
				adobeIntegrationStatus?: ApplicationDocumentsAdobeIntegrationStatus;
				createdDate?: Date;
			}>
		>this.getCustom(`/${URL_CONSTANTS.DOCUMENT_GENERATION}/GetCurrentStatus/${this.applicationDataQuery.applicationId()}/${templateDocument.id}`);
	}

	generateForLoanapp(templateDocument: TemplateDocumentDetail): Observable<any> {
		return this.postCustom(
			`/${URL_CONSTANTS.DOCUMENT_GENERATION}/GenerateForLoanapp/${this.applicationDataQuery.applicationId()}/${
				templateDocument.id
			}`,
			{}
		);
	}

	downloadForPerson(templateDocument: TemplateDocumentDetail, personId: number): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/${URL_CONSTANTS.DOCUMENT_GENERATION}/Download/${this.applicationDataQuery.applicationId()}/${
				templateDocument.id
			}?personId=${personId}`
		);
	}

	download(templateDocument: TemplateDocumentDetail): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/${URL_CONSTANTS.DOCUMENT_GENERATION}/Download/${this.applicationDataQuery.applicationId()}/${
				templateDocument.id
			}`
		);
	}

	downloadIndividualDoc(doc: IndividualDocument): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/${URL_CONSTANTS.DOCUMENT_GENERATION}/Download/${this.applicationDataQuery.applicationId()}/${
				doc.templateId
			}?personId=${doc.personId}`
		);
	}

	downloadCdpReport(): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/Loanapp/CdpReportGeneration/DownloadCdpReport/${this.applicationDataQuery.applicationId()}`
		);
	}

	deleteFile(applicationDocumentId: number, personId: number, documentName: string): Observable<boolean> {
		return this.delete(
			`/Loanapp/Document/DeleteFile/${this.applicationDataQuery.applicationId()}/${applicationDocumentId}/${personId}/${documentName}`
		).pipe(map(() => true));
	}

	deleteIdentityDocument(personId: number, documentName: string, proofOfIdentityId: number): Observable<boolean> {
		return this.delete(
			`/Loanapp/Document/DeleteIdentityDoc/${this.applicationDataQuery.applicationId()}/${personId}/${documentName}/${proofOfIdentityId}`
		).pipe(map(() => true));
	}

	getActiveGeneratedDocuments(): Observable<DocumentFileModel[]> {
		return <Observable<DocumentFileModel[]>>(
			this.getCustom(
				`/${URL_CONSTANTS.DOCUMENT_GENERATION}/GetActiveGeneratedDocuments/${this.applicationDataQuery.applicationId()}`
			)
		);
	}

	getIndividualDocuments(templateId: number): Observable<IndividualDocument[]> {
		return <Observable<IndividualDocument[]>>(
			this.getCustom(
				`/${
					URL_CONSTANTS.DOCUMENT_GENERATION
				}/GetIndividualGeneratedDocuments/${this.applicationDataQuery.applicationId()}/${templateId}`
			)
		);
	}

	checkDocsHasBeenGeneratedForFingerprint(): Observable<{
		isServiceActive: boolean;
		applicationHasNotBeenChanged: boolean | undefined;
		docsHasBeenGeneratedForLatestFingerprint: boolean | undefined;
	}> {
		return this.fingerprintService.registerCurrentApplicationForFingerprintCheck().pipe(
			switchMap((documentStatus: { isServiceActive: boolean; applicationHasNotBeenChanged: boolean | undefined }) => {
				if (!documentStatus?.isServiceActive) {
					return of({
						...documentStatus,
						docsHasBeenGeneratedForLatestFingerprint: undefined
					});
				}

				return this.checkIfApplicationDocumentsHaveBeenGenerated().pipe(
					map((docsHasBeenGeneratedForLatestFingerprint) => ({
						...documentStatus,
						docsHasBeenGeneratedForLatestFingerprint: docsHasBeenGeneratedForLatestFingerprint
					}))
				);
			})
		);
	}

	archiveNonLinkedDocuments(templateDocIds: Array<TemplateDocumentDetail>): Observable<void> {
		return <Observable<void>>this.postCustom(`${URL_CONSTANTS.DOCUMENT_GENERATION}/ArchiveDocuments`, {
			ladmApplicationId: this.applicationDataQuery.applicationId(),
			documentTemplateIds: templateDocIds.map((templateDoc) => templateDoc.id)
		});
	}

	downloadPrintForm(scenarioId: number): Observable<ArrayBuffer> {
		return this.getAsArrayBuffer(
			`/Loanapp/DocumentGeneration/GenerateAndDownloadByScenarioId/${this.applicationDataQuery.applicationId()}/${scenarioId}`
		);
	}

	private checkIfApplicationDocumentsHaveBeenGenerated(): Observable<boolean | undefined> {
		return <Observable<boolean | undefined>>(
			this.getCustom(
				`/${
					URL_CONSTANTS.DOCUMENT_GENERATION
				}/CheckIfApplicationDocumentsHaveBeenGenerated/${this.applicationDataQuery.applicationId()}`
			)
		);
	}
}
