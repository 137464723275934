import { AdditionalCommentsCommentDTO, AdditionalCommentsDTO } from '@app/modules/typings/api';
import { convertUTCToLocalDate, formatDate } from '../../shared/helper/date.helper';

export interface AdditionalCommentsModel {
	lenderNameId?: number;
	lodgementDate?: string;
	comments: AdditionalCommentsCommentModel[];
	attestationConfirmationDate?: string;
}

export interface AdditionalCommentsCommentModel {
	id: number;
	comment: string;
	name: string;
	authorId: number;
	lastUpdated?: string;
	editting?: boolean;
	existingComment?: string;
	loggedInId?: number;
}

export class AdditionalCommentsTransformer {
	static toPayload(
		comment: AdditionalCommentsCommentModel,
		authorId: number,
		authorName: string
	): AdditionalCommentsCommentDTO {
		return {
			id: comment.id ?? -1,
			comment: comment.comment,
			authorId: comment.authorId ?? authorId,
			authorName: comment.name ?? authorName,
			timeStamp: comment.lastUpdated ?? new Date().toISOString()
		};
	}

	static fromPayload(
		comments: AdditionalCommentsDTO,
		loggedInId: number,
		lenderNameId?: number
	): AdditionalCommentsModel {
		return {
			lenderNameId: lenderNameId,
			lodgementDate: comments.lodgementDate,
			attestationConfirmationDate: comments.attestationConfirmationDate
				? formatDate(convertUTCToLocalDate(comments.attestationConfirmationDate))
				: undefined,
			comments: comments.comments.map((comment) => ({
				id: comment.id,
				comment: comment.comment,
				name: comment.authorName,
				authorId: comment.authorId,
				lastUpdated: comment.dateUpdated,
				editting: false,
				loggedInId: loggedInId
			}))
		};
	}
}
