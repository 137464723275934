import { DetailedComment } from '@app/modules/summary-lodgement/models/comment.model';
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { EmploymentModel } from '@app/modules/financial-position/model/employment.model';
import { ApplicationDetails } from '@app/modules/setup/typings/setup';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { CommentsService } from '@app/modules/summary-lodgement/services/comments.service';
import { SummaryLodgementService } from '@app/modules/summary-lodgement/summary-lodgement.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get, remove } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicantKeyType, CommentLinkCommentType } from '../enums/app.enums';
import { LoanApplicationStatus } from '../enums/client-enum.model';
import { savedSuccessfullyMessage } from '../helper/util';
import { LoanApplication } from '../model/loan-application.model';
import { ApplicationService } from '../service/application.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ChannelSettingQuery } from '../store/channel-setting/channel-setting.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { EmploymentDetailsModel } from './../../financial-position/model/employment.model';
import { LoanServiceabilitySummaryTransformerService } from './summary-lodgement/loan-serviceability-summary-transformer.service';
import { SetupSummaryTransformerService } from './summary-lodgement/setup-summary-transformer.service';

@Injectable({ providedIn: 'root' })
export class SummaryLodgementTransformerService {
	constructor(
		private setupTransformerService: SetupSummaryTransformerService,
		private loanServiceabilitySummaryTransformerService: LoanServiceabilitySummaryTransformerService,
		private applicationService: ApplicationService,
		private channelSettingQuery: ChannelSettingQuery,
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private toastr: ToastrService,
		private commentsService: CommentsService,
		private summaryLodgementService: SummaryLodgementService
	) {}

	transform(formFields: FormlyFieldConfig[] | undefined): Observable<FormlyFieldConfig[] | undefined> {
		const finalExpenseType = this.channelSettingQuery.getExpenseType();

		const expenseLineItemField = getFormField(
			(formFields?.[0].fieldArray as FormlyFieldConfig)?.fieldGroup,
			'summaryData.financialPositionSummary.expenses.lineItem'
		);

		if (expenseLineItemField && expenseLineItemField.templateOptions) {
			expenseLineItemField.templateOptions.expenseType = finalExpenseType;
		}

		const summarySection = getFormField((formFields?.[0].fieldArray as FormlyFieldConfig)?.fieldGroup, 'summaryData');
		const lodgementSection = getFormField((formFields?.[0].fieldArray as FormlyFieldConfig)?.fieldGroup, 'lodgement');
		this.saveComment(lodgementSection);
		this.setMetricsLabels(summarySection);

		this.customerSignedApplicationConfig(lodgementSection);

		if (!formFields || !summarySection) {
			return of(formFields);
		}

		if (this.channelSettingQuery.getShowSummaryView()) {
			return this.transformSummaryMetadata(summarySection, formFields);
		} else {
			return this.hideSummaryViewMetaData(summarySection, formFields);
		}
	}

	private setMetricsLabels(summarySection: FormlyFieldConfig | undefined) {
		formlyExtendExpressionProperties(
			summarySection?.fieldGroup,
			'loanAndServiceabilitySummary.serviceability.secondaryDetails.sampleMetric.value',
			{
				'templateOptions.label': (model: EnumObject) => {
					return model?.label;
				}
			}
		);
	}

	private saveComment(lodgementSection: FormlyFieldConfig | undefined) {
		formlyOnStatusChangedToValid(
			lodgementSection?.fieldGroup,
			`applicationComment`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as DetailedComment;
				this.commentsService
					.saveDetailedComment({
						...model,
						applicationId: this.applicationDataQuery.applicationId(),
						contextId: this.applicationDataQuery.applicationId(),
						commentType: CommentLinkCommentType.Application
					})
					.subscribe((comment: DetailedComment) => {
						this.simpFormlyHandlerService.updateToState(`applicationComment`, comment, event.index!);
						this.toastr.success(savedSuccessfullyMessage('Comment'));
					});
			}
		);
	}

	private customerSignedApplicationConfig(lodgementSection: FormlyFieldConfig | undefined) {
		formlyRegisterHooks(lodgementSection?.fieldGroup, `applicationReady`, {
			onInit: (field) => {
				const customerSinedApplicationField = getFormField(
					(field?.fieldArray as FormlyFieldConfig).fieldGroup,
					'customerSignedApplication'
				);
				if (customerSinedApplicationField) {
					const detailsStateData =
						this.simpFormlyHandlerService.getStateDataFullPath<ApplicationDetails>('details')?.[0];
					this.summaryLodgementService.hasCustomerSignedAppication.next(
						detailsStateData.hasLenderAcknowledgedSignedDocument
					);
				} else {
					this.summaryLodgementService.hasCustomerSignedAppication.next(undefined);
				}
			}
		});

		formlyOnClick(
			lodgementSection?.fieldGroup,
			`applicationReady.customerSignedApplication`,
			(field: FormlyFieldConfig) => {
				const hasCustomerSignedApplication = field.formControl?.value as boolean;
				this.applicationService.saveCustomerSignedApplication(hasCustomerSignedApplication).subscribe(() => {
					this.summaryLodgementService.hasCustomerSignedAppication.next(hasCustomerSignedApplication);
					const detailsStateData =
						this.simpFormlyHandlerService.getStateDataFullPath<ApplicationDetails>('details')?.[0];
					detailsStateData.hasLenderAcknowledgedSignedDocument = hasCustomerSignedApplication;
					this.simpFormlyHandlerService.updateToState('details', detailsStateData, 0);
				});
			}
		);
	}

	private transformSummaryMetadata(summarySection: FormlyFieldConfig, formFields: FormlyFieldConfig[]) {
		const setupMetadata = getFormField(summarySection?.fieldGroup, 'setupSummary');
		const loanServiceabilityMetadata = getFormField(summarySection?.fieldGroup, 'loanAndServiceabilitySummary');

		return forkJoin({
			setupSummary: this.setupTransformerService.transform(setupMetadata),
			applicantsSummary: this.injectApplicantsForApplicantPage(summarySection),
			financialPositionSummary: this.injectApplicantsForFinPosPage(summarySection),
			loanServiceabilitySummary: this.loanServiceabilitySummaryTransformerService.transform(loanServiceabilityMetadata),
			applicationInfo: this.getBasicApplicationInfo()
		}).pipe(
			map((result) => {
				//move 1st item (lodgement section) to bottom
				this.moveLodgementSectionToBottom(result.applicationInfo.statusEnum, formFields);
				return formFields;
			}),
			catchError(() => {
				return of(undefined);
			})
		);
	}

	private injectApplicantsForApplicantPage(
		summarySection: FormlyFieldConfig
	): Observable<FormlyFieldConfig | undefined> {
		const applicantsMetadata = getFormField(summarySection?.fieldGroup, 'applicantsSummary');
		if (applicantsMetadata?.fieldGroup) {
			const applicantConfigs: FormlyFieldConfig[] = [];
			this.updateApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.PersonApplicant);
			this.updateApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.CompanyApplicant);
			this.updateApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.TrustApplicant);

			applicantConfigs.forEach((applicant, applicantIndex) => {
				applicant.props!.label = `${applicantIndex + 1} - ${applicant.props!.nameForApplicant}`;

				const key = applicant.key as string;
				if (key && key.includes('companyApplicant') && applicant.templateOptions) {
					const index = key.slice(-1);
					applicant.templateOptions.route = `applicants/company?index=${index}`;
				} else if (key && key.includes('trustApplicant') && applicant.templateOptions) {
					const index = key.slice(-1);
					applicant.templateOptions.route = `applicants/trust?index=${index}`;
				}

				formlyExtendExpressionProperties(
					applicantConfigs,
					`personApplicant${applicantIndex}.employments.employmentDetails.payg.typeOfIncome.type`,
					{
						'templateOptions.label': (model: any, state: unknown, field: FormlyFieldConfig) => {
							if (field) {
								const parentEmploymentDetailsField = getParentFormField(field, 'employmentDetails');
								const parentEmploymentsField = getParentFormField(field, 'employments');
								const index = (parentEmploymentsField?.model as EmploymentModel[])?.findIndex(
									(x) => x.employmentDetails === (parentEmploymentDetailsField?.model as EmploymentDetailsModel)
								);
								return index > -1 ? `${index + 1} - Type` : 'Type';
							}
							return 'Type';
						}
					}
				);

				formlyExtendExpressionProperties(
					applicantConfigs,
					`personApplicant${applicantIndex}.employments.employmentDetails.selfEmployment.typeOfIncome.type`,
					{
						'templateOptions.label': (model: any, state: unknown, field: FormlyFieldConfig) => {
							if (field) {
								const parentEmploymentDetailsField = getParentFormField(field, 'employmentDetails');
								const parentEmploymentsField = getParentFormField(field, 'employments');
								const index = (parentEmploymentsField?.model as EmploymentModel[])?.findIndex(
									(x) => x.employmentDetails === (parentEmploymentDetailsField?.model as EmploymentDetailsModel)
								);
								return index > -1 ? `${index + 1} - Type` : 'Type';
							}
							return 'Type';
						}
					}
				);

				formlyExtendExpressionProperties(
					applicantConfigs,
					`personApplicant${applicantIndex}.employments.employmentDetails.notEmployed.typeOfIncome.type`,
					{
						'templateOptions.label': (model: any, state: unknown, field: FormlyFieldConfig) => {
							if (field) {
								const parentEmploymentDetailsField = getParentFormField(field, 'employmentDetails');
								const parentEmploymentsField = getParentFormField(field, 'employments');
								const index = (parentEmploymentsField?.model as EmploymentModel[])?.findIndex(
									(x) => x.employmentDetails === (parentEmploymentDetailsField?.model as EmploymentDetailsModel)
								);
								return index > -1 ? `${index + 1} - Type` : 'Type';
							}
							return 'Type';
						}
					}
				);

				formlyExtendExpressionProperties(
					applicantConfigs,
					`personApplicant${applicantIndex}.employments.employmentDetails.foreignEmployment.typeOfIncome.type`,
					{
						'templateOptions.label': (model: any, state: unknown, field: FormlyFieldConfig) => {
							if (field) {
								const parentEmploymentDetailsField = getParentFormField(field, 'employmentDetails');
								const parentEmploymentsField = getParentFormField(field, 'employments');
								const index = (parentEmploymentsField?.model as EmploymentModel[])?.findIndex(
									(x) => x.employmentDetails === (parentEmploymentDetailsField?.model as EmploymentDetailsModel)
								);
								return index > -1 ? `${index + 1} - Type` : 'Type';
							}
							return 'Type';
						}
					}
				);
			});
			applicantsMetadata.fieldGroup.push(...applicantConfigs);
		}
		const noPersonApplicant = this.applicationDataQuery.getPersonShortApplicants().length === 0;
		if (noPersonApplicant) {
			const householdMetadata = getFormField(summarySection?.fieldGroup, 'applicantsSummary.household');
			if (householdMetadata) {
				householdMetadata.hide = true;
			}
		}
		return of(applicantsMetadata);
	}

	private injectApplicantsForFinPosPage(summarySection: FormlyFieldConfig): Observable<FormlyFieldConfig | undefined> {
		const applicantsMetadata = getFormField(summarySection?.fieldGroup, 'financialPositionSummary');
		if (applicantsMetadata?.fieldGroup) {
			const applicantConfigs: FormlyFieldConfig[] = [];
			this.updateMergedApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.PersonApplicant);
			this.updateApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.CompanyApplicant);
			this.updateApplicantConfig(applicantsMetadata, applicantConfigs, ApplicantKeyType.TrustApplicant);

			applicantConfigs.forEach((applicant, applicantIndex) => {
				applicant.templateOptions!.label = `${applicantIndex + 1} - ${applicant.templateOptions!.name}`;
				const key = applicant.key as string;
				if (key && key.includes('companyApplicant') && applicant.templateOptions) {
					const index = key.slice(-1);
					applicant.templateOptions.route = `financial-position/company?index=${index}`;
					return;
				}
				if (key && key.includes('trustApplicant') && applicant.templateOptions) {
					const index = key.slice(-1);
					applicant.templateOptions.route = `financial-position/trust?index=${index}`;
					return;
				}
			});
			applicantsMetadata.fieldGroup.push(...applicantConfigs);
		}

		return of(applicantsMetadata);
	}

	// Financial Position area has merged data for each person applicant
	private updateMergedApplicantConfig(
		formFields: FormlyFieldConfig | undefined,
		applicantConfigs: FormlyFieldConfig[],
		applicantKeyType: ApplicantKeyType
	) {
		const applicants = this.applicationDataQuery.getApplicants(applicantKeyType);
		const applicantConfig = getFormField(formFields?.fieldGroup, applicantKeyType);

		// don't add person applicant section if no person applicant
		if (applicantKeyType === ApplicantKeyType.PersonApplicant && applicants.length === 0) {
			remove(formFields?.fieldGroup!, (field) => field.key === applicantKeyType);
			return;
		}
		const clonedApplicantConfig = cloneDeep(applicantConfig);
		clonedApplicantConfig!.templateOptions!.name = applicants.map((applicant) => applicant.name).join(', ');
		applicantConfigs.push(clonedApplicantConfig!);
		remove(formFields?.fieldGroup!, (field) => field.key === applicantKeyType);
	}

	private updateApplicantConfig(
		formFields: FormlyFieldConfig | undefined,
		applicantConfigs: FormlyFieldConfig[],
		applicantKeyType: ApplicantKeyType
	) {
		const applicants = this.applicationDataQuery.getApplicants(applicantKeyType);
		const applicantConfig = getFormField(formFields?.fieldGroup, applicantKeyType);

		applicants.map((applicant, index) => {
			const clonedApplicantConfig = cloneDeep(applicantConfig);
			const applicantType = this.formEnumsQuery.getOptionLabel('ApplicantType', applicant.applicantType as number);
			const title = this.formEnumsQuery.getOptionLabel('NameTitle', applicant.title as number);
			clonedApplicantConfig!.key = `${clonedApplicantConfig?.key}${index}`;
			clonedApplicantConfig!.props!.name = applicant.name;
			clonedApplicantConfig!.props!.nameForApplicant = `${applicantType}, ${title} ${applicant.name}`;
			applicantConfigs.push(clonedApplicantConfig!);
		});
		remove(formFields?.fieldGroup!, (field) => field.key === applicantKeyType);
	}

	private hideSummaryViewMetaData(summarySection: FormlyFieldConfig, formFields: FormlyFieldConfig[]) {
		summarySection.hide = true;
		return of(formFields);
	}

	private moveLodgementSectionToBottom(loanApplicationStatus: LoanApplicationStatus, formFields: FormlyFieldConfig[]) {
		if (loanApplicationStatus === LoanApplicationStatus.Open) {
			const fieldArray = formFields[0].fieldArray as FormlyFieldConfig;
			const fields = fieldArray.fieldGroup;
			if (fields?.length) {
				fields.push(fields.shift()!);
				fieldArray.fieldGroup = [];
				fieldArray.fieldGroup.push(...fields);
			}
		}
	}

	private getBasicApplicationInfo(): Observable<LoanApplication> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.applicationService.getBasicApplicationInfo(applicationId);
	}
}
