import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { HomeLoanIntroducerDTO } from '@app/modules/typings/api';
export class HomeLoanIntroducerTransformer {
	static toPayload(model: HomeLoanInsuranceModel, applicationId: number): HomeLoanIntroducerDTO {
		return {
			applicationId: applicationId,
			isThisLoanReferred: getNullableBoolean(model.referredByIntroducer),
			hasConfirmed: model.lenderConfirmation,
			id: model.id ?? CONSTANTS.NEW_ID
		};
	}
	static fromPayload(data: HomeLoanIntroducerDTO): HomeLoanInsuranceModel {
		return {
			referredByIntroducer: getNullableYesNo(data.isThisLoanReferred),
			lenderConfirmation: data.hasConfirmed,
			id: data.id
		};
	}
}

export interface HomeLoanInsuranceModel {
	referredByIntroducer?: YesNo;
	lenderConfirmation?: boolean;
	id?: number;
}
