import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Sample Number
 * {
		className: 'col-3',
		type: 'number',
		key: 'dependants',
		validation: {
			messages: { required: 'We need the lender application number' }
		},
		templateOptions: {
			label: 'Dependants',
			required: true,
			disabled: true
		}
	},
 */
@Component({
	selector: 'formly-number',
	templateUrl: './formly-number.component.html',
	styleUrls: ['./formly-number.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyNumberComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
	}
}
