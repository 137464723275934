import { DatePipe } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	ViewChild
} from '@angular/core';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';
import { get, isArray } from 'lodash-es';
import { map, Observable, of } from 'rxjs';

import { PhoneDetails } from '@simpology/client-components';
import { EnumLabelPipe } from '../../pipes/formly-enum-label.pipe';

@Component({
	selector: 'formly-label',
	templateUrl: './formly-label.component.html',
	styleUrls: ['./formly-label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyLabelComponent extends FieldType<FieldTypeConfig> implements AfterViewChecked {
	@ViewChild('labelText') public labelText: ElementRef = {} as ElementRef;

	toolTipData!: Model;

	constructor(
		private datePipe: DatePipe,
		private enumLabelPipe: EnumLabelPipe,
		private formatter: AggregateFormatterService,
		private cdr: ChangeDetectorRef
	) {
		super();
	}

	get data(): Model | string {
		let modelData: Model = get<string>(this.model as string, this.key as number);
		const formatter = this.field.templateOptions?.formatter as string;
		modelData = this.format(modelData, formatter);
		return modelData;
	}

	get isEllipsis(): boolean {
		const nativeElement = this.labelText.nativeElement as HTMLElement;
		return nativeElement ? nativeElement.offsetWidth < nativeElement.scrollWidth : false;
	}

	getTooltipData(): Observable<string> {
		const modelData = this.data;
		if (typeof modelData === 'string') {
			return of(modelData.replace(/<\/?br\s*\/?>/gi, ' '));
		} else if (modelData instanceof Observable) {
			return modelData.pipe(map((data) => (typeof data === 'string' ? data.replace(/<\/?br\s*\/?>/gi, ' ') : data)));
		}
		return of('');
	}

	ngAfterViewChecked(): void {
		if (this.to.tooltip !== '') {
			this.toolTipData = this.to.tooltip ? of(this.to.tooltip as string) : this.data;
		} else {
			this.toolTipData = null;
		}
		this.cdr.detectChanges();
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}

	private format(data: string | number | SimpAddress | PhoneDetails, formatter: string): Model {
		if (data === CONSTANTS.DEFAULT_STRING || isNullOrUndefined(data) || data === '') {
			return of(this.field.defaultValue ? this.field.defaultValue : CONSTANTS.DEFAULT_STRING);
		}
		switch (formatter) {
			case 'date': {
				const dates = isArray(data) ? (data as string[]) : ([data] as string[]);
				return of(dates.map((date) => this.datePipe.transform(date, 'dd/MM/yyyy') as string).join(' - '));
			}
			case 'enum': {
				return this.enumLabelPipe.transform(
					data as number,
					this.field.templateOptions?.options as unknown as string,
					true,
					'enum'
				);
			}
			case 'infoWithEnum': {
				return this.enumLabelPipe.transform(
					data as number,
					this.field.templateOptions?.options as unknown as string,
					true,
					'infoWithEnum'
				);
			}
			case 'enumWithInfo': {
				return this.enumLabelPipe.transform(
					data as number,
					this.field.templateOptions?.options as unknown as string,
					true,
					'enumWithInfo'
				);
			}
			case 'amount': {
				return of(this.formatter.formatAmount(data as number));
			}
			case 'boolean':
				return of(data ? 'Yes' : 'No');
			case 'address': {
				const address = data as SimpAddress;
				return of(get(address, 'id') ? SimpAddressHelper.buildAddressLine(address) : CONSTANTS.DEFAULT_STRING);
			}
			case 'tel': {
				const phone = data as PhoneDetails;
				return of(`${phone?.dialCode}${phone?.phoneNumber}`);
			}
			case 'percent':
				return of(`${data}%`);
			default:
				return of((data as string) || '');
		}
	}
}

type Model = string | null | Observable<string>;
