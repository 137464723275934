import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { Discount } from '@app/modules/loan-serviceability/model/discount.model';
import {
	BorrowingAndProductInfo,
	LoanInformation
} from '@app/modules/loan-serviceability/model/loan-information.model';
import { RateToBorrowerTransformer } from '@app/modules/loan-serviceability/model/rate-to-borrower.model';
import {
	AssetToBeUsedAsType,
	InterestType,
	LoanFeature,
	LoanSubSection,
	PaymentType,
	SpinnerType,
	TermType,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, forkJoin, iif, of, tap } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { PropertyModalModel } from '../property/models/property-modal.model';
import { PropertyService } from '../property/services/property.service';
import { CONSTANTS } from '../shared/constants/constants';
import { savedSuccessfullyMessage } from '../shared/helper/util';
import { OtherSecuritiesModel, OtherSecuritiesModelTransformer } from '../shared/model/otherSecurities.model';
import { SavingModel } from '../shared/model/saving.model';
import { AddressService } from '../shared/service/address.service';
import { AggregateFormatterService } from '../shared/service/aggregate-formatter.service';
import { AssetsAndLiabilitiesService } from '../shared/service/assets-liabilities.service';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { ChannelSettingQuery } from '../shared/store/channel-setting/channel-setting.query';
import { FormDataService } from '../shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '../shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '../shared/store/form-enums/form-enums.service';
import { FormStateService } from '../shared/store/form-state/form-state.service';
import { TransformerUtilService } from '../shared/transformers/service/transformer-util.service';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import {
	BorrowingsDTO,
	CommisionDTO,
	ContributionDTO,
	CreditCardNonConflictDTO,
	CreditCardProductDTO,
	DepositDTO,
	DiscountDTO,
	FixedInterestRateLockDTO,
	GovernmentFeesDTO,
	LoanInformationDTO,
	LoanRequirementsDTO,
	OffsetDTO,
	ProductDTO,
	ProductFeatureDTO,
	ProductFeeTypeDTO,
	ProductRepaymentFrequency,
	PropertyDTO,
	RateAdjustmentsDTO,
	RateToBorrowerDTO
} from '../typings/api';
import { AppService } from './../../app.service';
import { FeePaymentTiming } from './../shared/enums/app.enums';
import { CreditCardApplicationDTO, FeeDTO, LmiDTO } from './../typings/api.d';
import { BorrowingInfo, BorrowingTransformer } from './model/borrowing.model';
import { CommissionModel, CommissionTransformer } from './model/commision.model';
import { DiscountTransformer } from './model/discount.model';
import { FeeListSectionModel, FeeListSectionTransformer } from './model/fee-detail.model';
import { GovernmentFeesModel, GovernmentFeesTransformer } from './model/government-fees-model';
import { JourneyProduct, LoanDetailJourneyProducts } from './model/journey-product.model';
import {
	LendersMortgageInsurance,
	LendersMortgageInsuranceTransformer
} from './model/lenders-mortgage-insurance-model';
import { LoanInformationTransformer } from './model/loan-information.model';
import {
	LoanServiceabilitySummaryModel,
	LoanServiceabilitySummaryModelTransformer
} from './model/loan-serviceability-summary.model';
import {
	CreditCardApplication,
	CreditCardNonConflict,
	LockedInterestRate,
	OffsetAccounts,
	ProductFeature,
	ProductFeatureTransformer
} from './model/product-features.model';
import { PropertyFilterParamsForProduct } from './model/product-filter-parameters.model';
import { Product, ProductTransformer } from './model/product.model';
import { PropertyAssetModel, PropertyAssetModelTransformer } from './model/property-asset.model';
import {
	CustomLoaders,
	RateAdjustment,
	RateAdjustmentsModel,
	RateAdjustmentsTransformer
} from './model/rate-adjustments.model';
import { RateToBorrower } from './model/rate-to-borrower.model';
import { ServiceabilityResult } from './model/serviceability-calculation-result.model';
import { LoanDetailsFormDataService } from './services/loan-details-form-data.service';

@Injectable({ providedIn: 'root' })
export class LoanServiceabilityService extends BaseJourneyService {
	areaChanged$: Subject<void> = new Subject();
	showSpinnerSubject: Subject<LoadSpinner> = new Subject();
	showSpinner$: Observable<LoadSpinner> = this.showSpinnerSubject.asObservable();

	splitLoanIdsObservable$: Observable<number[]>;
	splitLoanIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

	private triggerProductFilter$: Subject<number> = new Subject();
	private triggerProductFilterObservable$: Observable<number>;

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private channelSettingsQuery: ChannelSettingQuery,
		private formEnumsService: FormEnumsService,
		private formStateService: FormStateService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private addressService: AddressService,
		private aggregateFormatterService: AggregateFormatterService,
		private formEnumQuery: FormEnumsQuery,
		private transformerUtilService: TransformerUtilService,
		private appService: AppService,
		private propertyService: PropertyService,
		private loanDetailsFormDataService: LoanDetailsFormDataService,
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private formDataService: FormDataService
	) {
		super();
		this.setJourneyLadmRoute();
		this.splitLoanIdsObservable$ = this.splitLoanIds$.asObservable();
		this.triggerProductFilterObservable$ = this.triggerProductFilter$.asObservable();
	}

	setupState$() {
		this.setupState();
		return this.filterJourneyProducts(undefined, false).pipe(
			switchMap(() => {
				return forkJoin([
					this.fetchContributions(),
					this.fetchLenderMortgageInsurance(),
					this.fetchDeposit(),
					this.assetsAndLiabilitiesService.fetchSavings(),
					this.propertyService.syncPropertiesAndSecurities$(),
					this.loadFinancialTransactionAccountAssets$(),
					this.syncLoanInformation$(),
					this.fetchCreditCardProductsAndConflicts$(),
					this.fetchFees$(),
					this.fetchFeeTypes$(),
					this.loadFinancialAssets$(),
					this.fetchGovernmentFees()
				]).pipe(
					map(([otherContributions, lendersMortgageInsurance, depositsPaid, savings]) => {
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							'loan-serviceability-otherContributions',
							otherContributions
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							'loan-serviceability-lendersMortgageInsurance',
							lendersMortgageInsurance
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData('loan-serviceability-depositsPaid', depositsPaid);
						this.simpFormlyHandlerService.upsertToFullPathWithData('financial-position-savings', savings);
						this.updateSavingsEnums(savings);
						this.updateOtherSecurities(savings);
						return of([]);
					}),
					tap(() => {
						this.configureApplicationTitleInfoByLoanAmount();
					})
				);
			})
		);
	}

	setupState(): void {
		this.formEnumsService.updateFormEnums('FeesLinkedProducts', []);
		this.formEnumsService.updateFormEnums('CreditCardProducts', []);
		this.formEnumsService.updateFormEnums('ProductEligibleFees', []);

		// Now subsection data can be changed from more than one area (Eg: for Property Details => 'Loan and serviceability' and 'Financial position')
		// So when navigate between those areas, before getting the data from BE, sub section picks data from store.
		// There fore exact data will not be populated for some edge cases. So better to reset data in such cases.
		this.simpFormlyHandlerService.upsertToStateWithData('property', []);
		this.simpFormlyHandlerService.upsertToStateWithData('stages', []);
		this.simpFormlyHandlerService.upsertToStateWithData('builder', []);

		//If store contains data already, UI loads from it before getting data from Backend APIs.
		//This causes to show obselate fields in the UI. So better to reset data in such cases.
		this.simpFormlyHandlerService.upsertToStateWithData('loanInformation', []);
	}

	getSplitSubsectionKey(subsectionKey: string, loanId?: number): string {
		return `split-${subsectionKey}-${loanId ?? -1}`;
	}

	setSplitLoanIds(loanIds: number[]) {
		this.splitLoanIds$.next(loanIds);
	}

	syncLoanInformation$(): Observable<boolean> {
		return this.fetchLoanInformation().pipe(
			switchMap((loanInfo) => {
				this.simpFormlyHandlerService.upsertToStateWithData('loanInformation', [loanInfo]);

				const loanIds = loanInfo.isSplitLoan
					? loanInfo.loanSplits?.map((split) => split.loanDetailId as number)
					: [loanInfo.loanDetailId as number];

				if (!loanIds) {
					return of(true);
				}

				this.setSplitLoanIds(loanIds);
				return forkJoin([
					this.syncBorrowings$(loanIds).pipe(catchError(() => of(true))),
					...loanIds.map((loanId) => this.syncProduct$(loanId))
				]).pipe(map(() => true));
			})
		);
	}

	syncBorrowings(loanIds: number[]): void {
		this.syncBorrowings$(loanIds).subscribe();
	}

	fetchLoanInformationWithBorrowingsAndProducts(): Observable<LoanInformation> {
		return this.fetchLoanInformation().pipe(
			switchMap((loanInfo) => {
				const loanIds = loanInfo.isSplitLoan
					? loanInfo.loanSplits?.map((split) => split.loanDetailId as number)
					: [loanInfo.loanDetailId as number];
				if (!loanIds) {
					return of(loanInfo);
				}
				return forkJoin(
					loanIds.map((loanId) =>
						forkJoin([
							this.fetchBorrowing(loanId),
							this.fetchProductInfo(loanId),
							this.fetchLoanRateToBorrower(loanId),
							this.fetchJourneyProductsByLoanId(loanId).pipe(
								map((res) => res?.[0]?.journeyProducts.find((product) => product.selected))
							)
						]).pipe(
							map(
								([borrowing, product, rateToBorrower, selectedProduct]) =>
									({ borrowing, product, rateToBorrower, selectedProduct } as BorrowingAndProductInfo)
							)
						)
					)
				).pipe(
					map(
						(borrowingAndProductInfo: BorrowingAndProductInfo[]) =>
							({ ...loanInfo, borrowingAndProductInfo } as LoanInformation)
					)
				);
			})
		);
	}

	syncBorrowings$(loanIds: number[]): Observable<boolean> {
		return forkJoin(loanIds.map((loanId) => this.fetchBorrowing(loanId))).pipe(
			map((borrowings) => {
				borrowings.forEach((borrowing, index) => {
					const borrowingSubsectionKey = this.getSplitSubsectionKey(LoanSubSection.BORROWING, borrowing.loanId);
					this.simpFormlyHandlerService.upsertToStateWithData(borrowingSubsectionKey, [borrowing ?? {}]);
					this.loanDetailsFormDataService.updateFormStateForPrimaryPurpose(borrowing);
				});
				return true;
			})
		);
	}

	syncProduct$(loanId: number): Observable<boolean> {
		return this.fetchProductInfo(loanId).pipe(
			switchMap((product) => {
				const productSubsectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);

				const loanProducts = this.simpFormlyHandlerService.getStateData<LoanDetailJourneyProducts>('loanProducts');
				if (!loanProducts?.length) {
					this.resetProduct(product);
					this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
					return of(true);
				}

				const journeyProducts = loanProducts.find((loanProd) => loanProd.loanDetailId === loanId)?.journeyProducts;
				if (!journeyProducts?.length) {
					this.resetProduct(product);
					this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
					return of(true);
				}

				const isMatched = journeyProducts?.some(
					(prod) => prod.productVariationId === product.preferredProductVariationId && prod.selected
				);

				// Just update state if selected product has not changed
				if (isMatched) {
					this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
					return of(true);
				}

				// Update the selected product it it has changed and available within the filtered list
				const selectedProd = journeyProducts?.find((prod) => prod.selected);
				if (selectedProd) {
					product.preferredProductVariationId = selectedProd.productVariationId;
					product.preferredProductChannelId = selectedProd.productChannelId;
					this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
					return of(true);
				}

				// Update the selected product as zeroth one of the filtered list if there is no any selected product.
				if (journeyProducts?.length) {
					return this.updateLoanDetailProduct(journeyProducts[0]).pipe(
						map(() => {
							product.preferredProductVariationId = journeyProducts[0].productVariationId;
							product.preferredProductChannelId = journeyProducts[0].productChannelId;
							this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
							this.updateProductDependents(loanId, true);
							return true;
						})
					);
				}

				this.simpFormlyHandlerService.upsertToStateWithData(productSubsectionKey, [product ?? {}]);
				return of(true);
			}),
			switchMap(() =>
				forkJoin([
					this.syncDiscount$(loanId),
					this.syncRateAdjustment$(loanId),
					this.syncCommission$(loanId),
					this.syncRateToBorrower$(loanId)
				]).pipe(map(() => true))
			),
			catchError(() => of(true))
		);
	}

	syncDiscount$(loanId: number): Observable<boolean> {
		return this.fetchDiscount(loanId).pipe(
			map((discount) => {
				const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.DISCOUNT, loanId);
				this.simpFormlyHandlerService.upsertToStateWithData(subSectionKey, [discount]);

				return true;
			})
		);
	}

	syncRateAdjustment$(loanId: number, initial = false): Observable<boolean> {
		return this.fetchRateAdjustments(loanId).pipe(
			map((adjustment) => {
				const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.RATE_ADJUSTMENTS, loanId);
				if (!initial) {
					this.simpFormlyHandlerService.upsertToStateWithData(subSectionKey, []);
				}
				this.simpFormlyHandlerService.upsertToStateWithData(subSectionKey, [adjustment]);

				return true;
			})
		);
	}

	syncCommission$(loanId: number): Observable<boolean> {
		return this.fetchCommissions(loanId).pipe(
			map((commission) => {
				const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.COMMISSION, loanId);
				this.simpFormlyHandlerService.upsertToStateWithData(subSectionKey, [commission]);
				return true;
			})
		);
	}

	syncRateToBorrower$(loanId: number, checkProduct = false): Observable<boolean> {
		if (checkProduct) {
			// check whether product is selected or not before fetch rate to borrower
			const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
			const product = this.simpFormlyHandlerService.getStateData<Product>(subSectionKey)?.[0];
			if (!product?.preferredProductVariationId) {
				return of(true);
			}
		}

		return this.fetchLoanRateToBorrower(loanId).pipe(
			map((rate) => {
				const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.RATE_TO_BORROWER, loanId);
				this.simpFormlyHandlerService.upsertToStateWithData(subSectionKey, [rate]);
				this.loadRepaymentFrequencies(loanId);
				return true;
			})
		);
	}

	setSpinnerStatus(type: SpinnerType, status: boolean, loanId?: number) {
		this.loanDetailsFormDataService.updateLoadersInLoanDetails({
			type: type,
			loadingStatus: status,
			loanId
		});
	}

	updatePropertyDetails(propertyData: PropertyAssetModel, propertyDetails: PropertyModalModel): Observable<any> {
		const payload = PropertyAssetModelTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			propertyData,
			propertyDetails
		);
		return this.postCustom(`Property`, payload).pipe(
			tap(() => {
				this.addressService.fetchAllAddress();
				this.propertyService.syncSecurityProperties((properties: PropertyAssetModel[]) => {
					this.filterProductsWhenCertifiedValueChanged(
						properties.map((property) => {
							return {
								certifiedValue: property.certifiedValue ?? -1,
								contractPriceAmount: property.contractPriceAmount ?? -1
							};
						})
					);
				});
				this.toastr.success(savedSuccessfullyMessage('Property details'));
			})
		);
	}

	triggerPropertySaveSuccess(filterProducts: boolean): void {
		if (filterProducts) {
			this.filterJourneyProducts().subscribe();
		}
		this.propertyService.syncPropertiesAndSecurities();
		this.appService.applicationTitleInfoChange.next();
		this.addressService.fetchAllAddress();
		this.toastr.success(savedSuccessfullyMessage('Property details'));
	}

	getCreditCardNonConflicts(): Observable<CreditCardNonConflictDTO[]> {
		return <Observable<CreditCardNonConflictDTO[]>>(
			this.getCustom(`LoanRequirements/CreditCardNonConflicts/${this.applicationDataQuery.applicationId()}`)
		);
	}

	addNewLoanRequirement(): Observable<number> {
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<number>>this.postCustom(`LoanRequirements/AddNew/${applicationId}`, {});
	}

	saveLoanInformation(loanInfoModel: LoanInformation): Observable<void> {
		const loanInformationDTO = LoanInformationTransformer.toPayload(loanInfoModel);
		loanInformationDTO.applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom('LoanRequirements/LoanInfo', loanInformationDTO);
	}

	saveBorrowing(borrowing: BorrowingInfo): Observable<any> {
		const payload: BorrowingsDTO = BorrowingTransformer.toPayLoad(borrowing);
		payload.applicationId = this.applicationDataQuery.applicationId();
		this.loanDetailsFormDataService.updateFormStateForPrimaryPurpose(borrowing);
		return this.postCustom('LoanRequirements/Borrowings', payload);
	}

	saveProduct(product: Product): Observable<any> {
		const applicationId = this.applicationDataQuery.applicationId();
		const payload: ProductDTO = ProductTransformer.toPayload(product, applicationId);
		return this.postCustom('LoanRequirements/products', payload);
	}

	saveDiscount(discount: Discount): Observable<void> {
		const payload: DiscountDTO = DiscountTransformer.toPayload(discount);
		payload.applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom('LoanRequirements/Discounts', payload);
	}

	saveRateToBorrower(rateToBorrower: RateToBorrower): Observable<RateToBorrowerDTO> {
		const payLoad: RateToBorrowerDTO = RateToBorrowerTransformer.toPayload(rateToBorrower);
		return this.postCustom('LoanRequirements/RateToBorrower', payLoad) as Observable<RateToBorrowerDTO>;
	}

	saveRateAdjustment(rateAdjustment: RateAdjustment, loanId: number): Observable<void> {
		const payload = RateAdjustmentsTransformer.toPayLoad(rateAdjustment, true);
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom(`ProductRateAdjustment/${applicationId}/${loanId}`, payload);
	}

	saveCommission(commission: CommissionModel): Observable<void> {
		const payload = CommissionTransformer.toPayLoad(commission);
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom(`LoanDetailsCommission/${applicationId}`, payload);
	}

	saveSelectedJourneyProduct(product: JourneyProduct, loadFees?: boolean): Observable<void> {
		return this.updateLoanDetailProduct(product).pipe(
			tap(() => {
				const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, product.loanDetailId);
				const productInfo = this.simpFormlyHandlerService.getStateData<Product>(subsectionKey)?.[0];
				if (productInfo) {
					const productModel = cloneDeep(productInfo);
					productModel.preferredProductVariationId = product.productVariationId;
					productModel.preferredProductChannelId = product.productChannelId;
					this.simpFormlyHandlerService.updateToState(subsectionKey, productModel, 0);
				}
				this.updateProductDependents(product.loanDetailId, loadFees);
			})
		);
	}

	saveProductFeatures(feature: ProductFeature, loanId: number): Observable<void> {
		const payload: ProductFeatureDTO = ProductFeatureTransformer.toPayload(feature);
		payload.loanDetailId = loanId;
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom(`LoanRequirements/ProductFeature/${applicationId}`, payload);
	}

	updateLoanDetailProduct(product: JourneyProduct): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>this.postCustom(`LoanRequirements/UpdateLoanDetailProduct/${applicationId}`, product);
	}

	saveOffsetAccountDetails(offsetAccounts: OffsetAccounts, loanId: number): Observable<void> {
		const offsetDTO = ProductFeatureTransformer.toOffsetPayload(offsetAccounts, loanId);
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<void>>(
			this.postCustom(`LoanRequirements/LoanFeaturesOffsetAccountDetails/${applicationId}`, offsetDTO)
		);
	}

	saveCreditCardApplication(creditCardApplication: CreditCardApplication, loanId: number): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		const creditCardApplicationDTO = ProductFeatureTransformer.toCreditCardPayload(
			creditCardApplication,
			loanId,
			applicationId
		);
		return <Observable<void>>(
			this.postCustom(`LoanRequirements/CreditCardApplication/${applicationId}`, creditCardApplicationDTO)
		);
	}

	saveLockedInterestRate(lockedInterestRate: LockedInterestRate, loanId: number): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		const fixedRateLock = ProductFeatureTransformer.toLockedInterestPayload(lockedInterestRate, loanId, applicationId);
		return <Observable<void>>this.postCustom(`LoanRequirements/FixedRateLock/${applicationId}`, fixedRateLock);
	}

	deleteAllSplitLoans() {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.delete(`LoanRequirements/DeleteSplit/${applicationId}`).pipe(map(() => undefined));
	}

	deleteSplitLoan(loanDetailId: number) {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.delete(`LoanRequirements/DeleteSplit/${applicationId}/${loanDetailId}`).pipe(map(() => undefined));
	}

	saveDeposit(depositDTO: DepositDTO): Observable<number> {
		return this.postCustom('Deposit', depositDTO).pipe(map((id: number) => id));
	}

	deleteDeposit(applicantId: number, id: number): Observable<void> {
		return this.delete(`Deposit/${applicantId}/${id}`).pipe(map(() => undefined));
	}

	saveContributions(contributionDTO: ContributionDTO): Observable<number> {
		return this.postCustom('Contribution', contributionDTO).pipe(map((id: number) => id));
	}

	deleteContributions(applicantId: number, id: number): Observable<void> {
		return this.delete(`Contribution/${applicantId}/${id}`).pipe(map(() => undefined));
	}

	deleteGovernmentFees(applicantId: number, id: number): Observable<void> {
		return this.delete(`GovernmentFees/${applicantId}/${id}`).pipe(map(() => undefined));
	}

	fetchLoanInformation(): Observable<LoanInformation> {
		return this.getCustom(`LoanRequirements/LoanInfo/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loanInformation: LoanInformationDTO) => {
				const applicants = this.applicationDataQuery.getApplicants();
				return LoanInformationTransformer.fromPayload(loanInformation, applicants);
			})
		);
	}

	fetchBorrowing(loanId: number): Observable<BorrowingInfo> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/Borrowing/${applicationId}/${loanId}`).pipe(
			map((borrowing: BorrowingsDTO) => BorrowingTransformer.fromPayload(borrowing))
		);
	}

	fetchProductInfo(loanId: number): Observable<Product> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/Product/${applicationId}/${loanId}`).pipe(
			switchMap((productDto: ProductDTO) =>
				this.setInitialProductFeaturesList(loanId, productDto.features, productDto.productVariationId).pipe(
					map(() => productDto)
				)
			),
			map((productDto: ProductDTO) => ProductTransformer.fromPayload(productDto, this.formDataService))
		);
	}

	fetchDiscount(loanId: number): Observable<Discount> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/Discounts/${applicationId}/${loanId}`).pipe(
			map((discountDto: DiscountDTO) => this.mapDiscount(discountDto)),
			catchError(() => {
				return of({
					loanId,
					applicationId,
					isProductSelected: false,
					isHideSubTerm: this.isHideSubTermStatus(loanId)
				} as Discount);
			})
		);
	}

	fetchSplitLoans(): Observable<LoanRequirementsDTO[]> {
		return this.getCustom(`LoanRequirements/all/${this.applicationDataQuery.applicationId()}`).pipe(
			catchError(() => of([]))
		);
	}

	filterJourneyProducts(loanId?: number, updateSelectedProduct = true): Observable<LoanDetailJourneyProducts[]> {
		this.setSpinnerStatus(SpinnerType.FilterProduct, true, loanId);

		return iif(
			() => !loanId,
			this.fetchLoanJourneyProducts(),
			this.fetchJourneyProductsByLoanId(loanId as number)
		).pipe(
			tap((products: LoanDetailJourneyProducts[]) => {
				this.updateStateLoanProducts(products, loanId);

				if (updateSelectedProduct) {
					this.updateSelectedProductDetails(products, loanId);
				}
				this.setSpinnerStatus(SpinnerType.FilterProduct, false, loanId);
			})
		);
	}

	fetchLoanJourneyProducts(): Observable<LoanDetailJourneyProducts[]> {
		const applicationId = this.applicationDataQuery.applicationId();

		if (!applicationId) {
			return of([]);
		}

		return this.getCustom(
			`LoanServiceability/LoanDetailProductsByJourneyId/${applicationId}/${this.applicationDataQuery.getApplicationJourneyId()}`
		).pipe(
			tap((products: LoanDetailJourneyProducts[]) => this.updateLoanProductsEnums(products)),
			catchError(() => of([]))
		);
	}

	fetchJourneyProductsByLoanId(loanId: number): Observable<LoanDetailJourneyProducts[]> {
		const applicationId = this.applicationDataQuery.applicationId();

		if (!applicationId) {
			return of([]);
		}

		return this.getCustom(
			`LoanServiceability/LoanDetailProductsByJourneyId/${applicationId}/${loanId}/${this.applicationDataQuery.getApplicationJourneyId()}`
		).pipe(
			tap((products: LoanDetailJourneyProducts[]) => this.updateLoanProductsEnums(products, loanId)),
			catchError(() => of([]))
		);
	}

	fetchPropertyAssetsByAddressId(addressId: number): Observable<PropertyDTO> {
		return this.getCustom(
			`Property/GetByApplicationIdAndAddressId/${this.applicationDataQuery.applicationId()}/${addressId}`
		).pipe(
			map((propertyDTO: PropertyDTO) => {
				return propertyDTO;
			})
		);
	}

	fetchLoanRateToBorrower(loanId: number): Observable<RateToBorrower> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/RateToBorrower/${applicationId}/${loanId}`).pipe(
			map((rateToBorrower: RateToBorrowerDTO) => this.mapRateToBorrower(rateToBorrower)),
			catchError(() => {
				return of({
					loanId,
					applicationId: applicationId,
					isProductSelected: false,
					isHideSubTerm: this.isHideSubTermStatus(loanId)
				} as RateToBorrower);
			})
		);
	}

	loadDiscount(loanId: number) {
		this.fetchDiscount(loanId).subscribe((discount) => {
			const key = this.getSplitSubsectionKey(LoanSubSection.DISCOUNT, loanId);
			this.simpFormlyHandlerService.updateToState(key, discount, 0);
		});
	}

	mapRateToBorrower(rateDto: RateToBorrowerDTO): RateToBorrower {
		const rate = RateToBorrowerTransformer.fromPayload(rateDto);
		rate.isHideSubTerm = this.isHideSubTermStatus(rate.loanId);
		return rate;
	}

	loadRateToBorrower(loanId: number) {
		this.fetchLoanRateToBorrower(loanId).subscribe((rateToBorrower) => {
			const key = this.getSplitSubsectionKey(LoanSubSection.RATE_TO_BORROWER, loanId);
			this.simpFormlyHandlerService.updateToState(key, rateToBorrower, 0);
		});
	}

	fetchRateAdjustments(loanId: number): Observable<RateAdjustmentsModel> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`ProductRateAdjustment/${applicationId}/${loanId}`).pipe(
			map((rateAdjustment: RateAdjustmentsDTO[]) => {
				return this.mapRateAdjustment(rateAdjustment, loanId);
			}),
			catchError(() => {
				return of({
					loanId,
					isProductSelected: false,
					isHideSubTerm: this.isHideSubTermStatus(loanId)
				} as RateAdjustmentsModel);
			})
		);
	}

	mapRateAdjustment(rateAdjustment: RateAdjustmentsDTO[], loanId: number): RateAdjustmentsModel {
		const rateAdjustmentModel = RateAdjustmentsTransformer.fromPayload(rateAdjustment, loanId);
		const productSectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		const product = this.simpFormlyHandlerService.getStateData<Product>(productSectionKey)?.[0];
		if (product) {
			rateAdjustmentModel.isProductSelected = !!product.preferredProductVariationId;
			rateAdjustmentModel.isHideSubTerm = this.isHideSubTermStatus(loanId);
			if (rateAdjustmentModel.isProductSelected) {
				rateAdjustmentModel.isLoadersEmpty = rateAdjustment.length === 0;
			}
		}
		return rateAdjustmentModel;
	}

	loadRateAdjustment(loanId: number, initial = false) {
		this.fetchRateAdjustments(loanId).subscribe((adjustment) => {
			const rateAdjustmentSubsectionKey = this.getSplitSubsectionKey(LoanSubSection.RATE_ADJUSTMENTS, loanId);
			// UI doesn't update so we clean the state before updating
			if (!initial) {
				this.simpFormlyHandlerService.upsertToStateWithData(rateAdjustmentSubsectionKey, []);
			}
			this.simpFormlyHandlerService.upsertToStateWithData(rateAdjustmentSubsectionKey, [adjustment]);
		});
	}

	fetchLoaderTypes(loanId: number): void {
		this.fetchLoaderTypes$(loanId).subscribe();
	}

	fetchLoaderTypes$(loanId: number): Observable<boolean> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`ProductRateAdjustment/CustomLoader/${applicationId}/${loanId}`).pipe(
			map((customs: CustomLoaders[]) => {
				const adjustmentTitleList = this.transformCustomAdjustmentsToEnums(customs);
				this.loanDetailsFormDataService.updateFormStateForLoanDetails({ isCustomLoadersEmpty: customs.length === 0 });
				this.formEnumsService.updateFormEnums(`LoaderTypes-${loanId}`, adjustmentTitleList);
				return true;
			})
		);
	}

	fetchOffsetAccountDetails(loanId: number): Observable<OffsetDTO | null> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/OffsetAccountDetails/${applicationId}/${loanId}`).pipe(
			map((offsetDTO: OffsetDTO) => {
				return offsetDTO;
			}),
			catchError(() => of(null))
		);
	}

	fetchFixedRateLockedDetails(loanId: number): Observable<FixedInterestRateLockDTO | null> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/FixedRateLock/${applicationId}/${loanId}`).pipe(
			map((offsetDTO: FixedInterestRateLockDTO) => {
				return offsetDTO;
			}),
			catchError(() => of(null))
		);
	}

	deleteRateAdjustment(loaderId: number, loanId: number): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.delete(`ProductRateAdjustment/${applicationId}/${loanId}/${loaderId}`).pipe(map(() => undefined));
	}

	mapCommission(commission: CommisionDTO, loanId: number): CommissionModel {
		const commissionModel = CommissionTransformer.fromPayload(commission, loanId);
		const productSectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		const product = this.simpFormlyHandlerService.getStateData<Product>(productSectionKey)?.[0];
		if (product) {
			commissionModel.isHideSubTerm = this.isHideSubTermStatus(loanId);
		}
		return commissionModel;
	}

	fetchCommissions(loanId: number): Observable<CommissionModel> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanDetailsCommission/${applicationId}/${loanId}`).pipe(
			map((commission: CommisionDTO) => {
				return this.mapCommission(commission, loanId);
			}),
			catchError(() => {
				return of({
					loanId,
					isProductSelected: false,
					isHideSubTerm: this.isHideSubTermStatus(loanId)
				} as CommissionModel);
			})
		);
	}

	loadCommision(loanId: number) {
		this.fetchCommissions(loanId).subscribe((commission) => {
			const commisionSubsectionKey = this.getSplitSubsectionKey(LoanSubSection.COMMISSION, loanId);
			this.simpFormlyHandlerService.upsertToStateWithData(commisionSubsectionKey, [commission]);
		});
	}

	deleteCommission(commissionId: number, termType: TermType): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.delete(`LoanDetailsCommission/${applicationId}/${commissionId}/${termType}`).pipe(map(() => undefined));
	}

	fetchCreditCardApplication(loanId: number): Observable<CreditCardApplication> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanRequirements/CreditCardApplication/${applicationId}/${loanId}`).pipe(
			map((creditCardDto: CreditCardApplicationDTO) =>
				ProductFeatureTransformer.fromCreditCardPayload(creditCardDto ?? {})
			)
		);
	}

	getSummaryData(): Observable<LoanServiceabilitySummaryModel> {
		const enumsToFetch: string[] = [
			'InterestType',
			'RepaymentFrequency',
			'PaymentType',
			'InterestOnlyReason',
			'AbsLendingPurposeCode'
		]; // ['PrimaryPurposeLoanPurpose', 'BorrowingReason'];
		this.formEnumsService.fetchEnums(enumsToFetch).subscribe();

		const purchaseDetails$ = this.propertyService.fetchSecurityProperties();
		const serviceabilityData$: Observable<ServiceabilityResult | undefined> = this.getServiceabilitySummaryFromState();
		const deposits$ = this.fetchDeposit();
		const contributions$ = this.fetchContributions();
		const loanInformation$ = this.fetchLoanInformationWithBorrowingsAndProducts();
		const lendersMortgageInsurance$ = this.fetchLenderMortgageInsurance();

		return forkJoin({
			purchaseDetails: purchaseDetails$,
			serviceabilityData: serviceabilityData$,
			depositDetails: deposits$,
			contributionDetails: contributions$,
			loanInformation: loanInformation$,
			lendersMortgageInsurance: lendersMortgageInsurance$
		}).pipe(
			map((data) => {
				return LoanServiceabilitySummaryModelTransformer.fromPayload(
					data.purchaseDetails,
					data.serviceabilityData,
					data.depositDetails,
					data.contributionDetails,
					data.loanInformation,
					data.lendersMortgageInsurance,
					this.aggregateFormatterService,
					this.formEnumQuery
				);
			})
		);
	}

	getInitiallySelectedProduct(products: JourneyProduct[]): JourneyProduct {
		return products?.find((product) => product?.selected) ?? products?.[0];
	}

	loadProductFeatures(loanId?: number): void {
		if (loanId) {
			this.setSpinnerStatus(SpinnerType.LoadingFeatures, true, loanId);
			this.fetchProductFeatures(loanId).subscribe((features) => {
				this.updateLoanFeaturesSubsection(loanId, true, features).subscribe();

				const selectedFeatures: EnumObject[] = [];

				// Map features into enum objects while finding selected features
				const loanFeatures = features.map((feature) => {
					const loanFeature = {
						id: feature.featureEnum,
						label: feature.featureName
					} as EnumObject;

					if (feature.selected) {
						selectedFeatures.push(loanFeature);
					}
					return loanFeature;
				});

				this.formEnumsService.updateFormEnums(`ProductFeatures-${loanId}`, loanFeatures);

				const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
				const product = this.simpFormlyHandlerService.getStateData<Product>(subsectionKey)?.[0];
				if (product) {
					const productInfo = cloneDeep(product);
					productInfo.loanFeatures = selectedFeatures;
					this.simpFormlyHandlerService.updateToState(subsectionKey, productInfo, 0, productInfo);
				}
				this.setSpinnerStatus(SpinnerType.LoadingFeatures, false, loanId);
			});
		}
	}

	loadRepaymentFrequencies(loanId?: number): void {
		if (loanId) {
			this.fetchRepaymentFrequencies(loanId).subscribe((frequencies) => {
				this.formEnumsService.updateFormEnums(
					`RepaymentFrequencies-${loanId}`,
					frequencies.map((freq) => {
						return {
							id: freq.optionEnum,
							label: freq.optionName
						} as EnumObject;
					})
				);
			});
		}
	}

	fetchProductFeatures(loanId: number): Observable<ProductFeatureDTO[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`LoanServiceability/LoanDetailProductFeatures/${applicationId}/${loanId}`).pipe(
			map((features: ProductFeatureDTO[]) => features ?? []),
			catchError(() => of([]))
		);
	}

	fetchRepaymentFrequencies(loanId: number): Observable<ProductRepaymentFrequency[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(
			`LoanServiceability/LoanDetailProductRepaymentFrequencyOptions/${applicationId}/${loanId}`
		).pipe(
			map((frequencies: ProductRepaymentFrequency[]) => frequencies ?? []),
			catchError(() => of([]))
		);
	}

	fetchDeposit(): Observable<DepositDTO[]> {
		return this.getCustom(`Deposit/${this.applicationDataQuery.applicationId()}`).pipe(catchError(() => of([])));
	}

	/**
	 * this will request applicable fee types after fee modification
	 * Ex: after product modification / fee add, update
	 */
	fetchFeeTypes(): void {
		this.fetchFeeTypes$().subscribe();
	}

	fetchFeeTypes$(): Observable<boolean> {
		return this.getCustom(`LoanFee/ProductFee/${this.applicationDataQuery.applicationId()}`).pipe(
			map((productFeeTypes: ProductFeeTypeDTO[]) => {
				this.simpFormlyHandlerService.upsertToStateWithData('productFeeTypes', productFeeTypes);
				return true;
			})
		);
	}

	fetchFees(calculateRepayment?: boolean): void {
		this.fetchFees$().subscribe(() => {
			if (calculateRepayment) {
				this.calculateRepaymentsWithCapitalizedFeesLMI();
			}
		});
		this.fetchFeeTypes$().subscribe();
	}

	fetchFees$(): Observable<boolean> {
		return this.getCustom(`LoanFee/${this.applicationDataQuery.applicationId()}`).pipe(
			map((fees: FeeDTO[]) => {
				const paidOnOrBeforeList = fees
					.filter(
						(fee) =>
							fee.paymentTiming === FeePaymentTiming.BeforeCreditDrawdown ||
							fee.paymentTiming === FeePaymentTiming.OnCreditDrawdown ||
							!fee.paymentTiming
					)
					.map((fee) => {
						return FeeListSectionTransformer.fromPayload(fee);
					});

				const paidThroughoutLoanList = fees
					.filter(
						(fee) =>
							fee.paymentTiming === FeePaymentTiming.OnEventOccurrence ||
							fee.paymentTiming === FeePaymentTiming.RegularRecurring
					)
					.map((fee) => {
						return FeeListSectionTransformer.fromPayload(fee);
					});
				const combineSectionFees: [FeeListSectionModel[], FeeListSectionModel[]] = [
					paidOnOrBeforeList,
					paidThroughoutLoanList
				];
				return combineSectionFees;
			}),
			catchError(() => of([])),
			map((fees: [FeeListSectionModel[], FeeListSectionModel[]] | never[]) => {
				if (fees) {
					this.simpFormlyHandlerService.upsertToStateWithData('paidOnOrBefore', fees[0]);
					this.simpFormlyHandlerService.upsertToStateWithData('paidThroughoutLoan', fees[1]);
				}
				return true;
			})
		);
	}

	fetchLenderMortgageInsurance(): Observable<LendersMortgageInsurance[]> {
		return this.getCustom(`LendersMortgageInsurance/${this.applicationDataQuery.applicationId()}`).pipe(
			map((lmiDto: LmiDTO) => {
				return [LendersMortgageInsuranceTransformer.fromPayload(lmiDto)];
			}),
			catchError(() => of())
		);
	}

	deleteFees(feeId: number): Observable<number> {
		return this.delete(`LoanFee/${this.applicationDataQuery.applicationId()}/${feeId}`) as Observable<number>;
	}

	saveFees(fee: FeeListSectionModel): Observable<FeeListSectionModel> {
		const payload = FeeListSectionTransformer.toPayload(fee);
		return this.postCustom(`LoanFee/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			map((id: number) => {
				payload.id = id;
				const feeModel = FeeListSectionTransformer.fromPayload(payload);
				this.toastr.success(savedSuccessfullyMessage('Fee'));
				return feeModel;
			})
		);
	}

	resetFeesToDefault(): Observable<number> {
		return this.put(`LoanFee/${this.applicationDataQuery.applicationId()}/resetToDefault`, {}) as Observable<number>;
	}

	saveLendersMortgageInsurance(input: LendersMortgageInsurance): Observable<LmiDTO> {
		const lmiDto = LendersMortgageInsuranceTransformer.toPayload(this.applicationDataQuery.applicationId(), input);
		return this.postCustom('LendersMortgageInsurance', lmiDto).pipe(
			map((dtoModel) => {
				return dtoModel as LmiDTO;
			}),
			tap(() => {
				this.toastr.success(savedSuccessfullyMessage('Lenders mortgage insurance'));
			})
		);
	}

	saveGovernmentFees(input: GovernmentFeesModel): Observable<number> {
		const govFeesDto = GovernmentFeesTransformer.toPayload(this.applicationDataQuery.applicationId(), input);
		return this.postCustom('GovernmentFees', govFeesDto).pipe(
			tap(() => {
				this.toastr.success(savedSuccessfullyMessage('Government Fees'));
			})
		);
	}

	fetchGovernmentFees(): Observable<GovernmentFeesModel> {
		return this.getCustom(`GovernmentFees/${this.applicationDataQuery.applicationId()}`).pipe(
			map((governmentFeesDTO: GovernmentFeesDTO) => GovernmentFeesTransformer.fromPayload(governmentFeesDTO)),
			tap((governmentFees) => {
				this.simpFormlyHandlerService.upsertToStateWithData('governmentFees', governmentFees ? [governmentFees] : []);
			})
		);
	}

	fetchContributions(): Observable<ContributionDTO[]> {
		return this.getCustom(`Contribution/${this.applicationDataQuery.applicationId()}`).pipe(catchError(() => of([])));
	}

	fetchFeePaymentTiming(): Observable<EnumObject[]> {
		return this.formEnumsService.fetch('FeePaymentTiming');
	}

	fetchFinancialTransactionAccountAssets(): Observable<EnumObject[]> {
		return <Observable<EnumObject[]>>(
			this.getCustom(
				`LoanRequirements/GetFinancialTransactionAccountAssets/${this.applicationDataQuery.applicationId()}`
			)
		);
	}

	loadFinancialTransactionAccountAssets$(): Observable<boolean> {
		return this.fetchFinancialTransactionAccountAssets()
			.pipe(
				map((enumObj) => {
					enumObj.forEach((obj) => (obj.info = formatCurrency(Number(obj.info) || 0, 'en-AU', '$')));
					return enumObj;
				})
			)
			.pipe(
				tap((enumObj) => this.formEnumsService.updateFormEnums('FinancialTransactionAccountAssets', enumObj)),
				map(() => true)
			);
	}

	loadFinancialAssets$(): Observable<boolean> {
		return this.GetFinancialContributionFundAssets().pipe(
			map((enumObj) => {
				enumObj.forEach((obj) => (obj.info = formatCurrency(Number(obj.info) || 0, 'en-AU', '$')));
				this.formEnumsService.updateFormEnums('FinancialContributionFundAssets', enumObj);
				return true;
			})
		);
	}

	GetFinancialContributionFundAssets(): Observable<EnumObject[]> {
		return <Observable<EnumObject[]>>(
			this.getCustom(`Contribution/GetFinancialContributionFundAssets/${this.applicationDataQuery.applicationId()}`)
		);
	}

	fetchCreditCardProductsAndConflicts(): void {
		this.fetchCreditCardProductsAndConflicts$().subscribe();
	}

	fetchCreditCardProductsAndConflicts$(): Observable<boolean> {
		return this.getCustom(`LoanRequirements/CreditCardProducts/${this.applicationDataQuery.applicationId()}`).pipe(
			map((creditCardProducts: CreditCardProductDTO[]) => {
				this.simpFormlyHandlerService.upsertToStateWithData('creditCardProducts', creditCardProducts);

				const creditCardTypes = this.transformCreditCardTypesToEnums(creditCardProducts);
				this.formEnumsService.updateFormEnums('CreditCardProducts', creditCardTypes);
				return true;
			}),
			switchMap(() => {
				return this.getCreditCardNonConflicts().pipe(
					map((nonConflictsDto: CreditCardNonConflictDTO[]) =>
						nonConflictsDto.map((nonConflictDto) =>
							ProductFeatureTransformer.fromCreditCardNonConflictPayload(nonConflictDto)
						)
					),
					map((creditCardNonConflicts: CreditCardNonConflict[]) => {
						this.formStateService.updateFormState({ creditCardNonConflicts });
						return true;
					})
				);
			})
		);
	}

	getSelectedJourneyProductId(loanId: number | undefined): number | undefined {
		if (!loanId) {
			return;
		}
		const dynamicKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		const product = this.simpFormlyHandlerService.getStateDataFullPath<Product>(`loan-serviceability-${dynamicKey}`);
		return product?.[0]?.preferredProductVariationId;
	}

	getSelectedProductForLoan(loanDetailId: number | undefined): JourneyProduct | undefined {
		const selectedProductId = this.getSelectedJourneyProductId(loanDetailId);
		return this.getJourneyProduct(loanDetailId, selectedProductId);
	}

	getJourneyProduct(loanId: number | undefined, productId: number | undefined): JourneyProduct | undefined {
		if (!loanId || !productId) {
			return;
		}
		const journeyProducts = this.getJourneyProducts(loanId);
		return journeyProducts?.find((prod) => prod?.productVariationId === productId);
	}

	getJourneyProducts(loanId: number): JourneyProduct[] {
		const loanProducts = this.simpFormlyHandlerService.getStateData<LoanDetailJourneyProducts>('loanProducts') ?? [];
		return loanProducts.find((product) => product?.loanDetailId === loanId)?.journeyProducts ?? [];
	}

	isServiceabilityRequired(): Observable<boolean> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(
			`LoanServiceability/IsServiceabilityRequiredByJourneyId/${applicationId}/${this.applicationDataQuery.getApplicationJourneyId()}`
		).pipe(map((response: boolean) => response));
	}

	configureProductsFilterByTotalBorrowingAmount() {
		this.triggerProductFilterObservable$.pipe(takeUntil(this.areaChanged$), debounceTime(50)).subscribe((id) => {
			this.filterJourneyProducts(id > 0 ? id : undefined).subscribe();
		});

		this.loanDetailsFormDataService
			.selectTotalBorrowingAmount$()
			.pipe(takeUntil(this.areaChanged$), skip(1))
			.subscribe(() => {
				this.triggerProductFilter$.next(0);
			});
	}

	configureProductsFilterByBorrowingAndProductInfo(loanId: number) {
		const borrowingKey = this.getSplitSubsectionKey(LoanSubSection.BORROWING, loanId);
		const productKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);

		this.loanDetailsFormDataService
			.selectBorrowingFilterParamsForLoan$(borrowingKey)
			.pipe(takeUntil(this.areaChanged$))
			.subscribe(() => {
				this.triggerProductFilter$.next(loanId);
			});

		this.loanDetailsFormDataService
			.selectProductFilterParamsForLoan$(productKey)
			.pipe(takeUntil(this.areaChanged$))
			.subscribe(() => {
				this.triggerProductFilter$.next(loanId);
			});
	}

	configureSelectedProductInFormState(loanId: number) {
		const productKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);

		this.loanDetailsFormDataService
			.selectJourneyProduct$(productKey)

			.subscribe((productVariationId) => {
				this.loanDetailsFormDataService.updateFormStateForSelectedLoanProduct({
					loanId,
					productVariationId
				});
				this.updateFeesLinkedProductEnum(loanId, productVariationId);

				if (productVariationId < 0) {
					this.updateLoanFeaturesSubsection(loanId).subscribe();
				}
			});
	}

	updateLendersMortgageInsurance() {
		const lmiModel = this.simpFormlyHandlerService.getStateData(
			'lendersMortgageInsurance'
		)?.[0] as LendersMortgageInsurance;

		if (lmiModel && lmiModel.isLmiRequirement === YesNo.Yes) {
			this.saveLendersMortgageInsurance(lmiModel).subscribe((lmiDto) => {
				const lmi = LendersMortgageInsuranceTransformer.fromPayload(lmiDto);
				this.simpFormlyHandlerService.updateToState('lendersMortgageInsurance', lmi, 0);
			});
		}
	}

	updateSelectedCreditCardLimits(model: CreditCardApplication) {
		if (model.creditCardProductId) {
			const cardDetails = this.simpFormlyHandlerService
				.getStateData<CreditCardProductDTO>('creditCardProducts')
				?.find((card) => card.id === model.creditCardProductId);

			model.minCardLimit = cardDetails?.minLimit;
			model.maxCardLimit = cardDetails?.maxLimit;
			model.isFrequentFlyer = cardDetails?.frequentFlyer;
			return;
		}

		model.minCardLimit = undefined;
		model.maxCardLimit = undefined;
		model.isFrequentFlyer = undefined;
	}

	filterProductsWhenbuildPriceChanged(buildPriceAmount: number, index: number) {
		const property = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>('property')?.[index];

		const existingBuildPrice = property.constructionDetails?.constructionModal?.buildPriceAmount ?? -1;
		if (existingBuildPrice !== buildPriceAmount) {
			this.filterJourneyProducts().subscribe();
		}
	}

	calculateBorrowingAmountsWithCapitalizedFeesLMI(lendersMortgageInsurance?: LendersMortgageInsurance): void {
		if (!lendersMortgageInsurance) {
			lendersMortgageInsurance =
				this.simpFormlyHandlerService.getStateData<LendersMortgageInsurance>('lendersMortgageInsurance')?.[0];
		}

		let lmiPremiumValue = 0;
		if (lendersMortgageInsurance?.isLmiRequirement && lendersMortgageInsurance?.isCapitalize) {
			lmiPremiumValue = lendersMortgageInsurance?.lmiPremium ?? 0;
		}

		const loanFeeMap: Map<number, number> = new Map();
		const feeList = this.simpFormlyHandlerService.getStateData<FeeListSectionModel>('paidOnOrBefore');
		feeList.forEach((fee) => {
			const feeDetails = fee.details?.feePopup;
			const loanId = feeDetails?.linkProduct;
			if (feeDetails?.isCapitalise && loanId) {
				const feesInMap = loanFeeMap.get(loanId) ?? 0;
				const feeAmount = feeDetails?.feeAmount?.amount ?? 0;
				loanFeeMap.set(loanId, feesInMap + feeAmount);
			}
		});

		let governmentFeesValue = 0;
		const governmentFees = this.simpFormlyHandlerService.getStateData<GovernmentFeesModel>('governmentFees');
		if (governmentFees?.[0]?.isCapitalised) {
			governmentFeesValue = governmentFees?.[0]?.amount as number;
		}

		const loanInfoState = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
		const newloanInfo = cloneDeep(loanInfoState);
		if (!newloanInfo) {
			return;
		}

		let totalAmountRequestedWithCapFees = 0;
		if (newloanInfo.isSplitLoan && newloanInfo.loanSplits?.length) {
			newloanInfo.loanSplits.forEach((split, index) => {
				if (split.loanSplit) {
					let capitalizedAmount = split.loanSplit?.splitAmount ?? 0;
					if (index === 0) {
						capitalizedAmount = capitalizedAmount + lmiPremiumValue + governmentFeesValue;
					}
					if (split.loanDetailId && loanFeeMap.has(split.loanDetailId)) {
						capitalizedAmount = capitalizedAmount + (loanFeeMap.get(split.loanDetailId) ?? 0);
					}
					split.loanSplit.splitAmountWithCapitalisedFees = capitalizedAmount;
					totalAmountRequestedWithCapFees += capitalizedAmount;

					this.updateSplitAmountWithCapitalizedFeesInBorrowingSection(
						split.loanDetailId,
						split.loanSplit.splitAmountWithCapitalisedFees
					);
				}
			});
		} else {
			totalAmountRequestedWithCapFees = (loanInfoState.amountRequested ?? 0) + lmiPremiumValue;
			if (newloanInfo.loanDetailId && loanFeeMap.has(newloanInfo.loanDetailId)) {
				totalAmountRequestedWithCapFees =
					totalAmountRequestedWithCapFees + (loanFeeMap.get(newloanInfo.loanDetailId) ?? 0);
			}
			totalAmountRequestedWithCapFees += governmentFeesValue;
		}

		newloanInfo.amountRequestedWithCapitalisedFees = totalAmountRequestedWithCapFees;
		this.simpFormlyHandlerService.updateToState('loanInformation', newloanInfo, 0);
	}

	calculateRepaymentsWithCapitalizedFeesLMI(loanDetailId?: number) {
		if (loanDetailId) {
			this.syncRateToBorrower$(loanDetailId, true).subscribe();
			return;
		}
		const loanInfo = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
		if (loanInfo.isSplitLoan) {
			loanInfo.loanSplits?.forEach((split) => {
				if (split.loanDetailId) {
					this.syncRateToBorrower$(split.loanDetailId, true).subscribe();
				}
			});
		} else if (loanInfo.loanDetailId) {
			this.syncRateToBorrower$(loanInfo.loanDetailId, true).subscribe();
		}
	}

	fetchSavings(): Observable<SavingModel[]> {
		return this.assetsAndLiabilitiesService.fetchSavings().pipe(
			tap((savings) => {
				this.updateSavingsEnums(savings);
				this.updateOtherSecurities(savings);
			})
		);
	}

	updateSavingsEnums(savings: SavingModel[]): void {
		this.simpFormlyHandlerService.upsertToFullPathWithData('financial-position-savings', savings);
		const enums: EnumObject[] = [];
		savings.forEach((saving) => {
			enums.push({
				id: saving.id as number,
				label: `${saving.details.extract}, ${formatCurrency(saving.summaryAmount as number, 'en-AU', '$')}`,
				info: saving.savingType?.toString()
			});
		});
		enums.push({
			id: CONSTANTS.NEW_ID,
			label: 'Create a new asset',
			info: CONSTANTS.NEW_ID.toString()
		});
		this.formEnumsService.updateFormEnums('Savings', enums);
	}

	updateOtherSecurities(savings: SavingModel[]): void {
		const assetsWithSecuritiesOnly: OtherSecuritiesModel[] = [];
		savings
			.filter((d) => d.details.savingDetails.assetToBeUsedAsType === AssetToBeUsedAsType.Security)
			.forEach((asset) => {
				assetsWithSecuritiesOnly.push(
					OtherSecuritiesModelTransformer.fromPayload(
						asset,
						this.applicationDataQuery.getApplicants(),
						this.formEnumQuery
					)
				);
			});

		this.simpFormlyHandlerService.upsertToStateWithData('otherSecurities', assetsWithSecuritiesOnly);
	}

	saveOtherSecurities(index: number, data: OtherSecuritiesModel): Observable<any> {
		const payload = OtherSecuritiesModelTransformer.toPayload(data, this.applicationDataQuery.applicationId());
		return this.postCustom(`Savings`, payload).pipe(
			switchMap((id: number) => {
				data.id = id;
				this.simpFormlyHandlerService.updateToState('otherSecurities', data, index);
				this.toastr.success(savedSuccessfullyMessage('Other securities'));
				return this.assetsAndLiabilitiesService.fetchSavings();
			}),
			tap((res) => {
				this.updateSavingsEnums(res);
				this.updateOtherSecurities(res);
			})
		);
	}

	private filterProductsWhenCertifiedValueChanged(propertyFilterParams: PropertyFilterParamsForProduct[]) {
		const existingPropertyValues = this.simpFormlyHandlerService
			.getStateData<PropertyAssetModel>('property')
			.map((property) => {
				property.certifiedValue ?? -1, property.contractPriceAmount ?? -1;
			});

		if (!isEqual(existingPropertyValues, propertyFilterParams)) {
			this.filterJourneyProducts().subscribe();
		}
	}

	private configureShowingFees(showLoanFees: boolean): void {
		if (!this.channelSettingsQuery.isLoanFeesEnabled()) {
			return;
		}
		this.loanDetailsFormDataService.updateFormStateForLoanDetails({ showLoanFees });
	}

	private updateLoanProductsEnums(loanProducts: LoanDetailJourneyProducts[], loanId?: number): void {
		if (!loanProducts || loanProducts.length === 0) {
			this.resetLoanProductEnums(loanId);
			return;
		}

		loanProducts.forEach((loanProduct) => {
			this.formEnumsService.updateFormEnums(
				`LoanProducts-${loanProduct.loanDetailId}`,
				(loanProduct.journeyProducts ?? []).map((product) =>
					this.transformerUtilService.generateLoanProductEnumObject(product)
				)
			);
		});
	}

	private resetLoanProductEnums(loanId?: number): void {
		if (loanId) {
			this.formEnumsService.updateFormEnums(`LoanProducts-${loanId}`, []);
		} else {
			// Reset for all loans
			const loanInfo = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
			if (loanInfo?.isSplitLoan) {
				loanInfo.loanSplits?.forEach((split) => {
					if (split?.loanDetailId) {
						this.formEnumsService.updateFormEnums(`LoanProducts-${split.loanDetailId}`, []);
					}
				});
			} else if (loanInfo?.loanDetailId) {
				this.formEnumsService.updateFormEnums(`LoanProducts-${loanInfo.loanDetailId}`, []);
			}
		}
	}

	private setInitialProductFeaturesList(
		loanId: number,
		features?: ProductFeatureDTO[],
		productVariationId?: number
	): Observable<any> {
		const productFeaturesEnum = (features ?? []).map((feature) => ({
			id: feature.featureEnum,
			label: feature.featureName
		}));
		this.formEnumsService.updateFormEnums(`ProductFeatures-${loanId}`, productFeaturesEnum);

		const isProductSelected = (productVariationId ?? -1) > 0;
		return this.updateLoanFeaturesSubsection(loanId, isProductSelected, features);
	}

	private updateLoanFeaturesSubsection(
		loanId: number,
		isProductSelected?: boolean,
		features?: ProductFeatureDTO[]
	): Observable<any> {
		const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
		if (!isProductSelected) {
			this.simpFormlyHandlerService.upsertToStateWithData(subsectionKey, [{ loanId, isProductSelected: false }]);
			return of([]);
		}

		if (!features?.length) {
			this.simpFormlyHandlerService.upsertToStateWithData(subsectionKey, [
				{ loanId, isProductSelected: true, featuresAvailable: false }
			]);
			return of([]);
		}

		const productDetailCalls = [] as Observable<any>[];

		const productFeatures = features.map((feature) => ProductFeatureTransformer.fromPayload(feature, loanId));
		this.simpFormlyHandlerService.upsertToStateWithData(subsectionKey, productFeatures);
		const indexOfProductWithOffset = productFeatures.findIndex(
			(feature) => feature.id === LoanFeature.Offset && feature.isFeatureSelected
		);

		if (indexOfProductWithOffset > -1) {
			productDetailCalls.push(this.getOffsetAccountDetails(loanId, indexOfProductWithOffset));
		}

		const creditCardFeatureIndex = productFeatures.findIndex(
			(feature) => feature.id === LoanFeature.CreditCard && feature.isFeatureSelected
		);

		if (creditCardFeatureIndex > -1) {
			productDetailCalls.push(this.getCreditCardApplication(loanId, creditCardFeatureIndex));
		}
		const lockedInterestRateIndex = productFeatures.findIndex(
			(feature) => feature.id === LoanFeature.RateLock && feature.isFeatureSelected
		);

		if (lockedInterestRateIndex > -1) {
			productDetailCalls.push(this.getLockedInterestRateDetails(loanId, lockedInterestRateIndex));
		}
		return productDetailCalls.length ? forkJoin(productDetailCalls) : of([]);
	}

	private getOffsetAccountDetails(loanId: number, indexOfProductWithOffset: number): Observable<any> {
		return this.fetchOffsetAccountDetails(loanId).pipe(
			tap((offsetDetails: OffsetDTO | null) => {
				if (offsetDetails) {
					const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
					const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);

					const loanFeature = cloneDeep(loanFeatures[indexOfProductWithOffset]);

					loanFeature.offsetAccountDetails = ProductFeatureTransformer.fromOffsetPayload(offsetDetails);
					this.simpFormlyHandlerService.updateToState(subsectionKey, loanFeature, indexOfProductWithOffset);
				}
			})
		);
	}
	private getLockedInterestRateDetails(loanId: number, indexOfProductWithOffset: number): Observable<any> {
		return this.fetchFixedRateLockedDetails(loanId).pipe(
			tap((lockedInterestRateDetails: FixedInterestRateLockDTO | null) => {
				if (lockedInterestRateDetails) {
					const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
					const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);

					const loanFeature = cloneDeep(loanFeatures[indexOfProductWithOffset]);

					loanFeature.fixedRateLock = ProductFeatureTransformer.fromLockedInterestPayload(lockedInterestRateDetails);

					this.simpFormlyHandlerService.updateToState(subsectionKey, loanFeature, indexOfProductWithOffset);
				}
			})
		);
	}

	private getCreditCardApplication(loanId: number, creditCardFeatureIndex: number): Observable<any> {
		return this.fetchCreditCardApplication(loanId).pipe(
			tap((creditCardApplication: CreditCardApplication) => {
				this.updateSelectedCreditCardLimits(creditCardApplication);
				const subsectionKey = this.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
				const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);
				const loanFeature = cloneDeep(loanFeatures[creditCardFeatureIndex]);
				loanFeature.creditCardApplication = creditCardApplication;
				this.simpFormlyHandlerService.updateToState(subsectionKey, loanFeature, creditCardFeatureIndex);
			})
		);
	}

	private getServiceabilitySummaryFromState(): Observable<ServiceabilityResult | undefined> {
		const result = this.simpFormlyHandlerService.getStateData<ServiceabilityResult>('loanServiceabilitySummary');
		if (result) {
			return of(result[0]);
		}
		return of(undefined);
	}

	private configureApplicationTitleInfoByLoanAmount() {
		this.configureApplicationTitleInfoByLoanAmount$().subscribe();
	}
	private configureApplicationTitleInfoByLoanAmount$(): Observable<boolean> {
		return this.loanDetailsFormDataService.selectTotalBorrowingAmount$().pipe(
			takeUntil(this.areaChanged$),
			distinctUntilChanged(),
			map(() => {
				this.appService.applicationTitleInfoChange.next();
				return true;
			})
		);
	}

	private updateStateLoanProducts(products: LoanDetailJourneyProducts[], loanId?: number): void {
		if (!loanId) {
			this.simpFormlyHandlerService.upsertToStateWithData('loanProducts', products);
			return;
		}

		const loanProduct = products[0];
		if (!loanProduct) {
			return;
		}

		const loanProducts = this.simpFormlyHandlerService.getStateData<LoanDetailJourneyProducts>('loanProducts');
		if (!loanProducts?.length) {
			this.simpFormlyHandlerService.upsertToStateWithData('loanProducts', [loanProduct]);
			return;
		}

		const allProducts = cloneDeep(loanProducts);

		let index = loanProducts.findIndex((product) => product.loanDetailId === loanProduct.loanDetailId);
		if (index === -1) {
			index = allProducts.length;
		}
		allProducts.splice(index, 1, loanProduct);

		this.simpFormlyHandlerService.upsertToStateWithData('loanProducts', allProducts);
	}

	private updateSelectedProductDetails(loanProducts: LoanDetailJourneyProducts[], loanId?: number) {
		if (!loanProducts || loanProducts.length === 0) {
			this.resetSelectedProducts(loanId);
			this.fetchFees();
		}

		const selectedProducts: JourneyProduct[] = [];
		const productsForReselect: Product[] = [];
		const toBeSavedProducts: JourneyProduct[] = [];

		loanProducts.forEach((loanProduct) => {
			const productStateKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanProduct.loanDetailId);
			const productInfo = this.simpFormlyHandlerService.getStateData<Product>(productStateKey)?.[0];
			if (productInfo) {
				if (!loanProduct.journeyProducts.length) {
					this.resetSelectedProduct(productInfo.loanId as number, productInfo);
					return;
				}

				const selectedProduct = loanProduct.journeyProducts.find(
					(product) => product.productVariationId === productInfo.preferredProductVariationId && product.selected
				);

				if (selectedProduct) {
					selectedProducts.push(selectedProduct);
					return;
				}

				const productMatched = loanProduct.journeyProducts.find((product) => product.selected);
				if (productMatched) {
					const clonedProduct = cloneDeep(productInfo);
					clonedProduct.preferredProductVariationId = productMatched.productVariationId;
					clonedProduct.preferredProductChannelId = productMatched.productChannelId;
					productsForReselect.push(clonedProduct);
					return;
				}

				toBeSavedProducts.push(loanProduct.journeyProducts[0]);
			}
		});

		// Update state for selected products
		selectedProducts.forEach((product) => {
			this.loanDetailsFormDataService.updateFormStateForSelectedLoanProduct({
				loanId: product.loanDetailId,
				productVariationId: product.productVariationId
			});
			this.updateFeesLinkedProductEnum(product.loanDetailId, product.productVariationId);
			this.updateProductDependents(product.loanDetailId);
		});

		// Products need to re select
		if (productsForReselect?.length) {
			productsForReselect.forEach((product) => {
				const stateKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, product.loanId);
				this.simpFormlyHandlerService.upsertToStateWithData(stateKey, [product]);
				this.updateFeesLinkedProductEnum(product.loanId as number, product.preferredProductVariationId as number);
				this.updateProductDependents(product.loanId as number);
			});
		}

		// Save selected products and reload dependents
		if (toBeSavedProducts.length > 0) {
			forkJoin(toBeSavedProducts.map((product) => this.saveSelectedJourneyProduct(product))).subscribe(() => {
				this.fetchFees();
				this.updateLendersMortgageInsurance();
			});
		} else if (selectedProducts.length || productsForReselect?.length) {
			this.fetchFees();
			this.updateLendersMortgageInsurance();
		} else {
			this.fetchFees();
		}
	}

	private resetSelectedProducts(loanId?: number) {
		if (loanId) {
			this.resetSelectedProduct(loanId);
		} else {
			// Reset for all loans
			const loanInfo = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
			if (loanInfo?.isSplitLoan) {
				loanInfo.loanSplits?.forEach((split) => {
					if (split?.loanDetailId) {
						this.resetSelectedProduct(split.loanDetailId);
					}
				});
			} else if (loanInfo?.loanDetailId) {
				this.resetSelectedProduct(loanInfo.loanDetailId);
			}
		}
	}

	private resetSelectedProduct(loanId: number, product?: Product) {
		const productSectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		if (!product) {
			product = this.simpFormlyHandlerService.getStateData<Product>(productSectionKey)?.[0];
		}
		if (product) {
			const updatedProduct = cloneDeep(product);
			this.resetProduct(updatedProduct);
			this.simpFormlyHandlerService.updateToState(productSectionKey, updatedProduct, 0);
		}
	}

	private resetProduct(product: Product) {
		product.preferredProductVariationId = undefined;
		product.preferredProductChannelId = undefined;
		product.loanFeatures = [];
	}

	private updateProductDependents(loanId: number, loadFees?: boolean): void {
		this.loadProductFeatures(loanId);
		this.loadDiscount(loanId);
		this.loadRepaymentFrequencies(loanId);
		this.loadCommision(loanId);
		this.loadRateToBorrower(loanId);
		this.fetchLoaderTypes(loanId);
		this.loadRateAdjustment(loanId);
		if (loadFees) {
			this.fetchFees();
			this.updateLendersMortgageInsurance();
		}
	}

	private updateFeesLinkedProductEnum(loanId: number, productId: number) {
		const linkedProducts = this.formEnumQuery.getOptions('FeesLinkedProducts');
		const optionIndex = linkedProducts?.findIndex((linkedProduct) => linkedProduct.id === loanId) ?? -1;

		if (productId > -1) {
			const loanProducts = this.formEnumQuery.getOptions(`LoanProducts-${loanId}`);
			const productOption = loanProducts?.find((loanProduct) => loanProduct.id === productId);
			if (productOption) {
				const newProductOption = Object.assign({}, productOption);
				newProductOption.id = Number(productOption.info);
				newProductOption.info = productOption.id.toString();

				optionIndex > -1
					? linkedProducts?.splice(optionIndex, 1, newProductOption)
					: linkedProducts?.push(newProductOption);
			}
		} else if (optionIndex > -1) {
			linkedProducts.splice(optionIndex, 1);
		}

		this.formEnumsService.updateFormEnums('FeesLinkedProducts', linkedProducts);

		this.configureShowingFees(!!linkedProducts?.length);
	}

	private isHideSubTermStatus(loanId: number): boolean {
		const productSectionKey = this.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		const product = this.simpFormlyHandlerService.getStateData<Product>(productSectionKey)?.[0];
		if (!product) return true;
		return !(product.interestType === InterestType.FixedRate || product.paymentType === PaymentType.InterestOnly);
	}

	private mapDiscount(discountDTO: DiscountDTO): Discount {
		const discount = DiscountTransformer.fromPayload(discountDTO);
		discount.isHideSubTerm = this.isHideSubTermStatus(discount.loanId);
		return discount;
	}

	private transformCustomAdjustmentsToEnums(customs: CustomLoaders[]): EnumObject[] {
		return customs.map((customLoader) => {
			return {
				id: customLoader.featureOptionCode,
				label: customLoader.adjustmentDetail,
				info: customLoader.featureCode.toString()
			} as EnumObject;
		});
	}

	private transformCreditCardTypesToEnums(creditCardTypes: CreditCardProductDTO[]): EnumObject[] {
		return creditCardTypes.map((creditCardType) => {
			return {
				id: creditCardType.id,
				label: creditCardType.creditCardName
			} as EnumObject;
		});
	}

	private updateSplitAmountWithCapitalizedFeesInBorrowingSection(
		loanDetailId: number | undefined,
		splitAmountWithCapitalisedFees: number | undefined
	) {
		if (!loanDetailId) return;
		const subSectionKey = this.getSplitSubsectionKey(LoanSubSection.BORROWING, loanDetailId);
		const borrowing = this.simpFormlyHandlerService.getStateData<BorrowingInfo>(subSectionKey)?.[0];
		const updatedBorrowing = cloneDeep(borrowing);
		updatedBorrowing.amountRequestedWithCapitalisedFees = splitAmountWithCapitalisedFees;
		this.simpFormlyHandlerService.updateToState(subSectionKey, updatedBorrowing, 0);
	}
}

interface LoadSpinner {
	type: SpinnerType;
	loadingStatus: boolean;
	loanId?: number;
}
