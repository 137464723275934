import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { NonRealEstateAssetType } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { PercentOwned, PersonApplicantDTO, RefiOtherAssetsDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiOtherAssetsTransformer {
	static toPayload(
		otherAsset: OtherAssetsDetails,
		applicationId: number,
		formEnumQuery: FormEnumsQuery
	): RefiOtherAssetsDTO {
		return {
			id: otherAsset.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			description: otherAsset.other,
			value: otherAsset.worth,
			type: otherAsset.typeOfAsset,
			percentsOwned: RefiTransformerHelper.setPercentsOwnedWithJointSelection(formEnumQuery, otherAsset.owner)
		};
	}

	static fromPayload(
		payload: RefiOtherAssetsDTO,
		applicants: PersonApplicantDTO[],
		description?: EnumObject[]
	): OtherAssetsModel {
		const assetTypeEnum = description?.find((x) => x.id === payload.type);
		const typeDescription =
			assetTypeEnum?.id === 1013 ? `${payload.description} (${assetTypeEnum.label})` : assetTypeEnum?.label;

		return {
			otherAssetsModal: {
				id: payload.id,
				owner: RefiTransformerHelper.getOwnership(payload.percentsOwned as PercentOwned[]),
				other: payload.description,
				typeOfAsset: payload.type,
				worth: payload.value
			},
			summary: `${RefiTransformerHelper.getJointApplicantNames(applicants, payload.percentsOwned)} ${formatCurrency(
				payload.value || 0,
				'en-AU',
				'$'
			)} ${typeDescription}`
		};
	}
}

export interface OtherAssetsDetails {
	id?: number;
	typeOfAsset?: NonRealEstateAssetType;
	other?: string;
	worth?: number;
	owner?: number;
}

export interface OtherAssetsModel {
	otherAssetsModal: OtherAssetsDetails;
	summary: string;
}
