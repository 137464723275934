<div class="d-flex flex-row w-100" *ngIf="showHeader && !headerField?.hideExpression">
	<div class="header-block w-100" [ngClass]="headerField?.className">
		<form [formGroup]="headerForm">
			<formly-form [form]="headerForm" [fields]="$any(headerField?.fieldGroup)" [options]="(options$ | async)!">
				<ng-template formlyTemplate="input" let-field>
					<span
						*ngIf="headerModel"
						class="header-block-content-box"
						[ngClass]="field?.props?.customClass ?? ''"
						[class.header-block-content-box-orange-bg]="headerModel.showTooltip"
						[class.header-block-content-box-header-bg]="!headerModel.showTooltip"
					>
						<span class="simp-margin-horizontal-base flex-grow-1"
							>${{ headerModel | mapToKey: field?.key | number }}</span
						>
						<span class="simp-margin-horizontal-base">{{ field.templateOptions?.label }}</span>
					</span>
				</ng-template>
			</formly-form>
		</form>
	</div>

	<div class="d-flex align-items-center" *ngIf="headerField?.templateOptions?.tooltip && headerModel?.showTooltip">
		<simp-info-icon
			class="info-icon"
			[placement]="'top-end'"
			[tooltipHtmlContent]="headerField?.templateOptions?.tooltip"
		></simp-info-icon>
	</div>
</div>
