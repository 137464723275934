import { transformSchemaToFormlyFields } from '@app/modules/shared/transformers/area-transformer.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { JSONSchema7 } from 'json-schema';
import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';
import { formlyInput } from './formly-input.helper';

export const formlySubSection = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	const summaryAmount = formlyInput({
		key: 'summaryAmount',
		class: 'hidden'
	});
	childProperties = childProperties ?? {};
	childProperties['summaryAmount'] = summaryAmount;
	const defaultValue = property.templateOptions?.defaultValue ?? (property.templateOptions?.required ? [{}] : []);

	let header: FormlyFieldConfig | undefined = undefined;
	let loaderText: FormlyFieldConfig | undefined = undefined;
	Object.keys(childProperties).forEach((key) => {
		if (childProperties && childProperties[key] && (childProperties[key] as JsonSchemaProperty).type === 'header') {
			const headerSchema = childProperties[key] as JSONSchema7;
			headerSchema.type = 'object';
			header = transformSchemaToFormlyFields(headerSchema);
			delete childProperties[key];
		}
		if (key === 'subsectionLoaderText' && childProperties && childProperties[key]) {
			const loaderTextSchema = childProperties[key] as JSONSchema7;
			loaderTextSchema.type = 'object';
			loaderText = transformSchemaToFormlyFields(loaderTextSchema);
			delete childProperties[key];
		}
	});

	return {
		type: 'array',
		title: property.title,
		subtitle: property.subtitle,
		widget: {
			formlyConfig: {
				type: 'sub-section',
				className: `${property.templateOptions?.class ?? (property.class || 'col-12')}`,
				templateOptions: {
					...property.templateOptions,
					collapsible: property.templateOptions?.collapsible ?? true,
					header,
					loaderText
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property),
				validation: {
					messages: {
						required: 'This field is mandatory',
						...(property.errorMessages || {})
					}
				},
				defaultValue
			}
		},
		items: {
			type: 'object',
			required: property.required,
			properties: childProperties,
			widget: {
				formlyConfig: {
					templateOptions: {
						...property.templateOptions,
						customClass: `simp-row ${property.templateOptions?.customClass || ''}`
					},
					resetOnHide: !!property.templateOptions?.resetOnHide
				}
			}
		}
	};
};
