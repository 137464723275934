import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExpensesStore } from '@app/modules/shared/store/expenses/expenses.store';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { EnumObject, FrequencyShort } from '@simpology/client-components/utils';
import { JSONSchema7 } from 'json-schema';
import { cloneDeep, get, isEqual } from 'lodash-es';
import { Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { HouseholdService } from '../applicants/services/household.service';
import { CONSTANTS } from '../shared/constants/constants';
import { ExpenseSource, ExpensesType } from '../shared/enums/app.enums';
import { isNullOrUndefined, savedSuccessfullyMessage } from '../shared/helper/util';
import { TextOption } from '../shared/model/text-option';
import { AggregateFormatterService } from '../shared/service/aggregate-formatter.service';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { DebounceKey, DebounceService } from '../shared/service/debounce.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { ApplicationDataService } from '../shared/store/application-data/application-data.service';
import { ChannelSettingQuery } from '../shared/store/channel-setting/channel-setting.query';
import { ExpensesQuery } from '../shared/store/expenses/expenses.query';
import { FormEnumsService } from '../shared/store/form-enums/form-enums.service';
import {
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	resolvedFormArray
} from '../simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '../simp-formly/helpers/typings/formly-api';
import { Amount, AmountSelect } from '../simp-formly/typings/formly-app';
import {
	DeclaredExpenseDTO,
	ExpenseFloorCommentDTO,
	ExpenseFloorsDTO,
	ExpenseTotalsDTO,
	Household
} from '../typings/api';
import {
	DeclaredExpenseModel,
	DeclaredExpenseTransformer,
	ExpenseHeaderModel,
	ExpensesCategory,
	ExpensesHouseholdTotal,
	HemCommentModel
} from './model/expenses.model';

@Injectable({ providedIn: 'root' })
export class FinancialPositionExpensesService extends BaseJourneyService {
	expensesAdded = false;

	private hasNavigated: Subject<boolean> = new Subject(); // Indicate if user has navigated to another UI component
	hasNavigated$ = this.hasNavigated.asObservable();

	private hasChanged: Subject<boolean> = new Subject();
	hasChanged$ = this.hasChanged.asObservable();

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsService: FormEnumsService,
		private applicationDataService: ApplicationDataService,
		private expensesStore: ExpensesStore,
		private expensesQuery: ExpensesQuery,
		private channelSettingQuery: ChannelSettingQuery,
		private formatter: AggregateFormatterService,
		private formlyJsonschema: FormlyJsonschema,
		private householdsService: HouseholdService,
		private debounceService: DebounceService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	triggerHasNavigated() {
		this.hasNavigated.next(true);
	}

	// resolved before we render the expenses
	fetchAllExpenses() {
		return forkJoin([
			this.fetchExpensesTotal(),
			this.fetchExpenseFloors(),
			this.fetchExpenseFloorComments(),
			this.fetchExpenseOwnershipOptions().pipe(switchMap(() => this.fetchDeclaredExpenses()))
		]);
	}

	refetchAfterUpdate() {
		return forkJoin([this.fetchDeclaredExpenses(), this.fetchExpensesTotal()]);
	}

	setupExpenses(formFields: FormlyFieldConfig[] | undefined) {
		const finalExpenseType = this.finalExpenseType();
		this.setupSectionHeader(formFields, finalExpenseType);
		this.setupDeclaredExpenses(formFields);
		return forkJoin([
			this.setupSubSections(formFields, finalExpenseType),
			this.setupHouseholdTotals(formFields, finalExpenseType)
		]).pipe(
			map(([expenseCategories, expenseHouseholdTotals]) => {
				return { expenseCategories, expenseHouseholdTotals };
			})
		);
	}

	fetchExpensesTotal(): Observable<ExpenseTotalsDTO[]> {
		return this.getCustom(`Expense/Totals/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((res: ExpenseTotalsDTO[]) => {
				this.expensesStore.updateTotals(res);
				// There is always an empty total row
				this.expensesAdded = res.length > 1;
			})
		);
	}

	fetchExpenseFloors(): Observable<ExpenseFloorsDTO[]> {
		return this.getCustom(`Expense/ExpenseFloor/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((res: ExpenseFloorsDTO[]) => {
				this.expensesStore.updateExpenseFloors(res);
			})
		);
	}

	deleteExpenseFloorComment(model: HemCommentModel) {
		return this.delete(
			`Expense/ExpenseFloorComment/${this.applicationDataQuery.applicationId()}/${model.householdId}/${
				model.expenseCategory
			}`
		);
	}

	fetchExpenseFloorComments() {
		return this.getCustom(`Expense/ExpenseFloorComment/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((res: ExpenseFloorCommentDTO[]) => {
				this.expensesStore.updateExpenseFloorComments(res);
			})
		);
	}

	saveExpenseFloorComment(model: HemCommentModel) {
		return this.postCustom(`Expense/ExpenseFloorComment`, {
			comment: model.editComment,
			applicationId: this.applicationDataQuery.applicationId(),
			householdId: model.householdId,
			expenseCategory: model.expenseCategory
		});
	}

	private setupSectionHeader(formFields: FormlyFieldConfig[] | undefined, finalExpenseType: ExpensesType) {
		const expensesField = getFormField(formFields, 'expenses');
		const expenseSectionHeaderFieldsSchema = get(
			expensesField,
			'templateOptions.properties.expenseSectionHeaderFields'
		) as JSONSchema7 | undefined;
		const sectionHeaderFields = expenseSectionHeaderFieldsSchema
			? this.formlyJsonschema.toFieldConfig(expenseSectionHeaderFieldsSchema, resolvedFormArray)
			: undefined;
		if (!sectionHeaderFields) {
			return;
		}
		(sectionHeaderFields.templateOptions as FormlyTemplateOptions).expenseType = finalExpenseType;
		if (expensesField?.templateOptions) {
			expensesField.templateOptions.expenseSectionHeaderFields = sectionHeaderFields;
		}

		formlyExtendExpressionProperties(sectionHeaderFields.fieldGroup, 'digitalTotal', {
			'templateOptions.tooltipText': (model: AmountSelect, formState: unknown, f: FormlyFieldConfig) => {
				const expenseModel = f.parent?.model as ExpenseHeaderModel;
				return expenseModel?.differenceInDigitalTotalValuesText;
			}
		});

		formlyExtendExpressionProperties(sectionHeaderFields.fieldGroup, 'declaredTotal', {
			'templateOptions.tooltipText': (model: ExpenseHeaderModel, formState: unknown, f: FormlyFieldConfig) => {
				const expenseModel = f.parent?.model as ExpenseHeaderModel;
				return expenseModel?.differenceInDeclaredToDigitalTotal;
			}
		});

		formlyRegisterHooks(sectionHeaderFields.fieldGroup, 'showDeclared', {
			onInit: (showDeclaredField) => {
				return showDeclaredField?.formControl?.valueChanges.pipe(
					tap((value: boolean) => {
						this.updateShowDeclared(value);
						// TODO: When not show declared data, we should hide all declared fields, currently not working
						// if (!value) {
						// 	this.updateIsDigital(ExpenseSource.Digital);
						// }
					})
				);
			}
		});
	}

	private onToggleClick(field: FormlyFieldConfig) {
		const model = field.model as ExpenseHeaderModel;
		if (model.expenseSource === ExpenseSource.Declared) {
			this.updateIsDigital(model.categoryId, model.expenseSource);
		} else {
			this.triggerHasNavigated();
			setTimeout(() => {
				this.refetchAfterUpdate().subscribe();
				this.updateIsDigital(model.categoryId, model.expenseSource);
			}, 100);
		}
	}

	private setupHouseholdTotals(formFields: FormlyFieldConfig[] | undefined, finalExpenseType: ExpensesType) {
		const expensesField = getFormField(formFields, 'expenses');
		const expenseHeaderFieldsSchema = get(expensesField, 'templateOptions.properties.expenseTotalFields') as
			| JSONSchema7
			| undefined;
		if (!expenseHeaderFieldsSchema) {
			return of([]);
		}
		const expenseHouseholdTotals: ExpensesHouseholdTotal[] = [];
		const applicationId = this.applicationDataQuery.applicationId();
		return this.householdsService.get(`${applicationId}`).pipe(
			map((households: Household[]) => {
				const householdEnum = this.applicationDataQuery.getHouseHoldAndApplicants();
				households
					.filter((household) =>
						householdEnum.find((householdEnumOption) => householdEnumOption.value.includes(household.id.toString()))
					)
					.forEach((household) => {
						const field = this.formlyJsonschema.toFieldConfig(cloneDeep(expenseHeaderFieldsSchema), resolvedFormArray);
						if (field?.templateOptions) {
							field.templateOptions.expenseType = finalExpenseType;
						}
						expenseHouseholdTotals.push({
							expanded: false,
							loading: false,
							model$: this.expensesQuery.selectExpenseDetailsByHouseholdId$(household.id),
							fields: field,
							options: { formState: {} },
							id: household.id,
							label: household.name as string
						});
					});
				return expenseHouseholdTotals;
			})
		);
	}

	private setupSubSections(formFields: FormlyFieldConfig[] | undefined, finalExpenseType: ExpensesType) {
		const expensesField = getFormField(formFields, 'expenses');
		const expenseHeaderFieldsSchema = get(expensesField, 'templateOptions.properties.expenseHeaderFields') as
			| JSONSchema7
			| undefined;
		const expenseCategoriesList: ExpensesCategory[] = [];
		if (!expenseHeaderFieldsSchema) {
			return of([]);
		}
		return this.formEnumsService.fetch('ExpenseCategory', true).pipe(
			map((expenseCategories) => {
				expenseCategories.forEach((expenseCategory: EnumObject) => {
					const field = this.formlyJsonschema.toFieldConfig(cloneDeep(expenseHeaderFieldsSchema), resolvedFormArray);
					if (field.templateOptions) {
						field.templateOptions.expenseType = finalExpenseType;
					}
					const expenseToggleOptions = field.fieldGroup?.find((fg) => fg.key === 'expenseSource')
						?.templateOptions as FormlyTemplateOptions;
					expenseToggleOptions.click = this.onToggleClick.bind(this);

					if (finalExpenseType === ExpensesType.DeclaredOnly) {
						// amount select input here
						formlyRegisterHooks(field.fieldGroup, 'declaredEdit', {
							onInit: (declaredEditField) => {
								return declaredEditField?.formControl?.valueChanges.pipe(
									debounceTime(50), //NOTE: Must have a debounce time or the value changes will not be tracked correctly
									distinctUntilChanged((a, b) => isEqual(a, b)),
									tap((value: AmountSelect) => {
										const declaredEditFormControl = declaredEditField?.formControl as UntypedFormControl;
										if (!declaredEditFormControl.disabled && !declaredEditFormControl.pristine) {
											this.saveFromCollapsedView(value, expenseCategory.id);
										}
										declaredEditFormControl.markAsUntouched();
										declaredEditFormControl.markAsPristine();
									})
								);
							}
						});
					} else if (finalExpenseType === ExpensesType.Both) {
						// only amount input here
						formlyRegisterHooks(field.fieldGroup, 'declaredTotal', {
							onInit: (declaredTotalField) => {
								return declaredTotalField?.formControl?.valueChanges.pipe(
									debounceTime(50), //NOTE: Must have a debounce time or the value changes will not be tracked correctly
									distinctUntilChanged((a: number, b: number) => isEqual(a, b)),
									tap((value: number) => {
										const declaredTotalFormControl = declaredTotalField.formControl as UntypedFormControl;
										if (!declaredTotalFormControl.disabled && !declaredTotalFormControl.pristine) {
											const model = field?.parent?.model as {
												digitalTotal: Amount<number>;
												declaredTotal: number;
												differenceInDeclaredToDigitalTotal: string;
												differenceInDigitalTotalValuesText: string;
											};
											const declaredThreshold = this.channelSettingQuery.getDigitalDeclaredThresholdValue();
											const declaredToDigitalDifference = this.expensesStore.getDeclaredToDigitalTotalDifference(
												declaredThreshold,
												value,
												model.digitalTotal.amount
											);
											if (declaredTotalField.props) {
												declaredTotalField.props.tooltipText = declaredToDigitalDifference;
											}
											this.saveFromCollapsedView(
												{ amount: value, frequency: FrequencyShort.Monthly },
												expenseCategory.id
											);
										}
										declaredTotalFormControl.markAsUntouched();
										declaredTotalFormControl.markAsPristine();
									})
								);
							}
						});
					}

					formlyExtendExpressionProperties(field.fieldGroup, 'declaredTotal', {
						'templateOptions.tooltipText': (model: ExpenseHeaderModel) => {
							return model.differenceInDeclaredToDigitalTotal;
						}
					});

					formlyExtendExpressionProperties(field.fieldGroup, 'digitalTotal', {
						'templateOptions.tooltipText': (model: ExpenseHeaderModel) => {
							return model.differenceInDigitalTotalValuesText;
						}
					});

					const declaredExpensesFields = get(
						expensesField,
						'templateOptions.declaredExpensesFields'
					) as FormlyFieldConfig;
					const declaredExpense = getFormField(declaredExpensesFields?.fieldGroup, 'declaredExpense');
					expenseCategoriesList.push({
						formGroup: new UntypedFormGroup({}),
						isDigital: false,
						expanded: false,
						loading: false,
						model$: combineLatest([
							this.expensesQuery
								.selectTotals(expenseCategory.id)
								.pipe(
									distinctUntilChanged(
										(a, b) =>
											(a.declaredTotal === b.declaredTotal && isEqual(a.digitalTotal, b.digitalTotal)) ||
											((b.totalCount ?? 0) < 2 && !(b.shouldUpdate === true))
									)
								),
							this.expensesQuery.showDeclared$
						]).pipe(
							map(([headerModel, showDeclared]) => {
								if (!showDeclared) {
									headerModel.expenseSource = ExpenseSource.Digital;
									this.updateIsDigital(headerModel.categoryId, headerModel.expenseSource);
								}
								headerModel.showDeclared = showDeclared as boolean;
								return cloneDeep(headerModel);
							})
						),
						fields: field,
						options: { formState: {} },
						id: expenseCategory.id,
						label: expenseCategory.label,
						totalCount$: this.expensesQuery.totalCount(expenseCategory.id),
						hemBanners$: this.expensesQuery.hemBanners(expenseCategory.id),
						info: expenseCategory.info,
						static: declaredExpense?.templateOptions?.static as boolean,
						refetchLoading$: new Subject()
					});
				});
				return expenseCategoriesList;
			})
		);
	}

	private setupDeclaredExpenses(formFields: FormlyFieldConfig[] | undefined) {
		const expensesField = getFormField(formFields, 'expenses');
		const declaredFormFieldsSchema = get(expensesField, 'templateOptions.properties.declaredExpensesFields') as
			| JSONSchema7
			| undefined;
		if (!declaredFormFieldsSchema) {
			return;
		}
		const fields = this.formlyJsonschema.toFieldConfig(cloneDeep(declaredFormFieldsSchema), resolvedFormArray);

		formlyOnClick(fields.fieldGroup, 'declaredExpense.delete', (field: FormlyFieldConfig) => {
			this.deleteDeclaredExpense(field);
		});

		formlyExtendExpressionProperties(fields.fieldGroup, 'declaredExpense.value', {
			'templateOptions.arrowBoxText': (model: AmountSelect, formState: unknown, field: FormlyFieldConfig) => {
				const form = field?.parent?.formControl as UntypedFormControl;
				const declaredExpenseModel = form.value as DeclaredExpenseModel;

				return declaredExpenseModel.arrowBoxText;
			}
		});
		formlyExtendExpressionProperties(fields.fieldGroup, 'declaredExpense', {
			hide: (model: DeclaredExpenseModel, formState: unknown, field: FormlyFieldConfig) => {
				if (field.fieldGroup?.length) {
					field.fieldGroup.forEach((row: FormlyFieldConfig) => {
						row.className = (row.model as DeclaredExpenseModel).hide ? 'hidden' : '';
					});
				}
				return model.hide;
			}
		});

		formlyExtendExpressionProperties(fields.fieldGroup, 'declaredExpense.ownership', {
			'templateOptions.options': () => {
				const householdId = this.expensesQuery.selectedHouseholdId();
				// Only show ownership options based on selected household filter
				const ownerOptions: TextOption[] = this.applicationDataService
					.getHouseHoldAndApplicationsEnum()
					.map((owner, index) => ({ ...owner, id: index }))
					.filter((owner) => !householdId || householdId === 0 || owner.value.includes(householdId.toString()));

				return ownerOptions;
			}
		});

		formlyOnStatusChangedToValid(
			fields.fieldGroup,
			`declaredExpense`,
			(field: FormlyFieldConfig, event: FormlyArrayUpdateEvent<DeclaredExpenseModel | AmountSelect>) => {
				this.hasChanged.next(true);
				const expenseModel = cloneDeep(event.data) as DeclaredExpenseModel;
				expenseModel.isTotal = false;
				const categoryId = get(field, `parent.templateOptions.categoryId`) as number;
				const expenseArray = cloneDeep(field.model) as DeclaredExpenseModel[];
				if (expenseArray?.length > 1 && expenseArray[0].isTotal === true) {
					expenseArray[0].isTotal = false;
				}
				this.debounceSaveDeclaredExpenses(categoryId, expenseArray);
			}
		);

		if (expensesField?.templateOptions) {
			expensesField.templateOptions.declaredExpensesFields = fields;
		}
	}

	private debounceSaveDeclaredExpenses(categoryId: number, expenseArray: Partial<DeclaredExpenseModel>[]): void {
		this.debounceService.debounceOrTriggerOnEvent(
			`${DebounceKey.DeclaredExpenses}-${categoryId}-save`,
			this.hasNavigated$,
			() => {
				return this.saveDeclaredExpenses(categoryId, expenseArray).pipe(
					tap(() => {
						this.debounceService.debounce(DebounceKey.DeclaredExpenses, () => this.fetchExpensesTotal());
						return of(null);
					})
				);
			},
			900
		);
	}

	private saveFromCollapsedView(amountSelect: AmountSelect, expenseCategory: number): void {
		this.hasChanged.next(true);
		const householdId = this.expensesQuery.selectedHouseholdId();
		const field = this.expensesQuery.getExpenseField(expenseCategory, householdId);
		const ownershipEnum = this.applicationDataService.getHouseHoldAndApplicationsEnum();
		if (field?.isTotal === false) {
			field.id = CONSTANTS.NEW_ID;
		}

		const expenseModel: Partial<DeclaredExpenseModel> = {
			description: field?.description,
			value: amountSelect,
			// Set as field ownership, if field doesn't exist yet get ownership from householdId, if none (filtered to all), get first owner
			ownership: field?.ownership
				? field.ownership
				: householdId
				? ownershipEnum.findIndex((owner) => owner.value.includes(householdId.toString()))
				: 0,
			isTotal: true,
			id: field?.id || CONSTANTS.NEW_ID
		} as Partial<DeclaredExpenseModel>;
		this.debounceSaveDeclaredExpenses(expenseCategory, [expenseModel]);
	}

	private finalExpenseType(): ExpensesType {
		return this.channelSettingQuery.getExpenseType();
	}

	private deleteDeclaredExpense = (field: FormlyFieldConfig): void => {
		this.hasChanged.next(true);
		const declaredExpensesFieldsModel = field.parent?.parent?.parent?.model as { categoryId: number };
		const categoryId = declaredExpensesFieldsModel?.categoryId;
		if (!categoryId) {
			console.error('Missing expense category');
			return;
		}

		formlyDeleteFromArray(field);
		const expenseArray = cloneDeep(field.parent?.parent?.model) as DeclaredExpenseModel[];
		if (expenseArray?.length > 0) {
			const isTotal = expenseArray.length === 1;
			expenseArray.forEach((expense) => {
				expense.id = CONSTANTS.NEW_ID;
				expense.isTotal = isTotal;
			});
			this.saveDeclaredExpenses(categoryId, expenseArray)
				.pipe(
					tap(() => {
						this.debounceService.debounce(DebounceKey.DeclaredExpenses, () => this.fetchExpensesTotal(), 2000);
						return of(null);
					})
				)
				.subscribe();
		} else {
			this.deleteByCategory(categoryId).subscribe(() => {
				this.debounceService.debounce(DebounceKey.DeclaredExpenses, () => this.fetchExpensesTotal(), 2000);
			});
		}
	};

	private updateIsDigital(expenseCategory?: number, expenseSource?: ExpenseSource): void {
		this.expensesStore.updateIsDigital(expenseCategory, expenseSource);
	}

	private updateShowDeclared(showDeclared: boolean): void {
		this.expensesStore.updateShowDeclared(showDeclared);
	}

	private saveDeclaredExpenses(
		categoryId: number,
		expenseModels: Partial<DeclaredExpenseModel>[]
	): Observable<number | null> {
		const ownershipEnum = this.applicationDataService.getHouseHoldAndApplicationsEnum();
		let lastSortOrder = -1; // Initialize to -1 to start from 0 for the first item
		expenseModels.forEach((expenseModel) => {
			expenseModel.sortOrder = expenseModel.sortOrder !== undefined ? expenseModel.sortOrder : lastSortOrder + 1;
			lastSortOrder = expenseModel.sortOrder; // Update lastSortOrder for the next iteration
		});
		const payload = expenseModels.map((expenseModel) => {
			const transformedExpense = DeclaredExpenseTransformer.toPayload(
				expenseModel,
				this.applicationDataQuery.applicationId(),
				categoryId
			);
			const ownership = ownershipEnum[expenseModel.ownership || 0]?.value;
			if (isNullOrUndefined(ownership)) {
				console.error('Missing household ownership details');
				return of(null);
			} else {
				transformedExpense.ownership = ownership;
			}
			return transformedExpense;
		});

		return this.postCustom(`Expense/SaveMulti/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			tap((expenseId: number) => {
				if (expenseId !== null) {
					this.toastr.success(savedSuccessfullyMessage('Expense'));
				}
			}),
			catchError(() => {
				return of(null);
			})
		);
	}

	private fetchDeclaredExpenses() {
		const isDigitalOnly = this.finalExpenseType() === ExpensesType.DigitalOnly;
		if (isDigitalOnly) {
			return of([]);
		}
		return this.getCustom(`Expense/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((declaredExpenses: DeclaredExpenseDTO[]) => {
				const householdsAndApplicants = this.applicationDataService.getHouseHoldAndApplicationsEnum();
				const transformedExpenses = (declaredExpenses || [])
					.map((expense) => {
						return DeclaredExpenseTransformer.fromPayload(
							expense,
							householdsAndApplicants,
							this.formatter,
							this.channelSettingQuery.getDigitalDeclaredThresholdValue()
						);
					})
					.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)); // Sort by sortOrder
				this.expensesStore.updateDeclaredExpenses(transformedExpenses);
			})
		);
	}

	private fetchExpenseOwnershipOptions(): Observable<TextOption[]> {
		return this.getCustom(`Expense/ExpenseOwnerOptions/${this.applicationDataQuery.applicationId()}`).pipe(
			map((ownershipOptions: TextOption[]) => {
				const ownershipEnum = ownershipOptions.map((x: TextOption, index: number) => {
					return {
						id: index,
						label: x.label
					} as EnumObject;
				});
				this.formEnumsService.updateHouseholdAndApplicantsEnum(ownershipEnum);
				this.applicationDataService.updateHouseHoldAndApplicantEnum(ownershipOptions);
				return ownershipOptions;
			})
		);
	}

	private deleteByCategory(expenseCategory: number): Observable<void> {
		return <Observable<void>>(
			this.delete(`Expense/DeleteByCategory/${this.applicationDataQuery.applicationId()}/${expenseCategory}`)
		);
	}
}
