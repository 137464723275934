import { Injectable } from '@angular/core';
import { CurrentUserQuery } from '@app/modules/shared/store/current-user/current-user.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import { formlyExtendExpressionProperties, getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, Observable, startWith, switchMap } from 'rxjs';
import { RefiApplicationSummaryService } from '../../components/refi-form/application-summary/application-summary.service';
import { RefiApplicationStatusIndex } from '../../enums/refi-application-status.enum';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyApplicationSummaryTransformerService {
	constructor(
		private applicationSummaryService: RefiApplicationSummaryService,
		private currentUserQuery: CurrentUserQuery,
		private stepStatusQuery: StepStatusQuery,
		private formDataService: FormDataService,
		private simpFormlyHandlerService: SimpFormlyHandlerService
	) {}

	public applicationSummaryTransform(formFields: FormlyFieldConfig[]) {
		formlyExtendExpressionProperties(formFields, `summary.details`, {
			'templateOptions.label': () => {
				return this.applicationSummaryService.checkIndexChange.asObservable().pipe(
					startWith(null),
					switchMap(
						() =>
							this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.ApplicationSummary) as Observable<
								RefiApplicationSummaryDTO[]
							>
					),
					map((appStatus) => {
						const statusIndex = appStatus?.[0]?.statusId;
						const currentStatusIndex: RefiApplicationStatusIndex | undefined = this.formDataService
							.getRefiApplicationStatuses()
							.find((status) => status.id === statusIndex)?.index;

						let labelName = 'applicationStatusTitle';
						switch (currentStatusIndex) {
							case RefiApplicationStatusIndex.UPLOAD_DOCUMENTS:
								labelName = 'applicationDocUploadTitle';
								break;
							case RefiApplicationStatusIndex.ASSESSMENT:
								labelName = 'applicationAssessmentTitle';
								break;
							case RefiApplicationStatusIndex.DECISION:
								labelName = 'applicationDecisionTitle';
								break;
							default:
								break;
						}
						const label = getFormField(formFields, `summary.details.applicationSummaryLabels.${labelName}`)?.props
							?.label;
						return label?.replace('ApplicantFirstName', this.currentUserQuery.firstName());
					})
				);
			}
		});

		const applicantEditing = getFormField(
			formFields,
			'summary.details.applicationSummaryLabels.otherApplicantEditingText'
		);
		const otherApplicant = this.formDataService.getRefiPersonApplicants().find((applicant) => !applicant.isCurrentUser);
		if (applicantEditing?.templateOptions?.label && otherApplicant) {
			applicantEditing.templateOptions.label = applicantEditing.templateOptions.label.replace(
				'OtherApplicant',
				`${otherApplicant.firstName}`
			);
		}

		if (!!this.stepStatusQuery.getValue() && this.stepStatusQuery.isCompleted(RefiStepType.RefiUploadDocuments)) {
			const summary = getFormField(formFields, 'summary.details');
			if (summary?.templateOptions) {
				summary.templateOptions.prevRoute = 'review/details';
			}
			const processTitle = getFormField(formFields, 'summary.details.applicationSummaryLabels.applicationProcessTitle');
			if (processTitle?.templateOptions) {
				processTitle.templateOptions.label = 'Application status';
			}
		}
	}
}
