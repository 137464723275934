import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { AddressService } from '@app/modules/shared/service/address.service';
import { FormAddressesQuery } from '@app/modules/shared/store/form-addresses/form-addresses.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { Address } from '@app/modules/typings/api';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FullAddress } from '@simpology/client-components/lib/simp-address-search/typings/form-addresses';
import {
	CountryCode,
	EnumObject,
	PlaceType,
	SimpAddress,
	SimpAddressForm,
	SimpAddressHelper
} from '@simpology/client-components/utils';
import { BehaviorSubject, Subject, filter, map, of, takeUntil, tap } from 'rxjs';
import { FormlyFieldTypes } from '../../enums/formly-field-types';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-address-select',
	templateUrl: './formly-address-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAddressSelectComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	countries: Array<{ id: number; label: string }> = [];
	nonStandardCountries: Array<{ id: number; label: string }> = [];
	allowNonStandard = false;
	poBoxTypes: EnumObject[] = [];
	existingAddresses$ = this.formAddressesQuery.selectAddresses$.pipe(
		tap((addresses) => {
			const address = this.formControl.value as Address;
			const fullAddress = address?.id ? addresses.find((a) => a.id === address.id) : null;
			if (address?.id !== (this.selectedAddressId.value as FullAddress)?.id) {
				if (fullAddress || this.to.readonly) {
					this.selectedAddressId.setValue(fullAddress);
				} else {
					this.selectedAddressId.setValue(null);
				}
			}
		}),
		map((addresses) => {
			addresses.push({
				id: CONSTANTS.NEW_ID,
				label: 'Add new address'
			});
			return addresses;
		})
	);
	placeType = new BehaviorSubject(PlaceType.Standard);
	selectedAddressId = new UntypedFormControl();
	uniqueId = '';
	defaultManual: Partial<SimpAddressForm> = {};
	markAsTouched = new Subject();

	private destroy$ = new Subject<void>();

	constructor(
		private addressService: AddressService,
		private formAddressesQuery: FormAddressesQuery,
		private formEnumsQuery: FormEnumsQuery,
		private formValidationsService: FormValidationsService
	) {
		super();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	ngOnInit(): void {
		// Update address control errors
		this.formControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.formControl.disabled
				? this.selectedAddressId.disable({ emitEvent: false })
				: this.selectedAddressId.enable({ emitEvent: false });
			this.selectedAddressId.setErrors(this.formControl.errors);
		});
		// Set default manual form address values
		if (this.to.handler) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.defaultManual = JSON.parse(this.to.handler as string);
		}
		this.uniqueId = generateUniqueKey(this.field);

		if (this.to.placeTypes && Object.values(PlaceType).includes(this.to.placeTypes)) {
			this.placeType.next(this.to.placeTypes);
		}

		if (this.to.disabled) {
			this.selectedAddressId.disable();
			this.formControl.disable();
		}

		if (!this.to.options) {
			const allowedCountries = this.to.countries as string;
			const allCountries = this.formEnumsQuery.getOptions('CountryCode');
			this.countries = allowedCountries
				? allCountries.filter((x) => {
						return allowedCountries.toUpperCase().includes(CountryCode[x.id]);
				  })
				: allCountries;
			if ((this.to.allowNonStandard as string)?.toLowerCase() === 'true') {
				this.nonStandardCountries = allCountries;
				this.allowNonStandard = true;
			} else if (this.to.allowNonStandard && this.to.allowNonStandard !== 'false') {
				this.nonStandardCountries = allCountries.filter((x) => {
					return (this.to.allowNonStandard as string)?.toUpperCase().includes(CountryCode[x.id]);
				});
				this.allowNonStandard = true;
			} else {
				this.allowNonStandard = false;
			}
		} else {
			this.countries = this.formEnumsQuery.getOptions(this.to.options as unknown as string);
		}

		this.selectedAddressId.valueChanges.subscribe((address: FullAddress) => {
			if (address?.address && SimpAddressHelper.isValidAddress(address.address, this.placeType.value)) {
				this.formControl.setValue({ ...address.address, id: address.id });
				this.formControl.markAsDirty();
			} else if (address && address?.id !== -1) {
				this.selectedAddressId.setErrors({ error: true });
			} else {
				this.formControl.reset(null);
			}
		});

		this.formValidationsService.triggerFieldValidations$
			.pipe(
				takeUntil(this.destroy$),
				filter((field: FormlyFieldConfig) => field.type === FormlyFieldTypes.AddressSelect && field === this.field),
				tap(() => {
					this.touchAddressFormControl();
				})
			)
			.subscribe();

		this.formValidationsService.triggerValidations$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.touchAddressFormControl();
		});

		this.poBoxTypes = this.formEnumsQuery.getOptions('PoBoxType');
	}

	saveAddress = (address: SimpAddress) => {
		if (address && this.to.saveAddressOnChange && SimpAddressHelper.isValidAddress(address, this.placeType.value)) {
			return this.addressService.saveAddress(address).pipe(
				map((id) => {
					const newAddress = { address, id, label: SimpAddressHelper.buildAddressLine(address, true) };

					this.selectedAddressId.setValue(address);
					this.selectedAddressId.markAsDirty();
					return newAddress;
				})
			);
		}

		this.selectedAddressId.setValue({ address });
		this.selectedAddressId.markAsDirty();

		if (this.to.change) {
			this.to.change(this.field);
		}

		return of();
	};

	private touchAddressFormControl() {
		this.markAsTouched.next(true);
		this.selectedAddressId.markAllAsTouched();
		this.formControl.markAllAsTouched();
		this.formControl.markAsDirty();
	}
}
