import { YesNone } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { PersonApplicantDTO } from '@app/modules/typings/api';
import { PersonApplicant } from '../typings/applicants';

export class PersonalDetailsTransformer {
	static fromPayload(
		applicant: PersonApplicantDTO,
		titleLabel: string,
		consentInitiated: boolean
	): PersonalDetailsModel {
		return {
			title: applicant.title,
			clientId: applicant?.clientId?.toString(),
			fullName: `${titleLabel ?? ''} ${applicant.firstName} ${applicant.middleNames ?? ''} ${
				applicant.lastName
			}`.replace('  ', ' '),
			fullNameWithoutTitle: `${applicant.firstName} ${applicant.middleNames ?? ''} ${applicant.lastName}`.replace(
				'  ',
				' '
			),
			isProspect: applicant.isProspect,
			firstName: applicant.firstName,
			middleNames: applicant.middleNames,
			lastName: applicant.lastName,
			otherName: applicant.knownAs ? YesNone.Yes : YesNone.None,
			knownAs: applicant.knownAs,
			gender: applicant.gender,
			hasPreviousName:
				applicant.nameChangeReason ||
				applicant.previousNameTitleId ||
				applicant.previousFirstName ||
				applicant.previousMiddleNames ||
				applicant.previousSurname
					? YesNone.Yes
					: YesNone.None,
			nameChangeReason: applicant.nameChangeReason,
			dateOfBirth: applicant.dateOfBirth,
			countryOfBirth: applicant.countryOfBirth,
			firstHomeBuyer: getNullableYesNo(applicant.firstHomeBuyer),
			consentInitiated: consentInitiated,
			previousNameTitleId: applicant.previousNameTitleId,
			previousFirstName: applicant.previousFirstName,
			previousMiddleNames: applicant.previousMiddleNames,
			previousSurname: applicant.previousSurname,
			allowCreditCheck: getNullableYesNo(applicant.allowCreditCheck),
			allowElectronicIdentityCheck: getNullableYesNo(applicant.allowElectronicIdentityCheck),
			allowCreditBureauIdentityCheck: getNullableYesNo(applicant.allowCreditBureauIdentityCheck),
			privacyActConsentSigned: getNullableYesNo(applicant.privacyActConsentSigned),
			intendedRetirementAge: applicant.intendedRetirementAge
		};
	}

	static toPayload(applicant: PersonApplicant): Partial<PersonApplicantDTO> {
		const personalDetailsModel = applicant.personalDetails?.length ? applicant.personalDetails[0] : null;
		return personalDetailsModel
			? {
					title: personalDetailsModel.title,
					clientId: personalDetailsModel.clientId?.toString(),
					isProspect: personalDetailsModel.isProspect,
					firstName: personalDetailsModel.firstName || applicant.firstName,
					middleNames: personalDetailsModel.middleNames,
					lastName: personalDetailsModel.lastName || applicant.lastName,
					knownAs: personalDetailsModel.otherName === YesNone.Yes ? personalDetailsModel.knownAs : undefined,
					nameChangeReason:
						personalDetailsModel.hasPreviousName === YesNone.Yes ? personalDetailsModel.nameChangeReason : undefined,
					gender: personalDetailsModel.gender,
					dateOfBirth: personalDetailsModel.dateOfBirth,
					countryOfBirth: personalDetailsModel.countryOfBirth,
					firstHomeBuyer: !!personalDetailsModel.firstHomeBuyer,
					previousNameTitleId:
						personalDetailsModel.hasPreviousName === YesNone.Yes ? personalDetailsModel.previousNameTitleId : undefined,
					previousFirstName:
						personalDetailsModel.hasPreviousName === YesNone.Yes ? personalDetailsModel.previousFirstName : undefined,
					previousMiddleNames:
						personalDetailsModel.hasPreviousName === YesNone.Yes ? personalDetailsModel.previousMiddleNames : undefined,
					previousSurname:
						personalDetailsModel.hasPreviousName === YesNone.Yes ? personalDetailsModel.previousSurname : undefined,
					hasPreviousName: personalDetailsModel.hasPreviousName === YesNone.Yes ? true : false,
					intendedRetirementAge: personalDetailsModel.intendedRetirementAge
			  }
			: {
					title: applicant.title,
					firstName: applicant.firstName,
					lastName: applicant.lastName
			  };
	}
}
export interface PersonalDetailsModel {
	title: number;
	fullName?: string;
	clientId?: string;
	fullNameWithoutTitle?: string;
	firstName?: string;
	middleNames?: string;
	lastName?: string;
	otherName: YesNone;
	knownAs?: string;
	gender: number | undefined;
	hasPreviousName: YesNone;
	nameChangeReason?: string;
	dateOfBirth: string;
	countryOfBirth: number | undefined;
	firstHomeBuyer: number | undefined;
	editName?: {
		firstName?: string;
		middleNames?: string;
		lastName?: string;
	};
	editDateOfBirth?: {
		dateOfBirth: string | undefined;
	};
	consentInitiated: boolean;
	previousNameTitleId?: number;
	previousFirstName?: string;
	previousMiddleNames?: string;
	previousSurname?: string;
	allowCreditCheck?: number;
	allowElectronicIdentityCheck?: number;
	allowCreditBureauIdentityCheck?: number;
	privacyActConsentSigned?: number;
	intendedRetirementAge?: number;
	isProspect: boolean;
}
