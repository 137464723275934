import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-button-input',
	templateUrl: './formly-button-input.component.html',
	styleUrls: ['./formly-button-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyButtonInputComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
	@ViewChild('content') public contentTemplate?: TemplateRef<unknown>;
	@ViewChild('buttonInput') public buttonInput: ElementRef = {} as ElementRef;
	@ViewChild('buttonInputText') public buttonInputText: ElementRef = {} as ElementRef;

	extract$ = of('');
	uniqueId = '';

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	public get isEllipsis(): boolean {
		const nativeElement = this.buttonInputText.nativeElement as HTMLElement;
		return nativeElement ? nativeElement.offsetWidth < nativeElement.scrollWidth : false;
	}

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		this.extract$ = this.formControl.valueChanges.pipe(
			startWith(this.formControl.value as { extract: string }),
			map((model) => (model as { extract: string })?.extract)
		);
	}

	ngAfterViewInit(): void {
		this.changeDetectorRef.detectChanges();
		if (this.to.disabled) {
			if (this.buttonInput.nativeElement) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				this.buttonInput.nativeElement.disabled = true;
			}
		}
	}

	onClick(event: Event): void {
		event.stopPropagation();
		if (this.to.click) {
			this.to.click(this.field);
		}
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}

	clearClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'clearClick' });
		}
	}
}
