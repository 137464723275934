import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '@app/modules/shared/constants/constants';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';

@Pipe({
	name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
	constructor(private aggregateFormatterService: AggregateFormatterService) {}

	transform(date: string | Date | null | undefined, format?: DateFormat, withTimeZone?: boolean): string {
		return this.aggregateFormatterService.formatDate(date, format, withTimeZone);
	}
}
