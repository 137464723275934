import { Pipe, PipeTransform } from '@angular/core';
import DOMPurify from 'dompurify';

@Pipe({
	name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {
	transform(value: string | null | undefined): string {
		if (value) {
			const parsedString = new DOMParser().parseFromString(value, 'text/html');
			const removeTags = [];
			for (let index = 0; index < parsedString.images.length; index++) {
				if (parsedString.images[index].src.includes('.html')) {
					removeTags.push(index);
				}
			}
			removeTags.reverse().forEach((i) => {
				parsedString.images[i].parentNode?.removeChild(parsedString.images[i]);
			});
			return DOMPurify.sanitize(parsedString.body.innerHTML);
		}
		return value || '';
	}
}
