import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
	parse(value: string): NgbDateStruct | null {
		if (value) {
			const dateParts = value.trim().split('/');
			if (dateParts.length === 3 && !isNaN(+dateParts[0]) && !isNaN(+dateParts[1]) && !isNaN(+dateParts[2])) {
				return { day: +dateParts[0], month: +dateParts[1], year: +dateParts[2] };
			} else if (value.match(/^\d{8}$/)) {
				return { day: +value.slice(0, 2), month: +value.slice(2, 4), year: +value.slice(4, 8) };
			} else {
				return null;
			}
		}
		return null;
	}

	format(date: NgbDateStruct): string {
		return date
			? `${date.day ? this.appendZero(date.day) : ''}/${date.month ? this.appendZero(date.month) : ''}/${date.year}`
			: '';
	}

	appendZero(dateMonth: number): string {
		return `${dateMonth}`.length === 1 ? `0${dateMonth}` : `${dateMonth}`;
	}
}

@Injectable()
export class NgbDateCustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '-';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				year: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				day: parseInt(date[2], 10)
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		return date
			? String(date.year) +
					this.DELIMITER +
					String(date.month).padStart(2, '0') +
					this.DELIMITER +
					String(date.day).padStart(2, '0')
			: null;
	}
}
