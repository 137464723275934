import { Injectable } from '@angular/core';
import { AddressService } from '@app/modules/shared/service/address.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsStore } from '@app/modules/shared/store/form-enums/form-enums.store';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	RefiCreditCardDTO,
	RefiHomeLoanDTO,
	RefiOtherLiabilityDTO,
	RefiPersonalLoanDTO,
	WithinMbcResponseModelDTO
} from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { Observable, forkJoin, map, of } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';

import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { RefiCreditCardModel, RefiCreditCardTransformer } from '../../models/refi-credit-card-model';
import { RefiHomeLoanModel, RefiHomeLoansTransformer } from '../../models/refi-home-loans.model';
import { RefiOtherLiabilitiesTransformer, RefiOtherLiabilityModel } from '../../models/refi-otherliabilites.model';
import { RefiPersonalLoanModel, RefiPersonalLoanTransformer } from '../../models/refi-personal-loan.model';
import { RefiAssetsService } from '../assets/refi-assets.service';

@Injectable({
	providedIn: 'root'
})
export class RefiLiabilitiesService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumQuery: FormEnumsQuery,
		private addressService: AddressService,
		private assetsService: RefiAssetsService,
		private formEnumsStore: FormEnumsStore,
		private formDataService: FormDataService,
		private sharedFlagService: SharedFlagsService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.assetsService
			.fetchPropertyAssets()
			.pipe(
				map((properties) => {
					return properties.map((property) => {
						return {
							id: property.propertyModal.realEstateAssetId,
							label: property.propertyModal.address?.unformattedAddress
						} as EnumObject;
					});
				})
			)
			.subscribe((properties) => {
				this.formEnumsStore.update({ Assets: properties });
				this.fetchLiabilities();
			});
	}

	fetchLiabilities(): void {
		this.formDataService.setLoading(RefiStore.Liabilities, true);

		forkJoin([
			this.fetchHomeLoans(),
			this.fetchPersonalLoan(),
			this.fetchOtherLiabilities(),
			this.fetchCreditCards()
		]).subscribe(([homeLoans, personalLoans, otherLiabilities, creditCards]) => {
			this.simpFormlyHandlerService.upsertToState(
				'details',
				of([
					{
						homeLoans: homeLoans,
						personalLoans: personalLoans,
						creditCards: creditCards,
						otherLiabilities: otherLiabilities
					}
				])
			);
		});
	}
	fetchOtherLiabilities(): Observable<RefiOtherLiabilityModel[]> {
		return this.getCustom(`OtherLiability/${this.applicationDataQuery.applicationId()}`).pipe(
			map((otherLiabilities: RefiOtherLiabilityDTO[]) => {
				return otherLiabilities.map((otherLiability) =>
					RefiOtherLiabilitiesTransformer.fromPayload(
						otherLiability,
						this.formEnumQuery,
						this.applicationDataQuery.getRefiApplicants()
					)
				);
			})
		);
	}

	fetchCreditCards(): Observable<RefiCreditCardModel[]> {
		return this.getCustom(`CreditCard/${this.applicationDataQuery.applicationId()}`).pipe(
			map((otherLiabilities: RefiCreditCardDTO[]) => {
				return otherLiabilities.map((otherLiability) =>
					RefiCreditCardTransformer.fromPayload(
						otherLiability,
						this.formEnumQuery,
						this.applicationDataQuery.getRefiApplicants()
					)
				);
			})
		);
	}

	fetchHomeLoans(): Observable<RefiHomeLoanModel[]> {
		return this.getCustom(`HomeLoan/${this.applicationDataQuery.applicationId()}`).pipe(
			map((homeLoans: RefiHomeLoanDTO[]) => {
				const transformedHomeLoans = homeLoans.map((homeLoan) =>
					RefiHomeLoansTransformer.fromPayload(
						homeLoan,
						this.formEnumQuery,
						this.applicationDataQuery.getRefiApplicants(),
						this.sharedFlagService.currentJourney
					)
				);
				// Store these loans
				this.simpFormlyHandlerService.upsertToFullPathWithData(RefiStore.Liabilities, [
					{
						homeLoans: transformedHomeLoans
					}
				]);
				return transformedHomeLoans;
			})
		);
	}

	fetchPersonalLoan(): Observable<RefiPersonalLoanModel[]> {
		return this.getCustom(`PersonalLoan/${this.applicationDataQuery.applicationId()}`).pipe(
			map((personalLoans: RefiPersonalLoanDTO[]) => {
				return personalLoans.map((personalLoan) =>
					RefiPersonalLoanTransformer.fromPayload(
						personalLoan,
						this.formEnumQuery,
						this.applicationDataQuery.getRefiApplicants()
					)
				);
			})
		);
	}

	withinMaximumBorrowingCapacity(): Observable<WithinMbcResponseModelDTO> {
		return <Observable<WithinMbcResponseModelDTO>>(
			this.getCustom(`Application/WithinMaximumBorrowingCapacity/${this.applicationDataQuery.applicationId()}`)
		);
	}

	public savePersonalLoan(payload: RefiPersonalLoanModel): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`PersonalLoan`,
				RefiPersonalLoanTransformer.toPayload(payload, this.applicationDataQuery.applicationId(), this.formEnumQuery)
			)
		);
	}

	public deletePersonalLoan(id: number): Observable<void> {
		return this.delete(`PersonalLoan/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}

	public saveOtherLiability(payload: RefiOtherLiabilityModel): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`OtherLiability`,
				RefiOtherLiabilitiesTransformer.toPayload(
					payload,
					this.applicationDataQuery.applicationId(),
					this.formEnumQuery
				)
			)
		);
	}

	public deleteOtherLiability(id: number): Observable<void> {
		return this.delete(`OtherLiability/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}

	public saveCreditCard(payload: RefiCreditCardModel): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`CreditCard`,
				RefiCreditCardTransformer.toPayload(payload, this.applicationDataQuery.applicationId())
			)
		);
	}

	public deleteCreditCard(id: number): Observable<void> {
		return this.delete(`CreditCard/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}

	public saveHomeLoan(payload: RefiHomeLoanModel): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`HomeLoan`,
				RefiHomeLoansTransformer.toPayload(
					payload.homeLoansModal,
					this.applicationDataQuery.applicationId(),
					this.formEnumQuery
				)
			)
		);
	}

	public deleteHomeLoan(id: number): Observable<void> {
		return this.delete(`HomeLoan/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}
}
