import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { of } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiHomeLoanModel } from '../../models/refi-home-loans.model';
import { RefiLiabilitiesModel } from '../../models/refi-liabilities.model';

@Injectable({
	providedIn: 'root'
})
export class RefiLoanDetailsService extends BaseJourneyService {
	constructor(private formDataService: FormDataService, private simpFormlyHandlerService: SimpFormlyHandlerService) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		// Only populate from liabilities if not already populated from previous choice in choose loans
		if (!(this.simpFormlyHandlerService.getStateDataFullPath(RefiStore.LoanDetails)?.[0] as RefiLiabilitiesModel)) {
			const loans = (
				this.simpFormlyHandlerService.getStateDataFullPath(RefiStore.Liabilities)?.[0] as RefiLiabilitiesModel
			)?.homeLoans;
			this.formDataService.upsertStateWithAsyncData(
				RefiStore.LoanDetails,
				of([loans.find((loan) => loan.homeLoansModal.realEstateAssetId)?.homeLoansModal])
			);
		}
	}
}
