import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { isArray, isNil } from 'lodash-es';
import { combineLatest, isObservable, Observable, startWith, Subject, take, takeUntil } from 'rxjs';

import { EnumObjectWithClick } from '@app/modules/shared/enums/enum-helper';
import { SimpSelectComponent } from '@simpology/client-components';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { SelectOptionsPipe } from '../../pipes/formly-select-options.pipe';
import { InputActionType } from '@app/modules/shared/enums/app.enums';

@Component({
	selector: 'formly-select',
	templateUrl: './formly-select.component.html',
	styleUrls: ['./formly-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySelectComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('select') simpSelect!: SimpSelectComponent;
	uniqueId = '';
	valueString = '';
	expanded = false;

	private destroy$: Subject<void> = new Subject();

	constructor(private selectOptionsPipe: SelectOptionsPipe, private cdr: ChangeDetectorRef) {
		super();
	}

	get enum() {
		const formatter = this.field.props?.formatter as string;
		return isNullOrUndefined(formatter) || formatter === 'enum';
	}

	get enumWithInfo() {
		const formatter = this.field.props?.formatter as string;
		return formatter === 'enumWithInfo';
	}

	get infoWithEnum() {
		const formatter = this.field.props?.formatter as string;
		return formatter === 'infoWithEnum';
	}

	get items(): Observable<EnumObject[]> {
		const dynamicOptions = this.props.dynamicOptions as (number | string)[] | null;
		if (isArray(dynamicOptions) && dynamicOptions?.length) {
			// Set value to first available if filter list changes and no longer includes selected value
			if (
				this.formControl.value &&
				!dynamicOptions.includes('^') &&
				!dynamicOptions.includes(this.formControl.value as number)
			) {
				this.formControl.setValue(dynamicOptions[0], { onlySelf: true });
			}
		}
		return this.selectOptionsPipe.transform(this.props.options as string | EnumObject[]);
	}

	onItemClick(event: Event, item: EnumObjectWithClick): void {
		if (item.click) {
			event.stopPropagation();
			this.simpSelect.ngSelect.close();
			item.click(this.field);
		}
	}

	onChange(value: EnumObject) {
		this.valueString = value?.label;
		if (this.props.change) {
			this.props.change(this.field);
		}
	}

	/**
	 * On enter key press on item
	 * @param event
	 */
	enterKeydown() {
		if (!this.simpSelect.ngSelect.isOpen && this.props.click) {
			this.props.click(this.field);
		}
	}

	searchFn = (term: string, item: EnumObject): boolean => {
		const searchTerm = term.toLowerCase().trim();

		return (
			item.info?.toLowerCase().trim().includes(searchTerm) || item.label?.toLowerCase().trim().includes(searchTerm)
		);
	};

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		// If form control doesn't have a value, prepolate based on template options
		if (isNil(this.formControl.value)) {
			if (this.props.selectFirstOption) {
				setTimeout(() => {
					this.items.pipe(take(1)).subscribe((items) => {
						this.formControl.setValue(items[0]?.id);
					});
					this.formControl.markAsDirty(); // Mark as dirty so the ChangeDetection in Subsection will trigger and API will be called
				});
			} else if (!isNil(this.props.defaultValue)) {
				// Set Timeout so that it will run after the Subsection has been setupChangeDetection
				setTimeout(() => {
					this.formControl.setValue(this.props.defaultValue);

					this.formControl.markAsDirty(); // Mark as dirty so the ChangeDetection in Subsection will trigger and API will be called
				});
			}
		}
	}

	ngAfterViewInit(): void {
		if (this.field.props?.notFoundText) {
			this.simpSelect.ngSelect.notFoundText = String(this.field.props.notFoundText);
		}

		this.cdr.markForCheck();

		if (!(this.props.options instanceof Array)) {
			this.clearFormControlIfValueNotInList();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	isObservable(tooltip: string | Observable<string>): boolean {
		return isObservable(tooltip);
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.props.click) {
			this.props.click(field, { type: 'tooltipClick' });
		}
	}

	handleEditClick(field: FormlyFieldConfig) {
		if (this.props.click) {
			this.props.click(field, InputActionType.EDIT);
		}
	}

	/**
	 * Only do this when "options" is a string - ie, a RefList name.
	 * For scenarios where the options list is based on another field's value, the handling needs
	 * to be done in the respective transformer service.
	 * ie, Set formControl value as null/undefined if it does not exist in the options list.
	 */
	private clearFormControlIfValueNotInList() {
		// subscribing to "items" ensures the options are loaded (to cater for slow internet)
		combineLatest([this.items, this.formControl.valueChanges.pipe(startWith(undefined))])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([items, value]) => {
				setTimeout(() => {
					if (
						items?.length &&
						this.simpSelect.ngSelect.selectedValues.length &&
						(this.simpSelect.ngSelect.selectedValues[0] as EnumObject).label === null
					) {
						this.formControl.setValue(undefined, { emitEvent: false });
					}
				});
			});
	}
}
