/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable } from '@angular/core';
import { ApplicantsService } from '@app/modules/applicants/services/applicants.service';
import { ManageParticipantsService } from '@app/modules/setup/services/manage-participants.service';
import { SetupService } from '@app/modules/setup/services/setup.service';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SummaryBaseTransformerService } from './summary-base-transformer.service';

@Injectable({ providedIn: 'root' })
export class SetupSummaryTransformerService extends SummaryBaseTransformerService {
	constructor(
		private manageParticipantsService: ManageParticipantsService,
		private applicantsService: ApplicantsService,
		private setupService: SetupService
	) {
		super();
	}

	transform(formFields: FormlyFieldConfig | undefined): Observable<FormlyFieldConfig | undefined> {
		if (!formFields) {
			return of(formFields);
		}

		return forkJoin({
			managementParticipants: this.transformManagementParticipants(formFields),
			applicants: this.transformApplicantParticipants(formFields)
		}).pipe(
			map(() => {
				return formFields;
			})
		);
	}

	private transformManagementParticipants(formFields: FormlyFieldConfig) {
		const managementParticipantsSection = getFormField(formFields?.fieldGroup, 'managementParticipants');

		return this.manageParticipantsService.fetchManagedParticipant().pipe(
			map((participants) => {
				if (managementParticipantsSection) {
					this.transformFieldConfig(managementParticipantsSection, participants, 'managementParticipants');
				}
			})
		);
	}

	private transformApplicantParticipants(formFields: FormlyFieldConfig) {
		const applicants$ = this.setupService.fetchSetupApplicants();
		const applicantsSection = getFormField(formFields?.fieldGroup, 'applicants');
		return applicants$.pipe(
			map((participants) => {
				if (applicantsSection) {
					this.transformFieldConfig(applicantsSection, participants, 'applicants');
				}
			})
		);
	}
}
