import { Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { formlyOnClick, formlyRegisterHooks, getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BrandingService } from '@simpology/authentication';
import { Observable, take } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiProducts } from '../../models/refi-product-select.model';
import { RefiJourneyService } from '../refi-journey.service';

@Injectable({
	providedIn: 'root'
})
export class RefiProductSelectionTransformerService {
	private productsListMessage: string | undefined;
	constructor(
		private brandingService: BrandingService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private refiJourneyService: RefiJourneyService,
		private refiModalService: RefiModalService
	) {}

	transform(formFields: FormlyFieldConfig[]) {
		formlyRegisterHooks(formFields, 'products.details', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					this.openRefiModal(formFields, RefiModalName.beforeYouChoose).subscribe();
					this.productsListMessage = field.templateOptions?.subText as string | undefined;
					this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.ProductSelection)?.subscribe(() => {
						const hasProducts = (field.model as RefiProducts[])[0]?.products?.some((product) => !!product);

						if (field.templateOptions) {
							field.templateOptions.subText = hasProducts
								? this.productsListMessage
								: field.templateOptions?.placeholder;
						}

						if (field.templateOptions?.primaryButtonText) {
							field.templateOptions.primaryButtonText = this.refiJourneyService.isAdminUser
								? 'Continue'
								: hasProducts
								? null
								: (field.templateOptions?.primaryButtonText as string);
						}
						if (field.templateOptions?.secondaryButtonText) {
							field.templateOptions.secondaryButtonText =
								hasProducts || this.refiJourneyService.isAdminUser
									? null
									: (field.templateOptions?.secondaryButtonText as string);
						}
					});
				}
			}
		});

		formlyOnClick(formFields, 'products.details', (field: FormlyFieldConfig, event: { type: string }) => {
			if (event.type === 'primary') {
				if (this.refiJourneyService.isAdminUser) {
					this.refiJourneyService.navigateToNextStep(field);
					return;
				}

				const bookAppointmentLinkField = getFormField(formFields, 'products.details.bookAppointmentLink');
				if (
					bookAppointmentLinkField &&
					bookAppointmentLinkField.templateOptions &&
					bookAppointmentLinkField.templateOptions.label
				) {
					window.open(bookAppointmentLinkField.templateOptions.label, '_blank');
				}
			}
			if (event.type === 'secondary') {
				this.brandingService.brandData.pipe(take(1)).subscribe((res) => {
					if (res) {
						window.location.href = 'tel://' + res.contactPhone;
					}
				});
			}
		});

		return formFields;
	}

	private openRefiModal(
		formFields: FormlyFieldConfig[] | undefined,
		refiModalName: RefiModalName,
		target?: string
	): Observable<string> {
		const refiModalField = getFormField(formFields, 'products.refi-modals');
		return this.refiModalService.openRefiModal(refiModalField, refiModalName, target);
	}
}
