import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { PersonApplicantDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class PersonContactDetailsTransformer {
	static fromPayload(applicant: PersonApplicantDTO, consentInitiated: boolean): PersonContactDetailsModel {
		return {
			mobilePhoneNumber: PhoneNumberHelper.fromPayload(applicant.mobilePhone),
			homePhoneNumber: PhoneNumberHelper.fromPayload(applicant.homePhone),
			workPhoneNumber: PhoneNumberHelper.fromPayload(applicant.workPhone),
			faxNumber: PhoneNumberHelper.fromPayload(applicant.fax),
			email: applicant.email,
			preferredContact: applicant.preferredContact,
			informedConsent: applicant.informedConsent === null ? undefined : applicant.informedConsent ? 1 : 0,
			consentInitiated: consentInitiated
		};
	}

	static toPayload(personContactDetailsModel: PersonContactDetailsModel): Partial<PersonApplicantDTO> {
		return {
			email: personContactDetailsModel.email,
			mobilePhone: PhoneNumberHelper.toPayLoad(personContactDetailsModel.mobilePhoneNumber),
			homePhone: PhoneNumberHelper.toPayLoad(personContactDetailsModel.homePhoneNumber),
			workPhone: PhoneNumberHelper.toPayLoad(personContactDetailsModel.workPhoneNumber),
			fax: PhoneNumberHelper.toPayLoad(personContactDetailsModel.faxNumber),
			preferredContact: personContactDetailsModel.preferredContact,
			informedConsent: isNullOrUndefined(personContactDetailsModel.informedConsent)
				? undefined
				: !!personContactDetailsModel.informedConsent
		};
	}
}

export interface PersonContactDetailsModel {
	mobilePhoneNumber?: PhoneDetails;
	homePhoneNumber?: PhoneDetails;
	workPhoneNumber?: PhoneDetails;
	faxNumber?: PhoneDetails;
	email?: string;
	preferredContact?: number;
	informedConsent?: number;
	editMobile?: {
		mobilePhoneNumber?: PhoneDetails;
	};
	editEmail?: {
		email?: string;
	};
	consentInitiated: boolean;
}
