import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ENV_CONFIG } from '@app/env-config';
import { AuthService, BrandingService } from '@simpology/authentication';
import { User } from 'oidc-client';
import { Observable, Subject, from, takeUntil } from 'rxjs';

import { CONSTANTS } from '../constants/constants';
import { ExtraAuthIdentityService } from './extra-auth-identity.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
	private UserIdKey = 'simp:userId';
	private ChannelIdKey = 'simp:channelId';
	private destroy$: Subject<void> = new Subject();

	constructor(
		private extraAuthIdentityService: ExtraAuthIdentityService,
		private authService: AuthService,
		private brandingService: BrandingService,
		private router: Router
	) {}

	goToChannelSelection(oneTimeAccessCode: string, channelId: number): void {
		void this.authService.login({
			extraQueryParams: {
				oneTimeAccessCode,
				channelId,
				goToChannelSelection: true
			}
		});
	}

	logOutToChannelSelection(): void {
		if (this.authService.isExternalUser) {
			this.logOut();
			return;
		}
		void this.authService.signoutToSwitch({
			extraQueryParams: {
				goToChannelSelection: true
			}
		});
	}

	logIn(): void {
		this.brandingService.brandData.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			if (res) {
				sessionStorage.clear();
				const options = {
					extraQueryParams: {
						brandLogo: this.brandingService.brandData.getValue()?.brandUrl
					}
				};
				void this.authService.login(this.brandingService.brandData.getValue() ? options : null);
			}
		});
		if (!this.brandingService.brandData.getValue()?.brandUrl) {
			this.brandingService.setBranding(ENV_CONFIG, true);
		}
	}

	logInWithOtac(otac: string, returnUrl: string): void {
		this.extraAuthIdentityService.getUserIdByAccessCode(otac).subscribe((user) => {
			if (user?.userId > 0) {
				this.setLogInUser(user.channelId, user.userId);
				this.setReturnUrl(returnUrl);
				void this.authService.login({
					extraQueryParams: {
						oneTimeAccessCode: otac,
						channelId: user.channelId
					}
				});
			} else {
				this.logOut();
			}
		});
	}

	async completeAuthentication(): Promise<void> {
		await this.authService.completeAuthentication();
		const previousChannelId = sessionStorage.getItem(this.ChannelIdKey);
		const previousUserId = sessionStorage.getItem(this.UserIdKey);
		if (
			(previousChannelId &&
				+previousChannelId > 0 &&
				this.authService.channelId > 0 &&
				this.authService.channelId !== +previousChannelId) ||
			(previousUserId && +previousUserId > 0 && this.authService.id > 0 && this.authService.id !== +previousUserId)
		) {
			this.logOut();
			return;
		}
		await this.router.navigateByUrl(this.authService.returnUrl);
	}

	logOut(logOutRedirectUri?: string): void {
		sessionStorage.removeItem('application-data');
		sessionStorage.removeItem('journeyType');
		const param = logOutRedirectUri
			? {
					extraQueryParams: {
						logOutRedirectUri: logOutRedirectUri
					}
			  }
			: null;
		void this.authService.signout(param);
	}

	getSessionUser(): Observable<User | null> {
		return from(this.authService.manager.getUser());
	}

	setReturnUrl(returnUrl: string): void {
		this.authService.returnUrl = returnUrl || CONSTANTS.LOANAPP_CLIENT_LANDING_URL;
	}

	getAndClearReturnUrl(): string {
		const returnUrl = this.authService.returnUrl;

		this.setReturnUrl('');

		return returnUrl;
	}

	setLogInUser(channelId: number, userId: number): void {
		sessionStorage.setItem(this.ChannelIdKey, channelId.toString());
		sessionStorage.setItem(this.UserIdKey, userId.toString());
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
