import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';

import { ApplicationSaveResult } from '@app/modules/setup/typings/setup';
import { JourneyParams } from '@app/modules/typings/loanapp-application';
import { JourneyType } from '../enums/app.enums';
import { LoanApplication, LoanApplicationDetail } from '../model/loan-application.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class ApplicationService extends BaseJourneyService<any> {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.APPLICATION);
	}

	getAppIdByKey(appGuid: string): Observable<number> {
		return <Observable<number>>this.getCustom(`GetApplicationIdByKey/${appGuid}`).pipe(catchError(() => of(null)));
	}

	getUserApplications(includingAppId?: number, searchTerm?: string): Observable<LoanApplication[]> {
		const appIdQueryString = includingAppId ?? 0 > 0 ? `includingApplicationId=${includingAppId}` : '';
		const searchQueryString = searchTerm ? `searchTerm=${searchTerm}` : '';
		const queryString = this.buildQueryString(appIdQueryString, searchQueryString);
		if (queryString) {
			return <Observable<LoanApplication[]>>this.getCustom(`GetUserApplications/?${queryString}`);
		}
		return <Observable<LoanApplication[]>>this.getCustom(`GetUserApplications`);
	}

	getApplicationTitleInfo(applicationId: number): Observable<LoanApplication> {
		return <Observable<LoanApplication>>this.getCustom(`GetApplicationTitleInfo/${applicationId}`);
	}

	getJourneys(): Observable<JourneyType[]> {
		return <Observable<JourneyType[]>>this.getCustom(`GetSubJourneys/${JourneyType.FullApplication}`);
	}

	getUserServiceabilityApplications(includingAppId?: number, searchTerm?: string): Observable<LoanApplication[]> {
		const appIdQueryString = includingAppId ?? 0 > 0 ? `includingApplicationId=${includingAppId}` : '';
		const searchQueryString = searchTerm ? `searchTerm=${searchTerm}` : '';
		const queryString = this.buildQueryString(appIdQueryString, searchQueryString);
		if (queryString) {
			return <Observable<LoanApplication[]>>this.getCustom(`GetUserServiceabilityApplications/?${queryString}`);
		}
		return <Observable<LoanApplication[]>>this.getCustom(`GetUserServiceabilityApplications`);
	}

	getBasicApplicationInfo(appId: number): Observable<LoanApplication> {
		return <Observable<LoanApplication>>this.getCustom(`GetApplicationBasicInfo/${appId}`);
	}

	getApplicationInfo(appId: number): Observable<LoanApplicationDetail> {
		return <Observable<LoanApplicationDetail>>this.getCustom(`GetApplicationInfo/${appId}`);
	}

	createApplication(journeyType: JourneyType, journeyDetails: JourneyParams): Observable<ApplicationSaveResult> {
		return <Observable<ApplicationSaveResult>>(
			this.postCustom(
				`${URL_CONSTANTS.CREATE_FOR_JOURNEY}/${journeyType ?? JourneyType.FullApplication}`,
				journeyDetails
			)
		);
	}

	convertToFullApplication(applicationId: number): Observable<void> {
		return <Observable<void>>this.postCustom(`PromoteToFullApp/${applicationId}`, {});
	}

	saveCustomerSignedApplication(hasLenderAcknowledgedSignedDocument: boolean) {
		return this.postCustom(
			`${this.applicationDataQuery.applicationId()}/LenderAcknowledgedSignedDocument/${hasLenderAcknowledgedSignedDocument}`,
			{}
		);
	}

	private buildQueryString(...params: string[]) {
		return params.filter((p) => p).join('&');
	}
}
