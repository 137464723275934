import { AdditionalRepaymentsDTO } from '@app/modules/typings/api';
import { Importance } from '@app/modules/shared/enums/app.enums';

export class AdditionalRepaymentsTransformer {
	static toPayload(applicationId: number, repayments: AdditionalRepaymentsModel): AdditionalRepaymentsDTO {
		return {
			applicationId: applicationId,
			importance: repayments.additionalRepayments
		};
	}

	static fromPayload(data: AdditionalRepaymentsDTO): AdditionalRepaymentsModel {
		const repayments: AdditionalRepaymentsModel = {
			additionalRepayments: data.importance
		};
		return repayments;
	}
}

export interface AdditionalRepaymentsModel {
	additionalRepayments: Importance;
}

export interface AdditionalRepaymentsStoreModel {
	additionalRepaymentsContainer: AdditionalRepaymentsModel;
}
