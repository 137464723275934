import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RelatedCompanyModel } from '@app/modules/applicants/models/related-company.model';
import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { ApplicantSelfEmploymentModel } from '@app/modules/financial-position/model/applicant-self-employment.model';
import { EmploymentModel } from '@app/modules/financial-position/model/employment.model';
import { SelfEmploymentModel } from '@app/modules/financial-position/model/self-employment.model';
import { formlyOnClick, formlyRegisterHooks, getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { Subscription, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { ApplicantKeyType, RelatedCompanyType, TargetType } from '../../enums/app.enums';
import { ApplicantEnumObject } from '../../enums/enum-helper';
import { getFinancialYearEndDate, getFinancialYearStartDate, parseArea } from '../../helper/util';
import { RelatedCompanyFinancialModal, RelatedCompanyFinancials } from '../../model/related-company-financial.model';
import { PersonsCompaniesEnumService } from '../../service/persons-companies-enum.service';
import { RelatedCompanyFinancialsService } from '../../service/related-company-financials-service';
import { RelatedEntitiesService } from '../../service/related-entities.service';
import { ApplicationDataQuery } from '../../store/application-data/application-data.query';
import { FormEnumsQuery } from '../../store/form-enums/form-enums.query';
import { FormAreaPath } from '../../typings/form-areas.types';

@Injectable({ providedIn: 'root' })
export class SelfEmploymentTransformerService {
	constructor(
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private formEnumsQuery: FormEnumsQuery,
		private relatedEntitiesService: RelatedEntitiesService,
		private simpFormlyModalService: SimpFormlyModalService,
		private companyFinancialsService: RelatedCompanyFinancialsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private router: Router
	) {}

	configureSelfEmployment(
		formFields: FormlyFieldConfig[] | undefined,
		path: string,
		saveFunction: (
			personnelModel: any,
			personnelIndex: number,
			applicantId: number,
			field?: FormlyFieldConfig
		) => Subscription
	) {
		this.configureBusinessFinancials(formFields, path);
		this.defaultFinancialYears(formFields, path);
		formlyRegisterHooks(formFields, `${path}.businessDetailsSelect`, {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions('AddNewCompany');
					field.templateOptions.options = combineLatest([
						this.formEnumsQuery.selectEnumOptions('CompanyApplicants'),
						this.formEnumsQuery.selectEnumOptions('SelfEmployedCompanies')
					]).pipe(
						map(([applicants, related]) => [
							// -1 ID is for new related persons, -2 for new related companies
							...fieldOptions.map((existing) => (existing.id === -1 ? { ...existing, id: -2 } : existing)),
							...applicants.concat(related).map((company) => ({
								...company,
								click:
									(company.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													field,
													(field.model as SelfEmploymentModel).ownership || 0,
													this.saveField,
													'businessDetailsSelect',
													'relatedCompany',
													RelatedCompanyType.SelfEmployed
												)
										: null
							}))
						])
					);

					// patch business structure id every time business details change for applicant income types
					return this.relatedEntitiesService
						.configureRelatedEntityModal(
							field,
							(field.model as SelfEmploymentModel).ownership || 0,
							this.saveField,
							'businessDetailsSelect',
							'relatedCompany',
							RelatedCompanyType.SelfEmployed
						)
						.pipe(
							switchMap((company: [ApplicantEnumObject, ApplicantEnumObject]) =>
								company.length > 1 && company[1]?.type === TargetType.RelatedCompany
									? this.personsCompaniesEnumService.getRelatedCompany(company[1].id)
									: of(undefined)
							),
							tap((value?: RelatedCompanyModel) => {
								field?.parent?.formControl?.patchValue({ businessStructureId: value?.businessStructure });
							})
						);
				}

				return of();
			}
		});

		formlyRegisterHooks(formFields, `${path}.accountantName`, {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions('AddNewCompany');
					field.templateOptions.options = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
						map((related) => [
							...fieldOptions.map((existing) => (existing.id === -1 ? { ...existing, id: -2 } : existing)),
							...related.map((company) => ({
								...company,
								click:
									(company.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													field,
													(field.model as SelfEmploymentModel).ownership || 0,
													this.saveFieldAccountant,
													'accountantName',
													'relatedCompany'
												)
										: null
							}))
						])
					);

					return this.relatedEntitiesService.configureRelatedEntityModal(
						field,
						(field.model as SelfEmploymentModel).ownership || 0,
						this.saveFieldAccountant,
						'accountantName',
						'relatedCompany'
					);
				}

				return of();
			}
		});
	}

	defaultFinancialYears(formFields: FormlyFieldConfig[] | undefined, path: string) {
		const financialYearEnum = this.formEnumsQuery.getOptions('FinancialYear');

		const latestFinancialYear = getFormField(formFields, `${path}.latestAtoGrossIncomeFinancialYear`);
		if (latestFinancialYear) {
			latestFinancialYear.defaultValue = financialYearEnum?.[0]?.id;
		}

		const previousFinancialYear = getFormField(formFields, `${path}.previousAtoGrossIncomeFinancialYear`);
		if (previousFinancialYear) {
			previousFinancialYear.defaultValue = financialYearEnum?.[1]?.id;
		}

		const latestAtoGrossIncomeStatementStart = getFormField(formFields, `${path}.latestAtoGrossIncomeStatementStart`);
		if (latestAtoGrossIncomeStatementStart) {
			latestAtoGrossIncomeStatementStart.defaultValue = getFinancialYearStartDate(financialYearEnum?.[0]?.id);
		}

		const latestAtoGrossIncomeStatementEnd = getFormField(formFields, `${path}.latestAtoGrossIncomeStatementEnd`);
		if (latestAtoGrossIncomeStatementEnd) {
			latestAtoGrossIncomeStatementEnd.defaultValue = getFinancialYearEndDate(financialYearEnum?.[0]?.id);
		}
		const previousAtoGrossIncomeStatementStart = getFormField(
			formFields,
			`${path}.previousAtoGrossIncomeStatementStart`
		);
		if (previousAtoGrossIncomeStatementStart) {
			previousAtoGrossIncomeStatementStart.defaultValue = getFinancialYearStartDate(financialYearEnum?.[1]?.id);
		}

		const previousAtoGrossIncomeStatementEnd = getFormField(formFields, `${path}.previousAtoGrossIncomeStatementEnd`);
		if (previousAtoGrossIncomeStatementEnd) {
			previousAtoGrossIncomeStatementEnd.defaultValue = getFinancialYearEndDate(financialYearEnum?.[1]?.id);
		}
	}

	private configureBusinessFinancials(formFields: FormlyFieldConfig[] | undefined, path: string) {
		formlyOnClick(formFields, `${path}.businessFinancials`, this.openBusinessFinancialsModal.bind(this));
		const financialYearArray = getFormField(formFields, `${path}.businessFinancials.modal.financialYear`);
		if (financialYearArray?.templateOptions) {
			const numberOfBusinessIncome = isNaN(financialYearArray.templateOptions.maxLength as number)
				? 2
				: (financialYearArray.templateOptions.maxLength as number);
			const defaultObject = [];
			for (let i = 0; i < numberOfBusinessIncome; i++) {
				defaultObject.push({});
			}
			financialYearArray.defaultValue = defaultObject;
		}
	}

	private openBusinessFinancialsModal(field: FormlyFieldConfig): void {
		const modalRef = this.simpFormlyModalService.openModal(
			field,
			'modal',
			{
				windowClass: 'simp-padding-left-small'
			},
			false,
			`${(field.parent?.model as SelfEmploymentModel).businessDetailsSelect?.companyName} - Business financials`
		);

		const subscription = modalRef.action.subscribe((action) => {
			if (action === 'submit') {
				const model = field.model as {
					modal: {
						financialYear: RelatedCompanyFinancials[];
					};
				};
				const employerId = (field.parent?.model as ApplicantSelfEmploymentModel)?.businessDetailsSelect?.id || 0;
				const employmentId = (field.parent?.parent?.parent?.model as ApplicantEmploymentModel)?.id || -1;

				this.companyFinancialsService
					.saveCompanyFinancials(employerId, model.modal.financialYear || [])
					.subscribe((id: number) => {
						getFormField(field.fieldGroup, 'extract')?.formControl?.setValue('Business financials');
						const updatedFinancialModel: RelatedCompanyFinancialModal = {
							modal: {
								financialYear: model.modal.financialYear
							},
							extract: 'Business financials'
						};
						this.updateBusinessFinancialInSelfEmployments(employmentId, employerId, updatedFinancialModel);
						modalRef.close();
						subscription.unsubscribe();
					});
			} else {
				modalRef.close();
				subscription.unsubscribe();
			}
		});
	}
	private saveField = (
		model: SelfEmploymentModel,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(model.businessDetailsSelect);

		return new Subscription();
	};

	private saveFieldAccountant = (
		model: SelfEmploymentModel,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(model.accountantName);

		return new Subscription();
	};

	private updateBusinessFinancialInSelfEmployments(
		employmentId: number,
		employerId: number,
		updatedFinancialModel: RelatedCompanyFinancialModal
	) {
		const formAreaPath = parseArea(this.router.url.replace('/', '')) as FormAreaPath;
		switch (formAreaPath) {
			case FormAreaPath.applicants: {
				this.updateFinancialsInApplicantsArea(employmentId, employerId, updatedFinancialModel);
				break;
			}
			case FormAreaPath.financialPosition: {
				const key = 'employments';
				const employmentData = this.simpFormlyHandlerService.getStateData<EmploymentModel>(key);
				this.updateBusinessFinancialState(employmentData, employmentId, employerId, updatedFinancialModel, key);
				break;
			}
		}
	}

	private updateFinancialsInApplicantsArea(
		employmentId: number,
		employerId: number | undefined,
		updatedFinancialModel: RelatedCompanyFinancialModal
	) {
		const applicants = this.applicationDataQuery.getApplicants(ApplicantKeyType.PersonApplicant);
		if (!applicants?.length) {
			return;
		}

		applicants.forEach((applicant, index) => {
			const key = `employments-${index}`;
			const employmentData = this.simpFormlyHandlerService.getStateData<ApplicantEmploymentModel>(key);
			this.updateBusinessFinancialState(employmentData, employmentId, employerId, updatedFinancialModel, key);
		});
	}

	private updateBusinessFinancialState(
		empDataArray: EmploymentModel[] | ApplicantEmploymentModel[],
		employmentId: number,
		employerId: number | undefined,
		updatedFinancialModel: RelatedCompanyFinancialModal,
		key: string
	) {
		empDataArray?.forEach((empData, index) => {
			const selfEmploymentModel = empData?.employmentDetails?.selfEmployment;
			if (selfEmploymentModel && empData.id !== employmentId) {
				const isEmployerIdMatch = selfEmploymentModel?.businessDetailsSelect?.id === employerId;

				const updatedEmpData = cloneDeep(empData);
				if (isEmployerIdMatch && updatedEmpData?.employmentDetails?.selfEmployment) {
					updatedEmpData.employmentDetails.selfEmployment.businessFinancials = cloneDeep(updatedFinancialModel);
					this.simpFormlyHandlerService.updateToState(key, updatedEmpData, index, updatedEmpData);
				}
			}
		});
	}
}
