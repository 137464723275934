import { Injectable } from '@angular/core';
import { JourneyProduct } from '@app/modules/loan-serviceability/model/journey-product.model';
import { OldApplicationDetails } from '@app/modules/setup/typings/setup';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { FormEnumsQuery } from '../../store/form-enums/form-enums.query';
import { RbaLendingPurpose } from './../../enums/app.enums';

// Common Service for All Transformations
// Include Reusable Methods between transformers into this service
@Injectable({
	providedIn: 'root'
})
export class TransformerUtilService {
	constructor(private formEnumsQuery: FormEnumsQuery, private formDataService: FormDataService) {}

	updateDynamicListOfSelectFieldOptions(
		field: FormlyFieldConfig,
		fieldGroup: FormlyFieldConfig[] | undefined,
		path: string,
		key: string,
		option: string
	): void {
		if (fieldGroup) {
			const selectedValues = fieldGroup?.map((row) => {
				const value = row.fieldGroup?.find((x) => x.key === key)?.model as { type: number };
				return value?.type;
			});

			if (selectedValues) {
				const selectField = getFormField(field.fieldGroup, `${path}.${key}`);
				if (selectField && selectField.templateOptions) {
					const availableOptions: EnumObject[] = this.formEnumsQuery
						.getOptions(option)
						.filter((x) => selectedValues.indexOf(x.id) === -1);
					// Updating for To be created fields
					selectField.templateOptions.options = availableOptions;

					// Updating Existing Fields
					fieldGroup?.forEach((row) => {
						const select = row.fieldGroup?.find((x) => x.key === key);
						if (select && select.templateOptions) {
							const currentOrAvailableOptions = this.formEnumsQuery
								.getOptions(option)
								.filter((x) => selectedValues.indexOf(x.id) === -1 || x.id === (select.formControl?.value as number));
							select.templateOptions.options = currentOrAvailableOptions;
							select.formControl?.markAsDirty();
						}
					});
				}
			}
		}
	}

	/**
	 * Get the reason for borrowing
	 * @returns RbaLendingPurpose
	 */
	public getPrimaryLendingPurpose(): RbaLendingPurpose | undefined {
		// TODO Handle all the scenarios of RbaLendingPurpose
		const setupDetails = this.formDataService.getStateData<OldApplicationDetails>('details');
		if (setupDetails?.length > 0 && setupDetails[0].primaryLendingPurpose) {
			return setupDetails[0].primaryLendingPurpose;
		}
		return undefined;
	}

	public generateLoanProductEnumObject(product: JourneyProduct): EnumObject {
		return {
			id: product.productVariationId,
			label: `${product.productNameWithFeature} - ${product.interestRate.toFixed(2)}% (${product.comparisonRate.toFixed(
				2
			)}% comp rate)`,
			info: product.loanDetailId?.toString()
		};
	}
}
