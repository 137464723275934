import { Injectable } from '@angular/core';
import { ExpensesType } from '../../enums/app.enums';
import { ChannelSettingStore } from './channel-setting.store';

@Injectable({ providedIn: 'root' })
export class ChannelSettingQuery {
	constructor(protected store: ChannelSettingStore) {}

	getExpenseType(): ExpensesType {
		return this.store.getStoreValue().expenseType;
	}

	getIsDigitalEditable(): boolean {
		return this.store.getStoreValue().isDigitalEditable;
	}

	getDigitalDeclaredThresholdValue(): number {
		return this.store.getStoreValue().declaredDigitalThreshold;
	}

	isGuarantorEnabled(): boolean {
		return this.store.getStoreValue().enableGuarantorInterview;
	}

	isBorrowerInterviewEnabled(): boolean {
		return this.store.getStoreValue().enableBorrowerInterview;
	}

	hasSideNavSubMenu(): boolean {
		return this.getAllowCompanyApplicants() || this.getAllowTrustApplicants();
	}

	getAllowCompanyApplicants(): boolean {
		return this.store.getStoreValue().allowCompanyApplicants;
	}

	getAthenaLockControlsState(): boolean {
		return this.store.getStoreValue().lockAthenaControls;
	}

	getShowSummaryView(): boolean | undefined {
		return this.store.getStoreValue().doShowSummaryView;
	}

	getAllowTrustApplicants(): boolean {
		return this.store.getStoreValue().allowTrustApplicants;
	}

	isDigitalFastTrackEnabled(): boolean {
		return this.store.getStoreValue().enableDigitalFastTrack;
	}

	canImportApplication(): boolean {
		return this.store.getStoreValue().enableUIApplicationImport;
	}

	isLoanFeesEnabled(): boolean {
		return this.store.getStoreValue().enableLoanFees;
	}

	getAllowedFileExtensionsList(): string[] {
		return this.store.getStoreValue().allowedFileExtensionsList;
	}

	getSkipServiceabilitySubmitWorkflow(): boolean {
		return this.store.getStoreValue().skipServiceabilitySubmitWorkflow;
	}

	getUseRelationshipForSOP(): boolean {
		return this.store.getStoreValue().useRelationshipForSOP;
	}

	isIntercomEnabled(): boolean {
		return this.store.getStoreValue().enableIntercom;
	}

	getRequireApplicationPreCheck(): boolean {
		return this.store.getStoreValue().requireApplicationPreCheck;
	}

	getLmiFingerprintEnabled(): boolean {
		return this.store.getStoreValue().enableLmiFingerprint;
	}

	getFinancialInstitutionId(): number {
		return this.store.getStoreValue().financialInstitutionId;
	}

	getEnableCustomerDetailSearch(): boolean {
		return this.store.getStoreValue().enableCustomerDetailSearch;
	}
}
