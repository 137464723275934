import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URL_CONSTANTS } from '../constants/api.constants';
import { JourneySetting } from '../model/journey-setting.model';
import { SideNavItem } from '../store/application-data/typings/application-data';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class SideNavService extends BaseJourneyService<JourneySetting> {
	constructor() {
		super();
		this.setMetaRoute(URL_CONSTANTS.SIDE_NAV);
	}

	getSideNavItems(journeyId: number): Observable<SideNavItem[]> {
		return this.getCustom(`${journeyId}`) as Observable<SideNavItem[]>;
	}
}
