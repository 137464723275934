import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { EmploymentIncomeType, PaygBasis } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly } from '@app/modules/shared/helper/util';
import { EmploymentDTO, EmploymentIncomeDTO, PersonApplicantDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class EmploymentIncomeTransformer {
	static toPayload(employmentModel: EmploymentModelDetails, applicationId: number): EmploymentDTO {
		return {
			id: employmentModel.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			applicantId: employmentModel.ownerApplicantId,
			basis: employmentModel.basis,
			employerNameOrAbn: employmentModel.employerNameOrAbn?.toUpperCase(),
			ascoOccupationCategoryId: employmentModel.occupationCategory,
			ascoOccupationCodeId: employmentModel.occupation,
			dateEnded: employmentModel.dates?.dateEnded,
			dateStarted: employmentModel.dates?.dateEnded
				? employmentModel.dates.dateStartedPrevious
				: employmentModel.dates?.dateStartedCurrent,
			income: [
				{
					id: employmentModel.grossIncome?.id,
					type: EmploymentIncomeType.Salary,
					grossAmount: employmentModel.grossIncome?.amount,
					frequency: employmentModel.grossIncome?.frequency
				},
				{
					id: employmentModel.overtimeIncome?.id,
					type: EmploymentIncomeType.RegularOvertime,
					grossAmount: employmentModel.overtimeIncome?.amount,
					frequency: employmentModel.overtimeIncome?.frequency
				},
				{
					id: employmentModel.bonusAmount?.id,
					type: EmploymentIncomeType.Other,
					grossAmount: employmentModel.bonusAmount?.amount,
					frequency: employmentModel.bonusAmount?.frequency
				}
			]
		};
	}

	static fromPayload(payload: EmploymentDTO, applicants: PersonApplicantDTO[]): EmploymentModel {
		return {
			employmentModal: {
				isCurrentEmployment: payload.dateEnded ? false : true,
				id: payload?.id,
				ownerApplicantId: payload?.applicantId,
				basis: payload?.basis,
				employerNameOrAbn: payload?.employerNameOrAbn,
				occupationCategory: payload?.ascoOccupationCategoryId,
				occupation: payload?.ascoOccupationCodeId,
				dates: {
					dateEnded: payload.dateEnded,
					dateStartedCurrent: payload.dateEnded ? undefined : payload.dateStarted,
					dateStartedPrevious: payload.dateEnded ? payload.dateStarted : undefined
				},
				grossIncome: {
					id: payload?.income?.find((x) => x.type === EmploymentIncomeType.Salary)?.id,
					amount: payload?.income?.find((x) => x.type === EmploymentIncomeType.Salary)?.grossAmount,
					frequency: payload?.income?.find((x) => x.type === EmploymentIncomeType.Salary)?.frequency,
					type: EmploymentIncomeType.Salary
				},
				overtimeIncome: {
					id: payload?.income?.find((x) => x.type === EmploymentIncomeType.RegularOvertime)?.id,
					amount: payload?.income?.find((x) => x.type === EmploymentIncomeType.RegularOvertime)?.grossAmount,
					frequency: payload?.income?.find((x) => x.type === EmploymentIncomeType.RegularOvertime)?.frequency,
					type: EmploymentIncomeType.RegularOvertime
				},
				bonusAmount: {
					id: payload?.income?.find((x) => x.type === EmploymentIncomeType.Other)?.id,
					amount: payload?.income?.find((x) => x.type === EmploymentIncomeType.Other)?.grossAmount,
					frequency: payload?.income?.find((x) => x.type === EmploymentIncomeType.Other)?.frequency,
					type: EmploymentIncomeType.Salary
				}
			},
			summary: this.calculateEmploymentIncomeSummary(applicants, payload.applicantId, payload.income)
		};
	}

	static calculateEmploymentIncomeSummary(
		applicants: PersonApplicantDTO[],
		ownerId?: number,
		income?: EmploymentIncomeDTO[]
	): string {
		let totalIncome = 0;
		if (income) {
			totalIncome = income.reduce((x, y) => x + calculateMonthly(y.grossAmount, y.frequency), 0);
		}
		return `${
			applicants.length > 1 ? applicants.find((x) => x.id === ownerId)?.firstName.concat('<br/>') : ''
		} ${formatCurrency(totalIncome, 'en-AU', '$')} per month`;
	}
}

export interface EmploymentModelDetails {
	isCurrentEmployment?: boolean;
	id?: number;
	ownerApplicantId?: number;
	basis?: PaygBasis;
	employerNameOrAbn?: string;
	occupationCategory?: number;
	occupation?: number;
	grossIncome?: EmploymentIncome;
	overtimeIncome?: EmploymentIncome;
	bonusAmount?: EmploymentIncome;
	dates?: EmploymentDates;
}

export interface EmploymentModel {
	employmentModal: EmploymentModelDetails;
	summary: string;
}

interface EmploymentIncome {
	id?: number;
	amount?: number;
	frequency?: FrequencyShort;
	type: EmploymentIncomeType;
}

export interface EmploymentDates {
	dateStartedCurrent?: string;
	dateStartedPrevious?: string;
	dateEnded?: string;
}
