<simp-number-input
	[id]="uniqueId"
	[formControl]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[min]="$any(to.min)"
	[max]="$any(to.max)"
	[required]="!!to.required"
	[readonly]="!!to.readonly"
	[incrementBy]="to.incrementBy || 1"
	[tooltipContent]="to.tooltip"
	[simpleView]="to.simpleView"
	metadataKey
	[formlyField]="field"
	[customClass]="to.customClass"
>
	{{ to.label }}
</simp-number-input>
