import { DiscountCalculationMode } from '@app/modules/shared/enums/app.enums';
import { DiscountDTO } from '@app/modules/typings/api';

export class DiscountTransformer {
	static fromPayload(discountDto: DiscountDTO): Discount {
		const initialTermTotalDiscount = Number(
			((discountDto.initialTermStandardRate ?? 0) + (discountDto.initialTermAdditionalDiscount ?? 0)).toFixed(2)
		);

		const subSequentTermTotalDiscount = Number(
			((discountDto?.subsequentTermStandardRate ?? 0) + (discountDto?.subsequentTermAdditionalDiscount ?? 0)).toFixed(2)
		);

		return {
			applicationId: discountDto.applicationId,
			loanId: discountDto.loanDetailsId,
			discountCalculationMode: discountDto.discountCalculateMode,
			initialTermBaseRate: discountDto.initialTermBaseRate ?? 0,
			initialTermStandardDiscount: discountDto.initialTermStandardRate ?? 0,
			initialTermDiscountedRate: discountDto.initialTermDiscountRate ?? 0,
			initialTermAdditionalDiscount: discountDto.initialTermAdditionalDiscount ?? 0,
			initialTermTotalDiscount: initialTermTotalDiscount,
			initialTermReasonForDiscount: discountDto?.initialTermReasonForDiscount ?? '',
			subSequentTermBaseRate: discountDto?.subsequentTermBaseRate ?? 0,
			subSequentTermStandardDiscount: discountDto?.subsequentTermStandardRate ?? 0,
			subSequentTermDiscountedRate: discountDto?.subsequentTermDiscountRate ?? 0,
			subSequentTermAdditionalDiscount: discountDto?.subsequentTermAdditionalDiscount ?? 0,
			subSequentTermTotalDiscount: subSequentTermTotalDiscount,
			subSequentTermReasonForDiscount: discountDto?.subsequentTermReasonForDiscount ?? '',
			isProductSelected: true // UI Purpose only
		};
	}

	static toPayload(discount: Discount): DiscountDTO {
		const discountDto = {
			applicationId: discount.applicationId,
			loanDetailsId: discount.loanId,
			discountCalculateMode: discount.discountCalculationMode,
			initialTermBaseRate: discount.initialTermBaseRate,
			initialTermStandardRate: discount.initialTermStandardDiscount,
			initialTermDiscountRate: discount.initialTermDiscountedRate,
			initialTermAdditionalDiscount: discount.initialTermAdditionalDiscount,
			initialTermReasonForDiscount: discount.initialTermReasonForDiscount
		} as DiscountDTO;

		if (!discount.isHideSubTerm) {
			discountDto.subsequentTermBaseRate = discount.subSequentTermBaseRate;
			discountDto.subsequentTermStandardRate = discount.subSequentTermStandardDiscount;
			discountDto.subsequentTermDiscountRate = discount.subSequentTermDiscountedRate;
			discountDto.subsequentTermAdditionalDiscount = discount.subSequentTermAdditionalDiscount;
			discountDto.subsequentTermReasonForDiscount = discount.subSequentTermReasonForDiscount;
		}

		return discountDto;
	}
}

export interface Discount {
	applicationId: number;
	loanId: number;
	discountCalculationMode?: DiscountCalculationMode;
	initialTermBaseRate?: number;
	initialTermStandardDiscount?: number;
	initialTermDiscountedRate?: number;
	initialTermAdditionalDiscount?: number;
	initialTermTotalDiscount?: number;
	initialTermReasonForDiscount?: string;
	subSequentTermBaseRate?: number;
	subSequentTermStandardDiscount?: number;
	subSequentTermDiscountedRate?: number;
	subSequentTermAdditionalDiscount?: number;
	subSequentTermTotalDiscount?: number;
	subSequentTermReasonForDiscount?: string;
	isProductSelected?: boolean; // UI Purpose only
	isHideSubTerm?: boolean; // UI Purpose only
}
