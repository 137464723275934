<div class="loanapp-x">
	<div class="loanapp-x__header">
		<refi-header></refi-header>
	</div>
	<section
		class="loanapp-x__body simp-padding-medium simp-text--primary"
		*ngIf="labels.discardedTitle; else genericMessage"
	>
		<div class="d-flex align-items-center simp-margin-bottom-large">
			<simp-icon
				name="{{ applicationDiscarded ? labels.discardedIcon : labels.savedIcon }}"
				class="fa-2x simp-margin-right-small simp-text--primary"
			></simp-icon>
			<h1 class="simp-h1 simp-text--bold no-margin">
				{{ applicationDiscarded ? labels.discardedTitle : labels.savedTitle }}
			</h1>
		</div>

		<p>{{ applicationDiscarded ? labels.discardedMessage1 : labels.savedMessage1 }}</p>
		<p class="simp-margin-top-base">{{ applicationDiscarded ? labels.discardedMessage2 : labels.savedMessage2 }}</p>

		<div class="simp-row">
			<a
				class="simp-button btn btn-lg btn-secondary simp-margin-top-large col-12 col-md-4"
				href="{{ labels.secondaryButtonLink }}"
				(click)="handleButtonClick()"
			>
				{{ labels.secondaryButton }}
			</a>
		</div>
	</section>

	<ng-template #genericMessage>
		<div class="loanapp-x__body simp-padding-medium simp-text--primary">
			You've been logged out. Please close this window.
		</div>
	</ng-template>
	<div class="loanapp-x__footer">
		<refi-footer></refi-footer>
	</div>
</div>
