import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { TrustBusinessDetailsDTO } from '@app/modules/typings/api';

export class TrustBusinessDetailsTransformer {
	static toPayload(applicationId: number, model: TrustBusinessDetailsModel): TrustBusinessDetailsDTO {
		return {
			id: model.id,
			applicationId: applicationId,
			businessNameSameAsTrustName: model.businessNameSameAsTrustName,
			businessName: model.businessName,
			abn: model.abn,
			abnVerified: model.abnVerified,
			businessIndustryCode: model.businessIndustryCode,
			businessMainBusinessActivity: model.businessMainBusinessActivity,
			isAllBusinessInsuranceInPlaceAndCurrent: getNullableBoolean(model.isAllBusinessInsuranceInPlaceAndCurrent)
		};
	}

	static fromPayload(businessDetails: TrustBusinessDetailsDTO): TrustBusinessDetailsModel {
		return {
			id: businessDetails.id,
			businessNameSameAsTrustName: businessDetails.businessNameSameAsTrustName,
			businessName: businessDetails.businessName,
			abn: businessDetails.abn,
			abnVerified: businessDetails?.abnVerified,
			businessIndustryCode: businessDetails.businessIndustryCode,
			businessMainBusinessActivity: businessDetails.businessMainBusinessActivity,
			isAllBusinessInsuranceInPlaceAndCurrent: getNullableYesNo(businessDetails.isAllBusinessInsuranceInPlaceAndCurrent)
		};
	}
}

export interface TrustBusinessDetailsModel {
	id: number;
	businessNameSameAsTrustName: boolean;
	businessName: string;
	abn: string;
	abnVerified?: boolean;
	businessIndustryCode: number;
	businessMainBusinessActivity: string;
	isAllBusinessInsuranceInPlaceAndCurrent?: YesNo;
}
