import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationPreCheck } from '../enums/app.enums';
import { EligibilityResult } from '../model/eligibility-result.model';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class PreCheckApiService extends BaseJourneyService {
	constructor() {
		super();
		this.setJourneyLadmRoute();
	}

	getEligibility(postcode: string, typeOfProperty: number): Observable<EligibilityResult> {
		return <Observable<EligibilityResult>>(
			this.getCustom(`PreCheck/GetPostcodeEligibility/${postcode}?propertyType=${typeOfProperty}`)
		);
	}

	updateApplicationPreCheck(applicationId: number, precheck: ApplicationPreCheck): Observable<void> {
		return <Observable<void>>this.patch(`Application/SetPrecheckStatus/${applicationId}/${precheck}`, {});
	}
}
