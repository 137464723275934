import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, UrlTree } from '@angular/router';
import { InformedConsentService } from '@app/modules/applicants/services/informed-consent.service';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { Observable, of, timer } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';

import { formAreaPathToArea, parseArea } from '../helper/util';
import { RemoteValidationService } from '../service/remote-validation.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ApplicationDataService } from '../store/application-data/application-data.service';
import { ChannelSettingQuery } from '../store/channel-setting/channel-setting.query';
import { EventAction, EventsRepository, EventTarget } from '../store/events/events.repository';
import { FormValidationsQuery } from '../store/form-validations/form-validations.query';
import { FormValidationsService } from '../store/form-validations/form-validations.service';
import { FormAreaPath } from '../typings/form-areas.types';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<unknown> {
	constructor(
		private formValidationsQuery: FormValidationsQuery,
		private formValidationsService: FormValidationsService,
		private applicationDataQuery: ApplicationDataQuery,
		private router: Router,
		private informedConsentService: InformedConsentService,
		private remoteValidationService: RemoteValidationService,
		private applicationDataService: ApplicationDataService,
		private channelSettingQuery: ChannelSettingQuery,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository,
		private eventsRepository: EventsRepository
	) {}

	/**
	 * If Area change event is allowed or not
	 */
	canDeactivate(
		component: any,
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = this.router.url.replace('/', '');
		const formAreaPath = parseArea(url) as FormAreaPath;
		const redirectedFromAppChange = this.router.getCurrentNavigation()?.extras.state
			?.redirectedFromAppChange as boolean;

		//If application is changed, we set redirectedFromAppChange as true
		//We skip running validations as onApplicationChange method runs validations (confirmUnsaved)
		if (redirectedFromAppChange) {
			return of(true);
		}

		this.formValidationsService.triggerValidation(true);
		// Delay to leave ample time for form validations and debounce.
		return timer(1005).pipe(
			switchMap(() => {
				if (this.applicationDataQuery.applicationInEligible()) {
					return of(false);
				}
				const drafted = this.formValidationsQuery.isDrafted(route.data.draftStateNames);
				// if no unsaved changes
				if (!drafted) {
					if (!redirectedFromAppChange) {
						// marking form as valid and showing tick on the sidenav
						this.applicationDataService.setSideNavStatus(url, true);
					}
					if (
						formAreaPath === FormAreaPath.applicants &&
						!redirectedFromAppChange &&
						this.channelSettingQuery.isDigitalFastTrackEnabled()
					) {
						return this.informedConsentService.checkConsentInitiation();
					}
					return of(true);
				} else {
					const subSection = this.formValidationsQuery.firstDraftedSubsectionKey(route.data.draftStateNames);
					console.error(`Invalid sub section: ${subSection}`);
					this.applicationDataService.setSideNavStatus(url, false);
				}

				return this.formValidationsService
					.confirmUnsaved(formAreaPath, this.applicationDataQuery.applicationHardStopMessage())
					.pipe(delay(1000));
			}),
			tap((allowedToNavigate) => {
				if (allowedToNavigate && !redirectedFromAppChange) {
					// Reset hard stop message when navigation is allowed to proceed
					this.applicationDataService.updateHardStopMessage();
					// delay the remote validations a bit so that JS thread can focus on next page execution first.
					setTimeout(() => {
						this.remoteValidationService.fetchRemoteValidations(formAreaPath);
						this.digitalWidgetsConfigurationRepository
							.triggerAutoWidgets()
							.pipe(switchMap(() => this.digitalWidgetsConfigurationRepository.fetchAllStatus()))
							.subscribe(() => this.digitalWidgetsConfigurationRepository.detectRefDigitalWidgetComponent.next());
					}, 3000);

					this.applicationDataService.setSideNavActive(formAreaPath);
				}

				if (allowedToNavigate) {
					this.eventsRepository.dispatchEvent({
						targetName: formAreaPathToArea(formAreaPath) as string,
						targetType: EventTarget.Area,
						action: EventAction.Exited
					});
				}
			}),
			take(1)
		);
	}
}
