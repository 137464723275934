import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { BusinessStructureFull, EmploymentIncomeType } from '@app/modules/shared/enums/app.enums';
import { calculateYearly } from '@app/modules/shared/helper/util';
import { EmploymentIncomeDTO, PersonApplicantDTO, RefiSelfEmploymentDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class SelfEmploymentIncomeTransformer {
	static toPayload(employmentModel: SelfEmploymentModelDetails, applicationId: number): RefiSelfEmploymentDTO {
		return {
			id: employmentModel.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			applicantId: employmentModel.ownerApplicantId,
			typeOfBusiness: employmentModel.typeOfBusiness,
			ownershipShare:
				employmentModel.typeOfBusiness === BusinessStructureFull.Company ? employmentModel.ownershipShare : 100,
			financialYear: employmentModel.financialYear,
			grossIncome: employmentModel.grossIncome?.amount
		};
	}

	static fromPayload(payload: RefiSelfEmploymentDTO, applicants: PersonApplicantDTO[]): SelfEmploymentModel {
		return {
			selfEmploymentModal: {
				id: payload?.id,
				ownerApplicantId: payload?.applicantId,
				typeOfBusiness: payload?.typeOfBusiness,
				ownershipShare: payload?.ownershipShare,
				financialYear: payload?.financialYear,
				grossIncome: {
					amount: payload?.grossIncome,
					frequency: FrequencyShort.Yearly,
					type: EmploymentIncomeType.Salary
				}
			},
			summary: this.calculateSelfEmploymentIncomeSummary(
				applicants,
				{
					grossAmount: payload.grossIncome,
					frequency: FrequencyShort.Yearly
				} as EmploymentIncomeDTO,
				payload.applicantId
			)
		};
	}

	static calculateSelfEmploymentIncomeSummary(
		applicants: PersonApplicantDTO[],
		income?: EmploymentIncomeDTO,
		ownerId?: number
	): string {
		const totalIncome = calculateYearly(income?.grossAmount, income?.frequency);
		return `${
			applicants.length > 1 ? applicants.find((x) => x.id === ownerId)?.firstName.concat('<br/>') : ''
		} ${formatCurrency(totalIncome, 'en-AU', '$')} per year`;
	}
}

export interface SelfEmploymentModelDetails {
	id?: number;
	ownerApplicantId?: number;
	typeOfBusiness?: BusinessStructureFull;
	ownershipShare?: number;
	financialYear?: number; // add fin year
	grossIncome?: SelfEmploymentIncome;
}

export interface SelfEmploymentModel {
	selfEmploymentModal: SelfEmploymentModelDetails;
	summary: string;
}

interface SelfEmploymentIncome {
	amount?: number;
	frequency?: FrequencyShort;
	type: EmploymentIncomeType;
}
