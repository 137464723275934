import { FormlyTemplateOptions } from '@ngx-formly/core';
import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';
import { formlyArray } from './formly-array.helper';

const formlySummaryItem = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'object',
			className: `${property.templateOptions?.class ?? (property.class || 'col-12 summary-wrapper')}`,
			templateOptions: {
				customClass: 'simp-row gap-h-base',
				...property.templateOptions
			},
			hideExpression: parseExpressionString(property.hide),
			defaultValue: {}
		}
	},
	properties: childProperties
});

const formlySummarySection = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'section',
			className: property.class || 'simp-margin-top-base simp-margin-right-base',
			templateOptions: {
				...property.templateOptions
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	},
	required: property.required,
	properties: childProperties
});

export const formlySummary = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	if (property.type === 'summaryItem') {
		return formlySummaryItem(property, childProperties);
	}

	if (property.type === 'summarySection') {
		return formlySummarySection(property, childProperties);
	}

	if (property.type === 'summaryArray') {
		property.class = `summary-wrapper ${property.class ?? 'col-12'}`;
		const to = {
			arrayType: 'alternate',
			static: true,
			defaultValue: property.templateOptions?.defaultValue ?? [{}]
		} as FormlyTemplateOptions;
		if (property.templateOptions) {
			Object.assign(property.templateOptions, to);
		} else {
			property.templateOptions = to;
		}
		return formlyArray(property, childProperties);
	}

	return {
		type: 'array',
		title: property.title,
		widget: {
			formlyConfig: {
				wrappers: ['summary'],
				type: 'sub-section',
				className: property.class || 'col-12',
				templateOptions: {
					collapsible: true,
					expanded: true,
					customClass: 'simp-row',
					...property.templateOptions
				},
				hideExpression: parseExpressionString(property.hide),
				defaultValue: [{}]
			}
		},
		items: {
			type: 'object',
			required: property.required,
			properties: childProperties,
			widget: {
				formlyConfig: {
					templateOptions: {
						...property.templateOptions,
						customClass: `simp-row ${property.templateOptions?.customClass || ''}`
					}
				}
			}
		}
	};
};
