import { TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';

export class AuthorisedSignatoriesTransformer {
	static fromPayload(
		authorisedSignatory: AuthorisedSignatoriesDTO,
		existingSelect?: ApplicantEnumObject,
		fullName = ''
	): AuthorisedSignatoriesModel {
		return {
			id: authorisedSignatory.id,
			existingSelect,
			position: authorisedSignatory.positionTitle,
			fullName
		};
	}

	static toPayload(
		authorisedSignatory: AuthorisedSignatoriesModel,
		companyApplicantId: number,
		applicationId: number
	): AuthorisedSignatoriesDTO {
		const existingSelectId = authorisedSignatory.existingSelect?.id ?? 0;
		const personId = existingSelectId > 0 ? existingSelectId : authorisedSignatory.personId;
		const type =
			existingSelectId > 0
				? authorisedSignatory.existingSelect?.type ?? TargetType.RelatedPerson
				: TargetType.RelatedPerson;
		return {
			id: authorisedSignatory.id,
			applicationId,
			personId,
			type,
			companyApplicantId,
			positionTitle: authorisedSignatory.position
		};
	}
}

export interface AuthorisedSignatoriesModel {
	id?: number;
	existingSelect?: ApplicantEnumObject;
	nameTitleId?: number;
	firstName?: string;
	lastName?: string;
	position?: string;
	personId?: number;
	fullName?: string;
}

export interface AuthorisedSignatoriesDTO {
	id?: number;
	companyApplicantId: number;
	applicationId?: number;
	type: TargetType;
	personId?: number;
	positionTitle?: string;
	nameTitleId?: number;
	firstName?: string;
	lastName?: string;
}
