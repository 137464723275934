import { YesNo, YesNoNotApplicable } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo, getNullableYesNoNotApplicable } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class ServicingGuaranteeTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): ServicingGuaranteeModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			lendingGuaranteeTypeId: payload.lendingGuaranteeTypeId,
			spousalOrParent: getNullableYesNo(payload.servicingGuaranteeConfirmRelationshipToBorrower),
			relyingOnPayments: getNullableYesNo(payload.servicingGuaranteeConfirmUnderstandsBorrowerReliance),
			understandRepaymentsForBorrower: getNullableYesNo(payload.servicingGuaranteeConfirmUnderstandsRepaymentsRequired),
			makeRepaymentsForBorrower: getNullableYesNo(payload.servicingGuaranteeConfirmUnderstandsRepaymentsRequired),
			benefitToGuarantor: getNullableYesNoNotApplicable(payload.servicingGuaranteeConfirmIsBenefitToFamily)
		};
	}
}

export interface ServicingGuaranteeModel {
	id?: number;
	lendingGuaranteeId?: number;
	lendingGuaranteeTypeId?: number;
	spousalOrParent?: YesNo;
	understandRepaymentsForBorrower?: YesNo;
	makeRepaymentsForBorrower?: YesNo;
	relyingOnPayments?: YesNo;
	benefitToGuarantor?: YesNoNotApplicable;
}
