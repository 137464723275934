import { Injectable } from '@angular/core';
import {
	DIVIDEND_FINANCIAL_ASSET_TYPES,
	INTEREST_FINANCIAL_ASSET_TYPES
} from '@app/modules/financial-position/model/linked-asset.model';
import { OtherIncomeModel } from '@app/modules/financial-position/model/otherincome.model';
import {
	formlyAddItemToArray,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnChange,
	formlyOnClick,
	getFormField,
	getInitializedFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AssetTypeEnum, LiabilityTypeEnum } from '../enums/app.enums';
import { deletedSuccessfullyMessage } from '../helper/util';
import { CreditCardModel } from '../model/creditcard.model';
import {
	IncomeHeaderSummary,
	LiabilitiesHeaderSummary,
	LiabilitiesHeaderTransformer
} from '../model/header-summary.model';
import { OtherAssetModel } from '../model/otherasset.model';
import { OtherLiabilityModel } from '../model/otherliabilites.model';
import { PercentageOwned } from '../model/percentage-owned.model';
import { PersonalLoanModel } from '../model/personalloan.model';
import { SavingDetails, SavingModel } from '../model/saving.model';
import { AssetsAndLiabilitiesService } from '../service/assets-liabilities.service';
import { FormArrayDeleteService } from '../service/form-array-delete.service';
import { PercentOwnedService } from '../service/percent-owned.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { SimpFormlyHandlerService } from './../../simp-formly/services/simp-formly-handler.service';

@Injectable({ providedIn: 'root' })
export class AssetsLiabilitiesTransformerService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private formEnumsQuery: FormEnumsQuery,
		private percentOwnedService: PercentOwnedService,
		private formArrayDeleteService: FormArrayDeleteService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private toastr: ToastrService,
		private formEnumService: FormEnumsService
	) {}
	transform(formFields: FormlyFieldConfig[] | undefined): FormlyFieldConfig[] | undefined {
		this.assetsAndLiabilitiesService.fetchLinkedAssetEnumsForOtherIncome();

		const defaultAllPercentageOwned = this.percentOwnedService.getAllDefaultSharePercents();
		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'assets.otherAssets.details.otherAssetDetails.percentsOwned'
		);
		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'assets.savings.details.savingDetails.percentsOwned'
		);
		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'income.otherIncome.details.otherIncomeDetails.percentsOwned'
		);

		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'liabilities.personalLoans.details.personalLoanDetails.percentsOwned'
		);
		const linkedAssetsLiabilitiesPath = [
			'liabilities.personalLoans.details.personalLoanDetails.linkedAssets',
			'liabilities.otherLiabilities.details.otherLiabilityDetails.linkedAssets',
			'assets.savings.details.savingDetails.linkedLiabilities',
			'assets.otherAssets.details.otherAssetDetails.linkedLiabilities'
		];
		linkedAssetsLiabilitiesPath.forEach((path) => {
			this.configureLinkedAssetsLiabilities(formFields, path);
		});

		this.setSharePercentageTitles(formFields!, `assets.savings.details.savingDetails.percentsOwned.percent`);
		this.setSharePercentageTitles(formFields!, `assets.otherAssets.details.otherAssetDetails.percentsOwned.percent`);
		this.setSharePercentageTitles(formFields!, `income.otherIncome.details.otherIncomeDetails.percentsOwned.percent`);

		this.formArrayDeleteService.handleDelete(formFields, 'assets.otherAssets', 'OtherAsset', 'Other asset');
		formlyOnClick(formFields, `assets.savings.delete`, (field: FormlyFieldConfig) => {
			this.openConfirmOtherAssetDeletionModal(field).subscribe((confirm) => {
				if (confirm) {
					this.formArrayDeleteService.deleteItem(field, 'Savings', 'savings').subscribe(() => {
						this.assetsAndLiabilitiesService.fetchLinkedAssetEnumsForOtherIncome();
					});
				}
			});
		});

		this.handleCreditCardDelete(formFields);
		this.handlePersonalLoanDelete(formFields);
		this.handleOtherLiabilityDelete(formFields);

		formlyOnClick(formFields, 'assets.otherAssets.details', this.setupOtherAsset.bind(this));
		formlyOnClick(formFields, 'assets.savings.details', (field) => this.setupSavingAssets(field));
		formlyOnClick(formFields, 'liabilities.creditCards.details', this.setupCreditCardLiability.bind(this));
		formlyOnClick(formFields, 'liabilities.personalLoans.details', this.setupPersonalLoanLiability.bind(this));

		formlyOnClick(
			formFields,
			'liabilities.personalLoans.details.personalLoanDetails.isEqualShare',
			(field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			}
		);
		this.setSharePercentageTitles(
			formFields!,
			`liabilities.personalLoans.details.personalLoanDetails.percentsOwned.percent`
		);
		this.setSharePercentageTitles(
			formFields!,
			`liabilities.otherLiabilities.details.otherLiabilityDetails.percentsOwned.percent`
		);
		formlyExtendExpressionProperties(formFields, '', {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});
		formlyExtendExpressionProperties(formFields, '', {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});
		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'liabilities.otherLiabilities.details.otherLiabilityDetails.percentsOwned'
		);
		formlyOnClick(formFields, 'liabilities.otherLiabilities.details', this.setupOtherLiability.bind(this));
		formlyOnClick(
			formFields,
			'assets.otherAssets.details.otherAssetDetails.isEqualShare',
			(field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			}
		);
		formlyOnClick(
			formFields,
			'income.otherIncome.details.otherIncomeDetails.isEqualShare',
			(field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			}
		);

		formlyOnClick(formFields, 'assets.savings.details.savingDetails.isEqualShare', (field: FormlyFieldConfig) => {
			this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
		});

		formlyOnClick(
			formFields,
			'liabilities.otherLiabilities.details.otherLiabilityDetails.isEqualShare',
			(field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			}
		);

		formlyOnChange(
			formFields,
			'liabilities.otherLiabilities.details.otherLiabilityDetails.linkedAssets.assetType',
			this.openSelectNewAssetTypeModal.bind(this)
		);

		formlyOnChange(
			formFields,
			'liabilities.personalLoans.details.personalLoanDetails.linkedAssets.assetType',
			this.openSelectNewAssetTypeModal.bind(this)
		);

		formlyOnChange(
			formFields,
			'assets.savings.details.savingDetails.linkedLiabilities.liabilityType',
			this.openSelectNewLiabilityTypeModal.bind(this)
		);

		formlyOnChange(
			formFields,
			'assets.otherAssets.details.otherAssetDetails.linkedLiabilities.liabilityType',
			this.openSelectNewLiabilityTypeModal.bind(this)
		);

		return formFields;
	}

	configureLinkedAssetsLiabilities(formFields: FormlyFieldConfig[] | undefined, path: string) {
		const linkedAssetLiabilitiesArray = getFormField(formFields, path);
		if (linkedAssetLiabilitiesArray && linkedAssetLiabilitiesArray.templateOptions) {
			linkedAssetLiabilitiesArray.templateOptions.showAllLabels = true;
			linkedAssetLiabilitiesArray.defaultValue = null;
		}

		formlyOnClick(formFields, `${path}.delete`, (field: FormlyFieldConfig) => {
			formlyDeleteFromArray(field);
		});
		// TODO
		// duplicates link assets or liabilaities to be restricted in options
		// handler to used to be configure options to show certain kind of options e.g show only personalLoan  for 'Savings'
	}

	private openConfirmOtherAssetDeletionModal(field: FormlyFieldConfig): Observable<boolean> {
		const model = field.model as SavingModel;
		if (!model.id) {
			return of(true);
		}

		return this.assetsAndLiabilitiesService.fetchOtherIncome().pipe(
			map((incomes: OtherIncomeModel[]) => {
				return incomes.filter(
					(income) =>
						income?.details?.otherIncomeDetails.interestIncomeLinkedAsset === model.id ||
						income?.details?.otherIncomeDetails.dividendLinkedAsset === model.id
				);
			}),
			switchMap((incomes) => {
				if (incomes?.length > 0) {
					return this.showWarningForLinkedOtherIncomeDelete(incomes);
				} else {
					return of(true);
				}
			})
		);
	}

	private showWarningForLinkedOtherIncomeDelete(linkedOtherIncomes: OtherIncomeModel[]): Observable<boolean> {
		let recordsText = '';
		linkedOtherIncomes.forEach((income) => {
			const [key, id] = income.details.extract.split('.');
			recordsText += `<p class="simp-text--bold">${this.formEnumsQuery.getOptionLabel(key, Number(id))} of $${
				income.grossAmount
			}</p>`;
		});

		return this.confirmationDialogService
			.warnToProceed(
				`This asset is associated with other incomes`,
				`If you continue ${linkedOtherIncomes.length > 1 ? 'these records' : 'this record'} will be deleted<br><br>` +
					recordsText,
				'Continue',
				'Cancel'
			)
			.pipe(
				switchMap((confirmContinue) => {
					const header = this.simpFormlyHandlerService.getHeaderData<OtherIncomeModel, IncomeHeaderSummary>(
						'otherIncome'
					);
					return confirmContinue
						? forkJoin(
								linkedOtherIncomes.map((income) => {
									header.totalNetAmount ? (header.totalNetAmount -= income.netAmount ?? 0) : 0;
									header.totalGrossAmount ? (header.totalGrossAmount -= income.grossAmount ?? 0) : 0;
									return this.formArrayDeleteService.delete(
										`OtherIncome/${this.applicationDataQuery.applicationId()}/${income.id}`
									);
								})
						  ).pipe(
								switchMap(() =>
									this.assetsAndLiabilitiesService.fetchOtherIncome().pipe(
										tap((otherIncome: OtherIncomeModel[]) => {
											this.simpFormlyHandlerService.upsertToStateWithData(`otherIncome`, otherIncome);
											this.simpFormlyHandlerService.upsertToSubSectionHeader('otherIncome', header);
										}),
										switchMap(() => of(true))
									)
								)
						  )
						: of(false);
				})
			);
	}

	private setDefaultPercentageOwned(
		formFields: FormlyFieldConfig[] | undefined,
		DefaultPercentageOwned: PercentageOwned[],
		path: string
	) {
		const percentOwned = getFormField(formFields, path);
		if (percentOwned && percentOwned.templateOptions) {
			percentOwned.templateOptions.showAllLabels = true;
			percentOwned.defaultValue = DefaultPercentageOwned;
		}
	}
	private setSharePercentageTitles(formFields: FormlyFieldConfig[], path: string): void {
		formlyExtendExpressionProperties(formFields, path, {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});
	}

	private setupOtherAsset(field: FormlyFieldConfig, isNavigatedFromLinkedSecurity = false): void {
		const modalRef = this.simpFormlyModalService.openModal(field, 'otherAssetDetails');
		if (isNavigatedFromLinkedSecurity) {
			const hideLinkedSecuritiesField = getFormField(field.fieldGroup, 'otherAssetDetails.hideLinkedSecurities');
			hideLinkedSecuritiesField?.formControl?.patchValue(true);
		}
		const subscription = modalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				if (isNavigatedFromLinkedSecurity) {
					// Delete the newly added 'Other Asset' field from the liability modal
					formlyDeleteFromArray(field);
				}
				modalRef.close();
				subscription.unsubscribe();
				return;
			}

			const model = Object.assign({}, field.parent?.model, field.form?.value) as OtherAssetModel;
			const key = Number(field.parent?.key);

			this.assetsAndLiabilitiesService.saveOtherAsset(key, model).subscribe(() => {
				modalRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private setupOtherLiability(field: FormlyFieldConfig, isNavigatedFromLinkedSecurity?: boolean): void {
		const modelRef = this.simpFormlyModalService.openModal(field, 'otherLiabilityDetails', { backdrop: 'static' });
		if (isNavigatedFromLinkedSecurity) {
			const hideLinkedSecuritiesField = getFormField(field.fieldGroup, 'otherLiabilityDetails.hideLinkedSecurities');
			hideLinkedSecuritiesField?.formControl?.patchValue(true);
		}

		const subscription = modelRef.action.subscribe((action) => {
			if (action !== 'submit') {
				if (isNavigatedFromLinkedSecurity) {
					// Delete the newly added 'Other liability' field from the Asset modal
					formlyDeleteFromArray(field);
				}
				modelRef.close();
				subscription.unsubscribe();
				return;
			}

			const key = Number(field.parent?.key);
			const uiModel = field.form?.value as OtherLiabilityModel; // NOTE: uiModel only has data fields that are not hidden
			const dtoModel = field.parent?.model as OtherLiabilityModel;

			dtoModel.details.otherLiabilityDetails.clearingBalanceOnSettlement =
				uiModel.details.otherLiabilityDetails.clearingBalanceOnSettlement;
			dtoModel.details.otherLiabilityDetails.clearingFromThisLoan =
				uiModel.details.otherLiabilityDetails.clearingFromThisLoan;
			dtoModel.details.otherLiabilityDetails.clearingThisLiability =
				uiModel.details.otherLiabilityDetails.clearingThisLiability;

			const model = Object.assign({}, dtoModel, uiModel);

			this.assetsAndLiabilitiesService.saveOtherLiability(key, model).subscribe(() => {
				modelRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private setupPersonalLoanLiability(field: FormlyFieldConfig, isNavigatedFromLinkedSecurity?: boolean): void {
		const modelRef = this.simpFormlyModalService.openModal(field, 'personalLoanDetails', { backdrop: 'static' });
		if (isNavigatedFromLinkedSecurity) {
			const hideLinkedSecuritiesField = getFormField(field.fieldGroup, 'personalLoanDetails.hideLinkedSecurities');
			hideLinkedSecuritiesField?.formControl?.patchValue(true);
		}

		const subscription = modelRef.action.subscribe((action) => {
			if (action !== 'submit') {
				if (isNavigatedFromLinkedSecurity) {
					// Delete the newly added 'Personal loan' field from the Asset modal
					formlyDeleteFromArray(field);
				}
				modelRef.close();
				subscription.unsubscribe();
				return;
			}

			const key = Number(field.parent?.key);

			const uiModel = field.form?.value as PersonalLoanModel; // NOTE: uiModel only has data fields that are not hidden
			const dtoModel = field.parent?.model as PersonalLoanModel;

			dtoModel.details.personalLoanDetails.clearingBalanceOnSettlement =
				uiModel.details.personalLoanDetails.clearingBalanceOnSettlement;
			dtoModel.details.personalLoanDetails.clearingFromThisLoan =
				uiModel.details.personalLoanDetails.clearingFromThisLoan;
			dtoModel.details.personalLoanDetails.clearingThisLiability =
				uiModel.details.personalLoanDetails.clearingThisLiability;

			const model = Object.assign({}, dtoModel, uiModel);

			this.assetsAndLiabilitiesService.savePersonalLoan(key, model).subscribe(() => {
				modelRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private setupCreditCardLiability(field: FormlyFieldConfig): void {
		this.assetsAndLiabilitiesService.setupCreditCardLiability(field);
	}

	private setupSavingAssets(field: FormlyFieldConfig, isNavigatedFromLinkedSecurity?: boolean): void {
		const modelRef = this.simpFormlyModalService.openModal(field, 'savingDetails', { backdrop: 'static' });

		if (isNavigatedFromLinkedSecurity) {
			const hideLinkedSecuritiesField = getFormField(field.fieldGroup, 'savingDetails.hideLinkedSecurities');
			hideLinkedSecuritiesField?.formControl?.patchValue(true);
		}

		const subscription = modelRef.action.subscribe((action) => {
			if (action !== 'submit') {
				if (isNavigatedFromLinkedSecurity) {
					// Delete the newly added 'Saving' field from the liability modal
					formlyDeleteFromArray(field);
				}
				modelRef.close();
				subscription.unsubscribe();
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const newValue = (field.model.savingDetails as SavingDetails).financialAssetType;
			const prevValue = (field.parent?.model as SavingModel).savingType;
			const model = Object.assign({}, field.parent?.model, field.form?.value) as SavingModel;
			const key = Number(field.parent?.key);

			if (
				prevValue &&
				((INTEREST_FINANCIAL_ASSET_TYPES.includes(prevValue) && !INTEREST_FINANCIAL_ASSET_TYPES.includes(newValue)) ||
					(DIVIDEND_FINANCIAL_ASSET_TYPES.includes(prevValue) && !DIVIDEND_FINANCIAL_ASSET_TYPES.includes(newValue)))
			) {
				this.openConfirmOtherAssetDeletionModal(field.parent as FormlyFieldConfig).subscribe((confirm) => {
					if (confirm) {
						this.assetsAndLiabilitiesService.saveSavings(key, model).subscribe(() => {
							this.assetsAndLiabilitiesService.fetchLinkedAssetEnumsForOtherIncome();
							modelRef.close();
							subscription.unsubscribe();
						});
					}
				});
			} else {
				this.assetsAndLiabilitiesService.saveSavings(key, model).subscribe(() => {
					modelRef.close();
					subscription.unsubscribe();
				});
			}
		});
	}

	private handleCreditCardDelete(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'liabilities.creditCards.delete', (field: FormlyFieldConfig) => {
			const creditCard = get(field, 'model') as CreditCardModel;

			this.formArrayDeleteService.deleteItem(field, 'CreditCard', 'creditCards').subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage('Credit card'));

				const header = this.simpFormlyHandlerService.getHeaderData<CreditCardModel, LiabilitiesHeaderSummary>(
					'creditCards'
				);
				this.updateSubSectionHeaderAfterDelete(header, creditCard, 'creditCards');
			});
		});
	}

	private handlePersonalLoanDelete(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'liabilities.personalLoans.delete', (field: FormlyFieldConfig) => {
			const personalLoan = get(field, 'model') as PersonalLoanModel;

			this.formArrayDeleteService.deleteItem(field, 'PersonalLoan', 'personalLoans').subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage('Personal loan'));

				const header = this.simpFormlyHandlerService.getHeaderData<PersonalLoanModel, LiabilitiesHeaderSummary>(
					'personalLoans'
				);

				this.updateSubSectionHeaderAfterDelete(header, personalLoan, 'personalLoans');
				this.assetsAndLiabilitiesService.updateAssetsLiabilities();
			});
		});
	}

	private handleOtherLiabilityDelete(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'liabilities.otherLiabilities.delete', (field: FormlyFieldConfig) => {
			const otherLiability = get(field, 'model') as OtherLiabilityModel;

			this.formArrayDeleteService.deleteItem(field, 'OtherLiability', 'otherLiabilities').subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage('Other liability'));

				const header = this.simpFormlyHandlerService.getHeaderData<OtherLiabilityModel, LiabilitiesHeaderSummary>(
					'otherLiabilities'
				);

				this.updateSubSectionHeaderAfterDelete(header, otherLiability, 'otherLiabilities');
				this.assetsAndLiabilitiesService.updateAssetsLiabilities();
			});
		});
	}

	private updateSubSectionHeaderAfterDelete(
		header: LiabilitiesHeaderSummary,
		deletedData: OtherLiabilityModel | PersonalLoanModel | CreditCardModel,
		state: string
	) {
		const updatedHeader = LiabilitiesHeaderTransformer.updateHeaderAfterDelete(header, deletedData);
		this.simpFormlyHandlerService.upsertToSubSectionHeader(state, updatedHeader);
	}

	private openSelectNewAssetTypeModal(field: FormlyFieldConfig): void {
		const model = field.formControl?.value as EnumObject;
		const liabilitiesField = getParentFormField(field, 'liabilities');

		if (!liabilitiesField || model.id !== -1) {
			return;
		}

		// Reset the field
		field.formControl?.reset();

		// Open the modal for selecting the type of liabilities
		const modalConfig = {
			size: 'md',
			windowClass: 'd-block modal fade show simp-modal-confirm'
		};
		const parentModalRef = this.simpFormlyModalService.openModal(liabilitiesField, 'selectNewAssetType', modalConfig);

		const parentSubscription = parentModalRef.action.subscribe((parentAction) => {
			if (parentAction !== 'submit') {
				parentModalRef.close();
				parentSubscription.unsubscribe();
				return;
			}

			const typeOfAssetsField = getFormField(liabilitiesField?.fieldGroup, 'selectNewAssetType');
			const typeOfAssetsModel = typeOfAssetsField?.model as EnumObject;

			if (!typeOfAssetsModel?.type) {
				parentModalRef.close();
				parentSubscription.unsubscribe();
				return;
			}

			// Handle 'Other Asset' type
			if (parseInt(typeOfAssetsModel.type) === AssetTypeEnum.OtherAsset) {
				this.addOtherAssetField(liabilitiesField);
			}

			// Handle 'Savings' type
			if (parseInt(typeOfAssetsModel.type) === AssetTypeEnum.Savings) {
				this.addSavingField(liabilitiesField);
			}

			parentModalRef.close();
			parentSubscription.unsubscribe();
		});
	}

	private openSelectNewLiabilityTypeModal(field: FormlyFieldConfig): void {
		const model = field.formControl?.value as EnumObject;
		const assetsField = getParentFormField(field, 'assets');

		if (!assetsField || model.id !== -1) {
			return;
		}

		// Reset the field
		field.formControl?.reset();

		// Open the modal for selecting the type of liabilities
		const modalConfig = {
			size: 'md',
			windowClass: 'd-block modal fade show simp-modal-confirm'
		};
		const parentModalRef = this.simpFormlyModalService.openModal(assetsField, 'selectNewLiabilityType', modalConfig);

		const parentSubscription = parentModalRef.action.subscribe((parentAction) => {
			if (parentAction !== 'submit') {
				parentModalRef.close();
				parentSubscription.unsubscribe();
				return;
			}

			const typeOfLiabilitiesField = getFormField(assetsField?.fieldGroup, 'selectNewLiabilityType');
			const typeOfLiabilitiesModel = typeOfLiabilitiesField?.model as EnumObject;

			if (!typeOfLiabilitiesModel?.type) {
				parentModalRef.close();
				parentSubscription.unsubscribe();
				return;
			}

			// Handle 'Other Liability' type
			if (parseInt(typeOfLiabilitiesModel.type) === LiabilityTypeEnum.OtherLiability) {
				this.addOtherLiabilityField(assetsField);
			}

			// Handle 'Personal loan' type
			if (parseInt(typeOfLiabilitiesModel.type) === LiabilityTypeEnum.PersonalLoan) {
				this.addPersonalLoanField(assetsField);
			}

			parentModalRef.close();
			parentSubscription.unsubscribe();
		});
	}

	private addOtherAssetField(liabilitiesField: FormlyFieldConfig): void {
		const otherAssetsField = getFormField(liabilitiesField.parent?.fieldGroup, 'assets.otherAssets');
		if (!otherAssetsField) return;

		formlyAddItemToArray(otherAssetsField);

		this.setupDetailsFormField(otherAssetsField);
	}

	private addSavingField(liabilitiesField: FormlyFieldConfig): void {
		const savingField = getFormField(liabilitiesField.parent?.fieldGroup, 'assets.savings');
		if (!savingField) return;

		formlyAddItemToArray(savingField);

		this.setupDetailsFormField(savingField);
	}

	private addOtherLiabilityField(liabilitiesField: FormlyFieldConfig): void {
		const otherLiabilityField = getFormField(liabilitiesField.parent?.fieldGroup, 'liabilities.otherLiabilities');
		if (!otherLiabilityField) return;

		formlyAddItemToArray(otherLiabilityField);

		this.setupDetailsFormField(otherLiabilityField);
	}

	private addPersonalLoanField(liabilitiesField: FormlyFieldConfig): void {
		const personalLoansField = getFormField(liabilitiesField.parent?.fieldGroup, 'liabilities.personalLoans');
		if (!personalLoansField) return;

		formlyAddItemToArray(personalLoansField);

		this.setupDetailsFormField(personalLoansField);
	}

	private setupDetailsFormField(field: FormlyFieldConfig): void {
		// Using setTimeout to ensure we get the fully initialized field after adding the new field to the array
		setTimeout(() => {
			const detailsField = getInitializedFormField(field.fieldGroup, '0.details');
			const fieldKey = field.key as string;

			if (detailsField) {
				switch (fieldKey) {
					case 'personalLoans':
						this.setupPersonalLoanLiability(detailsField, true);
						break;

					case 'otherLiabilities':
						this.setupOtherLiability(detailsField, true);
						break;

					case 'savings':
						this.setupSavingAssets(detailsField, true);
						break;

					case 'otherAssets':
						this.setupOtherAsset(detailsField, true);
						break;

					default:
						break;
				}
			}
		}, 10);
	}
}
