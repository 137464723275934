import { Injectable } from '@angular/core';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { ApplicantEntityType, YesNo } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { defaultArray, getNullableBoolean } from '@app/modules/shared/helper/util';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	BankDetailsDTO,
	CompanyTrustAddressesDTO,
	ContactPersonDTO,
	TrustApplicantTypeDTO,
	TrustBeneficiaryDTO,
	TrustBusinessDetailsDTO,
	TrustContactDetailsDTO,
	TrustDeedVariationDTO,
	TrusteeDetailsDTO,
	TrustSettlorDTO
} from '@app/modules/typings/api';
import { catchError, combineLatest, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { CompanyTrustAddressesTransformer } from '../../models/company-addressess.model';
import { ContactPersonTransformer } from '../../models/contact-person.model';
import { SourceOfWealthModel } from '../../models/source-of-wealth.model';
import { TrustApplicantDTO, TrustApplicantTransformer } from '../../models/trust-applicant.model';
import { TrustBeneficiaryModel, TrustBeneficiaryTransformer } from '../../models/trust-beneficiary.model';
import { TrustBusinessDetailsModel, TrustBusinessDetailsTransformer } from '../../models/trust-business-details.model';
import { TrustContactDetailsModel, TrustContactDetailsTransformer } from '../../models/trust-contact-details.model';
import { TrustDeedVariationModel, TrustDeedVariationTransformer } from '../../models/trust-deed-variation.model';
import { TrustDetailsModel, TrustDetailsTransformer } from '../../models/trust-details.model';
import { TrustSettlorModel, TrustSettlorTransformer } from '../../models/trust-settlor.model';
import { TrustApplicantTypeModel } from '../../models/trust-type.model';
import { TrusteeModel, TrusteeTransformer } from '../../models/trustee.model';
import { BankingService } from '../banking.service';
import { SourceOfWealthService } from '../source-of-wealth.service';

@Injectable({ providedIn: 'root' })
export class TrustApplicantsService extends BaseJourneyService {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private channelSettingQuery: ChannelSettingQuery,
		private formEnumsQuery: FormEnumsQuery,
		private bankingService: BankingService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private sourceOfWealthService: SourceOfWealthService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	deleteBusinessDetails(details: TrustBusinessDetailsDTO, applicantId: number): Observable<number> {
		return <Observable<number>>this.patch(
			`TrustApplicant/ResetBusiness/${this.applicationDataQuery.applicationId()}/${applicantId}`,
			{
				abn: null,
				businessIndustryCode: null,
				businessMainBusinessActivity: null,
				businessName: null,
				businessNameSameAsTrustName: null
			}
		);
	}

	deleteDeedVariation(deedVariation: TrustDeedVariationDTO, applicantId: number): Observable<boolean> {
		if (deedVariation?.id) {
			return this.delete(
				`TrustDeedVariation/${this.applicationDataQuery.applicationId()}/${applicantId}/${deedVariation.id}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	deleteTrustApplicant(applicant: SetUpApplicant): Observable<number> {
		return this.delete(`TrustApplicant/${applicant.id}`) as Observable<number>;
	}

	deleteTrustee(trustee: TrusteeDetailsDTO, applicantId: number): Observable<boolean> {
		if (trustee?.id) {
			return this.delete(`Trustee/${this.applicationDataQuery.applicationId()}/${applicantId}/${trustee.id}`).pipe(
				map(() => true)
			);
		} else {
			return of(true);
		}
	}

	deleteBankingDetails(payload: BankDetailsDTO): Observable<boolean> {
		return this.bankingService.deleteBankDetails(payload.id!, ApplicantEntityType.TrustApplicant);
	}

	deleteBeneficiary(beneficiary: TrustBeneficiaryModel, applicantId: number): Observable<boolean> {
		if (beneficiary?.id) {
			return this.delete(
				`TrustBeneficiary/${this.applicationDataQuery.applicationId()}/${applicantId}/${beneficiary.id}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	deleteSettlors(settlor: TrustSettlorModel, applicantId: number): Observable<boolean> {
		if (settlor?.id) {
			return this.delete(`Settlor/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchTrustApplicants(): Observable<TrustApplicantDTO[]> {
		if (this.channelSettingQuery.getAllowTrustApplicants()) {
			return <Observable<TrustApplicantDTO[]>>(
				this.getCustom(`TrustApplicant/${this.applicationDataQuery.applicationId()}`).pipe(catchError(() => of([])))
			);
		} else {
			return of([]);
		}
	}

	getSummaryData() {
		const trustApplicants = this.applicationDataQuery.getTrustApplicants();
		return forkJoin(
			trustApplicants.map((ta) =>
				forkJoin({
					type: this.fetchTrustType(ta.id),
					trustDetails: combineLatest([
						this.fetchTrustDetails(ta.id).pipe(map((d) => d[0])),
						this.fetchTrustDeedVariation(ta.id)
					]).pipe(map(([details, deedVariations]) => ({ ...details, deedVariations: deedVariations }))),
					businessDetails: this.fetchBusinessDetails(ta.id).pipe(map((d) => d[0])),
					applicantDetails: combineLatest([
						this.fetchContactPerson(ta.id).pipe(map((d) => d[0])),
						this.fetchContactDetails(ta.id).pipe(map((d) => d[0])),
						this.fetchAddresses(ta.id).pipe(map((d) => d[0]))
					]).pipe(
						map(([contactPerson, contactDetails, addresses]) => ({ ...contactPerson, ...contactDetails, ...addresses }))
					),
					bankingDetails: this.fetchBankingDetails(ta.id).pipe(map(defaultArray)),
					personnel: combineLatest([
						this.fetchTrusteeDetails(ta.id),
						this.fetchBeneficiaries(ta.id),
						this.fetchSettlor(ta.id)
					]).pipe(
						map(([trustees, beneficiaries, settlors]) => ({
							trustees: trustees.map((trustee) => ({
								appointmentDate: trustee.appointmentDate,
								name: trustee.existingSelect.firstName
									? `${trustee.existingSelect.firstName} ${trustee.existingSelect.lastName}`
									: trustee.existingSelect.companyName
									? trustee.existingSelect.companyName
									: trustee.existingSelect.trustName
							})),
							beneficiaries,
							settlors
						}))
					)
				})
			)
		).pipe(
			map((res) =>
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				Object.assign(
					{},
					...res.map((applicant, index) => {
						const key = `trustApplicant${index}`;
						return {
							[key]: [applicant]
						};
					})
				)
			)
		);
	}

	saveAddresses(payload: CompanyTrustAddressesDTO): Observable<number> {
		const toPayload = CompanyTrustAddressesTransformer.toPayload(payload);
		return <Observable<number>>this.patch(`TrustApplicant/Address`, toPayload);
	}

	saveTrustApplicant(applicant: SetUpApplicant): Observable<number> {
		const payload = TrustApplicantTransformer.toPayload(applicant, this.applicationDataQuery.applicationId());
		return <Observable<number>>this.postCustom(`TrustApplicant`, payload);
	}

	saveTrustType(payload: TrustApplicantTypeModel): Observable<number> {
		const applicant: SetUpApplicant = {
			id: payload.id,
			primaryApplicant: getNullableBoolean(payload.primaryApplicant)!,
			applicantType: payload.role,
			trustName: payload.trustName
		};
		const toPayload = TrustApplicantTransformer.toPayload(applicant, this.applicationDataQuery.applicationId());
		return <Observable<number>>this.postCustom(`TrustApplicant`, toPayload);
	}

	saveTrustDetails(payload: TrustDetailsModel): Observable<number> {
		const toPayload = TrustDetailsTransformer.toPayload(this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.patch(`TrustApplicant/Details`, toPayload);
	}

	saveTrustDeedVariation(
		variationData: TrustDeedVariationModel,
		index: number,
		applicantId: number
	): Observable<number> {
		const payload = TrustDeedVariationTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			applicantId,
			variationData
		);
		return this.postCustom(`TrustDeedVariation`, payload).pipe(
			map((variationId: number) => {
				(payload.id = variationId), (variationData = TrustDeedVariationTransformer.fromPayload(payload));
				return variationId;
			})
		);
	}

	saveTrustBusinessDetails(businessDetails: TrustBusinessDetailsModel, applicantId: number): Observable<number> {
		return this.fetchBusinessDetailsId(applicantId).pipe(
			switchMap((id: number) => {
				const payload = TrustBusinessDetailsTransformer.toPayload(this.applicationDataQuery.applicationId(), {
					...businessDetails,
					id
				});
				return <Observable<number>>this.patch(`TrustApplicant/Business`, payload);
			})
		);
	}

	saveContactPerson(payload: ContactPersonDTO): Observable<number> {
		const toPayload = ContactPersonTransformer.toPayload(this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.patch(`TrustApplicant/ContactPerson`, toPayload);
	}

	saveContactDetails(payload: TrustContactDetailsModel, applicantId: number): Observable<number> {
		const toPayload = TrustContactDetailsTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			applicantId,
			payload
		);
		return <Observable<number>>this.patch(`TrustApplicant/ContactDetails`, toPayload);
	}

	saveTrustee(payload: TrusteeModel, applicantId: number): Observable<number> {
		const toPayload = TrusteeTransformer.toPayload(applicantId, this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.postCustom(`Trustee`, toPayload);
	}

	saveBankingDetails(payload: BankDetailsDTO): Observable<number> {
		return this.bankingService.saveBankDetails(payload, ApplicantEntityType.TrustApplicant);
	}

	saveBeneficiary(payload: TrustBeneficiaryModel, applicantId: number): Observable<number> {
		const toPayload = TrustBeneficiaryTransformer.toPayload(applicantId, payload);
		return <Observable<number>>this.postCustom(`TrustBeneficiary`, toPayload);
	}

	saveSettlor(payload: TrustSettlorModel, applicantId: number): Observable<number> {
		const toPayload = TrustSettlorTransformer.toPayload(applicantId, payload);
		return <Observable<number>>this.postCustom(`Settlor`, toPayload);
	}

	fetchSourceOfWealth(applicantId: number): Observable<SourceOfWealthModel[]> {
		return this.sourceOfWealthService.fetchSourceOfWealth(applicantId, ApplicantEntityType.TrustApplicant);
	}

	setupState$(i?: number) {
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		let trustApplicants = applicants.filter(
			(applicant) => applicant.applicantEntityType === ApplicantEntityType.TrustApplicant
		);
		trustApplicants = i === undefined ? trustApplicants : [trustApplicants[i]];
		return forkJoin(
			trustApplicants.map((trustApplicant) =>
				forkJoin([
					this.fetchTrustType(trustApplicant.id).pipe(catchError(() => of([]))),
					this.fetchTrustDetails(trustApplicant.id),
					this.fetchTrustDeedVariation(trustApplicant.id),
					this.fetchBusinessDetails(trustApplicant.id),
					this.fetchContactPerson(trustApplicant.id),
					this.fetchContactDetails(trustApplicant.id),
					this.fetchAddresses(trustApplicant.id),
					this.fetchBankingDetails(trustApplicant.id),
					this.fetchTrusteeDetails(trustApplicant.id),
					this.fetchBeneficiaries(trustApplicant.id),
					this.fetchSettlor(trustApplicant.id),
					this.fetchSourceOfWealth(trustApplicant.id)
				])
			)
		).pipe(
			tap((applicantData) => {
				applicantData.forEach(
					([
						trustType,
						trustDetails,
						trustDeedVariation,
						businessDetails,
						contactPerson,
						contactDetails,
						addresses,
						bankingDetails,
						trusteeDetails,
						beneficiaries,
						settlors,
						sourceOfWealth
					]) => {
						this.simpFormlyHandlerService.upsertToStateWithData(`type`, trustType);
						this.simpFormlyHandlerService.upsertToStateWithData(`trustDetails`, trustDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`deedVariation`, trustDeedVariation);
						this.simpFormlyHandlerService.upsertToStateWithData(`businessDetails`, businessDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`contactPerson`, contactPerson);
						this.simpFormlyHandlerService.upsertToStateWithData(`contactDetails`, contactDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`addresses`, addresses);
						this.simpFormlyHandlerService.upsertToStateWithData(`bankingDetails`, bankingDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`trusteeDetails`, trusteeDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`beneficiaries`, beneficiaries);
						this.simpFormlyHandlerService.upsertToStateWithData(`settlors`, settlors);
						this.simpFormlyHandlerService.upsertToStateWithData(`sourceOfWealth`, sourceOfWealth);
					}
				);
			})
		);
	}

	private fetchTrustType(applicantId: number): Observable<TrustApplicantTypeDTO[]> {
		return this.fetchTrustApplicants().pipe(
			map((res: TrustApplicantDTO[]) => {
				const applicantById = res.filter((x) => x.id == applicantId);
				return applicantById.map((x) => {
					return {
						id: x.id,
						role: x.applicantType,
						primaryApplicant: x.primaryApplicant ? YesNo.Yes : YesNo.No
					} as TrustApplicantTypeDTO;
				});
			})
		);
	}

	private fetchTrustDetails(applicantId: number): Observable<TrustDetailsModel[]> {
		return this.getCustom(`TrustApplicant/Details/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((r) => [TrustDetailsTransformer.fromPayload(r)])
		);
	}

	private fetchTrustDeedVariation(applicantId: number): Observable<TrustDeedVariationModel[]> {
		return this.getCustom(`TrustDeedVariation/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((variations: TrustDeedVariationDTO[]) =>
				variations.map((variation) => TrustDeedVariationTransformer.fromPayload(variation))
			)
		);
	}

	private fetchBusinessDetails(applicantId: number): Observable<TrustBusinessDetailsModel[]> {
		return this.getCustom(`TrustApplicant/Business/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((details: TrustBusinessDetailsDTO) =>
				details.abn ? [TrustBusinessDetailsTransformer.fromPayload(details)] : []
			)
		);
	}

	private fetchBusinessDetailsId(applicantId: number): Observable<number> {
		return this.getCustom(`TrustApplicant/Business/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((details: TrustBusinessDetailsDTO) => details.id)
		);
	}

	private fetchContactPerson(applicantId: number): Observable<ContactPersonDTO[]> {
		return this.getCustom(
			`TrustApplicant/ContactPerson/${this.applicationDataQuery.applicationId()}/${applicantId}`
		).pipe(
			map((details: ContactPersonDTO) => [
				ContactPersonTransformer.fromPayload(
					details,
					this.formEnumsQuery.getOptionLabel('NameTitle', details.nameTitleId)
				)
			])
		);
	}

	private fetchContactDetails(applicantId: number): Observable<TrustContactDetailsModel[]> {
		return this.getCustom(
			`TrustApplicant/ContactDetails/${this.applicationDataQuery.applicationId()}/${applicantId}`
		).pipe(map((details: TrustContactDetailsDTO) => [TrustContactDetailsTransformer.fromPayload(details)]));
	}

	private fetchAddresses(applicantId: number): Observable<CompanyTrustAddressesDTO[]> {
		return this.getCustom(`TrustApplicant/Address/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((r) => [CompanyTrustAddressesTransformer.fromPayload(r)])
		);
	}

	private fetchBankingDetails(applicantId: number): Observable<BankDetailsDTO[]> {
		return this.bankingService.getBankDetails(applicantId, ApplicantEntityType.TrustApplicant);
	}

	private fetchTrusteeDetails(applicantId: number): Observable<TrusteeModel[]> {
		return this.getCustom(`Trustee/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((trustees: TrusteeDetailsDTO[]) =>
				trustees.map((trustee) => TrusteeTransformer.fromPayload(trustee, this.formEnumsQuery))
			)
		);
	}

	private fetchBeneficiaries(applicantId: number): Observable<TrustBeneficiaryModel[]> {
		return this.getCustom(`TrustBeneficiary/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((beneficiaries: TrustBeneficiaryDTO[]) =>
				beneficiaries.map((beneficiary) => TrustBeneficiaryTransformer.fromPayload(beneficiary, this.formEnumsQuery))
			)
		);
	}

	private fetchSettlor(applicantId: number): Observable<TrustSettlorModel[]> {
		return forkJoin([
			this.getCustom(`Settlor/${this.applicationDataQuery.applicationId()}/${applicantId}`),
			this.personsCompaniesEnumService.fetchPersons()
		]).pipe(
			map(([settlors, persons]: [TrustSettlorDTO[], ApplicantEnumObject[]]) => {
				return settlors.map((settlor) => {
					return TrustSettlorTransformer.fromPayload(settlor, this.formEnumsQuery);
				});
			})
		);
	}
}
