import { FormlyAttributeEvent } from '@ngx-formly/core/lib/models';
import { EnumObject } from '@simpology/client-components/utils';
import { RelatedCompanyType, TargetType } from './app.enums';

export interface EnumObjectWithClick extends EnumObject {
	click?: FormlyAttributeEvent | (() => void);
	selectedLabel?: string;
	class?: string;
	selectedClass?: string;
}

export interface ApplicantEnumObject {
	id: number;
	entityType?: number;
	type: TargetType;
	firstName?: string;
	lastName?: string;
	companyName?: string;
	trustName?: string;
	label?: string;
	groupBy?: string;
	relatedCompanyType?: RelatedCompanyType;
}

/**
 * Filter out id's provided in the excludeList from an enum array (Reflist)
 * excludeList is a comma separated string with id's to exclude. eg.: "1077, 1081, 2000"
 * @param list
 * @param excludeList
 */
export const filterListOptions = (list: EnumObject[], excludeList: string): EnumObject[] => {
	const optionsToExclude = excludeList
		.replace(' ', '')
		.split(',')
		.map((a) => +a);
	return list.filter((option) => !optionsToExclude.some((excludedOption) => excludedOption === option.id));
};
