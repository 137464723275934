import { ContactPersonDTO } from '@app/modules/typings/api';

export class ContactPersonTransformer {
	static fromPayload(contact: ContactPersonDTO, title: string): ContactPersonDTO {
		contact.fullName = contact.firstName ? `${title}. ${contact.firstName} ${contact.lastName}` : '';
		return contact;
	}

	static toPayload(applicationId: number, contact: ContactPersonDTO): Partial<ContactPersonDTO> {
		return contact;
	}
}
