import { ApplicationDetailsDto, SetUpApplicant } from '@app/modules/setup/typings/setup';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ApplicantEntityType,
	ApplicantType,
	PredominantLoanPurpose,
	RbaLendingPurpose,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { CoBorrowerBenefitDTO, PersonApplicantDTO } from '@app/modules/typings/api';
export class CoBorrowerBenefitTransformer {
	static toPayload(model: CoBorrowerBenefitModel, applicationId: number): CoBorrowerBenefitDTO {
		return {
			applicationId: applicationId,
			id: model.id ?? CONSTANTS.NEW_ID,
			areAllApplicantsRegisteredOnTitle: getNullableBoolean(model.allApplicantsTitleYesNo),
			areTheApplicantsSpousal: getNullableBoolean(model.borrowerSpouseYesNo),
			doesApplicantsUnderstandTheRisk: getNullableBoolean(model.riskExplainedYesNo),
			coBorrowerReasonsTakenIntoAccount: getNullableBoolean(model.reasonForCoBorrowerYesNo),
			isApplyingInTheirCapacity: getNullableBoolean(model.borrowerCapacityYesNo),
			isJointApplication: getNullableBoolean(model.jointYesNo),
			predominantLoanPurposeId: model.predominantLoanPurpose,
			reason: model.applicantsCoBorrowerReason,
			substantialBenefitsReason: model.documentSubstantialBenefits,
			substantialBenefits: getNullableBoolean(model.substantialBenefitsYesNo),
			isEachCoBorrowersOwnershipAboveThreshold: getNullableBoolean(model.applicantsOwnershipYesNo),
			applicantsUnderPressureToEnterLoan: getNullableBoolean(model.applicantsUnderPressureToEnterLoanYesNo),
			applicantsSubjectedToUnreasonableConduct: getNullableBoolean(model.applicantsSubjectedToUnreasonableConductYesNo),
			applicantsDifficultyUnderstandingObligations: getNullableBoolean(
				model.applicantsDifficultyUnderstandingObligationsYesNo
			),
			applicantsFinancialAbuse: getNullableBoolean(model.applicantsFinancialAbuseYesNo)
		};
	}
	static fromPayload(
		data: CoBorrowerBenefitDTO,
		personApplicants?: PersonApplicantDTO[],
		setupApplicants?: SetUpApplicant[],
		applicationDetails?: ApplicationDetailsDto
	): CoBorrowerBenefitModel {
		const primaryLendingPurpose = applicationDetails?.rbaLendingPurpose;

		return {
			allApplicantsTitleYesNo: getNullableYesNo(data.areAllApplicantsRegisteredOnTitle),
			applicantsCoBorrowerReason: data.reason,
			borrowerCapacityYesNo: getNullableYesNo(data.isApplyingInTheirCapacity),
			borrowerSpouseYesNo: getNullableYesNo(data.areTheApplicantsSpousal),
			id: data.id,
			jointYesNo: getNullableYesNo(data.isJointApplication),
			predominantLoanPurpose: data.predominantLoanPurposeId,
			predominantLoanPurposeFromPrimaryLendingPurpose:
				primaryLendingPurpose &&
				[
					RbaLendingPurpose.PurchaseExistingDwelling,
					RbaLendingPurpose.PurchaseNewDwelling,
					RbaLendingPurpose.Refinance,
					RbaLendingPurpose.Construction,
					RbaLendingPurpose.FurtherAdvance
				].includes(primaryLendingPurpose)
					? PredominantLoanPurpose.PurchaseRefinanceOrRenovations
					: primaryLendingPurpose &&
					  [RbaLendingPurpose.PurchaseOther, RbaLendingPurpose.Other].includes(primaryLendingPurpose)
					? PredominantLoanPurpose.Other
					: PredominantLoanPurpose.NoAdditionalFunds,
			reasonForCoBorrowerYesNo: getNullableYesNo(data.coBorrowerReasonsTakenIntoAccount),
			riskExplainedYesNo: getNullableYesNo(data.doesApplicantsUnderstandTheRisk),
			documentSubstantialBenefits: data.substantialBenefitsReason,
			substantialBenefitsYesNo: getNullableYesNo(data.substantialBenefits),
			applicantsOwnershipYesNo: getNullableYesNo(data.isEachCoBorrowersOwnershipAboveThreshold),
			applicantsUnderPressureToEnterLoanYesNo: getNullableYesNo(data.applicantsUnderPressureToEnterLoan),
			applicantsSubjectedToUnreasonableConductYesNo: getNullableYesNo(data.applicantsSubjectedToUnreasonableConduct),
			applicantsDifficultyUnderstandingObligationsYesNo: getNullableYesNo(
				data.applicantsDifficultyUnderstandingObligations
			),
			applicantsFinancialAbuseYesNo: getNullableYesNo(data.applicantsFinancialAbuse),
			spouseIsCoBorrower: !!personApplicants?.some((applicant) => {
				return (
					applicant.applicantType !== ApplicantType.Guarantor &&
					personApplicants.some(
						(app) =>
							app.id !== applicant.id &&
							app.householdId === applicant.householdId &&
							app.applicantType !== ApplicantType.Guarantor
					)
				);
			}),
			hasCompanyOrTrustBorrower: !!setupApplicants?.some(
				(applicant) =>
					applicant.applicantType !== ApplicantType.Guarantor &&
					applicant.applicantTypeModal?.type !== ApplicantEntityType.PersonApplicant
			)
		};
	}
}

export interface CoBorrowerBenefitModel {
	jointYesNo?: YesNo;
	borrowerCapacityYesNo?: YesNo;
	predominantLoanPurpose?: PredominantLoanPurpose;
	predominantLoanPurposeFromPrimaryLendingPurpose?: PredominantLoanPurpose;
	borrowerSpouseYesNo?: YesNo;
	allApplicantsTitleYesNo?: YesNo;
	applicantsOwnershipYesNo?: YesNo;
	substantialBenefitsYesNo?: YesNo;
	riskExplainedYesNo?: YesNo;
	reasonForCoBorrowerYesNo?: YesNo;
	applicantsCoBorrowerReason?: string;
	documentSubstantialBenefits?: string;
	id?: number;
	applicantsUnderPressureToEnterLoanYesNo?: YesNo;
	applicantsSubjectedToUnreasonableConductYesNo?: YesNo;
	applicantsDifficultyUnderstandingObligationsYesNo?: YesNo;
	applicantsFinancialAbuseYesNo?: YesNo;
	spouseIsCoBorrower: boolean;
	hasCompanyOrTrustBorrower: boolean;
}
