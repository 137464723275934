import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyDatePicker = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'datepicker',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `simp-datepicker ${property.templateOptions?.class ?? (property.class || 'col-4')}`,
			templateOptions: {
				...property.templateOptions
			},
			resetOnHide: !!property.templateOptions?.resetOnHide,
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
