import { Injectable } from '@angular/core';
import { EmployerDetailsModel, EmployerModelTransformer } from '@app/modules/employers/model/employer.model';
import {
	ApplicantEmploymentModel,
	ApplicantEmploymentModelTransformer
} from '@app/modules/financial-position/model/applicant-employment.model';
import { ApplicantForeignEmploymentModelTransformer } from '@app/modules/financial-position/model/applicant-foreign-employment.model';
import { ApplicantNotEmployedModelTransformer } from '@app/modules/financial-position/model/applicant-notemployed-income.model';
import { ApplicantSelfEmploymentModelTransformer } from '@app/modules/financial-position/model/applicant-self-employment.model';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import {
	EmployerDTO,
	EmploymentDTO,
	ForeignEmploymentDTO,
	NotEmployedDTO,
	SelfEmploymentDTO
} from '@app/modules/typings/api';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { TargetType } from '../enums/app.enums';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { AggregateFormatterService } from './aggregate-formatter.service';
import { BaseJourneyService } from './base-journey.service';
import { PersonsCompaniesEnumService } from './persons-companies-enum.service';

@Injectable({ providedIn: 'root' })
export class EmploymentService extends BaseJourneyService {
	constructor(
		private aggregateFormatterService: AggregateFormatterService,
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsQuery: FormEnumsQuery,
		private personsCompaniesEnumService: PersonsCompaniesEnumService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	fetchEmployer(): Observable<EmployerDetailsModel[]> {
		return this.getCustom(`EntityTarget/Employer/${this.applicationDataQuery.applicationId()}`).pipe(
			map((employers: EmployerDTO[]) => {
				return employers.map((employer) => EmployerModelTransformer.fromPayload(employer, this.formEnumsQuery));
			})
		);
	}

	fetchEmploymentsForApplicant(
		applicantId: number,
		applicantsSchema?: FormlyApiProperty
	): Observable<ApplicantEmploymentModel[]> {
		return forkJoin([
			this.getCustom(`Employment/applicant/${applicantId}`).pipe(
				map((employments: EmploymentDTO[] = []) => {
					return employments.map((employment) =>
						ApplicantEmploymentModelTransformer.fromPayload(
							employment,
							this.aggregateFormatterService,
							this.formEnumsQuery
						)
					);
				})
			),
			this.fetchSelfEmploymentForApplicant(applicantId, applicantsSchema),
			this.getCustom(`NotEmployed/applicant/${applicantId}`).pipe(
				map((notEmployedDTO: NotEmployedDTO[] = []) => {
					return notEmployedDTO.map((employment) =>
						ApplicantNotEmployedModelTransformer.fromPayload(employment, this.aggregateFormatterService)
					);
				})
			),
			this.getCustom(`ForeignEmployed/applicant/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
				map((foreignEmployedDTO: ForeignEmploymentDTO[] = []) => {
					return foreignEmployedDTO.map((employment) =>
						ApplicantForeignEmploymentModelTransformer.fromPayload(
							employment,
							this.aggregateFormatterService,
							this.formEnumsQuery
						)
					);
				})
			)
		]).pipe(
			map(([paygEmploymentModel, selfEmploymentModel, notEmployedModel, foreignEmployedModel]) =>
				paygEmploymentModel.concat(selfEmploymentModel).concat(notEmployedModel).concat(foreignEmployedModel)
			)
		);
	}

	private fetchSelfEmploymentForApplicant(applicantId: number, applicantsSchema?: FormlyApiProperty) {
		return this.getCustom(`SelfEmployedIncome/applicant/${applicantId}`).pipe(
			switchMap((selfEmploymentDTO: SelfEmploymentDTO[] = []) => {
				if (!selfEmploymentDTO?.length) {
					return of([]);
				}
				return forkJoin(
					selfEmploymentDTO.map((selfEmployment) =>
						selfEmployment.employerPartyId && selfEmployment.employerTargetType === TargetType.RelatedCompany
							? this.personsCompaniesEnumService.getRelatedCompany(selfEmployment.employerPartyId)
							: of(null)
					)
				).pipe(
					map((relatedCompanies) => {
						return selfEmploymentDTO.map((employment) =>
							ApplicantSelfEmploymentModelTransformer.fromPayload(
								employment,
								this.formEnumsQuery,
								relatedCompanies?.filter((company) => company?.id === employment.employerPartyId)?.[0] ?? undefined
							)
						);
					})
				);
			})
		);
	}
}
