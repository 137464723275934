export enum RefiStepType {
	RefiEligibility = 1001,
	RefiConfirmDetails = 1002,
	RefiApplicantConsent = 1003,
	RefiLoanRequirements = 1004,
	RefiIncome = 1005,
	RefiExpenses = 1006,
	RefiAssets = 1007,
	RefiLiabilities = 1008,
	RefiSelectProduct = 1009,
	RefiReview = 1010,
	RefiUploadDocuments = 1011,
	RefiChooseLoan = 1012,
	RefiPropertyDetails = 1013,
	RefiLoanDetails = 1014
}

export const RefiStepToPath = new Map<RefiStepType, string>([
	[RefiStepType.RefiConfirmDetails, 'confirmDetails/details'],
	[RefiStepType.RefiEligibility, 'eligibility/details'],
	[RefiStepType.RefiChooseLoan, 'chooseLoan/details'],
	[RefiStepType.RefiLoanDetails, 'loanDetails/details'],
	[RefiStepType.RefiPropertyDetails, 'propertyDetails/details'],
	[RefiStepType.RefiLoanRequirements, 'loanRequirements/details'],
	[RefiStepType.RefiIncome, 'income/details'],
	[RefiStepType.RefiExpenses, 'expenses/details'],
	[RefiStepType.RefiAssets, 'assets/details'],
	[RefiStepType.RefiLiabilities, 'liabilities/details'],
	[RefiStepType.RefiSelectProduct, 'products/details'],
	[RefiStepType.RefiReview, 'review/details']
]);
