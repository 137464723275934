import { LendingGuaranteeDTO } from '@app/modules/typings/api';

export class LendingGuaranteeTransformer {
	static fromPayload(lendingGuaranteeDTO: LendingGuaranteeDTO): LendingGuaranteeModel {
		return {
			lendingGuaranteeTypeId: lendingGuaranteeDTO?.lendingGuaranteeTypeId,
			relationshipToPrimaryApplicantId: lendingGuaranteeDTO?.relationshipToPrimaryApplicantId
		};
	}

	static toPayload(lendingGuaranteeModel: LendingGuaranteeModel): LendingGuaranteeDTO {
		return {
			lendingGuaranteeTypeId: lendingGuaranteeModel?.lendingGuaranteeTypeId,
			relationshipToPrimaryApplicantId: lendingGuaranteeModel?.relationshipToPrimaryApplicantId
		};
	}
}
export interface LendingGuaranteeModel {
	lendingGuaranteeTypeId?: number;
	relationshipToPrimaryApplicantId?: number;
}
