import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class GuarantorInterviewDetailsTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorInterviewDetailsModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			lendingGuaranteeTypeId: payload.lendingGuaranteeTypeId,
			name: payload.partyName,
			date: payload.interviewDate,
			loanFacilities: payload.loanProduct,
			occupation: payload.occupation,
			dateOfBirth: payload.dateOfBirth,
			phoneNumber: PhoneNumberHelper.fromPayload(payload.phoneNumber),
			photoId: payload.photoIdSighted,
			guarantorApplicantId: payload.guarantorApplicantId,
			hideChecklist: payload.hideChecklist
		};
	}
}

export interface GuarantorInterviewDetailsModel {
	id?: number;
	lendingGuaranteeId?: number;
	lendingGuaranteeTypeId?: number;
	name?: string;
	date?: string;
	occupation?: string;
	loanFacilities?: string;
	dateOfBirth?: string;
	phoneNumber?: PhoneDetails;
	photoId?: number;
	guarantorApplicantId?: number;
	hideChecklist?: boolean;
}
