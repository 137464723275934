{{ to.label }}
<div *ngIf="to.subText" [innerHTML]="to.subText"></div>
<div
	*ngIf="multiSelectFormGroup.dirty && multiSelectFormGroup.touched && multiSelectFormGroup.invalid && to.required"
	class="simp-field-error simp-text-small simp-text--red simp-margin-top-micro"
>
	Please select at least one option {{ hasOther ? 'or enter ' + otherLabel : '' }}
</div>
<div [formGroup]="multiSelectFormGroup">
	<div formArrayName="multiSelectCheckboxes">
		<div *ngIf="!multiSelectCheckboxes.controls.length">{{ to.placeholder }}</div>
		<div class="simp-row">
			<div class="col-8" *ngFor="let checkbox of multiSelectCheckboxes.controls; let i = index" [formGroupName]="i">
				<simp-checkbox
					[id]="uniqueId + i"
					[ngClass]="uniqueId"
					[formControl]="$any(checkbox.get('checkbox'))"
					[readonly]="!!to.readonly"
					[labelOnSide]="to.labelOnSide!"
					[tooltip]="$any(multiSelectOptions[i].info)"
					(checkboxChange)="updateSelectOptions(true)"
					[validationErrors]="checkBoxErrorMessages"
					>{{ multiSelectOptions[i].label }}
				</simp-checkbox>
			</div>

			<div *ngIf="hasOther" class="col-12 simp-margin-top-small">
				<p class="simp-label">
					{{ otherLabel }} {{ otherReasonInput.hasValidator(Validators.required) ? '' : '(optional)' }}
				</p>
				<textarea
					[id]="uniqueId"
					[readonly]="!!to.readonly"
					[formControl]="otherReasonInput"
					class="form-control simp-form-control simp-margin-bottom-medium"
					[class.is-invalid]="otherReasonInput.invalid && (otherReasonInput.dirty || otherReasonInput.touched)"
					rows="2"
				>
				</textarea>

				<simp-field-error
					*ngIf="(otherReasonInput.dirty || otherReasonInput.touched) && otherReasonInput.errors"
					[errors]="otherReasonValidationError"
					[validationErrors]="field.validation?.messages!"
				>
				</simp-field-error>
			</div>
		</div>
	</div>
</div>
