import { Injectable } from '@angular/core';
import { StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { formlyOnClick } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { switchMap } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiPropertyDetails } from '../../models/refi-property-details.model';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiPropertyDetailsService } from './refi-property-details.service';

@Injectable({
	providedIn: 'root'
})
export class RefiPropertyDetailsTransformerService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private journeyStepService: JourneyStepService,
		private refiJourneyService: RefiJourneyService,
		private refiPropertyDetailsService: RefiPropertyDetailsService
	) {}

	propertyDetailsTransformer(formFields: FormlyFieldConfig[]) {
		formlyOnClick(
			formFields,
			'propertyDetails.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				if (!clickType.invalid && clickType.type === 'primary') {
					this.refiPropertyDetailsService
						.savePropertyDetails({
							...(field.model as RefiPropertyDetails[])?.[0],
							applicationId: this.applicationDataQuery.applicationId()
						})
						.pipe(
							switchMap(() => this.journeyStepService.updateStep(RefiStepType.RefiPropertyDetails, StepStatus.Complete))
						)
						.subscribe(() => {
							this.refiJourneyService.navigateToNextStep(field);
						});
				}
			}
		);
	}
}
