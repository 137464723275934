import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RefiApplicationSummaryService } from '@app/modules/refi-journey/components/refi-form/application-summary/application-summary.service';
import { RefiStore } from '@app/modules/refi-journey/enums/refi-store.enum';
import { AnalyticsEventType, RefiAnalyticsService } from '@app/modules/refi-journey/services/refi-analytics.service';
import { RefiJourneyService } from '@app/modules/refi-journey/services/refi-journey.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { JourneyType } from '@app/modules/shared/enums/app.enums';
import { NavigationStep } from '@app/modules/shared/store/application-data/typings/application-data';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormMetaDataQuery } from '@app/modules/shared/store/form-metadata/form-metadata.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { FormAreas } from '@app/modules/shared/typings/form-areas.types';
import { toJsonSchema } from '@app/modules/simp-formly/helpers/elements/formly-transformer';
import { getFormField, resolvedFormArray } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { getRegistry } from '@ngneat/elf';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { BrandingService } from '@simpology/authentication';
import { SimpConfirmationDialogService, SimpProgressTrackerStep } from '@simpology/client-components';
import { ConfirmationDialogHeaderType } from '@simpology/client-components/utils';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash-es';
import {
	Observable,
	Subject,
	delay,
	distinctUntilChanged,
	filter,
	map,
	of,
	switchMap,
	take,
	takeUntil,
	tap
} from 'rxjs';
import { RefiModalName, RefiModalService } from '../services/refi-modal.service';

@Component({
	selector: 'refi-header',
	templateUrl: './refi-header.component.html',
	styleUrls: ['./refi-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefiHeaderComponent implements OnInit, OnDestroy {
	@Input() steps?: NavigationStep[];
	@Input() currentStep?: SimpProgressTrackerStep;

	appTitle = '';
	saveCloseTitle = '';
	showLogoutButton = false;
	brandUrl$?: Observable<string>;
	formFields?: FormlyFieldConfig[];
	field?: FormlyFieldConfig;
	brandName = '';
	formAreaToFetch!: FormAreas;
	private destroy$: Subject<void> = new Subject();

	constructor(
		private router: Router,
		private cdr: ChangeDetectorRef,
		private brandingService: BrandingService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private applicationSummaryService: RefiApplicationSummaryService,
		private refiJourneyService: RefiJourneyService,
		private formDataService: FormDataService,
		private formMetaDataQuery: FormMetaDataQuery,
		private formlyJsonschema: FormlyJsonschema,
		private refiModalService: RefiModalService,
		private sharedFlagService: SharedFlagsService,
		private refiAnalyticsService: RefiAnalyticsService
	) {}

	protected get showSteps(): boolean {
		return !this.router.url.includes('summary');
	}

	ngOnInit(): void {
		switch (this.sharedFlagService.currentJourney) {
			case JourneyType.Refi:
				this.appTitle = 'Refinance application';
				this.formAreaToFetch = FormAreas.refi;
				break;
			case JourneyType.Purchase:
				this.appTitle = 'Home loan application';
				this.formAreaToFetch = FormAreas.purchase;
				break;
			default:
				this.appTitle = this.router.url.includes(CONSTANTS.REFI_LOGOUT_PATH)
					? 'Refinance application'
					: 'Home loan application';
				break;
		}

		this.brandUrl$ = this.brandingService.brandData.pipe(
			filter((data) => !!data?.brandUrl),
			take(1),
			tap((data) => (this.brandName = data.brandName)),
			map((data) => data.brandUrl)
		);
		this.saveCloseTitle = this.updateSaveCloseTitle();
		this.showLogoutButton = this.updateShowLogoutButton();
		this.router.events.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$)).subscribe(() => {
			this.saveCloseTitle = this.updateSaveCloseTitle();
			this.showLogoutButton = this.updateShowLogoutButton();
			this.cdr.markForCheck();
		});
		this.formMetaDataQuery
			.select(this.formAreaToFetch)
			.pipe(
				filter((metadata) => !!metadata),
				take(1),
				map((metadata) => {
					const schema = toJsonSchema(metadata) as JSONSchema7;
					return [this.formlyJsonschema.toFieldConfig(schema, resolvedFormArray)];
				})
			)
			.subscribe((fields) => {
				this.formFields = fields;
				this.showFEAModal();
			});
		this.formDataService
			.select$<RefiApplicationSummaryDTO>(RefiStore.ApplicationSummary)
			.pipe(
				filter((x) => !!x && x.length > 0),
				takeUntil(this.destroy$),
				distinctUntilChanged()
			)
			.subscribe((data) => {
				if (data[0] && data[0].hasApplicationSubmitted) {
					const tertiaryButton = getFormField(this.formFields, 'refiCommon.saveAndExitLabels.tertiaryButton');
					if (tertiaryButton?.templateOptions) {
						tertiaryButton.templateOptions.label = '';
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	updateShowLogoutButton(): boolean {
		return (
			!this.router.url.includes('error') &&
			!this.router.url.includes('improvements') &&
			!this.router.url.includes('refi-logout') &&
			!this.refiJourneyService.isAdminUser
		);
	}

	updateSaveCloseTitle(): string {
		const summaryDetails = this.formDataService.getStateData(
			RefiStore.ApplicationSummary
		)[0] as RefiApplicationSummaryDTO;
		if (summaryDetails?.hasApplicationSubmitted) {
			return '';
		} else if (
			this.router.url.includes('summary/details') ||
			this.router.url.includes('eligibility/details') ||
			this.router.url.includes('confirmDetails/details')
		) {
			return 'Close';
		} else {
			return 'Save';
		}
	}

	logOut(): void {
		if (this.saveCloseTitle === 'Save') {
			this.refiAnalyticsService.trackEvent(AnalyticsEventType.CustomEvent, 'save');
		} else {
			this.refiAnalyticsService.trackEvent(AnalyticsEventType.CustomEvent, 'close');
		}

		if (
			this.router.url.includes('eligibility/details') ||
			this.router.url.includes('confirmDetails/details') ||
			this.formDataService.getRefiApplicationSummary().isCurrentUserDiscarded !== null
		) {
			this.refiJourneyService.logOut(true);
			return;
		}
		const field = getFormField(this.formFields, 'refiCommon');
		this.refiModalService
			.openRefiModal(field, RefiModalName.saveAndExit)
			.pipe(takeUntil(this.destroy$))
			.subscribe((action) => {
				if (action === 'primaryButton') {
					this.applicationSummaryService.unlockApplication().subscribe(() => {
						this.refiJourneyService.logOut();
					});
				} else if (action === 'tertiaryButton') {
					this.confirmationDialogService
						.confirm(
							// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
							ConfirmationDialogHeaderType.Delete,
							`Are you sure you want to delete?`,
							``,
							'Yes, delete',
							'No, continue',
							'sm',
							'refi-modal-content'
						)
						.subscribe((res) => {
							if (res) {
								this.refiJourneyService.deleteApplication().subscribe(() => {
									const currentJourney = this.sharedFlagService.currentJourney;
									getRegistry().forEach((store) => store.reset());
									this.refiJourneyService.logOut(true, currentJourney);
								});
							}
						});
				}
			});
	}

	private showFEAModal() {
		this.refiJourneyService.FEAInfoLoaded$.pipe(
			takeUntil(this.destroy$),
			switchMap((res) => {
				if (res === false) {
					const field = getFormField(this.formFields, 'refiCommon');
					this.refiModalService
						.openRefiModal(field, RefiModalName.dataEnrichment, '', 'modal--full-screen')
						.pipe(takeUntil(this.destroy$));
				} else if (res === true) {
					this.refiModalService.closeRefiModal(RefiModalName.dataEnrichment);
					this.refiModalService.closeRefiModal(RefiModalName.dataEnrichmentLonger);
				}
				return of(res);
			}),
			delay(30000),
			map((res) => {
				if (res === false && this.refiModalService.isModalOpen(RefiModalName.dataEnrichment)) {
					this.refiModalService.closeRefiModal(RefiModalName.dataEnrichment);
					const field = getFormField(this.formFields, 'refiCommon');
					this.refiModalService
						.openRefiModal(field, RefiModalName.dataEnrichmentLonger, '', 'modal--full-screen')
						.pipe(takeUntil(this.destroy$));
				} else if (res === true) {
					this.refiModalService.closeRefiModal(RefiModalName.dataEnrichmentLonger);
				}
			})
		).subscribe();
	}
}
