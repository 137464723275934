import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService } from '@app/layouts/feature-flag/feature-flag.service';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isFunction, pickBy } from 'lodash-es';

/**
 * show the metadata key when hovered for more than 2 seconds
 * conditional on 'configMode' feature flag, non-focus mode
 */
@Directive({
	selector: '[metadataKey]',
	providers: [NgbPopover]
})
export class MetadataKeyDirective implements OnInit, OnDestroy {
	@Input() formlyField!: FormlyFieldConfig;
	private hoverTimeout?: number;
	private popoverTimeout?: number;
	private hoverTitle = '';
	private hoverTemplateOptions = '';
	private hoverHide = '';
	private hoverHideExpression = '';
	private ignoreFocusElements: string[] = ['input', 'textarea'];

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private popover: NgbPopover,
		private featureFlagService: FeatureFlagService
	) {}

	ngOnInit(): void {
		if (this.featureFlagService.getFlag('configMode')) {
			this.hoverTitle = this.generateHoverText(this.formlyField);

			this.elementRef.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
			this.elementRef.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
		}
	}

	ngOnDestroy(): void {
		this.elementRef.nativeElement.removeEventListener('mouseenter', this.onMouseEnter.bind(this));
		this.elementRef.nativeElement.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
		clearTimeout(this.hoverTimeout);
		clearTimeout(this.popoverTimeout);
	}

	private onMouseEnter(): void {
		this.hoverTimeout = setTimeout(() => {
			if (!this.shouldIgnoreFocus()) {
				this.hoverTemplateOptions = JSON.stringify(
					pickBy(this.formlyField.templateOptions, (value) => !isFunction(value) && typeof value !== 'object')
				);
				this.hoverHide = JSON.stringify(this.formlyField.hide);
				this.hoverHideExpression = JSON.stringify(this.formlyField.hideExpression);
				const configValues: ConfigValues = {
					templateOptions: JSON.parse(this.hoverTemplateOptions) as Object,
					hide: this.hoverHide,
					hideExpression: JSON.parse(this.hoverHideExpression) as Object
				};
				this.popover.ngbPopover = JSON.stringify(configValues);
				console.table(configValues);
				this.popover.popoverTitle = this.hoverTitle;
				this.popover.autoClose = 'outside';
				this.popover.open();
			}
		}, 2000) as unknown as number;
	}

	private onMouseLeave(): void {
		clearTimeout(this.hoverTimeout);
	}

	private generateHoverText(field: FormlyFieldConfig): string {
		let hoverText = '';
		let currentField: FormlyFieldConfig | undefined = field;

		while (currentField) {
			if (!isNullOrUndefined(currentField.key) && isNaN(currentField.key as number)) {
				hoverText = `${currentField.key} ${hoverText}`;
			}
			currentField = currentField.parent;
		}

		return hoverText.trim().replace(/ /g, '#');
	}

	/**
	 * ignore when element is focussed
	 * @returns
	 */
	private shouldIgnoreFocus(): boolean {
		const activeElement = document.activeElement;
		if (activeElement && this.ignoreFocusElements.includes(activeElement.tagName.toLowerCase())) {
			return true;
		}
		return false;
	}
}

interface ConfigValues {
	templateOptions: Object;
	hide: string;
	hideExpression: Object;
}
