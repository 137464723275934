import { RelatedCompanyFinancialsDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { getFinancialYearEndDate, getFinancialYearStartDate } from '../helper/util';

export class RelatedCompanyFinancialsTransformer {
	static fromPayload(companyFinancial: RelatedCompanyFinancialsDTO, financialYear: number): RelatedCompanyFinancials {
		return {
			financialYear: companyFinancial.financialYearId ?? financialYear,
			depreciation: companyFinancial.depreciationExpense,
			endDate: companyFinancial.endDate,
			interest: companyFinancial.interestExpense,
			lease: companyFinancial.leaseExpenses,
			netProfit: companyFinancial.netProfitBeforeTax,
			nonCashBenefits: companyFinancial.nonCashBenefits,
			nonRecurringExpenses: companyFinancial.nonRecurringExpenses,
			depreciationInstantAssetWriteOff: companyFinancial.depreciationInstantAssetWriteOff,
			interestExpenseNonOngoingLiability: companyFinancial.interestExpenseNonOngoingLiability,
			startDate: companyFinancial.startDate,
			isNetProfitUsedInServicing: companyFinancial.isNetProfitUsedInServicing,
			otherExpenses: companyFinancial.otherExpenses,
			paymentsToRelatedParties: companyFinancial.paymentsToRelatedParties,
			paymentsToDirectors: companyFinancial.paymentsToDirectors,
			totalExpenses: companyFinancial.totalExpenses,
			grossProfit: companyFinancial.grossProfit
		};
	}
	static toPayload(companyFinancial: RelatedCompanyFinancials): RelatedCompanyFinancialsDTO {
		return {
			depreciationExpense: companyFinancial.depreciation,
			endDate: companyFinancial.endDate,
			startDate: companyFinancial.startDate,
			interestExpense: companyFinancial.interest,
			leaseExpenses: companyFinancial.lease,
			netProfitBeforeTax: companyFinancial.netProfit,
			nonCashBenefits: companyFinancial.nonCashBenefits,
			nonRecurringExpenses: companyFinancial.nonRecurringExpenses,
			depreciationInstantAssetWriteOff: companyFinancial.depreciationInstantAssetWriteOff,
			interestExpenseNonOngoingLiability: companyFinancial.interestExpenseNonOngoingLiability,
			financialYearId: companyFinancial.financialYear,
			isNetProfitUsedInServicing: companyFinancial.isNetProfitUsedInServicing,
			otherExpenses: companyFinancial.otherExpenses,
			paymentsToRelatedParties: companyFinancial.paymentsToRelatedParties,
			paymentsToDirectors: companyFinancial.paymentsToDirectors,
			totalExpenses: companyFinancial.totalExpenses,
			grossProfit: companyFinancial.grossProfit
		};
	}
	static getDefaultFinancialYears(financialYearEnum: EnumObject[]): RelatedCompanyFinancials[] {
		const defaultObject = [] as RelatedCompanyFinancials[];
		for (let i = 0; i < financialYearEnum.length; i++) {
			const financialYear = financialYearEnum?.length ? financialYearEnum[i].id : 0;
			defaultObject.push({
				financialYear: financialYear,
				startDate: getFinancialYearStartDate(financialYear),
				endDate: getFinancialYearEndDate(financialYear)
			} as RelatedCompanyFinancials);
		}
		return defaultObject;
	}
}

export interface RelatedCompanyFinancials {
	financialYear?: number;
	startDate?: string;
	endDate?: string;
	netProfit?: number;
	depreciation?: number;
	interest?: number;
	lease?: number;
	nonCashBenefits?: number;
	nonRecurringExpenses?: number;
	depreciationInstantAssetWriteOff?: number;
	interestExpenseNonOngoingLiability?: number;
	isNetProfitUsedInServicing?: boolean;
	otherExpenses?: number;
	paymentsToRelatedParties?: number;
	paymentsToDirectors?: number;
	totalExpenses?: number;
	grossProfit?: number;
}
export interface RelatedCompanyFinancialModal {
	modal: { financialYear: RelatedCompanyFinancials[] };
	extract: string;
}
