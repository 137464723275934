/* eslint-disable brace-style */
import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { RefiAnalyticsService } from '@app/modules/refi-journey/services/refi-analytics.service';
import { parseArea } from '@app/modules/shared/helper/util';
import { NavigationService } from '@app/modules/shared/service/navigation.service';
import { RemoteValidationService } from '@app/modules/shared/service/remote-validation.service';
import { FormValidationsQuery } from '@app/modules/shared/store/form-validations/form-validations.query';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { FormAreaPath } from '@app/modules/shared/typings/form-areas.types';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { SimpSpinnerService } from '@simpology/client-components';
import { cloneDeep } from 'lodash-es';
import isEqual from 'lodash-es/isEqual';
import { Observable, Subject, Subscription, isObservable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { SimpFormlyHandlerService } from '../../services/simp-formly-handler.service';

@Component({
	selector: 'formly-mobile-step',
	templateUrl: './formly-mobile-step.component.html',
	styleUrls: ['./formly-mobile-step.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMobileStepComponent
	extends FieldArrayType
	implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked
{
	@ViewChild('subSection') subSection?: ElementRef;
	loading$ = of(true);
	remoteErrors$?: Observable<string[] | undefined>;
	label$ = of('');
	validated = false;
	subSectionId = '';

	private hasRemoteError = false;
	private valueChangeSubject: Subject<any> = new Subject();
	private destroy$: Subject<void> = new Subject();
	private subscriptions: Subscription[] = [];
	private storeData: unknown[] = [];

	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private cdr: ChangeDetectorRef,
		private simpSpinnerService: SimpSpinnerService,
		private formValidationsService: FormValidationsService,
		private formValidationsQuery: FormValidationsQuery,
		private router: Router,
		private navigationService: NavigationService,
		private remoteValidationService: RemoteValidationService,
		private refiAnalyticsService: RefiAnalyticsService,
		private elementRef: ElementRef
	) {
		super();
	}

	ngOnInit(): void {
		this.to.remove = this.remove.bind(this);
		this.field.resetOnHide = false;
		const key = this.getKey();

		if (typeof this.props.label === 'string') {
			this.label$ = of(this.props.label);
		} else if (isObservable(this.props.label)) {
			this.label$ = this.props.label;
		}

		/**
		 * Watch for changes in each control inside sub section
		 */

		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;
		this.to.validate = this.validate.bind(this);
		this.loading$ = this.simpFormlyHandlerService.mapToLoading(key) || of(false);
		this.simpSpinnerService.loading$ = of(true);
		const data$ = this.simpFormlyHandlerService.mapToStateData(key);
		if (data$) {
			// update form control when store gets updated
			data$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
				this.storeData = cloneDeep(data) as [];
				if (this.formControl?.controls.length !== data.length) {
					const noOfDifItems = data.length - this.formControl?.controls.length ?? 0;
					if (noOfDifItems > 0) {
						for (let index = 0; index < noOfDifItems; index++) {
							this.add();
						}
					} else {
						for (let index = 0; index < -noOfDifItems; index++) {
							if (this.remove instanceof Function) {
								this.remove(0);
							}
						}
					}
				}
				if (this.model && this.field.options?.checkExpressions) {
					Object.assign(this.model, data);
					// check expression to repopulate the controls hidden from DOM because of hideExpression.
					this.field.options.checkExpressions(this.field);
					this.cdr.markForCheck();
				}
				this.formControl.patchValue(data);
				setTimeout(() => {
					if (this.hasRemoteError && this.formControl.dirty) {
						const formAreaPath = parseArea(this.router.url.replace('/', '')) as FormAreaPath;
						this.remoteValidationService.fetchRemoteValidations(formAreaPath);
					}
					this.formControl.markAsUntouched();
					this.formControl.markAsPristine();
					this.cdr.markForCheck();
				});
			});
		} else {
			console.warn(`Store not found for sub-section - ${key}`);
		}
		this.formValidationsService.triggerValidations$.pipe(takeUntil(this.destroy$), startWith()).subscribe(() => {
			this.validate();
		});
		this.subSectionId = this.simpFormlyHandlerService.getKey(key);
		this.remoteErrors$ = this.formValidationsQuery.mapToSubSectionRemoteError(this.subSectionId).pipe(
			tap((remoteErrors) => {
				if (remoteErrors && !this.to.expanded) {
					this.to.expanded = true;
				}
				this.hasRemoteError = !!remoteErrors && remoteErrors.length > 0;
			})
		);

		this.navigationService.scrolledToSubSection$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (this.subSectionId === id) {
				this.scrollIntoView();
			}
		});
		this.navigationService.mobileBackButtonClicked$
			.pipe(
				map((currentRoute) => {
					this.navigationService.mobileBackButtonClickedPrehookSubject.next(
						this.router.url.replace('/' + currentRoute + '/', '')
					);
					return currentRoute;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((path) => {
				const prevRoute = this.to.prevRoute as string;
				if (prevRoute) {
					void this.router.navigateByUrl(`${path}/${prevRoute}`);
				}
			});
	}

	ngAfterViewInit(): void {
		this.formControl.valueChanges
			.pipe(
				startWith(this.formControl.value),
				takeUntil(this.destroy$),
				distinctUntilChanged((a: unknown[], b: unknown[]) => a?.length === b?.length)
			)
			.subscribe(() => {
				this.subscriptions.forEach((s) => {
					s.unsubscribe();
				});
				this.subscriptions = this.setupChangeDetection();
			});
		if (this.navigationService.navigatedTo(this.subSectionId)) {
			this.scrollIntoView();
		}
	}

	ngAfterViewChecked(): void {
		const el = this.elementRef.nativeElement as HTMLElement;
		el.querySelectorAll('.sub-text a')?.forEach((e) =>
			e.addEventListener('click', (event) => {
				this.subTextCallBack(event, this.field);
			})
		);
	}

	primaryCallBack(field: FormlyFieldConfig) {
		if (field.fieldGroup) {
			this.formValidationsService.triggerFieldsValidation(field.fieldGroup);
		}

		this.formControl.markAllAsTouched();
		if (this.formControl.invalid) {
			setTimeout(() => {
				this.scrollToFirstError();
			}, 100);
		}
		if (this.to.click) {
			if (this.formControl.valid) {
				this.formControl.markAsUntouched();
			}
			this.to.click(field, {
				type: 'primary',
				invalid: this.formControl.invalid,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				changed: this.formValidationsQuery.dataChanged(this.model[0] as Object, this.storeData[0] as Object)
			});

			if (this.formControl.valid) {
				this.refiAnalyticsService.trackEvent('primaryButtonClick', this.router.url);
			}
		}
	}

	secondaryCallBack(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'secondary' });
			this.refiAnalyticsService.trackEvent('secondaryButtonClick', this.router.url);
		}
	}

	subTextCallBack(event: Event, field: FormlyFieldConfig) {
		event.preventDefault();
		event.stopImmediatePropagation();
		if (this.to.click) {
			let value;
			if (event.target instanceof HTMLAnchorElement) {
				value = event.target.pathname.replace('/', '');
			}
			this.to.click(field, { type: 'subText', value: value });
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleButtonClick() {
		if (this.to.click) {
			this.to.click(this.field);
		} else {
			this.add();
		}
	}

	private scrollToFirstError(): void {
		const element = document.getElementsByClassName('is-invalid');
		if (element[0]) {
			this.scrollIntoView(element[0]);
			if (element[0].tagName === 'SIMP-ADDRESS-INPUT') {
				const inputElement = element[0].getElementsByTagName('input');
				inputElement[0].focus();
			} else {
				(element[0] as HTMLElement).focus();
			}
		}
	}

	private scrollIntoView(element?: Element): void {
		setTimeout(() => {
			if (this.subSection?.nativeElement) {
				(this.subSection.nativeElement as Element).scrollIntoView({ behavior: 'smooth', inline: 'center' });
			}

			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			this.to.expanded = true;
			this.cdr.detectChanges();
		});
	}

	private validate(): void {
		if (this.field.validation) {
			this.field.validation.show = false;
			this.field.validation.show = true;
		}

		this.formControl.markAllAsTouched();
		this.simpFormlyHandlerService.updateDraftFlag(this.getKey(), this.formControl.invalid);
		this.to.expanded = this.formControl.invalid;
		this.validated = true;
	}

	private setupChangeDetection() {
		return this.formControl.controls.map((control, index) => {
			return control.valueChanges
				.pipe(
					debounceTime(this.to.debounceTime === undefined ? 1000 : this.to.debounceTime),
					distinctUntilChanged((a: unknown, b: unknown) => isEqual(a, b)),
					takeUntil(this.destroy$),
					filter(() => control.valid && control.dirty && this.formControl.valid)
				)
				.subscribe((data: unknown) => {
					this.valueChangeSubject.next({ data, index });
					this.cdr.markForCheck();
				});
		});
	}

	private getKey(): string {
		let key = this.field.key as string;
		if (key !== 'household' && key !== 'relationship' && this.router.url === '/applicants') {
			const parentKey = this.field.parent?.key as string;
			const index = parentKey.substring(parentKey.lastIndexOf('-') + 1);
			key += `-${index}`;
		}
		return key;
	}
}
