import { RelatedCompanyModel } from '@app/modules/applicants/models/related-company.model';
import { RelatedPersonModel } from '@app/modules/applicants/models/related-person.model';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { DocumentInstructionDTO } from '../typings/setup';

export interface DocumentInstruction {
	id?: number;
	methodId?: number;
	sendDocumentsToId?: number;
	existingSelect?: ApplicantEnumObject;
	relatedPerson?: RelatedPersonModel;
	relatedCompany?: RelatedCompanyModel;
}

export class DocumentInstructionsModelTransformer {
	static fromPayload(
		documentInstructionDTO: DocumentInstructionDTO,
		formEnumsQuery: FormEnumsQuery
	): DocumentInstruction {
		const documentInstruction = {
			id: documentInstructionDTO.id,
			methodId: documentInstructionDTO.methodId,
			sendDocumentsToId: documentInstructionDTO.sendDocumentsToId
		} as DocumentInstruction;

		const applicantEnumObject = {
			id: documentInstructionDTO.nominatedAuthorityId,
			type: documentInstructionDTO.nominatedAuthorityType
		} as ApplicantEnumObject;

		let existingParty = formEnumsQuery.getExistingPartiesById(applicantEnumObject);
		if (!existingParty) {
			existingParty = applicantEnumObject;
		}
		documentInstruction.existingSelect = existingParty;

		return documentInstruction;
	}

	static toPayload(documentInstruction: DocumentInstruction): DocumentInstructionDTO {
		return {
			id: documentInstruction.id ?? CONSTANTS.NEW_ID,
			methodId: documentInstruction.methodId,
			sendDocumentsToId: documentInstruction.sendDocumentsToId,
			nominatedAuthorityId: documentInstruction.existingSelect?.id,
			nominatedAuthorityType: documentInstruction.existingSelect?.type
		} as DocumentInstructionDTO;
	}
}
