import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Sample Input
 * {
		className: 'col-2',
		type: 'input',
		key: 'firstName',
		validation: {
			messages: { required: 'We need the lender application number' }
		},
		templateOptions: {
			label: 'First Name',
			required: true,
			disabled: true
		}
	},
 */
@Component({
	selector: 'formly-textarea',
	templateUrl: './formly-textarea.component.html',
	styleUrls: ['./formly-textarea.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTextAreaComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
	}
}
