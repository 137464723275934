import { KinRelationship } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { PersonApplicantDTO } from '@app/modules/typings/api';
import { RelatedPersonAndContacts } from '../typings/applicants';
import { RelatedPersonModel } from './related-person.model';

export class NextOfKinTransformer {
	static fromPayload(
		applicant: PersonApplicantDTO,
		formEnumsQuery: FormEnumsQuery,
		relatedPersonAndContacts: RelatedPersonAndContacts
	): PersonNextOfKinModel[] {
		const existing = NextOfKinTransformer.getExistingParty(applicant, formEnumsQuery);
		const person = relatedPersonAndContacts?.relatedPersons?.find(
			(relatedPerson: RelatedPersonModel) => relatedPerson && relatedPerson.id === existing?.id
		);

		return existing
			? [
					{
						existingNextOfKin: existing,
						nextOfKinRelationship: applicant.nextOfKinRelationshipId,
						relatedPerson: person
					}
			  ]
			: [];
	}

	static toPayload(nextOfKindModel: PersonNextOfKinModel): Partial<PersonApplicantDTO> {
		return {
			nextOfKinPersonId: nextOfKindModel.existingNextOfKin?.id,
			nextOfKinPersonTypeId: nextOfKindModel.existingNextOfKin?.type,
			nextOfKinRelationshipId: nextOfKindModel.existingNextOfKin ? nextOfKindModel.nextOfKinRelationship : undefined
		};
	}

	static getExistingParty(applicant: PersonApplicantDTO, formEnumsQuery: FormEnumsQuery) {
		const existingParty = formEnumsQuery.getExistingPartiesById({
			id: applicant.nextOfKinPersonId!,
			type: Number(applicant.nextOfKinPersonTypeId!)
		});
		return existingParty ?? null;
	}
}

export interface PersonNextOfKinModel {
	existingNextOfKin: ApplicantEnumObject;
	nextOfKinRelationship?: KinRelationship;
	relatedPerson?: RelatedPersonModel;
}
