import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-summary-wrapper',
	templateUrl: './formly-summary-wrapper.component.html',
	styleUrls: ['./formly-summary-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySummaryWrapperComponent extends FieldWrapper implements OnInit {
	constructor(private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.to.label = this.to.label?.replace('(optional)', '');
	}

	navigateTo(route: string): void {
		void this.router.navigateByUrl(route);
	}

	trackByFn(index: number, item: FormlyFieldConfig): string | undefined {
		return item.id; // Assuming `id` is the unique identifier property
	}
}
