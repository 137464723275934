import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';
import { CONSTANTS } from '../constants/constants';
import { ApplicantEntityType } from '../enums/app.enums';
import { formAreaPathToArea, parseArea } from '../helper/util';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { SideNavItem } from '../store/application-data/typings/application-data';
import { EventAction, EventTarget, EventsRepository } from '../store/events/events.repository';
import { FormAreaPath, JourneyArea } from '../typings/form-areas.types';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class AreaValidationService extends BaseJourneyService<AreaValidation[]> {
	constructor(private applicationDataQuery: ApplicationDataQuery, private eventsRepository: EventsRepository) {
		super();
		this.setLadmRoute(URL_CONSTANTS.AREA_VALIDATION);
	}

	fetchAreaValidation() {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return of([]);
		}
		return this.getCustom(`${applicationId}`) as Observable<AreaValidation[]>;
	}

	setAreaValidation(item: SideNavItem) {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return;
		}

		if (item.formValid) {
			const formAreaPath = parseArea(item.url) as FormAreaPath;
			const targetName = formAreaPathToArea(formAreaPath) as string;
			this.eventsRepository.dispatchEvent({
				targetName,
				targetType: EventTarget.Area,
				action: EventAction.Completed,
				applicantId: item.applicantId
			});
		}

		if (item.applicantId !== undefined && item.applicantId > 0) {
			const payload: AreaValidation = {
				applicantId: item.applicantId,
				applicationId,
				applicantEntityType:
					item.applicantEntityType ??
					this.applicationDataQuery.getRefiApplicants().find((applicant) => applicant.isCurrentUser)?.applicantType ??
					ApplicantEntityType.PersonApplicant,
				area: item.area,
				valid: item.formValid
			};
			this.postCustom(``, payload).subscribe();
		}
	}
}

export interface AreaValidation {
	applicantId?: number;
	applicationId: number;
	applicantEntityType: ApplicantEntityType;
	area: JourneyArea;
	valid?: boolean;
}
