import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyNestedSection = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	return {
		type: 'object',
		title: property.title,
		required: property.required,
		widget: {
			formlyConfig: {
				type: 'object',
				wrappers: ['nested-section'],
				className: `${property.templateOptions?.class ?? property.class}`,
				templateOptions: {
					collapsible: property.templateOptions?.collapsible ?? true,
					...property.templateOptions
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property)
			}
		},
		properties: childProperties
	};
};
