import { Injectable } from '@angular/core';
import { ApplicationDetails } from '@app/modules/setup/typings/setup';
import { SpinnerType } from '@app/modules/shared/enums/app.enums';
import { FormStore } from '@app/modules/shared/store/form-data/model/form-data.model';
import { FormStateService } from '@app/modules/shared/store/form-state/form-state.service';
import { FormStateStore } from '@app/modules/shared/store/form-state/form-state.store';
import {
	FormStateLoanDetails,
	LoadSpinner,
	LoanJourneyProduct
} from '@app/modules/shared/store/form-state/typings/form-state';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { select } from '@ngneat/elf';
import { isEqual, merge } from 'lodash-es';
import { Observable, distinctUntilChanged, filter, map, skip } from 'rxjs';
import { BorrowingInfo } from '../model/borrowing.model';
import { FeeListSectionModel } from '../model/fee-detail.model';
import { LoanInformation } from '../model/loan-information.model';
import {
	BorrowingFilterParams,
	LoanAmounts,
	PaidOnOrBeforeFee,
	ProductFilterParams
} from '../model/product-filter-parameters.model';
import { Product } from '../model/product.model';

@Injectable({ providedIn: 'root' })
export class LoanDetailsFormDataService {
	constructor(
		private formStateService: FormStateService,
		private formStateStore: FormStateStore,
		private simpFormlyHandlerService: SimpFormlyHandlerService
	) {}

	selectBorrowingFilterParamsForLoan$(borrowingKey: string): Observable<BorrowingFilterParams> {
		const borrowingStore = this.simpFormlyHandlerService.getState<BorrowingInfo>(borrowingKey);

		return borrowingStore.pipe(
			select((state: FormStore<BorrowingInfo>) => state.data),
			filter((data: BorrowingInfo[]) => data && data.length > 0),
			map((borrowings: BorrowingInfo[]) => this.mapBorrowingFilterParams(borrowings?.[0])),
			distinctUntilChanged((prev: BorrowingFilterParams, curr: BorrowingFilterParams) => isEqual(prev, curr)),
			skip(1)
		);
	}

	selectProductFilterParamsForLoan$(productKey: string): Observable<ProductFilterParams> {
		const productStore = this.simpFormlyHandlerService.getState<Product>(productKey);

		return productStore.pipe(
			select((state: FormStore<Product>) => state.data),
			filter((data: Product[]) => data && data.length > 0),
			map((products: Product[]) => this.mapProductFilterParamsForLoan(products?.[0])),
			distinctUntilChanged((prev: ProductFilterParams, curr: ProductFilterParams) => isEqual(prev, curr)),
			skip(1)
		);
	}

	selectTotalBorrowingAmount$(): Observable<number> {
		const store = this.simpFormlyHandlerService.getState<LoanInformation>('loanInformation');
		return store.pipe(
			select((state: FormStore<LoanInformation>) => state.data?.[0]?.amountRequested ?? -1),
			filter((amount) => amount > -1)
		);
	}

	selectTotalAndSplitAmounts$(): Observable<LoanAmounts> {
		const store = this.simpFormlyHandlerService.getState<LoanInformation>('loanInformation');
		return store.pipe(
			select((state: FormStore<LoanInformation>) => this.mapLoanAmounts(state.data?.[0])),
			distinctUntilChanged((prev: LoanAmounts, curr: LoanAmounts) => isEqual(prev, curr))
		);
	}

	selectPaidOnOrBeforeFees$(): Observable<PaidOnOrBeforeFee[]> {
		const store = this.simpFormlyHandlerService.getState<FeeListSectionModel>('paidOnOrBefore');
		return store.pipe(
			select((state: FormStore<FeeListSectionModel>) => this.mapPaidOnOrBeforeFees(state.data)),
			distinctUntilChanged((prev: PaidOnOrBeforeFee[], curr: PaidOnOrBeforeFee[]) => isEqual(prev, curr))
		);
	}

	selectSplitLoans$(): Observable<number[]> {
		const store = this.simpFormlyHandlerService.getState<LoanInformation>('loanInformation');
		return store.pipe(
			select((state: FormStore<LoanInformation>) => this.mapSplitLoans(state.data?.[0])),
			distinctUntilChanged((prev: number[], curr: number[]) => isEqual(prev, curr))
		);
	}

	selectJourneyProduct$(productSubSectionKey: string): Observable<number> {
		const productStore = this.simpFormlyHandlerService.getState<Product>(productSubSectionKey);
		return productStore.pipe(
			select((state: FormStore<Product>) => state.data?.[0]?.preferredProductVariationId ?? -1),
			distinctUntilChanged()
		);
	}

	updateFormStateForLoanDetails(formState: FormStateLoanDetails): void {
		this.formStateService.updateFormState({
			loanDetails: merge(this.formStateStore.store.getValue()?.loanDetails ?? {}, formState)
		});
	}

	updateFormStateForSelectedLoanProduct(selectedProduct: LoanJourneyProduct): void {
		const loanDetails = this.formStateStore.store.getValue()?.loanDetails ?? {};
		const loanProducts = loanDetails.loanJourneyProducts ?? [];

		const product = loanProducts?.find((loanProduct) => loanProduct.loanId === selectedProduct.loanId);

		if (product) {
			product.productVariationId = selectedProduct.productVariationId;
		} else {
			loanProducts.push(selectedProduct);
		}

		loanDetails.loanJourneyProducts = loanProducts;

		this.formStateService.updateFormState({ loanDetails });
	}

	updateFormStateForPrimaryPurpose(borrowing: BorrowingInfo): void {
		const applicationDetails = this.formStateStore.store.getValue()?.applicationDetails ?? ({} as ApplicationDetails);
		const loanDetails = this.formStateStore.store.getValue()?.loanDetails ?? {};
		const primaryLendingPurposes = loanDetails.primaryLendingPurposes ?? [];

		const primaryLendingPurpose = primaryLendingPurposes?.find((purpose) => purpose.loanId === borrowing.loanId);

		if (primaryLendingPurpose) {
			primaryLendingPurpose.primaryLendingPurpose = borrowing?.lendingPurpose?.[0]?.primaryLendingPurpose;
		} else {
			primaryLendingPurposes.push({
				loanId: borrowing.loanId!,
				primaryLendingPurpose: borrowing.lendingPurpose?.[0]?.primaryLendingPurpose
			});
		}

		loanDetails.primaryLendingPurposes = primaryLendingPurposes;

		if (primaryLendingPurposes?.[0]?.primaryLendingPurpose) {
			const mainPrimaryLendingPurpose = primaryLendingPurposes[0].primaryLendingPurpose;
			if (applicationDetails.primaryLendingPurpose !== mainPrimaryLendingPurpose) {
				applicationDetails.primaryLendingPurpose = mainPrimaryLendingPurpose;

				this.formStateService.updateFormState({ applicationDetails, loanDetails });
				return;
			}
		}

		this.formStateService.updateFormState({ loanDetails });
	}

	updateLoadersInLoanDetails(loader: LoadSpinner) {
		switch (loader.type) {
			case SpinnerType.FilterProduct:
				this.formStateService.updateFormState({ loanProductLoader: loader });
				break;
			case SpinnerType.LoadingFeatures:
				this.formStateService.updateFormState({ loanProductFeaturesLoader: loader });
				break;
		}
	}

	private mapBorrowingFilterParams(borrowing: BorrowingInfo): BorrowingFilterParams {
		const filterParams: BorrowingFilterParams = {};
		if (borrowing) {
			filterParams.primaryLoanPurpose = borrowing.primaryPurpose ?? -1;
			filterParams.primaryLendingPurpose = (borrowing.lendingPurpose ?? []).map(
				(purpose) => purpose.primaryLendingPurpose ?? -1
			);
		}
		return filterParams;
	}

	private mapProductFilterParamsForLoan(product: Product): ProductFilterParams {
		const filterParams: ProductFilterParams = {};
		if (product) {
			filterParams.interestType = product.interestType ?? -1;
			filterParams.fixedInterestPeriod = product.fixedRatePeriod ?? -1;
			filterParams.paymentType = product.paymentType ?? -1;
			filterParams.interestOnlyPeriod = product.interestOnlyPeriod ?? -1;
			filterParams.loanPeriod = product.loanPeriod ?? -1;
		}
		return filterParams;
	}

	private mapSplitLoans(loanInfo: LoanInformation): number[] {
		if (!loanInfo || !loanInfo.loanSplits || loanInfo.loanSplits.length === 0) {
			return [];
		}
		return loanInfo.loanSplits.map((split) => split.loanDetailId ?? -1);
	}

	private mapLoanAmounts(loanInfo: LoanInformation): LoanAmounts {
		const loanAmounts: LoanAmounts = {
			totalBorrowingAmount: loanInfo.amountRequested ?? 0,
			splitAmounts: []
		};
		if (loanInfo?.loanSplits?.length) {
			loanAmounts.splitAmounts = loanInfo.loanSplits.map((split) => split.loanSplit?.splitAmount ?? 0);
		}
		return loanAmounts;
	}

	private mapPaidOnOrBeforeFees(fees: FeeListSectionModel[]): PaidOnOrBeforeFee[] {
		return fees.map((fee) => {
			return {
				feeAmount: fee.details?.feePopup?.feeAmount ?? 0,
				isCapitalise: fee.details?.feePopup?.isCapitalise ?? false
			} as PaidOnOrBeforeFee;
		});
	}
}
