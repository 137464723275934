import { TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { CompanyShareholderDTO } from '@app/modules/typings/api';

export class CompanyShareholderTransformer {
	static fromPayload(shareholder: CompanyShareholderDTO, formEnumsQuery: FormEnumsQuery): CompanyShareholderModel {
		return {
			id: shareholder.id,
			existingSelect: formEnumsQuery.getExistingPartiesById({ type: shareholder.type, id: shareholder.partyId }),
			shareholding: shareholder.percentOwned,
			fullName: shareholder.partyName
		};
	}

	static toPayload(applicantId: number, shareholder: CompanyShareholderModel): Partial<CompanyShareholderDTO> {
		return {
			id: shareholder.id || 0,
			companyApplicantId: applicantId,
			applicationId: shareholder.applicationId,
			type: shareholder.type || shareholder.existingSelect.type,
			partyId: shareholder.partyId || shareholder.existingSelect.id,
			percentOwned: shareholder.shareholding
		};
	}
}

export interface CompanyShareholderModel {
	id?: number;
	type?: TargetType;
	applicationId?: number;
	existingSelect: ApplicantEnumObject;
	partyId?: number;
	fullName?: string;
	shareholding: number;
}
