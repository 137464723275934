import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { GovernmentBenefitsType, OtherIncomeType, Proportions } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwned, PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { OtherIncomeDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class OtherIncomeTransformer {
	static toPayload(applicationId: number, otherIncomeModel: OtherIncomeModel): OtherIncomeDTO {
		const otherIncomeDetails = otherIncomeModel.details.otherIncomeDetails;
		return {
			applicationId: applicationId,
			id: otherIncomeModel.id ?? CONSTANTS.NEW_ID,
			type: otherIncomeDetails.typeOfIncome,
			amount: otherIncomeDetails.value.amount,
			frequency: otherIncomeDetails.value.frequency,
			isTaxable: otherIncomeDetails.isTaxable,
			percentOwnedProportions: otherIncomeDetails.isEqualShare ? Proportions.Equal : Proportions.Specified,
			percentsOwned: otherIncomeDetails.percentsOwned,
			governmentBenefitsType: otherIncomeDetails.governmentBenefitsType,
			nonRealEstateAssetId: OtherIncomeTransformer.getNonRealStateAssetId(otherIncomeDetails),
			description: otherIncomeDetails.description
		};
	}

	static getNonRealStateAssetId(otherIncomeDetails: OtherIncomeDetails): number | undefined {
		if (otherIncomeDetails.typeOfIncome === OtherIncomeType.Dividends) {
			return otherIncomeDetails.dividendLinkedAsset;
		} else if (otherIncomeDetails.typeOfIncome === OtherIncomeType.InterestIncome) {
			return otherIncomeDetails.interestIncomeLinkedAsset;
		} else {
			return undefined;
		}
	}

	static fromPayload(otherIncome: OtherIncomeDTO, applicants: ShortApplicant[]): OtherIncomeModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			otherIncome.percentsOwned,
			applicants
		);

		const grossAmount = calculateMonthly(otherIncome.amount, otherIncome.frequency);

		const otherIncomeModel: OtherIncomeModel = {
			id: otherIncome.id,
			summaryAmount: grossAmount,
			details: {
				otherIncomeDetails: {
					value: {
						amount: otherIncome.amount,
						frequency: otherIncome.frequency
					},
					isEqualShare: otherIncome.percentOwnedProportions === Proportions.Equal,
					percentsOwned: percentageOwned,
					typeOfIncome: otherIncome.type,
					isTaxable: otherIncome.isTaxable,
					governmentBenefitsType: otherIncome.governmentBenefitsType,
					dividendLinkedAsset:
						OtherIncomeType.Dividends === otherIncome.type ? otherIncome.nonRealEstateAssetId : undefined,
					interestIncomeLinkedAsset:
						OtherIncomeType.InterestIncome === otherIncome.type ? otherIncome.nonRealEstateAssetId : undefined,
					description: otherIncome.description
				},
				extract: `OtherIncomeType.${otherIncome.type}`
			},
			amountEntry: { amount: otherIncome.amount, frequency: otherIncome.frequency },
			grossAmount: grossAmount,
			netAmount: 0, // When net income is null it should 0 as per the requirement
			frequency: FrequencyShort.Monthly // Frequency should be always monthly as per the requirement
		};
		return otherIncomeModel;
	}
}

export interface OtherIncomeModel {
	id?: number;
	details: { otherIncomeDetails: OtherIncomeDetails; extract: string };
	grossAmount?: number;
	netAmount?: number;
	frequency?: FrequencyShort;
	// Used in Company & Trust Financial Position
	amountEntry: AmountSelect;
	summaryAmount?: number;
}

interface OtherIncomeDetails {
	isEqualShare: boolean;
	percentsOwned?: PercentageOwned[];
	value: AmountSelect;
	typeOfIncome: OtherIncomeType;
	isTaxable: boolean;
	governmentBenefitsType?: GovernmentBenefitsType;
	description?: string;
	interestIncomeLinkedAsset?: number;
	dividendLinkedAsset?: number;
}
