import { Injectable } from '@angular/core';
import { EnumObject } from '@simpology/client-components/utils';

import { URL_CONSTANTS } from '../../constants/api.constants';
import { BaseJourneyService } from '../../service/base-journey.service';

@Injectable({ providedIn: 'root' })
export class FormEnumsLadmService extends BaseJourneyService<EnumObject[]> {
	constructor() {
		super();
		this.setLadmRoute(`${URL_CONSTANTS.OPTIONS_LIST}/`);
	}
}
