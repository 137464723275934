import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-radio-button',
	templateUrl: './formly-radio-button.component.html',
	styleUrls: ['./formly-radio-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRadioButtonComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
	}

	select() {
		if (this.to.readonly) {
			return;
		}
		this.formControl.setValue(true);
		this.formControl.markAsDirty();
		if (this.to?.click) {
			this.to?.click(this.field);
		}
	}
}
