import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseJourneyService } from '../shared/service/base-journey.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { EventAction, EventTarget, EventsRepository } from '../shared/store/events/events.repository';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import { ServiceabilityResult } from './model/serviceability-calculation-result.model';

@Injectable({ providedIn: 'root' })
export class ServiceabilityCalculationService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private eventsRepository: EventsRepository
	) {
		super();
		this.setJourneyLadmRoute();
	}

	checkServiceability(): void {
		this.simpFormlyHandlerService.upsertToStateWithData('serviceabilityResults', []);

		this.calculateServiceability()
			.pipe(catchError(() => of(undefined)))
			.subscribe((serviceabilityResult: ServiceabilityResult | undefined) => {
				this.simpFormlyHandlerService.upsertToStateWithData(
					'serviceabilityResults',
					serviceabilityResult ? [serviceabilityResult] : []
				);
				this.eventsRepository.dispatchEvent({ targetType: EventTarget.Serviceability, action: EventAction.Calculated });
			});
	}

	calculateServiceability(): Observable<ServiceabilityResult> {
		const applicationId = this.applicationDataQuery.applicationId();
		return <Observable<ServiceabilityResult>>(
			this.get(
				`LoanServiceability/ServiceabilityByJourneyId/${applicationId}/${this.applicationDataQuery.getApplicationJourneyId()}`
			)
		);
	}
}
