import { formatCurrency } from '@angular/common';
import {
	InterestType,
	PaymentType,
	PrimaryPurposeLoanPurpose,
	RbaLendingPurpose,
	RefiApplicationSection,
	ResidentialType
} from '@app/modules/shared/enums/app.enums';
import { calculateMonthly, calculateYearly } from '@app/modules/shared/helper/util';
import {
	ApplicationChangeLog,
	DeclaredExpenseDTO,
	EmploymentDTO,
	OtherIncomeDTO,
	PersonApplicantDTO,
	RefiCreditCardDTO,
	RefiHomeLoanDTO,
	RefiOtherAssetsDTO,
	RefiOtherLiabilityDTO,
	RefiPersonalLoanDTO,
	RefiPropertyAssetsDTO,
	RefiRentalIncomeDTO,
	RefiReviewDTO,
	RefiSavingsAssetsDTO,
	RefiSelfEmploymentDTO
} from '@app/modules/typings/api';
import { FrequencyShort, SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

export class RefiReviewTransformer {
	static fromPayload(payload: RefiReviewDTO, readyToSubmit: boolean, applicants: PersonApplicantDTO[]): ReviewModel[] {
		const reconcileRequiredCount = this.getLiabilitiesReconciliationRecords(
			payload.creditCards,
			payload.homeLoans,
			payload.personalLoans,
			payload.otherLiabilities
		);
		const currentUserId = applicants.find((app) => app.isCurrentUser)?.id;
		return [
			{
				summarySections: {
					securityProperty: [
						{
							securityPropertyItems: {
								propertyAddress: payload.securityProperty.address
									? SimpAddressHelper.buildAddressLine(payload.securityProperty.address as SimpAddress)
									: '',
								propertyPostcode: payload.securityProperty.address?.suburb
									? SimpAddressHelper.buildAddressLine(payload.securityProperty.address as SimpAddress)
									: '',
								propertyType: payload.securityProperty.propertyType,
								loanPurpose: payload.securityProperty.loanPurpose,
								foundProperty: payload.securityProperty.foundProperty,
								purchasePrice: payload.securityProperty.purchasePrice
							}
						}
					],
					loanRequirements: [
						{
							loanRequirementsItems: {
								totalLoanAmount: payload.totalLoanAmount?.toString(),
								loanPurpose: payload.loanRequirement.rbaLendingPurpose,
								applicants: payload.numberOfApplicants,
								repaymentType: payload.loanRequirement.paymentType,
								interestType: payload.loanRequirement.interestRate
							},
							change: payload.changedSections.find(
								(change) => change.section === RefiApplicationSection.LoanRequirements
							)
						}
					],
					product:
						payload.selectedProduct?.length > 0
							? [
									{
										selectedProduct: `${payload.selectedProduct[0]?.productName} - ${payload.selectedProduct[0].productNameWithFeature}`,
										change: payload.changedSections.find(
											(change) => change.section === RefiApplicationSection.ProductSelection
										)
									}
							  ]
							: [],
					income: [
						{
							totalIncome: this.calculateTotalIncome(
								payload.employmentIncomes,
								payload.selfEmploymentIncomes,
								payload.rentalIncomes,
								payload.otherIncomes
							),
							change: payload.changedSections.find((change) => change.section === RefiApplicationSection.Income)
						}
					],
					expenses: [
						{
							totalExpenses: this.calculateTotalExpenses(payload.expenses),
							change: payload.changedSections.find((change) => change.section === RefiApplicationSection.Expenses)
						}
					],
					assets: [
						{
							totalAssets: this.calculateTotalAssets(payload.propertyAssets, payload.savings, payload.otherAssets),
							change: payload.changedSections.find((change) => change.section === RefiApplicationSection.Assets)
						}
					],
					liabilities: [
						{
							totalLiabilities: this.calculateLiabilities(
								payload.creditCards,
								payload.homeLoans,
								payload.personalLoans,
								payload.otherLiabilities
							),
							reconcileRecords: reconcileRequiredCount,
							change: payload.changedSections.find((change) => change.section === RefiApplicationSection.Liabilities)
						}
					],
					readyToSubmit: readyToSubmit,
					reconcileRequired: reconcileRequiredCount !== 0
				}
			}
		];
	}

	static getLiabilitiesReconciliationRecords(
		creditCards: RefiCreditCardDTO[],
		homeLoans: RefiHomeLoanDTO[],
		personalLoans: RefiPersonalLoanDTO[],
		otherLiabilities: RefiOtherLiabilityDTO[]
	): number {
		const reconciliationRecords = [
			creditCards.find((cc) => cc.reconcileRequired),
			homeLoans.find((hl) => hl.reconcileRequired),
			personalLoans.find((pl) => pl.reconcileRequired),
			otherLiabilities.find((ol) => ol.reconcileRequired)
		];
		return reconciliationRecords.filter((rr) => rr !== undefined).length;
	}

	static calculateTotalIncome(
		employmentIncomes: EmploymentDTO[],
		selfEmploymentIncomes: RefiSelfEmploymentDTO[],
		rentalIncomes: RefiRentalIncomeDTO[],
		otherIncomes: OtherIncomeDTO[]
	): string {
		let total = 0;
		employmentIncomes.forEach((employment) => {
			if (employment.income && !employment.dateEnded) {
				total += employment.income.reduce((x, y) => x + calculateYearly(y.grossAmount, y.frequency), 0);
			}
		});
		total += selfEmploymentIncomes.reduce((x, y) => x + calculateYearly(y.grossIncome, FrequencyShort.Yearly), 0);
		total += rentalIncomes.reduce((x, y) => x + calculateYearly(y.grossRentalAmount, y.grossRentalFrequency), 0);
		total += otherIncomes.reduce((x, y) => x + calculateYearly(y.amount, y.frequency), 0);
		return `<span class="simp-text--bold">${formatCurrency(total, 'en-AU', '$')}</span> total per year`;
	}

	static calculateTotalExpenses(expenses: DeclaredExpenseDTO[]): string {
		const total = expenses.reduce((x, y) => x + calculateMonthly(y.amount, y.frequency), 0);
		return `<span class="simp-text--bold">${formatCurrency(total, 'en-AU', '$')}</span> total per month`;
	}

	static calculateTotalAssets(
		propertyAssets: RefiPropertyAssetsDTO[],
		savings: RefiSavingsAssetsDTO[],
		otherAssets: RefiOtherAssetsDTO[]
	): string {
		let total = 0;
		total += propertyAssets.reduce((x, y) => x + (y.estimatedValue ? y.estimatedValue : 0), 0);
		total += savings.reduce((x, y) => x + (y.value ? y.value : 0), 0);
		total += otherAssets.reduce((x, y) => x + (y.value ? y.value : 0), 0);
		return `<span class="simp-text--bold">${formatCurrency(total, 'en-AU', '$')}</span> total assets`;
	}

	static calculateLiabilities(
		creditCards: RefiCreditCardDTO[],
		homeLoans: RefiHomeLoanDTO[],
		personalLoans: RefiPersonalLoanDTO[],
		otherLiabilities: RefiOtherLiabilityDTO[]
	): string {
		let total = 0;
		total += creditCards.reduce((x, y) => x + (y.creditLimit ? y.creditLimit : 0), 0);
		total += homeLoans.reduce((x, y) => x + (y.outstandingBalance ? y.outstandingBalance : 0), 0);
		total += personalLoans.reduce((x, y) => x + (y.currentLoanBalance ? y.currentLoanBalance : 0), 0);
		total += otherLiabilities.reduce((x, y) => x + (y.currentLoanBalance ? y.currentLoanBalance : 0), 0);
		return `<span class="simp-text--bold">${formatCurrency(total, 'en-AU', '$')}</span> total liabilities`;
	}
}

export interface ReviewModel {
	summarySections: ReviewSummarySections;
}

interface ReviewSummarySections {
	securityProperty?: SecurityPropertySummary[];
	loanRequirements?: LoanRequirementsSummary[];
	product?: ProductItems[];
	income?: IncomeItems[];
	expenses?: ExpenseItems[];
	assets?: AssetItems[];
	liabilities?: LiabilitiesItems[];
	readyToSubmit: boolean;
	reconcileRequired: boolean;
}

interface SecurityPropertySummary {
	securityPropertyItems: SecurityPropertyItems;
}
interface SecurityPropertyItems {
	propertyAddress?: string;
	propertyPostcode?: string;
	loanPurpose?: PrimaryPurposeLoanPurpose;
	propertyType?: ResidentialType | string;
	purchasePrice?: number;
	foundProperty?: boolean;
}

interface LoanRequirementsSummary {
	loanRequirementsItems: LoanRequirementsItems;
	change?: ApplicationChangeLog;
}

interface LoanRequirementsItems {
	totalLoanAmount: string;
	loanPurpose?: RbaLendingPurpose;
	applicants: number;
	repaymentType?: PaymentType;
	interestType?: InterestType | null;
}
interface ProductItems {
	selectedProduct: string;
	change?: ApplicationChangeLog;
}

interface IncomeItems {
	totalIncome: string;
	change?: ApplicationChangeLog;
}

interface ExpenseItems {
	totalExpenses: string;
	change?: ApplicationChangeLog;
}

interface AssetItems {
	totalAssets: string;
	change?: ApplicationChangeLog;
}

export interface LiabilitiesItems {
	totalLiabilities: string;
	reconcileRecords: number;
	change?: ApplicationChangeLog;
}
