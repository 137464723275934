import { LoanDetailScenarioDTO, ScenarioLeverDTO } from '@app/modules/typings/api';

export class BorrowingScenarioTransformer {
	static fromPayload(
		baseScenario: ScenarioLeverDTO,
		selectedScenario: ScenarioLeverDTO | undefined
	): BorrowingScenario {
		const baseLoanSplits: LoanDetailScenarioDTO[] = baseScenario.loanDetails;
		const selectedScenarioLoanSplits: LoanDetailScenarioDTO[] | undefined = selectedScenario?.loanDetails;

		const totalCapitalisedFee = baseScenario.totalCapitalisedFeeAmount ?? 0;
		const lmiPremiumFee = baseScenario?.lmiPremiumAmount ?? 0;

		if (baseLoanSplits.length == 1) {
			return {
				baseScenarioId: baseLoanSplits[0].id,
				selectedScenarioId: selectedScenarioLoanSplits?.[0]?.id,
				loanDetailsId: baseLoanSplits[0]?.loanDetailsId,
				baseScenarioTotalLoanAmount:
					(baseLoanSplits[0]?.totalBorrowingAmount ?? 0) + lmiPremiumFee + totalCapitalisedFee,
				selectedScenarioTotalLoanAmount: selectedScenarioLoanSplits?.[0]?.totalBorrowingAmount ?? undefined
			};
		}

		return {
			baseScenarioTotalLoanAmount: BorrowingScenarioTransformer.generateBaseScenarioTotal(
				baseLoanSplits,
				lmiPremiumFee,
				totalCapitalisedFee
			),
			selectedScenarioTotalLoanAmount: BorrowingScenarioTransformer.generateSelectedScenarioTotal(
				baseLoanSplits,
				selectedScenarioLoanSplits
			),
			splits: baseLoanSplits.map((baseLoanSplit, index) => {
				const selectedScenarioLoanSplit = BorrowingScenarioTransformer.getSelectedScenarioLoanSplitBasedOnLoanId(
					baseLoanSplit.loanDetailsId,
					selectedScenarioLoanSplits
				);

				return {
					loanDetailsId: baseLoanSplit.loanDetailsId,
					splitName: BorrowingScenarioTransformer.generateSplitName(index, baseLoanSplit),
					baseScenarioId: baseLoanSplit.id,
					baseScenarioLoanSplit:
						index === 0
							? (baseLoanSplit.totalBorrowingAmount ?? 0) + lmiPremiumFee + totalCapitalisedFee
							: baseLoanSplit.totalBorrowingAmount,
					selectedScenarioId: selectedScenarioLoanSplit?.id,
					selectedScenarioLoanSplit: selectedScenarioLoanSplit?.totalBorrowingAmount
				} as Split;
			})
		};
	}

	static toPayload(borrowingScenario: BorrowingScenario): LoanDetailScenarioDTO[] {
		if (!borrowingScenario.splits && borrowingScenario.selectedScenarioTotalLoanAmount != undefined) {
			return [
				{
					id: borrowingScenario.selectedScenarioId!,
					loanDetailsId: borrowingScenario.loanDetailsId!,
					totalBorrowingAmount: borrowingScenario.selectedScenarioTotalLoanAmount
				}
			];
		}
		return (borrowingScenario.splits || [])
			.filter((split) => split.selectedScenarioLoanSplit != undefined)
			.map((split) => {
				return {
					id: split.selectedScenarioId!,
					loanDetailsId: split.loanDetailsId!,
					totalBorrowingAmount: split.selectedScenarioLoanSplit
				};
			});
	}

	static generateBaseScenarioTotal(
		splits: LoanDetailScenarioDTO[] | undefined,
		lmiPremiumAmount: number,
		totalCapitalisedFee: number
	) {
		const totalBorrowingAmount =
			splits?.reduce((accumulator, current) => accumulator + (current.totalBorrowingAmount || 0), 0) ?? 0;
		return totalBorrowingAmount + lmiPremiumAmount + totalCapitalisedFee;
	}

	static generateSelectedScenarioTotal(
		baseLoanSplit: LoanDetailScenarioDTO[] | undefined,
		selectedScenarioLoanSplits: LoanDetailScenarioDTO[] | undefined
	): number | undefined {
		if (!baseLoanSplit) {
			return undefined;
		}

		const isBorrowingAmountsAvailable = selectedScenarioLoanSplits?.some(({ totalBorrowingAmount }) =>
			Boolean(totalBorrowingAmount)
		);

		if (!isBorrowingAmountsAvailable) {
			return undefined;
		}

		const totalBorrowingAmount = baseLoanSplit?.reduce((acc, split) => {
			const matchingSplit = selectedScenarioLoanSplits?.find(
				(selectedSplit) => selectedSplit.loanDetailsId === split.loanDetailsId
			);

			const borrowingAmount = matchingSplit?.totalBorrowingAmount ?? split.totalBorrowingAmount ?? 0;
			return acc + borrowingAmount;
		}, 0);

		return totalBorrowingAmount;
	}

	static generateSplitName(index: number, baseLoanSplit: LoanDetailScenarioDTO): string {
		let name = `Split ${index + 1}`;
		if (baseLoanSplit.productNameWithFeature && baseLoanSplit.productInterestRate) {
			name = name.concat(
				` - ${baseLoanSplit.productNameWithFeature} - ${baseLoanSplit.productInterestRate.toFixed(2)}%`
			);
		}
		return name;
	}

	static getSelectedScenarioLoanSplitBasedOnLoanId(
		loanDetailsId: number,
		selectedScenarioLoanSplits: LoanDetailScenarioDTO[] | undefined
	): LoanDetailScenarioDTO | undefined {
		return selectedScenarioLoanSplits?.find(
			(selectedScenarioLoanSplit) => selectedScenarioLoanSplit.loanDetailsId === loanDetailsId
		);
	}
}

export interface BorrowingScenario {
	loanDetailsId?: number;
	baseScenarioId?: number;
	baseScenarioTotalLoanAmount?: number;
	selectedScenarioId?: number;
	selectedScenarioTotalLoanAmount?: number;
	splits?: Split[];
}
export interface Split {
	loanDetailsId?: number;
	splitName: string;
	baseScenarioId?: number;
	baseScenarioLoanSplit?: number;
	selectedScenarioId?: number;
	selectedScenarioLoanSplit?: number;
}
