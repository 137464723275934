import { FinancialAssetType } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SavingsDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';

export const DIVIDEND_FINANCIAL_ASSET_TYPES = [FinancialAssetType.ManagedFunds, FinancialAssetType.SharesandDebentures];
export const INTEREST_FINANCIAL_ASSET_TYPES = [
	FinancialAssetType.Investment,
	FinancialAssetType.Other,
	FinancialAssetType.SavingsAccount,
	FinancialAssetType.TermDeposit,
	FinancialAssetType.TransactionAccount
];

export class LinkedAssetTransformer {
	static fromPayload(savingDTO: SavingsDTO[], formEnumsQuery: FormEnumsQuery): CombinedLinkedAssets {
		const dividendLinkedAssets = savingDTO
			?.filter((linkedAsset) => DIVIDEND_FINANCIAL_ASSET_TYPES.includes(linkedAsset.type))
			.map((savingData: SavingsDTO) => {
				return LinkedAssetTransformer.createOptionLabel(savingData, formEnumsQuery);
			});

		const InterestIncomeLinkedAssets = savingDTO
			?.filter((linkedAsset) => INTEREST_FINANCIAL_ASSET_TYPES.includes(linkedAsset.type))
			.map((savingData: SavingsDTO) => {
				return LinkedAssetTransformer.createOptionLabel(savingData, formEnumsQuery);
			});

		return { dividendLinkedAssets, InterestIncomeLinkedAssets };
	}

	static createOptionLabel(savingDTO: SavingsDTO, formEnumsQuery: FormEnumsQuery): EnumObject {
		const assetTypeLabel = formEnumsQuery?.getOptionLabel('FinancialAssetType', savingDTO.type);
		const financialInstitution = formEnumsQuery?.getOptionLabel('FinancialInstitution', savingDTO.financialInstitution);

		let label = `${assetTypeLabel} - $${savingDTO.value}`;
		if (financialInstitution) {
			label = label + ` - ${financialInstitution}`;
		}
		return {
			id: savingDTO.id!,
			label
		};
	}
}

export interface CombinedLinkedAssets {
	dividendLinkedAssets: EnumObject[];
	InterestIncomeLinkedAssets: EnumObject[];
}
