import { Injectable } from '@angular/core';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApplicantEnumObject } from '../../enums/enum-helper';

import { FormEnumsStore } from './form-enums.store';

@Injectable({ providedIn: 'root' })
export class FormEnumsQuery {
	constructor(protected store: FormEnumsStore) {
		FormEnumsQuery.getStaticOptionsLabel = (key: string, id?: number) => {
			const enums = store.getValue();
			const enumObj = enums[key];
			if (!enumObj) {
				console.error('Enum not found in store', key);
				return '';
			}
			return enums[key].find((item) => item.id === id)?.label;
		};
	}

	/**
	 * For the usage in Static model transformation classes where we can't inject the service. It's implementation is overridden in constructor above
	 * @param key
	 * @returns
	 */
	static getStaticOptionsLabel = (key: string, id?: number): string | undefined => (key && id ? '' : undefined);

	getOptions = (key: string): EnumObject[] => this.store.getValue()[key];

	getOption = (key: string, id: number): EnumObject | undefined => {
		return (this.getOptions(key) ?? []).find((option) => option.id === id);
	};

	getOptionLabel = (key: string, id: number): string => {
		const options = this.store.getValue()[key] || [];
		const selectedOption = options.find((option) => option.id === id);
		return selectedOption ? selectedOption.label : '';
	};

	getOptionId = (key: string, label: string): number => {
		const options = this.store.getValue()[key] || [];
		const selectedOption = options.find((option) => option.label === label);
		return selectedOption ? selectedOption.id : 0;
	};

	getHouseHolds(): EnumObject[] {
		return this.store.getValue()['Households'] as unknown as EnumObject[];
	}

	getAddOptions(key: string): EnumObject[] {
		const options = cloneDeep(this.getOptions(key));
		options?.forEach((option) => {
			option.label = "<i class='icon fas fa-plus'></i>" + option.label;
		});
		return options;
	}

	getExistingPartiesById(selected: ApplicantEnumObject): ApplicantEnumObject {
		const persons = this.getOptions('Persons') as unknown as ApplicantEnumObject[];
		const companies = this.getOptions('Companies') as unknown as ApplicantEnumObject[];
		const trusts = this.getOptions('Trusts') as unknown as ApplicantEnumObject[];
		const relations = this.getOptions('Relations') as unknown as ApplicantEnumObject[];
		const relatedPersons = this.getOptions('RelatedPersons') as unknown as ApplicantEnumObject[];
		const parties = persons.concat(companies, trusts, relations, relatedPersons);
		const filteredParty = parties.find((x) => x.id == selected.id && x.type == selected.type) as ApplicantEnumObject;

		return filteredParty;
	}

	getRelatedCompanyName(id: number): string {
		const companies = this.getOptions('RelatedCompanies') as unknown as ApplicantEnumObject[];
		const company = companies.find((x) => x.id === id);

		return company ? company.companyName ?? '' : '';
	}

	selectEnumOptions(name: string, takeOne = false): Observable<EnumObject[]> {
		return takeOne ? this.select(name).pipe(take(1)) : this.select(name);
	}

	private select(key: string): Observable<EnumObject[]> {
		return this.store.select(key).pipe(map((options: EnumObject[]) => options ?? []));
	}
}
