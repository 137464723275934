import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApplicationDetailsDto } from '@app/modules/setup/typings/setup';

@Component({
	selector: 'pre-check-wrapper',
	templateUrl: './pre-check-wrapper.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreCheckWrapperComponent {
	@Input() applicationDetailsAPI?: ApplicationDetailsDto;
}
