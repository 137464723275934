export enum RefiStore {
	Eligibility = 'refi-eligibility-details',
	ApplicationSummary = 'refi-summary-details',
	LoanDetails = 'refi-loanDetails-details',
	LoanRequirements = 'refi-loanRequirements-details',
	Income = 'refi-income-details',
	Expenses = 'refi-expenses-details',
	Assets = 'refi-assets-details',
	Liabilities = 'refi-liabilities-details',
	ProductSelection = 'refi-products-details',
	Review = 'refi-review-details',
	ApplicationStatuses = 'refi-application-statuses',
	Common = 'refi-common',
	ConfirmDetails = 'refi-confirmDetails-details',
	PropertyDetails = 'refi-propertyDetails-details',
	Modals = 'refi-modals'
}
