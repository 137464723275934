import { Address, CurrentAddressDTO, PostSettlementAddressDTO, PreviousAddressDTO } from '@app/modules/typings/api';

import { PersonAddresses, PreviousAddressModel } from '../typings/applicants';

export class AddressDetailsTransformer {
	static fromPayload(
		currentAddressDTO: CurrentAddressDTO,
		previousAddressDTO: PreviousAddressDTO[],
		postSettlementAddressDTO: PostSettlementAddressDTO
	): PersonAddresses {
		return {
			currentAddress: currentAddressDTO,
			previousAddresses:
				previousAddressDTO.length > 0
					? previousAddressDTO.map((address: PreviousAddressDTO) => {
							return {
								...address,
								duration: [address.startDate, address.endDate]
							} as PreviousAddressModel;
					  })
					: [],
			mailingAddressContainer: { mailingAddress: currentAddressDTO.mailingAddress },
			postSettlementAddress: postSettlementAddressDTO
		};
	}
}
