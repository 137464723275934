import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs/operators';

import { FormAddressesStore } from './form-addresses.store';
import { FormAddress } from './typings/form-addresses';

@Injectable({ providedIn: 'root' })
export class FormAddressesQuery {
	selectAddresses$ = this.store.select().pipe(map((formAddress) => cloneDeep((formAddress as FormAddress).addresses)));

	constructor(protected store: FormAddressesStore) {}
}
