<simp-button-group
	[id]="uniqueId"
	[options]="$any($any(to.options) | selectOptions | async | dynamicOptions: to.dynamicOptions)"
	[formControl]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[vertical]="to.vertical"
	(click)="onClick($event)"
	[tooltipContent]="to.tooltip"
	[tooltipAsModal]="to.tooltipAsModal"
	(tooltipClick)="tooltipClick(field)"
	[readonly]="!!to.readonly"
	[clearable]="to.clearable"
	metadataKey
	[formlyField]="field"
	><span [innerHTML]="to.label"></span>
</simp-button-group>
