import { EmployerModelTransformer, EmployerPartner } from '@app/modules/employers/model/employer.model';
import { BusinessStructureFull, CountryCode, NameTitle, RelatedCompanyType } from '@app/modules/shared/enums/app.enums';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import {
	RelatedCompanyFinancials,
	RelatedCompanyFinancialsTransformer
} from '@app/modules/shared/model/related-company-financial.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Address, RelatedCompanyDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export interface RelatedCompanyModel {
	id: number;
	applicationId: number;
	companyName: string;
	abn?: string;
	acn?: string;
	isABNVerified?: boolean;
	registeredInCountry?: CountryCode;
	registrationDate?: string;
	registeredForGST?: boolean;
	gstRegistrationDate?: string;
	address?: Address;
	officePhoneNumber?: PhoneDetails;
	officeFaxNumber?: PhoneDetails;
	webAddress?: string;
	email?: string;
	contactPerson?: {
		nameTitleId?: NameTitle;
		firstName?: string;
		lastName?: string;
		position?: string;
	};
	businessStructure?: BusinessStructureFull;
	industryDivision?: number;
	industrySubdivision?: number;
	mainBusinessActivity?: string;
	dateStarted?: string;
	partners?: EmployerPartner[];
	shareHolders?: EmployerPartner[];
	trustBeneficiaries?: EmployerPartner[];
	companyFinancials?: RelatedCompanyFinancials[];
	relatedCompanyType?: RelatedCompanyType;
}

export class RelatedCompanyModelTransformer {
	static toPayload(relatedCompany: RelatedCompanyModel, applicationId: number): RelatedCompanyDTO {
		if (relatedCompany.address) {
			relatedCompany.address.applicationId = applicationId;
		}
		return {
			id: relatedCompany?.id ?? 0,
			applicationId,
			companyName: relatedCompany.companyName,
			abn: relatedCompany.abn,
			acn: relatedCompany.acn,
			abnVerified: relatedCompany.isABNVerified,
			registeredInCountryId: relatedCompany.registeredInCountry,
			dateRegistered: relatedCompany.registrationDate,
			registeredForGST: relatedCompany.registeredForGST,
			gstRegisteredDate: relatedCompany.gstRegistrationDate,
			address: relatedCompany.address,
			officePhone: PhoneNumberHelper.toPayLoad(relatedCompany.officePhoneNumber),
			email: relatedCompany.email,
			contactPersonNameTitleId: relatedCompany.contactPerson?.nameTitleId,
			contactPersonFirstName: relatedCompany.contactPerson?.firstName,
			contactPersonSurname: relatedCompany.contactPerson?.lastName,
			contactPersonRole: relatedCompany.contactPerson?.position,
			businessStructure: relatedCompany.businessStructure,
			webAddress: relatedCompany.webAddress,
			officeFax: PhoneNumberHelper.toPayLoad(relatedCompany.officeFaxNumber),
			dateStarted: relatedCompany.dateStarted,
			mainBusinessActivity: relatedCompany.mainBusinessActivity,
			industryDivisionId: relatedCompany.industrySubdivision,
			industrySubdivisionId: relatedCompany.industrySubdivision,
			partners:
				relatedCompany.businessStructure === BusinessStructureFull.Partnership && relatedCompany.partners
					? EmployerModelTransformer.toPartnerPayload(relatedCompany.partners)
					: [],
			shareholders:
				relatedCompany.businessStructure === BusinessStructureFull.Company && relatedCompany.shareHolders
					? EmployerModelTransformer.toPartnerPayload(relatedCompany.shareHolders)
					: [],
			beneficiaries:
				relatedCompany.businessStructure === BusinessStructureFull.Trust && relatedCompany.trustBeneficiaries
					? EmployerModelTransformer.toTrustBeneficiaryPayload(relatedCompany.trustBeneficiaries)
					: [],
			relatedCompanyType: relatedCompany.relatedCompanyType
		};
	}

	static fromPayload(
		relatedCompanyDTO: RelatedCompanyDTO,
		applicationId: number,
		formEnumsQuery: FormEnumsQuery
	): RelatedCompanyModel {
		const financialYearEnum = formEnumsQuery.getOptions('FinancialYear');
		return {
			id: relatedCompanyDTO.id,
			applicationId,
			companyName: relatedCompanyDTO.companyName,
			abn: relatedCompanyDTO.abn,
			acn: relatedCompanyDTO.acn,
			isABNVerified: relatedCompanyDTO.abnVerified,
			registeredInCountry: relatedCompanyDTO.registeredInCountryId,
			registrationDate: relatedCompanyDTO.dateRegistered,
			registeredForGST: relatedCompanyDTO.registeredForGST,
			gstRegistrationDate: relatedCompanyDTO.gstRegisteredDate,
			address: relatedCompanyDTO.address,
			officePhoneNumber: PhoneNumberHelper.fromPayload(relatedCompanyDTO.officePhone),
			email: relatedCompanyDTO.email,
			webAddress: relatedCompanyDTO.webAddress,
			officeFaxNumber: PhoneNumberHelper.fromPayload(relatedCompanyDTO.officeFax),
			contactPerson: {
				nameTitleId: relatedCompanyDTO.contactPersonNameTitleId,
				firstName: relatedCompanyDTO.contactPersonFirstName,
				lastName: relatedCompanyDTO.contactPersonSurname,
				position: relatedCompanyDTO.contactPersonRole
			},
			businessStructure: relatedCompanyDTO.businessStructure,
			dateStarted: relatedCompanyDTO.dateStarted,
			mainBusinessActivity: relatedCompanyDTO.mainBusinessActivity,
			industryDivision: relatedCompanyDTO.industrySubdivisionId,
			industrySubdivision: relatedCompanyDTO.industrySubdivisionId,
			relatedCompanyType: relatedCompanyDTO.relatedCompanyType,
			partners: relatedCompanyDTO.partners
				? EmployerModelTransformer.fromPartnerPayload(relatedCompanyDTO.partners, formEnumsQuery)
				: [],
			shareHolders: relatedCompanyDTO.shareholders
				? EmployerModelTransformer.fromPartnerPayload(relatedCompanyDTO.shareholders, formEnumsQuery)
				: [],
			trustBeneficiaries: relatedCompanyDTO.beneficiaries
				? EmployerModelTransformer.fromTrustBenefieciaryPayload(relatedCompanyDTO.beneficiaries, formEnumsQuery)
				: [],
			companyFinancials: relatedCompanyDTO.companyFinancials?.length
				? relatedCompanyDTO.companyFinancials?.map((financial, index) => {
						const financialYear = financialYearEnum?.length ? financialYearEnum[index]?.id : 0;

						return RelatedCompanyFinancialsTransformer.fromPayload(financial, financialYear);
				  })
				: RelatedCompanyFinancialsTransformer.getDefaultFinancialYears(financialYearEnum)
		};
	}
}
