import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { GovernmentBenefitsType, OtherIncomeType, Proportions } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { OtherIncomeDTO, PercentOwned, PersonApplicantDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiOtherIncomeTransformer {
	static toPayload(model: RefiOtherIncomeModel, applicationId: number, formEnumQuery: FormEnumsQuery): OtherIncomeDTO {
		return {
			id: model.otherIncomeModal.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			type: model.otherIncomeModal.typeOfIncome,
			amount: model.otherIncomeModal?.incomeRate?.amount || 0,
			frequency: model.otherIncomeModal?.incomeRate?.frequency as FrequencyShort,
			percentsOwned: RefiTransformerHelper.setPercentsOwnedWithJointSelection(
				formEnumQuery,
				model.otherIncomeModal.ownership
			),
			governmentBenefitsType: model.otherIncomeModal?.governmentBenefitsType,
			isTaxable: true,
			description: model.otherIncomeModal.otherIncomeDetails,
			percentOwnedProportions: Proportions.Specified
		};
	}
	static fromPayload(data: OtherIncomeDTO, applicants: PersonApplicantDTO[]): RefiOtherIncomeModel {
		return {
			otherIncomeModal: {
				id: data.id,
				incomeRate: {
					amount: data.amount,
					frequency: data.frequency
				},
				typeOfIncome: data.type,
				ownership: RefiTransformerHelper.getOwnership(data.percentsOwned as PercentOwned[]),
				governmentBenefitsType: data.governmentBenefitsType,
				otherIncomeDetails: data.description
			},
			summary: `${RefiTransformerHelper.getJointApplicantNames(applicants, data.percentsOwned)} ${formatCurrency(
				calculateMonthly(data.amount, data.frequency),
				'en-AU',
				'$'
			)} per month`
		};
	}
}

export interface RefiOtherIncomeModel {
	otherIncomeModal: RefiOtherIncomeDetails;
	summary: string;
}

interface RefiOtherIncomeDetails {
	id?: number;
	typeOfIncome: OtherIncomeType;
	otherIncomeDetails?: string;
	governmentBenefitsType?: GovernmentBenefitsType;
	ownership?: number;
	incomeRate?: AmountSelect;
}
