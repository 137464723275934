import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { JOURNEY_MAPPINGS } from '../constants/constants';
import { SharedFlagsService } from '../store/shared-flags/shared-flags.service';
import { FormAreaPath } from '../typings/form-areas.types';
import { RemoteErrorPanelSelection } from '../typings/validations';

@Injectable({ providedIn: 'root' })
export class NavigationService {
	remoteErrorPanelSelection: RemoteErrorPanelSelection = {
		validationErrorsExpanded: true,
		selectedValidationErrorPosition: undefined
	};

	scrolledToSubSectionSubject = new Subject<string>();
	mobileBackButtonClickedSubject = new Subject<string>();
	mobileBackButtonClickedPrehookSubject = new Subject<string>();
	scrolledToSubSection$ = this.scrolledToSubSectionSubject.asObservable();
	mobileBackButtonClicked$ = this.mobileBackButtonClickedSubject.asObservable();
	mobileBackButtonClickedPrehook$ = this.mobileBackButtonClickedPrehookSubject.asObservable();

	private anchor = '';
	constructor(private router: Router, private sharedFlagsService: SharedFlagsService) {}

	navigateToArea(area?: FormAreaPath, subSectionKey?: string): void {
		this.anchor = subSectionKey as string;
		void this.router.navigate([`/${area}`]);
		if (subSectionKey) {
			this.scrollToSubSection(subSectionKey);
		}
	}

	scrollToSubSection(id: string): void {
		this.scrolledToSubSectionSubject.next(id);
	}

	mobileGoBack() {
		const journeyMapping = JOURNEY_MAPPINGS.find(
			(journey) => journey.journeyType === this.sharedFlagsService.currentJourney
		);
		const journeyPath = journeyMapping?.returnUrl ?? '';
		this.mobileBackButtonClickedSubject.next(journeyPath);
	}
	navigatedTo(anchor: string): boolean {
		const navigatedTo = anchor === this.anchor;
		if (navigatedTo) {
			this.anchor = '';
		}
		return navigatedTo;
	}

	navigateByUrl(url: string): void {
		void this.router.navigateByUrl(url);
	}
}
