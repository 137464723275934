import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';
import { formlyInput } from './formly-input.helper';

export const formlyButtonInput = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	const inputBoxProperty = formlyInput({
		key: 'extract',
		class: 'hidden'
	});
	// `extract` field's value would be displayed as input box
	childProperties = childProperties ?? {};
	childProperties['extract'] = inputBoxProperty;
	const validations = property.required && property.required.length ? property.required.concat('extract') : ['extract'];

	return {
		type: 'object',
		title: property.title,
		required: property?.templateOptions?.required ? validations : undefined,
		widget: {
			formlyConfig: {
				type: 'button-input',
				className: `${property.templateOptions?.class ?? (property.class || 'col-4')}`,
				templateOptions: {
					customClass: 'simp-row',
					...property.templateOptions
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property)
			}
		},
		properties: childProperties
	};
};
