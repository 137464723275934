import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { NonRealEstateAssetType, Proportions, YesNo } from '@app/modules/shared/enums/app.enums';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { OtherAssetDTO, PercentOwned } from '@app/modules/typings/api';
import { getNullableBoolean, getNullableYesNo } from '../helper/util';
import { OtherAssetType, VehicleType } from './../enums/app.enums';

import { EnumObject } from '@simpology/client-components/utils';
import { PercentageOwnedTransformer } from './percentage-owned.model';

export class OtherAssetTransformer {
	static toPayload(applicationId: number, otherAssetModel: OtherAssetModel): OtherAssetDTO {
		const otherAssetDetails = otherAssetModel.details.otherAssetDetails;
		return {
			applicationId: applicationId,
			id: otherAssetModel.id ?? CONSTANTS.NEW_ID,
			description: otherAssetDetails.description,
			value: otherAssetDetails.value.amount,
			type: otherAssetDetails?.assetType,
			toBeUsedAsSecurity: getNullableBoolean(otherAssetDetails.useAsSecurity),
			percentOwnedProportions: otherAssetDetails.isEqualShare ? Proportions.Equal : Proportions.Specified,
			percentsOwned: otherAssetDetails.percentsOwned,
			estimateBasisId: otherAssetDetails.value.frequency,
			businessName: otherAssetDetails.businessName,
			mainBusinessActivity: otherAssetDetails.mainBusinessActivity,
			otherAssetType: otherAssetDetails.otherAssetType,
			vehicleType: otherAssetDetails.vehicleType,
			vehicleModel: otherAssetDetails.vehicleModel,
			isForeign: otherAssetDetails.isForeign,
			linkedLiabilities:
				otherAssetDetails.linkedLiabilities
					?.filter((linkedLiability) => linkedLiability.liabilityType)
					?.map((x) => {
						return {
							id: x.liabilityType.id,
							liabilityType: parseInt(x.liabilityType.info as string)
						};
					}) ?? []
		};
	}

	static fromPayload(
		otherAsset: OtherAssetDTO,
		applicants: ShortApplicant[],
		formEnumsQuery: FormEnumsQuery
	): OtherAssetModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			otherAsset?.percentsOwned,
			applicants
		);

		const otherAssetModel: OtherAssetModel = {
			id: otherAsset.id,
			summaryAmount: otherAsset.value,
			details: {
				otherAssetDetails: {
					value: {
						amount: otherAsset.value,
						frequency: otherAsset.estimateBasisId
					},
					assetType: otherAsset.type,
					percentsOwned: percentageOwned,
					description: otherAsset.description,
					useAsSecurity: getNullableYesNo(otherAsset.toBeUsedAsSecurity),
					businessName: otherAsset.businessName,
					mainBusinessActivity: otherAsset.mainBusinessActivity,
					vehicleType: otherAsset.vehicleType,
					vehicleModel: otherAsset.vehicleModel,
					otherAssetType: otherAsset.otherAssetType,
					isEqualShare: otherAsset.percentOwnedProportions === Proportions.Equal,
					isForeign: otherAsset.isForeign,
					linkedLiabilities: otherAsset.linkedLiabilities?.map((x) => {
						return {
							liabilityType: {
								id: x.id
							} as EnumObject
						};
					})
				},
				extract: this.setOtherAssetExtract(otherAsset, formEnumsQuery)
			},
			amountEntry: otherAsset.value
		};
		return otherAssetModel;
	}

	static setOtherAssetExtract(otherAsset: OtherAssetDTO, formEnumsQuery: FormEnumsQuery): string {
		let extract = otherAsset.type ? formEnumsQuery.getOptionLabel('NonRealEstateAssetType', otherAsset.type) : ``;
		switch (otherAsset.type) {
			case NonRealEstateAssetType.MotorVehicleandTransport:
				extract += otherAsset.vehicleType
					? ` - ${formEnumsQuery.getOptionLabel('VehicleType', otherAsset.vehicleType)}`
					: '';
				break;
			case NonRealEstateAssetType.Business:
				extract +=
					otherAsset.businessName && otherAsset.businessName.trim().length != 0 ? ` - ${otherAsset.businessName}` : '';
				break;
			case NonRealEstateAssetType.Other:
				extract += otherAsset.otherAssetType
					? ` - ${formEnumsQuery.getOptionLabel('OtherAssetType', otherAsset.otherAssetType)}`
					: '';

				break;
		}
		return extract;
	}

	static updateLiabilityTypeEnum(otherAssets: OtherAssetModel[], formEnumsQuery: FormEnumsQuery): OtherAssetModel[] {
		return otherAssets.map((otherAsset) => {
			const linkedLiabilities = otherAsset.details?.otherAssetDetails?.linkedLiabilities || [];

			return {
				...otherAsset,
				details: {
					...otherAsset.details,
					otherAssetDetails: {
						...otherAsset.details?.otherAssetDetails,
						linkedLiabilities: linkedLiabilities.map((linkedLiability) => {
							return {
								liabilityType: formEnumsQuery.getOption('Liabilities', linkedLiability.liabilityType.id) as EnumObject
							};
						})
					}
				}
			};
		});
	}
}

export interface OtherAssetModel {
	id?: number;
	amountEntry?: number;
	details: { otherAssetDetails: OtherAssetDetails; extract: string };
	summaryAmount?: number;
}

export interface OtherAssetDetails {
	isEqualShare: boolean;
	percentsOwned?: PercentOwned[];
	assetType: NonRealEstateAssetType;
	value: AmountSelect;
	description: string;
	useAsSecurity?: YesNo;
	businessName?: string;
	mainBusinessActivity?: string;
	vehicleType?: VehicleType;
	vehicleModel?: string;
	otherAssetType?: OtherAssetType;
	isForeign: boolean;
	linkedLiabilities?: { liabilityType: EnumObject }[];
	hideLinkedSecurities?: boolean; //UI purposes only
}
