import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyMobileModal = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	return {
		type: 'object',
		title: property.title,
		required: property.required,
		widget: {
			formlyConfig: {
				wrappers: ['mobile-modal'],
				className: `${property.templateOptions?.class ?? property.class}`,
				templateOptions: {
					customClass: 'simp-row',
					...property.templateOptions
				},
				expressionProperties: parseExpressionProperties(property)
			}
		},
		properties: childProperties
	};
};
