<!-- TODO Cleanup, no arrayTypes here -->
<simp-field-error
	*ngIf="(formControl.dirty || formControl.touched) && formControl.errors"
	[errors]="formControl.errors"
	[validationErrors]="field.validation?.messages!"
>
</simp-field-error>
<ng-container [ngSwitch]="to.arrayType">
	<ng-container *ngSwitchDefault>
		<div [class]="to.customClass">
			<ng-container *ngFor="let f of field.fieldGroup">
				<div class="w-100" *ngIf="f?.templateOptions?.newLine"></div>
				<formly-field [field]="f"></formly-field>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'alternate'">
		<div class="simp-row" [ngClass]="{ 'object-container': to.greyBackground }">
			<ng-container *ngFor="let f of field.fieldGroup">
				<div class="w-100" *ngIf="f?.templateOptions?.newLine"></div>
				<formly-field [field]="f"></formly-field>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'inline'">
		<div class="simp-row">
			<ng-container *ngFor="let f of field.fieldGroup">
				<div class="w-100" *ngIf="f?.templateOptions?.newLine"></div>
				<formly-field [field]="f"></formly-field>
			</ng-container>
		</div>
	</ng-container>
</ng-container>
