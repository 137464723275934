import { Injectable } from '@angular/core';
import { finalize, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { AppService } from '@app/app.service';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { TemplateDocumentTemplateType } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import {
	SignatureRequiredDetails,
	SignatureRequiredDetailsDto
} from '@app/modules/summary-lodgement/models/signature-required-details';
import {
	IndividualDocument,
	TemplateDocumentDetail
} from '@app/modules/summary-lodgement/models/template-document.model';
import { ApplicationFormElectronicSignaturesService } from '@app/modules/summary-lodgement/services/application-form-electronic-signatures.service';
import { mergeMap, tap } from 'rxjs/operators';
import { ApplicationNonLinkedDocumentsService } from './application-non-linked-documents.service';

@Injectable({
	providedIn: 'root'
})
export class ApplicationTemplateDocumentsService extends BaseJourneyService<TemplateDocumentDetail[]> {
	private documentsTemplates: TemplateDocumentDetail[] = [];
	private cachedHasDocuments$?: Observable<boolean>;

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private appService: AppService,
		private applicationSignaturesService: ApplicationFormElectronicSignaturesService,
		private documentsService: ApplicationNonLinkedDocumentsService
	) {
		super();
		this.setRoute(URL_CONSTANTS.TEMPLATE_DOCUMENT);
	}

	fetchTemplateDocuments() {
		this.appService.appSpinnerStatus$.next(CONSTANTS.SYNCING);
		return this.getTemplateDocumentsDetails().pipe(
			switchMap((templateDocumentDetails) => {
				const observables = templateDocumentDetails.map((template) => this.addExtraDataForTemplate(template));

				return forkJoin(observables).pipe(
					switchMap(() => {
						this.documentsTemplates = [...templateDocumentDetails];
						return of(this.documentsTemplates);
					})
				);
			}),
			finalize(() => {
				this.appService.appSpinnerStatus$.next('');
			})
		);
	}

	getTemplateDocumentsDetails(): Observable<TemplateDocumentDetail[]> {
		return this.get(`GetTemplateDocuments/${this.applicationDataQuery.applicationId()}`);
	}

	// calling secure if journey has configured documents
	hasDocuments(): Observable<boolean> {
		if (!this.cachedHasDocuments$) {
			this.cachedHasDocuments$ = this.get(
				`GetTemplateDocumentsForJourney/${this.applicationDataQuery.getApplicationJourneyId()}`
			).pipe(
				map((documents) => documents && documents.length > 0),
				shareReplay(1)
			);
		}
		return this.cachedHasDocuments$;
	}

	// reset when journey is changed within application
	resetCache() {
		this.cachedHasDocuments$ = undefined;
	}

	getDocumentsTemplates(): Array<TemplateDocumentDetail> {
		return this.documentsTemplates;
	}

	getLinkedDocsIds(): Array<TemplateDocumentDetail> {
		return this.documentsTemplates.filter(
			(templateDoc) => templateDoc.templateType === TemplateDocumentTemplateType.Linked
		);
	}

	getNonLinkedDocs(): Array<TemplateDocumentDetail> {
		return this.documentsTemplates.filter(
			(templateDoc) => templateDoc.templateType !== TemplateDocumentTemplateType.Linked
		);
	}

	getSignatureRequiredDocs(): Array<TemplateDocumentDetail> {
		return this.documentsTemplates.filter((templateDoc) => templateDoc?.signatureRequired);
	}

	getTemplatesForIndividual(): Array<TemplateDocumentDetail> {
		return this.documentsTemplates.filter(
			(templateDoc) => templateDoc.forIndividualPerson && !templateDoc.signatureRequired
		);
	}

	getSignatureRequiredDocsIds(): Array<number> {
		return this.getSignatureRequiredDocs().map((template) => template?.id);
	}

	private addExtraDataForTemplate(template: TemplateDocumentDetail): Observable<IndividualDocument[]> {
		return this.updateTemplateDetailsWithApplicationSignatures(template).pipe(
			mergeMap(() => this.updateTemplateDetailsWithIndividualDocs(template))
		);
	}

	private updateTemplateDetailsWithIndividualDocs(template: TemplateDocumentDetail): Observable<IndividualDocument[]> {
		return this.documentsService.getIndividualDocuments(template.id).pipe(
			tap((docs: IndividualDocument[]) => {
				template.documentDetails = docs;
			})
		);
	}

	private updateTemplateDetailsWithApplicationSignatures(
		template: TemplateDocumentDetail
	): Observable<SignatureRequiredDetailsDto[]> {
		return this.applicationSignaturesService.getApplicationSignatures(template.id).pipe(
			tap((requiredDetailsDtos: SignatureRequiredDetailsDto[]) => {
				template.signatureRequiredDetails = this.buildDistinctPersonsWithMaxDate(requiredDetailsDtos, template);
			})
		);
	}

	private buildDistinctPersonsWithMaxDate(
		requiredDetailsDtos: SignatureRequiredDetailsDto[],
		template: TemplateDocumentDetail
	): SignatureRequiredDetails[] {
		const requiredDetails = requiredDetailsDtos.map((dto) => {
			return {
				templateId: template.id,
				requestedDate: dto?.requestedDate ? new Date(Date.parse(dto.requestedDate)) : undefined,
				signedDate: dto?.signedDate ? new Date(Date.parse(dto.signedDate)) : undefined,
				personId: dto.personId,
				personName: dto.personName,
				status: dto.status,
				createdDate: dto.createdDate,
				declineReason: dto.declineReason
			} as SignatureRequiredDetails;
		});

		const distinctPersons: SignatureRequiredDetails[] = [];
		const uniquePersonIds = [...new Set(requiredDetails.map((obj) => obj.personId))];
		uniquePersonIds.forEach((personId) => {
			const personsRequestedSignature = requiredDetails.filter((obj) => obj.personId === personId);

			const maxDate = personsRequestedSignature.reduce((max, obj) => {
				const requestedDate = obj.requestedDate?.getTime() ?? 0;
				const signedDate = obj.signedDate?.getTime() ?? 0;
				const currentDate = Math.max(requestedDate, signedDate);
				return Math.max(max, currentDate);
			}, 0);

			let requestedSignObject;
			if (maxDate === 0) {
				const existsGeneratedObject = personsRequestedSignature.length > 0;
				requestedSignObject = existsGeneratedObject ? personsRequestedSignature[0] : undefined;
			} else {
				requestedSignObject = personsRequestedSignature.find(
					(obj) => (obj.requestedDate?.getTime() ?? 0) === maxDate || (obj.signedDate?.getTime() ?? 0) === maxDate
				);
			}

			if (requestedSignObject) {
				distinctPersons.push(requestedSignObject);
			}
		});
		return distinctPersons;
	}
}
