import { RateToBorrowerDTO } from '@app/modules/typings/api';

export class RateToBorrowerTransformer {
	static fromPayload(rateToBorrower: RateToBorrowerDTO): RateToBorrower {
		return {
			loanId: rateToBorrower.loanDetailsId,
			applicationId: rateToBorrower.applicationId,
			interestRate: rateToBorrower.interestRate,
			initialTermDiscountRate: rateToBorrower.initialTermDiscountRate ?? 0,
			initialBorrowersRate: rateToBorrower.initialBorrowersRate,
			initialCommission: rateToBorrower.initialCommission,
			initialTermPeriod: rateToBorrower.initialTermPeriod,
			repaymentFrequency: rateToBorrower.initialTermRepaymentFrequencyId,
			initialRepayments: rateToBorrower.initialRepayments,
			initialTermRepaymentAmount: rateToBorrower.initialTermRepaymentAmount,
			revertRate: rateToBorrower.revertRate,
			subSequentTermDiscountRate: rateToBorrower.subSequentTermDiscountRate ?? 0,
			subSequentBorrowersRate: rateToBorrower.subSequentBorrowersRate,
			subSequentTermPeriod: rateToBorrower.subSequentTermPeriod,
			subSeqRepaymentFrequency: rateToBorrower.subSequentTermRepaymentFrequencyId,
			subSequentRepayments: rateToBorrower.subSequentRepayments,
			subSequentTermRepaymentAmount: rateToBorrower.subSequentTermRepaymentAmount,
			isProductSelected: true, // UI Purpose only
			initialAdjustments: rateToBorrower.adjustments,
			subSequentAdjustments: rateToBorrower.subSequentAdjustments,
			subSequentCommission: rateToBorrower.subSequentCommission
		};
	}

	static toPayload(rateToBorrower: RateToBorrower): RateToBorrowerDTO {
		const rateToBorrowerDto = {
			loanDetailsId: rateToBorrower.loanId,
			applicationId: rateToBorrower.applicationId,
			interestRate: rateToBorrower.interestRate,
			initialTermDiscountRate: rateToBorrower.initialTermDiscountRate,
			initialBorrowersRate: rateToBorrower.initialBorrowersRate,
			initialCommission: rateToBorrower.initialCommission,
			initialTermPeriod: rateToBorrower.initialTermPeriod,
			initialTermRepaymentFrequencyId: rateToBorrower.repaymentFrequency,
			initialRepayments: rateToBorrower.initialRepayments,
			initialTermRepaymentAmount: rateToBorrower.initialTermRepaymentAmount,
			adjustments: rateToBorrower.initialAdjustments
		} as RateToBorrowerDTO;

		if (!rateToBorrower.isHideSubTerm) {
			rateToBorrowerDto.revertRate = rateToBorrower.revertRate;
			rateToBorrowerDto.subSequentTermDiscountRate = rateToBorrower.subSequentTermDiscountRate;
			rateToBorrowerDto.subSequentBorrowersRate = rateToBorrower.subSequentBorrowersRate;
			rateToBorrowerDto.subSequentCommission = rateToBorrower.subSequentCommission;
			rateToBorrowerDto.subSequentTermPeriod = rateToBorrower.subSequentTermPeriod;
			rateToBorrowerDto.subSequentTermRepaymentFrequencyId = rateToBorrower.subSeqRepaymentFrequency;
			rateToBorrowerDto.subSequentRepayments = rateToBorrower.subSequentRepayments;
			rateToBorrowerDto.subSequentTermRepaymentAmount = rateToBorrower.subSequentTermRepaymentAmount;
			rateToBorrowerDto.subSequentAdjustments = rateToBorrower.subSequentAdjustments;
		}
		return rateToBorrowerDto;
	}
}

export interface RateToBorrower {
	applicationId: number;
	loanId: number;
	interestRate?: number;
	initialTermDiscountRate?: number;
	initialBorrowersRate?: number;
	initialCommission?: number;
	initialTermPeriod?: number;
	repaymentFrequency?: number;
	initialRepayments?: number;
	initialTermRepaymentAmount?: number;
	initialAdjustments?: number;
	revertRate?: number;
	subSequentAdjustments?: number;
	subSequentTermDiscountRate?: number;
	subSequentBorrowersRate?: number;
	subSequentCommission?: number;
	subSequentTermPeriod?: number;
	subSeqRepaymentFrequency?: number;
	subSequentRepayments?: number;
	subSequentTermRepaymentAmount?: number;
	isProductSelected?: boolean; // UI Purpose only
	isHideSubTerm?: boolean; // UI Purpose only
}
