import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { ApplicantsService } from '@app/modules/applicants/services/applicants.service';
import { CompanyApplicantsService } from '@app/modules/applicants/services/company/company-applicants.service';
import { TrustApplicantsService } from '@app/modules/applicants/services/trust/trust-applicants.service';
import { ComplianceService } from '@app/modules/compliance/compliance.service';
import { ComplianceGuarantorService } from '@app/modules/compliance/services/compliance-guarantor.service';
import { FinancialPositionService } from '@app/modules/financial-position/financial-position.service';
import { CompanyFinancialPositionService } from '@app/modules/financial-position/services/company/company-financial-position.service';
import { TrustFinancialPositionService } from '@app/modules/financial-position/services/trust/trust-financial-position.service';
import { LoanServiceabilityService } from '@app/modules/loan-serviceability/loan-serviceability.service';
import { ServiceabilityScenariosService } from '@app/modules/serviceability-scenarios/serviceability-scenarios.service';
import { SetupService } from '@app/modules/setup/services/setup.service';
import {
	filterDigitalWidgetsFromSchema,
	filterSectionsFromSchema,
	findPropertyFromSchema
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CONSTANTS } from '../../constants/constants';

import { hyphenToCamelCase } from '../../helper/util';
import { AreaTransformerService } from '../../transformers/area-transformer.service';
import { FormAreas } from '../../typings/form-areas.types';
import { ApplicationDataStore } from '../application-data/application-data.store';
import { SideNavItem } from '../application-data/typings/application-data';
import { FormDataSelectorService } from '../form-data/form-data-selector.service';
import { FormMetaDataStore } from './form-metadata.store';
import { FormMetaData } from './typings/form-metadata';

@Injectable({ providedIn: 'root' })
export class FormMetaDataQuery {
	areaLoadingSubject = new BehaviorSubject(false);
	/**
	 * showing spinner in area component when area is loading
	 */
	areaLoading$ = this.areaLoadingSubject.asObservable();

	constructor(
		protected store: FormMetaDataStore,
		private areaTransformerService: AreaTransformerService,
		private formDataSelectorService: FormDataSelectorService,
		private router: Router,
		private setupService: SetupService,
		private financialPositionService: FinancialPositionService,
		private loanServiceabilityService: LoanServiceabilityService,
		private serviceabilityScenariosService: ServiceabilityScenariosService,
		private appService: AppService,
		private applicantsService: ApplicantsService,
		private trustApplicantService: TrustApplicantsService,
		private companyFinancialPositionService: CompanyFinancialPositionService,
		private companyApplicantService: CompanyApplicantsService,
		private trustFinancialPositionService: TrustFinancialPositionService,
		private complianceService: ComplianceService,
		private complianceGuarantorService: ComplianceGuarantorService,
		private applicationDataStore: ApplicationDataStore
	) {}

	select(formArea: FormAreas) {
		return this.store.select(formArea);
	}

	getStore(formArea: FormAreas) {
		return this.store.getValue()[formArea];
	}

	getProperty(formArea: FormAreas, path: string) {
		return findPropertyFromSchema(this.getStore(formArea), path);
	}

	getAvailableSections = (area: FormAreas, subSectionUrl: string, fullAppSideNavItems: SideNavItem[]) => {
		const sideNavItem = fullAppSideNavItems.find((sideNav) => {
			return hyphenToCamelCase(sideNav.url) === area;
		});
		return sideNavItem?.children?.find((item) => item.url === subSectionUrl)?.includedSections;
	};

	selectLoanAppXArea$() {
		return this.store.select(FormAreas.loanAppX).pipe(
			filter((metadata) => !!metadata),
			switchMap((metadata: FormlyApiProperty) =>
				this.areaTransformerService.transformMetadata(cloneDeep(metadata), FormAreas.loanAppX)
			)
		);
	}

	selectArea$ = (area: FormAreas, index?: number): Observable<FormlyFieldConfig[] | undefined> => {
		this.areaLoadingSubject.next(true);

		const fullAppSideNavItems: SideNavItem[] = this.applicationDataStore.getValue().sideNavItems.fullApplication;
		const sectionsToShow = this.getAvailableSections(area, this.router.url.replace('/', ''), fullAppSideNavItems);

		return this.store.select(area).pipe(
			filter((val) => !!val),
			map((metadata: FormlyApiProperty) => {
				const clonedMetadata = cloneDeep(metadata);
				filterSectionsFromSchema(clonedMetadata, sectionsToShow);
				filterDigitalWidgetsFromSchema(clonedMetadata, sectionsToShow);
				return clonedMetadata;
			}),
			switchMap((metadata: FormlyApiProperty) => {
				this.insertCommonFields(metadata, area);
				return this.areaTransformerService.transformMetadata(metadata, area, index);
			}),
			tap(() => {
				this.appService.appSpinnerStatus$.next('');
				this.areaLoadingSubject.next(false);
			})
		);
	};

	selectAreaAndModel$ = (area: FormAreas, index?: number) => {
		this.areaLoadingSubject.next(true);
		return this.setupState(area, index).pipe(
			switchMap(() => this.selectArea$(area, index).pipe(take(1))),
			map((formFields) => {
				return {
					formFields,
					model: this.formDataSelectorService.model(area, formFields as FormlyFieldConfig[])
				};
			}),
			tap(() => {
				this.areaLoadingSubject.next(false);
			})
		);
	};

	hasArea = (area: FormAreas): boolean => {
		const enums = this.store.getValue() || {};

		return !!enums[area];
	};

	selectArea = (): FormMetaData => {
		return this.store.getValue();
	};

	selectAreaStore = (area: string): FormlyApiProperty | undefined => {
		return Object.entries(this.selectArea()).filter(
			(x) => x[0].toLowerCase() === area?.replace('-', '')?.toLowerCase()
		)?.[0] as FormlyApiProperty;
	};

	private insertCommonFields(metadata: FormlyApiProperty, area: FormAreas) {
		if (area === FormAreas.setup || !this.isLoanAppClientArea(area)) {
			return;
		}
		['relatedEntityModals', 'digitalServiceLabels'].forEach((fieldName) => {
			const setupMetadata: FormlyApiProperty | undefined = this.store.getValue().setup;
			const fieldToClone: FormlyApiProperty | undefined = setupMetadata?.properties?.find((x) => x.key === fieldName);
			if (fieldToClone) {
				metadata.properties?.push(cloneDeep(fieldToClone));
			}
		});
	}

	private isLoanAppClientArea(area: FormAreas) {
		return [
			FormAreas.applicants,
			FormAreas.financialPosition,
			FormAreas.trustApplicants,
			FormAreas.companyApplicants,
			FormAreas.companyFinancialPosition,
			FormAreas.trustFinancialPosition,
			FormAreas.loanServiceability,
			FormAreas.setup,
			FormAreas.compliance,
			FormAreas.serviceabilityScenarios
		].includes(area);
	}

	private setupState(nextFormArea: FormAreas, index?: number): Observable<boolean> {
		this.appService.appSpinnerStatus$.next(CONSTANTS.SYNCING);
		return this.syncStates(nextFormArea, index).pipe(
			map(() => {
				return true;
			})
		);
	}

	private syncStates(nextFormArea: FormAreas, index?: number) {
		switch (nextFormArea) {
			case FormAreas.setup: {
				const setupSchema = this.getStore(FormAreas.setup);
				return this.setupService.setupState$(setupSchema);
			}
			case FormAreas.applicants: {
				const applicantsSchema = this.getStore(FormAreas.applicants);
				return this.applicantsService.setupState$(applicantsSchema);
			}
			case FormAreas.financialPosition: {
				const schema = this.getStore(FormAreas.financialPosition);
				return this.financialPositionService.setupState$(schema);
			}
			case FormAreas.loanServiceability:
				return this.loanServiceabilityService.setupState$();
			case FormAreas.serviceabilityScenarios: {
				const serviceabilityScenariosSchema = this.getStore(FormAreas.serviceabilityScenarios);
				return this.serviceabilityScenariosService.setupState$(serviceabilityScenariosSchema);
			}
			case FormAreas.compliance: {
				const schema = this.getStore(FormAreas.compliance);
				return this.complianceService.setupState$(schema);
			}
			case FormAreas.complianceGuarantor:
				return this.complianceGuarantorService.setupState$();
			case FormAreas.companyApplicants:
				return this.companyApplicantService.setupState$(index);
			case FormAreas.companyFinancialPosition:
				return this.companyFinancialPositionService.setupState$(index);
			case FormAreas.trustApplicants:
				return this.trustApplicantService.setupState$(index);
			case FormAreas.trustFinancialPosition:
				return this.trustFinancialPositionService.setupState$(index);
			default:
				return of(true);
		}
	}
}
