import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { GuarantorInterviewCompletionDTO, GuarantorInterviewDTO } from '@app/modules/typings/api';
import { Observable, catchError, forkJoin, map, of, tap } from 'rxjs';

import { LoanDetailJourneyProducts } from '@app/modules/loan-serviceability/model/journey-product.model';
import { TransformerUtilService } from '@app/modules/shared/transformers/service/transformer-util.service';
import { ComplianceService } from '../compliance.service';
import { GuarantorAdditionalCommentTransformer } from '../model/guarantor-additional-comment.model';
import { GuarantorConfirmCompletedTransformer } from '../model/guarantor-confirmed.model';
import { GuarantorIndependentAdviceTransformer } from '../model/guarantor-independent-advice.model';
import {
	GuarantorInterviewCompletionModel,
	GuarantorInterviewCompletionTransformer
} from '../model/guarantor-interview-completion.model';
import {
	GuarantorInterviewSectionModel,
	GuarantorInterviewSectionTransformer
} from '../model/guarantor-interview-section.model';
import { GuarantorInterviewTransformer } from '../model/guarantor-interview.model';
import { GuarantorUnderstandingTransformer } from '../model/guarantor-understanding.model';
import { GuarantorDeliveryMethodTransfomer } from '../model/guarantors-delivery-method.model';
import { GuarantorEligibilityTransformer } from '../model/guarantors-eligibility.model';
import { GuarantorOpinionTransformer } from '../model/guarantors-opinion.model';
import { GuarantorInterviewDetailsTransformer } from '../model/guarrantor-interview-details.model';
import { SecurityGuaranteeTransformer } from '../model/security-guarantee.model';
import { ServicingGuaranteeTransformer } from '../model/servicing-guarantee.model';

@Injectable({ providedIn: 'root' })
export class ComplianceGuarantorService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private complianceService: ComplianceService,
		private transformerUtilService: TransformerUtilService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(): Observable<any> {
		return this.upsertGuarantorInterview();
	}

	fetchGuarantorInterviews(): Observable<GuarantorInterviewDTO[]> {
		return this.getCustom(`GuarantorInterview/${this.applicationDataQuery.applicationId()}`).pipe(
			map((interviews: GuarantorInterviewDTO[]) => interviews)
		);
	}

	fetchGuarantorInterviewCompletions(): Observable<GuarantorInterviewCompletionDTO[]> {
		return this.getCustom(`GuarantorInterview/Completion/${this.applicationDataQuery.applicationId()}`).pipe(
			map((interviews: GuarantorInterviewCompletionDTO[]) => interviews)
		);
	}

	upsertGuarantorInterview(): Observable<any> {
		const statePrefix = 'complianceguarantor';
		return forkJoin([
			this.fetchGuarantorInterviews(),
			this.fetchGuarantorInterviewCompletions().pipe(catchError(() => of([]))),
			this.complianceService.fetchAllProducts().pipe(catchError(() => of([])))
		]).pipe(
			tap(
				([interviews, completions, selectedProducts]: [
					GuarantorInterviewDTO[],
					GuarantorInterviewCompletionDTO[],
					LoanDetailJourneyProducts[]
				]) => {
					interviews.forEach((interview, index) => {
						if (!interview.loanProduct) {
							if (selectedProducts.length > 0) {
								interview.loanProduct = selectedProducts
									.map((product) => {
										const p = product.journeyProducts.map((j) => {
											return j.selected ? this.transformerUtilService.generateLoanProductEnumObject(j).label : {};
										});
										return p;
									})
									.reduce((acc, productName) => acc.concat(', ').concat(productName))
									.join('');
							}
						}

						this.simpFormlyHandlerService.upsertToStateWithData(`confirmCompleted-${index}`, [
							GuarantorConfirmCompletedTransformer.fromPayload(interview) ?? {}
						]);

						this.simpFormlyHandlerService.upsertToStateWithData(`interviewDetails-${index}`, [
							GuarantorInterviewDetailsTransformer.fromPayload(interview) ?? {}
						]);

						this.simpFormlyHandlerService.upsertToStateWithData(`interview-${index}`, [
							GuarantorInterviewTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`guarantorsOpinion-${index}`, [
							GuarantorOpinionTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`guarantorsEligibility-${index}`, [
							GuarantorEligibilityTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`securityGuarantee-${index}`, [
							SecurityGuaranteeTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`servicingGuarantee-${index}`, [
							ServicingGuaranteeTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`guarantorUnderstanding-${index}`, [
							GuarantorUnderstandingTransformer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`guarantorsDeliveryMethod-${index}`, [
							GuarantorDeliveryMethodTransfomer.fromPayload(interview) ?? {}
						]);
						this.simpFormlyHandlerService.upsertToStateWithData(`independentAdvice-${index}`, [
							GuarantorIndependentAdviceTransformer.fromPayload(interview) ?? {}
						]);

						this.simpFormlyHandlerService.upsertToStateWithData(`additionalComments-${index}`, [
							GuarantorAdditionalCommentTransformer.fromPayload(interview) ?? {}
						]);

						const completionDetails = completions?.find(
							(completion) => completion.lendingGuaranteeId === interview.lendingGuaranteeId
						);

						this.simpFormlyHandlerService.upsertToStateWithData(
							`interviewCompletion-${index}`,
							completionDetails
								? [GuarantorInterviewCompletionTransformer.fromPayload(completionDetails)]
								: [{ lendingGuaranteeId: interview.lendingGuaranteeId }]
						);
					});
				}
			)
		);
	}

	saveGuarantorInterview(model: GuarantorInterviewSectionModel, guarantorId: number): Observable<any> {
		return this.postCustom(
			`GuarantorInterview`,
			GuarantorInterviewSectionTransformer.toPayload(model, this.applicationDataQuery.applicationId(), guarantorId)
		);
	}

	saveCompletion(model: GuarantorInterviewCompletionModel): Observable<any> {
		return this.postCustom(
			`GuarantorInterview/Completion/${this.applicationDataQuery.applicationId()}/${model.lendingGuaranteeId}`,
			GuarantorInterviewCompletionTransformer.toPayload(model, this.applicationDataQuery.applicationId())
		);
	}
}
