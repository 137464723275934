import { TermType } from '@app/modules/shared/enums/app.enums';
import { RateAdjustmentsDTO } from '@app/modules/typings/api';

export class RateAdjustmentsTransformer {
	static fromPayload(rateAdjustmentDto: RateAdjustmentsDTO[], loanId: number): RateAdjustmentsModel {
		const rateAdjustments = this.fromPayloadRateAdjustment(rateAdjustmentDto);
		const defaultRateAdjustment = rateAdjustments.filter((ra) => ra.isCustomAdjustment === false);
		const customInitialRateAdjustment = rateAdjustments.filter(
			(ra) => ra.isCustomAdjustment === true && ra.termType === TermType.InitialTerm
		);
		const customSubSequentRateAdjustment = rateAdjustments.filter(
			(ra) => ra.isCustomAdjustment === true && ra.termType === TermType.SubsequentTerm
		);

		return {
			loanId,
			initialDefaultRateAdjustment: defaultRateAdjustment,
			initialCustomRateAdjustment: customInitialRateAdjustment,
			subSequentDefaultRateAdjustment: defaultRateAdjustment,
			subSequentCustomRateAdjustment: customSubSequentRateAdjustment
		} as RateAdjustmentsModel;
	}

	static fromPayloadRateAdjustment(rateAdjustment: RateAdjustmentsDTO[]): RateAdjustment[] {
		return rateAdjustment.map((adjustment) => ({ ...adjustment } as RateAdjustment));
	}

	static toPayLoad(rateAdjustments: RateAdjustment, isCustom = false): RateAdjustmentsDTO {
		return {
			id: rateAdjustments.id,
			featureCode: rateAdjustments.featureCode,
			adjustmentTitle: rateAdjustments.adjustmentTitle,
			featureOptionCode: rateAdjustments.featureOptionCode,
			adjustmentDetail: rateAdjustments.adjustmentDetail,
			loaderValue: rateAdjustments.loaderValue,
			allowedToAdjust: rateAdjustments.allowedToAdjust,
			allowedMaxAdjustmentValue: rateAdjustments.allowedMaxAdjustmentValue,
			allowedMinAdjustmentValue: rateAdjustments.allowedMinAdjustmentValue,
			allowedToBeDeleted: rateAdjustments.allowedToBeDeleted,
			isCustomAdjustment: isCustom,
			termType: rateAdjustments.termType
		} as RateAdjustmentsDTO;
	}
}

export interface RateAdjustmentsModel {
	loanId: number;
	initialDefaultRateAdjustment?: RateAdjustment[];
	initialCustomRateAdjustment?: RateAdjustment[];
	subSequentDefaultRateAdjustment?: RateAdjustment[];
	subSequentCustomRateAdjustment?: RateAdjustment[];
	isProductSelected?: boolean; // UI Purpose only
	isHideSubTerm?: boolean; // UI Purpose only
	isLoadersEmpty?: boolean; // UI Purpose only
}

export interface RateAdjustment {
	id: number;
	featureCode: number;
	adjustmentTitle?: string;
	featureOptionCode: number;
	adjustmentDetail?: string;
	loaderValue: number;
	allowedToAdjust: boolean;
	allowedMaxAdjustmentValue?: number;
	allowedMinAdjustmentValue?: number;
	allowedToBeDeleted: boolean;
	isCustomAdjustment: boolean;
	termType: TermType;
}

export interface CustomLoadersModel {
	id: number;
	featureCode: number;
	featureOptionCode: number;
}

export interface CustomLoaders {
	id: number;
	featureCode: number;
	adjustmentTitle: string;
	featureOptionCode: number;
	adjustmentDetail: string;
	loaderValue: number;
	allowedToAdjust: boolean;
	allowedMaxAdjustmentValue: boolean;
	allowedMinAdjustmentValue: boolean;
	allowedToBeDeleted: boolean;
	isCustomAdjustment: boolean;
	termType: TermType;
}
