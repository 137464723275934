import { Injectable } from '@angular/core';
import { PropertyService } from '@app/modules/property/services/property.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { formatPlural } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { CreditIssueModel, CreditIssueTransformer } from '@app/modules/shared/model/credit-issue.model';
import { WarningIssueModel } from '@app/modules/shared/model/warning-issue-model';
import { AssetsAndLiabilitiesService } from '@app/modules/shared/service/assets-liabilities.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { AccountantDTO, CompanyTrustIncomesDTO, CreditStatusDTO, WarningIssueDTO } from '@app/modules/typings/api';
import { sumBy } from 'lodash-es';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { AccountantModel } from '../../model/company-trust-accountant.model';

import {
	ForeignTaxAssociationModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { ForeignTaxAssociationService } from '@app/modules/shared/service/foreign-tax-association.service';
import { CompanyTrustIncome, CompanyTrustIncomeTransformer } from '../../model/company-trust-income.model';

@Injectable({
	providedIn: 'root'
})
export class CompanyFinancialPositionService extends BaseJourneyService {
	constructor(
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private propertyService: PropertyService,
		private foreignTaxAssociationService: ForeignTaxAssociationService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(index?: number): Observable<unknown> {
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		const companyApplicants = applicants.filter(
			(applicant) => applicant.applicantEntityType === ApplicantEntityType.CompanyApplicant
		);
		this.getRelatedCompanies();
		if (index !== undefined) {
			const companyApplicant = companyApplicants[index];
			return forkJoin([
				this.fetchCompanyCreditStatus(companyApplicant),
				this.fetchCompanyIncomeDetails(companyApplicant),
				this.fetchForeignTaxAssociation(companyApplicant),
				this.fetchCompanyAccountant(companyApplicant),
				this.assetsAndLiabilitiesService.fetchSavings(),
				this.assetsAndLiabilitiesService.fetchOtherAssets(),
				this.assetsAndLiabilitiesService.fetchAccessSeeker(),
				this.assetsAndLiabilitiesService.fetchCreditCards(),
				this.assetsAndLiabilitiesService.fetchPersonalLoans(),
				this.assetsAndLiabilitiesService.fetchOtherLiabilities(),
				this.propertyService.syncPropertiesAndSecurities$()
			]).pipe(
				map(
					([
						creditStatus,
						incomeDetails,
						foreignTaxAssociation,
						accountant,
						savings,
						otherAssets,
						accessSeekerLiabilities,
						creditCards,
						personalLoans,
						otherLiabilities
					]) => {
						this.simpFormlyHandlerService.upsertToStateWithData('creditStatus', creditStatus);
						this.simpFormlyHandlerService.upsertToStateWithData('foreignTaxAssociation', [foreignTaxAssociation]);
						this.simpFormlyHandlerService.upsertToStateWithData('incomeDetails', incomeDetails);
						this.simpFormlyHandlerService.upsertToStateWithData('accountant', accountant);
						this.simpFormlyHandlerService.upsertToStateWithData('savings', savings);
						this.simpFormlyHandlerService.upsertToStateWithData('otherAssets', otherAssets);
						this.simpFormlyHandlerService.upsertToStateWithData('accessSeekerLiabilities', accessSeekerLiabilities);
						this.simpFormlyHandlerService.upsertToStateWithData('creditCards', creditCards);
						this.simpFormlyHandlerService.upsertToStateWithData('personalLoans', personalLoans);
						this.simpFormlyHandlerService.upsertToStateWithData('otherLiabilities', otherLiabilities);
					}
				)
			);
		}
		return of(true);
	}

	fetchForeignTaxAssociation(companyApplicant: ShortApplicant): Observable<ForeignTaxAssociationModel> {
		if (!companyApplicant.foreignTaxAssociationId) {
			return of({} as ForeignTaxAssociationModel);
		}
		return this.foreignTaxAssociationService
			.getForeignTaxAssociations(companyApplicant.foreignTaxAssociationId)
			.pipe(map((res) => ForeignTaxAssociationTransformer.fromPayload(res, companyApplicant)));
	}

	getSummaryData() {
		const companyApplicants = this.applicationDataQuery.getCompanyApplicants();
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		return forkJoin(
			companyApplicants.map((companyApplicant) =>
				forkJoin({
					creditStatus: this.fetchCompanyCreditStatus(companyApplicant).pipe(map((d) => d[0])),
					foreignTaxAssociation: this.fetchForeignTaxAssociation(companyApplicant),
					incomeDetails: this.fetchCompanyIncomeDetails(companyApplicant).pipe(map((d) => d[0])),
					accountant: this.fetchCompanyAccountant(companyApplicant).pipe(
						map((d) => ({ accountantName: (d[0] as AccountantModel).existingSelect?.companyName }))
					),
					liabilities: forkJoin({
						homeloans: this.propertyService.fetchHomeLoans(),
						accessSeeker: this.assetsAndLiabilitiesService.fetchAccessSeeker(),
						creditCards: this.assetsAndLiabilitiesService.fetchCreditCards(),
						personalLoans: this.assetsAndLiabilitiesService.fetchPersonalLoans(),
						otherLiabilities: this.assetsAndLiabilitiesService.fetchOtherLiabilities()
					}).pipe(
						map((res) => {
							let totalPool = sumBy(res.homeloans, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.creditCards, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.personalLoans, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.otherLiabilities, (value) => value.summaryAmount || 0);

							const itemsEntered = [];
							itemsEntered.push(
								res.creditCards.length
									? `${res.creditCards.length} ${formatPlural(res.creditCards.length, 'credit card')}`
									: ''
							);
							itemsEntered.push(
								res.personalLoans.length
									? `${res.personalLoans.length} ${formatPlural(res.personalLoans.length, 'personal loan')}`
									: ''
							);
							itemsEntered.push(
								res.otherLiabilities.length
									? `${res.otherLiabilities.length} ${formatPlural(
											res.otherLiabilities.length,
											'other liability',
											'other liabilities'
									  )}`
									: ''
							);

							return {
								totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
								itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
							};
						})
					),
					assets: forkJoin({
						realEstate: this.propertyService.fetchProperties(),
						savings: this.assetsAndLiabilitiesService.fetchSavings(),
						otherAssets: this.assetsAndLiabilitiesService.fetchOtherAssets(applicants)
					}).pipe(
						map((res) => {
							let totalPool = sumBy(res.realEstate, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.savings, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.otherAssets, (value) => value.summaryAmount || 0);

							const itemsEntered = [];
							itemsEntered.push(
								res.savings.length ? `${res.savings.length} ${formatPlural(res.savings.length, 'saving')}` : ''
							);
							itemsEntered.push(
								res.otherAssets.length
									? `${res.otherAssets.length} ${formatPlural(res.otherAssets.length, 'other asset')}`
									: ''
							);

							return {
								totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
								itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
							};
						})
					)
				})
			)
		).pipe(
			map((res) =>
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				Object.assign(
					{},
					...res.map((applicant, index) => {
						const key = `companyApplicant${index}`;
						return {
							[key]: [applicant]
						};
					})
				)
			)
		);
	}

	getRelatedCompanies() {
		this.personsCompaniesEnumService.getRelatedCompanies();
	}

	saveCompanyCreditStatus(model: CreditStatusDTO): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`CompanyApplicant/CreditStatus/${model.applicationId}/${model.applicantId}/${model.creditStatus}`,
				model
			)
		);
	}

	saveCompanyCreditIssues(model: WarningIssueDTO, companyApplicantId: number): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`CompanyApplicant/CreditStatusIssue/${this.applicationDataQuery.applicationId()}/${companyApplicantId}`,
				model
			)
		);
	}

	saveCompanyAccountant(payload: AccountantDTO): Observable<AccountantDTO> {
		return <Observable<AccountantDTO>>this.postCustom('CompanyApplicant/Accountant', payload);
	}

	saveCompanyIncome(payload: CompanyTrustIncomesDTO, companyApplicantID: number): Observable<void> {
		return <Observable<void>>(
			this.postCustom(
				`CompanyApplicant/Income/${this.applicationDataQuery.applicationId()}/${companyApplicantID}`,
				payload
			)
		);
	}

	deleteWarningIssues(model: WarningIssueModel, applicantId: any): Observable<boolean> {
		if (model?.id) {
			return this.delete(
				`CompanyApplicant/CreditStatusIssue/${this.applicationDataQuery.applicationId()}/${applicantId}/${model.id}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}
	private fetchCompanyAccountant(companyApplicant: ShortApplicant): Observable<AccountantModel[] | {}[]> {
		return forkJoin([
			this.getCustom(`CompanyApplicant/Accountant/${this.applicationDataQuery.applicationId()}/${companyApplicant.id}`),
			this.personsCompaniesEnumService.fetchCompanies()
		]).pipe(
			map(([accountantDTO, companies]: [AccountantDTO, ApplicantEnumObject[]]) => [
				{
					existingSelect: companies.find((company) => company.id === accountantDTO.accountantId)
				} as AccountantModel
			]),
			catchError(() => of([{}]))
		);
	}

	private fetchCompanyIncomeDetails(companyApplicant: ShortApplicant): Observable<CompanyTrustIncome[] | {}[]> {
		return this.getCustom(
			`CompanyApplicant/Income/${this.applicationDataQuery.applicationId()}/${companyApplicant.id}`
		).pipe(
			map((res: CompanyTrustIncomesDTO) => [CompanyTrustIncomeTransformer.fromPayload(res)]),
			catchError(() => of([{}]))
		);
	}

	private fetchCompanyCreditStatus(companyApplicant: ShortApplicant): Observable<CreditIssueModel[]> {
		return this.getCustom(
			`CompanyApplicant/CreditStatus/${this.applicationDataQuery.applicationId()}/${companyApplicant.id}`
		).pipe(map((res: CreditStatusDTO) => [CreditIssueTransformer.fromPayload(res, companyApplicant)]));
	}
}
