import { transformSchemaToFormlyFields } from '@app/modules/shared/transformers/area-transformer.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { JSONSchema7 } from 'json-schema';
import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlySection = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	childProperties = childProperties ?? {};

	let header: FormlyFieldConfig | undefined = undefined;
	Object.keys(childProperties).forEach((key) => {
		if (childProperties && childProperties[key] && (childProperties[key] as JsonSchemaProperty).type === 'header') {
			const headerSchema = childProperties[key] as JSONSchema7;
			headerSchema.type = 'object';
			header = transformSchemaToFormlyFields(headerSchema);
			delete childProperties[key];
		}
	});

	return {
		type: 'object',
		title: property.title,
		widget: {
			formlyConfig: {
				type: 'section',
				className: (property.templateOptions?.class as string) ?? property.class,
				templateOptions: {
					...property.templateOptions,
					header
				},
				hideExpression: parseExpressionString(property.hide),
				resetOnHide: !!property.templateOptions?.resetOnHide,
				expressionProperties: parseExpressionProperties(property)
			}
		},
		required: property.required,
		properties: childProperties
	};
};
