import { formatCurrency } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ExpenseCategory, JourneyType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly } from '@app/modules/shared/helper/util';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { NavigationService } from '@app/modules/shared/service/navigation.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormStore } from '@app/modules/shared/store/form-data/model/form-data.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import {
	formlyAddCustomValidator,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { DeclaredExpenseDTO, Household } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CalculationHelper, EnumObject } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import { Observable, Subject, map, of, switchMap, take, takeUntil } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiExpenseCategory, RefiExpensesModel, RefiHousehold } from '../../models/refi-expenses.model';
import { RefiJourneyService } from '../refi-journey.service';
import { HEMRangeResponse, RefiExpensesService } from './refi-expenses.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyExpensesTransformerService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private totalExpenses = 0;
	isBackButtonClicked = false;
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private refiJourneyService: RefiJourneyService,
		private refiExpensesService: RefiExpensesService,
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private journeyStepService: JourneyStepService,
		private formEnumsService: FormEnumsService,
		private refiModalService: RefiModalService,
		private formDataService: FormDataService,
		private navigationService: NavigationService,
		private sharedFlagService: SharedFlagsService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public expensesTransform(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(formFields, 'expenses.details.household', (field) => {
			const households = this.formEnumsQuery.getOptions('Households');
			const model = field.model as RefiHousehold;
			model.id = households.length ? households[0].id : CONSTANTS.NEW_ID;
			model.applicationId = this.applicationDataQuery.applicationId();
			this.refiExpensesService.saveHousehold(model).subscribe((id) => {
				model.id = id;
				this.formDataService.updateRefiHouseholdDetails(model);
				if (field.parent?.parent) {
					this.saveExpenses(field.parent.parent)
						.pipe(switchMap(() => this.refiExpensesService.fetchHEMRangeSummary()))
						.subscribe((res) => {
							const currentState = this.formDataService.getStateData(
								'refi-expenses-details'
							) as unknown as RefiExpensesModel[];
							if (currentState[0]?.HEMRanges) {
								currentState[0].HEMRanges = res.expenseRangesByCategory;
								currentState[0].totalHEMCeiling = res.totalCeiling;
								currentState[0].totalHEMFloor = res.totalFloor;
								this.simpFormlyHandlerService.updateToFullStatePath('refi-expenses-details', currentState[0], 0);
							}
						});
				}
			});
		});

		formlyRegisterHooks(formFields, 'expenses.details.household.numberOfAdults', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const options: EnumObject[] = [];
					for (let i = 1; i <= parseInt(field.templateOptions.options as unknown as string); i++) {
						options.push({ id: i, label: i.toString() });
					}
					field.templateOptions.options = options;
				}
			}
		});

		formlyRegisterHooks(formFields, 'expenses.details.household.numberOfDependants', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const options: EnumObject[] = [];
					for (let i = 0; i <= parseInt(field.templateOptions.options as unknown as string); i++) {
						options.push({ id: i, label: i.toString() });
					}
					field.templateOptions.options = options;
				}
			}
		});

		formlyExtendExpressionProperties(formFields, 'expenses.details.summaryAmount', {
			'templateOptions.label': (model: RefiExpensesModel, formState, field: FormlyFieldConfig) => {
				const summary: number[] = [];
				Object.values(model).forEach((item) => {
					const expenseCategory = item as RefiExpenseCategory;
					if (expenseCategory && expenseCategory.amount) {
						summary.push(calculateMonthly(expenseCategory.amount, expenseCategory.frequency));
					}
				});

				this.totalExpenses = summary.reduce((x, y) => x + y, 0);
				if (this.totalExpenses > 0) {
					field.className = '';
					model.summaryAmount = `<span class="simp-text--bold">${formatCurrency(
						this.totalExpenses,
						'en-AU',
						'$'
					)}</span> per month`;
					return model.summaryAmount;
				} else {
					field.className = 'hidden';
					return '';
				}
			}
		});

		formlyOnClick(
			formFields,
			'expenses.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				if (clickType.type === 'primary' && this.refiJourneyService.isAdminUser) {
					this.refiJourneyService.navigateToNextStep(field);
					return;
				}
				if (clickType.type === 'primary' && !clickType.invalid) {
					const model = (
						this.formDataService.getState('refi-expenses-details').getValue() as FormStore<RefiExpensesModel>
					).data[0];

					const annualTotalCeiling = model.totalHEMCeiling as number;
					const annualTotalFloor = model.totalHEMFloor as number;

					const annuaExpensesTotal = this.buildExpensesModel(field)
						.map((expense) => CalculationHelper.CalculateAnnualAmount(expense.amount, expense.frequency))
						.reduce((total, next) => (total += next));

					const modalToOpen =
						annuaExpensesTotal > annualTotalCeiling
							? RefiModalName.ReviewExpenses
							: annuaExpensesTotal < annualTotalFloor
							? RefiModalName.ExpensesLow
							: null;

					if (modalToOpen) {
						this.openRefiModal(formFields, modalToOpen).subscribe((action) => {
							if (action === 'secondaryButton') {
								this.saveExpensesAndNavigateNextStep(field, clickType.changed);
							}
						});
					} else {
						this.saveExpensesAndNavigateNextStep(field, clickType.changed);
					}
				}
			}
		);

		this.formEnumsService
			.fetchEnums(['LivingExpenseCategory', 'OtherCommitmentCategory'])
			.subscribe((expenseEnums: EnumObject[][]) => {
				const otherCommitmentCategory = expenseEnums[1].map((item) => {
					return { ...item, id: item.id + 2000 }; // Add 2000 to OtherCommitmentCategory enums to differentiate from LivingExpenseCategory
				});
				const expenses = [...expenseEnums[0], ...otherCommitmentCategory];
				expenses.forEach((expense) => {
					formlyExtendExpressionProperties(formFields, `expenses.details.expenseCategory-${expense.id}`, {
						'templateOptions.label': (model, formState: any, field: FormlyFieldConfig) => {
							return expense.label;
						}
					});

					formlyRegisterHooks(formFields, `expenses.details.expenseCategory-${expense.id}`, {
						onInit: (field) => {
							if (field && field.templateOptions) {
								if (expense.id === ExpenseCategory.OtherCommitmentOther) {
									field.templateOptions.tooltipAsModal = true;
								}
								field.templateOptions.label = expense.label;
								field.hide = false;
							}
							return of();
						}
					});

					formlyRegisterHooks(formFields, `expenses.details.preText-${expense.id}`, {
						onInit: (field) => {
							if (field && field.templateOptions) {
								field.hide = false;
							}
							return of();
						}
					});

					formlyExtendExpressionProperties(formFields, `expenses.details.preText-${expense.id}`, {
						template: (model: RefiExpensesModel, formState, field: FormlyFieldConfig) => {
							const HEMRanges = model.HEMRanges as HEMRangeResponse[];
							let templateText = `${field?.template}`;
							const labelText = `<div class="refi-expense-category-label">${expense.label}</div>`;
							let HEMMessage = getFormField(field.parent?.fieldGroup, 'HEMRanges')?.template;
							if (HEMRanges && HEMMessage) {
								const HEMrangeForId = HEMRanges.find((range) => range.categoryId === expense.id);
								if (templateText && HEMrangeForId) {
									HEMMessage = HEMMessage.replace(
										'HEMRangeFloor',
										formatCurrency(HEMrangeForId.floorAmount, 'en-AU', '$', undefined, '1.0-0')
									);
									HEMMessage = HEMMessage.replace(
										'HEMRangeCeil',
										formatCurrency(HEMrangeForId.ceilingAmount, 'en-AU', '$', undefined, '1.0-0')
									);
									templateText = templateText?.split('<br>')[0] + '<br>' + HEMMessage;
								} else {
									templateText = templateText?.split('<br>')[0];
								}
							}
							return templateText?.includes(labelText) ? templateText : labelText + templateText;
						}
					});

					formlyOnClick(
						formFields,
						`expenses.details.expenseCategory-${expense.id}`,
						(field, clickType: { type: string }) => {
							if (clickType.type === 'tooltipClick') {
								this.openExpensesModal(formFields, expense.id);
							}
						}
					);
				});
			});

		formlyRegisterHooks(formFields, 'expenses.details', {
			onInit: (field) => {
				this.replaceMoneyGoingOutText(formFields);
				this.openRefiModal(formFields, RefiModalName.MoneyGoingOut).subscribe();
				this.navigationService.mobileBackButtonClickedPrehook$
					.pipe(
						take(1),
						switchMap((currrentRoute) => {
							if (currrentRoute === 'expenses/details') {
								return this.saveExpenses(field);
							}
							return of();
						})
					)
					.subscribe();
			}
		});

		//Custom validators below for Refi and purchase
		//Required is always conditional
		formlyAddCustomValidator(formFields, 'expenses.details.expenseCategory-1015.amount', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const model = c.parent?.parent?.value as RefiExpensesModel;
					const rent = (model?.['expenseCategory-3004'] as AmountSelect)?.amount;
					const ownerOccupied = model?.ownerOccupied as boolean;
					return (rent > 0 || ownerOccupied) && !c.value ? false : true;
				}
			}
		});

		formlyAddCustomValidator(formFields, 'expenses.details.expenseCategory-1001.amount', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const model = c.parent?.parent?.value as RefiExpensesModel;
					const numberOfDependants = (model?.household as Household)?.numberOfDependants as number;
					return numberOfDependants === 0 || (numberOfDependants > 0 && c.value > 0) ? true : false;
				}
			}
		});

		formlyAddCustomValidator(formFields, 'expenses.details.expenseCategory-3003.amount', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const model = c.parent?.parent?.value as RefiExpensesModel;
					const tertiaryEducation = (model?.['expenseCategory-1017'] as AmountSelect)?.amount;
					const schoolAgeDependants = (model?.household as Household)?.hasSchoolAgeDependants;
					if (schoolAgeDependants) {
						return !c.errors?.required && (c.value > 0 || tertiaryEducation > 0) ? true : false;
					} else {
						return true;
					}
				}
			}
		});

		formlyAddCustomValidator(formFields, 'expenses.details.expenseCategory-1017.amount', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const model = c.parent?.parent?.value as RefiExpensesModel;
					const additionalExpenses = (model?.['expenseCategory-3003'] as AmountSelect)?.amount;
					const schoolAgeDependants = (model?.household as Household)?.hasSchoolAgeDependants;
					if (schoolAgeDependants) {
						return !c.errors?.required && (c.value > 0 || additionalExpenses > 0) ? true : false;
					} else {
						return true;
					}
				}
			}
		});

		//Added this custom validator only if it is purchase
		//because Refi required is always set to static true
		//But purchase required is conditional from schema
		if (this.sharedFlagService.currentJourney === JourneyType.Purchase) {
			formlyAddCustomValidator(formFields, 'expenses.details.expenseCategory-1006.amount', {
				invalid: {
					expression: (c: UntypedFormArray) => {
						const model = c.parent?.parent?.value as RefiExpensesModel;
						const insuranceRequired = model?.insuranceRequired as boolean;
						return (insuranceRequired && c.value > 0) || !insuranceRequired ? true : false;
					}
				}
			});
		}
	}

	private saveExpensesAndNavigateNextStep(field: FormlyFieldConfig, isSectionChanged: boolean): void {
		if (!isSectionChanged) {
			this.refiJourneyService.navigateToNextStep(field);
		}
		this.saveExpenses(field, true).subscribe(() => {
			this.refiJourneyService.navigateToNextStep(field);
		});
	}

	private saveExpenses(field: FormlyFieldConfig, stepComplete = false): Observable<void | null> {
		const data = this.buildExpensesModel(field);
		if (!data.length) {
			return of(null);
		}
		return this.refiExpensesService.saveExpense(data).pipe(
			switchMap(() => {
				return this.refiExpensesService.fetchHouseholds().pipe(
					map((res) => {
						// literal path used to accomodate handling save on back button click
						this.simpFormlyHandlerService.upsertToFullPathWithData('refi-expenses-details', res);
					}),
					switchMap(() => {
						if (stepComplete) {
							return this.journeyStepService.updateStep(RefiStepType.RefiExpenses, StepStatus.Complete);
						} else {
							return of();
						}
					})
				);
			}),
			takeUntil(this.destroy$)
		);
	}

	private buildExpensesModel(field: FormlyFieldConfig): DeclaredExpenseDTO[] {
		const households = this.formEnumsQuery.getOptions('Households');
		const livingExpenseCategory = this.formEnumsQuery.getOptions('LivingExpenseCategory');
		const otherCommitmentCategory = this.formEnumsQuery.getOptions('OtherCommitmentCategory').map((item) => {
			return { ...item, id: item.id + 2000 }; // Add 2000 to OtherCommitmentCategory enums to differentiate from LivingExpenseCategory
		});
		const fullExpenseCategories = [...livingExpenseCategory, ...otherCommitmentCategory];
		const data: DeclaredExpenseDTO[] = [];
		fullExpenseCategories.forEach((expense) => {
			const expenseField = getFormField(field.fieldGroup, `0.expenseCategory-${expense.id}`);
			const model = get(expenseField, 'model') as RefiExpenseCategory;
			if (model.amount !== null && model.amount !== undefined) {
				data.push({
					applicationId: this.applicationDataQuery.applicationId(),
					expenseCategory: expense.id,
					householdId: households.length ? households[0].id : CONSTANTS.NEW_ID,
					...model
				} as DeclaredExpenseDTO);
			}
		});
		return data;
	}

	private replaceMoneyGoingOutText(formFields: FormlyFieldConfig[]): void {
		const mgo = getFormField(formFields, 'expenses.refi-modals.moneyGoingOutLabels');
		if (mgo) {
			const textBlock2 = getFormField(mgo.fieldGroup, 'textBlock2');
			if (this.applicationDataQuery.getRefiApplicants().length > 1) {
				if (textBlock2?.templateOptions) {
					textBlock2.templateOptions.label = textBlock2.templateOptions.label?.replace('orChanged', 'or changed');
				}
			} else {
				if (textBlock2?.templateOptions) {
					textBlock2.templateOptions.label = textBlock2.templateOptions.label?.replace('orChanged', '');
				}
				const textBlock3 = getFormField(mgo.fieldGroup, 'textBlock3');
				if (textBlock3?.templateOptions) {
					textBlock3.templateOptions.label = '';
				}
			}
		}
	}

	private openExpensesModal(formFields: FormlyFieldConfig[], id: number): void {
		switch (id) {
			case ExpenseCategory.Groceries:
				this.openRefiModal(formFields, RefiModalName.FoodGroceries).subscribe();
				break;
			case ExpenseCategory.PrimaryResidenceRunningCosts:
				this.openRefiModal(formFields, RefiModalName.Utilities).subscribe();
				break;
			case ExpenseCategory.TelephoneInternetPayTV:
				this.openRefiModal(formFields, RefiModalName.Communication).subscribe();
				break;
			case ExpenseCategory.PublicOrGovernmentPrimaryandSecondaryEducation:
				this.openRefiModal(formFields, RefiModalName.Education).subscribe();
				break;
			case ExpenseCategory.ClothingAndPersonalCare:
				this.openRefiModal(formFields, RefiModalName.Clothing).subscribe();
				break;
			case ExpenseCategory.Transport:
				this.openRefiModal(formFields, RefiModalName.Transport).subscribe();
				break;
			case ExpenseCategory.MedicalAndHealth:
				this.openRefiModal(formFields, RefiModalName.Medical).subscribe();
				break;
			case ExpenseCategory.HealthInsurance:
				this.openRefiModal(formFields, RefiModalName.Insurance).subscribe();
				break;
			case ExpenseCategory.RecreationAndEntertainment:
				this.openRefiModal(formFields, RefiModalName.Recreation).subscribe();
				break;
			case ExpenseCategory.Childcare:
				this.openRefiModal(formFields, RefiModalName.Children).subscribe();
				break;
			case ExpenseCategory.Rent:
				this.openRefiModal(formFields, RefiModalName.RentOrBoard).subscribe();
				break;
			case ExpenseCategory.Other:
				this.openRefiModal(formFields, RefiModalName.Expenses).subscribe();
				break;
			case ExpenseCategory.OtherCommitmentOther:
				this.openRefiModal(formFields, RefiModalName.OtherExpenses).subscribe();
				break;
			default:
				break;
		}
	}

	private openRefiModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): Observable<string> {
		const refiModalField = getFormField(formFields, 'expenses.refi-modals');
		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}
}
