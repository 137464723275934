import { ApplicantType, Proportions } from '@app/modules/shared/enums/app.enums';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { LoanInformationDTO, PercentOwned, SplitLoanDTO } from '@app/modules/typings/api';
import { BorrowingInfo } from './borrowing.model';
import { JourneyProduct } from './journey-product.model';
import { Product } from './product.model';
import { RateToBorrower } from './rate-to-borrower.model';

export class LoanInformationTransformer {
	static fromPayload(loanInformation: LoanInformationDTO, applicants: ShortApplicant[]): LoanInformation {
		const percentsOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			loanInformation.percentsOwned,
			applicants,
			[ApplicantType.Borrower, ApplicantType.BorrowerandGuarantor]
		);

		const loanInfo: LoanInformation = {
			loanDetailId: this.mapMainLoanId(loanInformation?.splitLoans),
			amountRequested: this.calculateAmountRequested(loanInformation?.splitLoans),
			estimatedSettlementDate: loanInformation.estimatedSettlementDate,
			percentsOwned: percentsOwned,
			isEqualShare: loanInformation.percentOwnedProportions === Proportions.Equal ? true : false,
			isSplitLoan: this.hasSplitLoans(loanInformation?.splitLoans),
			borrowingAndProductInfo: []
		};

		if (loanInfo.isSplitLoan) {
			loanInfo.loanSplits = this.mapLoanSplit(loanInformation?.splitLoans);
		}

		return loanInfo;
	}

	static toPayload(loanInformation: LoanInformation): LoanInformationDTO {
		const loanInformationDto: LoanInformationDTO = {
			estimatedSettlementDate: loanInformation.estimatedSettlementDate,
			percentsOwned:
				loanInformation.percentsOwned?.map((percentOwned) => ({
					...percentOwned,
					percent: Number(percentOwned.percent)
				})) ?? [],
			percentOwnedProportions: loanInformation.isEqualShare ? Proportions.Equal : Proportions.Specified,
			splitLoans: this.toPayloadSplitLoans(loanInformation)
		};

		return loanInformationDto;
	}

	private static mapMainLoanId(splitLoanDto: SplitLoanDTO[] | undefined): number {
		return splitLoanDto?.[0].loanDetailsId ?? -1;
	}

	private static hasSplitLoans(splitLoanDto: SplitLoanDTO[] | undefined): boolean {
		return !!(splitLoanDto && splitLoanDto.length > 1);
	}

	private static calculateAmountRequested(splitLoanDto: SplitLoanDTO[] | undefined): number {
		let totalBorrowingAmount = 0;
		splitLoanDto?.forEach((loanAmount) => {
			totalBorrowingAmount += loanAmount.amount ?? 0;
		});
		return totalBorrowingAmount;
	}

	private static mapLoanSplit(splitLoanDto: SplitLoanDTO[] | undefined): LoanSplit[] {
		return (splitLoanDto ?? []).map(
			(splitLoan) =>
				({
					loanDetailId: splitLoan.loanDetailsId,
					loanSplit: {
						splitAmount: splitLoan.amount
					}
				} as LoanSplit)
		);
	}

	private static toPayloadSplitLoans(loanInformation: LoanInformation): SplitLoanDTO[] {
		let splitLoanDto: SplitLoanDTO[];
		if (loanInformation.isSplitLoan) {
			splitLoanDto =
				loanInformation.loanSplits?.map((split) => ({
					loanDetailsId: split.loanDetailId as number,
					amount: split.loanSplit?.splitAmount ?? 0
				})) ?? [];
		} else {
			splitLoanDto = [
				{
					loanDetailsId: loanInformation.loanDetailId as number,
					amount: loanInformation.amountRequested ?? 0
				}
			];
		}
		return splitLoanDto;
	}
}

export interface LoanInformation {
	loanDetailId?: number; // loan detailed Id when it is not a split loan.
	amountRequested?: number;
	amountRequestedWithCapitalisedFees?: number;
	estimatedSettlementDate?: string;
	percentsOwned?: PercentOwned[];
	isSplitLoan?: boolean;
	loanSplits?: LoanSplit[];
	borrowingAndProductInfo: BorrowingAndProductInfo[];
	isEqualShare?: boolean;
}

export interface LoanSplit {
	loanSplit?: {
		splitAmount?: number;
		splitAmountWithCapitalisedFees?: number;
	};
	loanDetailId?: number;
}

export interface BorrowingAndProductInfo {
	borrowing: BorrowingInfo;
	product: Product;
	rateToBorrower: RateToBorrower;
	selectedProduct?: JourneyProduct;
}
