import { Injectable } from '@angular/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';
import { CONSTANTS } from '../constants/constants';
import { JourneySetupMultipleSimultaneousSessions } from '../model/journey-setting.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ApplicationDataService } from '../store/application-data/application-data.service';
import { BaseJourneyService } from './base-journey.service';
import { JourneySettingService } from './journey-setting.service';

@Injectable({ providedIn: 'root' })
export class ActiveSessionService extends BaseJourneyService<any> {
	private multipleSessionConfig = JourneySetupMultipleSimultaneousSessions.Enabled;
	constructor(
		private confirmationDialogService: SimpConfirmationDialogService,
		private applicationDataQuery: ApplicationDataQuery,
		private journeySettingsService: JourneySettingService,
		private applicationDataService: ApplicationDataService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.APPLICATION);
	}

	checkIfUserCanEdit(applicationId?: number): void {
		this.journeySettingsService.fetchJourneySetting().subscribe((res) => {
			this.multipleSessionConfig = res.multipleSimultaneousSessions;
			if (!applicationId) {
				applicationId = this.applicationDataQuery.applicationId();
			}
			this.isActiveSession(applicationId).subscribe((response) => {
				if (this.multipleSessionConfig === JourneySetupMultipleSimultaneousSessions.Blocked) {
					if (!response.available) {
						if (!response.isHeldByCurrentUser) {
							this.blockUser();
						}
					}
					this.blockUser();
				}

				if (this.multipleSessionConfig === JourneySetupMultipleSimultaneousSessions.BlockedWithOverride) {
					if (!response.available) {
						if (!response.isHeldByCurrentUser) {
							this.blockUser();
						} else {
							this.allowOverride(applicationId);
						}
					}
				}
			});
		});
	}

	checkToShowEditWarning(applicationId?: number): void {
		this.journeySettingsService.fetchJourneySetting().subscribe((res) => {
			this.multipleSessionConfig = res.multipleSimultaneousSessions;
			if (!applicationId) {
				applicationId = this.applicationDataQuery.applicationId();
			}
			if (applicationId !== CONSTANTS.NEW_ID) {
				this.isActiveSession(applicationId).subscribe((response) => {
					if (this.multipleSessionConfig === JourneySetupMultipleSimultaneousSessions.WithWarning) {
						if (!response.available) {
							this.warnUser();
						}
					}
				});
			}
		});
	}

	deleteBrowserSession(applicationId: number): void {
		this.releaseActiveSession(applicationId).subscribe();
	}

	createFormInstanceId() {
		if (!sessionStorage.getItem(CONSTANTS.FORM_INSTANCE_ID)) {
			sessionStorage.setItem(CONSTANTS.FORM_INSTANCE_ID, Guid.raw());
		}
	}

	forceClaimSession(applicationId: number): Observable<boolean> {
		return <Observable<boolean>>this.postCustom(`/ForceClaimActiveSession/${applicationId}`, {});
	}

	releaseActiveSession(applicationId: number): Observable<boolean> {
		return <Observable<boolean>>(
			this.delete(
				`/${URL_CONSTANTS.RELEASE_ACTIVE_SESSION}/${applicationId}/${sessionStorage.getItem(
					CONSTANTS.FORM_INSTANCE_ID
				)}`
			)
		);
	}

	private isActiveSession(applicationId: number): Observable<ActiveSessionResponse> {
		return <Observable<ActiveSessionResponse>>this.getCustom(`/CheckActiveSession/${applicationId}`);
	}

	private blockUser(): void {
		this.toastr.error('This application is currently being edited. Please try again later.', 'Application locked', {
			tapToDismiss: true,
			disableTimeOut: true
		});
	}

	private warnUser(): void {
		this.toastr.warning(
			'Another active session is in progress. Any unsaved changes would get lost/overwritten should you choose to continue here.',
			'Another session in progress',
			{
				tapToDismiss: true,
				disableTimeOut: true
			}
		);
	}

	private allowOverride(applicationId?: number): void {
		this.confirmationDialogService
			.warnToProceed(
				'Another session',
				'This application is being edited in another session. Click Allow if you would like to override the existing session, then try again.',
				'Allow',
				'Cancel'
			)
			.subscribe((action) => {
				if (action && applicationId) {
					this.forceClaimSession(applicationId).subscribe(() => {
						this.applicationDataService.reloadApplication();
					});
				}
			});
	}
}

interface ActiveSessionResponse {
	available: boolean;
	isHeldByCurrentUser: boolean;
}
