import { Injectable } from '@angular/core';
import { RefiStore } from '@app/modules/refi-journey/enums/refi-store.enum';
import { RefiApplicationStatusModel } from '@app/modules/refi-journey/models/refi-application-status.model';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { BehaviorSubject, catchError, map, Observable, of, Subject, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RefiApplicationSummaryService extends BaseJourneyService {
	shouldRenewApplicationLock$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	checkIndexChange = new Subject<void>();

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private applicationDataService: ApplicationDataService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.APPLICATION);
	}

	setupState(): void {
		// Had to deliberately set loading false
		this.formDataService.setLoading(RefiStore.ApplicationSummary, false);
	}

	getApplicationActivityConsumerStatuses(): Observable<RefiApplicationStatusModel[]> {
		return this.getCustom(`/ApplicationActivityConsumerStatus`).pipe(
			map((statuses: { id: number; title: string }[]) =>
				statuses.map((status, index) => ({ ...status, index } as RefiApplicationStatusModel))
			)
		);
	}

	getApplicationStatus(): Observable<RefiApplicationSummaryDTO[]> {
		return this.getCustom(`/GetStatus/${this.applicationDataQuery.applicationId()}`).pipe(
			map((status: RefiApplicationSummaryDTO) => [status]),
			tap((data) => {
				this.formDataService.upsertStateWithAsyncData(RefiStore.ApplicationSummary, of(data));
				if (data?.[0]?.opportunityId && !this.applicationDataQuery.getRefiOpportunityId()) {
					this.applicationDataService.updateRefiOpportunityId(data?.[0]?.opportunityId);
				}
			}),
			catchError(() => of([{} as RefiApplicationSummaryDTO]))
		);
	}

	isApplicationLocked(): Observable<boolean> {
		return <Observable<boolean>>this.getCustom(`ApplicationLocked/${this.applicationDataQuery.applicationId()}`);
	}

	renewLock(): Observable<boolean> {
		if (this.applicationDataQuery.getRefiApplicants().length > 1) {
			return this.patch(`RenewApplicationLock/${this.applicationDataQuery.applicationId()}`, null).pipe(
				tap((result: boolean) => {
					this.shouldRenewApplicationLock$.next(result);
				})
			);
		} else {
			return of(true);
		}
	}

	unlockApplication(): Observable<null> {
		return this.patch(`UnlockApplication/${this.applicationDataQuery.applicationId()}`, null).pipe(
			map(() => {
				this.shouldRenewApplicationLock$.next(false);
				return null;
			})
		);
	}
}
