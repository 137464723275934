import { AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SimpAlertService } from '@simpology/client-components';

@Component({
	selector: 'formly-alert',
	templateUrl: './formly-alert.component.html',
	styleUrls: ['./formly-alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAlertComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
	@ViewChild('alertContent', { static: false }) alertContent: TemplateRef<HTMLElement> = {} as TemplateRef<HTMLElement>;

	constructor(private simpAlertService: SimpAlertService) {
		super();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.simpAlertService.show(this.alertContent);
		});
	}
}
