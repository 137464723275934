import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyAmountTotal = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'amount-total',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-4')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
