import {
	EmploymentModel,
	EmploymentModelTransformer,
	TypeOfIncome
} from '@app/modules/financial-position/model/employment.model';
import { EmploymentStatus, IncomeType, TargetType, YesNo } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { EmploymentIncomeDTO, ForeignEmploymentDTO } from '@app/modules/typings/api';
import { CurrencyHelper, FrequencyShort } from '@simpology/client-components/utils';

export interface ForeignEmploymentModel {
	id?: number;
	ownership?: number;
	status?: number;
	basis?: number;
	dateStarted?: string;
	occupation?: string;
	dateEnded?: string;
	industry?: string;
	duration?: string;
	mainBusinessActivity?: string;
	employerType?: number;
	employerDetailsSelect?: ApplicantEnumObject;
	companyCar?: YesNo;
	companyName?: string;
	isOnProbation?: boolean;
	probationDateStarts?: string;
	probationDateEnds?: string;
	income?: IncomeModel[];
	typeOfIncome?: TypeOfIncome;
	grossSalary?: string;
	netSalary?: string;
	employedByFamily?: YesNo;
}

interface IncomeModel {
	id?: number;
	type?: number;
	grossAmount?: string;
	netAmount?: string;
	frequency?: FrequencyShort;
}
export class ForeignEmploymentModelTransformer {
	static toPayload(applicationId: number, employmentModel: EmploymentModel): ForeignEmploymentDTO {
		const foreignEmployment = employmentModel.employmentDetails.foreignEmployment!;

		const incomes = (foreignEmployment.income || [])
			.filter((income) => !!income)
			.map((income) => this.incomeToPayload(income));

		return {
			id: foreignEmployment?.id,
			applicationId: applicationId,
			applicantId: foreignEmployment.ownership,
			status: foreignEmployment?.status ?? EmploymentStatus.Previous,
			basis: foreignEmployment?.basis,
			occupation: foreignEmployment?.occupation,
			dateStarted: foreignEmployment?.dateStarted,
			dateEnded: foreignEmployment?.dateEnded,
			industry: foreignEmployment.industry,
			mainBusinessActivity: foreignEmployment.mainBusinessActivity,
			employerType: foreignEmployment?.employerType,
			employerRelatedCompanyId: foreignEmployment.employerDetailsSelect?.id,
			employerPartyId: foreignEmployment.employerDetailsSelect?.id,
			employerTargetType: foreignEmployment.employerDetailsSelect?.type,
			companyName: foreignEmployment?.companyName,
			isOnProbation: foreignEmployment?.isOnProbation,
			probationDateStarts: foreignEmployment.isOnProbation ? foreignEmployment.probationDateStarts : undefined,
			probationDateEnds: foreignEmployment.isOnProbation ? foreignEmployment.probationDateEnds : undefined,
			companyCar: !!foreignEmployment.companyCar,
			income: incomes,
			employedByFamily: getNullableBoolean(foreignEmployment.employedByFamily)
		} as ForeignEmploymentDTO;
	}

	static incomeToPayload(income: IncomeModel): EmploymentIncomeDTO {
		return {
			type: income.type,
			grossAmount: CurrencyHelper.unformatAmount(income.grossAmount ?? '0'),
			netAmount: CurrencyHelper.unformatAmount(income.netAmount ?? '0'),
			frequency: income.frequency
		} as EmploymentIncomeDTO;
	}

	static fromPayload(foreignEmploymentDTO: ForeignEmploymentDTO, formEnumsQuery: FormEnumsQuery): EmploymentModel {
		const incomes = (foreignEmploymentDTO.income || []).map((income) => this.incomeFromPayload(income));

		const foreignEmploymentModel = {
			id: foreignEmploymentDTO.id,
			ownership: foreignEmploymentDTO.applicantId,
			status: foreignEmploymentDTO.status,
			basis: foreignEmploymentDTO.basis,
			dateStarted: foreignEmploymentDTO.dateStarted,
			occupation: foreignEmploymentDTO.occupation,
			dateEnded: foreignEmploymentDTO.dateEnded,
			industry: foreignEmploymentDTO.industry,
			mainBusinessActivity: foreignEmploymentDTO.mainBusinessActivity,
			employerType: foreignEmploymentDTO.employerType,
			employerDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: foreignEmploymentDTO.employerTargetType as TargetType,
				id: foreignEmploymentDTO.employerPartyId as number
			}),
			companyCar: getNullableYesNo(foreignEmploymentDTO.companyCar),
			isOnProbation: foreignEmploymentDTO.isOnProbation,
			probationDateStarts: foreignEmploymentDTO.probationDateStarts,
			probationDateEnds: foreignEmploymentDTO.probationDateEnds,
			employedByFamily: getNullableYesNo(foreignEmploymentDTO.employedByFamily),
			income: incomes
		} as ForeignEmploymentModel;

		const calculated = EmploymentModelTransformer.getNetAndGrossAmountForSummary(incomes);
		return {
			id: foreignEmploymentDTO.id,
			employmentDetails: {
				extract: 'Foreign employed',
				typeOfIncome: {
					type: IncomeType.ForeignEmployed,
					currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
						foreignEmploymentDTO.dateEnded,
						foreignEmploymentDTO.status
					)
				},
				foreignEmployment: foreignEmploymentModel
			},
			currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
				foreignEmploymentDTO.dateEnded,
				foreignEmploymentDTO.status
			),
			grossAmount: calculated.grossAmount,
			netAmount: calculated.netAmount,
			summaryAmount: calculated.grossAmount,
			frequency: FrequencyShort.Monthly,
			applicantId: foreignEmploymentDTO.applicantId
		} as EmploymentModel;
	}

	static incomeFromPayload(incomeDto: EmploymentIncomeDTO): IncomeModel {
		return {
			type: incomeDto.type,
			grossAmount: String(incomeDto.grossAmount),
			netAmount: String(incomeDto.netAmount),
			frequency: incomeDto.frequency
		} as IncomeModel;
	}
}
