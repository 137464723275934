import { CommentLinkCommentType } from '@app/modules/shared/enums/app.enums';
import { DetailedCommentDTO } from '../typings/summary-lodgement';

export class DetailedCommentTransformer {
	static fromPayload(detailedCommentDTO: DetailedCommentDTO): DetailedComment {
		return {
			id: detailedCommentDTO.id,
			applicationId: detailedCommentDTO.applicationId,
			contextId: detailedCommentDTO.contextId,
			commentType: detailedCommentDTO.commentType,
			comment: detailedCommentDTO.comment
		};
	}

	static toPayload(detailedCommentModel: DetailedComment): DetailedCommentDTO {
		return {
			id: detailedCommentModel.id ?? -1,
			applicationId: detailedCommentModel.applicationId,
			contextId: detailedCommentModel.contextId,
			commentType: detailedCommentModel.commentType,
			comment: detailedCommentModel.comment
		};
	}
}

export interface DetailedComment {
	id: number;
	applicationId: number;
	commentType: CommentLinkCommentType;
	contextId: number;
	comment: string;
}
