<simp-radio-button
	[id]="uniqueId"
	[readonly]="!!to.readonly"
	[formControl]="$any(formControl)"
	[options]="$any($any(to.options) | selectOptions | async)"
	[validationErrors]="field.validation?.messages!"
	[customClass]="to.customClass"
	[hideLabel]="to.hideLabel"
	[showInfo]="!!to.infoText"
	metadataKey
	[formlyField]="field"
>
	{{ to.label }}</simp-radio-button
>
