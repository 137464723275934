import { Injectable } from '@angular/core';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { catchError, of, tap } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';

import { CONSTANTS } from '../constants/constants';
import { ApplicantType } from '../enums/app.enums';
import { formAreaPathToArea, formAreaToLabel, parseArea } from '../helper/util';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormMetaDataStore } from '../store/form-metadata/form-metadata.store';
import { FormMetaData } from '../store/form-metadata/typings/form-metadata';
import { FormValidationsService } from '../store/form-validations/form-validations.service';
import { RemoteValidationError } from '../store/form-validations/typings/form-validations';
import { FormAreaPath, FormAreas } from '../typings/form-areas.types';
import { RuleModel, ValidationResponseModel, ValidationsErrorDetailDTO } from '../typings/validations';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class RemoteValidationService extends BaseJourneyService {
	constructor(
		private formValidationsService: FormValidationsService,
		private applicationDataQuery: ApplicationDataQuery,
		private formMetaDataStore: FormMetaDataStore
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.REMOTE_VALIDATION);
	}

	fetchCurrentAreaRemoteValidations() {
		const currentArea = this.applicationDataQuery.currentArea;
		if (currentArea) {
			this.fetchRemoteValidations(currentArea);
		}
	}

	/**
	 * Rule engine validation when application area is changed
	 * @param area
	 */
	fetchRemoteValidations(area: FormAreaPath | undefined) {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return;
		}
		this.getCustom(
			`AreaByJourneyId/${this.applicationDataQuery.applicationId()}/${this.applicationDataQuery.getApplicationJourneyId()}?areaNameKey=${area}`
		)
			.pipe(
				catchError(() => of([])),
				tap((response) => this.transformAndStore(response as ValidationResponseModel))
			)
			.subscribe();
	}

	recheckExistingRemoteValidationErrors(codes: string[]) {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return;
		}
		const ruleCodesCSV = codes.map((item) => item.toString()).join(',');
		this.getCustom(
			`RuleCodesByJourneyId/${this.applicationDataQuery.applicationId()}/${this.applicationDataQuery.getApplicationJourneyId()}?ruleCodes=${ruleCodesCSV}`
		).pipe(
			catchError(() => of([])),
			tap((response) => this.transformAndStore(response as ValidationResponseModel))
		);
	}

	resetAndStoreAllErrors(allErrors: ValidationsErrorDetailDTO[]): void {
		this.formValidationsService.resetAllRemoteErrors();
		this.transformAndStore({
			isValid: false,
			message: '',
			successRuleResults: [],
			allowToContinueErrors: [],
			errorDetails: allErrors,
			nonApplicableRuleResults: []
		});
	}

	transformAndStore(response: ValidationResponseModel): void {
		response.successRuleResults ??= [];
		response.allowToContinueErrors ??= [];
		const errorDetails = response.errorDetails;
		const nonErrorDetails: RuleModel[] = [
			...response.successRuleResults,
			...response.allowToContinueErrors,
			...(response.nonApplicableRuleResults ?? [])
		];
		const areaMetaData = this.formMetaDataStore.getValue();
		if (nonErrorDetails.length > 0) {
			this.formValidationsService.resetRemoteErrors(nonErrorDetails.map((e) => e.ruleCode));
		}

		if (errorDetails?.length) {
			errorDetails.map((error) => {
				const [area, section, subSection] = error.mappedPath ? error.mappedPath.split('#') : [];
				const parsedArea = parseArea(area);
				const formValidationPath = this.getValidationPath(areaMetaData, area, section, subSection);
				const formValidationErrorModel = this.getValidationErrorModel(error, parsedArea, formValidationPath);

				if (parsedArea) {
					if (parsedArea === FormAreaPath.complianceGuarantor) {
						const formArea = `${formAreaPathToArea(parsedArea)}`;
						const fullPath = `${formArea.toLowerCase()}-${subSection}`;
						// If API provides mappedPathIndex, use it, otherwise set the path for all guarantors
						if (error.mappedPathIndex != undefined && error.mappedPathIndex >= 0) {
							this.formValidationsService.updateRemoteError(
								`${fullPath}-${error.mappedPathIndex}`,
								formValidationErrorModel
							);
						} else {
							const applicants = this.applicationDataQuery.getApplicants();
							const guarantors = applicants.filter(
								(a) =>
									a.applicantType === ApplicantType.Guarantor || a.applicantType === ApplicantType.BorrowerandGuarantor
							);
							guarantors.forEach((guarantor, i) => {
								this.formValidationsService.updateRemoteError(`${fullPath}-${i}`, formValidationErrorModel);
							});
						}
					} else if (parsedArea === FormAreaPath.applicants) {
						const formArea = `${formAreaPathToArea(parsedArea)}`;
						const fullPath = `${formArea.toLowerCase()}-${subSection}`;
						// If API provides mappedPathIndex, use it, otherwise set the path for all applicants
						if (error.mappedPathIndex != undefined && error.mappedPathIndex >= 0) {
							this.formValidationsService.updateRemoteError(
								`${fullPath}-${error.mappedPathIndex}`,
								formValidationErrorModel
							);
						} else {
							const applicants = this.applicationDataQuery.getApplicants();
							applicants.forEach((applicant, i) => {
								this.formValidationsService.updateRemoteError(`${fullPath}-${i}`, formValidationErrorModel);
							});
						}
					} else {
						const key = parsedArea === FormAreaPath.setup ? subSection : `${parsedArea}-${subSection}`;
						this.formValidationsService.updateRemoteError(key, formValidationErrorModel);
					}
				} else {
					this.formValidationsService.updateRemoteError(CONSTANTS.NoArea, formValidationErrorModel);
				}
			});
		}
	}

	private getValidationPath(areaMetaData: FormMetaData, area: string, section: string, subSection: string): string {
		const areaStore = Object.entries(areaMetaData).filter(
			(x) => x[0].toLowerCase() === area?.replace(/[-/]/g, '')?.toLowerCase()
		)?.[0];
		let path = '';
		const pathSeparator = ' - ';

		if (areaStore) {
			path = formAreaToLabel(areaStore[0] as FormAreas)?.toString() ?? areaStore[0].toString();
			const sectionStore = this.getFormlyProperty(areaStore[1], section);
			if (sectionStore) {
				path = sectionStore.title ? path.concat(pathSeparator).concat(sectionStore.title ?? '') : path;

				if (subSection) {
					const subSectionStore = this.getFormlyProperty(sectionStore, subSection);
					if (subSectionStore && subSectionStore.title) {
						path = path.concat(pathSeparator).concat(subSectionStore.title);
					}
				}
			}
		}

		return path;
	}

	private getFormlyProperty(formlyProperty: FormlyApiProperty, propertyName: string): FormlyApiProperty | undefined {
		return formlyProperty.properties?.filter((x) => x.key?.toLowerCase() === propertyName?.toLowerCase())?.[0];
	}

	private getValidationErrorModel(
		error: ValidationsErrorDetailDTO,
		area: FormAreaPath | null,
		path: string | null
	): RemoteValidationError {
		return {
			code: error.ruleCode,
			errorMessage: error.message,
			title: error.ruleTitle,
			area: area ?? undefined,
			path: path ?? undefined,
			isOverrideable: error.isOverrideable,
			overridableAccessLevelEnum: error.overridableAccessLevelEnum
		};
	}
}
