import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { DigitalServiceItemStatus } from '@app/modules/shared/enums/digital-service-item-status.enum';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IdVerificationDetails } from '../typings/applicants';

@Injectable({
	providedIn: 'root'
})
export class IdVerificationService extends BaseJourneyService<IdVerificationDetails> {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery
	) {
		super();
		this.setRoute(URL_CONSTANTS.ID_VERIFICATION_DATA);
	}

	getApplicantIdVerificationState(key: string): Observable<IdVerificationDetails> {
		return this.get(`${this.applicationDataQuery.applicationId()}`).pipe(
			tap((idVerificationDetails) => {
				if (idVerificationDetails) {
					idVerificationDetails.anyCancelled = idVerificationDetails.applicantsIdVerification.some(
						(applicant) => applicant.status === DigitalServiceItemStatus.Cancelled
					);
					this.simpFormlyHandlerService.upsertToStateWithData(key, [idVerificationDetails]);
				}
			})
		);
	}

	resubmitIdVerificationRequest(applicationId: number, applicantId: number, message?: string): Observable<void> {
		return <Observable<void>>this.postCustom(`ResubmitRequest`, {
			ladmApplicationId: applicationId,
			secureApplicantId: applicantId,
			message
		});
	}

	cancelIdVerificationRequest(applicationId: number, applicantId: number, message: string): Observable<void> {
		return <Observable<void>>this.postCustom(`CancelRequest`, {
			ladmApplicationId: applicationId,
			secureApplicantId: applicantId,
			message
		});
	}
}
