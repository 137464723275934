import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { ValidationHelper } from '@simpology/client-components/utils';
import isNil from 'lodash-es/isNil';

import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { FormlyFieldTypes } from '@app/modules/simp-formly/enums/formly-field-types';
import { filter, Subject, takeUntil } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-amount',
	templateUrl: './formly-amount.component.html',
	styleUrls: ['./formly-amount.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAmountComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';

	private destroy$ = new Subject<void>();

	constructor(private formValidationsService: FormValidationsService, private formatter: AggregateFormatterService) {
		super();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
		this.formValidationsService.triggerFieldValidations$
			.pipe(
				takeUntil(this.destroy$),
				filter((field: FormlyFieldConfig) => field.type === FormlyFieldTypes.Amount && field === this.field)
			)
			.subscribe(() => {
				this.formControl.markAllAsTouched();
			});
		if (this.to.disabled) {
			this.formControl.disable();
		}
	}

	generateTooltipText(): string {
		const tooltipText = this.to.tooltipText as string | number;

		if (isNaN(Number(tooltipText))) {
			return (tooltipText as string).replace('-', '');
		} else {
			return this.formatter.formatAmount(tooltipText as number).replace('-', '');
		}
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}

	handleBlur(): void {
		if (this.field.formControl.value > 0) {
			this.field.formControl.updateValueAndValidity();
		}
	}

	private minValidator = (control: AbstractControl): ValidatorFn =>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		ValidationHelper.minimumAmount(control as UntypedFormControl, this.to.minValue as number);
	private maxValidator = (control: AbstractControl): ValidatorFn =>
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		ValidationHelper.maximumAmount(control as UntypedFormControl, this.to.maxValue as number);
}
