import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import { EnumObject } from '@simpology/client-components/utils';
import { isObservable, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Pipe({
	name: 'enumLabel'
})
export class EnumLabelPipe implements PipeTransform {
	constructor(private formEnumsService: FormEnumsService) {}

	/**
	 * Resolves the options keyword from API or Store
	 * options - as list of EnumObject[] returned as Observable
	 * @param val
	 * @param enumKey
	 * @param takeOne
	 * @param formatter
	 */
	transform(
		val: Observable<string> | number,
		enumKey?: string,
		takeOne = false,
		formatter: 'enum' | 'enumWithInfo' | 'infoWithEnum' = 'enum'
	): Observable<string> {
		if (isNullOrUndefined(val)) {
			return of(CONSTANTS.DEFAULT_STRING);
		}

		if (!isObservable(val)) {
			return this.formEnumsService.fetch(enumKey!, takeOne).pipe(
				map((options) => {
					return this.formatEnumLabel(options, val, formatter);
				})
			);
		}

		return val.pipe(
			switchMap((input) => {
				if (input === undefined || input === null) {
					return of('');
				}

				if (input.includes('.')) {
					const splits = input.split('.');
					enumKey = splits[0];
					const enumValue = Number(splits[1]);

					//keys won't have spaces - fixed the issue where address value is being treated as enumKey
					if (isNaN(enumValue) || enumKey.includes(' ')) {
						return of(input);
					}

					return this.formEnumsService.fetch(enumKey, takeOne).pipe(
						map((options) => {
							return this.formatEnumLabel(options, enumValue, formatter, input);
						})
					);
				}

				return of(input);
			})
		);
	}

	private formatEnumLabel(
		options: EnumObject[],
		enumValue: number,
		formatter: 'enum' | 'enumWithInfo' | 'infoWithEnum',
		inputValue = ''
	) {
		const selectedOption = (options || []).find((option) => option.id === enumValue);
		if (selectedOption) {
			const infoWithEnumValue = formatter === 'infoWithEnum' ? `${selectedOption.info} - ` : '';
			const enumWithInfoValue = formatter === 'enumWithInfo' ? ` - ${selectedOption.info}` : '';
			return `${infoWithEnumValue}${selectedOption.label}${enumWithInfoValue}`;
		}

		return inputValue;
	}
}
