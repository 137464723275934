import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';
import {
	RelatedCompanyFinancials,
	RelatedCompanyFinancialsTransformer
} from '../model/related-company-financial.model';

import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class RelatedCompanyFinancialsService extends BaseJourneyService<any> {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.COMPANY_FINANCIALS);
	}

	saveCompanyFinancials(relatedCompanyId: number, companyFinancials: RelatedCompanyFinancials[]): Observable<number> {
		const payload = companyFinancials.map((companyFinancial) => {
			return RelatedCompanyFinancialsTransformer.toPayload(companyFinancial);
		});

		return <Observable<number>>(
			this.postCustom(`RelatedCompany/${this.applicationDataQuery.applicationId()}/${relatedCompanyId}`, payload)
		);
	}
}
