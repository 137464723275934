import { Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { CurrentUserQuery } from '@app/modules/shared/store/current-user/current-user.query';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FinaliseAndSubmitResponse, StepResponse } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subject, filter, of, switchMap, tap } from 'rxjs';
import { RefiApplicationSummaryService } from '../../components/refi-form/application-summary/application-summary.service';
import { RefiStore } from '../../enums/refi-store.enum';
import { LiabilitiesItems, ReviewModel } from '../../models/refi-review.model';

import { Router } from '@angular/router';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiReviewService } from './refi-review.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyReviewTransformerService {
	private primaryButtonSpinner = new Subject();
	constructor(
		private refiJourneyService: RefiJourneyService,
		private reviewService: RefiReviewService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private stepStatusQuery: StepStatusQuery,
		private journeyStepService: JourneyStepService,
		private currentUserQuery: CurrentUserQuery,
		private applicationSummaryService: RefiApplicationSummaryService,
		private applicationDataQuery: ApplicationDataQuery,
		private refiModalService: RefiModalService,
		private router: Router
	) {}

	public reviewTransform(formFields: FormlyFieldConfig[]) {
		const otherApplicant = this.applicationDataQuery.getRefiApplicants().find((applicant) => !applicant.isCurrentUser);
		formlyOnClick(
			formFields,
			'review.details',
			(field: FormlyFieldConfig, clickType: { type: string; invalid: boolean }) => {
				if (this.stepStatusQuery.isCompleted(RefiStepType.RefiUploadDocuments)) {
					this.refiJourneyService.navigateToStep('summary/details');
					return of();
				} else if (!clickType.invalid) {
					if (field.templateOptions) {
						field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
					}

					return this.reviewService.finaliseAndSubmit().subscribe({
						next: (response: FinaliseAndSubmitResponse) => {
							if (response.status == false) {
								this.refiJourneyService.displayErrorScreen();
							} else {
								this.refiJourneyService.getApplicants().subscribe();
								this.applicationSummaryService.unlockApplication().subscribe();
								this.journeyStepService.updateStep(RefiStepType.RefiReview, StepStatus.Complete, false).subscribe();
								this.journeyStepService
									.updateActivityStatus()
									.pipe(switchMap(() => this.applicationSummaryService.getApplicationStatus()))
									.subscribe(() => {
										if (response.conditionallyApproved) {
											this.refiJourneyService.navigateToStep('result/approval');
										} else {
											this.refiJourneyService.navigateToStep('result/referral');
										}
									});
							}
						},
						error: () => this.refiJourneyService.displayErrorScreen()
					});
				} else {
					return of();
				}
			}
		);

		formlyExtendExpressionProperties(formFields, 'review.details', {
			'templateOptions.disablePrimaryButton': (model, formState, field) => {
				const reviewModel = model as ReviewModel[];
				if (reviewModel[0]?.summarySections && field.templateOptions) {
					const excludedSteps: RefiStepType[] = [RefiStepType.RefiReview, RefiStepType.RefiUploadDocuments];
					if (otherApplicant) {
						if (
							!otherApplicant.privacyActConsentSigned ||
							!!reviewModel[0]?.summarySections.reconcileRequired ||
							journeySteps.some((step) => !excludedSteps.includes(step.type) && step.status === StepStatus.Incomplete)
						) {
							field.templateOptions.disablePrimaryButton = true;
						} else {
							field.templateOptions.disablePrimaryButton = false;
						}
					} else {
						field.templateOptions.disablePrimaryButton = journeySteps.some(
							(step) => !excludedSteps.includes(step.type) && step.status === StepStatus.Incomplete
						);
					}
				}
				this.primaryButtonSpinner.subscribe(() => {
					if (field.templateOptions) {
						field.templateOptions.loading = field.templateOptions.disablePrimaryButton = false;
					}
				});
				return field?.templateOptions?.disablePrimaryButton as boolean;
			}
		});

		formlyRegisterHooks(formFields, 'review.details', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					if (this.stepStatusQuery.isCompleted(RefiStepType.RefiUploadDocuments)) {
						field.templateOptions.label = 'Submitted application';
						field.templateOptions.infoText = null;
						field.templateOptions.subText = null;
						field.templateOptions.primaryButtonText = 'Back to Application Summary';
					} else {
						let applicantFirstName = this.currentUserQuery.firstName();
						if (otherApplicant) {
							applicantFirstName = `${this.currentUserQuery.firstName()} & ${otherApplicant.firstName}`;

							if (field.templateOptions?.subText) {
								field.templateOptions.subText = String(field.templateOptions.infoText);
							}
						}
						field.templateOptions.label = this.refiJourneyService.isAdminUser
							? 'Review details'
							: field.templateOptions.label?.replace('ApplicantFirstName', applicantFirstName);
						if (field && field.templateOptions && this.refiJourneyService.isAdminUser) {
							field.templateOptions.primaryButtonText = '';
						}
						field.templateOptions?.infoText ? (field.templateOptions.infoText = '') : null;
					}
				}
				return of();
			}
		});

		formlyRegisterHooks(
			formFields,
			'review.details.summarySections.reminderBox.reminderBoxBody.notReadyToSubmitReason',
			{
				onInit: (field) => {
					if (field) {
						if (otherApplicant && !otherApplicant.privacyActConsentSigned) {
							while (field.template?.includes('CurrentApplicantFirstName')) {
								field.template = field.template?.replace(
									'CurrentApplicantFirstName',
									this.currentUserQuery.firstName()
								);
							}
							while (field.template?.includes('CoApplicantFirstName')) {
								field.template = field.template?.replace('CoApplicantFirstName', otherApplicant.firstName);
							}
						}
					}
					return of();
				}
			}
		);

		formlyRegisterHooks(formFields, 'review.details.summarySections.declaration', {
			onInit: (field) => {
				return this.simpFormlyHandlerService.mapToFullStateDataPath<ReviewModel[]>(RefiStore.Review)?.pipe(
					filter((review: ReviewModel[]) => !!review.length),
					tap((review: ReviewModel[]) => {
						if (field && field.template) {
							if (this.refiJourneyService.isAdminUser) {
								field.template = '';
								return;
							}

							field.template = field.template?.replace('ApplicantFirstName', this.currentUserQuery.firstName());
						}
					})
				);
			}
		});

		formlyRegisterHooks(formFields, 'review.details.summarySections.declarationItems', {
			onInit: (field) => {
				if (field && field.template && this.refiJourneyService.isAdminUser) {
					field.template = '';
				}
			}
		});

		formlyRegisterHooks(formFields, 'review.details.summarySections.submitFooter', {
			onInit: (field) => {
				if (field && field.template) {
					if (this.refiJourneyService.isAdminUser) {
						field.template = '';
						return;
					}
				}
			}
		});

		formlyOnClick(
			formFields,
			`review.details.summarySections.loanRequirements.loanRequirementsItems.totalLoanAmount`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openRefiModal(formFields, RefiModalName.totalLoanAmount);
				}
			}
		);

		formlyRegisterHooks(
			formFields,
			'review.details.summarySections.loanRequirements.loanRequirementsItems.totalLoanAmount',
			{
				onInit: (field) => {
					if (field && field.templateOptions) {
						field.templateOptions.tooltipAsModal = true;
					}
				}
			}
		);

		formlyRegisterHooks(formFields, 'review.details.summarySections.declarationItems', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'review.details.summarySections.securityProperty.securityPropertyItems', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const foundProperty = getFormField(field.fieldGroup, 'foundProperty');
					if (foundProperty?.formControl?.value === false) {
						field.templateOptions.label += '(still looking)';
					}
				}
			}
		});

		formlyExtendExpressionProperties(formFields, 'review.details.summarySections.liabilities.digitallyFound', {
			'props.label': (model: LiabilitiesItems, formState, field) => {
				if (model?.reconcileRecords > 0) {
					let label = field.props?.label?.replace('LiabilitiesToReconcileCount', model.reconcileRecords.toString());
					if (model.reconcileRecords === 1) {
						label = label?.replace('liabilities', 'liability');
					}
					return label;
				}
				return field.props?.label;
			}
		});

		formlyOnClick(formFields, `review.details.summarySections.liabilities.digitallyFound`, (field) => {
			if (field.props?.route) {
				void this.router.navigate([field.props.route as string]);
			}
		});

		formlyOnClick(
			formFields,
			`review.details.summarySections.reminderBox.reminderBoxBody.reminderButton`,
			(field, clickType: { type: string }) => {
				this.reviewService.sendCoBorrowerConsentReminder().subscribe((r) => {
					if (otherApplicant && !otherApplicant.privacyActConsentSigned) {
						const bodyLabel = getFormField(formFields, 'review.refi-modals.consentReminderSentLabels.textBlock1');
						if (bodyLabel?.props?.label) {
							bodyLabel.props.label = bodyLabel.props.label.replace(
								'CoApplicantFullName',
								`${otherApplicant.firstName} ${otherApplicant.lastName}`
							);
						}
					}

					this.openRefiModal(formFields, RefiModalName.consentReminderSent);

					if (field.props) {
						field.props.disabled = true;
					}
				});
			}
		);

		const journeySteps = this.stepStatusQuery.stepStatuses();
		if (journeySteps) {
			const loanRequirements = getFormField(formFields, 'review.details.summarySections.loanRequirements')
				?.fieldArray as FormlyFieldConfig;
			if (
				loanRequirements &&
				journeySteps.find((step) => step.type === RefiStepType.RefiLoanRequirements)?.status !== StepStatus.Complete
			) {
				const loanRequirementItems = loanRequirements.fieldGroup?.find((f) => f.key === 'loanRequirementsItems');
				if (loanRequirementItems?.templateOptions) {
					loanRequirementItems.templateOptions.customClass += ' mobile-summary-item--error';
				}
			} else {
				const errorMessage = loanRequirements.fieldGroup?.find((f) => f.key === 'errorMessage');
				if (errorMessage) {
					errorMessage.className = 'hidden';
				}
			}

			const productStep = journeySteps.find((step) => step.type === RefiStepType.RefiSelectProduct);
			this.setSectionCompletion(formFields, 'product', 'selectedProduct', productStep);
			const incomeStep = journeySteps.find((step) => step.type === RefiStepType.RefiIncome);
			this.setSectionCompletion(formFields, 'income', 'totalIncome', incomeStep);
			const expensesStep = journeySteps.find((step) => step.type === RefiStepType.RefiExpenses);
			this.setSectionCompletion(formFields, 'expenses', 'totalExpenses', expensesStep);
			const assetsStep = journeySteps.find((step) => step.type === RefiStepType.RefiAssets);
			this.setSectionCompletion(formFields, 'assets', 'totalAssets', assetsStep);
			const liabilitiesStep = journeySteps.find((step) => step.type === RefiStepType.RefiLiabilities);
			this.setSectionCompletion(formFields, 'liabilities', 'totalLiabilities', liabilitiesStep);
		}

		if (this.stepStatusQuery.isCompleted(RefiStepType.RefiUploadDocuments)) {
			const review = getFormField(formFields, 'review');
			if (review?.templateOptions) {
				review.templateOptions.steps = false;
			}
			if (review?.fieldGroup && review.fieldGroup[0].templateOptions) {
				review.fieldGroup[0].templateOptions.prevRoute = 'summary/details';
			}
			['loanRequirements', 'product', 'income', 'expenses', 'assets', 'liabilities'].forEach((section) => {
				formlyRegisterHooks(formFields, `review.details.summarySections.${section}`, {
					onInit: (field) => {
						if (field?.templateOptions) {
							field.templateOptions.readonly = true;
						}
					}
				});
			});
			['reviewUpdate', 'declaration', 'declarationItems'].forEach((section) => {
				formlyRegisterHooks(formFields, `review.details.summarySections.${section}`, {
					onInit: (field) => {
						if (field) {
							field.hide = true;
						}
					}
				});
			});
		}
	}

	private setSectionCompletion(
		formFields: FormlyFieldConfig[],
		section: string,
		subsection: string,
		step?: StepResponse
	): void {
		const formSection = getFormField(formFields, `review.details.summarySections.${section}`)
			?.fieldArray as FormlyFieldConfig;
		if (formSection && step?.status !== StepStatus.Complete) {
			const formSubsection = formSection.fieldGroup?.find((f) => f.key === `${subsection}`);
			if (formSubsection?.templateOptions) {
				formSubsection.templateOptions.labelClass += ' mobile-summary-item--error';
			}
		} else {
			const errorMessage = formSection.fieldGroup?.find((f) => f.key === 'errorMessage');
			if (errorMessage) {
				errorMessage.className = 'hidden';
			}
		}
	}

	private openRefiModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): Observable<string> {
		const refiModalField = getFormField(formFields, 'review.refi-modals');

		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}
}
