import { Injectable } from '@angular/core';
import { ParentDocumentTemplate, PoiDocTemplate } from '@app/modules/applicants/models/proof-of-identity-details-model';
import { RequirementCondition } from '@app/modules/shared/enums/app.enums';
import { cloneDeep, isEmpty } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ProofOfIdentityStatusHandlerService {
	isProofOfIdentityStatusCompleted(poiCompletedDocumentIds: number[], poiTemplateDocuments: PoiDocTemplate[]): boolean {
		const maxPoiIdValue = Math.max(...poiTemplateDocuments.map((item) => item.identityDocumentTypeEnum));
		const parentsDocumentCondition = this.getParentCondition(cloneDeep(poiTemplateDocuments), maxPoiIdValue);
		const flatParentsDocumentCondition = parentsDocumentCondition.filter((item) => item.isFlat);

		this.getFulfilledPOIDocumentsIdsRecursive(
			poiCompletedDocumentIds,
			parentsDocumentCondition,
			parentsDocumentCondition,
			maxPoiIdValue
		);

		return this.isStatusCompleted(flatParentsDocumentCondition, poiCompletedDocumentIds);
	}

	private isStatusCompleted(
		parentsDocumentCondition: ParentDocumentTemplate[],
		poiCompletedDocumentIds: number[]
	): boolean {
		parentsDocumentCondition.forEach((item) => {
			item.isValid = this.isPOIDocumentTemplateFulfilled(item, poiCompletedDocumentIds);
		});

		return !parentsDocumentCondition.some((document) => !document.isValid);
	}

	private getParentCondition(poiDocTemplates: PoiDocTemplate[], maxPoiIdValue: number) {
		const documentParents: ParentDocumentTemplate[] = [];
		const conditionalDocuments = poiDocTemplates.filter((item) => item.condition !== null);

		for (const conditionalDocument of conditionalDocuments) {
			const children = poiDocTemplates.filter((item) => item.parentName === conditionalDocument.name);
			const isFlat = poiDocTemplates.some((item) => item.name === conditionalDocument.name && !item.parentName);

			if (!conditionalDocument.identityDocumentTypeEnum) {
				maxPoiIdValue++;
				conditionalDocument.identityDocumentTypeEnum = maxPoiIdValue;
			}

			const document = {
				name: conditionalDocument.name,
				condition: conditionalDocument.condition,
				conditionNumber: conditionalDocument.conditionNumber,
				isVerified: false,
				isValid: false,
				isFlat: isFlat,
				children: children
			} as ParentDocumentTemplate;

			if (document.children.length === 0) {
				const unconditionalChildren = poiDocTemplates.filter((item) => item.condition === undefined);

				if (unconditionalChildren[0]?.name === document.name) {
					document.condition = RequirementCondition.Any;
					document.children = unconditionalChildren;
				}
			}

			documentParents.push(document);
		}

		return documentParents;
	}

	private getFulfilledPOIDocumentsIdsRecursive(
		poiCompletedDocumentIds: number[],
		parentsDocumentCondition: ParentDocumentTemplate[],
		childChildren: ParentDocumentTemplate[],
		maxPoiIdValue: number
	) {
		if (childChildren.length === 0) {
			return true;
		}

		let isValid = true;

		childChildren.forEach((item) => {
			const itemChildChildren = item.children.find((child) => child.identityDocumentTypeEnum > maxPoiIdValue);

			if (itemChildChildren && !item.isVerified && !isEmpty(parent?.name) && !isEmpty(itemChildChildren.name)) {
				const child = parentsDocumentCondition.find((parent) => parent.name === itemChildChildren.name);

				if (child) {
					isValid = this.getFulfilledPOIDocumentsIdsRecursive(
						poiCompletedDocumentIds,
						parentsDocumentCondition,
						[child],
						maxPoiIdValue
					);
				}

				if (isValid) {
					poiCompletedDocumentIds.push(itemChildChildren.identityDocumentTypeEnum);
				}
			}

			isValid = this.isPOIDocumentTemplateFulfilled(item, poiCompletedDocumentIds);
			item.isVerified = true;
		});

		return isValid;
	}

	private isPOIDocumentTemplateFulfilled(
		parentDocumentTemplate: ParentDocumentTemplate,
		poiCompletedDocumentIds: number[]
	): boolean {
		switch (parentDocumentTemplate.condition) {
			case RequirementCondition.All: {
				const completedAll = parentDocumentTemplate.children.every((document) =>
					poiCompletedDocumentIds.includes(document.identityDocumentTypeEnum)
				);

				if (!completedAll) {
					return false;
				}
				break;
			}
			case RequirementCondition.Any: {
				const existDocumentCompleted = parentDocumentTemplate.children.some((document) =>
					poiCompletedDocumentIds.includes(document.identityDocumentTypeEnum)
				);

				if (!existDocumentCompleted) {
					return false;
				}
				break;
			}
			case RequirementCondition.AtLeast: {
				const completedChildren = parentDocumentTemplate.children.filter((document) =>
					poiCompletedDocumentIds.includes(document.identityDocumentTypeEnum)
				);

				if (parentDocumentTemplate.conditionNumber > completedChildren.length) {
					return false;
				}
				break;
			}
		}

		return true;
	}
}
