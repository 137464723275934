import { formatCurrency } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { JourneyType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { filterListOptions } from '@app/modules/shared/enums/enum-helper';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { PercentOwnedService } from '@app/modules/shared/service/percent-owned.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { AssetsDTO, PercentOwned } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { ConfirmationDialogHeaderType, EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get } from 'lodash-es';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { OtherAssetsDetails, OtherAssetsModel } from '../../models/refi-other-assets.model';
import { PropertyAssetsDetails, PropertyModel } from '../../models/refi-property-assets.model';
import { SavingsAssetsDetails, SavingsAssetsModel } from '../../models/refi-savings-assets.model';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiAssetsService } from './refi-assets.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyAssetsTransformerService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private totalAssets = 0;

	constructor(
		private refiJourneyService: RefiJourneyService,
		private assetService: RefiAssetsService,
		private journeyStepService: JourneyStepService,
		private formDataService: FormDataService,
		private formEnumQuery: FormEnumsQuery,
		private confirmationDialogService: SimpConfirmationDialogService,
		private applicationDataQuery: ApplicationDataQuery,
		private percentsOwnedService: PercentOwnedService,
		private refiModalService: RefiModalService,
		private sharedFlagService: SharedFlagsService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public assetsTransform(formFields: FormlyFieldConfig[]) {
		this.formEnumQuery
			.selectEnumOptions('AllApplicants')
			.pipe(take(1))
			.subscribe((applicants: EnumObject[]) => {
				if (applicants.length === 1) {
					this.selectFirstApplicantForOwnership(formFields, 'assets.details.property.propertyModal.owner');
					this.selectFirstApplicantForOwnership(formFields, 'assets.details.savings.savingsModal.owner');
					this.selectFirstApplicantForOwnership(formFields, 'assets.details.otherAssets.otherAssetsModal.owner');
				}
			});

		formlyRegisterHooks(formFields, 'assets.details.savings.savingsModal.financialInstitution', {
			onInit: (field) => {
				if (
					field &&
					field.templateOptions &&
					field.templateOptions.handler &&
					!(field.model as SavingsAssetsDetails)?.readonly
				) {
					const options = filterListOptions(
						this.formEnumQuery.getOptions('FinancialInstitution'),
						field.templateOptions.handler as string
					);
					field.templateOptions.options = options;

					const matchingOption = options.find((option) => option.id === field.formControl?.value);
					if (!matchingOption) {
						field.formControl?.setValue(null);
					}
				}
			}
		});

		formlyOnClick(
			formFields,
			'assets.details.property',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Assets, true);
					this.assetService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.savePropertyAssets(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as PropertyModel[];
									const id = model[event.index].propertyModal.id;
									if (id) {
										this.deletePropertyAssets(id);
									}
								}
							});
					}
				}
			}
		);
		formlyOnClick(
			formFields,
			'assets.details.savings',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Assets, true);
					this.assetService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveSavingsAssets(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as SavingsAssetsModel[];
									const id = model[event.index].savingsModal.id;
									if (id) {
										this.deleteSavingsAsset(id);
									}
								}
							});
					}
				}
			}
		);
		formlyOnClick(
			formFields,
			'assets.details.otherAssets',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Assets, true);
					this.assetService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveOtherAssets(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as OtherAssetsModel[];
									const id = model[event.index].otherAssetsModal.id;
									if (id) {
										this.deleteOtherAssets(id);
									}
								}
							});
					}
				}
			}
		);

		formlyOnClick(formFields, 'assets.details', (field: FormlyFieldConfig, event: { type: string }) => {
			if (event.type === 'primary') {
				if (
					this.sharedFlagService.currentJourney === JourneyType.Purchase &&
					field.parent &&
					field.parent.formControl &&
					field.parent.formControl.valid
				) {
					this.journeyStepService
						.updateStep(RefiStepType.RefiAssets, StepStatus.Complete)
						.pipe(takeUntil(this.destroy$))
						.subscribe();
				}
				this.refiJourneyService.navigateToStep('liabilities/details');
			}
		});

		let subscription: Subscription | undefined;
		formlyRegisterHooks(formFields, 'assets.details.summaryAmount', {
			afterContentInit: (field) => {
				if (field) {
					subscription = this.formDataService.select$(RefiStore.Assets).subscribe(() => {
						this.calculateSummary(field);
					});
				}
			},
			onDestroy: () => {
				if (subscription) {
					subscription.unsubscribe();
				}
			}
		});

		formlyExtendExpressionProperties(formFields, `assets.details.property.propertyModal`, {
			'templateOptions.hideModalFooter': (model: PropertyAssetsDetails) => {
				return model?.toBeUsedAsSecurity || !!model?.readonly || this.refiJourneyService.isAdminUser || false;
			}
		});

		formlyExtendExpressionProperties(formFields, `assets.details.savings.savingsModal`, {
			'templateOptions.hideModalFooter': (
				model: SavingsAssetsDetails,
				formState: unknown,
				field: FormlyFieldConfig
			) => {
				field.fieldGroup?.forEach((fieldObject) => {
					if (fieldObject.templateOptions) {
						fieldObject.templateOptions.disabled = model?.readonly;
					}
				});
				return model?.readonly || this.refiJourneyService.isAdminUser || false;
			}
		});

		formlyExtendExpressionProperties(formFields, `assets.details.otherAssets.otherAssetsModal`, {
			'templateOptions.hideModalFooter': () => {
				return this.refiJourneyService.isAdminUser || false;
			}
		});

		const equalOwnership = getFormField(formFields, 'assets.details.property.propertyModal.equalOwnership');
		if (equalOwnership) {
			equalOwnership.hideExpression = `${this.applicationDataQuery.getRefiApplicants().length} === 1`;
		}

		const percentArray = getFormField(formFields, 'assets.details.property.propertyModal.percentsOwned');
		const numberOfApplicants = this.applicationDataQuery.getRefiApplicants();
		if (percentArray) {
			percentArray.defaultValue = numberOfApplicants.map(() => ({}));
		}

		formlyOnClick(
			formFields,
			`assets.details.property.propertyModal.equalOwnership`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openInfoModal(formFields, RefiModalName.StructureOfOwnership, 'loanRequirements.info-modals');
				}
			}
		);

		formlyRegisterHooks(formFields, 'assets.details.property.propertyModal.equalOwnership', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(
			formFields,
			`assets.details.property.propertyModal.percentsOwned.percent`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openInfoModal(formFields, RefiModalName.SubstantialBenefit, 'loanRequirements.info-modals');
				}
			}
		);

		formlyRegisterHooks(formFields, 'assets.details.property.propertyModal.percentsOwned', {
			onInit: (field) => {
				const lastItem = field?.fieldGroup?.slice(-1).pop()?.fieldGroup?.slice(-1).pop();
				if (lastItem && lastItem.templateOptions) {
					lastItem.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyExtendExpressionProperties(formFields, 'assets.details.property.propertyModal.percentsOwned', {
			'': (model, formState, field: FormlyFieldConfig) => {
				if (field && field.templateOptions) {
					field.fieldGroup?.forEach((f, index) => {
						const currentModel = f.model as PercentOwned;
						const refiApplicants = this.applicationDataQuery.getRefiApplicants();
						const firstName = refiApplicants.find((x) => x.id === currentModel?.applicantId)?.firstName || '';
						const percentField = getFormField(f.fieldGroup, 'percent');
						if (percentField?.templateOptions && percentField.templateOptions.label?.includes('Applicant')) {
							if (currentModel?.applicantId) {
								percentField.templateOptions.label = percentField?.templateOptions?.label?.replace(
									'Applicant',
									firstName
								);
							} else {
								percentField.templateOptions.label = percentField?.templateOptions?.label?.replace(
									'Applicant',
									refiApplicants[index].firstName
								);
							}
						}
					});
				}
			}
		});
	}

	selectFirstApplicantForOwnership(formFields: FormlyFieldConfig[], path: string) {
		const ownershipField = getFormField(formFields, path) as FormlyFieldConfig;
		if (ownershipField && ownershipField.templateOptions) {
			ownershipField.templateOptions.selectFirstOption = true;
		}
	}

	private calculateSummary(field: FormlyFieldConfig): void {
		const model = field.model as { property: Object; savings: Object; otherAssets: Object; summaryAmount: string };
		const [property, savings, otherAssets] = [
			model.property as PropertyModel[],
			model.savings as SavingsAssetsModel[],
			model.otherAssets as OtherAssetsModel[]
		];
		this.totalAssets = 0;
		if (property?.length > 0) {
			this.totalAssets += property.reduce((x, y) => x + (y?.propertyModal.worth ? y.propertyModal.worth : 0), 0);
		}

		if (savings?.length > 0) {
			this.totalAssets += savings.reduce(
				(x, y) => x + (y?.savingsModal.savingsValue ? y.savingsModal.savingsValue : 0),
				0
			);
		}

		if (otherAssets?.length > 0) {
			this.totalAssets += otherAssets.reduce(
				(x, y) => x + (y?.otherAssetsModal.worth ? y.otherAssetsModal.worth : 0),
				0
			);
		}

		if (this.totalAssets > 0) {
			field.className = '';
			model.summaryAmount = `<span class="simp-text--bold">${formatCurrency(
				this.totalAssets,
				'en-AU',
				'$'
			)}</span> total assets`;
			if (field.parent && field.parent.formControl && field.parent.formControl.valid) {
				this.journeyStepService
					.updateStep(RefiStepType.RefiAssets, StepStatus.Complete)
					.pipe(takeUntil(this.destroy$))
					.subscribe();
			}
		} else {
			field.className = 'hidden';
			if (this.sharedFlagService.currentJourney === JourneyType.Refi) {
				this.journeyStepService
					.updateStep(RefiStepType.RefiAssets, StepStatus.Incomplete)
					.pipe(takeUntil(this.destroy$))
					.subscribe();
			}
		}
	}

	private savePropertyAssets(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as PropertyModel[];
		if (model) {
			const modelCloned = cloneDeep(Object.values(model[index]));
			const payload = modelCloned[0] as PropertyAssetsDetails;

			this.formDataService.setLoading(RefiStore.Assets, true);
			this.assetService.savePropertyAsset(payload).subscribe({
				next: (res) => {
					if (res) {
						this.assetService.setupState();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Assets, false)
			});
		}
	}

	private deletePropertyAssets(id: number): void {
		this.formDataService.setLoading(RefiStore.Assets, true);
		this.assetService.deletePropertyAsset(id).subscribe({
			next: () => {
				this.assetService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Assets, false)
		});
	}

	private saveSavingsAssets(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as SavingsAssetsModel[];
		if (model) {
			const modelCloned = cloneDeep(Object.values(model[index]));
			const payload = modelCloned[0] as SavingsAssetsDetails;

			this.formDataService.setLoading(RefiStore.Assets, true);
			this.assetService.saveSavings(payload).subscribe({
				next: (res) => {
					if (res) {
						this.assetService.setupState();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Assets, false)
			});
		}
	}

	private deleteSavingsAsset(id: number): void {
		this.formDataService.setLoading(RefiStore.Assets, true);
		this.assetService.deleteSavingsAsset(id).subscribe({
			next: () => {
				this.assetService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Assets, false)
		});
	}

	private saveOtherAssets(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as AssetsDTO[];
		if (model) {
			const modelCloned = cloneDeep(Object.values(model[index]));
			const payload = modelCloned[0] as OtherAssetsDetails;

			this.formDataService.setLoading(RefiStore.Assets, true);
			this.assetService.saveOtherAsset(payload).subscribe({
				next: (res) => {
					if (res) {
						this.assetService.setupState();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Assets, false)
			});
		}
	}

	private deleteOtherAssets(id: number): void {
		this.formDataService.setLoading(RefiStore.Assets, true);
		this.assetService.deleteOtherAsset(id).subscribe({
			next: () => {
				this.assetService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Assets, false)
		});
	}

	private openInfoModal(
		formFields: FormlyFieldConfig[] | undefined,
		refiModalName: RefiModalName,
		path = 'assets.info-modals'
	): void {
		const refiModalField = getFormField(formFields, path);
		this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}
}
