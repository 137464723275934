import { Injectable } from '@angular/core';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SourceOfWealthDTO } from '@app/modules/typings/api';
import { SourceOfWealthModel, SourceOfWealthTransformer } from '../models/source-of-wealth.model';

@Injectable({ providedIn: 'root' })
export class SourceOfWealthService extends BaseJourneyService {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute();
	}

	saveSourceOfWealth(
		sourceOfWealth: SourceOfWealthModel,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<number> {
		const route = this.getRoute(applicantEntityType);
		return <Observable<number>>this.postCustom(route, SourceOfWealthTransformer.toPayload(sourceOfWealth));
	}

	fetchSourceOfWealth(
		applicantId: number,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<SourceOfWealthModel[]> {
		const route = this.getRoute(applicantEntityType);
		return this.getCustom(`${route}/${applicantId}`).pipe(
			map((sourceOfWealth: SourceOfWealthDTO[]) => SourceOfWealthTransformer.fromPayload(sourceOfWealth))
		);
	}

	deleteSourceOfWealth(
		sourceOfWealth: SourceOfWealthModel,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<boolean> {
		const route = this.getRoute(applicantEntityType);
		return this.delete(`${route}/${sourceOfWealth?.applicantId}/${sourceOfWealth?.id}`).pipe(map(() => true));
	}

	private getRoute(applicantEntityType: ApplicantEntityType) {
		switch (applicantEntityType) {
			case ApplicantEntityType.PersonApplicant:
				return `PersonApplicantSourceOfWealth/${this.applicationDataQuery.applicationId()}`;
			case ApplicantEntityType.CompanyApplicant:
				return `CompanyApplicantSourceOfWealth/${this.applicationDataQuery.applicationId()}`;
			case ApplicantEntityType.TrustApplicant:
				return `TrustApplicantSourceOfWealth/${this.applicationDataQuery.applicationId()}`;
			default:
				return `PersonApplicantSourceOfWealth/${this.applicationDataQuery.applicationId()}`;
		}
	}
}
