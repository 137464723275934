import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { OnboardCoapplicantDTO, RefiSecondBorrowerDTO } from '@app/modules/typings/api';
import { catchError, map, Observable, of } from 'rxjs';

import { EligibilityModelDTO } from '../../models/refi-eligibility-dto.model';

@Injectable({
	providedIn: 'root'
})
export class RefiSecondBorrowerService extends BaseJourneyService<EligibilityModelDTO> {
	constructor() {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.CONFIRM_APPLICANT_DETAILS);
	}

	saveSecondBorrowerDetails(model: RefiSecondBorrowerDTO): Observable<OnboardCoapplicantDTO> {
		return this.postCustom('SaveCoapplicant', model).pipe(
			map((response: OnboardCoapplicantDTO) => response),
			catchError(() => of({ status: false } as OnboardCoapplicantDTO))
		);
	}
}
