import { SelectedScenarioDTO as ScenarioSelectDTO } from '@app/modules/typings/api';

export class ScenarioSelectionTransformer {
	static fromPayload(scenarios: ScenarioSelectDTO[], scenarioId?: number): SelectedScenario {
		const selectedScenario = scenarios.find((scenario) =>
			scenarioId ? scenario.id === scenarioId : scenario.hasNominated
		);
		return {
			scenarioId: selectedScenario!.id
		};
	}
}

export interface SelectedScenario {
	scenarioId: number;
}
