import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/modules/shared/service/authentication.service';

@Component({
	selector: 'external-login',
	templateUrl: './external-login.component.html',
	styleUrls: ['./external-login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLoginComponent implements OnInit {
	private otac = '';
	private applicationKey = '';

	constructor(private route: ActivatedRoute, private authService: AuthenticationService) {}

	ngOnInit(): void {
		this.otac =
			(this.route.snapshot.queryParams['otac'] as string) ?? (this.route.snapshot.paramMap.get('token') as string);

		this.applicationKey =
			(this.route.snapshot.queryParams['applicationKey'] as string) ??
			(this.route.snapshot.paramMap.get('applicationKey') as string);
		const page = this.route.snapshot.queryParams['page'] as string | undefined;
		const returnUrl = this.getReturnUrl(page);

		if (this.route.snapshot.queryParams['goToChannelSelection']) {
			this.authService.goToChannelSelection(this.otac, this.route.snapshot.queryParams['channelId'] as number);
		} else {
			this.authService.logInWithOtac(this.otac, returnUrl);
		}
	}

	private getReturnUrl(page: string | undefined): string {
		if (!page) {
			return `${this.applicationKey}`;
		}

		switch (page.toUpperCase()) {
			//TODO: Transform page param to actual path if necessary
			// case 'APPLICATION': {
			// 	return `solutions/applications/${this.applicationId}`;
			// }
			default: {
				return page;
			}
		}
	}
}
