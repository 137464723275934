import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorAdditionalCommentTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorAdditionalCommentModel {
		return {
			comment: payload.comment
		};
	}
}
export interface GuarantorAdditionalCommentModel {
	comment?: string;
}
