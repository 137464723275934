import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	AccountVariations,
	AssetTransaction,
	FinancialInstitution,
	LoanTermUnits,
	PaymentType,
	Proportions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { Address, HomeLoanDTO, PropertyDTO, RentalIncomeDTO } from '@app/modules/typings/api';
import { AddressType, CountryCodeLimited, CurrencyHelper } from '@simpology/client-components/utils';
import { HomeLoanDetails } from '../../financial-position/model/homeloan.model';
import { RentalDetailsModel } from '../../financial-position/model/rental.model';
import { PropertyModalDTO } from '../typings/property-modal';
import { PropertyDetails, PropertyDetailsModelTransformer } from './property-details.model';

export class PropertyModalModelTransformer {
	static toPayload(
		applicationId: number,
		propertyModal: PropertyModalModel,
		saveRentalIncomes: boolean,
		saveHomeLoanDetails: boolean
	): PropertyModalDTO {
		const address =
			propertyModal?.transaction !== AssetTransaction.Purchasing || propertyModal.approvalInPrinciple !== YesNo.Yes
				? propertyModal.address
				: propertyModal.suburbAddress;

		const assetProperty = PropertyDetailsModelTransformer.toPayload(
			applicationId,
			propertyModal.propertyAssetDetails[0],
			propertyModal.transaction,
			address,
			propertyModal.approvalInPrinciple
		);

		const rentalIncomes = saveRentalIncomes
			? (propertyModal.rentalIncomeDetails || []).map((rentalModel) =>
					this.rentalIncomeToPayload(applicationId, propertyModal.transaction, rentalModel)
			  )
			: [];

		const homeLoans = saveHomeLoanDetails
			? (propertyModal.homeLoanDetails || []).map((homeLoan) => this.homeLoanToPayload(applicationId, homeLoan))
			: [];

		return {
			propertyDto: assetProperty,
			rentalIncomeList: rentalIncomes,
			homeLoanList: homeLoans
		} as PropertyModalDTO;
	}

	static fromPayload(propertyDto: PropertyDTO, applicants: ShortApplicant[]): PropertyModalModel {
		if (propertyDto.address && propertyDto.approvalInPrinciple) {
			propertyDto.address.addressType = propertyDto.address.addressType ?? AddressType.Standard;
			propertyDto.address.country = propertyDto.address.country ?? CountryCodeLimited.AU;
		}

		return {
			propertyId: propertyDto.id,
			transaction: propertyDto.transaction,
			approvalInPrinciple: getNullableYesNo(propertyDto.approvalInPrinciple),
			address: !propertyDto.approvalInPrinciple ? propertyDto.address : undefined,
			suburbAddress: propertyDto.approvalInPrinciple ? propertyDto.address : undefined,
			propertyAssetDetails: [PropertyDetailsModelTransformer.fromPayload(propertyDto, applicants)],
			rentalIncomeDetails: [],
			homeLoanDetails: [],
			showFullModal: true,
			isAIPProperty: propertyDto.transaction === AssetTransaction.Purchasing && propertyDto.approvalInPrinciple === true
		} as PropertyModalModel;
	}

	private static rentalIncomeToPayload(
		applicationId: number,
		transaction: AssetTransaction,
		rentalDetails: RentalDetailsModel
	): RentalIncomeDTO {
		if (rentalDetails.address) {
			rentalDetails.address.applicationId = applicationId;
		}
		const propertyValue = String(rentalDetails.propertyValue?.amount);
		const grossIncomeAmount = String(rentalDetails.grossIncomeAmount?.amount) || '';
		const grossIncomeRentalFrequency = rentalDetails.grossIncomeAmount?.frequency || undefined;
		const rentalStartDate = rentalDetails.startDate ?? undefined;

		return {
			id: rentalDetails.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			applicantId: undefined,
			address: rentalDetails.address, // TODO: this is not necessary. can be removed refine later
			propertyEvaluationType: rentalDetails.propertyValue?.frequency,
			estimatedValue: CurrencyHelper.unformatAmount(propertyValue),
			hasMortgage: getNullableBoolean(rentalDetails.existingMortgage),
			primaryUsage: rentalDetails.primaryUse!,
			residentialType: rentalDetails.propertyTypeResidential,
			commercialType: rentalDetails.propertyTypeCommercial,
			industrialType: rentalDetails.propertyTypeIndustrial,
			ruralType: rentalDetails.propertyTypeRural,
			toBeUsedAsSecurity: getNullableBoolean(rentalDetails.useAsSecurity),
			rentalTerm: rentalDetails.rentalType,
			governmentBenefitsType: rentalDetails.governmentBenefitsType,
			evidenceOfTenancy: rentalDetails.evidenceOfTenancy,
			grossRentalAmount: CurrencyHelper.unformatAmount(grossIncomeAmount),
			grossRentalFrequency: grossIncomeRentalFrequency,
			netRentalAmount: rentalDetails.netIncomeAmount?.amount,
			netRentalFrequency: rentalDetails.netIncomeAmount?.frequency,
			startDate: rentalStartDate,
			propertyPartType: rentalDetails.propertyPartType,
			isFuture: transaction === AssetTransaction.Purchasing
		};
	}

	private static homeLoanToPayload(applicationId: number, homeLoanDetails: HomeLoanDetails): HomeLoanDTO {
		return {
			id: homeLoanDetails.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			description: '',
			financialInstitution: homeLoanDetails.financialInstitution,
			outstandingBalance: homeLoanDetails.outstandingBalance,
			creditLimit: homeLoanDetails.creditLimit,
			availableForRedrawAmount: homeLoanDetails.availableForRedrawAmount,
			repaymentAmount: homeLoanDetails.repayments.amount,
			repaymentFrequency: homeLoanDetails.repayments.frequency,
			percentOwnedProportions: homeLoanDetails.isEqualShare ? Proportions.Equal : Proportions.Specified,
			percentsOwned: homeLoanDetails.percentsOwned,
			address: Object.assign({}, homeLoanDetails.address, { applicationId }),
			remainingTermDuration: +homeLoanDetails.remainingTerm?.number,
			remainingTermUnits: homeLoanDetails.remainingTerm?.frequency,
			interestRate: homeLoanDetails.annualInterestRate,
			originalAmount: homeLoanDetails.loanAmount,
			negativelyGeared: homeLoanDetails.negativelyGeared,
			accountNumber: homeLoanDetails.accountNumber,
			accountName: homeLoanDetails.accountName,
			bsb: homeLoanDetails.bsb,
			otherFIName:
				homeLoanDetails.financialInstitution === FinancialInstitution.Other ? homeLoanDetails.otherFIName : undefined,
			hasArrears: getNullableBoolean(homeLoanDetails.hasArrears),
			arrearsAmount: homeLoanDetails.hasArrears ? homeLoanDetails.arrearsAmount : undefined,
			arrearsNumberOfMissedPayments: homeLoanDetails.hasArrears
				? homeLoanDetails.arrearsNumberOfMissedPayments
				: undefined,
			closingOnSettlement: getNullableBoolean(homeLoanDetails.clearingBalanceOnSettlement),
			clearingFromThisLoan: getNullableBoolean(homeLoanDetails.clearingFromThisLoan),
			clearingThisLiability: homeLoanDetails.clearingThisLiability,
			refinanceAmount: homeLoanDetails.disbursementAmount,
			refinanceCosts: homeLoanDetails.refinanceCosts,
			liabilitySecurity: { priorityId: homeLoanDetails.securityPriorityId },
			isForeign: homeLoanDetails.isForeign,
			repaymentType: homeLoanDetails.repaymentType,
			mortgageLiabilityType: homeLoanDetails.mortgageLiabilityType,
			originalLoanTerm: homeLoanDetails.originalLoanTerm,
			originalLoanTermUnitsId: homeLoanDetails.originalLoanTerm ? LoanTermUnits.Months : undefined,
			originalPaymentTerm: homeLoanDetails.originalPaymentTerm,
			originalPaymentTypeId: homeLoanDetails.originalLoanTerm ? PaymentType.InterestOnly : undefined,
			originalPaymentTermUnitsId: homeLoanDetails.originalLoanTerm ? LoanTermUnits.Months : undefined,
			originalSettlementDate: homeLoanDetails.originalSettlementDate,
			accountVariations: this.setLimitIncrease(homeLoanDetails.limitIncrease, homeLoanDetails.accountVariations)
		};
	}

	private static setLimitIncrease(limitIncrease?: YesNo, accountVariations?: AccountVariations[]): AccountVariations[] {
		if (!limitIncrease) {
			return accountVariations?.filter((x) => x !== AccountVariations.LimitIncrease) || [];
		}
		const updatedAccountVariations: AccountVariations[] = accountVariations || [];
		if (updatedAccountVariations.length || !updatedAccountVariations.includes(AccountVariations.LimitIncrease)) {
			updatedAccountVariations.push(AccountVariations.LimitIncrease);
		}
		return [AccountVariations.LimitIncrease];
	}
}

export interface PropertyModalModel {
	propertyId?: number;
	transaction: AssetTransaction;
	approvalInPrinciple: YesNo;
	address: Address;
	suburbAddress?: Address;
	propertyAssetDetails: PropertyDetails[];
	rentalIncomeDetails: RentalDetailsModel[];
	homeLoanDetails: HomeLoanDetails[];
	showFullModal?: boolean;
	isAIPProperty?: boolean; // Used when editing a property, to keep AIP status without changing until save changes
}
