import { BusinessStructureFull, TargetType } from '@app/modules/shared/enums/app.enums';
import { RelatedPartyDTO } from '../typings/applicants';
import { RelatedPartyType } from './../../shared/enums/app.enums';
import { ApplicantEnumObject } from './../../shared/enums/enum-helper';
import { FormEnumsQuery } from './../../shared/store/form-enums/form-enums.query';
import { RelatedCompanyModel } from './related-company.model';
import { RelatedPersonModel } from './related-person.model';

export interface RelatedPartyModel {
	id?: number;
	applicationId?: number;
	applicantId?: number;
	type?: number;
	existingSelect: ApplicantEnumObject;
	relatedPerson?: RelatedPersonModel;
	relatedCompany?: RelatedCompanyModel;
	poaType?: number;
	businessStructureId?: BusinessStructureFull;
	tentativeRelatedPerson?: ApplicantEnumObject; // this will keep the related person/company details temporary until page get refreshed
}

export class RelatedPartyModelTransformer {
	static toPayload(relatedParty: RelatedPartyModel, applicationId: number): RelatedPartyDTO {
		const relatedPartyDto = {
			applicationId,
			applicantId: relatedParty.applicantId,
			relatedPartyType: relatedParty.type
		} as RelatedPartyDTO;

		const tempRelatedPersonId = relatedParty?.tentativeRelatedPerson?.id ?? -1;
		const existingApplicantId = relatedParty?.existingSelect?.id;

		const applicantType = RelatedPartyModelTransformer.getExistingApplicantType(
			relatedParty?.existingSelect,
			relatedParty.type!
		);

		relatedPartyDto.personCompanyType = applicantType;
		if (existingApplicantId && existingApplicantId > 0) {
			relatedPartyDto.personCompanyId = existingApplicantId;
		} else if (applicantType === TargetType.PersonApplicant || applicantType === TargetType.RelatedPerson) {
			relatedPartyDto.firstName = relatedParty.existingSelect?.firstName;
			relatedPartyDto.lastName = relatedParty.existingSelect?.lastName;
		} else if (applicantType === TargetType.CompanyApplicant || applicantType === TargetType.RelatedCompany) {
			relatedPartyDto.companyName = relatedParty?.existingSelect?.companyName;
		}
		if (relatedParty.type === RelatedPartyType.PoaHolder) {
			relatedPartyDto.poaType = relatedParty.poaType;
		}

		if (!relatedPartyDto.personCompanyId && tempRelatedPersonId > 0) {
			relatedPartyDto.personCompanyId = tempRelatedPersonId;
		}

		return relatedPartyDto;
	}

	static fromPayload(relatedPartyDto: RelatedPartyDTO, formEnumsQuery: FormEnumsQuery): RelatedPartyModel {
		const relatedParty = {
			id: relatedPartyDto.relatedPartyId,
			applicantId: relatedPartyDto.applicantId,
			type: relatedPartyDto.relatedPartyType
		} as RelatedPartyModel;

		const applicantEnumObject = {
			id: relatedPartyDto.personCompanyId,
			type: relatedPartyDto.personCompanyType
		} as ApplicantEnumObject;

		let existingParty = formEnumsQuery.getExistingPartiesById(applicantEnumObject);
		if (!existingParty) {
			existingParty = {
				id:
					relatedPartyDto.relatedPartyType === RelatedPartyType.Accountant ||
					relatedPartyDto?.personCompanyType === TargetType.RelatedPerson
						? -1
						: -2,
				type: relatedPartyDto.personCompanyType!
			};

			// Keep newly created related person/company in temporary until page get refreshed
			relatedParty.tentativeRelatedPerson = applicantEnumObject;
		}
		relatedParty.existingSelect = existingParty;
		relatedParty.poaType = relatedPartyDto.poaType;

		return relatedParty;
	}

	static getExistingApplicantType(
		existingApplicant: ApplicantEnumObject | undefined,
		relatedPartyType: RelatedPartyType
	): TargetType | undefined {
		if (existingApplicant?.type) {
			return existingApplicant.type;
		}
		switch (relatedPartyType) {
			case RelatedPartyType.PoaHolder:
			case RelatedPartyType.Solicitor:
				return existingApplicant?.id === -1
					? TargetType.RelatedPerson
					: existingApplicant?.id === -2
					? TargetType.RelatedCompany
					: undefined;
			case RelatedPartyType.Accountant:
				return existingApplicant?.id === -2 ? TargetType.RelatedCompany : undefined;
		}
		return undefined;
	}
}
