<simp-input
	[id]="uniqueId"
	[placeholder]="to.placeholder!"
	[formControl]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[editable]="to.editable"
	[actionIcon]="actionIcon"
	[tooltipContent]="to.tooltip"
	(editClick)="handleEdit()"
	[readonly]="!!to.readonly"
	(enterKeyPress)="handleActionIconClick()"
	(actionIconClick)="handleActionIconClick($event)"
	metadataKey
	[formlyField]="field"
	>{{ to.label }}
</simp-input>
