import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { PersonApplicantDTO, RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { EligibilityModelDTO } from './refi-eligibility-dto.model';

export class RefiEligibilityTransformer {
	static toPayload(model: EligibilityModel, applicationId: number): EligibilityModelDTO {
		return {
			applicationId: applicationId,
			realEstateAssetId: model.realEstateAssetId,
			guarantorOnLoan: getNullableBoolean(model.guarantorOnLoan),
			incomeSource: model.incomeSource,
			loanForConstruction: getNullableBoolean(model.loanForConstruction),
			numberOfApplicants: model.numberOfApplicants
		};
	}

	static fromPayload(
		data: EligibilityModelDTO,
		summary: { summaryDetails: RefiApplicationSummaryDTO; applicants: PersonApplicantDTO[] },
		applicationId: number,
		readonly = false
	): EligibilityModel {
		return {
			summary: this.populateSummaryData(summary, data),
			numberOfApplicants: data.numberOfApplicants,
			applicationId: applicationId,
			realEstateAssetId: data.realEstateAssetId,
			guarantorOnLoan: getNullableYesNo(data.guarantorOnLoan),
			incomeSource: data.incomeSource,
			loanForConstruction: getNullableYesNo(data.loanForConstruction),
			isEligible: data.isEligible ?? false,
			readonly: readonly,
			isAdminUser: !summary.applicants.some((app) => app.isCurrentUser)
		};
	}

	private static populateSummaryData(
		summary: {
			summaryDetails: RefiApplicationSummaryDTO;
			applicants: PersonApplicantDTO[];
		},
		data: EligibilityModelDTO
	): RefiSummaryModel {
		const privacyConsentedBy = summary.applicants
			.filter((a) => a.privacyActConsentSigned)
			.map((a) => `${a.firstName} ${a.lastName}`);
		return {
			summaryItems: {
				lenderApplicationNumber: summary.summaryDetails.lenderApplicationNumber,
				statusName: summary.summaryDetails.statusName,
				applicants: summary.applicants.map((a) => `${a.firstName} ${a.lastName}`).join(' & '),
				consentedBy: privacyConsentedBy.length === 2 ? 'Both' : privacyConsentedBy.length ? privacyConsentedBy[0] : '--'
			}
		} as RefiSummaryModel;
	}
}
export interface EligibilityModel {
	summary?: RefiSummaryModel;
	applicationId: number;
	realEstateAssetId: number;
	numberOfApplicants: number;
	guarantorOnLoan: YesNo | undefined;
	incomeSource: number;
	loanForConstruction: YesNo | undefined;
	isEligible: boolean;
	readonly: boolean;
	isAdminUser?: boolean;
	existingLoan?: YesNo;
}

interface RefiSummaryModel {
	summaryItems: {
		lenderApplicationNumber: string;
		statusName: string;
		applicants: string;
		consentedBy: string;
	};
}
