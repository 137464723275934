import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { IncomeHeaderSummary, LiabilitiesHeaderSummary } from '@app/modules/shared/model/header-summary.model';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { UntypedFormGroup } from '@angular/forms';
import { EventAction, EventTarget, EventsRepository } from '@app/modules/shared/store/events/events.repository';
import { FormlyUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { isEqual } from 'lodash-es';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'formly-section',
	templateUrl: './formly-section.component.html',
	styleUrls: ['./formly-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySectionComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit, OnDestroy {
	private valueChangeSubject: Subject<any> = new Subject();
	private destroy$: Subject<void> = new Subject();

	private previousValue: unknown;
	headerField: FormlyFieldConfig | undefined = undefined;
	headerForm = new UntypedFormGroup({});
	headerData$: Observable<IncomeHeaderSummary | LiabilitiesHeaderSummary> = of();

	constructor(private simpFormlyHandlerService: SimpFormlyHandlerService, private eventsRepository: EventsRepository) {
		super();
	}

	ngOnInit(): void {
		/**
		 * Watch for changes in each control inside sub section
		 */
		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;
		const key = this.getKey();

		if (this.to.header) {
			this.headerField = this.to.header as FormlyFieldConfig;
			this.headerData$ = this.simpFormlyHandlerService.mapToSectionHeader(key);
		}

		forkJoin([this.destroy$, this.valueChangeSubject.asObservable()]).subscribe(() => {
			this.eventsRepository.dispatchEvent({
				targetName: key,
				targetType: EventTarget.Section,
				action: EventAction.Completed
			});
		});
	}

	ngAfterViewInit(): void {
		this.setupChangeDetection();
	}

	ngOnDestroy(): void {
		this.valueChangeSubject.complete();
		this.destroy$.next();
		this.destroy$.complete();
	}

	trackByFn(index: number, item: FormlyFieldConfig): string | undefined {
		return item.id; // Assuming `id` is the unique identifier property
	}

	private setupChangeDetection() {
		return this.formControl.valueChanges
			.pipe(
				debounceTime(1000),
				takeUntil(this.destroy$),
				filter(() => {
					return (this.formControl.valid || (this.to.saveInvalid as boolean)) && this.formControl.dirty;
				})
			)
			.subscribe((currentValue: unknown) => {
				if (!isEqual(this.previousValue, currentValue)) {
					this.valueChangeSubject.next({
						previousData: this.previousValue,
						data: currentValue
					} as FormlyUpdateEvent<unknown>);
					this.previousValue = currentValue;
				}
			});
	}

	private getKey(): string {
		const key = this.field.key as string;
		return key;
	}
}
