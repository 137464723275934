import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyTable = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'table',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-12')}`,
			templateOptions: {
				...property.templateOptions,
				headers: property.templateOptions?.headers,
				data: property.templateOptions?.data
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
