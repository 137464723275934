import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ENV_CONFIG } from '@app/env-config';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpAuthenticationModule } from '@simpology/authentication';
import { SimpologyComponentModule } from '@simpology/client-components';
import { ToastrModule } from 'ngx-toastr';

import { AuthenticationModule } from '../authentication/authentication.module';
import { OtherModule } from '../other/other.module';
import {
	NgbDateCustomAdapter,
	NgbDateCustomParserFormatter
} from './../shared/helper/ngb-date-custom-parser-formatter';
import { ApplicationSubmittedComponent } from './components/application-submitted/application-submitted.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { LoanAppAreaComponent } from './components/loan-app-area/loan-app-area.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { DisableOnClickDirective } from './directives/disable-on-click.directive';
import { EnumDesc } from './enums/enum-desc.pipe';
import { AnonymousAuthGuard } from './guards/anonymous-auth.guard';
import { ApplicantsGuard } from './guards/applicants.guard';
import { ApplicationGuard } from './guards/application.guard';
import { AuthGuard } from './guards/auth.guard';
import { FeatureFlagGuard } from './guards/feature-flag.guard';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { ApiInterceptor } from './interceptor/api.interceptor';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { MapToKeyPipe } from './pipes/map-to-key.pipe';
import { OverridableForUserPipe } from './pipes/overridable-for-user.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { SortOptionsPipe } from './pipes/sort-options.pipe';
import { JOURNEY_TYPE } from './service/base-journey.service';
import { LoanappXAuthenticationService } from './service/loanappx-authentication.service';

@NgModule({
	declarations: [
		SortOptionsPipe,
		FormatDatePipe,
		EnumDesc,
		ApplicationSubmittedComponent,
		LoanAppAreaComponent,
		SanitizePipe,
		OverridableForUserPipe,
		MapToKeyPipe,
		DisableOnClickDirective,
		SimpleTableComponent,
		CopyToClipboardComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		NgSelectModule,
		SimpologyComponentModule,
		RouterModule,
		AuthenticationModule,
		OtherModule,
		SimpAuthenticationModule.forRoot({
			environment: ENV_CONFIG
		}),
		ToastrModule.forRoot({
			closeButton: true,
			positionClass: 'toast-top-center',
			preventDuplicates: true
		}),
		CarouselModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		NgSelectModule,
		SimpologyComponentModule,
		AuthenticationModule,
		SimpAuthenticationModule,
		SortOptionsPipe,
		FormatDatePipe,
		EnumDesc,
		LoanAppAreaComponent,
		SanitizePipe,
		OverridableForUserPipe,
		MapToKeyPipe,
		DisableOnClickDirective,
		SimpleTableComponent,
		CopyToClipboardComponent,
		CarouselModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		DatePipe,
		DecimalPipe,
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
		{ provide: NgbDateAdapter, useClass: NgbDateCustomAdapter },
		{ provide: JOURNEY_TYPE, useValue: null },
		AuthGuard,
		FeatureFlagGuard,
		ApplicantsGuard,
		UnsavedChangesGuard,
		ApplicationGuard,
		AnonymousAuthGuard,
		LoanappXAuthenticationService
	]
})
export class SharedModule {}
