import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyPercentage = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'percentage',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || '')}`,
			templateOptions: {
				...property.templateOptions
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			defaultValue: property.templateOptions?.defaultValue,
			resetOnHide: !!property.templateOptions?.resetOnHide
		}
	}
});
