import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';
import { CurrentUser } from '../model/current-user.model';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class ChannelUserApiService extends BaseJourneyService<CurrentUser> {
	constructor() {
		super();
		this.setRoute(URL_CONSTANTS.CHANNEL_USER);
	}

	public getCurrentUser(): Observable<CurrentUser> {
		return this.get('');
	}
}
