import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { FormAreas } from '../../typings/form-areas.types';

import { FormMetaData } from './typings/form-metadata';

const createInitialState = (): FormMetaData => {
	return {};
};

@Injectable({ providedIn: 'root' })
export class FormMetaDataStore {
	store = createStore({ name: 'form-metadata' }, withProps<FormMetaData>(createInitialState()));

	getValue() {
		return this.store.getValue();
	}

	update(newState: FormMetaData) {
		this.store.update((state: FormMetaData) => ({ ...state, ...newState }));
	}

	reset() {
		this.store.reset();
	}

	select(selectFunction: ((state: FormMetaData) => any) | FormAreas = (state) => state): Observable<any> {
		if (typeof selectFunction === 'string') {
			return this.store.pipe(select((state) => state[selectFunction]));
		}
		return this.store.pipe(select(selectFunction));
	}
}
