import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService, STORE_KEYS } from '@app/modules/shared/store/form-data/form-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ConfirmConsentInitiationComponent } from '../component/confirm-consent-initiation/confirm-consent-initiation.component';
import { InformedConsentDetails } from '../typings/applicants';

@Injectable({
	providedIn: 'root'
})
export class InformedConsentService extends BaseJourneyService<InformedConsentDetails> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private modalService: NgbModal
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.CONSENT);
	}

	getApplicantConsentState(): Observable<InformedConsentDetails> {
		return this.get(`${this.applicationDataQuery.applicationId()}`).pipe(
			tap((informedConsentDetails) => {
				if (informedConsentDetails) {
					this.formDataService.upsertStateWithAsyncData(STORE_KEYS.INFORMED_CONSENT, of([informedConsentDetails]));
				}
			})
		);
	}

	checkConsentInitiation(): Observable<boolean> {
		if (!this.formDataService.isApplicantsConsentPending(STORE_KEYS.INFORMED_CONSENT)) {
			return of(true);
		}

		const modalRef = this.modalService.open(ConfirmConsentInitiationComponent, {
			backdrop: 'static',
			centered: true,
			keyboard: false,
			size: 'xl',
			animation: true
		});

		return from(modalRef.result).pipe(
			switchMap((result: boolean) => {
				if (result) {
					return this.initiateApplicantConsent().pipe(
						tap(() => this.getApplicantConsentState().subscribe()),
						map(() => true)
					);
				}
				return of(false);
			})
		);
	}

	resubmitConsentRequest(applicationId: number, applicantId: number, additionalMessage?: string): Observable<void> {
		return <Observable<void>>this.postCustom(`ResendRequest/${applicationId}/${applicantId}`, { additionalMessage });
	}

	resetApplicantConsent(applicantId: number): Observable<unknown> {
		return this.postCustom(`ResetConsent/${this.applicationDataQuery.applicationId()}/${applicantId}`, {});
	}

	private initiateApplicantConsent(): Observable<unknown> {
		return this.post(`${this.applicationDataQuery.applicationId()}`, {} as InformedConsentDetails);
	}
}
