import { Injectable } from '@angular/core';
import { FormEnumsService } from '../../shared/store/form-enums/form-enums.service';

import { combineLatest, map, Observable, of, startWith, Subscription, tap } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { EmployerPartner } from '@app/modules/employers/model/employer.model';
import { DocumentInstruction } from '@app/modules/setup/model/document-instructions.model';
import { DocumentInstructionsService } from '@app/modules/setup/services/document-instructions.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { RelatedEntitiesService } from '@app/modules/shared/service/related-entities.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import {
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { EnumObject } from '@simpology/client-components/utils';
import { AddNewPersonCompany, SendDocumentsTo, TargetType } from '../../shared/enums/app.enums';
import { ApplicantEnumObject } from '../../shared/enums/enum-helper';
import { DocumentDeliveryMethod } from '../model/document-delivery-method.model';

@Injectable({ providedIn: 'root' })
export class DocumentInstructionsTransformerService {
	constructor(
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private documentInstructionsService: DocumentInstructionsService,
		private relatedEntitiesService: RelatedEntitiesService,
		private formDataService: FormDataService
	) {}

	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] {
		formlyOnStatusChangedToValid(
			formFields,
			'documentationInstructions.instructions',
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const documentInstructions = field?.model as DocumentInstruction[];
				if (documentInstructions?.length) {
					this.documentInstructionsService.saveDocumentInstructions(documentInstructions[0]);
				}
			}
		);

		this.configureNominatedAuthority(formFields);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'documentationInstructions');

		this.configureDocumentDeliveryMethod(formFields);

		return formFields;
	}

	private configureNominatedAuthority(formFields: FormlyFieldConfig[] | undefined): void {
		const nominatedTemplate = getFormField(formFields, 'documentationInstructions.instructions.existingSelect');
		formlyRegisterHooks(formFields, 'documentationInstructions.instructions.existingSelect', {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField?.templateOptions) {
					const addNewRelatedEnumKey = nominatedTemplate?.templateOptions?.options as unknown as string;

					formField.templateOptions.options = combineLatest([
						this.formEnumsService.fetch(addNewRelatedEnumKey, true),
						this.formEnumsQuery.selectEnumOptions('PersonApplicants'),
						this.formEnumsQuery.selectEnumOptions('CompanyApplicants'),
						this.formEnumsQuery.selectEnumOptions('Trusts'),
						this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies'),
						this.formEnumsQuery.selectEnumOptions('RelatedPersons'),
						this.getDocumentSendToValueChanges(formField.parent)
					]).pipe(
						map(
							([
								fieldOptions,
								personApplicants,
								companyApplicants,
								trusts,
								relatedCompanies,
								relatedPersons,
								sendDocumentsToId
							]) => {
								// Override these value to get the updated value
								sendDocumentsToId = (formField.model as DocumentInstruction)?.sendDocumentsToId;
								fieldOptions = this.formEnumsQuery.getAddOptions(addNewRelatedEnumKey) || [];

								if (
									sendDocumentsToId === SendDocumentsTo.Accountant ||
									sendDocumentsToId === SendDocumentsTo.Solicitor
								) {
									return [
										...fieldOptions.filter((option) => option.id === AddNewPersonCompany.NewCompany),
										...relatedCompanies
									];
								} else if (sendDocumentsToId === SendDocumentsTo.Applicant) {
									return [...personApplicants, ...companyApplicants, ...trusts];
								} else if (sendDocumentsToId === SendDocumentsTo.Other) {
									return [...fieldOptions, ...relatedPersons, ...relatedCompanies];
								}
								return [];
							}
						),
						map((options: EnumObject[]) =>
							options.map((option) => ({
								...option,
								click:
									(option.type as unknown as TargetType) === TargetType.RelatedPerson ||
									(option.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													formField,
													0,
													this.onSaveRelatedEntity
												)
										: null
							}))
						)
					);

					return this.relatedEntitiesService.configureRelatedEntityModal(formField, 0, this.onSaveRelatedEntity);
				}
				return of();
			}
		});
	}

	private getDocumentSendToValueChanges(formField: FormlyFieldConfig | undefined): Observable<number | undefined> {
		const sendDocumentsToIdField = getFormField(formField?.fieldGroup, 'sendDocumentsToId');
		const nominatedAuthorityField = getFormField(formField?.fieldGroup, 'existingSelect');
		return (sendDocumentsToIdField?.formControl?.valueChanges as Observable<number | undefined>)?.pipe(
			startWith(sendDocumentsToIdField?.formControl?.value as number),
			tap((sendDocumentsToId) => {
				if (
					!this.isSelectedNominatedAuthorityValid(
						sendDocumentsToId,
						nominatedAuthorityField?.formControl?.value as ApplicantEnumObject
					)
				) {
					nominatedAuthorityField?.formControl?.setValue(null);
				}
			})
		);
	}

	private onSaveRelatedEntity = (
		partner: EmployerPartner,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(partner.existingSelect);
		return new Subscription();
	};

	private isSelectedNominatedAuthorityValid(
		sendDocumentsToId: SendDocumentsTo | undefined,
		nominatedAuthority: ApplicantEnumObject | undefined
	): boolean {
		if (sendDocumentsToId && nominatedAuthority?.type) {
			switch (sendDocumentsToId) {
				case SendDocumentsTo.Accountant:
				case SendDocumentsTo.Solicitor:
					return nominatedAuthority?.type === TargetType.RelatedCompany;
				case SendDocumentsTo.Other:
					return (
						nominatedAuthority?.type === TargetType.RelatedPerson ||
						nominatedAuthority?.type === TargetType.RelatedCompany
					);
				case SendDocumentsTo.Applicant:
					return (
						nominatedAuthority?.type === TargetType.PersonApplicant ||
						nominatedAuthority?.type === TargetType.CompanyApplicant ||
						nominatedAuthority?.type === TargetType.TrustApplicant
					);
				default:
					return false;
			}
		}
		return false;
	}

	private configureDocumentDeliveryMethod(formFields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(formFields, 'documentationInstructions.documentDeliveryMethod', {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				const documentDeliveryMethodState =
					this.formDataService.getStateData<DocumentDeliveryMethod>('documentDeliveryMethod');
				const documentDeliveryMethod = formField?.model as DocumentDeliveryMethod[];
				if (!documentDeliveryMethodState?.[0]?.id && documentDeliveryMethod?.[0].methodId) {
					documentDeliveryMethod[0].id = CONSTANTS.NEW_ID;
					this.documentInstructionsService.saveDocumentDeliveryMethod(documentDeliveryMethod[0], false);
				}

				return of();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'documentationInstructions.documentDeliveryMethod', (field) => {
			const documentDeliveryMethod = field?.model as DocumentDeliveryMethod[];
			if (documentDeliveryMethod?.length) {
				this.documentInstructionsService.saveDocumentDeliveryMethod(documentDeliveryMethod[0]);
			}
		});
	}
}
