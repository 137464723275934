import { NameTitle } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { CompanyBeneficialOwnersDTO } from '@app/modules/typings/api';

export class CompanyBeneficialOwnersTransformer {
	static fromPayload(
		beneficialOwner: CompanyBeneficialOwnersDTO,
		formEnumsQuery: FormEnumsQuery
	): CompanyBeneficialOwnerModel {
		const title = beneficialOwner.nameTitleId
			? formEnumsQuery.getOptionLabel('NameTitle', beneficialOwner.nameTitleId)
			: '';
		return {
			id: beneficialOwner.id,
			existingSelect: formEnumsQuery.getExistingPartiesById({
				type: beneficialOwner.type,
				id: beneficialOwner.personId
			}),
			fullName: beneficialOwner.firstName ? `${title}. ${beneficialOwner.firstName} ${beneficialOwner.lastName}` : ''
		};
	}

	static toPayload(applicantId: number, beneficialOwner: CompanyBeneficialOwnerModel): CompanyBeneficialOwnersDTO {
		return {
			id: beneficialOwner.id || 0,
			companyApplicantId: applicantId,
			applicationId: beneficialOwner.applicationId || 0,
			type: beneficialOwner.type || beneficialOwner.existingSelect.type,
			personId: beneficialOwner.personId || beneficialOwner.existingSelect.id
		};
	}
}
export interface CompanyBeneficialOwnerModel {
	id?: number;
	type?: number;
	applicationId?: number;
	nameTitleId?: NameTitle;
	existingSelect: ApplicantEnumObject;
	firstName?: string;
	lastName?: string;
	personId?: number;
	fullName?: string;
}
