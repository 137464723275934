import { PaymentType } from '@app/modules/shared/enums/app.enums';
import { EnumObject, FrequencyShort } from '@simpology/client-components/utils';

export class RefiProductSelectTransformer {
	static fromPayload(productDetails: RefiProductSelectDTO[]): RefiProductSelectModel[] {
		const productIds = Array.from(new Set(productDetails.map((product) => product.productId)));
		return productIds.map((id) => {
			const productDetail = productDetails.filter((product) => product.productId === id);
			const variations = productDetail.map((product, index) => ({
				id: index,
				variationId: product.productVariationId,
				interestRate: product.interestRate,
				comparisonRate: product.comparisonRate,
				label: this.getLabel(product),
				specifications: product.productSpecificationSections.map((specification) => ({ specification })),
				selected: product.selected,
				features: product.features,
				indicativeRepayments: product.indicativeRepayments
			}));
			const selectedVariationId = variations.some((variation) => variation.selected)
				? variations.find((v) => v.selected)?.id
				: variations.sort((v) => v.interestRate)[0].id;

			const indicativeRepayments = variations.find((v) => v.id === selectedVariationId)?.indicativeRepayments;

			return {
				productId: id,
				productCode: productDetail[0].productCode,
				productVariations: variations,
				productChannelId: productDetail[0].productChannelId,
				interestRate: variations.find((v) => v.id === selectedVariationId)?.interestRate,
				comparisonRate: variations.find((v) => v.id === selectedVariationId)?.comparisonRate,
				specifications: productDetail[0].productSpecificationSections.map((specification) => ({ specification })),
				productName: productDetails.find((product) => product.productId === id)?.productName,
				loanDetailId: productDetails[0].loanDetailId,
				selected: !!productDetail.find((p) => p.selected),
				selectedProductVariation: selectedVariationId,
				description: productDetail[0].description,
				paymentType: productDetail[0].paymentType,
				features: productDetail[0].features,
				indicativeRepayments: indicativeRepayments,
				repaymentAmount: indicativeRepayments
					? this.getRepaymentAmount(indicativeRepayments.repaymentAmounts)
					: undefined,
				revertRepaymentAmount: indicativeRepayments
					? this.getRevertRepaymentAmount(indicativeRepayments.repaymentAmounts)
					: undefined,
				repaymentFrequency: FrequencyShort.Monthly,
				expanded: false
			};
		}) as RefiProductSelectModel[];
	}

	static toPayload(productModel: RefiProductSelectModel, applicationId: number): RefiSelectedProductDTO {
		return {
			applicationId: applicationId,
			loanDetailId: productModel.loanDetailId,
			lenderChannelId: productModel.productChannelId,
			productId: productModel.productId,
			productCode: productModel.productCode,
			productVariationId: productModel.productVariations[productModel.selectedProductVariation].variationId,
			productName: productModel.productName,
			productNameWithFeature: productModel.productVariations[productModel.selectedProductVariation].label
		};
	}

	static getRepaymentAmount(repaymentAmounts: RepaymentAmountModel[]): number | undefined {
		return repaymentAmounts.find((repayment) => repayment.frequency === FrequencyShort.Monthly)?.repaymentAmount;
	}

	static getRevertRepaymentAmount(repaymentAmounts: RepaymentAmountModel[]): number | undefined {
		return repaymentAmounts.find((repayment) => repayment.frequency === FrequencyShort.Monthly)?.revertRepaymentAmount;
	}

	static getLabel(product: RefiProductSelectDTO): string | undefined {
		if (product.fixedTermYears) {
			return `${product.fixedTermYears} ${product.retailName}`;
		}
		return product.retailName;
	}
}

export interface RefiProductSelectModel {
	productId: number;
	productCode: string;
	productVariations: RefiProductVariationModel[];
	productChannelId: number;
	productName: string;
	selected: boolean;
	selectedProductVariation: number;
	interestRate: number;
	comparisonRate: number;
	specifications: RefiSpecificationNestedWrapper[];
	loanDetailId: number;
	description: string;
	paymentType: PaymentType;
	features: RefiProductFeatures;
	indicativeRepayments: {
		repaymentAmounts: Array<RepaymentAmountModel>;
	};
	repaymentAmount?: number;
	revertRepaymentAmount?: number;
	repaymentFrequency: FrequencyShort;
	expanded: boolean;
}

export interface RefiProductFeatures {
	offset: boolean;
	redraw: boolean;
	rateLock: boolean;
	splitLoan: boolean;
}

export interface RefiProductModel {
	product: RefiProductSelectModel;
}

export interface RefiProducts {
	products: RefiProductModel[];
}

interface RefiSpecificationNestedWrapper {
	specification: RefiSpecification;
}

export interface RefiProductVariationModel extends EnumObject {
	variationId: number;
	interestRate: number;
	comparisonRate: number;
	specifications: RefiSpecificationNestedWrapper[];
	selected: boolean;
	indicativeRepayments: {
		repaymentAmounts: Array<RepaymentAmountModel>;
	};
	features: RefiProductFeatures;
}

interface RefiSpecificationClaus {
	id: number;
	title: string;
	standardClauseId: number;
	publishEnum: number;
	descriptionHtml: string;
	sortOrder: number;
}

interface RefiSpecification {
	id: number;
	productVersionId: number;
	title: string;
	specificationClauses: RefiSpecificationClaus[];
}

export interface RefiProductSelectDTO {
	productId: number;
	productChannelId: number;
	productVariationId: number;
	productName: string;
	productCode: string;
	interestRate: number;
	revertRate?: number;
	comparisonRate: number;
	productNameWithFeature: string;
	productSpecificationSections: RefiSpecification[];
	loanDetailId: number;
	selected: boolean;
	description: string;
	fixedTermYears?: string;
	retailName?: string;
	paymentType: PaymentType;
	features: RefiProductFeatures;
	indicativeRepayments: {
		repaymentAmounts: Array<RepaymentAmountModel>;
	};
}

interface RepaymentAmountModel {
	frequency: FrequencyShort;
	repaymentAmount: number;
	revertRepaymentAmount: number;
}

interface RefiSelectedProductDTO {
	productId: number;
	productCode: string;
	productVariationId: number;
	applicationId: number;
	loanDetailId: number;
	lenderChannelId: number;
	productName: string;
	productNameWithFeature: string;
}
