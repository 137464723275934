import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IncomeHeaderSummary, LiabilitiesHeaderSummary } from '@app/modules/shared/model/header-summary.model';
import { FormStateQuery } from '@app/modules/shared/store/form-state/form-state.query';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, map, of, withLatestFrom } from 'rxjs';

@Component({
	selector: 'formly-header',
	templateUrl: './formly-header.component.html',
	styleUrls: ['./formly-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyHeaderComponent implements OnInit {
	@Input() headerField: FormlyFieldConfig | undefined = undefined;
	@Input() headerModel: IncomeHeaderSummary | LiabilitiesHeaderSummary | null = {};
	@Input() showHeader = false;

	public headerForm = new UntypedFormGroup({});

	options$: Observable<FormlyFormOptions> = of({
		formState: {}
	});

	constructor(private formStateQuery: FormStateQuery) {}

	ngOnInit(): void {
		this.options$ = this.formStateQuery.simpFormState$.pipe(
			withLatestFrom(this.options$),
			map(([simpFormState, state]) => {
				return Object.assign(state, {
					formState: simpFormState
				});
			})
		);
	}
}
