import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { DependantDTO, Household, PersonApplicantDTO } from '@app/modules/typings/api';
import { SimpMultiBadgeSelectItem } from '@simpology/client-components';
import { HouseholdInfo, RelationshipInfo } from '../typings/household';
import { DependantDetailsModel, DependantTransformer } from './dependants.model';

export class HouseholdAndFamilyTransformer {
	static fromPayload(
		applicants: PersonApplicantDTO[],
		households: Household[],
		formEnumsQuery: FormEnumsQuery,
		arrConsentInitiated: boolean[]
	): HouseholdAndFamilyModel {
		const arrHH: HouseholdInfo[] = [];
		const arrRel: RelationshipInfo[] = [];
		households.forEach((household: Household) => {
			arrHH.push(this.householdsFromPayload(household, applicants));
		});
		applicants.forEach((applicant: PersonApplicantDTO, index) => {
			arrRel.push(this.relationshipsFromPayload(applicant, formEnumsQuery, arrConsentInitiated[index]));
		});
		return { household: arrHH, relationship: arrRel } as HouseholdAndFamilyModel;
	}

	static householdsFromPayload(household: Household, applicants: PersonApplicantDTO[]): HouseholdInfo {
		const arrDependants: DependantDetailsModel[] = [];
		household?.dependantInfo?.forEach((dependant: DependantDTO) => {
			arrDependants.push(DependantTransformer.fromPayload(dependant));
		});
		return {
			numberOfAdults: household.numberOfAdults ?? 0,
			numberOfDependants:
				household.numberOfDependants && household.numberOfDependants > 0 ? household.numberOfDependants : '0',
			householdId: household.id ?? -1,
			householdName: household.name ?? '',
			whoLivesHere: this.getSelectedApplicants(applicants, household.id),
			whoLiveHereSummaryText: this.getWhoLivesHereSummaryText(applicants, household.id),
			dependants: arrDependants
		} as HouseholdInfo;
	}

	static relationshipsFromPayload(
		applicant: PersonApplicantDTO,
		formEnumsQuery: FormEnumsQuery,
		consentInitiated: boolean
	): RelationshipInfo {
		return {
			applicantId: applicant.id,
			isPrimary: getNullableYesNo(applicant.primaryApplicant),
			applicantName: `${applicant.firstName} ${applicant.lastName}`,
			maritalStatus: applicant.maritalStatus,
			isPartnerCoApplicant: getNullableYesNo(applicant.isPartnerCoApplicant),
			jointStatementOfPosition: getNullableYesNo(applicant.jointStatementOfPosition),
			newSpouse:
				(applicant.spousePersonId && applicant.spousePersonId > 0) ||
				applicant.spousePersonId === null ||
				!applicant.spousePersonId
					? false
					: true,
			existingSelect: HouseholdAndFamilyTransformer.getExistingParty(applicant, formEnumsQuery),
			spousePersonType: applicant?.spousePersonTypeId,
			spouseEarnsIncome: !!applicant?.spouseEarnsIncome,
			consentInitiated
		} as RelationshipInfo;
	}

	static getExistingParty(applicant: PersonApplicantDTO, formEnumsQuery: FormEnumsQuery) {
		const existingParty = formEnumsQuery.getExistingPartiesById({
			id: applicant.spousePersonId!,
			type: Number(applicant.spousePersonTypeId!)
		});
		return existingParty ?? null;
	}

	static toHouseholdPayload(householdInfo: HouseholdInfo, applicationId: number): Household {
		const householdDto: Household = {
			id: householdInfo.householdId ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			name: householdInfo.householdName,
			numberOfDependants: householdInfo.numberOfDependants,
			numberOfAdults: householdInfo.numberOfAdults
		};
		return householdDto;
	}

	static toRelationshipPayload(relationshipInfo?: RelationshipInfo): Partial<PersonApplicantDTO> {
		return {
			maritalStatus: relationshipInfo?.maritalStatus,
			isPartnerCoApplicant: getNullableBoolean(relationshipInfo?.isPartnerCoApplicant),
			jointStatementOfPosition: getNullableBoolean(relationshipInfo?.jointStatementOfPosition),
			spousePersonId:
				relationshipInfo?.existingSelect && relationshipInfo?.existingSelect?.id > 0
					? relationshipInfo?.existingSelect?.id
					: relationshipInfo?.tentativeRelatedPerson?.id
					? relationshipInfo.tentativeRelatedPerson?.id
					: null,
			spousePersonTypeId:
				relationshipInfo?.existingSelect && relationshipInfo?.existingSelect?.type > 0
					? relationshipInfo?.existingSelect?.type
					: relationshipInfo?.tentativeRelatedPerson?.type
					? relationshipInfo?.tentativeRelatedPerson?.type
					: null,
			spouseEarnsIncome: !!relationshipInfo?.spouseEarnsIncome
		} as Partial<PersonApplicantDTO>;
	}

	static getSelectedApplicants(
		applicants: PersonApplicantDTO[],
		householdId: Number | undefined
	): SimpMultiBadgeSelectItem[] {
		const selectItems: SimpMultiBadgeSelectItem[] = [];
		const selectedApplicantIds: number[] = applicants
			.filter((a) => a.householdId === householdId)
			.map((a) => a.id ?? CONSTANTS.NEW_ID);
		applicants.forEach((applicant) => {
			if (selectedApplicantIds.some((selectedApplicant) => selectedApplicant === applicant.id)) {
				selectItems.push({
					id: applicant.id,
					label: `${applicant.firstName} ${applicant.lastName}`
				});
			}
		});

		return selectItems;
	}

	static getWhoLivesHereSummaryText(applicants: PersonApplicantDTO[], householdId: Number | undefined): string {
		const whoLivesHere = this.getSelectedApplicants(applicants, householdId);
		const arrayOfWhoLivesHere = whoLivesHere?.map((applicant) => {
			return applicant.label;
		});
		return arrayOfWhoLivesHere.join(', ');
	}
}

export interface HouseholdAndFamilyModel {
	household: HouseholdInfo[];
	relationship: RelationshipInfo[];
}
