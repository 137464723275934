import { PropertyEvaluationType } from '@app/modules/shared/enums/app.enums';
import { DigitalWidgetItem, DigitalWidgetStatus } from '@app/modules/shared/typings/digital-widget.types';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { Address, PropertyValuationDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';

export interface ValuationProductOption extends EnumObject {
	orderCode: number;
	name: string;
	description: string;
	costInCents: number;
	referenceSsiId: string;
	supportingDocuments: Object;
}

export interface PropertyValuationProducts {
	valuationProductOptions: ValuationProductOption[];
	hasError: boolean;
	isCompleted: boolean;
}

export interface PropertyValuationsDetails extends DigitalWidgetItem {
	propertyId: number;
	address: string;
	valuationOrderResult: Object;
	hasPolicyChange: boolean;
	orderedValuationType: string;
	updatedValuationType: string;
	showDetailsButton: () => boolean;
}

export interface PropertyValuationDetails extends DigitalWidgetStatus {
	propertyValuationsDetails: PropertyValuationsDetails[];
	showGeneralInfoOnly: boolean;
}

export interface PropertyValuationModel {
	propertyId?: number;
	applicationId: number;
	estimatedValue?: AmountSelect;
	address: Address;
}

export class PropertyValuationModelTransformer {
	static toPayload(propertyValuationModel: PropertyValuationModel): PropertyValuationDTO {
		return {
			propertyId: propertyValuationModel.propertyId,
			applicationId: propertyValuationModel.applicationId,
			address: propertyValuationModel.address
		};
	}

	static fromPayload(propertyValuationDTO: PropertyValuationDTO): PropertyValuationModel {
		return {
			propertyId: propertyValuationDTO.propertyId,
			address: propertyValuationDTO.address,
			applicationId: propertyValuationDTO.applicationId,
			estimatedValue: {
				amount: propertyValuationDTO.estimatedValue!,
				frequency: PropertyEvaluationType.ElectronicValuation
			}
		};
	}
}
