import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { CurrentUser } from '../../model/current-user.model';

@Injectable({ providedIn: 'root' })
export class CurrentUserStore {
	store = createStore({ name: 'currentUser' }, withProps<CurrentUser>({} as CurrentUser));

	updateStore(newState: CurrentUser) {
		this.store.update((state: CurrentUser) => newState);
	}

	getStoreValue(): CurrentUser {
		return this.store.getValue();
	}
}
