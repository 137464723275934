import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'formly-renderer',
	template: `
		<form [formGroup]="modalForm" *ngIf="modalFields">
			<formly-form [form]="modalForm" [model]="model" [fields]="$any(modalFields)" [options]="(options$ | async)!">
			</formly-form>
		</form>
	`,
	styles: [
		`
			.example-component {
				text-align: center;
			}
			.example-component h1 {
				color: blue;
			}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRendererComponent {
	modalFields!: FormlyFieldConfig[];
	modalForm = new UntypedFormGroup({});
	model: unknown = [];
	options$: Observable<FormlyFormOptions> = of({
		formState: {}
	});
}
