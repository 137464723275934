import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Expects model to contain yesNo (0,1) property
 */
@Component({
	selector: 'formly-tick-box',
	templateUrl: './formly-tick-box.component.html',
	styleUrls: ['./formly-tick-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTickBoxComponent extends FieldType<FieldTypeConfig> implements OnInit {
	showTick$ = of(false);
	uniqueId = '';

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		this.showTick$ = this.form.valueChanges.pipe(
			startWith(this.form.value as unknown),
			map((value) => !!(value as { yesNo: boolean })?.yesNo)
		);
	}
}
