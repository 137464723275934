<!-- TODO: fix simp-checkbox to accept dynamic id, then add [id]="id" -->
<simp-checkbox
	[id]="uniqueId"
	[ngClass]="uniqueId"
	[formControl]="$any(formControl)"
	[labelOnSide]="to.labelOnSide!"
	[validationErrors]="field.validation?.messages!"
	[tooltip]="to.tooltip"
	[subText]="to.subText"
	[readonly]="!!to.readonly"
	metadataKey
	[formlyField]="field"
	(checkboxChange)="checkboxChange()"
	><p [innerHTML]="to.label"></p>
</simp-checkbox>
