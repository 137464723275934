import { get } from 'lodash-es';

import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyArea = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	const items = { properties: {} };
	property.properties?.forEach((p) => {
		const nestedProperty = get(childProperties, `properties.${p.key}`, get(childProperties, `${p.key}`));
		Object.assign(items.properties, { [p.key as string]: nestedProperty });
	});
	return {
		type: 'array',
		widget: {
			formlyConfig: {
				type: 'area',
				templateOptions: {
					...property.templateOptions
				},
				expressionProperties: parseExpressionProperties(property)
			}
		},
		items: items as JsonSchema | undefined
	};
};
