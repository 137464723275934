import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AppService } from '@app/app.service';
import { HouseholdAndFamilyTransformer } from '@app/modules/applicants/models/household-and-family.model';
import { PersonResidencyModel } from '@app/modules/applicants/models/residency.model';
import { ApplicantAddressService } from '@app/modules/applicants/services/applicant-address.service';
import { ApplicantsService } from '@app/modules/applicants/services/applicants.service';
import { BankingService } from '@app/modules/applicants/services/banking.service';
import { HouseholdService } from '@app/modules/applicants/services/household.service';
import {
	PersonAddresses,
	PersonApplicant,
	PersonApplicantAPIResponse,
	PreviousAddressModel
} from '@app/modules/applicants/typings/applicants';
import { HouseholdInfo, RelationshipInfo } from '@app/modules/applicants/typings/household';
import { FinancialPositionExpensesService } from '@app/modules/financial-position/financial-position-expenses.service';
import { FinancialPositionService } from '@app/modules/financial-position/financial-position.service';
import {
	ApplicantEmploymentDetailsModel,
	ApplicantEmploymentModel,
	ApplicantPaygModel
} from '@app/modules/financial-position/model/applicant-employment.model';
import { ApplicantIncome, SelfEmploymentModel } from '@app/modules/financial-position/model/self-employment.model';
import { SetupService } from '@app/modules/setup/services/setup.service';
import {
	ForeignTaxAssociationStatus,
	InputActionType,
	KinRelationship,
	MaritalStatus,
	PreferredContactPerson,
	RelatedPartyType,
	ResidencyStatus,
	TargetType,
	TaxResidencyOptions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { EmployersTransformerService } from '@app/modules/shared/transformers/employers/employers-transformer.service';
import { ApplicantsEmploymentTransformerService } from '@app/modules/shared/transformers/employment/applicants-employment-transformer.service';
import { ProofOfIdentityTransformerService } from '@app/modules/shared/transformers/proof-of-identity-transformer.service';
import {
	formlyAddCustomValidator,
	formlyAddItemToArray,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyGenerateLabels,
	formlyOnChange,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { Amount } from '@app/modules/simp-formly/typings/formly-app';
import {
	Address,
	BankDetailsDTO,
	CurrentAddressDTO,
	Household,
	PostSettlementAddressDTO
} from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PhoneDetails, SimpConfirmationDialogService } from '@simpology/client-components';
import {
	AddressType,
	CountryCode,
	EnumObject,
	SimpAddress,
	SimpAddressHelper
} from '@simpology/client-components/utils';
import { cloneDeepWith, isEqual, isNil, lowerCase, merge, startCase, upperFirst } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription, combineLatest, filter, forkJoin, iif, of } from 'rxjs';
import {
	catchError,
	distinctUntilChanged,
	map,
	pairwise,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap
} from 'rxjs/operators';

import { PersonContactDetailsModel } from '@app/modules/applicants/models/contact-details.model';
import { PersonInsurancesModel } from '@app/modules/applicants/models/insurances-model';
import { LendingGuaranteeModel } from '@app/modules/applicants/models/lending-guarantee.model';
import { PersonNextOfKinModel } from '@app/modules/applicants/models/next-of-kin.model';
import { PersonOtherDetailsModel } from '@app/modules/applicants/models/other-details-model';
import { PersonalDetailsModel } from '@app/modules/applicants/models/personal-details.model';
import { RelatedPartyModel } from '@app/modules/applicants/models/related-party.model';
import { SourceOfWealthModel } from '@app/modules/applicants/models/source-of-wealth.model';
import { SourceOfWealthService } from '@app/modules/applicants/services/source-of-wealth.service';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { ApplicantForeignEmploymentModel } from '@app/modules/financial-position/model/applicant-foreign-employment.model';
import { ApplicantSelfEmploymentModel } from '@app/modules/financial-position/model/applicant-self-employment.model';
import { TypeOfIncome } from '@app/modules/financial-position/model/employment.model';
import { NotEmployedModel } from '@app/modules/financial-position/model/notemployed-income.model';
import { DependantDetailsModel } from '../../applicants/models/dependants.model';
import { CONSTANTS } from '../constants/constants';
import { IncomeType } from '../enums/app.enums';
import { getPreviousDay, isDateFuture } from '../helper/date.helper';
import {
	calculateAgeFromDateOfBirth,
	deletedSuccessfullyMessage,
	getFinancialYearStartDate,
	isNullOrUndefined,
	savedSuccessfullyMessage
} from '../helper/util';
import { ForeignTaxAssociationModel, ForeignTaxAssociationTransformer } from '../model/foreign-tax-association.model';
import { AddressService } from '../service/address.service';
import { ForeignTaxAssociationService } from '../service/foreign-tax-association.service';
import { FormArrayDeleteService } from '../service/form-array-delete.service';
import { NotEmployedIncomeService } from '../service/notemployed-income.service';
import { RelatedEntitiesService } from '../service/related-entities.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ApplicationDataService } from '../store/application-data/application-data.service';
import { ChannelSettingQuery } from '../store/channel-setting/channel-setting.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { ApplicantType } from '../store/form-enums/form-enums.store';
import { FormValidationsService } from '../store/form-validations/form-validations.service';
import { SelfEmploymentTransformerService } from './employment/self-employment-transformer.service';
import { TransformerUtilService } from './service/transformer-util.service';

@Injectable({ providedIn: 'root' })
export class ApplicantsTransformerService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private warningMaritalChange = false;

	constructor(
		private appService: AppService,
		private applicantAddressService: ApplicantAddressService,
		private addressService: AddressService,
		private bankingService: BankingService,
		private channelSettingQuery: ChannelSettingQuery,
		private applicantsService: ApplicantsService,
		private applicationDataQuery: ApplicationDataQuery,
		private financialPositionService: FinancialPositionService,
		private formArrayDeleteService: FormArrayDeleteService,
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private householdService: HouseholdService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private datePipe: DatePipe,
		private toastr: ToastrService,
		private transformerUtilService: TransformerUtilService,
		private notEmployedIncomeService: NotEmployedIncomeService,
		private employerTransformerService: EmployersTransformerService,
		private applicantsEmploymentTransformerService: ApplicantsEmploymentTransformerService,
		private proofOfIdentityTransformerService: ProofOfIdentityTransformerService,
		private selfEmploymentTransformerService: SelfEmploymentTransformerService,
		private relatedEntitiesService: RelatedEntitiesService,
		private formValidationsService: FormValidationsService,
		private applicationDataService: ApplicationDataService,
		private financialPositionExpensesService: FinancialPositionExpensesService,
		private setupService: SetupService,
		private foreignTaxAssociationService: ForeignTaxAssociationService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository,
		private sourceOfWealthService: SourceOfWealthService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	cloneSchemaForAllApplicants(fields: FormlyFieldConfig[] | undefined): Observable<FormlyFieldConfig[] | undefined> {
		const applicants = this.applicantsService.getCachedPersonApplicantDetails();
		const applicantsArea = fields?.[0].fieldArray as FormlyFieldConfig;
		if (applicantsArea && applicants) {
			const applicantsSection = cloneDeepWith(getFormField(fields, 'personApplicant') as FormlyFieldConfig);
			const key = applicantsSection.key as string;
			const remainingApplicantFields = applicantsArea.fieldGroup?.splice(2, applicantsArea.fieldGroup?.length);
			const householdSection = cloneDeepWith(getFormField(fields, 'householdAndFamily') as FormlyFieldConfig);
			applicantsArea.fieldGroup = [];

			// Process Household Section
			this.processHouseholdAndFamily(fields, applicants, applicantsArea, householdSection);
			// Process Applicants
			applicants.forEach((applicant, index) => {
				const newSection = cloneDeep(applicantsSection);
				newSection.key = `${key}-${index}`;
				applicantsArea.fieldGroup?.push(newSection);

				this.setDefaultIncomeFYs(fields, index);
				this.handleFYChange(fields, index);
				this.setDefaultYearAddBackLabels(fields, index);
				this.relatedEntitiesService.configureABNLookupForRelatedCompany(
					fields as FormlyFieldConfig[],
					`personApplicant-${index}`
				);
				this.registerHandlers(fields, applicants, index);
			});

			if (remainingApplicantFields) {
				applicantsArea.fieldGroup?.push(...remainingApplicantFields);
			}
			applicants.forEach((applicant, index) => {
				this.relatedEntitiesService.configureABNLookupForRelatedCompany(
					fields as FormlyFieldConfig[],
					`personApplicant-${index}`
				);
			});
		}
		return of(fields);
	}

	handleShowHideSpouseFields(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnChange(formFields, 'householdAndFamily.relationship.maritalStatus', (formField: FormlyFieldConfig) => {
			const newValue = formField.formControl?.value as MaritalStatus;
			const labels = formlyGenerateLabels(formField);
			const stateData = this.simpFormlyHandlerService.getStateData<RelationshipInfo>(`relationship`);
			const applicantIndex: number = formField.parent?.key as number;
			const previousMaritalStatus = stateData[applicantIndex].maritalStatus as MaritalStatus;
			if (newValue && previousMaritalStatus && this.channelSettingQuery.getUseRelationshipForSOP()) {
				const prevIsMarriedOrDefacto = this.isMarriedOrDefacto(previousMaritalStatus);
				const nextIsMarriedOrDefacto = this.isMarriedOrDefacto(newValue);
				if (prevIsMarriedOrDefacto !== nextIsMarriedOrDefacto && this.financialPositionExpensesService.expensesAdded) {
					this.warningMaritalChange = true;

					this.confirmationDialogService
						.warnToProceed(
							labels['changeMaritalConfirmationTitle'],
							labels['changeMaritalConfirmationBody'],
							'Change',
							'Cancel'
						)
						.subscribe((change) => {
							if (change && formField.parent?.parent) {
								this.saveRelationship(formField.parent.parent, applicantIndex);
								this.warningMaritalChange = false;
							} else {
								formField.formControl?.setValue(previousMaritalStatus, { onlySelf: true });
							}
						});
				} else {
					this.warningMaritalChange = false;
				}
			}
		});

		formlyRegisterHooks(formFields, 'householdAndFamily.relationship', {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField && this.channelSettingQuery.getUseRelationshipForSOP()) {
					return this.formValidationsService.triggerValidations$.pipe(
						tap(() => {
							this.applicationDataService.updateHardStopMessage(
								formField.formControl?.invalid ? 'Please provide marital status to proceed' : undefined
							);
						})
					);
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'householdAndFamily.relationship.existingSelect', {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					const applicantName = getFormField(formField.parent?.fieldGroup, 'applicantName');

					if (applicantName && formField.templateOptions && applicantName) {
						const applicantNameModel = applicantName.model as RelationshipInfo;
						const applicantId = applicantNameModel.applicantId;
						const fieldOptions = this.formEnumsQuery.getAddOptions('AddNewPerson'); // temp fix for test today will do proper fix tomorrow 19/07/2023

						const listItems = this.formEnumsQuery.selectEnumOptions('Persons').pipe(
							map((persons) => [
								...fieldOptions,
								...persons
									.filter((listItem) => listItem.id !== applicantId)
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															formField,
															applicantId,
															this.onSaveRelationship.bind(this)
														)
												: null
									}))
							])
						);
						formField.templateOptions.options = listItems;
						return this.relatedEntitiesService.configureRelatedEntityModal(
							formField,
							applicantId,
							this.onSaveRelationship.bind(this)
						);
					}
				}

				return of();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			'householdAndFamily.relationship',
			(field, event: FormlyArrayUpdateEvent<RelationshipInfo>) => {
				if (this.warningMaritalChange) {
					return;
				}
				this.saveRelationship(field, event.index!);
			}
		);
	}

	public processHouseholdAndFamily(
		fields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		applicantsArea: FormlyFieldConfig,
		householdSection: FormlyFieldConfig
	) {
		this.preprocessHouseholdFamilyAndRegisterHandlers(fields, applicants, applicantsArea, householdSection);

		this.addHooksToSaveHouseholdAndFamily(fields);

		// Delete households
		this.handleHouseholdDeleteButtonClick(fields);

		// No need of relationship controllers for single applicant
		this.hideRelationshipControls(fields, applicants);

		this.addDependantDobAndAgeFields(fields);

		this.calculateDependantAge(fields);

		this.handleShowHideSpouseFields(fields);
	}

	private saveRelationship(field: FormlyFieldConfig, applicantIndex: number) {
		const relationshipInfo = cloneDeep(get(field, 'model') as RelationshipInfo[]);

		// Don't save if editing related person
		if (
			relationshipInfo.find((relationship) => relationship.existingSelect?.id === -1 || relationship.relatedPerson?.id)
		) {
			return;
		}
		const applicants = cloneDeep(this.formDataService.getPersonApplicants());
		const householdInfo = this.simpFormlyHandlerService.getStateData<HouseholdInfo>('household');
		const updatedApplicants = applicants.map((applicant, index) => {
			const newApplicant = Object.assign({}, applicant, {
				...applicant,
				relationshipInfo: relationshipInfo[index]
			}) as PersonApplicant;
			householdInfo.forEach((household) => {
				if (household.whoLivesHere?.find((x) => x.id === newApplicant.id)) {
					newApplicant.householdId = household.householdId as number;
				}
			});
			return newApplicant;
		});
		return this.saveApplicantsRelationships([updatedApplicants[applicantIndex]]).subscribe(() => {
			this.toastr.success(savedSuccessfullyMessage('Marital status'));
			this.simpFormlyHandlerService
				.mapToStateData('relationship')
				?.pipe(takeUntil(this.destroy$))
				.subscribe((res) => {
					const householdField = getFormField(field.parent?.fieldGroup, 'household');
					householdField?.formControl?.markAllAsTouched();
					householdField?.formControl?.markAsDirty();
					householdField?.formControl?.updateValueAndValidity();
				});
			this.simpFormlyHandlerService.upsertToStateWithData('relationship', relationshipInfo);

			if (this.channelSettingQuery.getUseRelationshipForSOP()) {
				this.simpFormlyHandlerService.upsertToState(
					`household`,
					this.applicantsService.fetchHouseHoldAndRelationship().pipe(map((data) => data.household))
				);
			}
		});
	}

	private handleHouseholdDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnClick(formFields, 'householdAndFamily.household.delete', (field: FormlyFieldConfig) => {
			const household = field?.model as HouseholdInfo;
			if (!household?.householdId) {
				formlyDeleteFromArray(field);
				return;
			}

			const deleteMessage = field?.parent?.fieldGroup?.find((group) => group?.key === 'deleteConfirmation')
				?.template as string;

			// Bind householdName into the confirmation message
			// Above household variable should be contain householdName in order to process below line
			const confirmMessage = eval(deleteMessage) as string;

			this.confirmationDialogService
				.deleteConfirmation('Delete household?', confirmMessage, 'Delete', 'Cancel')
				.pipe(take(1))
				.subscribe((isDelete) => {
					if (isDelete) {
						const rowIndex = Number(field.parent?.key);
						this.householdService
							.deleteHousehold(household.householdId!)
							.pipe(take(1))
							.subscribe(() => {
								formlyDeleteFromArray(field);
								this.simpFormlyHandlerService.deleteFromState('household', rowIndex);
								this.toastr.success(deletedSuccessfullyMessage(`Household`));
							});
					}
				});
		});
	}

	private isMarriedOrDefacto(status: MaritalStatus) {
		return [MaritalStatus.DeFacto, MaritalStatus.Married].includes(status);
	}

	private addHooksToSaveHouseholdAndFamily(fields: FormlyFieldConfig[] | undefined) {
		// Save households
		// Households should have the ability to be saved without applicants in it, in order to identify them uniquely with id
		// to handle interrelations (deselect one applicant from household when he is selected on the other)
		formlyOnStatusChangedToValid(
			fields,
			'householdAndFamily.household',
			(field, event: FormlyArrayUpdateEvent<Household>) => {
				const model = get(field, `model[${event.index}]`) as HouseholdInfo;
				this.saveHouseholdAndApplicantsIn(model, field, event.index!);
			}
		);
	}

	private saveHouseholdAndApplicantsIn(
		model: HouseholdInfo,
		householdsField: FormlyFieldConfig,
		clickedHouseholdIndex: number
	) {
		const householdDto: Household = HouseholdAndFamilyTransformer.toHouseholdPayload(
			model,
			this.applicationDataQuery.applicationId()
		);

		const households = householdsField?.model as HouseholdInfo[];
		this.householdService
			.saveHousehold(householdDto)
			.pipe(
				switchMap((household: Household) => {
					model.householdId = household.id;
					const wlh = model.whoLivesHere ? model.whoLivesHere.map((a) => a.id) : [];

					return wlh.length > 0 ? this.applicantsService.saveApplicantsInHousehold(wlh, household.id) : of(-1);
				}),
				switchMap((data) => {
					const dependantInfo: DependantDetailsModel[] = [];
					model?.dependants?.forEach((dependant) => {
						if (dependant) {
							dependantInfo.push(dependant);
						} else {
							dependantInfo.push({
								id: -1,
								householdId: -1
							});
						}
					});

					const dependantsField = getFormField(householdsField?.fieldGroup, `${clickedHouseholdIndex}.dependants`);
					if (!dependantsField?.hide) {
						return this.applicantsService
							.addDependantInfo(dependantInfo, model.householdId!)
							.pipe(catchError(() => of({})));
					} else {
						return of(data);
					}
				}),
				take(1)
			)
			.subscribe((data: any) => {
				const clickedHouseholdApplicants = households?.[clickedHouseholdIndex].whoLivesHere;
				if (data !== -1 && clickedHouseholdApplicants) {
					households.forEach((hh, index) => {
						// Remove selected applicant from every other households
						if (index !== clickedHouseholdIndex) {
							const currApplicants = hh.whoLivesHere;
							const newApplicants = currApplicants
								? currApplicants.filter((ca) => !clickedHouseholdApplicants.map((cha) => cha.id).includes(ca.id))
								: [];
							hh.whoLivesHere = newApplicants;
						}
					});
				}
				this.toastr.success(savedSuccessfullyMessage(`Household`));
				this.applicantsService.upsertHouseholds(cloneDeep(households));
			});
	}

	private saveApplicantsRelationships(applicants: PersonApplicant[]): Observable<any[]> {
		return forkJoin(
			applicants.map((applicant) => {
				return this.applicantsService.savePersonApplicant(applicant);
			})
		);
	}

	private hideRelationshipControls(fields: FormlyFieldConfig[] | undefined, applicants: PersonApplicant[]): void {
		const coApplicantField = getFormField(fields, 'householdAndFamily.relationship.isPartnerCoApplicant');
		if (applicants.length === 1 && coApplicantField) {
			this.setHideExpression(coApplicantField, true);
		}
	}

	private preprocessHouseholdFamilyAndRegisterHandlers(
		fields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		applicantsArea: FormlyFieldConfig,
		householdSection: FormlyFieldConfig
	): void {
		applicantsArea?.fieldGroup?.push(householdSection);

		// Add a dummy household name for newly added household (where no household name is there)
		formlyRegisterHooks(fields, `householdAndFamily.household.householdName`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					return field?.parent?.parent?.formControl?.valueChanges.pipe(
						tap((households: HouseholdInfo[]) => {
							if (this.isNewlyAddedHousehold(field)) {
								field.formControl?.patchValue(`Household ${households.length}`);
								field.formControl?.markAsDirty();
							}
						})
					);
				}
				return of();
			}
		});

		formlyRegisterHooks(fields, `householdAndFamily.household`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field?.templateOptions) {
					field.templateOptions.list = !this.channelSettingQuery.getUseRelationshipForSOP();
				}
				return of();
			}
		});

		this.setHouseholdTitle(fields!);
		this.setDependantDOBTitle(fields!);

		// Add Validations
		this.addHouseholdValidations(fields, applicants);
	}

	private isNewlyAddedHousehold(field: FormlyFieldConfig) {
		return (
			field.formControl &&
			!(field.parent?.model as HouseholdInfo)?.householdId &&
			!field.formControl?.value &&
			!field.formControl?.dirty
		);
	}

	private addHouseholdValidations(fields: FormlyFieldConfig[] | undefined, applicants: PersonApplicant[]) {
		// Limit households to max amount of applicants
		const Households = getFormField(fields, 'householdAndFamily.household');
		if (Households) {
			Object.assign(Households, {
				...Households,
				expressionProperties: {
					'templateOptions.maxLength': () => {
						return applicants.length;
					}
				}
			});
		}

		// Hide who lives here field for one applicant
		const whoLivesHere = getFormField(
			(Households?.fieldArray as FormlyFieldConfig)?.fieldGroup as FormlyFieldConfig[],
			'whoLivesHere'
		);
		if (whoLivesHere?.templateOptions) {
			Object.assign(whoLivesHere.templateOptions, {
				...whoLivesHere.templateOptions,
				disabled: applicants.length === 1
			});
		}

		// Custom Validators
		formlyAddCustomValidator(fields, 'householdAndFamily.household', {
			uniqueHouseholdName: {
				expression: (c: UntypedFormControl) => {
					const households = (c.value || []) as HouseholdInfo[];
					const names = households.map((h) => h.householdName);
					// names set size should be equal to array size if no duplicate names are there
					return new Set(names).size === households.length;
				}
			},
			applicantShouldHaveHousehold: {
				expression: (c: UntypedFormControl, field: FormlyFieldConfig) => {
					const households = (field.model || []) as HouseholdInfo[];
					if (!households.length) {
						return true;
					}
					const applicantsInHousehold = households.reduce(
						(prev, curr) => prev.concat(curr?.whoLivesHere?.map((who) => who.id) ?? []),
						[] as number[]
					);
					const applicantsFromState = this.formDataService.getPersonApplicants();

					// Only borrower applicants need to be in a household
					return !applicantsFromState.some(
						(applicant) =>
							applicant.type[0].role === ApplicantType.Borrower && !applicantsInHousehold.includes(applicant.id)
					);
				}
			}
		});
	}

	private registerHandlers(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	): FormlyFieldConfig[] | undefined {
		this.setApplicantNames(formFields, index, applicants[index]);
		this.configureSubSections(formFields, index);
		this.handleIndustryChange(formFields, index);
		this.handleSelfEmployeeIndustryCategoryChange(formFields, index);
		this.configureResidency(formFields, index);
		this.handleDefaultPaygIncomeRow(formFields, index);
		this.handleDefaultValueForPreviousAddress(formFields, index);

		this.handlePostSettlementAddressChange(formFields, index);
		this.handleNameEdit(formFields, index, applicants[index]);
		this.handleDateOfBirthEdit(formFields, index, applicants[index]);
		this.handleEmailEdit(formFields, applicants, index);
		this.handleMobileNumberEdit(formFields, applicants, index);
		this.handleNextOfKin(formFields, applicants, index);

		formlyExtendExpressionProperties(formFields, `personApplicant-${index}`, {
			'templateOptions.subText': () => this.formDataService.getApplicantSubText(applicants[index].id)
		});

		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.addresses.previousAddresses`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = get(field, `model[${event.index}]`) as PreviousAddressModel;

				model.applicantId = applicants[index].id;

				if (model.homeAddress) {
					model.homeAddress.addressType = model.homeAddress.addressType ?? AddressType.Standard;
					model.homeAddress.applicationId = this.applicationDataQuery.applicationId();
				}

				if (model.duration?.length && model.duration.length > 1) {
					this.applicantAddressService.savePreviousAddress(model).subscribe((response) => {
						const personAddressModel = cloneDeep(get(field, `parent.model`) as PersonAddresses);
						const previousAddress = personAddressModel.previousAddresses[event.index as number];
						previousAddress.id = response.id;
						if (previousAddress.homeAddress) {
							previousAddress.homeAddress.id = response.addressId;
						}
						this.simpFormlyHandlerService.updateToState(`addresses-${index}`, personAddressModel, 0);
					});
				}
			}
		);

		formlyOnStatusChangedToValid(formFields, `personApplicant-${index}.addresses.currentAddress`, (field) => {
			const model = get(field, `model`) as CurrentAddressDTO;
			const mailingAddress = get(field, `parent.model.mailingAddressContainer.mailingAddress`) as Address;

			const payload = cloneDeep(model);
			payload.applicantId = applicants[index].id;

			if (mailingAddress?.id && SimpAddressHelper.isValidAddress(mailingAddress as SimpAddress)) {
				payload.mailingAddress = cloneDeep(mailingAddress);
				payload.mailingAddress.addressType = mailingAddress.addressType ?? AddressType.Standard;
				payload.mailingAddress.applicationId = this.applicationDataQuery.applicationId();
			} else {
				payload.mailingAddress = undefined;
			}

			if (payload.homeAddress) {
				payload.homeAddress.addressType = payload.homeAddress.addressType ?? AddressType.Standard;
				payload.homeAddress.applicationId = this.applicationDataQuery.applicationId();
				this.applicantAddressService.saveCurrentAddress(payload).subscribe((response) => {
					const personAddressModel = cloneDeep(get(field, `parent.model`) as PersonAddresses);
					if (personAddressModel.currentAddress.homeAddress) {
						personAddressModel.currentAddress.homeAddress.id = response.homeAddressId;
						personAddressModel.currentAddress.id = response.id;
					}
					this.simpFormlyHandlerService.updateToState(`addresses-${index}`, personAddressModel, 0);
				});
			}
		});

		formlyOnStatusChangedToValid(formFields, `personApplicant-${index}.addresses.postSettlementAddress`, (field) => {
			const model = get(field, `model`) as PostSettlementAddressDTO;

			model.applicantId = applicants[index].id;
			model.applicationId = this.applicationDataQuery.applicationId();

			if (model.homeAddress) {
				model.homeAddress.addressType = model.homeAddress.addressType ?? AddressType.Standard;
				model.homeAddress.applicationId = this.applicationDataQuery.applicationId();
			}

			if (model.mailingAddress) {
				model.mailingAddress.addressType = model.mailingAddress.addressType ?? AddressType.Standard;
				model.mailingAddress.applicationId = this.applicationDataQuery.applicationId();
			}

			this.applicantAddressService.savePostSettlementAddress(model).subscribe((response) => {
				const personAddressModel = cloneDeep(get(field, `parent.model`) as PersonAddresses);
				if (personAddressModel.postSettlementAddress.homeAddress) {
					personAddressModel.postSettlementAddress.homeAddress.id = response.addressId;
				}

				if (personAddressModel.postSettlementAddress.mailingAddress) {
					personAddressModel.postSettlementAddress.mailingAddress.id = response.mailingAddressId;
				}

				this.simpFormlyHandlerService.updateToState(`addresses-${index}`, personAddressModel, 0);
			});
		});

		formlyOnStatusChangedToValid(formFields, `personApplicant-${index}.addresses.mailingAddressContainer`, (field) => {
			const model = get(field, `parent.model.currentAddress`) as CurrentAddressDTO;
			const mailingAddress = get(field, `model.mailingAddress`) as Address;

			const payload = cloneDeep(model);
			const applicant = applicants[index];
			payload.applicantId = applicant.id;
			payload.mailingAddress = cloneDeep(mailingAddress);

			if (payload.homeAddress) {
				payload.homeAddress.addressType = payload.homeAddress.addressType ?? AddressType.Standard;
				payload.homeAddress.applicationId = this.applicationDataQuery.applicationId();
			}

			if (payload.mailingAddress) {
				payload.mailingAddress.addressType = payload.mailingAddress.addressType ?? AddressType.Standard;
				payload.mailingAddress.applicationId = this.applicationDataQuery.applicationId();
				this.applicantAddressService.saveCurrentAddress(payload).subscribe((response) => {
					const personAddressModel = cloneDeep(get(field, `parent.model`) as PersonAddresses);
					personAddressModel.currentAddress.mailingAddress = payload.mailingAddress;
					if (personAddressModel.currentAddress.mailingAddress) {
						personAddressModel.currentAddress.mailingAddress.id = response.mailingAddressId;
						personAddressModel.currentAddress.id = response.id;

						if (!personAddressModel.mailingAddressContainer.mailingAddress) {
							personAddressModel.mailingAddressContainer.mailingAddress = {};
						}
						personAddressModel.mailingAddressContainer.mailingAddress.id = response.mailingAddressId;
					}
					this.simpFormlyHandlerService.updateToState(`addresses-${index}`, personAddressModel, 0);
				});
			}
		});

		this.handlePreviousAddressDeleteButtonClick(formFields, index);

		this.handleDefaultValueForBankDetails(formFields, index);
		this.handleBankDetailsSave(formFields, index, applicants[index]);
		this.handleOtherDetailsSave(formFields, index, applicants[index]);

		this.handleLendingGuaranteeSave(formFields, index, applicants[index]);

		this.handleBankingDeleteButtonClick(formFields, index);

		this.handleDefaultValuesForResidency(formFields, index);

		this.configureSourceOfWealth(formFields, index, applicants[index]);

		this.handleInsuranceDetailsSave(formFields, index, applicants[index]);
		this.handleInsuranceDeleteButtonClick(formFields, index, applicants[index]);

		this.setEmploymentTitle(formFields, index);
		this.setIncomeTitle(formFields, index);
		this.configureOpenEmploymentModal(formFields, index, applicants[index]);

		this.setNotEmployedDuration(formFields, index);

		formlyOnClick(formFields, `personApplicant-${index}.employments.delete`, (field: FormlyFieldConfig) => {
			const employmentModel = field.model as ApplicantEmploymentModel;
			let segmentUrl = '';
			if (employmentModel.id) {
				const typeOfIncome = employmentModel?.employmentDetails?.typeOfIncome.type;
				switch (typeOfIncome) {
					case IncomeType.selfEmployed:
						segmentUrl = 'SelfEmployedIncome';
						break;
					case IncomeType.NotEmployed:
						segmentUrl = 'NotEmployed';
						break;
					case IncomeType.ForeignEmployed:
						segmentUrl = 'ForeignEmployed';
						break;
					default:
						segmentUrl = 'Employment';
				}
			}
			this.formArrayDeleteService.deleteItem(field, segmentUrl, `employments-${index}`).subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage(`Employment`));
			});
		});

		const paygForm = getFormField(formFields, `personApplicant-${index}.employments.employmentDetails.payg`);
		if (paygForm) {
			paygForm.hideExpression = `field?.parent?.formControl?.value?.typeOfIncome?.type !== ${IncomeType.payg}`;
		}

		const selfEmploymentForm = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment`
		);
		if (selfEmploymentForm) {
			selfEmploymentForm.hideExpression = `field?.parent?.formControl?.value?.typeOfIncome?.type !== ${IncomeType.selfEmployed}`;
		}

		this.selfEmploymentTransformerService.configureSelfEmployment(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment`,
			this.saveSelfEmployedBusinessDetails
		);

		this.employerTransformerService.configureEmployerModal(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.payg`
		);

		this.employerTransformerService.configureEmployerModal(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.foreignEmployment`
		);

		const notEmployedForm = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.notEmployed`
		);
		if (notEmployedForm) {
			notEmployedForm.hideExpression = `field?.parent?.formControl?.value?.typeOfIncome?.type !== ${IncomeType.NotEmployed}`;
		}

		const foreignEmployedForm = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.foreignEmployment`
		);
		if (foreignEmployedForm) {
			foreignEmployedForm.hideExpression = `field?.parent?.formControl?.value?.typeOfIncome?.type !== ${IncomeType.ForeignEmployed}`;
		}

		const latestIncomeField = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.latestIncome`
		);
		if (latestIncomeField) {
			Object.assign(latestIncomeField, {
				...latestIncomeField,
				expressionProperties: {
					'templateOptions.label': (model: Amount, formState: unknown, field: FormlyFieldConfig) => {
						const fyCurrentField = getFormField(field.parent?.fieldGroup, 'addBacks.fyCurrent');
						if (fyCurrentField?.expressionProperties) {
							fyCurrentField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery.getOptions('FinancialYear').find((option) => option.id === model.frequency?.id)
										?.label
							};
						}
						const fyPreviousField = getFormField(field.parent?.fieldGroup, 'addBacks.fyPrevious');
						if (fyPreviousField?.expressionProperties) {
							fyPreviousField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery
										.getOptions('FinancialYear')
										.find((option) => option.id === model.frequency?.id - 1)?.label
							};
						}
						const fySecondPreviousField = getFormField(field.parent?.fieldGroup, 'addBacks.fySecondPrevious');
						if (fySecondPreviousField?.expressionProperties) {
							fySecondPreviousField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery
										.getOptions('FinancialYear')
										.find((option) => option.id === model.frequency?.id - 2)?.label
							};
						}
						return latestIncomeField.templateOptions?.label;
					}
				}
			});
		}

		this.configureRelatedParty(formFields, applicants, index);

		this.proofOfIdentityTransformerService.transformMetadata(formFields, index, applicants[index]);

		this.validateBusinessStartDate(formFields, index);

		return formFields;
	}

	private handleDefaultValueForPreviousAddress(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const previousAddressDurationFormField = getFormField(
			formFields,
			`personApplicant-${index}.addresses.previousAddresses.duration`
		);
		const addressRequiredTillYears = get(
			previousAddressDurationFormField,
			'templateOptions.defaultDurationInYears',
			5
		) as number;
		if (previousAddressDurationFormField) {
			Object.assign(previousAddressDurationFormField, {
				...previousAddressDurationFormField,
				hooks: {
					onInit: (field: FormlyFieldConfig) => {
						const durationControl = field.formControl;
						const durationValue = durationControl?.value as string[];
						if (!durationValue) {
							const endDate = this.getDefaultEndDate(index);
							if (endDate.length > 0) {
								const startDate = this.getDefaultStartDate(endDate, addressRequiredTillYears);
								durationControl?.setValue([startDate, endDate]);
							}
						}
						return of();
					}
				}
			});
		}
	}

	private getDefaultStartDate(endDate: string, addressRequiredTillYears: number): string {
		const date = new Date();
		date.setFullYear(date.getFullYear() - addressRequiredTillYears);
		if (endDate) {
			const endDateParts = endDate.split('-').map(function (item) {
				return parseInt(item, 10);
			});
			const endDateAsDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2]);
			if (endDateAsDate > date) {
				return this.datePipe.transform(date, 'yyyy-MM-dd') ?? endDate;
			}
		}

		return endDate;
	}

	private getDefaultEndDate(index: number): string {
		const applicantAddressSnapShot: PersonAddresses = this.simpFormlyHandlerService.getApplicantAddresses(index);

		if (applicantAddressSnapShot) {
			if (applicantAddressSnapShot.previousAddresses) {
				const lastAddress =
					applicantAddressSnapShot.previousAddresses[applicantAddressSnapShot.previousAddresses.length - 1];

				if (lastAddress?.duration) {
					const lastAddressStartDate = lastAddress.duration[0];
					return getPreviousDay(lastAddressStartDate, this.datePipe);
				}
			}
			if (applicantAddressSnapShot.currentAddress?.startDate) {
				return getPreviousDay(applicantAddressSnapShot.currentAddress.startDate, this.datePipe);
			}
		}
		return '';
	}

	private configureResidency(formFields: FormlyFieldConfig[] | undefined, index: number) {
		formlyRegisterHooks(formFields, `personApplicant-${index}.residency.countryOfForeignTaxResidency`, {
			onInit: (formField: FormlyFieldConfig) => {
				if (formField) {
					const reasonForNotProvidingTINField = getFormField(formField.parent?.fieldGroup, 'reasonForNotProvidingTIN');

					return formField?.formControl?.valueChanges.pipe(
						tap((value) => {
							reasonForNotProvidingTINField?.formControl?.setValue(null);
							if (reasonForNotProvidingTINField?.props) {
								//1039: CountryCode
								//1180: TinNotProvidedReason
								//value for country code requested
								reasonForNotProvidingTINField.props.options = this.formEnumsService.fetchSubEnums(
									'ByCategory',
									1039,
									1180,
									value as number
								);
							}
						})
					);
				}
				return of();
			}
		});
		formlyOnClick(formFields, `personApplicant-${index}.residency.taxResidencyOutsideAu`, (field) => {
			const model = field.model as PersonResidencyModel;
			const applicant = this.formDataService.getPersonApplicantByIndex(index);
			if (model.taxResidencyOutsideAu === TaxResidencyOptions.AustraliaOnly) {
				const foreignTaxResidencyModel = {
					id: model.foreignTaxAssociationId ?? CONSTANTS.NEW_ID,
					targetId: applicant.id,
					targetType: TargetType.PersonApplicant,
					selfCertificationDeclaration: YesNo.No,
					foreignTaxAssociationStatus: ForeignTaxAssociationStatus.None,
					taxResidencyOutsideAu: TaxResidencyOptions.AustraliaOnly
				} as ForeignTaxAssociationModel;

				const payload = ForeignTaxAssociationTransformer.toPayLoad(
					foreignTaxResidencyModel,
					this.applicationDataQuery.applicationId()
				);

				this.foreignTaxAssociationService
					.saveForeignTaxAssociation(payload)
					.pipe(
						map((response: number) => {
							model.foreignTaxAssociationId = response;
							this.simpFormlyHandlerService.updateToState(`applicants-residency-${index}`, model, 0);
						})
					)
					.subscribe();
			}
		});
		const foreignTaxAssociationLabel = getFormField(
			formFields,
			`personApplicant-${index}.residency.foreignTaxAssociationDetails.countryOfForeignTaxResidency`
		)?.props?.label;
		formlyExtendExpressionProperties(
			formFields,
			`personApplicant-${index}.residency.foreignTaxAssociationDetails.countryOfForeignTaxResidency`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					return `${Number(field.parent?.key) + 1} - ${foreignTaxAssociationLabel}`;
				}
			}
		);
	}

	private setNotEmployedDuration(formFields: FormlyFieldConfig[] | undefined, index: number) {
		const duration = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.notEmployed.duration`
		);
		if (duration) {
			Object.assign(duration, {
				...duration,
				expressionProperties: {
					'templateOptions.label': (model: PersonApplicant, formState: unknown, field: FormlyFieldConfig) => {
						const dateStartedField = field.parent?.fieldGroup?.find((f) => f.key === 'dateStarted')?.formControl;
						const dateEndedField = field.parent?.fieldGroup?.find((f) => f.key === 'dateEnded');
						let label = `Duration: ${0} year, ${0} month`;
						if (dateStartedField?.value && dateEndedField?.hide) {
							const endedDate = new Date().toUTCString();
							label = this.notEmployedIncomeService.getDuration(dateStartedField.value, endedDate);
						} else if (dateStartedField?.value && dateEndedField?.formControl?.value) {
							label = this.notEmployedIncomeService.getDuration(
								dateStartedField?.value,
								dateEndedField?.formControl.value
							);
						}
						return label;
					}
				}
			});
		}
	}

	private saveSelfEmployedBusinessDetails = (
		selfEmploymentModel: any,
		index: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		const employmentModel = cloneDeep(field?.parent?.parent?.parent?.model as ApplicantEmploymentModel);
		employmentModel.employmentDetails.selfEmployment = selfEmploymentModel as SelfEmploymentModel;
		this.simpFormlyHandlerService.updateToState(
			`employments-${applicantId}`,
			employmentModel,
			field?.parent?.parent?.parent?.key as number
		);

		return new Subscription();
	};

	private setHideExpression(field: FormlyFieldConfig, hide: boolean) {
		Object.assign(field, {
			...field,
			hideExpression: hide
		});
	}

	private setApplicantNames(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		const personApplicantSection = getFormField(formFields, `personApplicant-${index}`);
		if (personApplicantSection) {
			Object.assign(personApplicantSection, {
				...personApplicantSection,
				expressionProperties: {
					'templateOptions.label': () => {
						return `${index + 1} - ${applicant.personalDetails![0].firstName} ${
							applicant.personalDetails![0].lastName
						}`;
					}
				}
			});
		}
	}

	private configureSubSections(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const subSections = ['personalDetails', 'contactDetails', 'residency'];

		subSections.forEach((subSection: string) => {
			formlyOnStatusChangedToValid(formFields, `personApplicant-${index}.${subSection}`, (field) => {
				const subSectionModel = cloneDeep(
					get(field, 'model') as PersonalDetailsModel[] | PersonContactDetailsModel[] | PersonResidencyModel[]
				);

				const applicant = this.formDataService.getPersonApplicantByIndex(index);
				const model = get(field.parent, 'model') as PersonApplicant;
				const applicantModel = Object.assign({}, applicant, model);
				if (applicantModel.editMode) {
					return;
				}

				const personalDetailsModel = applicantModel.personalDetails?.[0];
				const personalDetailsApplicantFromStore = applicant.personalDetails?.[0];
				//check if personaldetails model does not have last name and consent initiated is true then copy the personal details from store
				if (personalDetailsModel && !personalDetailsModel.lastName) {
					this.copyPersonalDetailsFromStore(personalDetailsModel, personalDetailsApplicantFromStore);
				}
				if (subSection === 'personalDetails') {
					applicantModel.firstName = model.personalDetails?.[0]?.firstName ?? '';
				}
				const relationshipInfo = this.simpFormlyHandlerService.getStateData<RelationshipInfo>('relationship');

				applicantModel.relationshipInfo = relationshipInfo.find(
					(x) => x.applicantId === applicant.id
				) as RelationshipInfo;

				const householdInfo = this.simpFormlyHandlerService.getStateData<HouseholdInfo>('household');

				householdInfo.forEach((household) => {
					if (household.whoLivesHere?.find((x) => x.id === applicantModel.id)) {
						applicantModel.householdId = household.householdId as number;
						return;
					}
				});

				this.applicantsService
					.savePersonApplicant(applicantModel)
					.pipe(
						switchMap((result: PersonApplicantAPIResponse) =>
							iif(
								() => subSection === 'residency',
								this.saveForeignTaxResidency(applicantModel, subSection, index, subSectionModel, field, result),
								of(result)
							)
						)
					)
					.subscribe((result: PersonApplicantAPIResponse) => {
						if (subSection !== 'residency') {
							this.toastr.success(savedSuccessfullyMessage(upperFirst(lowerCase(startCase(subSection)))));
						}
						this.handleSavePersonApplicantSubscription(
							applicantModel,
							subSection,
							index,
							subSectionModel,
							field,
							result
						);
					});
			});
		});
	}

	private copyPersonalDetailsFromStore(
		personalDetailsModel: PersonalDetailsModel | undefined,
		personalDetailsApplicantFromStore: PersonalDetailsModel | undefined
	) {
		if (personalDetailsModel?.consentInitiated && personalDetailsApplicantFromStore) {
			personalDetailsModel.firstName = personalDetailsApplicantFromStore.firstName;
			personalDetailsModel.middleNames = personalDetailsApplicantFromStore.middleNames;
			personalDetailsModel.lastName = personalDetailsApplicantFromStore.lastName;
		}
	}

	private saveForeignTaxResidency(
		applicantModel: PersonApplicant,
		subSection: string,
		index: number,
		subSectionModel:
			| PersonalDetailsModel[]
			| PersonContactDetailsModel[]
			| PersonResidencyModel[]
			| PersonInsurancesModel[],
		field: FormlyFieldConfig,
		result: PersonApplicantAPIResponse
	): Observable<PersonApplicantAPIResponse> {
		const model = subSectionModel[0] as PersonResidencyModel;
		if (model.taxResidencyOutsideAu === TaxResidencyOptions.Yes) {
			const foreignTaxResidencyModel = {
				id: model.foreignTaxAssociationId ?? CONSTANTS.NEW_ID,
				targetId: applicantModel.id,
				targetType: TargetType.PersonApplicant,
				selfCertificationDeclaration: YesNo.No,
				foreignTaxAssociationDetails: model.foreignTaxAssociationDetails
			} as ForeignTaxAssociationModel;

			const payload = ForeignTaxAssociationTransformer.toPayLoad(
				foreignTaxResidencyModel,
				this.applicationDataQuery.applicationId()
			);

			return this.foreignTaxAssociationService.saveForeignTaxAssociation(payload).pipe(
				map((response: number) => {
					model.foreignTaxAssociationId = response;
					return result;
				})
			);
		}
		return of(result);
	}

	private updateNames(
		applicantModel: PersonApplicant,
		index: number,
		subSectionModel:
			| PersonalDetailsModel[]
			| PersonContactDetailsModel[]
			| PersonResidencyModel[]
			| PersonInsurancesModel[],
		field: FormlyFieldConfig
	) {
		const subSectionPersonalDetailsModel = subSectionModel[0] as PersonalDetailsModel;
		const storeData = cloneDeep(this.formDataService.getSetupApplicants());
		const personIndex = storeData.findIndex((x) => x.id === applicantModel.id);
		if (
			(storeData[personIndex].firstName !== subSectionPersonalDetailsModel.firstName &&
				subSectionPersonalDetailsModel.firstName !== undefined) ||
			(storeData[personIndex].lastName !== subSectionPersonalDetailsModel.lastName &&
				subSectionPersonalDetailsModel.lastName !== undefined)
		) {
			storeData[personIndex].firstName = subSectionPersonalDetailsModel.firstName;
			storeData[personIndex].lastName = subSectionPersonalDetailsModel.lastName;
			this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[personIndex], personIndex);

			const section = field.parent;
			const name = `${subSectionPersonalDetailsModel.firstName} ${subSectionPersonalDetailsModel.lastName}`;

			// Update section title
			if (section?.templateOptions) {
				section.templateOptions.label = `${index + 1} - ${name}`;
			}

			// Refresh relationships
			this.applicantsService.refreshRelationships().subscribe();

			// Refresh header
			this.appService.applicationTitleInfoChange.next();
		}
	}

	private handleSavePersonApplicantSubscription(
		applicantModel: PersonApplicant,
		subSection: string,
		index: number,
		subSectionModel:
			| PersonalDetailsModel[]
			| PersonContactDetailsModel[]
			| PersonResidencyModel[]
			| PersonInsurancesModel[],
		field: FormlyFieldConfig,
		result: PersonApplicantAPIResponse
	) {
		this.simpFormlyHandlerService.updateToState(`${subSection}-${index}`, subSectionModel[0], 0, {});
		applicantModel = cloneDeep(applicantModel);
		applicantModel.id = result.applicantId;
		applicantModel.securePersonId = result.securePersonId;
		//UPDATE NAMES IF CHANGED
		this.updateNames(applicantModel, index, subSectionModel, field);

		const storeData = cloneDeep(this.formDataService.getSetupApplicants());
		const personIndex = storeData.findIndex((x) => x.id === applicantModel.id);
		if (applicantModel?.personalDetails?.[0].dateOfBirth) {
			storeData[personIndex].dateOfBirth = applicantModel?.personalDetails?.[0].dateOfBirth;
			this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[personIndex], personIndex);
		}
		if (applicantModel.securePersonId ?? 0 > 0) {
			// TODO: Revisit this approach since it's not disable the field immediately but wait for the next model update
			// const emailField = getFormField(field.parent?.fieldGroup as FormlyFieldConfig[], 'contactDetails.email');
			// if (emailField?.templateOptions) {
			// 	emailField.templateOptions.disabled = true;
			// }

			storeData[personIndex].securePersonId = applicantModel.securePersonId;
			this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[personIndex], personIndex);

			this.proofOfIdentityTransformerService.fetchPOIForAplicant(index, applicantModel).subscribe();
		}
	}

	private handlePreviousAddressDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyOnClick(
			formFields,
			`personApplicant-${index}.addresses.previousAddresses.delete`,
			(field: FormlyFieldConfig) => {
				const previousAddressModel = field.model as PreviousAddressModel;
				if (!previousAddressModel?.id) {
					formlyDeleteFromArray(field);
					return;
				}
				const oneLineaddress = SimpAddressHelper.buildAddressLine(previousAddressModel.homeAddress as SimpAddress);

				const message = `The address <b>${oneLineaddress}</b>  will be deleted.<br/><br/>`;

				this.confirmationDialogService
					.deleteConfirmation('Delete Address?', message, 'Delete', 'Cancel')
					.subscribe((result: boolean) => {
						if (result) {
							this.applicantAddressService.deletePreviousAddress(previousAddressModel).subscribe(() => {
								const personAddressModel = cloneDeep(get(field, `parent.parent.parent.model`) as PersonAddresses);
								this.simpFormlyHandlerService.updateToState(`addresses-${index}`, personAddressModel, 0);
								this.addressService.fetchAllAddress();
								formlyDeleteFromArray(field);
							});
						}
					});
			}
		);
	}

	private handleDefaultValueForBankDetails(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const financialInstitutionField = getFormField(formFields, `personApplicant-${index}.banking.financialInstitution`);

		if (financialInstitutionField) {
			Object.assign(financialInstitutionField, {
				...financialInstitutionField,
				hooks: {
					onInit: (field: FormlyFieldConfig) => {
						const bankDetailsModel = field.parent?.parent?.model as BankDetailsDTO[];
						const financialInstitutionControl = field.formControl;
						if (bankDetailsModel.length > 1 && !financialInstitutionControl?.value) {
							const lastAddedFinancialInstitution = bankDetailsModel[bankDetailsModel.length - 2].financialInstitution;
							financialInstitutionControl?.setValue(lastAddedFinancialInstitution);
						}
						return of();
					}
				}
			});
		}
	}

	private handleBankDetailsSave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.banking`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as BankDetailsDTO;

				model.applicantId = applicant.id;
				model.applicationId = this.applicationDataQuery.applicationId();

				this.bankingService.saveBankDetails(model).subscribe((response: number) => {
					model.id = response;
					this.simpFormlyHandlerService.updateToState(`banking-${index}`, model, event.index!, {
						id: response
					});
				});
			}
		);
	}

	private configureSourceOfWealth(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	) {
		this.sourceOfWealthTitleUpdate(formFields, index);
		this.handleSourceOfWealthSave(formFields, index, applicant);
		this.handleSourceOfWealthDeleteButtonClick(formFields, index);
	}

	private sourceOfWealthTitleUpdate(formFields: FormlyFieldConfig[] | undefined, index: number) {
		const sourceOfIncomeTypeField = getFormField(
			formFields,
			`personApplicant-${index}.sourceOfWealth.typeId`
		) as FormlyFieldConfig;
		formlyExtendExpressionProperties(formFields, `personApplicant-${index}.sourceOfWealth.typeId`, {
			'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
				return `${Number(field.parent?.key) + 1} - ${sourceOfIncomeTypeField?.props?.label}`;
			}
		});
	}

	private handleSourceOfWealthDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined, index: number) {
		formlyOnClick(formFields, `personApplicant-${index}.sourceOfWealth.delete`, (field: FormlyFieldConfig) => {
			const sourceOfWealthModel = field.model as SourceOfWealthModel;

			if (!sourceOfWealthModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}

			const rowIndex = Number(field.parent?.key);
			this.sourceOfWealthService.deleteSourceOfWealth(sourceOfWealthModel).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`sourceOfWealth-${index}`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage(`Source of wealth`));
			});
		});
	}

	private handleSourceOfWealthSave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	) {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.sourceOfWealth`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as SourceOfWealthModel;
				model.applicantId = applicant.id;
				this.sourceOfWealthService.saveSourceOfWealth(model).subscribe((id: number) => {
					model.id = id;
					this.simpFormlyHandlerService.updateToState(`sourceOfWealth-${index}`, model, event.index!);
					this.toastr.success(savedSuccessfullyMessage('Source of wealth'));
				});
			}
		);
	}

	private handleInsuranceDetailsSave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.insurances`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as PersonInsurancesModel;
				model.personApplicantId = applicant.id;
				this.applicantsService.savePersonInsurance(model).subscribe((id: number) => {
					model.id = id;
					this.simpFormlyHandlerService.updateToState(`insurances-${index}`, model, event.index!);
				});
			}
		);
	}

	private handleInsuranceDeleteButtonClick(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnClick(formFields, `personApplicant-${index}.insurances.delete`, (field: FormlyFieldConfig) => {
			const insuranceDetailsModel = field.model as PersonInsurancesModel;

			if (!insuranceDetailsModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}

			const rowIndex = Number(field.parent?.key);
			this.applicantsService.deleteInsuranceDetails(insuranceDetailsModel.id, applicant.id).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`insurances-${index}`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage(`Insurance`));
			});
		});
	}

	private handleOtherDetailsSave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.otherDetails`,
			(field, event: FormlyArrayUpdateEvent<PersonOtherDetailsModel>) => {
				const model = event.data;
				model.applicantId = applicant.id;

				this.applicantsService.updateApplicantOtherDetails(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState(`otherDetails-${index}`, model, event.index!, {});
				});
			}
		);
	}

	private handleLendingGuaranteeSave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.lendingGuarantee`,
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as LendingGuaranteeModel;
				this.applicantsService
					.saveLendingGuarantee(model, applicant.id)
					.pipe(switchMap(() => this.setupService.fetchSetupApplicants()))
					.subscribe((data) => {
						this.toastr.success(savedSuccessfullyMessage('Lending guarantee'));
						this.formDataService.upsertData('applicants', data);
						this.applicantsService.refreshRelationships().subscribe();
						this.applicantsService.refreshHouseholds().subscribe();
					});
			}
		);
	}

	private handleBankingDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyOnClick(formFields, `personApplicant-${index}.banking.deleteBankAccount`, (field: FormlyFieldConfig) => {
			const bankDetailsModel = field.model as BankDetailsDTO;
			if (!bankDetailsModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}
			const bankName = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				bankDetailsModel.financialInstitution
			);

			const message = `The account <b>${
				bankDetailsModel.accountNumber ?? ''
			}</b> held with <b>${bankName}</b> will be deleted.`;

			this.confirmationDialogService
				.deleteConfirmation('Delete bank account?', message, 'Delete', 'Cancel')
				.subscribe((result) => {
					if (result) {
						const rowIndex = Number(field.parent?.key);
						this.bankingService.deleteBankDetails(bankDetailsModel.id!).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState(`banking-${index}`, rowIndex);
						});
					}
				});
		});
	}

	private setEmploymentTitle(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const employmentDetailsField = getFormField(formFields, `personApplicant-${index}.employments.employmentDetails`);
		if (employmentDetailsField) {
			Object.assign(employmentDetailsField, {
				...employmentDetailsField,
				expressionProperties: {
					'templateOptions.label': (
						model: ApplicantEmploymentDetailsModel,
						formState: unknown,
						field: FormlyFieldConfig
					) => {
						const applicantEmploymentModel = field.parent?.model as ApplicantEmploymentModel;
						let labelSuffix = '';
						if (applicantEmploymentModel.employmentDetails.extract) {
							labelSuffix = ' - '.concat(
								applicantEmploymentModel.employmentDetails.typeOfIncome.currentEmployment ? 'Current' : 'Past'
							);
						}

						return `${field.parent?.key ? +field.parent?.key + 1 : ''}${labelSuffix}`;
					}
				}
			});
		}
	}

	private setIncomeTitle(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const incomeItemField = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.notEmployed.income.type`
		);
		if (incomeItemField) {
			Object.assign(incomeItemField, {
				...incomeItemField,
				expressionProperties: {
					'templateOptions.label': (model: {}, formState: unknown, field: FormlyFieldConfig) => {
						return `${field.parent?.key ? +field.parent?.key + 1 : ''} - Income type`;
					}
				}
			});
		}
	}

	private configureOpenEmploymentModal(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnClick(formFields, `personApplicant-${index}.employments.employmentDetails`, (field: FormlyFieldConfig) => {
			let employmentDetailsModel = field.formControl?.value as ApplicantEmploymentDetailsModel;
			employmentDetailsModel.applicantId = applicant.id;
			if (employmentDetailsModel.extract) {
				this.openEmploymentModalByType(employmentDetailsModel?.typeOfIncome, field, index, applicant);
				return;
			}

			const parentModalRef = this.simpFormlyModalService.openModal(field, 'typeOfIncome', {
				size: 'md',
				windowClass: 'd-block modal fade show simp-modal-confirm'
			});
			const parentSubscription = parentModalRef.action.subscribe((parentAction) => {
				if (parentAction !== 'submit') {
					parentModalRef.close();
					parentSubscription.unsubscribe();
					return;
				}

				parentModalRef.close();
				parentSubscription.unsubscribe();
				// reassign to fetch current values
				employmentDetailsModel = field.formControl?.value as ApplicantEmploymentDetailsModel;
				this.openEmploymentModalByType(employmentDetailsModel?.typeOfIncome, field, index, applicant);
			});
		});
	}

	private openEmploymentModalByType(
		typeOfIncome: TypeOfIncome,
		field: FormlyFieldConfig,
		index: number,
		applicant: PersonApplicant
	) {
		switch (typeOfIncome?.type) {
			case IncomeType.payg:
				this.openPaygEmploymentModal(field, index, applicant.id);
				break;
			case IncomeType.selfEmployed:
				this.applicantsEmploymentTransformerService.openSelfEmploymentModal(field, index, applicant.id);
				break;
			case IncomeType.NotEmployed:
				this.openNotEmploymentModal(field, index, applicant.id);
				break;
			case IncomeType.ForeignEmployed:
				this.openForeignEmploymentModal(field, index, applicant.id);
				break;
		}
	}

	private openNotEmploymentModal(field: FormlyFieldConfig, index: number, applicantId: number): void {
		const key = Number(field.parent?.key);
		const applicantEmploymentDetailsModel = Object.assign(
			{},
			field.model,
			field.form?.value
		) as ApplicantEmploymentDetailsModel;

		applicantEmploymentDetailsModel.notEmployed = {} as NotEmployedModel;
		applicantEmploymentDetailsModel.notEmployed.ownership = applicantId;

		const ownershipField = getFormField(field.fieldGroup, 'notEmployed.ownership');
		if (ownershipField) {
			ownershipField.formControl?.setValue(applicantEmploymentDetailsModel.notEmployed.ownership);
			ownershipField.formControl?.disable();
		}

		const isCurrentEmployment = applicantEmploymentDetailsModel?.typeOfIncome?.currentEmployment;

		if (!isNullOrUndefined(isCurrentEmployment)) {
			const employmentStatusField = getFormField(field.fieldGroup, 'notEmployed.status');

			if (employmentStatusField && employmentStatusField.templateOptions) {
				const employmentStatusEnumKey = isCurrentEmployment ? 'CurrentEmploymentStatus' : 'PreviousEmploymentStatus';
				employmentStatusField.templateOptions.options = this.formEnumsService.fetch(employmentStatusEnumKey, true);
			}
		}

		const notEmployedModalRef = this.simpFormlyModalService.openModal(field, 'notEmployed', { backdrop: 'static' });

		const subscription = notEmployedModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				notEmployedModalRef.close();
				subscription.unsubscribe();
				return;
			}

			this.financialPositionService
				.saveApplicantNotEmployed(field.parent?.model)
				.subscribe((applicantEmploymentModel: ApplicantEmploymentModel) => {
					notEmployedModalRef.close();
					subscription.unsubscribe();
					this.simpFormlyHandlerService.updateToState(`employments-${index}`, applicantEmploymentModel, key);
				});
		});

		formlyRegisterHooks(field.fieldGroup, `notEmployed.income.type`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					this.updateNotEmployedDynamicSelect(field, formField);
					return formField?.formControl?.valueChanges.pipe(
						tap(() => this.updateNotEmployedDynamicSelect(field, formField))
					);
				}
				return of();
			},
			onDestroy: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					this.updateNotEmployedDynamicSelect(field, formField);
				}
				return of();
			}
		});
	}

	private updateNotEmployedDynamicSelect(field: FormlyFieldConfig, formField: FormlyFieldConfig) {
		this.transformerUtilService.updateDynamicListOfSelectFieldOptions(
			field,
			formField.parent?.parent?.fieldGroup,
			'notEmployed.income',
			'type',
			'NotEmployedIncomeType'
		);
	}

	private openPaygEmploymentModal(field: FormlyFieldConfig, index: number, applicantId: number): void {
		const key = Number(field.parent?.key);
		const applicantEmploymentDetailsModel = Object.assign(
			{},
			field.model,
			field.form?.value
		) as ApplicantEmploymentDetailsModel;
		applicantEmploymentDetailsModel.payg = {} as ApplicantPaygModel;
		applicantEmploymentDetailsModel.payg.ownership = applicantId;

		const ownershipField = getFormField(field.fieldGroup, 'payg.ownership');
		if (ownershipField) {
			ownershipField.formControl?.setValue(applicantEmploymentDetailsModel.payg.ownership);
			ownershipField.formControl?.disable();
		}

		const isCurrentEmployment = applicantEmploymentDetailsModel?.typeOfIncome?.currentEmployment;

		if (!isNullOrUndefined(isCurrentEmployment)) {
			const employmentStatusField = getFormField(field.fieldGroup, 'payg.status');

			if (employmentStatusField && employmentStatusField.templateOptions) {
				const employmentStatusEnumKey = isCurrentEmployment ? 'CurrentEmploymentStatus' : 'PreviousEmploymentStatus';
				employmentStatusField.templateOptions.options = this.formEnumsService.fetch(employmentStatusEnumKey, true);
			}
		}

		const paygModalRef = this.simpFormlyModalService.openModal(field, 'payg', { backdrop: 'static' });
		const subscription = paygModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				paygModalRef.close();
				subscription.unsubscribe();
				return;
			}

			const defaultStartDate = getFinancialYearStartDate(new Date().getFullYear());
			const defaultEndDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

			const startDate =
				(getFormField(field.fieldGroup, 'payg.incomePeriodModal.startDate')?.templateOptions?.defaultValue as string) ??
				defaultStartDate;
			const endDate =
				(getFormField(field.fieldGroup, 'payg.incomePeriodModal.endDate')?.templateOptions?.defaultValue as string) ??
				defaultEndDate;

			this.financialPositionService
				.saveApplicantPaygEmployment(field.parent?.model, startDate, endDate)
				.subscribe((applicantEmploymentModel: ApplicantEmploymentModel) => {
					paygModalRef.close();
					subscription.unsubscribe();
					field.formControl?.parent?.patchValue(applicantEmploymentModel as any);
					this.simpFormlyHandlerService.updateToState(`employments-${index}`, applicantEmploymentModel, key);
				});
		});
	}

	private openForeignEmploymentModal(field: FormlyFieldConfig, index: number, applicantId: number): void {
		const key = Number(field.parent?.key);
		const applicantEmploymentDetailsModel = merge(
			{},
			field.model,
			field.form?.value
		) as ApplicantEmploymentDetailsModel;

		const isCurrentEmployment = applicantEmploymentDetailsModel?.typeOfIncome?.currentEmployment;

		applicantEmploymentDetailsModel.foreignEmployment = {} as ApplicantForeignEmploymentModel;
		applicantEmploymentDetailsModel.foreignEmployment.ownership = applicantId;

		const ownershipField = getFormField(field.fieldGroup, 'foreignEmployment.ownership');
		if (ownershipField) {
			ownershipField.formControl?.setValue(applicantEmploymentDetailsModel.foreignEmployment.ownership);
			ownershipField.formControl?.disable();
		}

		if (!isNullOrUndefined(isCurrentEmployment)) {
			const employmentStatusField = getFormField(field.fieldGroup, 'foreignEmployment.status');

			if (employmentStatusField && employmentStatusField.templateOptions) {
				const employmentStatusEnumKey = isCurrentEmployment ? 'CurrentEmploymentStatus' : 'PreviousEmploymentStatus';
				employmentStatusField.templateOptions.options = this.formEnumsService.fetch(employmentStatusEnumKey, true);
			}
		}

		const foreignEmploymentModalRef = this.simpFormlyModalService.openModal(field, 'foreignEmployment', {
			backdrop: 'static'
		});
		const subscription = foreignEmploymentModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				foreignEmploymentModalRef.close();
				subscription.unsubscribe();
				return;
			}

			this.financialPositionService
				.saveApplicantForeignEmployment(field.parent?.model as ApplicantEmploymentModel)
				.subscribe((applicantEmploymentModel: ApplicantEmploymentModel) => {
					foreignEmploymentModalRef.close();
					subscription.unsubscribe();
					field.formControl?.parent?.patchValue([applicantEmploymentModel]);
					this.simpFormlyHandlerService.updateToState(`employments-${index}`, applicantEmploymentModel, key);
				});
		});
	}

	private handleDefaultValuesForResidency(fields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyRegisterHooks(fields, `personApplicant-${index}.residency.residencyStatus`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					return formField?.formControl?.valueChanges.pipe(
						pairwise(),
						tap(([prevResidencyStatus, residencyStatus]) => {
							if (
								prevResidencyStatus !== residencyStatus &&
								residencyStatus === ResidencyStatus.PermanentlyInAustralia
							) {
								const residencyField = getFormField(formField?.parent?.fieldGroup, 'countryOfResidency');
								residencyField?.formControl?.setValue(residencyField.templateOptions?.defaultValue ?? CountryCode.AU);
								const citizenshipField = getFormField(formField?.parent?.fieldGroup, 'countryOfCitizenship');
								if (isNil(citizenshipField?.formControl?.value)) {
									citizenshipField?.formControl?.setValue(
										citizenshipField.templateOptions?.defaultValue ?? CountryCode.AU
									);
								}
							}
						})
					);
				}
				return of();
			}
		});
	}

	private setDefaultIncomeFYs(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const latestIncome = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.latestIncome`
		);
		if (latestIncome) {
			latestIncome.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[0] };
		}

		const secondLatestIncome = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.secondLatestIncome`
		);
		if (secondLatestIncome) {
			secondLatestIncome.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[1] };
		}

		const thirdLatestIncome = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.thirdLatestIncome`
		);
		if (thirdLatestIncome) {
			thirdLatestIncome.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[2] };
		}

		const netProfitAfterTaxFirst = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.firstNetProfitAfterTax`
		);
		if (netProfitAfterTaxFirst) {
			netProfitAfterTaxFirst.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[0] };
		}

		const netProfitAfterTaxSecond = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.secondNetProfitAfterTax`
		);
		if (netProfitAfterTaxSecond) {
			netProfitAfterTaxSecond.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[1] };
		}

		const netProfitAfterTaxThird = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.thirdNetProfitAfterTax`
		);
		if (netProfitAfterTaxThird) {
			netProfitAfterTaxThird.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[2] };
		}

		const atoGrossIncomeFirst = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.firstAtoGrossIncome`
		);
		if (atoGrossIncomeFirst) {
			atoGrossIncomeFirst.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[0] };
		}

		const atoGrossIncomeSecond = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.secondAtoGrossIncome`
		);
		if (atoGrossIncomeSecond) {
			atoGrossIncomeSecond.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[1] };
		}

		const atoGrossIncomeThird = getFormField(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.thirdAtoGrossIncome`
		);
		if (atoGrossIncomeThird) {
			atoGrossIncomeThird.defaultValue = { frequency: this.formEnumsQuery.getOptions('FinancialYear')[2] };
		}
	}

	private handleFYChange(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const latestFinancialYearField =
			getFormField(formFields, `personApplicant-${index}.employments.employmentDetails.selfEmployment.latestIncome`) ??
			{};
		Object.assign(latestFinancialYearField, {
			...latestFinancialYearField,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const secondLatestIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'secondLatestIncome');
					const thirdLatestIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'thirdLatestIncome');
					const nonRecurringIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'nonRecurringIncome');
					const secondNonRecurringIncomeField = field.parent?.fieldGroup?.find(
						(f) => f.key === 'secondNonRecurringIncome'
					);

					return field.formControl?.valueChanges.pipe(
						startWith(field.formControl?.value),
						filter((latestIncome: Amount) => !!latestIncome.frequency),
						distinctUntilChanged((a: Amount, b: Amount) => isEqual(a.frequency, b.frequency)),
						map((latestIncome: Amount) => {
							const formEnumsFinancialYear = this.formEnumsQuery.getOptions('FinancialYear');
							const latestYear = formEnumsFinancialYear.find((option) => option.id === latestIncome.frequency.id);
							const secondLatestYear = formEnumsFinancialYear.find(
								(option) => option.id === latestIncome.frequency.id - 1
							);
							const thirdLatestYear = formEnumsFinancialYear.find(
								(option) => option.id === latestIncome.frequency.id - 2
							);

							if (secondLatestIncomeField && thirdLatestIncomeField) {
								secondLatestIncomeField.formControl?.patchValue({
									frequency: secondLatestYear
								});

								if (!secondLatestYear) {
									secondLatestIncomeField.formControl?.get('amount')?.patchValue(null);
									secondLatestIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as SelfEmploymentModel).hideSecondAddback = true;
								} else {
									secondLatestIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as SelfEmploymentModel).hideSecondAddback = false;
								}

								thirdLatestIncomeField.formControl?.patchValue({
									frequency: thirdLatestYear
								});

								if (!thirdLatestYear) {
									thirdLatestIncomeField.formControl?.get('amount')?.patchValue(null);
									thirdLatestIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as SelfEmploymentModel).hideThirdAddback = true;
								} else {
									thirdLatestIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as SelfEmploymentModel).hideThirdAddback = false;
								}
							}
							if (nonRecurringIncomeField) {
								nonRecurringIncomeField?.formControl?.patchValue({ frequency: latestYear });
							}

							if (secondNonRecurringIncomeField) {
								secondNonRecurringIncomeField?.formControl?.patchValue({ frequency: secondLatestYear });
							}
						})
					);
				}
			}
		});

		formlyRegisterHooks(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.firstNetProfitAfterTax`,
			{
				onInit: (field: FormlyFieldConfig | undefined) => {
					const secondNetProfitAfterTaxField = field?.parent?.fieldGroup?.find(
						(f) => f.key === 'secondNetProfitAfterTax'
					);
					const thirdNetProfitAfterTaxField = field?.parent?.fieldGroup?.find(
						(f) => f.key === 'thirdNetProfitAfterTax'
					);
					if (secondNetProfitAfterTaxField && thirdNetProfitAfterTaxField) {
						return field?.formControl?.valueChanges.pipe(
							startWith<Amount>(field.formControl?.value as Amount),
							filter((latestIncome: Amount) => !!latestIncome.frequency),
							distinctUntilChanged((a: Amount, b: Amount) => isEqual(a.frequency, b.frequency)),
							map((latestIncome: Amount) => {
								const secondLatestYear = this.formEnumsQuery
									.getOptions('FinancialYear')
									.find((option) => option.id === latestIncome.frequency.id - 1);
								secondNetProfitAfterTaxField.formControl?.patchValue({
									frequency: secondLatestYear
								});
								secondNetProfitAfterTaxField.formControl?.get('frequency')?.disable();

								if (!secondLatestYear) {
									secondNetProfitAfterTaxField.formControl?.get('amount')?.patchValue(null);
									secondNetProfitAfterTaxField.formControl?.get('amount')?.disable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideSecondAddback = true;
								} else {
									secondNetProfitAfterTaxField.formControl?.get('amount')?.enable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideSecondAddback = false;
								}

								const thirdLatestYear = this.formEnumsQuery
									.getOptions('FinancialYear')
									.find((option) => option.id === latestIncome.frequency.id - 2);
								thirdNetProfitAfterTaxField.formControl?.patchValue({
									frequency: thirdLatestYear
								});
								thirdNetProfitAfterTaxField.formControl?.get('frequency')?.disable();

								if (!thirdLatestYear) {
									thirdNetProfitAfterTaxField.formControl?.get('amount')?.patchValue(null);
									thirdNetProfitAfterTaxField.formControl?.get('amount')?.disable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideThirdAddback = true;
								} else {
									thirdNetProfitAfterTaxField.formControl?.get('amount')?.enable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideThirdAddback = false;
								}
							})
						);
					}
					return of();
				}
			}
		);

		formlyRegisterHooks(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.firstAtoGrossIncome`,
			{
				onInit: (field: FormlyFieldConfig | undefined) => {
					const secondAtoGrossIncomeField = field?.parent?.fieldGroup?.find((f) => f.key === 'secondAtoGrossIncome');
					const thirdAtoGrossIncomeField = field?.parent?.fieldGroup?.find((f) => f.key === 'thirdAtoGrossIncome');
					if (secondAtoGrossIncomeField && thirdAtoGrossIncomeField) {
						return field?.formControl?.valueChanges.pipe(
							startWith<Amount>(field.formControl?.value as Amount),
							filter((latestIncome: Amount) => !!latestIncome.frequency),
							distinctUntilChanged((a: Amount, b: Amount) => isEqual(a.frequency, b.frequency)),
							map((latestIncome: Amount) => {
								const secondLatestYear = this.formEnumsQuery
									.getOptions('FinancialYear')
									.find((option) => option.id === latestIncome.frequency.id - 1);
								secondAtoGrossIncomeField.formControl?.patchValue({
									frequency: secondLatestYear
								});
								secondAtoGrossIncomeField.formControl?.get('frequency')?.disable();

								if (!secondLatestYear) {
									secondAtoGrossIncomeField.formControl?.get('amount')?.patchValue(null);
									secondAtoGrossIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideSecondAddback = true;
								} else {
									secondAtoGrossIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideSecondAddback = false;
								}

								const thirdLatestYear = this.formEnumsQuery
									.getOptions('FinancialYear')
									.find((option) => option.id === latestIncome.frequency.id - 2);
								thirdAtoGrossIncomeField.formControl?.patchValue({
									frequency: thirdLatestYear
								});
								thirdAtoGrossIncomeField.formControl?.get('frequency')?.disable();

								if (!thirdLatestYear) {
									thirdAtoGrossIncomeField.formControl?.get('amount')?.patchValue(null);
									thirdAtoGrossIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideThirdAddback = true;
								} else {
									thirdAtoGrossIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as ApplicantSelfEmploymentModel).hideThirdAddback = false;
								}
							})
						);
					}
					return of();
				}
			}
		);
	}

	private setDefaultYearAddBackLabels(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const date = new Date();
		const currentYear = date.getMonth() < 6 ? date.getFullYear() - 2 : date.getFullYear() - 1;

		this.formEnumsService
			.fetch('FinancialYear')
			.pipe(take(1))
			.subscribe((financialYearEnum: EnumObject[]) => {
				this.setDefaultAddBackLabel(
					formFields,
					`personApplicant-${index}.employments.employmentDetails.selfEmployment.addBacks.fySecondPrevious`,
					financialYearEnum,
					currentYear - 2
				);
				this.setDefaultAddBackLabel(
					formFields,
					`personApplicant-${index}.employments.employmentDetails.selfEmployment.addBacks.fyPrevious`,
					financialYearEnum,
					currentYear - 1
				);
				this.setDefaultAddBackLabel(
					formFields,
					`personApplicant-${index}.employments.employmentDetails.selfEmployment.addBacks.fyCurrent`,
					financialYearEnum,
					currentYear
				);
			});
	}

	private setDefaultAddBackLabel(
		formFields: FormlyFieldConfig[] | undefined,
		path: string,
		financialYearEnum: EnumObject[],
		year: number
	) {
		const financialYearField = getFormField(formFields, path);

		if (financialYearField && financialYearField.templateOptions) {
			financialYearField.templateOptions.label = financialYearEnum.find((x) => x.id === year)?.label;
		}
	}

	private handleIndustryChange(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		const paygPath = `personApplicant-${index}.employments.employmentDetails.payg`;
		const industryField = getFormField(formFields, `${paygPath}.industryDivision`) ?? {};
		if (industryField) {
			Object.assign(industryField, {
				...industryField,
				hooks: {
					onInit: (field: FormlyFieldConfig) => {
						const industrySubdivisionField = field.parent?.fieldGroup?.find((f) => f.key === 'industrySubdivision');
						if (industrySubdivisionField) {
							industrySubdivisionField.templateOptions = industrySubdivisionField.templateOptions ?? {};
							return field.formControl?.valueChanges.pipe(
								startWith(field.formControl?.value),
								filter((val) => !!val),
								switchMap((val: number) => this.formEnumsService.fetchSubEnums('IndustryDivision', val)),
								tap((options) => {
									industrySubdivisionField.templateOptions!.options = options;

									const matchingOption = options.find(
										(option) => option.id === industrySubdivisionField.formControl?.value
									);
									if (!matchingOption) {
										industrySubdivisionField.formControl?.setValue(null);
									} else {
										industrySubdivisionField.formControl?.setValue(matchingOption.id);
									}
								})
							);
						}
						return;
					}
				}
			});
		}
	}

	private handleSelfEmployeeIndustryCategoryChange(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyRegisterHooks(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.industryCategory`,
			{
				onInit: (field: FormlyFieldConfig | undefined) => {
					if (field) {
						const industrySubCategoryField = field.parent?.fieldGroup?.find((f) => f.key === 'industrySubCategory');
						if (industrySubCategoryField) {
							industrySubCategoryField.templateOptions = industrySubCategoryField.templateOptions ?? {};
							return field.formControl?.valueChanges.pipe(
								startWith(field.formControl?.value),
								filter((val) => !!val),
								switchMap((val: number) => this.formEnumsService.fetchSubEnums('IndustryDivision', val)),
								tap((options) => {
									if (industrySubCategoryField.templateOptions) {
										industrySubCategoryField.templateOptions.options = options ?? [];
									}
									const matchingOption = options.find(
										(option) => option.id === industrySubCategoryField.formControl?.value
									);
									industrySubCategoryField?.formControl?.setValue(matchingOption?.id ?? null);
								})
							);
						}
					}
					return of();
				}
			}
		);
	}

	private handleDefaultPaygIncomeRow(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyRegisterHooks(formFields, `personApplicant-${index}.employments.employmentDetails.payg.income`, {
			afterViewInit: (field: FormlyFieldConfig | undefined) => {
				// only add if there is no record yet
				if (field && !(field?.model as ApplicantIncome[])?.length) {
					formlyAddItemToArray(field, { type: 1000 });
				}
			}
		});
	}

	private handlePostSettlementAddressChange(formFields: FormlyFieldConfig[] | undefined, index: number) {
		formlyRegisterHooks(formFields, `personApplicant-${index}.addresses.postSettlementAddress.homeAddress`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					const currentAddressControl = field.parent?.parent?.fieldGroup?.find(
						(f) => f.key === 'currentAddress'
					)?.formControl;
					const housingStatusField = field.parent?.fieldGroup?.find((f) => f.key === 'housingStatus');
					return field.formControl?.valueChanges.pipe(
						tap((postSettlementAddress: Address) => {
							const currentAddress = currentAddressControl?.value as CurrentAddressDTO;
							if (
								postSettlementAddress &&
								currentAddress?.homeAddress?.id === postSettlementAddress.id &&
								!(housingStatusField?.model as PostSettlementAddressDTO).housingStatus
							) {
								housingStatusField?.formControl?.setValue(currentAddress?.housingStatus);
							}
						})
					);
				}
				return of();
			}
		});
	}

	private handleNameEdit(formFields: FormlyFieldConfig[] | undefined, index: number, applicant: PersonApplicant): void {
		formlyOnClick(
			formFields,
			`personApplicant-${index}.personalDetails.fullNameWithoutTitle`,
			(field: FormlyFieldConfig) => {
				if (field.parent) {
					const personApplicantModel = field.parent.parent?.parent?.model as PersonApplicant;
					const editNameModal = field.parent.fieldGroup?.find((f) => f.key === 'editName');
					if (editNameModal?.templateOptions) {
						this.disableModal(editNameModal, false);
						personApplicantModel.editMode = true;
						const personalDetailsModel = applicant.personalDetails as PersonalDetailsModel[];
						editNameModal.formControl?.patchValue({
							firstName: personalDetailsModel[0].firstName,
							middleNames: personalDetailsModel[0].middleNames,
							lastName: personalDetailsModel[0].lastName
						});
					}
					const editNameModalRef = this.simpFormlyModalService.openModal(field.parent, 'editName', {
						backdrop: 'static',
						centered: true,
						keyboard: false,
						size: 'md',
						animation: true
					});

					const subscription = editNameModalRef.action.subscribe((action) => {
						if (action !== 'submit') {
							personApplicantModel.editMode = false;
							editNameModalRef.close();
							subscription.unsubscribe();
							this.disableModal(editNameModal, true);
							return;
						}

						const model = (field.model as PersonalDetailsModel).editName;

						this.applicantsService.resetApplicantConsent(applicant.id, index).subscribe(() => {
							this.disableModal(editNameModal, true);

							const personalDetailsModel = personApplicantModel.personalDetails as PersonalDetailsModel[];
							personalDetailsModel[0].firstName = model?.firstName as string;
							personalDetailsModel[0].middleNames = model?.middleNames;
							personalDetailsModel[0].lastName = model?.lastName as string;
							personalDetailsModel[0].consentInitiated = false;
							personApplicantModel.personalDetails = personalDetailsModel;

							const applicantModel = Object.assign(
								{},
								this.formDataService.getPersonApplicantByIndex(index),
								personApplicantModel
							);
							this.applicantsService.savePersonApplicant(applicantModel).subscribe(() => {
								this.toastr.success(savedSuccessfullyMessage('Name'));
								this.simpFormlyHandlerService.upsertToStateWithData(`personalDetails-${index}`, personalDetailsModel);
								this.updateNames(applicantModel, index, personalDetailsModel, field.parent!.parent!);
								personApplicantModel.editMode = false;
							});
							editNameModalRef.close();
						});
					});
				}
			}
		);
	}

	private handleDateOfBirthEdit(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnClick(formFields, `personApplicant-${index}.personalDetails.dateOfBirth`, (field: FormlyFieldConfig) => {
			if (field.parent) {
				const personApplicantModel = field.parent.parent?.parent?.model as PersonApplicant;
				const editDOBModal = field.parent.fieldGroup?.find((f) => f.key === 'editDateOfBirth');
				if (editDOBModal?.templateOptions) {
					this.disableModal(editDOBModal, false);
					personApplicantModel.editMode = true;
					editDOBModal.fieldGroup
						?.find((f) => f.key === 'dateOfBirth')
						?.formControl?.patchValue(field.formControl?.value);
				}
				const editDateOfBirthModalRef = this.simpFormlyModalService.openModal(field.parent, 'editDateOfBirth', {
					backdrop: 'static',
					centered: true,
					keyboard: false,
					size: 'md',
					animation: true
				});

				const subscription = editDateOfBirthModalRef.action.subscribe((action) => {
					if (action !== 'submit') {
						editDateOfBirthModalRef.close();
						subscription.unsubscribe();
						this.disableModal(editDOBModal, true);
						return;
					}

					const model = (field.model as PersonalDetailsModel).editDateOfBirth?.dateOfBirth;
					this.applicantsService.resetApplicantConsent(applicant.id, index).subscribe(() => {
						this.disableModal(editDOBModal, true);
						setTimeout(() => {
							field.formControl?.patchValue(model);
							field.parent?.formControl?.markAsDirty();
							personApplicantModel.editMode = false;
						});

						editDateOfBirthModalRef.close();
					});
				});
			}
		});
	}

	private handleEmailEdit(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	): void {
		this.validateEmail(formFields, applicants, index);
		formlyOnClick(
			formFields,
			`personApplicant-${index}.contactDetails.email`,
			(field: FormlyFieldConfig, clickType: InputActionType) => {
				if (field.parent && clickType === InputActionType.EDIT) {
					const personApplicantModel = field.parent.parent?.parent?.model as PersonApplicant;
					const editEmailModal = field.parent.fieldGroup?.find((f) => f.key === 'editEmail');
					if (editEmailModal?.templateOptions) {
						this.disableModal(editEmailModal, false);
						personApplicantModel.editMode = true;
						editEmailModal.fieldGroup
							?.find((f) => f.key === 'email')
							?.formControl?.patchValue(field.formControl?.value);
					}
					const editEmailModalRef = this.simpFormlyModalService.openModal(field.parent, 'editEmail', {
						backdrop: 'static',
						centered: true,
						keyboard: false,
						size: 'md',
						animation: true
					});

					const subscription = editEmailModalRef.action.subscribe((action) => {
						if (action !== 'submit') {
							editEmailModalRef.close();
							subscription.unsubscribe();
							this.disableModal(editEmailModal, true);
							return;
						}

						const model = (field.model as PersonContactDetailsModel).editEmail?.email;
						this.applicantsService.resetApplicantConsent(applicants[index].id, index).subscribe(() => {
							this.disableModal(editEmailModal, true);
							setTimeout(() => {
								field.formControl?.patchValue(model);
								field.parent?.formControl?.markAsDirty();
								personApplicantModel.editMode = false;
							});
							editEmailModalRef.close();
						});
					});
				}
			}
		);
	}

	private handleMobileNumberEdit(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	): void {
		this.validateMobileNumber(formFields, applicants, index);
		formlyOnClick(
			formFields,
			`personApplicant-${index}.contactDetails.mobilePhoneNumber`,
			(field: FormlyFieldConfig, actionType: InputActionType) => {
				if (field.parent && actionType === InputActionType.EDIT) {
					const personApplicantModel = field.parent.parent?.parent?.model as PersonApplicant;
					const editMobileModal = field.parent.fieldGroup?.find((f) => f.key === 'editMobile');
					if (editMobileModal?.templateOptions) {
						this.disableModal(editMobileModal, false);
						personApplicantModel.editMode = true;
						editMobileModal.fieldGroup
							?.find((f) => f.key === 'mobilePhoneNumber')
							?.formControl?.patchValue(field.formControl?.value);
					}
					const editMobileModalRef = this.simpFormlyModalService.openModal(field.parent, 'editMobile', {
						backdrop: 'static',
						centered: true,
						keyboard: false,
						size: 'md',
						animation: true
					});

					const subscription = editMobileModalRef.action.subscribe((action) => {
						if (action !== 'submit') {
							editMobileModalRef.close();
							subscription.unsubscribe();
							this.disableModal(editMobileModal, true);
							return;
						}

						const model = (field?.parent?.model as PersonContactDetailsModel)?.editMobile?.mobilePhoneNumber;
						this.applicantsService.resetApplicantConsent(applicants[index].id, index).subscribe(() => {
							this.disableModal(editMobileModal, true);
							setTimeout(() => {
								field.formControl?.patchValue(model);
								field.parent?.formControl?.markAsDirty();
								personApplicantModel.editMode = false;
							});
							editMobileModalRef.close();
						});
					});
				}
			}
		);
	}

	private validateEmail(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	): void {
		formlyAddCustomValidator(formFields, `personApplicant-${index}.contactDetails.editEmail.email`, {
			uniqueEmail: {
				expression: (c: UntypedFormControl) => {
					const newEmail = c.value as string;
					const emailAddresses = applicants
						.filter((app) => app.id !== applicants[index].id)
						.map((a) => get(a, 'contactDetails[0].email', ''));
					return !emailAddresses.includes(newEmail);
				}
			},
			noChange: {
				expression: (c: UntypedFormControl) => {
					const applicant = applicants[index];
					const newEmail = c.value as string;
					const currentEmail = String(applicant.contactDetails ? applicant.contactDetails[0].email : '');
					return newEmail !== currentEmail;
				}
			}
		});
	}

	private validateMobileNumber(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	): void {
		formlyAddCustomValidator(formFields, `personApplicant-${index}.contactDetails.editMobile.mobilePhoneNumber`, {
			uniqueMobileNumber: {
				expression: (c: UntypedFormControl) => {
					const newMobileNumber = c.value as PhoneDetails;
					const mobilePhoneNumbers = applicants
						.filter((app) => app.id !== applicants[index].id)
						.map((a) => get(a, 'contactDetails[0].mobilePhoneNumber', '')) as PhoneDetails[];

					return !mobilePhoneNumbers.some(
						(number) =>
							number.dialCode === newMobileNumber.dialCode && number.phoneNumber === newMobileNumber.phoneNumber
					);
				}
			},
			noChange: {
				expression: (c: UntypedFormControl) => {
					const applicant = applicants[index];
					const newMobileNumber = c.value as PhoneDetails;

					const currentMobileNumber: PhoneDetails | undefined = applicant.contactDetails
						? (applicant.contactDetails[0].mobilePhoneNumber as PhoneDetails)
						: undefined;

					return !(
						newMobileNumber.phoneNumber === currentMobileNumber?.phoneNumber &&
						newMobileNumber.dialCode === currentMobileNumber?.dialCode
					);
				}
			}
		});
	}

	private validateBusinessStartDate(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyAddCustomValidator(
			formFields,
			`personApplicant-${index}.employments.employmentDetails.selfEmployment.businessStartDate`,
			{
				invalid: {
					expression: (c: UntypedFormControl) => {
						if (c.value) {
							return !isDateFuture(c.value);
						}
						return true;
					}
				}
			}
		);
	}

	private disableModal(modalField: FormlyFieldConfig | undefined, disable: boolean): void {
		if (modalField?.templateOptions) {
			modalField.templateOptions.disabled = disable;
		}
	}

	private setHouseholdTitle(formFields: FormlyFieldConfig[]): void {
		formlyExtendExpressionProperties(formFields, 'householdAndFamily.household.householdName', {
			'templateOptions.label': (model: HouseholdInfo, state: unknown, field: FormlyFieldConfig) => {
				const labelText = this.channelSettingQuery.getUseRelationshipForSOP() ? 'Statement of position' : 'Household';

				if (field) {
					const parentField = getParentFormField(field, 'household');
					const index = (parentField?.model as HouseholdInfo[])?.indexOf(model);
					return index > -1 ? `${index + 1} - ${labelText}` : `${labelText}`;
				}
				return `${labelText}`;
			}
		});
	}

	private setDependantDOBTitle(formFields: FormlyFieldConfig[]): void {
		formlyExtendExpressionProperties(formFields, 'householdAndFamily.household.dependants.dependantDateOfBirth', {
			'templateOptions.label': (model: DependantDetailsModel, state: unknown, field: FormlyFieldConfig) => {
				let label = 'Dependant date of birth';

				if (field) {
					label = field?.templateOptions?.required ? label : label + ' (optional)';
					const index = +(field?.parent?.key as number) ?? -1;
					return index > -1 ? `${index + 1} - ${label}` : `${label}`;
				}
				return label;
			}
		});
	}

	private addDependantDobAndAgeFields(fields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(fields, `householdAndFamily.household.numberOfDependants`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					return field?.formControl?.valueChanges.pipe(
						startWith(field?.formControl?.value as number),
						pairwise<number>(),
						tap(([prev, next]: [number, number]) => {
							// When maximum number of dependants are defined in metadata,
							// it won't add any excess controls to the DOM so it won't make the app unresponsive (Eg: typing 99 in dependants)
							const maxDependants = field?.props?.max as number;
							const dependantInfo = getFormField(field.parent?.fieldGroup, 'dependants');
							if (prev !== next && next !== (dependantInfo?.model as DependantDetailsModel[])?.length) {
								if (prev > next) {
									//delete last rows
									const noOfRowsToDelete = Math.min(prev, maxDependants ?? prev) - next;
									for (let i = 0; i < noOfRowsToDelete; i++) {
										if (dependantInfo?.fieldGroup) {
											const index = dependantInfo?.fieldGroup?.length - 1 ?? -1;
											const dependantDateOfBirth = getFormField(
												dependantInfo?.fieldGroup,
												`${index}.dependantDateOfBirth`
											);
											formlyDeleteFromArray(dependantDateOfBirth!);
										}
									}
								} else if (prev < next) {
									const noOfRowsToAdd = Math.min(next, maxDependants ?? next) - prev;
									//add new rows at the top
									for (let i = 0; i < noOfRowsToAdd; i++) {
										formlyAddItemToArray(dependantInfo!);
									}
								}
							}
						})
					);
				}
				return of();
			}
		});
	}

	private calculateDependantAge(fields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(fields, `householdAndFamily.household.dependants.dependantDateOfBirth`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					return field?.formControl?.valueChanges.pipe(
						startWith(field?.formControl.value),
						pairwise<string>(),
						tap(([prevDob, dob]: [string, string]) => {
							const ageField = getFormField(field.parent?.fieldGroup, 'dependantAge');
							// Only patch age if date of birth is valid
							if (ageField && field?.formControl?.valid) {
								if (dob !== null && prevDob !== dob) {
									ageField.formControl?.patchValue(calculateAgeFromDateOfBirth(dob));
								}
							}
						})
					);
				}
				return of();
			}
		});
	}

	private handleNextOfKin(formFields: FormlyFieldConfig[] | undefined, applicants: PersonApplicant[], index: number) {
		formlyRegisterHooks(formFields, `personApplicant-${index}.nextOfKin.relatedPerson.preferredContact`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField && formField.templateOptions) {
					const listItems = this.formEnumsQuery
						.selectEnumOptions('PreferredContactPerson')
						.pipe(
							map((options: EnumObject[]) => options.filter((option) => option.id !== PreferredContactPerson.HomePhone))
						);
					formField.templateOptions.options = listItems;
				}
			}
		});

		formlyRegisterHooks(formFields, `personApplicant-${index}.nextOfKin.existingNextOfKin`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					if (formField.templateOptions) {
						const applicant = this.formDataService.getPersonApplicantByIndex(index);
						const fieldOptions = this.formEnumsQuery.getAddOptions(
							formField?.templateOptions?.options as unknown as string
						);

						const listItems = this.formEnumsQuery.selectEnumOptions('Persons').pipe(
							map((persons) => [
								...fieldOptions,
								...persons
									.filter((listItem) => listItem.id !== applicant.id)
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															formField,
															applicant.id,
															this.saveNextOfKin.bind(this),
															'existingNextOfKin'
														)
												: null
									}))
							])
						);
						formField.templateOptions.options = listItems;

						return this.relatedEntitiesService.configureRelatedEntityModal(
							formField,
							applicant.id,
							this.saveNextOfKin.bind(this),
							'existingNextOfKin'
						);
					}
				}
				return of();
			}
		});
		formlyRegisterHooks(formFields, `personApplicant-${index}.nextOfKin.nextOfKinRelationship`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					return formField?.formControl?.valueChanges.pipe(
						startWith(formField?.formControl?.value as KinRelationship),
						pairwise<KinRelationship>(),
						tap(([prev, next]: [KinRelationship, KinRelationship]) => {
							if (prev !== next) {
								const applicant = this.formDataService.getPersonApplicantByIndex(index);
								const applicantModel = Object.assign(
									{},
									applicant,
									get(formField?.parent?.parent?.parent, 'model')
								) as PersonApplicant;
								this.applicantsService.savePersonApplicant(applicantModel).subscribe(() => {
									if (applicantModel.nextOfKin?.length) {
										this.toastr.success(savedSuccessfullyMessage('Next of kin'));
										this.simpFormlyHandlerService.updateToState(`nextOfKin-${index}`, applicantModel.nextOfKin[0], 0);
									}
								});
							}
						})
					);
				}
				return of();
			}
		});

		formlyOnClick(formFields, `personApplicant-${index}.nextOfKin.nextOfKinDelete`, (field) => {
			this.deleteNextOfKin(field, index);
		});
	}

	private saveRelatedParty = (
		model: RelatedPartyModel,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(model.existingSelect);

		return new Subscription();
	};

	private onSaveRelationship(
		model: RelationshipInfo,
		index: number,
		applicantId: number,
		field: FormlyFieldConfig | undefined
	) {
		field?.formControl?.setValue(model.existingSelect);
		field?.formControl?.markAsDirty();

		return new Subscription();
	}

	private saveNextOfKin(
		model: PersonNextOfKinModel,
		index: number,
		applicantId: number,
		field: FormlyFieldConfig | undefined
	) {
		const applicantModel = Object.assign(
			{},
			this.formDataService.getPersonApplicantByIndex(index),
			get(field?.parent?.parent?.parent, 'model')
		) as PersonApplicant;
		applicantModel.nextOfKin = [model];
		this.applicantsService.savePersonApplicant(applicantModel).subscribe(() => {
			if (applicantModel.nextOfKin?.length) {
				this.toastr.success(savedSuccessfullyMessage('Next of kin'));
				this.simpFormlyHandlerService.updateToState(`nextOfKin-${index}`, applicantModel.nextOfKin[0], 0);
			}
		});

		return new Subscription();
	}

	private deleteNextOfKin(field: FormlyFieldConfig, index: number, removeRow = true) {
		const applicant = this.formDataService.getPersonApplicantByIndex(index);
		applicant.nextOfKin = [];
		this.applicantsService.savePersonApplicant(applicant).subscribe(() => {
			if (removeRow) {
				this.formArrayDeleteService.deleteItem(field, 'NextOfKin', `nextOfKin-${index}`).subscribe(() => {
					this.toastr.success(deletedSuccessfullyMessage('Next of kin'));
				});
			}
		});
	}

	private configureRelatedParty(
		formFields: FormlyFieldConfig[] | undefined,
		applicants: PersonApplicant[],
		index: number
	) {
		// Handle related party type drop down
		this.handleRelatedPartyType(formFields, index);

		// Handle related party selection (EX:- existing person, company / related person, company)
		this.handleRelatedParty(formFields, index, applicants[index]?.id);

		// Handle related party saving
		this.handleRelatedPartySave(formFields, index, applicants[index]);

		// Handle related party deleting
		formlyOnClick(formFields, `personApplicant-${index}.relatedParties.delete`, (formField) =>
			this.deleteRelatedParty(formField, applicants[index]?.id, index)
		);
	}

	private handleRelatedPartyType(formFields: FormlyFieldConfig[] | undefined, index: number): void {
		formlyRegisterHooks(formFields, `personApplicant-${index}.relatedParties.type`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				const personApplicantsCount = this.formEnumsQuery.getOptions('PersonApplicants')?.length ?? 0;
				const relatedPartyField = getParentFormField(formField, 'relatedParties');
				const parentModel: RelatedPartyModel[] = relatedPartyField?.model as RelatedPartyModel[];
				const relatedPartyData$ = this.simpFormlyHandlerService.mapToStateData<RelatedPartyModel[]>(
					`relatedParties-${index}`
				);

				if (formField?.templateOptions && relatedPartyData$) {
					formField.templateOptions.options = this.formEnumsService
						.fetch(formField?.templateOptions?.options as unknown as string)
						.pipe(
							map((options) => {
								if (personApplicantsCount === 1) {
									// Remove nominee type if there is only one person applicant
									options = options?.filter((type) => type?.id !== RelatedPartyType.Nominee);

									// Change the max length after nominee removed
									if (relatedPartyField && relatedPartyField.templateOptions) {
										relatedPartyField.templateOptions.maxLength = 3;
									}
								}
								if (!get(formField?.model, 'id')) {
									// Remove already selected types
									const selectedTypes = parentModel?.filter((model) => !!model?.id).map((model) => model.type);
									if (selectedTypes.length > 0) {
										options = options?.filter((option) => !selectedTypes.some((type) => option.id === type));
									}
								}
								return options;
							})
						);
				}
			}
		});
	}

	private handleRelatedParty(
		formFields: FormlyFieldConfig[] | undefined,
		applicantIndex: number,
		applicantId: number
	): void {
		formlyRegisterHooks(formFields, `personApplicant-${applicantIndex}.relatedParties.existingSelect`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField?.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(
						formField?.templateOptions?.options as unknown as string
					);

					formField.templateOptions.options = combineLatest([
						this.formEnumsQuery.selectEnumOptions('Persons'),
						this.formEnumsQuery.selectEnumOptions('PersonApplicants'),
						this.formEnumsQuery.selectEnumOptions('OtherCompanies'),
						this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies'),
						(formField.parent?.formControl?.valueChanges as Observable<RelatedPartyModel>).pipe(
							map((model) => model.type),
							startWith((formField.parent?.formControl?.value as RelatedPartyModel).type)
						)
					]).pipe(
						map(([persons, personApplicants, companies, relatedCompanies, relatedType]) => {
							const relatedPartyIndex = Number(formField.parent?.key);

							relatedType = (formField.parent?.form?.getRawValue() as RelatedPartyModel[])[relatedPartyIndex].type;

							if (relatedType === RelatedPartyType.Accountant || relatedType === RelatedPartyType.Solicitor) {
								return [...fieldOptions.filter((option) => option.id === -2), ...relatedCompanies];
							} else if (relatedType === RelatedPartyType.Nominee) {
								return personApplicants.filter((person) => person.id !== applicantId);
							}
							return [...fieldOptions, ...persons, ...companies];
						}),
						map((options: EnumObject[]) =>
							options.map((option) => ({
								...option,
								click:
									(option.type as unknown as TargetType) === TargetType.RelatedPerson ||
									(option.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													formField,
													applicantId,
													this.saveRelatedParty.bind(this)
												)
										: null
							}))
						)
					);

					return this.relatedEntitiesService.configureRelatedEntityModal(
						formField,
						applicantId,
						this.saveRelatedParty.bind(this)
					);
				}
				return of();
			}
		});
	}

	private handleRelatedPartySave(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		applicant: PersonApplicant
	): void {
		formlyOnStatusChangedToValid(
			formFields,
			`personApplicant-${index}.relatedParties`,
			(field, event: FormlyArrayUpdateEvent<RelatedPartyModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as RelatedPartyModel;
				if (
					model?.relatedPerson?.id !== model.existingSelect.id &&
					model?.relatedCompany?.id !== model.existingSelect.id
				) {
					model.applicantId = applicant.id;
					// If ID is 0, existing related target being editted
					if (model.existingSelect?.id && model.existingSelect?.id > 0) {
						this.applicantsService.saveRelatedParties(model).subscribe((savedRelatedParty: RelatedPartyModel) => {
							this.simpFormlyHandlerService.updateToState(`relatedParties-${index}`, savedRelatedParty, event.index!);
							this.toastr.success(savedSuccessfullyMessage('Related party'));
						});
					}
				}
			}
		);
	}

	private deleteRelatedParty(formField: FormlyFieldConfig, applicantId: number, index: number): void {
		if (formField) {
			const id = get(getFormField(formField.parent?.fieldGroup, 'type')?.model, 'id') as number;
			if (!id || id === -1) {
				formlyDeleteFromArray(formField);
			} else {
				this.applicantsService
					.deleteRelatedPartyOfApplicant(formField, applicantId, `relatedParties-${index}`)
					.subscribe(() => {
						this.toastr.success(deletedSuccessfullyMessage('Related party'));
					});
			}
		}
	}
}
