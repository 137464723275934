import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyMobileStepSection = (
	property: FormlyApiProperty,
	childProperties?: JsonSchema
): JsonSchemaProperty => {
	const defaultValue = property.templateOptions?.defaultValue ?? (property.templateOptions?.required ? [{}] : []);
	return {
		type: 'array',
		title: property.title,
		widget: {
			formlyConfig: {
				type: 'mobile-step',
				templateOptions: {
					...property.templateOptions,
					collapsible: property.templateOptions?.collapsible ?? true
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property),
				validation: {
					messages: {
						required: 'This field is mandatory',
						...(property.errorMessages || {})
					}
				},
				defaultValue
			}
		},
		items: {
			type: 'object',
			required: property.required,
			properties: childProperties,
			widget: {
				formlyConfig: {
					templateOptions: {
						...property.templateOptions,
						customClass: `simp-row row ${property.templateOptions?.customClass || ''}`
					}
				}
			}
		}
	};
};
