import { InsuranceType } from '@app/modules/shared/enums/app.enums';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PersonInsuranceDTO } from '@app/modules/typings/api';
import { CurrencyHelper } from '@simpology/client-components/utils';

export class PersonInsurancesTransformer {
	static fromPayload(personInsurancesDTO: PersonInsuranceDTO[]): PersonInsurancesModel[] {
		return personInsurancesDTO.map((personInsuranceDTO: PersonInsuranceDTO) => {
			return {
				id: personInsuranceDTO?.id,
				personApplicantId: personInsuranceDTO?.personApplicantId,
				insuranceType: personInsuranceDTO?.insuranceType as InsuranceType,
				premiumAmount: {
					amount: personInsuranceDTO.premiumAmount!,
					frequency: personInsuranceDTO.premiumFrequency!
				}
			};
		});
	}

	static toPayload(personalDetailsModel: PersonInsurancesModel): PersonInsuranceDTO {
		const amount = String(personalDetailsModel.premiumAmount?.amount);
		const premiumFrequency = personalDetailsModel.premiumAmount?.frequency;

		return {
			id: personalDetailsModel.id,
			personApplicantId: personalDetailsModel?.personApplicantId,
			insuranceType: personalDetailsModel.insuranceType as number,
			premiumAmount: CurrencyHelper.unformatAmount(amount),
			premiumFrequency
		};
	}
}
export interface PersonInsurancesModel {
	id?: number;
	personApplicantId?: number;
	insuranceType?: InsuranceType | undefined;
	premiumAmount?: AmountSelect;
}
