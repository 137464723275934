import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

/**
 * DebounceService is responsible for debouncing actions based on a unique key.
 * It ensures that the provided action is executed only once within the specified debounce time.
 * Additionally, it exposes an observable to show a loading state while the request is in progress.
 * This is useful for scenarios where you want to limit the rate of execution of a function, such as API calls,
 * and also provide feedback to the user about the loading state.
 */
@Injectable({ providedIn: 'root' })
export class DebounceService {
	private subjectsMap: Map<DebounceKey | string, Subject<() => Observable<unknown>>> = new Map();
	private functionsMap: Map<DebounceKey | string, () => Observable<unknown>> = new Map();
	private executedFuncMap: Map<DebounceKey | string, () => Observable<unknown>> = new Map();
	private loadingMap: Map<DebounceKey | string, BehaviorSubject<boolean>> = new Map();

	/**
	 * Debounces the provided action based on the given key.
	 * If the action is triggered multiple times within the debounce time, it will only be executed once.
	 *
	 * @param key - A unique key to identify the action.
	 * @param action - The action to be debounced, which returns an Observable.
	 * @param debounceTimeMs - The debounce time in milliseconds (default is 3000ms).
	 */
	debounce(key: DebounceKey | string, action: () => Observable<unknown>, debounceTimeMs = 3000): void {
		const loadingSubject = this.loadingMap.get(key) ?? new BehaviorSubject<boolean>(true);
		loadingSubject.next(true);
		this.loadingMap.set(key, loadingSubject);
		if (!this.subjectsMap.has(key)) {
			const subject = new Subject<() => Observable<unknown>>();
			this.subjectsMap.set(key, subject);

			subject
				.pipe(
					debounceTime(debounceTimeMs),
					switchMap((debouncedAction) =>
						debouncedAction().pipe(
							tap({
								next: () => loadingSubject.next(false),
								error: () => loadingSubject.next(false),
								complete: () => loadingSubject.next(false)
							})
						)
					)
				)
				.subscribe();
		}

		this.subjectsMap.get(key)?.next(action);
	}

	/**
	 * Debounces the provided action based on the given key.
	 * If the action is triggered multiple times within the debounce time, it will only be executed once.
	 * If the event$ happens and there is a delayed action, the action will be executed immediately.
	 * This function is useful when we want to debounce but also trigger saving immediately when go to other UI component.
	 * @param key - A unique key to identify the action.
	 * @param event$ - The Observable that represents the event to trigger the delayed action immediately.
	 * @param action - The action to be debounced, which returns an Observable.
	 * @param debounceTimeMs - The debounce time in milliseconds (default is 3000ms).
	 */
	debounceOrTriggerOnEvent(
		key: DebounceKey | string,
		event$: Observable<unknown>,
		action: () => Observable<unknown>,
		debounceTimeMs = 3000
	): void {
		const loadingSubject = this.loadingMap.get(key) ?? new BehaviorSubject<boolean>(true);
		loadingSubject.next(true);
		this.loadingMap.set(key, loadingSubject);
		this.functionsMap.set(key, action); // Set the latest action

		if (!this.subjectsMap.has(key)) {
			const subject = new Subject<() => Observable<unknown>>();
			this.subjectsMap.set(key, subject);

			const subject$ = subject.pipe(debounceTime(debounceTimeMs));

			merge(subject$, event$)
				.pipe(
					switchMap(() => {
						const latestFunc = this.functionsMap.get(key);
						if (!latestFunc) {
							return of();
						}

						// Avoid duplication by only executing the latest action if it's different from the last executed action
						if (this.executedFuncMap.get(key) !== latestFunc) {
							this.executedFuncMap.set(key, latestFunc);
							return latestFunc().pipe(
								tap({
									next: () => loadingSubject.next(false),
									error: () => loadingSubject.next(false),
									complete: () => loadingSubject.next(false)
								})
							);
						} else {
							return of();
						}
					})
				)
				.subscribe();
		}

		this.subjectsMap.get(key)?.next(action);
	}

	/**
	 * Returns an Observable that emits the loading state for the given key.
	 *
	 * @param key - A unique key to identify the action.
	 * @returns An Observable that emits the loading state (true while the action is in progress, false when it completes).
	 */
	getLoadingState(key: DebounceKey): Observable<boolean> {
		if (!this.loadingMap.has(key)) {
			this.loadingMap.set(key, new BehaviorSubject<boolean>(false));
		}
		return this.loadingMap.get(key)?.asObservable() ?? of(false);
	}
}

/**
 * Enum for debounce keys.
 */
export enum DebounceKey {
	DigitalExpenses = 'digital-expenses-updated',
	DeclaredExpenses = 'declared-expenses-updated'
}
