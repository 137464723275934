import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyHeader = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'header',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'object',
			className: property.class ?? '',
			hideExpression: parseExpressionString(property.hide),
			templateOptions: {
				...property.templateOptions
			}
		}
	},
	properties: childProperties
});
