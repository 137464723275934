import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV_CONFIG } from '@app/env-config';
import { AuthService, IdentityBaseApiService } from '@simpology/authentication';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LoanappXAuthenticationService extends IdentityBaseApiService {
	private channelKey = '';
	constructor(http: HttpClient, toastr: ToastrService, private authService: AuthService) {
		super(http, toastr, ENV_CONFIG);
		this.setRoute('api/ExtraAuth');
	}

	getAnonymousAuthToken(): Observable<boolean> {
		const userId = this.authService.anonymousUserId ?? Guid.create().toString();
		const clientHost = window.location.host;
		if (clientHost.startsWith('localhost')) {
			this.channelKey = ENV_MAPPING[0].channelKey;
		} else {
			const matchedEnvironment = ENV_MAPPING.find((mapping) => clientHost.startsWith(mapping.host));
			this.channelKey = matchedEnvironment?.channelKey ?? '';
		}
		return this.getAuthToken(ENV_CONFIG.clientId, this.channelKey, userId).pipe(
			map((result: { token: string }) => {
				this.authService.createUserWithToken(result.token, userId);
				return true;
			})
		);
	}
}

const ENV_MAPPING: Array<{
	channelKey: string;
	host: string;
}> = [
	{
		channelKey: '0a64120c-c244-4bbf-aab5-d99240ecdd58',
		host: 'dev-loanapp.simpology.com.au'
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 'test-loanapp.simpology.com.au'
	},
	{
		channelKey: '714BF026-7624-439C-9CA5-B333205D2674',
		host: 'uat-loanapp.simpology.com.au'
	}
];
