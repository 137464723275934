import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { catchError, map, Observable, of } from 'rxjs';

import { RefiStore } from '../../enums/refi-store.enum';
import { EligibilityModelDTO } from '../../models/refi-eligibility-dto.model';
import { EligibilityModel, RefiEligibilityTransformer } from '../../models/refi-eligibility.model';
import { RefiConfirmDetailsService } from '../confirm-details/refi-confirm-details.service';

@Injectable({
	providedIn: 'root'
})
export class RefiEligibilityService extends BaseJourneyService<EligibilityModelDTO> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private confirmDetailsService: RefiConfirmDetailsService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.ELIGIBILITY);
	}

	getEligibility(hasAtLeastOneApplicantConsented = false): Observable<EligibilityModel[]> {
		const summaryDetails = this.formDataService.getStateData(
			RefiStore.ApplicationSummary
		)[0] as RefiApplicationSummaryDTO;
		const applicants = this.applicationDataQuery.getRefiApplicants();
		const isAdminUser = !applicants.some((app) => app.isCurrentUser);
		const summary = { summaryDetails, applicants };
		return this.getCustom(`${this.applicationDataQuery.applicationId()}`).pipe(
			map((eligibilityData: EligibilityModelDTO) => [
				RefiEligibilityTransformer.fromPayload(
					eligibilityData,
					summary,
					this.applicationDataQuery.applicationId(),
					isAdminUser || hasAtLeastOneApplicantConsented
				)
			]),
			catchError(() => of([{} as EligibilityModel]))
		);
	}

	saveEligibility(model: EligibilityModelDTO): Observable<boolean> {
		return <Observable<boolean>>this.postCustom('', model);
	}
}
