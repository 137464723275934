import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { formlyGenerateLabels } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IndividualConfig } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RefiReconcileService extends BaseJourneyService {
	shouldRenewApplicationLock$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	openModal$: BehaviorSubject<ReconcileParams | null> = new BehaviorSubject<ReconcileParams | null>(null);
	toastConfig: Partial<IndividualConfig> = { toastClass: 'tiny-toast', enableHtml: true };

	constructor(private sharedFlagService: SharedFlagsService, private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute();
	}

	getLabels(field: FormlyFieldConfig) {
		return formlyGenerateLabels(field, 'reconcileLabels');
	}

	showToast(message: string): void {
		this.toastr.show(`<i class="fas fa-check-circle success-icon"></i>${message}`, '', this.toastConfig);
	}

	removeRecord(section: string, id: number): Observable<boolean> {
		const applicationId = this.applicationDataQuery.applicationId();
		const apiPath = `${pathMap.get(section)}/Remove`;
		const payload: RemovePayload = {
			applicationId,
			recordId: id
		};
		return this.delete(`${apiPath}`, payload) as Observable<boolean>;
	}

	matchRecord(section: string, id: number, lenderId: number): Observable<boolean> {
		const applicationId = this.applicationDataQuery.applicationId();
		const apiPath = `${pathMap.get(section)}/Match`;
		const payload: MatchPayload = {
			applicationId,
			userDeclaredRecordId: id,
			lenderSyncedRecordId: lenderId
		};
		return this.patch(apiPath, payload) as Observable<boolean>;
	}
}

export interface ReconcileParams {
	key?: string;
	index?: number;
}

const pathMap = new Map<string, string>([['liabilities', '/LiabilityReconciliation']]);

interface RemovePayload {
	applicationId: number;
	recordId: number;
}

interface MatchPayload {
	applicationId: number;
	userDeclaredRecordId: number;
	lenderSyncedRecordId: number;
}
