import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { JourneyType } from '@app/modules/shared/enums/app.enums';
import { AuthenticationService } from '@app/modules/shared/service/authentication.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';

@Component({
	selector: 'app-expired',
	templateUrl: './expired.component.html',
	styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {
	public returnUrl = '';

	constructor(
		private route: ActivatedRoute,
		private baseJourneyService: BaseJourneyService,
		private authenticationService: AuthenticationService,
		private sharedFlagService: SharedFlagsService
	) {}

	public ngOnInit(): void {
		if (
			sessionStorage.getItem('returnUrl') === CONSTANTS.REFI_PATH ||
			sessionStorage.getItem('returnUrl') === CONSTANTS.PURCHASE_PATH
		) {
			this.authenticationService.logOut(
				this.sharedFlagService.currentJourney === JourneyType.Refi
					? CONSTANTS.REFI_LOGOUT_PATH
					: CONSTANTS.PURCHASE_LOGOUT_PATH
			);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.baseJourneyService.journey?.returnUrl;
		}
	}
}
