import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class SecurityGuaranteeTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): SecurityGuaranteeModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			lendingGuaranteeTypeId: payload.lendingGuaranteeTypeId,
			payGuaranteedAmount: getNullableYesNo(payload.securityGuaranteeUnderstandsSecurityGuarantee),
			spousalOrDeFactoRelationship: getNullableYesNo(payload.securityGuaranteeConfirmRelationshipToBorrower),
			sellFamilyHome: getNullableYesNo(payload.securityGuaranteeConfirmWouldSellFamilyHome),
			meetRepaymentsAndLivingExpenses: getNullableYesNo(payload.securityGuaranteeConfirmCouldMeetLoanRepayments)
		};
	}
}
export interface SecurityGuaranteeModel {
	id?: number;
	lendingGuaranteeId?: number;
	lendingGuaranteeTypeId?: number;
	payGuaranteedAmount?: YesNo;
	spousalOrDeFactoRelationship?: YesNo;
	sellFamilyHome?: YesNo;
	meetRepaymentsAndLivingExpenses?: YesNo;
}
