import { Importance, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableNumber, getNullableYesNo } from '@app/modules/shared/helper/util';
import { Term } from '@app/modules/simp-formly/typings/formly-app';
import { LoanPreferenceFixedInterestDTO } from '@app/modules/typings/api';
export class FixedInterestTransformer {
	static toPayload(applicationId: number, details: FixedInterestModel): LoanPreferenceFixedInterestDTO {
		return {
			applicationId: applicationId,
			importance: details.fixedRateImportanceId,
			risksExplained: details.fixedRateRisksExplained === true,
			reasonDescription: details.fixedRateExtras?.fixedRateReasonDescription ?? '',
			fixedPeriodDurationLength: getNullableNumber(details.fixedRateExtras?.fixedRatePeriodDuration?.number),
			fixedPeriodDurationUnit: details.fixedRateExtras?.fixedRatePeriodDuration?.frequency,
			reasonAvoidingRateIncreaseRisk: getNullableBoolean(
				details.fixedRateExtras?.fixedRateReasonAvoidingRateIncreaseRisk
			),
			reasonMakeBudgetingEasier: getNullableBoolean(details.fixedRateExtras?.fixedRateReasonMakeBudgetingEasier),
			reasonOther: !!details.fixedRateExtras?.fixedRateReasonDescription
		};
	}
	static fromPayload(fixedInterest: LoanPreferenceFixedInterestDTO): FixedInterestModel {
		const details: FixedInterestModel = {
			fixedRateExtras: {
				fixedRateReasonAvoidingRateIncreaseRisk: getNullableYesNo(fixedInterest.reasonAvoidingRateIncreaseRisk),
				fixedRateReasonMakeBudgetingEasier: getNullableYesNo(fixedInterest.reasonMakeBudgetingEasier),
				fixedRateReasonDescription: fixedInterest.reasonDescription,
				fixedRatePeriodDuration: {
					frequency: fixedInterest.fixedPeriodDurationUnit,
					number: fixedInterest.fixedPeriodDurationLength!
				}
			},
			fixedRateRisksExplained: fixedInterest.risksExplained,
			fixedRateImportanceId: fixedInterest.importance
		};
		return details;
	}
}

interface FixedInterestExtras {
	fixedRateReasonAvoidingRateIncreaseRisk?: YesNo;
	fixedRateReasonMakeBudgetingEasier?: YesNo;
	fixedRateReasonDescription: string;
	fixedRatePeriodDuration: Term;
}

export interface FixedInterestModel {
	fixedRateImportanceId: Importance;
	fixedRateRisksExplained: boolean;
	fixedRateExtras: FixedInterestExtras;
}

export interface FixedInterestStoreModel {
	fixedRateContainer: FixedInterestModel;
}
