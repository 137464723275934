import { Injectable } from '@angular/core';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { environment } from '@environments/environment';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { URL_CONSTANTS } from '../../constants/api.constants';
import { JourneyConfig } from '../../model/loan-application.model';

import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { AnalyticsMetadata } from '@simpology/authentication';
import { uniqBy } from 'lodash-es';
import { CONSTANTS } from '../../constants/constants';
import { ApplicantEntityType, JourneyType } from '../../enums/app.enums';
import { camelCaseToHyphen, toLowercaseFirstChar } from '../../helper/util';
import { BaseJourneyService } from '../../service/base-journey.service';
import { FormAreas, getSchemaArea, JourneyArea } from '../../typings/form-areas.types';
import { ApplicationDataQuery } from '../application-data/application-data.query';
import { ApplicationDataService } from '../application-data/application-data.service';
import { ApplicationDataStore } from '../application-data/application-data.store';
import { SideNavItem } from '../application-data/typings/application-data';
import { FormMetaDataQuery } from './form-metadata.query';
import { FormMetaDataStore } from './form-metadata.store';
@Injectable({ providedIn: 'root' })
export class FormMetaDataService extends BaseJourneyService {
	schemaList$?: Observable<JourneyConfig[]>;
	constructor(
		private formMetaDataStore: FormMetaDataStore,
		private formMetaDataQuery: FormMetaDataQuery,
		private applicationDataQuery: ApplicationDataQuery,
		private applicationDataService: ApplicationDataService,
		private applicationDataStore: ApplicationDataStore
	) {
		super();
		this.setMetaRoute(URL_CONSTANTS.FORM_SCHEMA);
	}

	fetch(area: FormAreas): void {
		this.fetch$(area).subscribe();
	}

	fetch$(area: FormAreas): Observable<FormlyApiProperty> {
		const applicationId = this.applicationDataQuery.applicationId();
		if (!this.formMetaDataQuery.hasArea(area)) {
			const journeyId = getSchemaArea(area);
			// based on environmental flag, either fetch from API or use schema from assets and apply API side channel based merge.
			const schema$ = journeyId
				? environment.local
					? applicationId === CONSTANTS.NEW_ID
						? this.fetchSchemaFromAssets(area)
						: this.mergeSchema(area)
					: this.fetchSchema(area)
				: this.fetchSchemaFromAssets(area);

			return schema$.pipe(
				map((metadata: FormlyApiProperty) => {
					this.formMetaDataStore.update({ [area]: metadata });
					return metadata;
				})
			);
		}
		return of({});
	}

	mapSchemaField$(formArea: FormAreas) {
		this.fetch(formArea);
		return this.formMetaDataQuery.selectArea$(formArea);
	}

	fetchSchemaList$() {
		if (!this.schemaList$) {
			this.schemaList$ = this.http.get<JourneyConfig[]>(`assets/form/metadata/schema-list.json`).pipe(shareReplay(1));
		}
		return this.schemaList$;
	}

	/**
	 * See journeys in schema-list.json for reference
	 * @param journey
	 */
	fetchByJourney(journeyType?: JourneyType): Observable<FormAreas> {
		return this.fetchSchemaList$().pipe(
			switchMap((schemaList: JourneyConfig[]) => {
				const schemaListItem = schemaList.find((schema) => schema.journey === journeyType);
				const area = schemaListItem?.name?.toLowerCase() as FormAreas;
				const schema$ = environment.local
					? this.mergeSchema(area, schemaListItem?.schemaFiles, schemaListItem?.area)
					: this.fetchSchema(area, schemaListItem?.area);
				return schema$.pipe(
					map((metadata: FormlyApiProperty) => {
						this.formMetaDataStore.update({ [FormAreas.loanAppX]: metadata });
						return area;
					})
				);
			})
		);
	}

	fetchSplit(area: FormAreas, sections: string[]): void {
		if (!this.formMetaDataQuery.hasArea(area)) {
			const schemaArea = getSchemaArea(area);
			// based on environmental flag, either fetch from API or use schema from assets and apply API side channel based merge.
			const schema$ = schemaArea
				? environment.local
					? this.mergeSchema(area, sections)
					: this.fetchSchema(area)
				: this.fetchSplitSchemaFromAssets(sections);

			schema$.subscribe((metadata: FormlyApiProperty) => {
				this.formMetaDataStore.update({ [area]: metadata });
			});
		}
	}

	updateSchemaList(): Observable<JourneyConfig[]> {
		return this.http.get<JourneyConfig[]>(`assets/form/metadata/schema-list.json`).pipe(
			tap((schemaList: JourneyConfig[]) => {
				this.applicationDataService.updateSchemaList(schemaList);
			})
		);
	}

	fetchAnalytics() {
		return this.http.get(`assets/form/metadata/analytics.json`) as Observable<AnalyticsMetadata>;
	}

	fetchAllSchemas(areasToFetch: number[]) {
		areasToFetch = [...new Set(areasToFetch)];
		const areasQueryString = areasToFetch.map((area) => `areas=${encodeURIComponent(area)}`).join('&');

		const url = `Journey/SchemaAll/${this.applicationDataQuery.getApplicationJourneyId()}/${this.applicationDataQuery.applicationId()}?${areasQueryString}`;

		return this.getCustom(url).pipe(
			tap((metadataList: FormlyApiProperty[]) => this.updateStoreForAllSchema(metadataList)),
			catchError((error: Error) => {
				this.toastr.error(`Error fetching remote schema for all schemas`);
				return throwError(() => error);
			})
		);
	}
	preloadAllSchemas(applicants: SetUpApplicant[]) {
		if (environment.local) {
			return this.preLoadAllSchemaForLocal(applicants);
		}
		return this.preLoadAllSchemaWithFetch(applicants);
	}

	private preLoadAllSchemaWithFetch(applicants: SetUpApplicant[]) {
		const sideNavItems = this.applicationDataService.getSideNavItems('fullApplication');
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const uniqueApplicants = uniqBy(applicants, (applicant) => applicant.applicantTypeModal?.type);
		const excludedAreasToFetchFromSideNav = [JourneyArea.applicants, JourneyArea.financialPosition, JourneyArea.setup];

		if (uniqueApplicants.length) {
			const schemasToFetch: number[] = [];
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			uniqueApplicants.map((applicant) => {
				if (applicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant) {
					schemasToFetch.push(JourneyArea.applicants, JourneyArea.financialPosition);
				}
				if (applicant.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant) {
					schemasToFetch.push(JourneyArea.companyApplicants, JourneyArea.companyFinancialPosition);
				}
				if (applicant.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant) {
					schemasToFetch.push(JourneyArea.trustApplicants, JourneyArea.trustFinancialPosition);
				}
			});
			const journeyConfig = this.applicationDataStore.getValue().journeyConfig as JourneyConfig[];
			sideNavItems
				.filter((x) => !excludedAreasToFetchFromSideNav.includes(x.area))
				.forEach((item) => {
					const area = journeyConfig.find((x) => x.area === item.area)?.area as number;
					if (area) {
						schemasToFetch.push(area);
					}
				});
			schemasToFetch.push(JourneyArea.loanAppClientCommon, JourneyArea.complianceGuarantor);

			return this.fetchAllSchemas(schemasToFetch).pipe(map(() => applicants));
		}
		return of(applicants);
	}
	private preLoadAllSchemaForLocal(applicants: SetUpApplicant[]) {
		const sideNavItems = this.applicationDataService.getSideNavItems('fullApplication');
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const uniqueApplicants = uniqBy(applicants, (applicant) => applicant.applicantTypeModal?.type);
		if (uniqueApplicants.length) {
			const schemasToFetch = [
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				...uniqueApplicants.map((applicant) => {
					if (applicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant) {
						return forkJoin([
							this.fetchSideNav(sideNavItems, FormAreas.applicants),
							this.fetchSideNav(sideNavItems, FormAreas.financialPosition)
						]);
					}
					if (applicant.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant) {
						return forkJoin([
							this.fetch$(FormAreas.companyApplicants),
							this.fetch$(FormAreas.companyFinancialPosition)
						]);
					}
					return forkJoin([this.fetch$(FormAreas.trustApplicants), this.fetch$(FormAreas.trustFinancialPosition)]);
				}),
				this.fetch$(FormAreas.complianceGuarantor)
			];
			const journeyConfig = this.applicationDataStore.getValue().journeyConfig as JourneyConfig[];
			sideNavItems
				.filter((x) => x.area !== JourneyArea.applicants && x.area !== JourneyArea.financialPosition)
				.forEach((item) => {
					const area = journeyConfig.find((x) => x.area === item.area)?.name as string;
					if (area) {
						const formArea = toLowercaseFirstChar(area);
						schemasToFetch.push(this.fetch$(formArea as FormAreas));
					}
				});
			schemasToFetch.push(this.fetch$(FormAreas.loanAppClientCommon));
			return forkJoin(schemasToFetch).pipe(map(() => applicants));
		} else {
			return of([]);
		}
	}
	private fetchSchema(area: FormAreas, journeyAreaFromSchemaList = 0): Observable<FormlyApiProperty> {
		const journeyArea = getSchemaArea(area) ?? journeyAreaFromSchemaList;
		const applicationId =
			journeyArea === JourneyArea.loanAppClientCommon ? 0 : this.applicationDataQuery.applicationId();
		let url = '';
		if (journeyArea === JourneyArea.loanAppClientCommon) {
			url = `Journey/Schema/${this.applicationDataQuery.getApplicationJourneyId()}/${journeyArea}`;
		} else {
			url = `Journey/Schema/${this.applicationDataQuery.getApplicationJourneyId()}/${journeyArea}/${applicationId}`;
		}

		return this.getCustom(url).pipe(
			catchError((error: Error) => {
				if (journeyArea === JourneyArea.loanAppClientCommon) {
					return this.fetchSchemaFromAssets(FormAreas.loanAppClientCommon);
				}
				console.error('Error fetching remote schema for: ', area);
				this.toastr.error(`Error fetching remote schema for: ${area}`);
				return throwError(() => error);
			})
		);
	}

	private updateStoreForAllSchema(metadataList: FormlyApiProperty[]): void {
		const metadataStore: { [key: string]: FormlyApiProperty } = {};

		metadataList.forEach((metadata) => {
			const areaKey = JourneyArea[metadata.areaId as unknown as keyof typeof JourneyArea];

			if (areaKey) {
				metadataStore[areaKey] = metadata;
			}
		});
		this.formMetaDataStore.update(metadataStore);
	}

	private mergeSchema(
		area: FormAreas,
		sections: string[] = [],
		journeyAreaFromSchemaList = 0
	): Observable<FormlyApiProperty> {
		const journeyArea = getSchemaArea(area);
		const applicationId =
			!journeyArea || journeyArea === JourneyArea.loanAppClientCommon ? 0 : this.applicationDataQuery.applicationId();
		const url = `Journey/MergeSchema/${this.applicationDataQuery.getApplicationJourneyId()}/${
			journeyArea ?? journeyAreaFromSchemaList
		}/${applicationId}`;
		const schema$ = sections.length > 0 ? this.fetchSplitSchemaFromAssets(sections) : this.fetchSchemaFromAssets(area);

		return schema$.pipe(
			switchMap((metadata) => {
				return (this.postCustom(url, metadata) as Observable<FormlyApiProperty>).pipe(
					catchError(() => {
						console.error('Error patching remote schema for: ', area);
						return of(metadata);
					})
				);
			})
		);
	}

	private combineSplitSchemas(schemas$: Observable<FormlyApiProperty>[]): Observable<FormlyApiProperty> {
		return forkJoin(schemas$).pipe(
			map((sections) => ({
				type: 'area',
				properties: sections
			}))
		);
	}

	private fetchSchemaFromAssets(area: FormAreas): Observable<FormlyApiProperty> {
		const fullAppSchemaUrl = `assets/form/metadata/${area.toLowerCase()}-schema.json`;
		return this.http.get<FormlyApiProperty>(fullAppSchemaUrl);
	}

	private findPropertiesWithType(rootObject: FormlyApiProperty, targetType: string): FormlyApiProperty[] {
		const foundProperties: FormlyApiProperty[] = [];

		const searchForObject = (obj: FormlyApiProperty) => {
			if (obj.type === targetType) {
				foundProperties.push(obj);
			}

			if (obj.properties && obj.properties.length > 0) {
				for (const nestedObj of obj.properties) {
					searchForObject(nestedObj);
				}
			}
		};

		searchForObject(rootObject);
		return foundProperties;
	}

	private fetchSplitSchemaFromAssets(sections: string[]): Observable<FormlyApiProperty> {
		const schemas$ = sections.map((section) => {
			const sectionFile = section.includes('json') ? section : `${section}.json`;
			return this.http.get<FormlyApiProperty>(`assets/form/metadata/${sectionFile.toLowerCase()}`);
		});
		return this.combineSplitSchemas(schemas$);
	}
	private fetchSideNav(sideNavItems: SideNavItem[], area: FormAreas) {
		return sideNavItems.some((x) => x.url === camelCaseToHyphen(area)) ? this.fetch$(area) : of(true);
	}
}
