import { InterestOnlyReason, MetricFormat, PaymentType, YesNo } from '@app/modules/shared/enums/app.enums';
import { formatPlural, getYearsAndMonthsText } from '@app/modules/shared/helper/util';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ContributionDTO, DepositDTO, PercentOwned } from '@app/modules/typings/api';
import { SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';
import { LendersMortgageInsurance } from './lenders-mortgage-insurance-model';
import { LoanInformation } from './loan-information.model';
import { Product } from './product.model';
import { PropertyAssetModel } from './property-asset.model';
import {
	IndicatorMetric,
	ServiceabilityCalculationResult,
	ServiceabilityResult
} from './serviceability-calculation-result.model';

export class LoanServiceabilitySummaryModelTransformer {
	static fromPayload(
		purchaseDetails: PropertyAssetModel[],
		serviceabilityData: ServiceabilityResult | undefined,
		deposits: DepositDTO[],
		contributions: ContributionDTO[],
		loanInformation: LoanInformation,
		lendersMortgageInsurance: LendersMortgageInsurance[],
		aggregateFormatter: AggregateFormatterService,
		formEnumQuery: FormEnumsQuery
	): LoanServiceabilitySummaryModel {
		const purchaseDetailModel = {} as Partial<PurchaseSummaryModel>;
		const propertyDetails: PropertyDetailsModel[] = [];

		const serviceability = serviceabilityData?.calculationResults
			? serviceabilityData.calculationResults[0]
			: undefined;

		purchaseDetails.forEach((purchase, index) => {
			const propertyAssetDetails = purchase.details?.propertyDetails.propertyAssetDetails[0];

			purchaseDetailModel[`primaryUsage${index}`] = propertyAssetDetails?.primaryUse;
			purchaseDetailModel[`propertyTypeName${index}`] = propertyAssetDetails?.propertyTypeName;
			purchaseDetailModel[`address${index}`] = this.getPropertyAddress(purchase);
			purchaseDetailModel[`primaryPurpose${index}`] = propertyAssetDetails?.purpose;
			purchaseDetailModel[`status${index}`] = propertyAssetDetails?.status;
			purchaseDetailModel[`tenureType${index}`] = propertyAssetDetails?.tenureType;
			purchaseDetailModel[`ownershipPercentages${index}`] = propertyAssetDetails?.percentsOwned
				? buildOwnershipPercentageInfo(propertyAssetDetails?.percentsOwned, formEnumQuery)
				: '';
			purchaseDetailModel[`estimatedValue${index}`] = propertyAssetDetails?.value.amount;
			purchaseDetailModel[`estimatedValueDate${index}`] = propertyAssetDetails?.estimatedValueValuedDate;
			purchaseDetailModel[`certifiedValue${index}`] = propertyAssetDetails?.certifiedValue;
			purchaseDetailModel[`certifiedValueDate${index}`] = propertyAssetDetails?.certifiedValueValuedDate;
			purchaseDetailModel[`valuer${index}`] = purchase.valuer?.companyName;

			propertyDetails.push({
				address: this.getPropertyAddress(purchase),
				isLimitedGuarantee: propertyAssetDetails?.isLimitedGuarantee,
				guaranteeAmount: propertyAssetDetails?.guaranteeAmount,
				fairMarketValue: purchase.fairMarketValue
			});
		});

		const depositsAndContributionDetails = buildDepositsContributions(deposits, contributions, aggregateFormatter);

		const ownershipDetails = buildOwnershipDetails(loanInformation, aggregateFormatter, formEnumQuery);

		let loanServiceabilitySummary = {
			loanRequirements: [...ownershipDetails],
			purchaseDetails: [
				{
					purchaseDetails: purchaseDetailModel,
					propertyDetails: propertyDetails.length > 0 ? propertyDetails : undefined
				}
			],
			depositsContributions: [
				{
					deposits: depositsAndContributionDetails,
					depositsContributionsWithAmount: this.getContributionsAndDepositswithAmount(
						deposits,
						contributions,
						formEnumQuery
					)
				}
			],
			lendersMortgageInsurance: {
				lvr: lendersMortgageInsurance[0]?.lvrIncludingLmi
			}
		};

		if (serviceability) {
			loanServiceabilitySummary = Object.assign(loanServiceabilitySummary, {
				...loanServiceabilitySummary,
				serviceability: [
					{
						primaryDetails: {
							maxLoanAmount: aggregateFormatter.formatAmount(`${serviceability.maximumLoanAmount}`),
							maxRepayment: aggregateFormatter.formatAmount(`${serviceability.repaymentAmount}`)
						},
						secondaryDetails: this.getSecondaryDetails(serviceability, aggregateFormatter)
					}
				]
			});
		}

		return loanServiceabilitySummary as LoanServiceabilitySummaryModel;
	}

	private static getFormattedAddress(address?: SimpAddress): string {
		return address ? SimpAddressHelper.buildAddressLine(address) : '';
	}

	private static getPropertyAddress(purchase: PropertyAssetModel): string {
		return purchase.details?.propertyDetails.isAIPProperty
			? this.getFormattedAddress(purchase.details?.propertyDetails.suburbAddress as SimpAddress)
			: this.getFormattedAddress(purchase.details?.propertyDetails.address as SimpAddress) ?? '';
	}

	private static getContributionsAndDepositswithAmount(
		deposits: DepositDTO[],
		contributions: ContributionDTO[],
		formEnumsQuery: FormEnumsQuery
	): ConstructionsAndDepositsWithAmount[] {
		const contributionTypes = formEnumsQuery.getOptions(`ContributionFundsType`);

		const depositsWithAmount = (deposits || []).map(
			(deposit) =>
				({
					contributions: 'Deposit',
					contributionAmount: deposit.amount as number
				} as ConstructionsAndDepositsWithAmount)
		);

		if (contributionTypes && contributions) {
			const contributionsWithAmount = contributions.map(
				(contribution) =>
					({
						contributions: contributionTypes?.find((contributionType) => contributionType.id == contribution.type)
							?.label as string,
						contributionAmount: contribution.amount as number
					} as ConstructionsAndDepositsWithAmount)
			);
			return depositsWithAmount.concat(contributionsWithAmount);
		}

		return depositsWithAmount;
	}

	private static getSecondaryDetails(
		serviceability: ServiceabilityCalculationResult,
		aggregateFormatter: AggregateFormatterService
	) {
		const secondaryDetails: { [key: string]: { label?: string; value?: string } } = {};
		if (serviceability) {
			serviceability.metrics.forEach((m) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				secondaryDetails[this.getAttributeKey(m)] = {
					label: m.name,
					value: this.getCalculatedMetricValue(m, aggregateFormatter)
				};
			});
		}
		return secondaryDetails;
	}

	private static getCalculatedMetricValue(
		metric: IndicatorMetric,
		aggregateFormatter: AggregateFormatterService
	): string {
		let value = `${metric?.calculatedValue?.toFixed(2)}`;
		// Append text
		switch (metric.metricFormat) {
			case MetricFormat.Currency:
				value = `$${value}`;
				break;
			case MetricFormat.CurrencyWithFrequency:
				value = `${aggregateFormatter.formatAmount(value)} per month`;
				break;
			case MetricFormat.Percentage:
				value = `${value}%`;
				break;
		}
		return value;
	}

	public static getAttributeKey(m: IndicatorMetric): string {
		return m.shortName.toLowerCase().replace(' ', '_');
	}
}

const buildOwnershipPercentageInfo = (percentsOwned: PercentOwned[], formEnumQuery: FormEnumsQuery) => {
	const ownershipInfoStrings: string[] = [];

	percentsOwned.forEach((owner) => {
		const ownerName = owner.applicantId ? formEnumQuery.getOptionLabel('AllApplicants', owner.applicantId) : '';
		ownershipInfoStrings.push(`${ownerName} ${owner.percent?.toFixed(2).replace(/[.,]00$/, '')}%`);
	});

	return ownershipInfoStrings.join(', ');
};

const buildDepositsContributions = (
	deposits: DepositDTO[],
	contributions: ContributionDTO[],
	aggregateFormatter: AggregateFormatterService
): DepositsContributionsModel => {
	let totalSum = 0;
	const itemsEntered: string[] = [];

	if (deposits.length > 0) {
		deposits.forEach((deposit) => {
			if (deposit.amount) totalSum += deposit.amount;
		});

		itemsEntered.push(`${deposits.length} deposit`);
	}

	if (contributions.length > 0) {
		contributions.forEach((contribution) => {
			if (contribution.amount) totalSum += contribution.amount;
		});

		itemsEntered.push(`${contributions.length} other contributions`);
	}

	return {
		totalAmount: aggregateFormatter.formatAmount(`${totalSum}`),
		itemsEntered: itemsEntered.join(', ')
	};
};

const buildOwnershipDetails = (
	loanInformation: LoanInformation,
	aggregateFormatter: AggregateFormatterService,
	formEnumQuery: FormEnumsQuery
): OwnershipDetailsSummaryModel[] => {
	const builtOwnershipDetails: OwnershipDetailsSummaryModel[] = [];

	loanInformation.borrowingAndProductInfo.map((info) => {
		const ownership: OwnershipSummaryModel = {};

		ownership.ownershipPercentage = loanInformation.percentsOwned
			? buildOwnershipPercentageInfo(loanInformation.percentsOwned, formEnumQuery)
			: '';
		ownership.reasonForBorrowing = formEnumQuery.getOptionLabel(
			'RbaLendingPurpose',
			info.borrowing.lendingPurpose?.[0]?.primaryLendingPurpose ?? info.borrowing.primaryPurpose!
		);

		ownership.primaryPurpose = formEnumQuery.getOptionLabel(
			'PrimaryPurposeLoanPurpose',
			info.borrowing.primaryPurpose!
		);

		ownership.aBSLendingPurposeArray = info.borrowing.lendingPurpose?.map((lp) => {
			return {
				aBSLendingPurpose: formEnumQuery.getOptionLabel('AbsLendingPurposeCode', lp.absLendingPurposeCode!)
			};
		});

		ownership.interestType = `${
			info.product.interestType
				? `${formEnumQuery.getOptionLabel('InterestType', info.product.interestType)} ${
						info.product.fixedRatePeriod
							? `(${info.product.fixedRatePeriod}
								${formatPlural(info.product.fixedRatePeriod, 'Year')})`
							: ''
				  }`
				: ''
		}`;

		ownership.pITenure = `${getYearsAndMonthsText(info.product.loanPeriod ?? 0)}`;
		ownership.totalBorrowingAmount = aggregateFormatter.formatAmount(info.borrowing.amountRequested);
		ownership.totalBorrowingAmountAsNumber = info.borrowing.amountRequested;

		// If there are no terms or has 1 term, repayment amount comes from initialTermRepaymentAmount
		ownership.repayment = info.rateToBorrower.initialTermRepaymentAmount
			? `${aggregateFormatter.formatAmount(info.rateToBorrower.initialTermRepaymentAmount)} ${
					// If repaymentFrequency is available, we add it parenthesis. Eg: (Monthly)
					info.rateToBorrower.repaymentFrequency
						? `(${formEnumQuery.getOptionLabel('RepaymentFrequency', info.rateToBorrower.repaymentFrequency)})`
						: ''
			  }
			 `
			: '';

		ownership.repaymentType = buildRepaymentTypeString(info.product, formEnumQuery);

		ownership.interestOnlyReason = `${
			info.product.interestOnlyReason === InterestOnlyReason.OtherReason
				? info.product.otherReason
				: formEnumQuery.getOptionLabel('InterestOnlyReason', info.product.interestOnlyReason!)
		}`;

		// If 2 terms, initial repayment amount comes in indRepaymentAmount for 1st term and subSeqRepaymentAmount for 2nd term
		ownership.initialRepayment = info.rateToBorrower.initialTermRepaymentAmount
			? `${aggregateFormatter.formatAmount(
					info.rateToBorrower.initialTermRepaymentAmount
			  )} (${formEnumQuery.getOptionLabel('RepaymentFrequency', info.rateToBorrower.repaymentFrequency!)})`
			: '';

		ownership.subsequentRepayment = info.rateToBorrower.subSequentRepayments
			? `${aggregateFormatter.formatAmount(
					info.rateToBorrower.subSequentRepayments ?? 0
			  )} (${formEnumQuery.getOptionLabel('RepaymentFrequency', info.rateToBorrower.repaymentFrequency!)})`
			: '';

		const selectedProduct = info.selectedProduct;

		ownership.preferredProduct = `${
			selectedProduct
				? `${selectedProduct?.productNameWithFeature} (${selectedProduct?.comparisonRate}% comp rate)`
				: ''
		}`;

		ownership.selectedFeatures = info.product.loanFeatures.map((x) => x.label).join(', ');

		builtOwnershipDetails.push({ ownership });
	});

	return builtOwnershipDetails;
};

const buildRepaymentTypeString = (product: Product, formEnumQuery: FormEnumsQuery) => {
	const paymentType = formEnumQuery.getOptionLabel('PaymentType', product.paymentType!);
	// Only for prepaid interest, we display the year(s).
	if (product.paymentType === PaymentType.InterestOnly && product.interestOnlyPeriod) {
		return `${paymentType} (${getYearsAndMonthsText(product.interestOnlyPeriod ?? 0)})`;
	} else if (product.paymentType === PaymentType.PrepaidInterest && product.prepaidInterestPeriod) {
		return `${paymentType} (${product.prepaidInterestPeriod} ${formatPlural(product.prepaidInterestPeriod, 'Year')})`;
	}
	return paymentType;
};

export interface LoanServiceabilitySummaryModel {
	loanRequirements: OwnershipDetailsSummaryModel[];
	purchaseDetails: PurchaseDetailsSummaryModel[];
	serviceability: [
		{
			primaryDetails: { maxLoanAmount: string; maxRepayment: string };
			secondaryDetails: { [key: string]: { label?: string; value?: string } };
		}
	];
	depositsContributions: DepositsContributionsSummaryModel[];
	lendersMortgageInsurance: { lvr?: string };
	serviceabilitySummary?: { umi: string };
}

export interface DepositsContributionsSummaryModel {
	deposits: DepositsContributionsModel;
	depositsContributionsWithAmount: ConstructionsAndDepositsWithAmount[];
}

interface ConstructionsAndDepositsWithAmount {
	contributions: string;
	contributionAmount: number | undefined;
}

interface DepositsContributionsModel {
	totalAmount: string;
	itemsEntered: string;
}

interface PurchaseDetailsSummaryModel {
	purchaseDetails: Partial<PurchaseSummaryModel>;
	propertyDetails?: PropertyDetailsModel[];
}

interface PurchaseSummaryModel {
	[key: string]: unknown;
}

interface PropertyDetailsModel {
	address: string;
	fairMarketValue?: number;
	isLimitedGuarantee?: YesNo;
	guaranteeAmount?: number;
}
interface OwnershipDetailsSummaryModel {
	ownership: Partial<OwnershipSummaryModel>;
}

interface OwnershipSummaryModel {
	[key: string]: unknown;
}
