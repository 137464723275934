import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { YesNo } from '@app/modules/shared/enums/app.enums';
import {
	getBoolean,
	getNullableBoolean,
	getNullableYesNo,
	truncateDecimalsAndFormat
} from '@app/modules/shared/helper/util';
import { LmiDTO } from '@app/modules/typings/api';
import { FeePayableBy, FeePaymentTiming } from './../../shared/enums/app.enums';

export interface LendersMortgageInsurance {
	id: number;
	applicationId: number;
	isLmiRequirement?: YesNo;
	lmiPremium?: number;
	payableTo?: FeePayableBy;
	isCapitalize: boolean;
	whenPayable?: FeePaymentTiming;
	lvrExcludingLmi?: string;
	lvrIncludingLmi?: string;
	stampDutyAmount?: number;
}

export class LendersMortgageInsuranceTransformer {
	static toPayload(applicationId: number, input: LendersMortgageInsurance): LmiDTO {
		return {
			id: input.id,
			applicationId: applicationId,
			lmiRequirement: getNullableBoolean(input.isLmiRequirement),
			lmiPremiumAmount: input.lmiPremium,
			payableTo: input.payableTo,
			capitalised: getBoolean(input.isCapitalize),
			whenPayable: input.whenPayable,
			lvrExcludingLMI: undefined,
			lvrIncludingLMI: undefined,
			stampDutyAmount: input.stampDutyAmount
		} as LmiDTO;
	}

	static fromPayload(input?: LmiDTO): LendersMortgageInsurance {
		return {
			id: input?.id ?? CONSTANTS.NEW_ID,
			applicationId: input?.applicationId,
			isLmiRequirement: getNullableYesNo(input?.lmiRequirement),
			lmiPremium: input?.lmiPremiumAmount,
			payableTo: input?.payableTo,
			isCapitalize: input?.capitalised,
			whenPayable: input?.whenPayable,
			lvrExcludingLmi: `${truncateDecimalsAndFormat(input?.lvrExcludingLMI as number, 2)}%`,
			lvrIncludingLmi: `${truncateDecimalsAndFormat(input?.lvrIncludingLMI as number, 2)}%`,
			stampDutyAmount: input?.stampDutyAmount
		} as LendersMortgageInsurance;
	}
}
