import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpTabConfig } from '@simpology/client-components/lib/simp-tabs/models/simp-tab-config';
import { FrequencyShort } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import {
	IndicatorMetric,
	ServiceabilityResult
} from '../loan-serviceability/model/serviceability-calculation-result.model';
import { MetricFormat } from '../shared/enums/app.enums';
import { truncateDecimalsAndFormat } from '../shared/helper/util';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { EventAction, EventTarget, EventsRepository } from '../shared/store/events/events.repository';
import { FormEnumsQuery } from '../shared/store/form-enums/form-enums.query';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';

@Injectable({ providedIn: 'root' })
export class OutcomesMetricsCalculationService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private eventsRepository: EventsRepository,
		private formEnumsQuery: FormEnumsQuery,
		private currencyPipe: CurrencyPipe
	) {
		super();
		this.setJourneyLadmRoute();
	}

	isServiceabilityRequired(): Observable<boolean> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(
			`LoanServiceability/IsServiceabilityRequiredByJourneyId/${applicationId}/${this.applicationDataQuery.getApplicationJourneyId()}`
		).pipe(map((response: boolean) => response));
	}

	checkServiceability(scenarioId: number, index: number): void {
		this.calculateServiceability(scenarioId)
			.pipe(catchError(() => of(undefined)))
			.subscribe((serviceabilityResult: ServiceabilityResult | undefined) => {
				if (serviceabilityResult) {
					serviceabilityResult.scenarioId = scenarioId;
					serviceabilityResult.isActiveScenario = true;
					this.simpFormlyHandlerService.updateToState('serviceabilityResults', serviceabilityResult, index);
					this.eventsRepository.dispatchEvent({
						targetType: EventTarget.Serviceability,
						action: EventAction.Calculated
					});
				}
			});
	}

	calculateServiceability(scenarioId?: number): Observable<ServiceabilityResult> {
		const applicationId = this.applicationDataQuery.applicationId();
		let apiURL = `LoanServiceability/ServiceabilityByJourneyId/${applicationId}/${this.applicationDataQuery.getApplicationJourneyId()}`;
		if (scenarioId && scenarioId > 0) {
			apiURL = apiURL.concat(`/${scenarioId}`);
		}
		return <Observable<ServiceabilityResult>>this.get(apiURL);
	}

	updateActiveStateOfOutcomeMetricsTabs(scenarioId: number, removeExistingRecord?: boolean) {
		let serviceabilityResults =
			this.simpFormlyHandlerService.getStateData<ServiceabilityResult>('serviceabilityResults');

		if (removeExistingRecord) {
			serviceabilityResults = serviceabilityResults.filter((result) => result.scenarioId !== scenarioId);
		}

		if (!serviceabilityResults || !serviceabilityResults.some((result) => result.scenarioId === scenarioId)) {
			serviceabilityResults.push({ scenarioId } as ServiceabilityResult);
		}

		const updatedServiceabilityResults = serviceabilityResults.map((result) => {
			return { ...result, isActiveScenario: result.scenarioId === scenarioId };
		});

		this.simpFormlyHandlerService.upsertToStateWithData('serviceabilityResults', updatedServiceabilityResults);
	}

	getConfiguredTabs(): SimpTabConfig[] {
		const scenarioEnumList = this.formEnumsQuery.getOptions('ScenariosEnum');

		return scenarioEnumList
			?.map((scenarioEnum) => {
				return { id: scenarioEnum.id, label: scenarioEnum.label };
			})
			.sort((a, b) => a.id - b.id);
	}

	getCustomProperties(field: FormlyFieldConfig) {
		return {
			downloadCdpBtn: { show: this.showServiceabilityCustomField(field, 'downloadCdpAction') },
			borrowingEstimateSection: { show: this.showServiceabilityCustomField(field, 'borrowingEstimateSection') },
			estimatedRepayments: { show: this.showServiceabilityCustomField(field, 'estimatedRepayments') },
			borrowingEstimateMaximumLoanAmountCard: {
				show: this.showServiceabilityCustomField(field, 'borrowingEstimateMaximumLoanAmountCard')
			},
			borrowingEstimateMaximumRepaymentsCard: {
				show: this.showServiceabilityCustomField(field, 'borrowingEstimateMaximumRepaymentsCard')
			}
		};
	}

	getCalculationResults(serviceability: ServiceabilityResult) {
		if (serviceability?.calculationResults?.length) {
			serviceability.calculationResults[0].metrics.forEach((metric) => {
				metric.calculatedValueForDisplay = this.formatValue(
					metric,
					serviceability.calculationResults[0].calculationFrequency
				);
			});
			return serviceability.calculationResults[0];
		} else {
			return undefined;
		}
	}

	private formatValue(metric: IndicatorMetric, calculationFrequency: FrequencyShort): string {
		switch (metric.metricFormat) {
			case MetricFormat.Number:
				return `${truncateDecimalsAndFormat(metric.calculatedValue, 2)}`;
			case MetricFormat.CurrencyWithFrequency:
				return `${this.currencyPipe.transform(metric.calculatedValue)} ${
					calculationFrequency == FrequencyShort.Monthly ? 'per month' : 'per year'
				}`;
			case MetricFormat.Percentage:
				return `${truncateDecimalsAndFormat(metric.calculatedValue, 2)}%`;
			case MetricFormat.Currency:
				return `${this.currencyPipe.transform(metric.calculatedValue)}`;
			default:
				return `${truncateDecimalsAndFormat(metric.calculatedValue, 2)}`;
		}
	}

	private showServiceabilityCustomField(field: FormlyFieldConfig, fieldName: string): boolean {
		const hideField = get(
			field,
			`templateOptions.properties.${fieldName}.widget.formlyConfig.hideExpression`
		) as boolean;
		return !hideField ?? true;
	}
}
