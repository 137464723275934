import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { GuarantorInterviewCompletionDTO } from '@app/modules/typings/api';

export class GuarantorInterviewCompletionTransformer {
	static fromPayload(payload: GuarantorInterviewCompletionDTO): GuarantorInterviewCompletionModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			statementOfSP: payload.statementOfSP,
			spousalOrDefactoFinancialDisclosure: payload.spousalOrDefactoFinancialDisclosure,
			verifyIncome: payload.verifyIncome,
			signedDeclaration: payload.signedDeclaration,
			adviseDocumentConsent: payload.adviseDocumentConsent
		};
	}

	static toPayload(
		model: GuarantorInterviewCompletionModel,
		applicationId: number
	): Partial<GuarantorInterviewCompletionDTO> {
		return {
			id: model.id ?? CONSTANTS.NEW_ID,
			applicationId,
			lendingGuaranteeId: model.lendingGuaranteeId,
			statementOfSP: model.statementOfSP,
			spousalOrDefactoFinancialDisclosure: model.spousalOrDefactoFinancialDisclosure,
			verifyIncome: model.verifyIncome,
			signedDeclaration: model.signedDeclaration,
			adviseDocumentConsent: model.adviseDocumentConsent
		};
	}
}

export interface GuarantorInterviewCompletionModel {
	id?: number;
	lendingGuaranteeId?: number;
	statementOfSP?: boolean;
	spousalOrDefactoFinancialDisclosure?: boolean;
	verifyIncome?: boolean;
	signedDeclaration?: boolean;
	adviseDocumentConsent?: boolean;
}
