import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { clone, cloneDeep } from 'lodash-es';

import { SimpConfirmationDialogService } from '@simpology/client-components';
import { ConfirmationDialogHeaderType } from '@simpology/client-components/utils';

import {
	defaultPoiSecureValue,
	IdentityDocumentDetailsModel,
	poiSecuredFields,
	poiTemplateFields,
	ProofOfIdentityDetailsModel,
	ProofOfIdentityModel,
	ProofOfIdentityModelTransformer
} from '@app/modules/applicants/models/proof-of-identity-details-model';
import { ProofOfIdentityService } from '@app/modules/applicants/services/proof-of-identity.service';
import { PersonApplicant } from '@app/modules/applicants/typings/applicants';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { InputActionType } from '@app/modules/shared/enums/app.enums';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormlyFieldTypes } from '@app/modules/simp-formly/enums/formly-field-types';
import {
	formlyExtendExpressionProperties,
	formlyGenerateLabels,
	formlyOnChange,
	formlyOnClick,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { ApplicationNonLinkedDocumentsService } from '@app/modules/summary-lodgement/services/application-non-linked-documents.service';
import { DocumentFileModel } from '@app/modules/typings/document-checklist-template-details-dto';

@Injectable({ providedIn: 'root' })
export class ProofOfIdentityTransformerService {
	constructor(
		private simpFormlyModalService: SimpFormlyModalService,
		private proofOfIdentityService: ProofOfIdentityService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formDataService: FormDataService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private applicationDocumentsService: ApplicationNonLinkedDocumentsService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository
	) {}

	transformMetadata(formFields: FormlyFieldConfig[] | undefined, index: number, personApplicant: PersonApplicant) {
		this.configPOIHtmlInstructions(formFields, index);
		this.handlePOIDocuments(formFields, index, personApplicant);
		this.handleSubsectionStatus(formFields, index, personApplicant);
		this.handleEditSecuredFields(formFields, index);
	}

	fetchPOIForAplicant(index: number, personApplicant: PersonApplicant): Observable<ProofOfIdentityDetailsModel[]> {
		return this.proofOfIdentityService
			.fetchPOIDocumentsForApplicant(personApplicant)
			.pipe(
				tap((newPOIData) => this.simpFormlyHandlerService.upsertToState(`proofOfIdentity-${index}`, of(newPOIData)))
			);
	}

	private configPOIHtmlInstructions(formFields: FormlyFieldConfig[] | undefined, index: number) {
		formlyExtendExpressionProperties(formFields, `personApplicant-${index}.proofOfIdentity`, {
			'templateOptions.subtitle': (model: ProofOfIdentityDetailsModel[]) => {
				return model[0]?.identityDocumentDetails?.poiHtmlInstructions;
			}
		});
	}

	private handleEditSecuredFields(formFields: FormlyFieldConfig[] | undefined, index: number) {
		poiSecuredFields.forEach((secureField) => {
			// drivers licence field is used for validation only
			let fetchField = secureField;
			if (secureField === 'driversLicenceNumber') {
				fetchField = 'documentNumber';
			}
			formlyExtendExpressionProperties(
				formFields,
				`personApplicant-${index}.proofOfIdentity.identityDocumentDetails.proofOfIdentityModal.${secureField}`,
				{
					'templateOptions.placeholder': (model) => ((model as ProofOfIdentityModel).id ? defaultPoiSecureValue : '')
				}
			);

			formlyOnClick(
				formFields,
				`personApplicant-${index}.proofOfIdentity.identityDocumentDetails.proofOfIdentityModal.${secureField}`,
				(field: FormlyFieldConfig, clickType: InputActionType) => {
					if (clickType !== InputActionType.EDIT) {
						return;
					}

					if (!field.templateOptions) {
						return;
					}

					field.templateOptions.placeholder = '';
					field.templateOptions.disabled = false;
					field.formControl?.patchValue(null);

					if (field.type === FormlyFieldTypes.DatePicker) {
						this.proofOfIdentityService
							.getSensitiveDateFieldValue((field.model as ProofOfIdentityModel).id, field.key as string)
							.subscribe((value) => {
								field.formControl?.patchValue(value);
							});
					} else {
						this.proofOfIdentityService
							.getSensitiveFieldValue((field.model as ProofOfIdentityModel).id, fetchField)
							.subscribe((response: { value: string }) => {
								let value: string | number = response.value;
								if (field.type === FormlyFieldTypes.Select) {
									value = Number(response.value);
								}
								field.formControl?.patchValue(value);
							});
					}
				}
			);
		});
	}

	private handlePOIDocuments(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		personApplicant: PersonApplicant
	) {
		this.setPOIModalTitle(formFields, index);

		formlyOnClick(
			formFields,
			`personApplicant-${index}.proofOfIdentity.identityDocumentDetails`,
			(field: FormlyFieldConfig, clickType: { type: string }) => {
				const proofOfIdentityDetailsModel = field.parent?.model as ProofOfIdentityDetailsModel;

				if (clickType?.type === 'clearClick') {
					this.openClearPOIConfirmedDialog(
						personApplicant.securePersonId!,
						proofOfIdentityDetailsModel.identityDocumentDetails.poiDocTemplate.name,
						proofOfIdentityDetailsModel.identityDocumentDetails.proofOfIdentityModal.id,
						personApplicant,
						index,
						field
					);
				} else {
					const poiFromState = this.getPoiFromState(index, proofOfIdentityDetailsModel);
					if (poiFromState) {
						field.formControl?.patchValue(poiFromState);
					}

					this.openPoiModal(field, personApplicant, proofOfIdentityDetailsModel, index);
				}
			}
		);

		formlyOnChange(
			formFields,
			`personApplicant-${index}.proofOfIdentity.identityDocumentDetails.proofOfIdentityModal.uploadDocument`,
			(field: FormlyFieldConfig, file: Array<File>) => {
				const uploadedDocs = getFormField(field?.parent?.fieldGroup, 'uploadedDocs');
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				uploadedDocs?.templateOptions!.add(null, {
					fileName: file[0].name,
					file: file[0]
				} as DocumentFileModel);
			}
		);

		formlyOnClick(
			formFields,
			`personApplicant-${index}.proofOfIdentity.identityDocumentDetails.proofOfIdentityModal.uploadedDocs.delete`,
			(field: FormlyFieldConfig) => {
				const upDocField = field?.parent?.parent as FormlyFieldConfig;
				const upDocModel = field?.parent?.parent?.model as DocumentFileModel[];
				const docIndex = upDocModel.findIndex(
					(item: DocumentFileModel) => item.id === (field.model as DocumentFileModel).id
				);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				upDocField?.templateOptions!.remove(docIndex);
			}
		);
	}

	private getPoiFromState(index: number, proofOfIdentityDetailsModel: ProofOfIdentityDetailsModel) {
		const statePersonApplicant = this.formDataService.getPersonApplicantByIndex(index);
		const stateProofOfIdentities = statePersonApplicant.proofOfIdentity;
		return stateProofOfIdentities?.find(
			(poi) =>
				poi?.identityDocumentDetails?.poiDocTemplate?.identityDocumentTypeEnum ===
				proofOfIdentityDetailsModel?.identityDocumentDetails?.poiDocTemplate?.identityDocumentTypeEnum
		);
	}

	private openPoiModal(
		field: FormlyFieldConfig,
		personApplicant: PersonApplicant,
		proofOfIdentityDetailsModel: ProofOfIdentityDetailsModel,
		index: number
	) {
		const proofOfIdentityModal = getFormField(field?.fieldGroup, 'proofOfIdentityModal');

		const oldProofOfIdentityModel = clone(field.model as IdentityDocumentDetailsModel);
		oldProofOfIdentityModel.proofOfIdentityModal = ProofOfIdentityModelTransformer.createDefaultProofOfIdentityModalObj(
			oldProofOfIdentityModel.proofOfIdentityModal
		);

		this.handeOpenPOIModalSecuredFields(proofOfIdentityModal);

		const uploadedDocs = getFormField(proofOfIdentityModal?.fieldGroup, 'uploadedDocs');
		const oldUploadedDocsData = cloneDeep(uploadedDocs?.model) as DocumentFileModel[];
		const initalProofOfIdentityState: ProofOfIdentityDetailsModel[] = cloneDeep(
			this.simpFormlyHandlerService.getStateData(`proofOfIdentity-${index}`)
		);

		const modalodalRef = this.simpFormlyModalService.openModal(field, 'proofOfIdentityModal', {
			windowClass: 'simp-padding-left-small'
		});

		modalodalRef.dismissed.subscribe(() => {
			this.revertModalStateToPrevious(
				field,
				oldProofOfIdentityModel,
				index,
				initalProofOfIdentityState,
				oldUploadedDocsData
			);
			modalodalRef.close();
			modalSubscription.unsubscribe();
		});

		const modalSubscription = modalodalRef.action.subscribe((parentAction) => {
			if (parentAction !== 'submit') {
				this.revertModalStateToPrevious(
					field,
					oldProofOfIdentityModel,
					index,
					initalProofOfIdentityState,
					oldUploadedDocsData
				);
				modalodalRef.close();
				modalSubscription.unsubscribe();
				return;
			}

			const applicants = cloneDeep(this.formDataService.getSetupApplicants());
			const personApplicantState = applicants.find((x) => x.id === personApplicant.id);
			if (!personApplicantState) {
				return;
			}

			personApplicant.securePersonId = personApplicantState.securePersonId;
			this.savePoiForApplicant(oldUploadedDocsData, proofOfIdentityDetailsModel, index, personApplicant)
				.pipe(
					tap(() => {
						this.markAllFieldsAsUntochoued(field);
						modalodalRef.close();
						modalSubscription.unsubscribe();
					})
				)
				.subscribe();
		});
	}

	private revertModalStateToPrevious(
		field: FormlyFieldConfig,
		oldProofOfIdentityModel: IdentityDocumentDetailsModel,
		index: number,
		initalProofOfIdentityState: ProofOfIdentityDetailsModel[],
		oldUploadedDocsData: DocumentFileModel[]
	) {
		if (field?.formControl && oldProofOfIdentityModel) {
			field.formControl.patchValue(oldProofOfIdentityModel);
		}

		this.simpFormlyHandlerService.upsertToStateWithData(`proofOfIdentity-${index}`, initalProofOfIdentityState);
		this.discardUnsavedDocumentsModal(field, oldUploadedDocsData);
		this.markAllFieldsAsUntochoued(field);
	}

	private setPOIModalTitle(formFields: FormlyFieldConfig[] | undefined, index: number) {
		formlyExtendExpressionProperties(
			formFields,
			`personApplicant-${index}.proofOfIdentity.identityDocumentDetails.proofOfIdentityModal`,
			{
				'templateOptions.label': (model, formState, field: FormlyFieldConfig) => {
					const identityDocumentDetailsModel = field.parent?.model as IdentityDocumentDetailsModel;
					return identityDocumentDetailsModel?.poiDocTemplate.name;
				}
			}
		);
	}

	private handeOpenPOIModalSecuredFields(proofOfIdentityModal: FormlyFieldConfig | undefined) {
		if (!(proofOfIdentityModal?.model as ProofOfIdentityModel)?.id) {
			return;
		}

		poiSecuredFields.forEach((secureField) => {
			const field = getFormField(proofOfIdentityModal?.fieldGroup, secureField);
			if (field) {
				if (field.templateOptions) {
					field.templateOptions.placeholder = defaultPoiSecureValue;
				}
				field.formControl?.setValue(null);
				field?.formControl?.disable();
			}
		});
	}

	private markAllFieldsAsUntochoued(field: FormlyFieldConfig) {
		field.parent?.parent?.fieldGroup?.forEach((item) => {
			item?.formControl?.markAsPristine();
			item?.formControl?.markAsUntouched();
		});
	}

	private savePoiForApplicant(
		oldDocs: DocumentFileModel[],
		proofOfIdentityDetailsModel: ProofOfIdentityDetailsModel,
		index: number,
		personApplicant: PersonApplicant
	): Observable<ProofOfIdentityDetailsModel[]> {
		const poiDocTemplate = proofOfIdentityDetailsModel.identityDocumentDetails.poiDocTemplate;
		const proofOfIdentityModel = proofOfIdentityDetailsModel.identityDocumentDetails.proofOfIdentityModal;
		const uploadedDocs = cloneDeep(
			proofOfIdentityDetailsModel.identityDocumentDetails.proofOfIdentityModal.uploadedDocs
		);

		const removedDocuments = oldDocs?.filter((oldDoc) => !uploadedDocs.find((doc) => doc.id === oldDoc.id));
		const removeDocumentsBeforeUpatePOI = oldDocs && removedDocuments?.length !== 0;
		if (removeDocumentsBeforeUpatePOI) {
			return forkJoin(
				removedDocuments.map((doc) =>
					this.applicationDocumentsService.deleteFile(doc.id, personApplicant.securePersonId!, poiDocTemplate.name)
				)
			).pipe(
				switchMap(() =>
					this.proofOfIdentityService.updatePOIForApplicant(
						personApplicant.id,
						personApplicant.securePersonId!,
						poiDocTemplate,
						proofOfIdentityModel,
						uploadedDocs?.filter((doc) => doc.file).map((doc) => doc.file) ?? []
					)
				),
				switchMap(() => this.fetchPOIForAplicant(index, personApplicant))
			);
		} else {
			return this.proofOfIdentityService
				.updatePOIForApplicant(
					personApplicant.id,
					personApplicant.securePersonId!,
					poiDocTemplate,
					proofOfIdentityModel,
					uploadedDocs?.filter((doc) => doc.file).map((doc) => doc.file) ?? []
				)
				.pipe(switchMap(() => this.fetchPOIForAplicant(index, personApplicant)));
		}
	}

	private discardUnsavedDocumentsModal(field: FormlyFieldConfig, oldDocs: DocumentFileModel[]) {
		const uploadedDocs = this.removeAllDocs(field);

		for (let i = 0; i < oldDocs?.length; i++) {
			const doc = oldDocs[i];
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			uploadedDocs?.templateOptions!.add(i, doc);
		}
	}

	private removeAllDocs(field: FormlyFieldConfig) {
		const proofOfIdentityModal = getFormField(field?.fieldGroup, 'proofOfIdentityModal');
		const uploadedDocs = getFormField(proofOfIdentityModal?.fieldGroup, 'uploadedDocs');
		const model = cloneDeep(uploadedDocs?.model as DocumentFileModel[]);
		const uploadedDocsField = getFormField(proofOfIdentityModal?.fieldGroup, 'uploadedDocs');

		if (!uploadedDocsField?.fieldGroup || uploadedDocsField?.fieldGroup?.length === 0) {
			return;
		}

		for (let i = 0; i < model?.length; i++) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			uploadedDocs?.templateOptions!.remove(0);
		}

		return uploadedDocs;
	}

	private clearPOIData(field: FormlyFieldConfig) {
		const proofOfIdentityModal = getFormField(field?.fieldGroup, 'proofOfIdentityModal');

		proofOfIdentityModal?.fieldGroup
			?.filter((control) => !poiTemplateFields.includes(String(control.key)))
			.forEach((control) => {
				control.formControl?.patchValue(null);
			});
	}

	private openClearPOIConfirmedDialog(
		personId: number,
		documentName: string,
		proofOfIdentityId: number,
		personApplicant: PersonApplicant,
		index: number,
		field: FormlyFieldConfig
	) {
		this.confirmationDialogService
			.confirm(
				ConfirmationDialogHeaderType.Delete,
				`Clear document?`,
				`All information and files will be deleted for this record.`,
				'Clear',
				'Cancel',
				'sm',
				'refi-modal-content'
			)
			.subscribe((isAccepted: boolean) => {
				if (!isAccepted) {
					return;
				}
				if (!proofOfIdentityId) {
					return;
				}
				this.applicationDocumentsService
					.deleteIdentityDocument(personId, documentName, proofOfIdentityId)
					.pipe(
						tap(() => {
							this.removeAllDocs(field);
							this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForSecurePerson(
								personApplicant.id,
								personApplicant.securePersonId
							);
						}),
						switchMap(() => this.fetchPOIForAplicant(index, personApplicant)),
						tap(() => {
							this.clearPOIData(field);
						})
					)
					.subscribe();
			});
	}

	private handleSubsectionStatus(
		formFields: FormlyFieldConfig[] | undefined,
		index: number,
		personApplicant: PersonApplicant
	) {
		formlyExtendExpressionProperties(formFields, `personApplicant-${index}.proofOfIdentity`, {
			'templateOptions.customTitleTemplate': (
				model: ProofOfIdentityDetailsModel[],
				formState: any,
				field: FormlyFieldConfig
			) => {
				const proofOfIdentityLabels = getFormField(field.parent?.fieldGroup, 'proofOfIdentityLabels');
				const labels = formlyGenerateLabels(proofOfIdentityLabels as FormlyFieldConfig, 'proofOfIdentityLabels');
				const personApplicantState: SetUpApplicant | undefined = this.formDataService.getSetupApplicantById(
					personApplicant.id
				);
				if (!personApplicantState?.securePersonId) {
					return labels.pending
						? `<div class='simp-badge simp-badge-pill simp-badge-light-grey simp-margin-left-small'><span>${
								labels.pending ?? 'Pending'
						  }</span></div>`
						: '';
				}

				if (model?.length === 0) {
					return;
				}
				return !model[0].identityDocumentDetails?.isPOIStatusCompleted || !labels.completed
					? ''
					: `<div class="simp-badge simp-badge-pill simp-badge-light-grey simp-margin-left-small">
								<span>${labels.completed}</span>
								<i class='fa fa-check-circle simp-text--green ms-sm-2 fa-lg'></i>
						</div>`;
			}
		});
	}
}
