import { ResidencyStatus, TaxResidencyOptions } from '@app/modules/shared/enums/app.enums';
import {
	ForeignTaxAssociationDetailsModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ForeignTaxAssociationDTO, PersonApplicantDTO } from '@app/modules/typings/api';

export class ResidencyTransformer {
	static fromPayload(applicant: PersonApplicantDTO, formEnumsQuery: FormEnumsQuery): PersonResidencyModel {
		return {
			countryOfResidency: applicant.countryOfResidency,
			residencyStatus: applicant.residencyStatus,
			countryOfCitizenship: applicant.countryOfCitizenship,
			taxResidencyOutsideAu: this.getTaxResidencyOutsideAu(applicant.taxResidencyOutsideAu),
			residentSince: applicant.residentSinceDate,
			visaSubclassCode: applicant.visaSubclassCode,
			temporaryVisaExpiryDate: applicant.temporaryVisaExpiryDate,
			hasAppliedForPermanentResidencyVisa: applicant.hasAppliedForPermanentResidencyVisa,
			hasAppliedForAustralianCitizenship: applicant.hasAppliedForAustralianCitizenship,
			foreignTaxAssociationId: applicant.foreignTaxAssociationId,
			countryOfForeignTaxResidencySummaryText: this.getCountryOfForeignTaxResidencySummaryText(
				formEnumsQuery,
				this.getTaxResidencyOutsideAu(applicant.taxResidencyOutsideAu),
				applicant.foreignTaxAssociation?.foreignTaxAssociationDetails?.map((x) => x.countryCode as number)
			),
			foreignTaxAssociationDetails: ForeignTaxAssociationTransformer.fromPayload(
				applicant.foreignTaxAssociation as ForeignTaxAssociationDTO,
				applicant
			)?.foreignTaxAssociationDetails as ForeignTaxAssociationDetailsModel[]
		};
	}

	static toPayload(personResidencyModel: PersonResidencyModel): Partial<PersonApplicantDTO> {
		const residencyStatus = personResidencyModel?.residencyStatus;
		return {
			countryOfResidency: personResidencyModel.countryOfResidency,
			residencyStatus,
			countryOfCitizenship: personResidencyModel.countryOfCitizenship,
			residentSinceDate:
				residencyStatus === ResidencyStatus.PermanentlyInAustralia ? personResidencyModel.residentSince : undefined,
			visaSubclassCode:
				residencyStatus !== ResidencyStatus.PermanentlyInAustralia ? personResidencyModel.visaSubclassCode : undefined,
			temporaryVisaExpiryDate:
				residencyStatus !== ResidencyStatus.PermanentlyInAustralia
					? personResidencyModel.temporaryVisaExpiryDate
					: undefined,
			hasAppliedForPermanentResidencyVisa:
				residencyStatus !== ResidencyStatus.PermanentlyInAustralia
					? personResidencyModel.hasAppliedForPermanentResidencyVisa
					: undefined,
			hasAppliedForAustralianCitizenship:
				residencyStatus !== ResidencyStatus.PermanentlyInAustralia
					? personResidencyModel.hasAppliedForAustralianCitizenship
					: undefined,
			taxResidencyOutsideAu: personResidencyModel.taxResidencyOutsideAu
				? personResidencyModel.taxResidencyOutsideAu === TaxResidencyOptions.Yes
				: undefined,
			foreignTaxAssociationId: personResidencyModel.foreignTaxAssociationId
		};
	}

	static getTaxResidencyOutsideAu(taxResidencyOutsideAu?: boolean) {
		return taxResidencyOutsideAu === null
			? undefined
			: taxResidencyOutsideAu
			? TaxResidencyOptions.Yes
			: TaxResidencyOptions.AustraliaOnly;
	}

	static getCountryOfForeignTaxResidencySummaryText(
		formEnumsQuery: FormEnumsQuery,
		taxResidencyOutsideAu: TaxResidencyOptions | undefined,
		countriesOfForeignTaxResidency: number[] | undefined
	): string | undefined {
		if (taxResidencyOutsideAu === TaxResidencyOptions.Yes) {
			return countriesOfForeignTaxResidency?.reduce((prev, curr) => {
				return `${prev} , ${formEnumsQuery.getOptionLabel('CountryCode', curr)}`;
			}, '');
		}
		return '';
	}
}

export interface PersonResidencyModel {
	countryOfResidency?: number;
	residencyStatus?: ResidencyStatus;
	countryOfCitizenship?: number;
	taxResidencyOutsideAu?: number;
	residentSince?: string;
	visaSubclassCode?: string;
	temporaryVisaExpiryDate?: string;
	hasAppliedForPermanentResidencyVisa?: boolean;
	hasAppliedForAustralianCitizenship?: boolean;
	countryOfForeignTaxResidencySummaryText?: string;
	foreignTaxAssociationId?: number;
	foreignTaxAssociationDetails?: ForeignTaxAssociationDetailsModel[];
}
