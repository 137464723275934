import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-toggle-switch',
	templateUrl: './formly-toggle-switch.component.html',
	styleUrls: ['./formly-toggle-switch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyToggleSwitchComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';
	onClick(): void {
		this.formControl.markAsDirty();
	}
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
	}
}
