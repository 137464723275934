import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FinancialAssetType, FinancialInstitution } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { PercentOwned, PersonApplicantDTO, RefiSavingsAssetsDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiSavingsAssetsTransformer {
	static toPayload(
		savings: SavingsAssetsDetails,
		applicationId: number,
		formEnumQuery: FormEnumsQuery
	): RefiSavingsAssetsDTO {
		return {
			nonRealEstateAssetId: savings.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			financialInstitution: savings.financialInstitution,
			value: savings.savingsValue,
			assetType: savings.assetType,
			percentsOwned: RefiTransformerHelper.setPercentsOwnedWithJointSelection(formEnumQuery, savings.owner)
		};
	}

	static fromPayload(
		payload: RefiSavingsAssetsDTO,
		applicants: PersonApplicantDTO[],
		description?: EnumObject[]
	): SavingsAssetsModel {
		const assetTypeEnum = description?.find((x) => x.id === payload.assetType);
		const typeDescription = assetTypeEnum?.label;

		return {
			savingsModal: {
				id: payload.nonRealEstateAssetId,
				owner: RefiTransformerHelper.getOwnership(payload.percentsOwned as PercentOwned[]),
				financialInstitution: payload.financialInstitution,
				assetType: payload.assetType,
				savingsValue: payload.value,
				readonly: payload.readonly
			},
			summary: `${RefiTransformerHelper.getJointApplicantNames(applicants, payload.percentsOwned)} ${formatCurrency(
				payload.value || 0,
				'en-AU',
				'$'
			)} ${typeDescription}`
		};
	}
}

export interface SavingsAssetsDetails {
	id?: number;
	assetType?: FinancialAssetType;
	savingsValue?: number;
	owner?: number;
	financialInstitution?: FinancialInstitution;
	readonly?: boolean;
}

export interface SavingsAssetsModel {
	savingsModal: SavingsAssetsDetails;
	summary: string;
}
