import {
	ApplicantEntityType,
	ClearingFromThisLoan,
	FinancialInstitution,
	PaymentType,
	Proportions,
	RepaymentFrequency,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import {
	calculateMonthly,
	getNullableBoolean,
	getNullableYesNo,
	repaymentToFrequencyFull
} from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwned, PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AmountSelect, Term } from '@app/modules/simp-formly/typings/formly-app';
import { OtherLiabilityDTO, PercentOwned } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { CONSTANTS } from '../constants/constants';

export class OtherLiabilitiesTransformer {
	static toPayload(applicationId: number, otherLiability: OtherLiabilityModel): OtherLiabilityDTO {
		const details = otherLiability.details.otherLiabilityDetails;
		return {
			id: otherLiability.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			financialInstitution: details.financialInstitution,
			outstandingBalance: details.outstandingBalance,
			creditLimit: details.creditLimit,
			newLimit: details.newLimit,
			interestRate: details.annualInterestRate,
			repaymentAmount: details.repayments?.amount,
			repaymentFrequency: details.repayments?.frequency,
			percentOwnedProportions: details.isEqualShare ? Proportions.Equal : Proportions.Specified, //
			liabilityType: details.type,
			remainingTermDuration: details.remainingTerm?.number ? +details.remainingTerm.number : undefined,
			remainingTermUnits: details.remainingTerm?.frequency ? details.remainingTerm.frequency : undefined,
			secured: details.secured,
			percentsOwned: this.setPercentageByOwnership(details.percentsOwned, details.ownershipId),
			otherFinancialInstitutionName: details.otherFinancialInstitutionName,
			description: details.description,
			accountName: details.accountName,
			bsb: details.bsb,
			accountNumber: details.accountNumber,
			paymentType: details.paymentType,
			hasArrears: !!details.hasArrears,
			arrearsNumberOfMissedPayments: details.arrearsNumberOfMissedPayments,
			arrearsAmount: details.arrearsAmount,
			closingOnSettlement: getNullableBoolean(details.clearingBalanceOnSettlement),
			clearingFromThisLoan: getNullableBoolean(details.clearingFromThisLoan),
			clearingThisLiability: details.clearingThisLiability,
			refinanceAmount: details.disbursementAmount,
			refinanceCosts: details.refinanceCost,
			availableForRedrawAmount: details.availableForRedrawAmount,
			originalAmount: details.originalAmount,
			isForeign: details.isForeign,
			paidInFull: details.paidInFull,
			negativelyGeared: getNullableBoolean(details.negativelyGeared),
			linkedNonRealEstateAssets: details.linkedAssets
				?.filter((linkedAsset) => linkedAsset.assetType)
				?.map((a) => {
					return {
						id: a.assetType.id,
						assetType: parseInt(a.assetType.info as string)
					};
				})
		};
	}

	static fromPayload(
		otherLiability: OtherLiabilityDTO,
		formEnumsQuery: FormEnumsQuery,
		applicants: ShortApplicant[]
	): OtherLiabilityModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			otherLiability?.percentsOwned,
			applicants
		);
		const liabilityType = formEnumsQuery.getOptionLabel('LiabilityType', otherLiability.liabilityType as number);
		const financialInstitutionString = formEnumsQuery.getOptionLabel(
			'FinancialInstitution',
			otherLiability.financialInstitution as number
		);

		const repaymentAmount = calculateMonthly(
			otherLiability.repaymentAmount,
			repaymentToFrequencyFull(otherLiability.repaymentFrequency as RepaymentFrequency)
		);

		const otherLiabilitiesModel: OtherLiabilityModel = {
			id: otherLiability.id,
			applicationId: otherLiability.applicationId,
			type: otherLiability.liabilityType,
			summaryAmount: otherLiability.outstandingBalance,
			repaymentAmount,
			amountEntry: {
				amount: otherLiability.repaymentAmount as number,
				frequency: otherLiability.repaymentFrequency as RepaymentFrequency
			},
			details: {
				otherLiabilityDetails: {
					type: otherLiability.liabilityType,
					financialInstitution: otherLiability.financialInstitution,
					outstandingBalance: otherLiability.outstandingBalance,
					creditLimit: otherLiability.creditLimit,
					newLimit: otherLiability.newLimit || otherLiability.creditLimit,
					annualInterestRate: otherLiability.interestRate,
					secured: otherLiability.secured,
					ownershipId: this.getOwnershipByPercentage(percentageOwned),
					percentsOwned: percentageOwned,
					isEqualShare: otherLiability.percentOwnedProportions === Proportions.Equal,
					remainingTerm: {
						number: otherLiability.remainingTermDuration,
						frequency: otherLiability.remainingTermUnits ? otherLiability.remainingTermUnits : undefined
					},
					repayments: {
						amount: otherLiability.repaymentAmount as number,
						frequency: otherLiability.repaymentFrequency as RepaymentFrequency
					},
					otherFinancialInstitutionName: otherLiability.otherFinancialInstitutionName,
					description: otherLiability.description,
					accountName: otherLiability.accountName,
					bsb: otherLiability.bsb,
					accountNumber: otherLiability.accountNumber,
					paymentType: otherLiability.paymentType,
					hasArrears: getNullableYesNo(otherLiability.hasArrears),
					arrearsNumberOfMissedPayments: otherLiability.arrearsNumberOfMissedPayments,
					arrearsAmount: otherLiability.arrearsAmount,
					clearingBalanceOnSettlement: getNullableYesNo(otherLiability.closingOnSettlement),
					clearingFromThisLoan: getNullableYesNo(otherLiability.clearingFromThisLoan),
					clearingThisLiability: otherLiability.clearingThisLiability,
					disbursementAmount: otherLiability.refinanceAmount,
					refinanceCost: otherLiability.refinanceCosts,
					availableForRedrawAmount: otherLiability.availableForRedrawAmount,
					originalAmount: otherLiability.originalAmount,
					isForeign: otherLiability.isForeign,
					paidInFull: otherLiability.paidInFull,
					negativelyGeared: getNullableYesNo(otherLiability.negativelyGeared),
					linkedAssets:
						otherLiability.linkedNonRealEstateAssets?.map((x) => {
							return {
								assetType: {
									id: x.id
								} as EnumObject
							};
						}) ?? []
				},

				extract: otherLiability.otherFinancialInstitutionName
					? `${liabilityType} ${financialInstitutionString ? '- ' + financialInstitutionString : ''} - ${
							otherLiability.otherFinancialInstitutionName
					  }`
					: `${liabilityType} ${financialInstitutionString ? '- ' + financialInstitutionString : ''}`
			},
			outstandingBalance: otherLiability.outstandingBalance ?? 0, // [TAMA5-9520] When outstaning balance is null it should 0 as per the requirement
			creditLimit: otherLiability.creditLimit ?? 0, // [TAMA5-9520] When credit limit is null it should 0 as per the requirement
			monthlyRepayment: repaymentAmount
		};
		return otherLiabilitiesModel;
	}

	static updateAssetTypeEnum(otherLiabilities: OtherLiabilityModel[], formEnumsQuery: FormEnumsQuery) {
		return otherLiabilities.map((otherLiability) => {
			const linkedAssets = otherLiability.details?.otherLiabilityDetails?.linkedAssets || [];

			return {
				...otherLiability,
				details: {
					...otherLiability.details,
					otherLiabilityDetails: {
						...otherLiability.details?.otherLiabilityDetails,
						linkedAssets: linkedAssets.map((linkedAsset) => {
							return {
								assetType: formEnumsQuery.getOption('Assets', linkedAsset.assetType.id) as EnumObject
							};
						})
					}
				}
			};
		});
	}

	private static getOwnershipByPercentage(percentsOwned: PercentageOwned[]): number {
		return percentsOwned?.find((percent) => percent?.percent === 100)?.applicantId ?? -1;
	}

	private static setPercentageByOwnership(
		percentsOwned: PercentOwned[] | undefined,
		ownershipId: number | undefined
	): PercentOwned[] | undefined {
		if (!ownershipId) return percentsOwned;
		return [
			{
				applicantId: ownershipId,
				percent: 100,
				applicantEntityTypeEnum: ApplicantEntityType.PersonApplicant
			}
		];
	}
}

export interface OtherLiabilityModel {
	applicationId: number;
	id?: number;
	details: { otherLiabilityDetails: OtherLiabilityDetails; extract: string };
	type?: number;
	repaymentAmount?: number;
	outstandingBalance?: number;
	creditLimit?: number;
	monthlyRepayment?: number;
	// Used in Company & Trust Financial Position
	amountEntry: AmountSelect;
	summaryAmount?: number;
}
interface OtherLiabilityDetails {
	financialInstitution?: FinancialInstitution;
	type?: number;
	outstandingBalance?: number;
	creditLimit?: number;
	newLimit?: number;
	annualInterestRate?: number;
	repayments: AmountSelect;
	ownershipId?: number;
	percentsOwned?: PercentOwned[];
	isEqualShare?: boolean;
	remainingTerm?: Partial<Term>;
	secured?: boolean;
	otherFinancialInstitutionName?: string;
	description?: string;
	accountName?: string;
	bsb?: string;
	accountNumber?: string;
	paymentType?: PaymentType;
	hasArrears?: YesNo;
	arrearsNumberOfMissedPayments?: number;
	arrearsAmount?: number;
	refinanceCost?: number;
	disbursementAmount?: number;
	clearingBalanceOnSettlement?: YesNo;
	clearingFromThisLoan?: YesNo;
	clearingThisLiability?: ClearingFromThisLoan;
	originalAmount?: number;
	availableForRedrawAmount?: number;
	isForeign: boolean;
	paidInFull?: boolean;
	negativelyGeared?: YesNo;
	linkedAssets?: { assetType: EnumObject }[];
	hideLinkedSecurities?: boolean; //UI purposes only
}
