import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { GovernmentFeesDTO } from '@app/modules/typings/api';

export interface GovernmentFeesModel {
	id: number | undefined;
	applicationId: number;
	amount?: number;
	description?: string;
	isCapitalised?: boolean;
}

export class GovernmentFeesTransformer {
	static toPayload(applicationId: number, input: GovernmentFeesModel): GovernmentFeesDTO {
		return {
			id: input.id || CONSTANTS.NEW_ID,
			applicationId: applicationId,
			amount: input.amount,
			description: input.description,
			isCapitalised: input.isCapitalised
		};
	}

	static fromPayload(input: GovernmentFeesDTO): GovernmentFeesModel {
		return input;
	}
}
