import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import { URL_CONSTANTS } from '../shared/constants/api.constants';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { ConstructionDetailsDTO } from '../typings/api';

@Injectable({ providedIn: 'root' })
export class ConstructionDetailsService extends BaseJourneyService {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.CONSTRUCTION_DETAILS);
	}

	getConstructionDetails(propertyId: number): Observable<ConstructionDetailsDTO> {
		return this.get(`${this.applicationDataQuery.applicationId()}/${propertyId}`).pipe(
			map((res) => res as ConstructionDetailsDTO),
			catchError(() => [{} as ConstructionDetailsDTO])
		);
	}

	saveConstructionDetails(constructionDetails: ConstructionDetailsDTO): Observable<void> {
		return <Observable<void>>this.postCustom('', constructionDetails);
	}
}
