import { Injectable } from '@angular/core';
import { PersonInsurancesModel } from '@app/modules/applicants/models/insurances-model';
import { HouseholdInfo } from '@app/modules/applicants/typings/household';
import {
	ApplicantEmploymentModel,
	ApplicantPaygModel,
	IncomeModel
} from '@app/modules/financial-position/model/applicant-employment.model';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { ApplicantRole, IncomeType, InsuranceType } from '@app/modules/shared/enums/app.enums';
import {
	applicantRoleToApplicantPrimary,
	applicantRoleToApplicantType,
	calculateMonthly
} from '@app/modules/shared/helper/util';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { formlyDeleteFromArray } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	CurrentAddressDTO,
	DependantDTO,
	Household,
	LendingGuaranteeDTO,
	PersonApplicantDTO,
	PersonInsuranceDTO,
	PostSettlementAddressDTO
} from '@app/modules/typings/api';
import { cloneDeep, get } from 'lodash-es';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { ApplicantForeignEmploymentModel } from '@app/modules/financial-position/model/applicant-foreign-employment.model';
import { ApplicantSelfEmploymentModel } from '@app/modules/financial-position/model/applicant-self-employment.model';
import { NotEmployedModel } from '@app/modules/financial-position/model/notemployed-income.model';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { EmploymentService } from '@app/modules/shared/service/employment.service';
import { ForeignTaxAssociationService } from '@app/modules/shared/service/foreign-tax-association.service';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FrequencyShort } from '@simpology/client-components/utils';
import { CONSTANTS } from '../../shared/constants/constants';
import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { AddressDetailsTransformer } from '../models/address-details.model';
import { PersonApplicantTransformer } from '../models/applicant.model';
import { DependantDetailsModel, DependantTransformer } from '../models/dependants.model';
import { EmploymentTransformer } from '../models/employment.model';
import { HouseholdAndFamilyModel, HouseholdAndFamilyTransformer } from '../models/household-and-family.model';
import { PersonInsurancesTransformer } from '../models/insurances-model';
import { LendingGuaranteeModel, LendingGuaranteeTransformer } from '../models/lending-guarantee.model';
import { RelatedPersonModel } from '../models/related-person.model';
import {
	InformedConsentDetails,
	PersonApplicant,
	PersonApplicantAPIResponse,
	RelatedPartyDTO,
	RelatedPersonAndContacts
} from '../typings/applicants';
import { PersonOtherDetailsModel, PersonOtherDetailsTransformer } from './../models/other-details-model';
import { RelatedPartyModel, RelatedPartyModelTransformer } from './../models/related-party.model';
import { ApplicantAddressService } from './applicant-address.service';
import { BankingService } from './banking.service';
import { HouseholdService } from './household.service';
import { IdVerificationService } from './id-verification.service';
import { InformedConsentService } from './informed-consent.service';
import { ProofOfIdentityService } from './proof-of-identity.service';
import { SourceOfWealthService } from './source-of-wealth.service';

@Injectable({ providedIn: 'root' })
export class ApplicantsService extends BaseJourneyService<any> {
	applicantsLoaded$: Observable<boolean>;
	private applicantsLoadedValue = false;
	private applicantsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.applicantsLoadedValue);
	// cached for schema to transform from, can't keep it in store
	private cachedPersonApplicantDetails?: PersonApplicant[];

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private employmentService: EmploymentService,
		private householdService: HouseholdService,
		private formEnumsQuery: FormEnumsQuery,
		private applicantAddressService: ApplicantAddressService,
		private bankingService: BankingService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private informedConsentService: InformedConsentService,
		private idVerificationService: IdVerificationService,
		private formDataService: FormDataService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private applicationDataService: ApplicationDataService,
		private proofOfIDentityService: ProofOfIdentityService,
		private aggregateFormatterService: AggregateFormatterService,
		private channelSettingQuery: ChannelSettingQuery,
		private foreignTaxAssociationService: ForeignTaxAssociationService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository,
		private sourceOfWealthService: SourceOfWealthService
	) {
		super();
		this.setJourneyLadmRoute();
		this.applicantsLoaded$ = this.applicantsLoaded.asObservable();
	}

	fetchHouseHoldAndRelationship() {
		const fetchPersonApplicants$ = this.fetchPersonApplicants().pipe(
			switchMap((applicants) => {
				return this.fetchRelatedPersonMobileNumber(applicants);
			})
		);
		const fetchHousehold$ = this.getHouseholds().pipe(
			switchMap((houses) => {
				return this.fetchDependantInfoAndCombineWithHouseholds(houses);
			})
		);

		return forkJoin([fetchPersonApplicants$, fetchHousehold$]).pipe(
			map(([applicants, households]) => this.transformHousehold(applicants.applicants, households))
		);
	}

	setupState$(applicantsSchema?: FormlyApiProperty) {
		return forkJoin([
			this.personsCompaniesEnumService.fetchPersons(),
			this.personsCompaniesEnumService.fetchCompanies(),
			this.upsertApplicants(applicantsSchema),
			this.fetchHouseHoldAndRelationship()
		]).pipe(
			map(([persons, companies, applicants, transformedHousehold]) => {
				this.simpFormlyHandlerService.upsertToFullPathWithData(`applicants-household`, transformedHousehold.household);
				this.simpFormlyHandlerService.upsertToFullPathWithData(
					`applicants-relationship`,
					transformedHousehold.relationship
				);
				this.cachedPersonApplicantDetails = applicants;
				return applicants;
			})
		);
	}

	getCachedPersonApplicantDetails() {
		return this.cachedPersonApplicantDetails;
	}

	refreshHouseholds(): Observable<HouseholdAndFamilyModel> {
		return this.fetchHouseHoldAndRelationship().pipe(
			tap((transformedHousehold) => {
				this.simpFormlyHandlerService.upsertToFullPathWithData(`applicants-household`, transformedHousehold.household);
			})
		);
	}

	refreshRelationships(): Observable<HouseholdAndFamilyModel> {
		return this.fetchHouseHoldAndRelationship().pipe(
			tap((transformedHousehold) => {
				this.simpFormlyHandlerService.upsertToFullPathWithData(
					`applicants-relationship`,
					transformedHousehold.relationship
				);
			})
		);
	}

	upsertApplicants(applicantsSchema?: FormlyApiProperty): Observable<PersonApplicant[]> {
		const fetchPersonApplicants$ = this.fetchPersonApplicants().pipe(
			switchMap((applicants) => {
				return this.fetchRelatedPersonMobileNumber(applicants);
			})
		);
		return forkJoin([fetchPersonApplicants$, this.informedConsentService.getApplicantConsentState()]).pipe(
			map(
				([personApplicantsAndRelatedPersons, informedConsentDetails]: [
					RelatedPersonAndContacts,
					InformedConsentDetails
				]) =>
					personApplicantsAndRelatedPersons.applicants.map((applicant) =>
						this.transformPersonApplicant(
							applicant,
							personApplicantsAndRelatedPersons,
							informedConsentDetails?.applicantsConsent.some(
								(applicantConsentDetails) => applicantConsentDetails.applicantId === applicant.id
							) || false
						)
					)
			),
			switchMap((personApplicants) =>
				forkJoin(
					personApplicants.map((personApplicant) =>
						this.getRemainingApplicantDetails(personApplicant, applicantsSchema)
					)
				)
			),
			map((applicants) => {
				applicants.forEach((personApplicant: PersonApplicant, index: number) => {
					if (personApplicant) {
						this.simpFormlyHandlerService.upsertToFullPathWithData(`applicants-type-${index}`, personApplicant.type);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-personalDetails-${index}`,
							personApplicant.personalDetails
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-contactDetails-${index}`,
							personApplicant.contactDetails
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-nextOfKin-${index}`,
							personApplicant.nextOfKin
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-addresses-${index}`,
							personApplicant.addresses
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-banking-${index}`,
							personApplicant.banking
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-residency-${index}`,
							personApplicant.residency
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-employments-${index}`,
							personApplicant.employments
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-insurances-${index}`,
							personApplicant.insurances
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-otherDetails-${index}`,
							personApplicant.otherDetails
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-relatedParties-${index}`,
							personApplicant.relatedParties
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-lendingGuarantee-${index}`,
							personApplicant.lendingGuarantee
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-proofOfIdentity-${index}`,
							personApplicant.proofOfIdentity
						);
						this.simpFormlyHandlerService.upsertToFullPathWithData(
							`applicants-sourceOfWealth-${index}`,
							personApplicant.sourceOfWealth
						);
					}
				});
				return cloneDeep(applicants);
			})
		);
	}

	getSummaryData() {
		return this.fetchPersonApplicants().pipe(
			switchMap((personApplicants) =>
				this.getHouseholds().pipe(map((households) => ({ personApplicants, households })))
			),
			switchMap((details) => {
				const transformedHousehold = this.transformHousehold(details.personApplicants, details.households);
				const response = {
					household: [
						{
							household: transformedHousehold.household,
							relationship: transformedHousehold.relationship
						}
					]
				};
				return forkJoin(
					details.personApplicants.map((personApplicant) =>
						this.getRemainingApplicantDetails(this.transformPersonApplicant(personApplicant))
					)
				).pipe(
					map((personApplicants) => {
						personApplicants.forEach((personApplicant, index) => {
							Object.assign(response, {
								[`personApplicant${index}`]: [
									{
										type: personApplicant.type,
										lendingGuarantee: personApplicant.lendingGuarantee![0],
										lendingGuaranteeCustomHideFlag: personApplicant.applicantRole !== ApplicantRole.Guarantor,
										proofOfIdentity: personApplicant.proofOfIdentity![0],
										personalDetails: personApplicant.personalDetails![0],
										contactDetails: personApplicant.contactDetails![0],
										addresses: personApplicant.addresses,
										banking: personApplicant.banking,
										residency: personApplicant.residency,
										employments: this.transformEmploymentForSummary(personApplicant.employments),
										insurances: personApplicant?.insurances?.map((insurance: PersonInsurancesModel) => {
											return {
												insuranceType: insurance.insuranceType as InsuranceType,
												premiumAmount: calculateMonthly(
													insurance.premiumAmount!.amount,
													insurance.premiumAmount!.frequency
												)
											};
										}),
										otherDetails: personApplicant.otherDetails
									}
								]
							});
						});
						return response;
					})
				);
			})
		);
	}

	upsertHouseholds(households: HouseholdInfo[]): void {
		this.simpFormlyHandlerService.upsertToStateWithData(`household`, households);
	}

	updateApplicantMainInfo(applicant: SetUpApplicant): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		const applicantDTO = {
			id: applicant.id,
			applicationId: applicationId,
			firstName: applicant.firstName?.trim(),
			lastName: applicant.lastName?.trim(),
			primaryApplicant: applicantRoleToApplicantPrimary(applicant.applicantRole || ApplicantRole.PrimaryBorrower),
			receivesAllNotification: applicant.receivesAllNotification,
			applicantType: applicantRoleToApplicantType(applicant.applicantRole || ApplicantRole.PrimaryBorrower)
		} as PersonApplicantDTO;
		return <Observable<void>>this.patch(`PersonApplicant/UpdateApplicantMainInfo`, applicantDTO);
	}

	updateApplicantOtherDetails(personOtherDetails: PersonOtherDetailsModel): Observable<void> {
		const applicationId = this.applicationDataQuery.applicationId();
		const applicantDTO = {
			applicationId: applicationId,
			...(personOtherDetails ? PersonOtherDetailsTransformer.toPayload(personOtherDetails) : {})
		} as PersonApplicantDTO;
		return <Observable<void>>this.patch(`PersonApplicant/UpdateApplicantOtherDetails`, applicantDTO);
	}

	saveLendingGuarantee(lendingGuaranteeDetails: LendingGuaranteeModel, applicantId: number): Observable<void> {
		const lendingGuaranteeDTO = lendingGuaranteeDetails
			? LendingGuaranteeTransformer.toPayload(lendingGuaranteeDetails)
			: {};
		return this.patch(`PersonApplicant/UpdateLendingGuarantee/${applicantId}`, lendingGuaranteeDTO).pipe(
			tap(() => this.applicationDataService.syncSideNav())
		);
	}

	savePersonApplicant(applicant: PersonApplicant): Observable<PersonApplicantAPIResponse> {
		const applicationId = this.applicationDataQuery.applicationId();
		const model = PersonApplicantTransformer.toPayload(applicant, applicationId);
		model.firstName = model.firstName?.trim();
		model.lastName = model.lastName?.trim();
		if (model.householdId) {
			return this.postCustom(`PersonApplicant`, model).pipe(
				tap((personApplicantAPIResponse) => {
					this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForSecurePerson(
						personApplicantAPIResponse.applicantId,
						personApplicantAPIResponse.securePersonId
					);
				})
			);
		} else {
			return this.getHouseholdForApplicant(model).pipe(
				switchMap((householdId: number | undefined) => {
					if (householdId) {
						model.householdId = householdId;
					}
					return this.postCustom(`PersonApplicant`, model).pipe(
						tap((personApplicantAPIResponse: PersonApplicantAPIResponse) => {
							this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForSecurePerson(
								personApplicantAPIResponse.applicantId,
								personApplicantAPIResponse.securePersonId
							);
						})
					);
				})
			);
		}
	}
	resetDigitalWidgetForPerson(applicantId: number) {
		this.fetchPersonApplicants().subscribe((applicants) => {
			const personApplicant = applicants.find((applicant) => applicant.id === applicantId);
			if (personApplicant) {
				this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForSecurePerson(
					personApplicant.id,
					personApplicant.securePersonId
				);
			}
		});
	}

	fetchPersonApplicants(): Observable<PersonApplicantDTO[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return of();
		}
		return this.get(`PersonApplicant/${applicationId}`).pipe(
			switchMap((applicants: PersonApplicantDTO[]) => {
				return applicants.length
					? forkJoin(
							applicants
								.sort((a, b) => {
									if (a.primaryApplicant && !b.primaryApplicant) {
										return -1;
									} else if (!a.primaryApplicant && b.primaryApplicant) {
										return 1;
									} else {
										return 0;
									}
								})
								.map((applicant) =>
									this.getLendingGuaranteeDetailsById(applicant.id).pipe(
										map((lendingGuaranteeType: LendingGuaranteeDTO) => ({ ...applicant, lendingGuaranteeType }))
									)
								)
					  ).pipe(
							switchMap((applicantsWithLendingGuarantee) =>
								forkJoin(
									applicantsWithLendingGuarantee.map((applicant) => {
										if (!applicant.foreignTaxAssociationId) {
											return of(applicant);
										}
										return this.foreignTaxAssociationService
											.getForeignTaxAssociations(applicant.foreignTaxAssociationId)
											.pipe(
												map((foreignTaxAssociation) => ({
													...applicant,
													foreignTaxAssociation: foreignTaxAssociation
												}))
											);
									})
								)
							)
					  )
					: of(applicants);
			})
		);
	}

	getHouseholds(): Observable<Household[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		if (applicationId === CONSTANTS.NEW_ID) {
			return of();
		}
		return this.householdService.get(`${applicationId}`) as Observable<Household[]>;
	}

	getInsuranceDetailsById(applicantId: number): Observable<PersonInsuranceDTO[]> {
		return this.getCustom(`PersonApplicantInsurance/${applicantId}`).pipe(
			map((insurances: PersonInsuranceDTO[]) => insurances)
		);
	}

	getLendingGuaranteeDetailsById(applicantId: number): Observable<LendingGuaranteeDTO> {
		return <Observable<LendingGuaranteeDTO>>this.get(`PersonApplicant/GetLendingGuarantee/${applicantId}`);
	}

	fetchDependantDetailsByHouseholdId(householdId: number): Observable<DependantDTO[]> {
		return this.getCustom(`Dependant/${this.applicationDataQuery.applicationId()}/${householdId}`).pipe(
			map((dependants: DependantDTO[]) => dependants)
		);
	}

	fetchDependantInfoAndCombineWithHouseholds(householdData: Household[]): Observable<Household[]> {
		return forkJoin(
			householdData.map((house) => (house?.id ? this.fetchDependantDetailsByHouseholdId(house.id) : of([])))
		).pipe(
			map((dependantData: DependantDTO[][]) => {
				const households: Household[] = householdData ? cloneDeep(householdData) : [];
				if (households?.length > 0) {
					households.forEach((household) => {
						if (dependantData?.length > 0) {
							household.dependantInfo =
								dependantData.find(
									(dependantInfo) => dependantInfo?.length > 0 && household.id === dependantInfo[0].householdId
								) ?? [];
							// already saved applications with noOfDependant will not have dependant dob and age. So add empty rows
						} else if (household?.dependantInfo === undefined && household.numberOfDependants! > 0) {
							household.dependantInfo = [];
							for (let i = 0; i < household.numberOfDependants!; i++) {
								household.dependantInfo.push({
									id: -1,
									householdId: household.id
								});
							}
						}
					});
				}
				return households;
			})
		);
	}

	deletePersonApplicant(applicant: SetUpApplicant): Observable<boolean> {
		if (applicant.id) {
			return this.delete(`PersonApplicant/${applicant.id}`).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	transformPersonApplicant(
		applicant: PersonApplicantDTO,
		relatedPersonsAndContacts: RelatedPersonAndContacts = { applicants: [], relatedPersons: [] },
		consentInitiated?: boolean
	): PersonApplicant {
		const titleLabel = this.formEnumsQuery.getOptionLabel('NameTitle', applicant.title);
		return PersonApplicantTransformer.fromPayload(
			applicant,
			titleLabel,
			consentInitiated ?? this.formDataService.hasApplicantConsentInitiated(applicant.id),
			this.formEnumsQuery,
			relatedPersonsAndContacts
		);
	}

	transformEmploymentForSummary(employments: ApplicantEmploymentModel[] | undefined) {
		return employments?.map((employment) => {
			if (employment.employmentDetails.typeOfIncome.type === IncomeType.selfEmployed) {
				const model = cloneDeep(employment.employmentDetails.selfEmployment!);

				return {
					...employment,
					employmentDetails: {
						...employment.employmentDetails,
						selfEmployment: {
							...employment.employmentDetails.selfEmployment,
							typeOfIncome: {
								type: this.getIncomeTypeWithPastOrCurrent(employment, model),
								currentEmployment: model.typeOfIncome?.currentEmployment
							},
							grossSalary:
								model.grossSalary?.amount && model.grossSalary?.frequency
									? calculateMonthly(model.grossSalary.amount, model.grossSalary.frequency)
									: // Other place to get gross salary if not the first location
									model.applicantIncome?.grossSalary && model.grossSalary?.frequency
									? calculateMonthly(model.applicantIncome?.grossSalary, model.grossSalary.frequency)
									: undefined,
							businessName: model.businessDetailsSelect?.companyName,
							netProfitBeforeTax:
								model.latestIncome?.amount && model.latestIncome?.amount !== '0'
									? `$${this.transformToCommaSeparatedNumber(model.latestIncome?.amount)} - ${
											model.latestIncome?.frequency?.label
									  }`
									: undefined,
							netProfitAfterTax:
								model.firstNetProfitAfterTax?.amount && model.firstNetProfitAfterTax?.amount !== '0'
									? `$${this.transformToCommaSeparatedNumber(model.firstNetProfitAfterTax.amount)} - ${
											model.firstNetProfitAfterTax?.frequency?.label
									  }`
									: undefined,
							atoGrossIncome:
								model.firstAtoGrossIncome?.amount && model.firstAtoGrossIncome?.frequency
									? `$${this.transformToCommaSeparatedNumber(model.firstAtoGrossIncome?.amount)} - ${
											model.firstAtoGrossIncome?.frequency?.label
									  }`
									: undefined,
							declaredNetIncome:
								model.declaredNetIncome && model.grossSalary?.frequency
									? calculateMonthly(model.declaredNetIncome, model.grossSalary.frequency)
									: undefined
						}
					}
				};
			} else if (employment.employmentDetails.typeOfIncome.type === IncomeType.payg) {
				const incomeModel = cloneDeep(employment.employmentDetails.payg?.income);
				const model = cloneDeep(employment.employmentDetails.payg!);
				const summaryIncomes = this.getIncomeDetailsForSummary(incomeModel);

				return {
					...employment,
					employmentDetails: {
						...employment.employmentDetails,
						payg: {
							...employment.employmentDetails.payg,
							typeOfIncome: {
								type: this.getIncomeTypeWithPastOrCurrent(employment, model),
								currentEmployment: model.typeOfIncome?.currentEmployment
							},
							grossTotal: `${employment.employmentDetails.payg?.grossTotal} - Monthly`,
							netTotal: `${employment.employmentDetails.payg?.netTotal} - Monthly`,
							...summaryIncomes
						}
					}
				};
			} else if (employment.employmentDetails.typeOfIncome.type === IncomeType.NotEmployed) {
				const model = cloneDeep(employment.employmentDetails.notEmployed!);

				return {
					...employment,
					employmentDetails: {
						...employment.employmentDetails,
						notEmployed: {
							...employment.employmentDetails.notEmployed,
							typeOfIncome: {
								type: this.getIncomeTypeWithPastOrCurrent(employment, model),
								currentEmployment: model.typeOfIncome?.currentEmployment
							}
						}
					}
				};
			} else if (employment.employmentDetails.typeOfIncome.type === IncomeType.ForeignEmployed) {
				const model = cloneDeep(employment.employmentDetails.foreignEmployment!);

				return {
					...employment,
					employmentDetails: {
						...employment.employmentDetails,
						foreignEmployment: {
							...employment.employmentDetails.foreignEmployment,
							typeOfIncome: {
								type: this.getIncomeTypeWithPastOrCurrent(employment, model),
								currentEmployment: model.typeOfIncome?.currentEmployment
							}
						}
					}
				};
			} else {
				return employment;
			}
		});
	}

	getIncomeDetailsForSummary(incomeModel: IncomeModel[] | undefined) {
		let incomes = {};

		incomeModel?.forEach((income: IncomeModel) => {
			const grossAmount = this.calculateMonthlyAmount(income?.grossAmount, income?.frequency);
			const netAmount = this.calculateMonthlyAmount(income?.netAmount, income?.frequency);

			const incomeType = income.type;

			switch (incomeType) {
				case 1000: {
					incomes = {
						...incomes,
						grossSalary: grossAmount,
						netSalary: netAmount
					};
					break;
				}
				case 1001: {
					incomes = {
						...incomes,
						grossRegularOvertime: grossAmount,
						netRegularOvertime: netAmount
					};
					break;
				}
				case 1002: {
					incomes = {
						...incomes,
						grossBonus: grossAmount,
						netBonus: netAmount
					};
					break;
				}
				case 1003: {
					incomes = {
						...incomes,
						grossCommission: grossAmount,
						netCommission: netAmount
					};
					break;
				}
				case 1004: {
					incomes = {
						...incomes,
						grossCarAllowance: grossAmount,
						netCarAllowance: netAmount
					};
					break;
				}
				case 1005: {
					incomes = {
						...incomes,
						grossWorkAllowance: grossAmount,
						netWorkAllowance: netAmount
					};
					break;
				}
				case 1006: {
					incomes = {
						...incomes,
						grossWorkersCompensation: grossAmount,
						netWorkersCompensation: netAmount
					};
					break;
				}
				case 1007: {
					incomes = {
						...incomes,
						grossOther: grossAmount,
						netOther: netAmount
					};
					break;
				}
			}
		});
		return incomes;
	}

	calculateMonthlyAmount(amount: string | undefined, freq: FrequencyShort | undefined) {
		if (!Number(amount) && !Number(freq)) {
			return undefined;
		}
		return this.aggregateFormatterService.formatAmount(calculateMonthly(Number(amount), Number(freq))) + ' - Monthly';
	}

	transformToCommaSeparatedNumber(amount: string): string {
		return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	}

	transformHousehold(applicants: PersonApplicantDTO[], households: Household[]): HouseholdAndFamilyModel {
		const arrConsentInitiated: boolean[] = [];

		applicants.forEach((applicant) => {
			arrConsentInitiated.push(this.formDataService.hasApplicantConsentInitiated(applicant.id));
		});
		return HouseholdAndFamilyTransformer.fromPayload(applicants, households, this.formEnumsQuery, arrConsentInitiated);
	}

	saveApplicantHousehold(personApplicantId: number, householdId: number): Observable<any> {
		return this.patch(`PersonApplicant/Household/${personApplicantId}/${householdId}`, null);
	}

	saveApplicantsInHousehold(personApplicantIds: number[], householdId: number): Observable<any> {
		return forkJoin(personApplicantIds.map((applicantID) => this.saveApplicantHousehold(applicantID, householdId)));
	}

	resetApplicantConsent(applicantId: number, index: number): Observable<unknown> {
		return this.informedConsentService.resetApplicantConsent(applicantId).pipe(
			tap(() => {
				this.formDataService.updateConsentInitiationForApplicant(index, false);
				this.informedConsentService.getApplicantConsentState().subscribe();
				//TODO don't trigger for new config widget
				this.idVerificationService.getApplicantIdVerificationState('idVerification').subscribe();
			})
		);
	}

	saveDependantInfo(dependant: DependantDTO[], householdId: number): Observable<any> {
		return this.postList(`Dependant/${this.applicationDataQuery.applicationId()}/${householdId}`, dependant);
	}

	addDependantInfo(dependants: DependantDetailsModel[], householdId: number): Observable<any> {
		return this.saveDependantInfo(DependantTransformer.toPayload(dependants, householdId), householdId);
	}

	savePersonInsurance(insurance: PersonInsurancesModel): Observable<number> {
		return this.postCustom(`PersonApplicantInsurance`, PersonInsurancesTransformer.toPayload(insurance)).pipe(
			map((response: number) => response)
		);
	}

	deleteInsuranceDetails(insuranceId: number, applicantId: number): Observable<boolean> {
		return this.delete(`PersonApplicantInsurance/${applicantId}/${insuranceId}`).pipe(map(() => true));
	}

	fetchRelatedPersonMobileNumber(applicantsData: PersonApplicantDTO[]): Observable<RelatedPersonAndContacts> {
		const applicants: PersonApplicantDTO[] = applicantsData ? cloneDeep(applicantsData) : [];

		return forkJoin([
			...applicantsData.map((applicant) =>
				applicant?.spousePersonId && applicant?.spousePersonTypeId === 2
					? this.personsCompaniesEnumService.getRelatedPerson(applicant.spousePersonId)
					: of({} as RelatedPersonModel)
			),
			...applicantsData.map((applicant) =>
				applicant?.nextOfKinPersonId && applicant?.nextOfKinPersonTypeId === 2
					? this.personsCompaniesEnumService.getRelatedPerson(applicant.nextOfKinPersonId)
					: of({} as RelatedPersonModel)
			)
		]).pipe(
			map((relatedPersons: RelatedPersonModel[]) => {
				return { applicants, relatedPersons };
			})
		);
	}

	saveRelatedParties(relatedParty: RelatedPartyModel): Observable<RelatedPartyModel> {
		const applicationId = this.applicationDataQuery.applicationId();
		const relatedPartyDto = RelatedPartyModelTransformer.toPayload(relatedParty, applicationId);
		return this.postCustom('RelatedParty', relatedPartyDto).pipe(
			switchMap((savedRelatedParty: RelatedPartyDTO) =>
				combineLatest([
					this.personsCompaniesEnumService.fetchCompanies(),
					this.personsCompaniesEnumService.fetchPersons()
				]).pipe(
					switchMap(() => {
						return of(savedRelatedParty);
					})
				)
			),
			map((savedRelatedParty: RelatedPartyDTO) =>
				RelatedPartyModelTransformer.fromPayload(savedRelatedParty, this.formEnumsQuery)
			)
		);
	}

	fetchRelatedPartiesOfApplicant(applicantId: number): Observable<RelatedPartyModel[]> {
		return this.getCustom(`RelatedParty/${applicantId}`).pipe(
			map((relatedParties: RelatedPartyDTO[]) =>
				relatedParties.map((relatedParty) =>
					RelatedPartyModelTransformer.fromPayload(relatedParty, this.formEnumsQuery)
				)
			)
		);
	}

	deleteRelatedPartyOfApplicant(
		formField: FormlyFieldConfig,
		applicantId: number,
		stateKey: string
	): Observable<boolean> {
		const index = Number(formField.parent?.key);
		const type = get(formField, 'model.type') as number;
		return this.delete(`RelatedParty/${applicantId}/${type}`).pipe(
			tap(() => {
				formlyDeleteFromArray(formField);
				this.simpFormlyHandlerService.deleteFromState(stateKey, index);
			}),
			map(() => true)
		);
	}

	private getRemainingApplicantDetails(
		personApplicant: PersonApplicant,
		applicantsSchema?: FormlyApiProperty
	): Observable<PersonApplicant> {
		const applicantId = personApplicant.id;
		return forkJoin({
			currentAddress: this.applicantAddressService
				.getCurrentAddress(applicantId)
				.pipe(catchError(() => of({} as CurrentAddressDTO))),
			previousAddresses: this.applicantAddressService.getPreviousAddresses(applicantId),
			postSettlementAddress: this.applicantAddressService
				.getPostSettlementAddress(applicantId)
				.pipe(catchError(() => of({} as PostSettlementAddressDTO))),
			employments: this.employmentService.fetchEmploymentsForApplicant(applicantId, applicantsSchema),
			employers: this.employmentService.fetchEmployer(),
			banking: this.bankingService.getBankDetails(applicantId),
			insurances: this.getInsuranceDetailsById(applicantId),
			relatedParties: this.fetchRelatedPartiesOfApplicant(applicantId),
			LendingGuarantee: this.getLendingGuaranteeDetailsById(applicantId).pipe(
				catchError(() => of({} as LendingGuaranteeDTO))
			),
			proofOfIdentity: this.proofOfIDentityService.fetchPOIDocumentsForApplicant(personApplicant),
			sourceOfWealth: this.sourceOfWealthService.fetchSourceOfWealth(applicantId)
		}).pipe(
			map((response) => {
				if (personApplicant.addresses?.length) {
					personApplicant.addresses[0] = AddressDetailsTransformer.fromPayload(
						response.currentAddress,
						response.previousAddresses,
						response.postSettlementAddress
					);
				}
				personApplicant.employments = EmploymentTransformer.fromPayload(
					this.formEnumsQuery,
					response.employments,
					response.employers
				);

				personApplicant.insurances = PersonInsurancesTransformer.fromPayload(response.insurances);

				personApplicant.banking = response.banking;
				personApplicant.relatedParties = response.relatedParties;

				personApplicant.lendingGuarantee = [LendingGuaranteeTransformer.fromPayload(response.LendingGuarantee)];
				personApplicant.proofOfIdentity = response.proofOfIdentity;
				personApplicant.sourceOfWealth = response.sourceOfWealth;
				return personApplicant;
			})
		);
	}

	private getHouseholdForApplicant(applicantModel: Partial<PersonApplicantDTO>): Observable<number | undefined> {
		const households = this.formEnumsQuery.getHouseHolds();

		if (households.length) {
			return of(households[0].id);
		} else {
			const fullName = `${applicantModel.firstName || ''} ${applicantModel.lastName || ''}`.trim();
			const householdDto: Household = {
				id: CONSTANTS.NEW_ID,
				applicationId: this.applicationDataQuery.applicationId(),
				name: this.channelSettingQuery.getUseRelationshipForSOP() ? `SOP ${fullName}` : `Household 1`
			};
			return this.householdService.saveHousehold(householdDto).pipe(map((household: Household) => household.id));
		}
	}

	private getIncomeTypeWithPastOrCurrent(
		employment: ApplicantEmploymentModel,
		model: ApplicantPaygModel | NotEmployedModel | ApplicantSelfEmploymentModel | ApplicantForeignEmploymentModel
	): string {
		const employmentType = this.formEnumsQuery.getOptionLabel(
			'IncomeType',
			employment.employmentDetails.typeOfIncome.type
		);

		return `${employmentType} (${model.typeOfIncome?.currentEmployment ? 'Current' : 'Past'})`;
	}

	updateIgnoreStatusForApplicant(personApplicantId: number) {
		return this.patch(`PersonApplicant/IgnoreCustomerSearchFailure/${personApplicantId}`, null);
	}
}
