import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { CommentLinkCommentType } from '@app/modules/shared/enums/app.enums';
import { savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { DetailedComment, DetailedCommentTransformer } from '@app/modules/summary-lodgement/models/comment.model';
import { DetailedCommentDTO } from '@app/modules/summary-lodgement/typings/summary-lodgement';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentsService extends BaseJourneyService<DetailedCommentDTO> {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.DETAILED_COMMENTS);
	}

	fetchDetailedComment(commentType: CommentLinkCommentType): Observable<DetailedCommentDTO> {
		const applicationId = this.applicationDataQuery.applicationId();
		const contextId = this.applicationDataQuery.applicationId();

		return this.get(`${applicationId}/${commentType}/${contextId}`);
	}

	saveDetailedComment(comment: DetailedComment): Observable<DetailedComment> {
		const payload = DetailedCommentTransformer.toPayload(comment);
		return this.postCustom('', payload).pipe(
			map((id: number) => {
				payload.id = id;
				const commentModel = DetailedCommentTransformer.fromPayload(payload);
				this.toastr.success(savedSuccessfullyMessage('Comment'));
				return commentModel;
			})
		);
	}
}
