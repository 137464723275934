<div class="form-group formly-datepicker">
	<div *ngIf="!to.hideLabel" class="formly-datepicker__label">
		<label class="simp-label" [for]="uniqueId">{{ to.label }} </label>
		<div class="formly-datepicker__label-icons">
			<i
				class="fas fa-pencil formly-datepicker__icon"
				[class.simp-margin-right-base]="to.tooltip"
				(click)="handleEditClick()"
				*ngIf="to.editable && formControl.disabled"
			></i>
			<simp-info-icon
				*ngIf="to.tooltip"
				[tooltipHtmlContent]="to.tooltip"
				class="formly-datepicker__icon simp-margin-left-base"
			></simp-info-icon>
			<simp-info-icon *ngIf="to.tooltipAsModal" [asModal]="true" (modalClick)="tooltipClick(field)"></simp-info-icon>
		</div>
	</div>

	<div class="input-group">
		<input
			type="text"
			class="form-control simp-form-control"
			[id]="uniqueId"
			[formControl]="$any(formControl)"
			[readonly]="!!to.readonly"
			[class.is-invalid]="formControl.invalid && (formControl.dirty || formControl.touched)"
			[placeholder]="to.placeholder || 'DD/MM/YYYY'"
			[minDate]="to.minDate || defaultMinDate"
			[maxDate]="to.maxDate || defaultMaxDate"
			ngbDatepicker
			#datePicker="ngbDatepicker"
			[attr.aria-describedby]="uniqueId + '-error'"
			metadataKey
			[formlyField]="field"
			[attr.aria-label]="to.ariaLabel"
		/>
		<div class="input-group-text">
			<button
				[disabled]="formControl.disabled || to.readonly"
				class="btn"
				(click)="datePicker.toggle()"
				type="button"
				[attr.aria-description]="formControl.value !== undefined ? 'selected ' + $any(formControl)?.value : ''"
			>
				<span class="visually-hidden">Date picker</span>
				<i class="fal fa-calendar-alt"></i>
			</button>
		</div>
	</div>

	<simp-field-error
		*ngIf="(formControl.dirty || formControl.touched) && formControl.errors"
		[errors]="formControl.errors"
		[validationErrors]="field.validation?.messages!"
		[fieldId]="uniqueId"
	>
	</simp-field-error>
</div>
