import {
	AccountHoldingEnum,
	ForeignTaxAssociationStatus,
	SourceOfFundsEnum,
	TaxResidencyOptions,
	TinNotProvidedReason,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNotNullBoolean, getNullableYesNo, isNullOrUndefined } from '@app/modules/shared/helper/util';
import {
	CreditCardApplicationDTO,
	CreditCardNonConflictDTO,
	FixedInterestRateLockDTO,
	ForeignTaxAssociationDetailsDTO,
	OffsetDTO,
	ProductFeatureDTO
} from '@app/modules/typings/api';

export class ProductFeatureTransformer {
	static fromPayload(feature: ProductFeatureDTO, loanId: number): ProductFeature {
		return {
			id: feature.featureEnum,
			featureName: feature.featureName,
			isFeatureSelected: feature.selected,
			loanId: loanId,
			infoText: feature.infoText,
			isProductSelected: true,
			featuresAvailable: true
		};
	}

	static fromOffsetPayload(offset: OffsetDTO): OffsetAccounts {
		const singleOffsetAccount = offset.offsetAccounts?.length === 1 ? offset.offsetAccounts[0] : undefined;
		return {
			offsetAccountIsExisting: getNullableYesNo(singleOffsetAccount?.isExistingAccount), // Deprecated: please use offsetAccounts array for all new apps
			existingFinancialAssetId: singleOffsetAccount?.existingAccountId,
			offsetAccountId: singleOffsetAccount?.offsetAccountId, // Deprecated: please use offsetAccounts array for all new apps
			offsetAccounts: offset.offsetAccounts?.length
				? (offset.offsetAccounts.map((account) => ({
						...account,
						isExistingAccount: getNullableYesNo(account.isExistingAccount)
				  })) as OffsetAccountDetails[])
				: []
		};
	}

	static fromCreditCardPayload(creditCardFeature: CreditCardApplicationDTO): CreditCardApplication {
		return {
			id: creditCardFeature.id,
			preferredCardConfirmed: creditCardFeature.preferredCardConfirmed,
			creditCardFeatureId: creditCardFeature.creditCardFeatureId,
			payClosingBalance: getNullableYesNo(creditCardFeature.payClosingBalance),
			creditCardProductId: creditCardFeature.creditCardProductId,
			requiredCardLimit: creditCardFeature.requiredCardLimit,
			primaryCardHolderId: creditCardFeature.primaryCardHolderId,
			primaryCardFlyerNumber: creditCardFeature.primaryFrequentFlyerNumber,
			additionalCardHolderId: creditCardFeature.additionalCardHolderId,
			additionalCardFlyerNumber: creditCardFeature.additionalFrequentFlyerNumber,
			conflictConfirmed: creditCardFeature.conflictConfirmed,
			taxResidencyOutsideAu: this.fromPayloadTaxResidencyOutsideAustralia(creditCardFeature.taxResidencyOutsideAu),
			foreignTaxAssociationDetails:
				creditCardFeature.foreignTaxAssociationDetails?.map((detail) =>
					this.fromForeignTaxAssociationDetailPayload(detail)
				) ?? [],
			foreignTaxAssociationId: creditCardFeature.foreignTaxAssociationId ?? 0
		};
	}

	static fromForeignTaxAssociationDetailPayload(
		foreignTaxAssociationDetailDto: ForeignTaxAssociationDetailsDTO
	): ForeignTaxAssociationDetail {
		return {
			id: foreignTaxAssociationDetailDto.id,
			countryOfForeignTaxResidency: foreignTaxAssociationDetailDto.countryCode,
			foreignTaxAssociationStatus: foreignTaxAssociationDetailDto.foreignTaxAssociationStatus,
			taxIdentificationNumber: foreignTaxAssociationDetailDto.taxIdentificationNumber,
			tinNotProvidedReason: foreignTaxAssociationDetailDto.tinNotProvidedReason,
			tinNotProvidedReasonDetail: foreignTaxAssociationDetailDto.tinNotProvidedReasonDetail
		};
	}

	static fromCreditCardNonConflictPayload(nonConflict: CreditCardNonConflictDTO): CreditCardNonConflict {
		return {
			creditCardProductId: nonConflict.creditCardProductId,
			creditCardFeatureId: nonConflict.creditCardFeatureTypeEnum,
			payClosingBalance: getNullableYesNo(nonConflict.likelyToPayClosingBalance)
		};
	}
	static fromLockedInterestPayload(lockedInterestRate: FixedInterestRateLockDTO): LockedInterestRate {
		return {
			id: lockedInterestRate.id,
			lockedInterestRate: lockedInterestRate.lockedInterestRate,
			lockedInterestRateDate: lockedInterestRate.lockedInterestRateDate
		};
	}

	static toPayload(feature: ProductFeature): ProductFeatureDTO {
		return {
			featureEnum: feature.id ?? 0,
			featureName: feature.featureName ?? '',
			selected: feature.isFeatureSelected,
			infoText: feature.infoText
		};
	}

	static toOffsetPayload(offset: OffsetAccounts, loanId: number): OffsetDTO {
		return {
			loanDetailsId: loanId,
			offsetAccounts: offset.offsetAccounts?.length
				? offset.offsetAccounts?.map((account) => ({
						...account,
						isExistingAccount: getNotNullBoolean(account.isExistingAccount)
				  }))
				: [
						{
							isExistingAccount: getNotNullBoolean(offset.offsetAccountIsExisting),
							existingAccountId: offset.existingFinancialAssetId
						}
				  ]
		};
	}

	static toCreditCardPayload(
		creditCardFeature: CreditCardApplication,
		loanId: number,
		applicationId: number
	): CreditCardApplicationDTO {
		return {
			id: creditCardFeature.id ?? 0,
			applicationId: applicationId,
			loanDetailsId: loanId,
			preferredCardConfirmed: creditCardFeature.preferredCardConfirmed ?? false,
			creditCardFeatureId: creditCardFeature.creditCardFeatureId,
			payClosingBalance: getNotNullBoolean(creditCardFeature.payClosingBalance),
			creditCardProductId: creditCardFeature.creditCardProductId,
			requiredCardLimit: creditCardFeature.requiredCardLimit,
			primaryCardHolderId: creditCardFeature.primaryCardHolderId,
			primaryFrequentFlyerNumber: creditCardFeature.primaryCardFlyerNumber?.length
				? creditCardFeature.primaryCardFlyerNumber
				: undefined,
			additionalCardHolderId: creditCardFeature.additionalCardHolderId,
			additionalFrequentFlyerNumber: creditCardFeature.additionalCardFlyerNumber?.length
				? creditCardFeature.additionalCardFlyerNumber
				: undefined,
			conflictConfirmed: creditCardFeature.conflictConfirmed ?? false,
			taxResidencyOutsideAu: this.toPayloadTaxResidencyOutsideAustralia(creditCardFeature.taxResidencyOutsideAu),
			foreignTaxAssociationId: creditCardFeature.foreignTaxAssociationId,
			foreignTaxAssociationDetails:
				creditCardFeature.foreignTaxAssociationDetails?.map((details) =>
					this.toForeignTaxAssociationDetailPayload(details)
				) ?? []
		};
	}
	static toLockedInterestPayload(
		lockedInterestRate: LockedInterestRate,
		loanId: number,
		applicationId: number
	): FixedInterestRateLockDTO {
		return {
			id: lockedInterestRate.id ?? 0,
			loanDetailsId: loanId,
			applicationId: applicationId,
			lockedInterestRate: Number(lockedInterestRate.lockedInterestRate),
			lockedInterestRateDate: lockedInterestRate.lockedInterestRateDate
		};
	}

	static toForeignTaxAssociationDetailPayload(
		foreignTaxAssociationDetail: ForeignTaxAssociationDetail
	): ForeignTaxAssociationDetailsDTO {
		return {
			id: foreignTaxAssociationDetail.id ?? 0,
			countryCode: foreignTaxAssociationDetail.countryOfForeignTaxResidency,
			foreignTaxAssociationStatus: foreignTaxAssociationDetail.foreignTaxAssociationStatus,
			taxIdentificationNumber: foreignTaxAssociationDetail.taxIdentificationNumber,
			tinNotProvidedReason: foreignTaxAssociationDetail.tinNotProvidedReason,
			tinNotProvidedReasonDetail: foreignTaxAssociationDetail.tinNotProvidedReasonDetail
		};
	}

	private static fromPayloadTaxResidencyOutsideAustralia(taxResidencyOutsideAu?: boolean): number | undefined {
		if (isNullOrUndefined(taxResidencyOutsideAu)) {
			return undefined;
		}
		return taxResidencyOutsideAu ? TaxResidencyOptions.Yes : TaxResidencyOptions.AustraliaOnly;
	}

	private static toPayloadTaxResidencyOutsideAustralia(taxResidencyOutsideAu?: number): boolean | undefined {
		if (isNullOrUndefined(taxResidencyOutsideAu)) {
			return undefined;
		}
		return taxResidencyOutsideAu === TaxResidencyOptions.Yes ? true : false;
	}
}

export interface ProductFeature {
	id?: number;
	featureName?: string;
	isFeatureSelected: boolean;
	offsetAccountDetails?: OffsetAccounts;
	creditCardApplication?: CreditCardApplication;
	fixedRateLock?: LockedInterestRate;
	loanId: number;
	infoText?: string;
	isProductSelected?: boolean; // UI Purpose only
	featuresAvailable?: boolean; // UI Purpose only
}

export interface OffsetAccounts {
	offsetAccountIsExisting?: YesNo; // Deprecated: please use offsetAccounts array for all new apps
	existingFinancialAssetId?: number; // Deprecated: please use offsetAccounts array for all new apps
	offsetAccountId?: number;
	offsetAccounts?: OffsetAccountDetails[];
}

export interface OffsetAccountDetails {
	offsetAccountId: number;
	isExistingAccount: YesNo;
	existingAccountId?: number;
	accountType?: string;
	sourceOfFunds?: SourceOfFundsEnum;
	accountHolding?: AccountHoldingEnum;
	hasDebitCard?: boolean;
}

export interface LockedInterestRate {
	id?: number;
	lockedInterestRate?: number;
	lockedInterestRateDate?: string;
}

export interface CreditCardApplication {
	id?: number;
	preferredCardConfirmed?: boolean;
	creditCardFeatureId?: number;
	payClosingBalance?: YesNo;
	creditCardProductId?: number;
	requiredCardLimit?: number;
	primaryCardHolderId?: number;
	taxResidencyOutsideAu?: number;
	foreignTaxAssociationDetails?: ForeignTaxAssociationDetail[];
	foreignTaxAssociationId?: number;
	primaryCardFlyerNumber?: string;
	additionalCardHolderId?: number;
	additionalCardFlyerNumber?: string;
	conflictConfirmed?: boolean;
	minCardLimit?: number; // For UI purpose only
	maxCardLimit?: number; // For UI purpose only
	isFrequentFlyer?: boolean; // For UI purpose only
}

export interface CreditCardNonConflict {
	creditCardProductId?: number;
	creditCardFeatureId?: number;
	payClosingBalance?: YesNo;
}

export interface ForeignTaxAssociationDetail {
	id?: number;
	countryOfForeignTaxResidency?: number;
	foreignTaxAssociationStatus?: ForeignTaxAssociationStatus;
	taxIdentificationNumber?: string;
	tinNotProvidedReason?: TinNotProvidedReason;
	tinNotProvidedReasonDetail?: string;
}
