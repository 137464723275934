import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorUnderstandingTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorUnderstandingModel {
		return {
			relationshipDescription: payload.guarantorUnderstandingRelationshipDescription,
			understandObligations: getNullableYesNo(payload.understandsGuarantorObligations),
			codeOfPractice: getNullableYesNo(payload.understandsGuaranteeSigningPeriod),
			adviseInformationUsed: payload.adviseInformationUsed,
			adviseReceiveDocumentsShortly: payload.adviseReceiveDocumentsShortly,
			adviseWithdraw: payload.adviseWithdraw,
			adviseKYC: payload.adviseKYC
		};
	}
}

export interface GuarantorUnderstandingModel {
	relationshipDescription?: string;
	understandObligations?: YesNo;
	codeOfPractice?: YesNo;
	adviseInformationUsed?: boolean;
	adviseReceiveDocumentsShortly?: boolean;
	adviseWithdraw?: boolean;
	adviseKYC?: boolean;
}
