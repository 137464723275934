import { Injectable } from '@angular/core';
import { CurrentUserQuery } from '@app/modules/shared/store/current-user/current-user.query';
import { formlyOnClick } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RefiJourneyService } from '../refi-journey.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyResultsTransformerService {
	constructor(private refiJourneyService: RefiJourneyService, private currentUserQuery: CurrentUserQuery) {}

	public resultsTransform(formFields: FormlyFieldConfig[]) {
		formlyOnClick(formFields, 'result.approval', (field, clickType: { type: string }) => {
			if (clickType.type === 'primary') {
				this.refiJourneyService.navigateToStep('summary/details');
			}
		});
		formlyOnClick(formFields, 'result.referral', (field, clickType: { type: string }) => {
			if (clickType.type === 'primary') {
				this.refiJourneyService.navigateToStep('summary/details');
			}
		});
	}
}
