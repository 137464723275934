import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CONSTANTS, DateFormatCodes } from '@app/modules/shared/constants/constants';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import {
	formlyExtendExpressionProperties,
	formlyGenerateLabels,
	formlyOnClick
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { filter, map } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiHomeLoanDetails, RefiHomeLoanModel } from '../../models/refi-home-loans.model';
import { RefiLiabilitiesModel } from '../../models/refi-liabilities.model';
import { RefiJourneyService } from '../refi-journey.service';

@Injectable({
	providedIn: 'root'
})
export class RefiChooseLoanTransformerService {
	constructor(
		private currencyPipe: CurrencyPipe,
		private datePipe: DatePipe,
		private refiJourneyService: RefiJourneyService,
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService
	) {}

	chooseLoanTransform(formFields: FormlyFieldConfig[]) {
		formlyOnClick(
			formFields,
			'chooseLoan.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				const loans = (
					this.simpFormlyHandlerService.getStateDataFullPath('refi-liabilities-details')?.[0] as RefiLiabilitiesModel
				)?.homeLoans;

				const selectedLoanDetails = loans.find(
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					(loan) => loan.id === (field.formControl?.value?.[0]?.loans as number)
				) as RefiHomeLoanModel;

				if (selectedLoanDetails) {
					this.simpFormlyHandlerService.upsertToFullPathWithData(`refi-loanDetails-details`, [
						{
							...selectedLoanDetails.homeLoansModal
						}
					]);
				}

				if (clickType.type === 'primary' && !clickType.invalid) {
					this.refiJourneyService.navigateToStep('loanDetails/details');
					return;
				} else if (clickType.type === 'secondary') {
					this.simpFormlyHandlerService.upsertToFullPathWithData(`refi-loanDetails-details`, [
						{
							id: CONSTANTS.NEW_ID
						}
					]);
					this.refiJourneyService.navigateToStep('loanDetails/details');

					return;
				}
			}
		);

		formlyExtendExpressionProperties(formFields, 'chooseLoan.details.loans', {
			'templateOptions.options': (model, formState, field) => {
				const institutions = this.formEnumsQuery.getOptions('FinancialInstitution');
				return this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.Liabilities)?.pipe(
					filter((liabilities) => liabilities.length > 0),
					map((liabilities) => {
						const loans = (liabilities as RefiLiabilitiesModel[])?.[0]?.homeLoans;
						const filteredLoans = loans.filter((loan) => !loan.homeLoansModal.readonly);
						return (
							filteredLoans?.map((loan) => {
								const formattedLoanLimit = this.currencyPipe.transform(
									loan.homeLoansModal.loanLimit,
									undefined,
									undefined,
									'1.0'
								);

								let info = formlyGenerateLabels(field.parent!, 'chooseLoanLabels')?.loanInfo;

								['accountNumber', 'loanEstablishedDate'].forEach((label) => {
									const titleCaseLabel = label.charAt(0).toUpperCase() + label.slice(1);
									const value = loan.homeLoansModal[label as keyof RefiHomeLoanDetails];
									if (value) {
										info = info.replace(`Hide${titleCaseLabel}`, '');
									} else {
										info = info.replace(`Hide${titleCaseLabel}`, 'hidden');
									}
									info = info.replace(
										new RegExp(titleCaseLabel, 'g'),
										label.includes('Date')
											? this.datePipe.transform(value as string, DateFormatCodes.shortDate) ?? ''
											: (value as string)
									);
								});

								return {
									id: loan.id,
									label: loan.homeLoansModal.currentLender
										? `${
												institutions.find((institution) => institution.id === loan.homeLoansModal.currentLender)?.label
										  } - ${formattedLoanLimit}`
										: formattedLoanLimit,
									info
								} as EnumObject;
							}) ?? []
						);
					})
				);
			}
		});
	}
}
