<ng-container *ngIf="eligibleScreen">
	<div class="simp-modal-header">
		<h4 class="simp-h4 simp-modal-header__title">{{ labels.precheckTitle }}</h4>
	</div>
	<div class="simp-modal-body">
		<h4 class="simp-h4 simp-text--grey110">{{ labels.precheckSubTitle }}</h4>
		<form [formGroup]="form" class="simp-margin-top-medium" *ngIf="formFields">
			<formly-form [form]="form" [model]="model" [fields]="formFields">
				<ng-template formlyTemplate="postCodeInfoButton" let-field>
					<button
						type="button"
						class="btn"
						tooltipClass="precheck-tooltip"
						container="body"
						(click)="openPrecheckModal()"
					>
						<i class="fa fa-info-circle light-gray ms-auto icon-secondary"></i>
					</button>
				</ng-template>

				<ng-template formlyTemplate="creditHistoryInfoButton" let-field>
					<button
						type="button"
						class="btn"
						tooltipClass="precheck-tooltip"
						[ngbTooltip]="precheckCreditHistoryToolTip"
						triggers="click"
						[autoClose]="'outside'"
						placement="auto"
						container="body"
					>
						<i class="fa fa-info-circle light-gray ms-auto icon-secondary"></i>
					</button>
				</ng-template>
			</formly-form>
		</form>
		<div class="alert-box alert-box--green" *ngIf="showSuccess">
			<h4 class="simp-h4 simp-text--green">{{ labels.successTitle }}</h4>
			<p class="simp-text-small">{{ labels.successLabel }} {{ lvr }}.</p>
		</div>

		<div class="alert-box alert-box--blue" *ngIf="showError">
			<h4 class="simp-h4 simp-text--blue" [innerHTML]="labels.errorTitle"></h4>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(1)">{{ labels.postCodeError }}</h6>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(2)">
				{{ labels.lvrErrorPortion1 }} {{ lvr }} {{ labels.lvrErrorPortion2 }}
			</h6>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(3)">{{ labels.borrowerError }}</h6>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(4)">{{ labels.creditHistoryError }}</h6>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(5)">{{ labels.employmentError }}</h6>
			<h6 class="simp-h6 simp-text--blue" *ngIf="errorMessage(6)">{{ labels.digitalExperienceError }}</h6>
			<div class="simp-margin-top-small">
				<h6 class="simp-h6 simp-text--grey110">{{ labels.optionsLabel }}</h6>
			</div>
			<div class="simp-row">
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					[ngbTooltip]="contactPhone"
					href="tel: {{ contactPhone }}"
				>
					Call us <i class="fas fa-mobile-alt simp-margin-left-base"></i
				></a>
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					href="mailto:{{ contactEmail }}"
					[ngbTooltip]="contactEmail"
				>
					Email us <i class="fas fa-envelope simp-margin-left-base"></i
				></a>
			</div>
		</div>
	</div>

	<div class="simp-modal-footer simp-row">
		<button type="button" class="btn btn-secondary simp-button col-2" (click)="eligibleScreen = false">Close</button>

		<button
			type="button"
			[disabled]="form.invalid || !showSuccess"
			class="btn btn-primary simp-button col-2 simp-margin-left-small"
			(click)="submit()"
		>
			Let's go
		</button>
	</div>
</ng-container>

<ng-container *ngIf="!eligibleScreen">
	<div class="simp-modal-header">
		<h4 class="simp-h4 simp-modal-header__title">{{ labels.notEligibleModalTitle }}</h4>
	</div>
	<div class="simp-modal-body">
		<h4 class="simp-h4">{{ labels.notEligibleBodyTitle }}</h4>
		<div class="alert-box alert-box--blue">
			<h4 class="simp-h4 simp-text--blue">{{ labels.notEligibleSectionTitle }}</h4>
			<h6 class="simp-h6 simp-text--blue">{{ labels.notEligibleLabel1 }}</h6>
			<p class="simp-text-small simp-margin-vertical-small">{{ labels.notEligibleLabel2 }}</p>
			<h6 class="simp-h6 simp-text--grey110">{{ labels.optionsLabel }}</h6>
			<div class="simp-row simp-margin-top-small">
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					[ngbTooltip]="contactPhone"
					href="tel: {{ contactPhone }}"
				>
					Call us <i class="fas fa-mobile-alt simp-margin-left-base"></i
				></a>
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					href="mailto:{{ contactEmail }}"
					[ngbTooltip]="contactEmail"
				>
					Email us <i class="fas fa-envelope simp-margin-left-base"></i
				></a>
			</div>
		</div>
	</div>

	<div class="simp-modal-footer simp-row">
		<button type="button" class="btn btn-secondary simp-button col-2" (click)="close()" data-testid="pre-check-close">
			Close
		</button>
		<button
			type="button"
			class="btn btn-primary simp-button col-2 simp-margin-left-small"
			(click)="eligibleScreen = true"
		>
			Go back
		</button>
	</div>
</ng-container>

<ng-template #precheckModal>
	<div class="d-flex flex-column precheck-tooltip">
		<div class="precheck-tooltip-close">
			<simp-icon name="fal fa-times fa-2x" (click)="closePrecheckModal()"></simp-icon>
		</div>
		<div class="tooltip-inner">
			<h5 class="simp-h5">{{ labels.applicationToolTipSectionTitle1 }}</h5>
			<ul class="simp-margin-top-small simp-text" [innerHTML]="labels.applicationToolTipSection1"></ul>

			<h5 class="simp-h5">{{ labels.applicationToolTipSectionTitle2 }}</h5>
			<ul class="simp-margin-top-small simp-text" [innerHTML]="labels.applicationToolTipSection2"></ul>
			<h5 class="simp-h5" [innerHTML]="labels.applicationToolTipSection3"></h5>

			<h5 class="simp-h5">
				{{ labels.applicationToolTipSection4 }}
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					[ngbTooltip]="contactPhone"
					href="tel: {{ contactPhone }}"
				>
					Call us <i class="fas fa-mobile-alt simp-margin-left-base"></i
				></a>
				<a
					class="simp-link btn btn-sm simp-button alert-box__btn alert-box__btn--blue"
					href="mailto:{{ contactEmail }}"
					[ngbTooltip]="contactEmail"
				>
					Email us <i class="fas fa-envelope simp-margin-left-base"></i
				></a>
			</h5>
		</div>
	</div>
</ng-template>

<ng-template #precheckCreditHistoryToolTip>
	<div class="d-flex flex-column">
		<p class="simp-text" [innerHTML]="labels.creditHistoryToolTipSection1"></p>
		<ul class="simp-margin-top-small simp-text" [innerHTML]="labels.creditHistoryToolTipSection2"></ul>
	</div>
</ng-template>
