import { PrimaryPurposeLoanPurpose, RbaLendingPurpose } from '@app/modules/shared/enums/app.enums';
import { BorrowingsDTO } from '@app/modules/typings/api';

export class BorrowingTransformer {
	static fromPayload(borrowing: BorrowingsDTO): BorrowingInfo {
		if (borrowing?.lendingPurpose?.length === 1 && borrowing.primaryLendingPurpose) {
			borrowing.lendingPurpose[0].primaryLendingPurpose = borrowing.primaryLendingPurpose;
		}
		return {
			loanId: borrowing.id,
			applicationId: borrowing.applicationId,
			amountRequested: borrowing.amountRequested,
			primaryPurpose: borrowing.primaryPurpose,
			cashOutAmount: borrowing.cashOutAmount,
			cashOutReason: borrowing.cashOutReason,
			isDebtConsolidation: borrowing.isDebtConsolidation,
			negativelyGeared: borrowing.negativelyGeared,
			nccpStatus: borrowing.nccpStatus,
			lendingPurpose: borrowing.lendingPurpose || [{}],
			isMainLoan: borrowing.isMainLoan
		} as BorrowingInfo;
	}

	static toPayLoad(borrowingInfo: BorrowingInfo): BorrowingsDTO {
		return {
			id: borrowingInfo.loanId,
			applicationId: borrowingInfo.applicationId,
			isMainLoan: borrowingInfo.isMainLoan,
			amountRequested: borrowingInfo.amountRequested,
			primaryPurpose: borrowingInfo.primaryPurpose,
			cashOutAmount: borrowingInfo.cashOutAmount,
			cashOutReason: borrowingInfo.cashOutReason,
			isDebtConsolidation: borrowingInfo.isDebtConsolidation,
			negativelyGeared: borrowingInfo.negativelyGeared,
			nccpStatus: borrowingInfo.nccpStatus,
			primaryLendingPurpose:
				borrowingInfo.lendingPurpose?.length === 1 ? borrowingInfo.lendingPurpose[0].primaryLendingPurpose : undefined,
			lendingPurpose: this.toPayloadLendingPurpose(borrowingInfo.lendingPurpose)
		};
	}

	private static toPayloadLendingPurpose(lendingPurpose?: BorrowingLendingPurpose[]): BorrowingLendingPurpose[] {
		if (!lendingPurpose) {
			return [];
		}
		return lendingPurpose.map((purpose, index) => ({
			...purpose,
			isPrimary: index === 0
		}));
	}
}

export interface BorrowingInfo {
	loanId?: number;
	primaryLendingPurpose?: number;
	applicationId?: number;
	amountRequested: number | undefined;
	amountRequestedWithCapitalisedFees: number | undefined;
	primaryPurpose?: PrimaryPurposeLoanPurpose;
	preferredProductChannelId?: number;
	cashOutAmount?: string;
	cashOutReason?: string;
	isDebtConsolidation: boolean;
	negativelyGeared: boolean;
	nccpStatus?: number;
	lendingPurpose?: BorrowingLendingPurpose[];
	isMainLoan?: boolean;
}

export interface BorrowingLendingPurpose {
	id: number;
	primaryLendingPurpose?: RbaLendingPurpose;
	absLendingPurposeCode?: number;
	purposeAmount?: number;
	description?: string;
	isPrimary?: boolean;
}
