import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { InterviewDeclarationDTO, InterviewPersonDTO } from '@app/modules/typings/api';

export class InterviewDeclarationTransformer {
	static fromPayload(payload: InterviewDeclarationDTO, personName: string): InterviewDeclarationModel {
		return {
			datedOutside: getNullableYesNo(payload.datedOutside),
			datedOutsideConfirmation: getNullableYesNo(payload.datedOutsideConfirmation),
			inPerson: getNullableYesNo(payload.inPerson),
			inPersonDate: payload.inPersonDate,
			inPersonLocation: payload.inPersonLocation,
			byPhone: getNullableYesNo(payload.byPhone),
			byPhoneDate: payload.byPhoneDate,
			byEmail: getNullableYesNo(payload.byEmail),
			byEmailDate: payload.byEmailDate,
			byVideoConferencing: getNullableYesNo(payload.byVideoConferencing),
			byVideoConferencingDate: payload.byVideoConferencingDate,
			statementRevalidationCheckboxes: {
				options: payload.statementRevalidationOption?.optionEnums ?? []
			},
			statementRevalidationExplanation: payload.statementRevalidationExplanation,
			applicantName: personName
		};
	}

	static toPayload(
		model: InterviewDeclarationModel,
		applicationId: number,
		applicant: InterviewPersonDTO
	): InterviewDeclarationDTO {
		return {
			applicationId,
			targetId: applicant.targetId,
			targetType: applicant.targetType,
			datedOutside: getNullableBoolean(model.datedOutside),
			datedOutsideConfirmation: getNullableBoolean(model.datedOutsideConfirmation),
			inPerson: getNullableBoolean(model.inPerson),
			inPersonDate: model.inPersonDate,
			inPersonLocation: model.inPersonLocation,
			byPhone: getNullableBoolean(model.byPhone),
			byPhoneDate: model.byPhoneDate,
			byEmail: getNullableBoolean(model.byEmail),
			byEmailDate: model.byEmailDate,
			byVideoConferencing: getNullableBoolean(model.byVideoConferencing),
			byVideoConferencingDate: model.byVideoConferencingDate,
			statementRevalidationOption: {
				optionEnums: model.statementRevalidationCheckboxes?.options ?? []
			},
			statementRevalidationExplanation: model.statementRevalidationExplanation
		};
	}
}

export interface InterviewDeclarationModel {
	datedOutside?: YesNo;
	applicantName?: string;
	datedOutsideConfirmation?: YesNo;
	inPerson?: YesNo;
	inPersonDate?: string;
	inPersonLocation?: string;
	byPhone?: YesNo;
	byPhoneDate?: string;
	byEmail?: YesNo;
	byEmailDate?: string;
	byVideoConferencing?: YesNo;
	byVideoConferencingDate?: string;
	statementRevalidationCheckboxes?: MultiCheckboxModel;
	statementRevalidationExplanation?: string;
}
