<!-- This spinner is driven by field hide expression -->
<ng-container *ngIf="!to.handler">
	<div class="loader">
		<div class="loader__spinner spinner-border" role="status"></div>
		<span class="loader__label">{{ to.subText || 'Loading…' }}</span>
	</div>
</ng-container>

<!-- This spinner is driven by field form control value -->
<ng-container *ngIf="to.handler && formControl.value">
	<div class="loader">
		<div class="loader__spinner spinner-border" role="status"></div>
		<span class="loader__label">{{ to.subText || 'Loading…' }}</span>
	</div>
</ng-container>
