import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'formly-templates',
	templateUrl: './formly-templates.component.html',
	styleUrls: ['./formly-templates.component.scss']
})
export class FormlyTemplatesComponent implements OnInit, OnDestroy {
	@Input() field: FormlyFieldConfig = {};
	isSubSection = false;
	summaryType = '';
	sumBlock = false;
	tooltipText = '';
	formGroup = new UntypedFormGroup({
		amount: new UntypedFormControl(),
		frequency: new UntypedFormControl()
	});
	private destroy$: Subject<void> = new Subject();

	get amount(): AbstractControl {
		return this.formGroup.get('amount') as AbstractControl;
	}

	get showSumBlock(): boolean {
		return (
			(this.field.templateOptions?.collapsible ? !this.field.templateOptions?.expanded : true) &&
			this.sumBlock &&
			!!this.amount?.value
		);
	}

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.isSubSection = this.field.type === 'sub-section';
		const sumBlock = this.field.templateOptions?.sumBlock as string;

		this.summaryType = sumBlock;
		this.sumBlock = !!sumBlock;

		this.tooltipText = this.field.templateOptions?.tooltipText as string;

		if (this.sumBlock) {
			this.field.formControl?.valueChanges
				.pipe(takeUntil(this.destroy$), startWith(this.field.formControl.value))
				.subscribe(() => {
					let total = 0;
					if (this.field?.formControl?.value === undefined) {
						total = 0;
					} else {
						const value = this.field.formControl.value as unknown;

						if (Array.isArray(value)) {
							value.forEach((item: { summaryAmount?: number }) => {
								total += item?.summaryAmount || 0;
							});
						} else if (value && typeof value === 'object') {
							Object.values(value).forEach((nestedValue) => {
								if (Array.isArray(nestedValue)) {
									nestedValue.forEach((item: { summaryAmount?: number }) => {
										total += item?.summaryAmount || 0;
									});
								}
							});
						}
					}

					if (this.amount.value !== total) {
						this.amount.setValue(total);
					}

					this.cdr.detectChanges();
				});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
