import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { TrusteeDetailsDTO } from '@app/modules/typings/api';

export class TrusteeTransformer {
	static fromPayload(trustee: TrusteeDetailsDTO, formEnumsQuery: FormEnumsQuery): TrusteeModel {
		const existingSelect = formEnumsQuery.getExistingPartiesById({ type: trustee.targetType, id: trustee.partyId });
		return {
			existingSelect,
			id: trustee.id,
			partyId: trustee.partyId,
			appointmentDate: trustee.appointmentDate,
			targetType: trustee.targetType
		};
	}

	static toPayload(applicantId: number, applicationId: number, trustee: TrusteeModel): TrusteeDetailsDTO {
		return {
			id: trustee.id,
			trustApplicantId: applicantId,
			appointmentDate: trustee.appointmentDate,
			applicationId: applicationId,
			partyId: trustee.existingSelect.id,
			targetType: trustee.existingSelect.type
		};
	}
}

export interface TrusteeModel {
	id: number;
	trustApplicantId?: number;
	appointmentDate: string;
	applicationId?: number;
	partyId: number;
	targetType: number;
	existingSelect: ApplicantEnumObject;
}
