import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorConfirmCompletedTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorConfirmCompletedModel {
		return {
			confirm: payload.confirmed
		};
	}
}
export interface GuarantorConfirmCompletedModel {
	confirm?: boolean;
}
