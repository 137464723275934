import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoanApplication } from './modules/shared/model/loan-application.model';
import { ApplicationService } from './modules/shared/service/application.service';

@Injectable({
	providedIn: 'root'
})
export class AppService {
	checkSmallScreen: Subject<void> = new Subject();
	applicationTitleInfoChange: Subject<void> = new Subject();
	appSpinnerStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private activeApp = AppType.LoanAppClient;
	constructor(private applicationService: ApplicationService, private router: Router) {}

	getUserApplications(includingAppId?: number, searchTerm?: string): Observable<LoanApplication[]> {
		return this.applicationService.getUserApplications(includingAppId, searchTerm).pipe(
			map((loanApplications: LoanApplication[]) => {
				return loanApplications.map((app) => {
					return {
						...app,
						title: this.getApplicationTitle(app)
					};
				});
			})
		);
	}

	getApplicationTitle(app: LoanApplication): string {
		let title = app.applicationNumber;

		if (app.title) {
			if (app.title.includes(app.applicationNumber)) {
				title = app.title;
			} else {
				title = `${app.applicationNumber} - ${app.title}`;
			}
		}

		if (app.primaryApplicantName) {
			title += ` - ${app.primaryApplicantName}`;
		}
		return title;
	}
	getUserServiceabilityApplications(includingAppId?: number, searchTerm?: string): Observable<LoanApplication[]> {
		return this.applicationService.getUserServiceabilityApplications(includingAppId, searchTerm).pipe(
			map((loanApplications: LoanApplication[]) =>
				loanApplications.map((app) => {
					return {
						...app,
						title: this.getApplicationTitle(app)
					};
				})
			)
		);
	}

	setActiveApp(app?: AppType): AppType {
		if (app) {
			this.activeApp = app;
		} else if (this.router.url.includes('feature-flag')) {
			this.activeApp = AppType.FeatureFlag;
		} else {
			this.activeApp = AppType.LoanAppClient;
		}

		return this.activeApp;
	}

	getActiveApp() {
		return this.activeApp;
	}

	isSmallScreen(element: ElementRef<HTMLElement>): boolean {
		const currentNavHeight = element?.nativeElement.offsetHeight;
		const headerHeight = 120;
		const footerHeight = 56;
		const currentWindowHeight = window.innerHeight - (headerHeight + footerHeight);
		return currentNavHeight > currentWindowHeight ? true : false;
	}
}

export enum AppType {
	LoanAppClient,
	LoanAppX,
	FeatureFlag
}
