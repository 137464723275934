/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { Permission } from '../access/permission';
import { AccessType } from '../model/access-type.model';
import { CurrentUser } from '../model/current-user.model';
import { PostLoginService } from './post-login.service';

@Injectable({
	providedIn: 'root'
})
export class AccessService {
	private Read = 1;
	private Write = 2;
	private Yes = 4;
	constructor(private authService: AuthService, private postLoginService: PostLoginService) {}

	getUserId(): number {
		return this.authService.id;
	}

	getCurrentUser(): CurrentUser {
		return this.postLoginService.getCurrentUser();
	}
	isANZChannel(): boolean {
		return this.getCurrentUser()?.channelName.includes('ANZ');
	}

	canReadAll(permissionId: number): boolean {
		const perm = this.findPermission(permissionId);
		return perm != null && (perm.all & (this.Read | this.Write)) !== 0;
	}

	canRead(permissionId: number): boolean {
		const perm = this.findPermission(permissionId);
		return (
			perm != null &&
			((perm.all & (this.Read | this.Write)) !== 0 ||
				(perm.team & (this.Read | this.Write)) !== 0 ||
				(perm.own & (this.Read | this.Write)) !== 0)
		);
	}

	canWriteAll(permissionId: number): boolean {
		const perm = this.findPermission(permissionId);
		return perm != null && (perm.all & this.Write) !== 0;
	}

	canWrite(permissionId: number): boolean {
		const perm = this.findPermission(permissionId);
		return (
			perm != null && ((perm.all & this.Write) !== 0 || (perm.team & this.Write) !== 0 || (perm.own & this.Write) !== 0)
		);
	}

	canDoAny(permissionIds: number[]): boolean {
		let canAccess = false;
		permissionIds.forEach((permissionId) => {
			const perm = this.findPermission(permissionId);
			canAccess = canAccess || (perm != null && (perm.all & this.Yes) !== 0);
		});

		return canAccess;
	}

	canAccessMenu(permissionIds: number[]): boolean {
		let canAccess = false;
		permissionIds.forEach((permissionId) => {
			const perm = this.findPermission(permissionId);
			canAccess = canAccess || this.canRead(permissionId) || this.canDoAny([permissionId]);
		});

		return canAccess;
	}

	canReadTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
		return this.canAccessTiered(this.Read, permissionId, targetPersonIds, targetEntityIds);
	}

	canWriteTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
		return this.canAccessTiered(this.Write, permissionId, targetPersonIds, targetEntityIds);
	}

	canDoTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
		return this.canAccessTiered(this.Yes, permissionId, targetPersonIds, targetEntityIds);
	}

	canReadOwnDataOnly(permissionId: number): boolean {
		return this.getAccessType(this.Read, permissionId) === AccessType.Own;
	}

	canWriteOwnDataOnly(permissionId: number): boolean {
		return this.getAccessType(this.Write, permissionId) === AccessType.Own;
	}

	canUseOwnDataOnly(permissionId: number): boolean {
		return this.getAccessType(this.Yes, permissionId) === AccessType.Own;
	}

	canAccess(permissionId: number): boolean {
		return this.getAccessType(this.Yes, permissionId) !== AccessType.None;
	}

	getUserReadAccessType(permissionId: number): AccessType {
		return this.getAccessType(this.Read, permissionId);
	}

	private canAccessTiered(
		access: number,
		permissionId: number,
		targetPersonIds: number[],
		targetEntityIds: number[]
	): boolean {
		const accessType = this.getAccessType(access, permissionId);

		if (accessType === AccessType.All) {
			return true;
		}
		const currentUser = this.postLoginService.getCurrentUser();

		if (
			accessType === AccessType.Team &&
			(targetEntityIds.length === 0 || targetEntityIds.includes(currentUser.entityId))
		) {
			return true;
		}
		if (
			accessType === AccessType.Own &&
			(targetEntityIds.length === 0 || targetEntityIds.includes(currentUser.entityId)) &&
			(targetPersonIds.length === 0 || targetPersonIds.includes(currentUser.peopleId))
		) {
			return true;
		}

		return false;
	}

	private getAccessType(access: number, permissionId: number): AccessType {
		const perm = this.findPermission(permissionId);
		if (!perm) {
			return AccessType.None;
		}
		if (this.hasAllAccess(perm, access)) {
			return AccessType.All;
		}
		if (this.hasTeamAccess(perm, access)) {
			return AccessType.Team;
		}
		if (this.hasOwnAccess(perm, access)) {
			return AccessType.Own;
		}
		return AccessType.None;
	}

	private hasAllAccess(permission: Permission, access: number): boolean {
		return this.hasSomeAccess(permission.all, access);
	}

	private hasTeamAccess(permission: Permission, access: number): boolean {
		return this.hasSomeAccess(permission.team, access);
	}

	private hasOwnAccess(permission: Permission, access: number): boolean {
		return this.hasSomeAccess(permission.own, access);
	}

	private hasSomeAccess(granted: number, access: number): boolean {
		const accessToCheck = granted === this.Write ? this.Write | this.Read : granted;
		return (access & accessToCheck) !== 0;
	}

	private findPermission(permissionId: number): Permission | undefined {
		return this.authService.permissions.find((perm: Permission) => perm.id === permissionId);
	}
}
