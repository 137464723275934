import {
	ScenarioLeverIncomeType as IncomeScenarioLeverType,
	ScenarioLeverIncomeType
} from '@app/modules/shared/enums/app.enums';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import {
	PaygIncomeScenarioDTO,
	RentalIncomeScenarioDTO,
	ScenarioLeverDTO,
	ScenariosDTO,
	SelfEmployedIncomeScenarioDTO
} from '@app/modules/typings/api';
import { FormEnumsQuery } from './../../shared/store/form-enums/form-enums.query';
import { ScenarioLeverDetails } from './scenario-lever.model';

export class IncomeScenarioTransformer {
	static fromPayload(scenariosDto: ScenariosDTO, formEnumQuery: FormEnumsQuery): IncomeScenario {
		const baseScenario = scenariosDto.scenarios.find((scenario: ScenarioLeverDTO) => scenario.isBaseScenario);
		const selectedScenario = scenariosDto.scenarios.find(
			(scenario: ScenarioLeverDTO) => scenario.isBaseScenario === false
		);
		const paygIncomes = baseScenario?.paygIncomes?.map((paygIncome: PaygIncomeScenarioDTO) => {
			return {
				id: paygIncome.id,
				incomeId: paygIncome.paygId,
				incomeLabel: IncomeScenarioTransformer.generateIncomeLabel(
					'PAYG',
					formEnumQuery,
					paygIncome.companyName,
					paygIncome.personApplicantId
				),
				incomeType: IncomeScenarioLeverType.Payg,
				baseScenarioSalary: { amount: paygIncome.grossSalaryAmount, frequency: paygIncome.grossSalaryFrequencyId },
				alternateScenarioIncomeChange: IncomeScenarioTransformer.getAlternateScenarioSalary(
					paygIncome.paygId,
					selectedScenario?.paygIncomes
				)
			};
		}) as IncomeScenarioDetails[];

		const selfEmployedIncomes = baseScenario?.selfEmployedIncomes?.map(
			(selfEmployedIncome: SelfEmployedIncomeScenarioDTO) => {
				return {
					id: selfEmployedIncome.id,
					incomeId: selfEmployedIncome.selfEmployedId,
					incomeLabel: IncomeScenarioTransformer.generateIncomeLabel(
						'Self employed',
						formEnumQuery,
						selfEmployedIncome.companyName,
						selfEmployedIncome.personApplicantId
					),
					incomeType: IncomeScenarioLeverType.SelfEmployed,
					baseScenarioNetProfitBeforeTax: {
						amount: selfEmployedIncome.businessIncomeRecentProfitBeforeTax,
						frequency: selfEmployedIncome.frequencyId
					},
					alternateScenarioIncomeChange: IncomeScenarioTransformer.getAlternateScenarioProfitBeforeTax(
						selfEmployedIncome.selfEmployedId,
						selectedScenario?.selfEmployedIncomes
					)
				};
			}
		) as IncomeScenarioDetails[];

		const rentalIncomes = baseScenario?.rentalIncomes?.map((rentalIncome: RentalIncomeScenarioDTO) => {
			return {
				id: rentalIncome.id,
				incomeId: rentalIncome.rentalIncomeId,
				futureRentalId: rentalIncome.futureRentalIncomeId,
				incomeLabel: IncomeScenarioTransformer.generateIncomeLabel(
					'Rental',
					formEnumQuery,
					rentalIncome.addressOneLine
				),
				incomeType: IncomeScenarioLeverType.Rental,
				baseScenarioRent: {
					amount: rentalIncome.rentalAmount,
					frequency: rentalIncome.rentalFrequencyId
				},
				alternateScenarioIncomeChange: IncomeScenarioTransformer.getAlternateScenarioRental(
					rentalIncome.rentalIncomeId,
					rentalIncome.futureRentalIncomeId,
					selectedScenario?.rentalIncomes
				)
			};
		}) as IncomeScenarioDetails[];

		return { incomes: [...paygIncomes, ...selfEmployedIncomes, ...rentalIncomes] };
	}

	static toPayload(scenarioLeverDetails?: ScenarioLeverDetails): ScenarioLeverDTO {
		const scenarioLeverDTO: ScenarioLeverDTO = {
			id: 0,
			isBaseScenario: false,
			loanDetails: [],
			expenses: [],
			propertyExpenses: [],
			liabilities: [],
			paygIncomes: [],
			selfEmployedIncomes: [],
			rentalIncomes: []
		};

		if (scenarioLeverDetails) {
			scenarioLeverDetails.incomes?.incomes.forEach((income) => {
				if (income?.alternateScenarioIncomeChange || income?.alternateScenarioIncomeChange === 0) {
					const baseFrequency = income.baseScenarioNetProfitBeforeTax?.frequency;
					switch (income.incomeType) {
						case ScenarioLeverIncomeType.Payg:
							scenarioLeverDTO.paygIncomes.push({
								paygId: income.incomeId,
								grossSalaryAmount: income.alternateScenarioIncomeChange,
								grossSalaryFrequencyId: baseFrequency
							});
							break;
						case ScenarioLeverIncomeType.SelfEmployed:
							scenarioLeverDTO.selfEmployedIncomes.push({
								selfEmployedId: income.incomeId,
								businessIncomeRecentProfitBeforeTax: income.alternateScenarioIncomeChange,
								frequencyId: baseFrequency
							});
							break;
						case ScenarioLeverIncomeType.Rental:
							scenarioLeverDTO.rentalIncomes.push({
								rentalIncomeId: income.incomeId,
								futureRentalIncomeId: income.futureRentalId,
								rentalAmount: income.alternateScenarioIncomeChange,
								rentalFrequencyId: baseFrequency
							});
							break;
					}
				}
			});
		}

		return scenarioLeverDTO;
	}

	static generateIncomeLabel(
		incomeType: string,
		formEnumQuery: FormEnumsQuery,
		companyName?: string,
		applicantId?: number
	): string {
		let incomeLabel = incomeType;

		if (applicantId) {
			const applicantName = formEnumQuery.getOptionLabel('Applicants', applicantId);
			incomeLabel = incomeLabel.concat(` - ${applicantName}`);
		}

		if (companyName) {
			incomeLabel = incomeLabel.concat(`</br>${companyName}`);
		}
		return incomeLabel;
	}

	static getAlternateScenarioSalary(
		basePaygId: number,
		alternateScenarioPaygIncomes: PaygIncomeScenarioDTO[] | undefined
	): number | undefined {
		return alternateScenarioPaygIncomes?.find((paygIncome: PaygIncomeScenarioDTO) => paygIncome?.paygId === basePaygId)
			?.grossSalaryAmount;
	}

	static getAlternateScenarioProfitBeforeTax(
		baseSelfEmployedId: number,
		alternateScenarioSelfEmployedIncomes: SelfEmployedIncomeScenarioDTO[] | undefined
	): number | undefined {
		return alternateScenarioSelfEmployedIncomes?.find(
			(selfEmployedIncome: SelfEmployedIncomeScenarioDTO) => selfEmployedIncome?.selfEmployedId === baseSelfEmployedId
		)?.businessIncomeRecentProfitBeforeTax;
	}

	static getAlternateScenarioRental(
		baseRentalId: number | undefined,
		baseFutureRentalId: number | undefined,
		alternateScenarioRentalIncomes: RentalIncomeScenarioDTO[] | undefined
	): number | undefined {
		if (baseFutureRentalId) {
			return alternateScenarioRentalIncomes?.find(
				(rentalIncome: RentalIncomeScenarioDTO) => rentalIncome?.futureRentalIncomeId === baseFutureRentalId
			)?.rentalAmount;
		}
		return alternateScenarioRentalIncomes?.find(
			(rentalIncome: RentalIncomeScenarioDTO) => rentalIncome?.rentalIncomeId === baseRentalId
		)?.rentalAmount;
	}
}

export interface IncomeScenario {
	incomes: IncomeScenarioDetails[];
}

export interface IncomeScenarioDetails {
	id: number;
	incomeId: number;
	futureRentalId?: number;
	incomeLabel: string;
	incomeType: IncomeScenarioLeverType;
	baseScenarioSalary?: AmountSelect;
	baseScenarioNetProfitBeforeTax?: AmountSelect;
	baseScenarioRent?: AmountSelect;
	alternateScenarioIncomeChange?: number;
}
