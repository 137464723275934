import { YesNo, YesNoIntent } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { BusinessDetailsDTO } from '@app/modules/typings/api';

export class BusinessDetailsTransformer {
	static fromPayload(businessDetails: BusinessDetailsDTO): BusinessDetailsModel {
		return {
			id: businessDetails.id,
			startDate: businessDetails.startDate,
			anzsicIndustry: businessDetails.industryCode,
			numberOfEmployees: businessDetails.numberOfEmployees,
			numberOfLocations: businessDetails.numberOfLocations,
			franchise: businessDetails.franchise,
			ownPremises: getNullableYesNo(businessDetails.ownPremises),
			businessMainBusinessActivity: businessDetails?.businessMainBusinessActivity,
			principalTradingAddressLeased: getNullableYesNo(businessDetails.principalTradingAddressLeased),
			importExport: businessDetails.importExport,
			pleaseProvideDetails: businessDetails.importExportDetails,
			accountNumber: businessDetails.accountNumber,
			branchDomicileInternalName: businessDetails.branchDomicileInternalName,
			businessConcentrationRiskConcentrationRiskDetails:
				businessDetails.businessConcentrationRiskConcentrationRiskDetails,
			businessConcentrationRiskCustomerOrSupplierConcentration: getNullableYesNo(
				businessDetails.businessConcentrationRiskCustomerOrSupplierConcentration
			),
			businessDiversificationDetails: businessDetails.businessDiversificationDetails,
			businessDiversificationDiversifiedDate: businessDetails.businessDiversificationDiversifiedDate,
			businessFranchiseDetails: businessDetails.businessFranchiseDetails,
			businessPrincipalTradingAddressLeaseAgreementDetails:
				businessDetails.businessPrincipalTradingAddressLeaseAgreementDetails,
			businessPropertyInvestmentDetails: businessDetails.businessPropertyInvestmentDetails,
			businessPropertyInvestmentIsInvolved: businessDetails.businessPropertyInvestmentIsInvolved,
			relatedLegalEntitiesCurrentCustomers: businessDetails.relatedLegalEntitiesCurrentCustomers,
			relatedLegalEntitiesEntityCount: businessDetails.relatedLegalEntitiesEntityCount,
			businessDiversificationIsDiversified: getNullableYesNo(businessDetails.businessDiversificationIsDiversified),
			isExistingCustomer: getNullableYesNo(businessDetails.isExistingCustomer),
			allowDirectMarketing: getNullableYesNo(businessDetails.allowDirectMarketing),
			isAllBusinessInsuranceInPlaceAndCurrent: getNullableYesNo(
				businessDetails.isAllBusinessInsuranceInPlaceAndCurrent
			),
			customerSince:
				!!businessDetails.customerSince && !!businessDetails.customerSinceMonth
					? `${businessDetails.customerSince}-${businessDetails.customerSinceMonth}-01`
					: undefined
		};
	}

	static toPayload(applicationId: number, businessDetails: BusinessDetailsModel): Partial<BusinessDetailsDTO> {
		return {
			id: businessDetails.id,
			applicationId: applicationId,
			startDate: businessDetails.startDate,
			industryCode: businessDetails.anzsicIndustry,
			numberOfEmployees: businessDetails.numberOfEmployees,
			numberOfLocations: businessDetails.numberOfLocations,
			franchise: businessDetails.franchise,
			ownPremises: getNullableBoolean(businessDetails.ownPremises),
			businessMainBusinessActivity: businessDetails?.businessMainBusinessActivity,
			principalTradingAddressLeased: getNullableBoolean(businessDetails.principalTradingAddressLeased),
			importExport: businessDetails.importExport,
			importExportDetails: businessDetails.pleaseProvideDetails || '',
			accountNumber: businessDetails.accountNumber,
			branchDomicileInternalName: businessDetails.branchDomicileInternalName,
			businessConcentrationRiskConcentrationRiskDetails:
				businessDetails.businessConcentrationRiskConcentrationRiskDetails,
			businessConcentrationRiskCustomerOrSupplierConcentration: getNullableBoolean(
				businessDetails.businessConcentrationRiskCustomerOrSupplierConcentration
			),
			businessDiversificationDetails: businessDetails.businessDiversificationDetails,
			businessDiversificationDiversifiedDate: businessDetails.businessDiversificationDiversifiedDate,
			businessFranchiseDetails: businessDetails.businessFranchiseDetails,
			businessPrincipalTradingAddressLeaseAgreementDetails:
				businessDetails.businessPrincipalTradingAddressLeaseAgreementDetails,
			businessPropertyInvestmentDetails: businessDetails.businessPropertyInvestmentDetails,
			businessPropertyInvestmentIsInvolved: businessDetails.businessPropertyInvestmentIsInvolved,
			relatedLegalEntitiesCurrentCustomers: businessDetails.relatedLegalEntitiesCurrentCustomers,
			relatedLegalEntitiesEntityCount: businessDetails.relatedLegalEntitiesEntityCount,
			businessDiversificationIsDiversified: getNullableBoolean(businessDetails.businessDiversificationIsDiversified),
			isExistingCustomer: getNullableBoolean(businessDetails.isExistingCustomer),
			allowDirectMarketing: getNullableBoolean(businessDetails.allowDirectMarketing),
			isAllBusinessInsuranceInPlaceAndCurrent: getNullableBoolean(
				businessDetails.isAllBusinessInsuranceInPlaceAndCurrent
			),
			customerSince:
				businessDetails.isExistingCustomer === YesNo.Yes && businessDetails.customerSince
					? new Date(businessDetails.customerSince).getFullYear()
					: undefined,
			customerSinceMonth:
				businessDetails.isExistingCustomer === YesNo.Yes && businessDetails.customerSince
					? new Date(businessDetails.customerSince).getMonth() + 1
					: undefined
		};
	}
}

export interface BusinessDetailsModel {
	id?: number;
	applicationId?: number;
	startDate?: string | undefined;
	anzsicIndustry?: number;
	numberOfEmployees?: number;
	numberOfLocations?: number;
	franchise?: YesNoIntent;
	ownPremises?: YesNo;
	businessMainBusinessActivity?: string;
	principalTradingAddressLeased?: YesNo;
	importExport?: YesNoIntent;
	pleaseProvideDetails?: string;
	accountNumber?: string;
	branchDomicileInternalName?: string;
	businessConcentrationRiskConcentrationRiskDetails?: string;
	businessConcentrationRiskCustomerOrSupplierConcentration?: YesNo;
	businessDiversificationDetails?: string;
	businessDiversificationDiversifiedDate?: string;
	businessFranchiseDetails?: string;
	businessPrincipalTradingAddressLeaseAgreementDetails?: string;
	businessPropertyInvestmentDetails?: string;
	businessPropertyInvestmentIsInvolved?: YesNoIntent;
	customerSince?: string;
	relatedLegalEntitiesCurrentCustomers?: string;
	relatedLegalEntitiesEntityCount?: number;
	businessDiversificationIsDiversified?: YesNo;
	isExistingCustomer?: YesNo;
	allowDirectMarketing?: YesNo | undefined;
	isAllBusinessInsuranceInPlaceAndCurrent?: YesNo;
}
