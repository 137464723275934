import { Pipe, PipeTransform } from '@angular/core';
import { EnumObject } from '@simpology/client-components/utils';
import { isArray } from 'lodash-es';

@Pipe({ name: 'dynamicOptions' })
export class DynamicOptionsPipe implements PipeTransform {
	public transform(options: EnumObject[] | null, filterIds: any): EnumObject[] | null {
		if (!filterIds) {
			return options;
		}

		// parse filter Ids
		if (typeof filterIds === 'string') {
			try {
				filterIds = JSON.parse(filterIds) as object;
			} catch (e) {
				console.error(`'${filterIds}' is not a valid array`);
				return options;
			}
		}

		// Check for valid filter array of options, else print out error
		if (isArray(filterIds)) {
			if (filterIds?.length) {
				const includeFilterIds = (filterIds ?? []).indexOf('^') < 0;
				return filterIds?.length
					? options?.filter((option) =>
							includeFilterIds
								? (filterIds as (string | number)[]).includes(option.id)
								: !(filterIds as (string | number)[]).includes(option.id)
					  ) ?? null
					: options;
			}
			return options;
		} else {
			console.error(`'${filterIds}' is not a valid array`);
			return options;
		}
	}
}
