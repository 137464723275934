import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/modules/shared/service/authentication.service';

@Component({
	selector: 'app-auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
	public error = false;

	constructor(private authService: AuthenticationService, private route: ActivatedRoute) {}

	public async ngOnInit(): Promise<void> {
		// check for error
		if (this.route.snapshot.fragment!.indexOf('error') >= 0) {
			this.error = true;
			return;
		}
		await this.authService.completeAuthentication();
	}
}
