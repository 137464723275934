import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';

import { SimpFormlyModule } from '../simp-formly/simp-formly.module';
import { PreCheckWrapperComponent } from './components/pre-check-wrapper/pre-check-wrapper.component';
import { PreCheckComponent } from './components/pre-check/pre-check.component';
import { NewApplicationOptionsComponent } from './components/new-application-options/new-application-options.component';

@NgModule({
	declarations: [PreCheckComponent, PreCheckWrapperComponent, NewApplicationOptionsComponent],
	imports: [CommonModule, SharedModule, SimpFormlyModule],
	providers: [NgbActiveModal]
})
export class PreCheckModule {}
