<ng-container>
	<div class="container bg-light form-group">
		<div class="simp-row">
			<div class="col px-3 pt-3" *ngFor="let h of to.headers">{{ h }}</div>
		</div>
		<div class="simp-row simp-text-small" *ngFor="let row of to.tableData">
			<div class="col p-3" *ngFor="let d of row">{{ d }}</div>
		</div>
	</div>
</ng-container>
