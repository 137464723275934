import { Injectable } from '@angular/core';
import { RelatedPartyModel } from '@app/modules/applicants/models/related-party.model';
import { PropertyModalTransformerService } from '@app/modules/property/services/property-modal-transformer.service';
import { ApplicationEntityType, TargetType } from '@app/modules/shared/enums/app.enums';
import { savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { RelatedEntitiesService } from '@app/modules/shared/service/related-entities.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AssetsLiabilitiesTransformerService } from '@app/modules/shared/transformers/assets-liabilities-transformer.service';
import {
	formlyExtendExpressionProperties,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { TrustExpensesDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { AccountantModel } from '../../model/company-trust-accountant.model';

import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ForeignTaxAssociationModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { ForeignTaxAssociationService } from '@app/modules/shared/service/foreign-tax-association.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { CompanyTrustIncome, CompanyTrustIncomeTransformer } from '../../model/company-trust-income.model';
import { TrustFinancialPositionService } from './trust-financial-position.service';

const SUB_KEY_TRUST_FINANCIAL_POSITION_INCOME = 'trustIncome';

@Injectable({
	providedIn: 'root'
})
export class TrustFinancialPositionTransformerService {
	constructor(
		private assetsLiabilityTransformerService: AssetsLiabilitiesTransformerService,
		private trustFinancialPositionService: TrustFinancialPositionService,
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsQuery: FormEnumsQuery,
		private toastr: ToastrService,
		private relatedEntitiesService: RelatedEntitiesService,
		private propertyModalTransformerService: PropertyModalTransformerService,
		private foreignTaxAssociationService: ForeignTaxAssociationService,
		private formDataService: FormDataService
	) {}

	transform(formFields: FormlyFieldConfig[], index: number): FormlyFieldConfig[] {
		const trustApplicants = this.applicationDataQuery.getTrustApplicants();
		formFields = this.assetsLiabilityTransformerService.transform(formFields) as FormlyFieldConfig[];

		const trustApplicant = trustApplicants[index];
		this.configureAccountant(formFields, trustApplicant);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'trustIncome');
		this.configureTrustIncome(formFields, index);
		this.configureForeignCompanyTaxAssociation(formFields, trustApplicant);
		this.configureExpenses(formFields, trustApplicant);

		this.propertyModalTransformerService.configurePropertyModalForFinancialPosition(
			formFields,
			SUB_KEY_TRUST_FINANCIAL_POSITION_INCOME
		);
		return formFields;
	}

	configureForeignCompanyTaxAssociation(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant) {
		formlyOnStatusChangedToValid(
			formFields,
			'trustApplicant.foreignTaxAssociation',
			(field, event: FormlyArrayUpdateEvent<ForeignTaxAssociationModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as ForeignTaxAssociationModel;
				const foreignTaxResidencyModel = {
					id: model.id ?? CONSTANTS.NEW_ID,
					targetId: trustApplicant.id,
					targetType: TargetType.TrustApplicant,
					selfCertificationDeclaration: model.selfCertificationDeclaration,
					foreignTaxAssociationDetails: model.foreignTaxAssociationDetails
				} as ForeignTaxAssociationModel;

				const payload = ForeignTaxAssociationTransformer.toPayLoad(
					foreignTaxResidencyModel,
					this.applicationDataQuery.applicationId()
				);

				return this.foreignTaxAssociationService
					.saveForeignTaxAssociation(payload)
					.subscribe((foreignTaxAssociationId: number) => {
						model.id = foreignTaxAssociationId;
						this.simpFormlyHandlerService.updateToState('foreignTaxAssociation', model, 0);
						const storeData = cloneDeep(this.formDataService.getSetupApplicants());
						const index = storeData.findIndex((x) => x.id === trustApplicant.id);
						if (!storeData[index].foreignTaxAssociationId) {
							storeData[index].foreignTaxAssociationId = foreignTaxAssociationId;
							this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[index], index);
						}
						this.toastr.success(savedSuccessfullyMessage('Foreign tax information'));
					});
			}
		);
		const foreignTaxAssociationLabel = getFormField(
			formFields,
			'trustApplicant.foreignTaxAssociation.foreignTaxAssociationDetails.countryOfForeignTaxResidency'
		)?.props?.label;
		formlyExtendExpressionProperties(
			formFields,
			`trustApplicant.foreignTaxAssociation.foreignTaxAssociationDetails.countryOfForeignTaxResidency`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					return `${Number(field.parent?.key) + 1} - ${foreignTaxAssociationLabel}`;
				}
			}
		);
	}

	configureExpenses(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant) {
		const applicationDetails = this.applicationDataQuery.getApplicationDetails();
		if (applicationDetails.applicationEntityType !== ApplicationEntityType.SMSF) {
			const expenses = getFormField(formFields, 'trustExpenses');
			if (expenses) {
				expenses.hide = true;
			}
			return;
		}

		formlyRegisterHooks(formFields, 'trustExpenses.smsfRunningCost.smsfRunningCostFrequency', {
			onInit: (field) => {
				const formControl = field?.formControl;
				return formControl?.valueChanges.pipe(
					debounceTime(1000),
					distinctUntilChanged(),
					filter(() => formControl.valid),
					switchMap((amountFrequencyValue: { amount: number; frequency: number }) =>
						this.trustFinancialPositionService
							.saveTrustSmsfRunningCost({
								trustApplicantId: trustApplicant.id,
								applicationId: this.applicationDataQuery.applicationId(),
								runningCost: amountFrequencyValue.amount,
								frequency: amountFrequencyValue.frequency
							} as TrustExpensesDTO)
							.pipe(
								tap(() => {
									this.simpFormlyHandlerService.updateToState('smsfRunningCost', amountFrequencyValue, 0);
									this.toastr.success(savedSuccessfullyMessage('Expenses'));
								})
							)
					)
				);
			}
		});
	}

	configureTrustIncome(formFields: FormlyFieldConfig[], sectionIndex: number, indexSuffix = ''): void {
		const trustApplicant = indexSuffix
			? this.applicationDataQuery.getApplicants()[sectionIndex]
			: this.applicationDataQuery.getTrustApplicants()[sectionIndex];

		formlyOnStatusChangedToValid(
			formFields,
			`trustIncome${indexSuffix}.incomeDetails${indexSuffix}`,
			(field: FormlyFieldConfig, event: FormlyArrayUpdateEvent<CompanyTrustIncome>) => {
				this.saveTrustIncome(field, trustApplicant, event.index ?? 0);
			}
		);
	}

	private saveTrustIncome(field: FormlyFieldConfig, trustApplicant: ShortApplicant, index: number) {
		const model = cloneDeep(field?.model) as CompanyTrustIncome[];
		const stateModel = model?.[index];

		stateModel.previousFY.financialYearId = stateModel.currentFY.financialYearId - 1;
		stateModel.secondPreviousFY.financialYearId = stateModel.currentFY.financialYearId - 2;
		stateModel.thirdPreviousFY.financialYearId = stateModel.currentFY.financialYearId - 3;

		stateModel.previousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 1
		);
		stateModel.secondPreviousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 2
		);
		stateModel.thirdPreviousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 3
		);
		const payload = CompanyTrustIncomeTransformer.toPayload(
			stateModel,
			this.applicationDataQuery.applicationId(),
			trustApplicant.id
		);

		this.trustFinancialPositionService.saveTrustIncome(payload, trustApplicant.id).subscribe((v: void) => {
			this.simpFormlyHandlerService.updateToState('incomeDetails', stateModel, 0);
		});
	}

	private saveAccountant = (model: RelatedPartyModel, personnelIndex: number, applicantId: number) => {
		return this.trustFinancialPositionService
			.saveTrustAccountant({
				applicationId: this.applicationDataQuery.applicationId(),
				accountantId: model.existingSelect?.id,
				accountantName: model.existingSelect?.companyName,
				applicantId
			})
			.pipe(take(1))
			.subscribe(() => {
				this.simpFormlyHandlerService.updateToState('accountant', model, 0);
				this.toastr.success(savedSuccessfullyMessage('Accountant'));
			});
	};

	private handleAccountantChange(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant): void {
		formlyOnStatusChangedToValid(formFields, 'trustIncome.accountant', (field: FormlyFieldConfig) => {
			const model = field.model as AccountantModel[];
			if (model[0].existingSelect.id === -2) {
				return;
			}
			this.saveAccountant(model[0], 0, trustApplicant.id);
		});
	}

	private configureAccountant(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant) {
		const accountantField = getFormField(formFields, 'trustIncome.accountant.existingSelect');
		formlyRegisterHooks(formFields, 'trustIncome.accountant.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const addNewCompany = this.formEnumsQuery.getAddOptions(
						accountantField?.templateOptions?.options as unknown as string
					);
					addNewCompany[0].id = -2;
					const listItems = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
						map((x) => {
							if (x) {
								return [...addNewCompany, ...x].map((company) => ({
									...company,
									click:
										(company.type as unknown as TargetType) === TargetType.RelatedCompany
											? () =>
													this.relatedEntitiesService.openPrefilledRelatedEntityModal(
														field,
														trustApplicant.id,
														this.saveAccountant
													)
											: null
								}));
							} else {
								return addNewCompany;
							}
						})
					);
					field.templateOptions.options = listItems;
					return this.relatedEntitiesService.configureRelatedEntityModal(field, trustApplicant.id, this.saveAccountant);
				}
				return of();
			}
		});
		this.handleAccountantChange(formFields, trustApplicant);
	}
}
