import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyNumberSelectInput = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,

	widget: {
		formlyConfig: {
			type: 'number-select-input',
			className: `${property.templateOptions?.class ?? (property.class || 'col-4')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	},
	required: property?.required,
	properties: {
		number: {
			type: 'input',
			widget: {
				formlyConfig: {
					templateOptions: {
						min: (property.templateOptions?.min as number) || undefined,
						max: (property.templateOptions?.max as number) || undefined
					},
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		frequency: {
			type: 'select',
			widget: {
				formlyConfig: {
					templateOptions: {
						options: property.options,
						bindValueToObject: property.templateOptions?.bindValueToObject ?? false,
						disabled: !!property.templateOptions?.disabled || !!property.templateOptions?.disableFrequency
					},
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		}
	}
});
