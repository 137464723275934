import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual, isNil, omitBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OverridableAccessLevel } from '../../enums/client-enum.model';
import { extractSubSection } from '../../helper/util';
import { ValidationsErrorDetailModel } from '../../typings/validations';
import { FormValidationsStore } from './form-validations.store';
import { FormValidations, FormValidationsDetails, RemoteValidationError } from './typings/form-validations';

@Injectable({ providedIn: 'root' })
export class FormValidationsQuery {
	constructor(protected store: FormValidationsStore, private router: Router) {}

	mapToSubSectionRemoteError = (key: string): Observable<string[] | undefined> =>
		this.store
			.select(key)
			.pipe(map((details: FormValidationsDetails) => details?.remoteErrors?.map((e) => e.errorMessage ?? '')));

	mapToAreaRemoteError = (): Observable<ValidationsErrorDetailModel[]> =>
		this.store.select().pipe(
			map((details: FormValidations) => {
				let result: ValidationsErrorDetailModel[] = [];
				Object.keys(details)
					.filter((key) => details[key]?.remoteErrors?.length ?? 0 > 0)
					.forEach((key) => {
						const errors: ValidationsErrorDetailModel[] = (details[key].remoteErrors as RemoteValidationError[]).map(
							(remoteError) => ({
								message: remoteError.errorMessage ?? '',
								title: remoteError.title ?? '',
								code: remoteError.code ?? '',
								area: remoteError.area,
								subSection: extractSubSection(key),
								path: remoteError.path,
								key,
								isOverrideable: remoteError.isOverrideable ?? false,
								overridableAccessLevelEnum: remoteError.overridableAccessLevelEnum ?? OverridableAccessLevel.All
							})
						);
						result = result.concat(errors);
					});

				return result;
			})
		);

	hasRemoteError(): boolean {
		const details = this.store.getValue();
		return Object.keys(details).some((key) => details[key]?.remoteErrors?.length ?? 0 > 0);
	}

	isDrafted = (stateNames?: string[]): boolean => {
		const state = this.store.getValue();
		const currentSubsectionKeys = this.getCurrentSubsectionKeys(state, stateNames);

		return currentSubsectionKeys.some((subSectionKey) => state[subSectionKey].draft);
	};

	firstDraftedSubsectionKey = (stateNames?: string[]): string | undefined => {
		const state = this.store.getValue();
		const currentSubsectionKeys = this.getCurrentSubsectionKeys(state, stateNames);

		return currentSubsectionKeys.find((subSectionKey) => state[subSectionKey].draft);
	};

	dataChanged = (model: Object, storeData: Object, subKey?: string): boolean => {
		if (subKey) {
			storeData = storeData[subKey as keyof typeof storeData];
			model = model[subKey as keyof typeof model];
		}
		const clippedModel = omitBy(model, isNil);
		const clippedStoreData = omitBy(storeData, isNil);
		delete clippedModel['summaryAmount'];
		delete clippedStoreData['summaryAmount'];
		return !isEqual(clippedModel, clippedStoreData);
	};

	private getCurrentSubsectionKeys(state: FormValidations, stateNames?: string[]): string[] {
		//TODO remove multiple replaces once we update the route without ? and =
		const currentRoute = this.router.url.replace(/\//g, '').replace('?', '-').replace('=', '-');
		return Object.keys(state).filter((x) =>
			stateNames && stateNames.length ? stateNames.includes(x) : x?.includes(currentRoute)
		);
	}
}
