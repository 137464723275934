import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ClearingFromThisLoan,
	FinancialInstitution,
	InterestType,
	Proportions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import {
	calculateMonthly,
	getNullableBoolean,
	getNullableYesNo,
	repaymentToFrequencyFull
} from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { AmountSelect, Term } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, PersonalLoanDTO } from '@app/modules/typings/api';
import { CurrencyHelper, EnumObject } from '@simpology/client-components/utils';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
export class LiabilityPersonalLoanTransformer {
	static toPayload(applicationId: number, personalLoan: PersonalLoanModel): PersonalLoanDTO {
		const personalLoanDetails = personalLoan.details.personalLoanDetails;
		return {
			id: personalLoan.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			description: '',
			financialInstitution: personalLoanDetails.financialInstitution,
			outstandingBalance: personalLoanDetails.outstandingBalance,
			creditLimit: personalLoanDetails.creditLimit,
			otherFinancialInstitutionName: personalLoanDetails?.otherFinancialInstitutionName,
			repaymentAmount: personalLoanDetails.repayments ? personalLoanDetails.repayments.amount : undefined,
			repaymentFrequency: personalLoanDetails.repayments ? personalLoanDetails.repayments.frequency : undefined,
			percentOwnedProportions: personalLoanDetails.isEqualShare ? Proportions.Equal : Proportions.Specified,
			percentsOwned: personalLoanDetails.percentsOwned,
			remainingTermDuration: personalLoanDetails.remainingTerm ? +personalLoanDetails.remainingTerm.number : undefined,
			remainingTermUnits: personalLoanDetails.remainingTerm ? personalLoanDetails.remainingTerm.frequency : undefined,
			interestRate: personalLoanDetails.annualInterestRate,
			originalAmount: CurrencyHelper.unformatAmount(String(personalLoanDetails.loanAmount)),
			closingOnSettlement: getNullableBoolean(personalLoanDetails.clearingBalanceOnSettlement),
			clearingFromThisLoan: getNullableBoolean(personalLoanDetails.clearingFromThisLoan),
			clearingThisLiability: personalLoanDetails.clearingThisLiability,
			refinanceAmount: personalLoanDetails.disbursementAmount,
			refinanceCosts: personalLoanDetails.refinanceCosts,
			bsb: personalLoanDetails.bsb,
			accountNumber: personalLoanDetails.accountNumber,
			accountName: personalLoanDetails.accountName,
			isForeign: personalLoanDetails.isForeign,
			hasArrears: getNullableBoolean(personalLoanDetails.hasArrears),
			arrearsNumberOfMissedPayments: personalLoanDetails.numberOfMissedPayments,
			arrearsAmount: personalLoanDetails.arrearsAmount,
			secured: !!personalLoanDetails.secured,
			interestType: personalLoanDetails.interestType,
			linkedNonRealEstateAssets: personalLoanDetails.linkedAssets
				?.filter((linkedAsset) => linkedAsset.assetType)
				.map((a) => {
					return {
						id: a.assetType.id,
						assetType: parseInt(a.assetType.info as string)
					};
				})
		};
	}

	static fromPayload(
		personalLoan: PersonalLoanDTO,
		applicants: ShortApplicant[],
		formEnumsQuery: FormEnumsQuery
	): PersonalLoanModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			personalLoan?.percentsOwned,
			applicants
		);

		const repaymentAmount = calculateMonthly(
			personalLoan.repaymentAmount,
			repaymentToFrequencyFull(personalLoan.repaymentFrequency!)
		);

		const personalLoanModel: PersonalLoanModel = {
			id: personalLoan.id,
			applicationId: personalLoan.applicationId,
			financialInstitution: personalLoan.financialInstitution,
			summaryAmount: personalLoan.outstandingBalance,
			repaymentAmount,
			amountEntry: {
				amount: personalLoan.repaymentAmount!,
				frequency: personalLoan.repaymentFrequency!
			},
			outstandingBalance: personalLoan.outstandingBalance ?? 0, // [TAMA5-9520] When outstanding balance is null it should 0 as per the requirement
			creditLimit: personalLoan.creditLimit ?? 0, // [TAMA5-9520] When credit limit is null it should 0 as per the requirement
			monthlyRepayment: repaymentAmount,
			details: {
				personalLoanDetails: {
					annualInterestRate: personalLoan.interestRate,
					financialInstitution: personalLoan.financialInstitution,
					loanAmount: personalLoan.originalAmount, // update api model
					outstandingBalance: personalLoan.outstandingBalance,
					creditLimit: personalLoan.creditLimit,
					isEqualShare: personalLoan.percentOwnedProportions === Proportions.Equal,
					percentsOwned: percentageOwned,
					remainingTerm: {
						number: personalLoan.remainingTermDuration!,
						frequency: personalLoan.remainingTermUnits!
					},
					repayments: {
						amount: personalLoan.repaymentAmount!,
						frequency: personalLoan.repaymentFrequency!
					},
					clearingBalanceOnSettlement: getNullableYesNo(personalLoan.closingOnSettlement),
					clearingFromThisLoan: getNullableYesNo(personalLoan.clearingFromThisLoan),
					clearingThisLiability: personalLoan.clearingThisLiability,
					disbursementAmount: personalLoan.refinanceAmount,
					refinanceCosts: personalLoan.refinanceCosts,
					bsb: personalLoan.bsb,
					accountNumber: personalLoan.accountNumber,
					accountName: personalLoan.accountName,
					otherFinancialInstitutionName: personalLoan?.otherFinancialInstitutionName,
					isForeign: personalLoan.isForeign,
					hasArrears: getNullableYesNo(personalLoan.hasArrears),
					numberOfMissedPayments: personalLoan.arrearsNumberOfMissedPayments,
					arrearsAmount: personalLoan.arrearsAmount,
					secured: personalLoan.secured,
					interestType: personalLoan.interestType,
					linkedAssets: personalLoan.linkedNonRealEstateAssets?.map((x) => {
						return {
							assetType: {
								id: x.id
							} as EnumObject
						};
					})
				},
				extract: `FinancialInstitution.${personalLoan.financialInstitution}`
			}
		};
		return personalLoanModel;
	}

	static updateAssetTypeEnum(personalLoans: PersonalLoanModel[], formEnumsQuery: FormEnumsQuery) {
		return personalLoans.map((personalLoan) => {
			const linkedAssets = personalLoan.details?.personalLoanDetails?.linkedAssets || [];

			return {
				...personalLoan,
				details: {
					...personalLoan.details,
					personalLoanDetails: {
						...personalLoan.details?.personalLoanDetails,
						linkedAssets: linkedAssets.map((linkedAsset) => {
							return {
								assetType: formEnumsQuery.getOption('Assets', linkedAsset.assetType.id) as EnumObject
							};
						})
					}
				}
			};
		});
	}
}

export interface PersonalLoanModel {
	financialInstitution: FinancialInstitution;
	applicationId: number;
	id?: number;
	details: { personalLoanDetails: PersonalLoanDetails; extract: string };
	outstandingBalance?: number;
	creditLimit?: number;
	monthlyRepayment?: number;
	// Used in Company & Trust Financial Position
	summaryAmount?: number;
	repaymentAmount?: number;
	amountEntry?: AmountSelect;
}

interface PersonalLoanDetails {
	financialInstitution: FinancialInstitution;
	outstandingBalance: number;
	creditLimit?: number;
	otherFinancialInstitutionName?: string;
	loanAmount: number;
	annualInterestRate: number;
	repayments: AmountSelect;
	percentsOwned?: PercentOwned[];
	remainingTerm: Term;
	accountName?: string;
	bsb?: string;
	accountNumber?: number;
	isEqualShare?: boolean;
	refinanceCosts?: number;
	disbursementAmount?: number;
	clearingBalanceOnSettlement?: YesNo;
	clearingFromThisLoan?: YesNo;
	clearingThisLiability?: ClearingFromThisLoan;
	isForeign: boolean;
	hasArrears?: YesNo;
	numberOfMissedPayments?: number;
	arrearsAmount?: number;
	secured?: boolean;
	interestType?: InterestType;
	linkedAssets?: { assetType: EnumObject }[];
	hideLinkedSecurities?: boolean; //UI purposes only
}
