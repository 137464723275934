import { AdditionalNotesDTO } from '@app/modules/typings/api';

export class AdditionalNotesTransformer {
	static toPayload(applicationId: number, repayments: AdditionalNotesModel): AdditionalNotesDTO {
		return {
			applicationId: applicationId,
			additionalNotes: repayments.isAdditionalNotes ? repayments.additionalNotes : undefined
		};
	}

	static fromPayload(data: AdditionalNotesDTO): AdditionalNotesModel {
		const notes: AdditionalNotesModel = {
			isAdditionalNotes: data.additionalNotes ? true : false,
			additionalNotes: data.additionalNotes!
		};
		return notes;
	}
}

export interface AdditionalNotesModel {
	isAdditionalNotes: boolean;
	additionalNotes: string;
}

export interface AdditionalNotesStoreModel {
	additionalNotesContainer: AdditionalNotesModel;
}
