import { Injectable } from '@angular/core';
import { CompanyApplicantTransformer } from '@app/modules/applicants/models/company-applicant.model';
import { LendingGuaranteeTransformer } from '@app/modules/applicants/models/lending-guarantee.model';
import { TrustApplicantTransformer } from '@app/modules/applicants/models/trust-applicant.model';
import { ApplicantsService } from '@app/modules/applicants/services/applicants.service';
import { CompanyApplicantsService } from '@app/modules/applicants/services/company/company-applicants.service';
import { HouseholdService } from '@app/modules/applicants/services/household.service';
import { TrustApplicantsService } from '@app/modules/applicants/services/trust/trust-applicants.service';
import { LoanServiceabilityService } from '@app/modules/loan-serviceability/loan-serviceability.service';
import { PreCheckModalService } from '@app/modules/pre-check/services/pre-check-modal.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantType, ApplicationPreCheck, ExternalActionStatus } from '@app/modules/shared/enums/app.enums';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormStateService } from '@app/modules/shared/store/form-state/form-state.service';
import { findPropertyFromSchema } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { LendingGuaranteeDTO, PersonApplicantDTO } from '@app/modules/typings/api';
import { IntercomService } from '@simpology/authentication';
import { IntercomInformation } from '@simpology/authentication/lib/simp-authentication/model/intercom.model';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { PersonApplicant } from '../../applicants/typings/applicants';
import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { ApplicationDetailsTransformer } from '../model/application-details.model';
import { LoanWriterCompanyDto } from '../model/loan-writer-company.model';
import { SetupSummaryModel, SetupSummaryModelTransformer } from '../model/setup-summary.model';
import {
	ApplicantExternalActionStatus,
	ApplicationDetails,
	ApplicationDetailsDto,
	ApplicationSaveResult,
	ManageParticipants,
	SetUpApplicant,
	SetupApplication
} from '../typings/setup';
import { DocumentInstructionsService } from './document-instructions.service';
import { ManageParticipantsService } from './manage-participants.service';

@Injectable({ providedIn: 'root' })
export class SetupService extends BaseJourneyService<any> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private applicationDataService: ApplicationDataService,
		private manageParticipantsService: ManageParticipantsService,
		private applicantsService: ApplicantsService,
		private preCheckModalService: PreCheckModalService,
		private householdService: HouseholdService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private aggregateFormatterService: AggregateFormatterService,
		private formEnumQuery: FormEnumsQuery,
		private companyApplicantsService: CompanyApplicantsService,
		private trustApplicantsService: TrustApplicantsService,
		private formDataService: FormDataService,
		private loanServiceabilityService: LoanServiceabilityService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private channelSettingQuery: ChannelSettingQuery,
		private documentInstructionsService: DocumentInstructionsService,
		private intercomService: IntercomService,
		private formStateService: FormStateService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(setupSchema?: FormlyApiProperty) {
		return forkJoin([
			this.getApplication(),
			this.householdService.fetchHouseholds(),
			this.manageParticipantsService.fetchManagedParticipant(true),
			this.fetchSetupApplicants(setupSchema),
			this.loanServiceabilityService.fetchSplitLoans(),
			this.personsCompaniesEnumService.fetchPersons(),
			this.personsCompaniesEnumService.fetchCompanies(),
			this.personsCompaniesEnumService.fetchTrusts(),
			this.documentInstructionsService.fetchDocumentInstructions(),
			this.documentInstructionsService.fetchDocumentDeliveryMethod()
		]).pipe(
			tap(
				([
					applicationData,
					households,
					managedParticipant,
					setupApplicants,
					loanInformation,
					persons,
					companies,
					trusts,
					documents,
					documentDeliveryMethod
				]) => {
					const details = ApplicationDetailsTransformer.fromPayload(applicationData);
					if (details.accreditedLoanWriterId) {
						this.getLoanWriterCompanies(details.accreditedLoanWriterId)
							.pipe(
								tap((loanWriterCompanies) =>
									this.simpFormlyHandlerService.upsertToStateWithData('loanWriterCompanies', loanWriterCompanies)
								)
							)
							.subscribe();
					}
					this.formStateService.updateFormState({
						variations: applicationData.variations || []
					});
					const configurations = this.getConfigurations(applicationData);
					this.householdService.getHouseholds();
					this.simpFormlyHandlerService.upsertToFullPathWithData('details', [details]);
					this.simpFormlyHandlerService.upsertToFullPathWithData('configuration', configurations);
					this.simpFormlyHandlerService.upsertToFullPathWithData('managementParticipants', managedParticipant);
					this.simpFormlyHandlerService.upsertToFullPathWithData('applicants', setupApplicants);
					this.simpFormlyHandlerService.upsertToFullPathWithData('instructions', documents);
					this.simpFormlyHandlerService.upsertToFullPathWithData('documentDeliveryMethod', documentDeliveryMethod);
					this.preCheckModalService.checkForPrecheck(applicationData);
					if (loanInformation) {
						this.applicationDataService.updateNumberOfSplitsLoan(loanInformation.length ?? 0);
					}
					if (this.channelSettingQuery.isIntercomEnabled()) {
						const intercomInformation = this.configureIntercom(details);
						this.intercomService.updateIntercom(intercomInformation);
					}
				}
			)
		);
	}

	/**
	 * TODO, use fetchSetupApplicants instead
	 * @param applicants$
	 */
	fetchApplicants(applicants$: Observable<PersonApplicantDTO[]>): Observable<PersonApplicant[]> {
		return applicants$.pipe(
			map((applicants: PersonApplicantDTO[]) =>
				applicants.map((applicant) => {
					const transformedApplicant = this.applicantsService.transformPersonApplicant(applicant);

					return transformedApplicant;
				})
			)
		);
	}

	getConfigurations(application: ApplicationDetailsDto): Partial<ApplicationDetailsDto>[] {
		const applicationDetails: Partial<ApplicationDetailsDto>[] = [];
		const configurationData = {
			applicationTemplateId: application.applicationTemplateId,
			branding: application.branding,
			mustSendInfoRequestsOnActivation: application.mustSendInfoRequestsOnActivation
		} as Partial<ApplicationDetailsDto>;
		applicationDetails.push(configurationData);
		return applicationDetails;
	}

	getApplication(): Observable<ApplicationDetailsDto> {
		return this.get(`Application/${this.applicationDataQuery.applicationId()}`) as Observable<ApplicationDetailsDto>;
	}

	updateApplication(applicationModel: SetupApplication, applicationId: number): Observable<ApplicationSaveResult> {
		return <Observable<ApplicationSaveResult>>(
			this.patch(`Application`, ApplicationDetailsTransformer.toPayload(applicationModel, applicationId))
		);
	}

	getPreCheckStatus(): ApplicationPreCheck {
		return this.applicationDataQuery.applicationPreCheckStatus();
	}

	checkEligibility(): void {
		this.getApplication().subscribe((res: ApplicationDetailsDto) => {
			this.preCheckModalService.openModal(res);
		});
	}

	getSummaryData(): Observable<SetupSummaryModel> {
		const application$ = this.getApplication();

		return forkJoin({
			applicationDetails: application$.pipe(
				map((application) => ApplicationDetailsTransformer.fromPayload(application))
			),
			managementParticipants: this.manageParticipantsService.fetchManagedParticipant(),
			applicants: this.fetchSetupApplicants()
		}).pipe(
			map((data) => {
				const managementParticipants = data.managementParticipants;
				const allParticipants = {
					applicants: data.applicants,
					managementParticipants
				} as ManageParticipants;
				return SetupSummaryModelTransformer.fromPayload(
					data.applicationDetails,
					allParticipants,
					this.aggregateFormatterService,
					this.formEnumQuery
				);
			})
		);
	}

	fetchSetupApplicants(setupSchema?: FormlyApiProperty): Observable<SetUpApplicant[]> {
		return forkJoin({
			personApplicants: this.applicantsService.fetchPersonApplicants(),
			companyApplicants: this.companyApplicantsService.fetchCompanyApplicants(),
			trustApplicants: this.trustApplicantsService.fetchTrustApplicants()
		}).pipe(
			map((applicants) => {
				const applicantTypeOptions = this.formEnumQuery.getOptions('ApplicantTypePerson');
				let totalApplicants: SetUpApplicant[] = [];
				const personApplicants = applicants.personApplicants.map((applicant) => {
					const setupApplicantModel = SetupSummaryModelTransformer.getSetupApplicantModel(
						applicant,
						this.formDataService.hasApplicantConsentInitiated(applicant.id),
						applicantTypeOptions
					);
					return setupApplicantModel;
				});

				const isPendingApplicants: boolean = personApplicants.some(
					(personApplicant) => personApplicant.externalActionStatus === ExternalActionStatus.Pending
				);

				if (isPendingApplicants) {
					totalApplicants = [];
					this.formDataService.setLoading('applicants', true);
					this.checkActionStatus(setupSchema);
				} else {
					totalApplicants = totalApplicants.concat(...personApplicants);
					this.formDataService.setLoading('applicants', false);
				}

				this.personsCompaniesEnumService.initPersonApplicantsSubTypeEnums(personApplicants);
				const companyApplicants = applicants.companyApplicants.map((applicant) => {
					const transformedApplicant = CompanyApplicantTransformer.fromPayload(
						applicant,
						this.getApplicantSubText(applicant)
					);

					return transformedApplicant;
				});
				totalApplicants = totalApplicants.concat(...companyApplicants);

				const trustApplicants = applicants.trustApplicants.map((applicant) => {
					const transformedApplicant = TrustApplicantTransformer.fromPayload(
						applicant,
						this.getApplicantSubText(applicant)
					);
					return transformedApplicant;
				});

				this.setUpLendingGuaranteeData(totalApplicants);
				return totalApplicants.concat(...trustApplicants);
			})
		);
	}

	setUpLendingGuaranteeData(existingApplicants: SetUpApplicant[]) {
		existingApplicants.forEach((applicant, index) => {
			this.formDataService.upsertStateWithAsyncData(
				`applicants-lendingGuarantee-${index}`,
				applicant.companyName || applicant.trustName ? of([{}]) : this.getLendingGuaranteeDetailsById(applicant.id)
			);
		});
	}

	getLendingGuaranteeDetailsById(applicantId: number): Observable<LendingGuaranteeDTO> {
		return <Observable<LendingGuaranteeDTO>>this.get(`PersonApplicant/GetLendingGuarantee/${applicantId}`).pipe(
			map((guarantee: LendingGuaranteeDTO) => {
				return [LendingGuaranteeTransformer.fromPayload(guarantee)] ?? [{}];
			})
		);
	}

	getLoanWriterCompanies(accreditedLoanWriterId: number): Observable<LoanWriterCompanyDto[]> {
		return <Observable<LoanWriterCompanyDto[]>>(
			this.get(
				`Application/GetLoanWriterCompanies/${this.applicationDataQuery.applicationId()}/${accreditedLoanWriterId}`
			)
		);
	}

	configureIntercom(details: Partial<ApplicationDetails>): IntercomInformation {
		const intercomInformation: IntercomInformation = {
			appId: details.id,
			appTitle: details.reference,
			aggregator: details.aggregatorCompany?.aggregatorCompanyModal.companyName
		};
		return intercomInformation;
	}

	private getApplicantSubText(applicant: SetUpApplicant): string {
		return `${this.formEnumQuery.getOptionLabel('ApplicantType', applicant?.applicantType || ApplicantType.Borrower)} ${
			applicant?.primaryApplicant ? '(primary)' : ''
		}`;
	}

	private fetchApplicantExternalActionStatus(): Observable<ApplicantExternalActionStatus[]> {
		return <Observable<ApplicantExternalActionStatus[]>>(
			this.get(`EntityTarget/ExternalActionStatus/${this.applicationDataQuery.applicationId()}`)
		);
	}

	private checkActionStatus(setupSchema?: FormlyApiProperty) {
		/**
		 *  the timeout used here because to check every 5s applicants pending status is still remaining
		    if all applicants did not have any pending status the fetchSetup call will create applicants
		    record in setup applicants section
		 */
		const checkApplicantsStatusTimeout =
			this.findCheckActionTimeoutDurationFromSchema(setupSchema) ?? CONSTANTS.CHECK_APPLICANTS_STATUS_TIMEOUT;
		setTimeout(() => {
			this.checkApplicantExternalActionStatus(setupSchema);
		}, Number(checkApplicantsStatusTimeout));
	}

	private findCheckActionTimeoutDurationFromSchema(setupSchema?: FormlyApiProperty) {
		const checkoutTimeDuration = findPropertyFromSchema(
			setupSchema,
			'manageParticipants.applicants.checkExternalActionStatusTimeout'
		) as FormlyApiProperty;
		return checkoutTimeDuration?.templateOptions?.['defaultValue'];
	}

	private findCheckActionStatusFailedMessageFromSchema(setupSchema?: FormlyApiProperty) {
		const field = findPropertyFromSchema(
			setupSchema,
			'manageParticipants.applicants.customLabels.checkExternalActionStatusFailedMessage'
		) as FormlyApiProperty;
		return field?.template;
	}

	private checkApplicantExternalActionStatus(setupSchema?: FormlyApiProperty) {
		this.fetchApplicantExternalActionStatus()
			.pipe(
				catchError((error) => {
					const checkActionStatusFailedMessage = this.findCheckActionStatusFailedMessageFromSchema(setupSchema) ?? '';
					this.formDataService.setPlaceholderTemplate('applicants', checkActionStatusFailedMessage);
					throw error;
				}),
				map((statuses: ApplicantExternalActionStatus[]) => {
					const isPending = statuses?.some((status) => status.externalActionStatus === ExternalActionStatus.Pending);
					if (isPending) {
						this.checkActionStatus(setupSchema);
					}
					return isPending;
				}),
				filter((isPending: boolean) => !isPending),
				switchMap(() => {
					return this.fetchSetupApplicants().pipe(
						map((setupApplicants) => {
							this.simpFormlyHandlerService.upsertToFullPathWithData('applicants', setupApplicants);
							return true;
						})
					);
				})
			)
			.subscribe();
	}
}
