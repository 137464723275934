export enum DigitalWidgetsAppType {
	LoanApp = 0,
	Omni = 1
}

export enum DigitalWidgetTriggerType {
	Manual = 0,
	Auto = 1
}

export enum DigitalWidgetTriggerLevel {
	Application = 1,
	Applicant = 2,
	Property = 3
}

export enum DigitalServiceType {
	InformedConsent = 1,
	IdentityVerification = 2,
	CreditCheck = 3,
	AccessSeeker = 4,
	LMI = 5,
	PropertyValuation = 6,
	Documents = 7,
	BankingStatement = 8,
	IdentityDocumentVerification = 9,
	EsignConsent = 10,
	IncomeVerification = 11,
	PropertyTitleSearch = 12,
	RentalIncomeVerification = 13
}

export enum DigitalWidgetSSIServiceType {
	BankingData = 1,
	Webhooks = 2,
	IdentityVerification = 3,
	CreditCheck = 4,
	InternalService = 5,
	AccreditationCheck = 6,
	LoanApplicationValidation = 7,
	AppLaunch = 8,
	AccessSeeker = 9,
	ApplicationLodgement = 10, // 0x0000000A
	DocumentVerification = 11, // 0x0000000B
	PropertyValuation = 12, // 0x0000000C
	Notification = 13, // 0x0000000D
	Serviceability = 14, // 0x0000000E
	PropertyInformation = 15, // 0x0000000F
	ApplicationExport = 16, // 0x00000010
	CompanyLookup = 17, // 0x00000011
	ElectronicSignature = 18, // 0x00000012
	CustomerOnboarding = 19, // 0x00000013
	CustomerInformation = 20, // 0x00000014
	FormGeneration = 21, // 0x00000015
	AppSync = 22, // 0x00000016
	DocumentLodgement = 23, // 0x00000017
	CreditAssessment = 24, // 0x00000018
	CustomerConsent = 25, // 0x00000019
	ReportingEtl = 26, // 0x0000001A
	IdentityDocumentVerification = 27, // 0x0000001B
	AccountInformation = 28, // 0x0000001C
	MortgageInsuranceQuotation = 29, // 0x0000001D
	MortgageInsuranceApplication = 30, // 0x0000001E
	Fingerprint = 31, // 0x0000001F
	CustomerSearch = 32, // 0x00000020
	PropertyTitleSearch = 33 // 0x00000021
}

export enum ConfigurableDigitalServiceStatus {
	Pending = 1000,
	Requested = 1001,
	Completed = 1002,
	Declined = 1003,
	Resubmitted = 1004,
	Cancelled = 1005,
	None = 1006,
	Skipped = 1007,
	ValidationError = 1008,
	Error = 1009,
	Quoted = 1010,
	Ready = 1011,
	AutoSkipped = 1012,
	Expired = 1013
}

export enum ConfigurableDigitalServiceHeaderStatus {
	Pending = 1000,
	Requested = 1001,
	Completed = 1002,
	Failed = 1003,
	Skipped = 1004,
	Quoted = 1005,
	Ready = 1006, // This does not exist from API,
	Declined = 1007, // This does not exist from API
	Error = 1008 // This does not exist from API
}

export enum ConfigurableDigitalWidgetDetailsTypes {
	Text = 0,
	Overall = 1,
	Header = 2
}

export enum DigitalServiceActionTypes {
	Remind = 1,
	Obsolete = 2, // this action is now obsolete on the backend DO NOT USE
	Details = 3,
	Documents = 4,
	Resubmit = 5,
	Revoke = 6,
	Skip = 7,
	Apply = 8,
	Request = 9,
	DeleteRequest = 11
}

export enum BankingFlowType {
	DefaultFlow = 1,
	BrokerFlow = 2
}

export interface BankingDigitalServiceResponse {
	clearPreviouslyRetrievedBankingData: boolean;
	documentSessionId?: string;
	bankingFlowTypeEnum: BankingFlowType;
}

export const ItemStatusToHeaderStatus = new Map<
	ConfigurableDigitalServiceStatus,
	ConfigurableDigitalServiceHeaderStatus
>([
	[ConfigurableDigitalServiceStatus.Pending, ConfigurableDigitalServiceHeaderStatus.Pending],
	[ConfigurableDigitalServiceStatus.Requested, ConfigurableDigitalServiceHeaderStatus.Requested],
	[ConfigurableDigitalServiceStatus.Completed, ConfigurableDigitalServiceHeaderStatus.Completed],
	[ConfigurableDigitalServiceStatus.Declined, ConfigurableDigitalServiceHeaderStatus.Declined],
	[ConfigurableDigitalServiceStatus.Resubmitted, ConfigurableDigitalServiceHeaderStatus.Requested],
	[ConfigurableDigitalServiceStatus.Cancelled, ConfigurableDigitalServiceHeaderStatus.Ready],
	[ConfigurableDigitalServiceStatus.None, ConfigurableDigitalServiceHeaderStatus.Completed],
	[ConfigurableDigitalServiceStatus.Skipped, ConfigurableDigitalServiceHeaderStatus.Skipped],
	[ConfigurableDigitalServiceStatus.Error, ConfigurableDigitalServiceHeaderStatus.Error],
	[ConfigurableDigitalServiceStatus.Ready, ConfigurableDigitalServiceHeaderStatus.Ready],
	[ConfigurableDigitalServiceStatus.ValidationError, ConfigurableDigitalServiceHeaderStatus.Ready],
	[ConfigurableDigitalServiceStatus.Expired, ConfigurableDigitalServiceHeaderStatus.Ready]
]);
