import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { InputActionType } from '@app/modules/shared/enums/app.enums';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { PhoneDetails } from '@simpology/client-components';
import { isEmpty, isNil } from 'lodash-es';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-tel-input',
	templateUrl: './formly-tel-input.component.html',
	styleUrls: ['./formly-tel-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTelInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';
	countryCodes: string[] = [];
	private destroy$ = new Subject<void>();

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);

		// Rebuild tel input when field groups are available but no form controls have been created.
		// Example field groups: phoneNumber/areaCode/countryCode/nationalNumber/dialCode
		if (
			this.field?.fieldGroup?.length &&
			isEmpty((this.formControl as unknown as FormGroup).controls) &&
			this.field?.options?.build
		) {
			this.field?.options?.build(this.field);
		}

		this.countryCodes = this.to.countries
			? (this.to.countries as string).split(',').map((country) => country.trim())
			: [];

		if (!isNil(this.to.defaultValue) && this.isNoNumber(this.formControl.value)) {
			this.formControl.patchValue(JSON.parse(this.to.defaultValue));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}

	handleEdit(): void {
		if (this.to.click) {
			this.to.click(this.field, InputActionType.EDIT);
		}
	}

	private isNoNumber(value: PhoneDetails) {
		return !value.countryCode && !value.phoneNumber;
	}
}
