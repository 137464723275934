import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

import { RefiLogoutComponent } from './layouts/refi/components/refi-logout/refi-logout.component';
import { AuthCallbackComponent } from './modules/authentication/auth-callback/auth-callback.component';
import { ExternalLoginComponent } from './modules/authentication/external-login/external-login.component';
import { DeniedComponent } from './modules/other/components/denied/denied.component';
import { CONSTANTS } from './modules/shared/constants/constants';
import { JourneyType } from './modules/shared/enums/app.enums';
import { AnonymousAuthGuard } from './modules/shared/guards/anonymous-auth.guard';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { FeatureFlagGuard } from './modules/shared/guards/feature-flag.guard';
import { NotFoundGuard } from './modules/shared/guards/not-found.guard';

const routes: Routes = [
	{ path: 'auth-callback', component: AuthCallbackComponent },
	{ path: 'externalLogin', component: ExternalLoginComponent },
	{ path: 'externalLogin/:token/:applicationKey', component: ExternalLoginComponent },
	{ path: CONSTANTS.REFI_LOGOUT_PATH, component: RefiLogoutComponent },
	{ path: CONSTANTS.PURCHASE_LOGOUT_PATH, component: RefiLogoutComponent },
	{
		path: 'access',
		outlet: 'primary',
		loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
	},
	{ path: 'accreditation-check', component: DeniedComponent },
	{
		path: '',
		loadChildren: () => import('./layouts/loanapp-client/loanapp-client.module').then((m) => m.LoanappClientModule),
		canActivate: [AuthGuard],
		data: {
			journeyType: JourneyType.FullApplication,
			bodyClass: 'desktop-only'
		}
	},
	{
		path: CONSTANTS.AIP_LANDING_PATH,
		loadChildren: () => import('./layouts/loanapp-x/loanapp-x.module').then((m) => m.LoanappXModule),
		canActivate: [AnonymousAuthGuard, FeatureFlagGuard],
		data: {
			journeyType: JourneyType.ApprovalInPrinciple
		}
	},
	{
		path: CONSTANTS.REFI_PATH,
		loadChildren: () => import('./layouts/refi/refi.module').then((m) => m.RefiModule),
		canActivate: [AuthGuard, FeatureFlagGuard],
		data: {
			journeyType: JourneyType.Refi
		}
	},
	{
		path: CONSTANTS.PURCHASE_PATH,
		loadChildren: () => import('./layouts/refi/refi.module').then((m) => m.RefiModule),
		canActivate: [AuthGuard, FeatureFlagGuard],
		data: {
			journeyType: JourneyType.Purchase
		}
	},
	{
		path: 'internal',
		loadChildren: () => import('./layouts/internal-refi/internal-refi.module').then((m) => m.InternalRefiModule)
	},
	{
		path: 'feature-flag',
		loadChildren: () => import('./layouts/feature-flag/feature-flag.module').then((m) => m.FeatureFlagModule),
		canActivate: [AuthGuard]
	},
	{ path: CONSTANTS.INVALID_URL, component: NotFoundComponent },
	{ path: '**', component: NotFoundComponent, canActivate: [NotFoundGuard] },
	{
		path: '**',
		redirectTo: CONSTANTS.LOANAPP_CLIENT_LANDING_URL,
		data: {
			journeyType: JourneyType.FullApplication
		}
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false,
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			preloadingStrategy: NoPreloading
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
