import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { formlyRegisterHooks } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { PreCheckApiService } from '../service/pre-check-api.service';
import { FormEnumsService } from '../store/form-enums/form-enums.service';

@Injectable({ providedIn: 'root' })
export class PreCheckTransformerService {
	constructor(private preCheckApiService: PreCheckApiService, private formEnumsService: FormEnumsService) {}

	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] {
		this.formEnumsService.fetch('PrecheckPropertyType', true).subscribe();

		formlyRegisterHooks(formFields[0].fieldGroup, '1', {
			onInit: (field) => {
				const formControl = field?.formControl as UntypedFormControl;
				const valueChanges = combineLatest([
					formControl.get('postCode')?.valueChanges as Observable<string>,
					formControl.get('typeOfProperty')?.valueChanges as Observable<number>
				]);
				return valueChanges.pipe(
					filter(() => formControl.valid && formControl.dirty),
					switchMap((value) => {
						return this.preCheckApiService.getEligibility(value[0], value[1]).pipe(
							tap((result) => {
								if (field?.fieldGroup?.length && field.fieldGroup[2].templateOptions) {
									field.fieldGroup[2].template = `Great! We currently lend in this area to a maximum LVR of ${result.eligibleLVR}%`;
									const lvrField = get(field, 'parent.fieldGroup[1].fieldGroup[2]') as FormlyFieldConfig;
									lvrField.template = `LVR in this application is ${result.eligibleLVR}% or less`;
								}
								if (result.isEligible) {
									formControl.get('yesNo')?.setValue(1);
									formControl.get('lvr')?.setValue(result.eligibleLVR);
									formControl.get('postCode')?.setErrors(null);
								} else {
									formControl.get('yesNo')?.setValue(0);
									formControl.get('postCode')?.setErrors({ invalid: true });
								}
							})
						);
					})
				);
			}
		});
		return formFields;
	}
}
