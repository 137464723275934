import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { JourneyType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { CurrentUserQuery } from '@app/modules/shared/store/current-user/current-user.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get, isArray } from 'lodash-es';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { EligibilityModel, RefiEligibilityTransformer } from '../../models/refi-eligibility.model';
import { RefiConfirmDetailsService } from '../confirm-details/refi-confirm-details.service';
import { RefiLiabilitiesService } from '../liabilities/refi-liabilities.service';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiEligibilityService } from './refi-eligibility.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyEligibilityTransformerService {
	public valuationPending = false;
	public lmiPending = false;
	public stampDutyPending = false;

	constructor(
		private refiModalService: RefiModalService,
		private refiJourneyService: RefiJourneyService,
		private sharedFlagService: SharedFlagsService,
		private eligibilityApiService: RefiEligibilityService,
		private applicationDataQuery: ApplicationDataQuery,
		private stepStatusQuery: StepStatusQuery,
		private journeyStepService: JourneyStepService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private currentUserQuery: CurrentUserQuery,
		private confirmDetailsService: RefiConfirmDetailsService,
		private refiLiabilitiesService: RefiLiabilitiesService,
		@Inject(DOCUMENT) private document: Document,
		private formEnumQuery: FormEnumsQuery
	) {}

	public eligibilityTransform(formFields: FormlyFieldConfig[]) {
		const titleField = getFormField(formFields, `eligibility.details`) as FormlyFieldConfig;
		const label = titleField?.templateOptions?.label as string;
		const numberOfApplicants = this.applicationDataQuery.getRefiApplicants().length;

		formlyExtendExpressionProperties(formFields, `eligibility.details`, {
			'templateOptions.label': () => {
				return this.refiJourneyService.isAdminUser
					? 'Eligibility check'
					: label.replace('ApplicantFirstName', this.currentUserQuery.firstName());
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details', {
			afterViewInit: (field) => {
				if (field && field.templateOptions && field.templateOptions?.subText && this.refiJourneyService.isAdminUser) {
					field.templateOptions.subText = '';
				}
			}
		});

		formlyOnClick(formFields, 'eligibility.details.numberOfApplicants', (field) => {
			this.refiModalService.attachEventsToOpenModal(field); // update t&c links for single/co-borrower flows
		});

		formlyRegisterHooks(formFields, 'eligibility.details.loanForConstruction', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					setTimeout(() => {
						this.refiModalService.attachEventsToOpenModal(field);
					}, 200);

					const model = field?.parent?.model as EligibilityModel;
					field.templateOptions.readonly =
						model.readonly ||
						numberOfApplicants > 1 ||
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiApplicantConsent) === StepStatus.Complete;
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details.incomeSource', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const model = field?.parent?.model as EligibilityModel;
					field.templateOptions.readonly =
						model.readonly ||
						numberOfApplicants > 1 ||
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiApplicantConsent) === StepStatus.Complete;
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details.guarantorOnLoan', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const model = field?.parent?.model as EligibilityModel;
					field.templateOptions.readonly =
						model.readonly ||
						numberOfApplicants > 1 ||
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiApplicantConsent) === StepStatus.Complete;
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details.numberOfApplicants', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const model = field?.parent?.model as EligibilityModel;
					field.templateOptions.readonly =
						model.readonly ||
						numberOfApplicants > 1 ||
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiApplicantConsent) === StepStatus.Complete;
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details', {
			onInit: (field) => {
				const hasAtLeastOneApplicantConsented = this.applicationDataQuery
					.getRefiApplicants()
					.some((applicant) => !!applicant.privacyActConsentSigned);
				if (
					field &&
					field.templateOptions &&
					(this.refiJourneyService.isAdminUser || hasAtLeastOneApplicantConsented)
				) {
					field.templateOptions.primaryButtonText =
						this.refiJourneyService.isAdminUser &&
						this.stepStatusQuery.getStepStatus(RefiStepType.RefiApplicantConsent) !== StepStatus.Complete
							? ''
							: 'Continue';
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'eligibility.details.foundProperty', {
			onInit: (field) => {
				if (field && field.props?.options) {
					const listItems = cloneDeep(this.formEnumQuery.getOptions('YesNo'));
					field.props.options = listItems.map((options) => {
						options.label = options.id === 0 ? options.label.concat(', still looking') : options.label;
						return options;
					});
				}
				return of();
			}
		});

		formlyOnClick(
			formFields,
			'eligibility.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				if (clickType.type === 'primary' && this.refiJourneyService.isAdminUser) {
					this.refiJourneyService.navigateToStep('loanRequirements/details');
					return;
				}

				if (clickType.type === 'primary' && !clickType.invalid) {
					const model = (get(field, 'model') as EligibilityModel[])[0];
					const existingLoan = model.existingLoan;
					if (existingLoan) {
						this.openRefiModal(formFields, RefiModalName.ineligibleToApply);
						return;
					}

					const modelToSave = RefiEligibilityTransformer.toPayload(model, this.applicationDataQuery.applicationId());
					if (field.templateOptions) {
						field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
					}
					this.refiJourneyService.customerInfoLoaded$
						.pipe(
							switchMap((customerInfoResult) => {
								if (customerInfoResult.status) {
									return !model.isEligible ? this.eligibilityApiService.saveEligibility(modelToSave) : of(true);
								} else {
									throw new Error('Customer info failed');
								}
							}),
							map((res) => {
								const currentApplicant = this.applicationDataQuery
									.getRefiApplicants()
									.find((applicant) => applicant.isCurrentUser);
								if (res && currentApplicant) {
									this.confirmDetailsService.consentToPrivacy(currentApplicant.id).subscribe((result) => {
										if (result.status) {
											this.refiJourneyService.getApplicants().subscribe(() => {
												this.journeyStepService
													.updateStep(RefiStepType.RefiApplicantConsent, StepStatus.Complete)
													.subscribe();
											});
										} else {
											this.refiJourneyService.displayErrorScreen('updating consent returned false');
										}
									});
								}
								return res;
							})
						)
						.subscribe({
							next: (res) => {
								model.isEligible = res;
								this.simpFormlyHandlerService.updateToState('details', model, 0);

								if (res) {
									this.openRefiModal(formFields, RefiModalName.eligibleToApply).subscribe(() => {
										this.journeyStepService.updateStep(RefiStepType.RefiEligibility, StepStatus.Complete).subscribe();
										this.navigateToNextScreen();
									});
								} else {
									this.openRefiModal(formFields, RefiModalName.ineligibleToApply).subscribe(() => {
										setTimeout(() => {
											this.document.getElementById('primary-button')?.focus();
										}, 200);
									});
								}

								if (field.templateOptions) {
									field.templateOptions.loading = field.templateOptions.disablePrimaryButton = false;
								}
							},
							error: () => {
								this.refiJourneyService.displayErrorScreen('Save eligibility failed');
							}
						});
				}
			}
		);

		formlyRegisterHooks(formFields, 'eligibility.details.existingLoan', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(formFields, 'eligibility.details.existingLoan', (field, clickType: { type: string }) => {
			const existingLoan = (field.model as EligibilityModel).existingLoan;
			if (clickType?.type === 'tooltipClick' || (clickType?.type === undefined && existingLoan)) {
				if (!this.refiModalService.isModalOpen(RefiModalName.existingLoan)) {
					this.openRefiModal(formFields, RefiModalName.existingLoan).subscribe();
				}
			}
		});
	}

	private openRefiModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): Observable<string> {
		const refiModalField = getFormField(formFields, 'eligibility.refi-modals');
		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}

	// Need to update to call CCR
	private navigateToNextScreen(): void {
		const applicant = this.applicationDataQuery.getRefiApplicants().find((app) => app.isCurrentUser);
		if (applicant) {
			this.refiJourneyService
				.initiateFEA(applicant.id)
				.pipe(
					switchMap((res) => {
						if (res.status) {
							return this.refiLiabilitiesService.fetchHomeLoans();
						} else {
							return of(false);
						}
					})
				)
				.subscribe((res) => {
					if (
						isArray(res) &&
						res.filter((loan) => !loan.homeLoansModal.readonly).length > 0 &&
						this.sharedFlagService.currentJourney === JourneyType.Refi
					) {
						this.refiJourneyService.navigateToStep('chooseLoan/details');
					} else if (res === false) {
						this.refiJourneyService.displayErrorScreen('FEA/Appsync returned false');
					} else if (this.sharedFlagService.currentJourney === JourneyType.Purchase) {
						// Update these steps that don't exist for purchase as complete
						forkJoin([
							this.journeyStepService.updateStep(RefiStepType.RefiChooseLoan, StepStatus.Complete),
							this.journeyStepService.updateStep(RefiStepType.RefiLoanDetails, StepStatus.Complete)
						]).subscribe();

						this.refiJourneyService.navigateToStep('propertyDetails/details');
					} else {
						this.refiJourneyService.navigateToStep('loanDetails/details');
					}
				});
		}
	}
}
