import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { EmploymentModel } from '@app/modules/financial-position/model/employment.model';
import { HomeLoanModel } from '@app/modules/financial-position/model/homeloan.model';
import { CombinedLinkedAssets, LinkedAssetTransformer } from '@app/modules/financial-position/model/linked-asset.model';
import { OtherIncomeModel, OtherIncomeTransformer } from '@app/modules/financial-position/model/otherincome.model';
import { AccessSeekerService } from '@app/modules/financial-position/services/access-seeker.service';
import { AccessSeekerLiabilitiesModel } from '@app/modules/shared/model/access-seeker.model';
import { LiabilitiesHeaderTransformer } from '@app/modules/shared/model/header-summary.model';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import {
	CreditCardDTO,
	OtherAssetDTO,
	OtherIncomeDTO,
	OtherLiabilityDTO,
	PersonalLoanDTO,
	SavingsDTO
} from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { Observable, catchError, forkJoin, iif, map, of, switchMap, take } from 'rxjs';
import { AssetTypeEnum, LiabilityTypeEnum } from '../enums/app.enums';
import { savedSuccessfullyMessage } from '../helper/util';
import { ShortApplicant } from '../model/applicant.model';
import { CreditCardModel, LiabilityCreditCardTransformer } from '../model/creditcard.model';
import { OtherAssetModel, OtherAssetTransformer } from '../model/otherasset.model';
import { OtherLiabilitiesTransformer, OtherLiabilityModel } from '../model/otherliabilites.model';
import { LiabilityPersonalLoanTransformer, PersonalLoanModel } from '../model/personalloan.model';
import { AssetSavingModelTransformer, SavingModel } from '../model/saving.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { FormEnumsStore } from '../store/form-enums/form-enums.store';
import { BaseJourneyService } from './base-journey.service';
import { PercentOwnedService } from './percent-owned.service';

@Injectable({ providedIn: 'root' })
export class AssetsAndLiabilitiesService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsSerevice: FormEnumsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private percentOwnedService: PercentOwnedService,
		private formEnumsStore: FormEnumsStore,
		private accessSeekerService: AccessSeekerService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(statePrefix: string) {
		return of([]);
	}

	fetchOtherAssets(
		applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants()
	): Observable<OtherAssetModel[]> {
		return this.getCustom(`OtherAsset/${this.applicationDataQuery.applicationId()}`).pipe(
			map((assets: OtherAssetDTO[]) => {
				return assets.map((otherasset) =>
					OtherAssetTransformer.fromPayload(otherasset, applicants, this.formEnumsQuery)
				);
			})
		);
	}

	fetchSavings(applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants()): Observable<SavingModel[]> {
		return this.getCustom(`Savings/${this.applicationDataQuery.applicationId()}`).pipe(
			map((savings: SavingsDTO[]) => {
				return savings.map((saving) =>
					AssetSavingModelTransformer.fromPayload(saving, applicants, this.formEnumsQuery)
				);
			})
		);
	}

	fetchAccessSeeker(): Observable<AccessSeekerLiabilitiesModel[]> {
		return this.accessSeekerService.getAccessSeekerDetails().pipe(
			map((data) => {
				const liabilities = data.accessSeekerApplicants
					.flatMap((applicant) => {
						return applicant?.consumerCreditFile?.accounts.flatMap((account) => {
							return {
								accountType: account?.accountType,
								latestCreditProviderName: account?.latestCreditProviderName,
								applicantName: `${applicant?.firstName} ${applicant?.lastName}`,
								creditLimit: account?.latestMaximumAmountOfCreditAmount,
								accountId: account?.accountId,
								accountOpenedDate: account?.accountOpenDate,
								term: account?.termOfLoan
							} as AccessSeekerLiabilitiesModel;
						});
					})
					.filter((item) => !!item);
				return liabilities ?? [];
			})
		);
	}

	fetchOtherIncome(): Observable<OtherIncomeModel[]> {
		return this.getCustom(`OtherIncome/${this.applicationDataQuery.applicationId()}`).pipe(
			map((incomes: OtherIncomeDTO[]) => {
				const otherIncomes = incomes.map((otherIncome) =>
					OtherIncomeTransformer.fromPayload(otherIncome, this.applicationDataQuery.getPersonShortApplicants())
				);
				this.upsertIncomeSectionSubHeader(otherIncomes, 'otherIncome');
				return otherIncomes;
			})
		);
	}

	upsertIncomeSectionSubHeader(models: EmploymentModel[] | OtherIncomeModel[], subsectionKey: string) {
		let totalNetAmount = 0;
		let totalGrossAmount = 0;
		models.forEach((data) => {
			totalNetAmount += data.netAmount ?? 0;
			totalGrossAmount += data.grossAmount ?? 0;
		});

		if (totalNetAmount > 0 || totalGrossAmount > 0 || models.length > 0) {
			const header = { totalNetAmount: totalNetAmount, totalGrossAmount: totalGrossAmount };
			this.simpFormlyHandlerService.upsertToSubSectionHeader(subsectionKey, header);
		}
	}

	fetchCreditCards(): Observable<CreditCardModel[]> {
		return this.getCustom(`CreditCard/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loans: CreditCardDTO[]) => {
				const creditCardDetails = loans.map((loan) => LiabilityCreditCardTransformer.fromPayload(loan));
				this.upsertLiabilitiesSubSectionbHeaders(creditCardDetails, 'creditCards');
				return creditCardDetails;
			})
		);
	}

	fetchPersonalLoans(): Observable<PersonalLoanModel[]> {
		return this.getCustom(`PersonalLoan/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loans: PersonalLoanDTO[]) => {
				const personalLoans = loans.map((loan) =>
					LiabilityPersonalLoanTransformer.fromPayload(
						loan,
						this.applicationDataQuery.getApplicants(),
						this.formEnumsQuery
					)
				);
				this.upsertLiabilitiesSubSectionbHeaders(personalLoans, 'personalLoans');
				return personalLoans;
			})
		);
	}

	fetchOtherLiabilities(): Observable<OtherLiabilityModel[]> {
		return this.getCustom(`OtherLiability/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loans: OtherLiabilityDTO[]) => {
				const otherLiabilities = loans.map((loan) =>
					OtherLiabilitiesTransformer.fromPayload(loan, this.formEnumsQuery, this.applicationDataQuery.getApplicants())
				);
				this.upsertLiabilitiesSubSectionbHeaders(otherLiabilities, 'otherLiabilities');
				return otherLiabilities;
			})
		);
	}

	upsertLiabilitiesSubSectionbHeaders(
		models: CreditCardModel[] | PersonalLoanModel[] | OtherLiabilityModel[] | HomeLoanModel[],
		subsectionKey: string
	) {
		const header = LiabilitiesHeaderTransformer.transformHeader(models);
		this.simpFormlyHandlerService.upsertToSubSectionHeader(subsectionKey, header);
	}

	saveOtherAsset(index: number, otherAsset: OtherAssetModel): Observable<number> {
		const payload = OtherAssetTransformer.toPayload(this.applicationDataQuery.applicationId(), otherAsset);

		return this.postCustom(`OtherAsset`, payload).pipe(
			map((otherAssetId: number) => {
				payload.id = otherAssetId;
				const otherAssetModel = OtherAssetTransformer.fromPayload(
					payload,
					this.applicationDataQuery.getApplicants(),
					this.formEnumsQuery
				);
				this.simpFormlyHandlerService.updateToState('otherAssets', otherAssetModel, index);

				this.toastr.success(savedSuccessfullyMessage('Other asset'));
				this.updateAssetsLiabilities();
				return otherAssetId;
			})
		);
	}

	saveSavings(index: number, savingData: SavingModel): Observable<number> {
		const payload = AssetSavingModelTransformer.toPayload(this.applicationDataQuery.applicationId(), savingData);
		return this.postCustom(`Savings`, payload).pipe(
			map((savingId: number) => {
				payload.id = savingId;
				savingData = AssetSavingModelTransformer.fromPayload(
					payload,
					this.applicationDataQuery.getApplicants(),
					this.formEnumsQuery
				);
				this.simpFormlyHandlerService.updateToState('savings', savingData, index);
				this.fetchLinkedAssetEnumsForOtherIncome();
				this.toastr.success(savedSuccessfullyMessage('Savings'));
				this.updateAssetsLiabilities();
				return savingId;
			})
		);
	}

	fetchLinkedAssetEnumsForOtherIncome(): void {
		this.getCustom(`Savings/${this.applicationDataQuery.applicationId()}`)
			.pipe(
				map((savings: SavingsDTO[]) => {
					return LinkedAssetTransformer.fromPayload(savings, this.formEnumsQuery);
				})
			)
			.subscribe((linkedAssets: CombinedLinkedAssets) => {
				this.formEnumsStore.update({ DividendLinkedAsset: linkedAssets.dividendLinkedAssets });
				this.formEnumsStore.update({ InterestLinkedAsset: linkedAssets.InterestIncomeLinkedAssets });
			});
	}

	savePersonalLoan(index: number, loanData: PersonalLoanModel): Observable<any> {
		const payload = LiabilityPersonalLoanTransformer.toPayload(this.applicationDataQuery.applicationId(), loanData);

		return this.postCustom(`PersonalLoan`, payload).pipe(
			map((loanId: number) => {
				payload.id = loanId;
				loanData = LiabilityPersonalLoanTransformer.fromPayload(
					payload,
					this.applicationDataQuery.getApplicants(),
					this.formEnumsQuery
				);

				this.simpFormlyHandlerService.updateToState('personalLoans', loanData, index);

				this.toastr.success(savedSuccessfullyMessage('Personal loan'));

				const personalLoanData = this.simpFormlyHandlerService.getStateData<CreditCardModel>('personalLoans');
				this.upsertLiabilitiesSubSectionbHeaders(personalLoanData, 'personalLoans');
				this.updateAssetsLiabilities();
				return loanId;
			})
		);
	}

	saveOtherLiability(index: number, loanData: OtherLiabilityModel): Observable<any> {
		const payload = OtherLiabilitiesTransformer.toPayload(this.applicationDataQuery.applicationId(), loanData);

		return this.postCustom(`OtherLiability`, payload).pipe(
			map((loanId: number) => {
				payload.id = loanId;
				loanData = OtherLiabilitiesTransformer.fromPayload(
					payload,
					this.formEnumsQuery,
					this.applicationDataQuery.getApplicants()
				);
				this.simpFormlyHandlerService.updateToState('otherLiabilities', loanData, index);

				this.toastr.success(savedSuccessfullyMessage('Other liability'));

				const otherLiabilitiesData = this.simpFormlyHandlerService.getStateData<CreditCardModel>('otherLiabilities');
				this.upsertLiabilitiesSubSectionbHeaders(otherLiabilitiesData, 'otherLiabilities');
				this.updateAssetsLiabilities();

				return loanId;
			})
		);
	}

	saveCreditCard(index: number, creditCardData: CreditCardModel): Observable<any> {
		const payload = LiabilityCreditCardTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			creditCardData,
			this.applicationDataQuery.getApplicants()
		);

		return this.postCustom(`CreditCard`, payload).pipe(
			map((loanId: number) => {
				payload.id = loanId;
				creditCardData = LiabilityCreditCardTransformer.fromPayload(payload);
				this.simpFormlyHandlerService.updateToState('creditCards', creditCardData, index);
				this.toastr.success(savedSuccessfullyMessage('Credit card'));

				const creditCardDetails = this.simpFormlyHandlerService.getStateData<CreditCardModel>('creditCards');
				this.upsertLiabilitiesSubSectionbHeaders(creditCardDetails, 'creditCards');

				return loanId;
			})
		);
	}

	setupCreditCardLiability = (field: FormlyFieldConfig): void => {
		const model = Object.assign({}, field.parent?.model, field.form?.value) as CreditCardModel;

		// Preset owner if only one applicant
		const creditCardDetailsField = getFormField(field.fieldGroup, 'creditCardDetails');
		const ownershipField = getFormField(creditCardDetailsField?.fieldGroup, 'ownershipId');
		const applicants = this.applicationDataQuery.getApplicants();
		if (ownershipField && applicants?.length === 1) {
			model.details.creditCardDetails.ownershipId = applicants[0].id;
			ownershipField?.formControl?.setValue(model.details.creditCardDetails.ownershipId);
		}
		const modalRef = this.simpFormlyModalService.openModal(field, 'creditCardDetails', { backdrop: 'static' });

		modalRef.action
			.pipe(
				take(1),
				switchMap((action) => {
					const key = Number(field.parent?.key);

					const uiModel = field.form?.value as CreditCardModel; // NOTE: uiModel only has data fields that are not hidden
					const dtoModel = field.parent?.model as CreditCardModel;

					dtoModel.details.creditCardDetails.clearingBalanceOnSettlement =
						uiModel.details.creditCardDetails.clearingBalanceOnSettlement;
					dtoModel.details.creditCardDetails.clearingFromThisLoan =
						uiModel.details.creditCardDetails.clearingFromThisLoan;
					dtoModel.details.creditCardDetails.clearingThisLiability =
						uiModel.details.creditCardDetails.clearingThisLiability;

					const creditCardModel = Object.assign({}, dtoModel, uiModel);

					return iif(
						() => action === 'submit',
						this.saveCreditCard(key, creditCardModel).pipe(catchError(() => of('error'))),
						of(action)
					);
				})
			)
			.subscribe((res) => {
				if (res !== 'error') {
					modalRef.close();
				}
			});
	};

	updatePersonalLoanEnum(model: PersonalLoanModel[]): EnumObject[] {
		if (model.length === 0) {
			this.formEnumsStore.update({ PersonalLoan: [] });
			return [];
		}
		const enums: EnumObject[] = [];
		model.forEach((personalLoan) => {
			const label = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				personalLoan.details.personalLoanDetails.financialInstitution
			);
			enums.push({
				id: personalLoan.id as number,
				label: `${label}  ${formatCurrency(personalLoan.summaryAmount as number, 'en-AU', '$')}`,
				info: LiabilityTypeEnum.PersonalLoan.toString()
			});
		});

		this.formEnumsStore.update({ PersonalLoan: enums });
		return enums;
	}

	updateOtherLiabilityEnum(model: OtherLiabilityModel[]): EnumObject[] {
		if (model.length === 0) {
			this.formEnumsStore.update({ OtherLiability: [] });
			return [];
		}
		const enums: EnumObject[] = [];
		model.forEach((otherLiability) => {
			const liabilityTypeLabel = this.formEnumsQuery.getOptionLabel(
				'LiabilityType',
				otherLiability.details.otherLiabilityDetails.type as number
			);
			const financialInstitutionName = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				otherLiability.details.otherLiabilityDetails.financialInstitution as number
			);
			const label = `${financialInstitutionName} ${liabilityTypeLabel}`?.trim();
			enums.push({
				id: otherLiability.id as number,
				label: `${label}  ${formatCurrency(otherLiability.summaryAmount as number, 'en-AU', '$')}`,
				info: LiabilityTypeEnum.OtherLiability.toString()
			});
		});

		this.formEnumsStore.update({ OtherLiability: enums });
		return enums;
	}

	updateSavingEnum(model: SavingModel[]): EnumObject[] {
		if (model.length === 0) {
			this.formEnumsStore.update({ Saving: [] });
			return [];
		}
		const enums: EnumObject[] = [];
		model.forEach((saving) => {
			const assetTypeLabel = this.formEnumsQuery.getOptionLabel(
				'FinancialAssetType',
				saving.details.savingDetails.financialAssetType as number
			);
			const financialInstitutionName = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				saving.details.savingDetails.financialInstitution as number
			);
			const label = `${financialInstitutionName} ${assetTypeLabel}`?.trim();
			enums.push({
				id: saving.id as number,
				label: `${label} ${formatCurrency(saving.summaryAmount as number, 'en-AU', '$')}`,
				info: AssetTypeEnum.Savings.toString()
			});
		});

		this.formEnumsStore.update({ Saving: enums });
		return enums;
	}

	updateOtherAssetEnum(model: OtherAssetModel[]): EnumObject[] {
		if (model.length === 0) {
			this.formEnumsStore.update({ OtherAsset: [] });
			return [];
		}
		const enums: EnumObject[] = [];
		model.forEach((otherAsset) => {
			const label = this.formEnumsQuery.getOptionLabel(
				'NonRealEstateAssetType',
				otherAsset.details.otherAssetDetails.assetType as number
			);
			enums.push({
				id: otherAsset.id as number,
				label: `${label}, ${formatCurrency(otherAsset.summaryAmount as number, 'en-AU', '$')}`,
				info: AssetTypeEnum.OtherAsset.toString()
			});
		});

		this.formEnumsStore.update({ OtherAsset: enums });
		return enums;
	}

	updateAssetsLiabilities(updateSubsectionStore = true) {
		forkJoin([
			this.fetchSavings().pipe(catchError((err) => [])),
			this.fetchOtherAssets().pipe(catchError((err) => [])),
			this.fetchPersonalLoans().pipe(catchError((err) => [])),
			this.fetchOtherLiabilities().pipe(catchError((err) => []))
		]).subscribe(([savings, otherAssets, personalLoans, otherLiabilities]) => {
			this.createEnumsFromAssetsAndLiabilities(savings, otherAssets, personalLoans, otherLiabilities);

			const updatedSavings = AssetSavingModelTransformer.updateLiabilityTypeEnum(savings, this.formEnumsQuery);
			const updatedOtherAssets = OtherAssetTransformer.updateLiabilityTypeEnum(otherAssets, this.formEnumsQuery);
			const updatedPersonalLoans = LiabilityPersonalLoanTransformer.updateAssetTypeEnum(
				personalLoans,
				this.formEnumsQuery
			);
			const updatedOtherLiabilities = OtherLiabilitiesTransformer.updateAssetTypeEnum(
				otherLiabilities,
				this.formEnumsQuery
			);

			if (updateSubsectionStore) {
				this.simpFormlyHandlerService.upsertToStateWithData('savings', updatedSavings);
				this.simpFormlyHandlerService.upsertToStateWithData('otherAssets', updatedOtherAssets);
				this.simpFormlyHandlerService.upsertToStateWithData('personalLoans', updatedPersonalLoans);
				this.simpFormlyHandlerService.upsertToStateWithData('otherLiabilities', updatedOtherLiabilities);
			}
		});
	}

	private createEnumsFromAssetsAndLiabilities(
		savings: SavingModel[],
		otherAssets: OtherAssetModel[],
		personalLoans: PersonalLoanModel[],
		otherLiabilities: OtherLiabilityModel[]
	) {
		const savingEnum = this.updateSavingEnum(savings);
		const otherAssetEnum = this.updateOtherAssetEnum(otherAssets);
		const personalLoanEnum = this.updatePersonalLoanEnum(personalLoans);
		const otherLiabilityEnum = this.updateOtherLiabilityEnum(otherLiabilities);
		const addNewAssetOption = this.addNewAssetsEnum();
		const addNewLiabilityOption = this.addNewLiabilityEnum();
		this.formEnumsSerevice.updateFormEnums('Assets', [...addNewAssetOption, ...savingEnum, ...otherAssetEnum]);
		this.formEnumsSerevice.updateFormEnums('Liabilities', [
			...addNewLiabilityOption,
			...personalLoanEnum,
			...otherLiabilityEnum
		]);
	}

	private addNewAssetsEnum(): EnumObject[] {
		return [
			{
				id: -1,
				label: `+ Add new asset`
			}
		];
	}

	private addNewLiabilityEnum(): EnumObject[] {
		return [
			{
				id: -1,
				label: `+ Add new liability`
			}
		];
	}
}
