import { Injectable } from '@angular/core';
import { HouseholdInfo } from '@app/modules/applicants/typings/household';
import { ApplicationDetails, SetUpApplicant } from '@app/modules/setup/typings/setup';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PersonApplicantDTO } from '@app/modules/typings/api';
import { LoanappApplication } from '@app/modules/typings/loanapp-application';
import { cloneDeep, get, isEqual } from 'lodash-es';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { SimpProgressBarStatus } from '@simpology/client-components';
import { combineLatest } from 'rxjs';
import { CONSTANTS } from '../../constants/constants';
import { ApplicantEntityType, ApplicantKeyType, ApplicationPreCheck } from '../../enums/app.enums';
import { JourneyConfig, LoanApplication } from '../../model/loan-application.model';
import { FormAreaPath } from '../../typings/form-areas.types';
import { FormDataService } from '../form-data/form-data.service';
import { SharedFlagsService } from '../shared-flags/shared-flags.service';
import { ApplicationDataStore } from './application-data.store';
import {
	ApplicationDataState,
	LoanAppAreaNavigation,
	NavigationStep,
	SideNavItem,
	SideNavItems
} from './typings/application-data';

@Injectable({ providedIn: 'root' })
export class ApplicationDataQuery {
	selectSubNavItems$ = this.store.select((state: ApplicationDataState) => state.sideNavItems);
	currentArea: FormAreaPath | undefined = undefined;

	selectNavigationSteps$ = this.store
		.select((state) => state.navigationSteps)
		.pipe(map((steps: NavigationStep[]) => steps.filter((step) => step.stepId !== undefined)));

	/**
	 * Query the state for invalid forms for side nav
	 */
	selectInvalidForms$ = this.selectSubNavItems$.pipe(
		map((sideNavItems: SideNavItems) => cloneDeep(sideNavItems)),
		map((sideNavItems) => {
			const invalidItems: SideNavItem[] = [];
			sideNavItems.fullApplication.forEach((item) => {
				if (item.url === FormAreaPath.summaryLodgement) {
					return;
				}

				if (item.children?.length) {
					item.children
						.filter((child) => child.formValid !== true)
						.forEach((child) => {
							child.icon = item.icon;
							invalidItems.push(child);
						});
				} else if (item.formValid !== true) {
					invalidItems.push(item);
				}
			});
			return invalidItems;
		})
	);

	/**
	 * Distinct applicationId/journeyId change event triggers only when it's ready for Preload.
	 * It's ready for preload when all applications are fetched and application has JourneyId assigned
	 */
	selectApplication$ = combineLatest([
		this.store.select((state: ApplicationDataState) => state.loanappApplication?.applicationId),
		this.store
			.select((state: ApplicationDataState) => state.readyForPreload)
			.pipe(
				filter((readyForPreload) => readyForPreload === true),
				take(1)
			),
		this.store.select((state: ApplicationDataState) => state.loanappApplication?.journeyId)
	]).pipe(
		filter(([applicationId, readyForPreload, journeyId]: [number, boolean, number]) => !!journeyId),
		map(([applicationId, readyForPreload, journeyId]: [number, boolean, number]) => ({ applicationId, journeyId })),
		distinctUntilChanged((a, b) => isEqual(a, b)),
		map((res) => res.applicationId)
	);

	selectApplicationModel$ = this.store
		.select((state: ApplicationDataState) => state.loanappApplication)
		.pipe(
			filter((app: LoanappApplication) => app && app.applicationId !== CONSTANTS.NEW_ID),
			distinctUntilChanged((a: LoanappApplication, b: LoanappApplication) => isEqual(a.applicationId, b.applicationId))
		);

	preCheckStatus$ = this.store
		.select((state: ApplicationDataState) => state.loanappApplication)
		.pipe(
			map((application: LoanappApplication | undefined) => application?.preCheckStatus ?? ApplicationPreCheck.Unknown)
		);

	showRemoteValidationErrors$ = this.store
		.select((state: ApplicationDataState) => state.showRemoteValidationErrors)
		.pipe(map((showRemoteValidationErrors: boolean | undefined) => !!showRemoteValidationErrors));

	selectJourneyId$ = this.store.select((state: ApplicationDataState) => state.loanappApplication?.journeyId);

	constructor(
		protected store: ApplicationDataStore,
		private formDataService: FormDataService,
		private sharedFlagsService: SharedFlagsService
	) {}

	hasNavigationSteps(): boolean {
		return this.store.getValue().navigationSteps.length > 0;
	}

	hasAnyIncompleteStep(): boolean {
		return this.store.getValue().navigationSteps.some((step) => step.stepStatus !== SimpProgressBarStatus.Complete);
	}

	getNavigationStep(index: number): NavigationStep {
		const filteredSteps = this.store.getValue().navigationSteps.filter((step) => step.stepId);
		return filteredSteps[index];
	}

	getLoanApplications(): LoanApplication[] | undefined {
		return this.store.getValue().loanApplications;
	}

	getApplication(applicationId: number): LoanApplication | undefined {
		return this.store.getValue().loanApplications?.find((application) => application.id === applicationId);
	}

	getSelectedApplication(): LoanApplication | undefined {
		return this.getApplication(this.applicationId());
	}

	getApplicationJourneyId(): number {
		const storeValue = this.store.getValue();
		const loanApplication = this.getSelectedApplication();
		const journeyId = storeValue.loanappApplication?.journeyId;
		return loanApplication?.journeyId || journeyId || 0;
	}

	getAllNavigationSteps(): NavigationStep[] {
		return this.store.getValue().navigationSteps;
	}

	getNumberOfSplitsLoan(): number {
		return this.store.getValue().loanappApplication?.noOfSplitsLoan ?? 0;
	}

	getPersonShortApplicants(): ShortApplicant[] {
		const applicants = this.formDataService.getSetupApplicants().filter((x) => !!x.firstName || !!x.lastName);
		return applicants.map(this.mapApplicants.bind(this)) ?? [];
	}

	getCompanyApplicants(): ShortApplicant[] {
		const applicants = this.formDataService.getSetupApplicants().filter((x) => !!x.companyName);
		return applicants.map(this.mapApplicants.bind(this)) ?? [];
	}

	getTrustApplicants(): ShortApplicant[] {
		const applicants = this.formDataService.getSetupApplicants().filter((x) => !!x.trustName);
		return applicants.map(this.mapApplicants.bind(this)) ?? [];
	}

	getRefiApplicants(): PersonApplicantDTO[] {
		return this.formDataService.getRefiPersonApplicants();
	}

	getRefiApplicantNames(): String[] {
		return this.formDataService
			.getRefiPersonApplicants()
			.filter((applicant) => applicant.firstName)
			.map((applicant) => `${applicant.firstName} ${applicant.lastName}`);
	}

	getHouseholds(): HouseholdInfo[] {
		return this.formDataService.getHouseholds();
	}

	getHouseHoldAndApplicants() {
		return this.store.getValue().houseHoldAndApplicants ?? [];
	}

	getApplicationDetails(): ApplicationDetails {
		return this.formDataService.getStateData<ApplicationDetails>('details')[0];
	}

	getApplicants(applicantKeyType?: ApplicantKeyType): ShortApplicant[] {
		switch (applicantKeyType) {
			case ApplicantKeyType.PersonApplicant:
				return this.getPersonShortApplicants();
			case ApplicantKeyType.CompanyApplicant:
				return this.getCompanyApplicants();
			case ApplicantKeyType.TrustApplicant:
				return this.getTrustApplicants();
			default:
				return this.formDataService.getSetupApplicants().map(this.mapApplicants.bind(this));
		}
	}

	loanApplication = () => this.store.getValue().loanappApplication;

	applicationId = (): number => {
		const applicationState = this.store.getValue().loanappApplication;
		return applicationState?.applicationId ?? CONSTANTS.NEW_ID;
	};

	applicationInEligible = (): boolean => {
		const preCheckStatus = this.applicationPreCheckStatus();
		return preCheckStatus === ApplicationPreCheck.Ineligible;
	};

	applicationHardStopMessage = (): string | null => {
		const applicationState = this.store.getValue().loanappApplication;
		return applicationState?.hardStopMessage ?? null;
	};

	applicationPreCheckStatus = (): ApplicationPreCheck => {
		const applicationState = this.store.getValue().loanappApplication;
		return applicationState?.preCheckStatus ?? ApplicationPreCheck.Unknown;
	};

	getAreaNavigation = (currentArea: FormAreaPath): LoanAppAreaNavigation => {
		this.currentArea = currentArea;
		const sideNavItems = cloneDeep(this.store.getValue().sideNavItems);
		const flattened: SideNavItem[] = [];
		sideNavItems.fullApplication = this.filterForJourney(sideNavItems.fullApplication);

		sideNavItems?.fullApplication.forEach((item) => {
			if (item.children?.length) {
				item.children.forEach((child) => {
					if (!flattened.find((existing) => existing.url === child.url)) {
						flattened.push(child);
					}
				});
			} else if (!flattened.find((existing) => existing.url === item.url)) {
				flattened.push(item);
			}
		});

		const index = flattened.findIndex((s) => s.url === currentArea);
		return {
			previous: flattened[index - 1],
			current: flattened[index],
			next: flattened[index + 1]
		};
	};

	filterForJourney(items: SideNavItem[]): SideNavItem[] {
		const journeyType = this.sharedFlagsService.currentJourney;
		if (!journeyType) {
			return items;
		}
		const schemaList = this.store.getValue().journeyConfig as JourneyConfig[];
		const schemaNames = schemaList
			.filter((schema: JourneyConfig) => schema.journey === journeyType)
			.map((s) => s.name.toLowerCase());
		return items.filter((i) => i.url && schemaNames.includes(i.url.toLowerCase().replace('-', '') ?? ''));
	}

	getCompanyApplicantsSideNavInfo = (index: number): SideNavItem => {
		const parentRouteInfo = this.store
			.getValue()
			.sideNavItems.fullApplication.find((s) => s.url === FormAreaPath.applicants);
		index = (parentRouteInfo?.children?.filter((c) => c.url === FormAreaPath.applicants).length || 0) + index;
		return get(parentRouteInfo, `children[${index}]`) as unknown as SideNavItem;
	};

	isCurrentUserPrimaryApplicant(): boolean {
		return this.getRefiApplicants().find((user) => user.isCurrentUser)?.primaryApplicant as boolean;
	}

	getRefiOpportunityId(): string | undefined {
		const storeValue = this.store.getValue();
		return storeValue.refiOpportunityId;
	}

	private mapApplicants(applicant: SetUpApplicant): ShortApplicant {
		return {
			isPrimary: applicant.primaryApplicant,
			name:
				applicant.companyName ||
				applicant.trustName ||
				`${applicant.firstName || ''} ${applicant.lastName || ''}`.trim(),
			dateOfBirth: applicant.dateOfBirth,
			id: applicant.id,
			securePersonId: applicant.securePersonId,
			householdId: applicant.householdId ?? undefined,
			applicantEntityType: applicant.companyName
				? ApplicantEntityType.CompanyApplicant
				: applicant.trustName
				? ApplicantEntityType.TrustApplicant
				: ApplicantEntityType.PersonApplicant,
			applicantType: applicant.applicantType,
			applicantRole: applicant.applicantRole,
			foreignTaxAssociationId: applicant.foreignTaxAssociationId,
			title: applicant.title
		};
	}
}
