import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-number-select-input',
	templateUrl: './formly-number-select-input.component.html',
	styleUrls: ['./formly-number-select-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyNumberSelectComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';

	private destroy$ = new Subject<void>();

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (this.to.defaultNumber) {
			this.formControl.get('number')?.setValue(this.to.defaultNumber);
		}

		if (this.to.defaultFrequency) {
			this.formControl.get('frequency')?.setValue(this.to.defaultFrequency);
		}

		this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.to.required) {
				this.formControl.get('number')?.setValidators(Validators.required);
				this.formControl.get('frequency')?.setValidators(Validators.required);
			} else {
				this.formControl.get('number')?.removeValidators(Validators.required);
				this.formControl.get('frequency')?.removeValidators(Validators.required);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
