import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { isEqual } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'formly-array',
	templateUrl: './formly-array.component.html'
})
export class FormlyArrayComponent extends FieldArrayType implements OnInit, AfterViewInit, OnDestroy {
	private valueChangeSubject: Subject<any> = new Subject();
	private destroy$: Subject<void> = new Subject();

	ngOnInit(): void {
		this.to.remove = this.remove.bind(this);
		this.to.add = this.add.bind(this);
		this.field.resetOnHide = (this.field.templateOptions?.resetOnHide as boolean) ?? false;
		/**
		 * Watch for changes in each control inside sub section
		 */
		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;

		let modelLength = (this.model as unknown[])?.length;
		const formControlsLength = this.formControl.controls?.length;
		const maxLengthArray = this.props.maxLength;
		if (modelLength && maxLengthArray && modelLength > maxLengthArray) {
			modelLength = maxLengthArray;
		} // TAMA5-20677 a special case where the model length is greater than the  maxLengthArray of array, array is static so cannot be deleted and modal cannot be saved at the same time

		if (modelLength !== formControlsLength) {
			const noOfDifItems = modelLength - formControlsLength;
			if (noOfDifItems > 0) {
				for (let index = 0; index < noOfDifItems; index++) {
					this.add(0);
					this.remove(0);
				}
			} else {
				for (let index = 0; index < -noOfDifItems; index++) {
					this.remove(0);
				}
			}
		}
	}

	ngAfterViewInit(): void {
		let subscriptions: Subscription[] = [];
		this.formControl.valueChanges
			.pipe(
				startWith(this.formControl.value),
				takeUntil(this.destroy$),
				distinctUntilChanged((a: unknown[], b: unknown[]) => a?.length === b?.length)
			)
			.subscribe(() => {
				subscriptions.forEach((s) => {
					s.unsubscribe();
				});
				subscriptions = this.setupChangeDetection();
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setupChangeDetection() {
		const debounceTimeValue = isNaN(this.props.debounceTime as number) ? 1000 : Number(this.props.debounceTime);
		return (this.formControl.controls || []).map((control, index) => {
			return control.valueChanges
				.pipe(
					debounceTime(debounceTimeValue),
					distinctUntilChanged((a: unknown, b: unknown) => isEqual(a, b)),
					takeUntil(this.destroy$),
					filter(() => control.valid && control.dirty)
				)
				.subscribe((data: unknown) => {
					this.valueChangeSubject.next({ data, index });
				});
		});
	}
}
