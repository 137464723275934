import { RbaLendingPurpose } from './../../shared/enums/app.enums';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { getApplicantRole } from '@app/modules/shared/helper/util';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { PersonApplicant, PersonApplicantDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { ApplicationDetails, ManageParticipants, SetUpApplicant } from '../typings/setup';

export class SetupSummaryModelTransformer {
	static fromPayload(
		applicationDetails: Partial<ApplicationDetails>,
		participants: ManageParticipants,
		formatter: AggregateFormatterService,
		formEnumsQuery: FormEnumsQuery
	): SetupSummaryModel {
		const applicationInfo: SetupSummaryApplicationDetailsModel = {
			amount: formatter.formatAmount(applicationDetails?.amount?.toString()),
			targetDate: formatter.formatDate(applicationDetails.targetDate),
			applicationDate: formatter.formatDate(applicationDetails.applicationDate),
			authorizationLevel: formEnumsQuery.getOptionLabel(
				'AuthorisationLevel',
				applicationDetails?.authorisationLevelId!
			),
			reason: applicationDetails?.primaryLendingPurpose!,
			lenderApplicationNumber: applicationDetails.lenderApplicationNumber!,
			title: applicationDetails.title!,
			urgent: applicationDetails.isUrgent! ? 'Yes' : 'No',
			bsb: applicationDetails.bsb!,
			bankBranchId: applicationDetails.bankBranchId!,
			linkedApplications:
				applicationDetails.linkedApplicationDetailRecords!.length === 0 ||
				applicationDetails.linkedApplicationDetailRecords === null
					? 'No'
					: applicationDetails.linkedApplicationDetailRecords!.map((a) => a.brokerApplicationReferenceNumber).join(', ')
		};

		const managementParticipantInfo = {} as any;

		participants.managementParticipants.map((participant, index) => {
			managementParticipantInfo[`participantType${index}`] = formEnumsQuery.getOptionLabel(
				'ManagementPersonType',
				participant.participantType
			);
			managementParticipantInfo[`fullName${index}`] = participant.fullName;
			managementParticipantInfo[`currentCoordinator${index}`] = formatter.formatCheckbox(
				participant.isCurrentCoordinator
			);
			managementParticipantInfo[`receiveInfoRequests${index}`] = formatter.formatCheckbox(
				participant.receiveInfoRequests
			);
		});

		const applicantsInfo = {} as any;
		let applicantSummary: ApplicantInfo = { applicantInfo: [] };

		participants.applicants.map((applicant, index) => {
			applicantsInfo[`applicantType${index}`] = formEnumsQuery.getOptionLabel(
				'ApplicantTypePerson',
				applicant.applicantRole!
			);
			applicantsInfo[`name${index}`] = applicant.companyName
				? applicant.companyName
				: applicant.trustName
				? applicant.trustName
				: `${applicant.firstName} ${applicant.lastName}`;
			applicantsInfo[`participantType${index}`] = applicant.companyName
				? 'Company'
				: applicant.trustName
				? 'Trust'
				: 'Person';
			applicantsInfo[`receiveInfoRequests${index}`] = formatter.formatCheckbox(
				applicant?.receivesAllNotification ?? false
			);

			const applicantData = {
				applicantType: formEnumsQuery.getOptionLabel('ApplicantTypePerson', applicant.applicantRole!),
				name: applicant.companyName
					? applicant.companyName
					: applicant.trustName
					? applicant.trustName
					: `${applicant.firstName} ${applicant.lastName}`,
				participantType: applicant.companyName ? 'Company' : applicant.trustName ? 'Trust' : 'Person',
				receiveInfoRequests: formatter.formatCheckbox(applicant?.receivesAllNotification ?? false)
			};
			applicantSummary?.applicantInfo?.push(applicantData);
		});

		applicantSummary = {
			...applicantSummary,
			primaryLendingPurpose: applicationInfo.reason,
			amount: applicationInfo.amount
		};

		return {
			application: [
				{
					application: applicationInfo
				}
			],
			managementParticipants: [
				{
					managementParticipants: managementParticipantInfo
				}
			],
			applicants: [
				{
					applicants: applicantsInfo
				}
			],
			applicantSummary
		} as SetupSummaryModel;
	}

	static getSetupApplicantModel(
		model: SetUpApplicant | PersonApplicantDTO | PersonApplicant,
		consentInitiated: boolean,
		applicantTypeOptions: EnumObject[]
	): SetUpApplicant {
		return {
			applicantTypeModal: {
				type: ApplicantEntityType.PersonApplicant
			},
			firstName: model.firstName,
			id: model.id!,
			dateOfBirth: model.dateOfBirth,
			securePersonId: model.securePersonId,
			clientId: model.clientId,
			lastName: model.lastName,
			primaryApplicant: model.primaryApplicant!,
			receivesAllNotification: model.receivesAllNotification,
			consentInitiated: consentInitiated,
			applicantType: model.applicantType,
			applicantRole: getApplicantRole(
				model.primaryApplicant!,
				model.applicantType!,
				model.lendingGuaranteeType?.lendingGuaranteeTypeId,
				applicantTypeOptions
			),
			householdId: model.householdId,
			lendingGuaranteeType: model.lendingGuaranteeType,
			foreignTaxAssociationId: model.foreignTaxAssociationId,
			title: model.title,
			externalAction: model.externalAction,
			externalActionStatus: model.externalActionStatus
		};
	}
}

export interface SetupSummaryModel {
	application: any[];
	managementParticipants: any[];
	applicants: any[];
	applicantSummary?: ApplicantInfo;
}

export interface ApplicantInfo {
	applicantInfo?: {
		applicantType: string;
		name: string;
		participantType: string;
		receiveInfoRequests: string;
	}[];
	primaryLendingPurpose?: RbaLendingPurpose;
	amount?: string | undefined;
}

interface SetupSummaryApplicationDetailsModel {
	lenderApplicationNumber: string;
	title: string;
	amount: string;
	authorizationLevel: string;
	reason: RbaLendingPurpose;
	applicationDate: string;
	targetDate: string;
	urgent: string;
	bsb: string;
	bankBranchId: number;
	linkedApplications: string;
}
