import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyFileImage = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'file-image',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || '')} custom-field key-${property.key}`,
			template: property.template,
			hideExpression: parseExpressionString(property.hide),
			templateOptions: {
				properties: childProperties, // for content projected components
				...property.templateOptions,
				...{
					required: false
				}
			},
			validation: {
				messages: property.errorMessages || {}
			},
			expressionProperties: parseExpressionProperties(property),
			defaultValue: property.templateOptions?.defaultValue
		}
	}
});
