import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FeeType, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { FeeDTO } from './../../typings/api.d';

export interface FeeDetailModel {
	feeId?: number;
	feeType: FeeType;
	productFeeCodeId?: number;
	productFeeTypeId?: number;
	feeCode?: string;
	description?: string;
	feeAmount?: AmountSelect;
	isPaid?: YesNo;
	isCapitalise?: boolean;
	paymentTiming?: number;
	payableTo?: string;
	payableBy?: number;
	payfeeFrom?: number;
	linkProduct?: number;
	linkProductVariationId?: number;
}

export interface FeeListSectionModel {
	id?: number;
	value: AmountSelect;
	details: { feePopup: FeeDetailModel; extract: string };
}

export class FeeListSectionTransformer {
	static fromPayload(feeDto: FeeDTO): FeeListSectionModel {
		return {
			id: feeDto.id,
			value: {
				amount: feeDto.amount,
				frequency: feeDto.frequency
			} as AmountSelect,
			details: {
				feePopup: {
					feeId: feeDto.id,
					feeType: feeDto.type,
					productFeeCodeId: feeDto.productFeeCodeId,
					productFeeTypeId: feeDto.productFeeTypeId,
					feeCode: feeDto.feeCode,
					description: feeDto.description,
					feeAmount: {
						amount: feeDto.amount,
						frequency: feeDto.frequency
					},
					isPaid: getNullableYesNo(feeDto.feePaid),
					isCapitalise: feeDto.capitaliseFee,
					paymentTiming: feeDto.paymentTiming,
					payableTo: feeDto.payableTo,
					payableBy: feeDto.feePayableBy,
					payfeeFrom: feeDto.payFeesFrom,
					linkProduct: feeDto.loanDetailsId,
					linkProductVariationId: feeDto.productVariationId
				},
				extract: this.setFeesDetailsExtract(feeDto)
			}
		} as FeeListSectionModel;
	}

	static toPayload(feeListSectionModel: FeeListSectionModel): FeeDTO {
		const fee = feeListSectionModel?.details?.feePopup;
		return {
			id: feeListSectionModel.id ?? CONSTANTS.NEW_ID,
			type: fee.feeType,
			feeCode: fee.feeCode,
			productFeeCodeId: fee.productFeeCodeId,
			productFeeTypeId: fee.productFeeTypeId,
			amount: fee.feeAmount?.amount,
			description: fee.description,
			payableTo: fee.payableTo,
			frequency: fee.feeAmount?.frequency,
			paymentTiming: fee.paymentTiming,
			capitaliseFee: fee.isCapitalise,
			feePaid: !!fee.isPaid,
			payFeesFrom: fee.payfeeFrom,
			feePayableBy: fee.payableBy,
			productVariationId: fee.linkProductVariationId,
			loanDetailsId: fee.linkProduct
		} as FeeDTO;
	}

	private static setFeesDetailsExtract(feeDto: FeeDTO): string {
		const capitaliseOrNot = feeDto.capitaliseFee ? '(Capitalised)' : '(Not capitalised)';
		const description = feeDto.feeCode === 'Other' ? `${feeDto.description}` : feeDto.feeCode;
		return `${description} ${capitaliseOrNot}`;
	}
}
