import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../constants/constants';
import { ActiveSessionService } from '../service/active-session.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	private requestTimestamps: Map<string, number> = new Map();

	constructor(
		private authService: AuthService,
		private activeSessionService: ActiveSessionService,
		private toastrService: ToastrService
	) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// only check for duplicates in non-production environments
		if (environment.environmentName !== 'prod') {
			this.checkForDuplicateRequest(request);
		}

		// add authorization header with jwt token if available
		return this.authService.getAuthorizationHeaderValue().pipe(
			switchMap((token) => {
				if (token) {
					const headers = { Authorization: `${token}`, formInstanceId: '' };
					headers.formInstanceId = sessionStorage.getItem(CONSTANTS.FORM_INSTANCE_ID) || '';
					request = request.clone({
						url: request.url,
						setHeaders: headers
					});
				}

				return next.handle(request).pipe(
					catchError((error) => {
						const errorResponse = error as unknown as HttpErrorResponse;
						const errorMessage = (errorResponse.error as APIResponse)?.Message;
						if (errorMessage === 'SESSION_CONFLICT_01' && errorResponse.status === HttpStatusCode.Conflict) {
							this.activeSessionService.checkIfUserCanEdit();
						}

						return throwError(() => errorResponse);
					})
				);
			})
		);
	}
	/**
	 * Check for duplicate requests within 5 seconds
	 * @param request
	 */
	private checkForDuplicateRequest(request: HttpRequest<unknown>): void {
		const requestKey = this.getRequestKey(request);
		const currentTime = Date.now();
		const lastRequestTime = this.requestTimestamps.get(requestKey);

		if (lastRequestTime && currentTime - lastRequestTime < 5000) {
			const error = `Duplicate API call detected for ${request.url} within 5 seconds.`;
			console.error(error);
			// only show toast in local environment
			if (environment.environmentName === 'local' && request.method !== 'POST') {
				this.toastrService.error(error);
			}
		}

		this.requestTimestamps.set(requestKey, currentTime);
	}

	private getRequestKey(request: HttpRequest<unknown>): string {
		// Create a unique key for each request based on URL and method
		return `${request.method} ${request.urlWithParams}`;
	}
}

interface APIResponse {
	ErrorCode: number;
	Message: string;
	StackTrace: string;
}
