import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComplianceGuarantorService } from '@app/modules/compliance/services/compliance-guarantor.service';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { ApplicationTemplateDocumentsService } from '@app/modules/summary-lodgement/services/application-template-documents.service';
import { LoanappApplication } from '@app/modules/typings/loanapp-application';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { cloneDeep, isEqual } from 'lodash-es';
import { Observable, forkJoin, from, of } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { CONSTANTS } from '../../constants/constants';
import { ApplicantEntityType, ApplicantRole, ApplicationPreCheck } from '../../enums/app.enums';
import { JourneyConfig, LoanApplication } from '../../model/loan-application.model';
import { TextOption } from '../../model/text-option';
import { AreaValidation, AreaValidationService } from '../../service/area-validation.service';
import { SideNavService } from '../../service/sidenav.service';
import {
	FormAreaLabel,
	FormAreaPath,
	FormAreas,
	JourneyArea,
	loanServiceabilityChildren,
	serviceabilityScenariosChildren,
	setupChildren,
	summaryLodgementChildren
} from '../../typings/form-areas.types';
import { ChannelSettingQuery } from '../channel-setting/channel-setting.query';
import { ExpensesStore } from '../expenses/expenses.store';
import { FormAddressesStore } from '../form-addresses/form-addresses.store';
import { FormDataService } from '../form-data/form-data.service';
import { FormEnumsService } from '../form-enums/form-enums.service';
import { FormMetaDataStore } from '../form-metadata/form-metadata.store';
import { FormStateQuery } from '../form-state/form-state.query';
import { FormStateService } from '../form-state/form-state.service';
import { FormValidationsService } from '../form-validations/form-validations.service';
import { ApplicationDataQuery } from './application-data.query';
import {
	ApplicationDataStore,
	fullAppBorrowerInterviewSideNavItem,
	fullAppGuarantorInterviewSideNavItem
} from './application-data.store';
import { ApplicationDataState, NavigationStep, SideNavItem } from './typings/application-data';

@Injectable({ providedIn: 'root' })
export class ApplicationDataService {
	constructor(
		private router: Router,
		private applicationDataStore: ApplicationDataStore,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formAddressesStore: FormAddressesStore,
		private formEnumsService: FormEnumsService,
		private formValidationsService: FormValidationsService,
		private formDataService: FormDataService,
		private channelSettingQuery: ChannelSettingQuery,
		private areaValidationService: AreaValidationService,
		private expensesStore: ExpensesStore,
		private guarantorService: ComplianceGuarantorService,
		private formMetaDataStore: FormMetaDataStore,
		private applicationDataQuery: ApplicationDataQuery,
		private formStateQuery: FormStateQuery,
		private applicationTemplateDocumentsService: ApplicationTemplateDocumentsService,
		private sideNavService: SideNavService,
		private formStateService: FormStateService
	) {
		this.updatePreCheckStatusFlag(ApplicationPreCheck.Unknown);
	}

	handleApplicationChange(applicationId: number, newApplication = false): Observable<boolean> {
		const application = this.applicationDataQuery.getApplication(applicationId);
		this.updateLoanApplication({
			applicationId,
			newApplication,
			readyForPreload: true,
			journeyId: application?.journeyId
		});
		// redirect to setup page.
		return from(this.router.navigateByUrl(FormAreaPath.setup, { state: { redirectedFromAppChange: true } }));
	}

	observeApplicantsChange(): void {
		this.simpFormlyHandlerService
			.mapToFullStateDataPath<SetUpApplicant[]>('applicants')
			?.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
			.subscribe((applicants) => {
				this.formEnumsService.syncApplicantsEnum(applicants);
				if (applicants.length) {
					this.syncSideNav();
				}
			});
	}

	updateLoanApplications(loanApplications: LoanApplication[]) {
		this.applicationDataStore.update((draft) => {
			draft.loanApplications = cloneDeep(loanApplications);
			return draft;
		});
	}

	updateLoanApplication(loanappApplication: Partial<LoanappApplication>, resetStore = true): void {
		if (resetStore) {
			this.resetStore();
		}

		this.applicationDataStore.update((draft) => {
			if (draft.loanappApplication) {
				Object.assign(draft.loanappApplication, loanappApplication);
			} else {
				draft.loanappApplication = loanappApplication;
			}
			draft.readyForPreload = loanappApplication.readyForPreload;
			return draft;
		});
	}

	updatePreCheckStatusFlag(preCheckStatus: ApplicationPreCheck): void {
		this.applicationDataStore.update((draft) => {
			if (draft.loanappApplication) {
				draft.loanappApplication.preCheckStatus = preCheckStatus;
			}
			return draft;
		});
	}

	updateHardStopMessage(hardStopMessage?: string): void {
		this.applicationDataStore.update((draft) => {
			if (draft.loanappApplication) {
				draft.loanappApplication.hardStopMessage = hardStopMessage;
			}
			return draft;
		});
	}

	updateNumberOfSplitsLoan(numberOfSplitsLoan: number): void {
		this.applicationDataStore.update((draft) => {
			if (draft.loanappApplication) {
				draft.loanappApplication.noOfSplitsLoan = numberOfSplitsLoan;
			}
			return draft;
		});
	}

	updateHouseHoldAndApplicantEnum(options: TextOption[]): void {
		this.applicationDataStore.update((draft) => {
			draft.houseHoldAndApplicants = options;
			return draft;
		});
	}

	updatePreCheckStatus(status: ApplicationPreCheck): void {
		this.updatePreCheckStatusFlag(status);
		this.formStateService.updateFormState({ preCheckStatusFlag: status });
	}

	updateRefiOpportunityId(id: string): void {
		this.applicationDataStore.update((draft) => {
			draft.refiOpportunityId = id;
			return draft;
		});
	}
	getHouseHoldAndApplicationsEnum(): TextOption[] {
		// Only show households if channel setting true
		return (
			this.applicationDataStore
				.getValue()
				.houseHoldAndApplicants?.filter((option) =>
					this.channelSettingQuery.getUseRelationshipForSOP() ? option.value.includes('||False') : true
				) ?? []
		);
	}

	updateShowRemoteValidationErrors(val: boolean): void {
		this.applicationDataStore.update((draft) => {
			draft.showRemoteValidationErrors = val;
			return draft;
		});
	}

	setSideNavActive(url: string) {
		const sideNavItems = cloneDeep(this.applicationDataStore.getValue().sideNavItems);

		sideNavItems.fullApplication.forEach((item) => {
			if (item.url === url) {
				item.active = true;
				item.expanded = true;
			} else {
				item.active = false;
				item.expanded = false;
			}
			(item.children || []).forEach((child) => {
				if (child.url === url) {
					item.active = true;
					child.active = true;
					item.expanded = true;
				} else {
					item.expanded = false;
					child.active = false;
				}
			});
		});
		this.applicationDataStore.update((draft) => {
			draft.sideNavItems = sideNavItems;
			return draft;
		});
	}

	/**
	 * Set the form status on side nav as valid, invalid or undefined, for FullApplication
	 * @param url
	 * @param formValid
	 */
	setSideNavStatus(url: string, formValid: boolean) {
		const sideNavItems = cloneDeep(this.applicationDataStore.getValue().sideNavItems);
		const applicantId = this.formDataService.getSetupApplicants()[0]?.id;
		const applicantEntityType = this.formDataService.getSetupApplicants()[0]?.applicantTypeModal?.type;
		sideNavItems.fullApplication.forEach((item) => {
			if (item.url === url) {
				item.formValid = formValid;
				item.applicantId = applicantId;
				item.applicantEntityType = applicantEntityType;
				if (!item.children?.length) {
					this.areaValidationService.setAreaValidation(item);
				}
			}
			(item.children || []).forEach((child) => {
				if (child.url === url) {
					child.formValid = formValid;
					if (!child.applicantId) {
						child.applicantId = applicantId;
					}
					this.areaValidationService.setAreaValidation(child);
				}
			});
			if (item.children?.length) {
				if (item.children.some((child) => child.formValid === false)) {
					item.formValid = false;
				} else if (item.children.some((child) => child.formValid === undefined)) {
					item.formValid = undefined;
				} else {
					item.formValid = true;
				}
			}
		});
		this.applicationDataStore.update((draft) => {
			draft.sideNavItems = sideNavItems;
			return draft;
		});
	}

	fetchSideNavItems(): Observable<SideNavItem[]> {
		const journeyId = this.applicationDataQuery.getApplicationJourneyId();
		if (journeyId) {
			return this.sideNavService.getSideNavItems(journeyId).pipe(
				tap((data) => {
					data = data.filter((item) => !!item.url);
					data.forEach((item) => {
						switch (item.url) {
							case FormAreaPath.loanServiceability:
								item.children = this.checkAndUpdateChildren(loanServiceabilityChildren, item);
								item.expanded = false;
								break;
							case FormAreaPath.serviceabilityScenarios:
								item.children = this.checkAndUpdateChildren(serviceabilityScenariosChildren, item);
								item.expanded = false;
								break;
							case FormAreaPath.summaryLodgement:
								item.children = this.checkAndUpdateChildren(summaryLodgementChildren, item);
								item.expanded = false;
								break;
							case FormAreaPath.setup:
								item.children = this.updateSetupChildren(item);
								item.children.forEach((child) => (child.active = true));
								item.active = true;
								item.expanded = false;
								break;
							default:
								item.expanded = false;
								break;
						}
					});
					this.upsertSideNavItems('fullApplication', data);
					this.syncSideNav();
				})
			);
		}

		return of([]);
	}

	checkAndUpdateChildren(staticChildren: SideNavItem[], item: SideNavItem): SideNavItem[] {
		item.children = [];
		staticChildren.forEach((child) => {
			const hasSections = child.includedSections?.filter((y) => item.includedSections?.includes(y));
			hasSections?.length ? item.children?.push(child) : null;
		});
		return item.children;
	}

	updateSetupChildren(item: SideNavItem): SideNavItem[] {
		item.children = [];
		const hasSections = item.includedSections?.filter((y) => setupChildren.includedSections?.includes(y));
		hasSections?.forEach((section) => {
			this.formMetaDataStore.select(FormAreas.setup).subscribe((metadata: FormlyApiProperty) => {
				if (metadata) {
					const child = cloneDeep(setupChildren);
					child.linkText = metadata.properties?.find((s) => s.key === section)?.title as string;
					item.children?.push(child);
				}
			});
		});
		return item.children;
	}

	upsertSideNavItems(key: 'fullApplication', sideNavItems: SideNavItem[]) {
		this.applicationDataStore.update((draft) => {
			draft.sideNavItems[key] = sideNavItems;
			draft.defaultSideNavItems[key] = sideNavItems;
			return draft;
		});
	}

	getSideNavItems(key: 'fullApplication'): SideNavItem[] {
		return this.applicationDataStore.getValue().sideNavItems[key];
	}

	toggleSideNavItem(uniqueId: number, expand = true) {
		this.applicationDataStore.update((draft) => {
			Object.values(draft.sideNavItems).forEach((sideNavItem: SideNavItem[]) => {
				const relatedItem = sideNavItem.find((item) => item.uniqueId === uniqueId);
				if (relatedItem && relatedItem.expanded !== expand) {
					relatedItem.expanded = expand;
				}
			});
			return draft;
		});
	}

	updateNavigationSteps(navigationSteps: NavigationStep[]) {
		this.applicationDataStore.update((draft) => {
			draft.navigationSteps = navigationSteps;
			return draft;
		});
	}

	updateSchemaList(journeyConfig: JourneyConfig[]) {
		this.applicationDataStore.update((draft) => {
			draft.journeyConfig = journeyConfig;
			return draft;
		});
	}

	updateNavigationStepStatus(stepType: number, status: SimpProgressBarStatus) {
		this.applicationDataStore.update((draft) => {
			const stepToUpdate = draft.navigationSteps.find((step) => step.stepType === stepType);
			if (stepToUpdate) {
				stepToUpdate.stepStatus = status;
			}
			return draft;
		});
	}

	updateNavigationActiveStep(index: number) {
		this.applicationDataStore.update((draft) => {
			draft.navigationSteps.forEach((step) => (step.active = false));
			draft.navigationSteps[index].active = true;
			return draft;
		});
	}

	syncSideNav() {
		const applicantId = this.formDataService.getSetupApplicants()[0]?.id;
		forkJoin([
			this.areaValidationService.fetchAreaValidation(),
			this.guarantorService.fetchGuarantorInterviews(),
			this.applicationTemplateDocumentsService.hasDocuments()
		]).subscribe(([areaValidations, guarantorInterview, hasDocuments]) => {
			const hasSideNavSubMenu = this.channelSettingQuery.hasSideNavSubMenu();
			const guarantorEnabled = this.channelSettingQuery.isGuarantorEnabled();
			const borrowerInterviewEnabled = this.channelSettingQuery.isBorrowerInterviewEnabled();
			this.applicationDataStore.update((draft) => {
				const originalSideNav = hasSideNavSubMenu
					? this.updatedSideNavApplicants(!!guarantorInterview.length && guarantorEnabled, borrowerInterviewEnabled)
					: draft.sideNavItems;
				const sideNavItems = cloneDeep(originalSideNav);

				sideNavItems.fullApplication.forEach((item) => {
					item.formValid = areaValidations.find((areaValidation) => this.matchedToSideNav(item, areaValidation))?.valid;
					item.applicantId = item.applicantId ?? applicantId;
					if (item.area !== JourneyArea.setup) {
						item.expanded = false;
					}
					if (item.children?.length) {
						item.children.forEach((child, index) => {
							const matchedAreaValidation = areaValidations.find((areaValidation) =>
								this.matchedToSideNav(child, areaValidation)
							);
							child.formValid = matchedAreaValidation?.valid;
							if (item.area === JourneyArea.setup && child.includedSections?.length === item.children?.length) {
								child.elementId = child.includedSections?.[index] ?? '';
							}
						});
					}
				});

				// hide serviceability based on channel setting and applicants type
				const loanServiceabilityItems =
					sideNavItems.fullApplication.find((item) => item.area === JourneyArea.loanServiceability)?.children ?? [];
				const serviceabilityIndex = loanServiceabilityItems.findIndex(
					(item) => item.url === FormAreaPath.serviceability
				);

				if (this.formStateQuery.skipServiceability()) {
					if (serviceabilityIndex >= 0) {
						loanServiceabilityItems.splice(serviceabilityIndex, 1);
					}
				}

				//hide documents sidenav when no documents configured for journey
				if (!hasDocuments) {
					const summaryItems =
						sideNavItems.fullApplication.find((item) => item.area === JourneyArea.summaryLodgement)?.children ?? [];
					const documentsIndex = summaryItems.findIndex((item) => item.url === FormAreaPath.documents);
					if (documentsIndex >= 0) {
						summaryItems.splice(documentsIndex, 1);
					}
				}
				draft.sideNavItems = sideNavItems;
				return draft;
			});
		});
	}

	reloadApplication() {
		this.applicationTemplateDocumentsService.resetCache();
		void this.router.navigateByUrl(CONSTANTS.LOANAPP_CLIENT_LANDING_URL).then(() => {
			this.updateLoanApplication({ applicationId: this.applicationDataQuery.applicationId() }, true);
			this.formEnumsService.fetchSetupInitialEnums().subscribe();
			void this.router.navigateByUrl(FormAreaPath.setup);
		});
	}
	resetStore(): void {
		this.applicationDataStore.update((draft: ApplicationDataState) => {
			draft.loanappApplication = undefined;
			draft.houseHoldAndApplicants = undefined;
			return draft;
		});
		this.applicationTemplateDocumentsService.resetCache();
		this.formAddressesStore.reset();
		this.formEnumsService.resetEnums();
		this.formValidationsService.reset();
		this.formDataService.hardResetStore();
		this.expensesStore.reset();
		this.formMetaDataStore.reset();
	}

	private matchedToSideNav(item: SideNavItem, areaValidation: AreaValidation) {
		if (item.applicantEntityType) {
			return (
				item.area === areaValidation.area &&
				item.applicantEntityType === areaValidation.applicantEntityType &&
				item.applicantId === areaValidation.applicantId
			);
		} else {
			return item.area === areaValidation.area;
		}
	}

	private updatedSideNavApplicants(showGuarantor: boolean, showBorrowerInterview: boolean) {
		const stateApplicants = this.formDataService.getSetupApplicants();
		const personApplicantsSideNavItemsForApplicantsArea = this.getPersonApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.applicants
		);
		const companyApplicantsSideNavItemsForApplicants = this.getCompanyApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.applicants
		);
		const trustApplicantsSideNavItemsForApplicants = this.getTrustApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.applicants
		);

		const storeSideNav = cloneDeep(this.applicationDataStore.getValue().sideNavItems);
		const storeDefaultSideNav = cloneDeep(this.applicationDataStore.getValue().defaultSideNavItems);
		const sideNavItems = {
			fullApplication: storeSideNav.fullApplication
		};
		const defaultFullAppSideNav = storeDefaultSideNav.fullApplication;

		const applicantsSideNav = sideNavItems.fullApplication.find((s) => s.url === FormAreaPath.applicants);
		if (applicantsSideNav) {
			applicantsSideNav.children = [
				...personApplicantsSideNavItemsForApplicantsArea,
				...companyApplicantsSideNavItemsForApplicants,
				...trustApplicantsSideNavItemsForApplicants
			];
			applicantsSideNav.expanded = true;
		}

		const personApplicantsSideNavItems = this.getPersonApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.financialPosition
		);
		const companyApplicantsSideNavItems = this.getCompanyApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.financialPosition
		);

		const trustApplicantsSideNavItems = this.getTrustApplicantsSideNavItems(
			stateApplicants,
			FormAreaPath.financialPosition
		);

		const financialPositionSideNav = sideNavItems.fullApplication.find((s) => s.url === FormAreaPath.financialPosition);
		if (financialPositionSideNav) {
			financialPositionSideNav.children = [
				...personApplicantsSideNavItems,
				...companyApplicantsSideNavItems,
				...trustApplicantsSideNavItems
			];
			financialPositionSideNav.expanded = true;
		}
		const defaultComplianceSideNav = defaultFullAppSideNav.find((s) => s.url === FormAreaPath.compliance);
		const defaultComplianceSideNavIndex = defaultFullAppSideNav.findIndex((s) => s.url === FormAreaPath.compliance);
		let complianceSideNav = sideNavItems.fullApplication.find((s) => s.url === FormAreaPath.compliance);
		let complianceSideNavIndex = sideNavItems.fullApplication.findIndex((s) => s.url === FormAreaPath.compliance);
		if ((showBorrowerInterview || showGuarantor) && defaultComplianceSideNav && !complianceSideNav) {
			sideNavItems.fullApplication.splice(defaultComplianceSideNavIndex, 0, { ...defaultComplianceSideNav });
			complianceSideNav = sideNavItems.fullApplication.find((s) => s.url === FormAreaPath.compliance);
			complianceSideNavIndex = sideNavItems.fullApplication.findIndex((s) => s.url === FormAreaPath.compliance);
		}

		if (complianceSideNav) {
			if (showBorrowerInterview) {
				complianceSideNav.children = [{ ...fullAppBorrowerInterviewSideNavItem }];
			} else {
				complianceSideNav.children = [];
			}

			if (this.hasGuarantor(showGuarantor, stateApplicants)) {
				complianceSideNav.children.push({ ...fullAppGuarantorInterviewSideNavItem });

				if (!showBorrowerInterview) {
					complianceSideNav.area = JourneyArea.complianceGuarantor;
				}
			}

			if (showBorrowerInterview || showGuarantor) {
				complianceSideNav.expanded = true;
			} else {
				sideNavItems.fullApplication.splice(complianceSideNavIndex, 1);
			}
		}

		return sideNavItems;
	}

	private isGuarantor(role: ApplicantRole) {
		return [
			ApplicantRole.GuarantorIncomeAndSecurity,
			ApplicantRole.Guarantor,
			ApplicantRole.GuarantorIncomeOnly,
			ApplicantRole.GuarantorSecurityOnly
		].includes(role);
	}

	private hasGuarantor(showGuarantor: boolean, stateApplicants: SetUpApplicant[]): boolean {
		return (
			showGuarantor &&
			stateApplicants.findIndex(
				(app) =>
					app.applicantRole &&
					this.isGuarantor(app.applicantRole) &&
					app.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant
			) > -1
		);
	}

	private getCompanyApplicantsSideNavItems(
		stateApplicants: SetUpApplicant[],
		formAreaPath: FormAreaPath
	): SideNavItem[] {
		const companyApplicants = stateApplicants.filter(
			(stateApplicant) => stateApplicant.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant
		);
		const url =
			formAreaPath === FormAreaPath.applicants ? FormAreaPath.companyApplicants : FormAreaPath.companyFinancialPosition;
		const titleText =
			formAreaPath === FormAreaPath.applicants ? FormAreaLabel.applicants : FormAreaLabel.financialPosition;
		const area =
			formAreaPath === FormAreaPath.applicants ? JourneyArea.companyApplicants : JourneyArea.companyFinancialPosition;

		const companyApplicantsSideNavItems: SideNavItem[] = companyApplicants.map((companyApplicant, index) => ({
			url: `${url}?index=${index}`,
			linkText: companyApplicant.companyName || '',
			titleText: titleText,
			active: false,
			applicantEntityType: ApplicantEntityType.CompanyApplicant,
			applicantId: companyApplicant.id,
			area
		}));

		return companyApplicantsSideNavItems;
	}

	private getTrustApplicantsSideNavItems(stateApplicants: SetUpApplicant[], formAreaPath: FormAreaPath): SideNavItem[] {
		const trustApplicants = stateApplicants.filter(
			(stateApplicant) => stateApplicant.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant
		);
		const url =
			formAreaPath === FormAreaPath.applicants ? FormAreaPath.trustApplicants : FormAreaPath.trustFinancialPosition;
		const titleText =
			formAreaPath === FormAreaPath.applicants ? FormAreaLabel.applicants : FormAreaLabel.financialPosition;
		const area =
			formAreaPath === FormAreaPath.applicants ? JourneyArea.trustApplicants : JourneyArea.trustFinancialPosition;
		const trustApplicantsSideNavItems: SideNavItem[] = trustApplicants.map((trustApplicant, index) => ({
			url: `${url}?index=${index}`,
			linkText: trustApplicant.trustName || '',
			titleText: titleText,
			active: false,
			applicantEntityType: ApplicantEntityType.TrustApplicant,
			applicantId: trustApplicant.id,
			area
		}));

		return trustApplicantsSideNavItems;
	}

	private getPersonApplicantsSideNavItems(
		stateApplicants: SetUpApplicant[],
		formAreaPath: FormAreaPath
	): SideNavItem[] {
		const personApplicants = stateApplicants.filter(
			(stateApplicant) => stateApplicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant
		);
		const url = formAreaPath === FormAreaPath.applicants ? FormAreaPath.applicants : FormAreaPath.financialPosition;

		const titleText =
			formAreaPath === FormAreaPath.applicants ? FormAreaLabel.applicants : FormAreaLabel.financialPosition;
		const area = formAreaPath === FormAreaPath.applicants ? JourneyArea.applicants : JourneyArea.financialPosition;
		if (personApplicants.length) {
			const personApplicantsSideNavs: SideNavItem[] = personApplicants.map((applicant, index) => ({
				url: url,
				elementId: url === FormAreaPath.financialPosition ? 'finances' : `personApplicant-${index}`,
				linkText: `${applicant.firstName ?? ''} ${applicant.lastName ?? ''}`.trim(),
				titleText: titleText,
				active: false,
				applicantEntityType: ApplicantEntityType.PersonApplicant,
				applicantId: applicant.id,
				area
			}));

			return personApplicantsSideNavs;
		}
		return [];
	}
}
