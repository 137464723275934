import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	AssetToBeUsedAsType,
	EstimateBasisNonRealEstateAsset,
	FinancialAssetType,
	FinancialInstitution,
	Proportions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, SavingsDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';
import { getNullableBoolean, getNullableYesNo } from '../helper/util';
import { PercentageOwnedTransformer } from './percentage-owned.model';

export class AssetSavingModelTransformer {
	static toPayload(applicationId: number, assetSavingModel: SavingModel): SavingsDTO {
		const savingDetails = assetSavingModel.details.savingDetails;
		return {
			applicationId: applicationId,
			id: assetSavingModel.id ?? CONSTANTS.NEW_ID,
			type: savingDetails.financialAssetType,
			description: savingDetails.description,
			value: savingDetails.estimatedValue?.amount,
			estimateBasisNonRealEstateAssetId: savingDetails.estimatedValue?.frequency,
			toBeUsedAsDeposit: getNullableBoolean(savingDetails.willBeUsedForDeposit),
			percentOwnedProportions: this.getPercentOwnedProportions(savingDetails.isEqualShare),
			bsb: savingDetails.bsb,
			accountNumber: savingDetails.accountNumber,
			financialInstitution: savingDetails.financialInstitution,
			otherFinancialInstitutionName: savingDetails?.otherFinancialInstitutionName,
			percentsOwned: savingDetails.percentsOwned,
			isForeign: savingDetails.isForeign,
			linkedLiabilities:
				savingDetails.linkedLiabilities
					?.filter((linkedLiability) => linkedLiability.liabilityType)
					?.map((x) => {
						return {
							id: x.liabilityType.id,
							liabilityType: parseInt(x.liabilityType.info as string)
						};
					}) ?? []
		};
	}

	static fromPayload(saving: SavingsDTO, applicants: ShortApplicant[], formEnumsQuery: FormEnumsQuery): SavingModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(saving?.percentsOwned, applicants);

		const assetTypeLabel = formEnumsQuery.getOptionLabel('FinancialAssetType', saving.type);
		const financialInstitution = formEnumsQuery.getOptionLabel('FinancialInstitution', saving.financialInstitution);
		const savingModel: SavingModel = {
			id: saving.id,
			savingType: saving.type,
			summaryAmount: saving.value,
			details: {
				savingDetails: {
					financialInstitution: saving.financialInstitution,
					otherFinancialInstitutionName: saving?.otherFinancialInstitutionName,
					percentsOwned: percentageOwned,
					financialAssetType: saving.type,
					estimatedValue: {
						amount: saving.value as number,
						frequency: saving.estimateBasisNonRealEstateAssetId as EstimateBasisNonRealEstateAsset
					},
					description: saving.description,
					bsb: saving.bsb,
					accountNumber: saving.accountNumber,
					willBeUsedForDeposit: getNullableYesNo(saving.toBeUsedAsDeposit),
					isEqualShare: saving.percentOwnedProportions === Proportions.Equal,
					isForeign: saving.isForeign,
					assetToBeUsedAsType: saving.assetToBeUsedAsType,
					linkedLiabilities: saving.linkedLiabilities?.map((x) => {
						return {
							liabilityType: {
								id: x.id
							} as EnumObject
						};
					})
				},
				extract: `${assetTypeLabel} ${financialInstitution ? ' - ' + financialInstitution : ''}`
			},
			value: saving.value
		};
		return savingModel;
	}

	static updateLiabilityTypeEnum(savings: SavingModel[], formEnumsQuery: FormEnumsQuery): SavingModel[] {
		return savings.map((saving) => {
			const linkedLiabilities = saving.details?.savingDetails?.linkedLiabilities || [];

			return {
				...saving,
				details: {
					...saving.details,
					savingDetails: {
						...saving.details?.savingDetails,
						linkedLiabilities: linkedLiabilities.map((linkedLiability) => {
							return {
								liabilityType: formEnumsQuery.getOption('Liabilities', linkedLiability.liabilityType.id) as EnumObject
							};
						})
					}
				}
			};
		});
	}

	private static getPercentOwnedProportions(isEqualShare: boolean): Proportions {
		return isEqualShare ? Proportions.Equal : Proportions.Specified;
	}
}

export interface SavingModel {
	id?: number;
	savingType?: number;
	details: { savingDetails: SavingDetails; extract: string };
	summaryAmount?: number;
	value?: number;
}

export interface SavingDetails {
	financialInstitution: FinancialInstitution;
	otherFinancialInstitutionName?: string;
	financialAssetType: FinancialAssetType;
	estimatedValue?: AmountSelect;
	description: string;
	bsb: string;
	accountNumber: string;
	isEqualShare: boolean;
	percentsOwned?: PercentOwned[];
	willBeUsedForDeposit?: YesNo;
	isForeign: boolean;
	assetToBeUsedAsType?: AssetToBeUsedAsType;
	linkedLiabilities?: { liabilityType: EnumObject }[];
	hideLinkedSecurities?: boolean;
}
