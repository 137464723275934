<ng-container *ngIf="existingAddresses$ | async as existingAddresses">
	<div
		class="form-group simp-margin-bottom-base"
		*ngIf="existingAddresses.length && to.showExisting"
		metadataKey
		[formlyField]="field"
	>
		<div class="formly-address__label">
			<label class="simp-label" [for]="uniqueId">{{ to.label }}</label>
			<simp-info-icon *ngIf="to.tooltip" [tooltipHtmlContent]="to.tooltip"></simp-info-icon>
			<simp-info-icon *ngIf="to.tooltipAsModal" [asModal]="true" (modalClick)="tooltipClick(field)"></simp-info-icon>
		</div>
		<ng-select
			class="simp-select simp-form-control"
			[id]="uniqueId + '-select'"
			[labelForId]="uniqueId"
			[placeholder]="to.placeholder || 'Please select address'"
			[items]="existingAddresses"
			[multiple]="false"
			[searchable]="false"
			[readonly]="!!to.readonly"
			[clearable]="to.clearable ?? true"
			(change)="addressChanged($event?.address)"
			[formControl]="selectedAddressId"
			[formlyAttributes]="field"
		>
		</ng-select>
		<simp-address-input
			*ngIf="selectedAddressId.value?.id === -1"
			[id]="uniqueId + '-address'"
			[placeholder]="to.placeholder || 'Please enter address'"
			[countries]="countries"
			[formControl]="addressFormControl"
			[readonly]="!!to.readonly"
			(addressChange)="addressChanged($event)"
			[validationErrors]="field.validation?.messages!"
			(addressClear)="addressCleared()"
			[convertSlashToSpace]="to.convertSlashToSpace"
			[convertToUpperCase]="to.convertToUpperCase"
			[truncateCommas]="to.truncateCommas"
			[placeType]="placeType"
			[class.is-invalid]="formControl.invalid"
		>
		</simp-address-input>
		<simp-field-error
			*ngIf="
				(selectedAddressId.dirty || selectedAddressId.touched) &&
				selectedAddressId.errors &&
				selectedAddressId.value !== -1
			"
			[errors]="selectedAddressId.errors"
			[validationErrors]="field.validation?.messages!"
		>
		</simp-field-error>
	</div>
	<simp-address-input
		*ngIf="!existingAddresses.length || !to.showExisting"
		[id]="uniqueId + '-address'"
		[placeholder]="to.placeholder || 'Please enter address'"
		[countries]="countries"
		[formControl]="addressFormControl"
		[validationErrors]="field.validation?.messages!"
		[readonly]="!!to.readonly"
		(addressChange)="addressChanged($event)"
		(addressClear)="addressCleared()"
		[convertSlashToSpace]="to.convertSlashToSpace"
		[placeType]="placeType"
		[convertToUpperCase]="to.convertToUpperCase"
		[truncateCommas]="to.truncateCommas"
		[class.is-invalid]="formControl.invalid"
		metadataKey
		[formlyField]="field"
		>{{ to.label }}
	</simp-address-input>
</ng-container>
