import { Injectable } from '@angular/core';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { FinancialPositionService } from '@app/modules/financial-position/financial-position.service';
import {
	ApplicantEmploymentDetailsModel,
	ApplicantEmploymentModel
} from '@app/modules/financial-position/model/applicant-employment.model';
import { ApplicantSelfEmploymentModel } from '@app/modules/financial-position/model/applicant-self-employment.model';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { isNullOrUndefined } from '../../helper/util';

@Injectable({ providedIn: 'root' })
export class ApplicantsEmploymentTransformerService {
	constructor(
		private simpFormlyModalService: SimpFormlyModalService,
		private financialPositionService: FinancialPositionService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsService: FormEnumsService
	) {}

	openSelfEmploymentModal(field: FormlyFieldConfig, index: number, applicantId: number): void {
		const key = Number(field.parent?.key);
		const applicantEmploymentDetailsModel = Object.assign(
			{},
			field.model,
			field.form?.value
		) as ApplicantEmploymentDetailsModel;
		applicantEmploymentDetailsModel.selfEmployment = {} as ApplicantSelfEmploymentModel;
		applicantEmploymentDetailsModel.selfEmployment.ownership = applicantId;

		const isCurrentEmployment = applicantEmploymentDetailsModel?.typeOfIncome?.currentEmployment;

		if (!isNullOrUndefined(isCurrentEmployment)) {
			const employmentStatusField = getFormField(field.fieldGroup, 'selfEmployment.status');

			if (employmentStatusField && employmentStatusField.templateOptions) {
				const employmentStatusEnumKey = isCurrentEmployment ? 'CurrentEmploymentStatus' : 'PreviousEmploymentStatus';
				employmentStatusField.templateOptions.options = this.formEnumsService.fetch(employmentStatusEnumKey, true);
			}
		}

		const ownershipField = getFormField(field.fieldGroup, 'selfEmployment.ownership');
		if (ownershipField) {
			ownershipField.formControl?.setValue(applicantEmploymentDetailsModel.selfEmployment.ownership);
			ownershipField.formControl?.disable();
		}

		const selfEmploymentModalRef = this.simpFormlyModalService.openModal(field, 'selfEmployment', {
			backdrop: 'static'
		});
		const subscription = selfEmploymentModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				subscription.unsubscribe();
				selfEmploymentModalRef.close();
				return;
			}
			this.financialPositionService
				.saveApplicantSelfEmployment(field.parent?.model as ApplicantEmploymentModel)
				.subscribe((applicantEmploymentModel: ApplicantEmploymentModel) => {
					selfEmploymentModalRef.close();
					field.formControl?.parent?.patchValue(applicantEmploymentModel as any);
					subscription.unsubscribe();
					this.simpFormlyHandlerService.updateToState(`employments-${index}`, applicantEmploymentModel, key);
				});
		});
	}
}
