import { Injectable } from '@angular/core';
import { RefiApplicationSummaryService } from '@app/modules/refi-journey/components/refi-form/application-summary/application-summary.service';
import { StepResponse } from '@app/modules/typings/api';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { URL_CONSTANTS } from '../constants/api.constants';

import { RefiStepType } from '@app/modules/refi-journey/enums/refi-steps.enum';
import { JourneyType, StepStatus } from '../enums/app.enums';
import { stepToSimpProgress } from '../helper/util';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ApplicationDataService } from '../store/application-data/application-data.service';
import { NavigationStep } from '../store/application-data/typings/application-data';
import { FormDataService } from '../store/form-data/form-data.service';
import { SharedFlagsService } from '../store/shared-flags/shared-flags.service';
import { StepStatusQuery } from '../store/step-status/step-status.query';
import { StepStatusService } from '../store/step-status/step-status.service';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class JourneyStepService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private stepStatusService: StepStatusService,
		private applicationDataService: ApplicationDataService,
		private stepStatusQuery: StepStatusQuery,
		private formDataService: FormDataService,
		private applicationSummaryService: RefiApplicationSummaryService,
		private sharedFlagService: SharedFlagsService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.APPLICATION);
	}

	getParentIndex(type: RefiStepType): number {
		switch (type) {
			case RefiStepType.RefiEligibility:
			case RefiStepType.RefiConfirmDetails:
				return 0;
			case RefiStepType.RefiLoanDetails:
				return this.sharedFlagService.currentJourney === JourneyType.Refi ? 0 : -1;

			case RefiStepType.RefiPropertyDetails:
			case RefiStepType.RefiLoanRequirements:
			case RefiStepType.RefiIncome:
			case RefiStepType.RefiExpenses:
			case RefiStepType.RefiAssets:
			case RefiStepType.RefiLiabilities:
			case RefiStepType.RefiSelectProduct:
			case RefiStepType.RefiReview:
				return 1;

			case RefiStepType.RefiUploadDocuments:
				return 2;

			default:
				return -1;
		}
	}

	updateStep(type: RefiStepType, status: StepStatus, markCurrentStatusCompleted = true): Observable<void | null> {
		if (!this.applicationDataQuery.getRefiApplicants().some((app) => app.isCurrentUser)) {
			return of(null);
		}

		if (this.stepStatusQuery.getStepStatus(type) === status) {
			return of(null);
		} else {
			const steps = this.applicationDataQuery.getAllNavigationSteps();
			return this.patch(`SetStep/${this.applicationDataQuery.applicationId()}/${type}/${status}`, undefined).pipe(
				tap(() => {
					this.stepStatusService.updateStepStatus({ type, status });
					const stepToUpdate = steps.find((route) => route.stepType === type)?.stepType;
					if (stepToUpdate) {
						this.applicationDataService.updateNavigationStepStatus(
							stepToUpdate,
							status === StepStatus.Complete ? SimpProgressBarStatus.Complete : SimpProgressBarStatus.NotStarted
						);
					}
					if (
						status === StepStatus.Complete &&
						markCurrentStatusCompleted &&
						!this.applicationDataQuery.getRefiApplicants().some((applicant) => !!applicant.hasFinalisedApplication)
					) {
						this.markCurrentStatusCompleted(type);
					}
				})
			);
		}
	}

	updateStepLoanAppX(status: SimpProgressBarStatus): Observable<boolean> {
		const steps = this.applicationDataQuery.getAllNavigationSteps();
		const currentStep = steps.find((step) => step.active);
		if (!currentStep || currentStep.stepStatus === status) {
			return of(true);
		} else {
			return this.patch(
				`SetStep/${this.applicationDataQuery.applicationId()}/${currentStep.stepId}/${status}`,
				undefined
			).pipe(
				tap(() => {
					this.applicationDataService.updateNavigationStepStatus(currentStep.stepType, status);
				}),
				map(() => true)
			);
		}
	}

	getStepStatus(): Observable<StepResponse[]> {
		return this.getCustom(`GetSteps/${this.applicationDataQuery.applicationId()}`).pipe(
			map((statuses: { type: RefiStepType; status: StepStatus }[]) =>
				statuses.map(
					(status) =>
						({
							...status,
							parentStatusIndex: this.getParentIndex(status.type)
						} as StepResponse)
				)
			)
		);
	}

	fetchNavigationSteps() {
		return this.getStepStatus().pipe(
			catchError(() => of([])),
			map((remoteSteps) => {
				const currentSteps: NavigationStep[] = remoteSteps.map((remoteStep) => ({
					stepId: remoteStep.type,
					stepStatus: stepToSimpProgress(remoteStep.status),
					active: false,
					stepType: remoteStep.type
				}));
				this.applicationDataService.updateNavigationSteps(currentSteps);
			})
		);
	}

	updateActivityStatus(): Observable<null> {
		if (!this.applicationDataQuery.getRefiApplicants().some((app) => app.isCurrentUser)) {
			return of(null);
		}

		const currentStatusId = this.formDataService.getRefiApplicationSummary().statusId;
		const currentStatusIndex = this.formDataService
			.getRefiApplicationStatuses()
			.find((status) => status.id === currentStatusId)?.index;

		return this.patch(
			`CurrentActivityStatusAsCompleted/${this.applicationDataQuery.applicationId()}/${currentStatusIndex}`,
			undefined
		).pipe(
			map(() => {
				this.applicationSummaryService.setupState();
				return null;
			})
		);
	}

	private markCurrentStatusCompleted(type: RefiStepType): void {
		const stepStatuses = this.stepStatusQuery.stepStatuses();
		const currentStatusId = this.formDataService.getRefiApplicationSummary().statusId;
		const currentStatusIndex = this.formDataService
			.getRefiApplicationStatuses()
			.find((status) => status.id === currentStatusId)?.index;

		if (
			currentStatusIndex === stepStatuses.find((step) => step.type === type)?.parentStatusIndex &&
			stepStatuses
				.filter((step) => step.parentStatusIndex === this.stepStatusQuery.getStepParentStatusIndex(type))
				.every((step) => step.status === StepStatus.Complete)
		) {
			this.patch(
				`CurrentActivityStatusAsCompleted/${this.applicationDataQuery.applicationId()}/${currentStatusIndex}`,
				undefined
			).subscribe(() => {
				this.applicationSummaryService.setupState();
			});
		}
	}
}
