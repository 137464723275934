import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';

import { Validators } from '@angular/forms';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Sample Input
 * {
		className: 'col-2',
		type: 'duration',
		key: 'loanTerms',
		validation: {
			messages: { required: 'We need the loan terms' }
		},
		templateOptions: {
			label: 'Loan terms',
			required: true,
			disabled: true,
			handler: yearsOnly or monthsOnly or yearsAndMonths
		}
	},
 */
@Component({
	selector: 'formly-duration',
	templateUrl: './formly-duration.component.html',
	styleUrls: ['./formly-duration.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDurationComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';

	ngOnInit(): void {
		if (!isNil(this.props.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.props.defaultValue);
		}
		this.uniqueId = generateUniqueKey(this.field);
	}
}
