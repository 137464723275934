import { ApplicantForeignEmploymentModel } from '@app/modules/financial-position/model/applicant-foreign-employment.model';
import { IncomeType } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly, getMonthAndYearDifference, getNullableYesNo } from '@app/modules/shared/helper/util';
import { NotEmployedDTO } from '@app/modules/typings/api';
import { AggregateFormatterService } from './../../shared/service/aggregate-formatter.service';
import { ApplicantPaygModel } from './applicant-employment.model';
import { ApplicantSelfEmploymentModel } from './applicant-self-employment.model';
import { NotEmployedBaseModelTransfer, NotEmployedModel } from './notemployed-income.model';
import { EmploymentModelTransformer } from './employment.model';

export class ApplicantNotEmployedModelTransformer {
	static toPayload(employmentModel: ApplicantNotEmployedIncomeModel, applicationId: number): NotEmployedDTO {
		return {
			...NotEmployedBaseModelTransfer.CreatePayload(
				employmentModel.employmentDetails,
				employmentModel.id,
				applicationId
			)
		};
	}

	static fromPayload(
		notEmployedDTO: NotEmployedDTO,
		formatter: AggregateFormatterService
	): ApplicantNotEmployedIncomeModel {
		const notEmployedModel = {} as NotEmployedModel;
		const updatedNotEmployedModel = Object.assign(notEmployedModel, notEmployedDTO) as NotEmployedModel;
		updatedNotEmployedModel.ownership = notEmployedDTO.applicantId;
		updatedNotEmployedModel.incomeSMSF = getNullableYesNo(notEmployedDTO.incomeSMSF);
		updatedNotEmployedModel.typeOfIncome = {
			type: IncomeType.NotEmployed,
			currentEmployment: EmploymentModelTransformer.isCurrentEmployment(notEmployedDTO.dateEnded, notEmployedDTO.status)
		};
		updatedNotEmployedModel.status = notEmployedDTO.status;
		updatedNotEmployedModel.type = notEmployedDTO.type;
		updatedNotEmployedModel.income = (notEmployedDTO.income || []).map((income) => ({
			grossAmount: String(income.grossAmount!),
			netAmount: String(income.netAmount!),
			frequency: income.frequency,
			description: income?.description,
			type: income.type
		}));
		updatedNotEmployedModel.duration = getMonthAndYearDifference(
			notEmployedDTO.dateStarted!,
			notEmployedDTO.dateEnded!
		);
		let totalMonthyGrossIncome = 0;
		updatedNotEmployedModel.totalMonthlyIncome = '--';
		(notEmployedDTO.income || []).forEach((income) => {
			totalMonthyGrossIncome += calculateMonthly(income.grossAmount, income.frequency);
		});
		if (totalMonthyGrossIncome > 0) {
			updatedNotEmployedModel.totalMonthlyIncome = formatter.formatAmount(totalMonthyGrossIncome) + ' Monthly';
		}

		const employmentModel: ApplicantNotEmployedIncomeModel = {
			employmentDetails: {
				applicantId: notEmployedDTO.applicantId,
				extract: `NotEmployed`,
				typeOfIncome: {
					type: IncomeType.NotEmployed,
					currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
						notEmployedDTO.dateEnded,
						notEmployedDTO.status
					)
				},
				notEmployed: updatedNotEmployedModel
			},
			dateStarted: notEmployedDTO.dateStarted,
			dateEnded: notEmployedDTO.dateEnded,
			id: notEmployedDTO.employmentId
		};
		return employmentModel;
	}
}

export interface ApplicantNotEmployedIncomeModel {
	employmentDetails: ApplicantNotEmployedDetailsModel;
	id?: number;
	dateStarted?: string;
	dateEnded?: string;
}

interface ApplicantNotEmployedDetailsModel {
	typeOfIncome: TypeOfIncome;
	payg?: ApplicantPaygModel;
	selfEmployment?: ApplicantSelfEmploymentModel;
	notEmployed?: NotEmployedModel;
	foreignEmployment?: ApplicantForeignEmploymentModel;
	extract?: string;
	applicantId?: number;
}

interface TypeOfIncome {
	type: number;
	currentEmployment: boolean;
}
