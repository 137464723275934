import { DOCUMENT, formatCurrency } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { JourneyType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { filterListOptions } from '@app/modules/shared/enums/enum-helper';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import { Action } from '@app/modules/simp-formly/components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import {
	disableFutureDates,
	formlyExtendExpressionProperties,
	formlyGenerateLabels,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { ConfirmationDialogHeaderType, EnumObject } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import { Observable, Subject, Subscription, forkJoin, of, take, takeUntil } from 'rxjs';
import { RefiReconcileService } from '../../components/refi-form/reconcile/reconcile.service';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import {
	CreditCardSummary,
	CreditCardSummaryItems,
	RefiCreditCardDetails,
	RefiCreditCardModel
} from '../../models/refi-credit-card-model';
import { RefiHomeLoanDetails, RefiHomeLoanModel } from '../../models/refi-home-loans.model';
import { RefiLiabilitiesModel } from '../../models/refi-liabilities.model';
import { RefiOtherLiabilityDetails, RefiOtherLiabilityModel } from '../../models/refi-otherliabilites.model';
import { RefiPersonalLoanDetails, RefiPersonalLoanModel } from '../../models/refi-personal-loan.model';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiLiabilitiesService } from './refi-liabilities.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyLiabilitiesTransformerService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private totalLiabilities = 0;
	private allStepsCompleted = false;

	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private refiModalService: RefiModalService,
		private refiJourneyService: RefiJourneyService,
		private journeyStepService: JourneyStepService,
		private refiLiabilitiesService: RefiLiabilitiesService,
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private formEnumQuery: FormEnumsQuery,
		private confirmationDialogService: SimpConfirmationDialogService,
		private stepStatusQuery: StepStatusQuery,
		private formatter: AggregateFormatterService,
		@Inject(DOCUMENT) private document: Document,
		private sharedFlagService: SharedFlagsService,
		private reconcileService: RefiReconcileService,
		private simpFormlyModalService: SimpFormlyModalService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public liabilitiesTransform(formFields: FormlyFieldConfig[]) {
		const textBlockField = getFormField(
			formFields,
			`liabilities.refi-modals.mbcGoodNewsLabels.textBlock1`
		) as FormlyFieldConfig;

		this.simpFormlyHandlerService
			.mapToFullStateDataPath<RefiApplicationSummaryDTO[]>(RefiStore.ApplicationSummary)
			?.subscribe((summary) => {
				if (textBlockField.templateOptions?.label) {
					const amount = summary[0]?.amountRequested;
					textBlockField.templateOptions.label = textBlockField.templateOptions.label.replace(
						'LoanAmount',
						this.formatter.formatAmount(amount)
					);
				}
			});

		formlyRegisterHooks(formFields, 'liabilities.details.liabilitiesModal', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.confirm', {
			afterViewInit: (field) => {
				if (field) {
					if (this.stepStatusQuery.isCompleted(RefiStepType.RefiLiabilities)) {
						field.formControl?.setValue(true);
					}
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.homeLoans.homeLoansModal.currentLender', {
			onInit: (field) => {
				if (!(field?.model as RefiHomeLoanDetails)?.readonly) {
					this.updateListOptions(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.personalLoans.personalLoansModal.currentLender', {
			onInit: (field) => {
				if (!(field?.model as RefiPersonalLoanDetails)?.readonly) {
					this.updateListOptions(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.creditCards.creditCardsModal.financialInstitution', {
			onInit: (field) => {
				if (!(field?.model as RefiCreditCardDetails)?.readonly) {
					this.updateListOptions(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.otherLiabilities.otherLiabilitiesModal.currentLender', {
			onInit: (field) => {
				if (!(field?.model as RefiOtherLiabilityDetails)?.readonly) {
					this.updateListOptions(field);
				}
			}
		});

		this.formEnumQuery
			.selectEnumOptions('AllApplicants')
			.pipe(take(1))
			.subscribe((applicants: EnumObject[]) => {
				const confirmWhatYouOweLabels = getFormField(
					formFields,
					`liabilities.refi-modals.confirmWhatYouOweLabels.confirm`
				) as FormlyFieldConfig;
				if (confirmWhatYouOweLabels.templateOptions?.label) {
					confirmWhatYouOweLabels.templateOptions.label = confirmWhatYouOweLabels.templateOptions.label.replace(
						'our/my',
						applicants.length === 1 ? 'my' : 'our'
					);
				}
				if (applicants.length === 1) {
					this.selectFirstApplicantForOwnership(formFields, 'liabilities.details.homeLoans.homeLoansModal.ownership');
					this.selectFirstApplicantForOwnership(
						formFields,
						'liabilities.details.personalLoans.personalLoansModal.ownership'
					);
					this.selectFirstApplicantForOwnership(
						formFields,
						'liabilities.details.creditCards.creditCardsModal.ownership'
					);
					this.selectFirstApplicantForOwnership(
						formFields,
						'liabilities.details.otherLiabilities.otherLiabilitiesModal.liabilityOwner'
					);
				}
			});

		formlyExtendExpressionProperties(formFields, `liabilities.details.creditCards.creditCardsModal`, {
			'templateOptions.deleteButtonText': (model: RefiCreditCardDetails, formState, field) => {
				let labels;
				if (field.parent?.parent?.parent) {
					labels = formlyGenerateLabels(field.parent?.parent?.parent, 'reconcileSummaryLabels');
				}
				return !model?.isUserDeclared ? labels?.deleteButtonText : '';
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.homeLoans.homeLoansModal`, {
			'templateOptions.deleteButtonText': (model: RefiHomeLoanDetails, formState, field) => {
				let labels;
				if (field.parent?.parent?.parent) {
					labels = formlyGenerateLabels(field.parent?.parent?.parent, 'reconcileSummaryLabels');
				}
				return !model?.isUserDeclared ? labels?.deleteButtonText : '';
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.personalLoans.personalLoansModal`, {
			'templateOptions.deleteButtonText': (model: RefiPersonalLoanDetails, formState, field) => {
				let labels;
				if (field.parent?.parent?.parent) {
					labels = formlyGenerateLabels(field.parent?.parent?.parent, 'reconcileSummaryLabels');
				}
				return !model?.isUserDeclared ? labels?.deleteButtonText : '';
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.otherLiabilities.otherLiabilitiesModal`, {
			'templateOptions.deleteButtonText': (model: RefiOtherLiabilityDetails, formState, field) => {
				let labels;
				if (field.parent?.parent?.parent) {
					labels = formlyGenerateLabels(field.parent?.parent?.parent, 'reconcileSummaryLabels');
				}
				return !model?.isUserDeclared ? labels?.deleteButtonText : '';
			}
		});

		formlyOnClick(
			formFields,
			'liabilities.details.homeLoans',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Liabilities, true);
					this.refiLiabilitiesService.fetchLiabilities();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				const model = get(field, 'model') as RefiHomeLoanModel[];

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveHomeLoan(model, event.index);
					}
					return;
				}

				if (event.action == 'delete') {
					const isAppSyncData = !model[event.index].homeLoansModal.isUserDeclared;
					if (event.index !== undefined && !isAppSyncData) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const id = model[event.index].id;
									if (id) {
										this.deleteHomeLoan(id);
									}
								}
							});
					} else if (event.index !== undefined && isAppSyncData) {
						this.simpFormlyModalService.openSlideModal(field, 'removeModal', (action: string) => {
							const id = model[event.index].homeLoansModal.id;
							if (id && action === Action.Submit) {
								this.deleteHomeLoan(id);
							}
						});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.homeLoans.homeLoansModal.summaryItemArray.summary.yourEntry.match',
			(field: FormlyFieldConfig) => {
				this.matchRecord(field);
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.personalLoans.personalLoansModal.summaryItemArray.summary.yourEntry.match',
			(field: FormlyFieldConfig) => {
				this.matchRecord(field);
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.creditCards.creditCardsModal.summaryItemArray.summary.yourEntry.match',
			(field: FormlyFieldConfig) => {
				this.matchRecord(field);
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.otherLiabilities.otherLiabilitiesModal.summaryItemArray.summary.yourEntry.match',
			(field: FormlyFieldConfig) => {
				this.matchRecord(field);
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.personalLoans',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Liabilities, true);
					this.refiLiabilitiesService.fetchLiabilities();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				const model = get(field, 'model') as RefiPersonalLoanModel[];

				if (event.action == 'submit') {
					if (event.changed) {
						this.savePersonalLoan(model, event.index);
					}
					return;
				}

				if (event.action == 'delete') {
					const isAppSyncData = !model[event.index].personalLoansModal.isUserDeclared;
					if (event.index !== undefined && !isAppSyncData) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const id = model[event.index].personalLoansModal.id;
									if (id) {
										this.deletePersonalLoan(id);
									}
								}
							});
					} else if (event.index !== undefined && isAppSyncData) {
						this.simpFormlyModalService.openSlideModal(field, 'removeModal', (action: string) => {
							const id = model[event.index].personalLoansModal.id;
							if (id && action === Action.Submit) {
								this.deletePersonalLoan(id);
							}
						});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.creditCards',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Liabilities, true);
					this.refiLiabilitiesService.fetchLiabilities();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				const model = get(field, 'model') as RefiCreditCardModel[];

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveCreditCard(model, event.index);
					}
					return;
				}

				if (event.action == 'delete') {
					const isAppSyncData = !model[event.index].creditCardsModal.isUserDeclared;
					if (event.index !== undefined && !isAppSyncData) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const id = model[event.index].creditCardsModal.id;
									if (id) {
										this.deleteCreditCard(id);
									}
								}
							});
					} else if (event.index !== undefined && isAppSyncData) {
						this.simpFormlyModalService.openSlideModal(field, 'removeModal', (action: string) => {
							const id = model[event.index].creditCardsModal.id;
							if (id && action === Action.Submit) {
								this.deleteCreditCard(id);
							}
						});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'liabilities.details.otherLiabilities',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Liabilities, true);
					this.refiLiabilitiesService.fetchLiabilities();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveOtherLiability(field, event.index);
					}
				}

				const model = get(field, 'model') as RefiOtherLiabilityModel[];

				if (event.action == 'delete') {
					const isAppSyncData = !model[event.index].otherLiabilitiesModal.isUserDeclared;
					if (event.index !== undefined && !isAppSyncData) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const id = model[event.index].otherLiabilitiesModal.id;
									if (id) {
										this.deleteOtherLiability(id);
									}
								}
							});
					} else if (event.index !== undefined && isAppSyncData) {
						this.simpFormlyModalService.openSlideModal(field, 'removeModal', (action: string) => {
							const id = model[event.index].otherLiabilitiesModal.id;
							if (id && action === Action.Submit) {
								this.deleteOtherLiability(id);
							}
						});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			`liabilities.details.homeLoans.homeLoansModal.loanBalance`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openRefiModal(formFields, RefiModalName.currentLoanBalance, 'eligibility.refi-modals');
				}
			}
		);

		formlyRegisterHooks(formFields, 'liabilities.details.homeLoans.homeLoansModal.loanBalance', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(
			formFields,
			`liabilities.details.homeLoans.homeLoansModal.redrawAvailable`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openRefiModal(formFields, RefiModalName.redrawInfo, 'loanRequirements.info-modals');
				}
			}
		);

		formlyRegisterHooks(formFields, 'liabilities.details.homeLoans.homeLoansModal.redrawAvailable', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(
			formFields,
			`liabilities.details.homeLoans.homeLoansModal.isLoanTaxDeductible`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openRefiModal(formFields, RefiModalName.isTaxDeductible, 'loanRequirements.info-modals');
				}
			}
		);

		formlyRegisterHooks(formFields, 'liabilities.details.homeLoans.homeLoansModal.isLoanTaxDeductible', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(formFields, 'liabilities.details', (field: FormlyFieldConfig, event: { type: string }) => {
			if (event.type === 'primary') {
				this.handleContinueButtonClick(formFields, field);
			}
			if (event.type === 'subText') {
				this.openRefiModal(formFields, RefiModalName.ccr).subscribe();
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details`, {
			'templateOptions.loading': (model, formState, field: FormlyFieldConfig) => {
				return field.templateOptions?.loading as boolean;
			}
		});

		let subscription: Subscription | undefined;
		formlyRegisterHooks(formFields, 'liabilities.details.summaryAmount', {
			afterContentInit: (field) => {
				if (field && field.templateOptions) {
					subscription = this.formDataService.select$(RefiStore.Liabilities).subscribe(() => {
						this.calculateSummary(field);
					});
				}
			},
			onDestroy: () => {
				if (subscription) {
					subscription.unsubscribe();
				}
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.homeLoans', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					if (field.fieldGroup) {
						return this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.Liabilities)?.subscribe(() => {
							const modelIds = [] as number[];
							field.fieldGroup?.forEach((f) => {
								const model = f.model as unknown as RefiHomeLoanModel;
								if (model?.homeLoansModal.isMainLoan) {
									modelIds.push(model.id as number);
								}
								if (f.fieldGroup) {
									const accountNumber = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'accountNumber');
									if (accountNumber?.templateOptions) {
										accountNumber.templateOptions.readonly =
											!model?.homeLoansModal.isUserDeclared && !!model?.homeLoansModal.accountNumber;
									}
									const loanLimit = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'loanLimit');
									if (loanLimit?.templateOptions) {
										loanLimit.templateOptions.readonly =
											!model?.homeLoansModal.isUserDeclared && !!model?.homeLoansModal.loanLimit;
									}
									const finInstitution = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'currentLender');
									if (finInstitution?.templateOptions) {
										finInstitution.templateOptions.readonly =
											!model?.homeLoansModal.isUserDeclared && !!model?.homeLoansModal.currentLender;
									}
								}
							});

							const lowestId = Math.min(...modelIds);
							const index = modelIds.findIndex((x) => x == lowestId);
							field.fieldGroup?.forEach((f, i) => {
								if (i === index && f.fieldGroup?.length && f.fieldGroup[0].templateOptions) {
									f.fieldGroup[0].templateOptions.showDeleteButton = false; // Remove this to metadata once showDeleteButton is made dynamic
								}
							});
						});
					}
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.creditCards', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					if (field.fieldGroup) {
						return this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.Liabilities)?.subscribe(() => {
							field.fieldGroup?.forEach((f) => {
								const model = f.model as unknown as RefiCreditCardModel;
								if (f.fieldGroup) {
									const cardLimit = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'cardLimit');
									if (cardLimit?.templateOptions) {
										cardLimit.templateOptions.readonly =
											!model?.creditCardsModal.isUserDeclared && !!model?.creditCardsModal.cardLimit;
									}
									const cardNumber = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'cardNumber');
									if (cardNumber?.templateOptions) {
										cardNumber.templateOptions.readonly =
											!model?.creditCardsModal.isUserDeclared && !!model?.creditCardsModal.cardNumber;
									}
									const finInstitution = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'financialInstitution');
									if (finInstitution?.templateOptions) {
										finInstitution.templateOptions.readonly =
											!model?.creditCardsModal.isUserDeclared && !!model?.creditCardsModal.financialInstitution;
									}
								}
							});
						});
					}
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.personalLoans', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					if (field.fieldGroup) {
						return this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.Liabilities)?.subscribe(() => {
							field.fieldGroup?.forEach((f) => {
								const model = f.model as unknown as RefiPersonalLoanModel;
								if (f.fieldGroup) {
									const creditLimit = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'creditLimit');
									if (creditLimit?.templateOptions) {
										creditLimit.templateOptions.readonly =
											!model?.personalLoansModal.isUserDeclared && !!model?.personalLoansModal.creditLimit;
									}
									const accountNumber = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'accountNumber');
									if (accountNumber?.templateOptions) {
										accountNumber.templateOptions.readonly =
											!model?.personalLoansModal.isUserDeclared && !!model?.personalLoansModal.accountNumber;
									}
									const finInstitution = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'currentLender');
									if (finInstitution?.templateOptions) {
										finInstitution.templateOptions.readonly =
											!model?.personalLoansModal.isUserDeclared && !!model?.personalLoansModal.currentLender;
									}
								}
							});
						});
					}
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.otherLiabilities', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					if (field.fieldGroup) {
						return this.simpFormlyHandlerService.mapToFullStateDataPath(RefiStore.Liabilities)?.subscribe(() => {
							field.fieldGroup?.forEach((f) => {
								const model = f.model as unknown as RefiOtherLiabilityModel;
								if (f.fieldGroup) {
									const creditLimit = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'creditLimit');
									if (creditLimit?.templateOptions) {
										creditLimit.templateOptions.readonly =
											!model?.otherLiabilitiesModal.isUserDeclared && !!model?.otherLiabilitiesModal.creditLimit;
									}
									const accountNumber = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'accountNumber');
									if (accountNumber?.templateOptions) {
										accountNumber.templateOptions.readonly =
											!model?.otherLiabilitiesModal.isUserDeclared && !!model?.otherLiabilitiesModal.accountNumber;
									}
									const finInstitution = f.fieldGroup[0].fieldGroup?.find((x) => x.key === 'currentLender');
									if (finInstitution?.templateOptions) {
										finInstitution.templateOptions.readonly =
											!model?.otherLiabilitiesModal.isUserDeclared && !!model?.otherLiabilitiesModal.currentLender;
									}
								}
							});
						});
					}
				}
				return of();
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.homeLoans.homeLoansModal`, {
			'templateOptions.hideModalFooter': (model: RefiHomeLoanDetails, formState: unknown, field: FormlyFieldConfig) => {
				field.fieldGroup?.forEach((fieldObject) => {
					if (fieldObject.templateOptions) {
						fieldObject.templateOptions.disabled = model?.readonly;
					}
				});
				return (
					(model?.reconcileRequired && model?.reconcileAction !== Action.Add) ||
					model?.readonly ||
					this.refiJourneyService.isAdminUser ||
					false
				);
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.personalLoans.personalLoansModal`, {
			'templateOptions.hideModalFooter': (
				model: RefiPersonalLoanDetails,
				formState: unknown,
				field: FormlyFieldConfig
			) => {
				field.fieldGroup?.forEach((fieldObject) => {
					if (fieldObject.templateOptions) {
						fieldObject.templateOptions.disabled = model?.readonly;
					}
				});
				return (
					(model?.reconcileRequired && model?.reconcileAction !== Action.Add) ||
					model?.readonly ||
					this.refiJourneyService.isAdminUser ||
					false
				);
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.otherLiabilities.otherLiabilitiesModal`, {
			'templateOptions.hideModalFooter': (
				model: RefiOtherLiabilityDetails,
				formState: unknown,
				field: FormlyFieldConfig
			) => {
				field.fieldGroup?.forEach((fieldObject) => {
					if (fieldObject.templateOptions) {
						fieldObject.templateOptions.disabled = model?.readonly;
					}
				});
				return (
					(model?.reconcileRequired && model?.reconcileAction !== Action.Add) ||
					model?.readonly ||
					this.refiJourneyService.isAdminUser ||
					false
				);
			}
		});

		formlyExtendExpressionProperties(formFields, `liabilities.details.creditCards.creditCardsModal`, {
			'templateOptions.hideModalFooter': (
				model: RefiCreditCardDetails,
				formState: unknown,
				field: FormlyFieldConfig
			) => {
				field.fieldGroup?.forEach((fieldObject) => {
					if (fieldObject.templateOptions) {
						fieldObject.templateOptions.disabled = model?.readonly;
					}
				});
				return (
					(model?.reconcileRequired && model?.reconcileAction !== Action.Add) ||
					model?.readonly ||
					this.refiJourneyService.isAdminUser ||
					false
				);
			}
		});

		formlyRegisterHooks(formFields, 'liabilities.details.creditCards.creditCardsModal.ownership', {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field?.templateOptions?.options && typeof field?.templateOptions.options === 'string') {
					field.templateOptions.options = this.formEnumQuery
						.getOptions(field.templateOptions.options as unknown as string)
						.filter((x) => x.id !== CONSTANTS.JointOwnership);
				}
				return of();
			}
		});

		disableFutureDates(
			formFields,
			'liabilities.details.homeLoans.homeLoansModal.remainingLoanDetails.loanEstablishmentDate'
		);
		disableFutureDates(
			formFields,
			'liabilities.details.personalLoans.personalLoansModal.remainingLoanDetails.loanEstablishmentDate'
		);
		disableFutureDates(
			formFields,
			'liabilities.details.otherLiabilities.otherLiabilitiesModal.remainingLoanDetails.loanEstablishmentDate'
		);
	}

	selectFirstApplicantForOwnership(formFields: FormlyFieldConfig[], path: string) {
		const ownershipField = getFormField(formFields, path) as FormlyFieldConfig;
		if (ownershipField && ownershipField.templateOptions) {
			ownershipField.templateOptions.selectFirstOption = true;
		}
	}
	private matchRecord(field: FormlyFieldConfig): void {
		const currentModel = get(field?.parent?.parent, 'model') as CreditCardSummaryItems;
		const allSummaryItems = get(field?.parent?.parent?.parent?.parent, 'model') as CreditCardSummary[];
		this.reconcileService
			.matchRecord('liabilities', currentModel?.items?.id as number, allSummaryItems?.[0].summary?.items?.id as number)
			.subscribe(() => {
				this.reconcileService.showToast('Item matched');
				this.refiLiabilitiesService.fetchLiabilities();
				this.simpFormlyModalService.openExistingModal$.next(null);
			});
	}

	private calculateSummary(field: FormlyFieldConfig): void {
		const model = field.model as RefiLiabilitiesModel;
		const [homeLoans, personalLoans, creditCards, otherLiabilities] = [
			model.homeLoans,
			model.personalLoans as RefiPersonalLoanModel[],
			model.creditCards as RefiCreditCardModel[],
			model.otherLiabilities as RefiOtherLiabilityModel[]
		];
		this.totalLiabilities = 0;
		if (homeLoans?.length > 0) {
			this.totalLiabilities += homeLoans.reduce(
				(x, y) => x + (y?.homeLoansModal.loanBalance ? y.homeLoansModal.loanBalance : 0),
				0
			);
		}

		if (personalLoans?.length > 0) {
			this.totalLiabilities += personalLoans.reduce(
				(x, y) => x + (y?.personalLoansModal.currentBalance ? y.personalLoansModal.currentBalance : 0),
				0
			);
		}

		if (creditCards?.length > 0) {
			this.totalLiabilities += creditCards.reduce(
				(x, y) => x + (y?.creditCardsModal.cardLimit ? y.creditCardsModal.cardLimit : 0),
				0
			);
		}

		if (otherLiabilities?.length > 0) {
			this.totalLiabilities += otherLiabilities.reduce(
				(x, y) => x + (y?.otherLiabilitiesModal.balance ? y.otherLiabilitiesModal.balance : 0),
				0
			);
		}

		if (this.totalLiabilities > 0) {
			field.className = '';
			model.summaryAmount = `<span class="simp-text--bold">${formatCurrency(
				this.totalLiabilities,
				'en-AU',
				'$'
			)}</span> total liabilities`;
		} else {
			field.className = 'hidden';
			if (this.sharedFlagService.currentJourney === JourneyType.Refi) {
				this.journeyStepService
					.updateStep(RefiStepType.RefiLiabilities, StepStatus.Incomplete)
					.pipe(takeUntil(this.destroy$))
					.subscribe();
			}
		}
	}

	private saveHomeLoan(model: RefiHomeLoanModel[], index: number): void {
		if (model) {
			const payload = model[index];
			this.formDataService.setLoading(RefiStore.Liabilities, true);
			this.refiLiabilitiesService.saveHomeLoan(payload).subscribe({
				next: (res) => {
					if (res) {
						this.refiLiabilitiesService.fetchLiabilities();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
			});
		}
	}

	private savePersonalLoan(model: RefiPersonalLoanModel[], index: number): void {
		if (model) {
			const payload = model[index];
			this.formDataService.setLoading(RefiStore.Liabilities, true);
			this.refiLiabilitiesService.savePersonalLoan(payload).subscribe({
				next: (res) => {
					if (res) {
						this.refiLiabilitiesService.fetchLiabilities();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
			});
		}
	}

	private saveCreditCard(model: RefiCreditCardModel[], index: number): void {
		if (model) {
			const payload = model[index];
			this.formDataService.setLoading(RefiStore.Liabilities, true);
			this.refiLiabilitiesService.saveCreditCard(payload).subscribe({
				next: (res) => {
					if (res) {
						this.refiLiabilitiesService.fetchLiabilities();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
			});
		}
	}

	private saveOtherLiability(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as RefiOtherLiabilityModel[];

		if (model) {
			const payload = model[index];
			this.formDataService.setLoading(RefiStore.Liabilities, true);
			this.refiLiabilitiesService.saveOtherLiability(payload).subscribe({
				next: (res) => {
					if (res) {
						this.refiLiabilitiesService.fetchLiabilities();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
			});
		}
	}

	private deleteHomeLoan(id: number): void {
		this.formDataService.setLoading(RefiStore.Liabilities, true);
		this.refiLiabilitiesService.deleteHomeLoan(id).subscribe({
			next: () => {
				this.refiLiabilitiesService.fetchLiabilities();
			},
			error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
		});
	}

	private deletePersonalLoan(id: number): void {
		this.formDataService.setLoading(RefiStore.Liabilities, true);
		this.refiLiabilitiesService.deletePersonalLoan(id).subscribe({
			next: () => {
				this.refiLiabilitiesService.fetchLiabilities();
			},
			error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
		});
	}

	private deleteCreditCard(id: number): void {
		this.formDataService.setLoading(RefiStore.Liabilities, true);
		this.refiLiabilitiesService.deleteCreditCard(id).subscribe({
			next: () => {
				this.refiLiabilitiesService.fetchLiabilities();
			},
			error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
		});
	}

	private deleteOtherLiability(id: number): void {
		this.formDataService.setLoading(RefiStore.Liabilities, true);
		this.refiLiabilitiesService.deleteOtherLiability(id).subscribe({
			next: () => {
				this.refiLiabilitiesService.fetchLiabilities();
			},
			error: () => this.formDataService.setLoading(RefiStore.Liabilities, false)
		});
	}

	private openRefiModal(
		formFields: FormlyFieldConfig[] | undefined,
		refiModalName: RefiModalName,
		path = 'liabilities.refi-modals'
	): Observable<string> {
		const refiModalField = getFormField(formFields, path);

		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}

	private handleContinueButtonClick(formFields: FormlyFieldConfig[], field: FormlyFieldConfig): void {
		if (this.refiJourneyService.isAdminUser) {
			this.refiJourneyService.navigateToNextStep(field);
			return;
		}

		if (!(field.parent && field.parent.formControl && field.parent.formControl.valid)) {
			return;
		}

		this.openRefiModal(formFields, RefiModalName.ConfirmWhatYouOwe).subscribe((action) => {
			if (action === 'primaryButton') {
				if (field.parent && field.parent.formControl && field.parent.formControl.valid) {
					switch (this.sharedFlagService.currentJourney) {
						case JourneyType.Refi:
							if (this.hasPrimaryHomeLoan(field) && this.hasCompletedPrerequisiteStepsForRefi()) {
								this.allStepsCompleted = true;
							}
							break;
						case JourneyType.Purchase:
							if (this.hasCompletedPrerequisiteStepsForPurchase()) {
								this.allStepsCompleted = true;
							}
							break;
						default:
							this.allStepsCompleted = false;
							break;
					}
					if (this.allStepsCompleted) {
						if (field.templateOptions) {
							field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
						}
						this.refiLiabilitiesService.withinMaximumBorrowingCapacity().subscribe((response) => {
							if (response.status) {
								if (field.templateOptions) {
									field.templateOptions.loading = field.templateOptions.disablePrimaryButton = false;
								}
								if (response.withinMbc) {
									this.journeyStepService.updateStep(RefiStepType.RefiLiabilities, StepStatus.Complete).subscribe();
									if (this.stepStatusQuery.isCompleted(RefiStepType.RefiSelectProduct)) {
										// Don't display popup if product selection step has already been completed. Proceed straight to next step.
										this.refiJourneyService.navigateToStep('products/details');
									} else {
										this.openRefiModal(formFields, RefiModalName.mbcGoodNews).subscribe((actionGoodNews) => {
											if (actionGoodNews === 'primaryButton') {
												this.refiJourneyService.navigateToStep('products/details');
											} else {
												setTimeout(() => {
													this.document.getElementById('primary-button')?.focus();
												}, 200);
											}
										});
									}
								} else {
									forkJoin([
										this.journeyStepService.updateStep(RefiStepType.RefiLiabilities, StepStatus.Incomplete),
										this.journeyStepService.updateStep(RefiStepType.RefiSelectProduct, StepStatus.Incomplete)
									]).subscribe();
									this.openRefiModal(formFields, RefiModalName.mbcTalkToUs).subscribe();
								}
							} else {
								this.refiJourneyService.displayErrorScreen('MBC response returned false');
							}
						});
					} else {
						forkJoin([
							this.journeyStepService.updateStep(RefiStepType.RefiLiabilities, StepStatus.Incomplete),
							this.journeyStepService.updateStep(RefiStepType.RefiSelectProduct, StepStatus.Incomplete)
						]).subscribe();
						this.openRefiModal(formFields, RefiModalName.missingInformation).subscribe(() =>
							this.refiJourneyService.navigateToStep('summary/details')
						);
					}
				}
			}
		});
	}

	private hasCompletedPrerequisiteStepsForRefi(): boolean {
		return (
			this.stepStatusQuery.isCompleted(RefiStepType.RefiLoanRequirements) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiIncome) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiExpenses) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiAssets)
		);
	}

	private hasCompletedPrerequisiteStepsForPurchase(): boolean {
		return (
			this.stepStatusQuery.isCompleted(RefiStepType.RefiLoanRequirements) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiIncome) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiExpenses) &&
			this.stepStatusQuery.isCompleted(RefiStepType.RefiAssets)
		);
	}

	private hasPrimaryHomeLoan(field: FormlyFieldConfig): boolean {
		const homeLoans = get(field, 'model[0].homeLoans') as RefiHomeLoanModel[];
		return !!homeLoans.find((hl) => hl.homeLoansModal.isMainLoan);
	}

	private updateListOptions(field: FormlyFieldConfig | undefined): void {
		if (field && field.templateOptions && field.templateOptions.handler) {
			const options = filterListOptions(
				this.formEnumQuery.getOptions('FinancialInstitution'),
				field.templateOptions.handler as string
			);
			field.templateOptions.options = options;

			const matchingOption = options.find((option) => option.id === field.formControl?.value);
			if (!matchingOption) {
				field.formControl?.setValue(null);
			}
		}
	}
}
