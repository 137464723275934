import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { AbnLookupTransformerService } from '@app/modules/abn-lookup/services/abn-lookup-transformer.service';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import {
	ApplicantEntityType,
	CountryCodeLimited,
	PreferredContactPerson,
	TargetType
} from '@app/modules/shared/enums/app.enums';
import { deletedSuccessfullyMessage, hasDuplicates, savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { RelatedEntitiesService } from '@app/modules/shared/service/related-entities.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnChange,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	getTemplateOptions
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	BankDetailsDTO,
	CompanyContactDetailsDTO,
	CompanyDirectorsDTO,
	CompanyShareholderDTO,
	CompanyTrustAddressesDTO,
	ContactPersonDTO
} from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { BusinessDetailsModel } from '../../models/business-details.model';
import {
	AuthorisedSignatoriesModel,
	AuthorisedSignatoriesTransformer
} from '../../models/company-authorised-signatories.model';
import {
	CompanyBeneficialOwnerModel,
	CompanyBeneficialOwnersTransformer
} from '../../models/company-beneficialowners.model';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanyDirectorsModel } from '../../models/company-directors.model';
import { CompanyShareholderModel } from '../../models/company-shareholder.model';
import { CompanyApplicantTypeModel } from '../../models/company-type.model';
import { SolicitorModel } from '../../models/solicitor.model';
import { SourceOfWealthModel } from '../../models/source-of-wealth.model';
import { SourceOfWealthService } from '../source-of-wealth.service';
import { CompanyApplicantsService } from './company-applicants.service';

@Injectable({ providedIn: 'root' })
export class CompanyApplicantsTransformerService {
	constructor(
		private companyApplicantsService: CompanyApplicantsService,
		private relatedEntitiesService: RelatedEntitiesService,
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsQuery: FormEnumsQuery,
		private confirmationDialogService: SimpConfirmationDialogService,
		private formDataService: FormDataService,
		private toastr: ToastrService,
		private formEnumsService: FormEnumsService,
		private abnLookupTransformerService: AbnLookupTransformerService,
		private sourceOfWealthService: SourceOfWealthService
	) {}

	transform(formFields: FormlyFieldConfig[], index: number): FormlyFieldConfig[] {
		const companyApplicant = this.applicationDataQuery.getCompanyApplicants()[index];
		this.updateLabel(formFields, index);
		this.configureCompanyDetails(formFields);
		this.configureBusinessDetails(formFields);
		this.configureContactPerson(formFields);
		this.configureAddresses(formFields);
		this.configureSourceOfWealth(formFields, companyApplicant.id);
		this.configureBankingDetails(formFields, companyApplicant.id);
		this.configureDirectors(formFields, companyApplicant.id);
		this.configureShareholders(formFields, companyApplicant.id);
		this.configureBeneficialOwners(formFields, companyApplicant.id);
		this.configureAuthorisedSignatories(formFields, companyApplicant.id);
		this.configureSolicitor(formFields, companyApplicant.id);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'companyApplicant');

		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.type`,
			(field, event: FormlyArrayUpdateEvent<CompanyApplicantTypeModel>) => {
				const model = get(field, `model[${event.index}]`) as CompanyApplicantTypeModel;
				const clonedModel = cloneDeep(model);
				clonedModel.id = this.getApplicantId(formFields, index);
				clonedModel.companyName = this.getApplicantName(formFields, index);
				this.companyApplicantsService.saveCompanyType(clonedModel).subscribe((id: number) => {
					this.toastr.success(savedSuccessfullyMessage('Company role'));
					model.id = id;
				});
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.contactDetails`,
			(field, event: FormlyArrayUpdateEvent<CompanyContactDetailsDTO>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as CompanyContactDetailsDTO;
				model.applicationId = model.applicationId ?? this.applicationDataQuery.applicationId();
				model.id = this.getApplicantId(formFields, index);
				this.companyApplicantsService.saveContactDetails(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState('contactDetails', model, 0);
					this.toastr.success(savedSuccessfullyMessage('Contact details'));
				});
			}
		);

		formlyOnClick(formFields, 'companyApplicant.contactPerson.deleteContactPerson', (field: FormlyFieldConfig) => {
			formlyDeleteFromArray(field);
		});

		formlyRegisterHooks(formFields, `companyApplicant.relatedPerson.preferredContact`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField && formField.templateOptions) {
					const listItems = this.formEnumsService
						.fetch('PreferredContactPerson')
						.pipe(
							map((options: EnumObject[]) => options.filter((option) => option.id !== PreferredContactPerson.HomePhone))
						);
					formField.templateOptions.options = listItems;
				}
			}
		});

		return formFields;
	}

	private updateLabel(formFields: FormlyFieldConfig[], index: number) {
		const sideNavData = this.applicationDataQuery.getCompanyApplicantsSideNavInfo(index);
		const companyApplicantTO = getTemplateOptions(formFields, 'companyApplicant');
		companyApplicantTO.label = sideNavData.linkText;
	}

	private getApplicantId(formFields: FormlyFieldConfig[], index: number) {
		const allApplicants = this.formDataService.getSetupApplicants();
		const companyApplicantsOnly: SetUpApplicant[] = allApplicants.filter(
			(x) => x.companyName !== '' && x.companyName !== undefined
		);
		return companyApplicantsOnly[index].id;
	}

	private getApplicantName(formFields: FormlyFieldConfig[], index: number) {
		const allApplicants = this.formDataService.getSetupApplicants();
		const companyApplicantsOnly: SetUpApplicant[] = allApplicants.filter(
			(x) => x.companyName !== '' && x.companyName !== undefined
		);
		return companyApplicantsOnly[index].companyName;
	}

	private configureCompanyDetails(formFields: FormlyFieldConfig[]) {
		formlyOnClick(formFields, 'companyApplicant.companyDetails.abn', this.handleAbnSearch.bind(this));
		formlyOnClick(formFields, 'companyApplicant.companyDetails.acn', this.handleAbnSearch.bind(this));
		formlyOnClick(formFields, 'companyApplicant.companyDetails.businessName', this.handleAbnSearch.bind(this));
		formlyOnClick(formFields, 'companyApplicant.companyDetails.companyName', this.handleAbnSearch.bind(this));

		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.companyDetails`,
			(field, event: FormlyArrayUpdateEvent<CompanyDetailsModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as CompanyDetailsModel;
				this.companyApplicantsService.saveCompanyDetails(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState(`companyDetails`, model, 0);

					//UPDATE STORE TO REFLECT COMPANY NAME CHANGES
					const storeData = cloneDeep(this.formDataService.getSetupApplicants());
					const index = storeData.findIndex((x) => x.id === model.id);
					if (storeData[index].companyName !== model.companyName) {
						storeData[index].companyName = model.companyName;
						this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[index], index);

						//UPDATE SECTION TITLE
						const section = field.parent;
						if (section?.templateOptions) {
							section.templateOptions.label = model.companyName;
						}
					}
					this.toastr.success(savedSuccessfullyMessage('Company details'));
				});
			}
		);

		formlyOnClick(formFields, `companyApplicant.companyDetails.registeredForGST`, (field: FormlyFieldConfig) => {
			field.parent?.formControl?.get('gstRegisteredDate')?.setValue(null);
			field.parent?.formControl?.get('gstRegisteredDate')?.markAsPristine();
			field.parent?.formControl?.get('gstRegisteredDate')?.markAsUntouched();
		});

		formlyOnChange(formFields, `companyApplicant.companyDetails.registeredInCountry`, (field: FormlyFieldConfig) => {
			field.formControl?.value !== CountryCodeLimited.AU
				? field.parent?.formControl?.get('registeredInState')?.setValue(null)
				: null;
		});
	}

	private configureContactPerson(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.contactPerson`,
			(field, event: FormlyArrayUpdateEvent<ContactPersonDTO>) => {
				//CALL API SUBSCRIBE FROM HERE BY PASSING PAYLOAD
				const model = cloneDeep(get(field, `model[${event.index}]`)) as ContactPersonDTO;
				this.companyApplicantsService.saveContactPerson(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState(`contactPerson`, model, 0);
					this.toastr.success(savedSuccessfullyMessage('Contact person'));
				});
			}
		);

		formlyRegisterHooks(formFields, 'companyApplicant.contactPerson.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = field.templateOptions.options as EnumObject[];
					const listItems = this.formEnumsQuery.selectEnumOptions('Persons').pipe(map((x) => [...fieldOptions, ...x]));
					field.templateOptions.options = listItems;
				}
				return of();
			}
		});
	}

	private configureBusinessDetails(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(formFields, `companyApplicant.businessDetails`, (field) => {
			const model = field?.formControl?.value as BusinessDetailsModel[];
			this.companyApplicantsService.saveBusinessDetails(model[0]).subscribe(() => {
				this.simpFormlyHandlerService.updateToState(`businessDetails`, model[0], 0);
				this.toastr.success(savedSuccessfullyMessage('Business details'));
			});
		});
	}

	private configureAddresses(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.addressess`,
			(field, event: FormlyArrayUpdateEvent<CompanyTrustAddressesDTO>) => {
				const model = get(field, `model[${event.index}]`) as CompanyTrustAddressesDTO;
				this.companyApplicantsService.saveCompanyAddressess(model).subscribe((id: number) => {
					this.toastr.success(savedSuccessfullyMessage('Address'));
				});
			}
		);
	}

	private configureBankingDetails(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.bankingDetails`,
			(field, event: FormlyArrayUpdateEvent<BankDetailsDTO>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`) as BankDetailsDTO);
				model.applicantId = applicantId;
				this.companyApplicantsService.saveCompanyBankingDetails(model).subscribe((id: number) => {
					model.id = id;
					this.simpFormlyHandlerService.updateToState(`bankingDetails`, model, event.index!);
					this.toastr.success(savedSuccessfullyMessage('Banking details'));
				});
			}
		);

		formlyOnClick(formFields, `companyApplicant.bankingDetails.delete`, (field: FormlyFieldConfig) => {
			const bankDetailsModel = field.model as BankDetailsDTO;
			if (!bankDetailsModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}
			const bankName = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				bankDetailsModel.financialInstitution
			);

			const message = `The account <b>${
				bankDetailsModel.accountNumber ?? ''
			}</b> held with <b>${bankName}</b> will be deleted.`;

			this.confirmationDialogService
				.deleteConfirmation('Delete bank account?', message, 'Delete', 'Cancel')
				.subscribe((result) => {
					if (result) {
						const rowIndex = Number(field.parent?.key);
						this.companyApplicantsService.deleteCompanyBankingDetails(bankDetailsModel).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState(`banking`, rowIndex);
							this.toastr.success(deletedSuccessfullyMessage('Bank account'));
						});
					}
				});
		});
	}

	private handleAbnSearch(formField: FormlyFieldConfig, action: string): void {
		this.abnLookupTransformerService.handleCompanyApplicantAbnSearch(formField, action);
	}

	private saveShareholder = (personnelsModel: CompanyShareholderModel, personnelIndex: number, applicantId: number) =>
		this.companyApplicantsService.saveShareHolder(applicantId, personnelsModel).subscribe((id: number) => {
			personnelsModel.id = id;
			this.toastr.success(savedSuccessfullyMessage('Shareholder'));
			this.simpFormlyHandlerService.updateToState(`shareHolders`, personnelsModel, personnelIndex || 0);
		});

	private saveDirector = (personnelsModel: CompanyDirectorsModel, personnelIndex: number, applicantId: number) =>
		this.companyApplicantsService.saveDirectors(applicantId, personnelsModel).subscribe((id: number) => {
			personnelsModel.id = id;
			this.toastr.success(savedSuccessfullyMessage('Director'));
			this.simpFormlyHandlerService.updateToState(`directors`, personnelsModel, personnelIndex || 0);
		});

	private saveAuthorisedSignatory = (
		personnelsModel: AuthorisedSignatoriesModel,
		personnelIndex: number,
		applicantId: number
	) => {
		const payload = AuthorisedSignatoriesTransformer.toPayload(
			personnelsModel,
			applicantId,
			this.applicationDataQuery.applicationId()
		);

		return this.companyApplicantsService.saveAuthorisedSignatories(applicantId, payload).subscribe((id: number) => {
			personnelsModel.id = id;
			this.toastr.success(savedSuccessfullyMessage('Authorised signatories'));
			this.simpFormlyHandlerService.updateToState(`authorisedSignatories`, personnelsModel, personnelIndex || 0);
		});
	};

	private saveBeneficialOwner = (
		personnelsModel: CompanyBeneficialOwnerModel,
		personnelIndex: number,
		applicantId: number
	) => {
		const payload = CompanyBeneficialOwnersTransformer.toPayload(applicantId, personnelsModel);
		return this.companyApplicantsService.saveBeneficialOwner(payload).subscribe((id: number) => {
			personnelsModel.id = id;
			this.toastr.success(savedSuccessfullyMessage('Beneficial owner'));
			this.simpFormlyHandlerService.updateToState(`beneficialOwners`, personnelsModel, personnelIndex || 0);
		});
	};

	private configureDirectors(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.directors`,
			(field, event: FormlyArrayUpdateEvent<CompanyDirectorsDTO>) => {
				const model = Object.assign({}, get(field, `model[${event.index}]`)) as CompanyDirectorsModel;
				model.applicationId = this.applicationDataQuery.applicationId();

				if (model.existingSelect && model.existingSelect.id !== -1) {
					this.saveDirector(model, event.index || 0, applicantId);
				}
			}
		);

		formlyRegisterHooks(formFields, 'companyApplicant.directors.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					const data$ = this.simpFormlyHandlerService.mapToStateData<CompanyDirectorsModel[]>('directors');
					if (data$) {
						const listItems = combineLatest([this.formEnumsQuery.selectEnumOptions('Persons'), data$]).pipe(
							map(([x, directors]) => {
								const model = field.model as CompanyDirectorsModel;
								const existingDirectors = directors
									.filter((di) => di?.existingSelect?.id !== model?.existingSelect?.id)
									.map((di) => {
										if ([-1, -2].includes(di?.existingSelect?.id)) {
											return di.personId;
										}
										return di?.existingSelect?.id;
									});
								return [...fieldOptions, ...x]
									.filter((di) => !existingDirectors.includes(di.id))
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															field,
															applicantId,
															this.saveDirector
														)
												: null
									}));
							})
						);
						field.templateOptions.options = listItems;
					}
					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveDirector);
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'companyApplicant.directors', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const directors = (c.value || []) as CompanyDirectorsModel[];
					const existingDirectors = directors
						.map((di) => {
							if (!!di?.existingSelect && di?.existingSelect?.id > 0) {
								return di?.existingSelect.id ?? 0;
							}
							return di?.personId || 0;
						})
						.filter((x) => x > 0);

					return !hasDuplicates(existingDirectors);
				}
			}
		});

		formlyOnClick(formFields, 'companyApplicant.directors.deleteDirector', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as CompanyDirectorsModel;
			const rowIndex = Number(field.parent?.key);
			this.companyApplicantsService.deleteDirectors(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`directors`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Director'));
			});
		});
	}

	private configureShareholders(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.shareHolders`,
			(field, event: FormlyArrayUpdateEvent<CompanyDirectorsDTO>) => {
				const model = Object.assign({}, get(field, `model[${event.index}]`)) as CompanyShareholderModel;
				model.applicationId = this.applicationDataQuery.applicationId();

				if (model.existingSelect?.id !== -1 && model.existingSelect?.id !== -2) {
					this.saveShareholder(model, event.index || 0, applicantId);
				}
			}
		);

		formlyRegisterHooks(formFields, 'companyApplicant.shareHolders.shareholding', {
			onInit: (field) => {
				if (field !== undefined) {
					const model = field.parent?.model as CompanyShareholderModel;
					if (model.existingSelect?.id === undefined) {
						field?.formControl?.patchValue(this.getAvailableShareHoldingValue(field));
					}
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'companyApplicant.shareHolders', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const value = c.value as CompanyShareholderModel[];
					let total = 0;
					if (value) {
						const percentages = value.map((per) => {
							return per.shareholding ?? 0;
						});
						total = percentages.reduce((a, b) => a + b, 0);
					}

					return total <= 100;
				}
			},
			uniqueEmail: {
				//TODO :- talk with Col to change it to 'unique' only
				expression: (c: UntypedFormArray) => {
					const shareHolders = (c.value || []) as CompanyShareholderModel[];
					const existingShareHolders = shareHolders
						.map((di) => {
							if (!!di?.existingSelect && di?.existingSelect.id > 0) {
								return di.existingSelect.id;
							}
							return di.partyId || 0;
						})
						.filter((x) => x > 0);

					return !hasDuplicates(existingShareHolders);
				}
			}
		});

		formlyRegisterHooks(formFields, 'companyApplicant.shareHolders.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					const data$ = this.simpFormlyHandlerService.mapToStateData<CompanyShareholderModel[]>('shareHolders');
					if (data$) {
						const listItems = combineLatest([
							this.formEnumsQuery.selectEnumOptions('Persons'),
							this.formEnumsQuery.selectEnumOptions('OtherCompanies'),
							data$
						]).pipe(
							map(([x, y, shareholders]) => {
								const model = field.model as CompanyShareholderModel;
								const existingShareholders = shareholders
									.filter((di) => di?.existingSelect?.id !== model?.existingSelect?.id)
									.map((di) => {
										if ([-1, -2].includes(di?.existingSelect?.id)) {
											return di.partyId;
										}
										return di?.existingSelect?.id;
									});
								return [...fieldOptions, ...x, ...y]
									.filter((di) => !existingShareholders.includes(di.id))
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson ||
											(person.type as unknown as TargetType) === TargetType.RelatedCompany
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															field,
															applicantId,
															this.saveShareholder
														)
												: null
									}));
							})
						);
						field.templateOptions.options = listItems;
					}
					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveShareholder);
				}
				return of();
			}
		});

		formlyOnClick(formFields, 'companyApplicant.shareHolders.deleteShareholder', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as CompanyShareholderDTO;
			const rowIndex = Number(field.parent?.key);
			this.companyApplicantsService.deleteShareHolder(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`shareHolders`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Shareholder'));
			});
		});
	}

	private getTotalShareHoldingValue(field: FormlyFieldConfig) {
		let currentTotalShareHolding = 0;
		const formArray = field?.parent?.parent?.fieldGroup as unknown as Array<FormlyFieldConfig>;
		formArray.forEach((form) => {
			const formControl = form.formControl?.value as CompanyShareholderModel;
			currentTotalShareHolding += formControl.shareholding;
		});
		return currentTotalShareHolding;
	}

	private getAvailableShareHoldingValue(field: FormlyFieldConfig) {
		const maxPercentage = 100;
		return maxPercentage - this.getTotalShareHoldingValue(field);
	}

	private configureBeneficialOwners(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyRegisterHooks(formFields, 'companyApplicant.beneficialOwners.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					const data$ = this.simpFormlyHandlerService.mapToStateData<CompanyBeneficialOwnerModel[]>('beneficialOwners');
					if (data$) {
						const listItems = combineLatest([this.formEnumsQuery.selectEnumOptions('Persons'), data$]).pipe(
							map(([x, beneficialOwners]) => {
								const model = field.model as CompanyBeneficialOwnerModel;

								const existingBeneficialOwners = beneficialOwners
									.filter((bo) => bo?.existingSelect?.id !== model?.existingSelect?.id)
									.map((bo) => {
										if ([-1, -2].includes(bo?.existingSelect?.id)) {
											return bo.personId;
										}
										return bo?.existingSelect?.id;
									});
								return [...fieldOptions, ...x]
									.filter((bo) => !existingBeneficialOwners.includes(bo.id))
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															field,
															applicantId,
															this.saveBeneficialOwner
														)
												: null
									}));
							})
						);
						field.templateOptions.options = listItems;
					}
					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveBeneficialOwner);
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'companyApplicant.beneficialOwners', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const beneficialOwners = (c.value || []) as CompanyBeneficialOwnerModel[];

					const existingBeneficialOwners = beneficialOwners
						.map((di) => {
							if (!!di.existingSelect && di.existingSelect.id > 0) {
								return di.existingSelect.id;
							}
							return di.personId || 0;
						})
						.filter((x) => x > 0);

					return !hasDuplicates(existingBeneficialOwners);
				}
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.beneficialOwners`,
			(field, event: FormlyArrayUpdateEvent<CompanyBeneficialOwnerModel>) => {
				//CALL API SUBSCRIBE FROM HERE BY PASSING PAYLOAD
				const model = Object.assign({}, get(field, `model[${event.index}]`)) as CompanyBeneficialOwnerModel;
				model.applicationId = this.applicationDataQuery.applicationId();

				if (model.existingSelect.id != -1) {
					this.saveBeneficialOwner(model, event.index || 0, applicantId);
				}
			}
		);

		formlyOnClick(formFields, 'companyApplicant.beneficialOwners.deleteBeneficialOwner', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as CompanyBeneficialOwnerModel;
			const rowIndex = Number(field.parent?.key);
			this.companyApplicantsService.deleteBeneficialOwner(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`beneficialOwners`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Beneficial owner'));
			});
		});
	}

	private configureAuthorisedSignatories(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyRegisterHooks(formFields, 'companyApplicant.authorisedSignatories.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					const data$ =
						this.simpFormlyHandlerService.mapToStateData<AuthorisedSignatoriesModel[]>('authorisedSignatories');
					if (data$) {
						const listItems = combineLatest([this.formEnumsQuery.selectEnumOptions('Persons'), data$]).pipe(
							map(([x, shareHolders]) => {
								const model = field.model as AuthorisedSignatoriesModel;
								const existingSignatories = shareHolders
									.filter((au) => au?.existingSelect?.id !== model?.existingSelect?.id)
									.map((as) => {
										if ([-1, -2].includes(as.existingSelect?.id ?? 0)) {
											return as.personId;
										}
										return as.existingSelect?.id;
									});
								return [...fieldOptions, ...x]
									.filter((as) => !existingSignatories.includes(as.id))
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															field,
															applicantId,
															this.saveAuthorisedSignatory
														)
												: null
									}));
							})
						);
						field.templateOptions.options = listItems;
					}
					return this.relatedEntitiesService.configureRelatedEntityModal(
						field,
						applicantId,
						this.saveAuthorisedSignatory
					);
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'companyApplicant.authorisedSignatories', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const authorisedSignatories = (c.value || []) as AuthorisedSignatoriesModel[];
					const existingAuthorisedSignatories = authorisedSignatories
						.map((di) => {
							if (!!di.existingSelect && di.existingSelect.id > 0) {
								return di.existingSelect.id;
							}
							return di.personId || 0;
						})
						.filter((x) => x > 0);

					return !hasDuplicates(existingAuthorisedSignatories);
				}
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.authorisedSignatories`,
			(field, event: FormlyArrayUpdateEvent<AuthorisedSignatoriesModel>) => {
				const model = get(field, `model[${event.index}]`) as AuthorisedSignatoriesModel;

				if (model.existingSelect && model.existingSelect?.id !== -1) {
					this.saveAuthorisedSignatory(model, event.index || 0, applicantId);
				}
			}
		);

		formlyOnClick(formFields, 'companyApplicant.authorisedSignatories.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as AuthorisedSignatoriesModel;
			const rowIndex = Number(field.parent?.key);
			this.companyApplicantsService.deleteAuthorisedSignatories(applicantId, model).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`authorisedSignatories`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Authorised signatories'));
			});
		});
	}

	private configureSolicitor(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyRegisterHooks(formFields, 'companyApplicant.solicitor.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const solicitorSelect = getFormField(formFields, 'companyApplicant.solicitor.existingSelect');

					const addNewCompany = this.formEnumsQuery.getAddOptions(
						solicitorSelect?.templateOptions?.options as unknown as string
					);
					addNewCompany[0].id = -2;

					const listItems = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
						map((x) => {
							if (x) {
								return [...addNewCompany, ...x].map((company) => ({
									...company,
									click:
										(company.type as unknown as TargetType) === TargetType.RelatedCompany
											? () =>
													this.relatedEntitiesService.openPrefilledRelatedEntityModal(
														field,
														applicantId,
														this.saveSolicitor
													)
											: null
								}));
							} else {
								return addNewCompany;
							}
						})
					);
					field.templateOptions.options = listItems;

					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveSolicitor);
				}
				return of();
			}
		});

		this.handleSolicitorChange(formFields, applicantId);

		// Handle delete
		formlyOnClick(formFields, 'companyApplicant.solicitor.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as SolicitorModel;
			const rowIndex = Number(field.parent?.key);
			this.companyApplicantsService.deleteSolicitor(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`solicitor`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Solicitor'));
			});
		});
	}

	private handleSolicitorChange(formFields: FormlyFieldConfig[], applicantId: number): void {
		formlyOnStatusChangedToValid(formFields, 'companyApplicant.solicitor', (field: FormlyFieldConfig) => {
			const model = field.model as SolicitorModel[];
			if (model[0].existingSelect.id === -2) {
				return;
			}
			this.saveSolicitor(model[0], 0, applicantId);
		});
	}

	private saveSolicitor = (model: SolicitorModel, personnelIndex: number, applicantId: number) => {
		return this.companyApplicantsService
			.saveSolicitor({
				applicationId: this.applicationDataQuery.applicationId(),
				solicitorId: model.existingSelect.id,
				solicitorName: model.existingSelect?.companyName,
				applicantId
			})
			.pipe(take(1))
			.subscribe(() => {
				this.simpFormlyHandlerService.updateToState('solicitor', model, 0);
				this.toastr.success(savedSuccessfullyMessage('Solicitor'));
			});
	};

	private configureSourceOfWealth(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		this.sourceOfWealthTitleUpdate(formFields);
		this.handleSourceOfWealthDeleteButtonClick(formFields);
		this.handleSourceOfWealthSave(formFields, applicantId);
	}

	private sourceOfWealthTitleUpdate(formFields: FormlyFieldConfig[] | undefined) {
		const sourceOfIncomeTypeField = getFormField(
			formFields,
			`companyApplicant.sourceOfWealth.typeId`
		) as FormlyFieldConfig;
		formlyExtendExpressionProperties(formFields, `companyApplicant.sourceOfWealth.typeId`, {
			'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
				return `${Number(field.parent?.key) + 1} - ${sourceOfIncomeTypeField?.props?.label}`;
			}
		});
	}

	private handleSourceOfWealthDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnClick(formFields, `companyApplicant.sourceOfWealth.delete`, (field: FormlyFieldConfig) => {
			const sourceOfWealthModel = field.model as SourceOfWealthModel;

			if (!sourceOfWealthModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}

			const rowIndex = Number(field.parent?.key);
			this.sourceOfWealthService
				.deleteSourceOfWealth(sourceOfWealthModel, ApplicantEntityType.CompanyApplicant)
				.subscribe(() => {
					formlyDeleteFromArray(field);
					this.simpFormlyHandlerService.deleteFromState(`sourceOfWealth`, rowIndex);
					this.toastr.success(deletedSuccessfullyMessage(`Source of wealth`));
				});
		});
	}

	private handleSourceOfWealthSave(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`companyApplicant.sourceOfWealth`,
			(field, event: FormlyArrayUpdateEvent<SourceOfWealthModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as SourceOfWealthModel;
				model.applicantId = applicantId;
				this.sourceOfWealthService
					.saveSourceOfWealth(model, ApplicantEntityType.CompanyApplicant)
					.subscribe((id: number) => {
						model.id = id;
						this.simpFormlyHandlerService.updateToState(`sourceOfWealth`, model, event.index!, model);
						this.toastr.success(savedSuccessfullyMessage('Source of wealth'));
					});
			}
		);
	}
}
