import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FinancialPositionService } from '@app/modules/financial-position/financial-position.service';
import {
	EmploymentDetailsModel,
	EmploymentModel,
	TypeOfIncome
} from '@app/modules/financial-position/model/employment.model';
import { OtherIncomeModel } from '@app/modules/financial-position/model/otherincome.model';
import { SelfEmploymentModel } from '@app/modules/financial-position/model/self-employment.model';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { Amount } from '@app/modules/simp-formly/typings/formly-app';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get, isEqual, merge } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { EmployersTransformerService } from '@app/modules/shared/transformers/employers/employers-transformer.service';
import { FinancialPositionEmploymentTransformerService } from '@app/modules/shared/transformers/employment/financial-position-employment-transformer.service';
import { PropertyModalTransformerService } from '../../property/services/property-modal-transformer.service';
import { CreditStatus, IncomeType } from '../enums/app.enums';
import { isDateFuture } from '../helper/date.helper';
import { deletedSuccessfullyMessage, savedSuccessfullyMessage } from '../helper/util';
import { CreditIssueModel, CreditIssueTransformer } from '../model/credit-issue.model';
import { IncomeHeaderSummary } from '../model/header-summary.model';
import { PercentageOwned } from '../model/percentage-owned.model';
import { WarningIssueModel } from '../model/warning-issue-model';
import { FormArrayDeleteService } from '../service/form-array-delete.service';
import { NotEmployedIncomeService } from '../service/notemployed-income.service';
import { PercentOwnedService } from '../service/percent-owned.service';
import { RelatedEntitiesService } from '../service/related-entities.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { SimpFormlyHandlerService } from './../../simp-formly/services/simp-formly-handler.service';
import { AssetsLiabilitiesTransformerService } from './assets-liabilities-transformer.service';
import { SelfEmploymentTransformerService } from './employment/self-employment-transformer.service';
import { TransformerUtilService } from './service/transformer-util.service';

@Injectable({ providedIn: 'root' })
export class FinancialPositionTransformerService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private financialPositionService: FinancialPositionService,
		private formArrayDeleteService: FormArrayDeleteService,
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private notEmployedIncomeService: NotEmployedIncomeService,
		private percentOwnedService: PercentOwnedService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private toastr: ToastrService,
		private transformerUtilService: TransformerUtilService,
		private propertyModalTransformerService: PropertyModalTransformerService,
		private assetsLiabilityTransformerService: AssetsLiabilitiesTransformerService,
		private employerTransformerService: EmployersTransformerService,
		private financialPositionEmploymentTransformerService: FinancialPositionEmploymentTransformerService,
		private relatedEntitiesService: RelatedEntitiesService,
		private selfEmploymentTransformerService: SelfEmploymentTransformerService
	) {}

	updateSchema(fields: FormlyFieldConfig[] | undefined): Observable<FormlyFieldConfig[] | undefined> {
		this.handleFYChange(fields);
		this.financialPositionService.setSectionHeaders(fields);
		return of(fields);
	}

	transform(formFields: FormlyFieldConfig[] | undefined): FormlyFieldConfig[] | undefined {
		const defaultAllPercentageOwned = this.percentOwnedService.getAllDefaultSharePercents();
		const defaultPersonsPercentageOwned = this.percentOwnedService.getPersonsDefaultSharePercents();
		formFields = this.assetsLiabilityTransformerService.transform(formFields);

		this.setStatementOfPositionHeader(formFields);

		this.configureCreditStatus(formFields);

		this.setEmploymentTitle(formFields);
		this.setNotEmployedDuration(formFields);
		this.setIncomeTitle(formFields);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields as FormlyFieldConfig[], 'income');

		formlyOnClick(formFields, 'income.employments.employmentDetails', this.openEmploymentModal.bind(this));

		formlyOnClick(formFields, 'finances.statements.statementsCopyRef', this.copyRef.bind(this));

		this.setDefaultPercentageOwned(
			formFields,
			defaultPersonsPercentageOwned,
			'income.otherIncome.details.otherIncomeDetails.percentsOwned'
		);
		formlyOnClick(formFields, 'income.otherIncome.details', this.setupOtherIncome.bind(this));
		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'assets.otherAssets.details.otherAssetDetails.percentsOwned'
		);
		const paygForm = getFormField(formFields, 'income.employments.employmentDetails.payg');
		if (paygForm) {
			paygForm.hideExpression = `field?.parent?.model?.typeOfIncome?.type !== ${IncomeType.payg}`;
		}

		const selfEmploymentForm = getFormField(formFields, 'income.employments.employmentDetails.selfEmployment');
		if (selfEmploymentForm) {
			selfEmploymentForm.hideExpression = `field?.parent?.model?.typeOfIncome?.type !== ${IncomeType.selfEmployed}`;
		}

		this.selfEmploymentTransformerService.configureSelfEmployment(
			formFields,
			'income.employments.employmentDetails.selfEmployment',
			this.saveSelfEmployedBusinessDetails
		);

		this.employerTransformerService.configureEmployerModal(formFields, 'income.employments.employmentDetails.payg');

		this.employerTransformerService.configureEmployerModal(
			formFields,
			'income.employments.employmentDetails.foreignEmployment'
		);

		const notEmployedForm = getFormField(formFields, 'income.employments.employmentDetails.notEmployed');
		if (notEmployedForm) {
			notEmployedForm.hideExpression = `field?.parent?.model?.typeOfIncome?.type !== ${IncomeType.NotEmployed}`;
		}

		const foreignEmployedForm = getFormField(formFields, 'income.employments.employmentDetails.foreignEmployment');
		if (foreignEmployedForm) {
			foreignEmployedForm.hideExpression = `field?.parent?.model?.typeOfIncome?.type !== ${IncomeType.ForeignEmployed}`;
		}

		this.handleIndustryChange(formFields);
		this.handleSelfEmployeeIndustryCategoryChange(formFields);
		this.handleEmployementDeletes(formFields);
		this.handleOtherIncomeDeletes(formFields);

		const latestIncomeField = getFormField(
			formFields,
			'income.employments.employmentDetails.selfEmployment.latestIncome'
		);
		if (latestIncomeField) {
			Object.assign(latestIncomeField, {
				...latestIncomeField,
				expressionProperties: {
					'templateOptions.label': (model: Amount, formState: unknown, field: FormlyFieldConfig) => {
						const fyCurrentField = getFormField(field.parent?.fieldGroup, 'addBacks.fyCurrent');
						if (fyCurrentField?.expressionProperties) {
							fyCurrentField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery.getOptions('FinancialYear').find((option) => option.id === model.frequency?.id)
										?.label
							};
						}
						const fyPreviousField = getFormField(field.parent?.fieldGroup, 'addBacks.fyPrevious');
						if (fyPreviousField?.expressionProperties) {
							fyPreviousField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery
										.getOptions('FinancialYear')
										.find((option) => option.id === model.frequency?.id - 1)?.label
							};
						}
						const fySecondPreviousField = getFormField(field.parent?.fieldGroup, 'addBacks.fySecondPrevious');
						if (fySecondPreviousField?.expressionProperties) {
							fySecondPreviousField.expressionProperties = {
								'templateOptions.label': () =>
									this.formEnumsQuery
										.getOptions('FinancialYear')
										.find((option) => option.id === model.frequency?.id - 2)?.label
							};
						}
						return latestIncomeField.templateOptions?.label;
					}
				}
			});
		}

		this.validateBusinessStartDate(formFields);
		this.propertyModalTransformerService.configurePropertyModalForFinancialPosition(formFields!);
		return formFields;
	}

	private saveSelfEmployedBusinessDetails = (
		selfEmploymentModel: any,
		index: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		const employmentModel = field?.parent?.parent?.parent?.model as ApplicantEmploymentModel;
		employmentModel.employmentDetails.selfEmployment = selfEmploymentModel as SelfEmploymentModel;
		this.simpFormlyHandlerService.updateToState(
			'employments',
			employmentModel,
			field?.parent?.parent?.parent?.key as number
		);

		return new Subscription();
	};

	private configureCreditStatus(formFields: FormlyFieldConfig[] | undefined) {
		this.setDefaultCreditStatus(formFields);
		formlyOnClick(
			formFields,
			'finances.creditStatus.creditStatusContainer.status',
			this.updateApplicantCreditStatus.bind(this)
		);

		formlyRegisterHooks(formFields, 'finances.creditStatus.warningIssuesList.issueDetails', {
			onInit: (field) => {
				const form = field?.parent?.formControl as UntypedFormControl;
				if (form) {
					return form.valueChanges.pipe(
						debounceTime(1000),
						filter(() => {
							return form.dirty && form.valid;
						}),
						tap((model: WarningIssueModel) => {
							if (model) {
								const payload = CreditIssueTransformer.toPayloadWarningIssue(model);
								const applicantIndex = field?.parent?.parent?.parent?.key as number;
								const applicantId = this.applicationDataQuery.getPersonShortApplicants()[applicantIndex].id;
								this.financialPositionService
									.saveWarningIssue(payload, applicantId)
									.pipe(take(1))
									.subscribe((issueId: number) => {
										model.id = issueId;
										const storeData = cloneDeep(
											this.simpFormlyHandlerService.getStateData<CreditIssueModel>('creditStatus')
										);

										if (storeData && storeData[applicantIndex]) {
											const index = Number(field?.parent?.key);
											let warningList = storeData[applicantIndex]?.warningIssuesList;
											if (warningList) {
												warningList[index] = model;
											} else {
												warningList = [model];
											}
											storeData[applicantIndex].warningIssuesList = warningList;
										}
										this.simpFormlyHandlerService.updateToState(
											'creditStatus',
											storeData[applicantIndex],
											applicantIndex
										);
										this.toastr.success(savedSuccessfullyMessage(`Warning issue`));
									});
							}
						})
					);
				}
				return of();
			}
		});

		formlyOnClick(formFields, 'finances.creditStatus.warningIssuesList.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as WarningIssueModel;
			const applicantIndex = field?.parent?.parent?.parent?.key as number;
			const applicantId = this.applicationDataQuery.getPersonShortApplicants()[applicantIndex].id;

			this.financialPositionService.deleteWarningIssues(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				const storeData = cloneDeep(this.simpFormlyHandlerService.getStateData<CreditIssueModel>('creditStatus'));
				if (storeData && storeData[applicantIndex]) {
					const index = Number(field?.parent?.key);
					storeData[applicantIndex]?.warningIssuesList?.splice(index, 1);
				}
				this.simpFormlyHandlerService.updateToState('creditStatus', storeData[applicantIndex], applicantIndex);
				this.toastr.success(deletedSuccessfullyMessage(`Warning issue`));
			});
		});
	}

	private setStatementOfPositionHeader(formFields: FormlyFieldConfig[] | undefined) {
		let headerLabel: string | undefined;

		formlyRegisterHooks(formFields, `finances.statements`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					headerLabel = field.templateOptions!.label;
					return this.formDataService.select$('statements-of-position').pipe(
						tap((data) => {
							if (field && data) {
								field.templateOptions!.label = headerLabel!.concat(` (${data})`);
							}
						})
					);
				}
				return of();
			}
		});
	}

	private handleEmployementDeletes(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'income.employments.delete', (field: FormlyFieldConfig) => {
			const employmentModel = field.model as EmploymentModel;
			let segmentUrl = '';
			if (employmentModel.id) {
				const typeOfIncome = employmentModel?.employmentDetails?.typeOfIncome.type;
				switch (typeOfIncome) {
					case IncomeType.selfEmployed:
						segmentUrl = 'SelfEmployedIncome';
						break;
					case IncomeType.NotEmployed:
						segmentUrl = 'NotEmployed';
						break;
					case IncomeType.ForeignEmployed:
						segmentUrl = 'ForeignEmployed';
						break;
					default:
						segmentUrl = 'Employment';
				}
			}
			const grossAmount = get(field, 'model.grossAmount') as number;
			const netAmount = get(field, 'model.netAmount') as number;
			this.formArrayDeleteService.deleteItem(field, segmentUrl, 'employments').subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage(`Employment`));
				const header = this.simpFormlyHandlerService.getHeaderData<EmploymentModel, IncomeHeaderSummary>('employments');
				this.updateSubSectionHeaderAfterDelete(header, grossAmount, netAmount, 'employments');
			});
		});
	}

	private handleOtherIncomeDeletes(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'income.otherIncome.delete', (field: FormlyFieldConfig) => {
			const grossAmount = get(field, 'model.grossAmount') as number;
			const netAmount = get(field, 'model.netAmount') as number;
			this.formArrayDeleteService.deleteItem(field, 'OtherIncome', 'otherIncome').subscribe(() => {
				this.toastr.success(deletedSuccessfullyMessage('Other income'));

				const header = this.simpFormlyHandlerService.getHeaderData<OtherIncomeModel, IncomeHeaderSummary>(
					'otherIncome'
				);
				this.updateSubSectionHeaderAfterDelete(header, grossAmount, netAmount, 'otherIncome');
			});
		});
	}

	private updateSubSectionHeaderAfterDelete(
		header: IncomeHeaderSummary,
		grossAmount: number,
		netAmount: number,
		state: string
	) {
		const newHeader = {
			totalNetAmount: header?.totalNetAmount ? header?.totalNetAmount - netAmount : 0,
			totalGrossAmount: header.totalGrossAmount ? header.totalGrossAmount - grossAmount : 0
		} as IncomeHeaderSummary;
		this.simpFormlyHandlerService.upsertToSubSectionHeader(state, newHeader);
	}

	private handleIndustryChange(formFields: FormlyFieldConfig[] | undefined): void {
		const paygPath = 'income.employments.employmentDetails.payg';
		const industryField = getFormField(formFields, `${paygPath}.industryDivision`) ?? {};
		Object.assign(industryField, {
			...industryField,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const industrySubdivisionField = field.parent?.fieldGroup?.find((f) => f.key === 'industrySubdivision');
					if (industrySubdivisionField) {
						industrySubdivisionField.templateOptions = industrySubdivisionField.templateOptions ?? {};
						return field.formControl?.valueChanges.pipe(
							startWith(field.formControl?.value),
							filter((val) => !!val),
							switchMap((val) => this.formEnumsService.fetchSubEnums('IndustryDivision', val)),
							tap((options) => {
								industrySubdivisionField.templateOptions!.options = options;

								const matchingOption = options.find(
									(option) => option.id === industrySubdivisionField.formControl?.value
								);
								if (!matchingOption) {
									industrySubdivisionField.formControl?.setValue(null);
								} else {
									industrySubdivisionField.formControl?.setValue(matchingOption.id);
								}
							})
						);
					}
					return;
				}
			}
		});
	}

	private handleSelfEmployeeIndustryCategoryChange(formFields: FormlyFieldConfig[] | undefined): void {
		formlyRegisterHooks(formFields, `income.employments.employmentDetails.selfEmployment.industryCategory`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					const industrySubCategoryField = field.parent?.fieldGroup?.find((f) => f.key === 'industrySubCategory');
					if (industrySubCategoryField) {
						industrySubCategoryField.templateOptions = industrySubCategoryField.templateOptions ?? {};
						return field.formControl?.valueChanges.pipe(
							startWith(field.formControl?.value),
							filter((val) => !!val),
							switchMap((val: number) => this.formEnumsService.fetchSubEnums('IndustryDivision', val)),
							tap((options) => {
								if (industrySubCategoryField.templateOptions) {
									industrySubCategoryField.templateOptions.options = options ?? [];
								}
								const matchingOption = (options ?? []).find(
									(option) => option.id === industrySubCategoryField.formControl?.value
								);
								industrySubCategoryField?.formControl?.setValue(matchingOption?.id ?? null);
							})
						);
					}
				}
				return of();
			}
		});
	}

	private handleFYChange(formFields: FormlyFieldConfig[] | undefined): void {
		const latestFinancialYearField =
			getFormField(formFields, `income.employments.employmentDetails.selfEmployment.latestIncome`) ?? {};
		Object.assign(latestFinancialYearField, {
			...latestFinancialYearField,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const secondLatestIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'secondLatestIncome');
					const thirdLatestIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'thirdLatestIncome');
					const nonRecurringIncomeField = field.parent?.fieldGroup?.find((f) => f.key === 'nonRecurringIncome');
					const secondNonRecurringIncomeField = field.parent?.fieldGroup?.find(
						(f) => f.key === 'secondNonRecurringIncome'
					);

					return field.formControl?.valueChanges.pipe(
						startWith(field.formControl?.value),
						filter((latestIncome: Amount) => !!latestIncome.frequency),
						distinctUntilChanged((a: Amount, b: Amount) => isEqual(a.frequency, b.frequency)),
						map((latestIncome: Amount) => {
							const formEnumsFinancialYear = this.formEnumsQuery.getOptions('FinancialYear');
							const latestYear = formEnumsFinancialYear.find((option) => option.id === latestIncome.frequency.id);
							const secondLatestYear = formEnumsFinancialYear.find(
								(option) => option.id === latestIncome.frequency.id - 1
							);
							const thirdLatestYear = formEnumsFinancialYear.find(
								(option) => option.id === latestIncome.frequency.id - 2
							);

							if (secondLatestIncomeField && thirdLatestIncomeField) {
								secondLatestIncomeField.formControl?.patchValue({
									frequency: secondLatestYear
								});

								if (!secondLatestYear) {
									secondLatestIncomeField.formControl?.get('amount')?.patchValue(null);
									secondLatestIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as SelfEmploymentModel).hideSecondAddback = true;
								} else {
									secondLatestIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as SelfEmploymentModel).hideSecondAddback = false;
								}

								thirdLatestIncomeField.formControl?.patchValue({
									frequency: thirdLatestYear
								});

								if (!thirdLatestYear) {
									thirdLatestIncomeField.formControl?.get('amount')?.patchValue(null);
									thirdLatestIncomeField.formControl?.get('amount')?.disable();
									(field.parent!.model as SelfEmploymentModel).hideThirdAddback = true;
								} else {
									thirdLatestIncomeField.formControl?.get('amount')?.enable();
									(field.parent!.model as SelfEmploymentModel).hideThirdAddback = false;
								}
							}
							if (nonRecurringIncomeField) {
								nonRecurringIncomeField?.formControl?.patchValue({ frequency: latestYear });
							}

							if (secondNonRecurringIncomeField) {
								secondNonRecurringIncomeField?.formControl?.patchValue({ frequency: secondLatestYear });
							}
						})
					);
				}
			}
		});

		formlyRegisterHooks(formFields, `income.employments.employmentDetails.selfEmployment.firstNetProfitAfterTax`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				const secondNetProfitAfterTaxField = field?.parent?.fieldGroup?.find(
					(f) => f.key === 'secondNetProfitAfterTax'
				);
				const thirdNetProfitAfterTaxField = field?.parent?.fieldGroup?.find((f) => f.key === 'thirdNetProfitAfterTax');
				if (secondNetProfitAfterTaxField && thirdNetProfitAfterTaxField) {
					return field?.formControl?.valueChanges.pipe(
						startWith(field.formControl?.value),
						filter((latestIncome: Amount) => !!latestIncome.frequency),
						distinctUntilChanged((a: Amount, b: Amount) => isEqual(a.frequency, b.frequency)),
						map((latestIncome: Amount) => {
							const secondLatestYear = this.formEnumsQuery
								.getOptions('FinancialYear')
								.find((option) => option.id === latestIncome.frequency.id - 1);
							secondNetProfitAfterTaxField.formControl?.patchValue({
								frequency: secondLatestYear
							});

							if (!secondLatestYear) {
								secondNetProfitAfterTaxField.formControl?.get('amount')?.patchValue(null);
								secondNetProfitAfterTaxField.formControl?.get('amount')?.disable();
								(field.parent!.model as SelfEmploymentModel).hideSecondAddback = true;
							} else {
								secondNetProfitAfterTaxField.formControl?.get('amount')?.enable();
								(field.parent!.model as SelfEmploymentModel).hideSecondAddback = false;
							}

							const thirdLatestYear = this.formEnumsQuery
								.getOptions('FinancialYear')
								.find((option) => option.id === latestIncome.frequency.id - 2);
							thirdNetProfitAfterTaxField.formControl?.patchValue({
								frequency: thirdLatestYear
							});

							if (!thirdLatestYear) {
								thirdNetProfitAfterTaxField.formControl?.get('amount')?.patchValue(null);
								thirdNetProfitAfterTaxField.formControl?.get('amount')?.disable();
								(field.parent!.model as SelfEmploymentModel).hideThirdAddback = true;
							} else {
								thirdNetProfitAfterTaxField.formControl?.get('amount')?.enable();
								(field.parent!.model as SelfEmploymentModel).hideThirdAddback = false;
							}
						})
					);
				}
				return of();
			}
		});
	}

	private setDefaultPercentageOwned(
		formFields: FormlyFieldConfig[] | undefined,
		DefaultPercentageOwned: PercentageOwned[],
		path: string
	) {
		const percentOwned = getFormField(formFields, path);
		if (percentOwned && percentOwned.templateOptions) {
			percentOwned.templateOptions.showAllLabels = true;
			percentOwned.defaultValue = DefaultPercentageOwned;
		}
	}

	private setDefaultCreditStatus(formFields: FormlyFieldConfig[] | undefined) {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		const defaultCreditStatus = applicants.map((a) => ({
			applicantId: a.id,
			name: a.name,
			status: CreditStatus.Unknown
		}));
		const creditStatus = getFormField(formFields, 'finances.creditStatus.creditStatusContainer');

		if (creditStatus) {
			creditStatus.defaultValue = defaultCreditStatus;
		}
	}

	private copyRef(field: FormlyFieldConfig): void {
		void navigator.clipboard.writeText(field?.templateOptions?.label || '');
		this.toastr.success('Copied', '', { positionClass: 'toast-bottom-right' });
	}

	private openNotEmploymentModal(field: FormlyFieldConfig): void {
		const model = merge({}, field.parent?.model, field.form?.value) as EmploymentModel;
		const ownershipField = getFormField(field.fieldGroup, 'notEmployed.ownership');
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		if (ownershipField && applicants?.length === 1) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			model.employmentDetails.notEmployed!.ownership = applicants[0].id;
		}
		ownershipField?.formControl?.setValue(model.employmentDetails.notEmployed?.ownership);

		const NotEmployedModalRef = this.simpFormlyModalService.openModal(field, 'notEmployed', { backdrop: 'static' });

		const subscription = NotEmployedModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				NotEmployedModalRef.close();
				subscription.unsubscribe();
				return;
			}

			const key = Number(field.parent?.key);
			const payload = Object.assign({}, field.parent?.model, field.form?.value) as EmploymentModel;

			this.financialPositionService.saveNotEmployed(key, payload).subscribe(() => {
				NotEmployedModalRef.close();
				subscription.unsubscribe();
			});
		});

		formlyRegisterHooks(field.fieldGroup, `notEmployed.income.type`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					this.updateNotEmployedDynamicSelect(field, formField);
					return formField?.formControl?.valueChanges.pipe(
						tap(() => this.updateNotEmployedDynamicSelect(field, formField))
					);
				}
				return of();
			},
			onDestroy: (formField: FormlyFieldConfig | undefined) => {
				if (formField) {
					this.updateNotEmployedDynamicSelect(field, formField);
				}
				return of();
			}
		});
	}

	private openForeignEmploymentModal(field: FormlyFieldConfig): void {
		const model = merge({}, field.parent?.model, field.form?.value) as EmploymentModel;

		const ownershipField = getFormField(field.fieldGroup, 'foreignEmployment.ownership');
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		if (ownershipField && applicants?.length === 1) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			model.employmentDetails.foreignEmployment!.ownership = applicants[0].id;
		}
		ownershipField?.formControl?.setValue(model.employmentDetails.foreignEmployment?.ownership);

		const foreignEmploymentModalRef = this.simpFormlyModalService.openModal(field, 'foreignEmployment', {
			backdrop: 'static'
		});

		const subscription = foreignEmploymentModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				foreignEmploymentModalRef.close();
				subscription.unsubscribe();
				return;
			}

			const key = Number(field.parent?.key);
			const payload = field.parent?.model as EmploymentModel;

			this.financialPositionService.saveForeignEmployment(key, payload).subscribe(() => {
				foreignEmploymentModalRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private updateNotEmployedDynamicSelect(field: FormlyFieldConfig, formField: FormlyFieldConfig) {
		this.transformerUtilService.updateDynamicListOfSelectFieldOptions(
			field,
			formField.parent?.parent?.fieldGroup,
			'notEmployed.income',
			'type',
			'NotEmployedIncomeType'
		);
	}

	private openPaygEmploymentModal(field: FormlyFieldConfig): void {
		const paygModalRef = this.simpFormlyModalService.openModal(field, 'payg', { backdrop: 'static' });
		const subscription = paygModalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				paygModalRef.close();
				subscription.unsubscribe();
				return;
			}

			const key = Number(field.parent?.key);
			const model = merge({}, field.parent?.model, field.form?.value) as EmploymentModel;
			this.financialPositionService.savePaygEmployment(key, model).subscribe(() => {
				paygModalRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private updateApplicantCreditStatus(field: FormlyFieldConfig): void {
		const index = Number(field.parent?.parent?.key);

		const applicantId = this.applicationDataQuery.getPersonShortApplicants()[index].id;
		const model = field.parent?.parent?.model as CreditIssueModel;
		if (model.creditStatusContainer.status) {
			this.financialPositionService.setApplicantCreditStatus(index, applicantId, model);
		} else {
			this.financialPositionService.resetApplicantCreditStatus(index, applicantId, model);
		}
	}

	private openEmploymentModal(field: FormlyFieldConfig): void {
		let model = field.model as EmploymentDetailsModel;
		if (model?.extract && model?.typeOfIncome) {
			this.openEmploymentModalByType(model?.typeOfIncome, field);
			return;
		}

		const parentModalRef = this.simpFormlyModalService.openModal(field, 'typeOfIncome', {
			size: 'md',
			windowClass: 'd-block modal fade show simp-modal-confirm'
		});
		const parentSubscription = parentModalRef.action.subscribe((parentAction) => {
			if (parentAction !== 'submit') {
				parentModalRef.close();
				parentSubscription.unsubscribe();
				return;
			}

			parentModalRef.close();
			parentSubscription.unsubscribe();
			// reassign to fetch current values
			model = field.model as EmploymentDetailsModel;
			this.openEmploymentModalByType(model?.typeOfIncome, field);
		});
	}

	private openEmploymentModalByType(typeOfIncome: TypeOfIncome, field: FormlyFieldConfig) {
		switch (typeOfIncome?.type) {
			case IncomeType.payg:
				this.openPaygEmploymentModal(field);
				break;
			case IncomeType.selfEmployed:
				this.financialPositionEmploymentTransformerService.openSelfEmploymentModal(field);
				break;
			case IncomeType.NotEmployed:
				this.openNotEmploymentModal(field);
				break;
			case IncomeType.ForeignEmployed:
				this.openForeignEmploymentModal(field);
				break;
		}
	}

	private setNotEmployedDuration(formFields: FormlyFieldConfig[] | undefined) {
		const duration = getFormField(formFields, `income.employments.employmentDetails.notEmployed.duration`);
		if (duration) {
			merge(duration, {
				...duration,
				expressionProperties: {
					'templateOptions.label': (model: any, formState: unknown, field: FormlyFieldConfig) => {
						const dateStartedField = field.parent?.fieldGroup?.find((f) => f.key === 'dateStarted')?.formControl;
						let label = `Duration: ${0} year, ${0} month`;
						if (dateStartedField?.value) {
							const endedDate = new Date().toUTCString();
							label = this.notEmployedIncomeService.getDuration(dateStartedField.value, endedDate);
						}
						return label;
					}
				}
			});
		}
	}

	private setupOtherIncome(field: FormlyFieldConfig): void {
		const modalRef = this.simpFormlyModalService.openModal(field, 'otherIncomeDetails');
		const subscription = modalRef.action.subscribe((action) => {
			if (action !== 'submit') {
				modalRef.close();
				subscription.unsubscribe();
				return;
			}

			const model = merge(field.parent?.model, field.form?.value) as OtherIncomeModel;
			const key = Number(field.parent?.key);
			this.financialPositionService.saveOtherIncome(key, model).subscribe(() => {
				modalRef.close();
				subscription.unsubscribe();
			});
		});
	}

	private setEmploymentTitle(formFields: FormlyFieldConfig[] | undefined): void {
		const employmentDetailsField = getFormField(formFields, 'income.employments.employmentDetails');
		if (employmentDetailsField) {
			Object.assign(employmentDetailsField, {
				...employmentDetailsField,
				expressionProperties: {
					'templateOptions.label': (model: EmploymentDetailsModel, formState: any, field: FormlyFieldConfig) => {
						const employmentDetailModel = field.model as EmploymentDetailsModel;
						const applicantName = this.getApplicantNameFromEmploymentDetail(employmentDetailModel);
						if (applicantName) {
							return `${applicantName} - Current`;
						}

						return '';
					}
				}
			});
		}
	}

	private setIncomeTitle(formFields: FormlyFieldConfig[] | undefined): void {
		const incomeItemField = getFormField(formFields, `income.employments.employmentDetails.notEmployed.income.type`);
		if (incomeItemField) {
			Object.assign(incomeItemField, {
				...incomeItemField,
				expressionProperties: {
					'templateOptions.label': (model: {}, formState: unknown, field: FormlyFieldConfig) => {
						return `${field.parent?.key ? +field.parent?.key + 1 : ''} - Income type`;
					}
				}
			});
		}
	}

	private getApplicantNameFromEmploymentDetail(employmentDetail?: EmploymentDetailsModel): string {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		if (employmentDetail?.payg?.ownership) {
			return applicants.find((applicant) => applicant.id === employmentDetail.payg?.ownership)?.name ?? '';
		} else if (employmentDetail?.selfEmployment?.ownership) {
			return applicants.find((applicant) => applicant.id === employmentDetail.selfEmployment?.ownership)?.name ?? '';
		} else if (employmentDetail?.notEmployed?.ownership) {
			return applicants.find((applicant) => applicant.id === employmentDetail.notEmployed?.ownership)?.name ?? '';
		} else if (employmentDetail?.foreignEmployment?.ownership) {
			return applicants.find((applicant) => applicant.id === employmentDetail.foreignEmployment?.ownership)?.name ?? '';
		}
		return '';
	}

	private validateBusinessStartDate(formFields: FormlyFieldConfig[] | undefined): void {
		formlyAddCustomValidator(formFields, `income.employments.employmentDetails.selfEmployment.businessStartDate`, {
			invalid: {
				expression: (c: UntypedFormControl) => {
					if (c.value) {
						return !isDateFuture(c.value);
					}
					return true;
				}
			}
		});
	}
}
