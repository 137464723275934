<div class="simp-modal-header">
	<h4 class="simp-h4 simp-modal-header__title">{{ labels.title }}</h4>
	<button type="button" class="btn simp-modal-header__close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="simp-modal-body">
	<div class="d-flex align-items-center simp-margin-bottom-small">
		<div class="confirm-consent__icon-wrapper simp-margin-right-base">
			<i class="{{ labels.iconClass + ' confirm-consent__icon' }}"></i>
		</div>
		<h4 class="simp-h4 simp-text--bold simp-text--secondary confirm-consent__sub-title">{{ labels.subTitle }}</h4>
	</div>
	<h6 class="simp-h6 simp-text--grey90">{{ labels.confirmationText }}</h6>

	<div class="simp-warning-message-box simp-margin-top-medium">{{ labels.warningMessage }}</div>

	<div class="simp-content-display-box simp-bg--grey10 simp-margin-top-medium">
		<div class="simp-row confirm-consent__labels-row">
			<label class="simp-label col-3">{{ labels.fullNameLabel }}</label>
			<label class="simp-label col-2">{{ labels.dobLabel }}</label>
			<label class="simp-label col-3">{{ labels.mobileLabel }}</label>
			<label class="simp-label col-4">{{ labels.emailLabel }}</label>
		</div>
		<div class="simp-row simp-margin-top-small" *ngFor="let applicant of applicants">
			<span class="col-3">{{ applicant.fullName }}</span>
			<span class="col-2">{{ applicant.dateOfBirth | date: 'd/MM/yyyy' }}</span>
			<span class="col-3"
				>{{ applicant.mobilePhoneNumber?.dialCode }}{{ applicant.mobilePhoneNumber?.phoneNumber }}</span
			>
			<span class="col-4">{{ applicant.email }}</span>
		</div>
	</div>

	<form [formGroup]="confirmConsentForm">
		<simp-checkbox [id]="'confirmDetailsCheckboxId'" [formControl]="confirmDetails" [labelOnSide]="true">
			{{ labels.confirmCheckboxLabel }}
		</simp-checkbox>
	</form>
</div>
<div class="simp-modal-footer align-items-center justify-content-start">
	<button
		type="button"
		class="btn btn-lg btn-secondary simp-button col-2 simp-margin-right-small ms-auto"
		(click)="close()"
	>
		{{ labels.cancelButton }}
	</button>
	<button
		type="button"
		class="btn btn-lg btn-primary simp-button col-2"
		(click)="submit()"
		[disabled]="!confirmDetails.value"
	>
		{{ labels.confirmButton }}
	</button>
</div>
