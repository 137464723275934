import { TargetType, YesNo } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { getMonthAndYearDifference, getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { CurrencyHelper } from '@simpology/client-components/utils';
import { EmploymentStatus, IncomeType } from './../../shared/enums/app.enums';
import { AggregateFormatterService } from './../../shared/service/aggregate-formatter.service';
import { EmploymentIncomeDTO, ForeignEmploymentDTO } from './../../typings/api.d';
import { ApplicantEmploymentModel, IncomeModel } from './applicant-employment.model';
import { EmploymentModelTransformer, TypeOfIncome } from './employment.model';

export interface ApplicantForeignEmploymentModel {
	ownership?: number;
	status?: number;
	basis?: number;
	dateStarted?: string;
	occupation?: string;
	dateEnded?: string;
	industry?: string;
	duration?: string;
	mainBusinessActivity?: string;
	employerType?: number;
	employerDetailsSelect?: ApplicantEnumObject;
	companyCar?: YesNo;
	isOnProbation?: boolean;
	probationDateStarts?: string;
	probationDateEnds?: string;
	income?: IncomeModel[];
	typeOfIncome?: TypeOfIncome;
	grossSalary?: string;
	netSalary?: string;
	employedByFamily?: YesNo;
}

export class ApplicantForeignEmploymentModelTransformer {
	static toPayload(employmentModel: ApplicantEmploymentModel, applicationId: number): ForeignEmploymentDTO {
		const foreignEmployment = employmentModel.employmentDetails.foreignEmployment!;
		const incomes = (foreignEmployment.income || [])
			.filter((income) => !!income)
			.map((income) => this.incomeToPayload(income));

		return {
			id: employmentModel.id,
			applicationId: applicationId,
			applicantId: foreignEmployment.ownership,
			status: foreignEmployment.status ?? EmploymentStatus.Previous,
			basis: foreignEmployment.basis,
			dateStarted: foreignEmployment.dateStarted,
			occupation: foreignEmployment.occupation,
			dateEnded: foreignEmployment.dateEnded,
			industry: foreignEmployment.industry,
			mainBusinessActivity: foreignEmployment.mainBusinessActivity,
			employerType: foreignEmployment.employerType,
			employerRelatedCompanyId: foreignEmployment.employerDetailsSelect?.id,
			employerPartyId: foreignEmployment.employerDetailsSelect?.id,
			employerTargetType: foreignEmployment.employerDetailsSelect?.type,
			companyCar: !!foreignEmployment.companyCar,
			isOnProbation: !!foreignEmployment.isOnProbation,
			probationDateStarts: foreignEmployment.isOnProbation ? foreignEmployment.probationDateStarts : undefined,
			probationDateEnds: foreignEmployment.isOnProbation ? foreignEmployment.probationDateEnds : undefined,
			income: incomes,
			employedByFamily: getNullableBoolean(foreignEmployment.employedByFamily)
		};
	}

	static fromPayload(
		foreignEmploymentDto: ForeignEmploymentDTO,
		formatter: AggregateFormatterService,
		formEnumsQuery: FormEnumsQuery
	): ApplicantEmploymentModel {
		const incomes: IncomeModel[] = (foreignEmploymentDto.income || []).map((income) => this.incomeFromPayload(income));

		const calculated = EmploymentModelTransformer.getNetAndGrossAmountForSummary(incomes);

		const foreignEmploymentModel: ApplicantForeignEmploymentModel = {
			ownership: foreignEmploymentDto.applicantId,
			status: foreignEmploymentDto.status,
			basis: foreignEmploymentDto.basis,
			dateStarted: foreignEmploymentDto.dateStarted,
			occupation: foreignEmploymentDto.occupation,
			dateEnded: foreignEmploymentDto.dateEnded,
			industry: foreignEmploymentDto.industry,
			duration: getMonthAndYearDifference(foreignEmploymentDto.dateStarted!, foreignEmploymentDto.dateEnded!),
			mainBusinessActivity: foreignEmploymentDto.mainBusinessActivity,
			employerType: foreignEmploymentDto.employerType,
			employerDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: foreignEmploymentDto.employerTargetType as TargetType,
				id: foreignEmploymentDto.employerPartyId as number
			}),
			typeOfIncome: {
				type: IncomeType.ForeignEmployed,
				currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
					foreignEmploymentDto.dateEnded,
					foreignEmploymentDto.status
				)
			},
			companyCar: getNullableYesNo(foreignEmploymentDto.companyCar),
			isOnProbation: foreignEmploymentDto.isOnProbation,
			probationDateStarts: foreignEmploymentDto.probationDateStarts,
			probationDateEnds: foreignEmploymentDto.probationDateEnds,
			income: incomes,
			grossSalary: formatter.formatAmount(calculated.grossAmount),
			netSalary: formatter.formatAmount(calculated.netAmount),
			employedByFamily: getNullableYesNo(foreignEmploymentDto.employedByFamily)
		};

		const employmentModel: ApplicantEmploymentModel = {
			id: foreignEmploymentDto.id,
			employmentDetails: {
				applicantId: foreignEmploymentDto.applicantId,
				extract: 'Foreign employed',
				typeOfIncome: {
					type: IncomeType.ForeignEmployed,
					currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
						foreignEmploymentDto.dateEnded,
						foreignEmploymentDto.status
					)
				},
				foreignEmployment: foreignEmploymentModel
			},
			dateStarted: foreignEmploymentDto.dateStarted,
			dateEnded: foreignEmploymentDto.dateEnded
		};

		return employmentModel;
	}

	static incomeToPayload(income: IncomeModel): EmploymentIncomeDTO {
		return {
			type: income.type,
			grossAmount: CurrencyHelper.unformatAmount(income.grossAmount ?? '0'),
			netAmount: CurrencyHelper.unformatAmount(income.netAmount ?? '0'),
			frequency: income.frequency
		} as EmploymentIncomeDTO;
	}

	static incomeFromPayload(incomeDto: EmploymentIncomeDTO): IncomeModel {
		return {
			type: incomeDto.type,
			grossAmount: String(incomeDto.grossAmount),
			netAmount: String(incomeDto.netAmount),
			frequency: incomeDto.frequency
		} as IncomeModel;
	}
}
