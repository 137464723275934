import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, from } from 'rxjs';
import { RefiModalComponent } from '../refi-modal/refi-modal.component';

@Injectable({
	providedIn: 'root'
})
export class RefiModalService {
	modalRef!: NgbModalRef;
	constructor(
		private modalService: NgbModal,
		private simpFormlyModalService: SimpFormlyModalService,
		@Inject(DOCUMENT) private document: Document
	) {}

	public openRefiModal(
		formField: FormlyFieldConfig | undefined,
		modalContent: RefiModalName,
		target?: string,
		classes?: string
	): Observable<string> {
		this.modalRef = this.modalService.open(RefiModalComponent, {
			backdrop: 'static',
			centered: true,
			keyboard: false,
			animation: true,
			windowClass: classes
		});
		const modalComponent = this.modalRef.componentInstance as RefiModalComponent;
		modalComponent.refiModalName = modalContent;
		modalComponent.modalFormField = formField;
		if (target) modalComponent.target = target;

		return from(this.modalRef.result) as Observable<string>;
	}

	public closeRefiModal(refiModalName: string) {
		if ((this.modalRef?.componentInstance as RefiModalComponent)?.refiModalName === refiModalName) {
			this.modalRef?.close();
		}
	}

	public closeOpenModals(): void {
		if (this.modalService.hasOpenModals()) {
			this.modalService.dismissAll();
		}
	}

	public isModalOpen(refiModalName: string) {
		if ((this.modalRef?.componentInstance as RefiModalComponent)?.refiModalName === refiModalName) {
			return true;
		}
		return false;
	}

	public attachEventsToOpenModal(field: FormlyFieldConfig): void {
		const elementsWithOpenModal = this.document.querySelectorAll('div.open-modal');
		elementsWithOpenModal?.forEach((el: Element) =>
			'keypress click'.split(' ').forEach((e) => {
				const target = el.getAttribute('href');
				if (target) {
					el.addEventListener(e, (event) => {
						event.stopImmediatePropagation();
						if (
							(event instanceof KeyboardEvent && (event.code === 'Space' || event.code === 'Enter')) ||
							event instanceof PointerEvent ||
							event instanceof MouseEvent
						) {
							if (field.parent) {
								const modalRef = this.simpFormlyModalService.openModal(
									field.parent,
									target,
									{
										windowClass: 'mobile-modal-class',
										animation: false,
										backdrop: false
									},
									true
								);

								const subscription = modalRef.action.subscribe(() => {
									modalRef.close();
									subscription.unsubscribe();
								});
							}
						}
					});
				}
			})
		);
	}
}

export enum RefiModalName {
	ineligibleToApply = 'ineligibleToApply',
	ineligibleToApplyComplexLoan = 'ineligibleToApplyComplexLoan',
	existingLoan = 'existingLoan',
	saved = 'saved',
	eligibleToApply = 'eligibleToApply',
	consentWithheld = 'consentWithheld',
	propertyValuation = 'propertyValuation',
	currentLoanBalance = 'currentLoanBalance',
	updateYourDetails = 'updateYourDetails',
	jointApplications = 'jointApplications',
	inviteSent = 'inviteSent',
	ccr = 'ccr',
	offsetFacilityInfo = 'offsetFacilityInfo',
	noOngoingFeesInfo = 'noOngoingFeesInfo',
	redrawInfo = 'redrawInfo',
	splitLoanInfo = 'splitLoanInfo',
	feesInfo = 'feesInfo',
	redrawPayments = 'redrawPayments',
	FoodGroceries = 'foodGroceries',
	Utilities = 'utilities',
	Communication = 'communication',
	Education = 'education',
	Clothing = 'clothing',
	Transport = 'transport',
	Medical = 'medical',
	Insurance = 'insurance',
	Recreation = 'recreation',
	Children = 'children',
	RentOrBoard = 'rentOrBoard',
	Expenses = 'expenses',
	OtherExpenses = 'otherExpenses',
	StructureOfOwnership = 'structureOfOwnership',
	SubstantialBenefit = 'substantialBenefit',
	deleteConfirmation = 'deleteConfirmation',
	mbcGoodNews = 'mbcGoodNews',
	mbcTalkToUs = 'mbcTalkToUs',
	missingInformation = 'missingInformation',
	borrowAdditionalMoney = 'borrowAdditionalMoney',
	isTaxDeductible = 'isTaxDeductible',
	finaliseAndSubmitError = 'finaliseAndSubmitError',
	grossSalary = 'grossSalary',
	saveAndExit = 'saveAndExit',
	totalLoanAmount = 'totalLoanAmount',
	beforeYouChoose = 'beforeYouChoose',
	lowDepositOptions = 'lowDepositOptions',
	lvrCondition = 'lvrCondition',
	dataEnrichment = 'dataEnrichment',
	consentReminderSent = 'consentReminderSent',
	ReviewExpenses = 'reviewExpenses',
	ExpensesLow = 'expensesLow',
	coborrowerInformation = 'coborrowerInformation',
	ConfirmWhatYouOwe = 'confirmWhatYouOwe',
	MoneyGoingOut = 'moneyGoingOut',
	CreditCheck = 'creditCheck',
	ConfirmDetailsLoading = 'confirmDetailsLoading',
	dataEnrichmentLonger = 'dataEnrichmentLonger'
}
