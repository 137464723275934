import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy
} from '@angular/core';
import { RefiStore } from '@app/modules/refi-journey/enums/refi-store.enum';
import { RefiCommon } from '@app/modules/refi-journey/typings/refi-common';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'refi-footer',
	templateUrl: './refi-footer.component.html',
	styleUrls: ['./refi-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefiFooterComponent implements OnDestroy {
	showPopup = false;
	needHelpMessage = '';
	contactUsButton = { title: 'Contact us' };
	emailButton = { title: 'Email' };
	callButton = { title: 'Call' };
	contactEmail = '';
	contactPhone = '';

	private destroy$: Subject<void> = new Subject();

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private formDataService: FormDataService,
		private cdr: ChangeDetectorRef
	) {
		this.formDataService
			.select$(RefiStore.Common)
			.pipe(
				takeUntil(this.destroy$),
				filter((res) => !!res.length)
			)
			.subscribe((res) => {
				const refiCommonMetadata = (res as RefiCommon[])?.[0];
				if (refiCommonMetadata) {
					this.needHelpMessage = refiCommonMetadata.footerContent;
					this.contactEmail = refiCommonMetadata.contactEmail;
					this.contactPhone = refiCommonMetadata.contactPhone;
					this.cdr.markForCheck();
				}
			});
	}

	@HostListener('document:click', ['$event'])
	clickout(event: Event): void {
		if (!this.elementRef.nativeElement.contains(event.target as Node)) {
			this.closePopup();
		}
	}

	public closePopup(): void {
		this.showPopup = false;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
