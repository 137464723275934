/**
 * this util was created based on AccessService
 * extend further if another method is needed from AccessService
 */

import { AccessType } from '../model/access-type.model';
import { Permission, PermissionEvaluator, PermissionType } from './permission';

export const USER_PERMISSIONS: Permission[] = [] as Permission[];

export const isAllowed = (permissionId: number, evaluator: PermissionEvaluator): boolean => {
	const permissionIdValue: number = permissionId;
	if (!permissionIdValue) {
		return true;
	}
	switch (evaluator.toLowerCase()) {
		case PermissionEvaluator.canAccess.toLowerCase():
			return canAccess(permissionIdValue);
		case PermissionEvaluator.canWrite.toLowerCase():
			return canWrite(permissionIdValue);
	}
	return true;
};

const canWrite = (permissionId: number): boolean => {
	const perm = findPermission(permissionId);
	return (
		perm != null &&
		((perm.all & PermissionType.Write) !== 0 ||
			(perm.team & PermissionType.Write) !== 0 ||
			(perm.own & PermissionType.Write) !== 0)
	);
};

const canAccess = (permissionId: number): boolean => {
	return getAccessType(PermissionType.Yes, permissionId) !== AccessType.None;
};

const getAccessType = (access: number, permissionId: number): AccessType => {
	const perm = findPermission(permissionId);
	if (!perm) {
		return AccessType.None;
	}
	if (hasAllAccess(perm, access)) {
		return AccessType.All;
	}
	if (hasTeamAccess(perm, access)) {
		return AccessType.Team;
	}
	if (hasOwnAccess(perm, access)) {
		return AccessType.Own;
	}
	return AccessType.None;
};

const hasAllAccess = (permission: Permission, access: number): boolean => {
	return hasSomeAccess(permission.all, access);
};

const hasTeamAccess = (permission: Permission, access: number): boolean => {
	return hasSomeAccess(permission.team, access);
};

const hasOwnAccess = (permission: Permission, access: number): boolean => {
	return hasSomeAccess(permission.own, access);
};

const hasSomeAccess = (granted: number, access: number): boolean => {
	const accessToCheck = granted === PermissionType.Write ? PermissionType.Write | PermissionType.Read : granted;
	return (access & accessToCheck) !== 0;
};

const findPermission = (permissionId: number): Permission | undefined => {
	return USER_PERMISSIONS.find((perm: Permission) => perm.id === permissionId);
};
