export interface Permission {
	id: number;
	all: number;
	own: number;
	team: number;
}

export enum PermissionType {
	Read = 1,
	Write = 2,
	Yes = 4
}

export enum PermissionEvaluator {
	canAccess = 'canAccess',
	canWrite = 'canWrite'
}

export enum PermissionOperation {
	hide = 'hide',
	readonly = 'readonly'
}
