import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { FormAddressesStore } from '@app/modules/shared/store/form-addresses/form-addresses.store';
import { FullAddress } from '@app/modules/shared/store/form-addresses/typings/form-addresses';
import { Address } from '@app/modules/typings/api';
import { CountryCodeLimited, SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { JourneyType } from '../enums/app.enums';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { SharedFlagsService } from '../store/shared-flags/shared-flags.service';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formAddressesStore: FormAddressesStore,
		private sharedFlagsService: SharedFlagsService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.ADDRESS);
	}

	saveAddress(address: Address): Observable<number> {
		address.applicationId = this.applicationDataQuery.applicationId();
		return this.postCustom(``, address).pipe(
			tap(() => {
				this.fetchAllAddress();
			})
		);
	}

	/**
	 * Updates addresses store with latest list of addresses
	 */
	fetchAllAddress(onComplete?: Function): void {
		const urlPrefix = this.sharedFlagsService.currentJourney === JourneyType.ApprovalInPrinciple ? '' : 'application/';
		const addresses$ = <Observable<Address[]>>this.get(`${urlPrefix}${this.applicationDataQuery.applicationId()}`);
		addresses$
			.pipe(
				map((addresses) => {
					const transformed = addresses.map((address) => {
						if (address.state) {
							address.country = address.country ?? CountryCodeLimited.AU;
						}
						return {
							id: address.id,
							address: address,
							label: SimpAddressHelper.buildAddressLine(address as SimpAddress)
						};
					}) as FullAddress[];
					this.formAddressesStore.update({ addresses: transformed });
					this.formAddressesStore.setLoading(false);
					return transformed;
				})
			)
			.subscribe(() => {
				if (onComplete) {
					onComplete();
				}
			});
	}
}
