import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { URL_CONSTANTS } from '../constants/api.constants';
import { JourneySetting } from '../model/journey-setting.model';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class JourneySettingService extends BaseJourneyService<JourneySetting> {
	private journeySetting$?: Observable<JourneySetting>;
	constructor() {
		super();
		this.setRoute(URL_CONSTANTS.JOURNEY_SETTING);
	}

	/**
	 * It's cached after first fetch. If we switch journey on the go, we need to invalidate the cache before we toggle journey
	 * @returns
	 */
	fetchJourneySetting(): Observable<JourneySetting> {
		this.journeySetting$ = this.journeySetting$ ?? this.get(`FullApplication`).pipe(shareReplay(1));
		return this.journeySetting$;
	}
}
