import { Injectable } from '@angular/core';
import { JourneyType } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { map, Observable, tap } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiPropertyDetails, RefiPropertyDetailsTransformer } from '../../models/refi-property-details.model';
import { RefiAssetsService } from '../assets/refi-assets.service';

@Injectable({
	providedIn: 'root'
})
export class RefiPropertyDetailsService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private sharedFlagService: SharedFlagsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private refiAssetService: RefiAssetsService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.simpFormlyHandlerService.upsertToState(
			'details',
			this.refiAssetService
				.getPrimaryProperty()
				.pipe(
					map((property) => [
						RefiPropertyDetailsTransformer.fromPayload(
							property,
							this.sharedFlagService.currentJourney === JourneyType.Refi
						)
					])
				)
		);
	}

	getPropertyDetails(): Observable<number> {
		return <Observable<number>>this.getCustom('Property/PrimaryProperty');
	}

	savePropertyDetails(payload: RefiPropertyDetails): Observable<number> {
		return this.postCustom(
			'Property/PrimaryProperty',
			RefiPropertyDetailsTransformer.toPayload(payload, this.applicationDataQuery.applicationId())
		).pipe(tap(() => this.simpFormlyHandlerService.upsertToFullPathWithData(RefiStore.PropertyDetails, [payload])));
	}
}
