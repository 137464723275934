<div class="refi-modal">
	<div class="refi-modal-header">
		<div *ngIf="labels.spinner" class="refi-spinner simp-margin-bottom-small"></div>
		<simp-icon *ngIf="labels.iconClass" [name]="labels.iconClass" class="simp-margin-bottom-small"></simp-icon>
		<h1 class="simp-h4 simp-text--primary refi-modal__title">{{ labels.title }}</h1>
	</div>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock1"></p>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock2"></p>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock3"></p>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock4"></p>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock5"></p>
	<p class="simp-text--primary refi-modal__text-block" [innerHtml]="labels.textBlock6"></p>
	<div *ngIf="labels.confirm" class="simp-margin-bottom-large">
		<simp-checkbox
			[id]="refiModalName"
			[ngClass]="refiModalName"
			[formControl]="$any(confirmControl)"
			[labelOnSide]="true"
			metadataKey
			><span [innerHTML]="labels.confirm"></span>
		</simp-checkbox>
	</div>
	<div class="refi-modal-footer simp-margin-top-medium">
		<button
			type="button"
			class="btn btn-lg simp-button btn-primary col-12"
			[class.simp-margin-bottom-medium]="labels.secondaryButton.length > 0 || labels.tertiaryButton.length > 0"
			(click)="handleActionButtonClick('primaryButton')"
			[disabled]="confirmControl.value === false"
			*ngIf="labels.primaryButton.length > 0"
		>
			<span [innerHTML]="labels.primaryButton"></span>
		</button>
		<button
			type="button"
			class="btn btn-lg simp-button btn-secondary col-12"
			[class.simp-margin-bottom-small]="labels.tertiaryButton.length > 0"
			(click)="handleActionButtonClick('secondaryButton')"
			*ngIf="labels.secondaryButton.length > 0"
		>
			<span [innerHTML]="labels.secondaryButton"></span>
		</button>
		<button
			type="button"
			class="btn btn-lg simp-button simp-link col-12"
			(click)="handleActionButtonClick('tertiaryButton')"
			*ngIf="labels.tertiaryButton.length > 0"
		>
			<span [innerHTML]="labels.tertiaryButton"></span>
			<simp-icon name="fal fa-external-link" *ngIf="external" class="simp-text--grey90"></simp-icon>
		</button>
	</div>
</div>
