import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateFormat, DateFormatCodes } from '@app/modules/shared/constants/constants';
import { getAUTimeZone } from '../helper/date.helper';

@Injectable({ providedIn: 'root' })
export class AggregateFormatterService {
	constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

	public formatDate(
		date: string | Date | null | undefined,
		format: DateFormat = 'shortDate',
		withTimeZone?: boolean
	): string {
		if (!date) return '';

		const newDate = date instanceof Date ? date : new Date(date);
		const formattedDate = this.datePipe.transform(newDate, DateFormatCodes[format]) ?? '';

		if (withTimeZone) {
			const timeZone = getAUTimeZone(newDate);
			return formattedDate ? `${formattedDate} ${timeZone}` : '';
		}

		return formattedDate;
	}

	public formatAmount(amount: string | number | undefined): string {
		if (amount != undefined) {
			return (
				this.currencyPipe.transform(amount, undefined, undefined, String(amount).split('.')[1] ? '1.2-2' : '1.0-2') ??
				''
			);
		}

		return '';
	}

	public formatCheckbox(isEnabled: boolean): string {
		return isEnabled ? 'Yes' : 'No';
	}

	public formatConditionalCommaSeparator(value: string): string {
		if (value.length > 0 && !(value.endsWith(',') || value.endsWith(', '))) {
			return (value += ', ');
		}
		return value;
	}
}
