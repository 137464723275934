import { PreferredContactCompanyTrust } from '@app/modules/shared/enums/app.enums';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { CompanyContactDetailsDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class CompanyContactDetailsTransformer {
	// TODO: Update model
	static fromPayload(details: CompanyContactDetailsDTO): CompanyContactDetailsModel[] {
		return [
			{
				applicationId: details.applicationId,
				id: details.id,
				email: details.email,
				mobile: PhoneNumberHelper.fromPayload(details.officeMobile),
				landline: PhoneNumberHelper.fromPayload(details.officePhone),
				fax: PhoneNumberHelper.fromPayload(details.officeFax),
				preferredContact: details.preferredContact,
				webAddress: details.webAddress
			}
		];
	}

	static toPayload(details: CompanyContactDetailsModel): Partial<CompanyContactDetailsDTO> {
		return {
			applicationId: details.applicationId,
			id: details.id,
			email: details.email,
			officeFax: PhoneNumberHelper.toPayLoad(details.fax),
			officeMobile: PhoneNumberHelper.toPayLoad(details.mobile),
			officePhone: PhoneNumberHelper.toPayLoad(details.landline),
			preferredContact: details.preferredContact,
			webAddress: details.webAddress
		};
	}
}

interface CompanyContactDetailsModel {
	id: number;
	applicationId: number;
	email?: string;
	mobile?: PhoneDetails;
	landline?: PhoneDetails;
	fax?: PhoneDetails;
	preferredContact?: PreferredContactCompanyTrust;
	webAddress?: string;
}
