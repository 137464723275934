import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { AbnLookupTransformerService } from '@app/modules/abn-lookup/services/abn-lookup-transformer.service';
import { ApplicantEntityType, PreferredContactPerson, TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { deletedSuccessfullyMessage, hasDuplicates, savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { RelatedEntitiesService } from '@app/modules/shared/service/related-entities.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	getTemplateOptions
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	BankDetailsDTO,
	CompanyTrustAddressesDTO,
	ContactPersonDTO,
	TrustApplicantTypeDTO,
	TrustBusinessDetailsDTO,
	TrustDeedVariationDTO,
	TrustDetailsDTO,
	TrusteeDetailsDTO
} from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get, merge } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, map, of } from 'rxjs';
import { SourceOfWealthModel } from '../../models/source-of-wealth.model';
import { TrustBeneficiaryModel } from '../../models/trust-beneficiary.model';
import { TrustBusinessDetailsModel } from '../../models/trust-business-details.model';
import { TrustContactDetailsModel } from '../../models/trust-contact-details.model';
import { TrustDetailsModel } from '../../models/trust-details.model';
import { TrustSettlorModel } from '../../models/trust-settlor.model';
import { TrustApplicantTypeModel } from '../../models/trust-type.model';
import { TrusteeModel } from '../../models/trustee.model';
import { SourceOfWealthService } from '../source-of-wealth.service';
import { TrustApplicantsService } from './trust-applicants.service';

@Injectable({ providedIn: 'root' })
export class TrustApplicantTransformerService {
	constructor(
		private relatedEntitiesService: RelatedEntitiesService,
		private trustApplicantService: TrustApplicantsService,
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private toastr: ToastrService,
		private formEnumsService: FormEnumsService,
		private abnLookupTransformerService: AbnLookupTransformerService,
		private sourceOfWealthService: SourceOfWealthService
	) {}

	transform(formFields: FormlyFieldConfig[], index: number): FormlyFieldConfig[] {
		const trustApplicant = this.applicationDataQuery.getTrustApplicants()[index];
		this.updateLabel(formFields, trustApplicant);
		this.configureDeedVariation(formFields, trustApplicant.id);
		this.configureBusinessDetails(formFields, trustApplicant.id, trustApplicant.name);
		this.configureContactPerson(formFields);
		this.configureContactDetails(formFields, trustApplicant.id);
		this.configureAddresses(formFields);
		this.configureTrustees(formFields, trustApplicant.id);
		this.configureSourceOfWealth(formFields, trustApplicant.id);
		this.configureBankingDetails(formFields, trustApplicant.id);
		this.configureBeneficiaries(formFields, trustApplicant.id);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'trustApplicant');

		this.configureSettlors(formFields, trustApplicant.id);

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.type`,
			(field, event: FormlyArrayUpdateEvent<TrustApplicantTypeDTO>) => {
				const model = get(field, `model[${event.index}]`) as TrustApplicantTypeModel;
				const clonedModel = cloneDeep(model);

				clonedModel.id = trustApplicant.id;
				clonedModel.trustName = trustApplicant.name;
				this.trustApplicantService.saveTrustType(clonedModel).subscribe((id: number) => {
					this.toastr.success(savedSuccessfullyMessage('Trust role'));
					this.simpFormlyHandlerService.updateToState(`type`, model, 0);
				});
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.trustDetails`,
			(field, event: FormlyArrayUpdateEvent<TrustDetailsDTO>) => {
				const model = get(field, `model[${event.index}]`) as TrustDetailsModel;
				const clonedModel = cloneDeep(model);
				clonedModel.id = trustApplicant.id;
				this.trustApplicantService.saveTrustDetails(clonedModel).subscribe((id: number) => {
					this.simpFormlyHandlerService.updateToState(`trustDetails`, model, 0);
					//UPDATE STORE TO REFLECT TRUST NAME CHANGES
					const storeData = cloneDeep(this.formDataService.getSetupApplicants());
					const i = storeData.findIndex((x) => x.id === model.id);
					if (storeData[i].trustName !== model.trustName) {
						storeData[i].trustName = model.trustName;
						this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[i], i);

						//UPDATE SECTION TITLE
						const section = field.parent;
						if (section?.templateOptions) {
							section.templateOptions.label = model.trustName;
						}
					}
					this.toastr.success(savedSuccessfullyMessage('Trust details'));
				});
			}
		);

		formlyRegisterHooks(formFields, `trustApplicant.relatedPerson.preferredContact`, {
			onInit: (formField: FormlyFieldConfig | undefined) => {
				if (formField && formField.templateOptions) {
					const listItems = this.formEnumsService
						.fetch('PreferredContactPerson')
						.pipe(
							map((options: EnumObject[]) => options.filter((option) => option.id !== PreferredContactPerson.HomePhone))
						);
					formField.templateOptions.options = listItems;
				}
			}
		});

		return formFields;
	}

	private configureBusinessDetails(formFields: FormlyFieldConfig[], applicantId: number, applicantName: string) {
		formlyOnClick(formFields, 'trustApplicant.businessDetails.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as TrustBusinessDetailsDTO;
			const rowIndex = Number(field.parent?.key);
			this.trustApplicantService.deleteBusinessDetails(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`trustApplicant.businessDetails`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Business'));
			});
		});

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.businessDetails`,
			(field, event: FormlyArrayUpdateEvent<TrustBusinessDetailsModel>) => {
				const models = field.model as TrustBusinessDetailsModel[];
				const businessDetails = merge({}, event.data, models[event.index || 0]);
				this.trustApplicantService.saveTrustBusinessDetails(businessDetails, applicantId).subscribe(() => {
					this.toastr.success(savedSuccessfullyMessage('Business details'));
				});
			}
		);

		// Sets business name to trust name if checkbox ticked
		formlyOnClick(
			formFields,
			'trustApplicant.businessDetails.businessNameSameAsTrustName',
			(field: FormlyFieldConfig) => {
				const isSelected = field?.formControl?.value as boolean;
				const businessNameField = getFormField(field?.parent?.fieldGroup, 'businessName');
				const trustName = isSelected
					? this.simpFormlyHandlerService.getStateData<TrustDetailsModel>('trustDetails')?.[0]?.trustName || ''
					: '';
				businessNameField?.formControl?.setValue(trustName);
				field?.parent?.formControl?.markAllAsTouched();
			}
		);

		formlyOnClick(formFields, 'trustApplicant.businessDetails.abn', this.handleAbnSearch.bind(this));
		formlyOnClick(formFields, 'trustApplicant.businessDetails.businessName', this.handleAbnSearch.bind(this));
	}

	private updateLabel(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant) {
		const trustApplicantTO = getTemplateOptions(formFields, 'trustApplicant');
		trustApplicantTO.label = trustApplicant?.name;
	}

	private configureContactPerson(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.contactPerson`,
			(field, event: FormlyArrayUpdateEvent<ContactPersonDTO>) => {
				const model = cloneDeep(event.data);
				model.applicationId = this.applicationDataQuery.applicationId();
				this.trustApplicantService.saveContactPerson(model).subscribe(() => {
					this.toastr.success(savedSuccessfullyMessage('Contact person'));
				});
			}
		);
	}

	private configureContactDetails(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.contactDetails`,
			(field, event: FormlyArrayUpdateEvent<TrustContactDetailsModel>) => {
				const model = cloneDeep(event.data);
				model.applicationId = this.applicationDataQuery.applicationId();
				this.trustApplicantService.saveContactDetails(model, applicantId).subscribe(() => {
					this.toastr.success(savedSuccessfullyMessage('Contact details'));
				});
			}
		);
	}

	private configureAddresses(formFields: FormlyFieldConfig[]) {
		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.addresses`,
			(field, event: FormlyArrayUpdateEvent<CompanyTrustAddressesDTO>) => {
				const model = get(field, `model[${event.index}]`) as CompanyTrustAddressesDTO;
				this.trustApplicantService.saveAddresses(model).subscribe((id: number) => {
					this.toastr.success(savedSuccessfullyMessage('Address'));
				});
			}
		);
	}

	private configureTrustees(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyRegisterHooks(formFields, 'trustApplicant.trusteeDetails.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const model = field.model as TrusteeModel;
					let fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);

					const data$ = this.simpFormlyHandlerService.mapToStateData<TrusteeModel[]>('trusteeDetails');
					if (data$) {
						const listItems = combineLatest([
							this.formEnumsQuery.selectEnumOptions('Persons'),
							this.formEnumsQuery.selectEnumOptions('OtherCompanies'),
							data$
						]).pipe(
							map(([personApplicants, companyApplicants, trustees]) => {
								const filteredPersons = (personApplicants as unknown as ApplicantEnumObject[]).filter(
									(x) => x.type === TargetType.PersonApplicant
								);
								const filteredCompanies = (companyApplicants as unknown as ApplicantEnumObject[]).filter(
									(y) => y.type === TargetType.CompanyApplicant
								);

								fieldOptions = model?.id ? [] : fieldOptions;
								const existingTrustees = trustees
									.filter((sh) => sh?.existingSelect?.id !== model?.existingSelect?.id)
									.map((sh) => {
										if ([-1, -2].includes(sh.existingSelect?.id)) {
											return sh.partyId;
										}
										return sh.existingSelect?.id;
									});
								return [...filteredPersons, ...filteredCompanies].filter((sh) => !existingTrustees.includes(sh.id));
							})
						);
						field.templateOptions.options = listItems;
					}
				}
				return of();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.trusteeDetails`,
			(field, event: FormlyArrayUpdateEvent<TrusteeModel>) => {
				this.trustApplicantService.saveTrustee(event.data, applicantId).subscribe(() => {
					this.toastr.success(savedSuccessfullyMessage('Trustee'));
					this.simpFormlyHandlerService.updateToState(`trusteeDetails`, event.data, event.index || 0);
				});
			}
		);

		formlyAddCustomValidator(formFields, 'trustApplicant.trusteeDetails', {
			uniqueEmail: {
				expression: (c: UntypedFormArray) => {
					const trusteeIds = ((c.value || []) as TrusteeModel[]).map((trustee) => trustee.existingSelect?.id);
					return !hasDuplicates(trusteeIds);
				}
			}
		});

		formlyOnClick(formFields, 'trustApplicant.trusteeDetails.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as TrusteeDetailsDTO;
			const rowIndex = Number(field.parent?.key);
			this.trustApplicantService.deleteTrustee(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`trusteeDetails`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Trustee'));
			});
		});
	}

	private configureDeedVariation(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		// Delete all trust deeds if trust not established and section is hidden
		formlyOnClick(formFields, `trustApplicant.trustDetails.established`, (field: FormlyFieldConfig) => {
			const established = (field?.model as TrustDetailsModel).established;

			if (!established) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				const deeds = field.parent?.parent?.parent?.model.deedVariation as TrustDeedVariationDTO[];
				deeds.forEach((deed, index) => {
					this.trustApplicantService.deleteDeedVariation(deed, applicantId).subscribe(() => {
						this.simpFormlyHandlerService.deleteFromState(`trustDeedVariation`, index);
						this.toastr.success(deletedSuccessfullyMessage('Trust deed variation'));
					});
				});
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.deedVariation`,
			(field, event: FormlyArrayUpdateEvent<TrustDeedVariationDTO>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`) as TrustDeedVariationDTO);
				this.trustApplicantService
					.saveTrustDeedVariation(model, event.index as number, applicantId)
					.subscribe((id: number) => {
						model.id = id;
						this.simpFormlyHandlerService.updateToState(`deedVariation`, model, event.index!);
						this.toastr.success(savedSuccessfullyMessage('Trust deed variation'));
					});
			}
		);

		formlyOnClick(formFields, 'trustApplicant.deedVariation.deleteVariation', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as TrustDeedVariationDTO;
			const rowIndex = Number(field.parent?.key);
			this.trustApplicantService.deleteDeedVariation(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`deedVariation`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Trust deed variation'));
			});
		});
	}

	private configureBankingDetails(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.bankingDetails`,
			(field, event: FormlyArrayUpdateEvent<BankDetailsDTO>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`) as BankDetailsDTO);
				model.applicantId = applicantId;
				this.trustApplicantService.saveBankingDetails(model).subscribe((id: number) => {
					model.id = id;
					this.simpFormlyHandlerService.updateToState(`bankingDetails`, model, event.index!);
					this.toastr.success(savedSuccessfullyMessage('Banking details'));
				});
			}
		);

		formlyOnClick(formFields, `trustApplicant.bankingDetails.delete`, (field: FormlyFieldConfig) => {
			const bankDetailsModel = field.model as BankDetailsDTO;
			if (!bankDetailsModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}
			const bankName = this.formEnumsQuery.getOptionLabel(
				'FinancialInstitution',
				bankDetailsModel.financialInstitution
			);

			const message = `The account <b>${
				bankDetailsModel.accountNumber ?? ''
			}</b> held with <b>${bankName}</b> will be deleted.`;

			this.confirmationDialogService
				.deleteConfirmation('Delete bank account?', message, 'Delete', 'Cancel')
				.subscribe((result) => {
					if (result) {
						const rowIndex = Number(field.parent?.key);
						this.trustApplicantService.deleteBankingDetails(bankDetailsModel).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState(`banking`, rowIndex);
							this.toastr.success(deletedSuccessfullyMessage('Bank account'));
						});
					}
				});
		});
	}

	private configureBeneficiaries(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyAddCustomValidator(formFields, 'trustApplicant.beneficiaries', {
			uniqueEmail: {
				expression: (c: UntypedFormArray) => {
					const beneficiaries = ((c.value || []) as TrusteeModel[]).map((trustee) => trustee.partyId);
					return !hasDuplicates(beneficiaries);
				}
			}
		});

		formlyRegisterHooks(formFields, 'trustApplicant.beneficiaries.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);

					const data$ = this.simpFormlyHandlerService.mapToStateData<TrusteeModel[]>('beneficiaries');
					if (data$) {
						const listItems = combineLatest([
							this.formEnumsQuery.selectEnumOptions('Persons'),
							this.formEnumsQuery.selectEnumOptions('OtherCompanies'),
							data$
						]).pipe(
							map(([x, y, trustees]) => {
								const model = field.model as TrusteeModel;
								const existingTrustees = trustees
									.filter((sh) => sh?.existingSelect?.id !== model?.existingSelect?.id)
									.map((sh) => {
										if ([-1, -2].includes(sh.existingSelect?.id)) {
											return sh.partyId;
										}
										return sh.existingSelect?.id;
									});
								return [...fieldOptions, ...x, ...y]
									.filter((sh) => !existingTrustees.includes(sh.id))
									.map((person) => ({
										...person,
										click:
											(person.type as unknown as TargetType) === TargetType.RelatedPerson ||
											(person.type as unknown as TargetType) === TargetType.RelatedCompany
												? () =>
														this.relatedEntitiesService.openPrefilledRelatedEntityModal(
															field,
															applicantId,
															this.saveBeneficiary
														)
												: null
									}));
							})
						);
						field.templateOptions.options = listItems;
					}
					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveBeneficiary);
				}
				return of();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.beneficiaries`,
			(field, event: FormlyArrayUpdateEvent<TrustBeneficiaryModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`) as TrustBeneficiaryModel);
				model.applicationId = this.applicationDataQuery.applicationId();
				const partyId = model.existingSelect.id;
				if (partyId !== -2 && partyId !== -1) {
					this.saveBeneficiary(model, event.index || 0, applicantId);
				}
			}
		);

		formlyOnClick(formFields, 'trustApplicant.beneficiaries.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as TrustBeneficiaryModel;
			const rowIndex = Number(field.parent?.key);
			this.trustApplicantService.deleteBeneficiary(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`beneficiaries`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Beneficiary'));
			});
		});
	}

	private handleAbnSearch(formField: FormlyFieldConfig, action: string): void {
		this.abnLookupTransformerService.handleTrustApplicantAbnSearch(formField, action);
	}

	private saveBeneficiary = (model: TrustBeneficiaryModel, index: number, applicantId: number) =>
		this.trustApplicantService.saveBeneficiary(model, applicantId).subscribe((id: number) => {
			model.id = id;
			model.partyId = model.existingSelect.id;
			this.toastr.success(savedSuccessfullyMessage('Beneficiary'));
			this.simpFormlyHandlerService.updateToState(`beneficiaries`, model, index || 0);
		});

	private saveSettlor = (model: TrustSettlorModel, index = 0, applicantId: number) => {
		if (model.vestingDate) {
			return this.trustApplicantService.saveSettlor(model, applicantId).subscribe((id) => {
				model.partyId = model.existingSelect.id;
				model.id = id;
				this.toastr.success(savedSuccessfullyMessage('Settlor'));
				this.simpFormlyHandlerService.updateToState(`settlors`, model, index);
			});
		} else {
			this.simpFormlyHandlerService.updateToState(`settlors`, model, index);
			return new Subscription();
		}
	};

	private configureSettlors(formFields: FormlyFieldConfig[], applicantId: number) {
		formlyRegisterHooks(formFields, 'trustApplicant.settlors.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const model = field.model as TrusteeModel;
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);

					const data$ = this.simpFormlyHandlerService.mapToStateData<TrusteeModel[]>('settlors');
					if (data$) {
						const listItems = combineLatest([this.formEnumsQuery.selectEnumOptions('Persons'), data$]).pipe(
							map(([x, trustees]) => {
								const existingTrustees = trustees
									.filter((sh) => sh?.existingSelect?.id !== model?.id)
									.map((sh) => {
										if ([-1, -2].includes(sh.existingSelect?.id)) {
											return sh.partyId;
										}
										return sh.existingSelect?.id;
									});
								return [...fieldOptions, ...x].map((person) => ({
									...person,
									click:
										(person.type as unknown as TargetType) === TargetType.RelatedPerson
											? () =>
													this.relatedEntitiesService.openPrefilledRelatedEntityModal(
														field,
														applicantId,
														this.saveSettlor
													)
											: null
								}));
							})
						);
						field.templateOptions.options = listItems;
					}

					return this.relatedEntitiesService.configureRelatedEntityModal(field, applicantId, this.saveSettlor);
				}
				return of();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.settlors`,
			(field, event: FormlyArrayUpdateEvent<TrustSettlorModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`) as TrustSettlorModel);
				model.applicationId = this.applicationDataQuery.applicationId();
				const modelId = model.existingSelect.id;
				if (modelId != -1) {
					this.trustApplicantService.saveSettlor(model, applicantId).subscribe((id: number) => {
						model.id = id;
						this.toastr.success(savedSuccessfullyMessage('Settlor'));
						this.simpFormlyHandlerService.updateToState(`settlors`, model, 0);
					});
				}
			}
		);

		formlyOnClick(formFields, 'trustApplicant.settlors.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as TrustSettlorModel;
			const rowIndex = Number(field.parent?.key);
			this.trustApplicantService.deleteSettlors(model, applicantId).subscribe(() => {
				formlyDeleteFromArray(field);
				this.simpFormlyHandlerService.deleteFromState(`settlors`, rowIndex);
				this.toastr.success(deletedSuccessfullyMessage('Settlor'));
			});
		});
	}

	private configureSourceOfWealth(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		this.sourceOfWealthTitleUpdate(formFields);
		this.handleSourceOfWealthDeleteButtonClick(formFields);
		this.handleSourceOfWealthSave(formFields, applicantId);
	}

	private sourceOfWealthTitleUpdate(formFields: FormlyFieldConfig[] | undefined) {
		const sourceOfIncomeTypeField = getFormField(
			formFields,
			`trustApplicant.sourceOfWealth.typeId`
		) as FormlyFieldConfig;
		formlyExtendExpressionProperties(formFields, `trustApplicant.sourceOfWealth.typeId`, {
			'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
				return `${Number(field.parent?.key) + 1} - ${sourceOfIncomeTypeField?.props?.label}`;
			}
		});
	}

	private handleSourceOfWealthDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnClick(formFields, `trustApplicant.sourceOfWealth.delete`, (field: FormlyFieldConfig) => {
			const sourceOfWealthModel = field.model as SourceOfWealthModel;

			if (!sourceOfWealthModel?.id) {
				formlyDeleteFromArray(field);
				return;
			}

			const rowIndex = Number(field.parent?.key);
			this.sourceOfWealthService
				.deleteSourceOfWealth(sourceOfWealthModel, ApplicantEntityType.TrustApplicant)
				.subscribe(() => {
					formlyDeleteFromArray(field);
					this.simpFormlyHandlerService.deleteFromState(`sourceOfWealth`, rowIndex);
					this.toastr.success(deletedSuccessfullyMessage(`Source of wealth`));
				});
		});
	}

	private handleSourceOfWealthSave(formFields: FormlyFieldConfig[] | undefined, applicantId: number) {
		formlyOnStatusChangedToValid(
			formFields,
			`trustApplicant.sourceOfWealth`,
			(field, event: FormlyArrayUpdateEvent<SourceOfWealthModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as SourceOfWealthModel;
				model.applicantId = applicantId;
				this.sourceOfWealthService
					.saveSourceOfWealth(model, ApplicantEntityType.TrustApplicant)
					.subscribe((id: number) => {
						model.id = id;
						this.simpFormlyHandlerService.updateToState(`sourceOfWealth`, model, event.index!);
						this.toastr.success(savedSuccessfullyMessage('Source of wealth'));
					});
			}
		);
	}
}
