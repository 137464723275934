import { NameTitle, TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { CompanyDirectorsDTO } from '@app/modules/typings/api';

export class CompanyDirectorsTransformer {
	static fromPayload(director: CompanyDirectorsDTO, formEnumsQuery: FormEnumsQuery): CompanyDirectorsModel {
		const title = formEnumsQuery.getOptionLabel('NameTitle', director.nameTitleId);
		return {
			existingSelect: formEnumsQuery.getExistingPartiesById({ type: director.type, id: director.personId }),
			id: director.id,
			fullName: director.firstName ? `${title}. ${director.firstName} ${director.lastName}` : ''
		};
	}

	static toPayload(applicantId: number, director: CompanyDirectorsModel): Partial<CompanyDirectorsDTO> {
		return {
			id: director.id || 0,
			companyApplicantId: applicantId,
			applicationId: director.applicationId,
			type: director.type || director.existingSelect.type,
			personId: director.personId || director.existingSelect.id
		};
	}
}

export interface CompanyDirectorsModel {
	id?: number;
	type?: TargetType;
	applicationId?: number;
	nameTitleId?: NameTitle;
	existingSelect: ApplicantEnumObject;
	firstName?: string;
	lastName?: string;
	personId?: number;
	fullName?: string;
}
