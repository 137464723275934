import { EmploymentStatus, IncomeType, JobLevel, TargetType, YesNo } from '@app/modules/shared/enums/app.enums';
import {
	addDaysToDate,
	calculateMonthly,
	getNullableBoolean,
	getNullableYesNo,
	isFutureDate,
	roundNumber
} from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { EmploymentDTO } from '@app/modules/typings/api';
import { CurrencyHelper, FrequencyShort } from '@simpology/client-components/utils';

import { ForeignEmploymentModel } from '@app/modules/financial-position/model/foreign-employment.model';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { EmployerDetailsModel } from '../../employers/model/employer.model';
import { IncomePeriodModel } from './applicant-employment.model';
import { NotEmployedModel } from './notemployed-income.model';
import { SelfEmploymentModel } from './self-employment.model';
import { IncomeModel } from './employment-income.model';

export class EmploymentModelTransformer {
	static setEmploymentExtract(
		formEnumsQuery: FormEnumsQuery,
		employment: EmploymentModel,
		employerDetailsModel: EmployerDetailsModel[]
	): void {
		const payg = employment.employmentDetails.payg;
		const paygEmployerId = payg?.employerDetailsSelect?.id;
		const selfEmployed = employment.employmentDetails.selfEmployment;
		const foreignEmployment = employment.employmentDetails.foreignEmployment;
		const foreignEmploymentId = foreignEmployment?.employerDetailsSelect?.id;

		switch (employment.employmentDetails.typeOfIncome.type) {
			case IncomeType.payg: {
				const matchedEmployer = employerDetailsModel.find((employer) => employer.employerId === paygEmployerId);
				const employerName = paygEmployerId
					? matchedEmployer?.extract ?? payg.employerDetailsSelect?.companyName
					: 'PAYG';
				const basis = payg?.basis ? formEnumsQuery.getOptionLabel('PaygBasis', payg.basis) : '';
				employment.employmentDetails.extract = `${employerName} - ${basis}`;
				break;
			}
			case IncomeType.selfEmployed: {
				const basis = selfEmployed?.basis ? formEnumsQuery.getOptionLabel('SelfEmployedBasis', selfEmployed.basis) : '';
				employment.employmentDetails.extract = `Self employed - ${basis}`;
				break;
			}
			case IncomeType.ForeignEmployed: {
				const matchedEmployer = employerDetailsModel.find((employer) => employer.employerId === foreignEmploymentId);
				const basis = foreignEmployment?.basis
					? formEnumsQuery.getOptionLabel('ForeignEmployedBasis', foreignEmployment.basis)
					: '';
				employment.employmentDetails.extract = `${
					matchedEmployer?.extract ?? (foreignEmployment?.employerDetailsSelect?.companyName || '')
				} (Foreign employed) - ${basis}`;
				break;
			}

			case IncomeType.NotEmployed: {
				employment.employmentDetails.extract = `Not employed`;
				break;
			}
		}
	}

	static toPayload(employmentModel: EmploymentModel, applicationId: number): EmploymentDTO {
		const payg = employmentModel.employmentDetails.payg!;
		return {
			id: employmentModel.id,
			applicationId: applicationId,
			applicantId: payg.ownership,
			status: payg.status ?? EmploymentStatus.Previous,
			basis: payg.basis,
			employerType: payg.employerType,
			employerId: payg.employerDetailsSelect?.id,
			employerTargetType: payg.employerDetailsSelect?.type,
			isOnProbation: !!payg.isOnProbation,
			dateStarted: payg.dateStarted,
			dateEnded: payg.dateEnded,
			occupation: payg.occupation,
			jobLevel: payg.jobLevel,
			industryDivisionId: payg.industryDivision,
			industrySubdivisionId: payg.industrySubdivision,
			industryCodeId: payg.anzsicIndustry,
			income: (payg.income || [])
				.map((income) => {
					return {
						id: income.id,
						type: income.type,
						grossAmount: income.grossAmount ? CurrencyHelper.unformatAmount(income.grossAmount) : undefined,
						netAmount: income.netAmount ? CurrencyHelper.unformatAmount(income.netAmount) : undefined,
						frequency: income.frequency !== FrequencyShort.YearToDate ? income.frequency : undefined,
						includedInBase: income.includedInBase,
						startDate: income.frequency === FrequencyShort.YearToDate ? payg.incomePeriodModal?.startDate : undefined,
						endDate: income.frequency === FrequencyShort.YearToDate ? payg.incomePeriodModal?.endDate : undefined
					};
				})
				.filter((income) => !!income.grossAmount || !!income.netAmount),
			companyCar: !!payg.companyCar,
			probationDateStarts: payg.isOnProbation ? payg.probationDateStarts : undefined,
			probationDateEnds: payg.isOnProbation ? payg.probationDateEnds : undefined,
			anzscoOccupationCode: payg.anzscoOccupationCode,
			occupationCode: payg.occupationCode,
			averageHoursPerWeek: payg.averageHoursPerWeek,
			mainBusinessActivity: payg.mainBusinessActivity,
			employedByFamily: getNullableBoolean(payg.employedByFamily),
			positionTitle: payg.positionTitle
		};
	}

	static fromPayload(employment: EmploymentDTO, formEnumsQuery: FormEnumsQuery): EmploymentModel {
		const employmentEndDate = employment.income?.find((income) => income.endDate);
		let endDate = undefined;
		if (employmentEndDate != null) {
			// for current employments endDate is null
			const endDateStr = employment.income?.find((income) => income.endDate)?.endDate;
			// added 1 day to end date due to the change of business logic. refer TAMA5-10050
			endDate = addDaysToDate(endDateStr as string, 1);
		}

		const paygModel: PaygModel = {
			status: employment.status,
			basis: employment.basis,
			ownership: employment.applicantId,
			employerType: employment.employerType,
			dateStarted: employment.dateStarted,
			dateEnded: employment.dateEnded,
			isOnProbation: employment.isOnProbation,
			jobLevel: employment.jobLevel,
			occupation: employment.occupation,
			industryDivision: employment.industryDivisionId,
			industrySubdivision: employment.industrySubdivisionId,
			anzsicIndustry: employment.industryCodeId,
			income: (employment.income || []).map((income) => ({
				id: income.id,
				grossAmount: String(income.grossAmount!),
				netAmount: String(income.netAmount!),
				frequency: income.frequency ?? (income.startDate ? FrequencyShort.YearToDate : undefined),
				type: income.type,
				includedInBase: income.includedInBase
			})),
			incomePeriodModal: {
				startDate: employment.income?.find((income) => income.startDate)?.startDate,
				endDate: employment.income?.find((income) => income.endDate)?.endDate
			},
			employerDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: employment.employerTargetType as TargetType,
				id: employment.employerId as number
			}),
			typeOfIncome: {
				type: IncomeType.payg,
				currentEmployment: this.isCurrentEmployment(employment.dateEnded, employment.status)
			},
			companyCar: getNullableYesNo(employment.companyCar),
			probationDateStarts: employment.probationDateStarts,
			probationDateEnds: employment.probationDateEnds,
			anzscoOccupationCode: employment.anzscoOccupationCode,
			occupationCode: employment.occupationCode,
			averageHoursPerWeek: employment.averageHoursPerWeek ?? undefined,
			mainBusinessActivity: employment.mainBusinessActivity,
			employedByFamily: getNullableYesNo(employment.employedByFamily),
			positionTitle: employment.positionTitle
		};

		const calculated = EmploymentModelTransformer.getNetAndGrossAmountForSummary(
			paygModel.income,
			employment.income?.find((income) => income.startDate)?.startDate,
			endDate
		);
		const employmentModel: EmploymentModel = {
			employmentDetails: {
				typeOfIncome: {
					type: IncomeType.payg,
					currentEmployment: this.isCurrentEmployment(employment.dateEnded, employment.status)
				},
				payg: paygModel
			},
			currentEmployment: this.isCurrentEmployment(employment.dateEnded, employment.status),
			grossAmount: calculated.grossAmount,
			netAmount: calculated.netAmount,
			summaryAmount: calculated.grossAmount,
			frequency: FrequencyShort.Monthly,
			id: employment.id,
			applicantId: employment.applicantId
		};
		return employmentModel;
	}

	static getNetAndGrossAmountForSummary(
		incomes: IncomeModel[] = [],
		startDate?: string,
		endDate?: string
	): Partial<EmploymentModel> {
		let grossAmount = 0;
		let netAmount = 0;
		incomes.forEach((item) => {
			const gross = item.grossAmount ? CurrencyHelper.unformatAmount(item.grossAmount) : 0;
			const net = item.netAmount ? CurrencyHelper.unformatAmount(item.netAmount) : 0;
			if (!item.includedInBase) {
				// We need to calculate larger precision and then round at the end
				grossAmount += calculateMonthly(gross, item.frequency, 3, startDate, endDate);
				netAmount += calculateMonthly(net, item.frequency, 3, startDate, endDate);
			}
		});
		return {
			grossAmount: roundNumber(grossAmount),
			netAmount: roundNumber(netAmount)
		};
	}

	static getEmployerNameForSummary(employment: EmploymentModel) {
		const payg = employment.employmentDetails.payg;
		const paygEmployerId = payg?.employerDetailsSelect?.id;
		const selfEmployed = employment.employmentDetails.selfEmployment;
		const foreignEmployment = employment.employmentDetails.foreignEmployment;
		const foreignEmploymentId = foreignEmployment?.employerDetailsSelect?.id;

		if (payg && paygEmployerId !== undefined) {
			return payg.employerDetailsSelect?.companyName;
		} else if (selfEmployed?.ownership) {
			return 'Self employed';
		} else if (foreignEmployment && foreignEmploymentId !== undefined) {
			return foreignEmployment.employerDetailsSelect?.companyName;
		} else {
			return `Not employed`;
		}
	}

	static isCurrentEmployment(employmentDateEnded?: string, employmentStatus?: EmploymentStatus): boolean {
		if (employmentDateEnded) {
			return isFutureDate(employmentDateEnded);
		}
		return employmentStatus !== EmploymentStatus.Previous;
	}
}

export interface EmploymentModel {
	employmentDetails: EmploymentDetailsModel;
	grossAmount?: number;
	netAmount?: number;
	frequency?: number;
	id?: number;
	summaryAmount?: number; // Used in Company & Trust Financial Position
	currentEmployment: boolean;
	applicantId?: number;
}

export interface EmploymentDetailsModel {
	typeOfIncome: TypeOfIncome;
	payg?: PaygModel;
	selfEmployment?: SelfEmploymentModel;
	notEmployed?: NotEmployedModel;
	foreignEmployment?: ForeignEmploymentModel;
	extract?: string;
	employerNameForSummary?: string;
}

interface PaygModel {
	status?: number;
	basis?: number;
	dateStarted?: string;
	jobLevel?: JobLevel;
	dateEnded?: string;
	isOnProbation?: boolean;
	occupation?: string;
	ownership?: number;
	employerType?: number;
	industryDivision?: number;
	industrySubdivision?: number;
	anzsicIndustry?: number;
	employerDetailsSelect?: ApplicantEnumObject;
	income?: IncomeModel[];
	incomePeriodModal?: IncomePeriodModel;
	typeOfIncome?: TypeOfIncome;
	duration?: string;
	companyCar?: YesNo;
	probationDateStarts?: string;
	probationDateEnds?: string;
	anzscoOccupationCode?: number;
	occupationCode?: number;
	averageHoursPerWeek?: number;
	mainBusinessActivity?: string;
	employedByFamily?: YesNo;
	positionTitle?: string;
}

export interface TypeOfIncome {
	type: number;
	currentEmployment?: boolean;
	incomeDocumentationTypeId?: number;
}
