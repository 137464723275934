import { Injectable } from '@angular/core';
import { AuthService, IntercomService } from '@simpology/authentication';
import { IntercomInformation } from '@simpology/authentication/lib/simp-authentication/model/intercom.model';
import { BehaviorSubject, Observable, forkJoin, take } from 'rxjs';
import { USER_PERMISSIONS } from '../access/permission-utils';
import { CONSTANTS } from '../constants/constants';
import { CurrentUser } from '../model/current-user.model';
import { ChannelSettingQuery } from '../store/channel-setting/channel-setting.query';
import { ChannelSettingService } from '../store/channel-setting/channel-setting.service';
import { ChannelApiService } from './channel-api.service';
import { ChannelUserApiService } from './channel-user-api.service';

@Injectable({ providedIn: 'root' })
export class PostLoginService {
	public loginCompleted$: Observable<CurrentUser | null>;
	private loginCompletedSource = new BehaviorSubject<CurrentUser | null>(null);

	constructor(
		private authService: AuthService,
		private channelApiService: ChannelApiService,
		private channelUserApiService: ChannelUserApiService,
		private channelSettingService: ChannelSettingService,
		private channelSettingQuery: ChannelSettingQuery,
		private intercomService: IntercomService
	) {
		this.loginCompleted$ = this.loginCompletedSource.asObservable();
	}

	setupLogin() {
		this.authService.authNavStatus$.subscribe((value: boolean) => {
			if (value) {
				// initializing USER_PERMISSIONS within permission-utils
				Object.assign(USER_PERMISSIONS, this.authService.permissions);
				forkJoin({
					channelSettings: this.channelSettingService.fetchChannelSettings(),
					currentUser: this.channelUserApiService.getCurrentUser(),
					personChannel: this.channelApiService.getCurrentPersonChannel()
				}).subscribe((response) => {
					this.loginCompletedSource.next({
						...response.currentUser,
						hasMultiChannels: response.personChannel.numberOfChannels > 1
					});
					sessionStorage.setItem(CONSTANTS.CURRENT_USER, JSON.stringify(response.currentUser));
					if (this.channelSettingQuery.isIntercomEnabled()) {
						const intercomInformation: IntercomInformation = {
							userId: response.currentUser.userId,
							fullName: response.currentUser.displayName,
							name: response.currentUser.displayName,
							email: response.currentUser.email,
							mobile: response.currentUser.mobile,
							channelName: response.currentUser.channelName,
							channelId: response.currentUser.channelId,
							broker:
								response.currentUser.applicationRoleName === 'Broker'
									? response.currentUser.applicationRoleName
									: undefined
						};
						this.intercomService.bootIntercom(intercomInformation);
					}
				});
			}
		});
	}

	setupLoginAnonymous(): void {
		this.channelUserApiService
			.getCurrentUser()
			.pipe(take(1))
			.subscribe((currentUser) => {
				this.loginCompletedSource.next(currentUser);
				sessionStorage.setItem(CONSTANTS.CURRENT_USER, JSON.stringify(currentUser));
			});
	}

	public getCurrentUser(): CurrentUser {
		const value = sessionStorage.getItem(CONSTANTS.CURRENT_USER);
		if (value && value.trim().length > 0) {
			return JSON.parse(value) as CurrentUser;
		}
		return {
			applicationRoleId: 0,
			applicationRoleName: '',
			channelId: 0,
			email: '',
			mobile: '',
			userId: 0,
			displayName: this.authService.name,
			photoUrl: '',
			peopleId: 0,
			entityId: 0,
			channelName: '',
			channelLogoUrl: '',
			firstName: '',
			lastName: '',
			hasMultiChannels: false
		};
	}
}
