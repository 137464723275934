import {
	EmploymentStatus,
	IncomeType,
	NotEmployedIncomeType,
	NotEmployedType,
	ProofCodeNotEmployed,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { calculateMonthly, getNullableBoolean, getNullableYesNo, roundNumber } from '@app/modules/shared/helper/util';
import { NotEmployedDTO } from '@app/modules/typings/api';
import { CurrencyHelper, FrequencyShort } from '@simpology/client-components/utils';

import { EmploymentDetailsModel, EmploymentModel, EmploymentModelTransformer } from './employment.model';

export class NotEmployedModelTransformer {
	static toPayload(employmentModel: EmploymentModel, applicationId: number): NotEmployedDTO {
		return {
			...NotEmployedBaseModelTransfer.CreatePayload(
				employmentModel.employmentDetails,
				employmentModel.id,
				applicationId
			)
		};
	}

	static fromPayload(notEmployedDTO: NotEmployedDTO): EmploymentModel {
		const notEmployedModel = {} as NotEmployedModel;
		const clonedNotEmployedModel = Object.assign(notEmployedModel, notEmployedDTO) as NotEmployedModel;
		clonedNotEmployedModel.notEmployedId = notEmployedDTO.notEmployedId;
		clonedNotEmployedModel.ownership = notEmployedDTO.applicantId;
		clonedNotEmployedModel.incomeSMSF = getNullableYesNo(notEmployedDTO.incomeSMSF);

		const calculated = NotEmployedModelTransformer.notEmployedModelToUI(clonedNotEmployedModel.income);
		clonedNotEmployedModel.typeOfIncome = {
			type: IncomeType.NotEmployed
		};
		const employmentModel: EmploymentModel = {
			employmentDetails: {
				typeOfIncome: {
					type: IncomeType.NotEmployed
				},
				notEmployed: clonedNotEmployedModel
			},
			currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
				notEmployedDTO.dateEnded,
				notEmployedDTO.status
			),
			grossAmount: calculated.grossAmount,
			netAmount: calculated.netAmount,
			summaryAmount: calculated.grossAmount,
			frequency: FrequencyShort.Monthly,
			id: notEmployedDTO.employmentId,
			applicantId: notEmployedDTO.applicantId
		};

		return employmentModel;
	}

	static notEmployedModelToUI(incomes: IncomeModel[] = []): Partial<NotEmployedIncomeModel> {
		let grossAmount = 0;
		let netAmount = 0;
		incomes.forEach((item) => {
			const gross = item.grossAmount ? CurrencyHelper.unformatAmount(item.grossAmount) : 0;
			const net = item.netAmount ? CurrencyHelper.unformatAmount(item.netAmount) : 0;
			grossAmount += calculateMonthly(gross, item.frequency);
			netAmount += calculateMonthly(net, item.frequency);
		});

		return {
			grossAmount: roundNumber(grossAmount),
			netAmount: roundNumber(netAmount)
		};
	}
}

export class NotEmployedBaseModelTransfer {
	static CreatePayload(
		employmentModel: EmploymentDetailsModel,
		employmentId: number | undefined,
		applicationId: number | undefined
	) {
		const notEmployed = employmentModel.notEmployed!;
		return {
			employmentId: employmentId ?? 0,
			notEmployedId: notEmployed.notEmployedId ?? 0,
			applicationId: applicationId,
			applicantId: notEmployed.ownership,
			type: notEmployed.type,
			status: notEmployed.status ?? EmploymentStatus.Previous,
			dateStarted: notEmployed.dateStarted,
			dateEnded: notEmployed.dateEnded,
			incomeProofSighted: notEmployed.incomeProofSighted,
			incomeProofCode: notEmployed.incomeProofCode,
			incomeSMSF: getNullableBoolean(notEmployed.incomeSMSF),
			income: (notEmployed.income || [])
				.map((income) => {
					return {
						type: income.type,
						grossAmount: CurrencyHelper.unformatAmount(income.grossAmount!),
						netAmount: CurrencyHelper.unformatAmount(income.netAmount!),
						frequency: income.frequency,
						description: income?.description
					};
				})
				.filter((income) => !!income.grossAmount || !!income.netAmount)
		} as NotEmployedDTO;
	}
}

interface NotEmployedIncomeModel {
	notEmployedDetails: NotEmployedDetailsModel;
	grossAmount?: number;
	netAmount?: number;
	frequency?: number;
	id?: number;
	summaryAmount?: number; // Used in Company & Trust Financial Position
	currentEmployment: boolean;
}

interface NotEmployedDetailsModel {
	typeOfIncome: TypeOfIncome;
	payg: {};
	selfEmployment: {};
	notEmployed: NotEmployedModel;
	extract?: string;
}

export interface NotEmployedModel {
	notEmployedId?: number;
	employmentId?: number;
	ownership?: number;
	applicationId?: number;
	type?: NotEmployedType;
	status?: EmploymentStatus;
	incomeProofSighted?: boolean | undefined;
	incomeProofCode?: ProofCodeNotEmployed;
	incomeSMSF?: YesNo;
	dateStarted?: string | undefined;
	dateEnded?: string | undefined;
	income?: IncomeModel[];
	typeOfIncome?: TypeOfIncome;
	duration?: string;
	totalMonthlyIncome?: string;
}

interface IncomeModel {
	type?: NotEmployedIncomeType;
	grossAmount?: string;
	netAmount?: string;
	frequency?: FrequencyShort | undefined;
	description?: string | undefined;
}

interface TypeOfIncome {
	type: number;
	currentEmployment?: boolean;
}
