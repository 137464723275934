import { JsonSchemaProperty } from '../../typings/formly-app';
import {
	formlyParsePatternCustomValidator,
	parseExpressionProperties,
	parseExpressionString
} from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyInput = (property: FormlyApiProperty): JsonSchemaProperty => {
	const schema: JsonSchemaProperty = {
		type: 'input',
		title: property.title,

		widget: {
			formlyConfig: {
				className: `${property.templateOptions?.class ?? (property.class || '')}`,
				modelOptions: {
					...property.modelOptions
				},
				templateOptions: {
					...property.templateOptions
				},
				validation: {
					messages: {
						required: 'This field is mandatory',
						...(property.errorMessages || {})
					}
				},
				hideExpression: parseExpressionString(property.hide),
				resetOnHide: !!property.templateOptions?.resetOnHide,
				defaultValue: property.templateOptions?.defaultValue,
				expressionProperties: parseExpressionProperties(property)
			}
		}
	};

	return formlyParsePatternCustomValidator(schema, property);
};
