import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { EmploymentDTO, OtherIncomeDTO, RefiRentalIncomeDTO, RefiSelfEmploymentDTO } from '@app/modules/typings/api';
import { Observable, forkJoin, map, of } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';

import {
	EmploymentIncomeTransformer,
	EmploymentModel,
	EmploymentModelDetails
} from '../../models/refi-employment.model';
import { RefiOtherIncomeTransformer } from '../../models/refi-other-income.model';
import { RefiRentalIncomeModel, RefiRentalIncomeTransformer } from '../../models/refi-rental-income.model';
import {
	SelfEmploymentIncomeTransformer,
	SelfEmploymentModel,
	SelfEmploymentModelDetails
} from '../../models/refi-self-employment.model';

@Injectable({
	providedIn: 'root'
})
export class RefiIncomeService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formDataService: FormDataService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.formDataService.setLoading(RefiStore.Income, true);
		this.fetchIncomes();
	}

	saveEmploymentIncome(payload: EmploymentModelDetails): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`EmploymentIncome`,
				EmploymentIncomeTransformer.toPayload(payload, this.applicationDataQuery.applicationId())
			)
		);
	}

	saveSelfEmploymentIncome(payload: SelfEmploymentModelDetails): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`SelfEmploymentIncome`,
				SelfEmploymentIncomeTransformer.toPayload(payload, this.applicationDataQuery.applicationId())
			)
		);
	}

	saveRentalIncome(payload: RefiRentalIncomeDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`RentalIncome`, payload);
	}

	saveOtherIncome(payload: OtherIncomeDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`OtherIncome`, payload);
	}

	deleteEmploymentIncome(payload: number): Observable<boolean> {
		return this.delete(
			`EmploymentIncome/${this.applicationDataQuery.applicationId()}/${payload}`
		) as Observable<boolean>;
	}

	deleteSelfEmploymentIncome(payload: number): Observable<boolean> {
		return this.delete(
			`SelfEmploymentIncome/${this.applicationDataQuery.applicationId()}/${payload}`
		) as Observable<boolean>;
	}

	deleteRentalIncome(payload: number): Observable<boolean> {
		return this.delete(`RentalIncome/${this.applicationDataQuery.applicationId()}/${payload}`) as Observable<boolean>;
	}

	deleteOtherIncome(payload: number): Observable<boolean> {
		return this.delete(`OtherIncome/${this.applicationDataQuery.applicationId()}/${payload}`) as Observable<boolean>;
	}

	private fetchIncomes(): void {
		forkJoin([
			this.fetchEmploymentIncome(),
			this.fetchSelfEmploymentIncome(),
			this.fetchRentalIncome(),
			this.fetchOtherIncome()
		]).subscribe(([employment, selfEmploymentIncome, rentalIncome, otherIncome]) => {
			this.simpFormlyHandlerService.upsertToState(
				'details',
				of([
					{
						employment: employment,
						selfEmployment: selfEmploymentIncome,
						rentalIncome: rentalIncome,
						otherIncome: otherIncome,
						summaryAmount: ''
					}
				])
			);
		});
	}

	private fetchEmploymentIncome(): Observable<EmploymentModel[]> {
		return this.getCustom(`EmploymentIncome/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((incomes: EmploymentDTO[]) => {
				return incomes.map((income) =>
					EmploymentIncomeTransformer.fromPayload(income, this.applicationDataQuery.getRefiApplicants())
				);
			})
		);
	}

	private fetchSelfEmploymentIncome(): Observable<SelfEmploymentModel[]> {
		return this.getCustom(`SelfEmploymentIncome/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((incomes: RefiSelfEmploymentDTO[]) => {
				return incomes.map((income) =>
					SelfEmploymentIncomeTransformer.fromPayload(income, this.applicationDataQuery.getRefiApplicants())
				);
			})
		);
	}

	private fetchRentalIncome(): Observable<RefiRentalIncomeModel[]> {
		return this.getCustom(`RentalIncome/${this.applicationDataQuery.applicationId()}`).pipe(
			map((rentals: RefiRentalIncomeDTO[]) => {
				return rentals.map((rental) =>
					RefiRentalIncomeTransformer.fromPayload(rental, this.applicationDataQuery.getRefiApplicants())
				);
			})
		);
	}

	private fetchOtherIncome(): Observable<RefiRentalIncomeModel[]> {
		return this.getCustom(`OtherIncome/${this.applicationDataQuery.applicationId()}`).pipe(
			map((otherIncomes: OtherIncomeDTO[]) => {
				return otherIncomes.map((otherIncome) =>
					RefiOtherIncomeTransformer.fromPayload(otherIncome, this.applicationDataQuery.getRefiApplicants())
				);
			})
		);
	}
}
