import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { CountryCode } from '@app/modules/shared/enums/app.enums';
import { AddressService } from '@app/modules/shared/service/address.service';
import { FormAddressesQuery } from '@app/modules/shared/store/form-addresses/form-addresses.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { EnumObject, PlaceType, SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

import { Address } from '@app/modules/typings/api';

import { FormlyFieldTypes } from '@app/modules/simp-formly/enums/formly-field-types';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { SelectOptionsPipe } from '../../pipes/formly-select-options.pipe';

@Component({
	selector: 'formly-address',
	templateUrl: './formly-address.component.html',
	styleUrls: ['./formly-address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAddressComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	addressFormControl = new UntypedFormControl();
	selectedAddressId = new UntypedFormControl();
	countries: Array<{ id: number; label: string }> = [];
	uniqueId = '';
	placeType = new BehaviorSubject(PlaceType.Standard);

	existingAddresses$ = this.formAddressesQuery.selectAddresses$.pipe(
		tap((addresses) => {
			const address = this.formControl.value as Address;
			const fullAddress = address?.id ? addresses.find((a) => a.id === address.id) : null;
			if (fullAddress || this.to.readonly) {
				this.selectedAddressId.setValue(fullAddress);
			} else if (this.to.showExisting) {
				this.formControl.setValue(null);
			}
		}),
		map((addresses) => {
			if (addresses?.length) {
				addresses.push({
					id: CONSTANTS.NEW_ID,
					label: 'Add new address'
				});
			}
			const excludeAddresses = this.to.excludeAddresses as number[];
			if (excludeAddresses) {
				return addresses.filter((a) => (a.id != null ? !excludeAddresses.includes(a.id) : true));
			}
			return addresses;
		})
	);
	private destroy$: Subject<void> = new Subject();

	constructor(
		private formValidationsService: FormValidationsService,
		private formAddressesQuery: FormAddressesQuery,
		private formBuilder: UntypedFormBuilder,
		private addressService: AddressService,
		private selectOptionsPipe: SelectOptionsPipe,
		private formEnumsQuery: FormEnumsQuery
	) {
		super();
	}

	get items(): Observable<EnumObject[]> {
		return this.selectOptionsPipe.transform(this.to.options as string | EnumObject[]);
	}

	ngOnInit(): void {
		if (this.to.placeTypes && Object.values(PlaceType).includes(this.to.placeTypes)) {
			setTimeout(() => {
				// Wait 200ms as the reusable component implements a 100ms delay in listening to placeType input changes
				this.placeType.next(this.to.placeTypes);
			}, 200);
		}
		this.uniqueId = generateUniqueKey(this.field);
		if (this.field.templateOptions) {
			this.field.templateOptions.customFormControls = this.formBuilder.group({
				addressFormControl: this.addressFormControl,
				selectedAddressControl: this.selectedAddressId
			});
		}

		if (this.field.templateOptions?.required) {
			this.addressFormControl.setValidators(Validators.required);
			this.selectedAddressId.setValidators(Validators.required);
			this.formControl.setValidators(Validators.required);
		}

		this.formValidationsService.triggerFieldValidations$
			.pipe(
				takeUntil(this.destroy$),
				filter((field: FormlyFieldConfig) => field.type === FormlyFieldTypes.Address && field === this.field),
				tap(() => {
					this.touchAddressFormControl();
				})
			)
			.subscribe();

		this.formValidationsService.triggerValidations$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.touchAddressFormControl();
		});

		if (this.to.disabled) {
			this.addressFormControl.disable();
			this.selectedAddressId.disable();
		}
		setTimeout(() => {
			this.setAddress();
		}, 200);

		if (this.to.options && !this.to.handler) {
			this.items.pipe(takeUntil(this.destroy$)).subscribe((countryList) => {
				this.countries = countryList;
			});
		}

		if (!this.to.options) {
			const allowedCountries = this.to.handler as string;
			const allCountries = this.formEnumsQuery.getOptions('CountryCode');
			if (allowedCountries) {
				this.countries = allCountries?.filter((x) => {
					return allowedCountries.toUpperCase().includes(CountryCode[x.id]);
				});
			} else {
				this.countries = this.formEnumsQuery.getOptions('CountryCodeLimited');
			}
		} else {
			this.countries = this.formEnumsQuery.getOptions(this.to.options as unknown as string);
		}
	}

	addressChanged(address?: Address): void {
		if (address) {
			if (
				this.to.saveAddressOnChange &&
				SimpAddressHelper.isValidAddress(address as SimpAddress, this.placeType.value)
			) {
				this.addressService.saveAddress(address).subscribe((id) => {
					address.id = id;
					this.formControl.setValue(address);
					this.formControl.markAsDirty();
				});
			} else {
				this.formControl.setValue(address);
				this.formControl.markAsDirty();
			}
			if (!SimpAddressHelper.isValidAddress(address as SimpAddress) && this.placeType.getValue() !== PlaceType.Region) {
				this.addressFormControl.setErrors({ error: true });
			}
		} else {
			this.addressFormControl.reset();
			this.formControl.markAsDirty();
			this.formControl.setValue(null);
		}
		if (this.to.change) {
			this.to.change(this.field);
		}
	}

	addressCleared(): void {
		this.formControl.setValue(undefined);
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	private setAddress() {
		if (!this.to.showExisting) {
			const address = this.formControl.value as Address;
			if (address) {
				let addressString = SimpAddressHelper.isValidAddress(address as SimpAddress)
					? SimpAddressHelper.buildAddressLine(address as SimpAddress)
					: this.to.readonly
					? address.unformattedAddress
					: '';
				if (this.placeType.value === PlaceType.Region) {
					addressString = SimpAddressHelper.buildAddressLine(address as SimpAddress);
				}
				if (addressString && addressString !== this.addressFormControl.value) {
					this.addressFormControl.setValue(addressString);
				}
			}
		}
	}

	private touchAddressFormControl() {
		this.addressFormControl.markAsTouched();
		this.selectedAddressId.markAllAsTouched();
		this.formControl.markAllAsTouched();
		this.formControl.markAsDirty();
	}
}
