import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@simpology/authentication';
import { CONSTANTS } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {}

	public canActivate(r: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authService.isAuthenticated()) {
			if (sessionStorage.getItem('returnUrl') === CONSTANTS.REFI_PATH) {
				if (state.url.includes(CONSTANTS.REFI_PATH)) {
					return true;
				} else {
					void this.router.navigateByUrl(CONSTANTS.REFI_PATH);
				}
			}

			if (sessionStorage.getItem('returnUrl') === CONSTANTS.PURCHASE_PATH) {
				if (state.url.includes(CONSTANTS.PURCHASE_PATH)) {
					return true;
				} else {
					void this.router.navigateByUrl(CONSTANTS.PURCHASE_PATH);
				}
			}

			return true;
		}

		// not logged in so redirect to login page with the return url
		// if unauthorized, force login
		void this.router.navigate(['/access/login'], {
			queryParams: { returnUrl: state.url }
		});
		return false;
	}
}
