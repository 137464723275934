import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { isEqual } from 'lodash-es';

import isNil from 'lodash-es/isNil';
import { debounceTime, distinctUntilChanged, filter, startWith, Subject, take, takeUntil } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { SelectOptionsPipe } from '../../pipes/formly-select-options.pipe';

@Component({
	selector: 'formly-multi-checkbox-select',
	templateUrl: './formly-multi-checkbox-select.component.html',
	styleUrls: ['./formly-multi-checkbox-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiCheckboxSelectComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	otherReasonValidationError = { required: 'Please specify the reason' };
	uniqueId = '';
	multiSelectOptions: EnumObject[] = [];
	multiSelectFormGroup: UntypedFormGroup;
	checkBoxErrorMessages = { required: '' };
	multiCheckBoxErrorMessage = { required: 'Please select at least one option' };
	hasOther = false;
	Validators = Validators;
	otherLabel = 'Other';

	private destroy$: Subject<void> = new Subject();
	private valueChangeSubject: Subject<any> = new Subject();

	constructor(
		private formValidationsService: FormValidationsService,
		private formBuilder: UntypedFormBuilder,
		private selectPipe: SelectOptionsPipe
	) {
		super();
		this.multiSelectFormGroup = this.formBuilder.group({
			multiSelectCheckboxes: this.formBuilder.array([]),
			otherReasonInput: new UntypedFormControl()
		});
	}

	get multiSelectCheckboxes() {
		return this.multiSelectFormGroup.controls['multiSelectCheckboxes'] as UntypedFormArray;
	}
	get otherReasonInput() {
		return this.multiSelectFormGroup.controls['otherReasonInput'] as UntypedFormControl;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}

	ngOnInit(): void {
		if (this.to.subText) {
			this.otherLabel = this.to.subText as string;
		}
		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;

		this.setupChangeDetection();
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}

		if (this.to.options) {
			const fieldOptions = this.to.options as string | EnumObject[];

			this.selectPipe
				.transform(fieldOptions)
				.pipe(take(1))
				.subscribe((options) => {
					if (options.some((option) => option.label === 'Other')) {
						this.hasOther = true;
					}

					this.multiSelectOptions = options.filter((x) => x.label !== 'Other');
					this.multiSelectOptions.forEach(() => {
						this.multiSelectCheckboxes.push(this.formBuilder.group({ checkbox: [] }));
					});
				});
		}

		this.formValidationsService.triggerValidations$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.multiSelectCheckboxes.markAllAsTouched();
			this.multiSelectCheckboxes.markAsDirty();
			this.otherReasonInput.markAsDirty();
			this.otherReasonInput.markAllAsTouched();
			this.formControl.markAllAsTouched();
			this.formControl.markAsDirty();
		});

		this.formControl.valueChanges
			.pipe(startWith(this.formControl.value), take(1))
			.subscribe((value: MultiCheckboxModel) => {
				if (value) {
					const selectedIds = value.options;
					this.multiSelectCheckboxes.controls?.forEach((control: AbstractControl, index: number) => {
						const formControl = control?.get('checkbox') as UntypedFormControl;
						const isSelected = selectedIds.includes(this.multiSelectOptions[index]?.id);
						formControl?.setValue(isSelected, { emitEvent: false });
					});
					this.otherReasonInput.setValue(value.otherReasonInput, { emitEvent: false });
				}
				this.updateSelectOptions();
			});

		this.otherReasonInput.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateSelectOptions(true);
		});

		this.uniqueId = generateUniqueKey(this.field);
	}

	handleEdit(): void {
		if (this.to.click) {
			this.to.click(this.field);
		}
	}

	updateSelectOptions(updateControl = false): void {
		const multiCheckboxValues = this.multiSelectCheckboxes.value as { checkbox: boolean }[];
		const selectedValues = multiCheckboxValues.filter((value) => value.checkbox).map((value) => value.checkbox);
		if (!this.multiSelectOptions.length) {
			this.to.required = false;
		}
		if (selectedValues.length > 0) {
			this.removeCheckBoxValidators();
			this.otherReasonInput.clearValidators();
		} else {
			if (this.to.required && this.multiSelectOptions.length) {
				this.otherReasonInput.setValidators(Validators.required);
			} else {
				this.otherReasonInput.clearValidators();
			}
			if (this.to.required && !this.otherReasonInput.value && this.multiSelectOptions.length) {
				this.addCheckBoxValidators(updateControl);
			} else {
				this.removeCheckBoxValidators();
			}
		}

		this.otherReasonInput.updateValueAndValidity({ emitEvent: false });

		const formValue = Object.assign(
			{},
			{
				options: Object.values(
					multiCheckboxValues
						.map((v, index: number) => {
							return {
								id: this.multiSelectOptions[index].id,
								value: multiCheckboxValues[index].checkbox ?? false
							};
						})
						.filter((x) => x.value)
						.map((x) => {
							return x.id;
						})
				)
			},
			{ otherReasonInput: this.otherReasonInput.value as string }
		);

		if (!!formValue.options.length || formValue.otherReasonInput) {
			this.formControl.setValue(formValue);
		} else {
			this.formControl.setValue(null);
		}
		this.formControl.markAsDirty();
	}

	removeCheckBoxValidators() {
		this.multiSelectCheckboxes.controls?.forEach((control: AbstractControl, index: number) => {
			const formControl = control?.get('checkbox') as UntypedFormControl;
			formControl?.clearValidators();
			this.updateControl(formControl);
		});
	}

	addCheckBoxValidators(updateControl: boolean) {
		this.multiSelectCheckboxes.controls?.forEach((control: AbstractControl) => {
			const formControl = control.get('checkbox') as UntypedFormControl;
			formControl?.setValidators([Validators.requiredTrue]);
			if (updateControl) {
				this.updateControl(formControl);
			}
		});
		if (updateControl) {
			this.multiSelectFormGroup.markAllAsTouched();
			this.multiSelectCheckboxes.markAllAsTouched();
			this.multiSelectCheckboxes.markAsDirty();
		}
	}

	private setupChangeDetection() {
		return this.formControl.valueChanges
			.pipe(
				debounceTime(this.to.debounceTime === undefined ? 1000 : this.to.debounceTime),
				distinctUntilChanged((a: unknown, b: unknown) => isEqual(a, b)),
				takeUntil(this.destroy$),
				filter(() => this.formControl.valid && this.formControl.dirty)
			)
			.subscribe((data: unknown) => {
				this.valueChangeSubject.next({ data });
			});
	}

	private updateControl(formControl: UntypedFormControl) {
		formControl?.markAsDirty();
		formControl?.markAllAsTouched();
		formControl?.updateValueAndValidity();
	}
}
