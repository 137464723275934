import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { ENV_CONFIG } from '@app/env-config';
import { BaseApiService } from '@simpology/authentication';
import { ChannelRegistration, PersonChannel } from '../model/channel-registration.model';

@Injectable({ providedIn: 'root' })
export class ChannelApiService extends BaseApiService<ChannelRegistration> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setRoute('Channel');
	}

	public getCurrentPersonChannel(): Observable<PersonChannel> {
		return this.getCustom('CurrentPersonChannel') as Observable<PersonChannel>;
	}
}
