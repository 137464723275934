import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { SubJourneys } from '../application-data/typings/application-data';
import { SharedFlagsState } from './typings/shared-flags';

@Injectable({ providedIn: 'root' })
export class SharedFlagsStore {
	store = createStore(
		{ name: 'sharedFlags' },
		withProps<SharedFlagsState>({
			isPropertyInLoanServiceability: false,
			isPropertyModalOpened: false,
			propertyModalOpenedSection: '',
			complianceCoApplicantFilterTypes: [],
			currentJourney: undefined,
			subJourneys: []
		} as SharedFlagsState)
	);

	updateSharedFlag(
		sharedFlag: keyof SharedFlagsState,
		value: string | boolean | number | SubJourneys[] | number[] | undefined
	) {
		this.store.update(setProp(sharedFlag, value));
	}

	getStoreValue(): SharedFlagsState {
		return this.store.getValue();
	}

	select(key: string): Observable<any> {
		return this.store.pipe(select((state) => state[key as keyof SharedFlagsState]));
	}
}
