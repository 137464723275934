<footer class="app-footer">
	<div class="app-footer__contents">
		<span class="app-footer__help-text simp-margin-left-small simp-text--primary" [innerHTML]="needHelpMessage"></span>
		<button
			type="button"
			class="btn btn-secondary simp-button simp-margin-right-small"
			[style.visibility]="showPopup ? 'hidden' : 'visible'"
			(click)="showPopup = true"
		>
			{{ contactUsButton.title }}
		</button>
	</div>
	<div class="app-footer__popup-buttons" *ngIf="showPopup">
		<div class="app-footer__popup-button simp-box-shadow" *ngIf="contactEmail">
			<a class="btn btn-primary simp-button" href="mailto:{{ contactEmail }}" (click)="closePopup()">
				{{ emailButton.title }}<i class="fa fa-envelope simp-margin-left-base"></i>
			</a>
		</div>

		<div class="app-footer__popup-button simp-box-shadow" *ngIf="contactPhone">
			<a class="btn btn-primary simp-button" href="tel:{{ contactPhone }}" (click)="closePopup()">
				{{ callButton.title }}<i class="fa fa-mobile-alt simp-margin-left-base"></i>
			</a>
		</div>
	</div>
</footer>
