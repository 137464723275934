import { ComplianceNotesBrokerAttestationDTO } from '@app/modules/typings/api';

export class NotesBrokerAttestationTransformer {
	static toPayload(notes: NotesBrokerAttestation): ComplianceNotesBrokerAttestationDTO {
		return {
			applicationId: notes.applicationId,
			notes: notes.notes,
			feesAndChargesExplained: notes.brokerAttestation,
			brokerCertified: notes.brokerCertified
		};
	}
	static fromPayload(notes: ComplianceNotesBrokerAttestationDTO): NotesBrokerAttestation {
		const payload: NotesBrokerAttestation = {
			applicationId: notes.applicationId,
			notes: notes.notes ?? '',
			brokerAttestation: notes.feesAndChargesExplained,
			brokerCertified: notes.brokerCertified
		};
		return payload;
	}
}

export interface NotesBrokerAttestation {
	isLoanPurposeRefinanceOrDebtConsolidation?: boolean;
	applicationId: number;
	notes: string;
	brokerAttestation: boolean;
	brokerCertified?: boolean;
}
