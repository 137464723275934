import { PropertyModalModel } from '@app/modules/property/models/property-modal.model';
import {
	CommercialType,
	GovernmentBenefitsType,
	IndustrialType,
	PrimaryUsage,
	PropertyPartType,
	ResidentialType,
	RuralType,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { calculateMonthly, getNullableYesNo } from '@app/modules/shared/helper/util';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { Address, RentalIncomeDTO } from '@app/modules/typings/api';
import { FrequencyShort, SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

export interface RentalModel {
	details: RentalModelDetails;
	grossRentalAmountEntry?: AmountSelect;
	id?: number;
	isClone?: boolean; // Used for only UI purpose
	grossAmount?: number;
	netAmount?: number;
	frequency?: number;
	// Used in Company & Trust Financial Position
	summaryAmount?: number;
	amountEntry?: AmountSelect;
}

export interface RentalModelDetails {
	rentalDetails: PropertyModalModel;
	detailsModel: RentalDetailsModel; // For UI purposes only
	extract?: string;
}

export interface RentalDetailsModel {
	id?: number;
	primaryUse?: PrimaryUsage;
	address?: Address;
	propertyValue: AmountSelect;
	rentalType?: number;
	propertyTypeResidential: ResidentialType;
	propertyTypeCommercial: CommercialType;
	propertyTypeIndustrial: IndustrialType;
	propertyTypeRural: RuralType;
	existingMortgage?: YesNo;
	useAsSecurity?: YesNo;
	evidenceOfTenancy?: boolean;
	governmentBenefitsType: GovernmentBenefitsType;
	isFuture?: boolean;
	grossIncomeAmount?: AmountSelect;
	netIncomeAmount?: AmountSelect;
	propertyPartType?: PropertyPartType;
	startDate?: string | undefined;
	realEstateAssetId?: number;
}

export class RentalModelTransformer {
	static fromPayload(rentalIncomeDTO: RentalIncomeDTO): RentalModel {
		const grossRentalAmount = calculateMonthly(rentalIncomeDTO.grossRentalAmount, rentalIncomeDTO.grossRentalFrequency);
		const netRentalAmount = calculateMonthly(rentalIncomeDTO.netRentalAmount, rentalIncomeDTO.netRentalFrequency);

		return {
			id: rentalIncomeDTO.id,
			details: {
				detailsModel: {
					id: rentalIncomeDTO.id,
					primaryUse: rentalIncomeDTO.primaryUsage,
					address: rentalIncomeDTO.address!,
					propertyTypeResidential: rentalIncomeDTO.residentialType!,
					propertyTypeCommercial: rentalIncomeDTO.commercialType!,
					propertyTypeRural: rentalIncomeDTO.ruralType!,
					propertyTypeIndustrial: rentalIncomeDTO.industrialType!,
					rentalType: rentalIncomeDTO.rentalTerm, // pending on Col
					propertyValue: {
						amount: rentalIncomeDTO.estimatedValue,
						frequency: rentalIncomeDTO.propertyEvaluationType
					},
					existingMortgage: getNullableYesNo(rentalIncomeDTO.hasMortgage),
					useAsSecurity: getNullableYesNo(rentalIncomeDTO.toBeUsedAsSecurity),
					evidenceOfTenancy: rentalIncomeDTO.evidenceOfTenancy,
					// ownership: rentalIncomeDTO.applicantId,
					governmentBenefitsType: rentalIncomeDTO.governmentBenefitsType,
					isFuture: rentalIncomeDTO.isFuture,
					grossIncomeAmount: {
						amount: rentalIncomeDTO.grossRentalAmount,
						frequency: rentalIncomeDTO.grossRentalFrequency
					} as AmountSelect,
					netIncomeAmount: {
						amount: rentalIncomeDTO.netRentalAmount,
						frequency: rentalIncomeDTO.netRentalFrequency
					} as AmountSelect,
					propertyPartType: rentalIncomeDTO.propertyPartType,
					startDate: rentalIncomeDTO.startDate,
					realEstateAssetId: rentalIncomeDTO.realEstateAssetId
				},
				extract: rentalIncomeDTO.address
					? SimpAddressHelper.buildAddressLine(rentalIncomeDTO.address as SimpAddress)
					: ''
			} as RentalModelDetails,
			amountEntry: {
				amount: grossRentalAmount,
				frequency: FrequencyShort.Monthly
			},
			summaryAmount: grossRentalAmount,
			netAmount: netRentalAmount,
			grossAmount: grossRentalAmount,
			frequency: FrequencyShort.Monthly // Frequency should be always monthly as per the requirement
		};
	}
}
