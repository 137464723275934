import { Injectable } from '@angular/core';
import { DeclaredExpenseModel } from '@app/modules/financial-position/model/expenses.model';
import { ExpenseFloorCommentDTO, ExpenseFloorsDTO, ExpenseTotalsDTO } from '@app/modules/typings/api';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { ExpenseSource } from '../../enums/app.enums';
import { roundNumber } from '../../helper/util';
import { AggregateFormatterService } from '../../service/aggregate-formatter.service';
import { ChannelSettingQuery } from '../channel-setting/channel-setting.query';
import { ExpenseStateModel } from './typings/expenses';

const createInitialState = (): ExpenseStateModel => {
	return {
		isDigitalInStore: [],
		sectionTotal: {
			digitalTotal: 0,
			declaredTotal: 0,
			differenceInDeclaredToDigitalTotal: '',
			differenceInDigitalTotalValuesText: ''
		},
		showDeclared: true,
		declared: [],
		categoriesTotal: [],
		expenseFloors: [],
		expenseFloorComments: []
	};
};

@Injectable({ providedIn: 'root' })
export class ExpensesStore {
	store = createStore({ name: 'expenses' }, withProps<ExpenseStateModel>(createInitialState()));
	constructor(private formatter: AggregateFormatterService, private channelSettingQuery: ChannelSettingQuery) {}

	getValue(): ExpenseStateModel {
		return this.store.getValue();
	}

	reset(): void {
		this.store.reset();
	}

	select<T = any>(selectFunction: (state: ExpenseStateModel) => any = (state) => state): Observable<T> {
		return this.store.pipe(select(selectFunction)) as Observable<T>;
	}

	updateSelectedHousehold(householdId: number): void {
		this.store.update((draft) => {
			draft.selectedHousehold = householdId;
			return cloneDeep(draft);
		});
	}

	updateIsDigital(categoryId?: number, expenseSource?: ExpenseSource): void {
		this.store.update((draft) => {
			if (categoryId) {
				const index = draft.isDigitalInStore?.findIndex((i) => i.expenseCategory === categoryId) ?? -1;
				if (index > -1) {
					if (draft.isDigitalInStore) {
						draft.isDigitalInStore[index].isDigital = expenseSource === ExpenseSource.Digital;
					}
				} else {
					draft.isDigitalInStore ??= [];
					draft.isDigitalInStore?.push({
						expenseCategory: categoryId,
						isDigital: expenseSource === ExpenseSource.Digital
					});
				}
			}

			return cloneDeep(draft);
		});
	}

	updateShowDeclared(showDeclared: boolean): void {
		this.store.update((draft) => {
			draft.showDeclared = showDeclared;
			return cloneDeep(draft);
		});
	}

	updateShouldUpdateCategory(shouldUpdate: boolean): void {
		this.store.update((draft) => {
			draft.shouldUpdateCategoriesTotal = shouldUpdate;
			return cloneDeep(draft);
		});
	}

	updateDeclaredExpenses(declared: DeclaredExpenseModel[]): void {
		this.store.update((draft) => {
			draft.declared = declared;
			return cloneDeep(draft);
		});
	}

	updateExpenseFloorComments(comments: ExpenseFloorCommentDTO[]) {
		this.store.update((draft) => {
			draft.expenseFloorComments = comments;
			return cloneDeep(draft);
		});
	}

	updateExpenseFloors(expenseFloors: ExpenseFloorsDTO[]): void {
		this.store.update((draft) => {
			draft.expenseFloors = expenseFloors;
			return cloneDeep(draft);
		});
	}

	updateTotals(totals: ExpenseTotalsDTO[]): void {
		const declaredThreshold = this.channelSettingQuery.getDigitalDeclaredThresholdValue();
		this.store.update((draft) => {
			const sectionTotal = totals.find((t) => t.expenseCategory === null);

			draft.sectionTotal = {
				digitalTotal: roundNumber(sectionTotal?.digitalTotal),
				declaredTotal: roundNumber(sectionTotal?.declaredTotal),
				differenceInDeclaredToDigitalTotal: this.getDeclaredToDigitalTotalDifference(
					declaredThreshold,
					sectionTotal?.declaredTotal,
					sectionTotal?.digitalTotal
				),
				differenceInDigitalTotalValuesText: this.getDigitalTotalDifferenceText(
					sectionTotal?.originalDigitalTotal,
					sectionTotal?.digitalTotal
				)
			};
			draft.categoriesTotal = totals
				.filter((t) => t.expenseCategory !== null)
				.map((t) => ({
					declaredTotal: roundNumber(t.declaredTotal),
					digitalTotal: roundNumber(t.digitalTotal),
					expenseCategory: roundNumber(t.expenseCategory),
					isTotal: draft.declared.find((e) => e.expenseCategory === t.expenseCategory)?.isTotal,
					declaredCount: t.declaredCount,
					digitalCount: t.digitalCount,
					differenceInDeclaredToDigitalTotal: this.getDeclaredToDigitalTotalDifference(
						declaredThreshold,
						t?.declaredTotal,
						t?.digitalTotal
					),
					householdId: t.householdId,
					differenceInDigitalTotalValuesText: this.getDigitalTotalDifferenceText(t.originalDigitalTotal, t.digitalTotal)
				}));
			return cloneDeep(draft);
		});
	}

	getDeclaredToDigitalTotalDifference(
		declaredThreshold: number,
		declaredTotal?: number,
		digitalTotal?: number
	): string {
		return Math.abs(roundNumber(declaredTotal) - roundNumber(digitalTotal)) > declaredThreshold
			? (roundNumber(declaredTotal) - roundNumber(digitalTotal) < 0 ? '-' : '') +
					this.formatter.formatAmount(Math.abs(roundNumber(declaredTotal) - roundNumber(digitalTotal)))
			: '';
	}

	private getDigitalTotalDifferenceText(originalDigitalTotal?: number, digitalTotal?: number): string {
		if (!originalDigitalTotal) {
			return '';
		}

		const absoluteDifferenceInDigital = Math.abs(roundNumber(originalDigitalTotal) - roundNumber(digitalTotal));
		const result =
			roundNumber(digitalTotal) !== roundNumber(originalDigitalTotal)
				? `${
						roundNumber(originalDigitalTotal) > roundNumber(digitalTotal) ? 'Reduced' : 'Increased'
				  } by ${this.formatter.formatAmount(absoluteDifferenceInDigital)} (was ${this.formatter.formatAmount(
						roundNumber(originalDigitalTotal)
				  )})`
				: '';
		return result;
	}
}
