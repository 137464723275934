import { RelatedCompanyModel } from '@app/modules/applicants/models/related-company.model';
import {
	AddbackType,
	EmploymentStatus,
	IncomeDocumentationType,
	IncomeType,
	NonRecurringIncomeType,
	TargetType
} from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { calculateMonthly, getLatestFinancialYear } from '@app/modules/shared/helper/util';
import {
	RelatedCompanyFinancialModal,
	RelatedCompanyFinancialsTransformer
} from '@app/modules/shared/model/related-company-financial.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { yearlyAmountToMonthly } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { Amount, AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { AddBackDTO, NonRecurringIncomes, SelfEmploymentDTO } from '@app/modules/typings/api';
import { CurrencyHelper, EnumObject, FrequencyShort } from '@simpology/client-components/utils';
import { ApplicantSelfEmploymentModelTransformer } from './applicant-self-employment.model';
import { EmploymentModel, EmploymentModelTransformer } from './employment.model';

export class SelfEmploymentModelTransformer {
	static toPayload(
		employmentModel: EmploymentModel,
		applicationId: number,
		formEnumsQuery: FormEnumsQuery
	): SelfEmploymentDTO {
		const selfEmployment = employmentModel.employmentDetails.selfEmployment!;
		return {
			id: employmentModel.id,
			applicationId: applicationId,
			applicantId: selfEmployment.ownership,
			status: selfEmployment.status ?? EmploymentStatus.Previous,
			basis: selfEmployment.basis,
			dateStarted: selfEmployment.dateStarted,
			profitsBeforeTax: [
				selfEmployment.latestIncome,
				selfEmployment.secondLatestIncome,
				selfEmployment.thirdLatestIncome
			]
				.filter((income) => income?.amount !== undefined && income?.amount !== null)
				.map((income) => ({
					amount: CurrencyHelper.unformatAmount(income?.amount as string),
					year: { id: income?.frequency?.id, name: income?.frequency?.label }
				})),
			profitsAfterTax: [
				selfEmployment.firstNetProfitAfterTax,
				selfEmployment.secondNetProfitAfterTax,
				selfEmployment.thirdNetProfitAfterTax
			]
				.filter((prof) => prof?.amount !== undefined && prof?.amount !== null)
				.map((prof) => ({
					amount: CurrencyHelper.unformatAmount(prof?.amount as string),
					year: { id: prof?.frequency?.id, name: prof?.frequency?.label }
				})),
			selfEmployedAtoIncomeStatements: [
				{
					grossIncome: selfEmployment.latestAtoGrossIncomeStatement,
					startDate: selfEmployment.latestAtoGrossIncomeStatementStart,
					endDate: selfEmployment.latestAtoGrossIncomeStatementEnd,
					financialYearId: selfEmployment.latestAtoGrossIncomeFinancialYear
				},
				{
					grossIncome: selfEmployment.previousAtoGrossIncomeStatement,
					startDate: selfEmployment.previousAtoGrossIncomeStatementStart,
					endDate: selfEmployment.previousAtoGrossIncomeStatementEnd,
					financialYearId: selfEmployment.previousAtoGrossIncomeFinancialYear
				}
			],
			anzscoOccupationCodeId: selfEmployment.anzscoOccupationCodeId,
			occupationCodeId: selfEmployment.occupationCodeId,
			addbacks: ApplicantSelfEmploymentModelTransformer.getAddBacks(selfEmployment, formEnumsQuery),
			employerId: selfEmployment.businessDetailsSelect?.id,
			grossSalaryAmount: selfEmployment.grossSalary?.amount,
			grossSalaryFrequency: selfEmployment.grossSalary?.frequency,
			declaredIncomeIncomeAmount: selfEmployment?.declaredIncomeIncome,
			declaredNetIncomeAmount: selfEmployment.declaredNetIncome,
			businessMainBusinessActivity: selfEmployment.mainBusinessActivity,
			businessStartDate: selfEmployment.businessStartDate,
			industryDivisionId: selfEmployment.industryCategory,
			industrySubdivisionId: selfEmployment.industrySubCategory,
			industryCodeId: selfEmployment.anzsicIndustry,
			incomeDocumentationType:
				employmentModel.employmentDetails.typeOfIncome.incomeDocumentationTypeId ??
				(selfEmployment.latestAtoGrossIncomeStatement ? IncomeDocumentationType.ATOIncomeStatement : undefined),
			selfEmployedApplicantIncome: ApplicantSelfEmploymentModelTransformer.getApplicantIncomeDTO(
				selfEmployment.applicantIncome
			),
			selfEmployedCompanyFinancials:
				selfEmployment.businessFinancials?.modal?.financialYear?.map((financial) => {
					return RelatedCompanyFinancialsTransformer.toPayload(financial);
				}) ?? [],
			employerPartyId: selfEmployment.businessDetailsSelect?.id,
			employerTargetType: selfEmployment.businessDetailsSelect?.type,
			accountantId: selfEmployment.accountantName?.id,
			hasForeignSourcedIncome: selfEmployment.hasForeignSourcedIncome,
			nonRecurringIncomes: this.setNonRecurringIncomes(selfEmployment)
		};
	}

	static fromPayload(
		selfEmploymentDTO: SelfEmploymentDTO,
		formEnumsQuery: FormEnumsQuery,
		businessDetails?: RelatedCompanyModel,
		incomeCalculationType?: IncomeCalculationType
	): EmploymentModel {
		const financialYearEnum = formEnumsQuery.getOptions('FinancialYear');
		const profitsAfterTax = selfEmploymentDTO.profitsAfterTax?.filter((profit) =>
			financialYearEnum.some((year) => year.id === profit.year.id)
		);
		const profitsBeforeTax = selfEmploymentDTO.profitsBeforeTax?.filter((profit) =>
			financialYearEnum.some((year) => year.id === profit.year.id)
		);
		const latestFinancialYear = getLatestFinancialYear(financialYearEnum);
		const selfEmploymentModel: SelfEmploymentModel = {
			status: selfEmploymentDTO.status,
			basis: selfEmploymentDTO.basis,
			businessStructureId: selfEmploymentDTO.businessStructureId,
			dateStarted: selfEmploymentDTO.dateStarted,
			dateEnded: selfEmploymentDTO.dateEnded,
			ownership: selfEmploymentDTO.applicantId,
			anzscoOccupationCodeId: selfEmploymentDTO.anzscoOccupationCodeId,
			occupationCodeId: selfEmploymentDTO.occupationCodeId,
			accountantName: formEnumsQuery.getExistingPartiesById({
				type: TargetType.RelatedCompany,
				id: selfEmploymentDTO.accountantId as number
			}),
			addBacks: this.getAddBackModel(selfEmploymentDTO.addbacks!, latestFinancialYear),
			businessDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: selfEmploymentDTO.employerTargetType as TargetType,
				id: selfEmploymentDTO.employerPartyId as number
			}),
			latestIncome: this.getYearlyIncome(profitsBeforeTax, 0),
			secondLatestIncome: this.getYearlyIncome(profitsBeforeTax, 1),
			thirdLatestIncome: this.getYearlyIncome(profitsBeforeTax, 2),
			grossSalary: {
				amount: selfEmploymentDTO.grossSalaryAmount as number,
				frequency: selfEmploymentDTO.grossSalaryFrequency as number
			},
			declaredIncomeIncome: selfEmploymentDTO?.declaredIncomeIncomeAmount,
			declaredNetIncome: selfEmploymentDTO.declaredNetIncomeAmount,
			firstNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 0),
			secondNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 1),
			thirdNetProfitAfterTax: this.getYearlyIncome(profitsAfterTax, 2),
			latestAtoGrossIncomeFinancialYear:
				selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[0]?.financialYearId ?? financialYearEnum[0].id,
			latestAtoGrossIncomeStatement: selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[0]?.grossIncome,
			latestAtoGrossIncomeStatementStart: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[0]?.startDate,
			latestAtoGrossIncomeStatementEnd: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[0]?.endDate,
			previousAtoGrossIncomeFinancialYear:
				selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[1]?.financialYearId ?? financialYearEnum[1].id,
			previousAtoGrossIncomeStatement: selfEmploymentDTO.selfEmployedAtoIncomeStatements?.[1]?.grossIncome,
			previousAtoGrossIncomeStatementStart: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[1]?.startDate,
			previousAtoGrossIncomeStatementEnd: selfEmploymentDTO?.selfEmployedAtoIncomeStatements?.[1]?.endDate,
			mainBusinessActivity: selfEmploymentDTO.businessMainBusinessActivity,
			businessStartDate: selfEmploymentDTO.businessStartDate,
			industryCategory: selfEmploymentDTO.industryDivisionId,
			industrySubCategory: selfEmploymentDTO.industrySubdivisionId,
			anzsicIndustry: selfEmploymentDTO.industryCodeId,
			applicantIncome: ApplicantSelfEmploymentModelTransformer.getApplicantIncomeModel(
				selfEmploymentDTO.selfEmployedApplicantIncome ?? [{}]
			),
			businessFinancials: {
				modal: {
					financialYear: businessDetails?.companyFinancials?.length
						? businessDetails?.companyFinancials
						: RelatedCompanyFinancialsTransformer.getDefaultFinancialYears(financialYearEnum)
				},
				extract: businessDetails?.companyFinancials?.length ? 'Business financials' : ''
			},
			employerPartyId: selfEmploymentDTO.employerPartyId,
			incomeCalculationType,
			hasForeignSourcedIncome: selfEmploymentDTO.hasForeignSourcedIncome,
			nonRecurringIncome: {
				amount: selfEmploymentDTO.nonRecurringIncomes?.[0]?.amount as number,
				frequency: selfEmploymentDTO.nonRecurringIncomes?.[0]?.year?.id as number
			},
			nonRecurringIncomeType: selfEmploymentDTO.nonRecurringIncomes?.[0]?.nonRecurringIncomeType,
			secondNonRecurringIncome: {
				amount: selfEmploymentDTO.nonRecurringIncomes?.[1]?.amount as number,
				frequency: selfEmploymentDTO.nonRecurringIncomes?.[1]?.year?.id as number
			},
			secondNonRecurringIncomeType: selfEmploymentDTO.nonRecurringIncomes?.[1]?.nonRecurringIncomeType
		};
		const calculated = SelfEmploymentModelTransformer.calculateTotal(
			selfEmploymentModel,
			selfEmploymentDTO?.incomeDocumentationType
		);
		const employmentModel: EmploymentModel = {
			employmentDetails: {
				typeOfIncome: {
					type: IncomeType.selfEmployed,
					incomeDocumentationTypeId: selfEmploymentDTO?.incomeDocumentationType
				},
				payg: {},
				selfEmployment: selfEmploymentModel,
				notEmployed: {},
				foreignEmployment: {}
			},
			currentEmployment: EmploymentModelTransformer.isCurrentEmployment(
				selfEmploymentDTO.dateEnded,
				selfEmploymentDTO.status
			),
			grossAmount: calculated.grossAmount,
			netAmount: calculated.netAmount,
			summaryAmount: calculated.grossAmount,
			frequency: FrequencyShort.Monthly,
			id: selfEmploymentDTO.id,
			applicantId: selfEmploymentDTO.applicantId
		};
		return employmentModel;
	}

	static getAddBackModel(addBackData: AddBackDTO[], currentYear: number | undefined): AddBackRow[] | undefined {
		const addBackModel: AddBackRow[] = [];
		if (addBackData) {
			currentYear =
				currentYear ??
				addBackData.reduce(
					(max, addBackItem) => (max = max > addBackItem.financialYearId! ? max : addBackItem.financialYearId!),
					0
				);
			const prevYear = currentYear - 1;
			const secondPrevYear = currentYear - 2;

			const addBackTypes = [...new Set(addBackData.map((item) => item.type))];
			if (addBackTypes) {
				Object.keys(addBackTypes).forEach((key, index: number) => {
					const addBackType = addBackTypes[index];
					const rowData = addBackData?.filter((x) => x.type === addBackType);
					if (rowData) {
						const addBackRow: AddBackRow = {
							fyCurrent: rowData.find((x) => x.financialYearId === currentYear)?.amount,
							fyPrevious: rowData.find((x) => x.financialYearId === prevYear)?.amount,
							fySecondPrevious: rowData.find((x) => x.financialYearId === secondPrevYear)?.amount,
							type: addBackType
						};
						addBackModel.push(addBackRow);
					}
				});
			}
		}
		return addBackModel;
	}

	static calculateTotal(
		selfEmploymentModel: SelfEmploymentModel,
		documentationType?: IncomeDocumentationType
	): Partial<EmploymentModel> {
		// TAMA5-9492: Show totals based on priority since totals doesn't work for some channels since not all fields are mapped
		const netAmount = yearlyAmountToMonthly(this.calculateNetAmount(selfEmploymentModel, documentationType));
		const grossAmount = this.calculateGrossAmount(selfEmploymentModel, documentationType);

		return {
			grossAmount,
			netAmount
		};
	}

	static calculateNetAmount(selfEmploymentModel: SelfEmploymentModel, documentationType?: IncomeDocumentationType) {
		if (documentationType === IncomeDocumentationType.CompanyFinancials) {
			// Used by ANZ
			return selfEmploymentModel.applicantIncome?.netIncome?.toString();
		} else if (selfEmploymentModel.incomeCalculationType === IncomeCalculationType.DeclaredNetIncome) {
			// Fieldkey income.employments.employmentDetails.selfEmployment.incomeCalculationType needs to have default value of 'DeclaredNetIncome'
			return selfEmploymentModel.declaredNetIncome?.toString();
		} else {
			return (
				selfEmploymentModel.latestIncome?.amount ||
				selfEmploymentModel.firstNetProfitAfterTax?.amount ||
				selfEmploymentModel.secondLatestIncome?.amount ||
				selfEmploymentModel.secondNetProfitAfterTax?.amount ||
				selfEmploymentModel.thirdLatestIncome?.amount ||
				selfEmploymentModel.thirdNetProfitAfterTax?.amount
			);
		}
	}

	static calculateGrossAmount(
		selfEmploymentModel: SelfEmploymentModel,
		documentationType?: IncomeDocumentationType
	): number {
		if (documentationType === IncomeDocumentationType.CompanyFinancials) {
			// Used by ANZ
			return yearlyAmountToMonthly(selfEmploymentModel.applicantIncome?.grossSalary?.toString());
		}

		if (selfEmploymentModel.grossSalary?.amount) {
			return calculateMonthly(selfEmploymentModel.grossSalary?.amount, selfEmploymentModel.grossSalary?.frequency);
		}

		const latestIncome = Number(selfEmploymentModel.latestIncome?.amount ?? 0);
		const amountATO =
			latestIncome +
			(selfEmploymentModel.latestAtoGrossIncomeStatement || selfEmploymentModel.previousAtoGrossIncomeStatement || 0);

		if (
			selfEmploymentModel.addBacks &&
			selfEmploymentModel.addBacks?.length > 0 &&
			this.isAddbackEmpty(selfEmploymentModel.addBacks)
		) {
			// Check gross ATO if addbacks are present but not filled.
			return calculateMonthly(amountATO, FrequencyShort.Yearly);
		}

		if (selfEmploymentModel.addBacks && selfEmploymentModel.addBacks?.length > 0) {
			const addBackSalary = this.getLatestAddbackSalary(selfEmploymentModel.addBacks);

			// If addback was of different type, no addbacks comes filtered. Therefore, we check gross ATO
			if (this.isAddbackEmpty([addBackSalary])) {
				return calculateMonthly(amountATO, FrequencyShort.Yearly);
			}

			// If an addback is found of salary type, we proceed to check from them
			const amountFy =
				latestIncome + (addBackSalary.fyCurrent || addBackSalary.fyPrevious || addBackSalary.fySecondPrevious || 0);
			return calculateMonthly(amountFy, FrequencyShort.Yearly);
		}

		// Checks gross ATO if addbacks are deleted
		return calculateMonthly(amountATO, FrequencyShort.Yearly);
	}

	// Checks whether addbacks are not filled
	static isAddbackEmpty(addbacks: AddBackRow[]) {
		let isEmpty = true;
		// Since can add more than one addback
		addbacks.map((addback) => {
			Object.entries(addback).forEach(([key, value]) => {
				// Since 'type' is a mandatory field, we skip it. Same with 'default' since default: true for the addback that is added by default
				if (key !== 'type' && key !== 'default') {
					if (value != null) {
						// If any field contains a value (filled), function should returns as false
						isEmpty = false;
					}
				}
			});
		});
		return isEmpty;
	}

	static getLatestAddbackSalary(addbacks: AddBackRow[]) {
		// Filter only addbacks that are of 'salary' type based on requirement
		const filteredAddbacks = addbacks.filter((addback) => {
			return addback.type === AddbackType.Salary;
		});
		// If found matching salary addback, get the last element since can only add 2 addbacks max and the latest years are found in the last addback.
		// Returns an empty addback with values as undefined if no match
		return filteredAddbacks.length > 0 ? filteredAddbacks[filteredAddbacks.length - 1] : ({} as AddBackRow);
	}

	static getYearlyIncome(profits: Profit[] | undefined, yearIndex: number): Amount {
		if (profits && profits[yearIndex]) {
			return {
				amount: profits[yearIndex].amount.toString(),
				frequency: {
					id: profits[yearIndex].year.id!,
					label: profits[yearIndex].year.name!
				}
			};
		} else {
			return {} as Amount;
		}
	}

	static setNonRecurringIncomes(selfEmployment: SelfEmploymentModel): NonRecurringIncomes[] {
		const incomes: NonRecurringIncomes[] = [];

		incomes.push({
			amount: selfEmployment.nonRecurringIncome?.amount,
			year: {
				id: (selfEmployment.nonRecurringIncome?.frequency as unknown as EnumObject)?.id,
				name: (selfEmployment.nonRecurringIncome?.frequency as unknown as EnumObject)?.label
			},
			nonRecurringIncomeType: selfEmployment.nonRecurringIncomeType
		});

		incomes.push({
			amount: selfEmployment.secondNonRecurringIncome?.amount,
			year: {
				id: (selfEmployment.secondNonRecurringIncome?.frequency as unknown as EnumObject)?.id,
				name: (selfEmployment.secondNonRecurringIncome?.frequency as unknown as EnumObject)?.label
			},
			nonRecurringIncomeType: selfEmployment.secondNonRecurringIncomeType
		});

		return incomes;
	}
}

export class SelfEmploymentModel {
	status?: number;
	ownership?: number;
	basis?: number;
	dateStarted?: string;
	dateEnded?: string;
	businessStructureId?: number;
	anzscoOccupationCodeId?: number;
	occupationCodeId?: number;
	latestIncome?: Partial<Amount>;
	secondLatestIncome?: Partial<Amount>;
	thirdLatestIncome?: Partial<Amount>;
	addBacks?: AddBackRow[];
	accountantName?: ApplicantEnumObject;
	hideSecondAddback? = false;
	hideThirdAddback? = false;
	businessDetailsSelect?: ApplicantEnumObject;
	grossSalary?: AmountSelect;
	declaredIncomeIncome?: number;
	declaredNetIncome?: number;
	firstNetProfitAfterTax?: Partial<Amount>;
	secondNetProfitAfterTax?: Partial<Amount>;
	thirdNetProfitAfterTax?: Partial<Amount>;
	latestAtoGrossIncomeFinancialYear?: number;
	latestAtoGrossIncomeStatement?: Partial<number>;
	latestAtoGrossIncomeStatementStart?: string;
	latestAtoGrossIncomeStatementEnd?: string;
	previousAtoGrossIncomeFinancialYear?: number;
	previousAtoGrossIncomeStatement?: Partial<number>;
	previousAtoGrossIncomeStatementStart?: string;
	previousAtoGrossIncomeStatementEnd?: string;
	mainBusinessActivity?: string;
	businessStartDate?: string;
	industryCategory?: number;
	industrySubCategory?: number;
	anzsicIndustry?: number;
	applicantIncome?: ApplicantIncome;
	businessFinancials?: RelatedCompanyFinancialModal;
	employerPartyId?: number;
	employerTargetType?: TargetType;
	incomeCalculationType?: IncomeCalculationType;
	hasForeignSourcedIncome?: boolean;
	nonRecurringIncome?: AmountSelect;
	nonRecurringIncomeType?: NonRecurringIncomeType;
	secondNonRecurringIncome?: AmountSelect;
	secondNonRecurringIncomeType?: NonRecurringIncomeType;
}

export enum IncomeCalculationType {
	DeclaredNetIncome = 'DeclaredNetIncome'
}

interface AddBackRow {
	type?: AddbackType;
	fySecondPrevious?: number;
	fyPrevious?: number;
	fyCurrent?: number;
}

export class ApplicantIncome {
	grossSalary?: number;
	dividends?: number;
	distributionFromPartnership?: number;
	distributionFromTrust?: number;
	netIncome?: number;
}

class Profit {
	amount!: number;
	year!: {
		id?: number;
		name?: string;
	};
}
