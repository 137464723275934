/* eslint-disable brace-style */
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { routeTransitionSlideAnimations, slideIn } from '@app/route-transition-animations';
import { FieldTypeConfig, FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'formly-mobile-modal-wrapper',
	templateUrl: './formly-mobile-modal-wrapper.component.html',
	styleUrls: [
		'./formly-mobile-modal-wrapper.component.scss',
		'../formly-mobile-step/formly-mobile-step.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [slideIn, routeTransitionSlideAnimations]
})
export class FormlyMobileModalWrapperComponent
	extends FieldWrapper<FieldTypeConfig>
	implements AfterViewInit, OnDestroy
{
	@ViewChild('content') public contentTemplate?: TemplateRef<unknown>;
	Action = Action;
	private actionSubject = new Subject<string>();
	private isValidationTriggered = false;
	private destroy$: Subject<void> = new Subject();

	constructor(private formValidationsService: FormValidationsService) {
		super();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.to.contentTemplate = this.contentTemplate;
			this.to.action = this.actionSubject.asObservable();
		});
	}

	onAction(action: Action): void {
		if (this.isValidationTriggered) {
			this.formValidationsService.triggerValidation();
		}

		if (action !== Action.Submit || this.formControl.valid) {
			this.closeModal(action);
		} else {
			if (!this.isValidationTriggered) {
				this.isValidationTriggered = true;
				this.formValidationsService.triggerValidation();
			}
			if (this.field.validation) {
				this.field.validation.show = false;
				this.field.validation.show = true;
			}
			this.formControl.markAllAsTouched();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private closeModal(action: string): void {
		const lastOpenedModal = document.getElementsByClassName('loanapp-x-modal');
		lastOpenedModal[lastOpenedModal.length - 1].classList.remove('loanapp-x-modal--show');
		setTimeout(() => {
			this.actionSubject.next(action);
		}, 100);
	}
}

export enum Action {
	Submit = 'submit',
	Close = 'close',
	Cancel = 'cancel',
	Delete = 'delete',
	Match = 'match',
	MatchDone = 'MatchDone',
	Add = 'add',
	Remove = 'remove',
	RemoveCancel = 'removeCancel',
	RemoveDone = 'removeDone',
	Find = 'Find',
	CancelSummary = 'cancelSummary'
}
