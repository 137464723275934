import { TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { TrustSettlorDTO } from '@app/modules/typings/api';

export class TrustSettlorTransformer {
	static fromPayload(settlor: TrustSettlorDTO, formEnumsQuery: FormEnumsQuery): TrustSettlorModel {
		return {
			id: settlor.settlorId,
			existingSelect: formEnumsQuery.getExistingPartiesById({
				type: settlor.targetType,
				id: settlor.partyId
			}),
			vestingDate: settlor.vestingDate
		};
	}

	static toPayload(applicantId: number, settlor: TrustSettlorModel): Partial<TrustSettlorDTO> {
		return {
			settlorId: settlor.id || 0,
			trustApplicantId: applicantId,
			applicationId: settlor.applicationId,
			targetType: settlor.targetType || settlor.existingSelect.type,
			partyId: settlor.existingSelect.id || settlor.existingSelect.id,
			vestingDate: settlor.vestingDate
		};
	}
}

export interface TrustSettlorModel {
	id?: number;
	targetType?: TargetType;
	applicationId?: number;
	existingSelect: ApplicantEnumObject;
	partyId?: number;
	vestingDate: string;
}
