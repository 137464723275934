import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-table',
	templateUrl: './formly-table.component.html',
	styleUrls: ['./formly-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTableComponent extends FieldType<FieldTypeConfig> {}
