<div class="amount-select-input-wrapper">
	<simp-amount-select-input
		[options]="$any($any(to.options) | selectOptions | async | dynamicOptions: to.dynamicOptions)"
		[id]="uniqueId"
		[formGroup]="$any(formControl)"
		[validationErrors]="field.validation?.messages!"
		[bindValueToObject]="to.bindValueToObject"
		[tooltipContent]="to.tooltip"
		[tooltipAsModal]="to.tooltipAsModal"
		(tooltipClick)="tooltipClick(field)"
		[wholeNumberOnly]="to.decimalPlaces === 0"
		[readonly]="!!to.readonly"
		metadataKey
		[formlyField]="field"
		[attr.id]="uniqueId"
		[label]="to.label || ''"
		[hideLabel]="to.hideLabel"
	>
	</simp-amount-select-input>
</div>

<div *ngIf="to.tooltipText" class="tooltip-text simp-text-small simp-padding-base" [innerHTML]="to.tooltipText"></div>
<div *ngIf="to.arrowBoxText" class="arrow-box simp-text-small simp-padding-base" [innerHTML]="to.arrowBoxText"></div>
<div *ngIf="to.subText" class="spinner-text">
	<span *ngIf="to.subTextSpinner" class="lds-ring">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</span>
	<span *ngIf="to.subText" [class.lds-text]="to.subTextSpinner">{{ to.subText }}</span>
</div>

<!-- quirky way to update form model -->
<formly-field [hidden]="true" *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
