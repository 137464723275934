import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, switchMap, tap } from 'rxjs';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { FormEnumsService } from '../shared/store/form-enums/form-enums.service';
import { FormStateService } from '../shared/store/form-state/form-state.service';
import { findPropertyFromSchema } from '../simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '../simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import { ScenariosDTO, SelectedScenarioDTO } from '../typings/api';
import { FormEnumsQuery } from './../shared/store/form-enums/form-enums.query';
import { ScenarioLeverDetails, ScenarioLeverTransformer } from './model/scenario-lever.model';
import { ScenarioSelectionTransformer, SelectedScenario } from './model/scenario-selection.model';
@Injectable({ providedIn: 'root' })
export class ServiceabilityScenariosService extends BaseJourneyService {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsService: FormEnumsService,
		private formEnumsQuery: FormEnumsQuery,
		private formStateService: FormStateService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(scenarioServiceabilitySchema?: FormlyApiProperty) {
		const baseScenarioLabel = this.getBaseScenarioLabel(scenarioServiceabilitySchema);
		this.updateScenarioEditState(false);
		this.simpFormlyHandlerService.upsertToStateWithData('serviceabilityResults', []);
		return forkJoin([this.syncScenarios$(baseScenarioLabel)]);
	}

	syncScenarios$(baseScenarioLabel?: string, scenarioId?: number) {
		return forkJoin([this.fetchScenarios(baseScenarioLabel, scenarioId)]).pipe(
			switchMap(([scenarioSelection]: [SelectedScenario]) => {
				return this.fetchScenarioLeverDetails(scenarioSelection.scenarioId).pipe(
					map((scenarioLevers) => [scenarioSelection, scenarioLevers])
				);
			}),
			tap(([scenarioSelection, scenarioLevers]) => {
				this.simpFormlyHandlerService.upsertToStateWithData('scenarioSelection', [scenarioSelection]);
				this.simpFormlyHandlerService.upsertToStateWithData('scenarioLevers', [scenarioLevers]);
			})
		);
	}

	getBaseScenarioLabel(scenarioServiceabilitySchema?: FormlyApiProperty): string | undefined {
		const baseScenarioLabel = findPropertyFromSchema(
			scenarioServiceabilitySchema,
			'scenarios.scenarioLevers.baseScenarioLabel'
		) as FormlyApiProperty;

		return baseScenarioLabel?.template;
	}

	fetchScenarios(baseScenarioLabel?: string, scenarioId?: number): Observable<SelectedScenario> {
		return this.getCustom(`/ServiceabilityScenario/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((scenarios: SelectedScenarioDTO[]) => {
				const scenarioList = scenarios
					.map((scenario: SelectedScenarioDTO) => {
						return {
							id: scenario.id,
							label: scenario.id === 0 && baseScenarioLabel ? baseScenarioLabel : scenario.title // always id = 0 returns base scenario
						};
					})
					.sort((a, b) => a.id - b.id);
				this.formEnumsService.updateFormEnums('ScenariosEnum', scenarioList);
			}),
			map((scenarios: SelectedScenarioDTO[]) => ScenarioSelectionTransformer.fromPayload(scenarios, scenarioId))
		);
	}

	fetchScenarioLeverDetails(scenarioId: number, isNewScenario = false): Observable<ScenarioLeverDetails> {
		return this.getCustom(`/ServiceabilityScenario/${this.applicationDataQuery.applicationId()}/${scenarioId}`).pipe(
			map((scenariosDto: ScenariosDTO) => {
				return ScenarioLeverTransformer.fromPayload(scenariosDto, isNewScenario, this.formEnumsQuery);
			})
		);
	}

	updateScenarioEditState(scenarioEditState: boolean) {
		this.formStateService.updateFormState({ scenarioEditState });
	}

	saveScenarioLever(
		scenarioLevers: ScenarioLeverDetails,
		formEnumsQuery: FormEnumsQuery,
		isNewScenario: boolean
	): Observable<any> {
		const payLoad = ScenarioLeverTransformer.toPayload(scenarioLevers, formEnumsQuery, isNewScenario);

		return this.postCustom(`/ServiceabilityScenario/${this.applicationDataQuery.applicationId()}`, payLoad);
	}

	deleteSelectedScenario(scenarioId: number): Observable<void> {
		return this.delete(
			`/ServiceabilityScenario/${this.applicationDataQuery.applicationId()}/${scenarioId}`
		) as Observable<void>;
	}
}
