import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnalyticsEventType, RefiAnalyticsService } from '@app/modules/refi-journey/services/refi-analytics.service';
import { formlyGenerateLabels, formlyGetFieldLink } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyLabels } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { RefiModalName } from '../services/refi-modal.service';

@Component({
	selector: 'refi-modal',
	templateUrl: './refi-modal.component.html',
	styleUrls: ['./refi-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefiModalComponent implements OnInit, OnDestroy {
	@Input() refiModalName!: RefiModalName;
	@Input() modalFormField: FormlyFieldConfig | undefined;
	@Input() target = '_blank';
	labels: FormlyLabels = {};
	external = false;
	confirmControl = new FormControl(false);
	private destroy$: Subject<void> = new Subject();

	constructor(public activeModal: NgbActiveModal, private refiAnalyticsService: RefiAnalyticsService) {}

	ngOnInit(): void {
		if (this.modalFormField) {
			this.labels = formlyGenerateLabels(this.modalFormField, `${this.refiModalName}Labels`);
		}
		const modals = document.getElementsByClassName('modal-content');
		const modal = modals[modals.length - 1];
		modal.classList.add('refi-modal-content');
		this.external = !!formlyGetFieldLink(this.modalFormField, `${this.refiModalName}Labels`, 'tertiaryButton');
		this.refiAnalyticsService.trackEvent(AnalyticsEventType.ModalView, this.refiModalName);
		if (!this.labels.confirm) {
			this.confirmControl.setValue(true);
			this.confirmControl.updateValueAndValidity();
		}
	}

	handleActionButtonClick(clickType: string): void {
		if (this.modalFormField?.fieldGroup) {
			const link = formlyGetFieldLink(this.modalFormField, `${this.refiModalName}Labels`, clickType);
			if (link) {
				window.open(link, this.target);
			}
		}
		this.refiAnalyticsService.trackEvent(`${clickType}Click`, this.refiModalName);
		this.activeModal.close(clickType);
	}

	close(): void {
		this.activeModal.dismiss();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
