import { UntypedFormGroup } from '@angular/forms';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ExpenseCategory, ExpenseSource } from '@app/modules/shared/enums/app.enums';
import { roundNumber } from '@app/modules/shared/helper/util';
import { TextOption } from '@app/modules/shared/model/text-option';
import { AggregateFormatterService } from '@app/modules/shared/service/aggregate-formatter.service';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { AddedDigitalExpenseDTO, DeclaredExpenseDTO, DigitalExpenseModelDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { EnumObject, FrequencyFull, FrequencyShort } from '@simpology/client-components/utils';
import { get } from 'lodash-es';
import { Observable, Subject } from 'rxjs';

export class DigitalExpenseTransformer {
	static toPayload(
		model: DigitalExpenseModel,
		expenseCategory: number,
		ownershipEnum: TextOption[]
	): DigitalExpenseModelDTO {
		return {
			applicationId: model.applicationId,
			id: model.id ?? CONSTANTS.NEW_ID,
			amount: Number(model.digitalValue),
			changeReason: model.changeReason,
			isOneOff: model.isOneOff,
			expenseCategory: model.id ? undefined : expenseCategory,
			ownership: ownershipEnum[model.ownership || 0]?.value,
			description: model.description,
			accountName: model.accountName,
			isAdded: model.isAdded,
			frequency: model.frequency
		};
	}

	static toAddDigitalExpensePayload(
		model: DigitalExpenseModel,
		expenseCategory: number,
		ownershipEnum: TextOption[]
	): AddedDigitalExpenseDTO {
		return {
			applicationId: model.applicationId,
			id: model.id ?? CONSTANTS.NEW_ID,
			amount: model.digitalValueSelect?.amount,
			expenseCategory: expenseCategory,
			ownership: ownershipEnum[model.ownership || 0]?.value,
			description: model.description,
			frequency: model.digitalValueSelect?.frequency
		};
	}

	static fromPayload(
		expenseDTO: DigitalExpenseModelDTO,
		ownershipValues: TextOption[],
		formatter: AggregateFormatterService
	): DigitalExpenseModel {
		const ownershipId = ownershipValues.findIndex((x) => x.value === expenseDTO.ownership) ?? 0;
		const tooltipText = this.getTooltipText(expenseDTO, formatter);
		const householdId = Number(ownershipValues.find((x) => x.value === expenseDTO.ownership)?.value.split('|')[0]);

		return {
			id: expenseDTO.id,
			description: expenseDTO.transactionDate
				? `${formatter.formatDate(expenseDTO.transactionDate)} - ${expenseDTO.description}`
				: expenseDTO.description,
			ownership: ownershipId,
			digitalValue: expenseDTO.amount,
			accountName: expenseDTO.accountName,
			changeReason: expenseDTO.changeReason,
			isOneOff: expenseDTO.isOneOff,
			applicationId: expenseDTO.applicationId,
			tooltipText: tooltipText,
			originalAmount: expenseDTO.originalAmount,
			isAdded: expenseDTO.isAdded,
			digitalValueSelect: expenseDTO.isAdded ? { amount: expenseDTO.amount, frequency: expenseDTO.frequency } : null,
			frequency: expenseDTO.frequency,
			householdId
		};
	}

	static getTooltipText(
		expenseDTO: DigitalExpenseModelDTO | DigitalExpenseModel,
		formatter: AggregateFormatterService
	): string {
		const currentAmount = Number(get(expenseDTO, 'amount', get(expenseDTO, 'digitalValue')) as unknown as number);
		if ((expenseDTO.originalAmount || 0) > 0 && expenseDTO.originalAmount !== currentAmount) {
			const formattedAmount = formatter.formatAmount(expenseDTO.originalAmount);
			const changeReason =
				expenseDTO.isOneOff || expenseDTO.changeReason
					? `(${expenseDTO.isOneOff ? 'excluded as once-off' : ''}${
							expenseDTO.changeReason && expenseDTO.isOneOff ? ' - ' : ''
					  }${expenseDTO.changeReason || ''})`
					: '';
			return `Was ${formattedAmount} ${changeReason}`;
		}
		return '';
	}
}

export class DeclaredExpenseTransformer {
	static toPayload(model: Partial<DeclaredExpenseModel>, applicationId: number, category: number): DeclaredExpenseDTO {
		const amountSelect = model.value as AmountSelect;

		return {
			applicationId,
			id: model.id ?? CONSTANTS.NEW_ID,
			amount: roundNumber(amountSelect.amount),
			expenseCategory: category,
			description: model.description,
			frequency: amountSelect.frequency ?? FrequencyShort.Monthly,
			isTotal: model.isTotal,
			isEvenSplit: undefined,
			importedAmount: model.importedAmount ?? 0,
			sortOrder: model.sortOrder
		};
	}

	static fromPayload(
		expenseDTO: DeclaredExpenseDTO,
		ownershipValues: TextOption[],
		formatter: AggregateFormatterService,
		arrowBoxThreshold: number
	): DeclaredExpenseModel {
		const ownershipId = ownershipValues.findIndex((x) => x.value === expenseDTO.ownership) ?? 0;
		const householdId = Number(ownershipValues.find((x) => x.value === expenseDTO.ownership)?.value.split('|')[0]);

		const value = {
			amount: expenseDTO.amount,
			frequency: expenseDTO.frequency
		} as AmountSelect;
		return {
			id: expenseDTO.id,
			description: expenseDTO.description,
			ownership: ownershipId,
			householdId,
			value,
			isTotal: !!expenseDTO.isTotal,
			expenseCategory: expenseDTO.expenseCategory as ExpenseCategory,
			applicantId: expenseDTO.applicantId,
			importedAmount: expenseDTO.importedAmount,
			arrowBoxText: this.getArrowBoxText(expenseDTO, formatter, arrowBoxThreshold),
			sortOrder: expenseDTO.sortOrder
		};
	}

	static getArrowBoxText(
		expenseDTO: DeclaredExpenseDTO,
		formatter: AggregateFormatterService,
		arrowBoxThreshold: number
	): string {
		const differenceFromImported = Math.abs((expenseDTO.importedAmount as number) - expenseDTO.amount);
		if ((expenseDTO.importedAmount || 0) > 0 && differenceFromImported > arrowBoxThreshold) {
			const formattedAmount = formatter.formatAmount(expenseDTO.importedAmount);
			return `Was ${formattedAmount}`;
		}

		return ``;
	}
}

export interface DeclaredExpenseModel {
	value?: AmountSelect;
	description?: string;
	ownership?: number;
	id?: number;
	isTotal: boolean;
	applicantId?: number;
	householdId?: number;
	expenseCategory: ExpenseCategory;
	importedAmount?: number;
	arrowBoxText: string;
	hide?: boolean;
	sortOrder?: number;
}

export interface HouseholdExpensesModel {
	id: number;
	declaredTotal: number;
	digitalTotal: AmountSelect;
	showDeclared?: boolean;
	hem?: number;
}

export interface ExpensesHouseholdTotal extends EnumObject {
	expanded: boolean;
	loading: boolean;
	model$: Observable<HouseholdExpensesModel>;
	formGroup?: UntypedFormGroup;
	fields: FormlyFieldConfig;
	options: FormlyFormOptions;
}

export interface DigitalExpenseModel {
	description?: string;
	ownership: number;
	id: number;
	applicationId: number;
	digitalValue: number | null;
	digitalValueSelect: AmountSelect | null;
	accountName?: string;
	changeReason: string;
	isOneOff: boolean;
	originalAmount?: number;
	tooltipText?: string;
	isAdded: boolean;
	frequency: FrequencyFull;
	householdId?: number;
}
export interface DigitalExpense {
	records: DigitalExpenseModel[];
	count: number;
}

export interface ExpenseHeaderModel {
	categoryId?: number;
	declaredTotal?: number;
	digitalTotal?: AmountSelect;
	expenseSource?: ExpenseSource;
	declaredEdit?: AmountSelect;
	isTotal?: boolean;
	showDeclared?: boolean;
	shouldUpdate?: boolean;
	differenceInDeclaredToDigitalTotal: string;
	differenceInDigitalTotalValuesText: string;
	selectedHousehold?: number;
	totalCount?: number;
}

export interface HemCommentModel {
	hem: number;
	householdId: number;
	expenseCategory: ExpenseCategory;
	householdName?: string;
	addingComment?: boolean;
	comment?: string;
	editComment?: string;
}

export interface DigitalExpenseForm extends DigitalExpenseModel {
	form?: {
		formGroup: UntypedFormGroup;
		fields: FormlyFieldConfig;
		options: FormlyFormOptions;
		model: DigitalExpenseModel;
	};
}

export interface ExpensesCategory extends EnumObject {
	expanded: boolean;
	isDigital: boolean;
	loading: boolean;
	model$: Observable<ExpenseHeaderModel>;
	formGroup?: UntypedFormGroup;
	fields: FormlyFieldConfig;
	options: FormlyFormOptions;
	totalCount$: Observable<number>;
	hemBanners$: Observable<HemCommentModel[]>;
	info?: string;
	static?: boolean;
	refetchLoading$: Subject<boolean>;
}
