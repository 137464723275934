import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { getCookie } from '@app/modules/shared/helper/util';
import { MetaJourneyConfigurationService } from '@app/modules/shared/service/meta-journey-configuration.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormMetaDataService } from '@app/modules/shared/store/form-metadata/form-metadata.service';
import { AnalyticsMetadata, AnalyticsPayload, AnalyticsPayloadType, AnalyticsService } from '@simpology/authentication';
import { Subject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class RefiAnalyticsService {
	public analyticsMetadataLoaded$ = new Subject();
	private analyticsMetadata!: AnalyticsMetadata;
	private userId: string | undefined;
	private maskedItemsList: string[] = [];

	constructor(
		private analyticsService: AnalyticsService,
		private formMetadataService: FormMetaDataService,
		private journeyConfigurationService: MetaJourneyConfigurationService,
		private formDataService: FormDataService,
		private applicationDataQuery: ApplicationDataQuery,
		private router: Router
	) {}

	initialiseAnalytics(): void {
		this.userId = getCookie('_tracker');
		const analyticsMetadataInSession = sessionStorage.getItem(CONSTANTS.ANALYTICS_METADATA);
		if (analyticsMetadataInSession) {
			this.analyticsMetadata = JSON.parse(analyticsMetadataInSession) as AnalyticsMetadata;
		}

		if (!this.analyticsMetadata) {
			const journeyId = this.applicationDataQuery.getApplicationJourneyId();
			this.journeyConfigurationService.getJourneyConfiguration(journeyId).subscribe((journeyConfig) => {
				if (!journeyConfig.analytics) {
					return;
				}
				const analyticsMetadata = JSON.parse(journeyConfig.analytics ?? '') as unknown as AnalyticsMetadata;
				if (analyticsMetadata) {
					this.analyticsMetadata = analyticsMetadata;
					sessionStorage.setItem(CONSTANTS.ANALYTICS_METADATA, JSON.stringify(analyticsMetadata));
					this.analyticsService.initialiseAnalytics(this.analyticsMetadata.config, this.userId);
					this.analyticsMetadataLoaded$.next(true);
				}
			});
		} else {
			this.analyticsService.initialiseAnalytics(this.analyticsMetadata.config, this.userId);
		}
	}

	trackEvent(eventType: AnalyticsEventType | string, id: string, info?: string): void {
		const payloads = this.getAnalyticsEventsPayload(eventType, id);
		if (payloads) {
			payloads.forEach((payload) => {
				if (payload.adobe) {
					this.maskedItemsList = [];
					this.maskData(payload.adobe, true);
					if (this.maskedItemsList.length) {
						payload.adobe.additionalpageinfo = {
							globalmaskingfieldcount: this.maskedItemsList.length,
							globalmaskingfieldname: this.maskedItemsList.join(', ')
						};
						payload.adobe.milestone = {
							cbaEvents: {
								globalMaskingApplied: {
									value: 1
								}
							}
						};
					}
				}

				let payloadString = JSON.stringify(payload);

				const pageLocation = { href: window.location.href };
				this.maskData(pageLocation);
				this.removeQueryParams(pageLocation);
				payloadString = payloadString.replace(new RegExp('{pageLocation}', 'g'), pageLocation.href);
				payloadString = payloadString.replace(new RegExp('{timestamp}', 'g'), new Date().getTime().toString());
				payloadString = payloadString.replace(new RegExp('{userId}', 'g'), this.userId ?? '');
				payloadString = payloadString.replace(new RegExp('{loanAmount}', 'g'), this.getLoanAmount() ?? '');
				payloadString = payloadString.replace(
					new RegExp('{opportunityId}', 'g'),
					this.applicationDataQuery.getRefiOpportunityId() ?? ''
				);

				payloadString = payloadString.replace(
					new RegExp('{applicationId}', 'g'),
					this.convertToUniqueId(this.applicationDataQuery.applicationId()) ?? ''
				);
				if (info) {
					payloadString = payloadString.replace(new RegExp('{info}', 'g'), info);
				}
				this.analyticsService.trackEvent(JSON.parse(payloadString) as AnalyticsPayload);
			});
		}
	}

	private maskData(obj: AnalyticsPayloadType, addToList = false, fieldPath = ''): void {
		Object.keys(obj).forEach((key) => {
			// Construct fieldPath by appending field key
			fieldPath += `${key}.`;
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				this.maskData(obj[key] as AnalyticsPayloadType, addToList, fieldPath);
			} else if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
				let maskingDone = false;

				// Redact email address if found and replace with xxxxx@xxx.xxx
				const emailregex = /([a-z0-9._-]+@[a-z0-9._-]+\.[a-z0-9._-]+)/gi;
				if (emailregex.test(String(obj[key]))) {
					obj[key] = String(obj[key]).replace(emailregex, 'xxxxx@xxx.xxx');
					maskingDone = true;
				}

				// Mask more than 5 consecutive digits
				if (
					String(obj[key])
						.replace(/[^a-zA-Z0-9]/g, '')
						.match(/\d{5}/)
				) {
					obj[key] = String(obj[key]).replace(new RegExp(/\d{1,}/, 'ig'), (m: string) => 'x'.repeat(m.length));
					maskingDone = true;
				}

				if (maskingDone && addToList) {
					this.maskedItemsList.push(fieldPath.slice(0, -1)); // Slice to remove dot at the end of fieldPath
				}
			}

			// Remove last added field key from fieldPath: 'parent.child.' -> 'parent.'
			const interimPath = fieldPath.slice(0, -1).split('.');
			fieldPath = interimPath.splice(0, interimPath.length - 1).join('.');
			if (fieldPath !== '') {
				fieldPath += '.';
			}
		});
	}

	private removeQueryParams(pageLocation: { href: string }): { href: string } {
		if (pageLocation.href.includes('?')) {
			pageLocation.href = pageLocation.href.split('?')[0];
		}
		return pageLocation;
	}

	private digitsToAscii(number: number) {
		const asciiOffset = 65; // ASCII code for 'A'

		// Convert number to string to loop through its digits
		const numberStr = String(number);

		let result = '';

		for (let i = 0; i < numberStr.length; i++) {
			const digit = parseInt(numberStr[i]);

			// Check if digit is within the range [0, 9]
			if (!isNaN(digit) && digit >= 0 && digit <= 9) {
				// Convert digit to ASCII alphabet
				const asciiCode = asciiOffset + digit;
				const asciiChar = String.fromCharCode(asciiCode);

				// Append the ASCII alphabet to the result
				result += asciiChar;
			}
		}

		return result;
	}

	private convertToUniqueId(number: number) {
		let uniqueId = '';
		const digitsToAscii = this.digitsToAscii(number);
		for (let i = 0; i < digitsToAscii.length; i++) {
			uniqueId += digitsToAscii[i] + String(number)[i];
		}
		return uniqueId;
	}

	private getAnalyticsEventsPayload(eventType: string, id: string): AnalyticsPayload[] | undefined {
		return this.analyticsMetadata?.events[eventType].find((ev) => id.includes(ev.id))?.payload;
	}

	private getLoanAmount(): string {
		// TODO: loan amount is being moved from eligibility
		const loanAmount = 0;
		const amountRanges = [
			{ amount: 9000000, code: '10M' },
			{ amount: 8000000, code: '9M' },
			{ amount: 7500000, code: '8M' },
			{ amount: 7000000, code: '7500' },
			{ amount: 6500000, code: '7000' },
			{ amount: 6000000, code: '6500' },
			{ amount: 5500000, code: '6000' },
			{ amount: 5000000, code: '5500' },
			{ amount: 4500000, code: '5000' },
			{ amount: 4000000, code: '4500' },
			{ amount: 3500000, code: '4000' },
			{ amount: 3000000, code: '3500' },
			{ amount: 2500000, code: '3000' },
			{ amount: 2000000, code: '2500' },
			{ amount: 1500000, code: '2000' },
			{ amount: 1000000, code: '1500' },
			{ amount: 500000, code: '1000' },
			{ amount: 100000, code: '500' }
		];

		for (const range of amountRanges) {
			if (loanAmount > range.amount) {
				return range.code;
			}
		}

		return '100';
	}

	// For local dev only
	// please run `node digi-analytics.mjs local` to generate the file to be added in assets folder
	private fetchAnalyticsDataFromLocalFile(): void {
		this.formMetadataService.fetchAnalytics().subscribe((res) => {
			this.analyticsMetadata = res;
			this.analyticsService.initialiseAnalytics(res.config, this.userId);
			this.analyticsMetadataLoaded$.next(true);
		});
	}
}

export enum AnalyticsEventType {
	PageView = 'pageView',
	ModalView = 'modalView',
	PrimaryButtonClick = 'primaryButtonClick',
	SecondaryButtonClick = 'secondaryButtonClick',
	TertiaryButtonClick = 'tertiaryButtonClick',
	CustomEvent = 'customEvent'
}
