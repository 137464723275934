import { JourneyType } from '../enums/app.enums';
import { Journey } from '../typings/journey';

export const CONSTANTS = {
	NEW_ID: -1,
	NotFoundCode: 404,
	NoArea: 'noarea',
	DEFAULT_STRING: '--',
	LOANAPP_CLIENT_LANDING_URL: '',
	LOANAPPX_PATH: 'mobile',
	REFI_PATH: 'retail/refinance',
	PURCHASE_PATH: 'retail/purchase',
	REFI_LOGOUT_PATH: 'refi-logout',
	PURCHASE_LOGOUT_PATH: 'purchase-logout',
	REFI_LOGOUT_DISCARD_APPLICATION: 'refi-logout-discard-application',
	EASY_REFI_PATH: 'easy-refi',
	CURRENT_USER: 'simp:currentUser',
	API_PATH_KEY: 'simp:apiPath',
	SECURE_APPLICATION_ID: 'simp:secureApplicationId',
	SECURE_JOURNEY_TYPE: 'simp:secureJourneyType',
	JointOwnership: 1,
	commonFileExtensions: ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'bmp'],
	emosFileExtension: ['mos'],
	OPTIONAL: '(optional)',
	FORM_INSTANCE_ID: 'simp:formInstanceId',
	SERVICEABILITY_PATH: 'serviceability',
	LAZY_LOAD_MODALS_FOR: ['employments'],
	SKIP_MAP_TO_STATE: 'SKIP_MAP_TO_STATE',
	INITIAL_STATUS_TEXT: 'Setting up application...',
	SYNCING: 'Syncing...',
	INVALID_URL: 'invalid-url',
	ANALYTICS_METADATA: 'analyticsMetadata',
	HIDE_GUARANTOR: 'HIDE_GUARANTOR',
	AIP_LANDING_PATH: 'aip',
	ACTIVITY_LOG_PATH: 'activity-log',
	CHECK_APPLICANTS_STATUS_TIMEOUT: 5000,
	ERROR_MIN: 'min',
	ERROR_MAX: 'max',
	ERROR_INVALID: 'invalid'
};

export type DateFormat =
	| 'short'
	| 'medium'
	| 'long'
	| 'full'
	| 'shortDate'
	| 'mediumDate'
	| 'longDate'
	| 'fullDate'
	| 'extendedDate'
	| 'shortTime'
	| 'mediumTime'
	| 'longTime'
	| 'fullTime';

export const DateFormatCodes: Record<DateFormat, string> = {
	short: 'dd/MM/yyyy, h:mm a',
	medium: 'd MMM yyyy, h:mm:ss a',
	long: 'd MMMM yyyy, h:mm:ss a Z',
	full: 'EEEE, d MMMM yyyy, h:mm:ss a Z',
	shortDate: 'dd/MM/yyyy',
	mediumDate: 'd MMM yyyy',
	longDate: 'd MMMM yyyy',
	fullDate: 'EEEE, d MMMM yyyy',
	extendedDate: 'dd MMMM yyyy',
	shortTime: 'h:mm a',
	mediumTime: 'h:mm:ss a',
	longTime: 'h:mm:ss a Z',
	fullTime: 'h:mm:ss a ZZZZ'
};

export enum API_PATH {
	FullApplication = 'FullApplication',
	Refi = 'Refi',
	Purchase = 'Purchase',
	ApprovalInPrinciple = 'InPrinciple'
}

export const JOURNEY_MAPPINGS: Journey[] = [
	{
		journeyType: JourneyType.FullApplication,
		returnUrl: CONSTANTS.LOANAPP_CLIENT_LANDING_URL,
		apiPath: API_PATH.FullApplication
	},
	{
		journeyType: JourneyType.Refi,
		returnUrl: CONSTANTS.REFI_PATH,
		apiPath: API_PATH.Refi
	},
	{
		journeyType: JourneyType.Purchase,
		returnUrl: CONSTANTS.PURCHASE_PATH,
		apiPath: API_PATH.Purchase
	},
	{
		journeyType: JourneyType.ApprovalInPrinciple,
		returnUrl: CONSTANTS.AIP_LANDING_PATH,
		apiPath: API_PATH.ApprovalInPrinciple
	}
];
