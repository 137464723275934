/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JourneyType } from '../../enums/app.enums';
import { SubJourneys } from '../application-data/typings/application-data';
import { SharedFlagsStore } from './shared-flags.store';
@Injectable({ providedIn: 'root' })
export class SharedFlagsService {
	constructor(private store: SharedFlagsStore) {}

	select(key: string): Observable<any> {
		return this.store.select(key);
	}
	get isPropertyModalOpened(): boolean {
		return this.store.getStoreValue().isPropertyModalOpened;
	}

	set isPropertyModalOpened(isOpened: boolean) {
		this.store.updateSharedFlag('isPropertyModalOpened', isOpened);
	}

	get isPropertyInLoanServiceability(): boolean {
		return this.store.getStoreValue().isPropertyInLoanServiceability as boolean;
	}

	set isPropertyInLoanServiceability(isInLoanServiceability: boolean) {
		this.store.updateSharedFlag('isPropertyInLoanServiceability', isInLoanServiceability);
	}

	get propertyModalOpenedSection(): string {
		return this.store.getStoreValue().propertyModalOpenedSection;
	}

	set propertyModalOpenedSection(section: string) {
		this.store.updateSharedFlag('propertyModalOpenedSection', section);
	}

	get currentJourney(): JourneyType | undefined {
		return this.store.getStoreValue().currentJourney;
	}

	set currentJourney(journeyName: JourneyType | undefined) {
		this.store.updateSharedFlag('currentJourney', journeyName);
	}

	get subJourneys(): SubJourneys[] {
		return this.store.getStoreValue().subJourneys;
	}

	set subJourneys(journeys: SubJourneys[]) {
		this.store.updateSharedFlag('subJourneys', journeys);
	}

	get complianceCoApplicantFilterTypes(): number[] {
		return this.store.getStoreValue().complianceCoApplicantFilterTypes;
	}

	set complianceCoApplicantFilterTypes(complianceCoApplicantFilterTypes: number[]) {
		this.store.updateSharedFlag('complianceCoApplicantFilterTypes', complianceCoApplicantFilterTypes);
	}

	/**
	 * Gets the first journeyId for selected base journey.
	 * Used for LoanappX. Eventually would come from redirect URL.
	 */
	get selectedJourneyId() {
		return this.subJourneys.find((journey) => journey.baseJourneyTypeEnum === this.currentJourney)?.id;
	}
}
