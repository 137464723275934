import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { excludeKeys, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';

import { FormAreaPath, JourneyArea } from '../../typings/form-areas.types';
import { ApplicationDataState } from './typings/application-data';

const createInitialState = (): ApplicationDataState => {
	return {
		defaultSideNavItems: {
			fullApplication: []
		},
		sideNavItems: {
			fullApplication: []
		},
		navigationSteps: [],
		loanApplications: undefined
	};
};

@Injectable({ providedIn: 'root' })
export class ApplicationDataStore {
	store = createStore({ name: 'application-data' }, withProps<ApplicationDataState>(createInitialState()));

	persist = persistState(this.store, {
		key: 'application-data',
		storage: sessionStorageStrategy,
		source: () =>
			this.store.pipe(
				excludeKeys([
					'sideNavItems',
					'navigationSteps',
					'loanApplications',
					'HouseHoldAndApplicants' as keyof ApplicationDataState,
					'showRemoteValidationErrors' as keyof ApplicationDataState,
					'readyForPreload'
				])
			)
	});

	getValue(): ApplicationDataState {
		return this.store.getValue();
	}

	select(selectFunction: (state: ApplicationDataState) => any = (state) => state): Observable<any> {
		return this.store.pipe(select(selectFunction));
	}

	update(updateFunction: (state: ApplicationDataState) => ApplicationDataState) {
		this.store.update((newState: ApplicationDataState) => {
			return cloneDeep(updateFunction(newState));
		});
	}
}

export const fullAppBorrowerInterviewSideNavItem = {
	url: `${FormAreaPath.compliance}`,
	linkText: `Borrower interview`,
	titleText: `Borrower interview`,
	active: false,
	area: JourneyArea.compliance
};

export const fullAppGuarantorInterviewSideNavItem = {
	url: `${FormAreaPath.complianceGuarantor}`,
	linkText: `Guarantor interview`,
	titleText: 'Guarantor interview',
	active: false,
	area: JourneyArea.complianceGuarantor
};
