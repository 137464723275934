import { Injectable } from '@angular/core';
import { EnumObject } from '@simpology/client-components/utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';

import {
	LoanPreferencesSummaryModel,
	LoanPreferencesSummaryTransformer
} from '@app/modules/compliance/model/loan-preferences-summary.model';
import { ApplicantEmploymentModel } from '../financial-position/model/applicant-employment.model';
import { LoanServiceabilityService } from '../loan-serviceability/loan-serviceability.service';
import { BorrowingInfo } from '../loan-serviceability/model/borrowing.model';
import { LoanDetailJourneyProducts } from '../loan-serviceability/model/journey-product.model';
import { LoanInformation } from '../loan-serviceability/model/loan-information.model';
import { Product } from '../loan-serviceability/model/product.model';
import { RateToBorrower } from '../loan-serviceability/model/rate-to-borrower.model';
import { ApplicationDetailsDto } from '../setup/typings/setup';
import { CONSTANTS } from '../shared/constants/constants';
import { ApplicantRole, LoanFeature, RbaLendingPurpose } from '../shared/enums/app.enums';
import { savedSuccessfullyMessage } from '../shared/helper/util';
import { ShortApplicant } from '../shared/model/applicant.model';
import { AccessService } from '../shared/service/access.service';
import { AddressService } from '../shared/service/address.service';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { EmploymentService } from '../shared/service/employment.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { FormDataService } from '../shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '../shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '../shared/store/form-enums/form-enums.service';
import { FormStateService } from '../shared/store/form-state/form-state.service';
import { SharedFlagsService } from '../shared/store/shared-flags/shared-flags.service';
import { findPropertyFromSchema } from '../simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '../simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import {
	AdditionalCommentsCommentDTO,
	AdditionalCommentsDTO,
	AdditionalNotesDTO,
	AdditionalRepaymentsDTO,
	Address,
	AssociatedProductsDTO,
	CoApplicantInterviewDTO,
	CoBorrowerBenefitDTO,
	ComplianceCommentsDTO,
	ComplianceNotesBrokerAttestationDTO,
	HomeLoanIntroducerDTO,
	IdAndAddressId,
	InterestInAdvanceDTO,
	InterestOnlyDTO,
	InterviewDeclarationDTO,
	InterviewDTO,
	InterviewPersonDTO,
	LineOfCreditDTO,
	LmiDTO,
	LoanPreferenceFixedInterestDTO,
	LoanPreferenceFixedVariableInterestDTO,
	LoanPreferenceOffsetAccountDTO,
	LoanPreferenceRedrawDTO,
	LoanPreferencesSummaryDTO,
	LoanPreferenceVariableInterestDTO,
	LoanPurposeConflictDTO,
	LoanPurposeObjectiveDTO,
	LoanPurposeRationaleDTO,
	LoanRequirementsDTO,
	PersonApplicantDTO,
	PrincipalAndInterestDTO,
	ProductSelectionComplianceDTO,
	PropertyDTO,
	RefinancingAndConsolidationDTO,
	RetirementDTO,
	SignificantChangeDocumentChecklistDTO,
	SignificantChangeDTO
} from '../typings/api';
import { AdditionalCommentsModel, AdditionalCommentsTransformer } from './model/additional-comments.model';
import { AdditionalRepaymentsModel, AdditionalRepaymentsTransformer } from './model/additional-repayments.model';
import { AdditionalNotesModel, AdditionalNotesTransformer } from './model/addtional-notes.model';
import { AttestationModel } from './model/attestation.model';
import { BackUpPlanTransformer } from './model/backup-plan.model';
import { CoApplicantInterviewDetails, CoApplicantInterviewTransformer } from './model/co-applicant-interview.model';
import { CoBorrowerBenefitModel, CoBorrowerBenefitTransformer } from './model/co-borrower-model';
import { ComplianceComments, ComplianceCommentsTransformer } from './model/compliance-comments.model';
import { ComplianceCreditCardTransformer } from './model/compliance-credit-card.model';
import {
	NotesBrokerAttestation,
	NotesBrokerAttestationTransformer
} from './model/compliance-notesBrokerAttestation.model';
import { DocumentChecklistModel, DocumentChecklistTransformer } from './model/document-checklist.model';
import { FixedInterestModel, FixedInterestTransformer } from './model/fixed-interest.model';
import { FixedVariableInterestModel, FixedVariableInterestTransformer } from './model/fixed-variable-interest.model';
import { HomeLandlordInsuranceTransformer } from './model/home-landlord-insurance.model';
import { HomeLoanInsuranceModel, HomeLoanIntroducerTransformer } from './model/homeloan-introducer.model';
import { InterestInAdvanceModel, InterestInAdvanceTransformer } from './model/interest-in-advice.model';
import { InterestOnlyModel, InterestOnlyTransformer } from './model/interest-only.model';
import { InterviewDeclarationModel, InterviewDeclarationTransformer } from './model/interview-declaration.model';
import {
	AnticipatedChanges,
	Interview,
	InterviewTransformer,
	MitigantFactor,
	SignificantChange
} from './model/interview.model';
import { LenderSalesChannel, LenderSalesChannelModelTransformer } from './model/lender-sales-channel.model';
import { LendersMortgageTransformer } from './model/lenders-mortgage-insurance.model';
import { LineOfCreditModel, LineOfCreditTransformer } from './model/line-of-credit.model';
import { LoanPurposeObjectiveModel, LoanPurposeObjectiveTransformer } from './model/loan-puprose-objective.model';
import { LoanPurposeConflictsModel, LoanPurposeConflictsTransformer } from './model/loan-purpose-conflicts.model';
import { LoanPurposePreferredFeatureTransformer } from './model/loan-purpose-preferred-feature';
import { LoanPurposeRationaleModel, LoanPurposeRationaleTransformer } from './model/loan-purpose-rationale.model';
import { OffsetModel, OffSetTransformer } from './model/offset.model';
import { PrincipleAndInterestModel, PrincipleAndInterestTransformer } from './model/principle-and-Interest.model';
import { ProductInfo, ProductSelectionTransformer, SelectedProductModel } from './model/product-selection';
import { RedrawModel, RedrawTransformer } from './model/redraw.model';
import {
	RefinancingAndConsolidationModel,
	RefinancingAndConsolidationTransformer
} from './model/refinancing-and-consolidation.model';
import { RetirementDetailsTransformer, RetirementModel, RetirementResultDTO } from './model/retirement.model';
import { SignificantChangeModel, SignificantChangeTransformer } from './model/significant-changes.model';
import { VariableInterestModel, VariableInterestTransformer } from './model/variable-interest.model';

@Injectable({ providedIn: 'root' })
export class ComplianceService extends BaseJourneyService {
	statePrefix = 'compliance';
	constructor(
		private accessService: AccessService,
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private formEnumQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private formStateService: FormStateService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private addressService: AddressService,
		private loanServiceabilityService: LoanServiceabilityService,
		private employmentService: EmploymentService,
		private sharedFlagService: SharedFlagsService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(compliaceSchema?: FormlyApiProperty): Observable<boolean> {
		let hideGurantor = false;
		const retirementDetailsSubSection = findPropertyFromSchema(compliaceSchema, 'retirement.retirementDetails');
		if (retirementDetailsSubSection?.templateOptions?.handler === CONSTANTS.HIDE_GUARANTOR) {
			hideGurantor = true;
		}

		const coBorrowerFilterField = findPropertyFromSchema(
			compliaceSchema,
			'interview.coApplicantInterview.coBorrowerFilter'
		);
		if (coBorrowerFilterField?.templateOptions?.defaultValue) {
			this.sharedFlagService.complianceCoApplicantFilterTypes = (
				coBorrowerFilterField?.templateOptions?.defaultValue as string
			)
				.split(',')
				.map((value) => Number(value));
		}

		return this.fetchInterview().pipe(
			switchMap((interview: Interview[]) => {
				const lenderSalesChannels = interview?.map((item) => LenderSalesChannelModelTransformer.fromPayload(item));
				const isHLIGManuallyCompleted = !!lenderSalesChannels?.[0]?.isHLIGManuallyCompleted;
				this.formStateService.updateFormState({ isHLIGManuallyCompleted });
				this.simpFormlyHandlerService.upsertToStateWithData(`lenderSalesChannel`, lenderSalesChannels);

				if (isHLIGManuallyCompleted) {
					return of(true);
				}

				const loanInformation$ = this.loanServiceabilityService.fetchLoanInformation().pipe(share());
				const loanIds$ = this.fetchLoanIds(loanInformation$);
				const borrowings$ = this.fetchBorrowings(loanIds$);
				const products$ = this.fetchProducts(loanIds$);
				const rates$ = this.fetchRatesToBorrower(loanIds$);
				const loanJourneySelectedProducts$ = this.fetchAllProducts().pipe(share());
				const interviewPersons$ = this.fetchInterviewPersons().pipe(share());

				return forkJoin({
					borrowings: borrowings$,
					products: products$,
					rates: rates$,
					refinancingAndConsolidation: this.fetchRefinancingAndConsolidation(borrowings$),
					retirementDetails: this.fetchRetirementDetails(hideGurantor, products$, compliaceSchema),
					coApplicantInterview: this.fetchCoApplicantInterview(),
					productFeatures: this.fetchProductFeatures(),
					repayments: this.fetchRepayments(),
					summary: this.fetchLoanPreferencesSummary(),
					commentsDetails: this.fetchComments(),
					applicationNotesDetails: this.fetchNotesBrokerAttestation(),
					documentChecklist: this.fetchDocumentChecklist(),
					attestation: this.fetchAttestation(),
					additionalNotesDetails: this.fetchAdditionalComments(),
					customerDetails: this.fetchCustomerDetails(interviewPersons$),
					coBorrowerSubstantialBenefit: this.fetchCoBorrowerBenefit(),
					rationaleForFeatureSelection: this.fetchRationaleFeatures(borrowings$, products$),
					homeLoanIntroducerApplication: this.fetchHomeLoanIntroducerApplication(),
					productSelection: this.fetchProductSelection(
						loanInformation$,
						loanJourneySelectedProducts$,
						borrowings$,
						products$
					),
					customerObjective: this.fetchCustomerObjective(),
					conflicts: this.fetchConflicts(),
					significantChangeDetails: this.fetchAllSignificantChanges(interviewPersons$),
					interviewDeclarations: this.fetchAllInterviewDeclarations(interviewPersons$)
				}).pipe(
					map(
						({
							borrowings,
							products,
							rates,
							refinancingAndConsolidation,
							retirementDetails,
							coApplicantInterview,
							productFeatures,
							repayments,
							summary,
							commentsDetails,
							applicationNotesDetails,
							documentChecklist,
							attestation,
							additionalNotesDetails,
							customerDetails,
							coBorrowerSubstantialBenefit,
							rationaleForFeatureSelection,
							homeLoanIntroducerApplication,
							productSelection,
							customerObjective,
							conflicts,
							significantChangeDetails,
							interviewDeclarations
						}) => {
							this.setPrimaryLendingPurpose(borrowings, interview, applicationNotesDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(`interviewDetails`, interview);
							this.simpFormlyHandlerService.upsertToStateWithData(`borrowingPurpose`, interview);
							this.simpFormlyHandlerService.upsertToStateWithData(`coApplicantInterview`, coApplicantInterview);
							this.simpFormlyHandlerService.upsertToStateWithData(
								`refinancingAndConsolidation`,
								refinancingAndConsolidation
							);
							this.simpFormlyHandlerService.upsertToStateWithData(`retirementDetails`, retirementDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(`productFeatures`, productFeatures);
							this.simpFormlyHandlerService.upsertToStateWithData(`repayments`, repayments);
							this.simpFormlyHandlerService.upsertToStateWithData(`summary`, summary);
							this.simpFormlyHandlerService.upsertToStateWithData(`commentsDetails`, commentsDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(`customerDetails`, customerDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(`applicationNotesDetails`, applicationNotesDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(`documentChecklist`, documentChecklist);
							this.simpFormlyHandlerService.upsertToStateWithData(`attestation`, attestation);
							this.simpFormlyHandlerService.upsertToStateWithData(`additionalNotesDetails`, additionalNotesDetails);
							this.simpFormlyHandlerService.upsertToStateWithData(
								`coBorrowerSubstantialBenefit`,
								coBorrowerSubstantialBenefit
							);
							const loanPurposeAmountAndPreferredFeatures = LoanPurposePreferredFeatureTransformer.fromPayload(
								borrowings,
								products,
								rates,
								this.formEnumQuery
							);
							this.simpFormlyHandlerService.upsertToStateWithData(`loanPurposeAmountAndPreferredFeatures`, [
								loanPurposeAmountAndPreferredFeatures
							]);
							this.simpFormlyHandlerService.upsertToStateWithData(
								`rationaleForFeatureSelection`,
								rationaleForFeatureSelection
							);
							this.simpFormlyHandlerService.upsertToStateWithData(
								`homeLoanIntroducerApplication`,
								homeLoanIntroducerApplication
							);
							this.simpFormlyHandlerService.upsertToStateWithData(`productSelection`, productSelection);
							this.simpFormlyHandlerService.upsertToStateWithData(`customerObjective`, customerObjective);
							this.simpFormlyHandlerService.upsertToStateWithData(`conflicts`, conflicts);
							significantChangeDetails.forEach((significantChange, index) => {
								this.simpFormlyHandlerService.upsertToStateWithData(
									`significantChangeDetails-${index}`,
									significantChange
								);
							});
							interviewDeclarations.forEach((interviewDeclaration, index) => {
								this.simpFormlyHandlerService.upsertToStateWithData(
									`interviewDeclarations-${index}`,
									interviewDeclaration
								);
							});
						}
					),
					switchMap(() => {
						return this.fetchAssociatedProducts();
					})
				);
			}),
			map(() => true)
		);
	}

	setPrimaryLendingPurpose(
		borrowings: BorrowingInfo[],
		interview: Interview[],
		applicationNotesDetails: NotesBrokerAttestation[]
	) {
		const loanPurposePurchaseOrRefinance = [
			RbaLendingPurpose.PurchaseExistingDwelling,
			RbaLendingPurpose.PurchaseNewDwelling,
			RbaLendingPurpose.PurchaseOther,
			RbaLendingPurpose.Refinance
		];

		const applicationDetails = this.formDataService.getStateData<ApplicationDetailsDto>('details');
		let isLoanPurposeRefinanceOrPurchase = false;
		if (applicationDetails.length > 0) {
			isLoanPurposeRefinanceOrPurchase =
				loanPurposePurchaseOrRefinance.includes(applicationDetails[0].primaryLendingPurpose as number) ||
				borrowings?.some((x) =>
					x.lendingPurpose?.some((y) => loanPurposePurchaseOrRefinance.includes(y.primaryLendingPurpose as number))
				);
		}

		if (interview.length > 0) {
			interview[0].isLoanPurposeRefinanceOrDebtConsolidation = isLoanPurposeRefinanceOrPurchase;
		}
		if (applicationNotesDetails.length > 0) {
			applicationNotesDetails[0].isLoanPurposeRefinanceOrDebtConsolidation = isLoanPurposeRefinanceOrPurchase;
		}
	}

	deleteAdditionalComment(commentId: number) {
		return this.delete(`Interview/AdditionalComment/${this.applicationDataQuery.applicationId()}/${commentId}`);
	}

	deleteAnticipatedChanges(changeId: number) {
		return this.delete(`Interview/SignificantChange/${this.applicationDataQuery.applicationId()}/${changeId}`);
	}

	deleteMitigantFactors(mitigantFactorId: number) {
		return this.delete(`Interview/MitigantFactor/${this.applicationDataQuery.applicationId()}/${mitigantFactorId}`);
	}

	fetchAdditionalComments(): Observable<AdditionalCommentsModel[]> {
		const model: AdditionalCommentsModel[] = [];
		return this.getCustom(`Interview/AdditionalComment/${this.applicationDataQuery.applicationId()}`).pipe(
			map((comments: AdditionalCommentsDTO) => {
				const details = this.formDataService.getStateData<ApplicationDetailsDto>('details');

				model.push(
					AdditionalCommentsTransformer.fromPayload(
						comments,
						this.accessService.getUserId(),
						details[0].accreditedLoanWriterId
					)
				);
				return model;
			})
		);
	}

	fetchAssociatedProducts(): Observable<AssociatedProductsDTO> {
		return forkJoin([
			this.getCustom(
				`Interview/AssociatedProducts/${this.applicationDataQuery.applicationId()}`
			) as Observable<AssociatedProductsDTO>,
			this.getCustom(`LendersMortgageInsurance/${this.applicationDataQuery.applicationId()}`) as Observable<LmiDTO>,
			this.loanServiceabilityService.fetchSplitLoans()
		]).pipe(
			map(([product, lmi, loans]: [AssociatedProductsDTO, LmiDTO, LoanRequirementsDTO[]]) => {
				this.simpFormlyHandlerService.upsertToStateWithData(`lendersMortgageInsurance`, [
					LendersMortgageTransformer.fromPayload(product, lmi)
				]);
				this.simpFormlyHandlerService.upsertToStateWithData(`homeLandlordInsurance`, [
					HomeLandlordInsuranceTransformer.fromPayload(product)
				]);
				this.simpFormlyHandlerService.upsertToStateWithData(`creditCard`, [
					ComplianceCreditCardTransformer.fromPayload(
						product,
						loans.some((loan) =>
							loan.features.some((feature) => feature.selected && feature.featureEnum === LoanFeature.CreditCard)
						)
					)
				]);
				this.simpFormlyHandlerService.upsertToStateWithData(`backUpPlan`, [BackUpPlanTransformer.fromPayload(product)]);
				return product;
			})
		);
	}

	fetchAttestation(): Observable<AttestationModel[]> {
		return this.getCustom(`Interview/Attestation/${this.applicationDataQuery.applicationId()}`).pipe(
			map((attestation) => [attestation] as AttestationModel[]),
			catchError(() => [[{}] as AttestationModel[]])
		);
	}

	fetchRefinancingAndConsolidation(
		borrowings$: Observable<BorrowingInfo[]>
	): Observable<RefinancingAndConsolidationModel[]> {
		const refinancingAndConsolidation$: Observable<RefinancingAndConsolidationDTO> = this.getCustom(
			`RefinancingAndConsolidation/${this.applicationDataQuery.applicationId()}`
		).pipe(catchError(() => of({})));

		return forkJoin([refinancingAndConsolidation$, borrowings$]).pipe(
			map(([refinancingAndConsolidation, borrowings]) => [
				RefinancingAndConsolidationTransformer.fromPayload(refinancingAndConsolidation, borrowings)
			])
		);
	}

	fetchInterviewDeclaration(person: InterviewPersonDTO): Observable<InterviewDeclarationModel[]> {
		return this.getCustom(
			`Interview/InterviewDeclaration/${this.applicationDataQuery.applicationId()}/${person.targetType}/${
				person.targetId
			}`
		).pipe(
			map((declaration: InterviewDeclarationDTO) => [
				InterviewDeclarationTransformer.fromPayload(declaration, person.fullName)
			]),
			catchError(() => [[{}] as InterviewDeclarationModel[]])
		);
	}

	fetchInterviewPersons(): Observable<InterviewPersonDTO[]> {
		return this.getCustom(`Interview/InterviewPersons/${this.applicationDataQuery.applicationId()}`).pipe(
			map((result: InterviewPersonDTO[]) => {
				this.formDataService.storeInterviewPersons(result);
				return result;
			})
		);
	}

	fetchDocumentChecklist(): Observable<DocumentChecklistModel[]> {
		return this.getCustom(`Interview/DocumentChecklist/${this.applicationDataQuery.applicationId()}`).pipe(
			map((checklist: SignificantChangeDocumentChecklistDTO) => [DocumentChecklistTransformer.fromPayload(checklist)]),
			catchError(() => [[{}] as DocumentChecklistModel[]])
		);
	}

	fetchCoApplicantInterview(): Observable<CoApplicantInterviewDetails[]> {
		return this.getCustom(`CoApplicantInterview/${this.applicationDataQuery.applicationId()}`).pipe(
			map((coApplicantInterview: CoApplicantInterviewDTO) =>
				CoApplicantInterviewTransformer.fromPayload(
					coApplicantInterview,
					this.applicationDataQuery.getPersonShortApplicants(),
					this.sharedFlagService.complianceCoApplicantFilterTypes
				)
			),
			catchError(() => of(this.getCoApplicantInitialState()))
		);
	}

	fetchInterview(): Observable<Interview[]> {
		return this.getCustom(`Interview/${this.applicationDataQuery.applicationId()}`).pipe(
			shareReplay(1),
			map((interview: InterviewDTO) => {
				const applicants = this.formEnumQuery.getOptions('Applicants');
				return [InterviewTransformer.fromPayload(interview, applicants)];
			}),
			catchError(() => of(this.getInterviewInitialState()))
		);
	}

	fetchSignificantChanges(person: InterviewPersonDTO): Observable<SignificantChangeModel[]> {
		if (!person.targetId) {
			return of([]);
		}

		return this.getCustom(
			`Interview/SignificantChange/${this.applicationDataQuery.applicationId()}/${person.targetType}/${person.targetId}`
		).pipe(
			map((change: SignificantChangeDTO) => [SignificantChangeTransformer.fromPayload(change, person)]),
			catchError(() => {
				// In case not found create a new record
				return of([
					{
						applicantName: person.fullName,
						age: person.age,
						ageGreaterThan: person.age ? person.age >= 60 : undefined
					} as SignificantChangeModel
				]);
			})
		);
	}

	saveAdditionalComments(comments: AdditionalCommentsCommentDTO): Observable<any> {
		return this.postCustom(`Interview/AdditionalComment/${this.applicationDataQuery.applicationId()}`, comments);
	}

	saveAssociatedProducts(products: AssociatedProductsDTO): Observable<any> {
		return this.postCustom(`Interview/AssociatedProducts/${this.applicationDataQuery.applicationId()}`, products);
	}

	saveInterview(interview: InterviewDTO): Observable<IdAndAddressId> {
		return this.postCustom('Interview', interview).pipe(
			tap(() => {
				this.addressService.fetchAllAddress();
			})
		);
	}

	resetBorrowerInterviewData() {
		return this.delete(`Interview/DeleteHLIGUserData/${this.applicationDataQuery.applicationId()}`);
	}

	saveLenderSalesChannel(model: LenderSalesChannel): Observable<number> {
		const applicationId = this.applicationDataQuery.applicationId();
		const payload = LenderSalesChannelModelTransformer.toPayload(model, applicationId);
		return <Observable<number>>this.postCustom('Interview/LenderSalesChannel', payload);
	}

	saveInterviewAttestation(lenderConfirmation: AttestationModel): Observable<any> {
		lenderConfirmation.applicationId = this.applicationDataQuery.applicationId();
		return this.postCustom(`Interview/Attestation`, lenderConfirmation);
	}

	saveRefinanceAndConsolidation(refinancingAndConsolidation: RefinancingAndConsolidationDTO): Observable<any> {
		return this.postCustom(`RefinancingAndConsolidation`, refinancingAndConsolidation).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Refinance reasons'));
			})
		);
	}

	saveInterviewDeclaration(declaration: InterviewDeclarationDTO): Observable<any> {
		return this.postCustom('Interview/InterviewDeclaration', declaration);
	}

	saveCoApplicantInterview(coApplicantInterviewModel: CoApplicantInterviewDetails): Observable<any> {
		return this.postCustom(
			`CoApplicantInterview`,
			CoApplicantInterviewTransformer.toPayload(this.applicationDataQuery.applicationId(), coApplicantInterviewModel)
		).pipe(
			map((coApplicantInterviewResponce: CoApplicantInterviewDTO) => {
				// if only we save new nodes , we need to set the ids for existing state
				if (coApplicantInterviewModel.ids?.some((id) => id === CONSTANTS.NEW_ID)) {
					const coApplicantInterviewDetails = CoApplicantInterviewTransformer.fromPayload(
						coApplicantInterviewResponce,
						this.applicationDataQuery.getPersonShortApplicants(),
						this.sharedFlagService.complianceCoApplicantFilterTypes
					);
					this.simpFormlyHandlerService.upsertToStateWithData(`coApplicantInterview`, coApplicantInterviewDetails);
				}
				this.toastr.success(savedSuccessfullyMessage('Co-applicant interview'));
			})
		);
	}

	saveComments(comments: ComplianceCommentsDTO): Observable<any> {
		return this.postCustom('Comments', comments);
	}

	saveDocumentChecklist(documentChecklist: SignificantChangeDocumentChecklistDTO): Observable<any> {
		return this.postCustom(`Interview/DocumentChecklist`, documentChecklist);
	}

	saveNotesAttestation(notes: ComplianceNotesBrokerAttestationDTO): Observable<any> {
		return this.postCustom('ApplicationNotes', notes);
	}

	saveRetirement(retirementFormModel: RetirementModel): Observable<number> {
		const payload = RetirementDetailsTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			retirementFormModel
		);
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		const addresses =
			retirementFormModel.retirementDetails[0].assetsList?.map((x) => {
				return {
					id: x.propetyId as number,
					address: x.assetAddress as Address
				};
			}) ?? [];

		return this.postCustom(`Retirement`, payload).pipe(
			map((retirementId: number) => {
				const retirementResult: RetirementResultDTO = {
					retirement: payload,
					propertyAssets: addresses
				};
				retirementFormModel = RetirementDetailsTransformer.fromPayload(retirementResult, applicants);
				this.toastr.success(savedSuccessfullyMessage('Retirement'));
				return retirementId;
			})
		);
	}

	saveSignificantChanges(payload: SignificantChangeDTO) {
		return this.postCustom(`/Interview/SignificantChange`, payload);
	}

	saveVariableInterest(variableInteresetModel: VariableInterestModel): Observable<void> {
		const payload = VariableInterestTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			variableInteresetModel
		);
		return this.postCustom(`LoanPreferences/VariableInterestRate`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Variable interest'));
			})
		);
	}

	saveFixedInterest(fixedInteresetModel: FixedInterestModel): Observable<void> {
		const payload = FixedInterestTransformer.toPayload(this.applicationDataQuery.applicationId(), fixedInteresetModel);
		return this.postCustom(`LoanPreferences/FixedInterestRate`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Fixed variable interest'));
			})
		);
	}

	saveFixedVariableInterest(fixedVariableInteresetModel: FixedVariableInterestModel): Observable<void> {
		const payload = FixedVariableInterestTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			fixedVariableInteresetModel
		);
		return this.postCustom(`LoanPreferences/FixedAndVariableInterestRate`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Fixed and variable interest'));
			})
		);
	}

	saveOffset(offsetModel: OffsetModel): Observable<void> {
		const payload = OffSetTransformer.toPayload(this.applicationDataQuery.applicationId(), offsetModel);
		return this.postCustom(`LoanPreferences/OffsetAccount`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Offset'));
			})
		);
	}

	saveRedraw(redrawModel: RedrawModel): Observable<void> {
		const payload = RedrawTransformer.toPayload(this.applicationDataQuery.applicationId(), redrawModel);
		return this.postCustom(`LoanPreferences/Redraw`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Redraw'));
			})
		);
	}

	savePrincipleAndInterest(principleAndInteresetModel: PrincipleAndInterestModel): Observable<void> {
		const payload = PrincipleAndInterestTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			principleAndInteresetModel
		);
		return this.postCustom(`LoanPreferences/PrincipalAndInterest`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Principal and interest'));
			})
		);
	}

	saveInterestOnly(interestOnly: InterestOnlyModel): Observable<void> {
		const payload = InterestOnlyTransformer.toPayload(this.applicationDataQuery.applicationId(), interestOnly);
		return this.postCustom(`LoanPreferences/InterestOnly`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Interest only'));
			})
		);
	}

	saveInterestInAdvance(interestInAdvance: InterestInAdvanceModel): Observable<void> {
		const payload = InterestInAdvanceTransformer.toPayload(
			this.applicationDataQuery.applicationId(),
			interestInAdvance
		);
		return this.postCustom(`LoanPreferences/InterestInAdvance`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Interest in advice'));
			})
		);
	}

	saveLineOfCredit(lineOfCredit: LineOfCreditModel): Observable<void> {
		const payload = LineOfCreditTransformer.toPayload(this.applicationDataQuery.applicationId(), lineOfCredit);
		return this.postCustom(`LoanPreferences/LineOfCredit`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Line of credit'));
			})
		);
	}

	saveAdditionalRepayments(repayments: AdditionalRepaymentsModel): Observable<void> {
		const payload = AdditionalRepaymentsTransformer.toPayload(this.applicationDataQuery.applicationId(), repayments);
		return this.postCustom(`LoanPreferences/AdditionalRepayments`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Additional repayments'));
			})
		);
	}

	saveLoanPreferencesSummary(summary: LoanPreferencesSummaryModel): Observable<void> {
		const payload = LoanPreferencesSummaryTransformer.toPayload(this.applicationDataQuery.applicationId(), summary);
		return this.postCustom(`LoanPreferences/Summary`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Summary'));
			})
		);
	}

	saveHomeLoanIntroducer(model: HomeLoanInsuranceModel): Observable<number> {
		const payload = HomeLoanIntroducerTransformer.toPayload(model, this.applicationDataQuery.applicationId());
		return this.postCustom(`interview/HomeLoanIntroducer`, payload).pipe(
			tap(() => this.toastr.success(savedSuccessfullyMessage('Home loan introducer'))),
			map((res: number) => {
				return res;
			})
		);
	}

	saveCoBorrowerBenefits(model: CoBorrowerBenefitModel): Observable<number> {
		const payload = CoBorrowerBenefitTransformer.toPayload(model, this.applicationDataQuery.applicationId());
		return this.postCustom(`interview/CoBorrowerBenefit`, payload).pipe(
			tap(() => this.toastr.success(savedSuccessfullyMessage('Co-borrower benefits')))
		);
	}

	saveSelectedProductExplained(model: SelectedProductModel): Observable<void> {
		const payload = ProductSelectionTransformer.toPayload(model);
		return this.postCustom(
			`interview/LoanPurposeSelectedProducts/${this.applicationDataQuery.applicationId()}`,
			payload
		).pipe(tap(() => this.toastr.success(savedSuccessfullyMessage('Product selection'))));
	}

	saveLoanPurposeCustomerObjective(model: LoanPurposeObjectiveModel): Observable<void> {
		const payload = LoanPurposeObjectiveTransformer.toPayload(model);
		return this.postCustom(`interview/LoanPurposeObjective/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			tap(() => this.toastr.success(savedSuccessfullyMessage('Loan purpose objective')))
		);
	}

	saveLoanPurposeRationale(model: LoanPurposeRationaleModel): Observable<void> {
		const payload = LoanPurposeRationaleTransformer.toPayload(model);
		return this.postCustom(`interview/LoanPurposeRationale/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			tap(() => this.toastr.success(savedSuccessfullyMessage('Loan purpose rationale')))
		);
	}

	saveLoanPurposeConflicts(model: LoanPurposeConflictsModel): Observable<void> {
		const payload = LoanPurposeConflictsTransformer.toPayload(model);
		return this.postCustom(`interview/LoanPurposeConflict/${this.applicationDataQuery.applicationId()}`, payload).pipe(
			tap(() => this.toastr.success(savedSuccessfullyMessage('Loan purpose conflicts')))
		);
	}

	saveAdditionalNotes(repayments: AdditionalNotesModel): Observable<void> {
		const payload = AdditionalNotesTransformer.toPayload(this.applicationDataQuery.applicationId(), repayments);
		return this.postCustom(`LoanPreferences/PreferencesAdditionalNotes`, payload).pipe(
			map(() => {
				this.toastr.success(savedSuccessfullyMessage('Additional notes'));
			})
		);
	}

	fetchAllProducts(): Observable<LoanDetailJourneyProducts[]> {
		return this.loanServiceabilityService.fetchLoanJourneyProducts().pipe(
			shareReplay(1),
			map((res) => {
				return res.map((x) => {
					return {
						...x,
						journeyProducts: x.journeyProducts?.filter((y) => y.selected) ?? []
					};
				});
			})
		);
	}

	fetchProductSelection(
		loanInformation$: Observable<LoanInformation>,
		loanJourneySelectedProducts$: Observable<LoanDetailJourneyProducts[]>,
		borrowings$: Observable<BorrowingInfo[]>,
		products$: Observable<Product[]>
	): Observable<SelectedProductModel[]> {
		return forkJoin({
			loanInformation: loanInformation$,
			borrowings: borrowings$,
			products: products$,
			selectedProduct: this.fetchLoanPurposeSelectedProductTexts(loanJourneySelectedProducts$),
			loanJourneyProducts: loanJourneySelectedProducts$,
			productsExplained: this.fetchSelectedProducts()
		}).pipe(
			map((result) => {
				result.selectedProduct = result.selectedProduct.map((x) => {
					return {
						...x,
						selectedProductExplained:
							result.productsExplained?.length > 0
								? result.productsExplained.find(
										(y) =>
											y.selectedProductId === x?.selectedProductId &&
											y.selectedProductVariationId === x?.selectedProductVariationId
								  )?.selectedProductExplained
								: false
					};
				});

				const productNames: ProductInfo[] =
					result.loanJourneyProducts
						.filter((x) => !!x.journeyProducts[0]?.productId)
						.map((x) => {
							return {
								loanId: x.loanDetailId,
								productId: x.journeyProducts[0].productId,
								productName: x.journeyProducts[0].productName,
								productVariationId: x.journeyProducts[0].productVariationId
							};
						}) ?? [];
				return [
					ProductSelectionTransformer.fromPayload(
						result.loanInformation,
						result.borrowings,
						result.products,
						productNames,
						result.selectedProduct,
						this.formEnumQuery
					)
				];
			})
		);
	}

	private fetchLoanIds(loanInformation$: Observable<LoanInformation>): Observable<number[]> {
		return loanInformation$.pipe(
			map((loanInfo) =>
				loanInfo?.isSplitLoan
					? (loanInfo?.loanSplits ?? []).map((split) => split.loanDetailId as number)
					: [loanInfo.loanDetailId as number]
			),
			tap((loanIds) =>
				loanIds.forEach((loanId) => {
					this.loanServiceabilityService.loadRepaymentFrequencies(loanId);
					this.loadProductFeatures(loanId);
				})
			),
			share()
		);
	}

	private fetchBorrowings(loanIds$: Observable<number[]>): Observable<BorrowingInfo[]> {
		return loanIds$.pipe(
			switchMap((loanIds: number[]) =>
				forkJoin(loanIds.map((loanId) => this.loanServiceabilityService.fetchBorrowing(loanId)))
			),
			share()
		);
	}

	private fetchProducts(loanIds$: Observable<number[]>): Observable<Product[]> {
		return loanIds$.pipe(
			switchMap((loanIds: number[]) =>
				forkJoin(loanIds.map((loanId) => this.loanServiceabilityService.fetchProductInfo(loanId)))
			),
			share()
		);
	}

	private fetchRatesToBorrower(loanIds$: Observable<number[]>): Observable<RateToBorrower[]> {
		return loanIds$.pipe(
			switchMap((loanIds: number[]) =>
				forkJoin(loanIds.map((loanId) => this.loanServiceabilityService.fetchLoanRateToBorrower(loanId)))
			),
			share()
		);
	}

	private fetchAllSignificantChanges(
		persons$: Observable<InterviewPersonDTO[]>
	): Observable<SignificantChangeModel[][]> {
		return persons$.pipe(
			switchMap((persons) =>
				forkJoin(persons.map((person) => this.fetchSignificantChanges(person))).pipe(defaultIfEmpty([]))
			)
		);
	}

	private fetchAllInterviewDeclarations(
		persons$: Observable<InterviewPersonDTO[]>
	): Observable<InterviewDeclarationModel[][]> {
		return persons$.pipe(
			switchMap((persons) =>
				forkJoin(persons.map((person) => this.fetchInterviewDeclaration(person))).pipe(defaultIfEmpty([]))
			)
		);
	}

	private fetchAllEmployments(
		shortApplicants: ShortApplicant[],
		complianceSchema?: FormlyApiProperty
	): Observable<ApplicantEmploymentModel[][]> {
		return forkJoin(
			shortApplicants.map((applicant) =>
				this.employmentService
					.fetchEmploymentsForApplicant(applicant.id, complianceSchema)
					.pipe(catchError(() => of([])))
			)
		).pipe(defaultIfEmpty([]));
	}

	/**
	 * This method gets the product features and updates them in the form enums.
	 * This method was added after removing the fetchLoanInformationOld call from the compliance area since it was part of the old API call.
	 * The enum values in form seem no longer in use so this method can be removed later if confirmed there are no usages.
	 * Reference Jira - TAMA5-18643
	 * @deprecated
	 */
	private loadProductFeatures(loanId: number): void {
		this.loanServiceabilityService.fetchProductFeatures(loanId).subscribe((features) => {
			const loanFeatures: EnumObject[] = features.map((feature) => {
				return {
					id: feature.featureEnum,
					label: feature.featureName
				};
			});

			this.formEnumsService.updateFormEnums(`ProductFeatures-${loanId}`, loanFeatures);
		});
	}

	private fetchRetirementDetails(
		hideGuarantor: boolean,
		products$: Observable<Product[]>,
		complianceSchema?: FormlyApiProperty
	) {
		let personApplicants = this.applicationDataQuery.getPersonShortApplicants();

		return forkJoin({
			retirement: this.fetchRetirement().pipe(catchError(() => of(null))),
			products: products$,
			propertyAssets: this.fetchProperties().pipe(catchError(() => of([]))),
			allApplicantsEmployments: this.fetchAllEmployments(personApplicants, complianceSchema)
		}).pipe(
			map((result) => {
				const targetDate = this.applicationDataQuery.getApplicationDetails().targetDate;
				const maxLoanPeriodInYears = this.getMaxLoanPeriodFromProducts(result.products) / 12;

				if (hideGuarantor) {
					personApplicants = personApplicants.filter((x) => x.applicantRole !== ApplicantRole.Guarantor);
				}
				return RetirementDetailsTransformer.fromPayload(result, personApplicants, targetDate, maxLoanPeriodInYears)
					.retirementDetails;
			})
		);
	}

	private getMaxLoanPeriodFromProducts(products: Product[]): number {
		return Math.max(...products.map((x) => x.loanPeriod || 0));
	}

	private fetchProductFeatures() {
		return forkJoin({
			variableInterestRate: this.fetchVariableInterest().pipe(catchError(() => of(null))),
			fixedInterestRate: this.fetchFixedInterest().pipe(catchError(() => of(null))),
			fixedAndVariableRate: this.fetchFixedAndVariableInterest(),
			offSetAccount: this.fetchOffset().pipe(catchError(() => of(null))),
			redraw: this.fetchRedraw().pipe(catchError(() => of(null)))
		}).pipe(
			map((result) => {
				return [
					{
						variableRateContainer: result.variableInterestRate ?? {},
						fixedRateContainer: result.fixedInterestRate ?? {},
						fixedAndVariableInterestRateContainer: result.fixedAndVariableRate ?? {},
						offsetContainer: result.offSetAccount ?? {},
						redrawContainer: result.redraw ?? {}
					}
				];
			})
		);
	}

	private fetchRepayments() {
		return forkJoin({
			principalAndInterest: this.fetchPrincipleAndInterest().pipe(catchError(() => of(null))),
			interestOnly: this.fetchInterestOnly().pipe(catchError(() => of(null))),
			interestInAdvance: this.fetchInterestInAdvance().pipe(catchError(() => of(null))),
			lineOfCredit: this.fetchLineOfCredit().pipe(catchError(() => of(null))),
			additionalRepayments: this.fetchAdditionalRepayments().pipe(catchError(() => of(null))),
			additionalNotes: this.fetchAdditionalNotes().pipe(catchError(() => of(null)))
		}).pipe(
			map((result) => {
				return [
					{
						principalAndInterestContainer: result.principalAndInterest ?? {},
						interestOnlyContainer: result.interestOnly ?? {},
						interestInAdvanceContainer: result.interestInAdvance ?? {},
						lineOfCreditContainer: result.lineOfCredit ?? {},
						additionalRepaymentsContainer: result.additionalRepayments ?? {},
						additionalNotesContainer: result.additionalNotes ?? {}
					}
				];
			})
		);
	}

	private fetchLoanPreferencesSummary(): Observable<LoanPreferencesSummaryModel[]> {
		return this.getCustom(`LoanPreferences/Summary/${this.applicationDataQuery.applicationId()}`).pipe(
			map((summary: LoanPreferencesSummaryDTO) => [LoanPreferencesSummaryTransformer.fromPayload(summary)])
		);
	}

	private fetchVariableInterest(): Observable<VariableInterestModel | null> {
		return this.getCustom(`LoanPreferences/VariableInterestRate/${this.applicationDataQuery.applicationId()}`).pipe(
			map((variableInterest: LoanPreferenceVariableInterestDTO) =>
				VariableInterestTransformer.fromPayload(variableInterest)
			)
		);
	}

	private fetchFixedInterest(): Observable<FixedInterestModel | null> {
		return this.getCustom(`LoanPreferences/FixedInterestRate/${this.applicationDataQuery.applicationId()}`).pipe(
			map((variableInterest: LoanPreferenceFixedInterestDTO) => FixedInterestTransformer.fromPayload(variableInterest))
		);
	}

	private fetchFixedAndVariableInterest(): Observable<FixedVariableInterestModel | null> {
		return this.getCustom(
			`LoanPreferences/FixedAndVariableInterestRate/${this.applicationDataQuery.applicationId()}`
		).pipe(
			map((variableInterest: LoanPreferenceFixedVariableInterestDTO) =>
				FixedVariableInterestTransformer.fromPayload(variableInterest)
			)
		);
	}

	private fetchOffset(): Observable<OffsetModel | null> {
		return this.getCustom(`LoanPreferences/OffsetAccount/${this.applicationDataQuery.applicationId()}`).pipe(
			map((variableInterest: LoanPreferenceOffsetAccountDTO) => OffSetTransformer.fromPayload(variableInterest))
		);
	}

	private fetchRedraw(): Observable<RedrawModel | null> {
		return this.getCustom(`LoanPreferences/Redraw/${this.applicationDataQuery.applicationId()}`).pipe(
			map((variableInterest: LoanPreferenceRedrawDTO) => RedrawTransformer.fromPayload(variableInterest))
		);
	}

	private fetchPrincipleAndInterest(): Observable<PrincipleAndInterestModel | null> {
		return this.getCustom(`LoanPreferences/PrincipalAndInterest/${this.applicationDataQuery.applicationId()}`).pipe(
			map((principalAndInterest: PrincipalAndInterestDTO) =>
				PrincipleAndInterestTransformer.fromPayload(principalAndInterest)
			)
		);
	}

	private fetchInterestOnly(): Observable<InterestOnlyModel | null> {
		return this.getCustom(`LoanPreferences/InterestOnly/${this.applicationDataQuery.applicationId()}`).pipe(
			map((interestOnly: InterestOnlyDTO) => InterestOnlyTransformer.fromPayload(interestOnly))
		);
	}

	private fetchInterestInAdvance(): Observable<InterestInAdvanceModel | null> {
		return this.getCustom(`LoanPreferences/InterestInAdvance/${this.applicationDataQuery.applicationId()}`).pipe(
			map((interestInAdvance: InterestInAdvanceDTO) => InterestInAdvanceTransformer.fromPayload(interestInAdvance))
		);
	}

	private fetchLineOfCredit(): Observable<LineOfCreditModel | null> {
		return this.getCustom(`LoanPreferences/LineOfCredit/${this.applicationDataQuery.applicationId()}`).pipe(
			map((lineOfCredit: LineOfCreditDTO) => LineOfCreditTransformer.fromPayload(lineOfCredit))
		);
	}

	private fetchComments(): Observable<ComplianceComments[]> {
		const model: ComplianceComments[] = [];
		return this.getCustom(`Comments/${this.applicationDataQuery.applicationId()}`).pipe(
			map((comments: ComplianceCommentsDTO) => {
				model.push(ComplianceCommentsTransformer.fromPayload(comments));
				return model;
			}),
			catchError(() => of(this.getCommentsInitialState()))
		);
	}

	private fetchCustomerDetails(
		interviewPersons$: Observable<InterviewPersonDTO[]>
	): Observable<{ applicantName: string; dateOfBirth: string }[]> {
		return interviewPersons$.pipe(
			map((interviewPersons) =>
				interviewPersons.map((persons) => ({
					applicantName: persons.fullName,
					dateOfBirth: persons.dateOfBirth
				}))
			)
		);
	}

	private fetchCoBorrowerBenefit(): Observable<CoBorrowerBenefitModel[] | {}[]> {
		let defaultJointApplicantValue = false;
		if (this.applicationDataQuery.getPersonShortApplicants()?.length > 1) {
			defaultJointApplicantValue = true;
		}
		if (this.applicationDataQuery.getCompanyApplicants()?.length > 0) {
			defaultJointApplicantValue = true;
		}
		if (this.applicationDataQuery.getTrustApplicants()?.length > 0) {
			defaultJointApplicantValue = true;
		}
		const defaultPayload: CoBorrowerBenefitDTO = {
			isJointApplication: defaultJointApplicantValue
		} as CoBorrowerBenefitDTO;
		return forkJoin([
			this.getCustom(`interview/CoBorrowerBenefit/${this.applicationDataQuery.applicationId()}`).pipe(
				catchError(() => of(defaultPayload))
			),
			this.getCustom(`PersonApplicant/${this.applicationDataQuery.applicationId()}`) as Observable<
				PersonApplicantDTO[]
			>,
			this.get(`Application/${this.applicationDataQuery.applicationId()}`) as Observable<ApplicationDetailsDto>
		]).pipe(
			map(
				([coBorrowersBenefit, applicants, applicationDetails]: [
					CoBorrowerBenefitDTO,
					PersonApplicantDTO[],
					ApplicationDetailsDto
				]) => {
					return [
						CoBorrowerBenefitTransformer.fromPayload(
							coBorrowersBenefit,
							applicants,
							this.formDataService.getSetupApplicants(),
							applicationDetails
						)
					];
				}
			)
		);
	}

	private fetchLoanPurposeSelectedProductTexts(
		loanJourneySelectedProducts$: Observable<LoanDetailJourneyProducts[]>
	): Observable<ProductSelectionComplianceDTO[]> {
		return loanJourneySelectedProducts$.pipe(
			switchMap((res) => {
				const products = res
					.filter((x) => !!x.journeyProducts[0]?.productId)
					.map((x) => {
						return {
							productId: x.journeyProducts[0].productId,
							productVariantId: x.journeyProducts[0].productVariationId
						};
					});
				if (products.length === 0) {
					return of([]);
				}
				const uniqueProducts = products.filter(
					(v, i, a) =>
						a.findIndex((t) => t.productId === v.productId && t.productVariantId === v.productVariantId) === i
				);

				return forkJoin(
					uniqueProducts.map((x) =>
						this.getCustom(`interview/ProductSpecification/${x.productId}/${x.productVariantId}`)
					)
				).pipe(
					map((data) =>
						data.map((x) => {
							return x as ProductSelectionComplianceDTO;
						})
					)
				);
			})
		);
	}

	private fetchRationaleFeatures(
		borrowings$: Observable<BorrowingInfo[]>,
		products$: Observable<Product[]>
	): Observable<unknown[]> {
		const rationale$: Observable<LoanPurposeRationaleDTO> = this.getCustom(
			`interview/LoanPurposeRationale/${this.applicationDataQuery.applicationId()}`
		).pipe(catchError(() => of({})));

		return forkJoin([rationale$, borrowings$, products$]).pipe(
			map(([rationale, borrowings, products]) => [
				LoanPurposeRationaleTransformer.fromPayload(
					rationale ?? {},
					this.applicationDataQuery.getCompanyApplicants(),
					borrowings,
					products
				)
			])
		);
	}

	private fetchHomeLoanIntroducerApplication(): Observable<HomeLoanInsuranceModel[] | {}[]> {
		return this.getCustom(`interview/HomeLoanIntroducer/${this.applicationDataQuery.applicationId()}`).pipe(
			map((data: HomeLoanIntroducerDTO) => {
				return [HomeLoanIntroducerTransformer.fromPayload(data)];
			}),
			catchError(() => of([{}]))
		);
	}
	private fetchCustomerObjective(): Observable<unknown[]> {
		return this.getCustom(`interview/LoanPurposeObjective/${this.applicationDataQuery.applicationId()}`).pipe(
			map((data: LoanPurposeObjectiveDTO) => {
				return [LoanPurposeObjectiveTransformer.fromPayload(data)];
			}),
			catchError(() => of([{}]))
		);
	}
	private fetchConflicts(): Observable<unknown[]> {
		return this.getCustom(`interview/LoanPurposeConflict/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loanPurposeConflict: LoanPurposeConflictDTO) => {
				return [LoanPurposeConflictsTransformer.fromPayload(loanPurposeConflict)];
			}),
			catchError(() => of([{}]))
		);
	}

	private fetchSelectedProducts(): Observable<ProductSelectionComplianceDTO[]> {
		return <Observable<ProductSelectionComplianceDTO[]>>(
			this.getCustom(`interview/LoanPurposeSelectedProducts/${this.applicationDataQuery.applicationId()}`).pipe(
				catchError(() => of([]))
			)
		);
	}

	private fetchNotesBrokerAttestation(): Observable<NotesBrokerAttestation[]> {
		const model: NotesBrokerAttestation[] = [];
		return this.getCustom(`ApplicationNotes/${this.applicationDataQuery.applicationId()}`).pipe(
			map((comments: ComplianceNotesBrokerAttestationDTO) => {
				model.push(NotesBrokerAttestationTransformer.fromPayload(comments));
				return model;
			}),
			catchError(() => of(this.getNotesInitialState()))
		);
	}

	private fetchAdditionalRepayments(): Observable<AdditionalRepaymentsModel> {
		return this.getCustom(`LoanPreferences/AdditionalRepayments/${this.applicationDataQuery.applicationId()}`).pipe(
			map((repayments: AdditionalRepaymentsDTO) => AdditionalRepaymentsTransformer.fromPayload(repayments))
		);
	}

	private fetchAdditionalNotes(): Observable<AdditionalNotesModel> {
		return this.getCustom(
			`LoanPreferences/PreferencesAdditionalNotes/${this.applicationDataQuery.applicationId()}`
		).pipe(map((notes: AdditionalNotesDTO) => AdditionalNotesTransformer.fromPayload(notes)));
	}

	private fetchProperties(): Observable<{ id: number; address: Address }[]> {
		return this.getCustom(`Property/${this.applicationDataQuery.applicationId()}`).pipe(
			map((properties: PropertyDTO[]) =>
				properties.map((property) => ({ id: property.id as number, address: property.address }))
			)
		);
	}

	private fetchRetirement(): Observable<RetirementDTO> {
		return this.getCustom(`Retirement/${this.applicationDataQuery.applicationId()}`) as Observable<RetirementDTO>;
	}

	private getCommentsInitialState(): ComplianceComments[] {
		const comments: ComplianceComments = {
			applicationId: this.applicationDataQuery.applicationId(),
			anyConflicts: undefined,
			preferredLenders: undefined,
			anyConflictsOfInterest: undefined,
			commentsGrouping: {
				anyOtherRequirements: undefined,
				anyOtherRequirementsSpecification: undefined
			},
			isAnyAdditionalNotes: false
		};
		return [comments];
	}

	private getNotesInitialState(): NotesBrokerAttestation[] {
		const notes: NotesBrokerAttestation = {
			applicationId: this.applicationDataQuery.applicationId(),
			notes: '',
			brokerAttestation: false
		};
		return [notes];
	}

	private getCoApplicantInitialState(): CoApplicantInterviewDetails[] {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		return CoApplicantInterviewTransformer.fromPayload(
			{},
			applicants,
			this.sharedFlagService.complianceCoApplicantFilterTypes
		);
	}

	private getInterviewInitialState(): Interview[] {
		const interview: Interview = {
			applicationId: this.applicationDataQuery.applicationId(),
			interviewDate: undefined,
			address: undefined,
			applicantPresentBadges: this.getSelectedApplicants(),
			type: undefined,
			isClearBenefit: undefined,
			doAllUnderstandEnglish: undefined,
			interpreterRequired: false,
			reasonForBorrowing: undefined,
			primaryPurpose: undefined,
			borrowingAmount: 0,
			loanTerm: 0,
			anticipatedChangesList: this.getAnticipatedChangeList(),
			exitStrategy: '',
			isAdditionalNotes: false,
			additionalNotes: '',
			salesChannel: undefined
		};
		return [interview];
	}

	private getAnticipatedChangeList(): AnticipatedChanges[] {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		return applicants.map((applicant) => {
			return {
				applicantId: applicant.id,
				applicantName: applicant.name,
				applicantAnticipatesChanges: undefined,
				anticipatedChanges: undefined,
				significantChanges: [{} as SignificantChange],
				mitigants: [{} as MitigantFactor]
			};
		});
	}

	private getSelectedApplicants(): EnumObject[] {
		const applicants = this.formEnumQuery.getOptions('Applicants');
		const selectItems: EnumObject[] = [];
		applicants.forEach((applicant) => {
			selectItems.push({
				id: applicant.id,
				label: applicant.label
			});
		});

		return selectItems;
	}
}
