import { Injectable } from '@angular/core';
import { GuarantorInterviewCompletionModel } from '@app/modules/compliance/model/guarantor-interview-completion.model';
import { GuarantorInterviewSectionModel } from '@app/modules/compliance/model/guarantor-interview-section.model';
import { GuarantorInterviewDetailsModel } from '@app/modules/compliance/model/guarrantor-interview-details.model';
import { ComplianceGuarantorService } from '@app/modules/compliance/services/compliance-guarantor.service';
import { LoanDetailJourneyProducts } from '@app/modules/loan-serviceability/model/journey-product.model';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { GuarantorInterviewCompletionDTO, GuarantorInterviewDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, debounceTime, distinctUntilChanged, filter, map, of, take, tap } from 'rxjs';
import { EmploymentStatus, IncomeType } from '../enums/app.enums';
import { savedSuccessfullyMessage } from '../helper/util';
import { EmploymentService } from '../service/employment.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';

@Injectable({ providedIn: 'root' })
export class ComplianceGuarantorTransformerService {
	constructor(
		private complianceGuarantorSerivce: ComplianceGuarantorService,
		private employmentService: EmploymentService,
		private toastrService: ToastrService,
		private formEnumsService: FormEnumsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsQuery: FormEnumsQuery
	) {}

	transform(formFields: FormlyFieldConfig[] | undefined): Observable<FormlyFieldConfig[] | undefined> {
		this.setupStateWithPrepopulateValues();

		const area = formFields?.[0].fieldArray as FormlyFieldConfig;
		if (area) {
			const interviewSection = getFormField(formFields, 'interviewFields');
			const key = interviewSection!.key as string;
			area.fieldGroup = [];

			return this.complianceGuarantorSerivce.fetchGuarantorInterviews().pipe(
				map((interviews) => {
					interviews.forEach((interview, index) => {
						const newSection = cloneDeep(interviewSection);
						if (newSection?.templateOptions) {
							const name = interview.partyName;
							newSection.templateOptions.label = name;
							newSection.key = `${key}-${index}`;
							newSection.fieldGroup?.forEach((field) => (field.key = `${field.key}-${index}`));
							area.fieldGroup?.push(newSection);

							formlyOnClick(formFields, `interviewFields-${index}.interviewDetails-${index}.hideChecklist`, (field) => {
								this.saveGuarantorInterview(
									field.parent?.parent as FormlyFieldConfig,
									interview.guarantorId,
									'Details',
									true
								);
								const subSections = field.parent?.parent?.parent?.fieldGroup;
								if (subSections?.length) {
									subSections?.forEach((sub) => {
										if (sub.key) {
											this.simpFormlyHandlerService.updateDraftFlag(sub.key as string, false);
										}
									});
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.confirmCompleted-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Confirm');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.interviewDetails-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Details');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.interview-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Interview');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.guarantorsOpinion-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Opinion');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.guarantorsEligibility-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Eligibility');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.securityGuarantee-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Security guarantee');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.servicingGuarantee-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Servicing guarantee');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.guarantorUnderstanding-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Guarantor understanding');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.guarantorsDeliveryMethod-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Delivery method');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.independentAdvice-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Independent advice');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.additionalComments-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.saveGuarantorInterview(field, interview.guarantorId, 'Comment');
											})
										);
									}
									return of();
								}
							});

							formlyRegisterHooks(formFields, `interviewFields-${index}.interviewCompletion-${index}`, {
								afterViewInit: (field) => {
									if (field) {
										const fieldModel = field.model as GuarantorInterviewCompletionModel[];
										return field.formControl?.valueChanges.pipe(
											debounceTime(1000),
											distinctUntilChanged(),
											filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
											tap(() => {
												this.complianceGuarantorSerivce.saveCompletion(fieldModel[0]).subscribe(() => {
													this.setupStateWithPrepopulateValues();
													this.toastrService.success(savedSuccessfullyMessage('Completion'));
												});
											})
										);
									}
									return of();
								}
							});

							formlyExtendExpressionProperties(
								formFields,
								`interviewFields-${index}.independentAdvice-${index}.independentAdvice`,
								{
									'templateOptions.options': (model, formState, formField) => {
										if (formField && formField.templateOptions) {
											if (typeof formField.templateOptions.options === 'string') {
												const listItems = this.formEnumsService.fetch(formField.templateOptions.options).pipe(
													map((option) => {
														if (!formField.templateOptions?.required && !option.some((x) => x.id === undefined)) {
															option.push({ id: undefined, label: 'Not required' } as unknown as EnumObject);
															return option;
														}
														return option;
													})
												);
												return (formField.templateOptions.options = listItems);
											} else {
												if (formField && formField.templateOptions.required) {
													const listItems = (formField.templateOptions.options as Observable<EnumObject[]>).pipe(
														map((options) => {
															return options.filter((option) => option.id !== undefined);
														})
													);
													return (formField.templateOptions.options = listItems);
												}
											}
										}
										return formField.templateOptions?.options;
									}
								}
							);
						}
					});
					return formFields;
				})
			);
		}
		return of(formFields);
	}

	private setupStateWithPrepopulateValues() {
		this.complianceGuarantorSerivce
			.setupState$()
			.pipe(
				tap((data: [GuarantorInterviewDTO[], GuarantorInterviewCompletionDTO[], LoanDetailJourneyProducts[]]) => {
					if (data[0].length) {
						data[0].forEach((interview, index) => {
							if (interview.guarantorApplicantId) {
								this.employmentService.fetchEmploymentsForApplicant(interview.guarantorApplicantId).subscribe((res) => {
									this.simpFormlyHandlerService
										.mapToStateData<GuarantorInterviewDetailsModel[]>(`interviewDetails-${index}`)
										?.pipe(take(2))
										.subscribe((state) => {
											if (state.length && !state[0].occupation) {
												const employmentDetails = res.map((d) => d.employmentDetails);
												let occupation = '';
												employmentDetails.forEach((employment) => {
													if (
														(employment.typeOfIncome.type === IncomeType.payg ||
															employment.typeOfIncome.type === IncomeType.ForeignEmployed) &&
														(employment.payg?.status === EmploymentStatus.Primary ||
															employment.foreignEmployment?.status === EmploymentStatus.Primary)
													) {
														if (employment?.payg?.occupationCode) {
															occupation = this.formEnumsQuery.getOptionLabel(
																'AbsOccupationCode',
																employment.payg?.occupationCode
															);
														}
													} else if (
														employment.typeOfIncome.type === IncomeType.selfEmployed &&
														employment.selfEmployment?.status === EmploymentStatus.Primary
													) {
														if (employment.selfEmployment?.occupationCodeId) {
															occupation = this.formEnumsQuery.getOptionLabel(
																'AbsOccupationCode',
																employment.selfEmployment?.occupationCodeId
															);
														}
													} else if (employment.typeOfIncome.type === IncomeType.NotEmployed) {
														occupation = 'Not Employed';
													}
													state[0].occupation = occupation;
													this.simpFormlyHandlerService.updateToState(`interviewDetails-${index}`, state[0], 0);
												});
											}
										});
								});
							}
						});
					}
				})
			)
			.subscribe();
	}

	private saveGuarantorInterview(field: FormlyFieldConfig, guarantorId: number, fieldName: string, fetch = false) {
		const model = JSON.parse(
			JSON.stringify(field?.parent?.formControl?.value).replace(/-\d":/g, '":')
		) as GuarantorInterviewSectionModel;
		this.complianceGuarantorSerivce.saveGuarantorInterview(model, guarantorId).subscribe(() => {
			if (fetch) {
				this.setupStateWithPrepopulateValues();
			}
			this.toastrService.success(savedSuccessfullyMessage(fieldName));
		});
	}
}
