import { SideNavItem } from '../store/application-data/typings/application-data';

export enum FormAreas {
	setup = 'setup',
	applicants = 'applicants',
	financialPosition = 'financialPosition',
	preCheck = 'preCheck',
	loanServiceability = 'loanServiceability',
	compliance = 'compliance',
	complianceGuarantor = 'complianceGuarantor',
	summaryLodgement = 'summaryLodgement',
	consentInitiation = 'consentInitiation',
	companyApplicants = 'companyApplicants',
	companyFinancialPosition = 'companyFinancialPosition',
	trustApplicants = 'trustApplicants',
	trustFinancialPosition = 'trustFinancialPosition',
	accreditationCheck = 'accreditationCheck',
	loanAppX = 'loanAppX',
	refi = 'refi',
	purchase = 'purchase',
	loanAppClientCommon = 'loanAppClientCommon',
	serviceabilityScenarios = 'serviceabilityScenarios'
}

export enum FormSubAreas {
	serviceability = 'serviceability'
}

// has to be in the same order as left menu
export enum FormAreaPath {
	setup = 'setup',
	applicants = 'applicants',
	financialPosition = 'financial-position',
	loanServiceability = 'loan-serviceability',
	loan = 'loan-serviceability',
	serviceability = 'loan-serviceability/serviceability',
	serviceabilityScenarios = 'serviceability-scenarios',
	serviceabilityMatrix = 'serviceability-scenarios/matrix',
	compliance = 'compliance',
	complianceGuarantor = 'compliance/guarantor',
	summaryLodgement = 'summary-lodgement',
	documents = 'summary-lodgement/documents',
	submission = 'summary-lodgement/submission',
	companyApplicants = 'applicants/company',
	companyFinancialPosition = 'financial-position/company',
	trustApplicants = 'applicants/trust',
	trustFinancialPosition = 'financial-position/trust'
}

// has to be in the same order as left menu
export enum FormAreaLabel {
	setup = 'Setup',
	applicants = 'Applicants',
	financialPosition = 'Financial position',
	loanServiceability = 'Loan & serviceability',
	loan = 'Loan',
	serviceability = 'Serviceability',
	serviceabilityScenarios = 'Serviceability',
	scenario = 'Scenario',
	outcomeMetrics = 'Outcomes & metrics',
	compliance = 'Compliance',
	complianceGuarantor = 'Guarantor',
	summaryLodgement = 'Summary & submit',
	summary = 'Summary',
	documents = 'Documents',
	submission = 'Submission'
}

enum FormAreaIcon {
	setup = 'fas fa-file-alt ',
	applicants = 'fas fa-user-friends ',
	financialPosition = 'fas fa-usd-circle',
	loanServiceability = 'fas fa-wallet',
	serviceabilityScenarios = 'fa-solid fa-calculator-simple',
	compliance = 'fas fa-clipboard-check',
	summaryLodgement = 'far fa-scroll'
}

export enum JourneyArea {
	setup = 4000,
	applicants = 4001,
	financialPosition = 4002,
	loanServiceability = 4003,
	compliance = 4004,
	consentInitiation = 4006,
	preCheck = 4007,
	summaryLodgement = 4008,
	companyApplicants = 4009,
	companyFinancialPosition = 4010,
	trustApplicants = 4011,
	trustFinancialPosition = 4012,
	accreditationCheck = 4013,
	complianceGuarantor = 4014,
	loanAppClientCommon = 4016,
	serviceabilityScenarios = 4017,
	refi = 11010,
	purchase = 51010,
	aip = 7000
}

export const loanServiceabilityChildren: SideNavItem[] = [
	{
		url: FormAreaPath.loanServiceability,
		linkText: FormAreaLabel.loan,
		titleText: FormAreaLabel.loan,
		icon: FormAreaIcon.loanServiceability,
		area: JourneyArea.loanServiceability,
		includedSections: [
			'purchaseDetails',
			'propertyValuation',
			'propertyTitleSearch',
			'propertyValuationLabels',
			'loanRequirements',
			'fees',
			'lmiQuote',
			'lmiQuoteLabels',
			'lmiApplicationLabels',
			'depositsAndContributions',
			'feePopup',
			'rentalIncomeVerification'
		]
	},
	{
		url: FormAreaPath.serviceability,
		linkText: FormAreaLabel.serviceability,
		titleText: FormAreaLabel.serviceability,
		icon: FormAreaIcon.loanServiceability,
		area: JourneyArea.loanServiceability,
		includedSections: ['serviceability']
	}
];

export const serviceabilityScenariosChildren: SideNavItem[] = [
	{
		url: FormAreaPath.serviceabilityScenarios,
		linkText: FormAreaLabel.scenario,
		titleText: FormAreaLabel.serviceabilityScenarios,
		area: JourneyArea.serviceabilityScenarios,
		includedSections: ['scenarios', 'outcomesMetrics'],
		elementId: 'scenarioSelection'
	},
	{
		url: FormAreaPath.serviceabilityScenarios,
		linkText: FormAreaLabel.outcomeMetrics,
		titleText: FormAreaLabel.outcomeMetrics,
		area: JourneyArea.serviceabilityScenarios,
		includedSections: ['scenarios', 'outcomesMetrics'],
		elementId: 'outcomesMetrics'
	}
];

export const summaryLodgementChildren: SideNavItem[] = [
	{
		url: FormAreaPath.summaryLodgement,
		linkText: FormAreaLabel.summary,
		titleText: FormAreaLabel.summary,
		area: JourneyArea.summaryLodgement,
		includedSections: ['summaryData', 'summaryLabels']
	},
	{
		url: FormAreaPath.documents,
		linkText: FormAreaLabel.documents,
		titleText: FormAreaLabel.documents,
		area: JourneyArea.summaryLodgement,
		includedSections: ['documents']
	},
	{
		url: FormAreaPath.submission,
		linkText: FormAreaLabel.submission,
		titleText: FormAreaLabel.submission,
		area: JourneyArea.summaryLodgement,
		includedSections: ['lodgement', 'summaryLabels', 'applicationSubmittedLabels']
	}
];

export const setupChildren: SideNavItem = {
	url: FormAreaPath.setup,
	linkText: FormAreaLabel.setup,
	titleText: FormAreaLabel.setup,
	area: JourneyArea.setup,
	includedSections: ['application', 'manageParticipants', 'documentationInstructions']
};

export const getSchemaArea = (area: FormAreas): JourneyArea | null => {
	switch (area) {
		case FormAreas.setup:
			return JourneyArea.setup;
		case FormAreas.applicants:
			return JourneyArea.applicants;
		case FormAreas.financialPosition:
			return JourneyArea.financialPosition;
		case FormAreas.loanServiceability:
			return JourneyArea.loanServiceability;
		case FormAreas.serviceabilityScenarios:
			return JourneyArea.serviceabilityScenarios;
		case FormAreas.compliance:
			return JourneyArea.compliance;
		case FormAreas.complianceGuarantor:
			return JourneyArea.complianceGuarantor;
		case FormAreas.summaryLodgement:
			return JourneyArea.summaryLodgement;
		case FormAreas.preCheck:
			return JourneyArea.preCheck;
		case FormAreas.consentInitiation:
			return JourneyArea.consentInitiation;
		case FormAreas.companyApplicants:
			return JourneyArea.companyApplicants;
		case FormAreas.companyFinancialPosition:
			return JourneyArea.companyFinancialPosition;
		case FormAreas.trustApplicants:
			return JourneyArea.trustApplicants;
		case FormAreas.trustFinancialPosition:
			return JourneyArea.trustFinancialPosition;
		case FormAreas.accreditationCheck:
			return JourneyArea.accreditationCheck;
		case FormAreas.refi:
			return JourneyArea.refi;
		case FormAreas.purchase:
			return JourneyArea.purchase;
		case FormAreas.loanAppClientCommon:
			return JourneyArea.loanAppClientCommon;
		default:
			return null;
	}
};
