import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';

import { formatDate } from '../../helper/date.helper';
import { SimpFormState } from './typings/form-state';

/**
 * This is a formState and can be used directly in schema as expression eg: formState.applicationId === -1
 */

const createInitialState = (): SimpFormState => {
	return {
		today: formatDate(new Date()),
		variations: []
	};
};

@Injectable({ providedIn: 'root' })
export class FormStateStore {
	store = createStore({ name: 'form-state' }, withProps<SimpFormState>(createInitialState()));
}
