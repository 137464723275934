export interface JourneySetting {
	enableStraightThroughProcess: boolean;
	enableAssessmentProcess: boolean;
	autoSubmitToLenderWhenCompleteProcess: boolean;
	submitToLenderFromLoanapp: boolean;
	autoSignatureRequest: boolean;
	multipleSimultaneousSessions: JourneySetupMultipleSimultaneousSessions;
	enableUncategorizedTransactionInBankingWidget: boolean;
}

export enum JourneySetupMultipleSimultaneousSessions {
	Enabled = 1,
	WithWarning = 2,
	BlockedWithOverride = 3,
	Blocked = 4
}
