/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class SummaryBaseTransformerService {
	transformFieldConfig(formFieldConfig: FormlyFieldConfig, data: any[], type: string): FormlyFieldConfig {
		if ((data && data.length === 0) || !data) {
			return formFieldConfig;
		}

		const clonedFields: FormlyFieldConfig[] = [];
		const fields = getFormField((formFieldConfig?.fieldArray as FormlyFieldConfig)?.fieldGroup, type);

		data.map((participant, index) => {
			fields?.fieldGroup?.map((field) => {
				const clonedField = cloneDeep(field);
				clonedField.key = `${clonedField.key}${index}`;
				if (index > 0) {
					clonedField.templateOptions!.hideLabel = true;
				}
				clonedFields.push(clonedField);
			});
		});

		if (fields?.fieldGroup) {
			fields.fieldGroup = [];
		}

		fields?.fieldGroup?.push(...clonedFields);

		const titlePostfix = data.length > 1 ? `(${data.length})` : '';
		formFieldConfig.templateOptions!.label = formFieldConfig
			.templateOptions!.label!.replace('(optional)', ``)
			.concat(titlePostfix);

		return formFieldConfig;
	}
}
