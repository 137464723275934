import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RefiChooseLoanService extends BaseJourneyService {
	constructor(private simpFormlyHandlerService: SimpFormlyHandlerService) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.simpFormlyHandlerService.upsertToState('details', of([{}]));
	}
}
