import { HomeLoanModel } from '@app/modules/financial-position/model/homeloan.model';
import { CreditCardModel } from './creditcard.model';
import { OtherLiabilityModel } from './otherliabilites.model';
import { PersonalLoanModel } from './personalloan.model';

export interface IncomeHeaderSummary {
	totalNetAmount?: number;
	totalGrossAmount?: number;
	showTooltip?: boolean;
}

export interface LiabilitiesHeaderSummary {
	totalOutstandingBalance?: number;
	totalCreditLimit?: number;
	totalRepayment?: number;
	showTooltip?: boolean;
}

export class LiabilitiesHeaderTransformer {
	static transformHeader(
		models: CreditCardModel[] | PersonalLoanModel[] | OtherLiabilityModel[] | HomeLoanModel[]
	): LiabilitiesHeaderSummary {
		const header = { totalOutstandingBalance: 0, totalCreditLimit: 0, totalRepayment: 0 };
		models?.forEach((data) => {
			header.totalOutstandingBalance += data.outstandingBalance ?? 0;
			header.totalCreditLimit += data.creditLimit ?? 0;
			header.totalRepayment += data.monthlyRepayment ?? 0;
		});
		return header;
	}

	static updateHeaderAfterDelete(
		header: LiabilitiesHeaderSummary,
		deletedData: OtherLiabilityModel | PersonalLoanModel | CreditCardModel
	) {
		header.totalOutstandingBalance = (header.totalOutstandingBalance ?? 0) - (deletedData.outstandingBalance ?? 0);
		header.totalCreditLimit = (header.totalCreditLimit ?? 0) - (deletedData.creditLimit ?? 0);
		header.totalRepayment = (header.totalRepayment ?? 0) - (deletedData.repaymentAmount ?? 0);

		return header;
	}
}
