import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApplicationDetailsDto } from '@app/modules/setup/typings/setup';
import { ApplicationPreCheck, YesNo } from '@app/modules/shared/enums/app.enums';
import { PreCheckApiService } from '@app/modules/shared/service/pre-check-api.service';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { FormMetaDataQuery } from '@app/modules/shared/store/form-metadata/form-metadata.query';
import { FormMetaDataService } from '@app/modules/shared/store/form-metadata/form-metadata.service';
import { FormAreas } from '@app/modules/shared/typings/form-areas.types';
import { formlyGenerateLabels } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyLabels } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpSpinnerService } from '@simpology/client-components';
import { Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { BrandingService } from '@simpology/authentication';
import { PreCheckModel } from '../../model/pre-check.model';

@Component({
	selector: 'app-pre-check',
	templateUrl: './pre-check.component.html',
	styleUrls: ['./pre-check.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreCheckComponent implements OnInit, OnDestroy {
	@Input() applicationDetailsAPI?: ApplicationDetailsDto;
	@ViewChild('precheckModal', { static: false }) precheckModal: TemplateRef<HTMLElement> =
		{} as TemplateRef<HTMLElement>;
	eligibleScreen = true;
	form = new UntypedFormGroup({});
	labels: FormlyLabels = {};
	formFields?: FormlyFieldConfig[];
	lvr = '0%';
	model: { [key: string]: PreCheckModel } = {};
	showError = false;
	showSuccess = false;
	contactEmail = '';
	contactPhone = '';
	private destroy$: Subject<void> = new Subject();
	private precheckModalRef?: NgbModalRef;

	constructor(
		private formMetaDataService: FormMetaDataService,
		private formMetaDataQuery: FormMetaDataQuery,
		private applicationDataService: ApplicationDataService,
		public activeModal: NgbActiveModal,
		private simpSpinnerService: SimpSpinnerService,
		private preCheckApiService: PreCheckApiService,
		private cdr: ChangeDetectorRef,
		private brandingService: BrandingService,
		private modalService: NgbModal
	) {
		this.brandingService.brandData.pipe(take(1)).subscribe((res) => {
			if (res) {
				this.contactEmail = res.contactEmail;
				this.contactPhone = res.contactPhone;
			}
		});
	}

	ngOnInit(): void {
		this.formMetaDataService.fetch(FormAreas.preCheck);
		this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			const hasNos = Object.values(value).filter(
				(v) => this.form.valid && (v as PreCheckModel).yesNo === YesNo.No
			).length;
			this.showError = this.model[1].postCode ? hasNos > 0 : hasNos > 1;
			this.showSuccess = !hasNos && this.form.valid;
			this.lvr = `${this.model[1].lvr}%`;
		});
		this.formMetaDataQuery
			.selectArea$(FormAreas.preCheck)
			.pipe(takeUntil(this.destroy$))
			.subscribe((formFields) => {
				if (formFields?.length) {
					this.labels = formlyGenerateLabels(formFields[0], 'precheckLabels');
				}
				this.formFields = formFields;
				this.cdr.detectChanges();
			});
	}

	close(): void {
		this.updateApplication(ApplicationPreCheck.Ineligible).subscribe(() => {
			this.activeModal.close();
			this.applicationDataService.updatePreCheckStatus(ApplicationPreCheck.Ineligible);
		});
	}

	errorMessage(key: number): boolean {
		return [1, 2, 3, 4, 5, 6].find((objKey) => this.model[objKey].yesNo === YesNo.No) === key;
	}

	submit(): void {
		this.simpSpinnerService.show();
		this.updateApplication(ApplicationPreCheck.Eligible).subscribe(() => {
			this.simpSpinnerService.hide();
			this.activeModal.close();
			this.applicationDataService.updatePreCheckStatus(ApplicationPreCheck.Eligible);
		});
	}

	openPrecheckModal() {
		this.precheckModalRef = this.modalService.open(this.precheckModal, { centered: true, size: 'lg' });
	}

	closePrecheckModal() {
		this.precheckModalRef?.close();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateApplication(preCheck: ApplicationPreCheck): Observable<any> {
		if (this.applicationDetailsAPI && this.applicationDetailsAPI.preCheck !== preCheck) {
			this.applicationDetailsAPI.preCheck = preCheck;
			return this.preCheckApiService.updateApplicationPreCheck(this.applicationDetailsAPI.id, preCheck);
		}

		return of(true);
	}
}
