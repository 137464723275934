import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ExpiredComponent } from './expired/expired.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { LoginComponent } from './login/login.component';

@NgModule({
	declarations: [LoginComponent, AuthCallbackComponent, ExpiredComponent, ExternalLoginComponent],
	imports: [CommonModule, ReactiveFormsModule, FormsModule, AuthenticationRoutingModule, NgbModule, ReactiveFormsModule]
})
export class AuthenticationModule {}
