import { Injectable } from '@angular/core';
import { ApplicantBasicInfo } from '@app/modules/applicants/models/applicant-basic-info.model';
import { PersonContactDetailsModel } from '@app/modules/applicants/models/contact-details.model';
import { PersonInsurancesModel } from '@app/modules/applicants/models/insurances-model';
import { LendingGuaranteeModel } from '@app/modules/applicants/models/lending-guarantee.model';
import { PersonalDetailsModel } from '@app/modules/applicants/models/personal-details.model';
import { PersonResidencyModel } from '@app/modules/applicants/models/residency.model';
import {
	InformedConsentDetails,
	NextOfKin,
	PersonAddresses,
	PersonApplicant,
	PersonType
} from '@app/modules/applicants/typings/applicants';
import { HouseholdInfo, RelationshipInfo } from '@app/modules/applicants/typings/household';
import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { RealEstateModel } from '@app/modules/financial-position/model/property.model';
import { RefiApplicationStatusIndex } from '@app/modules/refi-journey/enums/refi-application-status.enum';
import { RefiStore } from '@app/modules/refi-journey/enums/refi-store.enum';
import { RefiApplicationStatusModel } from '@app/modules/refi-journey/models/refi-application-status.model';
import { EligibilityModel } from '@app/modules/refi-journey/models/refi-eligibility.model';
import { RefiExpensesModel, RefiHousehold } from '@app/modules/refi-journey/models/refi-expenses.model';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import {
	BankDetailsDTO,
	InterviewPersonDTO,
	PersonApplicantDTO,
	RefiApplicationSummaryDTO
} from '@app/modules/typings/api';
import { Store, createStore, select, withProps } from '@ngneat/elf';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { cloneDeep, get, isEqual } from 'lodash-es';
import { Observable, distinctUntilChanged, map, take } from 'rxjs';
import { ApplicantEntityType, DigitalWidgetStatusType } from '../../enums/app.enums';
import { FormEnumsQuery } from '../form-enums/form-enums.query';
import { ApplicantType } from '../form-enums/form-enums.store';
import { PersonOtherDetailsModel } from './../../../applicants/models/other-details-model';
import { FormStore } from './model/form-data.model';

export const STORE_KEYS = {
	ABN_LOOKUP: 'abn-lookup',
	INTERVIEW_PERSONS: 'interview-persons',
	INFORMED_CONSENT: 'applicants-informedConsent'
};

@Injectable({ providedIn: 'root' })
export class FormDataService {
	private stores = new Map<string, Store>();

	constructor(private formEnumsQuery: FormEnumsQuery) {}

	createStore<T = unknown>(name: string, initialState: T[]) {
		const store = createStore({ name }, withProps({ data: initialState }));

		this.stores.set(name, store);

		return store;
	}

	isFormDataState = (name: string) => this.stores.has(name);

	getState<T>(name: string): Store {
		const store = this.stores.get(name);
		if (store) {
			return store;
		} else {
			const newStore = this.createStore(name, []);
			return newStore;
		}
	}

	/**
	 *
	 * @param name store name
	 * @param data update data
	 * @param index of item in sub-section array
	 * @param patch pass patch object to update only a part of form from state
	 */
	update(name: string, data: unknown, index: number, patch?: unknown) {
		this.getState(name).update((stateDraft: FormStore<unknown>) => {
			const draft = cloneDeep(stateDraft);
			draft.data[index] = data;
			draft.draft = false;
			draft.placeHolderTemplate = undefined;
			if (patch) {
				if (!draft.patch) {
					draft.patch = [];
				}
				draft.patch[index] = patch;
			} else {
				draft.patch = undefined;
			}
			return draft;
		});
	}

	/**
	 * Upsert to state synchronous data
	 * @param name
	 * @param data
	 */
	upsertData(name: string, data: unknown) {
		const state = this.getState(name);
		const oldData = (state.getValue() as FormStore).data;
		if (!isEqual(oldData, data)) {
			state.update((draft: FormStore<any>) => {
				draft.data = data as unknown[];
				draft.loading = false;
				draft.placeHolderTemplate = undefined;
				draft.draft = false;
				draft.patch = undefined;
				return cloneDeep(draft);
			});
		}
	}

	/**
	 * Upsert to state header
	 * @param name
	 * @param header
	 */
	upsertHeader(name: string, header: unknown) {
		this.getState(name).update((draft: FormStore<any>) => {
			draft.header = header;
			return cloneDeep(draft);
		});
	}

	setLoading(name: string, loading: boolean) {
		this.getState(name).update((draft: FormStore<any>) => {
			draft.loading = loading;
			return cloneDeep(draft);
		});
	}

	setPlaceholderTemplate(name: string, placeholder: string, loading = false) {
		this.getState(name).update((draft: FormStore<any>) => {
			draft.placeHolderTemplate = placeholder;
			draft.loading = loading;
			return cloneDeep(draft);
		});
	}

	delete(name: string, index: number) {
		this.getState(name).update((draft: FormStore<any>) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const clonedDraft = cloneDeep(draft);
			clonedDraft.data.splice(index, 1);
			return clonedDraft;
		});
	}

	upsertStateWithAsyncData(name: string, data$: Observable<unknown[] | unknown>, priority = 3) {
		const state = this.getState(name);
		this.setLoading(name, true);
		state.update((draft: FormStore<unknown>) => cloneDeep({ ...draft, loading: true }));
		data$.pipe(take(1)).subscribe({
			next: (data) => {
				state.update((draft: FormStore<unknown>) => {
					draft.data = data as unknown[];
					draft.loading = false;
					draft.placeHolderTemplate = undefined;
					draft.patch = undefined;
					return cloneDeep(draft);
				});
			},
			error: (error) => {
				console.error(error);
				state.update(updateRequestStatus(name, 'error', `${name} - api loading error`));
			}
		});
	}

	selectSubSectionHeader$<T, V>(name: string): Observable<V> {
		return this.getState<T>(name).pipe(select((state: FormStore<T>) => state.header as V));
	}

	selectSectionHeader$<T, V>(name: string): Observable<V> {
		return this.getState<T>(name).pipe(select((state: FormStore<T>) => state.header as V));
	}

	select$<T = unknown>(name: string): Observable<T[]> {
		return this.getState<T>(name).pipe(select((state: FormStore<T>) => state.data));
	}

	selectPatch$<T = unknown>(name: string): Observable<T[] | undefined> {
		return this.getState<T>(name).pipe(select((state: FormStore<T>) => state.patch));
	}

	selectCustom$<T = unknown, V = unknown>(name: string, customExpression: (data: FormStore<T>) => V): Observable<V> {
		return this.getState<T>(name).pipe(select((state: FormStore<T>) => customExpression(state)));
	}

	selectApplicantsLength$() {
		return this.select$<SetUpApplicant>('applicants').pipe(
			map((applicants) => applicants.length),
			distinctUntilChanged(),
			map(() => this.getSetupApplicants())
		);
	}

	hasApplicants(): boolean {
		return (this.getState('applicants').getValue() as FormStore<any>).data?.length > 0;
	}

	getPropertyAddresses(): RealEstateModel[] {
		return (this.getState<RealEstateModel>('financial-position-realEstate').getValue() as FormStore<RealEstateModel>)
			.data;
	}

	getApplicantAddresses(index: number): PersonAddresses {
		return (this.getState<PersonAddresses>(`applicants-addresses-${index}`).getValue() as FormStore<PersonAddresses>)
			?.data[0];
	}

	selectPlaceholder$(name: string): Observable<string | undefined> {
		return this.getState(name).pipe(
			select((state: FormStore<any>) => (state.placeHolderTemplate ? state.placeHolderTemplate : undefined))
		);
	}

	getStatePlaceholder<T = unknown>(storeName: string): string | undefined {
		return (this.getState<T>(storeName).getValue() as FormStore<T>).placeHolderTemplate;
	}

	selectLoading$(name: string): Observable<boolean> {
		return this.getState(name).pipe(select((state: FormStore<any>) => !!state.loading));
	}

	getStateLoading<T = unknown>(storeName: string): boolean | undefined {
		return (this.getState<T>(storeName).getValue() as FormStore<T>).loading;
	}

	selectDraft$(name: string): Observable<boolean> {
		return this.getState(name).pipe(
			select((state) => {
				return get(state, 'draft', false) as boolean;
			})
		);
	}

	resetStore(): void {
		this.stores.forEach((s) => s.reset());
	}

	hardResetStore(): void {
		this.stores.forEach((s) => s.update(() => ({ data: [] })));
	}

	resetState(name: string) {
		this.getState(name).update((stateDraft: FormStore<unknown>) => {
			const draft = cloneDeep(stateDraft);
			draft.data = [];
			draft.draft = false;
			draft.placeHolderTemplate = undefined;
			return draft;
		});
	}

	getSetupApplicants(): SetUpApplicant[] {
		return (this.getState<SetUpApplicant>('applicants').getValue() as FormStore<SetUpApplicant>).data;
	}

	getSetupApplicantById(applicantId: number): SetUpApplicant | undefined {
		return (this.getState<SetUpApplicant>('applicants').getValue() as FormStore<SetUpApplicant>).data.find(
			(x) => x.id === applicantId
		);
	}

	storeInterviewPersons(persons: InterviewPersonDTO[]): void {
		this.update(STORE_KEYS.INTERVIEW_PERSONS, persons, 0);
	}

	getInterviewPersons(): InterviewPersonDTO[] {
		return (
			this.getState<InterviewPersonDTO[]>(STORE_KEYS.INTERVIEW_PERSONS).getValue() as FormStore<InterviewPersonDTO[]>
		)?.data[0];
	}

	getApplicantSubText(setupApplicantId: number): string {
		const applicant = this.getSetupApplicants().find((x) => x.id === setupApplicantId);
		return `${this.formEnumsQuery.getOptionLabel(
			'ApplicantType',
			applicant?.applicantType || ApplicantType.Borrower
		)} ${applicant?.primaryApplicant ? '(primary)' : ''}`;
	}

	getRefiPersonApplicants(): PersonApplicantDTO[] {
		return (this.getState<PersonApplicantDTO>('applicants').getValue() as FormStore<PersonApplicantDTO>).data;
	}

	getHouseholds(): HouseholdInfo[] {
		return (this.getState<HouseholdInfo>('applicants-household').getValue() as FormStore<HouseholdInfo>).data;
	}

	getPersonApplicantByIndex(index: number) {
		return this.getPersonApplicants()[index];
	}

	getPersonApplicants(): PersonApplicant[] {
		const setupApplicants = (this.getState('applicants').getValue() as FormStore<SetUpApplicant>).data;
		const personalApplicants = setupApplicants.filter((x) => !!x.firstName || !!x.lastName);

		return personalApplicants.map((applicant, index) => {
			return {
				firstName: applicant.firstName,
				id: applicant.id,
				lastName: applicant.lastName,
				primaryApplicant: applicant.primaryApplicant,
				applicantType: applicant.applicantType,
				receivesAllNotification: applicant.receivesAllNotification,
				title: (this.getState(`applicants-personalDetails-${index}`).getValue() as FormStore<PersonalDetailsModel>)
					.data[0]?.title,
				addresses: (this.getState(`applicants-addresses-${index}`).getValue() as FormStore<PersonAddresses>).data,
				banking: (this.getState(`applicants-banking-${index}`).getValue() as FormStore<BankDetailsDTO>).data,
				residency: (this.getState(`applicants-residency-${index}`).getValue() as FormStore<PersonResidencyModel>).data,
				otherDetails: (
					this.getState(`applicants-otherDetails-${index}`).getValue() as FormStore<PersonOtherDetailsModel>
				).data,
				employments: (
					this.getState(`applicants-employments-${index}`).getValue() as FormStore<ApplicantEmploymentModel>
				).data,
				insurances: (this.getState(`applicants-insurances-${index}`).getValue() as FormStore<PersonInsurancesModel>)
					.data,
				contactDetails: (
					this.getState(`applicants-contactDetails-${index}`).getValue() as FormStore<PersonContactDetailsModel>
				).data,
				personalDetails: (
					this.getState(`applicants-personalDetails-${index}`).getValue() as FormStore<PersonalDetailsModel>
				).data,
				type: (this.getState(`applicants-type-${index}`).getValue() as FormStore<PersonType>).data,
				householdId: applicant.householdId,
				relationshipInfo: (this.getState(`applicants-relationship`).getValue() as FormStore<RelationshipInfo>).data[
					index
				],
				nextOfKin: (this.getState(`applicants-nextOfKin-${index}`).getValue() as FormStore<NextOfKin>).data,
				lendingGuarantee: (
					this.getState(`applicants-lendingGuarantee-${index}`).getValue() as FormStore<LendingGuaranteeModel>
				).data,
				proofOfIdentity: (
					this.getState(`applicants-proofOfIdentity-${index}`).getValue() as FormStore<LendingGuaranteeModel>
				).data
			} as PersonApplicant;
		});
	}

	isApplicantsConsentPending(key: string): boolean {
		const consentStatus = (this.getState(key).getValue() as FormStore<InformedConsentDetails>).data[0];
		return consentStatus?.status === DigitalWidgetStatusType.Pending;
	}

	getConsentNonInitiatedApplicants(): ApplicantBasicInfo[] {
		const applicants = this.getSetupApplicants();
		return applicants
			.filter((applicant) => applicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant)
			.map((applicant, index) => this.mapApplicantBasicInfo(applicant, index))
			.filter((applicant) => applicant && !this.getConsentedApplicantIds().includes(applicant.id));
	}

	hasApplicantConsentInitiated(applicantId: number): boolean {
		const informedConsentDetails = (
			this.getState(STORE_KEYS.INFORMED_CONSENT).getValue() as FormStore<InformedConsentDetails>
		).data[0];
		return (
			informedConsentDetails?.applicantsConsent.some(
				(applicantConsentDetails) => applicantConsentDetails.applicantId === applicantId
			) || false
		);
	}

	getStateData<T = unknown>(storeName: string): T[] {
		return (this.getState<T>(storeName).getValue() as FormStore<T>).data;
	}

	getHeaderData<T, V>(storeName: string): V {
		return (this.getState<T>(storeName).getValue() as FormStore<T>).header as V;
	}

	updateConsentInitiationForApplicant(index: number, status: boolean): void {
		this.getState(`applicants-personalDetails-${index}`).update((draft: FormStore<PersonalDetailsModel>) => {
			draft.data[0].consentInitiated = status;
			return cloneDeep(draft);
		});
		this.getState(`applicants-contactDetails-${index}`).update((draft: FormStore<PersonContactDetailsModel>) => {
			draft.data[0].consentInitiated = status;
			return cloneDeep(draft);
		});
	}

	getRefiEligibilityDetails(): EligibilityModel {
		const data = (this.getState<EligibilityModel>('refi-eligibility-details').getValue() as FormStore<EligibilityModel>)
			.data;
		return data.length ? data[0] : ({} as EligibilityModel);
	}

	getRefiApplicationSummary(): RefiApplicationSummaryDTO {
		return (this.getState(RefiStore.ApplicationSummary).getValue() as FormStore<RefiApplicationSummaryDTO>).data[0];
	}

	getRefiApplicationStatuses(): RefiApplicationStatusModel[] {
		return (this.getState(RefiStore.ApplicationStatuses).getValue() as FormStore<RefiApplicationStatusModel>).data;
	}

	hasCompletedPreSteps(): boolean {
		const currentStatusId = this.getRefiApplicationSummary().statusId;
		const currentStatusIndex =
			this.getRefiApplicationStatuses().find((status) => status.id === currentStatusId)?.index || 0;
		return currentStatusIndex > RefiApplicationStatusIndex.ACCOUNT_SETUP;
	}

	updateRefiHouseholdDetails(household: RefiHousehold): void {
		this.getState(RefiStore.Expenses).update((draft: FormStore<RefiExpensesModel>) => {
			draft.data[0].household = household;
			return cloneDeep(draft);
		});
	}

	private getConsentedApplicantIds(): number[] {
		const consentStatus = (this.getState(STORE_KEYS.INFORMED_CONSENT).getValue() as FormStore<InformedConsentDetails>)
			.data[0];
		if (consentStatus.status !== DigitalWidgetStatusType.Pending) {
			return [];
		} else {
			return consentStatus.applicantsConsent.map((applicant) => applicant.applicantId);
		}
	}

	private mapApplicantBasicInfo(applicant: SetUpApplicant, index: number): ApplicantBasicInfo {
		const personalDetails = (
			this.getState(`applicants-personalDetails-${index}`).getValue() as FormStore<PersonalDetailsModel>
		)?.data[0];
		const contactDetails = (
			this.getState(`applicants-contactDetails-${index}`).getValue() as FormStore<PersonContactDetailsModel>
		)?.data[0];
		return {
			id: applicant.id,
			fullName: `${this.formEnumsQuery.getOptionLabel('NameTitle', personalDetails?.title)} ${
				personalDetails?.firstName
			} ${personalDetails?.lastName}`,
			dateOfBirth: new Date(personalDetails?.dateOfBirth),
			mobilePhoneNumber: contactDetails?.mobilePhoneNumber,
			email: contactDetails?.email
		};
	}
}
