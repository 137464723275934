import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import {
	ApplicantDTO,
	ExpenseScenarioDTO,
	PropertyExpensesScenarioDTO,
	ScenarioLeverDTO
} from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';
import { ScenarioLeverDetails } from './scenario-lever.model';

export class ExpenseScenarioTransformer {
	static fromPayload(
		baseScenario: ScenarioLeverDTO,
		selectedScenario: ScenarioLeverDTO | undefined,
		applicants: ApplicantDTO[]
	): Expenses {
		const baseScenarioExpenses = baseScenario.expenses;
		const selectedScenarioExpenses = selectedScenario?.expenses;
		const scenarioExpenses: ExpenseScenario[] = [];

		// Extract categoryIds from baseScenarioExpenses
		const baseCategoryIds = new Set(baseScenarioExpenses.map((expense) => expense.categoryId));
		// Filter selectedScenarioExpenses for to get newly added categories
		const newlyAddedCategoryItems = selectedScenarioExpenses?.filter(
			(expense) => !baseCategoryIds.has(expense.categoryId)
		);

		const uniqueHouseholdIds = this.getUniqueHouseholdIds(baseScenarioExpenses);

		uniqueHouseholdIds.forEach((householdId) => {
			const baseExpensesForHousehold = baseScenarioExpenses.filter((expense) => expense.householdId === householdId);
			const selectedExpensesForHousehold = selectedScenarioExpenses?.filter(
				(expense) => expense.householdId === householdId
			);

			const changedExpenses = baseExpensesForHousehold.map((baseExpense) => {
				const selectedExpense = selectedExpensesForHousehold?.find(
					(selectedExp) => selectedExp.categoryId === baseExpense.categoryId
				);

				return {
					id: baseExpense.id,
					categoryId: baseExpense.categoryId,
					baseScenarioCategoryExpense: { amount: baseExpense.amount, frequency: baseExpense.frequencyId },
					selectedScenarioCategoryExpense: selectedExpense?.amount
				} as AddCategory;
			});

			newlyAddedCategoryItems
				?.filter((expense) => expense.householdId == householdId)
				.forEach((baseExpense) => {
					const selectedExpense = selectedExpensesForHousehold?.find(
						(selectedExp) => selectedExp.categoryId === baseExpense.categoryId
					);

					changedExpenses.push({
						id: baseExpense.id,
						categoryId: baseExpense.categoryId,
						baseScenarioCategoryExpense: { frequency: FrequencyShort.Monthly },
						selectedScenarioCategoryExpense: selectedExpense?.amount
					} as AddCategory);
				});

			scenarioExpenses.push({
				householdId: householdId,
				totalLivingExpensesLabel: this.getExpenseLabel(householdId, baseScenarioExpenses, applicants),
				baseScenarioTotalExpense: {
					amount: baseExpensesForHousehold.reduce((total, expense) => total + (expense?.amount ?? 0), 0),
					frequency: FrequencyShort.Monthly
				},

				selectedScenarioTotalExpense: selectedExpensesForHousehold?.reduce<number | undefined>((total, expense) => {
					const totalExpense = changedExpenses.reduce((sum, exp) => {
						return (
							sum +
							(exp.categoryId !== expense.householdId
								? exp.selectedScenarioCategoryExpense ?? exp.baseScenarioCategoryExpense?.amount ?? 0
								: 0)
						);
					}, 0);
					return totalExpense;
				}, undefined),

				changeExpenses: changedExpenses
			});
		});

		const propertyExpenses = baseScenario.propertyExpenses?.map((propertyExpense) => {
			return {
				id: propertyExpense.id,
				realEstateAssetId: propertyExpense.realEstateAssetId,
				baseScenarioPropertyExpenseAmount: {
					amount: propertyExpense.amount,
					frequency: propertyExpense.frequencyId
				},
				selectedScenarioPropertyExpenseAmount: ExpenseScenarioTransformer.getSelectedScenarioPropertyExpenseAmount(
					propertyExpense,
					selectedScenario
				),
				frequencyId: propertyExpense.frequencyId,
				addressOneLine: ExpenseScenarioTransformer.getPropertyExpenseLabel(propertyExpense.addressOneLine ?? '')
			} as PropertyExpense;
		});

		return { expenses: scenarioExpenses, propertyExpenses };
	}

	static toPayload(scenarioLeverDetails?: ScenarioLeverDetails): {
		expenses: ExpenseScenarioDTO[];
		propertyExpenses: PropertyExpensesScenarioDTO[];
	} {
		const expenses = scenarioLeverDetails?.expenses;
		const expensesDTO: ExpenseScenarioDTO[] = [];

		if (expenses?.expenses) {
			expenses.expenses.forEach((expense) => {
				if (expense.changeExpenses) {
					expense.changeExpenses.forEach((changeExpense) => {
						expensesDTO.push({
							id: changeExpense.id ?? 0,
							householdId: expense.householdId,
							categoryId: changeExpense.categoryId,
							amount: changeExpense.selectedScenarioCategoryExpense ?? null,
							frequencyId: changeExpense.baseScenarioCategoryExpense?.frequency
						});
					});
				}
			});
		}

		const propertyExpensesDTO =
			expenses?.propertyExpenses?.map((propertyExpense) => ({
				realEstateAssetId: propertyExpense.realEstateAssetId,
				amount: propertyExpense.selectedScenarioPropertyExpenseAmount
			})) ?? [];

		const accumulatedExpenses = this.accumulateAmountsByHouseholdAndCategory(expensesDTO);

		return {
			expenses: accumulatedExpenses,
			propertyExpenses: propertyExpensesDTO
		};
	}

	static getSelectedScenarioPropertyExpenseAmount(
		propertyExpense: PropertyExpensesScenarioDTO,
		selectedScenario: ScenarioLeverDTO | undefined
	): number | undefined {
		const amount = selectedScenario?.propertyExpenses?.find(
			(alternateScenarioPropertyExpense) =>
				propertyExpense.realEstateAssetId === alternateScenarioPropertyExpense.realEstateAssetId
		)?.amount;

		return amount ?? undefined;
	}

	static getUniqueHouseholdIds(expenses?: ExpenseScenarioDTO[]): number[] {
		const uniqueHouseholdIds = new Set(expenses?.map((expense) => expense?.householdId as number));
		return Array.from(uniqueHouseholdIds);
	}

	static getExpenseLabel(
		householdId: number,
		baseExpenses: ExpenseScenarioDTO[],
		applicants: ApplicantDTO[]
	): string | undefined {
		const expenseForHouseholdId = baseExpenses.find((expense) => expense.householdId === householdId);
		const applicantForHouseholdIds = applicants.filter((applicant) => applicant.householdId === householdId);

		if (expenseForHouseholdId && applicantForHouseholdIds) {
			const applicantNames = this.formatApplicantNames(applicantForHouseholdIds);
			return `Total living expenses - ${expenseForHouseholdId.householdName} <br> ${applicantNames}`;
		}

		return '';
	}

	static getPropertyExpenseLabel(addressOneLine: string): string {
		return `Property running cost <br> ${addressOneLine}`;
	}

	static formatApplicantNames(applicants: ApplicantDTO[]): string {
		if (!applicants || applicants.length === 0) {
			return '';
		}

		return applicants
			.filter(Boolean) // Remove any falsy values
			.map((applicant, index) => {
				const fullName = `${applicant.firstName} ${applicant.surname}`;
				if (index === 0) {
					return fullName;
				}
				const isLastApplicant = index === applicants.length - 1;
				const separator = isLastApplicant ? ' & ' : ', ';
				// For two names, they are separated by '&'.
				// For more than two names, the last one is separated by '&' and the others by a comma ','.

				return `${separator}${fullName}`;
			})
			.join('');
	}

	static accumulateAmountsByHouseholdAndCategory(expensesDTO: ExpenseScenarioDTO[]): ExpenseScenarioDTO[] {
		//Accumulate amounts by categoryId and householdId
		const categoryHouseholdMap = expensesDTO.reduce((acc, expense) => {
			if (expense.categoryId != null && expense.householdId != null) {
				const key = `${expense.categoryId}-${expense.householdId}`;
				if (!acc[key]) {
					acc[key] = { ...expense, amount: null };
				}
				if (expense.amount != null) {
					acc[key].amount! += expense.amount;
				}
			}
			return acc;
		}, {} as Record<string, ExpenseScenarioDTO>);

		//Convert the map back to an array
		return Object.values(categoryHouseholdMap);
	}
}

export interface ExpenseScenario {
	id?: number;
	householdId?: number;
	totalLivingExpensesLabel?: string;
	baseScenarioTotalExpense?: AmountSelect;
	selectedScenarioTotalExpense?: number;
	changeExpenses?: AddCategory[];
}
export interface AddCategory {
	id?: number;
	categoryId?: number;
	baseScenarioCategoryExpense?: AmountSelect;
	selectedScenarioCategoryExpense?: number;
}

export interface Expenses {
	expenses: ExpenseScenario[];
	propertyExpenses: PropertyExpense[];
}

export interface PropertyExpense {
	id?: number;
	frequencyId?: number;
	realEstateAssetId: number;
	baseScenarioPropertyExpenseAmount: AmountSelect;
	selectedScenarioPropertyExpenseAmount: number;
	addressOneLine: string;
}
