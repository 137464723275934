import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mapToKey'
})
export class MapToKeyPipe implements PipeTransform {
	transform(model: any, key: any): any {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return model[key];
	}
}
