import { CreditStatus } from '@app/modules/shared/enums/app.enums';
import { WarningIssueModel } from '@app/modules/shared/model/warning-issue-model';
import { CreditStatusDTO, WarningIssueDTO } from '@app/modules/typings/api';
import { getNullableBoolean, getNullableYesNo } from '../helper/util';
import { ShortApplicant } from './applicant.model';

export class CreditIssueTransformer {
	static fromPayload(res: CreditStatusDTO, applicant: ShortApplicant): CreditIssueModel {
		return {
			creditStatusContainer: {
				applicant: applicant.name,
				applicantId: applicant.id,
				status: res.creditStatus
			},
			warningIssuesList: this.getWarningList(res)
		};
	}

	static fromPayloadWarningIssues(payload: WarningIssueDTO): WarningIssueModel {
		return {
			creditIssue: payload.creditIssue,
			id: payload.id,
			isCreditIssueCurrent: getNullableYesNo(payload.isCreditIssueCurrent),
			issueDetails: payload.issueDetails
		};
	}
	static toPayloadWarningIssue(model: WarningIssueModel): WarningIssueDTO {
		return {
			creditIssue: model.creditIssue,
			id: model.id ?? 0,
			isCreditIssueCurrent: getNullableBoolean(model.isCreditIssueCurrent),
			issueDetails: model.issueDetails
		};
	}

	static toPayload(model: CreditIssueModel, applicationId: number, companyApplicantId: number): CreditStatusDTO {
		return {
			creditStatus: model.creditStatusContainer.status,
			applicationId: applicationId,
			applicantId: companyApplicantId
		};
	}
	private static getWarningList(res: CreditStatusDTO): WarningIssueModel[] | undefined {
		if (res.creditStatus === CreditStatus.Warning) {
			const warningIssueList = [] as WarningIssueModel[];
			if (res.warningIssuesList?.length) {
				return res.warningIssuesList?.map((warningDTO) => this.fromPayloadWarningIssues(warningDTO));
			}
			return warningIssueList;
		}
		return undefined;
	}
}

export interface CreditIssueModel {
	creditStatusContainer: CreditStatusModel;
	warningIssuesList?: WarningIssueModel[];
}

interface CreditStatusModel {
	applicant: string;
	applicantId: number;
	status?: CreditStatus;
}
