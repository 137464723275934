import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { updateRequestStatus, withRequestsStatus } from '@ngneat/elf-requests';
import { Observable } from 'rxjs';

import { FormAddress } from './typings/form-addresses';

const createInitialState = (): FormAddress => {
	return {
		addresses: []
	};
};

@Injectable({ providedIn: 'root' })
export class FormAddressesStore {
	store = createStore(
		{ name: 'form-addresses' },
		withProps<FormAddress>(createInitialState()),
		withRequestsStatus<'form-addresses'>()
	);

	reset(): void {
		this.store.reset();
	}

	select(selectFunction: (state: FormAddress) => any = (state) => state): Observable<any> {
		return this.store.pipe(select(selectFunction));
	}

	setLoading(loading: boolean): void {
		this.store.update(updateRequestStatus('form-addresses', loading ? 'success' : 'pending'));
	}

	update(newState: FormAddress): void {
		this.store.update(setProps(newState), updateRequestStatus('form-addresses', 'success'));
	}
}
