import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { upperFirst } from 'lodash-es';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventsRepository {
	store = createStore({ name: 'events' }, withProps<EventsStore>({ events: [] }));
	selectEvents$ = this.store.pipe(select((state) => state.events));
	selectLastEvent$ = this.store.pipe(
		select((state) => state.events[state.events.length - 1]),
		filter((event) => !!event)
	);

	dispatchEvent = (event: EventProps) => {
		event.targetName = upperFirst(event.targetName);
		this.store.update((state) => ({
			events: [...state.events, event]
		}));
	};
}

export interface EventProps {
	action?: EventAction;
	targetType?: EventTarget;
	targetName?: string;
	applicantId?: number;
}

export interface EventsStore {
	events: EventProps[];
}

export enum EventTarget {
	Application = 'Application',
	Area = 'Area',
	Section = 'Section',
	Serviceability = 'Serviceability'
}
export enum EventAction {
	Created = 'Created',
	Loaded = 'Loaded',
	Ready = 'Ready',
	Submitted = 'Submitted',
	Exited = 'Exited',
	Completed = 'Completed',
	Calculated = 'Calculated'
}
