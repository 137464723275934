import { EmployerDetailsModel } from '@app/modules/employers/model/employer.model';
import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { IncomeType } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';

export class EmploymentTransformer {
	static fromPayload(
		formEnumsQuery: FormEnumsQuery,
		applicantEmploymentModel: ApplicantEmploymentModel[],
		employerDetailsModel: EmployerDetailsModel[]
	): ApplicantEmploymentModel[] {
		applicantEmploymentModel.forEach((employment) => {
			this.setEmploymentExtract(formEnumsQuery, employment, employerDetailsModel);
		});
		return applicantEmploymentModel.length > 0 ? applicantEmploymentModel : [];
	}

	static setEmploymentExtract(
		formEnumsQuery: FormEnumsQuery,
		employment: ApplicantEmploymentModel,
		employerDetailsModel: EmployerDetailsModel[]
	): void {
		const payg = employment.employmentDetails.payg;
		const paygEmployerId = payg?.employerDetailsSelect?.id;

		const foreignEmployed = employment.employmentDetails.foreignEmployment;

		const selfEmployed = employment.employmentDetails.selfEmployment;

		switch (employment.employmentDetails.typeOfIncome.type) {
			case IncomeType.payg: {
				const matchedEmployer = employerDetailsModel.find((employer) => employer.employerId === paygEmployerId);
				const employerName = paygEmployerId
					? matchedEmployer?.extract ?? payg.employerDetailsSelect?.companyName
					: 'PAYG';
				const basis = payg?.basis ? formEnumsQuery.getOptionLabel('PaygBasis', payg.basis) : '';
				employment.employmentDetails.extract = `${employerName} - ${basis}`;
				break;
			}
			case IncomeType.selfEmployed: {
				const basis = selfEmployed?.basis ? formEnumsQuery.getOptionLabel('SelfEmployedBasis', selfEmployed.basis) : '';
				employment.employmentDetails.extract = `Self employed - ${basis}`;
				break;
			}
			case IncomeType.ForeignEmployed: {
				const matchedEmployer = employerDetailsModel.find(
					(employer) => employer.employerId === foreignEmployed?.employerDetailsSelect?.id
				);
				const basis = foreignEmployed?.basis
					? formEnumsQuery.getOptionLabel('ForeignEmployedBasis', foreignEmployed.basis)
					: '';
				employment.employmentDetails.extract =
					matchedEmployer?.extract ??
					`${foreignEmployed?.employerDetailsSelect?.companyName || ''} (Foreign employed) - ${basis}`;
				break;
			}

			case IncomeType.NotEmployed: {
				employment.employmentDetails.extract = `Not employed`;
				break;
			}
		}
	}
}
