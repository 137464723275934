import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { BaseResponseDTO, RefiConfirmDetailsDTO } from '@app/modules/typings/api';
import { Observable, catchError, map, of } from 'rxjs';

import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { RefiConfirmDetailsModel, RefiConfirmDetailsTransformer } from '../../models/refi-confirm-details';

@Injectable({
	providedIn: 'root'
})
export class RefiConfirmDetailsService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private refiModalService: RefiModalService
	) {
		super();
		this.setJourneyLadmRoute();
	}
	setupState(subSectionId: string): void {
		const applicant = this.applicationDataQuery.getRefiApplicants().find((a) => a.isCurrentUser)!;
		if (subSectionId === 'consent') {
			this.isPrivacyConsented(applicant.id).subscribe((consented: boolean) => {
				this.simpFormlyHandlerService.upsertToState(subSectionId, of([{ agreeTerms: consented }]));
			});
		} else if (subSectionId === 'details') {
			this.simpFormlyHandlerService.upsertToState(
				subSectionId,
				this.getApplicantDetails(applicant.id).pipe(map((details) => [details]))
			);
			this.refiModalService.closeRefiModal(RefiModalName.ConfirmDetailsLoading);
		} else {
			this.simpFormlyHandlerService.upsertToState(subSectionId, of([{}]));
		}
	}

	getApplicantDetails(applicantId: number): Observable<RefiConfirmDetailsModel> {
		return this.getCustom(`ConfirmApplicantDetails/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((details: RefiConfirmDetailsDTO) => {
				return RefiConfirmDetailsTransformer.fromPayload(details, this.applicationDataQuery.applicationId());
			}),
			catchError(() => of({} as RefiConfirmDetailsModel))
		);
	}

	confirmApplicantDetails(applicantId: number): Observable<void> {
		return <Observable<void>>(
			this.patch(
				`ConfirmApplicantDetails/ConfirmDetails/${this.applicationDataQuery.applicationId()}/${applicantId}`,
				null
			)
		);
	}

	consentToPrivacy(applicantId: number): Observable<BaseResponseDTO> {
		return this.patch(
			`ConfirmApplicantDetails/ConsentPrivacy/${this.applicationDataQuery.applicationId()}/${applicantId}`,
			null
		).pipe(
			map((response: BaseResponseDTO) => response),
			catchError(() => of({ status: false } as BaseResponseDTO))
		);
	}

	isApplicantDetailConfirmed(applicantId: number): Observable<boolean> {
		return <Observable<boolean>>(
			this.getCustom(
				`ConfirmApplicantDetails/DetailsConfirmed/${this.applicationDataQuery.applicationId()}/${applicantId}`
			)
		);
	}

	isPrivacyConsented(applicantId: number): Observable<boolean> {
		return <Observable<boolean>>(
			this.getCustom(
				`ConfirmApplicantDetails/PrivacyConsented/${this.applicationDataQuery.applicationId()}/${applicantId}`
			)
		);
	}
}
