import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, OnDestroy, Output } from '@angular/core';

/**
 * Trigger event when element is visible/hidden in viewport
 * example usage: (insideViewport)="onElementView($event)"
 */
@Directive({ selector: '[insideViewport]' })
export class InsideViewportDirective implements AfterViewInit, OnDestroy {
	@Output() insideViewport = new EventEmitter();
	private observer?: IntersectionObserver;

	constructor(@Host() private elementRef: ElementRef) {}

	ngAfterViewInit(): void {
		const options = { root: null, rootMargin: '0px', threshold: 0.0 };
		this.observer = new IntersectionObserver(this.callback, options);
		this.observer.observe(this.elementRef.nativeElement as Element);
	}

	ngOnDestroy() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	private callback: IntersectionObserverCallback = (entries) => {
		entries.forEach((entry) => this.insideViewport.emit(!!entry.isIntersecting));
	};
}
