import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ComplianceCommentsDTO } from '@app/modules/typings/api';

export class ComplianceCommentsTransformer {
	static toPayload(comments: ComplianceComments): ComplianceCommentsDTO {
		return {
			applicationId: comments.applicationId,
			conflicts: comments.anyConflicts === YesNo.Yes ? comments.anyConflictsSpecification : undefined,
			preferredLenders: comments.preferredLenders === YesNo.Yes ? comments.preferredLendersSpecification : undefined,
			conflictsOfInterest:
				comments.anyConflictsOfInterest === YesNo.Yes ? comments.anyConflictsOfInterestSpecification : undefined,
			otherRequirements:
				comments.commentsGrouping.anyOtherRequirements === YesNo.Yes
					? comments.commentsGrouping.anyOtherRequirementsSpecification
					: undefined,
			discussionComments: comments.commentary!,
			additonalNotes: comments.isAnyAdditionalNotes ? comments.additionalNotes : undefined,
			hasConflicts: getNullableBoolean(comments.anyConflicts),
			hasPreferredLenders: getNullableBoolean(comments.preferredLenders),
			hasConflictsOfInterest: getNullableBoolean(comments.anyConflictsOfInterest),
			hasOtherRequirements: getNullableBoolean(comments.commentsGrouping.anyOtherRequirements)
		};
	}
	static fromPayload(comments: ComplianceCommentsDTO): ComplianceComments {
		const commentsModel: ComplianceComments = {
			applicationId: comments.applicationId,
			anyConflicts: getNullableYesNo(comments.hasConflicts),
			anyConflictsSpecification: comments.conflicts,
			preferredLenders: getNullableYesNo(comments.hasPreferredLenders),
			preferredLendersSpecification: comments.preferredLenders,
			anyConflictsOfInterest: getNullableYesNo(comments.hasConflictsOfInterest),
			anyConflictsOfInterestSpecification: comments.conflictsOfInterest,
			commentsGrouping: {
				anyOtherRequirements: getNullableYesNo(comments.hasOtherRequirements),
				anyOtherRequirementsSpecification: comments.otherRequirements
			},
			commentary: comments.discussionComments,
			isAnyAdditionalNotes: comments.additonalNotes ? true : false,
			additionalNotes: comments.additonalNotes
		};
		return commentsModel;
	}
}

export interface ComplianceComments {
	applicationId: number;
	anyConflicts: YesNo | undefined;
	anyConflictsSpecification?: string | undefined;
	preferredLenders: YesNo | undefined;
	preferredLendersSpecification?: string | undefined;
	anyConflictsOfInterest: YesNo | undefined;
	anyConflictsOfInterestSpecification?: string | undefined;
	commentsGrouping: {
		anyOtherRequirements: YesNo | undefined;
		anyOtherRequirementsSpecification: string | undefined;
	};
	commentary?: string | null;
	isAnyAdditionalNotes: boolean;
	additionalNotes?: string | undefined;
}
