import { ForeignTaxAssociationDetailsDTO, ForeignTaxAssociationDTO } from '@app/modules/typings/api';
import { ForeignTaxAssociationStatus, TargetType, TaxResidencyOptions, YesNo } from '../enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '../helper/util';
import { ShortApplicant } from './applicant.model';

export class ForeignTaxAssociationTransformer {
	static fromPayload(data: ForeignTaxAssociationDTO, applicant: Partial<ShortApplicant>): ForeignTaxAssociationModel {
		return {
			applicantId: applicant.id,
			applicantName: applicant.name,
			id: data.id,
			selfCertificationDeclaration: getNullableYesNo(data.selfCertificationDeclaration),
			targetId: data.targetId,
			targetType: data.targetType,
			taxResidencyOutsideAu: data.foreignTaxAssociationDetails?.length
				? TaxResidencyOptions.Yes
				: TaxResidencyOptions.AustraliaOnly,

			foreignTaxAssociationDetails:
				data.foreignTaxAssociationDetails && data.foreignTaxAssociationDetails.length
					? data.foreignTaxAssociationDetails?.map((foreignTaxDetailDTO: ForeignTaxAssociationDetailsDTO) => ({
							id: foreignTaxDetailDTO.id,
							countryOfForeignTaxResidency: foreignTaxDetailDTO.countryCode,
							foreignTaxAssociationStatus: foreignTaxDetailDTO.foreignTaxAssociationStatus,
							taxIdentificationNumber: foreignTaxDetailDTO.taxIdentificationNumber,
							tinNotProvidedReason: foreignTaxDetailDTO.tinNotProvidedReason,
							tinNotProvidedReasonDetail: foreignTaxDetailDTO.tinNotProvidedReasonDetail,
							foreignTaxAssociationId: foreignTaxDetailDTO.foreignTaxAssociationId
					  }))
					: [{}]
		};
	}

	static toPayLoad(model: ForeignTaxAssociationModel, applicationId: number): ForeignTaxAssociationDTO {
		return {
			id: model.id,
			selfCertificationDeclaration: getNullableBoolean(model.selfCertificationDeclaration),
			targetId: model.targetId,
			targetType: model.targetType,
			applicationId: applicationId,
			foreignTaxAssociationDetails: model.foreignTaxAssociationDetails?.map(
				(foreignTaxDetail: ForeignTaxAssociationDetailsModel) =>
					({
						applicationId: applicationId,
						id: foreignTaxDetail.id,
						countryCode: foreignTaxDetail.countryOfForeignTaxResidency,
						foreignTaxAssociationStatus: foreignTaxDetail.foreignTaxAssociationStatus,
						taxIdentificationNumber: foreignTaxDetail.taxIdentificationNumber,
						tinNotProvidedReason: foreignTaxDetail.tinNotProvidedReason,
						tinNotProvidedReasonDetail: foreignTaxDetail.tinNotProvidedReasonDetail,
						foreignTaxAssociationId: foreignTaxDetail.foreignTaxAssociationId
					} ?? [])
			)
		};
	}
}

export interface ForeignTaxAssociationModel {
	applicantId?: number;
	applicantName?: string;
	id?: number;
	selfCertificationDeclaration?: YesNo;
	targetId?: number;
	targetType?: TargetType;
	foreignTaxAssociationDetails?: ForeignTaxAssociationDetailsModel[];
	taxResidencyOutsideAu: TaxResidencyOptions;
}

export interface ForeignTaxAssociationDetailsModel {
	id?: number;
	countryOfForeignTaxResidency?: number;
	foreignTaxAssociationStatus?: ForeignTaxAssociationStatus;
	taxIdentificationNumber?: string;
	tinNotProvidedReason?: number;
	tinNotProvidedReasonDetail?: string;
	foreignTaxAssociationId?: number;
}
