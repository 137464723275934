import { PercentOwned } from '@app/modules/typings/api';
import { ShortApplicant } from './applicant.model';
import { ApplicantType } from '@app/modules/shared/enums/app.enums';

export interface PercentageOwned extends PercentOwned {
	name: string;
}

export class PercentageOwnedTransformer {
	public static fromPayloadPercentageOwned(
		percentsOwnedData: PercentOwned[] | undefined,
		applicants: ShortApplicant[],
		includedApplicantTypeList: ApplicantType[] = []
	): PercentageOwned[] {
		return (applicants || [])
			.filter((v) =>
				includedApplicantTypeList.length ? includedApplicantTypeList?.includes(v.applicantType as ApplicantType) : true
			)
			.map((applicant) => {
				const percentsOwned = percentsOwnedData?.find((po) => po.applicantId === applicant.id);
				return {
					applicantId: applicant.id,
					name: applicant.name,
					percent: !percentsOwned ? (applicants.length > 1 ? 0 : 100) : percentsOwned.percent,
					applicantEntityTypeEnum: percentsOwned?.applicantEntityTypeEnum ?? applicant.applicantEntityType
				};
			});
	}
}
