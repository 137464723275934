<simp-multi-badge-select
	[id]="uniqueId"
	[items]="$any($any(to.options) | selectOptions | async)"
	[tooltipContent]="to.tooltip"
	[formControl]="$any(formControl)"
	[readonly]="!!to.readonly"
	(ngModelChange)="onSelectionChange($event)"
	metadataKey
	[formlyField]="field"
	>{{ to.label }}</simp-multi-badge-select
>
