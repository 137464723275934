import { PropertyModalModel } from '@app/modules/property/models/property-modal.model';
import {
	AccountVariations,
	ClearingFromThisLoan,
	FinancialInstitution,
	MortgageLiabilityType,
	PaymentType,
	Proportions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { calculateMonthly, getNullableYesNo, repaymentToFrequencyFull } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwned, PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { AmountSelect, Term } from '@app/modules/simp-formly/typings/formly-app';
import { Address, HomeLoanDTO } from '@app/modules/typings/api';
import { SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

export class LiabilityHomeLoanTransformer {
	static fromPayload(homeLoan: HomeLoanDTO, applicants: ShortApplicant[]): HomeLoanModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(homeLoan?.percentsOwned, applicants);
		const repaymentAmount = calculateMonthly(
			homeLoan.repaymentAmount,
			repaymentToFrequencyFull(homeLoan.repaymentFrequency)
		);

		const homeLoanModel: HomeLoanModel = {
			id: homeLoan.id,
			applicationId: homeLoan.applicationId,
			financialInstitution: homeLoan.financialInstitution,
			summaryAmount: homeLoan.outstandingBalance,
			repaymentAmount,
			amountEntry: {
				amount: homeLoan.repaymentAmount,
				frequency: homeLoan.repaymentFrequency
			},
			outstandingBalance: homeLoan.outstandingBalance ?? 0, // [TAMA5-9520] When oustanding balance is null it should 0 as per the requirement
			creditLimit: homeLoan.creditLimit ?? 0, // [TAMA5-9520] When credit limit is null it should 0 as per the requirement
			monthlyRepayment: repaymentAmount,
			details: {
				detailsModel: {
					id: homeLoan.id,
					annualInterestRate: homeLoan.interestRate,
					negativelyGeared: homeLoan.negativelyGeared,
					financialInstitution: homeLoan.financialInstitution,
					loanAmount: homeLoan.originalAmount,
					outstandingBalance: homeLoan.outstandingBalance,
					creditLimit: homeLoan.creditLimit,
					availableForRedrawAmount: homeLoan.availableForRedrawAmount,
					isEqualShare: homeLoan.percentOwnedProportions === Proportions.Equal,
					percentsOwned: percentageOwned,
					address: homeLoan.address,
					remainingTerm: {
						number: homeLoan.remainingTermDuration,
						frequency: homeLoan.remainingTermUnits
					},
					repayments: {
						amount: homeLoan.repaymentAmount,
						frequency: homeLoan.repaymentFrequency
					},
					accountNumber: homeLoan.accountNumber,
					accountName: homeLoan.accountName,
					bsb: homeLoan.bsb,
					otherFIName: homeLoan.otherFIName,
					hasArrears: getNullableYesNo(homeLoan.hasArrears),
					arrearsAmount: homeLoan.arrearsAmount,
					arrearsNumberOfMissedPayments: homeLoan.arrearsNumberOfMissedPayments,
					clearingBalanceOnSettlement: getNullableYesNo(homeLoan.closingOnSettlement),
					clearingFromThisLoan: getNullableYesNo(homeLoan.clearingFromThisLoan),
					clearingThisLiability: homeLoan.clearingThisLiability,
					disbursementAmount: homeLoan.refinanceAmount,
					refinanceCosts: homeLoan.refinanceCosts,
					securityPriorityId: homeLoan.liabilitySecurity?.priorityId,
					isForeign: homeLoan.isForeign,
					repaymentType: homeLoan.repaymentType,
					mortgageLiabilityType: homeLoan.mortgageLiabilityType,
					originalLoanTerm: homeLoan.originalLoanTerm,
					originalLoanTermUnitsId: homeLoan.originalLoanTermUnitsId,
					originalPaymentTerm: homeLoan.originalPaymentTerm,
					originalPaymentTypeId: homeLoan.originalPaymentTypeId,
					originalPaymentTermUnitsId: homeLoan.originalPaymentTermUnitsId,
					originalSettlementDate: homeLoan.originalSettlementDate,
					limitIncrease: homeLoan.accountVariations?.find((x) => x === AccountVariations.LimitIncrease)
						? YesNo.Yes
						: YesNo.No,
					accountVariations: homeLoan.accountVariations
				},
				extract: homeLoan.address ? this.appendClearingTypeToAddress(homeLoan) : ''
			} as HomeLoanModelDetails
		};
		return homeLoanModel;
	}

	static appendClearingTypeToAddress(homeLoan: HomeLoanDTO): string {
		const addressText = SimpAddressHelper.buildAddressLine(homeLoan.address as SimpAddress);
		switch (homeLoan.clearingThisLiability) {
			case ClearingFromThisLoan.No:
				return addressText + ' (Not clearing)';
			case ClearingFromThisLoan.Yes:
				return addressText + this.getLoanClearingText(homeLoan.clearingFromThisLoan, 'Full');
			case ClearingFromThisLoan.Partial:
				return addressText + this.getLoanClearingText(homeLoan.clearingFromThisLoan, 'Partial');
			default:
				return addressText;
		}
	}

	static getLoanClearingText(isClearing: boolean | undefined, clearingType: string): string {
		return isClearing
			? ` (Clearing from this loan - ${clearingType})`
			: ` (Clearing from other source - ${clearingType})`;
	}
}

export interface HomeLoanModel {
	financialInstitution: FinancialInstitution;
	applicationId: number;
	id?: number;
	details: HomeLoanModelDetails;
	isClone?: boolean; // Used for only UI purpose
	outstandingBalance?: number;
	creditLimit?: number;
	monthlyRepayment?: number;
	// Used in Company & Trust Financial Position
	summaryAmount?: number;
	repaymentAmount?: number;
	amountEntry?: AmountSelect;
}

export interface HomeLoanModelDetails {
	homeLoanDetails: PropertyModalModel;
	detailsModel: HomeLoanDetails; // For UI purposes only
	extract: string;
}

export interface HomeLoanDetails {
	id?: number;
	financialInstitution: FinancialInstitution;
	outstandingBalance: number;
	creditLimit?: number;
	availableForRedrawAmount?: number;
	loanAmount: number;
	annualInterestRate: number;
	repayments: AmountSelect;
	isEqualShare: boolean;
	percentsOwned?: PercentageOwned[];
	clearingBalanceOnSettlement?: YesNo;
	remainingTerm: Term;
	address?: Address;
	negativelyGeared?: boolean;
	accountNumber?: string;
	accountName?: string;
	bsb?: string;
	otherFIName?: string;
	hasArrears?: YesNo;
	arrearsAmount?: number;
	arrearsNumberOfMissedPayments?: number;
	clearingThisLiability?: ClearingFromThisLoan;
	clearingFromThisLoan?: YesNo;
	refinanceCosts?: number;
	disbursementAmount?: number;
	securityPriorityId?: number;
	isForeign: boolean;
	repaymentType: PaymentType;
	mortgageLiabilityType: MortgageLiabilityType;
	originalLoanTerm?: number;
	originalLoanTermUnitsId?: number;
	originalPaymentTerm?: number;
	originalPaymentTypeId?: number;
	originalPaymentTermUnitsId?: number;
	originalSettlementDate: Date;
	limitIncrease?: YesNo;
	accountVariations?: AccountVariations[];
}
