import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FinaliseAndSubmitResponse, RefiReviewDTO } from '@app/modules/typings/api';
import { Observable, map } from 'rxjs';

import { RefiStore } from '../../enums/refi-store.enum';
import { RefiReviewTransformer, ReviewModel } from '../../models/refi-review.model';

@Injectable({
	providedIn: 'root'
})
export class RefiReviewService extends BaseJourneyService {
	constructor(private applicationDataQuery: ApplicationDataQuery, private formDataService: FormDataService) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.formDataService.upsertStateWithAsyncData(RefiStore.Review, this.fetchReview());
	}

	public fetchReview(): Observable<ReviewModel[]> {
		return this.getCustom(`Review/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((review: RefiReviewDTO) => {
				const applicants = this.applicationDataQuery.getRefiApplicants();
				const otherApplicant = applicants.find((applicant) => !applicant.isCurrentUser);
				const readyToSubmit = otherApplicant ? !!otherApplicant.privacyActConsentSigned : true;
				return RefiReviewTransformer.fromPayload(review, readyToSubmit, applicants);
			})
		);
	}

	public finaliseAndSubmit(): Observable<FinaliseAndSubmitResponse> {
		return this.postCustom(`Application/FinaliseAndSubmit/${this.applicationDataQuery.applicationId()}`, {}).pipe(
			map((response: FinaliseAndSubmitResponse) => response)
		);
	}

	public sendCoBorrowerConsentReminder(): Observable<any> {
		return this.postCustom(`Application/ConsentReminder/${this.applicationDataQuery.applicationId()}`, {});
	}
}
