import {
	InterestOnlyReason,
	InterestType,
	PaymentType,
	PersonalisedPricing
} from '@app/modules/shared/enums/app.enums';
import { passDurationAndFrequency } from '@app/modules/shared/helper/util';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { ProductDTO, ProductFeatureDTO } from '@app/modules/typings/api';
import { EnumObject, FrequencyShort } from '@simpology/client-components/utils';
import { ProductFeature } from './product-features.model';

export class ProductTransformer {
	static fromPayload(productDto: ProductDTO, formDataService: FormDataService): Product {
		const summaryLoanFeatures = formDataService.getStateData(`summary-lodgement-split-loanFeatures-${productDto.id}`);
		return {
			loanId: productDto.id,
			interestType: productDto.interestType,
			fixedRatePeriod: productDto.fixedRatePeriod,
			paymentType: productDto.paymentType,
			interestOnlyPeriod: this.fromPayloadIOPeriodAndFrequency(productDto),
			prepaidInterestPeriod: this.fromPayloadPrepaidInterestPeriod(productDto),
			interestOnlyReason: productDto.interestOnlyReasonTypeId,
			otherReason: productDto.interestOnlyReasonDescription,
			loanPeriod: this.fromLoanPeriodAndFrequency(productDto.loanPeriod, productDto.loanPeriodFrequencyId),
			preferredProductVariationId: productDto.productVariationId,
			preferredProductChannelId: productDto.productChannelId,
			// For summary page we require more information (e.g. new/existing offset) which is stored in the state.  For compliance page we just need which features are selected
			loanFeatures: summaryLoanFeatures.length
				? this.fromSummaryFeatures(
						formDataService.getStateData(`summary-lodgement-split-loanFeatures-${productDto.id}`)
				  )
				: this.fromPayloadFeatures(productDto.features),
			personalisedPricing: productDto.personalisedPricing,
			ruleBasedShowPersonalisedPricing: productDto.ruleBasedShowPersonalisedPricing,
			ruleBasedPersonalisedPricingDefaultValue: productDto.ruleBasedPersonalisedPricingDefaultValue
		} as Product;
	}

	static toPayload(product: Product, applicationId: number): ProductDTO {
		return {
			applicationId: applicationId,
			id: product.loanId,
			interestType: product.interestType,
			fixedRatePeriod: product.fixedRatePeriod,
			paymentType: product.paymentType,
			...this.toIOPeriodAndFrequency(product),
			interestOnlyReasonTypeId: product.interestOnlyReason,
			interestOnlyReasonDescription: product.otherReason,
			...this.toLoanPeriodAndFrequency(Number(product.loanPeriod)),
			productVariationId: product.preferredProductVariationId,
			productChannelId: product.preferredProductChannelId,
			features: this.toPayloadFeatures(product.loanFeatures, product.preferredProductVariationId),
			personalisedPricing: product.personalisedPricing
		} as ProductDTO;
	}

	private static fromPayloadIOPeriodAndFrequency(productDto: ProductDTO) {
		if (!(productDto.paymentType === PaymentType.InterestOnly && productDto.interestOnlyPeriod)) {
			return undefined;
		}
		if (!productDto.interestOnlyPeriodFrequencyId) {
			productDto.interestOnlyPeriodFrequencyId === FrequencyShort.Monthly;
		}
		if (productDto.interestOnlyPeriodFrequencyId === FrequencyShort.Yearly) {
			return productDto.interestOnlyPeriod * 12;
		}
		return productDto.interestOnlyPeriod;
	}

	private static fromPayloadPrepaidInterestPeriod(productDto: ProductDTO): number | undefined {
		return productDto.paymentType === PaymentType.PrepaidInterest && productDto.interestOnlyPeriod
			? productDto.interestOnlyPeriod
			: undefined;
	}

	private static fromLoanPeriodAndFrequency(loanPeriod: number | undefined, loanPeriodFrequencyId: number | undefined) {
		if ((!loanPeriod && loanPeriod !== 0) || !loanPeriodFrequencyId) {
			return;
		}

		if (loanPeriodFrequencyId === FrequencyShort.Yearly) {
			loanPeriod = loanPeriod * 12;
		}

		return loanPeriod;
	}

	private static fromSummaryFeatures(features?: ProductFeature[]): EnumObject[] {
		const totalFeatures = [] as EnumObject[];
		(features ?? [])
			.filter((feature) => feature.isFeatureSelected)
			.forEach((feature) => {
				if (feature.offsetAccountDetails?.offsetAccounts?.length) {
					const offsetAccounts = feature.offsetAccountDetails?.offsetAccounts;
					if (offsetAccounts.some((account) => account.isExistingAccount)) {
						totalFeatures.push({ id: feature.id as number, label: `${feature.featureName} (existing)` });
					}

					if (offsetAccounts.some((account) => !account.isExistingAccount)) {
						totalFeatures.push({ id: feature.id as number, label: `${feature.featureName} (new)` });
					}

					if (offsetAccounts.some((account) => account.hasDebitCard)) {
						totalFeatures.push({ id: feature.id as number, label: `${feature.featureName} (debit)` });
					}
				} else {
					totalFeatures.push({
						id: feature.id,
						label: feature.featureName
					} as EnumObject);
				}
			});

		return totalFeatures;
	}

	private static fromPayloadFeatures(features?: ProductFeatureDTO[]): EnumObject[] {
		return (features ?? [])
			.filter((feature) => feature.selected)
			.map((feature) => ({
				id: feature.featureEnum,
				label: feature.featureName
			}));
	}

	private static toLoanPeriodAndFrequency(months: number | undefined) {
		const durationAndFrequency = passDurationAndFrequency(months);

		return {
			loanPeriod: durationAndFrequency?.amount,
			loanPeriodFrequencyId: durationAndFrequency?.frequency
		};
	}

	private static toPayloadInterestOnlyPeriod(product: Product): number | undefined {
		if (product.paymentType === PaymentType.InterestOnly) {
			return product.interestOnlyPeriod;
		} else if (product.paymentType === PaymentType.PrepaidInterest) {
			return product.prepaidInterestPeriod;
		}
		return undefined;
	}

	private static toIOPeriodAndFrequency(product: Product) {
		const months: number | undefined = this.toPayloadInterestOnlyPeriod(product);
		const durationAndFrequency = passDurationAndFrequency(months);

		return {
			interestOnlyPeriod: durationAndFrequency?.amount,
			interestOnlyPeriodFrequencyId: durationAndFrequency?.frequency
		};
	}

	private static toPayloadFeatures(features?: EnumObject[], preferredProductVariationId?: number): ProductFeatureDTO[] {
		return (features && preferredProductVariationId ? features : []).map((feature) => ({
			featureEnum: feature.id,
			featureName: feature.label,
			selected: true
		}));
	}
}

export interface Product {
	loanId?: number;
	interestType?: InterestType;
	fixedRatePeriod?: number;
	paymentType?: PaymentType;
	interestOnlyPeriod?: number;
	interestOnlyPeriodFrequencyId?: FrequencyShort;
	prepaidInterestPeriod?: number;
	interestOnlyReason?: InterestOnlyReason;
	otherReason?: string;
	loanPeriod?: number;
	loanPeriodFrequencyId?: FrequencyShort;
	preferredProductVariationId?: number;
	preferredProductChannelId?: number;
	loanFeatures: EnumObject[];
	personalisedPricing?: PersonalisedPricing;
	ruleBasedShowPersonalisedPricing?: boolean;
	ruleBasedPersonalisedPricingDefaultValue?: PersonalisedPricing;
}
