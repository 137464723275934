import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const routeTransitionAnimations = trigger('routeAnimations', [
	transition(
		`setup => applicants,
    setup => financialPosition,
    setup => loanServiceability,
    setup => compliance,
    setup => summaryLodgement,
    applicants => financialPosition,
    applicants => loanServiceability,
    applicants => compliance,
    applicants => summaryLodgement,
    financialPosition => loanServiceability,
    financialPosition => compliance,
    financialPosition => summaryLodgement,
    loanServiceability => compliance,
    loanServiceability => summaryLodgement,
    compliance => summaryLodgement`,
		[
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%'
				})
			]),
			query(':enter', [style({ top: '100%', opacity: 0 })]),
			query(':leave', animateChild()),
			group([
				query(':leave', [animate('0.5s .7s ease-out', style({ top: '-100%', opacity: 0 }))]),
				query(':enter', [animate('0.5s .7s ease-out', style({ top: '0%', opacity: 1 }))])
			]),
			query(':enter', animateChild())
		]
	),
	transition(
		`summaryLodgement => compliance,
    summaryLodgement => loanServiceability,
    summaryLodgement => financialPosition,
    summaryLodgement => applicants,
    summaryLodgement => setup,
    compliance => loanServiceability,
    compliance => financialPosition,
    compliance => applicants,
    compliance => setup,
    loanServiceability => financialPosition,
    loanServiceability => applicants,
    loanServiceability => setup,
    financialPosition => applicants,
    financialPosition => setup,
    applicants => setup`,
		[
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%'
				})
			]),
			query(':enter', [style({ top: '-100%', opacity: 0 })]),
			query(':leave', animateChild()),
			group([
				query(':leave', [animate('0.5s .7s ease-out', style({ top: '100%', opacity: 0 }))]),
				query(':enter', [animate('0.5s .7s ease-out', style({ top: '0%', opacity: 1 }))])
			]),
			query(':enter', animateChild())
		]
	)
]);

const left = [
	query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
	group([
		query(
			':enter',
			[style({ transform: 'translateX(-100%)' }), animate('0.3s ease-out', style({ transform: 'translateX(0%)' }))],
			{
				optional: true
			}
		),
		query(
			':leave',
			[style({ transform: 'translateX(0%)' }), animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))],
			{
				optional: true
			}
		)
	])
];

const right = [
	query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
	group([
		query(
			':enter',
			[style({ transform: 'translateX(100%)' }), animate('0.3s ease-out', style({ transform: 'translateX(0%)' }))],
			{
				optional: true
			}
		),
		query(
			':leave',
			[style({ transform: 'translateX(0%)' }), animate('0.3s ease-out', style({ transform: 'translateX(-100%)' }))],
			{
				optional: true
			}
		)
	])
];

export const routeTransitionSlideAnimations = trigger('routeSlideAnimations', [
	transition('* => right', right),
	transition('* => left', left),
	transition('* => right1', right),
	transition('* => left1', left)
]);

export const slideIn = trigger('slideIn', [
	transition(':enter', [
		style({ transform: 'translateY(100%)' }),
		animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
	]),
	transition(':leave', [
		style({ opacity: 1, height: 'auto' }),
		animate('10ms ease-out', style({ opacity: 0, height: 0 }))
	])
]);
