import { PreferredContactCompanyTrust } from '@app/modules/shared/enums/app.enums';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { TrustContactDetailsDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class TrustContactDetailsTransformer {
	static fromPayload(details: TrustContactDetailsDTO): TrustContactDetailsModel {
		return {
			applicationId: details.applicationId,
			id: details.id,
			email: details.email,
			mobile: PhoneNumberHelper.fromPayload(details.officeMobile),
			officePhone: PhoneNumberHelper.fromPayload(details.officePhone),
			fax: PhoneNumberHelper.fromPayload(details.officeFax),
			preferredContact: details.preferredContact,
			webAddress: details.webAddress
		};
	}

	static toPayload(
		applicationId: number,
		applicantId: number,
		details: TrustContactDetailsModel
	): TrustContactDetailsDTO {
		return {
			applicationId: applicationId,
			id: applicantId,
			email: details.email,
			officeFax: PhoneNumberHelper.toPayLoad(details.fax),
			officeMobile: PhoneNumberHelper.toPayLoad(details.mobile),
			officePhone: PhoneNumberHelper.toPayLoad(details.officePhone),
			preferredContact: details.preferredContact,
			webAddress: details.webAddress
		};
	}
}

export interface TrustContactDetailsModel {
	applicationId: number;
	id: number;
	email: string;
	mobile?: PhoneDetails;
	officePhone?: PhoneDetails;
	fax?: PhoneDetails;
	preferredContact: PreferredContactCompanyTrust;
	webAddress: string;
}
