import { PropertyModalModel, PropertyModalModelTransformer } from '@app/modules/property/models/property-modal.model';
import {
	PropertySecurityDetails,
	PropertySecurityDetailsModelTransformer
} from '@app/modules/property/models/property-security-details.model';
import { PrimaryUsage } from '@app/modules/shared/enums/app.enums';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PropertyDTO } from '@app/modules/typings/api';
import { SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

export class AssetPropertyModelTransformer {
	static fromPayload(
		assetPropertyData: PropertyDTO,
		applicants: ShortApplicant[],
		formEnumsQuery?: FormEnumsQuery
	): RealEstateModel {
		const assetPropertyDataModel: RealEstateModel = {
			id: assetPropertyData.id,
			summaryAmount: assetPropertyData.estimatedValue,
			details: {
				propertyDetails: PropertyModalModelTransformer.fromPayload(assetPropertyData, applicants),
				extract: assetPropertyData.address
					? SimpAddressHelper.buildAddressLine(assetPropertyData.address as SimpAddress)
					: '',
				typeOfProperty: { type: assetPropertyData.primaryUsage }
			},
			amountEntry: {
				amount: assetPropertyData.estimatedValue,
				frequency: assetPropertyData.propertyEvaluationType
			}
		};

		if (assetPropertyData.toBeUsedAsSecurity && formEnumsQuery) {
			assetPropertyDataModel.securityDetails = PropertySecurityDetailsModelTransformer.fromPayload(
				assetPropertyData,
				formEnumsQuery
			);
		}

		return assetPropertyDataModel;
	}
}

export interface RealEstateModel {
	details: RealEstateDetails;
	amountEntry: AmountSelect;
	id?: number;
	summaryAmount?: number;
	securityDetails?: PropertySecurityDetails;
	isClone?: boolean; // Used for only UI purpose
}

export interface RealEstateDetails {
	typeOfProperty: { type: PrimaryUsage };
	propertyDetails: PropertyModalModel;
	extract: string;
}
