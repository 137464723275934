import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DigitalWidgetsModule } from './modules/digital-widgets/digital-widgets.module';
import { OtherModule } from './modules/other/other.module';
import { PreCheckModule } from './modules/pre-check/pre-check.module';
import { SharedModule } from './modules/shared/shared.module';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
	declarations: [AppComponent, NotFoundComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule.withConfig({ disableAnimations: window.matchMedia('(prefers-reduced-motion)').matches }),
		AppRoutingModule,
		SharedModule,
		PreCheckModule,
		OtherModule,
		DigitalWidgetsModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

//TODO APP_INITIALIZER FOR SETBRANDING TO BE DONE WHEN UPGRADING TO ANGULAR 14.
//CURRENTLY FAILING IN ANGULAR 13 / 11 IN NGX_TOASTER BUT WORKS IN ANGULAR 14.
