import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo, hasValue } from '@app/modules/shared/helper/util';
import { CompanyTrustIncomeDTO, CompanyTrustIncomesDTO } from '@app/modules/typings/api';
import { omit, some } from 'lodash-es';

export class CompanyTrustIncomeTransformer {
	static toPayloadCompanyProfit(
		model: CompanyTrustProfit,
		applicationId: number,
		applicantId: number
	): CompanyTrustIncomeDTO {
		return {
			profitBeforeTax: model.profitBeforeTax,
			profitAfterTax: model.profitAfterTax,
			addbackSalary: model.addbackSalary,
			addbackBonus: model.addbackBonus,
			addbackAllowance: model.addbackAllowance,
			addbackDepreciation: model.addbackDepreciation,
			addbackInterest: model.addbackInterest,
			addbackLease: model.addbackLease,
			addbackNonCashBenefit: model.addbackNonCashBenefit,
			addbackNonRecurringExpense: model.addbackNonRecurringExpense,
			addbackCarExpense: model.addbackCarExpense,
			addbackSuperannuation: model.addbackSuperannuation,
			addbackCarryForwardLosses: model.addbackCarryForwardLosses,
			addbackAmortisationOfGoodwill: model.addbackAmortisationOfGoodwill,
			otherAddbackAmount: model.otherAddbackAmount,
			isIncomeGreaterThanPreviousYear: getNullableBoolean(model.incomeGreaterThanPreviousYear),
			isTaxOfficeAssessmentDone: getNullableBoolean(model.taxAssessmentDone),
			financialYearId: model.financialYearId,
			applicationId: applicationId,
			applicantId: applicantId
		} as CompanyTrustIncomeDTO;
	}

	static hasProfitValue(currentFY: CompanyTrustProfit) {
		const parsed = omit(currentFY, ['financialYear', 'financialYearId']);
		return some(parsed, (v) => hasValue(v));
	}

	static toPayload(
		model: CompanyTrustIncome,
		applicationId: number,
		companyApplicantId: number
	): CompanyTrustIncomesDTO {
		const payload: CompanyTrustIncomesDTO = { incomes: [] };
		if (this.hasProfitValue(model.currentFY)) {
			const currentFY = this.toPayloadCompanyProfit(model.currentFY, applicationId, companyApplicantId);
			payload.incomes.push(currentFY);
		}
		if (this.hasProfitValue(model.previousFY)) {
			const previousFY = this.toPayloadCompanyProfit(model.previousFY, applicationId, companyApplicantId);
			payload.incomes.push(previousFY);
		}
		if (this.hasProfitValue(model.secondPreviousFY)) {
			const secondPreviousFY = this.toPayloadCompanyProfit(model.secondPreviousFY, applicationId, companyApplicantId);
			payload.incomes.push(secondPreviousFY);
		}

		if (this.hasProfitValue(model.thirdPreviousFY)) {
			const thirdPreviousFY = this.toPayloadCompanyProfit(model.thirdPreviousFY, applicationId, companyApplicantId);
			payload.incomes.push(thirdPreviousFY);
		}

		return payload;
	}

	static fromPayload(companyIncomes: CompanyTrustIncomesDTO): CompanyTrustIncome {
		const incomes = companyIncomes.incomes;
		const companyIncome: CompanyTrustIncome = this.getAmountYears(incomes);

		return companyIncome;
	}
	static getFinancialYearLabel(financialYear: number): string {
		return `FY ${String(financialYear - 1).slice(-2)}/${String(financialYear).slice(-2)}`;
	}

	static getAmountYears(incomes: CompanyTrustIncomeDTO[]): CompanyTrustIncome {
		const model = {
			currentFY: {},
			previousFY: {},
			secondPreviousFY: {},
			thirdPreviousFY: {}
		} as CompanyTrustIncome;

		incomes?.forEach((income, index) => {
			if (index === 0) {
				this.fillProfit(income, model.currentFY);
			}
			if (index === 1) {
				this.fillProfit(income, model.previousFY);
			}
			if (index === 2) {
				this.fillProfit(income, model.secondPreviousFY);
			}
			if (index === 3) {
				this.fillProfit(income, model.thirdPreviousFY);
			}
		});

		return model;
	}

	static fillProfit(income: CompanyTrustIncomeDTO, profit: CompanyTrustProfit) {
		profit.financialYearId = income.financialYearId;
		profit.financialYear = this.getFinancialYearLabel(income.financialYearId);
		profit.profitBeforeTax = income.profitBeforeTax;
		profit.profitAfterTax = income.profitAfterTax;
		profit.addbackSalary = income.addbackSalary;
		profit.addbackBonus = income.addbackBonus;
		profit.addbackAllowance = income.addbackAllowance;
		profit.addbackDepreciation = income.addbackDepreciation;
		profit.addbackInterest = income.addbackInterest;
		profit.addbackLease = income.addbackLease;
		profit.addbackNonCashBenefit = income.addbackNonCashBenefit;
		profit.addbackNonRecurringExpense = income.addbackNonRecurringExpense;
		profit.addbackCarExpense = income.addbackCarExpense;
		profit.addbackSuperannuation = income.addbackSuperannuation;
		profit.addbackCarryForwardLosses = income.addbackCarryForwardLosses;
		profit.addbackAmortisationOfGoodwill = income.addbackAmortisationOfGoodwill;
		profit.incomeGreaterThanPreviousYear = getNullableYesNo(income.isIncomeGreaterThanPreviousYear);
		profit.taxAssessmentDone = getNullableYesNo(income.isTaxOfficeAssessmentDone);
	}
}

export interface CompanyTrustIncome {
	labels: { Addback: string; companyProfit: string };
	currentFY: CompanyTrustProfit;
	previousFY: CompanyTrustProfit;
	secondPreviousFY: CompanyTrustProfit;
	thirdPreviousFY: CompanyTrustProfit;
}
export interface CompanyTrustProfit {
	financialYearId: number;
	profitBeforeTax: number;
	profitAfterTax: number;
	addbackSalary: number;
	addbackBonus: number;
	addbackAllowance: number;
	addbackDepreciation: number;
	addbackInterest: number;
	addbackLease: number;
	addbackNonCashBenefit: number;
	addbackNonRecurringExpense: number;
	addbackCarExpense: number;
	addbackSuperannuation: number;
	addbackCarryForwardLosses: number;
	addbackAmortisationOfGoodwill: number;
	taxAssessmentDone?: YesNo;
	incomeGreaterThanPreviousYear?: YesNo;
	financialYear: string;
	otherAddbackAmount: number;
}
