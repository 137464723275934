/**
 * Filled in main.ts by config fetch
 * This is dynamically generated at runtime.
 * For local, it fetches from projects\loanapp-client\src\assets\configs\env.config.json
 * For remote, it fetched from assets\configs\env.config.json (filled with jenkins based on environment and channel)
 *
 */
export const ENV_CONFIG: EnvConfig = {} as EnvConfig;

interface EnvConfig {
	featureFlags: {
		loanAppX: boolean;
		configMode: boolean;
	};
	clientId: string;
	identityUrl: string;
	apiUrl: string;
	ladmApiUrl: string;
	metaApiUrl: string;
	adminUrl: string;
	omniUrl: string;
	logOutRedirectUri?: string;
	enableIntercom?: boolean;
	intercomAppId?: string;
	intercomSecret?: string;
	enableSessionExpiryWarning?: boolean;
	autoSilentRenew?: boolean;
	enableNotFoundPage?: boolean;
	dynamicIdentityUrl?: Object;
	dynamicApiUrl?: Object;
}
