import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, merge } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, share, take, takeUntil } from 'rxjs';
import { FormlyRendererComponent } from '../components/formly-renderer/formly-renderer.component';
import { formlyRegisterHooks, getFormField } from '../helpers/simp-formly.helper';

@Injectable({ providedIn: 'root' })
export class SimpFormlyModalService {
	openExistingModal$: BehaviorSubject<existingModalParams | null> = new BehaviorSubject<existingModalParams | null>(
		null
	);
	constructor(private modalService: NgbModal) {}

	/**
	 * Open field as modal
	 * @param field
	 * @param key
	 * @param options
	 * @param mobileModal
	 */
	openModal(
		field: FormlyFieldConfig,
		key?: string,
		options?: NgbModalOptions,
		mobileModal?: boolean,
		customTitle?: string
	): CustomNgbModalRef {
		const updatedModalField = key ? field.fieldGroup?.find((fg) => fg.key === key) : field;
		if (!updatedModalField) {
			console.error('Modal field with key not found: ', key);
		}
		if (updatedModalField?.props && customTitle) {
			updatedModalField.props.label = customTitle;
		}

		let initialValue = cloneDeep((updatedModalField?.model as unknown) ?? {});
		initialValue = merge(initialValue, updatedModalField?.formControl?.value as unknown);

		if (!options) {
			options = {};
		}

		let predefinedFunctions: () => boolean;
		if (options.beforeDismiss) {
			predefinedFunctions = options?.beforeDismiss as () => boolean;
		}

		options.beforeDismiss = () => {
			const result = predefinedFunctions ? predefinedFunctions() : true;
			if (result) {
				return this.resetUnsavedChanges(updatedModalField, initialValue);
			}
			return true;
		};

		if (updatedModalField?.props) {
			updatedModalField.props.isModalOpen = true;
		}

		const modalRef = this.modalService.open(updatedModalField?.props?.contentTemplate, {
			size: 'xl',
			centered: true,
			scrollable: true,
			...options
		});
		if (mobileModal) {
			setTimeout(() => {
				const lastOpenedModal = document.getElementsByClassName('loanapp-x-modal');
				lastOpenedModal[lastOpenedModal.length - 1].classList.add('loanapp-x-modal--show');
			}, 100);
		}

		const newModalRef = Object.assign(modalRef, {
			action: (updatedModalField?.props?.action as Action).pipe(
				takeUntil(modalRef.closed),
				takeUntil(modalRef.dismissed),
				share()
			)
		}) as CustomNgbModalRef;

		newModalRef.action.subscribe({
			complete: () => {
				if (updatedModalField?.props) {
					updatedModalField.props.isModalOpen = false;
				}
			}
		});

		return newModalRef;
	}

	resetUnsavedChanges(modalField?: FormlyFieldConfig, initialValue?: unknown): boolean {
		const isModalDirty = !!(modalField?.formControl?.touched && modalField?.formControl?.dirty);
		if (isModalDirty) {
			modalField?.formControl?.reset(initialValue);
		}
		return true;
	}
	public openSlideModal(field: FormlyFieldConfig, modalName: string, callBack: Function): void {
		if (field.parent) {
			const modalRef = this.openModal(
				field.parent,
				modalName,
				{
					windowClass: 'mobile-modal-class',
					animation: false,
					backdrop: false
				},
				true
			);

			const subscription = modalRef.action.subscribe((action) => {
				callBack(action);
				modalRef.close();
				subscription.unsubscribe();
			});
		}
	}

	closeExistingModal() {
		this.openExistingModal$.next(null);
	}

	/**
	 * Generic method to open modal from any schema. Schema would be loaded on FormlyRenderer component.
	 * @param areaField
	 * @param path
	 * @param modelData
	 * @returns CustomNgbModalRef
	 */
	openFieldModal(areaField: FormlyFieldConfig[] | undefined, path: string, modelData: unknown): CustomNgbModalRef {
		const modalFields = getFormField(areaField, path);
		const actionSubject = new Subject<string>();
		if (modalFields?.props) {
			modalFields.props.eagerRender = true;
			formlyRegisterHooks(areaField, path, {
				afterViewInit: (field) => {
					setTimeout(() => {
						const action = field.props?.action as Action;
						if (action) {
							action.pipe(take(1)).subscribe((modalAction) => {
								actionSubject.next(modalAction);
							});
						}
					});
				}
			});
		}

		const modalRef = this.modalService.open(FormlyRendererComponent, {
			backdrop: 'static',
			centered: true,
			keyboard: false,
			animation: true
		});
		const modalComponent = modalRef.componentInstance as FormlyRendererComponent;
		modalComponent.model = modelData;
		if (modalFields) {
			modalComponent.modalFields = [modalFields];
		}

		const newModalRef = Object.assign(modalRef, {
			action: actionSubject.asObservable().pipe(takeUntil(modalRef.closed), takeUntil(modalRef.dismissed), share())
		}) as CustomNgbModalRef;
		return newModalRef;
	}
}

export interface CustomNgbModalRef extends NgbModalRef {
	action: Action;
}

type Action = Observable<'close' | 'cancel' | 'submit' | 'delete'>;

export interface existingModalParams {
	key?: string;
	index?: number;
}
