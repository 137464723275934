import { FormlyButtonInputModel } from '@app/modules/simp-formly/models/formly-button-input.model';

export class LoanWriterCompanyTransformer {
	static createLoanWriterCompanyModel(dto: LoanWriterCompanyDto): LoanWriterCompanyModel {
		return {
			extract: dto?.companyName,
			loanWriterCompanyModal: this.fromPayload(dto)
		} as LoanWriterCompanyModel;
	}

	static fromPayload(dto: LoanWriterCompanyDto) {
		return {
			companyName: dto?.companyName,
			abn: dto?.abn,
			licenceTypeId: dto?.licenceTypeId,
			licenceNumber: dto?.licenceNumber,
			otherIdentifier: dto?.otherIdentifier,
			officePhoneNumber: dto?.officePhoneNumber,
			email: dto?.email,
			webAddress: dto?.webAddress,
			addressId: dto?.addressId
		} as LoanWriterCompany;
	}

	static toPayload(loanWriterCompanyModel: LoanWriterCompanyModel): LoanWriterCompanyDto {
		return {
			companyName: loanWriterCompanyModel?.loanWriterCompanyModal?.companyName,
			abn: loanWriterCompanyModel?.loanWriterCompanyModal?.abn,
			licenceTypeId: loanWriterCompanyModel?.loanWriterCompanyModal?.licenceTypeId,
			licenceNumber: loanWriterCompanyModel?.loanWriterCompanyModal?.licenceNumber,
			otherIdentifier: loanWriterCompanyModel?.loanWriterCompanyModal?.otherIdentifier,
			officePhoneNumber: loanWriterCompanyModel?.loanWriterCompanyModal?.officePhoneNumber,
			email: loanWriterCompanyModel?.loanWriterCompanyModal?.email,
			webAddress: loanWriterCompanyModel?.loanWriterCompanyModal?.webAddress,
			addressId: loanWriterCompanyModel?.loanWriterCompanyModal?.addressId
		} as LoanWriterCompanyDto;
	}
}

export interface LoanWriterCompanyModel extends FormlyButtonInputModel {
	loanWriterCompanyModal: LoanWriterCompany;
}

export interface LoanWriterCompany {
	companyName: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: string;
	accreditationNumber: string;
	otherIdentifier: string;
	officePhoneNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
}

export interface LoanWriterCompanyDto {
	companyName: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: string;
	accreditationNumber: string;
	otherIdentifier: string;
	officePhoneNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
}
