import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyRadioButton = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'radio-button',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'radio-button',
			className: `${property.templateOptions?.class ?? (property.class || 'col-2')}`,
			templateOptions: {
				...property.templateOptions
			},
			validation: {
				messages: property.errorMessages ?? {}
			},
			hideExpression: parseExpressionString(property.hide),
			defaultValue: property.templateOptions?.defaultValue,
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
