import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { hasInvalidField } from '@app/modules/shared/helper/util';
import { FieldArrayTypeConfig, FieldType } from '@ngx-formly/core';

import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'formly-object',
	templateUrl: './formly-object.component.html',
	styleUrls: ['./formly-object.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyObjectComponent extends FieldType<FieldArrayTypeConfig> implements OnInit, AfterViewInit, OnDestroy {
	private valueChangeSubject: Subject<any> = new Subject();
	private destroy$: Subject<void> = new Subject();
	ngOnInit(): void {
		/**
		 * Watch for changes in each control inside sub section
		 */
		this.to.onStatusChangedToValid = this.valueChangeSubject.asObservable();
		this.to.valueChangeSubject = this.valueChangeSubject;
	}

	ngAfterViewInit(): void {
		this.setupChangeDetection();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setupChangeDetection() {
		return this.formControl.valueChanges
			.pipe(
				debounceTime(this.to.debounceTime === undefined ? 1000 : (this.to.debounceTime as number)),
				takeUntil(this.destroy$),
				filter(() => {
					return (
						(this.formControl.valid || ((this.to.saveInvalid as boolean) && !hasInvalidField(this.formControl))) &&
						this.formControl.dirty
					);
				})
			)
			.subscribe((data: unknown) => {
				this.valueChangeSubject.next({ data });
			});
	}
}
