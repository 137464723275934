import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyTickBox = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'tick-box',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-2')}`,
			templateOptions: {
				...property.templateOptions
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			defaultValue: property.templateOptions?.defaultValue
		}
	}
});
