<simp-duration
	[id]="uniqueId"
	[formControl]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[tooltipContent]="props.tooltip"
	[readonly]="!!props.readonly"
	[maxMonths]="$any(props.max)"
	[minMonths]="$any(props.min)"
	[durationType]="props.handler"
	metadataKey
	[formlyField]="field"
	>{{ props.label }}
</simp-duration>
