import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';
import { validateSharePercents } from '../validators/formly-custom-validators';

export const formlyArray = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'array',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'array',
			className: `${property.templateOptions?.class ?? (property.class || 'col-12')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options
			},
			hideExpression: parseExpressionString(property.hide),
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			defaultValue: property.templateOptions?.defaultValue,
			expressionProperties: parseExpressionProperties(property),
			validators: {
				validSharePercents: validateSharePercents
			}
		}
	},
	items: {
		type: 'object',
		required: property.required,
		properties: childProperties,
		widget: {
			formlyConfig: {
				className: property.templateOptions?.customClass as string,
				templateOptions: {
					...property.templateOptions,
					customClass: `${property.templateOptions?.arrayType ? '' : property.templateOptions?.customClass || ''}`
				}
			}
		}
	}
});
