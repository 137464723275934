import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	BorrowingReason,
	EnumMitigantFactor,
	EnumSignificantChange,
	PrimaryPurposeLoanPurpose,
	SalesChannelType,
	TargetType,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { Address, AnticipatedChangesDTO, AttendeeDTO, InterviewDTO } from '@app/modules/typings/api';
import { EnumObject } from '@simpology/client-components/utils';

export class InterviewTransformer {
	static toPayload(interview: Interview): InterviewDTO {
		return {
			id: interview.id,
			applicationId: interview.applicationId,
			interviewDate: interview.interviewDate,
			address: interview.address,
			attendees: interview.applicantPresentBadges?.map(
				(applicant) =>
					({
						personId: applicant.id,
						type: applicant.type ?? TargetType.PersonApplicant
					} as AttendeeDTO)
			),
			type: interview.type,
			benefitsAllApplicants: getNullableBoolean(interview.isClearBenefit),
			allUnderstandEnglish: getNullableBoolean(interview.doAllUnderstandEnglish),
			interpreterRequired: interview.interpreterRequired ?? false,
			reasonForBorrowing: interview.reasonForBorrowing,
			primaryPurpose: interview.primaryPurpose,
			borrowingAmount: interview.borrowingAmount,
			loanTerm: interview.loanTerm,
			anticipatedChanges: interview.anticipatedChangesList?.map((change) => {
				return (
					({
						id: change.id ?? CONSTANTS.NEW_ID,
						applicantId: change.applicantId,
						applicantAnticipatesChanges: getNullableBoolean(change.applicantAnticipatesChanges),
						anticipatedChanges: change.applicantAnticipatesChanges ? change.anticipatedChanges : null,
						significantChanges: getNullableBoolean(change.applicantAnticipatesChanges)
							? this.significantChangesToPayload(change.significantChanges)
							: [],
						mitigants: getNullableBoolean(change.applicantAnticipatesChanges)
							? this.mitigantsToPayload(change.mitigants)
							: []
					} as AnticipatedChangesDTO) ?? []
				);
			}),
			exitStrategy: interview.exitStrategy,
			additionalNotes: interview.additionalNotes,
			salesChannelTypeId: interview.salesChannel,
			incomeDerivedBy: interview.incomeUsedDescription,
			applicantsUnderstandRefinance: interview.refinanceUnderstood,
			discussedRefinanceWithCustomer: interview.refinanceExplained,
			isHLIGManuallyCompleted: interview.isHLIGManuallyCompleted
		};
	}

	static fromPayload(interview: InterviewDTO, applicants: EnumObject[]): Interview {
		return {
			id: interview.id,
			applicationId: interview.applicationId,
			interviewDate: interview.interviewDate,
			address: interview.address,
			applicantPresentBadges: interview.attendees?.map(
				(attendee) =>
					({
						id: attendee.personId,
						label: ''
					} as EnumObject)
			),
			type: interview.type,
			isClearBenefit: getNullableYesNo(interview.benefitsAllApplicants),
			doAllUnderstandEnglish: getNullableYesNo(interview.allUnderstandEnglish),
			interpreterRequired: !!interview.interpreterRequired,
			reasonForBorrowing: interview.reasonForBorrowing,
			primaryPurpose: interview.primaryPurpose,
			borrowingAmount: interview.borrowingAmount,
			loanTerm: interview.loanTerm,
			anticipatedChangesList: this.getAnticipatedChanges(interview.anticipatedChanges, applicants),
			exitStrategy: interview.exitStrategy,
			isAdditionalNotes: !!interview.additionalNotes,
			additionalNotes: interview.additionalNotes,
			salesChannel: interview.salesChannelTypeId,
			incomeUsedDescription: interview.incomeDerivedBy,
			refinanceExplained: interview.discussedRefinanceWithCustomer,
			refinanceUnderstood: interview.applicantsUnderstandRefinance,
			isHLIGManuallyCompleted: interview.isHLIGManuallyCompleted
		};
	}

	static getAnticipatedChanges(
		anticipatedChanges: AnticipatedChangesDTO[],
		applicants: EnumObject[]
	): AnticipatedChanges[] {
		return applicants.map((applicant) => {
			return {
				applicantId: applicant.id,
				applicantName: applicant.label,
				applicantAnticipatesChanges: anticipatedChanges.find(
					(app: AnticipatedChangesDTO) => app.applicantId === applicant.id
				)?.applicantAnticipatesChanges
					? YesNo.Yes
					: YesNo.No,
				anticipatedChanges:
					anticipatedChanges.find(
						(app: AnticipatedChangesDTO) => app.applicantId === applicant.id && app.applicantAnticipatesChanges
					)?.anticipatedChanges ?? '',
				significantChanges: this.getSignificantChanges(applicant, anticipatedChanges),
				mitigants: this.getMitigantFactors(applicant, anticipatedChanges)
			};
		});
	}

	static getSignificantChanges(
		applicant: EnumObject,
		anticipatedChanges: AnticipatedChangesDTO[]
	): SignificantChange[] {
		const anticipatedChange: AnticipatedChangesDTO | undefined = anticipatedChanges.find(
			(app: AnticipatedChangesDTO) => app.applicantId === applicant.id
		);

		if (anticipatedChange?.significantChanges?.length === 0) {
			return [{} as SignificantChange];
		}

		return (
			anticipatedChange?.significantChanges?.map((change) => {
				return {
					id: change.id ?? CONSTANTS.NEW_ID,
					changeId: change?.changeId,
					startDate: change?.startDate,
					endDate: change?.endDate,
					monthlyFinancialImpact: change?.monthlyFinancialImpact,
					description: change?.description
				};
			}) ?? [{} as SignificantChange]
		);
	}

	static getMitigantFactors(applicant: EnumObject, anticipatedChanges: AnticipatedChangesDTO[]): MitigantFactor[] {
		const anticipatedChange: AnticipatedChangesDTO | undefined = anticipatedChanges.find(
			(app: AnticipatedChangesDTO) => app.applicantId === applicant.id
		);

		if (anticipatedChange?.mitigants?.length === 0) {
			return [{} as MitigantFactor];
		}

		return (
			anticipatedChange?.mitigants?.map((factor) => {
				return {
					id: factor.id,
					factorId: factor?.factorId,
					description: factor?.description,
					lenderHeldSavingsAccount: getNullableYesNo(factor?.savingsOrSuperannuationLenderHeldSavingsAccount),
					lenderHeldSavingsAccountDescription: factor?.savingsOrSuperannuationLenderHeldSavingsAccountDescription,
					otherLenderHeldSavingsAccount: getNullableYesNo(factor?.savingsOrSuperannuationOtherLenderHeldSavingsAccount),
					otherLenderHeldSavingsAccountDescription:
						factor?.savingsOrSuperannuationOtherLenderHeldSavingsAccountDescription,
					lenderHeldSecurity: getNullableYesNo(factor?.saleOfAssetsLenderHeldSecurity),
					lenderHeldSecurityDescription: factor?.saleOfAssetsLenderHeldSecurityDescription,
					otherLenderHeldSecurity: getNullableYesNo(factor?.saleOfAssetsOtherLenderHeldSecurity),
					otherLenderHeldSecurityDescription: factor?.saleOfAssetsOtherLenderHeldSecurityDescription,
					otherAssets: getNullableYesNo(factor?.saleOfAssetsOtherAssets),
					otherAssetsDescription: factor?.saleOfAssetsOtherAssetsDescription,
					shares: getNullableYesNo(factor?.saleOfAssetsShares),
					sharesDescription: factor?.saleOfAssetsSharesDescription
				};
			}) ?? [{} as MitigantFactor]
		);
	}

	static significantChangesToPayload(significantChanges: SignificantChange[] | undefined): SignificantChange[] {
		return (
			significantChanges?.map((change) => {
				return {
					id: change.id ?? CONSTANTS.NEW_ID,
					changeId: change?.changeId,
					startDate: change?.startDate,
					endDate: change?.endDate,
					monthlyFinancialImpact: change?.monthlyFinancialImpact,
					description: change?.description
				};
			}) ?? [{} as SignificantChange]
		);
	}

	static mitigantsToPayload(mitigants: MitigantFactor[] | undefined): MitigantFactor[] {
		return (
			mitigants?.map((mitigant) => {
				return {
					id: mitigant.id ?? CONSTANTS.NEW_ID,
					factorId: mitigant.factorId,
					description: mitigant.description,
					savingsOrSuperannuationLenderHeldSavingsAccount: getNullableBoolean(mitigant?.lenderHeldSavingsAccount),
					savingsOrSuperannuationLenderHeldSavingsAccountDescription: mitigant?.lenderHeldSavingsAccount
						? mitigant?.lenderHeldSavingsAccountDescription
						: null,
					savingsOrSuperannuationOtherLenderHeldSavingsAccount: getNullableBoolean(
						mitigant?.otherLenderHeldSavingsAccount
					),
					savingsOrSuperannuationOtherLenderHeldSavingsAccountDescription: mitigant?.otherLenderHeldSavingsAccount
						? mitigant?.otherLenderHeldSavingsAccountDescription
						: null,
					saleOfAssetsLenderHeldSecurity: getNullableBoolean(mitigant?.lenderHeldSecurity),
					saleOfAssetsLenderHeldSecurityDescription: mitigant?.lenderHeldSecurity
						? mitigant?.lenderHeldSecurityDescription
						: null,
					saleOfAssetsOtherLenderHeldSecurity: getNullableBoolean(mitigant?.otherLenderHeldSecurity),
					saleOfAssetsOtherLenderHeldSecurityDescription: mitigant?.otherLenderHeldSecurity
						? mitigant?.otherLenderHeldSecurityDescription
						: null,
					saleOfAssetsOtherAssets: getNullableBoolean(mitigant?.otherAssets),
					saleOfAssetsOtherAssetsDescription: mitigant?.otherAssets ? mitigant?.otherAssetsDescription : null,
					saleOfAssetsShares: getNullableBoolean(mitigant?.shares),
					saleOfAssetsSharesDescription: mitigant?.shares ? mitigant?.sharesDescription : null
				};
			}) ?? [{} as MitigantFactor]
		);
	}
}

export interface Interview {
	id?: number;
	applicationId: number;
	interviewDate: string | undefined;
	address: Address | undefined;
	applicantPresentBadges?: EnumObject[];
	type: number | undefined;
	isClearBenefit: YesNo | undefined;
	doAllUnderstandEnglish: YesNo | undefined;
	interpreterRequired: boolean;
	reasonForBorrowing: BorrowingReason | undefined;
	primaryPurpose: PrimaryPurposeLoanPurpose | undefined;
	borrowingAmount: number | undefined;
	loanTerm: number | undefined;
	anticipatedChangesList: AnticipatedChanges[];
	exitStrategy?: string;
	isAdditionalNotes: boolean;
	additionalNotes?: string;
	salesChannel?: SalesChannelType;
	incomeUsedDescription?: string;
	refinanceExplained?: boolean;
	refinanceUnderstood?: boolean;
	isLoanPurposeRefinanceOrDebtConsolidation?: boolean;
	isHLIGManuallyCompleted?: boolean;
}

export interface AnticipatedChanges {
	id?: number;
	applicantId: number;
	applicantName?: string;
	applicantAnticipatesChanges: YesNo | undefined;
	anticipatedChanges?: string;
	significantChanges?: SignificantChange[];
	mitigants?: MitigantFactor[];
}

export interface SignificantChange {
	id: number;
	changeId: EnumSignificantChange;
	startDate?: string;
	endDate?: string;
	monthlyFinancialImpact?: number;
	description?: string;
}

export interface MitigantFactor {
	id: number;
	factorId: EnumMitigantFactor;
	description?: string;
	lenderHeldSavingsAccount?: YesNo;
	lenderHeldSavingsAccountDescription?: string;
	otherLenderHeldSavingsAccount?: YesNo;
	otherLenderHeldSavingsAccountDescription?: string;
	lenderHeldSecurity?: YesNo;
	lenderHeldSecurityDescription?: string;
	otherLenderHeldSecurity?: YesNo;
	otherLenderHeldSecurityDescription?: string;
	otherAssets?: YesNo;
	otherAssetsDescription?: string;
	shares?: YesNo;
	sharesDescription?: string;
}
