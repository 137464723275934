import { PermissionOperation } from '@app/modules/shared/access/permission';
import { isAllowed } from '@app/modules/shared/access/permission-utils';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { calculateMonthly, isNullOrUndefined } from '@app/modules/shared/helper/util';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyAttributeEvent, FormlyHookConfig, FormlyTemplateOptions } from '@ngx-formly/core/lib/models';
import { CurrencyHelper, FrequencyShort } from '@simpology/client-components/utils';
import * as acorn from 'acorn';
import { cloneDeep, findIndex, get, isBoolean, isString, mapKeys, mapValues, pick, remove } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { FormlyPermission, JsonSchemaProperty } from '../typings/formly-app';
import { FormlyApiProperty, FormlyLabels, FormlyUpdateEvent } from './typings/formly-api';
import { customInputValidators } from './validators/formly-custom-validators';

export const yearlyAmountToMonthly = (amount?: string): number => {
	if (amount) {
		return calculateMonthly(CurrencyHelper.unformatAmount(amount), FrequencyShort.Yearly);
	} else {
		return 0;
	}
};

/**
 *
 * @param formFields
 * @param path Ex:- 'manageParticipants.applicants.applicantTypeModal'
 */
export const getFormField = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string
): FormlyFieldConfig | undefined => {
	const keys = path.split('.');
	let fieldGroup: FormlyFieldConfig[];

	// no key means it's of area type
	if (formFields && formFields.length && !formFields[0].key) {
		fieldGroup = (formFields[0].fieldArray as FormlyFieldConfig)?.fieldGroup || [];
		if (!fieldGroup[0]) {
			fieldGroup = [];
		}
	} else {
		fieldGroup = formFields || [];
	}

	let response: FormlyFieldConfig | undefined;
	keys.forEach((key) => {
		response = fieldGroup.find((field) => field.key === key);
		fieldGroup = (response?.fieldArray as FormlyFieldConfig)?.fieldGroup ?? response?.fieldGroup ?? [];
	});
	return response;
};

/**
 * Use this method to get form field only after form fields are initialized, this is checking only in field groups of a property
 * @param formFields
 * @param path Ex:- 'manageParticipants.applicants.0.applicantTypeModal'
 */
export const getInitializedFormField = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string
): FormlyFieldConfig | undefined => {
	const keys = path.split('.');
	let fieldGroup: FormlyFieldConfig[];

	// no key means it's of area type
	if (formFields && formFields.length > 0 && !formFields[0].key) {
		fieldGroup = formFields[0].fieldGroup || [];
	} else {
		fieldGroup = formFields || [];
	}
	let response: FormlyFieldConfig | undefined;
	keys.forEach((key) => {
		response = fieldGroup.find((field) => field.key === key);
		fieldGroup = fieldGroup.find((field) => field.key === key)?.fieldGroup ?? [];
	});
	return response;
};

/**
 *
 * @param formField
 * @param parentKey Key of the parent
 */
export const getParentFormField = (
	formField: FormlyFieldConfig | undefined,
	parentKey: string
): FormlyFieldConfig | undefined => {
	if (!parentKey || !formField || !formField.parent) {
		return undefined;
	}
	if (formField.parent.key === parentKey) {
		return formField.parent;
	} else {
		return getParentFormField(formField.parent, parentKey);
	}
};

export const getAreaField = (formField: FormlyFieldConfig | undefined): FormlyFieldConfig | undefined => {
	if (formField?.type === 'area') {
		return formField;
	}
	if (formField?.parent) {
		return getAreaField(formField.parent);
	} else {
		return undefined;
	}
};

const resolveFormlyArray = (field: FormlyFieldConfig | undefined): FormlyFieldConfig | undefined => {
	if (typeof field?.fieldArray === 'function') {
		const fieldArray = field.fieldArray(field);
		field.fieldArray = () => fieldArray;
		return fieldArray;
	}
	return field;
};

export const resolvedFormArray = {
	map: (mappedField: FormlyFieldConfig) => {
		if (mappedField?.fieldArray) {
			mappedField.fieldArray = resolveFormlyArray(mappedField);
		}
		return mappedField;
	}
};

export const getTemplateOptions = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string
): FormlyTemplateOptions => {
	const field = getFormField(formFields, path);
	return field?.templateOptions as FormlyTemplateOptions;
};

export const formlyOnClick = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	callBack: FormlyAttributeEvent
): void => {
	const field = getFormField(formFields, path);
	if (field?.templateOptions) {
		field.templateOptions.click = callBack;
	}
};

export const formlyOnChange = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	callBack: FormlyAttributeEvent
): void => {
	const field = getFormField(formFields, path);
	if (field?.templateOptions) {
		field.templateOptions.change = callBack;
	}
};

/**
 * Register onInit, afterInit etc hooks
 * If you register hooks multiple times on same field, it would override the previous hooks.
 * @param formFields
 * @param path
 * @param hooks
 */
export const formlyRegisterHooks = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	hooks: FormlyHookConfig
): void => {
	const field = getFormField(formFields, path);
	if (field) {
		Object.assign(field, {
			...field,
			hooks: Object.assign({}, field.hooks, hooks)
		});
	}
};

export const formlyOnStatusChangedToValid = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	callBack: (field: FormlyFieldConfig, model: FormlyUpdateEvent<any>) => void
): void => {
	let subscription: Subscription;

	formlyRegisterHooks(formFields, path, {
		afterViewInit: (field) => {
			if (field?.templateOptions?.onStatusChangedToValid) {
				const onStatusChangedToValid = field.templateOptions.onStatusChangedToValid as Observable<any>;
				subscription = onStatusChangedToValid.subscribe((model) => callBack(field, model));
			}
		},
		onDestroy: () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		}
	});
};

export const formlyAddItemToArray = (field: FormlyFieldConfig, value?: any, index = 0): void => {
	const add = field?.templateOptions?.add as Function;
	if (add && typeof add === 'function') {
		add(index, value);
	}
};

/**
 * Works with delete button component
 * @param field
 */
export const formlyDeleteFromArray = (field: FormlyFieldConfig): void => {
	const toRemove = field.parent?.parent?.templateOptions?.remove as Function;
	if (toRemove && typeof toRemove === 'function') {
		toRemove(field.parent?.key);
	}
};
export const formlyDeleteFromImmediateArray = (field: FormlyFieldConfig): void => {
	const toRemove = field.parent?.templateOptions?.remove as Function;
	if (toRemove && typeof toRemove === 'function') {
		toRemove(field.key);
	}
};

/**
 * transform Expression
 * @param expression
 * @param useField when property has nested properties
 */
export const parseExpressionString = (expression: string | number | boolean | undefined): string | boolean => {
	switch (typeof expression) {
		case 'boolean':
			return expression;
		case 'number':
			return `${expression}`;
		case 'string': {
			let updatedExpression = expression || '';
			// change model to field.parent.model so that hideExpression can access paren't model directly
			if (
				updatedExpression.includes('model.') &&
				!updatedExpression.includes('parent.') &&
				!updatedExpression.includes('field.model.')
			) {
				updatedExpression = updatedExpression.split('model.').join('field.parent.model.');
			}

			// Retain all quoted strings and don't update them as expressions
			const strings = updatedExpression.match(/"[^"]*"/g);
			updatedExpression = updatedExpression.replace(/"[^"]*"/g, 'URL');
			// add null check
			updatedExpression = updatedExpression.includes('model')
				? updatedExpression.split('?.').join('.').split('.').join('?.')
				: updatedExpression;
			strings?.forEach((string) => (updatedExpression = updatedExpression.replace('URL', string)));

			//ACORN can be removed once we fully use Platform app for metadata changes
			try {
				acorn.Parser.parse(updatedExpression, { ecmaVersion: 2020 });
			} catch (error) {
				console.error({ expression, updatedExpression, error });
			}

			return updatedExpression;
		}

		default:
			return '';
	}
};

export const formlyParsePatternCustomValidator = (schema: JsonSchemaProperty, property: FormlyApiProperty) => {
	const customValidator = customInputValidators[property.templateOptions?.pattern as string];

	if (customValidator && schema.widget?.formlyConfig?.templateOptions && schema.widget.formlyConfig.validation) {
		schema.widget.formlyConfig.validators = { validation: [customValidator?.validatorFn] };
		schema.widget.formlyConfig.templateOptions.pattern = undefined;
		schema.widget.formlyConfig.validation.messages = {
			...schema.widget.formlyConfig.validation?.messages,
			...customValidator?.messages
		};
	}
	return schema;
};

/**
 * override templateOptions based on permissions
 * @param property
 */
export const formlyParsePermissions = (property: FormlyApiProperty) => {
	const permissions = property?.templateOptions?.permissions as unknown as FormlyPermission[];
	if (permissions) {
		permissions.forEach((permission) => {
			applyPermissions(property, permission);
		});
	}
};

const applyPermissions = (property: FormlyApiProperty, permission: FormlyPermission) => {
	const isAllowedByPermission = isAllowed(permission.permissionId, permission.evaluator);
	if (!isAllowedByPermission && property?.templateOptions) {
		property.templateOptions['permissionsApplied'] = true;
		switch (permission.operation.toLowerCase()) {
			case PermissionOperation.hide.toLowerCase():
				property.hide = 'true';
				break;
			case PermissionOperation.readonly.toLowerCase():
				property.templateOptions.readonly = true;
				break;
		}
	}
};

/**
 * To toggle parent field class
 * @param model
 * @param formState
 * @param field
 */
const toggleHiddenClass = (model: unknown, formState: unknown, field?: FormlyFieldConfig): string => {
	const className = field?.className || '';
	if (field && field.hide) {
		if (className && field.templateOptions) {
			field.templateOptions.className = className;
		}
		return '';
	}
	return (field?.templateOptions?.className as string) || className;
};

export const formlyAddCustomValidator = (
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	validators: { [key: string]: { expression: Function } }
): void => {
	const field = getFormField(formFields, path);
	if (field) {
		if (field.validators) {
			Object.assign(field.validators, validators);
		} else {
			field.validators = validators;
		}
	}
};

/**
 * Used to generate labels object for content projected field
 * @param field
 * @param labelKey
 */
export const formlyGenerateLabels = (field: FormlyFieldConfig, labelKey?: string): FormlyLabels => {
	return formlyGetFieldLabels(field, labelKey, false);
};

/**
 * Used to generate labels object for content projected field
 * @param field
 * @param labelKey
 */
export const formlyGetFieldLabelsWithoutHideCheck = (field: FormlyFieldConfig, labelKey?: string): FormlyLabels => {
	return formlyGetFieldLabels(field, labelKey, true);
};

/**
 * Used to generate labels object for content projected field
 * @param field
 * @param key
 * @param skipFieldHiddenCheck
 */
export const formlyGetFieldLabels = (
	field: FormlyFieldConfig,
	key?: string,
	skipFieldHiddenCheck = false
): FormlyLabels => {
	// if there is a parent, then pick sibling fieldGroup otherwise child fieldGroup
	const fieldsGroup = field.parent
		? field.parent.fieldGroup
		: field.fieldGroup ||
		  ((field.fieldArray as FormlyFieldConfig)?.fieldGroup as FormlyFieldConfig as FormlyFieldConfig[]);

	const formlyFieldConfigs = fieldsGroup || [];
	const labelsKey = `${field.key}Labels`;
	const findFieldConfig =
		formlyFieldConfigs.find((fg: FormlyFieldConfig) => fg.key === (key || labelsKey)) ??
		field.fieldGroup?.find((fg: FormlyFieldConfig) => fg.key === key);
	return formlyGetLabelsObject(findFieldConfig, skipFieldHiddenCheck);
};

/**
 * Used to get labels object for content projected field
 * @param labelsField
 * @param skipFieldHiddenCheck
 */

export const formlyGetLabelsObject = (
	labelsField: FormlyFieldConfig | undefined,
	skipFieldHiddenCheck = false
): FormlyLabels => {
	const labels: FormlyLabels = {};
	labelsField?.fieldGroup?.forEach((field: FormlyFieldConfig) => {
		let label: string | undefined;
		if (!skipFieldHiddenCheck && (field.hideExpression === true || field.hide === true)) {
			label = '';
		} else {
			if (typeof field.props?.label === 'string') {
				label = field.props?.label;
			} else {
				label = ((field.props?.label as unknown as string[]) ?? []).join('');
			}
		}
		Object.assign(labels, {
			[field.key as string]: label
		});
	});
	return labels;
};

/**
 * Used to get templateOptions object for content projected field
 * @param field
 */
export const formlyGetTemplateOptions = (field: FormlyFieldConfig, key?: string): FormlyTemplateOptions => {
	const templateOptions: FormlyTemplateOptions = {};
	// if there is a parent, then pick sibling fieldGroup otherwise child fieldGroup
	const fieldsGroup = field.parent
		? field.parent.fieldGroup
		: field.fieldGroup ||
		  ((field.fieldArray as FormlyFieldConfig)?.fieldGroup as FormlyFieldConfig as FormlyFieldConfig[]);

	const formlyFieldConfigs = fieldsGroup || [];
	const labelsKey = `${field.key}Labels`;
	const findFieldConfig = formlyFieldConfigs.find((fg: FormlyFieldConfig) => fg.key === (key || labelsKey));
	findFieldConfig?.fieldGroup?.forEach((fg: FormlyFieldConfig) => {
		Object.assign(templateOptions, {
			[fg.key as string]: fg.templateOptions
		});
	});
	return templateOptions;
};

/**
 * Used to get link associated with action buttons
 * @param field
 */
export const formlyGetFieldLink = (field: FormlyFieldConfig | undefined, key: string, fieldName: string): string => {
	// if there is a parent, then pick sibling fieldGroup otherwise child fieldGroup
	const fieldsGroup = field?.parent
		? field.parent.fieldGroup
		: field?.fieldGroup ||
		  ((field?.fieldArray as FormlyFieldConfig)?.fieldGroup as FormlyFieldConfig as FormlyFieldConfig[]);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return (fieldsGroup || [])
		.find((fg: FormlyFieldConfig) => fg.key === key)
		?.fieldGroup?.find((fg: FormlyFieldConfig) => fg.key === fieldName)?.templateOptions?.subText;
};

/**
 * Extend expression properties
 * @param field
 * @param path
 * @param expressionProperties
 */
export const formlyExtendExpressionProperties = <T = unknown>(
	formFields: FormlyFieldConfig[] | undefined,
	path: string,
	expressionProperties: {
		[property: string]:
			| string
			| ((model: T, formState: unknown, f: FormlyFieldConfig) => unknown)
			| Observable<unknown>;
	}
): void => {
	const selectedField = getFormField(formFields, path);
	upsertExpressionProperties(selectedField, expressionProperties);
};

const upsertExpressionProperties = <T = unknown>(
	selectedField: FormlyFieldConfig | undefined,
	expressionProperties: {
		[property: string]:
			| string
			| ((model: T, formState: unknown, f: FormlyFieldConfig) => unknown)
			| Observable<unknown>;
	}
) => {
	if (selectedField) {
		if (selectedField.expressionProperties) {
			Object.assign(selectedField.expressionProperties, {
				...expressionProperties
			});
		} else {
			Object.assign(selectedField, { expressionProperties });
		}
	}
};

/**
 * Generates unique id for formly field for automation purposes
 * @param field
 */
export const generateUniqueKey = (field: FormlyFieldConfig | undefined): string => {
	const keys = [];
	let fieldParent = field;
	do {
		keys.push(fieldParent?.key);
		fieldParent = fieldParent?.parent;
	} while (fieldParent?.key !== undefined);
	return keys.reverse().join('-').replace('0-', '');
};

/**
 * Auto append `parent` for nested properties parsed expressions, eg: amount-select control
 * @param property
 * @returns
 */
export const parseNestedExpressionProperties = (property: FormlyApiProperty) => {
	const parsedExpressionObject = parseExpressionProperties(property);

	Object.keys(parsedExpressionObject).forEach((key) => {
		const expression = parsedExpressionObject[key];

		parsedExpressionObject[key] = isString(expression)
			? expression.split('field?.parent?.').join('field?.parent?.parent?.')
			: expression;
	});

	return parsedExpressionObject;
};

/**
 * Add any templateOptions property here that can be expression in form metadata
 */
const propertiesAsExpressions = [
	'disabled',
	'required',
	'readonly',
	'selectFirstOption',
	'initialRequiredExpression',
	'min',
	'max',
	'maxDate',
	'minDate',
	'maxLength',
	'dataOnly',
	'greyBackground',
	'hideLabel'
];

export const parseExpressionProperties = (
	property: FormlyApiProperty
): {
	[property: string]: string;
} => {
	// only if dynamicOptions is an expressionString
	const updatedPropertiesAsExpressions = [...propertiesAsExpressions];
	if (isString(property.templateOptions?.dynamicOptions) && property.templateOptions?.dynamicOptions) {
		updatedPropertiesAsExpressions.push('dynamicOptions');
	}

	// Only parse the following if it is an expression
	const conditionalParseOptions = ['subText', 'tooltip', 'handler', 'tooltipText'];
	conditionalParseOptions.forEach((option) => {
		if (
			property.templateOptions &&
			isString(property.templateOptions[option]) &&
			['model.', 'model?.', 'formState.', 'formState?.', 'field.', 'field?.'].some((key) =>
				(property.templateOptions?.[option] as string).includes(key)
			)
		) {
			updatedPropertiesAsExpressions.push(option);
		}
	});

	const pickedExpressions = pick(property.templateOptions, updatedPropertiesAsExpressions);
	const withMappedKeys = mapKeys(pickedExpressions, (value, key) => `templateOptions.${key}`);

	const expressionProperties = mapValues(withMappedKeys, (value) => parseExpressionString(value) as string);
	// show (optional) label dynamically based on expression
	if (property.templateOptions?.required && !isBoolean(property.templateOptions.required)) {
		Object.assign(expressionProperties, {
			['templateOptions.label']: (_model: unknown, _formState: unknown, field: FormlyFieldConfig) => {
				const label = field.templateOptions?.label ?? '';
				return field.templateOptions?.required
					? label.replace(CONSTANTS.OPTIONAL, '')
					: label.includes(CONSTANTS.OPTIONAL)
					? label
					: label.length
					? `${label} ${CONSTANTS.OPTIONAL}`
					: '';
			}
		});
	}

	// If the field is readonly, it should be treated like disabled and should not be required
	if (property.templateOptions?.readonly) {
		Object.assign(expressionProperties, {
			['props.required']: (_model: unknown, _formState: unknown, field: FormlyFieldConfig) => {
				// saving the initial required expression so it doesn't get overriden
				if (isNullOrUndefined(field.props?.initialRequiredExpression)) {
					field.props!.initialRequiredExpression = field.props?.required;
				}
				// if it is not readonly, it is required as per original required expression
				// if it is readonly, it will not be required
				return !field.props?.readonly && !!field.props?.initialRequiredExpression;
			}
		});
	}
	if (property.type === 'custom') {
		Object.assign(expressionProperties, {
			className: toggleHiddenClass
		});
	}

	/**
	 * Support for dynamic expressions
	 * eg: "dynamicValue": "`Amount is $${model.amount}`"
	 * eg: "dynamicValue": "model.isUrgent"
	 */
	if (!isNullOrUndefined(property.templateOptions?.dynamicValue)) {
		Object.assign(expressionProperties, {
			[`model.${property.key}`]: parseExpressionString(property.templateOptions?.dynamicValue)
		});
	}

	return expressionProperties;
};

/**
 * Inserts new modal fieldGroup
 * @param field
 * @param modalFieldPath
 * @param callBack
 * @returns
 */
export const formlyInsertModalField = (
	field: FormlyFieldConfig,
	modalFieldPath: string,
	callBack: (field: FormlyFieldConfig) => void
): FormlyFieldConfig | undefined => {
	if (field.templateOptions) {
		(field.templateOptions.add as Function)();
		const modalField = getFormField(field.fieldGroup![(field.fieldGroup?.length || 1) - 1].fieldGroup, modalFieldPath);
		if (modalField) {
			setTimeout(() => {
				callBack(modalField);
			}, 10);
		}
	}
	return undefined;
};

/**
 * Disabled seletion of future dates for a datepicker
 * @param field
 * @param modalFieldPath
 */
export const disableFutureDates = (formFields: FormlyFieldConfig[], datepickerPath: string): void => {
	formlyExtendExpressionProperties(formFields, datepickerPath, {
		'templateOptions.maxDate': () => {
			const current = new Date();
			return {
				year: current.getFullYear(),
				month: current.getMonth() + 1,
				day: current.getDate()
			};
		}
	});
};

/**
 *
 * @param formFields form schema object
 * @param key key or path of section within formFields
 * @param clonedFieldKey key of cloned section
 * @returns
 */
export const cloneSection = (
	formFields: FormlyFieldConfig[] | undefined,
	key: string,
	clonedFieldKey: string | number
): FormlyFieldConfig | undefined => {
	const sectionField = getFormField(formFields, key);
	if (!sectionField || length === 0) {
		return undefined;
	}
	const clonedSectionField = cloneDeep(sectionField);
	const fieldGroup = get(formFields, '0.fieldArray.fieldGroup', []) as FormlyFieldConfig[];
	const sectionFieldIndex = findIndex(fieldGroup, ['key', key]);
	clonedSectionField.key = clonedFieldKey;
	fieldGroup.splice(sectionFieldIndex + 1, 0, clonedSectionField);
	return clonedSectionField;
};

/**
 * updates keys of section(cloned) with a suffix
 * @param sectionField
 * @param suffix
 * @returns void
 */
export const appendIndexToSubSections = (sectionField?: FormlyFieldConfig, suffix?: string | number) => {
	if (!sectionField?.fieldGroup) {
		return;
	}
	sectionField.fieldGroup.forEach((subSection) => {
		subSection.key = `${subSection.key}${suffix}`;
	});
};

/**
 * deletes section field from fields (useful when cloned to another field)
 * @param formFields
 * @param key
 */
export const deleteSection = (formFields: FormlyFieldConfig[] | undefined, key: string) => {
	if (formFields) {
		const sectionFieldGroup = get(formFields, '[0].fieldArray.fieldGroup', []) as FormlyFieldConfig[];
		const incomeSectionFieldIndex = sectionFieldGroup.findIndex((field) => field.key === key);
		if (incomeSectionFieldIndex > -1) {
			sectionFieldGroup.splice(incomeSectionFieldIndex, 1);
		}
	}
};

/**
 * Just keep the sections passed a parameter, remove all others
 * @param metadata
 * @param sections
 */
export const filterSectionsFromSchema = (metadata: FormlyApiProperty, sections?: string[]) => {
	remove(
		metadata.properties as FormlyApiProperty[],
		(property) => property.type === 'section' && sections && !sections.includes(property.key as string)
	);
};

export const filterDigitalWidgetsFromSchema = (metadata: FormlyApiProperty, sectionsToShow?: string[]) => {
	remove(
		metadata.properties as FormlyApiProperty[],
		(property) => property.key === property.type && sectionsToShow && !sectionsToShow.includes(property.key as string)
	);
};

// Reads property from schema based on path provided
export const findPropertyFromSchema = (schema: FormlyApiProperty | undefined, path: string) => {
	const keys = path.split('.');
	let schemaProperty = schema;
	let response: FormlyApiProperty | undefined;
	keys.forEach((key) => {
		response = schemaProperty?.properties?.find((field) => field.key === key);
		schemaProperty = response ?? {};
	});
	return response;
};

export const getMobileStep = (field?: FormlyFieldConfig): FormlyFieldConfig | undefined => {
	let mobileStepField = field;
	do {
		mobileStepField = mobileStepField?.parent;
	} while (mobileStepField && mobileStepField?.type !== 'mobile-step');
	return mobileStepField;
};

export const validateMobileStep = (field?: FormlyFieldConfig): boolean => {
	const mobileStepField = getMobileStep(field);
	if (mobileStepField?.props) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		mobileStepField.props.validate();
		return mobileStepField.formControl?.valid ?? false;
	}
	return false;
};

export const asyncValidateMobileStep = (field?: FormlyFieldConfig): void => {
	const mobileStepField = getMobileStep(field);

	setTimeout(() => {
		if (mobileStepField?.props) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			mobileStepField.props.validate();
		}
	}, 100);
};
/**
 * Get form data after triggering validations and if form is valid, otherwise returns null
 * @param field
 * @returns
 */
export const getMobileStepFormData = <T>(field?: FormlyFieldConfig): T | null => {
	if (validateMobileStep(field) && !field?.form?.pristine) {
		return field?.form?.value as T;
	}
	return null;
};
