import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-spinner',
	templateUrl: './formly-spinner.component.html',
	styleUrls: ['./formly-spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySpinnerComponent extends FieldType<FieldTypeConfig> {}
