import { EmploymentIncomeDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class EmploymentIncomeModelTransformer {
	static fromPayload(employmentIncomeDTO: EmploymentIncomeDTO[]): IncomeModel[] {
		return (employmentIncomeDTO || []).map((income) => ({
			id: income.id,
			grossAmount: String(income.grossAmount!),
			netAmount: String(income.netAmount!),
			frequency: income.frequency ?? (income.startDate ? FrequencyShort.YearToDate : undefined),
			type: income.type,
			includedInBase: income.includedInBase
		}));
	}
}

export interface IncomeModel {
	id?: number;
	type?: number;
	grossAmount?: string;
	netAmount?: string;
	frequency?: FrequencyShort;
	includedInBase?: boolean;
}
