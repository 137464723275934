import { TargetType } from '@app/modules/shared/enums/app.enums';
import { InterviewPersonDTO, SignificantChangeDTO } from '@app/modules/typings/api';
import { DocumentChecklistModel } from './document-checklist.model';

export class SignificantChangeTransformer {
	static fromPayload(payload: SignificantChangeDTO, person: InterviewPersonDTO): SignificantChangeModel {
		return {
			applicantName: person.fullName,
			age: person.age,
			ageGreaterThan: person.age ? person.age >= 60 : undefined,
			noKnownChanges: payload.noKnowChanges,
			isRetired: payload.isRetired,
			changeDetails: {
				plannedRetirementBeforeStatutoryAge: payload.plannedRetirementBeforeStatutoryAge,
				intendedRetirementAge: payload.intendedRetirementAge,
				extendedUnpaidLeave: payload.extendedUnpaidLeave,
				extendedUnpaidLeaveFinancialImpact: payload.extendedUnpaidLeaveFinancialImpactAmount,
				extendedUnpaidLeaveImpactPeriod: payload.extendedUnpaidLeaveImpactPeriod,
				reducedIncome: payload.reducedIncome,
				reducedIncomeFinancialImpact: payload.reducedIncomeFinancialImpactAmount,
				reducedIncomeImpactPeriod: payload.reducedIncomeImpactPeriod,
				endOfContract: payload.endOfContract,
				endOfContractFinancialImpact: payload.endOfContractFinancialImpactAmount,
				endOfContractImpactPeriod: payload.endOfContractImpactPeriod,
				largeExpenditure: payload.largeExpenditure,
				largeExpenditureFinancialImpact: payload.largeExpenditureFinancialImpactAmount,
				largeExpenditureImpactPeriod: payload.largeExpenditureImpactPeriod,
				medicalTreatment: payload.medicalTreatment,
				medicalTreatmentFinancialImpact: payload.medicalTreatmentFinancialImpactAmount,
				medicalTreatmentImpactPeriod: payload.medicalTreatmentImpactPeriod,
				other: payload.other,
				otherFinancialImpact: payload.otherFinancialImpactAmount,
				otherImpactPeriod: payload.otherImpactPeriod,
				specifyDetails: payload.specifyDetails,
				meetingRepayments: {
					repaymentPriorToSettlement: payload.repaymentPriorToSettlement,
					downsizingHome: payload.downsizingHome,
					saleOfOtherAssets: payload.saleOfOtherAssets,
					saleOfOtherAssetsValue: payload.saleOfOtherAssetsValue,
					recurringIncomeFromSuper: payload.recurringIncomeFromSuper,
					superannuationLumpSum: payload.superannuationLumpSum,
					savings: payload.savings,
					savingsValue: payload.savingsValue,
					incomeFromOtherInvestments: payload.incomeFromOtherInvestments,
					coApplicantIncome: payload.coApplicantIncome,
					coApplicantIncomeAmount: payload.coApplicantIncomeAmount,
					coApplicantIncomeTimePeriod: payload.coApplicantIncomeTimePeriod,
					workPastRetirementAge: payload.workPastRetirementAge,
					additionalIncomeSource: payload.additionalIncomeSource,
					additionalIncomeSourceAmount: payload.additionalIncomeSourceAmount,
					additionalIncomeSourceTimePeriod: payload.additionalIncomeSourceTimePeriod,
					otherPlan: payload.otherPlan,
					otherPlanAmount: payload.otherPlanAmount,
					otherPlanTimePeriod: payload.otherPlanTimePeriod,
					specifyOtherPlanDetails: payload.specifyOtherPlanDetails,
					repaymentDetails: payload.repaymentDetails
				}
			}
		};
	}

	static toPayload(
		model: SignificantChangeModel,
		applicationId: number,
		targetType: TargetType,
		targetId: number
	): SignificantChangeDTO {
		return {
			applicationId,
			targetType,
			targetId,
			noKnowChanges: model?.noKnownChanges,
			plannedRetirementBeforeStatutoryAge: model?.changeDetails?.plannedRetirementBeforeStatutoryAge,
			intendedRetirementAge: model?.changeDetails?.intendedRetirementAge,
			extendedUnpaidLeave: model?.changeDetails?.extendedUnpaidLeave,
			extendedUnpaidLeaveFinancialImpactAmount: model?.changeDetails?.extendedUnpaidLeaveFinancialImpact,
			extendedUnpaidLeaveImpactPeriod: model?.changeDetails?.extendedUnpaidLeaveImpactPeriod,
			reducedIncome: model?.changeDetails?.reducedIncome,
			reducedIncomeFinancialImpactAmount: model?.changeDetails?.reducedIncomeFinancialImpact,
			reducedIncomeImpactPeriod: model?.changeDetails?.reducedIncomeImpactPeriod,
			endOfContract: model?.changeDetails?.endOfContract,
			endOfContractFinancialImpactAmount: model?.changeDetails?.endOfContractFinancialImpact,
			endOfContractImpactPeriod: model?.changeDetails?.endOfContractImpactPeriod,
			largeExpenditure: model?.changeDetails?.largeExpenditure,
			largeExpenditureFinancialImpactAmount: model?.changeDetails?.largeExpenditureFinancialImpact,
			largeExpenditureImpactPeriod: model?.changeDetails?.largeExpenditureImpactPeriod,
			medicalTreatment: model?.changeDetails?.medicalTreatment,
			medicalTreatmentFinancialImpactAmount: model?.changeDetails?.medicalTreatmentFinancialImpact,
			medicalTreatmentImpactPeriod: model?.changeDetails?.medicalTreatmentImpactPeriod,
			other: model?.changeDetails?.other,
			otherFinancialImpactAmount: model?.changeDetails?.otherFinancialImpact,
			otherImpactPeriod: model?.changeDetails?.otherImpactPeriod,
			specifyDetails: model?.changeDetails?.specifyDetails,
			repaymentPriorToSettlement: model?.changeDetails?.meetingRepayments?.repaymentPriorToSettlement,
			downsizingHome: model?.changeDetails?.meetingRepayments?.downsizingHome,
			saleOfOtherAssets: model?.changeDetails?.meetingRepayments?.saleOfOtherAssets,
			saleOfOtherAssetsValue: model?.changeDetails?.meetingRepayments?.saleOfOtherAssetsValue,
			recurringIncomeFromSuper: model?.changeDetails?.meetingRepayments?.recurringIncomeFromSuper,
			superannuationLumpSum: model?.changeDetails?.meetingRepayments?.superannuationLumpSum,
			savings: model?.changeDetails?.meetingRepayments?.savings,
			savingsValue: model?.changeDetails?.meetingRepayments?.savingsValue,
			incomeFromOtherInvestments: model?.changeDetails?.meetingRepayments?.incomeFromOtherInvestments,
			coApplicantIncome: model?.changeDetails?.meetingRepayments?.coApplicantIncome,
			coApplicantIncomeAmount: model?.changeDetails?.meetingRepayments?.coApplicantIncomeAmount,
			coApplicantIncomeTimePeriod: model?.changeDetails?.meetingRepayments?.coApplicantIncomeTimePeriod,
			workPastRetirementAge: model?.changeDetails?.meetingRepayments?.workPastRetirementAge,
			additionalIncomeSource: model?.changeDetails?.meetingRepayments?.additionalIncomeSource,
			additionalIncomeSourceAmount: model?.changeDetails?.meetingRepayments?.additionalIncomeSourceAmount,
			additionalIncomeSourceTimePeriod: model?.changeDetails?.meetingRepayments?.additionalIncomeSourceTimePeriod,
			otherPlan: model?.changeDetails?.meetingRepayments?.otherPlan,
			otherPlanAmount: model?.changeDetails?.meetingRepayments?.otherPlanAmount,
			otherPlanTimePeriod: model?.changeDetails?.meetingRepayments?.otherPlanTimePeriod,
			specifyOtherPlanDetails: model?.changeDetails?.meetingRepayments?.specifyOtherPlanDetails,
			repaymentDetails: model?.changeDetails?.meetingRepayments?.repaymentDetails,
			isRetired: model?.isRetired
		};
	}
}

export interface MeetingRepayments {
	repaymentPriorToSettlement?: boolean;
	downsizingHome?: boolean;
	saleOfOtherAssets?: boolean;
	saleOfOtherAssetsValue?: number;
	recurringIncomeFromSuper?: boolean;
	superannuationLumpSum?: boolean;
	savings?: boolean;
	savingsValue?: number;
	incomeFromOtherInvestments?: boolean;
	coApplicantIncome?: boolean;
	coApplicantIncomeAmount?: number;
	coApplicantIncomeTimePeriod?: number;
	workPastRetirementAge?: boolean;
	additionalIncomeSource?: boolean;
	additionalIncomeSourceAmount?: number;
	additionalIncomeSourceTimePeriod?: number;
	otherPlan?: boolean;
	otherPlanAmount?: number;
	otherPlanTimePeriod?: number;
	specifyOtherPlanDetails?: string;
	repaymentDetails?: string;
}

export interface SignificantChangeModel {
	applicantName: string;
	age?: number;
	ageGreaterThan?: boolean;
	noKnownChanges?: boolean;
	isRetired?: boolean;
	changeDetails?: ChangeDetails;
}

interface ChangeDetails {
	plannedRetirementBeforeStatutoryAge?: boolean;
	intendedRetirementAge?: number;
	extendedUnpaidLeave?: boolean;
	extendedUnpaidLeaveFinancialImpact?: number; // amount
	extendedUnpaidLeaveImpactPeriod?: number; // months
	reducedIncome?: boolean;
	reducedIncomeFinancialImpact?: number;
	reducedIncomeImpactPeriod?: number;
	endOfContract?: boolean;
	endOfContractFinancialImpact?: number;
	endOfContractImpactPeriod?: number;
	largeExpenditure?: boolean;
	largeExpenditureFinancialImpact?: number;
	largeExpenditureImpactPeriod?: number;
	medicalTreatment?: boolean;
	medicalTreatmentFinancialImpact?: number;
	medicalTreatmentImpactPeriod?: number;
	other?: boolean;
	otherFinancialImpact?: number;
	otherImpactPeriod?: number;
	specifyDetails?: string;
	meetingRepayments: MeetingRepayments;
}

export interface SignificantChangeSectionModel {
	documentChecklist: DocumentChecklistModel;
}
export interface SignificantChangeSectionFormControl {
	documentChecklist: DocumentChecklistModel[];
}
