import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyLink = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'link',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-12 form-field-link')}`,
			templateOptions: {
				...property.templateOptions
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
