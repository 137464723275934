import { CountryCode, TrustPurpose, TrustStructure, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { TrustDetailsDTO } from '@app/modules/typings/api';

export class TrustDetailsTransformer {
	static fromPayload(details: TrustDetailsDTO): TrustDetailsModel {
		return {
			applicationId: details.applicationId,
			id: details.id,
			established: getNullableYesNo(details.established),
			trustName: details.trustName,
			trustStructure: details.trustStructure,
			trustPurpose: details.trustPurpose,
			establishmentDate: details.establishmentDate,
			countryEstablished: details.countryEstablished,
			documentationType: details.documentationType
		};
	}

	static toPayload(applicationId: number, details: TrustDetailsModel): TrustDetailsDTO {
		return {
			applicationId: applicationId,
			id: details.id,
			established: getNullableBoolean(details.established),
			trustName: details.trustName,
			trustStructure: details.trustStructure,
			trustPurpose: details.trustPurpose,
			establishmentDate: details.establishmentDate,
			countryEstablished: details.countryEstablished,
			documentationType: details.documentationType
		};
	}
}

export interface TrustDetailsModel {
	applicationId: number;
	id: number;
	established: YesNo | undefined;
	trustName?: string;
	trustStructure?: TrustStructure;
	trustPurpose?: TrustPurpose;
	establishmentDate?: string;
	countryEstablished?: CountryCode;
	documentationType?: string;
}
