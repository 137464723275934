<div [id]="field.key" [ngClass]="field.className ? field.className : 'simp-card'" class="{{ to.customClass }}">
	<div class="loanapp-area__section-header" metadataKey [formlyField]="field">
		<i class="{{ to.iconClass + ' loanapp-area__section-icon' }}"></i>
		<div>
			<h4 class="simp-h4 simp-text--secondary loanapp-area__section-title">{{ to.label }}</h4>
			<h5 class="simp-h5 simp-text--extra-light">{{ to.subText }}</h5>
		</div>
		<ng-container *ngIf="headerField && headerData$">
			<formly-header
				class="d-flex flex-grow-1 justify-content-end simp-margin-right-small"
				[headerModel]="headerData$ | async"
				[headerField]="headerField"
				[showHeader]="true"
			></formly-header>
		</ng-container>
		<ng-container *ngIf="!headerField">
			<formly-templates
				class="d-flex flex-grow-1 simp-margin-right-small justify-content-end"
				[field]="field"
			></formly-templates>
		</ng-container>
	</div>
	<div class="loanapp-area__form-container simp-margin-top-small">
		<formly-field *ngFor="let f of field.fieldGroup; trackBy: trackByFn" [field]="f"></formly-field>
	</div>
</div>
