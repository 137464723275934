import { FaceToFaceType } from '@app/modules/shared/enums/app.enums';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { getNullableBoolean } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';
import { GuarantorAdditionalCommentModel } from './guarantor-additional-comment.model';
import { GuarantorConfirmCompletedModel } from './guarantor-confirmed.model';
import { GuarantorIndependentAdviceModel } from './guarantor-independent-advice.model';
import { GuarantorInterviewCompletionModel } from './guarantor-interview-completion.model';
import { GuarantorInterviewModel } from './guarantor-interview.model';
import { GuarantorUnderstandingModel } from './guarantor-understanding.model';
import { GuarantorsDeliveryMethodModel } from './guarantors-delivery-method.model';
import { GuarantorEligibilityModel } from './guarantors-eligibility.model';
import { GuarantorsOpinionModel } from './guarantors-opinion.model';
import { GuarantorInterviewDetailsModel } from './guarrantor-interview-details.model';
import { SecurityGuaranteeModel } from './security-guarantee.model';
import { ServicingGuaranteeModel } from './servicing-guarantee.model';

export class GuarantorInterviewSectionTransformer {
	static toPayload(
		model: GuarantorInterviewSectionModel,
		applicationId: number,
		guarantorId: number
	): Partial<GuarantorInterviewDTO> {
		const securityGuarantee: SecurityGuaranteeModel = model.securityGuarantee ? model.securityGuarantee?.[0] : {};
		const servicingGuarantee: ServicingGuaranteeModel = model.servicingGuarantee ? model.servicingGuarantee?.[0] : {};
		const dto: Partial<GuarantorInterviewDTO> = {
			id: model.interviewDetails?.[0].id,
			applicationId,
			guarantorId,
			lendingGuaranteeId: model.interviewDetails?.[0].lendingGuaranteeId,
			lendingGuaranteeTypeId: model.interviewDetails?.[0].lendingGuaranteeTypeId,
			interviewDate: model.interviewDetails?.[0].date,
			occupation: model.interviewDetails?.[0].occupation,
			loanProduct: model.interviewDetails?.[0].loanFacilities,
			dateOfBirth: model.interviewDetails?.[0].dateOfBirth,
			phoneNumber: PhoneNumberHelper.toPayLoad(model.interviewDetails?.[0].phoneNumber),
			photoIdSighted: model.interviewDetails?.[0].photoId,
			interviewIsFaceToFace: model.interview?.[0].faceToFace,
			faceToFaceType: model.interview?.[0].faceToFaceType,
			readWithoutDifficulty: getNullableBoolean(model.interview?.[0].readWithoutDifficulty),
			readWithoutDifficultyVideo: getNullableBoolean(model.interview?.[0].readWithoutDifficultyVideo),
			onlyPersonInRoom: model.interview?.[0].onlyPersonInRoom,
			interviewIsOnlyPersonPresent: model.interview?.[0].onlyPersonPresent,
			advisedToSeekIndependentAdvice: model.interview?.[0].seekIndependentAdvice,
			confirmNotUnderPressure: getNullableBoolean(model.guarantorsOpinion?.[0].underPressure),
			suspectUnderPressure: getNullableBoolean(model.guarantorsEligibility?.[0].suspectUnderPressure),
			suspectMisrepresentation: getNullableBoolean(model.guarantorsEligibility?.[0].suspectMisrepresentation),
			suspectUnfairConduct: getNullableBoolean(model.guarantorsEligibility?.[0].suspectUnreasonableConduct),
			suspectDifficultyUnderstandingObligations: getNullableBoolean(model.guarantorsEligibility?.[0].suspectIncapable),
			isUnderPowerOfAttorney: getNullableBoolean(model.guarantorsEligibility?.[0].executedUnderPowerOfAttorney),
			isGuarantorMinor: getNullableBoolean(model.guarantorsEligibility?.[0].isGuarantorMinor),

			securityGuaranteeConfirmRelationshipToBorrower: getNullableBoolean(
				securityGuarantee?.spousalOrDeFactoRelationship
			),

			guarantorUnderstandingRelationshipDescription: model.guarantorUnderstanding?.[0].relationshipDescription,
			understandsGuarantorObligations: getNullableBoolean(model.guarantorUnderstanding?.[0].understandObligations),
			understandsGuaranteeSigningPeriod: getNullableBoolean(model.guarantorUnderstanding?.[0].codeOfPractice),
			adviseInformationUsed: model.guarantorUnderstanding?.[0].adviseInformationUsed,
			adviseReceiveDocumentsShortly: model.guarantorUnderstanding?.[0].adviseReceiveDocumentsShortly,
			adviseWithdraw: model.guarantorUnderstanding?.[0].adviseWithdraw,
			adviseKYC: model.guarantorUnderstanding?.[0].adviseKYC,
			documentationDeliveryMethodId: model.guarantorsDeliveryMethod?.[0].deliveryMethod,
			independentAdviceTypeRequiredId: model.independentAdvice?.[0].independentAdvice,
			comment: model.additionalComments?.[0].comment,

			servicingGuaranteeConfirmIsBenefitToFamily: getNullableBoolean(servicingGuarantee?.benefitToGuarantor),
			servicingGuaranteeConfirmRelationshipToBorrower: getNullableBoolean(servicingGuarantee?.spousalOrParent),
			servicingGuaranteeConfirmUnderstandsBorrowerReliance: getNullableBoolean(servicingGuarantee?.relyingOnPayments),
			servicingGuaranteeConfirmUnderstandsRepaymentsRequired: getNullableBoolean(
				servicingGuarantee?.makeRepaymentsForBorrower
			),
			securityGuaranteeConfirmCouldMeetLoanRepayments: getNullableBoolean(
				securityGuarantee?.meetRepaymentsAndLivingExpenses
			),
			securityGuaranteeConfirmWouldSellFamilyHome: getNullableBoolean(securityGuarantee?.sellFamilyHome),
			securityGuaranteeUnderstandsSecurityGuarantee: getNullableBoolean(securityGuarantee?.payGuaranteedAmount),
			confirmed: model.confirmCompleted?.[0].confirm,
			hideChecklist: model.interviewDetails?.[0].hideChecklist
		};

		dto.demonstratesReadingEnglish =
			dto.faceToFaceType === FaceToFaceType.InPerson ? dto.readWithoutDifficulty : dto.readWithoutDifficultyVideo;
		if (dto.demonstratesReadingEnglish == false) {
			dto.suspectDifficultyUnderstandingEnglish = true;
		}

		return dto;
	}
}

export interface GuarantorInterviewSectionModel {
	interviewDetails: GuarantorInterviewDetailsModel[];
	interview: GuarantorInterviewModel[];
	guarantorsOpinion: GuarantorsOpinionModel[];
	guarantorsEligibility: GuarantorEligibilityModel[];
	securityGuarantee: SecurityGuaranteeModel[];
	servicingGuarantee: ServicingGuaranteeModel[];
	guarantorUnderstanding: GuarantorUnderstandingModel[];
	guarantorsDeliveryMethod: GuarantorsDeliveryMethodModel[];
	independentAdvice: GuarantorIndependentAdviceModel[];
	interviewCompletion: GuarantorInterviewCompletionModel[];
	additionalComments: GuarantorAdditionalCommentModel[];
	confirmCompleted: GuarantorConfirmCompletedModel[];
}
