import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { frequencyToEnumObject } from '@app/modules/shared/helper/util';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FrequencyShort } from '@simpology/client-components/utils';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { Amount } from '../../typings/formly-app';

@Component({
	selector: 'formly-amount-select-input',
	templateUrl: './formly-amount-select-input.component.html',
	styleUrls: ['./formly-amount-select-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAmountInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	uniqueId = '';
	private destroy$ = new Subject<void>();

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (this.to?.defaultFrequency) {
			this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: Amount) => {
				if ((value.amount || Number(value.amount) === 0) && !value.frequency && !this.to.tooltipText) {
					const frequencyEnum = +this.to.defaultFrequency;
					const frequency = frequencyEnum > 0 ? frequencyEnum : FrequencyShort.Monthly;
					const frequencyControlValue = this.to.bindValueToObject ? frequencyToEnumObject(frequency) : frequency;
					this.formControl.get('frequency')?.setValue(frequencyControlValue);
				}
			});
		}
		if (isNil(this.formControl.get('amount')?.value) && (this.to?.defaultAmount || this.to?.defaultAmount === 0)) {
			this.formControl.get('amount')?.setValue(this.to.defaultAmount);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}
}
