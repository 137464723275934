import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { StepStatus } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { map, Observable, of, switchMap } from 'rxjs';

import { RefiStepType } from '../../enums/refi-steps.enum';
import {
	RefiProducts,
	RefiProductSelectDTO,
	RefiProductSelectModel,
	RefiProductSelectTransformer
} from '../../models/refi-product-select.model';

@Injectable({
	providedIn: 'root'
})
export class RefiProductSelectionApiService extends BaseJourneyService<number> {
	constructor(private applicationDataQuery: ApplicationDataQuery, private journeyStepService: JourneyStepService) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.PRODUCT);
	}

	getProducts(): Observable<RefiProducts[]> {
		return this.getCustom(`${this.applicationDataQuery.applicationId()}`).pipe(
			map((products: RefiProductSelectDTO[]) => [
				{
					products: RefiProductSelectTransformer.fromPayload(products).map((product) => ({ product }))
				}
			])
		);
	}

	selectProduct(productModel: RefiProductSelectModel): Observable<any> {
		const payload = RefiProductSelectTransformer.toPayload(productModel, this.applicationDataQuery.applicationId());
		return this.postCustom('', payload);
	}

	checkProductSelection(): void {
		this.getProducts()
			.pipe(
				switchMap((products: RefiProducts[]) => {
					if (!products[0].products.some((product) => product.product.selected)) {
						// If selected product is not in the list of products retrieved, then mark step as incomplete
						return this.journeyStepService.updateStep(RefiStepType.RefiSelectProduct, StepStatus.Incomplete);
					} else {
						return of(null);
					}
				})
			)
			.subscribe();
	}
}
