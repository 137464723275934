import { LoanPurposeObjectiveDTO } from '@app/modules/typings/api';

export class LoanPurposeObjectiveTransformer {
	static toPayload(model: LoanPurposeObjectiveModel): LoanPurposeObjectiveDTO {
		return {
			customerCircumstance: model.customerObjectiveDetails,
			productSuitability: model.customerSuitabilityDetails
		};
	}
	static fromPayload(data: LoanPurposeObjectiveDTO): LoanPurposeObjectiveModel {
		return {
			customerSuitabilityDetails: data.productSuitability,
			customerObjectiveDetails: data.customerCircumstance
		};
	}
}

export interface LoanPurposeObjectiveModel {
	customerSuitabilityDetails?: string;
	customerObjectiveDetails?: string;
}
