import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { FormValidationsQuery } from '@app/modules/shared/store/form-validations/form-validations.query';

import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { Subject, Subscription, of, takeUntil } from 'rxjs';
import { SimpFormlyHandlerService } from '../../services/simp-formly-handler.service';
import { CustomNgbModalRef, SimpFormlyModalService } from '../../services/simp-formly-modal.service';

@Component({
	selector: 'formly-mobile-sub-section',
	templateUrl: './formly-mobile-sub-section.component.html',
	styleUrls: ['./formly-mobile-sub-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMobileSubSectionComponent extends FieldArrayType implements OnInit, OnDestroy {
	@ViewChild('content') public contentTemplate?: TemplateRef<unknown>;
	@ViewChild('buttonInput') public buttonInput: ElementRef = {} as ElementRef;
	extract$ = of('');
	loading$ = of(true);
	data!: Array<any>;
	modalRef!: CustomNgbModalRef;
	subscription!: Subscription;
	private storeData: unknown[] = [];
	private destroy$: Subject<void> = new Subject();

	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private cdr: ChangeDetectorRef,
		private formValidationsQuery: FormValidationsQuery
	) {
		super();
	}

	ngOnInit(): void {
		const key = this.field.parent?.parent?.key as string;
		const currentKey = this.field.key as string;

		const data$ = this.simpFormlyHandlerService.mapToStateData(key);
		if (data$) {
			data$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
				const dataProperty = res[0] as { [key: string]: [] };
				const data = dataProperty[currentKey];
				this.data = data as [];
				this.storeData = cloneDeep(data);
				this.add(0);

				if (data) {
					if (this.formControl?.controls.length !== data.length) {
						const noOfDifItems = data.length - this.formControl?.controls.length ?? 0;
						if (noOfDifItems > 0) {
							for (let index = 0; index <= noOfDifItems; index++) {
								this.add();
							}
						} else {
							for (let index = 0; index < -noOfDifItems; index++) {
								this.remove(0);
							}
						}
					}
				}

				if (this.model && this.field.options?.checkExpressions) {
					Object.assign(this.model, data);
					// check expression to repopulate the controls hidden from DOM because of hideExpression.
					this.field.options.checkExpressions(this.field);
					this.cdr.markForCheck();
				}
				this.formControl.patchValue(data);
				setTimeout(() => {
					this.formControl.markAsPristine();
				});
			});
		}

		this.simpFormlyModalService.openExistingModal$.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			if (params?.key?.includes(this.field.key as string)) this.openExisting(params.index as number);
			if (params === null && this.modalRef) {
				this.modalRef.close();
				this.subscription.unsubscribe();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	onClick(event: Event): void {
		event.stopPropagation();
		this.add();
		this.openMobileModal(this.field);
	}

	openExisting(index: number): void {
		this.openMobileModal(this.field, index);
		this.formControl.controls[index].markAllAsTouched();
	}

	private openMobileModal(field: FormlyFieldConfig, index?: number): void {
		const formGroup = field.fieldGroup;
		if (formGroup && formGroup[formGroup.length - 1]) {
			const openModal = index !== undefined ? index : formGroup.length - 1;
			setTimeout(() => {
				this.modalRef = this.simpFormlyModalService.openModal(
					formGroup[openModal],
					`${field.key}Modal`,
					{
						windowClass: 'mobile-modal-class',
						animation: false,
						backdrop: false
					},
					true
				);

				this.subscription = this.modalRef.action.subscribe((action) => {
					if (action == 'submit') {
						if (this.to.click) {
							this.to.click(this.field, {
								index: openModal,
								action: action,
								changed:
									index !== undefined
										? this.formValidationsQuery.dataChanged(
												// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
												this.model[index] as Object,
												this.storeData[index] as Object,
												`${field.key}Modal`
										  )
										: true
							});
						}
					}
					if (action == 'cancel' || action == 'close') {
						if (this.data?.length < formGroup?.length) {
							this.remove(formGroup.length - 1);
						}
						if (this.to.click) {
							this.to.click(this.field, { index: openModal, action: action });
						}
						this.simpFormlyModalService.closeExistingModal();
					}
					if (action == 'delete') {
						if (this.to.click) {
							this.to.click(this.field, { index: openModal, action: action });
						}
					}

					this.modalRef.close();
					this.subscription.unsubscribe();
					return;
				});
			}, 100);
		}
	}
}
