export enum LoanApplicationStatus {
	Open = 1,
	Waiting = 2,
	Assessment = 3,
	Decision = 4,
	Solicitors = 5,
	Finalised = 6
}

export enum OverridableAccessLevel {
	All = 1,
	PrivilegedOnly = 2
}

export const statusBadge = (status: LoanApplicationStatus): string => {
	switch (status) {
		case LoanApplicationStatus.Open:
			return 'simp-badge-blue';
		case LoanApplicationStatus.Waiting:
			return 'simp-badge-orange';
		case LoanApplicationStatus.Assessment:
			return 'simp-badge-blue';
		case LoanApplicationStatus.Decision:
			return 'simp-badge-orange';
		case LoanApplicationStatus.Solicitors:
			return 'simp-badge-blue';
		case LoanApplicationStatus.Finalised:
			return 'simp-badge-green';
		default:
			return 'simp-badge-blue';
	}
};
