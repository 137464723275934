import { CONSTANTS } from '@app/modules/shared/constants/constants';

import {
	formlyAddress,
	formlyAmount,
	formlyAmountSelectInput,
	formlyArea,
	formlyArray,
	formlyButton,
	formlyButtonGroup,
	formlyButtonInput,
	formlyCheckbox,
	formlyCustom,
	formlyDatePicker,
	formlyDateRangePicker,
	formlyEmail,
	formlyFileUpload,
	formlyIcon,
	formlyInput,
	formlyLabel,
	formlyLink,
	formlyMobile,
	formlyMobileSubSection,
	formlyModal,
	FormlyMultiBadgeSelect,
	formlyMultiCheckboxSelect,
	formlyNumber,
	formlyNumberSelectInput,
	formlyObject,
	formlyPercentage,
	formlyPhone,
	formlySection,
	formlySelect,
	formlySubSection,
	formlyTable,
	formlyTextarea,
	formlyTickBox,
	formlyToggleSwitch
} from '.';
import { FormlyFieldTypes } from '../../enums/formly-field-types';
import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { formlyParsePermissions } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';
import { formlyAddressSelect } from './formly-address-select.helper';
import { formlyAmountTotal } from './formly-amount-total.helper';
import { formlyDuration } from './formly-duration.helper';
import { formlyFileImage } from './formly-file-image.helper';
import { formlyHeader } from './formly-header.helper';
import { formlyMobileModal } from './formly-mobile-modal.helper';
import { formlyMobileStepSection } from './formly-mobile-step.helper';
import { formlyNestedSection } from './formly-nested-section.helper';
import { formlyRadioButtonGroup } from './formly-radio-button-group.helper';
import { formlyRadioButton } from './formly-radio-button.helper';
import { formlySummary } from './formly-summary.helper';
import { formlyTelInput } from './formly-tel-input.helper';

const extractProperties = (properties: FormlyApiProperty[]): JsonSchema => {
	const response = {} as JsonSchema;
	(properties || []).forEach((prop) => {
		const schema = toJsonSchema(prop);
		if (schema.properties) {
			response.properties = response.properties || {};
			Object.assign(response.properties, { [prop.key!]: schema });
		} else {
			Object.assign(response, schema);
		}
	});
	return response;
};

const updateTitle = (property: FormlyApiProperty) => {
	const skipTitleAppendFor = [
		'object',
		'area',
		'modal',
		'mobile-modal',
		'section',
		'sub-section',
		'array',
		'checkbox',
		'radio-button',
		'tick-box',
		'button',
		'table',
		'label',
		'summary',
		'summarySection',
		'nested-section',
		'toggle-switch',
		'mobile-step',
		'link',
		'mobile-sub-section',
		'button-input',
		'expenses',
		'multi-checkbox-select'
	];
	property.title =
		property.templateOptions?.required ||
		!property.title ||
		String(property.title).includes(CONSTANTS.OPTIONAL) ||
		skipTitleAppendFor.includes(property.type || 'object') ||
		property.templateOptions?.disabled === true ||
		property.templateOptions?.readonly === true
			? property.title
			: `${property.title} ${CONSTANTS.OPTIONAL}`;
};

export const toJsonSchema = (
	property: FormlyApiProperty
): JsonSchemaProperty | { [key: string]: JsonSchemaProperty } => {
	updateTitle(property);

	// update template options based on property
	formlyParsePermissions(property);

	switch (property.type) {
		/** If it's root type, then don't define the key */
		case 'object': {
			return property.key
				? {
						[property.key]: formlyObject(property, extractProperties(property.properties as FormlyApiProperty[]))
				  }
				: formlyObject(property, extractProperties(property.properties as FormlyApiProperty[]));
		}
		case 'header': {
			return {
				[property.key as string]: formlyHeader(property, extractProperties(property.properties as FormlyApiProperty[]))
			};
		}
		case 'area': {
			return formlyArea(property, extractProperties(property.properties as FormlyApiProperty[]));
		}

		case 'button-input': {
			return {
				[property.key as string]: formlyButtonInput(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'modal': {
			// all arrays need to be default initialised for validations to work
			property.properties?.forEach((childProperty) => {
				if (childProperty.type === 'array' || childProperty.type === 'sub-section') {
					childProperty.templateOptions = Object.assign(childProperty.templateOptions ?? {}, {
						defaultValue:
							// JSON parser has issue parsing empty array
							childProperty.templateOptions?.defaultValue === '[]'
								? []
								: childProperty.templateOptions?.defaultValue ?? [{ default: true }]
					});
				}
			});
			return {
				[property.key as string]: formlyModal(property, extractProperties(property.properties as FormlyApiProperty[]))
			};
		}
		case 'mobile-modal': {
			return {
				[property.key as string]: formlyMobileModal(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'nested-section': {
			return {
				[property.key as string]: formlyNestedSection(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'summarySection':
		case 'summarySubSection':
		case 'summaryItem':
		case 'summaryArray': {
			return {
				[property.key as string]: formlySummary(property, extractProperties(property.properties as FormlyApiProperty[]))
			};
		}
		case 'section': {
			return formlySection(property, extractProperties(property.properties as FormlyApiProperty[]));
		}
		case 'mobile-step': {
			return {
				[property.key as string]: formlyMobileStepSection(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'sub-section': {
			return {
				[property.key as string]: formlySubSection(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'mobile-sub-section': {
			return {
				[property.key as string]: formlyMobileSubSection(
					property,
					extractProperties(property.properties as FormlyApiProperty[])
				)
			};
		}
		case 'array':
			return {
				[property.key as string]: formlyArray(property, extractProperties(property.properties as FormlyApiProperty[]))
			};
		case 'select':
			return {
				[property.key as string]: formlySelect(property)
			};
		case 'amount-select-input':
			return {
				[property.key as string]: formlyAmountSelectInput(property)
			};
		case 'amount-total':
			return {
				[property.key as string]: formlyAmountTotal(property)
			};
		case 'number-select-input':
			return {
				[property.key as string]: formlyNumberSelectInput(property)
			};
		case 'datepicker':
			return {
				[property.key as string]: formlyDatePicker(property)
			};
		case 'date-range-picker':
			return {
				[property.key as string]: formlyDateRangePicker(property)
			};
		case 'duration':
			return {
				[property.key as string]: formlyDuration(property)
			};
		case 'input':
			return {
				[property.key as string]: formlyInput(property)
			};
		case 'phone':
			return {
				[property.key as string]: formlyPhone(property)
			};
		case FormlyFieldTypes.TelInput:
			return {
				[property.key as string]: formlyTelInput(property)
			};
		case 'mobile':
			return {
				[property.key as string]: formlyMobile(property)
			};
		case 'email':
			return {
				[property.key as string]: formlyEmail(property)
			};
		case 'amount':
			return {
				[property.key as string]: formlyAmount(property)
			};
		case 'checkbox':
			return {
				[property.key as string]: formlyCheckbox(property)
			};
		case 'radio-button':
			return {
				[property.key as string]: formlyRadioButton(property)
			};
		case 'radio-button-group':
			return {
				[property.key as string]: formlyRadioButtonGroup(property)
			};
		case 'custom':
			return {
				[property.key as string]: formlyCustom(property)
			};
		case 'button-group':
			return {
				[property.key as string]: formlyButtonGroup(property)
			};
		case 'button':
			return {
				[property.key as string]: formlyButton(property)
			};
		case 'tick-box':
			return {
				[property.key as string]: formlyTickBox(property)
			};
		case 'address':
			return {
				[property.key as string]: formlyAddress(property)
			};
		case 'address-select':
			return {
				[property.key as string]: formlyAddressSelect(property)
			};
		case 'number':
			return {
				[property.key as string]: formlyNumber(property)
			};
		case 'percentage':
			return {
				[property.key as string]: formlyPercentage(property)
			};
		case 'icon':
			return {
				[property.key as string]: formlyIcon(property)
			};
		case 'table':
			return {
				[property.key as string]: formlyTable(property)
			};
		case 'multi-badge-select':
			return {
				[property.key as string]: FormlyMultiBadgeSelect(property)
			};
		case 'label':
			return {
				[property.key as string]: formlyLabel(property)
			};
		case 'textarea':
			return {
				[property.key as string]: formlyTextarea(property)
			};
		case 'toggle-switch':
			return {
				[property.key as string]: formlyToggleSwitch(property)
			};
		case 'multi-checkbox-select':
			return {
				[property.key as string]: formlyMultiCheckboxSelect(property)
			};
		case 'link':
			return {
				[property.key as string]: formlyLink(property)
			};
		case 'file-upload':
			return {
				[property.key as string]: formlyFileUpload(property)
			};
		case 'file-image':
			return {
				[property.key as string]: formlyFileImage(property)
			};
		default:
			return {
				/** Custom fields can carry child properties for labels or content projected components */
				[property.key as string]: formlyCustom(property, extractProperties(property.properties as FormlyApiProperty[]))
			};
	}
};
