import { ProofOfIdentityDTO } from '@app/modules/applicants/typings/proof-of-identity-dto';
import { RequirementCondition, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormlyButtonInputModel } from '@app/modules/simp-formly/models/formly-button-input.model';
import { FormlyUploadedFilesModel } from '@app/modules/simp-formly/models/formly-file-upload.model';
import { DocumentFileModel } from '@app/modules/typings/document-checklist-template-details-dto';

export const poiTemplateFields = ['categoryName', 'identityDocumentTypeEnum', 'identityDocumentType'];
export const poiSecuredFields = [
	'documentNumber',
	'cardNumber',
	'dateOfIssue',
	'expiryDate',
	'driversLicenceNumber',
	'countryOfIssueId',
	'australianStateOfIssueId',
	'medicareCardIndividualReferenceNumber',
	'medicareCardColourId'
];

export const defaultPoiSecureValue = '\u2022\u2022\u2022\u2022\u2022\u2022';

export class ProofOfIdentityModelTransformer {
	static fromPayload(proofOfIdentityDto: ProofOfIdentityDTO): ProofOfIdentityModel {
		const proofOfIdentityModel = {} as ProofOfIdentityModel;
		Object.assign(proofOfIdentityModel, proofOfIdentityDto);
		proofOfIdentityModel.original = getNullableYesNo(proofOfIdentityDto.original);
		proofOfIdentityModel.certifiedCopy = getNullableYesNo(proofOfIdentityDto.certifiedCopy);
		proofOfIdentityModel.nameVerified = getNullableYesNo(proofOfIdentityDto.nameVerified);
		proofOfIdentityModel.dobVerified = getNullableYesNo(proofOfIdentityDto.dobVerified);
		proofOfIdentityModel.residentialAddressVerified = proofOfIdentityDto.residentialAddressVerified
			? YesNo.Yes
			: YesNo.No;
		proofOfIdentityModel.photographVerified = getNullableYesNo(proofOfIdentityDto.photographVerified);
		proofOfIdentityModel.signatureVerified = getNullableYesNo(proofOfIdentityDto.signatureVerified);
		return proofOfIdentityModel;
	}

	static createDefaultProofOfIdentityModalObj(proofOfIdentityModel: ProofOfIdentityModel): ProofOfIdentityModel {
		return {
			id: proofOfIdentityModel.id,
			documentTypeId: proofOfIdentityModel.documentTypeId,
			categoryName: proofOfIdentityModel.categoryName,
			identityDocumentType: proofOfIdentityModel.identityDocumentType,
			documentCategoryId: proofOfIdentityModel.documentCategoryId,
			documentNumber: proofOfIdentityModel.documentNumber,
			driversLicenceNumber: proofOfIdentityModel.driversLicenceNumber,
			cardNumber: proofOfIdentityModel.cardNumber,
			dateOfIssue: proofOfIdentityModel.dateOfIssue,
			countryOfIssueId: proofOfIdentityModel.countryOfIssueId,
			australianStateOfIssueId: proofOfIdentityModel.australianStateOfIssueId,
			placeOfIssue: proofOfIdentityModel.placeOfIssue,
			issuingOrganisation: proofOfIdentityModel.issuingOrganisation,
			expiryDate: proofOfIdentityModel.expiryDate,
			nameOnDocument: proofOfIdentityModel.nameOnDocument,
			middleNameOnDocument: proofOfIdentityModel.middleNameOnDocument,
			dateDocumentVerified: proofOfIdentityModel.dateDocumentVerified,
			medicareCardColourId: proofOfIdentityModel.medicareCardColourId,
			medicareCardIndividualReferenceNumber: proofOfIdentityModel.medicareCardIndividualReferenceNumber,
			isPreviousNameIdentification: !!proofOfIdentityModel.isPreviousNameIdentification,
			original: proofOfIdentityModel.original,
			certifiedCopy: proofOfIdentityModel.certifiedCopy,
			nameVerified: proofOfIdentityModel.nameVerified,
			dobVerified: proofOfIdentityModel.dobVerified,
			residentialAddressVerified: proofOfIdentityModel.residentialAddressVerified,
			photographVerified: proofOfIdentityModel.photographVerified,
			signatureVerified: proofOfIdentityModel.signatureVerified,
			uploadedDocs: proofOfIdentityModel.uploadedDocs || []
		};
	}
}

export interface ProofOfIdentityDetailsModel {
	identityDocumentDetails: IdentityDocumentDetailsModel;
}

export interface IdentityDocumentDetailsModel extends FormlyButtonInputModel {
	poiIndex: number;
	poiDocTemplate: PoiDocTemplate;
	poiHtmlInstructions: string;
	isPOIStatusCompleted: boolean;
	proofOfIdentityModal: ProofOfIdentityModel;
}

export interface ProofOfIdentityModel extends FormlyUploadedFilesModel {
	id: number;
	documentTypeId: number;
	categoryName: string;
	identityDocumentType: string;
	documentCategoryId: number;
	documentNumber?: string;
	driversLicenceNumber?: string;
	cardNumber?: string;
	dateOfIssue?: Date | string;
	countryOfIssueId: number;
	australianStateOfIssueId: number;
	placeOfIssue: string;
	issuingOrganisation: string;
	expiryDate?: Date | string;
	nameOnDocument: string;
	middleNameOnDocument: string;
	dateDocumentVerified: Date;
	medicareCardColourId: number;
	medicareCardIndividualReferenceNumber: number;
	isPreviousNameIdentification?: boolean;
	original?: YesNo;
	certifiedCopy?: YesNo;
	nameVerified?: YesNo;
	dobVerified?: YesNo;
	residentialAddressVerified?: YesNo;
	photographVerified?: YesNo;
	signatureVerified?: YesNo;
	uploadedDocs: Array<DocumentFileModel>;
}

export interface IdentityDocumentUploadModel {
	proofOfIdentityId?: number;
	ladmApplicationId?: number;
	typeId: number;
	checklistItemName: string;
	personApplicantId: number;
	documentNumber: string;
	cardNumber: string;
	dateOfIssue: Date;
	countryOfIssueId: number;
	australianStateOfIssueId: number;
	placeOfIssue: string;
	issuingOrganisation: string;
	expiryDate: Date;
	nameOnDocument: string;
	middleNameOnDocument: string;
	dateDocumentVerified: Date;
	medicareCardColourId: number;
	medicareCardIndividualReferenceNumber: number;
	isPreviousNameIdentification?: boolean;
	original: boolean;
	certifiedCopy: boolean;
	nameVerified: boolean;
	dobVerified: boolean;
	residentialAddressVerified: boolean;
	photographVerified: boolean;
	signatureVerified: boolean;
	personId: number;
}

export interface PoiDocTemplate {
	categoryName: string;
	identityDocumentTypeEnum: number;
	identityDocumentType: string;
	name: string;
	parentName: string;
	instructionShort: string;
	instructionHtml: string;
	condition: RequirementCondition;
	conditionNumber: number;
	numOfRequiredDocs: number;
}

export interface ParentDocumentTemplate {
	name: string;
	condition: number;
	conditionNumber: number;
	isVerified: boolean;
	isValid: boolean;
	isFlat: boolean;
	children: PoiDocTemplate[];
}
