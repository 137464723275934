import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyAmount = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'amount',
	title: property.title,
	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || '')}`,
			templateOptions: {
				...property.templateOptions,
				minValue: property.templateOptions?.min,
				maxValue: property.templateOptions?.max,
				min: undefined,
				max: undefined
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			resetOnHide: !!property.templateOptions?.resetOnHide,
			defaultValue: isNaN(property.templateOptions?.defaultValue as number)
				? undefined
				: Number(property.templateOptions?.defaultValue)
		}
	}
});
