import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SignatureRequiredDetailsDto } from '@app/modules/summary-lodgement/models/signature-required-details';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApplicationFormElectronicSignaturesService extends BaseJourneyService<any> {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setRoute(URL_CONSTANTS.FORM_GENERATION);
	}

	getApplicationSignatures(templateDocumentId: number): Observable<SignatureRequiredDetailsDto[]> {
		return <Observable<SignatureRequiredDetailsDto[]>>(
			this.getCustom(`getApplicationSignatures/${this.applicationDataQuery.applicationId()}/${templateDocumentId}`)
		);
	}
}
