<ng-container *ngIf="tooltipHtml">
	<button
		[id]="uniqueId"
		[type]="to.type || 'button'"
		[ngClass]="to.btnClass"
		(click)="onClick($event)"
		[ngbTooltip]="tipContent"
		[placement]="to.placement || 'top'"
		container="body"
		triggers="click"
		[autoClose]="'outside'"
		tooltipClass="custom-tool-tip"
		[disabled]="to.disabled || to.readonly"
		[attr.aria-label]="to.ariaLabel || to.label"
		[attr.data-testid]="uniqueId"
	>
		{{ to.label }}<simp-icon class="icon-container" *ngIf="to.iconClass" [name]="to.iconClass"></simp-icon>
	</button>
</ng-container>

<ng-container *ngIf="!tooltipHtml">
	<button
		[id]="uniqueId"
		[disabled]="to.disabled || to.readonly"
		[type]="to.type || 'button'"
		[ngClass]="to.btnClass"
		(click)="onClick($event)"
		[attr.aria-label]="to.ariaLabel || to.label"
		[attr.data-testid]="uniqueId"
	>
		{{ to.label }}<simp-icon class="icon-container" *ngIf="to.iconClass" [name]="to.iconClass"></simp-icon>
	</button>
</ng-container>

<ng-template #tipContent
	><div class="custom-tool-tip__container simp-text" [innerHtml]="tooltipHtml"></div
></ng-template>
