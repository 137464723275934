import { LoanServiceabilitySummaryModel } from '@app/modules/loan-serviceability/model/loan-serviceability-summary.model';
import { SetupSummaryModel } from '@app/modules/setup/model/setup-summary.model';
import { RbaLendingPurpose, YesNo } from '@app/modules/shared/enums/app.enums';

export class ApplicationSummaryTransformer {
	static populateApplicationSummaryModel(fullApplicationSummary: FullApplicationSummary): ApplicationSummaryModel {
		const applicationSummaryModel: ApplicationSummaryModel = {
			applicantDetails: { applicants: [] },
			securityDetails: { securities: [] },
			purposeDetails: { purposes: [] },
			totalRequiredDetails: { totalRequiredAmount: '' },
			contributionsDetails: { contributions: [] },
			productsDetails: { products: [{}] },
			totalAvailableDetails: { totalAvailableAmount: 0 },
			applicationLVRDetails: { applicationLVR: { amount: '' } },
			applicationUMIDetails: { applicationUMI: { amount: '' } }
		};

		applicationSummaryModel.applicantDetails.applicants = buildApplicants(fullApplicationSummary.setupSummary);
		applicationSummaryModel.securityDetails.securities = buildFMV(fullApplicationSummary.loanAndServiceabilitySummary);
		applicationSummaryModel.purposeDetails.purposes = buildPurposes(fullApplicationSummary.setupSummary);
		applicationSummaryModel.totalRequiredDetails.totalRequiredAmount =
			fullApplicationSummary.setupSummary?.applicantSummary?.amount;

		const contributionDetails = buildContributions(fullApplicationSummary.loanAndServiceabilitySummary);
		applicationSummaryModel.contributionsDetails.contributions = contributionDetails.contributions;
		applicationSummaryModel.totalAvailableDetails.totalAvailableAmount = contributionDetails.totalAvailableAmount;

		const productDetails = buildProducts(fullApplicationSummary.loanAndServiceabilitySummary);
		applicationSummaryModel.productsDetails.products = productDetails.products;
		applicationSummaryModel.totalAvailableDetails.totalAvailableAmount += productDetails.totalAvailableAmount;

		applicationSummaryModel.applicationLVRDetails.applicationLVR.amount = buildLvr(
			fullApplicationSummary.loanAndServiceabilitySummary
		);
		applicationSummaryModel.applicationUMIDetails.applicationUMI.amount = buildUMI(
			fullApplicationSummary.loanAndServiceabilitySummary
		);

		return applicationSummaryModel;
	}
}

const buildApplicants = (setupSummaryModel: SetupSummaryModel) => {
	return setupSummaryModel?.applicantSummary?.applicantInfo?.map((applicant) => {
		const applicantType = !applicant[`applicantType`].includes('Guarantor') ? applicant[`applicantType`] : 'Guarantor';
		return { applicantName: `${applicant[`name`]} (${applicantType})` };
	});
};

const buildFMV = (loanServiceabilitySummaryModel: LoanServiceabilitySummaryModel): security[] | [{}] => {
	const securities = loanServiceabilitySummaryModel.purchaseDetails?.[0]?.propertyDetails?.map((propertyDetail) => {
		let amount = 0;
		if (propertyDetail[`isLimitedGuarantee`] == YesNo.Yes) {
			amount = (propertyDetail[`guaranteeAmount`] as number) ?? 0;
		} else {
			amount = (propertyDetail[`fairMarketValue`] as number) ?? 0;
		}

		return {
			address: propertyDetail[`address`],
			amount
		};
	});
	return securities ?? [{}];
};

const buildPurposes = (setupSummaryModel: SetupSummaryModel) => {
	return [
		{
			purpose: setupSummaryModel?.applicantSummary?.primaryLendingPurpose,
			amount: setupSummaryModel?.applicantSummary?.amount
		}
	];
};

const buildContributions = (loanServiceabilitySummaryModel: LoanServiceabilitySummaryModel) => {
	const contributions: { contributionDescription?: string; contributionAmount?: number }[] = [];
	let totalAvailableAmount = 0;
	if (loanServiceabilitySummaryModel?.depositsContributions?.[0]?.depositsContributionsWithAmount?.length) {
		const depositsRecord = {
			contributionDescription: 'Deposits',
			contributionAmount: 0
		};
		loanServiceabilitySummaryModel?.depositsContributions?.[0].depositsContributionsWithAmount?.forEach((data) => {
			if (data.contributions && data.contributionAmount) {
				totalAvailableAmount += data.contributionAmount;
				if (data.contributions === 'Deposit') {
					depositsRecord.contributionAmount += data.contributionAmount;
				} else {
					contributions.push({
						contributionDescription: data.contributions,
						contributionAmount: data.contributionAmount
					});
				}
			}
		});
		// all the deposits should be shown as a one record
		if (depositsRecord.contributionAmount > 0) {
			contributions.push(depositsRecord);
		}
	} else {
		contributions.push({});
	}
	return { contributions, totalAvailableAmount };
};

const buildProducts = (loanServiceabilitySummaryModel: LoanServiceabilitySummaryModel) => {
	const products: { productName?: string; borrowingAmount?: number }[] = [{}];
	let totalAvailableAmount = 0;
	if (loanServiceabilitySummaryModel.loanRequirements?.length > 0) {
		loanServiceabilitySummaryModel?.loanRequirements.forEach((data, index) => {
			const ownership = data?.ownership;
			if (ownership?.['preferredProduct']) {
				totalAvailableAmount += (ownership?.['totalBorrowingAmountAsNumber'] as number) ?? 0;
				products[index] = {
					productName: ownership?.['preferredProduct'] as string,
					borrowingAmount: (ownership?.['totalBorrowingAmountAsNumber'] as number) ?? 0
				};
			}
		});
	}
	return { products, totalAvailableAmount };
};

const buildLvr = (loanServiceabilitySummaryModel: LoanServiceabilitySummaryModel) => {
	const secondaryDetails = loanServiceabilitySummaryModel?.serviceability?.[0]?.secondaryDetails;
	return secondaryDetails?.['overall_lvr']?.[`value`] ?? undefined;
};

const buildUMI = (loanServiceabilitySummaryModel: LoanServiceabilitySummaryModel) => {
	const secondaryDetails = loanServiceabilitySummaryModel?.serviceability?.[0]?.secondaryDetails;
	return secondaryDetails?.['uncommitted_monthly income']?.[`value`] ?? undefined;
};

export interface FullApplicationSummary {
	applicationSummarySection: ApplicationSectionSummary;
	setupSummary: SetupSummaryModel;
	applicantsSummary: any;
	financialPositionSummary: any;
	loanAndServiceabilitySummary: LoanServiceabilitySummaryModel;
}

export interface ApplicationSectionSummary {
	applicationSummary: ApplicationSummaryModel[];
}

export interface ApplicationSummaryModel {
	applicantDetails: { applicants?: { applicantName?: string }[] };
	securityDetails: { securities: security[] };
	purposeDetails: { purposes: { purpose: RbaLendingPurpose | undefined; amount: string | undefined }[] };
	totalRequiredDetails: { totalRequiredAmount: string | undefined };
	contributionsDetails: { contributions: { contributionDescription?: string; contributionAmount?: number }[] };
	productsDetails: { products: { productName?: string; borrowingAmount?: number }[] };
	totalAvailableDetails: { totalAvailableAmount: number };
	applicationLVRDetails: { applicationLVR: { amount?: string } };
	applicationUMIDetails: { applicationUMI: { amount?: string } };
}

export interface security {
	address?: string;
	amount?: number;
}
