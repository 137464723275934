<form class="form-group">
	<label class="simp-label" [for]="uniqueId">{{ to.label }}</label>
	<div class="input-group">
		<input
			#durationInput
			[id]="uniqueId"
			class="form-control simp-form-control"
			[class.is-invalid]="formControl.invalid && (formControl.dirty || formControl.touched)"
			placeholder="DD/MM/YYYY - DD/MM/YYYY"
			[name]="id"
			[autoClose]="'outside'"
			[displayMonths]="2"
			[readonly]="!!to.readonly"
			[dayTemplate]="dayTemplate"
			[minDate]="to.minDate || defaultMinDate"
			[maxDate]="to.maxDate || defaultMaxDate"
			outsideDays="hidden"
			[required]="$any(to.required)"
			ngbDatepicker
			#durationPicker="ngbDatepicker"
			[disabled]="$any(to.disabled)"
			(blur)="updateFormControl($any($event.target).value)"
			metadataKey
			[formlyField]="field"
			(keyup)="setErrors($any($event.target).value)"
		/>
		<div class="input-group-text">
			<button
				#buttonDatePicker
				class="btn"
				(click)="toggleDatePicker()"
				type="button"
				[disabled]="to.disabled || to.readonly"
			>
				<i class="fal fa-calendar-alt"></i>
			</button>
		</div>
	</div>
</form>
<ng-template #dayTemplate let-date="date" let-focused="focused">
	<span
		class="custom-day"
		[class.focused]="focused"
		[class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
		[class.faded]="isHovered(date) || isInside(date)"
		(click)="onDateSelection(date)"
		(mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null"
	>
		{{ date.day }}
	</span>
</ng-template>

<simp-field-error
	*ngIf="(formControl.touched || formControl.dirty || formControl.touched) && formControl.errors"
	[errors]="formControl.errors"
	[validationErrors]="field.validation?.messages!"
>
</simp-field-error>

<!-- sync up form control events -->
<input class="hidden" type="input" [formControl]="$any(formControl)" [formlyAttributes]="field" />
