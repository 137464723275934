import { HouseholdAndFamilyTransformer } from '@app/modules/applicants/models/household-and-family.model';
import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantRole, ApplicantType } from '@app/modules/shared/enums/app.enums';
import {
	applicantRoleToApplicantPrimary,
	applicantRoleToApplicantType,
	getApplicantRole
} from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { BankDetailsDTO, PersonApplicantDTO } from '@app/modules/typings/api';
import { PersonAddresses, PersonApplicant, RelatedPersonAndContacts } from '../typings/applicants';
import { PersonContactDetailsTransformer } from './contact-details.model';
import { PersonInsurancesModel } from './insurances-model';
import { LendingGuaranteeModel } from './lending-guarantee.model';
import { NextOfKinTransformer } from './next-of-kin.model';
import { PersonOtherDetailsTransformer } from './other-details-model';
import { PersonalDetailsTransformer } from './personal-details.model';
import { ResidencyTransformer } from './residency.model';

export class PersonApplicantTransformer {
	static fromPayload(
		applicant: PersonApplicantDTO,
		titleLabel: string,
		consentInitiated: boolean,
		formEnumsQuery: FormEnumsQuery,
		relatedPersonsAndContacts: RelatedPersonAndContacts
	): PersonApplicant {
		return {
			id: applicant.id ?? CONSTANTS.NEW_ID,
			securePersonId: applicant.securePersonId,
			title: applicant?.title,
			firstName: applicant.firstName,
			lastName: applicant.lastName,
			primaryApplicant: applicant.primaryApplicant,
			receivesAllNotification: applicant.receivesAllNotification,
			type: [
				{
					role: applicant.applicantType
				}
			],
			lendingGuarantee: [{} as LendingGuaranteeModel],
			personalDetails: [PersonalDetailsTransformer.fromPayload(applicant, titleLabel, consentInitiated)],
			contactDetails: [PersonContactDetailsTransformer.fromPayload(applicant, consentInitiated)],
			nextOfKin: NextOfKinTransformer.fromPayload(applicant, formEnumsQuery, relatedPersonsAndContacts),
			addresses: [{} as PersonAddresses],
			banking: [{} as BankDetailsDTO],
			residency: [ResidencyTransformer.fromPayload(applicant, formEnumsQuery)],
			employments: [{} as ApplicantEmploymentModel],
			insurances: [{} as PersonInsurancesModel],
			otherDetails: [
				PersonOtherDetailsTransformer.fromPayload(
					applicant,
					getApplicantRole(applicant.primaryApplicant, applicant.applicantType)
				)
			],
			householdId: applicant.householdId ?? CONSTANTS.NEW_ID,
			relationshipInfo: HouseholdAndFamilyTransformer.relationshipsFromPayload(
				applicant,
				formEnumsQuery,
				consentInitiated
			),
			editMode: false,
			applicantRole: getApplicantRole(applicant.primaryApplicant, applicant.applicantType),
			applicantType: applicant.applicantType
		};
	}

	static toPayload(applicant: PersonApplicant, applicationId: number): Partial<PersonApplicantDTO> {
		return {
			id: applicant.id ? applicant.id : CONSTANTS.NEW_ID,
			securePersonId: applicant.securePersonId,
			applicationId,
			receivesAllNotification: !!applicant.receivesAllNotification,
			applicantType: applicant.applicantRole
				? applicantRoleToApplicantType(applicant.applicantRole)
				: applicant.applicantType || applicant.type[0]?.role,
			...PersonalDetailsTransformer.toPayload(applicant),
			primaryApplicant: applicant.primaryApplicant || applicantRoleToApplicantPrimary(applicant.applicantRole),
			...(applicant.contactDetails?.length
				? PersonContactDetailsTransformer.toPayload(applicant.contactDetails[0])
				: {}),
			...(applicant.otherDetails?.length ? PersonOtherDetailsTransformer.toPayload(applicant.otherDetails[0]) : {}),
			...(applicant.residency?.length ? ResidencyTransformer.toPayload(applicant.residency[0]) : {}),
			householdId: applicant.householdId,
			...HouseholdAndFamilyTransformer.toRelationshipPayload(applicant.relationshipInfo),
			nextOfKinPersonId: applicant.nextOfKin?.length ? applicant.nextOfKin[0].existingNextOfKin.id : undefined,
			nextOfKinPersonTypeId: applicant.nextOfKin?.length ? applicant.nextOfKin[0].existingNextOfKin.type : undefined,
			nextOfKinRelationshipId: applicant.nextOfKin?.length ? applicant.nextOfKin[0].nextOfKinRelationship : undefined
		};
	}
}

export const setupToPersonApplicant = (setUpApplicant: SetUpApplicant): PersonApplicant => ({
	id: setUpApplicant.id,
	primaryApplicant: setUpApplicant.primaryApplicant,
	firstName: setUpApplicant.firstName!,
	lastName: setUpApplicant.lastName!,
	receivesAllNotification: setUpApplicant.receivesAllNotification,
	type: [],
	editMode: false,
	applicantRole: setUpApplicant.applicantRole
		? [ApplicantRole.Coborrower, ApplicantRole.PrimaryBorrower].includes(setUpApplicant.applicantRole)
			? setUpApplicant.applicantRole
			: ApplicantRole.Guarantor
		: ApplicantRole.PrimaryBorrower,
	applicantType: setUpApplicant.applicantType || ApplicantType.Borrower
});
