import { BorrowingInfo } from '@app/modules/loan-serviceability/model/borrowing.model';
import { RbaLendingPurpose, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { RefinancingAndConsolidationDTO } from '@app/modules/typings/api';

export interface RefinancingAndConsolidationModel {
	closeToEndOfCurrentLoanTerm?: boolean;
	closeToEndOfCurrentLoanTermDetails?: string;
	debtConsolidation?: boolean;
	debtConsolidationDetails?: string;
	dissatisfactionWithCurrentLender?: boolean;
	dissatisfactionWithCurrentLenderDetails?: string;
	greaterFlexibility?: boolean;
	greaterFlexibilityDetails?: string;
	increaseTotalLoanAmount?: boolean;
	increaseTotalLoanAmountDetails?: string;
	lowerInterestRate?: boolean;
	lowerInterestRateDetails?: string;
	reducedRepayments?: boolean;
	reducedRepaymentsDetails?: string;
	other?: boolean;
	otherDetails?: string;
	explainedIncreaseInterestRisk?: boolean;
	explainedIncreaseLoanTermRisk?: boolean;
	planToCloseOrReduceCreditCard?: YesNo;
	detailsOfCreditCardPlan?: string;
	lendingPurposes?: RbaLendingPurpose[];
	absLendingPurposes?: number[];
}

export class RefinancingAndConsolidationTransformer {
	static toPayload(model: RefinancingAndConsolidationModel, applicationId: number): RefinancingAndConsolidationDTO {
		return {
			applicationId: applicationId,
			refinancingReasonCloseToEndOfCurrentLoanTerm: model.closeToEndOfCurrentLoanTerm,
			refinancingReasonCloseToEndOfCurrentLoanTermDetails: model.closeToEndOfCurrentLoanTermDetails,
			refinancingReasonDebtConsolidation: model.debtConsolidation,
			refinancingReasonDebtConsolidationDetails: model.debtConsolidationDetails,
			refinancingReasonDissatisfactionWithCurrentLender: model.dissatisfactionWithCurrentLender,
			refinancingReasonDissatisfactionWithCurrentLenderDetails: model.dissatisfactionWithCurrentLenderDetails,
			refinancingReasonGreaterFlexibility: model.greaterFlexibility,
			refinancingReasonGreaterFlexibilityDetails: model.greaterFlexibilityDetails,
			refinancingReasonIncreaseTotalLoanAmount: model.increaseTotalLoanAmount,
			refinancingReasonIncreaseTotalLoanAmountDetails: model.increaseTotalLoanAmountDetails,
			refinancingReasonLowerInterestRate: model.lowerInterestRate,
			refinancingReasonLowerInterestRateDetails: model.lowerInterestRateDetails,
			refinancingReasonReducedRepayments: model.reducedRepayments,
			refinancingReasonReducedRepaymentsDetails: model.reducedRepaymentsDetails,
			refinancingReasonOther: model.other,
			refinancingReasonOtherDetails: model.otherDetails,
			planToCloseOrReduceCreditCard: getNullableBoolean(model.planToCloseOrReduceCreditCard),
			detailsOfCreditCardPlan: model.detailsOfCreditCardPlan,
			explainedIncreaseInterestRisk: model.explainedIncreaseInterestRisk,
			explainedIncreaseLoanTermRisk: model.explainedIncreaseLoanTermRisk
		};
	}

	static fromPayload(
		refinancingAndConsolidationDTO: RefinancingAndConsolidationDTO,
		borrowings: BorrowingInfo[]
	): RefinancingAndConsolidationModel {
		return {
			closeToEndOfCurrentLoanTerm: refinancingAndConsolidationDTO.refinancingReasonCloseToEndOfCurrentLoanTerm,
			closeToEndOfCurrentLoanTermDetails:
				refinancingAndConsolidationDTO.refinancingReasonCloseToEndOfCurrentLoanTermDetails,
			debtConsolidation: refinancingAndConsolidationDTO.refinancingReasonDebtConsolidation,
			debtConsolidationDetails: refinancingAndConsolidationDTO.refinancingReasonDebtConsolidationDetails,
			dissatisfactionWithCurrentLender:
				refinancingAndConsolidationDTO.refinancingReasonDissatisfactionWithCurrentLender,
			dissatisfactionWithCurrentLenderDetails:
				refinancingAndConsolidationDTO.refinancingReasonDissatisfactionWithCurrentLenderDetails,
			greaterFlexibility: refinancingAndConsolidationDTO.refinancingReasonGreaterFlexibility,
			greaterFlexibilityDetails: refinancingAndConsolidationDTO.refinancingReasonGreaterFlexibilityDetails,
			increaseTotalLoanAmount: refinancingAndConsolidationDTO.refinancingReasonIncreaseTotalLoanAmount,
			increaseTotalLoanAmountDetails: refinancingAndConsolidationDTO.refinancingReasonIncreaseTotalLoanAmountDetails,
			lowerInterestRate: refinancingAndConsolidationDTO.refinancingReasonLowerInterestRate,
			lowerInterestRateDetails: refinancingAndConsolidationDTO.refinancingReasonLowerInterestRateDetails,
			reducedRepayments: refinancingAndConsolidationDTO.refinancingReasonReducedRepayments,
			reducedRepaymentsDetails: refinancingAndConsolidationDTO.refinancingReasonReducedRepaymentsDetails,
			other: refinancingAndConsolidationDTO.refinancingReasonOther,
			otherDetails: refinancingAndConsolidationDTO.refinancingReasonOtherDetails,
			planToCloseOrReduceCreditCard: getNullableYesNo(refinancingAndConsolidationDTO.planToCloseOrReduceCreditCard),
			detailsOfCreditCardPlan: refinancingAndConsolidationDTO.detailsOfCreditCardPlan,
			explainedIncreaseInterestRisk: refinancingAndConsolidationDTO.explainedIncreaseInterestRisk,
			explainedIncreaseLoanTermRisk: refinancingAndConsolidationDTO.explainedIncreaseLoanTermRisk,
			lendingPurposes: borrowings
				.flatMap((borrowing) => borrowing.lendingPurpose)
				.flatMap((lendingPurpose) => lendingPurpose?.primaryLendingPurpose) as unknown as RbaLendingPurpose[],
			absLendingPurposes: borrowings
				.flatMap((borrowing) => borrowing.lendingPurpose)
				.flatMap((lendingPurpose) => lendingPurpose?.absLendingPurposeCode) as unknown as number[]
		};
		// Note lendingPurpose?.primaryLendingPurpose is for all lending purposes lendingPurpose?.isPrimary is to identify primary lending purpose
	}
}
