import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyModal = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => {
	return {
		type: 'object',
		title: property.title,
		required: property.required,
		widget: {
			formlyConfig: {
				wrappers: ['modal'],
				className: `${property.templateOptions?.class ?? property.class}`,
				templateOptions: {
					customClass: 'simp-row',
					...property.templateOptions
				},
				hideExpression: parseExpressionString(property.hide),
				expressionProperties: parseExpressionProperties(property)
			}
		},
		properties: childProperties
	};
};
