import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {
	AdditionalCommentsCommentModel,
	AdditionalCommentsTransformer
} from '@app/modules/compliance/model/additional-comments.model';
import {
	AdditionalRepaymentsModel,
	AdditionalRepaymentsStoreModel
} from '@app/modules/compliance/model/additional-repayments.model';
import { AssociatedProductsTransformer } from '@app/modules/compliance/model/associated-products.model';
import { AttestationModel } from '@app/modules/compliance/model/attestation.model';
import { BackUpPlanModel } from '@app/modules/compliance/model/backup-plan.model';
import { CoApplicantInterviewDetails } from '@app/modules/compliance/model/co-applicant-interview.model';
import { CoBorrowerBenefitModel } from '@app/modules/compliance/model/co-borrower-model';
import {
	ComplianceComments,
	ComplianceCommentsTransformer
} from '@app/modules/compliance/model/compliance-comments.model';
import {
	NotesBrokerAttestation,
	NotesBrokerAttestationTransformer
} from '@app/modules/compliance/model/compliance-notesBrokerAttestation.model';
import {
	DocumentChecklistModel,
	DocumentChecklistTransformer
} from '@app/modules/compliance/model/document-checklist.model';
import { FixedInterestModel, FixedInterestStoreModel } from '@app/modules/compliance/model/fixed-interest.model';
import {
	FixedAndVariableExtras,
	FixedVariableStoredModel
} from '@app/modules/compliance/model/fixed-variable-interest.model';
import { HomeLoanInsuranceModel } from '@app/modules/compliance/model/homeloan-introducer.model';
import {
	InterestInAdvanceModel,
	InterestInAdvanceStoreModel
} from '@app/modules/compliance/model/interest-in-advice.model';
import { InterestOnlyModel, InterestOnlyStoreModel } from '@app/modules/compliance/model/interest-only.model';
import {
	InterviewDeclarationModel,
	InterviewDeclarationTransformer
} from '@app/modules/compliance/model/interview-declaration.model';
import {
	AnticipatedChanges,
	Interview,
	InterviewTransformer,
	MitigantFactor
} from '@app/modules/compliance/model/interview.model';
import { LineOfCreditModel, LineOfCreditStoreModel } from '@app/modules/compliance/model/line-of-credit.model';
import { LoanPurposeObjectiveModel } from '@app/modules/compliance/model/loan-puprose-objective.model';
import { LoanPurposeConflictsModel } from '@app/modules/compliance/model/loan-purpose-conflicts.model';

import { AppService } from '@app/app.service';
import { ComplianceService } from '@app/modules/compliance/compliance.service';
import { AdditionalNotesModel, AdditionalNotesStoreModel } from '@app/modules/compliance/model/addtional-notes.model';
import { LenderSalesChannel } from '@app/modules/compliance/model/lender-sales-channel.model';
import { LoanPreferencesSummaryModel } from '@app/modules/compliance/model/loan-preferences-summary.model';
import { LoanPurposeRationaleModel } from '@app/modules/compliance/model/loan-purpose-rationale.model';
import { OffsetModel, OffsetStoreModel } from '@app/modules/compliance/model/offset.model';
import {
	PrincipleAndInterestModel,
	PrincipleAndInterestStoreModel
} from '@app/modules/compliance/model/principle-and-Interest.model';
import { SelectedProduct, SelectedProductModel } from '@app/modules/compliance/model/product-selection';
import { RedrawModel, RedrawStoredModel } from '@app/modules/compliance/model/redraw.model';
import {
	RefinancingAndConsolidationModel,
	RefinancingAndConsolidationTransformer
} from '@app/modules/compliance/model/refinancing-and-consolidation.model';
import { RetirementModel } from '@app/modules/compliance/model/retirement.model';
import {
	MeetingRepayments,
	SignificantChangeModel,
	SignificantChangeSectionFormControl,
	SignificantChangeSectionModel,
	SignificantChangeTransformer
} from '@app/modules/compliance/model/significant-changes.model';
import {
	VariableInterestModel,
	VariableInterestStoreModel
} from '@app/modules/compliance/model/variable-interest.model';
import { getAUTimeZone } from '@app/modules/shared/helper/date.helper';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField,
	getInitializedFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty, FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, pick } from 'lodash-es';
import get from 'lodash-es/get';
import { ToastrService } from 'ngx-toastr';
import { catchError, debounceTime, distinctUntilChanged, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { CONSTANTS } from '../constants/constants';
import { YesNo } from '../enums/app.enums';
import { deletedSuccessfullyMessage, savedSuccessfullyMessage } from '../helper/util';
import { ShortApplicant } from '../model/applicant.model';
import { AccessService } from '../service/access.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { CurrentUserQuery } from '../store/current-user/current-user.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormStateService } from '../store/form-state/form-state.service';

@Injectable({ providedIn: 'root' })
export class ComplianceTransformerService {
	constructor(
		private accessService: AccessService,
		private applicationDataQuery: ApplicationDataQuery,
		private formStateService: FormStateService,
		private complianceService: ComplianceService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private toastrService: ToastrService,
		private currentUserQuery: CurrentUserQuery,
		private formDataService: FormDataService,
		private datePipe: DatePipe,
		private appService: AppService
	) {}

	transform(formFields: FormlyFieldConfig[] | undefined): Observable<FormlyFieldConfig[] | undefined> {
		return this.complianceService.fetchInterviewPersons().pipe(
			map(() => {
				return this.configFormFields(formFields);
			})
		);
	}

	private configFormFields(formFields: FormlyFieldConfig[] | undefined): FormlyFieldConfig[] | undefined {
		this.handleAdditionalComments(formFields);
		const complianceArea = formFields?.[0].fieldArray as FormlyFieldConfig;
		if (complianceArea) {
			this.setupInterviewDeclarationSection(formFields);
			this.setupSignificantChangesSection(formFields);
			this.setupLoanPurposeAndCustomerConversationSection(formFields);
		}
		const interviewDate = getFormField(
			formFields ? (formFields[0].fieldArray as FormlyFieldConfig)?.fieldGroup : formFields,
			'interview.interviewDetails.date'
		);
		if (interviewDate) {
			Object.assign(interviewDate, {
				...interviewDate,
				hooks: {
					onInit: (field: FormlyFieldConfig) => {
						if (!field.formControl?.value) {
							const date = new Date((field.model as Interview).interviewDate ?? '');
							field.formControl?.setValue(`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`);
						}
					}
				}
			});
		}

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			`loanPurposeAndCustomerConversation.loanPurposeAmountAndPreferredFeatures.loanSplitsArray.loanSplits.SplitLabel`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					const label = this.getSplitLoanLabel(field);
					field.template = label;

					return label;
				}
			}
		);

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			`loanPurposeAndCustomerConversation.productSelection.selectedProductsArray.selectedProductTitle`,
			{
				'templateOptions.label': (model: SelectedProduct, formState: unknown, field: FormlyFieldConfig) => {
					field.template = model?.selectedProductTitle;

					return model?.selectedProductTitle;
				}
			}
		);

		formlyRegisterHooks(
			formFields,
			'loanPurposeAndCustomerConversation.productSelection.selectedProductsArray.selectedProductTitle',
			{
				afterViewInit: (field) => {
					const model = field?.model as SelectedProduct;
					if (field && field.template) {
						field.template = model.selectedProductTitle;
					}
				}
			}
		);

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			`loanPurposeAndCustomerConversation.productSelection.loanSplitsArray.loanSplits.SplitLabel`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					const label = this.getSplitLoanLabel(field);
					field.template = label;
					return label;
				}
			}
		);

		[
			`associatedProducts.lendersMortgageInsurance.isLmiRequired`,
			`associatedProducts.creditCard.isCreditCardRequired`,
			`hligInterview.coBorrowerSubstantialBenefit.borrowerSpouseYesNo`,
			`hligInterview.coBorrowerSubstantialBenefit.borrowerCapacityYesNo`
		].forEach((fieldName) => {
			formlyExtendExpressionProperties(formFields as FormlyFieldConfig[], fieldName, {
				'templateOptions.dynamicValue': (
					model: {
						[key: string]: string;
					},
					formState: unknown,
					field: FormlyFieldConfig
				) => {
					const handler = field.props?.['handler'] as string;
					if (handler) {
						field.formControl?.setValue(model?.[field.props?.['handler'] as string] ? 1 : 0);
					}
				}
			});
		});

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			'hligInterview.coBorrowerSubstantialBenefit.predominantLoanPurpose',
			{
				'templateOptions.dynamicValue': (
					model: {
						[key: string]: string;
					},
					formState: unknown,
					field: FormlyFieldConfig
				) => {
					const handler = field.props?.['handler'] as string;
					if (handler) {
						field.formControl?.setValue(model?.[field.props?.['handler'] as string]);
					}
				}
			}
		);

		formlyRegisterHooks(formFields, 'associatedProducts', {
			afterViewInit: (field) => {
				if (field) {
					return field.formControl?.valueChanges.pipe(
						debounceTime((field?.props?.debounceTime as number) ?? 1000),
						distinctUntilChanged(),
						filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
						tap(() => {
							this.complianceService
								.saveAssociatedProducts(AssociatedProductsTransformer.toPayload(field.formControl?.value))
								.pipe(switchMap(() => this.complianceService.fetchAssociatedProducts()))
								.subscribe(() => {
									this.toastrService.success(savedSuccessfullyMessage('Associated products'));
								});
						})
					);
				}
				return of();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'interview.borrowingPurpose', (field) => {
			this.saveInterview(field, 'borrowingPurpose');
		});

		formlyOnStatusChangedToValid(formFields, 'interview.interviewDetails', (field) => {
			this.saveInterview(field, 'interviewDetails');
		});

		formlyRegisterHooks(formFields, `interview.borrowingPurpose.anticipatedChangesList.significantChanges.changeId`, {
			onInit: (field) => {
				const changeIdLabelField = getFormField(
					formFields,
					'interview.borrowingPurpose.anticipatedChangesList.significantChanges.changeIdLabel'
				) as FormlyFieldConfig;
				if (field.props && field.props.label && changeIdLabelField?.props?.label) {
					field.props.label = `${Number(field.parent?.key) + 1} - ${changeIdLabelField.props.label}`;
				}
			}
		});

		formlyRegisterHooks(formFields, `interview.borrowingPurpose.anticipatedChangesList.mitigants.factorId`, {
			onInit: (field) => {
				const factorIdLabelField = getFormField(
					formFields,
					'interview.borrowingPurpose.anticipatedChangesList.mitigants.factorIdLabel'
				) as FormlyFieldConfig;
				if (field.props && field.props.label && factorIdLabelField?.props?.label) {
					field.props.label = `${Number(field.parent?.key) + 1} - ${factorIdLabelField.props.label}`;
				}
			}
		});

		formlyOnStatusChangedToValid(formFields, 'interview.refinancingAndConsolidation', (field) => {
			const fieldModel = field.model as RefinancingAndConsolidationModel[];
			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService
					.saveRefinanceAndConsolidation(
						RefinancingAndConsolidationTransformer.toPayload(model, this.applicationDataQuery.applicationId())
					)
					.subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'interview.coApplicantInterview', (field) => {
			const fieldModel = field.model as CoApplicantInterviewDetails[];
			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveCoApplicantInterview(model).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'retirement.retirementDetails', this.saveRetirement.bind(this));

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.productFeatures.variableRateContainer', (field) => {
			const fieldModel = field.model as VariableInterestModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as VariableInterestStoreModel;

				this.complianceService.saveVariableInterest(model?.variableRateContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.productFeatures.fixedRateContainer', (field) => {
			const fieldModel = field.model as FixedInterestModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as FixedInterestStoreModel;
				this.complianceService.saveFixedInterest(model?.fixedRateContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			'loanPreferences.productFeatures.fixedAndVariableInterestRateContainer',
			(field) => {
				const fieldModel = field.model as FixedVariableStoredModel[];
				if (fieldModel) {
					const model = Object.assign({}, fieldModel[0], field.form?.value) as FixedVariableStoredModel;

					this.complianceService.saveFixedVariableInterest(model?.fixedAndVariableInterestRateContainer).subscribe();
				}
			}
		);

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.productFeatures.redrawContainer', (field) => {
			const fieldModel = field.model as RedrawModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as RedrawStoredModel;
				this.complianceService.saveRedraw(model?.redrawContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.repayments.principalAndInterestContainer', (field) => {
			const fieldModel = field.model as PrincipleAndInterestModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as PrincipleAndInterestStoreModel;
				this.complianceService.savePrincipleAndInterest(model?.principalAndInterestContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.repayments.interestOnlyContainer', (field) => {
			const fieldModel = field.model as InterestOnlyModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as InterestOnlyStoreModel;

				this.complianceService.saveInterestOnly(model?.interestOnlyContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.repayments.interestInAdvanceContainer', (field) => {
			const fieldModel = field.model as InterestInAdvanceModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as InterestInAdvanceStoreModel;

				this.complianceService.saveInterestInAdvance(model?.interestInAdvanceContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.repayments.lineOfCreditContainer', (field) => {
			const fieldModel = field.model as LineOfCreditModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as LineOfCreditStoreModel;

				this.complianceService.saveLineOfCredit(model?.lineOfCreditContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.repayments.additionalRepaymentsContainer', (field) => {
			const fieldModel = field.model as AdditionalRepaymentsModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as AdditionalRepaymentsStoreModel;
				this.complianceService.saveAdditionalRepayments(model?.additionalRepaymentsContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			'loanPreferences.repayments.additionalNotesContainer',
			(field, event: FormlyArrayUpdateEvent<AdditionalNotesModel>) => {
				const fieldmodel = field.model as AdditionalNotesModel[];
				if (fieldmodel) {
					const model = Object.assign({}, fieldmodel[0], field.form?.value) as AdditionalNotesStoreModel;
					this.complianceService.saveAdditionalNotes(model?.additionalNotesContainer).subscribe();
				}
			}
		);

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.summary', (field) => {
			const fieldModel = field.model as LoanPreferencesSummaryModel[];
			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveLoanPreferencesSummary(model).subscribe();
			}
		});

		formlyOnStatusChangedToValid(formFields, 'hligInterview.lenderSalesChannel', (field) => {
			this.saveLenderSalesChannelInterview(field);
		});

		formlyOnClick(formFields, 'hligInterview.lenderSalesChannel.isHLIGManuallyCompleted', (field) => {
			// when the checkbox is checked, spinner should be loaded immediately until all the API calls are done in saveLenderSalesChannelInterview method
			this.appService.appSpinnerStatus$.next(CONSTANTS.SYNCING);

			setTimeout(() => {
				const lenderSalesChannelField = getParentFormField(field, 'lenderSalesChannel');
				if (lenderSalesChannelField?.formControl?.invalid) {
					this.saveLenderSalesChannelInterview(lenderSalesChannelField);
				}
			});
		});

		formlyRegisterHooks(formFields, 'hligInterview.coBorrowerSubstantialBenefit', {
			afterViewInit: (field) => {
				if (field) {
					return field.formControl?.valueChanges.pipe(
						debounceTime((field?.props?.debounceTime as number) ?? 1000),
						distinctUntilChanged(),
						filter(() => !!field.formControl?.dirty && !!field.formControl?.valid),
						tap(() => {
							const fieldModel = field.model as CoBorrowerBenefitModel[];
							if (fieldModel) {
								const model = fieldModel[0];
								this.complianceService.saveCoBorrowerBenefits(model).subscribe((res: number) => {
									model.id = res;
									this.simpFormlyHandlerService.updateToState('coBorrowerSubstantialBenefit', model, 0);
								});
							}
						})
					);
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'associatedProducts.backUpPlan', {
			invalid: {
				expression: (c: UntypedFormArray) => {
					let backUpPlanValid = true;
					const backupPlanModel = (c.value || []) as BackUpPlanModel[];
					if (backupPlanModel?.length > 0) {
						const model = backupPlanModel[0];
						backUpPlanValid = !!model.isExpensed || !!model.isImpacted || !!model.isNotApplicable;
					}
					return backUpPlanValid;
				}
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPurposeAndCustomerConversation.productSelection', (field) => {
			const fieldModel = field.model as SelectedProductModel[];

			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveSelectedProductExplained(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState('productSelection', model, 0);
				});
			}
		});
		formlyOnStatusChangedToValid(formFields, 'loanPurposeAndCustomerConversation.conflicts', (field) => {
			const fieldModel = field.model as LoanPurposeConflictsModel[];

			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveLoanPurposeConflicts(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState('conflicts', model, 0);
				});
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPurposeAndCustomerConversation.conflicts', (field) => {
			const fieldModel = field.model as LoanPurposeConflictsModel[];

			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveLoanPurposeConflicts(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState('conflicts', model, 0);
				});
			}
		});
		formlyOnStatusChangedToValid(formFields, 'loanPurposeAndCustomerConversation.customerObjective', (field) => {
			const fieldModel = field.model as LoanPurposeObjectiveModel[];

			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveLoanPurposeCustomerObjective(model).subscribe(() => {
					this.simpFormlyHandlerService.updateToState('customerObjective', model, 0);
				});
			}
		});

		formlyRegisterHooks(
			formFields,
			'loanPurposeAndCustomerConversation.rationaleForFeatureSelection.bridgingLoanYesNo',
			{
				afterViewInit: (field) => {
					const lendingPurpose = !isNaN(field?.props?.handler as number) ? Number(field?.props?.handler) : undefined;
					if (lendingPurpose) {
						setTimeout(() => {
							const model = field?.model as LoanPurposeRationaleModel;
							const currentValue = field?.formControl?.value as number;
							const newValue = model?.primaryLendingPurpose === lendingPurpose ? YesNo.Yes : YesNo.No;
							if (currentValue !== newValue) {
								field?.formControl?.setValue(newValue);
								field?.formControl?.markAsDirty();
							}
						});
					}
				}
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			'loanPurposeAndCustomerConversation.rationaleForFeatureSelection',
			(field) => {
				const fieldModel = field.model as LoanPurposeRationaleModel[];

				if (fieldModel) {
					const model = fieldModel[0];
					this.complianceService.saveLoanPurposeRationale(model).subscribe(() => {
						this.simpFormlyHandlerService.updateToState('rationaleForFeatureSelection', model, 0);
					});
				}
			}
		);

		formlyOnStatusChangedToValid(formFields, 'hligInterview.homeLoanIntroducerApplication', (field) => {
			const fieldModel = field.model as HomeLoanInsuranceModel[];

			if (fieldModel) {
				const model = fieldModel[0];
				this.complianceService.saveHomeLoanIntroducer(model).subscribe((res: number) => {
					model.id = res;
					this.simpFormlyHandlerService.updateToState('homeLoanIntroducerApplication', model, 0);
				});
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanPreferences.productFeatures.offsetContainer', (field) => {
			const fieldModel = field.model as OffsetModel[];
			if (fieldModel) {
				const model = Object.assign({}, fieldModel[0], field.form?.value) as OffsetStoreModel;

				this.complianceService.saveOffset(model?.offsetContainer).subscribe();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			'comments.commentsDetails',
			(field, event: FormlyArrayUpdateEvent<ComplianceComments>) => {
				const model = get(field, `model[${event.index}]`) as ComplianceComments;
				model.applicationId = this.applicationDataQuery.applicationId();
				this.complianceService.saveComments(ComplianceCommentsTransformer.toPayload(model)).subscribe();
			}
		);
		formlyOnStatusChangedToValid(
			formFields,
			'comments.commentsDetails',
			(field, event: FormlyArrayUpdateEvent<ComplianceComments>) => {
				const model = get(field, `model[${event.index}]`) as ComplianceComments;
				model.applicationId = this.applicationDataQuery.applicationId();
				this.complianceService.saveComments(ComplianceCommentsTransformer.toPayload(model)).subscribe();
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			'applicationNotesAndBrokerAttestation.applicationNotesDetails',
			(field, event: FormlyArrayUpdateEvent<NotesBrokerAttestation>) => {
				const model = get(field, `model[${event.index}]`) as NotesBrokerAttestation;
				model.applicationId = this.applicationDataQuery.applicationId();
				this.complianceService
					.saveNotesAttestation(NotesBrokerAttestationTransformer.toPayload(model))
					.subscribe(() => this.toastrService.success(savedSuccessfullyMessage('Application Notes')));
			}
		);
		this.setDefaultRetirementDetails(formFields);
		this.setDefaultCustomerDetails(formFields);

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			'loanPreferences.productFeatures.fixedAndVariableInterestRateContainer.fixedAndVariableRateExtras.fixedAndVariableRateReasonOther',
			{
				'templateOptions.pattern': (model: FixedAndVariableExtras) => {
					return model?.fixedAndVariableRateReasonFlexibility !== YesNo.Yes &&
						model?.fixedAndVariableRateReasonLimitingRateIncreaseRisk !== YesNo.Yes &&
						model?.fixedRateReasonMakeBudgetingEasier !== YesNo.Yes
						? 'true'
						: null;
				}
			}
		);

		// [TAMA5-19297]: Temporary fix until product finalize the decision
		this.updateCommentAdditionalNotes(formFields);

		return formFields;
	}

	private updateCommentAdditionalNotes(formFields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(formFields, 'loanPreferences.repayments.additionalNotesContainer.additionalNotes', {
			onInit: (field) => {
				if (field) {
					const commentsAdditionalNotes = getInitializedFormField(
						formFields,
						'0.comments.commentsDetails.0.additionalNotes'
					);
					return field.formControl?.valueChanges.pipe(
						debounceTime((field?.props?.debounceTime as number) ?? 1000),
						distinctUntilChanged(),
						tap((value: string) => {
							if (commentsAdditionalNotes) {
								(commentsAdditionalNotes.model as ComplianceComments).additionalNotes = value;
								(commentsAdditionalNotes.model as ComplianceComments).isAnyAdditionalNotes = true;
							}
						})
					);
				}
				return of();
			}
		});
	}

	private getSplitLoanLabel(field: FormlyFieldConfig) {
		const numberOfSplitsLoan = this.applicationDataQuery.getNumberOfSplitsLoan();

		const label = numberOfSplitsLoan > 1 ? `Split ${Number(field.parent?.parent?.key) + 1}` : '';
		return label;
	}

	private setupSignificantChangesSection(formFields: FormlyFieldConfig[] | undefined) {
		const significantChangesSection = getFormField(formFields, 'significantChanges') as FormlyFieldConfig;
		if (significantChangesSection && significantChangesSection.fieldGroup?.length) {
			const significationChangesFields = significantChangesSection.fieldGroup[0];
			const documentFields = significantChangesSection.fieldGroup[1];
			significantChangesSection.fieldGroup = [];
			const key = significationChangesFields.key as string;
			const persons = this.formDataService.getInterviewPersons();
			persons.forEach((person, index: number) => {
				const newSection = cloneDeep(significationChangesFields);
				newSection.key = `${key}-${index}`;
				if (newSection.templateOptions) {
					newSection.templateOptions.label = person.fullName;
				}

				significantChangesSection.fieldGroup?.push(newSection);
				formlyAddCustomValidator(formFields, `significantChanges.significantChangeDetails-${index}`, {
					invalid: {
						expression: (c: UntypedFormArray, field: FormlyFieldConfig) => {
							let significantChangesValid = true;
							const significantChangesModel = (field.model || []) as SignificantChangeModel[];
							if (significantChangesModel?.length > 0) {
								const model = significantChangesModel[0];
								significantChangesValid =
									model.noKnownChanges ||
									!!model.ageGreaterThan ||
									!!model.changeDetails?.plannedRetirementBeforeStatutoryAge ||
									!!model.changeDetails?.extendedUnpaidLeave ||
									!!model.changeDetails?.reducedIncome ||
									!!model.changeDetails?.endOfContract ||
									!!model.changeDetails?.largeExpenditure ||
									!!model.changeDetails?.other ||
									!!model.changeDetails?.medicalTreatment;
							}
							return significantChangesValid;
						}
					}
				});

				formlyAddCustomValidator(
					formFields,
					`significantChanges.significantChangeDetails-${index}.changeDetails.meetingRepayments`,
					{
						invalid: {
							expression: (c: UntypedFormControl) => {
								let meetingRepaymentValid = true;
								const model = c.value as MeetingRepayments;
								if (model) {
									meetingRepaymentValid =
										model.repaymentPriorToSettlement ||
										!!model.downsizingHome ||
										!!model.saleOfOtherAssets ||
										!!model.recurringIncomeFromSuper ||
										!!model.superannuationLumpSum ||
										!!model.savings ||
										!!model.incomeFromOtherInvestments ||
										!!model.superannuationLumpSum ||
										!!model.workPastRetirementAge ||
										!!model.additionalIncomeSource ||
										!!model.otherPlan;
								}
								return meetingRepaymentValid;
							}
						}
					}
				);
				formlyOnStatusChangedToValid(formFields, `significantChanges.significantChangeDetails-${index}`, (field) => {
					if (!field.formControl?.valid) {
						//some race condition is happening here, even though the customValidator is marking the formControl as invalid this event is still fired, hence this code
						return;
					}
					this.complianceService
						.saveSignificantChanges(
							SignificantChangeTransformer.toPayload(
								(field?.formControl?.value as SignificantChangeModel[])[0],
								this.applicationDataQuery.applicationId(),
								person.targetType,
								person.targetId
							)
						)
						.pipe(switchMap(() => this.complianceService.fetchSignificantChanges(person)))
						.subscribe((significantChange: SignificantChangeModel[]) => {
							this.simpFormlyHandlerService.updateToState(`significantChangeDetails-${index}`, significantChange[0], 0);
							this.toastrService.success(
								savedSuccessfullyMessage('Significant changes in future financial circumstances')
							);

							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							const documentCheckListField = field.parent?.fieldGroup?.find((x) => x.key === 'documentChecklist');
							if (documentCheckListField?.className?.includes('hidden')) {
								documentCheckListField.formControl?.reset();
							}
							const controlValue = (field?.parent?.formControl?.value as SignificantChangeSectionFormControl)
								?.documentChecklist;

							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							const documentModel: DocumentChecklistModel = ((controlValue?.length &&
								controlValue[0]) as DocumentChecklistModel) ?? {
								id: (field?.parent?.model as SignificantChangeSectionModel)?.documentChecklist?.id
							};
							this.saveAndFetchDocumentChecklist(documentModel, false);
						});
				});

				formlyRegisterHooks(formFields, `significantChanges.significantChangeDetails-${index}.changeDetails`, {
					onInit: (field) => {
						return field.formControl?.valueChanges.pipe(tap(() => field.parent?.formControl?.updateValueAndValidity()));
					}
				});
			});
			significantChangesSection.fieldGroup?.push(documentFields);

			const documentChecklistField = getFormField(
				formFields,
				'significantChanges.documentChecklist'
			) as FormlyFieldConfig;

			formlyOnStatusChangedToValid(formFields, `significantChanges.documentChecklist`, (documentField) => {
				if (documentField) {
					this.saveAndFetchDocumentChecklist((documentField.model as DocumentChecklistModel[])?.[0]);
				}
			});

			(documentChecklistField?.fieldArray as FieldTypeConfig)?.fieldGroup
				?.map((field) => field.key as string)
				.filter((fieldKey) => !['savings', 'savingsOfi'].includes(fieldKey))
				.forEach((fieldName: string) => {
					{
						formlyExtendExpressionProperties(formFields, `significantChanges.documentChecklist.${fieldName}`, {
							hide: (model, formState, field) => {
								if (field.parent?.parent?.parent?.model) {
									return !Object.values(field.parent?.parent?.parent?.model as any[])
										.map((significantChange: SignificantChangeModel[]) => {
											if (significantChange?.length > 0) {
												const meetingRepayments = significantChange[0].changeDetails?.meetingRepayments;
												if (meetingRepayments && field.templateOptions?.handler) {
													return meetingRepayments[field.templateOptions?.handler as keyof MeetingRepayments];
												}
											}
											return;
										})
										.includes(true);
								}
								return true;
							}
						});
					}
				});

			formlyExtendExpressionProperties(formFields, `significantChanges.documentChecklist`, {
				className: (model, formState, field) => {
					const hideDocumentCheckList = !Object.values(field.parent?.model)
						.map((significantChangeModels) => {
							const everySignificantDetails = (significantChangeModels as SignificantChangeModel[]).every(
								(significantModel) => {
									const changeDetails = significantModel.changeDetails;
									return Object.values(changeDetails?.meetingRepayments ?? {}).some((value) => value === true);
								}
							);

							return everySignificantDetails;
						})
						.some((val) => val === true);
					const className = field.className?.replace('hidden ', '');

					if (hideDocumentCheckList) {
						return `hidden ${className}`;
					} else {
						return className;
					}
				}
			});
		}
	}

	private saveAndFetchDocumentChecklist(model: DocumentChecklistModel, showToast = true) {
		const docChecklistDto = DocumentChecklistTransformer.toPayload(model, this.applicationDataQuery.applicationId());
		this.complianceService.saveDocumentChecklist(docChecklistDto).subscribe(() => {
			this.toastrService.success(savedSuccessfullyMessage('Document checklist'));
		});
	}

	private setupLoanPurposeAndCustomerConversationSection(formFields: FormlyFieldConfig[] | undefined) {
		const numberOfSplitsLoan = this.applicationDataQuery.getNumberOfSplitsLoan();
		const defaultLoans = [];
		for (let i = 0; i < numberOfSplitsLoan; i++) {
			defaultLoans.push({});
		}
		const loanSplitsField = getFormField(
			formFields,
			'loanPurposeAndCustomerConversation.loanPurposeAmountAndPreferredFeatures.loanSplitsArray'
		);

		if (loanSplitsField) {
			loanSplitsField.defaultValue = defaultLoans;
		}
		const productSelectionSplits = getFormField(
			formFields,
			'loanPurposeAndCustomerConversation.productSelection.loanSplitsArray'
		);

		if (productSelectionSplits) {
			productSelectionSplits.defaultValue = defaultLoans;
		}
	}

	private setupInterviewDeclarationSection(formFields: FormlyFieldConfig[] | undefined) {
		const interviewDeclarationSection = getFormField(formFields, 'interviewDeclarations') as FormlyFieldConfig;
		if (interviewDeclarationSection && interviewDeclarationSection.fieldGroup?.length) {
			const interviewDeclarationFields = interviewDeclarationSection.fieldGroup[0];
			const attestationSubSection = interviewDeclarationSection.fieldGroup[1];
			interviewDeclarationSection.fieldGroup = [];
			const key = interviewDeclarationSection.key as string;
			const persons = this.formDataService.getInterviewPersons();
			persons.forEach((applicant, index) => {
				const newSection = cloneDeep(interviewDeclarationFields);
				newSection.key = `${key}-${index}`;
				if (newSection.templateOptions) {
					newSection.templateOptions.label = applicant.fullName;
				}
				interviewDeclarationSection.fieldGroup?.push(newSection);
				formlyExtendExpressionProperties(
					formFields,
					`interviewDeclarations.interviewDeclarations-${index}.datedOutsideLabel`,
					{
						template: (model, formState, field) => {
							return (field as FormlyApiProperty)?.template?.replace('ApplicantFullName', applicant.fullName) as string;
						}
					}
				);
				formlyExtendExpressionProperties(
					formFields,
					`interviewDeclarations.interviewDeclarations-${index}.datedOutsideConfirmationLabel`,
					{
						template: (model, formState, field) => {
							return (field as FormlyApiProperty)?.template?.replace('ApplicantFullName', applicant.fullName) as string;
						}
					}
				);

				formlyOnStatusChangedToValid(formFields, `interviewDeclarations.interviewDeclarations-${index}`, (field) => {
					this.complianceService
						.saveInterviewDeclaration(
							InterviewDeclarationTransformer.toPayload(
								(field.formControl?.value as InterviewDeclarationModel[])[0],
								this.applicationDataQuery.applicationId(),
								applicant
							)
						)
						.pipe(
							tap(() =>
								this.simpFormlyHandlerService.upsertToState(
									`interviewDeclarations-${index}`,
									this.complianceService.fetchInterviewDeclaration(applicant)
								)
							)
						)
						.subscribe(() => {
							this.toastrService.success(savedSuccessfullyMessage('Interview declaration'));
						});
				});
			});
			if (attestationSubSection) {
				interviewDeclarationSection.fieldGroup?.push(attestationSubSection);

				formlyOnStatusChangedToValid(formFields, `interviewDeclarations.attestation`, (field) => {
					const attestationModel = (field.model as AttestationModel[])[0];
					this.complianceService
						.saveInterviewAttestation(attestationModel)
						.pipe(
							tap(() => {
								this.simpFormlyHandlerService.upsertToState(`attestation`, this.complianceService.fetchAttestation());
								this.simpFormlyHandlerService.upsertToState(
									'additionalNotesDetails',
									this.complianceService.fetchAdditionalComments()
								);
							})
						)
						.subscribe(() => {
							this.toastrService.success(savedSuccessfullyMessage('Attestation'));
						});
				});
			}
		}
	}

	private setDefaultCustomerDetails(formFields: FormlyFieldConfig[] | undefined) {
		const personApplicants = this.formDataService.getInterviewPersons();
		if (personApplicants?.length > 0) {
			formlyExtendExpressionProperties(
				formFields as FormlyFieldConfig[],
				`hligInterview.customerDetails.applicantName`,
				{
					'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
						return `${Number(field.parent?.key) + 1} - Customer name`;
					}
				}
			);

			personApplicants.forEach((app, index) => {
				const applicantNameField = getFormField(
					formFields,
					`interviewDeclarations.interviewDeclarations-${index}.applicantName`
				);
				if (applicantNameField) {
					applicantNameField.defaultValue = app.fullName;
				}
			});
		} else {
			const customerDetailsSubSection = getFormField(formFields, 'hligInterview.customerDetails') as FormlyFieldConfig;

			customerDetailsSubSection.hide = true;
		}
	}

	private handleAdditionalComments(formFields: FormlyFieldConfig[] | undefined) {
		const commentsArray = getFormField(formFields, 'additionalNotes.additionalNotesDetails.comments');
		if (commentsArray && commentsArray.templateOptions) {
			commentsArray.templateOptions.showAllLabels = true;
		}

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			'additionalNotes.additionalNotesDetails.comments.comment',
			{
				'templateOptions.label': (model: AdditionalCommentsCommentModel) => {
					if (model?.editting) {
						return `Comment by ${this.currentUserQuery.displayName()}`;
					}
					return `Comment by ${model?.name}${
						model.lastUpdated
							? `, ${this.datePipe.transform(model.lastUpdated, 'd MMMM yyyy h:mm aa')} ${getAUTimeZone(
									model.lastUpdated
							  )}`
							: ''
					}`;
				}
			}
		);

		formlyOnClick(
			formFields,
			'additionalNotes.additionalNotesDetails.comments.commentButtons.saveButton',
			(field: FormlyFieldConfig) => {
				const commentModel = field.parent?.parent?.model as AdditionalCommentsCommentModel;
				this.complianceService
					.saveAdditionalComments(
						AdditionalCommentsTransformer.toPayload(
							commentModel,
							this.accessService.getUserId(),
							this.currentUserQuery.displayName()
						)
					)
					.pipe(
						tap(() => {
							this.simpFormlyHandlerService.upsertToState(
								'additionalNotesDetails',
								this.complianceService.fetchAdditionalComments()
							);
							this.toastrService.success(savedSuccessfullyMessage('Comment'));
						})
					)
					.subscribe();
			}
		);

		formlyOnClick(
			formFields,
			'additionalNotes.additionalNotesDetails.comments.commentButtons.cancelButton',
			(field: FormlyFieldConfig) => {
				const commentModel = field.parent?.parent?.model as AdditionalCommentsCommentModel;
				field.parent?.parent?.formControl?.patchValue({ editting: false, comment: commentModel.existingComment });
				if (!commentModel?.comment && field?.parent) {
					formlyDeleteFromArray(field.parent);
				}
			}
		);

		formlyOnClick(formFields, 'additionalNotes.additionalNotesDetails.comments.edit', (field: FormlyFieldConfig) => {
			field.parent?.formControl?.patchValue({
				editting: true,
				existingComment: (field.parent.model as AdditionalCommentsCommentModel)?.comment
			});
		});

		formlyOnClick(formFields, 'additionalNotes.additionalNotesDetails.comments.delete', (field: FormlyFieldConfig) => {
			this.complianceService
				.deleteAdditionalComment((field.model as AdditionalCommentsCommentModel).id)
				.subscribe(() => {
					formlyDeleteFromArray(field);
					this.toastrService.success(deletedSuccessfullyMessage('Comment'));
				});
		});

		formlyOnClick(
			formFields,
			'interview.borrowingPurpose.anticipatedChangesList.significantChanges.delete',
			(field: FormlyFieldConfig) => {
				const id = (field.model as AnticipatedChanges)?.id;
				if (id) {
					this.complianceService.deleteAnticipatedChanges(id).subscribe(() => {
						formlyDeleteFromArray(field);
						this.toastrService.success(deletedSuccessfullyMessage('Significant Changes'));
					});
				} else {
					formlyDeleteFromArray(field);
				}
			}
		);

		formlyOnClick(
			formFields,
			'interview.borrowingPurpose.anticipatedChangesList.mitigants.delete',
			(field: FormlyFieldConfig) => {
				const id = (field.model as MitigantFactor)?.id;
				if (id) {
					this.complianceService.deleteMitigantFactors(id).subscribe(() => {
						formlyDeleteFromArray(field);
						this.toastrService.success(deletedSuccessfullyMessage('Mitigant Factor'));
					});
				} else {
					formlyDeleteFromArray(field);
				}
			}
		);
	}

	private setDefaultRetirementDetails(formFields: FormlyFieldConfig[] | undefined) {
		const applicants = this.applicationDataQuery.getPersonShortApplicants();
		this.setDefaultQuestions(applicants, formFields);
		this.setDefaultAssetAddresses(applicants, formFields);
	}

	private setDefaultQuestions(applicants: ShortApplicant[], formFields: FormlyFieldConfig[] | undefined) {
		const defaultValues = applicants.map((a) => ({
			answer: ''
		}));
		[
			'saving',
			'incomeFromOtherInvestments',
			'repaymentPriorToRetirement',
			'understandsImpactOnRetirementPlans',
			'downsizingHome',
			'recurringIncomeFromSuper',
			'lumpSumFromSuper',
			'incomeFromCoApplicant',
			'saleOfAssets'
		].forEach((val) => {
			const field = getFormField(formFields, `retirement.retirementDetails.${val}.yesNo`);
			if (field) {
				field.defaultValue = defaultValues;
			}
		});
	}

	private setDefaultAssetAddresses(applicants: ShortApplicant[], formFields: FormlyFieldConfig[] | undefined) {
		const assetAddresses = this.simpFormlyHandlerService.getPropertyAddresses();

		if (assetAddresses) {
			const defaultAddresses = assetAddresses.map(() => {
				return {
					question: {
						assetAddress: undefined,
						saleOfAsset: undefined
					}
				};
			});
			const assetAddressField = getFormField(formFields, 'retirement.retirementDetails.assetsList');

			if (assetAddressField) {
				assetAddressField.defaultValue = defaultAddresses;
			}
		}
	}

	private saveRetirement(field: FormlyFieldConfig): void {
		const fieldModel: RetirementModel = field.parent?.model as RetirementModel;

		const model = Object.assign({}, field.parent?.model, field.form?.value) as RetirementModel;
		const applicants = fieldModel.retirementDetails[0]?.applicants?.applicantsList;
		const retirementDetailsModel = model.retirementDetails[0];
		if (applicants && retirementDetailsModel.applicants) {
			retirementDetailsModel.applicants.applicantsList = applicants;
		}
		if (retirementDetailsModel.assetsList) {
			retirementDetailsModel.assetsList.forEach((x, index) => {
				if (retirementDetailsModel.assetsList && retirementDetailsModel.assetsList[index]) {
					x.propetyId = retirementDetailsModel.assetsList[index].propetyId as number;
					x.assetAddress = retirementDetailsModel.assetsList[index].assetAddress;
				}
			});
		}

		this.complianceService.saveRetirement(model).subscribe();
	}

	private saveLenderSalesChannelInterview(lenderSalesChannelField: FormlyFieldConfig): void {
		const stateData = this.simpFormlyHandlerService.getStateData<LenderSalesChannel>('lenderSalesChannel')?.[0];
		const previousHLIGCheckboxValue = stateData?.isHLIGManuallyCompleted;

		const fieldModel = lenderSalesChannelField.model as LenderSalesChannel[];
		if (fieldModel) {
			const model = fieldModel[0];
			this.complianceService
				.saveLenderSalesChannel(model)
				.pipe(
					switchMap(() => {
						if (model?.isHLIGManuallyCompleted) {
							return this.resetBorrowerInterviewData(lenderSalesChannelField.parent?.parent);
						}

						if (previousHLIGCheckboxValue !== model?.isHLIGManuallyCompleted) {
							this.formStateService.updateFormState({ isHLIGManuallyCompleted: false });
						} else {
							this.simpFormlyHandlerService.updateToState('lenderSalesChannel', model, 0);
						}
						return of(false);
					}),
					catchError(() => of(true))
				)
				.subscribe((value) => {
					if (value) {
						this.appService.appSpinnerStatus$.next('');
					}
				});
		}
	}

	private resetBorrowerInterviewData(areaFiled?: FormlyFieldConfig): Observable<boolean> {
		this.resetFormValidations(areaFiled);

		return this.complianceService.resetBorrowerInterviewData().pipe(
			tap(() => this.formStateService.updateFormState({ isHLIGManuallyCompleted: true })),
			map(() => true),
			catchError(() => of(true))
		);
	}

	private saveInterview(field: FormlyFieldConfig, subSectionName: string): void {
		const combinedModel = this.getInterviewModel(field);
		const interviewPayload = InterviewTransformer.toPayload(combinedModel);
		this.complianceService
			.saveInterview(interviewPayload)
			.pipe(
				switchMap((response) => {
					combinedModel.id = response.id;
					if (combinedModel.address) {
						combinedModel.address.id = response.addressId;
					}
					if (subSectionName === 'borrowingPurpose') {
						return this.complianceService.fetchInterview().pipe(
							map((fetchResponse) => {
								combinedModel.anticipatedChangesList = fetchResponse[0]?.anticipatedChangesList;
								return combinedModel;
							})
						);
					}

					return of({ combinedModel });
				})
			)
			.subscribe(() => {
				this.toastrService.success(savedSuccessfullyMessage('Interview details'));
				this.simpFormlyHandlerService.updateToState(subSectionName, combinedModel, 0);
			});
	}

	private getInterviewModel(field: FormlyFieldConfig): Interview {
		const combinedModel = {} as Interview;
		field.parent?.fieldGroup?.forEach((f) => {
			const model = f.model as Interview[];
			if (f.formControl && model.length) {
				const controlValue = f.formControl.value as Interview[];
				if (controlValue.length) {
					const controlKeys = Object.keys(controlValue[0]);
					Object.assign(combinedModel, pick(model[0], controlKeys));
				}
			}
		});
		combinedModel.applicationId = this.applicationDataQuery.applicationId();
		return cloneDeep(combinedModel);
	}

	private resetFormValidations(areaFiled?: FormlyFieldConfig) {
		const sections = (areaFiled?.fieldGroup ?? []).filter((s) => s.type === 'section');
		if (sections.length) {
			sections.forEach((section) => {
				section.fieldGroup
					?.filter((subSection) => subSection.type === 'sub-section')
					.forEach((subSection) => {
						this.simpFormlyHandlerService.updateDraftFlag(subSection.key as string, false);
						this.simpFormlyHandlerService.upsertToStateWithData(
							subSection.key as string,
							subSection.key === 'lenderSalesChannel' ? [{ isHLIGManuallyCompleted: true }] : [{}]
						);
					});
			});
		}
	}
}
