import { IndependentAdviceTypeRequired } from '@app/modules/shared/enums/app.enums';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorIndependentAdviceTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorIndependentAdviceModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			independentAdvice: payload.independentAdviceTypeRequiredId
		};
	}
}
export interface GuarantorIndependentAdviceModel {
	id?: number;
	lendingGuaranteeId?: number;
	independentAdvice?: IndependentAdviceTypeRequired;
}
