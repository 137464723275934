import { Importance, InterestOnlyImportantOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { Term } from '@app/modules/simp-formly/typings/formly-app';
import { InterestOnlyDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class InterestOnlyTransformer {
	static toPayload(applicationId: number, details: InterestOnlyModel): InterestOnlyDTO {
		const options = details.interestOnlyExtras?.interestOnlyOptions?.options ?? [];
		return {
			applicationId: applicationId,
			importance: details.interestOnlyImportanceId,
			risksExplained: details.interestOnlyRisksExplained ?? undefined,
			durationLength: details.interestOnlyExtras?.interestOnlyFixedPeriodDuration?.number,
			durationReason: details.interestOnlyExtras?.interestOnlyInterestOnlyDurationReason,
			durationUnit: details.interestOnlyExtras?.interestOnlyFixedPeriodDuration?.frequency,
			repaymentFrequency: details.interestOnlyExtras?.InterestOnlyRepaymentFrequencyId,
			reasonDescription: details.interestOnlyExtras?.interestOnlyOptions?.otherReasonInput,
			reasonAccommodateAnticipatedNonRecurringExpenseItem: options?.includes(
				InterestOnlyImportantOptions.ReasonAccommodateAnticipatedNonRecurringExpenseItem
			),
			reasonAccommodateTemporaryReductionInIncome: options?.includes(
				InterestOnlyImportantOptions.ReasonAccommodateTemporaryReductionInIncome
			),
			reasonMaximiseCashFlow: options?.includes(InterestOnlyImportantOptions.ReasonMaximiseCashFlow),
			reasonMinimiseCashFlowImpactDuringConstruction: options?.includes(
				InterestOnlyImportantOptions.ReasonMinimiseCashFlowImpactDuringConstruction
			),
			reasonMinimiseCashFlowImpactDuringTheBridgingPeriod: options?.includes(
				InterestOnlyImportantOptions.ReasonMinimiseCashFlowImpactDuringTheBridgingPeriod
			),
			reasonPlanToConvertToInvestmentProperty: options?.includes(
				InterestOnlyImportantOptions.ReasonPlanToConvertToInvestmentProperty
			),
			reasonPrincipalReductionsInAnOffsetFacility: options?.includes(
				InterestOnlyImportantOptions.ReasonPrincipalReductionsInAnOffsetFacility
			),
			reasonRecommendation: options?.includes(InterestOnlyImportantOptions.ReasonRecommendation),
			reasonTaxationOrAccountingReasons: options?.includes(
				InterestOnlyImportantOptions.ReasonTaxationOrAccountingReasons
			),
			reasonVariableAndUnpredictableIncome: options?.includes(
				InterestOnlyImportantOptions.ReasonVariableAndUnpredictableIncome
			),
			reasonOther: !!details.interestOnlyExtras?.interestOnlyOptions?.otherReasonInput
		};
	}
	static fromPayload(data: InterestOnlyDTO): InterestOnlyModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);

		const details: InterestOnlyModel = {
			interestOnlyImportanceId: data.importance!,
			interestOnlyRisksExplained: data.risksExplained!,
			interestOnlyExtras: {
				interestOnlyFixedPeriodDuration: {
					frequency: data.durationUnit!,
					number: data.durationLength!
				},
				interestOnlyInterestOnlyDurationReason: data.durationReason!,
				InterestOnlyRepaymentFrequencyId: data.repaymentFrequency!,
				interestOnlyOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				}
			}
		};
		return details;
	}

	static getSelectedIds(data: InterestOnlyDTO, selectedIds: number[]) {
		if (data.reasonAccommodateAnticipatedNonRecurringExpenseItem) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonAccommodateAnticipatedNonRecurringExpenseItem);
		}
		if (data.reasonAccommodateTemporaryReductionInIncome) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonAccommodateTemporaryReductionInIncome);
		}
		if (data.reasonMaximiseCashFlow) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonMaximiseCashFlow);
		}
		if (data.reasonMinimiseCashFlowImpactDuringConstruction) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonMinimiseCashFlowImpactDuringConstruction);
		}
		if (data.reasonMinimiseCashFlowImpactDuringTheBridgingPeriod) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonMinimiseCashFlowImpactDuringTheBridgingPeriod);
		}
		if (data.reasonPlanToConvertToInvestmentProperty) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonPlanToConvertToInvestmentProperty);
		}
		if (data.reasonPrincipalReductionsInAnOffsetFacility) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonPrincipalReductionsInAnOffsetFacility);
		}
		if (data.reasonRecommendation) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonRecommendation);
		}
		if (data.reasonTaxationOrAccountingReasons) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonTaxationOrAccountingReasons);
		}
		if (data.reasonMinimiseCashFlowImpactDuringConstruction) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonMinimiseCashFlowImpactDuringConstruction);
		}
		if (data.reasonVariableAndUnpredictableIncome) {
			selectedIds.push(InterestOnlyImportantOptions.ReasonVariableAndUnpredictableIncome);
		}
	}
}

export interface InterestOnlyModel {
	interestOnlyImportanceId: Importance;
	interestOnlyRisksExplained: boolean;
	interestOnlyExtras: {
		interestOnlyFixedPeriodDuration?: Term;
		interestOnlyInterestOnlyDurationReason?: string;
		InterestOnlyRepaymentFrequencyId?: FrequencyShort;
		interestOnlyOptions: MultiCheckboxModel;
	};
}
export interface InterestOnlyStoreModel {
	interestOnlyContainer: InterestOnlyModel;
}
