import { DependantDTO } from '@app/modules/typings/api';

export class DependantTransformer {
	static fromPayload(dependantDTO: DependantDTO): DependantDetailsModel {
		return {
			id: dependantDTO.id,
			householdId: dependantDTO.householdId,
			dependantAge: dependantDTO.age === null ? undefined : dependantDTO.age,
			dependantDateOfBirth: dependantDTO.dateOfBirth
		};
	}

	static toPayload(dependantData: DependantDetailsModel[], householdId: number): DependantDTO[] {
		const dependantDTOData = [];
		for (const dependantDetails of dependantData) {
			dependantDTOData.push({
				id: dependantDetails.id,
				householdId: householdId,
				age: dependantDetails.dependantAge?.toString() !== '' ? Number(dependantDetails.dependantAge) : undefined,
				dateOfBirth: dependantDetails.dependantDateOfBirth
			});
		}
		return dependantDTOData;
	}
}

export interface DependantDetailsModel {
	id?: number;
	householdId: number;
	dependantAge?: number;
	dependantDateOfBirth?: string;
}
