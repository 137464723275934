import { CommisionDTO } from '@app/modules/typings/api';

export class CommissionTransformer {
	static fromPayload(commissionDto: CommisionDTO, loanId: number): CommissionModel {
		const totalCommission = this.calculateTotalCommission(
			commissionDto.upFrontCommission,
			commissionDto.grossTrailCommission
		);

		const initialCommission = {
			upfrontCommission: commissionDto.upFrontCommission,
			grossTrailCommission: commissionDto.grossTrailCommission,
			totalCommission: totalCommission
		} as InitialCommission;

		const initialAdjustedCommission = {
			initialAdjustedUpfrontCommission: commissionDto.initialAdjustedUpFrontCommission,
			initialAdjustedGrossTrailCommission: commissionDto.initialAdjustedGrossTrailCommission,
			initialAdjustedTotalCommission: this.calculateTotalCommission(
				commissionDto.initialAdjustedUpFrontCommission,
				commissionDto.initialAdjustedGrossTrailCommission
			)
		} as InitialAdjustedCommission;

		const subsequentCommission = {
			subSequentUpfrontCommission: commissionDto.upFrontCommission,
			subSequentGrossTrailCommission: commissionDto.grossTrailCommission,
			subSequentTotalCommission: totalCommission // since both the initialCommission and subSequentCommission are same
		} as SubsequentCommission;

		const subsequentAdjustedCommission = {
			subSequentAdjustedUpfrontCommission: commissionDto.subsequentAdjustedUpFrontCommission,
			subSequentAdjustedGrossTrailCommission: commissionDto.subsequentAdjustedGrossTrailCommission,
			subSequentAdjustedTotalCommission: this.calculateTotalCommission(
				commissionDto.subsequentAdjustedUpFrontCommission,
				commissionDto.subsequentAdjustedGrossTrailCommission
			)
		} as SubsequentAdjustedCommission;

		return {
			id: commissionDto.id,
			loanId,
			initialCommission: initialCommission,
			initialAdjustedCommission: initialAdjustedCommission,
			subSequentCommission: subsequentCommission,
			subSequentAdjustedCommission: subsequentAdjustedCommission,
			isInitialAdjustedCommissionAvailable:
				!commissionDto.initialAdjustedUpFrontCommission && !commissionDto.initialAdjustedGrossTrailCommission,
			isSubSequentAdjustedCommissionAvailable:
				!commissionDto.subsequentAdjustedUpFrontCommission && !commissionDto.subsequentAdjustedGrossTrailCommission,
			isProductSelected: true // UI purpose Only
		};
	}

	static toPayLoad(commission: CommissionModel): CommisionDTO {
		return {
			id: commission.id,
			loanDetailId: commission.loanId,
			initialAdjustedUpFrontCommission: commission.initialAdjustedCommission.initialAdjustedUpfrontCommission,
			initialAdjustedGrossTrailCommission: commission.initialAdjustedCommission.initialAdjustedGrossTrailCommission,
			subsequentAdjustedUpFrontCommission: commission.subSequentAdjustedCommission.subSequentAdjustedUpfrontCommission,
			subsequentAdjustedGrossTrailCommission:
				commission.subSequentAdjustedCommission.subSequentAdjustedGrossTrailCommission
		} as CommisionDTO;
	}

	static calculateTotalCommission(upfrontCommission: number, grossTrailCommission: number): number {
		return Number(((upfrontCommission ?? 0) + (grossTrailCommission ?? 0)).toFixed(2));
	}
}

export interface CommissionModel {
	id: number;
	loanId: number;
	initialCommission: InitialCommission;
	initialAdjustedCommission: InitialAdjustedCommission;
	subSequentCommission: SubsequentCommission;
	subSequentAdjustedCommission: SubsequentAdjustedCommission;
	isInitialAdjustedCommissionAvailable?: boolean;
	isSubSequentAdjustedCommissionAvailable?: boolean;
	isProductSelected?: boolean; // UI Purpose only
	isHideSubTerm?: boolean; // UI Purpose only
}
interface InitialCommission {
	grossTrailCommission: number;
	upfrontCommission: number;
	totalCommission: number;
}

interface InitialAdjustedCommission {
	initialAdjustedUpfrontCommission: number;
	initialAdjustedGrossTrailCommission: number;
	initialAdjustedTotalCommission: number;
}

interface SubsequentCommission {
	subSequentUpfrontCommission: number;
	subSequentGrossTrailCommission: number;
	subSequentTotalCommission: number;
}

interface SubsequentAdjustedCommission {
	subSequentAdjustedUpfrontCommission: number;
	subSequentAdjustedGrossTrailCommission: number;
	subSequentAdjustedTotalCommission: number;
}
