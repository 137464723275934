import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { RentalTerm } from '@app/modules/shared/enums/app.enums';
import { calculateMonthly } from '@app/modules/shared/helper/util';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { Address, PersonApplicantDTO, RefiRentalIncomeDTO } from '@app/modules/typings/api';
import { SimpAddress, SimpAddressHelper } from '@simpology/client-components/utils';

export class RefiRentalIncomeTransformer {
	static toPayload(
		model: RefiRentalIncomeModel,
		applicationId: number,
		applicants: PersonApplicantDTO[]
	): RefiRentalIncomeDTO {
		return {
			id: model.rentalIncomeModal?.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			address: model.rentalIncomeModal?.address,
			applicantId: model.rentalIncomeModal?.ownership || applicants[0].id,
			rentalTerm: model.rentalIncomeModal?.rentalType,
			grossRentalAmount: model.rentalIncomeModal?.rentalRate?.amount || 0,
			grossRentalFrequency: model.rentalIncomeModal?.rentalRate?.frequency,
			addressId: model.rentalIncomeModal?.address?.id
		};
	}
	static fromPayload(data: RefiRentalIncomeDTO, applicants: PersonApplicantDTO[]): RefiRentalIncomeModel {
		return {
			rentalIncomeModal: {
				id: data.id,
				address: data.address,
				ownership: data.applicantId,
				rentalRate: {
					amount: data.grossRentalAmount,
					frequency: data.grossRentalFrequency
				} as AmountSelect,
				rentalType: data.rentalTerm
			},
			summary: `${
				data.address ? SimpAddressHelper.buildAddressLine(data.address as SimpAddress) + '<br> ' : ''
			}  ${formatCurrency(calculateMonthly(data.grossRentalAmount, data.grossRentalFrequency), 'en-AU', '$')} per month`
		};
	}
}

export interface RefiRentalIncomeModel {
	rentalIncomeModal?: RefiIncomeDetails;
	summary: string;
}

export interface RefiIncomeDetails {
	id?: number;
	address?: Address;
	rentalType?: RentalTerm;
	ownership?: number;
	rentalRate?: AmountSelect;
	realEstateAssetId?: number;
}
