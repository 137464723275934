<ng-template #content let-modal>
	<div [ngClass]="{ 'simp-modal-header': to.label, 'd-flex justify-content-end': !to.label }">
		<h4 class="simp-h4 simp-modal-header__title" *ngIf="to.label">{{ to.label }}</h4>
		<button
			type="button"
			class="btn simp-modal-header__close"
			aria-label="Close"
			(click)="onAction('close')"
			[class.no-header]="!to.label"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="simp-modal-body">
		<div [class]="to.customClass">
			<ng-container *ngFor="let f of field.fieldGroup">
				<div class="w-100" *ngIf="f?.templateOptions?.newLine"></div>
				<formly-field [field]="f"></formly-field>
			</ng-container>
		</div>
	</div>
	<div class="simp-modal-footer row" *ngIf="!to.hideModalFooter">
		<button
			type="button"
			class="btn btn-secondary simp-button col-2"
			[id]="uniqueId + '__cancel'"
			(click)="onAction('cancel')"
		>
			Cancel
		</button>
		<button
			[disabled]="to.readonly"
			type="button"
			class="btn btn-primary simp-button col-2 simp-margin-left-small"
			(click)="onAction('submit')"
			[id]="uniqueId + '__submit'"
		>
			{{ to.submitButtonText || 'Done' }}
		</button>
	</div>
</ng-template>

<!-- When modal content need to be rendered on page along with template -->
<div *ngIf="to.eagerRender">
	<ng-container *ngTemplateOutlet="content; context: { modal: this }"></ng-container>
</div>
