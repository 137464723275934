/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';

import { LoanInformation } from '@app/modules/loan-serviceability/model/loan-information.model';
import { ApplicationDetails, SetUpApplicant } from '@app/modules/setup/typings/setup';
import { ProductFeeTypeDTO, RefiApplicationSummaryDTO } from '@app/modules/typings/api';
import { merge } from 'lodash-es';
import { ApplicantEntityType } from '../../enums/app.enums';
import { ApplicationDataQuery } from '../application-data/application-data.query';
import { ChannelSettingState } from '../channel-setting/typings/channel-setting';
import { FormStore, formStoreCast } from '../form-data/model/form-data.model';
import { FormStateStore } from './form-state.store';
import { FormStateChannelSetting, RefiSummaryState, SimpFormState } from './typings/form-state';

@Injectable({ providedIn: 'root' })
export class FormStateService {
	constructor(private formStateStore: FormStateStore, private applicationDataQuery: ApplicationDataQuery) {}

	updateFormStateForStoreUpdates(nextState: unknown, storeName: string): void {
		switch (storeName) {
			case 'details': {
				const applicationDetails = formStoreCast<ApplicationDetails>(nextState)[0];
				this.updateFormState({
					applicationDetails: applicationDetails ?? {}
				});
				break;
			}
			case 'applicants': {
				const setupApplicants = formStoreCast<SetUpApplicant>(nextState);
				setTimeout(() => {
					this.updateFormState({
						hasCompanyOrTrustApplicants: setupApplicants?.some(
							(a) =>
								a.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant ||
								a.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant
						),
						setupApplicants: setupApplicants,
						refiPrimaryApplicant: this.applicationDataQuery.isCurrentUserPrimaryApplicant()
					});
				});
				break;
			}
			case 'channel-setting': {
				const channelSetting = nextState as ChannelSettingState;
				this.updateFormState({
					channelSetting: this.mapToFormStateChannelSetting(channelSetting)
				});
				break;
			}
			case 'loanInformation':
			case 'loan-serviceability-loanInformation':
				this.updateSplitLoanState(nextState as FormStore<LoanInformation>);
				break;
			case 'productFeeTypes':
			case 'loan-serviceability-productFeeTypes': {
				const productFeeTypes = formStoreCast<ProductFeeTypeDTO>(nextState);
				this.updateFormState({
					productFeeTypes: productFeeTypes
				});
				break;
			}
			case 'refi-summary-details':
				this.updateFormState({
					refiSummary: this.mapFromRefiSummaryDetailsState(nextState as FormStore<RefiApplicationSummaryDTO>)
				});
				break;
			case 'refi-confirmDetails-consent':
				this.updateFormState({
					refiConfirmDetailsConsent: {
						isJointApplication: this.applicationDataQuery.getRefiApplicants().length > 1
					}
				});
		}
	}

	updateFormState(updates: Partial<SimpFormState>): void {
		this.formStateStore.store.update((state: SimpFormState) => ({
			...state,
			...updates
		}));
	}

	private mapToFormStateChannelSetting(channelSetting: ChannelSettingState): FormStateChannelSetting {
		return {
			skipServiceabilitySubmitWorkflow: channelSetting.skipServiceabilitySubmitWorkflow,
			useRelationshipForSOP: channelSetting.useRelationshipForSOP,
			enableDiscount: channelSetting.enableDiscount,
			allowCustomLoaders: channelSetting.allowCustomLoaders,
			allowCommissions: channelSetting.allowCommissions,
			financialInstitutionId: channelSetting.financialInstitutionId
		};
	}

	private updateSplitLoanState(loanInformationStore: FormStore<LoanInformation>) {
		const isSplitLoan = loanInformationStore.data?.[0]?.loanSplits
			? loanInformationStore.data?.[0]?.loanSplits?.length > 0
			: false;
		this.updateFormState({
			loanDetails: merge(this.formStateStore.store.getValue()?.loanDetails ?? {}, {
				isSplitLoan
			})
		});
	}

	private mapFromRefiSummaryDetailsState(
		refiSummaryDetailsStore: FormStore<RefiApplicationSummaryDTO>
	): RefiSummaryState {
		return {
			mainSecurityAddressId: refiSummaryDetailsStore.data?.[0]?.address?.id
		};
	}
}
