import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { SimpFormlyNavigationService } from '../../services/simp-formly-navigation.service';

@Component({
	selector: 'formly-button',
	templateUrl: './formly-button.component.html',
	styleUrls: ['./formly-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyButtonComponent extends FieldType<FieldTypeConfig> implements OnInit {
	tooltipHtml = '';
	uniqueId = '';

	constructor(private simpFormlyNavigationService: SimpFormlyNavigationService) {
		super();
	}
	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (this.to.tooltip) {
			this.tooltipHtml = typeof this.to.tooltip === 'string' ? this.to.tooltip : (this.to.tooltip as string[]).join('');
		}
	}

	onClick(event: Event): void {
		event.stopPropagation();
		if (this.to.readonly) {
			return;
		}
		if (this.to.click) {
			this.to.click(this.field);
		} else if (!isNullOrUndefined(this.to.nextRoute)) {
			this.simpFormlyNavigationService.navigateToStep(this.to.nextRoute as string);
		}
	}
}
