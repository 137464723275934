import { Importance, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableNumber, getNullableYesNo } from '@app/modules/shared/helper/util';
import { Term } from '@app/modules/simp-formly/typings/formly-app';
import { LoanPreferenceFixedVariableInterestDTO } from '@app/modules/typings/api';

export class FixedVariableInterestTransformer {
	static toPayload(applicationId: number, details: FixedVariableInterestModel): LoanPreferenceFixedVariableInterestDTO {
		return {
			applicationId: applicationId,
			importance: details.fixedAndVariableRateImportanceId,
			risksExplained: details.fixedAndVariableRateRisksExplained === true,
			reasonDescription: details.fixedAndVariableRateExtras?.fixedAndVariableRateReasonDescription,
			fixedPeriodDurationLength: getNullableNumber(
				details.fixedAndVariableRateExtras?.fixedAndVariableRateFixedPeriodDuration?.number
			),
			fixedPeriodDurationUnit: details.fixedAndVariableRateExtras?.fixedAndVariableRateFixedPeriodDuration?.frequency,
			reasonFlexibility: getNullableBoolean(details.fixedAndVariableRateExtras?.fixedAndVariableRateReasonFlexibility),
			reasonMakeBudgetingEasier: getNullableBoolean(
				details.fixedAndVariableRateExtras?.fixedRateReasonMakeBudgetingEasier
			),
			reasonLimitingRateIncreaseRisk: getNullableBoolean(
				details.fixedAndVariableRateExtras?.fixedAndVariableRateReasonLimitingRateIncreaseRisk
			),
			reasonOther: !!details.fixedAndVariableRateExtras?.fixedAndVariableRateReasonDescription
		};
	}
	static fromPayload(fixedVariableInterest: LoanPreferenceFixedVariableInterestDTO): FixedVariableInterestModel {
		const details: FixedVariableInterestModel = {
			fixedAndVariableRateExtras: {
				fixedAndVariableRateReasonFlexibility: getNullableYesNo(fixedVariableInterest.reasonFlexibility),
				fixedAndVariableRateReasonDescription: fixedVariableInterest.reasonDescription,
				fixedAndVariableRateFixedPeriodDuration: {
					frequency: fixedVariableInterest.fixedPeriodDurationUnit,
					number: fixedVariableInterest.fixedPeriodDurationLength!
				},
				fixedAndVariableRateReasonLimitingRateIncreaseRisk: getNullableYesNo(
					fixedVariableInterest.reasonLimitingRateIncreaseRisk
				),
				fixedRateReasonMakeBudgetingEasier: getNullableYesNo(fixedVariableInterest.reasonMakeBudgetingEasier)
			},
			fixedAndVariableRateRisksExplained: fixedVariableInterest.risksExplained,
			fixedAndVariableRateImportanceId: fixedVariableInterest.importance
		};
		return details;
	}
}

export interface FixedAndVariableExtras {
	fixedAndVariableRateReasonFlexibility?: YesNo;
	fixedAndVariableRateReasonLimitingRateIncreaseRisk?: YesNo;
	fixedRateReasonMakeBudgetingEasier?: YesNo;
	fixedAndVariableRateReasonDescription: string;
	fixedAndVariableRateFixedPeriodDuration: Term;
}

export interface FixedVariableInterestModel {
	// make it partial class for loan preference
	fixedAndVariableRateImportanceId: Importance;
	fixedAndVariableRateRisksExplained: boolean;
	fixedAndVariableRateExtras: FixedAndVariableExtras;
}
export interface FixedVariableStoredModel {
	fixedAndVariableInterestRateContainer: FixedVariableInterestModel;
}
