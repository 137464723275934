import { Importance, PrincipleAndInterestImportantOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { PrincipalAndInterestDTO } from '@app/modules/typings/api';
import { FrequencyShort } from '@simpology/client-components/utils';

export class PrincipleAndInterestTransformer {
	static toPayload(applicationId: number, details: PrincipleAndInterestModel): PrincipalAndInterestDTO {
		const options = details.pandiExtras?.principalAndInterestOptions?.options ?? [];

		return {
			applicationId: applicationId,
			importance: details.principalAndInterestImportanceId,
			reasonDescription: details.pandiExtras?.principalAndInterestOptions?.otherReasonInput,
			risksExplained: details.principalAndInterestRisksExplained ?? undefined,
			repaymentFrequency: details.pandiExtras?.principalAndInterestRepaymentFrequency ?? undefined,
			reasonBuildUpEquityFromTheStart: options?.includes(
				PrincipleAndInterestImportantOptions.ReasonBuildUpEquityFromTheStart
			),
			reasonHigherLendingLimit: options?.includes(PrincipleAndInterestImportantOptions.ReasonHigherLendingLimit),
			reasonLowerDepositRequired: options?.includes(PrincipleAndInterestImportantOptions.ReasonLowerDepositRequired),
			reasonMinimiseInterestPaidOverLifeOfLoan: options?.includes(
				PrincipleAndInterestImportantOptions.ReasonMinimiseInterestPaidOverLifeOfLoan
			),
			reasonOther: !!details.pandiExtras?.principalAndInterestOptions?.otherReasonInput
		};
	}
	static fromPayload(data: PrincipalAndInterestDTO): PrincipleAndInterestModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);
		const details: PrincipleAndInterestModel = {
			principalAndInterestImportanceId: data.importance!,
			principalAndInterestRisksExplained: data.risksExplained!,
			pandiExtras: {
				principalAndInterestOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				},
				principalAndInterestRepaymentFrequency: data.repaymentFrequency ?? undefined
			}
		};
		return details;
	}
	private static getSelectedIds(data: PrincipalAndInterestDTO, selectedIds: number[]) {
		if (data.reasonMinimiseInterestPaidOverLifeOfLoan) {
			selectedIds.push(PrincipleAndInterestImportantOptions.ReasonMinimiseInterestPaidOverLifeOfLoan);
		}
		if (data.reasonHigherLendingLimit) {
			selectedIds.push(PrincipleAndInterestImportantOptions.ReasonHigherLendingLimit);
		}
		if (data.reasonLowerDepositRequired) {
			selectedIds.push(PrincipleAndInterestImportantOptions.ReasonLowerDepositRequired);
		}
		if (data.reasonBuildUpEquityFromTheStart) {
			selectedIds.push(PrincipleAndInterestImportantOptions.ReasonBuildUpEquityFromTheStart);
		}
	}
}

export interface PrincipleAndInterestModel {
	principalAndInterestImportanceId: Importance;
	principalAndInterestRisksExplained: boolean;
	pandiExtras: {
		principalAndInterestOptions: MultiCheckboxModel;
		principalAndInterestRepaymentFrequency?: FrequencyShort;
	};
}
export interface PrincipleAndInterestStoreModel {
	principalAndInterestContainer: PrincipleAndInterestModel;
}
