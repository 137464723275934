import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';
import { getSharePercentValidationError } from '@app/modules/shared/helper/util';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CustomValidator } from '../typings/custom-validator';

const driversLicenceValidator = (): ValidatorFn => {
	// If either has a value, the other control is also required
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value as string;
		if (value?.match(/[^a-zA-Z0-9]/)) {
			return { onlyAlpha: true };
		}
		if (!value?.match(/.*\d.*\d.*\d.*\d.*/)) {
			return { fourNumeric: true };
		}
		if (!value?.match(/^..\d\d/)) {
			return { thirdAndFourthNumeric: true };
		}

		return null;
	};
};
export const validateSharePercents = (formControl: AbstractControl, field: FormlyFieldConfig) => {
	const model = (formControl.value as { percent?: number }[]) || [];
	if (!model.length || !model.some((m) => m.percent != undefined) || !field.parent?.formControl?.dirty) {
		return true;
	}
	const min = field.props?.min as number;
	const max = field.props?.max as number;

	const errorKey = getSharePercentValidationError(model, min, max);
	const formArray = field.formControl as UntypedFormArray;
	formArray.controls.forEach((control) => {
		const percentControl = (control as UntypedFormGroup)?.controls?.percent as UntypedFormControl;
		if (percentControl) {
			if (errorKey) {
				percentControl.setErrors({ [errorKey]: true });
				if (errorKey !== 'min' || formArray.touched) {
					percentControl.markAsTouched();
				}
			} else {
				percentControl.updateValueAndValidity({ onlySelf: true });
			}
		}
	});

	return true;
};

export const customInputValidators: CustomValidator = {
	driversLicenceValidator: {
		validatorFn: driversLicenceValidator(),
		messages: {
			onlyAlpha: 'Only alphanumeric characters and mixed case allowed',
			fourNumeric: 'The driver licence number must contain at least 4 numeric characters',
			thirdAndFourthNumeric: 'The driver licence number must have the 3rd and the 4th character numeric'
		}
	}
};
