import { Injectable } from '@angular/core';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { EnumObject } from '@simpology/client-components/utils';
import { isEmpty, mapValues } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { URL_CONSTANTS } from '../../constants/api.constants';
import { ApplicantEntityType } from '../../enums/app.enums';
import { ApplicantEnumObject } from '../../enums/enum-helper';
import { formatPlural } from '../../helper/util';
import { BaseJourneyService } from '../../service/base-journey.service';
import { ApplicationDataQuery } from '../application-data/application-data.query';
import { FormEnumsLadmService } from './form-enums-ladm.service';
import { FormEnumsQuery } from './form-enums.query';
import { FormEnumsStore } from './form-enums.store';
import { FormEnums } from './typings/form-enums';

@Injectable({ providedIn: 'root' })
export class FormEnumsService extends BaseJourneyService<EnumObject[]> {
	private cache = new Map<string, Observable<EnumObject[]>>();
	private variationEnums: FormEnums = {};
	private useLadmAPIFor = [
		'FinancialYear',
		'AuthorisationLevel',
		'ApplicationTemplate',
		'AccreditedPeople',
		'BankBranch'
	];

	constructor(
		private formEnumsStore: FormEnumsStore,
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsLadmService: FormEnumsLadmService,
		private applicationDataQuery: ApplicationDataQuery
	) {
		super();
		this.setMetaRoute(URL_CONSTANTS.LIST_OPTION);
	}

	fetchEnums(keys: string[]): Observable<EnumObject[][]> {
		if (!keys.length) {
			return of([]);
		}
		return forkJoin(keys.map((e) => this.fetch(e, true)));
	}

	/**
	 * select.component - watch (observable)
	 * trx service fetch - observable (complete - takeOne(1))
	 * txn service fetchEnums observable (complete - takeOne(1))
	 * @param key
	 * @param takeOne
	 */

	fetch(key: string, takeOne = false): Observable<EnumObject[]> {
		if (!key) {
			return of([]);
		}
		const existingOptions = this.formEnumsQuery.getOptions(key);
		if (existingOptions) {
			return this.formEnumsQuery.selectEnumOptions(key, takeOne);
		}

		const cachedHttp$ = this.cache.get(key);

		if (cachedHttp$) {
			return cachedHttp$;
		}

		const http$ = this.getListOptions(key).pipe(
			map((enums: EnumObject[]) => {
				if (!enums || !enums.length) {
					enums = [];
				}
				this.formEnumsStore.update({ [key]: enums });
				return enums;
			}),
			switchMap(() => this.formEnumsQuery.selectEnumOptions(key, takeOne)),
			catchError(() => {
				console.error('Missing key:- ', key);
				return of([]);
			}),
			shareReplay(1)
		);
		this.cache.set(key, http$);
		return http$;
	}

	fetchSubEnums(
		key: string,
		parentId: number,
		secondDependency?: number,
		thirdDependency?: number
	): Observable<EnumObject[]> {
		if (this.useLadmAPIFor.includes(key)) {
			return this.formEnumsLadmService.get(key);
		} else {
			const journeyId = this.applicationDataQuery.getApplicationJourneyId();
			const url = thirdDependency
				? `Journey/${key}/${parentId}/${secondDependency}/${thirdDependency}/${journeyId}`
				: secondDependency
				? `Journey/${key}/${parentId}/${secondDependency}/${journeyId}`
				: `Journey/${key}/${parentId}/${journeyId}`;

			return this.getCustom(url).pipe(map(optionsToEnums));
		}
	}

	fetchSetupInitialEnums() {
		return forkJoin([this.getAllListOptions(), this.fetchEnums(this.useLadmAPIFor)]).pipe(
			tap(([enums]) => {
				const storeEnums = mapValues(enums, optionsToEnums);
				this.formEnumsStore.update(storeEnums);
			})
		);
	}

	/**
	 * A journey can have multiple variations and each variation has specific updated Enum dropdowns.
	 * @param variations
	 */
	fetchVariationsEnums(variations?: number[]) {
		if (!isEmpty(this.variationEnums)) {
			// Restore the original Enums before updating again
			this.formEnumsStore.update(this.variationEnums);
		}

		if (variations?.length) {
			this.getCustom(
				`journeyvariation/${this.applicationDataQuery.getApplicationJourneyId()}/${variations.join(',')}`
			).subscribe((enums: { [key: string]: BatchListOptions[] }) => {
				const storeEnums = mapValues(enums, optionsToEnums);
				this.variationEnums = mapValues(storeEnums, (v, key) => this.formEnumsQuery.getOptions(key));
				this.formEnumsStore.update(storeEnums);
			});
		}
	}

	syncApplicantsEnum(setupApplicants: SetUpApplicant[]): void {
		const applicants = setupApplicants
			.filter((setupApplicant) => setupApplicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant)
			.map((personApplicant) => ({
				id: personApplicant.id,
				label: `${personApplicant.firstName} ${personApplicant.lastName}`
			}));

		this.formEnumsStore.update({ Applicants: applicants });

		const allApplicants = setupApplicants.map((applicant) => ({
			id: applicant.id,
			label:
				applicant.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant
					? `${applicant.firstName} ${applicant.lastName}`
					: applicant.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant
					? (applicant.trustName as string)
					: (applicant.companyName as string),
			type: applicant.applicantTypeModal?.type.toString() ?? ApplicantEntityType.PersonApplicant.toString()
		}));

		this.formEnumsStore.update({ AllApplicants: allApplicants });
	}

	addToHouseholdsEnum(id: number, label: string): void {
		const households = cloneDeep(this.formEnumsQuery.getOptions('Households'));
		const household = households.find((a) => a.id === id);
		if (household) {
			household.label = label;
		} else {
			households.push({
				id,
				label
			});
		}

		this.formEnumsStore.update({ Households: households });
	}

	updateHouseholdAndApplicantsEnum(houseHoldandApplicants: EnumObject[]): void {
		this.formEnumsStore.update({ HouseholdsAndApplicants: houseHoldandApplicants });
	}

	updatePersonEnums(key: string, enums: ApplicantEnumObject[]): void {
		this.formEnumsStore.update({ [key]: enums as unknown as EnumObject[] });
	}

	updateFormEnums(key: string, enums: EnumObject[]): void {
		this.formEnumsStore.update({ [key]: enums });
	}

	updateYears(): void {
		this.addYearsEnum('Years', 30, false);
		this.addYearsEnum('YearsUpTo5', 5, true);
	}

	resetEnums(): void {
		this.formEnumsStore.reset();
		this.cache.clear();
		this.variationEnums = {};
	}

	private addYearsEnum(key: string, years: number, ascending: boolean): void {
		const options: EnumObject[] = [];
		let year = years;
		while (year >= 1) {
			options.push({
				id: year,
				label: `${year} ${formatPlural(year, 'year')}`
			});
			year--;
		}
		if (ascending) {
			options.sort((a, b) => a.id - b.id);
		}
		this.formEnumsStore.update({ [key]: options });
	}

	private getListOptions(key: string): Observable<EnumObject[]> {
		if (this.useLadmAPIFor.includes(key)) {
			return this.formEnumsLadmService.get(key);
		}
		return this.getCustom(`journey/${key}/${this.applicationDataQuery.getApplicationJourneyId()}`).pipe(
			map(optionsToEnums)
		);
	}

	private getAllListOptions(): Observable<{ [key: string]: BatchListOptions[] }> {
		const url = this.applicationDataQuery.getApplicationJourneyId()
			? `journey/${this.applicationDataQuery.getApplicationJourneyId()}`
			: '';
		return this.getCustom(url) as Observable<{
			[key: string]: BatchListOptions[];
		}>;
	}
}

interface BatchListOptions {
	optionId: number;
	text: string;
	isDefault?: boolean;
	info?: string;
}

const optionToEnum = (option: BatchListOptions): EnumObject => ({
	id: option.optionId,
	label: option.text,
	info: option.info
});

const optionsToEnums = (options: BatchListOptions[]): EnumObject[] => options.map(optionToEnum);
