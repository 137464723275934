<hr class="simp-margin-top-base" aria-hidden="true" />
<div>
	<div
		class="d-flex justify-content-between sub-section__header"
		[ngClass]="{
			'sub-section__header--validation-error': formControl.dirty && formControl.invalid && !formControl.value.length
		}"
	>
		<h5
			class="simp-h5 simp-text--primary simp-text--bold"
			[class.loanapp-area__sub-section-title]="to.collapsible"
			(click)="to.expanded = !to.expanded"
		>
			{{ to.label }} {{ to.list && to.showRowCount ? '(' + field.formControl.value?.length + ')' : '' }}
		</h5>
		<div class="d-flex flex-column col-3" *ngIf="!to.readonly">
			<ng-container *ngIf="to.list && (to.expanded || !to.collapsible)">
				<button
					(click)="onClick($event)"
					type="button"
					class="btn btn-sm btn-secondary simp-button simp-margin-left-small"
					*ngIf="to.maxLength ? field.fieldGroup?.length! < to.maxLength : true"
				>
					{{ to.subText ?? 'Add' }} <span class="visually-hidden">{{ to.label }}</span>
				</button>
			</ng-container>
		</div>
	</div>
	<div *ngFor="let d of data; index as i">
		<button
			type="button"
			(click)="openExisting(i)"
			class="col-12 border simp-padding-base refi-modal-summary d-flex justify-content-between align-items-center"
			[class.invalid]="formControl.controls[i].invalid"
		>
			<span [innerHTML]="d?.summary"></span
			><span class="visually-hidden"
				>{{ to.label }} {{ formControl.controls[i].invalid ? 'is invalid, please fix' : '' }}</span
			>

			<simp-icon name="fal fa-chevron-right" class="custom-input-block__icon"></simp-icon>
		</button>
		<div
			class="simp-field-error simp-text-small simp-text--red simp-margin-top-base"
			*ngIf="formControl.controls[i].invalid"
		>
			<simp-icon name="fas fa-exclamation-circle" class="simp-margin-right-base"></simp-icon
			>{{ formControl.controls[i].errors?.invalid || 'Please fix errors' }}
		</div>
	</div>
	<div class="refi-message-highlight simp-margin-top-medium simp-text--primary" *ngIf="to.message">
		<p [innerHTML]="to.message"></p>
	</div>

	<formly-field *ngFor="let f of field.fieldGroup" [field]="f"> </formly-field>
</div>
