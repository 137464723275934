import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { AssociatedProductsDTO, LmiDTO } from '@app/modules/typings/api';

export class LendersMortgageTransformer {
	static fromPayload(
		associatedProducts: AssociatedProductsDTO,
		valueFromLoanPage: LmiDTO
	): LendersMortgageInsuranceModel {
		return {
			id: associatedProducts?.id,
			isLmiRequired: getNullableYesNo(associatedProducts?.isLmiRequired),
			lmiExplained: associatedProducts?.lmiExplained,
			lmiConfirmed: associatedProducts?.lmiConfirmed,
			capitaliseLmi: getNullableYesNo(associatedProducts?.capitaliseLmi),
			capitaliseLmiExplained: associatedProducts?.capitaliseLmiExplained,
			valueFromLoanPage: valueFromLoanPage?.lmiRequirement
		};
	}
}

export interface LendersMortgageInsuranceModel {
	id?: number;
	isLmiRequired?: YesNo;
	lmiExplained?: boolean;
	lmiConfirmed?: boolean;
	capitaliseLmi?: YesNo;
	capitaliseLmiExplained?: boolean;
	valueFromLoanPage?: boolean;
}
