import { Injectable } from '@angular/core';
import { PropertyValuationService } from '@app/modules/applicants/services/property-valuation.service';
import {
	DigitalServiceType,
	DigitalWidgetTriggerType
} from '@app/modules/digital-widgets/enums/digital-widgets-configuration.enums';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { DigitalWidgetExecuteResponse } from '@app/modules/digital-widgets/typings/digital-widgets-configuration';
import { HomeLoanModel, HomeLoanModelDetails } from '@app/modules/financial-position/model/homeloan.model';
import { RealEstateDetails, RealEstateModel } from '@app/modules/financial-position/model/property.model';
import {
	RentalDetailsModel,
	RentalModel,
	RentalModelDetails
} from '@app/modules/financial-position/model/rental.model';
import { LoanServiceabilityService } from '@app/modules/loan-serviceability/loan-serviceability.service';
import {
	PropertyAssetModel,
	PropertyAssetModelTransformer
} from '@app/modules/loan-serviceability/model/property-asset.model';
import {
	PropertyValuationModel,
	PropertyValuationModelTransformer
} from '@app/modules/loan-serviceability/model/property-valuation.model';
import { PropertyModalModel } from '@app/modules/property/models/property-modal.model';
import { PropertyService } from '@app/modules/property/services/property.service';
import { RequestPropertyFieldsService } from '@app/modules/property/services/request-property-fields.service';
import { PropertyModalDTO } from '@app/modules/property/typings/property-modal';
import { ApplicationDetails } from '@app/modules/setup/typings/setup';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { isNullOrUndefined } from '@app/modules/shared/helper/util';
import { IncomeHeaderSummary } from '@app/modules/shared/model/header-summary.model';
import { PercentageOwned } from '@app/modules/shared/model/percentage-owned.model';
import { AddressService } from '@app/modules/shared/service/address.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { FormStore } from '@app/modules/shared/store/form-data/model/form-data.model';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import {
	formlyDeleteFromArray,
	formlyDeleteFromImmediateArray,
	formlyExtendExpressionProperties,
	formlyInsertModalField,
	formlyOnChange,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField,
	getInitializedFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { CustomNgbModalRef, SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { Address, IdAndAddressId, PropertyDTO, PropertyValuationInfoDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get, isEmpty, merge, remove } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, finalize, map, Observable, of, pairwise, startWith, Subscription, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LendingGuaranteeModel } from '../../applicants/models/lending-guarantee.model';
import {
	LendersMortgageInsurance,
	LendersMortgageInsuranceTransformer
} from '../../loan-serviceability/model/lenders-mortgage-insurance-model';
import {
	AssetTransaction,
	LendingGuaranteeType,
	PropertyEvaluationType,
	PropertyModalOpeningSection,
	YesNo
} from '../../shared/enums/app.enums';
import { deletedSuccessfullyMessage } from '../../shared/helper/util';
import { FormArrayDeleteService } from '../../shared/service/form-array-delete.service';
import { PercentOwnedService } from '../../shared/service/percent-owned.service';
import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { FormDataService } from '../../shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '../../shared/store/form-enums/form-enums.query';
import { ApplicantType } from '../../shared/store/form-enums/form-enums.store';
import { FormValidationsService } from '../../shared/store/form-validations/form-validations.service';
import { PropertySecurityDetails } from '../models/property-security-details.model';

const STATE_KEY_REAL_ESTATE = 'realEstate';
const STATE_KEY_HOME_LOANS = 'homeloans';
const STATE_KEY_RENTALS = 'rentals';
const STATE_KEY_PROPERTY = 'property';

const SUB_KEY_PROPERTY_ASSET_DETAILS = 'propertyAssetDetails';
const SUB_KEY_HOME_LOAN_DETAILS = 'homeLoanDetails';
const SUB_KEY_RENTAL_INCOME_DETAILS = 'rentalIncomeDetails';

const SUB_KEY_PERSON_FINANCIAL_POSITION_INCOME = 'income';

@Injectable({ providedIn: 'root' })
export class PropertyModalTransformerService {
	constructor(
		private addressService: AddressService,
		private applicationDataQuery: ApplicationDataQuery,
		private confirmationDialogService: SimpConfirmationDialogService,
		private loanServiceabilityService: LoanServiceabilityService,
		private formArrayDeleteService: FormArrayDeleteService,
		private formValidationsService: FormValidationsService,
		private percentOwnedService: PercentOwnedService,
		private propertyValuationService: PropertyValuationService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private simpFormlyModalService: SimpFormlyModalService,
		private toastr: ToastrService,
		private formEnumsService: FormEnumsService,
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private propertyService: PropertyService,
		private sharedFlags: SharedFlagsService,
		private requestPropertyFieldsService: RequestPropertyFieldsService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository
	) {}

	wireUpExpressions(propertyModalField: FormlyFieldConfig | undefined, defaultPercentsOwned: PercentageOwned[]) {
		if (propertyModalField) {
			formlyOnChange(propertyModalField.fieldGroup, 'transaction', this.handleTransactionChange.bind(this));

			formlyExtendExpressionProperties(propertyModalField.fieldGroup, 'transaction', {
				className: (model: PropertyModalModel) =>
					model?.showFullModal ? 'col-6' : this.sharedFlags.isPropertyInLoanServiceability ? 'col-7' : 'col-12'
			});

			formlyExtendExpressionProperties(propertyModalField.fieldGroup, 'address', {
				className: (model: PropertyModalModel) => (model?.showFullModal ? 'col-9' : 'col-12')
			});

			if (this.sharedFlags.isPropertyInLoanServiceability) {
				formlyExtendExpressionProperties(propertyModalField.fieldGroup, 'approvalInPrinciple', {
					className: (model: PropertyModalModel) => (model?.showFullModal ? 'col-3' : 'col-5')
				});

				formlyExtendExpressionProperties(propertyModalField.fieldGroup, 'suburbAddress', {
					className: (model: PropertyModalModel) => (model?.showFullModal ? 'col-9' : 'col-12')
				});

				formlyOnClick(
					propertyModalField.fieldGroup,
					'rentalIncomeDetails.delete',
					this.deleteRentalInLoanServiceability
				);
				formlyOnClick(propertyModalField.fieldGroup, 'homeLoanDetails.delete', this.deleteHomeLoanInLoanServiceability);
			} else {
				formlyOnClick(
					propertyModalField.fieldGroup,
					'rentalIncomeDetails.delete',
					this.deleteRentalInFinancialPosition
				);
				formlyOnClick(propertyModalField.fieldGroup, 'homeLoanDetails.delete', this.deleteHomeLoanInFinancialPosition);
			}

			['percentsOwned', 'useAsSecurity'].forEach((key) => {
				formlyRegisterHooks(propertyModalField.fieldGroup, `propertyAssetDetails.${key}`, {
					onInit: (field: FormlyFieldConfig | undefined) => {
						if (field) {
							return field.formControl?.valueChanges.pipe(
								tap(() => {
									this.showHideGuaranteeLimit(field.parent?.parent?.parent);
								})
							);
						} else {
							return of(null);
						}
					}
				});
			});

			// For property assets
			this.setDefaultPercentageOwned(
				propertyModalField?.fieldGroup,
				defaultPercentsOwned,
				'propertyAssetDetails.percentsOwned'
			);

			this.setSharePercentageTitles(propertyModalField?.fieldGroup, 'propertyAssetDetails.percentsOwned.percent');

			// For home loans
			this.setDefaultPercentageOwned(
				propertyModalField?.fieldGroup,
				defaultPercentsOwned,
				'homeLoanDetails.percentsOwned'
			);

			this.setSharePercentageTitles(propertyModalField?.fieldGroup, 'homeLoanDetails.percentsOwned.percent');

			formlyOnClick(propertyModalField?.fieldGroup, 'propertyAssetDetails.isEqualShare', (field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			});

			formlyOnClick(propertyModalField?.fieldGroup, 'homeLoanDetails.isEqualShare', (field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			});
		}
	}

	configurePropertyModalForFinancialPosition(
		formFields: FormlyFieldConfig[],
		incomeSectionKey: string = SUB_KEY_PERSON_FINANCIAL_POSITION_INCOME
	): void {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		const defaultAllPercentageOwned = this.percentOwnedService.getAllDefaultSharePercents();

		const propertyModalFields = getFormField(formFields, 'propertyModal') as FormlyFieldConfig;
		const newPropertyDetailsConfig = getFormField(formFields, 'assets.realEstate.details') as FormlyFieldConfig;
		const realEstateDetails = cloneDeep(propertyModalFields);
		if (realEstateDetails) {
			realEstateDetails.key = 'propertyDetails';
			this.wireUpExpressions(realEstateDetails, defaultAllPercentageOwned);
		}
		newPropertyDetailsConfig?.fieldGroup?.push(realEstateDetails);

		const newRentalIncomeDetailsConfig = getFormField(
			formFields,
			`${incomeSectionKey}.rentals.details`
		) as FormlyFieldConfig;
		const rentalDetails = cloneDeep(propertyModalFields);
		if (rentalDetails) {
			rentalDetails.key = 'rentalDetails';
			this.wireUpExpressions(rentalDetails, defaultAllPercentageOwned);
		}
		newRentalIncomeDetailsConfig?.fieldGroup?.push(rentalDetails);

		const newHomeLoanDetailsConfig = getFormField(formFields, 'liabilities.homeloans.details') as FormlyFieldConfig;
		const homeLoanDetails = cloneDeep(propertyModalFields);
		if (homeLoanDetails) {
			homeLoanDetails.key = 'homeLoanDetails';
			this.wireUpExpressions(homeLoanDetails, defaultAllPercentageOwned);
		}
		newHomeLoanDetailsConfig?.fieldGroup?.push(homeLoanDetails);

		remove(
			(formFields[0].fieldArray as FormlyFieldConfig)?.fieldGroup as FormlyFieldConfig[],
			(f: FormlyFieldConfig) => f?.key === 'propertyModal'
		);
		formlyOnClick(formFields, `${incomeSectionKey}.rentals`, this.addNewRentalIncome.bind(this));
		formlyOnClick(formFields, `${incomeSectionKey}.rentals.details`, this.editRentalIncome.bind(this));
		formlyOnClick(formFields, 'assets.realEstate', this.addNewPropertyAsset.bind(this));
		formlyOnClick(formFields, 'assets.realEstate.details', this.editPropertyAsset.bind(this));
		formlyOnClick(formFields, 'liabilities.homeloans', this.addNewHomeLoan.bind(this));
		formlyOnClick(formFields, 'liabilities.homeloans.details', this.editHomeLoan.bind(this));

		formlyOnClick(formFields, `${incomeSectionKey}.rentals.delete`, this.deleteRentalInFinancialPosition);
		formlyOnClick(formFields, 'assets.realEstate.delete', this.deleteProperty);
		formlyOnClick(formFields, 'liabilities.homeloans.delete', this.deleteHomeLoanInFinancialPosition);
	}

	configurePropertyModalForLoanServiceability(formFields: FormlyFieldConfig[]): void {
		this.sharedFlags.isPropertyInLoanServiceability = true;
		const defaultAllPercentageOwned = this.percentOwnedService.getAllDefaultSharePercents();

		const propertyModalField = getFormField(formFields, 'purchaseDetails.property.details.propertyDetails');
		this.wireUpExpressions(propertyModalField, defaultAllPercentageOwned);

		formlyOnClick(formFields, 'purchaseDetails.property', this.addNewPropertyAsset.bind(this));
		formlyOnClick(formFields, 'purchaseDetails.property', (field: FormlyFieldConfig) =>
			this.addNewPropertyAsset(field, true)
		);
		formlyOnClick(formFields, 'purchaseDetails.property.details', this.editSecurityPropertyAsset.bind(this));
	}

	handlePropertyEvaluationTypeChange(
		formField: FormlyFieldConfig,
		propertyFieldName: string,
		estimatedValueFieldName: string
	) {
		const propertyAssetDetailsField = getFormField(formField?.fieldGroup, propertyFieldName);

		formlyRegisterHooks(formField?.fieldGroup, `${propertyFieldName}.${estimatedValueFieldName}`, {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field) {
					const amount = field.formControl?.get('amount');
					const frequency = field.formControl?.get('frequency');
					const addressField = getFormField(formField?.fieldGroup, 'address');
					const amountField = getFormField(field?.fieldGroup, 'amount');
					const valuedDateField = getFormField(propertyAssetDetailsField?.fieldGroup, '0.estimatedValueValuedDate');
					const address = addressField?.formControl?.value as Address;
					if (address) {
						this.handlePropertyEvaluationTypeFilter(field, address);
					}
					if (amount?.valid && this.isElectronicValuationEnabled(frequency?.value as PropertyEvaluationType)) {
						this.propertyValuationService
							.isValuationServiceEnabled()
							.pipe(
								map((serviceEnabled) => {
									if (serviceEnabled) {
										this.disableEstimatedValueAndValuedDate(amountField, valuedDateField);
									}
								})
							)
							.subscribe();
					}

					return frequency?.valueChanges.pipe(
						startWith(null),
						pairwise(),
						tap(([prev, flag]) => {
							if (prev !== null && prev !== flag) {
								if (this.isElectronicValuationEnabled(flag as PropertyEvaluationType)) {
									// Handle property valuation related functionalities
									this.propertyValuationService
										.isValuationServiceEnabled()
										.pipe(
											map((serviceEnabled) => {
												if (serviceEnabled) {
													this.handlePropertyValuation(formField, address, propertyFieldName, estimatedValueFieldName);
												}
											})
										)
										.subscribe();
								} else {
									valuedDateField?.formControl?.setValue(null);
									this.disableEstimatedValueAndValuedDate(amountField, valuedDateField, false);
								}
							}
						})
					);
				}

				return of();
			}
		});
	}

	private setSharePercentageTitles(formFields: FormlyFieldConfig[] | undefined, path: string): void {
		formlyExtendExpressionProperties(formFields, path, {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});
	}

	private handleTransactionChange(field: FormlyFieldConfig) {
		const model = field?.model as PropertyModalModel;
		this.handlePrimaryUsageField(field?.parent, model.transaction);
		const defaultFromFormlyTransformer = !!get(model.homeLoanDetails, '[0].default', false);
		if (
			model.transaction === AssetTransaction.OwnsExistingMortgage &&
			(!model.homeLoanDetails.length || (defaultFromFormlyTransformer && model.homeLoanDetails.length === 1))
		) {
			setTimeout(() => {
				this.addNewEntry(field.parent as FormlyFieldConfig, SUB_KEY_HOME_LOAN_DETAILS);
				this.expandCollapseSectionInPropertyModal(field.parent as FormlyFieldConfig, SUB_KEY_HOME_LOAN_DETAILS, true);
			});
		}
	}

	private handlePrimaryUsageField(modalField: FormlyFieldConfig | undefined, transaction: AssetTransaction) {
		let primaryUsageField = getInitializedFormField(modalField?.fieldGroup, 'propertyAssetDetails.0.primaryUse');
		if (!primaryUsageField) {
			primaryUsageField = getFormField(modalField?.fieldGroup, 'propertyAssetDetails.primaryUse');
		}

		if (primaryUsageField?.templateOptions) {
			let primaryUsageEnumKey = 'PrimaryUsage';
			switch (transaction) {
				case AssetTransaction.Owns:
				case AssetTransaction.OwnsExistingMortgage:
					primaryUsageEnumKey = 'PrimaryUsagePropertyOwn';
					break;
				case AssetTransaction.Purchasing:
					primaryUsageEnumKey = 'PrimaryUsagePropertyPurchase';
					break;
			}

			const primaryUsage = primaryUsageField?.formControl?.value as number;

			primaryUsageField.templateOptions.options = this.formEnumsService.fetch(primaryUsageEnumKey, true).pipe(
				tap((options: EnumObject[]) => {
					if (primaryUsage && !options.some((option) => option.id === primaryUsage)) {
						primaryUsageField?.formControl?.setValue(null);
					}
				})
			);
		}
	}

	private setDefaultPercentageOwned(
		formFields: FormlyFieldConfig[] | undefined,
		DefaultPercentageOwned: PercentageOwned[],
		path: string
	) {
		const percentOwned = getFormField(formFields, path);
		if (percentOwned && percentOwned.templateOptions) {
			percentOwned.templateOptions.showAllLabels = true;
			percentOwned.defaultValue = DefaultPercentageOwned;
		}
	}

	private addNewPropertyAsset(field: FormlyFieldConfig, isSecuritySection = false) {
		this.sharedFlags.isPropertyInLoanServiceability = isSecuritySection;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.PROPERTY;
		formlyInsertModalField(field, 'details.propertyDetails', (modalField) => this.handlePropertyModal(modalField));
	}

	private addNewRentalIncome(field: FormlyFieldConfig) {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.RENTAL;
		formlyInsertModalField(field, 'details.rentalDetails', (modalField) => this.handlePropertyModal(modalField));
	}

	private addNewHomeLoan(field: FormlyFieldConfig) {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.HOME_LOAN;
		formlyInsertModalField(field, 'details.homeLoanDetails', (modalField) => this.handlePropertyModal(modalField));
	}

	private editPropertyAsset(field: FormlyFieldConfig): void {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.PROPERTY;
		const modalField = getFormField(field?.fieldGroup, 'propertyDetails');
		const details = field.model as RealEstateDetails;
		if (modalField) {
			this.handlePropertyModal(modalField, details?.propertyDetails?.address);
		}
	}

	private editSecurityPropertyAsset(field: FormlyFieldConfig): void {
		this.sharedFlags.isPropertyInLoanServiceability = true;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.PROPERTY;
		const modalField = getFormField(field?.fieldGroup, 'propertyDetails');
		if (modalField) {
			const id = get(field?.parent?.model, 'id') as number;
			const propertyDetails = this.getPropertyDetailsInLoanServiceability(
				(property: PropertyAssetModel) => property?.id === id
			);
			const address = this.isAIPProperty(propertyDetails) ? propertyDetails?.suburbAddress : propertyDetails?.address;
			this.handlePropertyModal(modalField, address);
		}
	}

	private editRentalIncome(field: FormlyFieldConfig): void {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.RENTAL;
		const modalField = getFormField(field.fieldGroup, 'rentalDetails');
		const details = field.model as RentalModelDetails;
		if (modalField) {
			this.handlePropertyModal(modalField, details?.rentalDetails?.address);
		}
	}

	private editHomeLoan(field: FormlyFieldConfig): void {
		this.sharedFlags.isPropertyInLoanServiceability = false;
		this.sharedFlags.propertyModalOpenedSection = PropertyModalOpeningSection.HOME_LOAN;
		const modalField = getFormField(field.fieldGroup, 'homeLoanDetails');
		const details = field.model as HomeLoanModelDetails;
		if (modalField) {
			this.handlePropertyModal(modalField, details?.homeLoanDetails?.address);
		}
	}

	private handlePropertyModal(modalField: FormlyFieldConfig, existingAddress?: Address) {
		const edit = !!existingAddress;

		this.setDefaultApprovalInPrincipleValue(modalField);
		this.handlePropertyEvaluationTypeChange(modalField, SUB_KEY_PROPERTY_ASSET_DETAILS, 'value');

		this.openPropertyModal(modalField, edit);

		if (edit) {
			this.showFullPropertyModal(modalField, false);
		} else {
			formlyOnClick(modalField.fieldGroup, 'go', () => {
				this.validateModal();

				if (modalField?.formControl?.valid) {
					const modalModel = modalField?.model as PropertyModalModel;
					const isAIP = this.isAIPProperty(modalModel);
					const addressId =
						!this.sharedFlags.isPropertyInLoanServiceability || !isAIP
							? modalModel?.address?.id
							: modalModel?.suburbAddress?.id;
					const isPropertyAvailable = this.isPropertyExistForExistingAddresses(addressId);
					if (isPropertyAvailable) {
						this.showFullPropertyModal(modalField);
					} else {
						this.checkPropertyExistForNewAddress(addressId as number, modalField);
					}
				}
			});
		}
	}

	private openPropertyModal(modalField: FormlyFieldConfig, edit: boolean) {
		this.sharedFlags.isPropertyModalOpened = true;

		// race condition in firefox, modalRef.action is not defined error
		setTimeout(() => {
			const modalRef = this.simpFormlyModalService.openModal(modalField, undefined, {
				backdrop: 'static',
				size: edit ? 'xl property-modal-wrapper' : 'lg property-modal-wrapper'
			});

			modalRef.shown.subscribe(() => {
				this.showHideGuaranteeLimit(modalField);
			});

			formlyOnClick(modalField.fieldGroup, 'done', () => {
				this.savePropertyModalDetails(modalField, modalRef, subscription);
			});

			// Handle modal close on cancel button click
			formlyOnClick(modalField.fieldGroup, 'cancel', () => {
				this.closePropertyModal(modalField, modalRef, subscription);
			});
			// Handle modal close on X button click
			const subscription = modalRef.action.subscribe((action: string) => {
				if (action !== 'submit') {
					this.closePropertyModal(modalField, modalRef, subscription);
				}
			});
		});
	}

	private checkPropertyExistForNewAddress(addressId: number, modalField: FormlyFieldConfig): void {
		this.loanServiceabilityService.fetchPropertyAssetsByAddressId(addressId).subscribe((propertyDto: PropertyDTO) => {
			if (
				!this.sharedFlags.isPropertyInLoanServiceability &&
				propertyDto?.transaction === AssetTransaction.Purchasing
			) {
				getFormField(modalField?.fieldGroup, 'address')?.formControl?.setErrors({ invalid: true });
			} else if (this.sharedFlags.isPropertyInLoanServiceability && propertyDto) {
				// Show modal with loaded non security property
				const applicants = this.applicationDataQuery.getApplicants();
				const property = PropertyAssetModelTransformer.fromPayload(
					propertyDto,
					applicants,
					this.formEnumsQuery,
					this.personsCompaniesEnumService
				);
				property.isClone = true;
				if (property?.details?.propertyDetails?.propertyAssetDetails?.[0]) {
					property.details.propertyDetails.propertyAssetDetails[0].useAsSecurity = YesNo.Yes;
				}
				const realEstates = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>(STATE_KEY_PROPERTY) ?? [];
				this.simpFormlyHandlerService.updateToState(STATE_KEY_PROPERTY, property, realEstates.length);
				this.showFullPropertyModal(modalField);
			} else {
				this.setValueForShowFullModalFlag(modalField, true);
				this.showFullPropertyModal(modalField, true, true);
			}
		});
	}

	private showFullPropertyModal(modalField: FormlyFieldConfig, isNew = true, newProperty = false) {
		const propertyModalModel = cloneDeep(
			merge({}, modalField?.model, modalField?.formControl?.value) as PropertyModalModel
		);
		const expandPropertyAsset =
			this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.PROPERTY || isNew;
		const expandRentalIncome = this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.RENTAL;
		const expandHomeLoan =
			propertyModalModel.transaction === AssetTransaction.OwnsExistingMortgage ||
			this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.HOME_LOAN;
		const addNewRentalIncome = expandRentalIncome && isNew && !propertyModalModel.rentalIncomeDetails.length;
		const addNewHomeLoan = expandHomeLoan && isNew;

		const modalElements = document.getElementsByClassName('property-modal-wrapper');
		if (modalElements.length > 0) {
			modalElements[0].classList.remove('modal-lg');
			modalElements[0].classList.add('modal-xl');
		}

		setTimeout(() => {
			// When need to add new entries, add function available after a while
			if (newProperty) {
				this.addNewEntry(modalField, SUB_KEY_PROPERTY_ASSET_DETAILS);
			}
			if (addNewRentalIncome) {
				this.addNewEntry(modalField, SUB_KEY_RENTAL_INCOME_DETAILS);
			}
			if (addNewHomeLoan) {
				this.addNewEntry(modalField, SUB_KEY_HOME_LOAN_DETAILS);
			}
		});

		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_PROPERTY_ASSET_DETAILS, expandPropertyAsset);
		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_RENTAL_INCOME_DETAILS, expandRentalIncome);
		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_HOME_LOAN_DETAILS, expandHomeLoan);
	}

	private savePropertyModalDetails(
		modalField: FormlyFieldConfig,
		modalRef: CustomNgbModalRef,
		subscription: Subscription
	) {
		this.validateModal();

		if (modalField?.formControl?.valid) {
			const uiModel = modalField?.formControl?.value as PropertyModalModel; // NOTE: uiModel only has data fields that are not hidden
			const model = cloneDeep(merge({}, modalField?.model, uiModel) as PropertyModalModel);

			if (model.transaction !== AssetTransaction.Purchasing) {
				model.approvalInPrinciple = YesNo.No;
			}

			let propertyAssetModel: PropertyAssetModel | undefined;

			if (this.sharedFlags.isPropertyInLoanServiceability) {
				const propertySecurityField = getParentFormField(modalField, 'details')?.parent;
				propertyAssetModel = propertySecurityField?.model as PropertyAssetModel;
			}

			let propertySecurityDetails: PropertySecurityDetails | undefined;
			const useAsSecurity = model.propertyAssetDetails[0].useAsSecurity;
			if (!this.sharedFlags.isPropertyInLoanServiceability && model.propertyId && useAsSecurity === YesNo.Yes) {
				propertySecurityDetails = this.getPropertySecurityDetails(model.propertyId);
			}

			const saveRentalIncomes = this.isSaveRentalIncomes(modalField);
			const saveHomeLoans = this.isSaveHomeLoans(modalField);

			if (saveHomeLoans) {
				model.homeLoanDetails?.forEach((homeLoan, index) => {
					if (uiModel.homeLoanDetails) {
						const uiData = uiModel.homeLoanDetails[index];
						if (uiData) {
							homeLoan.clearingBalanceOnSettlement = uiData.clearingBalanceOnSettlement;
							homeLoan.clearingFromThisLoan = uiData.clearingFromThisLoan;
							homeLoan.clearingThisLiability = uiData.clearingThisLiability;
							homeLoan.originalLoanTerm = uiData.originalLoanTerm;
							homeLoan.originalPaymentTerm = uiData.originalPaymentTerm;
						}
					}
				});
			}

			const propertyPayload = this.sharedFlags.isPropertyInLoanServiceability
				? this.propertyService.getPropertyPayloadFromLoanServiceability(
						model,
						saveRentalIncomes,
						saveHomeLoans,
						propertyAssetModel
				  )
				: this.propertyService.getPropertyPayloadFromFinancialPosition(
						model,
						saveRentalIncomes,
						saveHomeLoans,
						propertySecurityDetails
				  );

			let deleteRentals: RentalDetailsModel[] = [];
			if (!saveRentalIncomes && model.rentalIncomeDetails?.length > 0) {
				deleteRentals = model.rentalIncomeDetails.filter((rental) => rental.id && rental.id > 0);
			}

			this.savePropertyData(
				propertyPayload,
				modalField,
				deleteRentals,
				saveRentalIncomes,
				saveHomeLoans,
				modalRef,
				subscription
			)
				.pipe(
					switchMap((result: IdAndAddressId) => {
						propertyPayload.propertyDto.id = result.id;
						propertyPayload.propertyDto.address.id = result.addressId;

						const isAddMode = isNullOrUndefined(model.propertyId);
						if (!isAddMode && !isEmpty(propertyPayload.propertyDto?.features?.propertyImage)) {
							return of(null);
						}

						const isPropertySection =
							this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.PROPERTY;
						if (!isPropertySection) {
							return of(null);
						}

						if (!(this.sharedFlags.isPropertyInLoanServiceability || useAsSecurity)) {
							return of(null);
						}

						const address = propertyPayload.propertyDto.address;
						if (this.isPartialAddress(address)) {
							return of(null);
						}

						return this.requestPropertyFieldsService.loadPropertyInformationDetails(
							address,
							modalField,
							propertyPayload,
							!this.sharedFlags.isPropertyInLoanServiceability
						);
					}),
					switchMap((propertyModalDTO: PropertyModalDTO | null) => {
						if (propertyModalDTO !== null) {
							propertyModalDTO.rentalIncomeList = [];
							propertyModalDTO.homeLoanList = [];
							return this.propertyService.savePropertyData(
								propertyModalDTO,
								[],
								this.sharedFlags.isPropertyInLoanServiceability ? () => this.loadPropertyOnceSavedSuccess() : undefined
							);
						}
						return of(EMPTY);
					}),
					switchMap(() => {
						return propertyPayload.rentalIncomeList.length
							? this.triggerRentalIncomeVerificationIfEnabled()
							: of(EMPTY);
					}),
					tap(() =>
						this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForProperty(propertyPayload.propertyDto.id)
					)
				)
				.subscribe();
		}
	}

	private savePropertyData(
		propertyPayload: PropertyModalDTO,
		modalField: FormlyFieldConfig,
		deleteRentals: RentalDetailsModel[] = [],
		saveRentalIncomes = false,
		saveHomeLoans = false,
		modalRef: CustomNgbModalRef | null = null,
		subscription: Subscription | null = null
	): Observable<IdAndAddressId> {
		return this.propertyService
			.savePropertyData(propertyPayload, deleteRentals, () => this.loadPropertyOnceSavedSuccess(true))
			.pipe(
				tap(() => {
					if (!saveRentalIncomes) {
						const rentalIncomes = getFormField(modalField.fieldGroup, SUB_KEY_RENTAL_INCOME_DETAILS);
						(rentalIncomes?.fieldGroup || []).forEach((rentalField) => formlyDeleteFromImmediateArray(rentalField));
					}
					if (!saveHomeLoans) {
						const homeLoanDetails = getFormField(modalField.fieldGroup, SUB_KEY_HOME_LOAN_DETAILS);
						(homeLoanDetails?.fieldGroup || []).forEach((homeLoanField) =>
							formlyDeleteFromImmediateArray(homeLoanField)
						);
					}

					this.saveLendersMortgageInsurance(modalField);

					this.closePropertyModal(modalField, modalRef, subscription, 'submit');
				})
			);
	}

	private saveLendersMortgageInsurance(modalField: FormlyFieldConfig) {
		if (this.sharedFlags.isPropertyInLoanServiceability) {
			const purchaseDetailsField = getParentFormField(modalField, 'purchaseDetails');
			const feesField = getFormField(purchaseDetailsField?.parent?.fieldGroup, 'fees');
			const lmiField = getFormField(feesField?.fieldGroup, 'lendersMortgageInsurance');
			const lmiModel = lmiField?.model as LendersMortgageInsurance[];
			const modelFromFormField = lmiField?.formControl?.value as {};
			if (modelFromFormField && lmiModel && lmiModel[0] && lmiModel[0].isLmiRequirement === YesNo.Yes) {
				const mergedModel = merge(modelFromFormField, lmiModel[0]);
				this.loanServiceabilityService.saveLendersMortgageInsurance(mergedModel).subscribe((lmiDto) => {
					this.simpFormlyHandlerService.upsertToStateWithData('lendersMortgageInsurance', [
						LendersMortgageInsuranceTransformer.fromPayload(lmiDto)
					]);
				});
			}
		}
	}

	private closePropertyModal(
		modalField: FormlyFieldConfig,
		modalRef: CustomNgbModalRef | null,
		subscription: Subscription | null,
		action?: string
	) {
		modalRef?.close();
		subscription?.unsubscribe();

		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_PROPERTY_ASSET_DETAILS);
		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_RENTAL_INCOME_DETAILS);
		this.expandCollapseSectionInPropertyModal(modalField, SUB_KEY_HOME_LOAN_DETAILS);

		const assetFormControl = modalField?.parent?.parent?.formControl;
		if (this.sharedFlags.isPropertyInLoanServiceability && assetFormControl?.valid) {
			assetFormControl?.markAsPristine();
		}

		this.formValidationsService.triggerValidationByKey('realEstate');

		if (action === 'submit') {
			if (!this.sharedFlags.isPropertyInLoanServiceability) {
				this.sharedFlags.isPropertyModalOpened = false;
			}
			return;
		}

		this.sharedFlags.isPropertyModalOpened = false;

		setTimeout(() => {
			// Timeout will let angular finish form update along with model. Otherwise model won't be updated.
			this.deleteNewEntryOnPropertyModalCancel(modalField);
		}, 400);
	}

	private expandCollapseSectionInPropertyModal(modalField: FormlyFieldConfig, key: string, expand = false) {
		const subSection = getFormField(modalField?.fieldGroup, key);
		if (subSection?.templateOptions) {
			subSection.templateOptions.expanded = expand;
		}
	}

	private validateModal(): void {
		let parentSection = '';
		switch (this.sharedFlags.propertyModalOpenedSection) {
			case PropertyModalOpeningSection.PROPERTY:
				parentSection = this.sharedFlags.isPropertyInLoanServiceability ? STATE_KEY_PROPERTY : STATE_KEY_REAL_ESTATE;
				break;
			case PropertyModalOpeningSection.RENTAL:
				parentSection = STATE_KEY_RENTALS;
				break;
			case PropertyModalOpeningSection.HOME_LOAN:
				parentSection = STATE_KEY_HOME_LOANS;
				break;
		}

		this.formValidationsService.triggerValidationByKeys([
			parentSection,
			SUB_KEY_PROPERTY_ASSET_DETAILS,
			SUB_KEY_RENTAL_INCOME_DETAILS,
			SUB_KEY_HOME_LOAN_DETAILS
		]);
	}

	private isElectronicValuationEnabled(frequency: PropertyEvaluationType | undefined): boolean {
		return (
			frequency === PropertyEvaluationType.RPDataValex ||
			frequency === PropertyEvaluationType.RPDataPropTrack ||
			frequency === PropertyEvaluationType.RPDataOther ||
			frequency === PropertyEvaluationType.ElectronicValuation
		);
	}

	private filterPropertyEvaluationType(option: EnumObject): boolean {
		return (
			option.id !== PropertyEvaluationType.RPDataValex &&
			option.id !== PropertyEvaluationType.RPDataPropTrack &&
			option.id !== PropertyEvaluationType.RPDataOther &&
			option.id !== PropertyEvaluationType.ElectronicValuation
		);
	}

	private isPartialAddress(address: Address): boolean {
		return (
			!address.streetNumber || !address.streetName || !address.suburb || !address.city || !address.australianPostCode
		);
	}

	private handlePropertyEvaluationTypeFilter(field: FormlyFieldConfig, address: Address) {
		if (field.templateOptions && this.isPartialAddress(address)) {
			const listItems = this.formEnumsService
				.fetch('PropertyEvaluationType', true)
				.pipe(map((options: EnumObject[]) => options.filter(this.filterPropertyEvaluationType.bind(this))));
			field.templateOptions.options = listItems;
		}
	}

	private disableEstimatedValueAndValuedDate(
		amountField: FormlyFieldConfig | undefined,
		valuedDate: FormlyFieldConfig | undefined,
		disable = true
	) {
		if (amountField?.templateOptions) {
			amountField.templateOptions.disabled = disable;
		}
		if (valuedDate?.templateOptions) {
			valuedDate.templateOptions.disabled = disable;
		}
	}

	private loadPropertyOnceSavedSuccess(filterProducts = false): void {
		this.sharedFlags.isPropertyInLoanServiceability
			? this.loanServiceabilityService.triggerPropertySaveSuccess(filterProducts)
			: this.propertyService.triggerSavePropertyModalSuccessAction();
	}

	private showHideGuaranteeLimit(modelField: FormlyFieldConfig | undefined) {
		const propertyAssetDetailField = getFormField(modelField?.fieldGroup, 'propertyAssetDetails');
		const enableLimitedGuaranteeField = getFormField(
			propertyAssetDetailField?.fieldGroup,
			'0.enableLimitedGuarantee'
		) as FormlyFieldConfig;

		if (this.doesLendingGuaranteeWithSecurityAndPercentOwnedExist(propertyAssetDetailField)) {
			const propertyAssetDetail = modelField?.model as PropertyModalModel;

			const useAsSecurityFormField = getFormField(
				propertyAssetDetailField?.fieldGroup,
				'0.useAsSecurity'
			) as FormlyFieldConfig;

			if (
				(propertyAssetDetail.transaction === AssetTransaction.Owns ||
					propertyAssetDetail.transaction === AssetTransaction.OwnsExistingMortgage) &&
				useAsSecurityFormField?.formControl?.value === YesNo.Yes
			) {
				enableLimitedGuaranteeField?.formControl?.setValue(true);
			} else {
				enableLimitedGuaranteeField?.formControl?.setValue(false);
			}
		} else {
			enableLimitedGuaranteeField?.formControl?.setValue(false);
		}
	}

	private doesLendingGuaranteeWithSecurityAndPercentOwnedExist(
		propertyAssetDetailField: FormlyFieldConfig | undefined
	): boolean {
		const applicants = this.formDataService.getSetupApplicants();
		return applicants?.some((applicant, index) => {
			if (applicant?.firstName && applicant?.applicantType === ApplicantType.Guarantor) {
				const personGuaranteeIndex = index;
				const lendingGuaranteeList = (
					this.formDataService
						.getState(`applicants-lendingGuarantee-${personGuaranteeIndex}`)
						.getValue() as FormStore<LendingGuaranteeModel>
				).data;
				const guaranteeWithSecurity = lendingGuaranteeList.find(
					(guarantee) =>
						guarantee.lendingGuaranteeTypeId === LendingGuaranteeType.IncomeAndSecurity ||
						guarantee.lendingGuaranteeTypeId === LendingGuaranteeType.SecurityOnly
				);
				const percentOwnedFormField = getFormField(
					propertyAssetDetailField?.fieldGroup,
					'0.percentsOwned'
				) as FormlyFieldConfig;

				const percentOwnedList = percentOwnedFormField?.formControl?.value as PercentageOwned[];

				const guaranteePercentOwned = percentOwnedList?.some(
					(percentOwn) => percentOwn.applicantId === applicant.id && percentOwn.percent && percentOwn.percent > 0
				);

				return !!guaranteeWithSecurity && !!guaranteePercentOwned;
			}
			return false;
		});
	}

	private addNewEntry(modalField: FormlyFieldConfig, path: string): void {
		const field = getFormField(modalField.fieldGroup, path);
		if (field?.templateOptions?.add && typeof field.templateOptions.add === 'function') {
			(field.templateOptions.add as Function)();
		}
	}

	private isPropertyExistForExistingAddresses(addressId?: number): boolean {
		if (!addressId) {
			return false;
		}
		return this.sharedFlags.isPropertyInLoanServiceability
			? this.isPropertyExistInLoanServiceability(addressId, true)
			: this.isPropertyExistInFinancialPosition(addressId, true);
	}

	private isPropertyExistInFinancialPosition(addressId: number, clone = false): boolean {
		if (this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.PROPERTY) {
			return this.isPropertyExistUnderRealEstate(addressId, clone);
		} else if (this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.RENTAL) {
			return this.isPropertyExistUnderRentalIncomes(addressId, clone);
		} else if (this.sharedFlags.propertyModalOpenedSection === PropertyModalOpeningSection.HOME_LOAN) {
			return this.isPropertyExistUnderHomeLoans(addressId, clone);
		}
		return false;
	}

	private isPropertyExistUnderRealEstate(addressId: number, clone = false): boolean {
		const realStates = this.simpFormlyHandlerService.getStateData<RealEstateModel>(STATE_KEY_REAL_ESTATE);
		const property = realStates?.find(
			(realState: RealEstateModel) => realState?.details?.propertyDetails?.address?.id === addressId
		);
		if (property && clone) {
			this.clonePropertyDataInState(STATE_KEY_REAL_ESTATE, property, realStates.length);
		}
		return !!property;
	}

	private isPropertyExistUnderRentalIncomes(addressId: number, clone = false): boolean {
		const rentalIncomes = this.simpFormlyHandlerService.getStateData<RentalModel>(STATE_KEY_RENTALS);
		const rentalIncome = rentalIncomes?.find(
			(rental: RentalModel) => rental?.details?.rentalDetails?.address?.id === addressId
		);
		if (rentalIncome && clone) {
			this.clonePropertyDataInState(STATE_KEY_RENTALS, rentalIncome, rentalIncomes.length);
		}
		return !!rentalIncome;
	}

	private isPropertyExistUnderHomeLoans(addressId: number, clone = false): boolean {
		const homeLoans = this.simpFormlyHandlerService.getStateData<HomeLoanModel>(STATE_KEY_HOME_LOANS);
		const homeLoan = homeLoans?.find(
			(loan: HomeLoanModel) => loan?.details?.homeLoanDetails?.address?.id === addressId
		);
		if (homeLoan && clone) {
			this.clonePropertyDataInState(STATE_KEY_HOME_LOANS, homeLoan, homeLoans.length);
		}
		return !!homeLoan;
	}

	private isPropertyExistInLoanServiceability(addressId: number, clone = false): boolean {
		const properties = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>(STATE_KEY_PROPERTY);
		const propertyAsset = properties?.find(
			(property: PropertyAssetModel) => property?.details?.propertyDetails?.address?.id === addressId
		);
		if (propertyAsset && clone) {
			this.clonePropertyDataInState(STATE_KEY_PROPERTY, propertyAsset, properties.length);
		}
		return !!propertyAsset;
	}

	private clonePropertyDataInState(
		sateKey: string,
		data: RealEstateModel | RentalModel | HomeLoanModel | PropertyAssetModel,
		index: number
	) {
		const clonedData = cloneDeep(data);
		clonedData.isClone = true;
		this.simpFormlyHandlerService.updateToState(sateKey, clonedData, index);
	}

	private getPropertyDetailsInLoanServiceability(
		expression: (property: PropertyAssetModel) => boolean
	): PropertyModalModel | undefined {
		const properties = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>(STATE_KEY_PROPERTY);
		return (properties || []).find(expression)?.details?.propertyDetails;
	}

	private getPropertySecurityDetails(propertyId: number): PropertySecurityDetails | undefined {
		const realStates = this.simpFormlyHandlerService.getStateData<RealEstateModel>(STATE_KEY_REAL_ESTATE);
		return (realStates || []).find((property: RealEstateModel) => property?.id === propertyId)?.securityDetails;
	}

	private isSaveHomeLoans(modalField: FormlyFieldConfig): boolean {
		const homeLoanDetails = getFormField(modalField?.fieldGroup, 'homeLoanDetails');
		return !homeLoanDetails?.hide && (homeLoanDetails?.formControl?.value as unknown[]).length > 0;
	}

	private isSaveRentalIncomes(modalField?: FormlyFieldConfig): boolean {
		const rentalIncomeDetails = getFormField(modalField?.fieldGroup, 'rentalIncomeDetails');
		return !rentalIncomeDetails?.hide && (rentalIncomeDetails?.formControl?.value as unknown[]).length > 0;
	}

	private isAIPProperty(model: PropertyModalModel | undefined): boolean {
		return !!(model && model?.transaction === AssetTransaction.Purchasing && model?.approvalInPrinciple === YesNo.Yes);
	}

	private setValueForShowFullModalFlag(modalField: FormlyFieldConfig, isFullModal = false): void {
		const showFullModalFlag = getFormField(modalField?.fieldGroup, 'showFullModal');
		showFullModalFlag?.formControl?.setValue(isFullModal);
	}

	private setDefaultApprovalInPrincipleValue(modalField?: FormlyFieldConfig): void {
		if (this.sharedFlags.isPropertyInLoanServiceability) {
			let aipValue = YesNo.No;
			const setupDetails = this.formDataService.getStateData<ApplicationDetails>('details');
			if (setupDetails && setupDetails.length > 0) {
				aipValue = setupDetails[0].approvalInPrincipleToggle ?? YesNo.No;
			}

			const aipField = getFormField(modalField?.fieldGroup, 'approvalInPrinciple');
			if (aipField?.templateOptions) {
				aipField.templateOptions.defaultValue = aipValue;
			}
		}
	}

	private deleteProperty = (field: FormlyFieldConfig): void => {
		let hasRentals = false;
		let hasHomeLoans = false;

		const realEstate = field.model as RealEstateModel;
		if (realEstate?.details?.propertyDetails) {
			hasRentals = realEstate.details.propertyDetails.rentalIncomeDetails?.length > 0;
			hasHomeLoans = realEstate.details.propertyDetails.homeLoanDetails?.length > 0;
		}

		let messageKeyConfirmation = '';
		let messageKeyToast = '';

		if (hasRentals && hasHomeLoans) {
			messageKeyConfirmation = 'rentalAndHomeLoanLabel';
			messageKeyToast = 'toastRentalAndHomeLoanLabel';
		} else if (hasRentals) {
			messageKeyConfirmation = 'rentalLabel';
			messageKeyToast = 'toastRentalLabel';
		} else if (hasHomeLoans) {
			messageKeyConfirmation = 'homeLoanLabel';
			messageKeyToast = 'toastHomeLoanLabel';
		} else {
			messageKeyConfirmation = 'propertyLabel';
			messageKeyToast = 'toastPropertyLabel';
		}

		const labelFields = getFormField(field.parent?.fieldGroup, `deleteConfirmationLabels`) as FormlyFieldConfig;

		const deleteMessage = labelFields.fieldGroup?.find((group) => group?.key === messageKeyConfirmation)
			?.template as string;
		const toastMessage = labelFields.fieldGroup?.find((group) => group?.key === messageKeyToast)?.templateOptions
			?.label as string;

		this.confirmationDialogService
			.deleteConfirmation('Delete property?', deleteMessage, 'Delete', 'Cancel')
			.subscribe((result) => {
				if (result) {
					this.formArrayDeleteService.deleteItem(field, 'Property', STATE_KEY_REAL_ESTATE).subscribe(() => {
						this.addressService.fetchAllAddress();
						this.propertyService.syncPropertiesAndSecurities();
						this.toastr.success(toastMessage);
					});
				}
			});
	};

	private deleteRentalInFinancialPosition = (field: FormlyFieldConfig): void => {
		let id = get(field, 'model.id') as number;
		let rentalIncome = field.parent?.model as RentalDetailsModel;
		if (!rentalIncome.realEstateAssetId) {
			rentalIncome = (field.model as RentalModel)?.details?.detailsModel;
			id = rentalIncome?.id as number;
		}

		this.formArrayDeleteService
			.deleteItem(field, 'RentalIncome', STATE_KEY_RENTALS, !!rentalIncome?.isFuture)
			.subscribe(() => {
				if (rentalIncome?.id !== CONSTANTS.NEW_ID && rentalIncome?.id !== undefined) {
					this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForProperty(
						rentalIncome?.realEstateAssetId,
						true
					);
					this.removeRentalOrHomeLoanFromFinancialPositionState(id, true);
					this.toastr.success(deletedSuccessfullyMessage('Rental'));
				}
			});
	};

	private deleteHomeLoanInFinancialPosition = (field: FormlyFieldConfig): void => {
		const id = get(field, 'model.id') as number;
		this.formArrayDeleteService.deleteItem(field, 'HomeLoan', STATE_KEY_HOME_LOANS).subscribe(() => {
			this.removeRentalOrHomeLoanFromFinancialPositionState(id, false);
			this.toastr.success(deletedSuccessfullyMessage('Home loan'));
			this.propertyService.syncPropertiesAndSecurities();
		});
	};

	private removeRentalOrHomeLoanFromFinancialPositionState(id: number, isRental: boolean): void {
		if (id > 0) {
			const propertyData = this.simpFormlyHandlerService.getStateData<RealEstateModel>(STATE_KEY_REAL_ESTATE) || [];
			if (propertyData?.length > 0) {
				const properties = cloneDeep(propertyData);
				properties.forEach((property) => {
					const index = (
						isRental
							? property.details.propertyDetails.rentalIncomeDetails
							: property.details.propertyDetails.homeLoanDetails
					)?.findIndex((rental) => rental.id === id);
					if (index > -1) {
						if (isRental) {
							const grossAmount = property.details.propertyDetails.rentalIncomeDetails[index].grossIncomeAmount?.amount;
							const netAmount = property.details.propertyDetails.rentalIncomeDetails[index].netIncomeAmount?.amount;
							const header = this.simpFormlyHandlerService.getHeaderData<RentalDetailsModel, IncomeHeaderSummary>(
								'rentals'
							);
							this.updateHeaderAfterDelete(header, grossAmount ?? 0, netAmount ?? 0, STATE_KEY_RENTALS);
						}
						(isRental
							? property.details.propertyDetails.rentalIncomeDetails
							: property.details.propertyDetails.homeLoanDetails
						).splice(index, 1);
					}
				});

				this.simpFormlyHandlerService.upsertToStateWithData(STATE_KEY_REAL_ESTATE, properties);
			}

			const rentalData = this.simpFormlyHandlerService.getStateData<RentalModel>(STATE_KEY_RENTALS);
			if (rentalData?.length > 0) {
				const rentals = cloneDeep(rentalData);
				rentals.forEach((rental) => {
					const index = (
						isRental ? rental.details.rentalDetails.rentalIncomeDetails : rental.details.rentalDetails.homeLoanDetails
					)?.findIndex((item) => item.id === id);
					if (index > -1) {
						(isRental
							? rental.details.rentalDetails.rentalIncomeDetails
							: rental.details.rentalDetails.homeLoanDetails
						).splice(index, 1);
					}
				});
				this.simpFormlyHandlerService.upsertToStateWithData(STATE_KEY_RENTALS, rentals);
			}

			const homeLoanData = this.simpFormlyHandlerService.getStateData<HomeLoanModel>(STATE_KEY_HOME_LOANS);
			if (homeLoanData?.length > 0) {
				const homeLoans = cloneDeep(homeLoanData);
				homeLoans.forEach((homeLoan) => {
					const index = (
						isRental
							? homeLoan.details.homeLoanDetails.rentalIncomeDetails
							: homeLoan.details.homeLoanDetails.homeLoanDetails
					)?.findIndex((item) => item.id === id);
					if (index > -1) {
						(isRental
							? homeLoan.details.homeLoanDetails.rentalIncomeDetails
							: homeLoan.details.homeLoanDetails.homeLoanDetails
						).splice(index, 1);
					}
				});
				this.simpFormlyHandlerService.upsertToStateWithData(STATE_KEY_HOME_LOANS, homeLoans);
			}
		}
	}

	private updateHeaderAfterDelete(header: IncomeHeaderSummary, grossAmount: number, netAmount: number, state: string) {
		const newHeader = {
			totalNetAmount: header?.totalNetAmount ? header?.totalNetAmount - netAmount : 0,
			totalGrossAmount: header.totalGrossAmount ? header.totalGrossAmount - grossAmount : 0
		} as IncomeHeaderSummary;
		this.simpFormlyHandlerService.upsertToSubSectionHeader(state, newHeader);
	}

	private deleteRentalInLoanServiceability = (field: FormlyFieldConfig): void => {
		const id = get(field, 'model.id') as number;
		const propertyId = get(getParentFormField(field, 'propertyDetails'), 'model.propertyId') as number;
		const rentalIncome = field.parent?.model as RentalDetailsModel;
		this.formArrayDeleteService.deleteItem(field, 'RentalIncome', undefined, !!rentalIncome.isFuture).subscribe(() => {
			if (rentalIncome.id !== CONSTANTS.NEW_ID && rentalIncome.id !== undefined) {
				this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForProperty(propertyId, true);
			}
			this.removeRentalOrHomeLoanFromLoanServiceabilityState(propertyId, id, true);
			this.toastr.success(deletedSuccessfullyMessage('Rental'));
		});
	};

	private deleteHomeLoanInLoanServiceability = (field: FormlyFieldConfig): void => {
		const id = get(field, 'model.id') as number;
		const propertyId = get(getParentFormField(field, 'propertyDetails'), 'model.propertyId') as number;
		this.formArrayDeleteService.deleteItem(field, 'HomeLoan').subscribe(() => {
			this.removeRentalOrHomeLoanFromLoanServiceabilityState(propertyId, id, false);
			this.toastr.success(deletedSuccessfullyMessage('Home loan'));
		});
	};

	private removeRentalOrHomeLoanFromLoanServiceabilityState(propertyId: number, id: number, isRental: boolean): void {
		if (propertyId > 0 && id > 0) {
			const propertyData = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>(STATE_KEY_PROPERTY) || [];
			if (propertyData?.length > 0) {
				const propertyIndex = propertyData.findIndex((asset) => asset.id === propertyId) ?? -1;
				const property = propertyIndex > -1 ? cloneDeep(propertyData[propertyIndex]) : undefined;
				if (property?.details) {
					const index =
						(isRental
							? property.details.propertyDetails.rentalIncomeDetails
							: property.details.propertyDetails.homeLoanDetails
						)?.findIndex((rental) => rental.id === id) ?? -1;
					if (index > -1) {
						(isRental
							? property.details.propertyDetails.rentalIncomeDetails
							: property.details.propertyDetails.homeLoanDetails
						).splice(index, 1);
					}
					this.simpFormlyHandlerService.updateToState(STATE_KEY_PROPERTY, property, propertyIndex);
				}
			}
		}
	}

	private deleteNewEntryOnPropertyModalCancel(modalField: FormlyFieldConfig) {
		const subSectionField = modalField.parent?.parent;
		const id = get(subSectionField, 'model.id') as number;
		if (!id || id === CONSTANTS.NEW_ID) {
			this.deleteFormlyEntry(subSectionField);
		} else {
			const stateKey = this.getStateKeyOfModalOpenedSection();
			const data =
				this.simpFormlyHandlerService.getStateData<RealEstateModel | RentalModel | HomeLoanModel | PropertyAssetModel>(
					stateKey
				) || [];
			const index = data.findIndex((item) => item?.isClone);
			if (index > -1) {
				this.deleteFormlyEntry(subSectionField);
				this.simpFormlyHandlerService.deleteFromState(stateKey, index);
			}
		}
	}

	private deleteFormlyEntry(subSectionField: FormlyFieldConfig | undefined): void {
		const deleteField = getFormField(subSectionField?.fieldGroup, 'delete');
		if (deleteField) {
			formlyDeleteFromArray(deleteField);
		}
	}

	private getStateKeyOfModalOpenedSection(): string {
		switch (this.sharedFlags.propertyModalOpenedSection) {
			case PropertyModalOpeningSection.RENTAL:
				return STATE_KEY_RENTALS;
			case PropertyModalOpeningSection.HOME_LOAN:
				return STATE_KEY_HOME_LOANS;
			default:
				return this.sharedFlags.isPropertyInLoanServiceability ? STATE_KEY_PROPERTY : STATE_KEY_REAL_ESTATE;
		}
	}

	private handlePropertyValuation(
		modalField: FormlyFieldConfig,
		addressValue: Address,
		propertyFieldName: string,
		estimatedValueFieldName: string
	) {
		const propertyAssetDetailsField = getFormField(modalField?.fieldGroup, propertyFieldName);
		const estimatedValue = getFormField(propertyAssetDetailsField?.fieldGroup, `0.${estimatedValueFieldName}`);

		const estimatedValuePendingText = getFormField(
			propertyAssetDetailsField?.fieldGroup,
			'0.estimatedValuePendingText'
		);

		if (estimatedValue?.templateOptions) {
			estimatedValue.templateOptions.subText = estimatedValuePendingText?.template as string;
			estimatedValue.templateOptions.subTextSpinner = true;
			estimatedValue.templateOptions.disabled = true;
		}
		const valuedDateField = getFormField(propertyAssetDetailsField?.fieldGroup, '0.estimatedValueValuedDate');
		const amountField = getFormField(estimatedValue?.fieldGroup, 'amount');
		this.disableEstimatedValueAndValuedDate(amountField, valuedDateField);

		const doneField = getFormField(modalField?.fieldGroup, 'done');
		if (doneField && doneField.templateOptions) {
			doneField.templateOptions.disabled = true;
		}
		const valuationModel: PropertyValuationModel = {
			applicationId: this.applicationDataQuery.applicationId(),
			address: addressValue
		};

		this.propertyValuationService
			.submitAndGetPropertyValuation(PropertyValuationModelTransformer.toPayload(valuationModel))
			.pipe(
				finalize(() => {
					if (doneField && doneField.templateOptions) {
						doneField.templateOptions.disabled = false;
					}
				})
			)
			.subscribe({
				next: (data: PropertyValuationInfoDTO) => {
					this.resetSubTextSpinner(estimatedValue);
					amountField?.formControl?.setValue(data.estimatedValue, { onlySelf: true });
					valuedDateField?.formControl?.setValue(data.estimationDate);
					this.disableEstimatedValueAndValuedDate(amountField, valuedDateField);
				},
				error: () => {
					estimatedValue?.formControl?.setValue({
						amount: null,
						frequency: PropertyEvaluationType.ApplicantEstimate
					});
					this.resetSubTextSpinner(estimatedValue);
				}
			});
	}

	private resetSubTextSpinner(formField: FormlyFieldConfig | undefined) {
		if (formField?.templateOptions) {
			formField.templateOptions.subText = '';
			formField.templateOptions.subTextSpinner = false;
			formField.templateOptions.disabled = false;
		}
	}

	private triggerRentalIncomeVerificationIfEnabled(): Observable<DigitalWidgetExecuteResponse> | Observable<never> {
		const widget = this.digitalWidgetsConfigurationRepository.getDigitalWidget(
			DigitalServiceType.RentalIncomeVerification
		);
		if (widget) {
			if (widget.triggerType === DigitalWidgetTriggerType.Manual) {
				return this.digitalWidgetsConfigurationRepository.triggerAction(
					DigitalServiceType.RentalIncomeVerification,
					{}
				);
			} else {
				return this.digitalWidgetsConfigurationRepository.triggerAutoWidgets();
			}
		} else {
			return of();
		}
	}
}
