import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersonAddresses } from '@app/modules/applicants/typings/applicants';
import { RealEstateModel } from '@app/modules/financial-position/model/property.model';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { Store } from '@ngneat/elf';
import { cloneDeep, isEqual } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const ignorePrefixFor = ['setup'];

@Injectable({ providedIn: 'root' })
export class SimpFormlyHandlerService {
	constructor(
		private formDataService: FormDataService,
		private router: Router,
		private formValidationsService: FormValidationsService
	) {}

	/**
	 * generates unique keys, resolves the passed observable and set to state
	 * @param key
	 * @param data$
	 * @param priority
	 */
	upsertToState(key: string, data$: Observable<unknown[] | unknown>, priority = 3): void {
		const uniqueKey = this.getKey(key);

		this.formDataService.upsertStateWithAsyncData(uniqueKey, data$, priority);
	}

	/**
	 * generates unique keys, resolves the passed observable and set to header of a subsection
	 * @param key
	 * @param header
	 */
	upsertToSubSectionHeader(key: string, header: unknown): void {
		const uniqueKey = this.getKey(key);

		this.formDataService.upsertHeader(uniqueKey, header);
	}

	/**
	 * Generate an unique key, synchronous data will be set to state
	 * @param key
	 * @param data
	 */
	upsertToStateWithData(key: string, data: unknown[]): void {
		const uniqueKey = this.getKey(key);
		this.formDataService.upsertData(uniqueKey, data);
	}

	/**
	 * full data will be set to state
	 * @param key
	 * @param data
	 */
	upsertToFullPathWithData(uniqueKey: string, data: unknown | unknown[]): void {
		this.formDataService.upsertData(uniqueKey, data);
	}

	/**
	 * generates unique keys, resolves the passed observable and set to state
	 * @param key
	 * @param data
	 * @param index
	 * @param patch
	 */
	updateToState(key: string, data: unknown, index: number, patch?: unknown): void {
		const uniqueKey = this.getKey(key);
		this.formDataService.update(uniqueKey, data, index, patch);

		this.formValidationsService.updateDraft(uniqueKey, false);
	}

	updateToFullStatePath(key: string, data: unknown, index: number): void {
		this.formDataService.update(key, data, index);
	}

	updateDraftFlag(key: string, isDraft: boolean): void {
		const uniqueKey = this.getKey(key);
		this.formValidationsService.updateDraft(uniqueKey, isDraft);
	}

	/**
	 * This method generates the unique key for the subsection store and updates the placeholder template
	 * @param key - subsection key
	 * @param placeholder
	 */
	updateSubsectionPlaceholder(key: string, placeholder: string): void {
		const uniqueKey = this.getKey(key);
		this.formDataService.setPlaceholderTemplate(uniqueKey, placeholder);
	}

	/**
	 * generates unique keys, resolves the passed observable and set to state
	 * @param key
	 * @param index
	 */
	deleteFromState(key: string, index: number): void {
		const uniqueKey = this.getKey(key);
		this.formDataService.delete(uniqueKey, index);
	}

	deleteFromFullStatePath(key: string, index: number): void {
		this.formDataService.delete(key, index);
	}

	/**
	 * generates unique key and map to elf state
	 * @param key
	 */
	mapToStateData<T = unknown[]>(key: string): Observable<T> {
		const uniqueKey = this.getKey(key);
		return this.formDataService.select$(uniqueKey)?.pipe(
			distinctUntilChanged((prev: unknown, current: unknown) => isEqual(prev, current)),
			map((data: unknown) => cloneDeep(data))
		) as Observable<T>;
	}

	mapToSubSectionHeader<T = unknown[]>(key: string): Observable<T> {
		const uniqueKey = this.getKey(key);
		return this.formDataService
			.selectSubSectionHeader$(uniqueKey)
			?.pipe(map((data: unknown) => cloneDeep(data))) as Observable<T>;
	}

	mapToSectionHeader<T = unknown[]>(key: string): Observable<T> {
		const uniqueKey = this.getSectionKey(key);
		return this.formDataService
			.selectSectionHeader$(uniqueKey)
			?.pipe(map((data: unknown) => cloneDeep(data))) as Observable<T>;
	}

	/**
	 * Partial data set after the save API calls, eg: ID, addressId etc
	 * @param key
	 * @returns
	 */
	mapToPatchData<T = unknown>(key: string): Observable<T[] | undefined> {
		const uniqueKey = this.getKey(key);
		return this.formDataService.selectPatch$<T>(uniqueKey)?.pipe(map((data: T[] | undefined) => cloneDeep(data)));
	}

	/**
	 * map to elf state by using key as it is
	 * @param key
	 */
	mapToFullStateDataPath<T = unknown[]>(key: string): Observable<T> | null {
		return this.formDataService.select$(key)?.pipe(map((data: unknown) => cloneDeep(data))) as Observable<T>;
	}

	getStateData<T = unknown>(key: string): T[] {
		const uniqueKey = this.getKey(key);
		return this.formDataService.getStateData<T>(uniqueKey);
	}

	getStateDataFullPath<T = unknown>(key: string): T[] {
		return this.formDataService.getStateData<T>(key);
	}

	getHeaderData<T, V>(key: string): V {
		const uniqueKey = this.getKey(key);
		return this.formDataService.getHeaderData<T, V>(uniqueKey);
	}

	mapToLoading(key: string): Observable<boolean> | null {
		const uniqueKey = this.getKey(key);
		return this.formDataService.selectLoading$(uniqueKey) || of(false);
	}

	mapToPlaceHolderTemplate(key: string): Observable<string | undefined> {
		const uniqueKey = this.getKey(key);
		return this.formDataService.selectPlaceholder$(uniqueKey);
	}

	getPropertyAddresses(): RealEstateModel[] {
		return this.formDataService.getPropertyAddresses();
	}

	getApplicantAddresses(index: number): PersonAddresses {
		return this.formDataService.getApplicantAddresses(index);
	}

	getKey(name: string): string {
		let keyPrefix = this.router.url.replace(/\//g, '').replace(/\?/g, '-').replace('=', '-');
		const cleanedRefiUrl = CONSTANTS.REFI_PATH.replace(/\//g, '');
		const cleanedPurchaseUrl = CONSTANTS.PURCHASE_PATH.replace(/\//g, '');
		// if key is used in route (eg for loanappX, refi, purchase)
		keyPrefix = keyPrefix.replace(cleanedRefiUrl, 'refi-').replace(name, '');
		keyPrefix = keyPrefix.replace(cleanedPurchaseUrl, 'refi-').replace(name, '');

		return ignorePrefixFor.includes(keyPrefix) ? name : `${keyPrefix}-${name}`;
	}

	getSectionKey(name: string): string {
		const keyPrefix = this.router.url.replace(/\//g, '').replace(/\?/g, '-').replace('=', '-');
		return ignorePrefixFor.includes(keyPrefix) ? `section-${name}` : `${keyPrefix}-section-${name}`;
	}

	upsertToSectionHeader(key: string, header: unknown): void {
		const uniqueKey = this.getSectionKey(key);
		this.formDataService.upsertHeader(uniqueKey, header);
	}

	getState<T>(key: string): Store {
		const uniqueKey = this.getKey(key);
		return this.formDataService.getState<T>(uniqueKey);
	}
}
