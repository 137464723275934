import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AppService } from '@app/app.service';
import { setupToPersonApplicant } from '@app/modules/applicants/models/applicant.model';
import { ApplicantsService } from '@app/modules/applicants/services/applicants.service';
import { CompanyApplicantsService } from '@app/modules/applicants/services/company/company-applicants.service';
import { TrustApplicantsService } from '@app/modules/applicants/services/trust/trust-applicants.service';
import { PersonApplicant } from '@app/modules/applicants/typings/applicants';
import { LinkedApplicationDetailsService } from '@app/modules/setup/services/linked-application-details.service';
import { ManageParticipantsService } from '@app/modules/setup/services/manage-participants.service';
import { SetupService } from '@app/modules/setup/services/setup.service';
import {
	LinkedApplicationDetails,
	ManagementParticipant,
	ManagementParticipantAPI,
	ManagementParticipantLabel,
	Setup,
	SetUpApplicant,
	SetupApplication
} from '@app/modules/setup/typings/setup';
import {
	ApplicantRole,
	ExternalAction,
	ExternalActionStatus,
	LendingGuaranteeType
} from '@app/modules/shared/enums/app.enums';
import { FormArrayDeleteService } from '@app/modules/shared/service/form-array-delete.service';
import { ManagementParticipantsTransformerService } from '@app/modules/shared/transformers/management-participants-transformer.service';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnChange,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent, FormlyUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService, SimpSpinnerService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get, isEmpty, uniqBy } from 'lodash-es';
import { Observable, of, take } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { URL_CONSTANTS } from '../constants/api.constants';

import { HouseholdService } from '@app/modules/applicants/services/household.service';
import { CustomerSearchTransformerService } from '@app/modules/customer-search/services/customer-search-transformer.service';
import {
	LoanWriterCompany,
	LoanWriterCompanyDto,
	LoanWriterCompanyModel,
	LoanWriterCompanyTransformer
} from '@app/modules/setup/model/loan-writer-company.model';

import { CustomerSearchModalResponse } from '@app/modules/customer-search/components/customer-search/customer-search.component';
import { DocumentInstructionsTransformerService } from '../../setup/services/document-instructions-transformer.service';
import { CONSTANTS } from '../constants/constants';
import { ApplicantEntityType } from '../enums/app.enums';
import { applicantRoleToApplicantPrimary, applicantRoleToApplicantType } from '../helper/util';
import { AddressService } from '../service/address.service';
import { BaseJourneyService } from '../service/base-journey.service';
import { PersonsCompaniesEnumService } from '../service/persons-companies-enum.service';
import { RemoteValidationService } from '../service/remote-validation.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { ChannelSettingQuery } from '../store/channel-setting/channel-setting.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { ApplicantType } from '../store/form-enums/form-enums.store';
import { FormStateQuery } from '../store/form-state/form-state.query';
import { FormStateService } from '../store/form-state/form-state.service';
import { FormValidationsQuery } from '../store/form-validations/form-validations.query';
import { FormValidationsService } from '../store/form-validations/form-validations.service';
import { ValidationsErrorDetailModel } from '../typings/validations';
import { getParentFormField } from './../../simp-formly/helpers/simp-formly.helper';

@Injectable({ providedIn: 'root' })
export class SetupTransformerService extends BaseJourneyService<any> {
	constructor(
		private applicantsService: ApplicantsService,
		private manageParticipantsService: ManageParticipantsService,
		private formEnumsQuery: FormEnumsQuery,
		private formStateQuery: FormStateQuery,
		private setupService: SetupService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private simpFormlyModalService: SimpFormlyModalService,
		private companyApplicantsService: CompanyApplicantsService,
		private trustApplicantsService: TrustApplicantsService,
		private channelSettingQuery: ChannelSettingQuery,
		private formDataService: FormDataService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private managementParticipantsTransformerService: ManagementParticipantsTransformerService,
		private documentInstructionsTransformerService: DocumentInstructionsTransformerService,
		private formArrayDeleteService: FormArrayDeleteService,
		private linkedApplicationDetailsService: LinkedApplicationDetailsService,
		private appService: AppService,
		private formValidationsQuery: FormValidationsQuery,
		private remoteValidationService: RemoteValidationService,
		private householdService: HouseholdService,
		private formValidationsService: FormValidationsService,
		private formStateService: FormStateService,
		private formEnumService: FormEnumsService,
		private applicationDataQuery: ApplicationDataQuery,
		private customerSearchTransformerService: CustomerSearchTransformerService,
		private addressService: AddressService,
		private simpSpinnerService: SimpSpinnerService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.APPLICATION);
	}

	transform(formFields: FormlyFieldConfig[] | undefined): FormlyFieldConfig[] | undefined {
		this.setDefaultNotificationConsent(formFields);

		formlyOnStatusChangedToValid(formFields, 'application', (field, model: FormlyUpdateEvent<SetupApplication>) => {
			if (this.isOnlyLoanWriterCompanyFieldChanged(model)) {
				return;
			}

			this.saveApplication(field).subscribe();
		});

		const applicantsSubSection = getFormField(formFields, 'manageParticipants.applicants');
		const applicantsSubSectionHeaderField = applicantsSubSection?.props?.header as FormlyFieldConfig;

		formlyRegisterHooks(formFields, 'manageParticipants.applicants', {
			onInit: (field) => {
				if (field) {
					if (applicantsSubSectionHeaderField?.props) {
						applicantsSubSectionHeaderField.props.parentField = field;
					}
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, 'manageParticipants.applicants', {
			atLeastOnePrimary: {
				expression: (c: UntypedFormControl) => {
					const applicantSectionValue = (c.value || []) as PersonApplicant[];
					const noOfPrimaryApplicants = applicantSectionValue.filter(
						(a) => a.applicantRole == ApplicantRole.PrimaryBorrower
					).length;
					return noOfPrimaryApplicants > 0;
				}
			},
			maxOnePrimary: {
				expression: (c: UntypedFormControl) => {
					const applicantSectionValue = (c.value || []) as PersonApplicant[];
					const noOfPrimaryApplicants = applicantSectionValue.filter(
						(a) => a.applicantRole == ApplicantRole.PrimaryBorrower
					).length;
					return noOfPrimaryApplicants > 0 && noOfPrimaryApplicants < 2;
				}
			},
			invalid: {
				expression: () => {
					const applicants = this.simpFormlyHandlerService.getStateData<SetUpApplicant>('applicants');
					const isFailedApplicantExist =
						applicants?.some(
							(applicant) =>
								applicant.externalAction === ExternalAction.PulledFromExternal &&
								applicant.externalActionStatus === ExternalActionStatus.Failed
						) ?? false;
					return !isFailedApplicantExist;
				}
			}
		});

		formlyExtendExpressionProperties(formFields, 'manageParticipants.applicants.applicantRole', {
			'templateOptions.label': (model: SetUpApplicant) => {
				return model
					? `${
							model.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant
								? 'Company'
								: model.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant
								? 'Trust'
								: 'Person'
					  } - Type`
					: '';
			}
		});

		formlyRegisterHooks(formFields, 'manageParticipants.applicants.applicantRole', {
			onInit: (field) => {
				if (!field?.parent?.parent?.props?.isCustomerSearchFlow) {
					const allowCompanyApplicants = this.channelSettingQuery.getAllowCompanyApplicants();
					const allowTrustApplicants = this.channelSettingQuery.getAllowTrustApplicants();
					if (!allowCompanyApplicants && !allowTrustApplicants) {
						const applicantType = getFormField(field?.parent?.fieldGroup, 'applicantTypeModal.type');
						if (applicantType?.props) {
							applicantType.props.required = false;
							applicantType.props.defaultValue = ApplicantEntityType.PersonApplicant;
							applicantType.formControl?.setValue(ApplicantEntityType.PersonApplicant);
						}
					}
					if (field && field.formControl?.value === undefined) {
						if (allowCompanyApplicants || allowTrustApplicants) {
							setTimeout(() => {
								this.openApplicantTypeModal(field, allowCompanyApplicants, allowTrustApplicants);
							});
						} else {
							this.setApplicantRole(field);
						}
					}
				}

				if (field) {
					this.getApplicantTypeSpecificOptions(field);
					return field.formControl?.valueChanges.pipe(
						filter((val) => !!val),
						tap((value) => {
							const applicant = field.model as SetUpApplicant;
							applicant.primaryApplicant = value === ApplicantRole.PrimaryBorrower;
							applicant.applicantType =
								value === ApplicantRole.Guarantor ? ApplicantType.Guarantor : ApplicantType.Borrower;
						})
					);
				}
				return of();
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			'manageParticipants.applicants',
			(field, event: FormlyArrayUpdateEvent<SetUpApplicant>) => {
				const setupApplicants = this.formDataService.getSetupApplicants();
				const model = event.data;
				const dataModel = cloneDeep(get(field, `model[${event.index}]`)) as SetUpApplicant;
				model.receivesAllNotification = dataModel.receivesAllNotification;

				if (model.applicantTypeModal?.type == ApplicantEntityType.PersonApplicant) {
					if (!model.lastName) {
						model.lastName = setupApplicants.find((x) => x.id === model.id)?.lastName ?? '';
					}
					this.savePersonApplicant(model, event.index!);
				} else if (model.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant) {
					this.companyApplicantsService.saveCompanyApplicant(model).subscribe((id) => {
						model.id = id;
						model.primaryApplicant = applicantRoleToApplicantPrimary(
							model.applicantRole || ApplicantRole.PrimaryBorrower
						);
						model.applicantType = applicantRoleToApplicantType(model.applicantRole || ApplicantRole.PrimaryBorrower);
						this.personsCompaniesEnumService.fetchCompanies().subscribe();
						this.updateApplicant(model, event.index!);
					});
				} else if (model.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant) {
					this.trustApplicantsService.saveTrustApplicant(model).subscribe((id) => {
						model.id = id;
						model.primaryApplicant = applicantRoleToApplicantPrimary(
							model.applicantRole || ApplicantRole.PrimaryBorrower
						);
						model.applicantType = applicantRoleToApplicantType(model.applicantRole || ApplicantRole.PrimaryBorrower);
						this.personsCompaniesEnumService.fetchTrusts().subscribe();
						this.updateApplicant(model, event.index!);
					});
				}
			}
		);

		const errorTitlefieldConfig = getFormField(
			formFields,
			'manageParticipants.applicants.applicantImportFailedError.titleAndDescription.errorTitle'
		);

		formlyExtendExpressionProperties(
			formFields as FormlyFieldConfig[],
			`manageParticipants.applicants.applicantImportFailedError.titleAndDescription.errorTitle`,
			{
				template: (model: SetUpApplicant, formState: unknown, field: FormlyFieldConfig) => {
					if (errorTitlefieldConfig && errorTitlefieldConfig.template) {
						model = field.parent?.parent?.parent?.model as SetUpApplicant;
						let templateText = errorTitlefieldConfig.template;
						templateText = templateText?.replace(
							'${ApplicantFullName}',
							`${model?.firstName ?? ''} ${model?.lastName ?? ''}`.trim()
						);
						return templateText;
					} else {
						return field.template;
					}
				}
			}
		);

		formlyExtendExpressionProperties(formFields, `manageParticipants.applicants`, {
			'props.hideHeader': (model: SetUpApplicant[]) => {
				// header should be hidden while the sub-section spinner or placeholder showing
				const isApplicantSubSectionLoading = this.formDataService.getStateLoading('applicants');
				const placeholder = this.formDataService.getStatePlaceholder('applicants');
				if (isApplicantSubSectionLoading || !!placeholder) return true;

				return model.some((app) => !app?.id);
			}
		});

		this.handleAccreditedLoanWriter(formFields);
		this.handleApplicantsHeaderActions(applicantsSubSectionHeaderField);
		this.handleApplicantDeleteButtonClick(formFields);
		this.handleFailedApplicant(formFields);
		this.handleIgnoreApplicantError(formFields);
		this.handleManagementDeleteButtonClick(formFields);
		this.handlePersonIdChange(formFields);
		this.handleParticipantTypeChange(formFields);
		this.handleBranchClick(formFields);
		this.handleLinkedApplication(formFields);
		this.handleLoanWriterCompany(formFields);
		this.handleAggregatorCompany(formFields);
		this.handleBusinessChannel(formFields);

		this.managementParticipantsTransformerService.transform(formFields!);
		this.documentInstructionsTransformerService.transform(formFields!);

		return formFields;
	}

	private isOnlyLoanWriterCompanyFieldChanged(model: FormlyUpdateEvent<SetupApplication>) {
		const previousLoanWriterCompany = model?.previousData?.details?.[0].loanWriterCompany;
		const currentLoanWriterCompany = model?.data?.details?.[0].loanWriterCompany;

		// Check if loanWriterCompany field is present in both previous and current data
		if (previousLoanWriterCompany && currentLoanWriterCompany) {
			const previousLoanWriterCompanyName = previousLoanWriterCompany.loanWriterCompanyModal?.companyName;
			const currentLoanWriterCompanyName = currentLoanWriterCompany.loanWriterCompanyModal?.companyName;

			// Check if only the loanWriterCompany field has changed
			if (
				previousLoanWriterCompanyName !== currentLoanWriterCompanyName &&
				Object.keys(previousLoanWriterCompany).length === 1 &&
				Object.keys(currentLoanWriterCompany).length === 1
			) {
				return true;
			}
		}

		return false;
	}

	private handleAccreditedLoanWriter(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnChange(formFields, 'application.details.accreditedLoanWriterId', (field: FormlyFieldConfig) => {
			const applicationFormField = getParentFormField(field, 'application');
			if (!applicationFormField) {
				return;
			}

			const applicationModel = applicationFormField.form?.getRawValue() as Setup;
			const accreditedLoanWriterId = applicationModel.application.details[0].accreditedLoanWriterId;

			if (accreditedLoanWriterId) {
				this.setupService
					.getLoanWriterCompanies(accreditedLoanWriterId)
					.pipe(
						tap((loanWriterCompanies) =>
							this.simpFormlyHandlerService.upsertToStateWithData('loanWriterCompanies', loanWriterCompanies)
						)
					)
					.subscribe();
			}

			this.manageParticipantsService.saveManagementParticipantAdditionalInfo(accreditedLoanWriterId).subscribe();

			this.saveApplication(applicationFormField)
				.pipe(switchMap(() => this.managementParticipantsTransformerService.fetchManagementParticipantsList()))
				.subscribe();
		});
	}

	private handleApplicantsHeaderActions(headerField: FormlyFieldConfig) {
		if (headerField?.fieldGroup) {
			formlyOnClick(headerField.fieldGroup, 'addApplicantBtn', () => {
				const applicantsSubSection = headerField?.props?.parentField as FormlyFieldConfig;
				this.addUnsavedApplicantRecord(applicantsSubSection, false);
			});

			formlyOnClick(headerField.fieldGroup, 'searchApplicantBtn', () => {
				const applicantsSubSection = headerField?.props?.parentField as FormlyFieldConfig;
				const index = applicantsSubSection?.fieldGroup?.length ?? 0;
				this.addUnsavedApplicantRecord(applicantsSubSection, true);
				setTimeout(() => this.handleCustomerSearch(applicantsSubSection, index), 10);
			});
		}
	}

	private saveApplication(field: FormlyFieldConfig): Observable<LinkedApplicationDetails[]> {
		const applicationModel = field.form?.getRawValue() as Setup;
		const applicationDetail = applicationModel.application.details[0];
		applicationDetail.preCheck = this.setupService.getPreCheckStatus();
		return this.setupService
			.updateApplication(applicationModel.application, this.applicationDataQuery.applicationId())
			.pipe(
				switchMap((response) => {
					this.formStateService.updateFormState({
						variations: response.variationIds || []
					});
					return this.linkedApplicationDetailsService.getLinkedApplications().pipe(
						tap((newLinkedApplications) => {
							if (newLinkedApplications) {
								applicationDetail.linkedApplicationDetailRecords = newLinkedApplications;
							}
						})
					);
				}),
				tap(() => {
					const configurationData = applicationModel?.application?.configuration?.length
						? applicationModel?.application.configuration[0]
						: null;
					this.simpFormlyHandlerService.updateToState('details', applicationDetail, 0, {
						preCheck: applicationDetail.preCheck,
						linkedApplicationDetailRecords: applicationDetail.linkedApplicationDetailRecords
					});
					this.simpFormlyHandlerService.updateToState('configuration', configurationData, 0);
					this.appService.applicationTitleInfoChange.next();
				})
			);
	}

	private saveLendingGuarantee(model: SetUpApplicant): Observable<void> {
		let lendingGuaranteeTypeId;

		switch (model.applicantRole) {
			case ApplicantRole.GuarantorIncomeAndSecurity:
				lendingGuaranteeTypeId = LendingGuaranteeType.IncomeAndSecurity;
				break;
			case ApplicantRole.GuarantorIncomeOnly:
				lendingGuaranteeTypeId = LendingGuaranteeType.IncomeOnly;
				break;
			case ApplicantRole.GuarantorSecurityOnly:
				lendingGuaranteeTypeId = LendingGuaranteeType.SecurityOnly;
				break;
		}

		if (lendingGuaranteeTypeId) {
			return this.applicantsService.saveLendingGuarantee({ lendingGuaranteeTypeId }, model.id);
		}
		return of();
	}

	private savePersonApplicant(model: SetUpApplicant, index: number) {
		const personApplicant = setupToPersonApplicant(model);

		if (model.id > 0) {
			this.applicantsService.updateApplicantMainInfo(model).subscribe(() => {
				model.applicantType = applicantRoleToApplicantType(model.applicantRole || ApplicantRole.PrimaryBorrower);
				model.primaryApplicant = applicantRoleToApplicantPrimary(model.applicantRole || ApplicantRole.PrimaryBorrower);
				this.updateApplicant(model, index);

				this.personsCompaniesEnumService
					.fetchPersons()
					.pipe(switchMap(() => this.saveLendingGuarantee(model)))
					.subscribe();

				this.applicantsService.resetDigitalWidgetForPerson(model.id);
			});

			return;
		}
		model.id = CONSTANTS.NEW_ID;
		this.applicantsService
			.savePersonApplicant(personApplicant)
			.pipe(
				map((response: { applicantId: number; alreadyExists: boolean; reference: string }) => {
					model.id = response.applicantId;
					if (!model.householdId) {
						const households = this.formEnumsQuery.getOptions('Households');
						model.householdId = households[households.length - 1].id;
					}
					model.primaryApplicant = applicantRoleToApplicantPrimary(
						model.applicantRole || ApplicantRole.PrimaryBorrower
					);
					model.applicantType = applicantRoleToApplicantType(model.applicantRole || ApplicantRole.PrimaryBorrower);
					model.applicantTypeModal = { type: ApplicantEntityType.PersonApplicant };
					this.personsCompaniesEnumService.fetchPersons().subscribe();
					this.updateApplicant(model, index);
				}),
				switchMap(() => this.saveLendingGuarantee(model))
			)
			.subscribe();
	}

	private updateApplicant(model: SetUpApplicant, index: number) {
		this.simpFormlyHandlerService.updateToState('applicants', model, index);
		this.appService.applicationTitleInfoChange.next();
	}

	private openApplicantTypeModal(
		field: FormlyFieldConfig,
		allowCompanyApplicants: boolean,
		allowTrustApplicants: boolean
	): void {
		if (!field.parent) {
			return;
		}
		const applicantTypeField = getFormField(field.parent.fieldGroup, 'applicantTypeModal.type');

		if (applicantTypeField?.templateOptions) {
			const allowedApplicants = [ApplicantEntityType.PersonApplicant];

			if (allowCompanyApplicants) {
				allowedApplicants.push(ApplicantEntityType.CompanyApplicant);
			}

			if (allowTrustApplicants) {
				allowedApplicants.push(ApplicantEntityType.TrustApplicant);
			}

			applicantTypeField.templateOptions.required = true;
			const options = this.formEnumsQuery
				.getOptions('ApplicantEntityType')
				.filter((x) => allowedApplicants.includes(x.id));

			applicantTypeField.templateOptions.options = options;
		}
		const modelRef = this.simpFormlyModalService.openModal(field.parent, 'applicantTypeModal', {
			backdrop: true,
			beforeDismiss: () => {
				this.deleteUnsavedApplicant(field.parent, 'deleteApplicant');
				return false;
			},
			size: 'md',
			windowClass: 'd-block modal fade show simp-modal-confirm'
		});

		modelRef.action.subscribe((action) => {
			if (action !== 'submit') {
				modelRef.dismiss();
				return;
			}

			const setupApplicant = field.parent?.formControl?.value as SetUpApplicant;
			if (setupApplicant?.applicantTypeModal?.type) {
				this.getApplicantTypeSpecificOptions(getFormField(field.parent?.fieldGroup, 'applicantRole'));
				this.setApplicantRole(field);
			}

			if (
				action === 'submit' &&
				setupApplicant?.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant &&
				setupApplicant?.applicantTypeModal?.searchExisting &&
				field.parent
			) {
				this.handleCustomerSearch(field.parent, undefined, setupApplicant?.applicantTypeModal?.type);
			}

			modelRef.close();
			return;
		});
	}

	private setApplicantRole(field: FormlyFieldConfig) {
		if (field.parent?.key === '0') {
			field.formControl?.setValue(ApplicantRole.PrimaryBorrower, { emitEvent: false });
		} else {
			const applicants = get(field, 'parent.parent.formControl.value') as PersonApplicant[];
			const noOfPrimaryApplicants = applicants.filter((a) => a.applicantRole == ApplicantRole.PrimaryBorrower).length;
			field.formControl?.setValue(
				noOfPrimaryApplicants === 0 ? ApplicantRole.PrimaryBorrower : ApplicantRole.Coborrower,
				{ emitEvent: false }
			);
		}
	}

	private getApplicantTypeSpecificOptions(applicantRoleFormField: FormlyFieldConfig | undefined) {
		if (applicantRoleFormField?.props) {
			const applicantType = getFormField(applicantRoleFormField?.parent?.fieldGroup, 'applicantTypeModal.type');
			let applicantRole = '';
			switch (applicantType?.formControl?.value) {
				case ApplicantEntityType.PersonApplicant:
					applicantRole = 'ApplicantTypePerson';
					break;
				case ApplicantEntityType.CompanyApplicant:
					applicantRole = 'ApplicantTypeCompany';
					break;
				case ApplicantEntityType.TrustApplicant:
					applicantRole = 'ApplicantTypeTrust';
					break;
			}
			applicantRoleFormField.props.options = this.formEnumsQuery.selectEnumOptions(applicantRole);
		}
	}

	private setDefaultNotificationConsent(formFields: FormlyFieldConfig[] | undefined): void {
		formlyRegisterHooks(formFields, 'manageParticipants.managementParticipants.receiveInfoRequests', {
			onInit: (field: FormlyFieldConfig) => {
				this.managementParticipantsTransformerService.enableFormControlForAccreditedPeople(field);
				return of();
			}
		});
	}

	private handleFailedApplicant(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(
			formFields,
			'manageParticipants.applicants.applicantImportFailedError.searchApplicantAgain',
			(field: FormlyFieldConfig) => {
				const applicants = field?.parent?.parent as FormlyFieldConfig;
				const applicant = field?.parent?.parent?.model as SetUpApplicant;

				setTimeout(() => this.handleCustomerSearch(applicants, undefined, undefined, applicant.clientId), 10);
			}
		);
	}

	private handleIgnoreApplicantError(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(
			formFields,
			'manageParticipants.applicants.applicantImportFailedError.ignoreApplicantError',
			(field: FormlyFieldConfig) => {
				const applicant = field?.parent?.parent?.model as SetUpApplicant;
				const index = field?.parent?.parent?.key as number;
				this.appService.appSpinnerStatus$.next(CONSTANTS.SYNCING);
				this.applicantsService.updateIgnoreStatusForApplicant(applicant.id).subscribe({
					next: (result) => {
						applicant.externalAction = ExternalAction.NeverUpdate;
						this.updateApplicant(applicant, Number(index));
						this.appService.appSpinnerStatus$.next('');
					},
					error: () => {
						this.appService.appSpinnerStatus$.next('');
					}
				});
			}
		);
	}

	private handleApplicantDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'manageParticipants.applicants.deleteApplicant', (field: FormlyFieldConfig) => {
			const applicantModel = field.model as SetUpApplicant;
			if (!applicantModel.id) {
				this.deleteUnsavedApplicant(field);
				return;
			}

			const name =
				applicantModel.applicantTypeModal?.type === ApplicantEntityType.PersonApplicant
					? `${applicantModel.firstName} ${applicantModel.lastName}`
					: applicantModel.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant
					? applicantModel.trustName
					: applicantModel.companyName;
			const message = `${
				applicantModel.primaryApplicant ? 'Primary applicant' : 'Co-applicant'
			} <b> ${name}</b> and all associated information will be deleted.`;

			this.confirmationDialogService
				.deleteConfirmation('Delete applicant?', message, 'Delete', 'Cancel')
				.subscribe((result) => {
					if (!result) {
						return;
					}

					const index = Number(field.parent?.key);
					if (applicantModel.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant) {
						this.companyApplicantsService.deleteCompanyApplicant(applicantModel).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState('applicants', index);
							this.handleApplicantsChangeRemoteValidation();
							this.personsCompaniesEnumService.fetchCompanies().subscribe();
							this.householdService.getHouseholds();
						});
					} else if (applicantModel.applicantTypeModal?.type === ApplicantEntityType.TrustApplicant) {
						this.trustApplicantsService.deleteTrustApplicant(applicantModel).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState('applicants', index);
							this.handleApplicantsChangeRemoteValidation();
							this.personsCompaniesEnumService.fetchTrusts().subscribe();
							this.householdService.getHouseholds();
						});
					} else {
						this.applicantsService.deletePersonApplicant(applicantModel).subscribe(() => {
							formlyDeleteFromArray(field);
							this.simpFormlyHandlerService.deleteFromState('applicants', index);
							this.handleApplicantsChangeRemoteValidation();
							this.personsCompaniesEnumService.fetchPersons().subscribe();
							this.householdService.getHouseholds();
						});
					}
				});
		});
	}

	private handleManagementDeleteButtonClick(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(
			formFields,
			'manageParticipants.managementParticipants.deleteManagement',
			(field: FormlyFieldConfig) => {
				const applicationManagementModel = field.model as ManagementParticipant;
				if (!applicationManagementModel.id) {
					formlyDeleteFromArray(field);
					return;
				}
				const participantTypes = this.formEnumsQuery.getOptions('ManagementPersonType');
				const selectedType = participantTypes.find((t) => t.id === applicationManagementModel.participantType)?.label;
				const selectedPerson = (applicationManagementModel.id as any as EnumObject)?.label;
				const message = `<b>${selectedType}</b> management participant <b>${
					selectedPerson ? selectedPerson : ''
				}</b> will be permanently deleted.`;
				this.confirmationDialogService
					.deleteConfirmation('Delete management participant?', message, 'Delete', 'Cancel')
					.subscribe((result) => {
						if (result) {
							const index = Number(field.parent?.key);
							const selectedId = applicationManagementModel.id as any as ManagementParticipantLabel;
							this.manageParticipantsService.deleteManagementParticipant(selectedId.id).subscribe(() => {
								formlyDeleteFromArray(field);
								this.simpFormlyHandlerService.deleteFromState('managementParticipants', index);
								this.simpFormlyHandlerService.deleteFromState('managementParticipantsAdditionalInfo', index);
							});
						}
					});
			}
		);
	}

	private handlePersonIdChange(formFields: FormlyFieldConfig[] | undefined): void {
		const personId = getFormField(formFields, `manageParticipants.managementParticipants.id`) ?? {};
		Object.assign(personId, {
			...personId,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					this.managementParticipantsTransformerService.enableFormControlForAccreditedPeople(field);
				}
			}
		});
	}

	private handleParticipantTypeChange(formFields: FormlyFieldConfig[] | undefined): void {
		const participantTypeField =
			getFormField(formFields, `manageParticipants.managementParticipants.participantType`) ?? {};
		Object.assign(participantTypeField, {
			...participantTypeField,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const personField = field.parent?.fieldGroup?.find((f) => f.key === 'id');
					if (personField) {
						personField.templateOptions = personField.templateOptions ?? {};

						this.managementParticipantsTransformerService.enableFormControlForAccreditedPeople(field);

						return field.formControl?.valueChanges.pipe(
							startWith(field.formControl?.value),
							filter((val) => !!val),
							switchMap((val) => this.get(`ManagementPerson/${val}`)),
							map((persons: ManagementParticipantAPI[]) =>
								persons.map((person) => {
									return {
										id: person.id,
										label: person.fullName,
										userId: person.userId
									} as EnumObject;
								})
							),
							tap((options) => {
								personField.templateOptions!.options = options;

								const matchingOption = options.find((option) => option.id === personField.formControl?.value);
								if (!matchingOption) {
									personField.formControl?.setValue(null);
								} else {
									personField.formControl?.setValue(matchingOption);
								}
							})
						);
					}
					return;
				}
			}
		});
	}

	private handleBranchClick(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'application.details.bankBranchId', (field: FormlyFieldConfig) => {
			const branchEnums = this.formEnumsQuery.getOptions('BankBranch');
			const bsbValueFromAnzBranchField = branchEnums.find((value) => value.id === field.formControl?.value);
			const bsbField = getFormField(field.parent?.fieldGroup, 'bsb');
			bsbField?.formControl?.patchValue(bsbValueFromAnzBranchField?.info);
		});
	}

	private handleLinkedApplication(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(
			formFields,
			'application.details.linkedApplicationDetailRecords.delete',
			(field: FormlyFieldConfig) => {
				this.formArrayDeleteService.deleteItem(field, 'Application/LinkedApplicationDetails').subscribe();
			}
		);
	}

	private handleLoanWriterCompany(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnChange(
			formFields,
			'application.details.loanWriterCompany.loanWriterCompanyModal.companyName',
			(field: FormlyFieldConfig) => {
				const loanWriterCompanyField = field?.parent?.parent;
				const companyName = (field?.model as LoanWriterCompany).companyName;

				this.simpFormlyHandlerService
					.mapToFullStateDataPath<LoanWriterCompanyDto[]>('loanWriterCompanies')
					?.subscribe((loanWriterCompanies) => {
						const newCompany = loanWriterCompanies.find((company) => company?.companyName === companyName);
						if (newCompany) {
							loanWriterCompanyField?.formControl?.setValue({
								extract: newCompany?.companyName,
								loanWriterCompanyModal: LoanWriterCompanyTransformer.fromPayload(newCompany)
							} as LoanWriterCompanyModel);
						}
					});
			}
		);

		formlyOnClick(formFields, 'application.details.loanWriterCompany', (field: FormlyFieldConfig) => {
			this.simpFormlyHandlerService
				.mapToFullStateDataPath<LoanWriterCompanyDto[]>('loanWriterCompanies')
				?.pipe(take(1))
				?.subscribe((loanWriterCompanies) => {
					const loanWriterCompanyModal = getFormField(field?.fieldGroup, 'loanWriterCompanyModal.companyName');
					if (loanWriterCompanyModal && loanWriterCompanyModal.templateOptions) {
						const companyName = (loanWriterCompanyModal?.model as LoanWriterCompanyDto)?.companyName;
						if (isEmpty(loanWriterCompanies)) {
							if (companyName) {
								loanWriterCompanyModal.templateOptions.options = [{ id: companyName, label: companyName }];
							}
						} else {
							loanWriterCompanyModal.templateOptions.options = of(loanWriterCompanies).pipe(
								map((companies: LoanWriterCompanyDto[]) =>
									companies?.map((obj: LoanWriterCompanyDto) => ({
										id: obj.companyName,
										label: obj.companyName
									}))
								)
							);
						}
					}

					const modalRef = this.simpFormlyModalService.openModal(field, 'loanWriterCompanyModal', {
						windowClass: 'simp-padding-left-small'
					});

					const oldLoanWriterModel = cloneDeep(field.model) as LoanWriterCompanyModel;
					const modalSubscription = modalRef.action.subscribe((parentAction) => {
						if (parentAction !== 'submit') {
							if (field?.formControl && oldLoanWriterModel) {
								field.formControl.patchValue(oldLoanWriterModel);
							}
							modalRef.close();
							modalSubscription.unsubscribe();
							return;
						}

						const applicationFormField = getParentFormField(field, 'application');
						if (applicationFormField) {
							this.saveApplication(applicationFormField)
								.pipe(
									tap(() => {
										modalRef.close();
										modalSubscription.unsubscribe();
									})
								)
								.subscribe();
						}
					});
				});
		});
	}

	private handleAggregatorCompany(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'application.details.aggregatorCompany', (field: FormlyFieldConfig) => {
			const modalRef = this.simpFormlyModalService.openModal(field, 'aggregatorCompanyModal', {
				windowClass: 'simp-padding-left-small'
			});

			const modalSubscription = modalRef.action.subscribe((parentAction) => {
				if (parentAction !== 'submit') {
					modalRef.close();
					modalSubscription.unsubscribe();
					return;
				}
				modalRef.close();
				modalSubscription.unsubscribe();
			});
		});
	}

	private handleBusinessChannel(formFields: FormlyFieldConfig[] | undefined): void {
		formlyOnClick(formFields, 'application.details.businessChannel', (field: FormlyFieldConfig) => {
			const modalRef = this.simpFormlyModalService.openModal(field, 'businessChannelModal', {
				windowClass: 'simp-padding-left-small'
			});

			const modalSubscription = modalRef.action.subscribe((parentAction) => {
				if (parentAction !== 'submit') {
					modalRef.close();
					modalSubscription.unsubscribe();
					return;
				}
				modalRef.close();
				modalSubscription.unsubscribe();
			});
		});
	}

	private handleApplicantsChangeRemoteValidation() {
		this.formValidationsService.resetDrafts();
		this.formValidationsQuery
			.mapToAreaRemoteError()
			.pipe(take(1))
			.subscribe((validationErrors: ValidationsErrorDetailModel[]) => {
				if (validationErrors.length > 0) {
					const distinctCodes = uniqBy(validationErrors, (ve) => ve.code)
						.map((obj) => obj.code)
						.filter((code) => code !== undefined);
					if (distinctCodes.length > 0) {
						this.remoteValidationService.recheckExistingRemoteValidationErrors(distinctCodes);
					}
				}
			});
	}

	private handleCustomerSearch(
		applicantSubSection: FormlyFieldConfig,
		index: number | undefined,
		applicantEntityType?: number,
		clientId?: string | undefined
	): void {
		const modalPath = index !== undefined ? `${index}.customerSearchModal` : 'customerSearchModal';
		const modalRef = this.customerSearchTransformerService.openCustomerSearchModal(
			applicantSubSection,
			modalPath,
			applicantEntityType as ApplicantEntityType,
			clientId
		);
		if (modalRef) {
			const deleteKey = index !== undefined ? `${index}.deleteApplicant` : 'deleteApplicant';
			// add new applicant through customer search modal
			modalRef.closed.pipe(take(1)).subscribe((result: CustomerSearchModalResponse) => {
				if (result?.customerImported) {
					this.loadImportedApplicants(result, applicantSubSection, deleteKey);
				} else if (result?.applicantEntityType === undefined) {
					// on click add new applicant button in customer search modal
					this.deleteUnsavedApplicant(applicantSubSection, deleteKey);
					this.addUnsavedApplicantRecord(applicantSubSection, false);
				}
			});

			modalRef.action?.subscribe((action) => {
				if (action !== 'submit' && !clientId) {
					this.deleteUnsavedApplicant(applicantSubSection, deleteKey);
				}
				modalRef?.dismiss();
			});
		}
	}

	private loadImportedApplicants(
		result: CustomerSearchModalResponse,
		applicantSubSection: FormlyFieldConfig,
		deleteKey: string
	): void {
		this.deleteUnsavedApplicant(applicantSubSection, deleteKey);
		this.appService.appSpinnerStatus$.next(CONSTANTS.SYNCING);
		this.addressService.fetchAllAddress();

		switch (result?.applicantEntityType) {
			case ApplicantEntityType.PersonApplicant:
				this.personsCompaniesEnumService.fetchPersons().subscribe();
				break;
			case ApplicantEntityType.CompanyApplicant:
				this.personsCompaniesEnumService.fetchCompanies().subscribe();
				break;
			case ApplicantEntityType.TrustApplicant:
				this.personsCompaniesEnumService.fetchTrusts().subscribe();
				break;
			default:
				this.personsCompaniesEnumService.fetchPersons().subscribe();
				this.personsCompaniesEnumService.fetchCompanies().subscribe();
				this.personsCompaniesEnumService.fetchTrusts().subscribe();
		}

		this.setupService
			.fetchSetupApplicants()
			.pipe(take(1))
			.subscribe({
				next: (setupApplicants) => {
					this.appService.appSpinnerStatus$.next('');
					this.simpFormlyHandlerService.upsertToFullPathWithData('applicants', setupApplicants);
					this.appService.applicationTitleInfoChange.next();
				},
				error: () => {
					this.appService.appSpinnerStatus$.next('');
				}
			});
	}

	private deleteUnsavedApplicant(field: FormlyFieldConfig | undefined, key?: string): void {
		if (field) {
			const deleteButtonField = key ? getFormField(field?.fieldGroup, key) : field;
			if (deleteButtonField) {
				formlyDeleteFromArray(deleteButtonField);
			}
		}
	}

	private addUnsavedApplicantRecord(applicantsSubSection: FormlyFieldConfig, isCustomerSearchFlow = false) {
		if (applicantsSubSection?.props) {
			applicantsSubSection.props.isCustomerSearchFlow = isCustomerSearchFlow;
		}
		if (applicantsSubSection?.props?.add) {
			(applicantsSubSection.props.add as Function)();
		}
	}
}
