import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RelatedPartyModel } from '@app/modules/applicants/models/related-party.model';
import { PropertyModalTransformerService } from '@app/modules/property/services/property-modal-transformer.service';
import { TargetType } from '@app/modules/shared/enums/app.enums';
import { deletedSuccessfullyMessage, savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { CreditIssueModel, CreditIssueTransformer } from '@app/modules/shared/model/credit-issue.model';
import { WarningIssueModel } from '@app/modules/shared/model/warning-issue-model';
import { FormArrayDeleteService } from '@app/modules/shared/service/form-array-delete.service';
import { RelatedEntitiesService } from '@app/modules/shared/service/related-entities.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { AssetsLiabilitiesTransformerService } from '@app/modules/shared/transformers/assets-liabilities-transformer.service';
import {
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyOnStatusChangedToValid,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';

import { AccountantModel } from '../../model/company-trust-accountant.model';

import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	ForeignTaxAssociationModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { ForeignTaxAssociationService } from '@app/modules/shared/service/foreign-tax-association.service';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { CompanyTrustIncome, CompanyTrustIncomeTransformer } from '../../model/company-trust-income.model';
import { CompanyFinancialPositionService } from './company-financial-position.service';

const SUB_KEY_COMPANY_FINANCIAL_POSITION_INCOME = 'companyIncome';
@Injectable({
	providedIn: 'root'
})
export class CompanyFinancialPositionTransformerService {
	constructor(
		private assetsLiabilityTransformerService: AssetsLiabilitiesTransformerService,
		private companyFinancialPositionService: CompanyFinancialPositionService,
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsQuery: FormEnumsQuery,
		private formArrayDeleteService: FormArrayDeleteService,
		private toastr: ToastrService,
		private relatedEntitiesService: RelatedEntitiesService,
		private propertyModalTransformerService: PropertyModalTransformerService,
		private foreignTaxAssociationService: ForeignTaxAssociationService,
		private formDataService: FormDataService
	) {}

	transform(formFields: FormlyFieldConfig[], index: number): FormlyFieldConfig[] {
		const companyApplicants = this.applicationDataQuery.getCompanyApplicants();
		formFields = this.assetsLiabilityTransformerService.transform(formFields) as FormlyFieldConfig[];

		const companyApplicant = companyApplicants[index];
		this.configureAccountant(formFields, companyApplicant);
		this.configureCreditStatus(formFields, companyApplicant);
		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'companyIncome');

		this.configureCompanyIncome(formFields, index);
		this.configureForeignCompanyTaxAssociation(formFields, companyApplicant);

		this.propertyModalTransformerService.configurePropertyModalForFinancialPosition(
			formFields,
			SUB_KEY_COMPANY_FINANCIAL_POSITION_INCOME
		);
		return formFields;
	}

	configureForeignCompanyTaxAssociation(formFields: FormlyFieldConfig[], companyApplicant: ShortApplicant) {
		formlyOnStatusChangedToValid(
			formFields,
			'companyApplicant.foreignTaxAssociation',
			(field, event: FormlyArrayUpdateEvent<ForeignTaxAssociationModel>) => {
				const model = cloneDeep(get(field, `model[${event.index}]`)) as ForeignTaxAssociationModel;
				const foreignTaxResidencyModel = {
					id: model.id ?? CONSTANTS.NEW_ID,
					targetId: companyApplicant.id,
					targetType: TargetType.CompanyApplicant,
					selfCertificationDeclaration: model.selfCertificationDeclaration,
					foreignTaxAssociationDetails: model.foreignTaxAssociationDetails
				} as ForeignTaxAssociationModel;

				const payload = ForeignTaxAssociationTransformer.toPayLoad(
					foreignTaxResidencyModel,
					this.applicationDataQuery.applicationId()
				);

				return this.foreignTaxAssociationService
					.saveForeignTaxAssociation(payload)
					.subscribe((foreignTaxAssociationId: number) => {
						model.id = foreignTaxAssociationId;
						this.simpFormlyHandlerService.updateToState('foreignTaxAssociation', model, 0);
						const storeData = cloneDeep(this.formDataService.getSetupApplicants());
						const index = storeData.findIndex((x) => x.id === companyApplicant.id);
						if (!storeData[index].foreignTaxAssociationId) {
							storeData[index].foreignTaxAssociationId = foreignTaxAssociationId;
							this.simpFormlyHandlerService.updateToFullStatePath('applicants', storeData[index], index);
						}

						this.toastr.success(savedSuccessfullyMessage('Foreign tax information'));
					});
			}
		);
		const foreignTaxAssociationLabel = getFormField(
			formFields,
			'companyApplicant.foreignTaxAssociation.foreignTaxAssociationDetails.countryOfForeignTaxResidency'
		)?.props?.label;
		formlyExtendExpressionProperties(
			formFields,
			`companyApplicant.foreignTaxAssociation.foreignTaxAssociationDetails.countryOfForeignTaxResidency`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					return `${Number(field.parent?.key) + 1} - ${foreignTaxAssociationLabel}`;
				}
			}
		);
	}
	configureCreditStatus(formFields: FormlyFieldConfig[], companyApplicant: ShortApplicant) {
		this.setupDefaultsCreditStatus(formFields, companyApplicant);

		this.handleCompanyCreditStatusChange(formFields, companyApplicant);

		formlyOnClick(formFields, 'companyApplicant.creditStatus.warningIssuesList.delete', (field: FormlyFieldConfig) => {
			const model = cloneDeep(field.parent?.model) as WarningIssueModel;
			this.companyFinancialPositionService.deleteWarningIssues(model, companyApplicant.id).subscribe(() => {
				formlyDeleteFromArray(field);
				const storeData = cloneDeep(this.simpFormlyHandlerService.getStateData<CreditIssueModel>('creditStatus'));
				if (storeData && storeData[0]) {
					const index = Number(field?.parent?.key);
					storeData[0]?.warningIssuesList?.splice(index, 1);
				}
				this.simpFormlyHandlerService.updateToState('creditStatus', storeData[0], 0);
				this.toastr.success(deletedSuccessfullyMessage('Warning issue'));
			});
		});

		this.formArrayDeleteService.handleDelete(
			formFields,
			`CreditStatusIssue/${this.applicationDataQuery.applicationId()}/${companyApplicant.id}`,
			'Credit Issues '
		);
	}

	/**
	 * configure company income
	 * @param formFields
	 * @param sectionIndex
	 * @param indexSuffix when we multiple incomes together, eg: serviceability calculator
	 */
	configureCompanyIncome(formFields: FormlyFieldConfig[], sectionIndex: number, indexSuffix = ''): void {
		const companyApplicant = indexSuffix
			? this.applicationDataQuery.getApplicants()[sectionIndex]
			: this.applicationDataQuery.getCompanyApplicants()[sectionIndex];

		const incomesList = ['currentFY', 'previousFY', 'secondPreviousFY', 'thirdPreviousFY'];
		incomesList.forEach((income) => {
			formlyOnStatusChangedToValid(
				formFields,
				`companyIncome${indexSuffix}.incomeDetails${indexSuffix}.${income}`,
				(field: FormlyFieldConfig) => {
					this.saveCompanyIncome(field, companyApplicant);
				}
			);
		});
	}

	private saveCompanyIncome(field: FormlyFieldConfig, companyApplicant: ShortApplicant) {
		const stateModel = cloneDeep(field.parent?.model) as CompanyTrustIncome;

		stateModel.previousFY.financialYearId = stateModel.currentFY.financialYearId - 1;
		stateModel.secondPreviousFY.financialYearId = stateModel.currentFY.financialYearId - 2;
		stateModel.thirdPreviousFY.financialYearId = stateModel.currentFY.financialYearId - 3;

		stateModel.previousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 1
		);
		stateModel.secondPreviousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 2
		);
		stateModel.thirdPreviousFY.financialYear = CompanyTrustIncomeTransformer.getFinancialYearLabel(
			stateModel.currentFY.financialYearId - 3
		);
		const payload = CompanyTrustIncomeTransformer.toPayload(
			stateModel,
			this.applicationDataQuery.applicationId(),
			companyApplicant.id
		);

		this.companyFinancialPositionService.saveCompanyIncome(payload, companyApplicant.id).subscribe((v: void) => {
			this.simpFormlyHandlerService.updateToState('incomeDetails', stateModel, 0);
		});
	}

	private saveAccountant = (model: RelatedPartyModel, personnelIndex: number, applicantId: number) => {
		return this.companyFinancialPositionService
			.saveCompanyAccountant({
				applicationId: this.applicationDataQuery.applicationId(),
				accountantId: model.existingSelect?.id,
				accountantName: model.existingSelect?.companyName,
				applicantId
			})
			.pipe(take(1))
			.subscribe(() => {
				this.simpFormlyHandlerService.updateToState('accountant', model, 0);
				this.toastr.success(savedSuccessfullyMessage('Accountant'));
			});
	};

	private handleAccountantChange(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant): void {
		formlyOnStatusChangedToValid(formFields, 'companyIncome.accountant', (field: FormlyFieldConfig) => {
			const model = field.model as AccountantModel[];
			if (model[0].existingSelect.id === -2) {
				return;
			}
			this.saveAccountant(model[0], 0, trustApplicant.id);
		});
	}
	private configureAccountant(formFields: FormlyFieldConfig[], trustApplicant: ShortApplicant) {
		const accountantField = getFormField(formFields, 'companyIncome.accountant.existingSelect');
		formlyRegisterHooks(formFields, 'companyIncome.accountant.existingSelect', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const addNewCompany = this.formEnumsQuery.getAddOptions(
						accountantField?.templateOptions?.options as unknown as string
					);
					addNewCompany[0].id = -2;
					const listItems = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
						map((x) => {
							if (x) {
								return [...addNewCompany, ...x].map((company) => ({
									...company,
									click:
										(company.type as unknown as TargetType) === TargetType.RelatedCompany
											? () =>
													this.relatedEntitiesService.openPrefilledRelatedEntityModal(
														field,
														trustApplicant.id,
														this.saveAccountant
													)
											: null
								}));
							} else {
								return addNewCompany;
							}
						})
					);
					field.templateOptions.options = listItems;
					return this.relatedEntitiesService.configureRelatedEntityModal(field, trustApplicant.id, this.saveAccountant);
				}
				return of();
			}
		});
		this.handleAccountantChange(formFields, trustApplicant);
	}

	private handleCompanyCreditStatusChange(formFields: FormlyFieldConfig[], companyApplicant: ShortApplicant): void {
		formlyOnStatusChangedToValid(formFields, 'companyApplicant.creditStatus.creditStatusContainer', (field) => {
			const model = field.parent?.model as CreditIssueModel;
			if (model) {
				const payload = CreditIssueTransformer.toPayload(
					model,
					this.applicationDataQuery.applicationId(),
					companyApplicant.id
				);

				this.companyFinancialPositionService
					.saveCompanyCreditStatus(payload)
					.pipe(take(1))
					.subscribe((res) => {
						this.simpFormlyHandlerService.updateToState('creditStatus', model, 0);
						this.toastr.success(savedSuccessfullyMessage('Credit status'));
					});
			}
		});

		formlyRegisterHooks(formFields, 'companyApplicant.creditStatus.warningIssuesList.issueDetails', {
			onInit: (field) => {
				const form = field?.parent?.formControl as UntypedFormControl;
				if (form) {
					return form.valueChanges.pipe(
						debounceTime(1000),
						filter(() => {
							return form.dirty && form.valid;
						}),
						tap((model: WarningIssueModel) => {
							if (model) {
								const payload = CreditIssueTransformer.toPayloadWarningIssue(model);
								this.companyFinancialPositionService
									.saveCompanyCreditIssues(payload, companyApplicant.id)
									.pipe(take(1))
									.subscribe((issueId: number) => {
										model.id = issueId;
										const storeData = cloneDeep(
											this.simpFormlyHandlerService.getStateData<CreditIssueModel>('creditStatus')
										);
										if (storeData) {
											const index = Number(field?.parent?.key);
											if (storeData[0].warningIssuesList) {
												storeData[0].warningIssuesList[index] = model;
											} else {
												storeData[0].warningIssuesList = [model];
											}
										}
										this.simpFormlyHandlerService.updateToState('creditStatus', storeData[0], 0);
										this.toastr.success(savedSuccessfullyMessage('Credit status issue'));
									});
							}
						})
					);
				}
				return of();
			}
		});
	}

	private setupDefaultsCreditStatus(formFields: FormlyFieldConfig[], companyApplicant: ShortApplicant): void {
		const companyName = companyApplicant.name;
		const creditStatusCompanyNameField = getFormField(formFields, `companyApplicant.creditStatus.applicant`);
		if (creditStatusCompanyNameField && creditStatusCompanyNameField.templateOptions) {
			creditStatusCompanyNameField.templateOptions.defaultValue = companyName;
		}
	}
}
