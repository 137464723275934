import { LiabilityType } from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ApplicantDTO, LiabilityScenarioDTO } from '@app/modules/typings/api';

export class LiabilitiesTransformer {
	static fromPayload(
		baseScenarioLiability: LiabilityScenarioDTO[],
		selectedScenarioLiability: LiabilityScenarioDTO[] | undefined,
		applicants: ApplicantDTO[],
		formEnumQuery: FormEnumsQuery
	): Liabilities {
		const scenarioLiabilities: LiabilityScenario[] = baseScenarioLiability.map((baseLiability) => {
			const selectedLiability = selectedScenarioLiability?.find(
				(liability) => liability.liabilityId === baseLiability.liabilityId
			);
			// If `isMortgage` is true, `liabilityType` is set to 'Home Loan': Reference: Ticket TAMA5-20441
			const liabilityType = baseLiability.isMortgage
				? 'Home Loan'
				: formEnumQuery.getOptionLabel('LiabilityType', baseLiability.typeId);
			const repaymentFrequency = formEnumQuery.getOptionLabel('RepaymentFrequency', baseLiability.repaymentFrequencyId);
			return {
				id: baseLiability.id,
				liabilityId: baseLiability.liabilityId,
				typeId: baseLiability.typeId,
				liabilityLabel: this.generateLiabilityName(baseLiability, applicants, liabilityType, repaymentFrequency),
				creditCardLimit: baseLiability.amount,
				creditCardAmountChangedTo: selectedLiability?.amount,
				currentBalance: baseLiability.amount,
				isClearing: selectedLiability?.clearing
			};
		});

		this.sortTermedLiabilities(scenarioLiabilities);
		return { liabilities: scenarioLiabilities };
	}

	static toPayload(liabilityScenario: Liabilities): LiabilityScenarioDTO[] {
		const liabilitiesDTO: LiabilityScenarioDTO[] = liabilityScenario.liabilities
			.map((liability) => {
				if (liability.isClearing === undefined && liability.creditCardAmountChangedTo === undefined) {
					return null;
				}

				const liabilityDTO: Partial<LiabilityScenarioDTO> = {
					typeId: liability.typeId,
					liabilityId: liability.liabilityId
				};

				if (liability.isClearing !== undefined) {
					liabilityDTO.clearing = liability.isClearing;
				}

				if (liability.creditCardAmountChangedTo !== undefined) {
					liabilityDTO.amount = liability.creditCardAmountChangedTo;
				}

				return liabilityDTO as LiabilityScenarioDTO;
			})
			.filter((liabilityDTO): liabilityDTO is LiabilityScenarioDTO => liabilityDTO !== null);

		return liabilitiesDTO;
	}

	static generateLiabilityName(
		liabilityScenario: LiabilityScenarioDTO,
		applicants: ApplicantDTO[],
		scenarioLiabilityType: string,
		repaymentFrequency: string
	): string {
		const applicantList: ApplicantDTO[] = liabilityScenario.personApplicantIds.map((applicantId) => {
			return applicants.find((applicant) => applicant.id === applicantId) as ApplicantDTO;
		});

		const applicantNames = this.formatApplicantArray(applicantList);
		const liabilityType = this.formatLiabilityType(scenarioLiabilityType);
		const financialInstitutionName = liabilityScenario.financialInstitutionName;

		if (liabilityScenario.typeId !== LiabilityType.CreditCard) {
			const repaymentAmount = liabilityScenario.repaymentAmount;

			return `${liabilityType} - ${applicantNames}, <br/> ${financialInstitutionName}, ${repaymentFrequency} repayment $${repaymentAmount}`;
		}

		return `${liabilityType} - ${applicantNames}, <br/> ${financialInstitutionName}`;
	}

	static formatApplicantArray(arr: ApplicantDTO[]): string {
		if (arr.length === 0) return '';

		if (arr.length === 1) {
			const { firstName, surname } = arr[0];
			return `${firstName} ${surname}`;
		}

		const lastApplicant = arr.pop();
		const otherApplicants = arr.map(({ firstName, surname }) => `${firstName} ${surname}`).join(', ');

		if (lastApplicant) {
			const { firstName, surname } = lastApplicant;
			return `${otherApplicants} & ${firstName} ${surname}`;
		}

		return otherApplicants;
	}

	static formatLiabilityType(liabilityType: string): string {
		const words = liabilityType.split(' ');

		if (words.length < 2) {
			return liabilityType;
		}

		for (let i = 1; i < words.length; i++) {
			words[i] = words[i].toLowerCase();
		}

		return words.join(' ');
	}

	static sortTermedLiabilities(liabilityScenarios: LiabilityScenario[]): LiabilityScenario[] {
		const typeIds: number[] = [
			LiabilityType.CreditCard,
			LiabilityType.LineofCredit,
			LiabilityType.StoreCard,
			LiabilityType.Overdraft
		];
		liabilityScenarios.sort((a, b) => {
			const aIndex = typeIds.indexOf(a.typeId);
			const bIndex = typeIds.indexOf(b.typeId);

			if (aIndex !== -1 && bIndex !== -1) {
				return aIndex - bIndex;
			} else if (aIndex !== -1) {
				return -1;
			} else if (bIndex !== -1) {
				return 1;
			} else {
				return 0;
			}
		});
		return liabilityScenarios;
	}
}

export interface LiabilityScenario {
	id: number;
	liabilityId: number;
	typeId: number;
	liabilityLabel: string;
	creditCardLimit?: number;
	creditCardAmountChangedTo?: number;
	currentBalance?: number;
	isClearing?: boolean;
}

export interface Liabilities {
	liabilities: LiabilityScenario[];
}
