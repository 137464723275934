import {
	HousingStatus,
	InterestType,
	PaymentType,
	RbaLendingPurpose,
	ResidentialType,
	StepStatus,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { PersonApplicantDTO, RefiLoanRequirementsDTO, RefiPropertyAssetsDTO } from '@app/modules/typings/api';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiLoanRequirementTransformer {
	static toPayload(
		model: RefiLoanRequirementModel,
		applicationId: number,
		applicants: PersonApplicantDTO[]
	): RefiLoanRequirementsDTO {
		return {
			applicationId: applicationId,
			realEstateAssetId: model.realEstateAssetId,
			loanEstablishedDate: model.loanEstablishmentDate,
			loanAmount: model.loanAmount,
			paymentType: model.repaymentType,
			interestRate: (model.interestRate as number) === -1 ? null : model.interestRate,
			rbaLendingPurpose: model.rbaLendingPurpose,
			offset: model.offset,
			redraw: model.redraw,
			splitLoan: model.splitLoan,
			noOngoingFees: model.noOngoingFees,
			interestOnlyPeriodYears: model.interestOnlyPeriod,
			loanTerm: model.loanTerm,
			isTaxDeductible: getNullableBoolean(model.isTaxDeductible),
			postSettlementLivingSituation: model.livingSituation,
			depositPaid: getNullableBoolean(model.depositPaid),
			depositAmount: model.depositAmount,
			signedContractOfSale: getNullableBoolean(model.signedContractOfSale),
			percentsOwned: RefiTransformerHelper.setPercentsOwned(applicants, true)
		};
	}

	static fromPayload(
		data: RefiLoanRequirementsDTO,
		applicationId: number,
		stepStatus: StepStatus,
		propertyDetails?: RefiPropertyAssetsDTO
	): RefiLoanRequirementModel {
		return {
			applicationId: applicationId,
			outstandingBalance: data.outstandingBalance,
			realEstateAssetId: data.realEstateAssetId,
			loanEstablishmentDate: data.loanEstablishedDate,
			loanAmount: data.loanAmount,
			repaymentType: data.paymentType,
			rbaLendingPurpose: data.rbaLendingPurpose,
			offset: data.offset,
			redraw: data.redraw,
			splitLoan: data.splitLoan,
			noOngoingFees: data.noOngoingFees,
			interestRate: (data.interestRate as number) === null ? (stepStatus > 1 ? -1 : null) : data.interestRate,
			interestOnlyPeriod: data.interestOnlyPeriodYears,
			loanTerm: data.loanTerm === 0 ? undefined : data.loanTerm,
			isTaxDeductible: getNullableYesNo(data.isTaxDeductible),
			livingSituation: data.postSettlementLivingSituation,
			depositPaid: data.foundProperty ? getNullableYesNo(data.depositPaid) : undefined,
			depositAmount: data.depositAmount,
			signedContractOfSale: data.foundProperty ? getNullableYesNo(data.signedContractOfSale) : undefined,
			foundProperty: data.foundProperty,
			lvr: data.lvr,
			residentialType: data.residentialType,
			estimatedValue: propertyDetails?.estimatedValue // This is for calculating LMI only
		};
	}
}

export interface RefiLoanRequirementModel {
	applicationId: number;
	realEstateAssetId?: number;
	outstandingBalance?: number;
	repaymentType?: PaymentType;
	rbaLendingPurpose?: RbaLendingPurpose;
	loanAmount?: number;
	loanEstablishmentDate?: string;
	interestOnlyPeriod?: number;
	loanTerm?: number;
	offset?: boolean;
	splitLoan?: boolean;
	noOngoingFees?: boolean;
	redraw?: boolean;
	interestRate?: InterestType | null;
	isTaxDeductible?: YesNo;
	livingSituation?: HousingStatus;
	depositPaid?: YesNo;
	depositAmount?: number;
	signedContractOfSale?: YesNo;
	foundProperty?: boolean;
	lvr?: number;
	residentialType?: ResidentialType;
	estimatedValue?: number;
}
