import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { PersonApplicantDTO } from '@app/modules/typings/api';
import { ApplicantRole, YesNo } from './../../shared/enums/app.enums';

export class PersonOtherDetailsTransformer {
	static fromPayload(applicant: PersonApplicantDTO, role: ApplicantRole): PersonOtherDetailsModel {
		return {
			applicantId: applicant.id,
			dateSigned: applicant.dateSigned,
			companyDirector: getNullableYesNo(applicant.companyDirector),
			applicantRole: role,
			shareholderInDifficultiesAcknowledgement: !!applicant.shareholderInDifficultiesAcknowledgement,
			submittedSimilarAcknowledgement: !!applicant.submittedSimilarAcknowledgement,
			unsatisfiedJudgmentAcknowledgement: !!applicant.unsatisfiedJudgmentAcknowledgement,
			isLenderStaff: getNullableYesNo(applicant.isLenderStaff),
			firstHomeBuyer: getNullableYesNo(applicant.firstHomeBuyer),
			isExistingCustomer: getNullableYesNo(applicant.isExistingCustomer),
			customerSince:
				!!applicant.customerSince && !!applicant.customerSinceMonth
					? `${applicant.customerSince}-${applicant.customerSinceMonth}-01`
					: undefined,
			customerNumber: applicant.customerNumber,
			eligibleForFHOG: getNullableYesNo(applicant.eligibleForFHOG),
			privacyActConsentSigned: getNullableYesNo(applicant.privacyActConsentSigned),
			allowCreditCheck: getNullableYesNo(applicant.allowCreditCheck),
			allowElectronicIdentityCheck: getNullableYesNo(applicant.allowElectronicIdentityCheck),
			allowCreditBureauIdentityCheck: getNullableYesNo(applicant.allowCreditBureauIdentityCheck),
			allowDirectMarketing: getNullableYesNo(applicant.allowDirectMarketing)
		};
	}

	static toPayload(personalDetailsModel: PersonOtherDetailsModel): Partial<PersonApplicantDTO> {
		return {
			id: personalDetailsModel.applicantId,
			dateSigned: personalDetailsModel.privacyActConsentSigned ? personalDetailsModel.dateSigned : undefined,
			companyDirector: getNullableBoolean(personalDetailsModel.companyDirector),
			shareholderInDifficultiesAcknowledgement: !!personalDetailsModel.shareholderInDifficultiesAcknowledgement,
			submittedSimilarAcknowledgement: !!personalDetailsModel.submittedSimilarAcknowledgement,
			unsatisfiedJudgmentAcknowledgement: !!personalDetailsModel.unsatisfiedJudgmentAcknowledgement,
			isLenderStaff: getNullableBoolean(personalDetailsModel.isLenderStaff),
			firstHomeBuyer: getNullableBoolean(personalDetailsModel.firstHomeBuyer),
			isExistingCustomer: getNullableBoolean(personalDetailsModel.isExistingCustomer),
			customerSince: personalDetailsModel.customerSince
				? new Date(personalDetailsModel.customerSince).getFullYear()
				: undefined,
			customerSinceMonth: personalDetailsModel.customerSince
				? new Date(personalDetailsModel.customerSince).getMonth() + 1
				: undefined,
			customerNumber: personalDetailsModel.customerNumber,
			eligibleForFHOG: getNullableBoolean(personalDetailsModel.eligibleForFHOG),
			allowDirectMarketing: getNullableBoolean(personalDetailsModel.allowDirectMarketing),
			privacyActConsentSigned: getNullableBoolean(personalDetailsModel.privacyActConsentSigned),
			allowCreditCheck: getNullableBoolean(personalDetailsModel.allowCreditCheck),
			allowElectronicIdentityCheck: getNullableBoolean(personalDetailsModel.allowElectronicIdentityCheck),
			allowCreditBureauIdentityCheck: getNullableBoolean(personalDetailsModel.allowCreditBureauIdentityCheck)
		};
	}
}

export interface PersonOtherDetailsModel {
	applicantId: number;
	applicantRole: ApplicantRole;
	dateSigned?: string;
	companyDirector?: YesNo | undefined;
	shareholderInDifficultiesAcknowledgement?: boolean;
	submittedSimilarAcknowledgement?: boolean;
	unsatisfiedJudgmentAcknowledgement?: boolean;
	isLenderStaff?: YesNo | undefined;
	firstHomeBuyer?: YesNo | undefined;
	isExistingCustomer?: YesNo | undefined;
	customerSince?: string;
	customerNumber?: string;
	eligibleForFHOG?: YesNo | undefined;
	privacyActConsentSigned?: YesNo | undefined;
	allowCreditCheck?: YesNo | undefined;
	allowElectronicIdentityCheck?: YesNo | undefined;
	allowCreditBureauIdentityCheck?: YesNo | undefined;
	allowDirectMarketing?: YesNo | undefined;
}
