import { Injectable } from '@angular/core';
import { CurrentUserStore } from './current-user.store';

@Injectable({ providedIn: 'root' })
export class CurrentUserQuery {
	constructor(protected store: CurrentUserStore) {}

	firstName = (): string => {
		return this.store.getStoreValue().firstName;
	};

	lastName = (): string => {
		return this.store.getStoreValue().lastName;
	};

	displayName = (): string => {
		return this.store.getStoreValue().displayName;
	};

	personId = (): number => {
		return this.store.getStoreValue().peopleId;
	};
}
