import { LoanPreferencesSummaryDTO } from '@app/modules/typings/api';

export class LoanPreferencesSummaryTransformer {
	static toPayload(applicationId: number, model: LoanPreferencesSummaryModel): LoanPreferencesSummaryDTO {
		return {
			applicationId: applicationId,
			summary: model.summary,
			productSelection: model.productSelection
		};
	}

	static fromPayload(data: LoanPreferencesSummaryDTO): LoanPreferencesSummaryModel {
		return {
			summary: data.summary,
			productSelection: data.productSelection
		};
	}
}

export interface LoanPreferencesSummaryModel {
	productSelection: string;
	summary: string;
}
