import { BorrowingInfo } from '@app/modules/loan-serviceability/model/borrowing.model';
import { Product } from '@app/modules/loan-serviceability/model/product.model';
import { RateToBorrower } from '@app/modules/loan-serviceability/model/rate-to-borrower.model';
import {
	InterestType,
	PaymentType,
	PrimaryPurposeLoanPurpose,
	RbaLendingPurpose
} from '@app/modules/shared/enums/app.enums';
import { formatPlural, getYearsAndMonthsText } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';

export class LoanPurposePreferredFeatureTransformer {
	static fromPayload(
		borrowings: BorrowingInfo[],
		products: Product[],
		rates: RateToBorrower[],
		formEnumsQuery: FormEnumsQuery
	): LoanPurposeAndCustomerConversation {
		return {
			loanSplitsArray: borrowings.map((b) => {
				const loanProduct = products.find((product) => product.loanId === b.loanId);
				const loanRate = rates.find((rate) => rate.loanId === b.loanId);
				return this.getLoanSplits(b, loanProduct, loanRate, formEnumsQuery);
			}),
			fum: { totalFUMAmount: borrowings.reduce((a, b) => a + (b.amountRequested ?? 0), 0) }
		};
	}
	static getLoanSplits(
		borrowingInfo: BorrowingInfo,
		product: Product | undefined,
		rate: RateToBorrower | undefined,
		formEnumsQuery: FormEnumsQuery
	): LoanSplit {
		return {
			loanSplits: {
				borrowingAmount: borrowingInfo.amountRequested,
				reasonForBorrowing: this.getReasonForBorrowing(borrowingInfo),
				loanType: borrowingInfo.primaryPurpose,
				interestType:
					product?.interestType === InterestType.Variable
						? 'Variable'
						: product?.interestType === InterestType.FixedRate
						? `Fixed (${product?.fixedRatePeriod ?? 0} ${formatPlural(product.fixedRatePeriod ?? 0, 'year', 'years')})`
						: '',
				loanTerm: this.getLoanTermText(product),
				paymentType: this.getPaymentType(product, formEnumsQuery),
				repaymentFrequency: formEnumsQuery.getOption(
					`RepaymentFrequencies-${borrowingInfo.loanId}`,
					rate?.repaymentFrequency as number
				)?.label,

				otherFeatures: product?.loanFeatures.map((x) => x.label).join(', ')
			}
		};
	}

	static getLoanTermText(product: Product | undefined): string {
		// period is now always in months
		if (product?.loanPeriod) {
			const years = Math.floor(product.loanPeriod / 12);
			const months = product.loanPeriod - years * 12;
			if (months > 0) {
				return `${years} ${formatPlural(years ?? 0, 'year', 'years')} ${months} ${formatPlural(
					months ?? 0,
					'month',
					'months'
				)}`;
			} else {
				return `${years ?? 0} ${formatPlural(years ?? 0, 'year', 'years')}`;
			}
		}
		return '';
	}

	static getPaymentType(product: Product | undefined, formEnumsQuery: FormEnumsQuery): string {
		const months =
			product?.paymentType === PaymentType.InterestOnly
				? product.interestOnlyPeriod
				: product?.paymentType === PaymentType.PrepaidInterest
				? product.prepaidInterestPeriod
				: null;
		const termText = months ? `(${getYearsAndMonthsText(months ?? 0)})` : '';
		return product?.paymentType
			? `${formEnumsQuery.getOptionLabel('PaymentTypeNewLoan', product.paymentType)} ${termText} `
			: '';
	}

	static getReasonForBorrowing(borrowingInfo: BorrowingInfo): number | undefined {
		if (borrowingInfo.primaryLendingPurpose) {
			return borrowingInfo.primaryLendingPurpose;
		}

		if (borrowingInfo.lendingPurpose?.[0]?.primaryLendingPurpose) {
			return borrowingInfo.lendingPurpose[0].primaryLendingPurpose;
		}

		return undefined;
	}
}

export interface LoanPurposeAndCustomerConversation {
	loanSplitsArray: LoanSplit[];
	fum: { totalFUMAmount?: number };
}

interface LoanSplit {
	loanSplits: {
		reasonForBorrowing?: RbaLendingPurpose | null;
		loanType?: PrimaryPurposeLoanPurpose | null;
		borrowingAmount?: number;
		loanTerm: string;
		interestType: string;
		paymentType: string;
		repaymentFrequency?: string;
		otherFeatures?: string;
	};
}
