import { Injectable } from '@angular/core';
import { PrimaryPurposeRealEstateAsset, ResidentialType } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { DeclaredExpenseDTO, Household } from '@app/modules/typings/api';
import { Observable, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';

import { RefiExpensesTransformer, RefiHousehold } from '../../models/refi-expenses.model';
import { RefiAssetsService } from '../assets/refi-assets.service';

@Injectable({
	providedIn: 'root'
})
export class RefiExpensesService extends BaseJourneyService<any> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsService: FormEnumsService,
		private formEnumsQuery: FormEnumsQuery,
		private formDataService: FormDataService,
		private refiAssetsService: RefiAssetsService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.formDataService.upsertStateWithAsyncData(RefiStore.Expenses, of([]));
		this.formDataService.setLoading(RefiStore.Expenses, true);
		this.simpFormlyHandlerService.upsertToState('details', this.fetchHouseholds());
	}

	public fetchHouseholds() {
		return this.get(`Household/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((households: Household[]) => {
				households.forEach((household) => {
					if (household && household.id) {
						this.formEnumsService.addToHouseholdsEnum(household.id, '');
					}
				});
			}),
			switchMap((households) => {
				if (households.length) {
					return this.fetchExpenses(households);
				} else {
					return of();
				}
			})
		);
	}

	public saveHousehold(household: RefiHousehold): Observable<number> {
		if (household.name) {
			household.name = household.name.replace(/[^0-9a-zA-Z]/g, '');
		}

		const payload = RefiExpensesTransformer.toHouseholdPayload(household);

		return this.postCustom(`Household/`, payload).pipe(
			tap((id: number) => {
				this.formEnumsService.addToHouseholdsEnum(id, '');
			})
		);
	}

	public fetchExpenses(household: Household[]): Observable<any> {
		const livingExpenseCategory = this.formEnumsQuery.getOptions('LivingExpenseCategory');
		const otherCommitmentCategory = this.formEnumsQuery.getOptions('OtherCommitmentCategory').map((item) => {
			return { ...item, id: item.id + 2000 }; // Add 2000 to OtherCommitmentCategory enums to differentiate from LivingExpenseCategory
		});
		const fullExpenseCategories = [...livingExpenseCategory, ...otherCommitmentCategory];
		return forkJoin([
			this.refiAssetsService.getPrimaryProperty(),
			this.getCustom(`Expense/${this.applicationDataQuery.applicationId()}/`),
			this.fetchHEMRangeSummary()
		]).pipe(
			map(([mainProperty, expenseData, hemRangeSummary]) => {
				const ownerOccupied = mainProperty?.primaryPurpose === PrimaryPurposeRealEstateAsset.OwnerOccupied;
				const insuranceRequired =
					ownerOccupied &&
					(mainProperty?.propertyType === ResidentialType.FullyDetachedHouse ||
						mainProperty?.propertyType === ResidentialType.Duplex);

				const expenses = expenseData as DeclaredExpenseDTO[];
				return RefiExpensesTransformer.fromPayload(
					expenses,
					household[0],
					fullExpenseCategories,
					ownerOccupied,
					insuranceRequired,
					hemRangeSummary
				);
			})
		);
	}

	public deleteHousehold(id: number): void {
		this.delete(`Household/${this.applicationDataQuery.applicationId()}/${id}`).subscribe();
	}

	public saveExpense(payload: DeclaredExpenseDTO[]): Observable<any> {
		return this.postCustom(`Expense`, payload);
	}

	public fetchHEMRangeSummary(): Observable<HEMRangeSummary> {
		return this.getCustom(
			`/Expense/ExpenseRangeSummary/${this.applicationDataQuery.getApplicationJourneyId()}/${this.applicationDataQuery.applicationId()}`
		) as Observable<HEMRangeSummary>;
	}
}

export interface HEMRangeResponse {
	categoryId: number;
	floorAmount: number;
	ceilingAmount: number;
}

export interface HEMRangeSummary {
	totalCeiling: number;
	totalFloor: number;
	expenseRangesByCategory: HEMRangeResponse[];
}
