import { Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { formlyOnClick, formlyRegisterHooks, getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiHomeLoanDetails, RefiHomeLoansTransformer } from '../../models/refi-home-loans.model';
import { RefiJourneyLoanRequirementService } from '../loan-requirements/refi-loan-requirements.service';
import { RefiJourneyService } from '../refi-journey.service';

@Injectable({
	providedIn: 'root'
})
export class RefiLoanDetailsTransformerService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumQuery: FormEnumsQuery,
		private journeyStepService: JourneyStepService,
		private refiJourneyService: RefiJourneyService,
		private loanRequirementsService: RefiJourneyLoanRequirementService,
		private refiModalService: RefiModalService
	) {}

	loanDetailsTransform(formFields: FormlyFieldConfig[]) {
		// Only disable if pre-populated from CCR
		['currentLender', 'loanLimit', 'accountNumber', 'loanEstablishedDate'].forEach((field) => {
			formlyRegisterHooks(formFields, `loanDetails.details.${field}`, {
				onInit: (formField: FormlyFieldConfig | undefined) => {
					if (formField?.formControl?.value) {
						formField.formControl.disable();
					}
				}
			});
		});

		formlyRegisterHooks(formFields, 'loanDetails.details.isLoanTaxDeductible', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyOnClick(
			formFields,
			'loanDetails.details',
			(field, clickType: { type: string; invalid: boolean; changed: boolean }) => {
				if (clickType.type === 'primary' && !clickType.invalid) {
					this.loanRequirementsService
						.saveMainLoanRequirements(
							RefiHomeLoansTransformer.toPayload(
								(field.model as RefiHomeLoanDetails[])?.[0],
								this.applicationDataQuery.applicationId(),
								this.formEnumQuery
							)
						)
						.pipe(
							switchMap(() =>
								forkJoin([
									this.journeyStepService.updateStep(RefiStepType.RefiChooseLoan, StepStatus.Complete),
									this.journeyStepService.updateStep(RefiStepType.RefiLoanDetails, StepStatus.Complete)
								])
							)
						)
						.subscribe(() => {
							this.refiJourneyService.navigateToNextStep(field);
						});
				}
			}
		);

		formlyOnClick(formFields, `loanDetails.details.loanBalance`, (field, clickType: { type: string }) => {
			if (clickType?.type === 'tooltipClick') {
				this.openInfoModal(formFields, RefiModalName.currentLoanBalance, 'eligibility.refi-modals');
			}
		});

		formlyOnClick(formFields, `loanDetails.details.isLoanTaxDeductible`, (field, clickType: { type: string }) => {
			if (clickType?.type === 'tooltipClick') {
				this.openInfoModal(formFields, RefiModalName.isTaxDeductible);
			}
		});
	}

	private openInfoModal(
		formFields: FormlyFieldConfig[] | undefined,
		refiModalName: RefiModalName,
		path = 'loanRequirements.info-modals'
	): Observable<string> {
		const refiModalField = getFormField(formFields, path);
		return this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}
}
