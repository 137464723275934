import { ApplicantEmploymentModel } from '@app/modules/financial-position/model/applicant-employment.model';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { Address, RetirementDTO, RetirementDTODetails } from '@app/modules/typings/api';

export class RetirementDetailsTransformer {
	static toPayload(applicationId: number, model: RetirementModel): RetirementDTO {
		return {
			additonalNotes: model.retirementDetails[0].retirementAdditionalNotesInput,
			isAnyAdditonalNotes: model.retirementDetails[0].retirementAdditionalNotes,
			applicationId: applicationId,
			details: this.getRetirementDetails(model.retirementDetails[0]),
			saleOfAssets: (model.retirementDetails[0].assetsList || []).map((x) => {
				return {
					applicationId: applicationId,
					id: x.id,
					property: {
						id: x.propetyId,
						address: x.assetAddress
					},
					sellAsset: getNullableBoolean(x.saleOfAsset)
				};
			})
		};
	}
	static getRetirementDetails(details: RetirementDetails): RetirementDTODetails[] | undefined {
		const retirementDetails: RetirementDTODetails[] = [];
		details.applicants?.applicantsList.forEach((element, index) => {
			let retirementId: number = CONSTANTS.NEW_ID;
			if (details.id) {
				retirementId = details.id[index];
			}
			const applicantRetirementDetails: RetirementDTODetails = {
				applicantId: details.applicants?.applicantsList[index].applicantId,
				downsizingHome: getNullableBoolean(details.downsizingHome?.yesNo?.[index].answer),
				incomeFromCoApplicant: getNullableBoolean(details.incomeFromCoApplicant?.yesNo?.[index].answer),
				saleOfAssets: getNullableBoolean(details.saleOfAssets?.yesNo?.[index].answer),
				incomeFromOtherInvestments: getNullableBoolean(details.incomeFromOtherInvestments?.yesNo?.[index].answer),
				lumpSumFromSuper: getNullableBoolean(details.lumpSumFromSuper?.yesNo?.[index].answer),
				saving: getNullableBoolean(details.saving?.yesNo?.[index].answer),
				recurringIncomeFromSuper: getNullableBoolean(details.recurringIncomeFromSuper?.yesNo?.[index].answer),
				repaymentPriorToRetirement: getNullableBoolean(details.repaymentPriorToRetirement?.yesNo?.[index].answer),
				understandsImpactOnRetirementPlans: getNullableBoolean(
					details.understandsImpactOnRetirementPlans?.yesNo?.[index].answer
				),
				ageAtEndOfLoan: details.ageAtEndOfLoan?.ageList?.[index].age ?? 0,
				intendedRetirementAgeDetails: details.intendedRetirementAgeDetails?.reasonsList?.[index].reason ?? '',
				description: details.repaymentOptionsDescriptions?.descriptionsList?.[index].description ?? '',
				id: retirementId,
				intendedRetirementAge: details.ages?.ageList?.[index].age ?? 0,
				isImpactOnRetirementClear: undefined
			};

			retirementDetails.push(applicantRetirementDetails);
		});
		return retirementDetails;
	}

	static fromPayload(
		result: RetirementResultDTO,
		applicants: ShortApplicant[],
		targetDate?: string | null,
		maxLoanPeriodInYears?: number
	): RetirementModel {
		const retirementData = result.retirement as RetirementDTO;
		const retirementDetails: RetirementDetails = {
			ages: {
				ageList: this.getAgeList(retirementData, applicants)
			},
			applicants: {
				applicantsList:
					applicants?.map((applicant) => {
						return {
							applicantId: applicant.id,
							applicant: applicant.name,
							employments:
								result.allApplicantsEmployments?.find((applicantEmployments) =>
									applicantEmployments.some((employment) => employment.employmentDetails.applicantId === applicant.id)
								) ?? []
						};
					}) ?? null
			},
			assetsList: retirementData.saleOfAssets?.map((x) => {
				return (
					{
						assetAddress: x.property?.address,
						saleOfAsset: getNullableYesNo(x.sellAsset),
						id: x.id,
						propetyId: x.property?.id
					} ??
					result.propertyAssets.map((propAddress) => {
						return {
							id: CONSTANTS.NEW_ID,
							assetAddress: propAddress.address,
							saleOfAsset: undefined,
							propertyId: propAddress.id
						};
					})
				);
			}),
			id: retirementData.details?.map((x) => x.id!),
			repaymentPriorToRetirement: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.repaymentPriorToRetirement)
					};
				})
			},
			understandsImpactOnRetirementPlans: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.repaymentPriorToRetirement)
					};
				})
			},
			ageAtEndOfLoan: {
				ageList: this.getAgeList(retirementData, applicants, targetDate, 'ageAtEndOfLoan', maxLoanPeriodInYears)
			},
			intendedRetirementAgeDetails: {
				reasonsList: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						reason: applicantData?.intendedRetirementAgeDetails
					};
				})
			},
			repaymentOptionsDescriptions: {
				descriptionsList: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						description: applicantData?.description
					};
				})
			},
			downsizingHome: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.downsizingHome)
					};
				})
			},
			recurringIncomeFromSuper: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.recurringIncomeFromSuper)
					};
				})
			},
			incomeFromCoApplicant: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.incomeFromCoApplicant)
					};
				})
			},
			saleOfAssets: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.saleOfAssets)
					};
				})
			},
			incomeFromOtherInvestments: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.incomeFromOtherInvestments)
					};
				})
			},
			lumpSumFromSuper: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.lumpSumFromSuper)
					};
				})
			},
			saving: {
				yesNo: applicants.map((x) => {
					const applicantData = retirementData.details?.find((y) => y.applicantId == x.id) as RetirementDTODetails;
					return {
						answer: getNullableYesNo(applicantData?.saving)
					};
				})
			},
			retirementAdditionalNotes: retirementData.isAnyAdditonalNotes,
			retirementAdditionalNotesInput: retirementData.additonalNotes
		};
		const model: RetirementModel = { retirementDetails: [retirementDetails] };
		return model;
	}

	private static getAgeList(
		retirementData: RetirementDTO,
		applicants: ShortApplicant[],
		targetDate?: string | null,
		type?: string,
		maxLoanPeriodInYears?: number
	): { age: number | undefined }[] {
		return applicants.map((applicant) => {
			const applicantRetirementDetails = retirementData.details?.find((r) => r.applicantId === applicant.id);

			if (applicantRetirementDetails) {
				if (type === 'ageAtEndOfLoan') {
					return { age: applicantRetirementDetails.ageAtEndOfLoan as number };
				}
				return { age: applicantRetirementDetails.intendedRetirementAge as number };
			}

			// Set default value for ageAtEndOfLoan if retirement details not found
			if (type === 'ageAtEndOfLoan' && applicant.dateOfBirth && targetDate) {
				return {
					age: this.getLoanYearAndAgeDifference(targetDate, applicant.dateOfBirth) + (maxLoanPeriodInYears ?? 0)
				};
			}

			return { age: undefined };
		});
	}

	private static getLoanYearAndAgeDifference(targetDate: string, dateOfBirth: string) {
		const loanDate = new Date(targetDate);
		const birthDate = new Date(dateOfBirth);
		let age = loanDate.getFullYear() - birthDate.getFullYear();
		const monthDifference = loanDate.getMonth() - birthDate.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && loanDate.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
}

export class RetirementModel {
	id?: number;
	retirementDetails: RetirementDetails[] = [];
}
class RetirementDetails {
	id?: number[];
	ages?: { ageList?: { age: number | undefined }[] };
	repaymentPriorToRetirement?: { yesNo: { answer?: YesNo }[] };
	understandsImpactOnRetirementPlans?: { yesNo: { answer?: YesNo }[] };
	ageAtEndOfLoan?: { ageList?: { age: number | undefined }[] };
	intendedRetirementAgeDetails?: { reasonsList?: { reason: string | undefined }[] };
	repaymentOptionsDescriptions?: { descriptionsList?: { description: string | undefined }[] };
	downsizingHome?: { yesNo: { answer?: YesNo }[] };
	incomeFromCoApplicant?: { yesNo: { answer?: YesNo }[] };
	saleOfAssets?: { yesNo: { answer?: YesNo }[] };
	incomeFromOtherInvestments?: { yesNo: { answer?: YesNo }[] };
	lumpSumFromSuper?: { yesNo: { answer?: YesNo }[] };
	saving?: { yesNo: { answer?: YesNo }[] };
	recurringIncomeFromSuper?: { yesNo: { answer?: YesNo }[] };
	applicants?: {
		applicantsList: {
			applicantId: number;
			applicant: string;
			employments?: ApplicantEmploymentModel[];
		}[];
	};
	assetsList?: { id?: number; assetAddress?: Address; saleOfAsset?: YesNo; propetyId?: number }[];
	retirementAdditionalNotes?: boolean;
	retirementAdditionalNotesInput?: string;
}

export interface RetirementResultDTO {
	retirement: RetirementDTO | null;
	propertyAssets: {
		id: number;
		address: Address;
	}[];
	allApplicantsEmployments?: ApplicantEmploymentModel[][];
}
