/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ForeignTaxAssociationDTO } from '@app/modules/typings/api';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { BaseJourneyService } from './base-journey.service';

@Injectable({
	providedIn: 'root'
})
export class ForeignTaxAssociationService extends BaseJourneyService {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute();
	}

	getForeignTaxAssociations(foreignTaxAssociationId: number): Observable<ForeignTaxAssociationDTO> {
		return <Observable<ForeignTaxAssociationDTO>>(
			this.getCustom(`ForeignTaxAssociation/${this.applicationDataQuery.applicationId()}/${foreignTaxAssociationId}`)
		);
	}

	saveForeignTaxAssociation(foreignTaxAssociationDTO: ForeignTaxAssociationDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`ForeignTaxAssociation`, foreignTaxAssociationDTO);
	}
}
