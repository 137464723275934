<div
	[ngClass]="to.customClass"
	class="product-selector form-group"
	[class.product-selector--selected]="!!formControl.value"
	(click)="select()"
	[id]="uniqueId"
	metadataKey
	[formlyField]="field"
>
	<div class="product-selector__indicator"></div>
	<p class="product-selector__label simp-label">{{ to.label }}</p>
</div>
