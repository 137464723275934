import { Injectable } from '@angular/core';
import { RefiStepType } from '@app/modules/refi-journey/enums/refi-steps.enum';
import { StepResponse } from '@app/modules/typings/api';
import { Observable } from 'rxjs';
import { StepStatus } from '../../enums/app.enums';
import { StepStatusStore } from './step-status.store';
import { StepStatusState } from './typings/step-status';

@Injectable({ providedIn: 'root' })
export class StepStatusQuery {
	constructor(protected store: StepStatusStore) {}

	getValue(): StepStatusState {
		return this.store.getStoreValue();
	}

	stepStatuses = (): StepResponse[] => {
		return this.getValue().data;
	};

	getStepStatus = (stepType: RefiStepType): StepStatus => {
		return this.getValue().data?.find((step) => step.type === stepType)?.status || StepStatus.Incomplete;
	};

	isCompleted = (stepType: RefiStepType): boolean => {
		return this.getValue().data?.find((step) => step.type === stepType)?.status === StepStatus.Complete;
	};

	getStep = (stepType: RefiStepType): StepResponse | undefined => {
		return this.getValue().data?.find((step) => step.type === stepType);
	};

	getStepParentStatusIndex = (stepType: RefiStepType): number | undefined => {
		return this.getValue().data?.find((step) => step.type === stepType)?.parentStatusIndex;
	};

	getStepStatus$<T = unknown>(): Observable<StepResponse[]> {
		return this.store.getStoreDataAsObservable();
	}
}
