import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';
import { InputActionType } from '@app/modules/shared/enums/app.enums';

/**
 * Sample Datepicker
 * {
 className: 'col-2',
 type: 'datepicker',
 key: 'dateOfBirth',
 validation: {
 messages: { invalid: 'Enter a valid date' }
 },
 templateOptions: {
 label: 'Date of birth',
 required: true,
 disabled: true
 }
 },
 */
@Component({
	selector: 'formly-datepicker',
	templateUrl: './formly-datepicker.component.html',
	styleUrls: ['./formly-datepicker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDatepickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
	defaultMinDate = { year: 1900, month: 1, day: 1 };
	defaultMaxDate = { year: 2099, month: 12, day: 31 };
	uniqueId = '';

	ngOnInit(): void {
		this.uniqueId = generateUniqueKey(this.field);

		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
	}

	handleEditClick(): void {
		if (this.props.click) {
			this.props.click(this.field, InputActionType.EDIT);
		}
	}

	tooltipClick(field: FormlyFieldConfig) {
		if (this.to.click) {
			this.to.click(field, { type: 'tooltipClick' });
		}
	}
}
