import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';

import { DatePipe } from '@angular/common';
import {
	ApplicantPaygModel,
	IncomeModel,
	IncomePeriodModel
} from '@app/modules/financial-position/model/applicant-employment.model';
import { ForeignEmploymentModel } from '@app/modules/financial-position/model/foreign-employment.model';
import {
	formlyExtendExpressionProperties,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FrequencyShort } from '@simpology/client-components/utils';
import { Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { EmploymentIncomeType, RelatedCompanyType, TargetType } from '../../enums/app.enums';
import { ApplicantEnumObject } from '../../enums/enum-helper';
import { PersonsCompaniesEnumService } from '../../service/persons-companies-enum.service';
import { RelatedEntitiesService } from '../../service/related-entities.service';
import { FormEnumsQuery } from '../../store/form-enums/form-enums.query';

@Injectable({
	providedIn: 'root'
})
export class EmployersTransformerService {
	constructor(
		private simpFormlyModalService: SimpFormlyModalService,
		private formEnumsQuery: FormEnumsQuery,
		private relatedEntitiesService: RelatedEntitiesService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private datePipe: DatePipe
	) {}

	configureEmployerModal(formFields: FormlyFieldConfig[] | undefined, path: string): void {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();

		const defaultStartDate = `${month >= 7 ? year : year - 1}-07-01`;
		const defaultEndDate = `${year}-${month}-${day}`;
		formlyExtendExpressionProperties(formFields, `${path}.incomePeriodModal.startDate`, {
			'templateOptions.defaultValue': (model, formState, f: FormlyFieldConfig) => {
				return (f?.templateOptions?.defaultValue as string) ?? defaultStartDate;
			}
		});

		formlyExtendExpressionProperties(formFields, `${path}.incomePeriodModal.endDate`, {
			'templateOptions.defaultValue': (model, formState, f: FormlyFieldConfig) => {
				return (f?.templateOptions?.defaultValue as string) ?? defaultEndDate;
			}
		});

		formlyExtendExpressionProperties(formFields, `${path}.income.type`, {
			// Only allow one salary row (TAMA5-8402)
			'templateOptions.options': (model: IncomeModel, formState, field: FormlyFieldConfig) => {
				const numSalary = (field.parent?.parent?.model as IncomeModel[])?.filter(
					(income) => income?.type === EmploymentIncomeType.Salary
				).length;
				return this.formEnumsQuery.selectEnumOptions('EmploymentIncomeType').pipe(
					map((options) =>
						options?.filter(
							(option) =>
								option.id !== EmploymentIncomeType.Salary ||
								numSalary === 0 ||
								(model && model.type === EmploymentIncomeType.Salary)
						)
					),
					map((options) => {
						if (field?.templateOptions?.handler === 'oneInstanceOfTypeOnly') {
							const selectedTypes = field.parent?.parent?.model as IncomeModel[];
							return options?.filter((option) => {
								if (model && model.type !== option.id) {
									return !selectedTypes.map((x) => x?.type)?.includes(option.id);
								} else {
									return option;
								}
							});
						} else {
							return options;
						}
					})
				);
			}
		});

		formlyExtendExpressionProperties(formFields, `${path}.income.frequency`, {
			'templateOptions.options': (model: IncomeModel, formState, field: FormlyFieldConfig) => {
				const incomePeriodModalField = getFormField(field.parent?.parent?.parent?.fieldGroup, 'incomePeriodModal');
				return this.formEnumsQuery.selectEnumOptions('FrequencyShort').pipe(
					map((options) =>
						options?.map((option) =>
							option.id === FrequencyShort.YearToDate
								? {
										...option,
										label: !option.label.includes('icon')
											? "<i class='icon fal fa-calendar-alt'></i>" + option.label
											: option.label,
										class: 'item-wrapper--top-border',
										selectedClass: 'item-wrapper--small-text',
										selectedLabel: `YTD ${this.datePipe.transform(
											(incomePeriodModalField?.formControl?.value as IncomePeriodModel)?.startDate,
											'dd/MM/yyyy'
										)} - ${this.datePipe.transform(
											(incomePeriodModalField?.formControl?.value as IncomePeriodModel)?.endDate,
											'dd/MM/yyyy'
										)}`,
										click: () => {
											if (field.parent?.parent?.parent) {
												const oldValues = incomePeriodModalField?.formControl?.value as IncomePeriodModel;
												const modalRef = this.simpFormlyModalService.openModal(
													field.parent?.parent?.parent,
													'incomePeriodModal',
													{
														windowClass: 'simp-padding-left-small simp-modal-confirm',
														size: 'md'
													}
												);
												const subscription = modalRef.action.subscribe((action) => {
													if (action !== 'submit') {
														incomePeriodModalField?.formControl?.setValue(oldValues);
													}
													modalRef.close();
													subscription.unsubscribe();
												});
											}
										}
								  }
								: option
						)
					)
				);
			}
		});

		formlyRegisterHooks(formFields, `${path}.income.frequency`, {
			onInit: (field) => {
				const incomeFormControl = field?.parent?.parent?.formControl;
				const salaryFrequency = (incomeFormControl?.value as IncomeModel[])?.find(
					// Set new row frequencies to salary frequency TAMA5-8402
					(income) => income?.type === EmploymentIncomeType.Salary
				)?.frequency;

				if (field?.templateOptions && salaryFrequency) {
					field.templateOptions.defaultValue = salaryFrequency;
				}

				if (field && field.templateOptions) {
					return forkJoin([
						field.formControl?.valueChanges.pipe(
							startWith(field.formControl.value as FrequencyShort),
							pairwise<FrequencyShort>(),
							tap(([prev, next]: [FrequencyShort, FrequencyShort]) => {
								if (next === FrequencyShort.YearToDate) {
									const startDate =
										(getFormField(field.parent?.parent?.parent?.fieldGroup, 'incomePeriodModal.startDate')
											?.templateOptions?.defaultValue as string) ?? defaultStartDate;
									const endDate =
										(getFormField(field.parent?.parent?.parent?.fieldGroup, 'incomePeriodModal.endDate')
											?.templateOptions?.defaultValue as string) ?? defaultEndDate;

									if (!(field.parent?.parent?.parent?.model as ApplicantPaygModel)?.incomePeriodModal) {
										field.parent?.parent?.parent?.formControl?.patchValue({
											incomePeriodModal: {
												startDate,
												endDate
											}
										});
									}
								}
							})
						),
						incomeFormControl?.valueChanges.pipe(
							pairwise<IncomeModel[]>(),
							map(([prev, next]: [IncomeModel[], IncomeModel[]]) => {
								const prevSalaryRow = prev.find((income) => income?.type === EmploymentIncomeType.Salary);
								const nextSalaryRow = next.find((income) => income?.type === EmploymentIncomeType.Salary);
								const incomesModelValue = incomeFormControl.value as IncomeModel[];

								if (prevSalaryRow && !nextSalaryRow) {
									// Uncheck included in base if salary record deleted (TAMA5-8402)
									incomeFormControl.patchValue(
										incomesModelValue.map((income) => ({
											...income,
											frequency:
												income.includedInBase && income.frequency === FrequencyShort.YearToDate
													? FrequencyShort.Yearly
													: income.frequency,
											includedInBase: income.includedInBase ? false : income.includedInBase
										})),
										{ onlySelf: true }
									);
								}
							})
						)
					]);
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, `${path}.employerDetailsSelect`, {
			onInit: (field) => {
				const relatedCompanyType = path.includes('foreignEmployment')
					? RelatedCompanyType.Foreign
					: RelatedCompanyType.Payg;
				const relatedCompanyTypeEnum = path.includes('foreignEmployment') ? 'ForeignCompanies' : 'PAYGCompanies';
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions('AddNewCompany');
					field.templateOptions.options = combineLatest([
						this.formEnumsQuery.selectEnumOptions('CompanyApplicants'),
						this.formEnumsQuery.selectEnumOptions(relatedCompanyTypeEnum)
					]).pipe(
						map(([applicants, related]) => [
							// -1 ID is for new related persons, -2 for new related companies
							...fieldOptions.map((existing) => (existing.id === -1 ? { ...existing, id: -2 } : existing)),
							...applicants.concat(related).map((company) => ({
								...company,
								click:
									(company.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													field,
													(field.model as ForeignEmploymentModel).ownership || 0,
													this.saveField,
													'employerDetailsSelect',
													'relatedCompany',
													relatedCompanyType
												)
										: null
							}))
						])
					);

					return this.relatedEntitiesService
						.configureRelatedEntityModal(
							field,
							(field.model as ForeignEmploymentModel).ownership || 0,
							this.saveField,
							'employerDetailsSelect',
							'relatedCompany',
							RelatedCompanyType.Payg
						)
						.pipe(
							switchMap((company: [ApplicantEnumObject, ApplicantEnumObject]) =>
								company.length > 1 && company[1]?.type === TargetType.RelatedCompany
									? this.personsCompaniesEnumService.getRelatedCompany(company[1].id)
									: of(undefined)
							)
						);
				}

				return of();
			}
		});
	}

	private saveField = (
		model: ForeignEmploymentModel,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(model.employerDetailsSelect);

		return new Subscription();
	};
}
