/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SimpSpinnerService } from '@simpology/client-components';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { elfHooks } from '@ngneat/elf';
import { IntercomService } from '@simpology/authentication';
import { PostLoginService } from './modules/shared/service/post-login.service';
import { ChannelSettingQuery } from './modules/shared/store/channel-setting/channel-setting.query';
import { FormStateService } from './modules/shared/store/form-state/form-state.service';
import { routeTransitionAnimations, routeTransitionSlideAnimations } from './route-transition-animations';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routeTransitionAnimations, routeTransitionSlideAnimations],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private destroy$: Subject<void> = new Subject();

	constructor(
		private postLoginService: PostLoginService,
		private router: Router,
		private simpSpinnerService: SimpSpinnerService,
		private formStateService: FormStateService,
		private intercomService: IntercomService,
		private channelSettingQuery: ChannelSettingQuery,
		@Inject(DOCUMENT) private document: Document
	) {
		this.simpSpinnerService.loading$ = this.loading.asObservable();
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			if (event instanceof NavigationStart && this.router.url === '/') {
				// this only runs on page load and not while navigating between routes
				this.loading.next(true);
			} else if (
				event instanceof NavigationEnd ||
				event instanceof NavigationCancel ||
				event instanceof NavigationError
			) {
				this.loading.next(false);
				if (this.channelSettingQuery.isIntercomEnabled()) {
					this.intercomService.updateIntercom();
				}
			}
		});
	}

	ngOnInit(): void {
		this.addNewRelic();
		if (this.channelSettingQuery.isIntercomEnabled()) {
			this.intercomService.injectIntercomScript();
		}
		elfHooks.registerPreStoreUpdate((currentState: any, nextState: any, storeName: string) => {
			this.formStateService.updateFormStateForStoreUpdates(nextState, storeName);
			return nextState;
		});
		this.postLoginService.setupLogin();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private addNewRelic(): void {
		if (!environment.local) {
			const script = this.document.createElement('script');
			script.type = `text/javascript`;
			script.src = 'assets/scripts/newrelic.min.js';
			const head = document.getElementsByTagName('head')[0];
			head.appendChild(script);
		}
	}
}
