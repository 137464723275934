<simp-percentage-input
	[id]="uniqueId"
	[formControl]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[min]="to.min || 0"
	[max]="to.max || 100"
	[readonly]="!!to.readonly"
	[decimalPlaces]="to.decimalPlaces || 0"
	[tooltipContent]="to.tooltip"
	[tooltipAsModal]="to.tooltipAsModal"
	[simpleView]="to.simpleView"
	(tooltipClick)="tooltipClick(field)"
	metadataKey
	[formlyField]="field"
>
	{{ to.label }}
</simp-percentage-input>
