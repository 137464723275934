import { Importance, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { LineOfCreditDTO } from '@app/modules/typings/api';

export class LineOfCreditTransformer {
	static toPayload(applicationId: number, details: LineOfCreditModel): LineOfCreditDTO {
		return {
			applicationId: applicationId,
			importance: details.lineOfCredit,
			reasonAllowsAccessToFundsAtAnyTime: getNullableBoolean(details.lineOfCreditExtras?.accessToFundsYesNo),
			reasonAnticipatedVariableCashFlows: getNullableBoolean(details.lineOfCreditExtras?.variableCashFlowsYesNo),
			risksExplained: details.confirmCreditRisk,
			reasonAssistsWithInvestmentPurchasesAndTaxPlanning: getNullableBoolean(
				details.lineOfCreditExtras?.assistsInvestmentTaxYesNo
			),
			reasonDescription: details.lineOfCreditExtras?.lineOfCreditOtherInput,
			reasonFlexibilityToManageRepayments: getNullableBoolean(details.lineOfCreditExtras?.repaymentFlexibilityYesNo),
			reasonNoNeedToApplyForFurtherLendingInFuture: getNullableBoolean(
				details.lineOfCreditExtras?.needFutureLendingYesNo
			),
			reasonOngoingNeedForFunds: getNullableBoolean(details.lineOfCreditExtras?.needFutureLendingYesNo),
			repaymentPlanCoApplicantIncome: getNullableBoolean(details.lineOfCreditExtras?.coApplicantIncomeYesNo),
			repaymentPlanDescription: details.lineOfCreditExtras?.lineOfCreditOtherInput,
			repaymentPlanDownsizing: getNullableBoolean(details.lineOfCreditExtras?.downsizingYesNo),
			repaymentPlanIncomeFromOtherInvestments: getNullableBoolean(details.lineOfCreditExtras?.incomeOtherAssetsYesNo),
			repaymentPlanRepaymentOfLoanPriorToEndOfTerm: getNullableBoolean(details.lineOfCreditExtras?.earlyRepaymentYesNo),
			repaymentPlanSaleOfAssets: getNullableBoolean(details.lineOfCreditExtras?.saleOfAssetsYesNo),
			repaymentPlanSavings: getNullableBoolean(details.lineOfCreditExtras?.savingsYesNo),
			reasonOther: !!details.lineOfCreditExtras?.lineOfCreditOtherInput
		};
	}

	static fromPayload(data: LineOfCreditDTO): LineOfCreditModel {
		const details: LineOfCreditModel = {
			confirmCreditRisk: data.risksExplained!,
			lineOfCredit: data.importance!,
			lineOfCreditExtras: {
				lineOfCreditOtherInput: data.reasonDescription!,
				repaymentOtherInput: data.repaymentPlanDescription!,
				accessToFundsYesNo: getNullableYesNo(data.reasonAllowsAccessToFundsAtAnyTime),
				assistsInvestmentTaxYesNo: getNullableYesNo(data.reasonAssistsWithInvestmentPurchasesAndTaxPlanning),
				savingsYesNo: getNullableYesNo(data.repaymentPlanSavings),
				saleOfAssetsYesNo: getNullableYesNo(data.repaymentPlanSaleOfAssets),
				earlyRepaymentYesNo: getNullableYesNo(data.repaymentPlanRepaymentOfLoanPriorToEndOfTerm),
				incomeOtherAssetsYesNo: getNullableYesNo(data.repaymentPlanIncomeFromOtherInvestments),
				downsizingYesNo: getNullableYesNo(data.repaymentPlanDownsizing),
				coApplicantIncomeYesNo: getNullableYesNo(data.repaymentPlanCoApplicantIncome),
				needFutureLendingYesNo: getNullableYesNo(data.reasonNoNeedToApplyForFurtherLendingInFuture),
				repaymentFlexibilityYesNo: getNullableYesNo(data.reasonFlexibilityToManageRepayments),
				variableCashFlowsYesNo: getNullableYesNo(data.reasonAnticipatedVariableCashFlows),
				ongoingNeedForFundsYesNo: getNullableYesNo(data.reasonOngoingNeedForFunds)
			}
		};
		return details;
	}
}

interface LineOfCreditExtras {
	earlyRepaymentYesNo?: YesNo;
	downsizingYesNo?: YesNo;
	saleOfAssetsYesNo?: YesNo;
	savingsYesNo?: YesNo;
	incomeOtherAssetsYesNo?: YesNo;
	coApplicantIncomeYesNo?: YesNo;
	repaymentOtherInput: string;
	assistsInvestmentTaxYesNo?: YesNo;
	variableCashFlowsYesNo?: YesNo;
	accessToFundsYesNo?: YesNo;
	repaymentFlexibilityYesNo?: YesNo;
	needFutureLendingYesNo?: YesNo;
	ongoingNeedForFundsYesNo?: YesNo;
	lineOfCreditOtherInput: string;
}

export interface LineOfCreditModel {
	lineOfCredit: Importance;
	confirmCreditRisk: boolean;
	lineOfCreditExtras: LineOfCreditExtras;
}
export interface LineOfCreditStoreModel {
	lineOfCreditContainer: LineOfCreditModel;
}
