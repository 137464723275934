export const URL_CONSTANTS = {
	BY_PROVIDER_ID: 'api/ExtraAuth/ByProviderId',
	AREAS: 'formdata/areas/fullapplication',
	SCHEMA: 'formdata/schema',
	FORM_SCHEMA: 'FormSchema',
	LIST_OPTION: 'ListOption',
	OPTIONS_LIST: 'formdata/optionlist/fullapplication',
	ADDRESS: 'Address',
	CONSENT: 'Consent',
	APPLICATION: 'Application',
	ELIGIBILITY: 'Eligibility',
	VALUATION: 'Valuation',
	PRODUCT: 'Product',
	CONFIRM_APPLICANT_DETAILS: 'ConfirmApplicantDetails',
	LINKED_APPLICATION_DETAILS: 'Application/LinkedApplicationDetails',
	MANAGEMENT_PARTICIPANT: 'ManagementParticipant',
	AREA_VALIDATION: 'AreaValidation',
	REMOTE_VALIDATION: 'RemoteValidation',
	SETTINGS: 'Settings',
	LODGEMENT: 'Lodgement',
	DETAILED_COMMENTS: 'DetailedComments',
	HOUSEHOLD: 'Household',
	DOCUMENT_UPLOAD: 'Loanapp/Document',
	DOCUMENT: 'Document',
	ID_VERIFICATION_DATA: 'Loanapp/IdVerificationData',
	PROPERTY_INFORMATION: 'PropertyInformation',
	SSI_BANKING_DATA: 'Loanapp/BankingData',
	BANKING_DATA: 'BankingData',
	BANKING_DATA_CATEGORIZATION: 'BankingDataCategorization',
	CREDIT_CHECK: 'Loanapp/CreditCheck',
	DOCUMENT_GENERATION: 'Loanapp/DocumentGeneration',
	SSI_IMPORT_APPLICATION: 'SsiImportApplication',
	CHANNEL_USER: 'ChannelUser',
	EXTRA_AUTH: 'api/ExtraAuth',
	JOURNEY_SETTING: 'JourneySetting',
	LOAN_APP_RULE: 'LoanappRule',
	TEMPLATE_DOCUMENT: 'TemplateDocument',
	PROOF_OF_IDENTITY: 'ProofOfIdentity',
	CONSTRUCTION_DETAILS: 'ConstructionDetails',
	COMPANY_FINANCIALS: 'CompanyFinancials',
	META_JOURNEY_CONFIGURATION: 'JourneyConfiguration',
	REQUEST_SIGNATURE: 'RequestSignature',
	FORM_GENERATION: 'FormGeneration',
	ACCESS_SEEKER: 'Loanapp/AccessSeeker',
	FINGER_PRINT: 'Fingerprint',
	PROPERTY_VALUATION: 'PropertyValuation',
	CHANNEL: 'Channel',
	LMI_QUOTE: 'LmiQuote',
	LMI_APPLICATION: 'LmiApplication',
	SIDE_NAV: 'Area/Journey/SideNav',
	DIGITAL_SERVICE_FLOW: 'digitalserviceflow',
	JOURNEY: 'journey',
	EVENT: 'FullApplication/Application/Event',
	RELEASE_ACTIVE_SESSION: 'ReleaseActiveSession',
	CREATE_FOR_JOURNEY: 'CreateForJourney',
	APPLICATION_JOURNEY: 'FullApplication/Application/UpdateApplicationJourney',
	BANKING_DIGITAL_SERVICE_DATA: 'GetBankingDigitalServiceData'
};
