import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-input';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { isValidDateFormat } from '@app/modules/shared/helper/util';
import isNil from 'lodash-es/isNil';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

const equals = (value: NgbDateStruct, other?: NgbDateStruct) =>
	value && !!other && other.year === value.year && other.month === value.month && other.day === value.day;

const before = (value: NgbDateStruct, other?: NgbDateStruct) =>
	!value || !other
		? false
		: value.year === other.year
		? value.month === other.month
			? value.day === other.day
				? false
				: value.day < other.day
			: value.month < other.month
		: value.year < other.year;

const after = (value: NgbDateStruct, other?: NgbDateStruct) =>
	!value || !other
		? false
		: value.year === other.year
		? value.month === other.month
			? value.day === other.day
				? false
				: value.day > other.day
			: value.month > other.month
		: value.year > other.year;

@Component({
	selector: 'formly-date-range-picker',
	templateUrl: './formly-date-range-picker.component.html',
	styleUrls: ['./formly-date-range-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDateRangePickerComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
	@ViewChild('durationPicker') durationPicker!: NgbInputDatepicker;
	@ViewChild(NgModel) datePick!: NgModel;
	@ViewChild('durationInput') durationInput!: ElementRef;
	defaultMinDate = { year: 1900, month: 1, day: 1 };
	defaultMaxDate = { year: 2099, month: 12, day: 31 };
	fromDate?: NgbDateStruct;
	toDate?: NgbDateStruct;
	hoveredDate?: NgbDateStruct | null;
	uniqueId = '';

	constructor(
		private parserFormatter: NgbDateParserFormatter,
		private ngbDateAdapter: NgbDateAdapter<string>,
		private renderer: Renderer2
	) {
		super();
	}

	isHovered = (date: NgbDate): boolean | null | undefined =>
		this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
	isInside = (date: NgbDate): boolean => after(date, this.fromDate) && before(date, this.toDate);
	isFrom = (date: NgbDate): boolean => equals(date, this.fromDate);
	isTo = (date: NgbDate): boolean => equals(date, this.toDate);

	ngAfterViewInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.updateFormControlValue(this.to.defaultValue as string);
		}
		const parsed = this.getFormControlValue();
		if (this.formControl.value) {
			this.setErrors(parsed);
		}
		setTimeout(() => {
			this.renderer.setProperty(this.durationInput.nativeElement, 'value', parsed);
		});
	}

	toggleDatePicker(): void {
		this.durationPicker.toggle();
		this.durationPicker.navigateTo(this.fromDate);
	}

	updateFormControl(value: string): void {
		this.formControl.markAsTouched();
		if (value === this.getFormControlValue()) {
			return;
		} else if (value) {
			this.updateFormControlValue(value);
		} else {
			this.formControl.setValue(null);
		}
	}

	onDateSelection(date: NgbDateStruct): void {
		let parsed = '';
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
			this.toDate = date;
			this.durationPicker.close();
		} else {
			this.toDate = undefined;
			this.fromDate = date;
		}
		if (this.fromDate) {
			parsed += this.parserFormatter.format(this.fromDate);
		}
		if (this.toDate) {
			parsed += ' - ' + this.parserFormatter.format(this.toDate);
		}
		if (this.fromDate && this.toDate) {
			this.renderer.setProperty(this.durationInput.nativeElement, 'value', parsed);
			this.formControl.setValue([this.ngbDateAdapter.toModel(this.fromDate), this.ngbDateAdapter.toModel(this.toDate)]);
			this.setErrors(parsed);
		}
	}

	setErrors(value: string): void {
		const split = value.split('-').map((dateString) => dateString.trim());
		if (split[0] && split[1] && isValidDateFormat(split[0]) && isValidDateFormat(split[1])) {
			this.formControl.updateValueAndValidity();
		} else {
			this.formControl.setErrors({ invalid: true });
		}
		this.formControl.markAsDirty();
		this.formControl.markAsTouched();
	}

	private getFormControlValue() {
		const range = this.formControl.value as string[];
		if (range && range.length > 1) {
			let parsed = '';
			this.fromDate = this.ngbDateAdapter.fromModel(range[0]) as NgbDateStruct;
			parsed += this.parserFormatter.format(this.fromDate);
			this.toDate = this.ngbDateAdapter.fromModel(range[1]) as NgbDateStruct;
			parsed += ' - ' + this.parserFormatter.format(this.toDate);
			return parsed;
		}
		return '';
	}

	private updateFormControlValue(value: string) {
		const split = value.split(' - ');
		if (split.length === 2) {
			const startDate = this.parserFormatter.parse(split[0]);
			const endDate = this.parserFormatter.parse(split[1]);
			if (startDate && endDate && (before(startDate, endDate) || equals(startDate, endDate))) {
				this.fromDate = undefined;
				this.toDate = undefined;
				this.onDateSelection(startDate);
				this.onDateSelection(endDate);
				return;
			} else {
				this.fromDate = undefined;
				this.toDate = undefined;
				this.renderer.setProperty(this.durationInput.nativeElement, 'value', '');
			}
		}
	}
}
