import { BusinessStructureFull, NameTitle, TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { PhoneNumberHelper } from '@app/modules/shared/helper/phone-number.helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import {
	Address,
	EmployerDTO,
	RelatedCompanyPartnerDTO,
	RelatedCompanyTrustBeneficiaryDTO
} from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';

export class EmployerModelTransformer {
	static fromPayload(employerDTO: EmployerDTO, formEnumsQuery: FormEnumsQuery): EmployerDetailsModel {
		return {
			modal: {
				// id: employerDTO.id,
				// applicationId: applicationId,
				isABNVerified: employerDTO.isABNVerified,
				companyName: employerDTO.companyName,
				businessName: employerDTO.businessName,
				acn: employerDTO.acn,
				abn: employerDTO.abn,
				dateRegistered: employerDTO.dateRegistered,
				isRegisteredForGST: employerDTO.isRegisteredForGST ? 1 : 0,
				registeredInCountry: employerDTO.registeredInCountry,
				address: employerDTO.address,
				webAddress: employerDTO.webAddress,
				// officePhoneOverseasDialingCode: this,
				officePhoneNumber: PhoneNumberHelper.fromPayload(employerDTO.officePhone),
				// officeFaxOverseasDialingCode: this,
				officeFaxNumber: PhoneNumberHelper.fromPayload(employerDTO.officeFax),
				email: employerDTO.email,
				businessStructure: employerDTO.businessStructure,
				gstRegisteredDate: employerDTO.gstRegisteredDate,
				contactPersonTitle: employerDTO.contactPersonNameTitle,
				contactPersonFirstName: employerDTO.contactPersonFirstName,
				contactPersonLastName: employerDTO.contactPersonSurname,
				contactPersonRole: employerDTO.contactPersonRole,
				partners: employerDTO.partners
					? EmployerModelTransformer.fromPartnerPayload(employerDTO.partners, formEnumsQuery)
					: [],
				trustBeneficiaries: employerDTO.trustBeneficiaries
					? EmployerModelTransformer.fromTrustBenefieciaryPayload(employerDTO.trustBeneficiaries, formEnumsQuery)
					: []
			},
			employerId: employerDTO.id,
			extract: EmployerModelTransformer.getEmployerName(employerDTO)
		};
	}

	static fromPartnerPayload(
		partnerDTOs: RelatedCompanyPartnerDTO[],
		formEnumsQuery: FormEnumsQuery
	): EmployerPartner[] {
		const empPartners: EmployerPartner[] = [];

		partnerDTOs.forEach((partnerDTO) => {
			empPartners.push({
				id: partnerDTO.id,
				existingSelect: EmployerModelTransformer.getExistingParty(partnerDTO, formEnumsQuery),
				...EmployerModelTransformer.getPersonApplicantFromDTO(partnerDTO),
				...EmployerModelTransformer.getCompanyApplicantFromDTO(partnerDTO),
				shareHolding: partnerDTO.percentOwned
			} as EmployerPartner);
		});
		return empPartners;
	}

	static fromTrustBenefieciaryPayload(
		partnerDTOs: RelatedCompanyTrustBeneficiaryDTO[],
		formEnumsQuery: FormEnumsQuery
	): EmployerPartner[] {
		const empPartners: EmployerPartner[] = [];

		partnerDTOs.forEach((partnerDTO) => {
			empPartners.push({
				id: partnerDTO.id,
				existingSelect: EmployerModelTransformer.getExistingBeneficiaryParty(partnerDTO, formEnumsQuery),
				...EmployerModelTransformer.getPersonApplicantFromBeneficiaryDTO(partnerDTO),
				...EmployerModelTransformer.getCompanyApplicantFromBeneficiaryDTO(partnerDTO),
				shareHolding: partnerDTO.percentage
			} as EmployerPartner);
		});
		return empPartners;
	}

	static getPersonApplicantFromDTO(partnerDTO: RelatedCompanyPartnerDTO) {
		return partnerDTO.relatedPartnerType === TargetType.RelatedPerson
			? {
					nameTitleId: partnerDTO.relatedPerson?.nameTitleId,
					firstName: partnerDTO.relatedPerson?.firstName,
					lastName: partnerDTO.relatedPerson?.lastName
			  }
			: { nameTitleId: undefined, firstName: undefined, lastName: undefined };
	}

	static getCompanyApplicantFromDTO(partnerDTO: RelatedCompanyPartnerDTO) {
		return partnerDTO.relatedPartnerType === TargetType.RelatedCompany
			? { companyName: partnerDTO.relatedCompany?.companyName }
			: { companyName: undefined };
	}

	static getExistingParty(partnerDTO: RelatedCompanyPartnerDTO, formEnumsQuery: FormEnumsQuery) {
		const existingParty = formEnumsQuery.getExistingPartiesById({
			id: partnerDTO.companyOrPersonPartnerId!,
			type: Number(partnerDTO.relatedPartnerType)
		});
		if (existingParty) {
			return existingParty;
		} else {
			const existingId = EmployerModelTransformer.getExistingSelectId(partnerDTO);
			return { id: existingId };
		}
	}

	static getExistingSelectId(partnerDTO: RelatedCompanyPartnerDTO) {
		return partnerDTO.relatedPartnerType === TargetType.RelatedPerson
			? -1
			: partnerDTO.relatedPartnerType === TargetType.RelatedCompany
			? -2
			: partnerDTO.companyOrPersonPartnerId;
	}

	static getPersonApplicantFromBeneficiaryDTO(partnerDTO: RelatedCompanyTrustBeneficiaryDTO) {
		return partnerDTO.relatedApplicantType === TargetType.RelatedPerson
			? {
					nameTitleId: partnerDTO.relatedPerson?.nameTitleId,
					firstName: partnerDTO.relatedPerson?.firstName,
					lastName: partnerDTO.relatedPerson?.lastName
			  }
			: { nameTitleId: undefined, firstName: undefined, lastName: undefined };
	}

	static getCompanyApplicantFromBeneficiaryDTO(partnerDTO: RelatedCompanyTrustBeneficiaryDTO) {
		return partnerDTO.relatedApplicantType === TargetType.RelatedCompany
			? { companyName: partnerDTO.relatedCompany?.companyName }
			: { companyName: undefined };
	}

	static getExistingBeneficiaryParty(partnerDTO: RelatedCompanyTrustBeneficiaryDTO, formEnumsQuery: FormEnumsQuery) {
		const existingParty = formEnumsQuery.getExistingPartiesById({
			id: partnerDTO.relatedApplicantCompanyOrPersonId!,
			type: Number(partnerDTO.relatedApplicantType)
		});
		if (existingParty) {
			return existingParty;
		} else {
			const existingId = EmployerModelTransformer.getExistingBeneficiarySelectId(partnerDTO);
			return { id: existingId };
		}
	}

	static getExistingBeneficiarySelectId(partnerDTO: RelatedCompanyTrustBeneficiaryDTO) {
		return partnerDTO.relatedApplicantType === TargetType.RelatedPerson
			? -1
			: partnerDTO.relatedApplicantType === TargetType.RelatedCompany
			? -2
			: partnerDTO.relatedApplicantCompanyOrPersonId;
	}

	static toPartnerPayload(partners: EmployerPartner[]): RelatedCompanyPartnerDTO[] {
		const empPartnerDTOs: RelatedCompanyPartnerDTO[] = [];
		partners.forEach((partner) => {
			empPartnerDTOs.push({
				id: partner.id,
				relatedPartnerType: EmployerModelTransformer.getRelatedPartnerType(partner),
				companyOrPersonPartnerId: partner.existingSelect?.type ? partner.existingSelect.id : undefined,
				relatedPerson: EmployerModelTransformer.getRelatedPartyDTO(partner, 'person'),
				relatedCompany: EmployerModelTransformer.getRelatedPartyDTO(partner, 'company'),
				percentOwned: partner.shareHolding
			} as RelatedCompanyPartnerDTO);
		});
		return empPartnerDTOs;
	}

	static toTrustBeneficiaryPayload(partners: EmployerPartner[]): RelatedCompanyTrustBeneficiaryDTO[] {
		const empPartnerDTOs: RelatedCompanyTrustBeneficiaryDTO[] = [];
		partners.forEach((partner) => {
			empPartnerDTOs.push({
				id: partner.id,
				relatedApplicantType: EmployerModelTransformer.getRelatedPartnerType(partner),
				relatedApplicantCompanyOrPersonId: partner.existingSelect?.type ? partner.existingSelect.id : undefined,
				relatedPerson: EmployerModelTransformer.getRelatedPartyDTO(partner, 'person'),
				relatedCompany: EmployerModelTransformer.getRelatedPartyDTO(partner, 'company'),
				percentage: partner.shareHolding
			} as RelatedCompanyTrustBeneficiaryDTO);
		});
		return empPartnerDTOs;
	}

	static getRelatedPartnerType(partner: EmployerPartner): TargetType | undefined {
		return partner.existingSelect?.type
			? partner.existingSelect?.type
			: partner?.existingSelect?.id === -1
			? TargetType.RelatedPerson
			: partner?.existingSelect?.id === -2
			? TargetType.RelatedCompany
			: undefined;
	}

	static getRelatedPartyDTO(partner: EmployerPartner, type: 'person' | 'company') {
		if (partner.existingSelect?.id === -1 && type === 'person') {
			return {
				id: partner.id,
				nameTitleId: partner.nameTitleId,
				firstName: partner.firstName,
				lastName: partner.lastName
			};
		} else if (partner.existingSelect?.id === -2 && type === 'company') {
			return { id: partner.id, companyName: partner.companyName };
		} else {
			return undefined;
		}
	}
	private static getEmployerName(employerDTO: EmployerDTO): string | undefined {
		const employerName =
			employerDTO.businessStructure && employerDTO.businessStructure !== BusinessStructureFull.Company
				? employerDTO.businessName
				: employerDTO.companyName;

		return employerName ? employerName : employerDTO.businessName ? employerDTO.businessName : employerDTO.companyName;
	}
}

export interface EmployerDetailsModel {
	modal: EmployerModel;
	extract?: string;
	employerId?: number;
}

interface EmployerModel {
	index?: number;
	companyName?: string;
	businessName?: string;
	abn?: string;
	acn?: string;
	isABNVerified?: boolean;
	dateRegistered?: string;
	registeredInCountry?: number;
	isRegisteredForGST?: number;
	address?: Address;
	webAddress?: string;
	officePhoneNumber?: PhoneDetails;
	email?: string;
	officeFaxNumber?: PhoneDetails;
	businessStructure?: BusinessStructureFull;
	gstRegisteredDate?: string;
	contactPersonTitle?: NameTitle;
	contactPersonFirstName?: string;
	contactPersonLastName?: string;
	contactPersonRole?: string;
	partners?: EmployerPartner[];
	trustBeneficiaries?: EmployerPartner[];
}
export interface EmployerPartner {
	id?: number;
	existingSelect?: ApplicantEnumObject;
	nameTitleId?: NameTitle;
	firstName?: string;
	lastName?: string;
	companyName?: string;
	shareHolding?: number;
}
