import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { ApplicantEntityType, YesNo } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { defaultArray, getNullableBoolean } from '@app/modules/shared/helper/util';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { ChannelSettingQuery } from '@app/modules/shared/store/channel-setting/channel-setting.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import {
	BankDetailsDTO,
	CompanyApplicantTypeDTO,
	CompanyBeneficialOwnersDTO,
	CompanyContactDetailsDTO,
	CompanyDirectorsDTO,
	CompanyShareholderDTO,
	CompanyTrustAddressesDTO,
	ContactPersonDTO,
	SolicitorDTO
} from '@app/modules/typings/api';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { BusinessDetailsModel, BusinessDetailsTransformer } from '../../models/business-details.model';
import { CompanyTrustAddressesTransformer } from '../../models/company-addressess.model';
import { CompanyApplicantDTO, CompanyApplicantTransformer } from '../../models/company-applicant.model';
import {
	AuthorisedSignatoriesDTO,
	AuthorisedSignatoriesModel,
	AuthorisedSignatoriesTransformer
} from '../../models/company-authorised-signatories.model';
import {
	CompanyBeneficialOwnerModel,
	CompanyBeneficialOwnersTransformer
} from '../../models/company-beneficialowners.model';
import { CompanyContactDetailsTransformer } from '../../models/company-contact-details.model';
import { CompanyDetailsModel, CompanyDetailsTransformer } from '../../models/company-details.model';
import { CompanyDirectorsModel, CompanyDirectorsTransformer } from '../../models/company-directors.model';
import { CompanyShareholderModel, CompanyShareholderTransformer } from '../../models/company-shareholder.model';
import { CompanyApplicantTypeModel } from '../../models/company-type.model';
import { ContactPersonTransformer } from '../../models/contact-person.model';
import { SolicitorModel } from '../../models/solicitor.model';
import { SourceOfWealthModel } from '../../models/source-of-wealth.model';
import { BankingService } from '../banking.service';
import { SourceOfWealthService } from '../source-of-wealth.service';

@Injectable({ providedIn: 'root' })
export class CompanyApplicantsService extends BaseJourneyService<any> {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private channelSettingQuery: ChannelSettingQuery,
		private formDataService: FormDataService,
		private activatedRoute: ActivatedRoute,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private formEnumsQuery: FormEnumsQuery,
		private bankingService: BankingService,
		private sourceOfWealthService: SourceOfWealthService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(index?: number) {
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		const companyApplicants = applicants.filter(
			(applicant) => applicant.applicantEntityType === ApplicantEntityType.CompanyApplicant
		);
		this.personsCompaniesEnumService.fetchPersons().subscribe();
		this.personsCompaniesEnumService.getCompanies();
		if (index !== undefined) {
			const companyApplicantId = companyApplicants[index].id;
			return forkJoin([
				this.fetchCompanyType(companyApplicantId),
				this.fetchCompanyDetails(companyApplicantId),
				this.fetchBusinessDetails(companyApplicantId),
				this.fetchContactPerson(companyApplicantId),
				this.fetchContactDetails(companyApplicantId),
				this.fetchCompanyAddressess(companyApplicantId),
				this.fetchCompanyBankingDetails(companyApplicantId),
				this.fetchDirectors(companyApplicantId),
				this.fetchShareholders(companyApplicantId),
				this.fetchBeneficialOwner(companyApplicantId),
				this.fetchAuthorisedSignatories(companyApplicantId),
				this.fetchSolicitor(companyApplicantId),
				this.fetchSourceOfWealth(companyApplicantId)
			]).pipe(
				map(
					([
						companyType,
						companyDetails,
						businessDetails,
						contactPerson,
						contactDetails,
						addresses,
						bankingDetails,
						directors,
						shareHolders,
						beneficialOwners,
						authorisedSignatories,
						solicitor,
						sourceOfWealth
					]) => {
						this.simpFormlyHandlerService.upsertToStateWithData(`type`, companyType);
						this.simpFormlyHandlerService.upsertToStateWithData(`companyDetails`, companyDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`businessDetails`, businessDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`contactPerson`, contactPerson);
						this.simpFormlyHandlerService.upsertToStateWithData(`contactDetails`, contactDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`addressess`, addresses);
						this.simpFormlyHandlerService.upsertToStateWithData(`bankingDetails`, bankingDetails);
						this.simpFormlyHandlerService.upsertToStateWithData(`directors`, directors);
						this.simpFormlyHandlerService.upsertToStateWithData(`shareHolders`, shareHolders);
						this.simpFormlyHandlerService.upsertToStateWithData(`beneficialOwners`, beneficialOwners);
						this.simpFormlyHandlerService.upsertToStateWithData(`authorisedSignatories`, authorisedSignatories);
						this.simpFormlyHandlerService.upsertToStateWithData(`solicitor`, solicitor);
						this.simpFormlyHandlerService.upsertToStateWithData(`sourceOfWealth`, sourceOfWealth);
					}
				)
			);
		}
		return of([]);
	}

	getSummaryData() {
		const companyApplicants = this.applicationDataQuery.getCompanyApplicants();
		return forkJoin(
			companyApplicants.map((ca) =>
				forkJoin({
					type: this.fetchCompanyType(ca.id),
					companyDetails: this.fetchCompanyDetails(ca.id).pipe(map((d) => d[0])),
					businessDetails: this.fetchBusinessDetails(ca.id).pipe(map((d) => d[0])),
					contactPerson: this.fetchContactPerson(ca.id).pipe(map((d) => d[0])),
					contactDetails: this.fetchContactDetails(ca.id).pipe(map((d) => d[0])),
					addressess: this.fetchCompanyAddressess(ca.id).pipe(map((d) => d[0])),
					bankingDetails: this.fetchCompanyBankingDetails(ca.id).pipe(map(defaultArray)),
					directors: this.fetchDirectors(ca.id).pipe(map(defaultArray)),
					shareHolders: this.fetchShareholders(ca.id).pipe(map(defaultArray)),
					beneficialOwners: this.fetchBeneficialOwner(ca.id).pipe(map(defaultArray)),
					authorisedSignatories: this.fetchAuthorisedSignatories(ca.id).pipe(map(defaultArray)),
					solicitor: this.fetchSolicitor(ca.id).pipe(map((d) => [d[0]?.existingSelect]))
				})
			)
		).pipe(
			map((res) =>
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				Object.assign(
					{},
					...res.map((applicant, index) => {
						const key = `companyApplicant${index}`;
						return {
							[key]: [applicant]
						};
					})
				)
			)
		);
	}

	getApplicantIdByIndex(): number {
		const index = parseInt(this.activatedRoute.snapshot.queryParams['index']);
		const companyApplicant = this.formDataService
			.getSetupApplicants()
			.filter((applicant) => applicant.applicantTypeModal?.type === ApplicantEntityType.CompanyApplicant);
		return companyApplicant[index].id;
	}

	fetchCompanyApplicants(): Observable<CompanyApplicantDTO[]> {
		if (this.channelSettingQuery.getAllowCompanyApplicants()) {
			return <Observable<CompanyApplicantDTO[]>>(
				this.getCustom(`CompanyApplicant/${this.applicationDataQuery.applicationId()}`)
			);
		} else {
			return of([]);
		}
	}

	saveCompanyApplicant(applicant: SetUpApplicant): Observable<number> {
		const payload = CompanyApplicantTransformer.toPayload(applicant, this.applicationDataQuery.applicationId());
		return <Observable<number>>this.postCustom(`CompanyApplicant`, payload);
	}

	deleteCompanyApplicant(applicant: SetUpApplicant): Observable<number> {
		return this.delete(`CompanyApplicant/${applicant.id}`) as Observable<number>;
	}

	saveCompanyType(payload: CompanyApplicantTypeModel): Observable<number> {
		const applicant: SetUpApplicant = {
			id: payload.id,
			primaryApplicant: getNullableBoolean(payload.primaryApplicant)!,
			applicantType: payload.role,
			companyName: payload.companyName
		};
		const toPayload = CompanyApplicantTransformer.toPayload(applicant, this.applicationDataQuery.applicationId());
		return <Observable<number>>this.postCustom(`CompanyApplicant`, toPayload);
	}

	fetchCompanyType(applicantId: number): Observable<CompanyApplicantTypeDTO[]> {
		return this.fetchCompanyApplicants().pipe(
			map((res: CompanyApplicantDTO[]) => {
				const applicantById = res.filter((x) => x.id == applicantId);
				return applicantById.map((x) => {
					return {
						id: x.id,
						role: x.applicantType,
						primaryApplicant: x.primaryApplicant ? YesNo.Yes : YesNo.No
					} as unknown as CompanyApplicantTypeDTO;
				});
			})
		);
	}

	saveCompanyDetails(payload: CompanyDetailsModel): Observable<number> {
		const toPayload = CompanyDetailsTransformer.toPayload(this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.patch(`CompanyApplicant/Details`, toPayload);
	}

	fetchCompanyDetails(applicantId: number): Observable<CompanyDetailsModel[]> {
		return this.getCustom(`CompanyApplicant/Details/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((r) => [CompanyDetailsTransformer.fromPayload(r)])
		);
	}

	saveBusinessDetails(payload: BusinessDetailsModel): Observable<number> {
		const toPayload = BusinessDetailsTransformer.toPayload(this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.patch(`CompanyApplicant/Business`, toPayload);
	}

	fetchBusinessDetails(applicantId: number): Observable<BusinessDetailsModel[]> {
		return this.getCustom(`CompanyApplicant/Business/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((r) => [BusinessDetailsTransformer.fromPayload(r)])
		);
	}

	saveContactPerson(payload: ContactPersonDTO): Observable<number> {
		const toPayload = ContactPersonTransformer.toPayload(this.applicationDataQuery.applicationId(), payload);
		return <Observable<number>>this.patch(`CompanyApplicant/ContactPerson`, toPayload);
	}

	fetchContactPerson(applicantId: number): Observable<ContactPersonDTO[]> {
		return this.getCustom(
			`CompanyApplicant/ContactPerson/${this.applicationDataQuery.applicationId()}/${applicantId}`
		).pipe(
			map((person: ContactPersonDTO) => [
				ContactPersonTransformer.fromPayload(
					person,
					this.formEnumsQuery.getOptionLabel('NameTitle', person.nameTitleId)
				)
			])
		);
	}

	saveContactDetails(payload: CompanyContactDetailsDTO): Observable<number> {
		return <Observable<number>>(
			this.patch(`CompanyApplicant/ContactDetails`, CompanyContactDetailsTransformer.toPayload(payload))
		);
	}

	fetchContactDetails(applicantId: number): Observable<CompanyContactDetailsDTO[]> {
		return this.getCustom(
			`CompanyApplicant/ContactDetails/${this.applicationDataQuery.applicationId()}/${applicantId}`
		).pipe(map((res: CompanyApplicantDTO) => CompanyContactDetailsTransformer.fromPayload(res)));
	}

	saveCompanyAddressess(payload: CompanyTrustAddressesDTO): Observable<number> {
		const toPayload = CompanyTrustAddressesTransformer.toPayload(payload);
		return <Observable<number>>this.patch(`CompanyApplicant/Address`, toPayload);
	}

	fetchCompanyAddressess(applicantId: number): Observable<CompanyTrustAddressesDTO[]> {
		return this.getCustom(`CompanyApplicant/Address/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((r) => [CompanyTrustAddressesTransformer.fromPayload(r)])
		);
	}

	saveCompanyBankingDetails(payload: BankDetailsDTO): Observable<number> {
		return this.bankingService.saveBankDetails(payload, ApplicantEntityType.CompanyApplicant);
	}

	fetchCompanyBankingDetails(applicantId: number): Observable<BankDetailsDTO[]> {
		return this.bankingService.getBankDetails(applicantId, ApplicantEntityType.CompanyApplicant);
	}

	deleteCompanyBankingDetails(payload: BankDetailsDTO): Observable<boolean> {
		return this.bankingService.deleteBankDetails(payload.id!, ApplicantEntityType.CompanyApplicant);
	}

	saveDirectors(applicantId: number, payload: CompanyDirectorsModel): Observable<number> {
		return <Observable<number>>(
			this.postCustom(`CompanyApplicantDirector`, CompanyDirectorsTransformer.toPayload(applicantId, payload))
		);
	}

	deleteDirectors(director: CompanyDirectorsModel, applicantId: number): Observable<boolean> {
		if (director?.id) {
			return this.delete(
				`CompanyApplicantDirector/${this.applicationDataQuery.applicationId()}/${applicantId}/${director.id}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchDirectors(applicantId: number): Observable<CompanyDirectorsModel[]> {
		return this.getCustom(`CompanyApplicantDirector/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
			map((directors: CompanyDirectorsDTO[]) => {
				return directors.map((director: CompanyDirectorsDTO) => {
					return CompanyDirectorsTransformer.fromPayload(
						director,
						this.formEnumsQuery
					) as unknown as CompanyDirectorsModel;
				});
			})
		);
	}

	saveShareHolder(applicantId: number, payload: CompanyShareholderModel): Observable<number> {
		if (payload.shareholding && payload.shareholding > 0) {
			return <Observable<number>>(
				this.postCustom(`CompanyApplicantShareholder`, CompanyShareholderTransformer.toPayload(applicantId, payload))
			);
		}
		return of(0);
	}

	deleteShareHolder(shareHolder: CompanyShareholderDTO, applicantId: number): Observable<boolean> {
		if (shareHolder?.id) {
			return this.delete(
				`CompanyApplicantShareholder/${this.applicationDataQuery.applicationId()}/${applicantId}/${shareHolder.id}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchShareholders(applicantId: number): Observable<CompanyShareholderModel[]> {
		return forkJoin([
			this.getCustom(`CompanyApplicantShareholder/${this.applicationDataQuery.applicationId()}/${applicantId}`),
			this.personsCompaniesEnumService.fetchEntities()
		]).pipe(
			map(([shareholders, relatedEntities]: [CompanyShareholderDTO[], ApplicantEnumObject[]]) => {
				return shareholders.map((shareholder: CompanyShareholderDTO) => {
					return CompanyShareholderTransformer.fromPayload(
						shareholder,
						this.formEnumsQuery
					) as unknown as CompanyShareholderModel;
				});
			})
		);
	}

	saveBeneficialOwner(payload: CompanyBeneficialOwnersDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`CompanyApplicantBeneficialOwner`, payload);
	}

	deleteBeneficialOwner(beneficialOwner: CompanyBeneficialOwnerModel, applicantId: number): Observable<boolean> {
		if (beneficialOwner?.id) {
			return this.delete(
				`CompanyApplicantBeneficialOwner/${this.applicationDataQuery.applicationId()}/${applicantId}/${
					beneficialOwner.id
				}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchBeneficialOwner(applicantId: number): Observable<CompanyBeneficialOwnerModel[]> {
		return forkJoin([
			this.getCustom(`CompanyApplicantBeneficialOwner/${this.applicationDataQuery.applicationId()}/${applicantId}`),
			this.personsCompaniesEnumService.fetchEntities()
		]).pipe(
			map(([beneficialOwners, relatedEntities]: [CompanyBeneficialOwnersDTO[], ApplicantEnumObject[]]) => {
				return beneficialOwners.map((beneficial: CompanyBeneficialOwnersDTO) => {
					return CompanyBeneficialOwnersTransformer.fromPayload(beneficial, this.formEnumsQuery);
				});
			})
		);
	}

	saveAuthorisedSignatories(applicantId: number, payload: AuthorisedSignatoriesDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`CompanyApplicantAuthorisedSignatory`, payload);
	}

	deleteAuthorisedSignatories(
		applicantId: number,
		authorisedSignatory: AuthorisedSignatoriesModel
	): Observable<boolean> {
		if (authorisedSignatory?.id) {
			return this.delete(
				`CompanyApplicantAuthorisedSignatory/${this.applicationDataQuery.applicationId()}/${applicantId}/${
					authorisedSignatory.id
				}`
			).pipe(map(() => true));
		} else {
			return of(true);
		}
	}

	fetchAuthorisedSignatories(applicantId: number): Observable<AuthorisedSignatoriesModel[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.getCustom(`CompanyApplicantAuthorisedSignatory/${applicationId}/${applicantId}`).pipe(
			map((authorisedSignatories: AuthorisedSignatoriesDTO[]) => {
				const persons = this.formEnumsQuery.getOptions('Persons') as unknown as ApplicantEnumObject[];
				return authorisedSignatories.map((authorisedSignatory: AuthorisedSignatoriesDTO) => {
					authorisedSignatory.applicationId = applicationId;
					const existingSelect = persons.find(
						(p) => p.id === authorisedSignatory.personId && p.type === authorisedSignatory.type
					);
					const title = authorisedSignatory.nameTitleId
						? this.formEnumsQuery.getOptionLabel('NameTitle', authorisedSignatory.nameTitleId)
						: '';
					const fullName = authorisedSignatory.firstName
						? `${title}. ${authorisedSignatory.firstName} ${authorisedSignatory.lastName}`
						: '';
					return AuthorisedSignatoriesTransformer.fromPayload(authorisedSignatory, existingSelect, fullName);
				});
			})
		);
	}

	fetchSolicitor(applicantId: number): Observable<SolicitorModel[]> {
		const applicationId = this.applicationDataQuery.applicationId();
		return forkJoin([
			this.getCustom(`CompanyApplicant/Solicitor/${applicationId}/${applicantId}`),
			this.personsCompaniesEnumService.fetchCompanies()
		]).pipe(
			map(([dto, companies]: [SolicitorDTO, ApplicantEnumObject[]]) => [
				{
					existingSelect: companies.find((company) => company.id === dto.solicitorId) as ApplicantEnumObject
				} as SolicitorModel
			]),
			catchError(() => of([]))
		);
	}

	saveSolicitor(payload: SolicitorDTO): Observable<SolicitorDTO> {
		return <Observable<SolicitorDTO>>this.postCustom('CompanyApplicant/Solicitor', payload);
	}

	deleteSolicitor(solicitor: SolicitorModel, applicantId: number): Observable<boolean> {
		if (solicitor?.existingSelect) {
			return this.delete(`CompanyApplicant/Solicitor/${this.applicationDataQuery.applicationId()}/${applicantId}`).pipe(
				map(() => true)
			);
		} else {
			return of(true);
		}
	}

	fetchSourceOfWealth(applicantId: number): Observable<SourceOfWealthModel[]> {
		return this.sourceOfWealthService.fetchSourceOfWealth(applicantId, ApplicantEntityType.CompanyApplicant);
	}
}
