/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV_CONFIG } from '@app/env-config';
import { AuthService, IdentityBaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_CONSTANTS } from '../constants/api.constants';
import { BasicUser } from '../model/basic-user.model';

@Injectable({ providedIn: 'root' })
export class ExtraAuthIdentityService extends IdentityBaseApiService {
	constructor(http: HttpClient, toastr: ToastrService, private authService: AuthService) {
		super(http, toastr, ENV_CONFIG);
		this.setRoute(URL_CONSTANTS.EXTRA_AUTH);
		this.authService = authService;
	}

	public getAnonymousAuthToken(channelKey: string, userId: string): Observable<boolean> {
		return this.getAuthToken(ENV_CONFIG.clientId, channelKey, userId).pipe(
			map((result: { token: string }) => {
				this.authService.createUserWithToken(result.token, userId);
				return true;
			})
		);
	}

	public getUserIdByAccessCode(accessCode: string): Observable<BasicUser> {
		return this.getCustom<BasicUser>(`User/${accessCode}`);
	}

	protected handleError = (error: HttpErrorResponse): Observable<any> => {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			if (error.status === 403) {
				errorMessage = error.error?.Message ?? 'Forbidden';
				void this.authService.signout();
			}
			errorMessage = 'Failed to process the request.';
		}

		if (errorMessage) {
			this.toastr.error(errorMessage, 'Error', {
				enableHtml: true
			});
			errorMessage = '';
		}

		return throwError(errorMessage);
	};
}
