import { Injectable } from '@angular/core';
import { PropertyValuationService } from '@app/modules/applicants/services/property-valuation.service';
import { PropertyModalDTO } from '@app/modules/property/typings/property-modal';
import { convertZeroToUndefined } from '@app/modules/shared/helper/util';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { getFormField, getParentFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { Address, Features, PropertyInformationDetailsResponse, PropertyValuationDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isEmpty } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, finalize, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RequestPropertyFieldsService {
	constructor(
		private toastr: ToastrService,
		private applicationDataQuery: ApplicationDataQuery,
		private propertyValuationService: PropertyValuationService
	) {}

	loadPropertyInformationDetails(
		address: Address,
		modalField: FormlyFieldConfig,
		propertyPayload: PropertyModalDTO,
		disableAdditonalFields: boolean
	): Observable<PropertyModalDTO | null> {
		const valuationModel: PropertyValuationDTO = {
			propertyId: propertyPayload.propertyDto.id,
			applicationId: this.applicationDataQuery.applicationId(),
			address: address
		};

		const requestingPropertyInformationSpinnerField = getFormField(
			modalField?.parent?.parent?.fieldGroup,
			'requestingPropertyInformationSpinner'
		);
		const propertyImage = getFormField(modalField?.parent?.parent?.fieldGroup, 'propertyImage');
		const gapAfterpropertyImage = getFormField(modalField?.parent?.parent?.fieldGroup, 'gapAfterpropertyImage');

		if (!disableAdditonalFields) {
			this.hideRequestingPropertyInformationSpinnerField(requestingPropertyInformationSpinnerField, false);
			this.hidePropertyImageField(propertyImage, gapAfterpropertyImage, false);
			this.setReadOnlyRequestingPropertyFields(modalField, true);
		}

		return this.propertyValuationService.getPropertyInformationDetails(valuationModel).pipe(
			switchMap((valuationPropertyModel: PropertyInformationDetailsResponse | false) => {
				if (valuationPropertyModel === false || isEmpty(valuationPropertyModel)) {
					if (!disableAdditonalFields) {
						this.toastr.warning('No property details found for selected address!');
						this.hidePropertyImageField(propertyImage, gapAfterpropertyImage, true);
					}
					return of(null);
				}

				propertyPayload.propertyDto.zoningType = valuationPropertyModel.landTitle?.zone;
				propertyPayload.propertyDto.propertyTypeName = valuationPropertyModel.propertyType;
				propertyPayload.propertyDto.primaryLandUse = valuationPropertyModel.landTitle?.landUsePrimary;
				propertyPayload.propertyDto.lotPlan = valuationPropertyModel.landTitle?.lotPlan;
				propertyPayload.propertyDto.lot = valuationPropertyModel.landTitle?.lot;
				propertyPayload.propertyDto.realPropertyDescriptor = valuationPropertyModel.landTitle?.realPropertyDescriptor;

				const propertyImageURL = this.getPropertyImageURL(valuationPropertyModel);
				if (!disableAdditonalFields) {
					if (!propertyImageURL) {
						this.hidePropertyImageField(propertyImage, gapAfterpropertyImage, true);
					}
				}

				propertyPayload.propertyDto.features = {
					applicationId: this.applicationDataQuery.applicationId(),
					propertyImage: propertyImageURL,
					landArea: valuationPropertyModel.propertyAttributes.landArea,
					floorArea: convertZeroToUndefined(valuationPropertyModel.propertyAttributes.floorArea),
					wallMaterial: valuationPropertyModel.propertyAttributes.wallMaterial,
					roofMaterial: valuationPropertyModel.propertyAttributes.roofMaterial,
					numberOfBedrooms: convertZeroToUndefined(valuationPropertyModel.propertyAttributes.bedrooms),
					numberOfBathrooms: convertZeroToUndefined(valuationPropertyModel.propertyAttributes.bathrooms),
					numberOfCarSpaces: convertZeroToUndefined(valuationPropertyModel.propertyAttributes.carSpaces)
				} as Features;

				return of(propertyPayload);
			}),
			finalize(() => {
				if (!disableAdditonalFields) {
					this.hideRequestingPropertyInformationSpinnerField(requestingPropertyInformationSpinnerField, true);
					this.setReadOnlyRequestingPropertyFields(modalField, false);
				}
			})
		);
	}

	private getPropertyImageURL(valuationPropertyModel: PropertyInformationDetailsResponse): string | null {
		if (valuationPropertyModel?.propertyPhoto?.largeURL) {
			valuationPropertyModel?.propertyPhoto?.largeURL;
		}

		if (valuationPropertyModel?.propertyPhoto?.mediumURL) {
			return valuationPropertyModel.propertyPhoto.mediumURL;
		}

		if (valuationPropertyModel?.propertyPhoto?.thumbnailURL) {
			return valuationPropertyModel.propertyPhoto.thumbnailURL;
		}

		return null;
	}

	private hideRequestingPropertyInformationSpinnerField(
		requestingPropertyInformationSpinnerField: FormlyFieldConfig | undefined,
		hide: boolean
	) {
		if (requestingPropertyInformationSpinnerField) {
			requestingPropertyInformationSpinnerField?.formControl?.setValue(!hide);
		}
	}

	private hidePropertyImageField(
		propertyImage: FormlyFieldConfig | undefined,
		gapAfterpropertyImage: FormlyFieldConfig | undefined,
		hide: boolean
	) {
		if (propertyImage) {
			propertyImage.hide = hide;
		}

		if (gapAfterpropertyImage) {
			gapAfterpropertyImage.hide = hide;
		}
	}

	private setReadOnlyRequestingPropertyFields(modalField: FormlyFieldConfig, readOnly: boolean) {
		const propertyField = getParentFormField(modalField, 'details')?.parent;
		propertyField?.fieldGroup?.forEach((field) => {
			if (field?.templateOptions) {
				field.templateOptions.readonly = readOnly;
			}
		});
	}
}
