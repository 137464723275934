import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { AbnLookupTransformerService } from '@app/modules/abn-lookup/services/abn-lookup-transformer.service';
import {
	RelatedCompanyModel,
	RelatedCompanyModelTransformer
} from '@app/modules/applicants/models/related-company.model';
import { RelatedPartyModel } from '@app/modules/applicants/models/related-party.model';
import { RelatedPersonModel, RelatedPersonModelTransformer } from '@app/modules/applicants/models/related-person.model';
import { EmployerPartner } from '@app/modules/employers/model/employer.model';
import { EmploymentDetailsModel } from '@app/modules/financial-position/model/employment.model';
import { SelfEmploymentModel } from '@app/modules/financial-position/model/self-employment.model';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyOnClick,
	formlyRegisterHooks,
	getAreaField,
	getFormField,
	getInitializedFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash-es';
import { Observable, Subscription, combineLatest, iif, map, of, pairwise, startWith, switchMap, tap } from 'rxjs';
import { CONSTANTS } from '../constants/constants';
import {
	ApplicantEntityType,
	IncomeDocumentationType,
	IncomeType,
	RelatedCompanyType,
	TargetType
} from '../enums/app.enums';
import { ApplicantEnumObject } from '../enums/enum-helper';
import { ForeignTaxAssociationModel, ForeignTaxAssociationTransformer } from '../model/foreign-tax-association.model';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { AccessService } from './access.service';
import { ForeignTaxAssociationService } from './foreign-tax-association.service';
import { PersonsCompaniesEnumService } from './persons-companies-enum.service';

@Injectable({ providedIn: 'root' })
export class RelatedEntitiesService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsQuery: FormEnumsQuery,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private simpFormlyModalService: SimpFormlyModalService,
		private accessService: AccessService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private abnLookupTransformerService: AbnLookupTransformerService,
		private foreignTaxAssociationService: ForeignTaxAssociationService
	) {}

	configureRelatedEntityModal = (
		field: FormlyFieldConfig,
		applicantId: number,
		onSaveRelatedEntity: (
			personnelModel: any,
			personnelIndex: number,
			applicantId: number,
			field?: FormlyFieldConfig
		) => Subscription,
		selectFieldName = 'existingSelect',
		targetModalName?: string,
		relatedCompanyType: RelatedCompanyType = RelatedCompanyType.Other
	): Observable<[ApplicantEnumObject, ApplicantEnumObject]> => {
		return (
			field?.formControl?.valueChanges.pipe(
				startWith(field?.formControl?.value as ApplicantEnumObject),
				pairwise<ApplicantEnumObject>(),
				tap(([prev, next]: [ApplicantEnumObject, ApplicantEnumObject]) => {
					if (prev?.id !== next?.id) {
						this.patchBusinessFinancials(field);
					}
					if (next?.id === -1 || next?.id === -2) {
						const targetType = next?.id === -1 ? TargetType.RelatedPerson : TargetType.RelatedCompany;

						const modalName =
							targetType === TargetType.RelatedPerson
								? 'relatedPerson'
								: targetModalName
								? targetModalName
								: 'relatedCompany';

						const defaultObject = this.defaultPartnersAndShareHoldersStore(targetType, field, relatedCompanyType);
						this.simpFormlyHandlerService.upsertToStateWithData(modalName, [defaultObject]);
						this.openRelatedEntityModal(
							field,
							prev,
							applicantId,
							targetType,
							onSaveRelatedEntity,
							relatedCompanyType,
							selectFieldName,
							targetModalName
						);
					}
				})
			) ?? of()
		);
	};

	openPrefilledRelatedEntityModal = (
		formField: FormlyFieldConfig,
		applicantId: number,
		onSaveRelatedEntity: (
			personnelModel: any,
			personnelIndex: number,
			applicantId: number,
			field?: FormlyFieldConfig
		) => Subscription,
		selectFieldName = 'existingSelect',
		targetModalName?: string,
		relatedCompanyType: RelatedCompanyType = RelatedCompanyType.Other
	) => {
		const relatedPartyModel = formField.model as RelatedPartyModel;
		const targetType = (get(relatedPartyModel, selectFieldName) as ApplicantEnumObject)?.type;
		const relatedEntityId = (get(relatedPartyModel, selectFieldName) as ApplicantEnumObject)?.id;
		const modalName =
			targetType === TargetType.RelatedPerson ? 'relatedPerson' : targetModalName ? targetModalName : 'relatedCompany';

		this.defaultPartnersAndShareHoldersField(
			getFormField(this.getRelatedEntitiesField(formField)?.fieldGroup, `${modalName}`) as FormlyFieldConfig,
			formField
		);

		this.defaultTrustBeneficiariesField(
			getFormField(this.getRelatedEntitiesField(formField)?.fieldGroup, `${modalName}`) as FormlyFieldConfig,
			formField
		);

		if (relatedPartyModel && relatedEntityId) {
			iif(
				() => targetType === TargetType.RelatedPerson,
				this.personsCompaniesEnumService.getRelatedPerson(relatedEntityId).pipe(
					tap((relatedPersonDetails) => {
						this.simpFormlyHandlerService.upsertToStateWithData(modalName, [relatedPersonDetails]);
					})
				),
				this.personsCompaniesEnumService.getRelatedCompany(relatedEntityId).pipe(
					tap((relatedCompanyDetails) => {
						this.simpFormlyHandlerService.upsertToStateWithData(modalName, [relatedCompanyDetails]);
					})
				)
			).subscribe(() => {
				this.openRelatedEntityModal(
					formField,
					get(relatedPartyModel, selectFieldName) as ApplicantEnumObject,
					applicantId,
					targetType,
					onSaveRelatedEntity,
					relatedCompanyType,
					selectFieldName,
					targetModalName
				);
			});
		}
	};

	defaultPartnersAndShareHoldersField(modalField: FormlyFieldConfig, formField: FormlyFieldConfig) {
		if (formField.key !== 'businessDetailsSelect') {
			return;
		}
		const applicantId = (formField.model as SelfEmploymentModel)?.ownership as number;
		if (applicantId > 0 && modalField) {
			const applicantObject = this.formEnumsQuery.getExistingPartiesById({
				id: applicantId,
				type: TargetType.PersonApplicant
			});

			const partnersField = getFormField(
				(modalField?.fieldArray as FormlyFieldConfig)?.fieldGroup,
				'partners'
			) as FormlyFieldConfig;

			const defaultValue = [{ existingSelect: applicantObject, shareHolding: 100 }];
			if (partnersField) {
				partnersField.defaultValue = defaultValue;
			}

			const shareHoldersField = getFormField(
				(modalField?.fieldArray as FormlyFieldConfig)?.fieldGroup,
				'shareHolders'
			) as FormlyFieldConfig;
			if (shareHoldersField) {
				shareHoldersField.defaultValue = defaultValue;
			}
		}
	}

	defaultTrustBeneficiariesField(modalField: FormlyFieldConfig, formField: FormlyFieldConfig) {
		if (formField.key !== 'businessDetailsSelect') {
			return;
		}
		const applicantId = (formField.model as SelfEmploymentModel)?.ownership as number;
		if (applicantId > 0 && modalField) {
			const applicantObject = this.formEnumsQuery.getExistingPartiesById({
				id: applicantId,
				type: TargetType.PersonApplicant
			});

			const defaultValue = [{ existingSelect: applicantObject, shareHolding: 100 }];
			const trustBeneficiariesField = getFormField(
				(modalField?.fieldArray as FormlyFieldConfig)?.fieldGroup,
				'trustBeneficiaries'
			) as FormlyFieldConfig;
			if (trustBeneficiariesField) {
				trustBeneficiariesField.defaultValue = defaultValue;
			}
		}
	}

	configureABNLookupForRelatedCompany(formFields: FormlyFieldConfig[], sectionName: string) {
		formlyOnClick(
			formFields,
			'relatedEntityModals.relatedCompanyModal.relatedCompany.companyName',
			(field: FormlyFieldConfig, action: string) => {
				this.abnLookupTransformerService.handleRelatedCompanyAbnSearch(field, action, sectionName);
			}
		);
		formlyOnClick(
			formFields,
			'relatedEntityModals.relatedCompanyModal.relatedCompany.acn',
			(field: FormlyFieldConfig, action: string) => {
				this.abnLookupTransformerService.handleRelatedCompanyAbnSearch(field, action, sectionName);
			}
		);
		formlyOnClick(
			formFields,
			'relatedEntityModals.relatedCompanyModal.relatedCompany.abn',
			(field: FormlyFieldConfig, action: string) => {
				this.abnLookupTransformerService.handleRelatedCompanyAbnSearch(field, action, sectionName);
			}
		);

		formlyOnClick(
			formFields,
			'relatedEntityModals.relatedCompanyPartnerModal.relatedCompanyPartner.companyName',
			(field: FormlyFieldConfig, action: string) => {
				this.abnLookupTransformerService.handleRelatedCompanyAbnSearch(field, action, sectionName);
			}
		);
		formlyOnClick(
			formFields,
			'relatedEntityModals.relatedCompanyPartnerModal.relatedCompanyPartner.abn',
			(field: FormlyFieldConfig, action: string) => {
				this.abnLookupTransformerService.handleRelatedCompanyAbnSearch(field, action, sectionName);
			}
		);
		['partners', 'shareHolders', 'trustBeneficiaries'].forEach((fieldKey) => {
			this.configurePartnerOrShareHolder(
				formFields,
				`relatedEntityModals.relatedCompanyModal.relatedCompany.${fieldKey}`
			);
		});
	}

	private openRelatedEntityModal = (
		formField: FormlyFieldConfig,
		prev: ApplicantEnumObject,
		applicantId: number,
		targetType: TargetType,
		onSaveRelatedTarget: (
			personnelModel: any,
			personnelIndex: number,
			applicantId: number,
			field?: FormlyFieldConfig
		) => Subscription,
		relatedCompanyType: RelatedCompanyType,
		selectFieldName = 'existingSelect',
		targetModalName?: string
	) => {
		const modalName =
			targetType === TargetType.RelatedPerson ? 'relatedPerson' : targetModalName ? targetModalName : 'relatedCompany';

		const modalFormField = this.getRelatedEntitiesField(formField);

		const sourceFieldKey = formField?.parent?.parent?.key as string;
		const modalField = getFormField(modalFormField?.fieldGroup, `${modalName}Modal`) as FormlyFieldConfig;

		const modalLabel = sourceFieldKey?.includes('partners')
			? 'Partners details'
			: sourceFieldKey?.includes('shareHolders')
			? 'Shareholders details'
			: sourceFieldKey.includes('trustBeneficiaries')
			? 'TrustBeneficiaries details'
			: (modalField?.templateOptions?.label as string);

		this.defaultPartnersAndShareHoldersField(
			getFormField(modalField?.fieldGroup, `${modalName}`) as FormlyFieldConfig,
			formField
		);

		this.defaultTrustBeneficiariesField(
			getFormField(modalField?.fieldGroup, `${modalName}`) as FormlyFieldConfig,
			formField
		);
		const employmentDetails = getParentFormField(formField, 'employmentDetails')?.model as EmploymentDetailsModel;

		if (employmentDetails) {
			this.manageShowHideTrustBeneficiaries(employmentDetails, modalFormField);
		}

		const modalRef = this.simpFormlyModalService.openModal(
			modalFormField as FormlyFieldConfig,
			`${modalName}Modal`,
			{
				backdrop: 'static',
				size: 'xl'
			},
			false,
			modalLabel
		);

		const subscription = modalRef.action.subscribe((action) => {
			if (action === 'submit') {
				const personnelFormControl = formField.parent?.formControl;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const personnelModel = personnelFormControl?.value as RelatedPartyModel;

				const personnelIndex = formField.parent?.key as number;
				const modalFormFieldGroup = modalFormField?.fieldGroup;

				const relatedTargetDetailsFormField = getFormField(
					modalFormFieldGroup,
					`${modalName}Modal`
				) as FormlyFieldConfig;
				const applicationId = this.applicationDataQuery.applicationId();

				if (targetType === TargetType.RelatedPerson) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const relatedPersonModel = relatedTargetDetailsFormField?.model[`${modalName}`][0] as RelatedPersonModel;
					this.personsCompaniesEnumService
						.saveRelatedPersons(RelatedPersonModelTransformer.toPayload(relatedPersonModel, applicationId))
						.pipe(
							switchMap((relatedPersonId: number) =>
								this.saveForeignTaxAssociation(relatedPersonId, relatedPersonModel)
							),
							switchMap((relatedPersonId: number) =>
								this.personsCompaniesEnumService.fetchPersons().pipe(
									tap(() => {
										// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
										(personnelModel as any)[selectFieldName] = this.formEnumsQuery.getExistingPartiesById({
											id: relatedPersonId,
											type: TargetType.RelatedPerson
										});
										personnelModel.applicationId = applicationId;

										onSaveRelatedTarget(personnelModel, personnelIndex, applicantId, formField);
									})
								)
							)
						)
						.subscribe(() => {
							this.resetModal(modalField, modalName);
							modalRef.close();
							subscription.unsubscribe();
							return;
						});
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const model = relatedTargetDetailsFormField?.model[`${modalName}`][0] as RelatedCompanyModel;
					model.relatedCompanyType = relatedCompanyType;
					this.personsCompaniesEnumService
						.saveRelatedCompany(RelatedCompanyModelTransformer.toPayload(model, applicationId))
						.pipe(
							switchMap((relatedCompanyId: number) =>
								this.personsCompaniesEnumService.fetchCompanies().pipe(
									tap(() => {
										// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
										(personnelModel as any)[selectFieldName] = this.formEnumsQuery.getExistingPartiesById({
											id: relatedCompanyId,
											type: TargetType.RelatedCompany
										});
										personnelModel.applicationId = applicationId;
										personnelModel.businessStructureId = (
											relatedTargetDetailsFormField.model as RelatedCompanyModel
										).businessStructure;
										onSaveRelatedTarget(personnelModel, personnelIndex, applicantId, formField);
									})
								)
							)
						)
						.subscribe(() => {
							this.resetModal(modalField, modalName);
							modalRef.close();
							subscription.unsubscribe();
							return;
						});
				}
			} else {
				formField.formControl?.setValue(prev);
				this.resetModal(modalField, modalName);
				modalRef.close();
				subscription.unsubscribe();
				return;
			}
		});
	};

	private saveField = (
		partner: EmployerPartner,
		personnelIndex: number,
		applicantId: number,
		field?: FormlyFieldConfig
	) => {
		field?.formControl?.setValue(partner.existingSelect);
		return new Subscription();
	};

	private getAvailableShareHoldingValue(field: FormlyFieldConfig) {
		const maxPercentage = 100;
		return maxPercentage - this.getTotalShareHoldingValue(field);
	}

	private getTotalShareHoldingValue(field: FormlyFieldConfig) {
		let currentTotalShareHolding = 0;
		const formArray = field?.parent?.parent?.fieldGroup as unknown as Array<FormlyFieldConfig>;
		formArray.forEach((form) => {
			const formControl = form.formControl?.value as EmployerPartner;
			currentTotalShareHolding += formControl.shareHolding ?? 0;
		});
		return currentTotalShareHolding;
	}

	private defaultPartnersAndShareHoldersStore(
		targetType: TargetType,
		formField: FormlyFieldConfig,
		relatedCompanyType: RelatedCompanyType
	) {
		if (!this.isFromSelfEmployment(formField)) {
			return {
				relatedCompanyType: relatedCompanyType
			};
		}
		const applicantId = (formField.model as SelfEmploymentModel)?.ownership as number;

		if (targetType !== TargetType.RelatedCompany && applicantId < 1) return {};
		const applicant = this.applicationDataQuery.getApplicants().find((x) => x.id === applicantId);
		const applicantTargetType =
			applicant?.applicantEntityType === ApplicantEntityType.PersonApplicant
				? TargetType.PersonApplicant
				: TargetType.CompanyApplicant;

		const applicantEnumObject = this.formEnumsQuery.getExistingPartiesById({
			id: applicantId,
			type: applicantTargetType
		});

		return {
			relatedCompanyType: relatedCompanyType,
			partners: [this.defaultPartner(applicantEnumObject)],
			shareHolders: [this.defaultPartner(applicantEnumObject)],
			trustBeneficiaries: [this.defaultPartner(applicantEnumObject)]
		};
	}

	private isFromSelfEmployment(formField: FormlyFieldConfig) {
		return formField.key === 'businessDetailsSelect';
	}

	private defaultPartner(applicantEnumObject: ApplicantEnumObject) {
		return { id: CONSTANTS.NEW_ID, existingSelect: applicantEnumObject, shareHolding: 100 };
	}

	private resetModal(formField: FormlyFieldConfig, modalName: string) {
		formField.formControl?.markAsPristine();
		formField.formControl?.markAsUntouched();

		this.simpFormlyHandlerService.upsertToStateWithData(modalName, []);
	}

	private patchBusinessFinancials(field: FormlyFieldConfig | undefined) {
		if (!this.isFromSelfEmployment(field as FormlyFieldConfig)) {
			return;
		}
		const value = field?.formControl?.value as ApplicantEnumObject;
		if (value?.type === TargetType.RelatedCompany && value.id > 0) {
			this.personsCompaniesEnumService.getRelatedCompany(value.id).subscribe((res: RelatedCompanyModel) => {
				const businessFinancialsFormControl = getFormField(
					field?.parent?.fieldGroup,
					'businessFinancials'
				)?.formControl;

				businessFinancialsFormControl?.reset();

				businessFinancialsFormControl?.patchValue({
					modal: {
						financialYear: res.companyFinancials
					},
					extract: res.companyFinancials?.length ? 'Business Financials' : ''
				});
			});
		}
	}
	private getRelatedEntitiesField(formField: FormlyFieldConfig) {
		const areaField = getAreaField(formField);

		if (!areaField?.fieldGroup?.length) {
			return;
		}
		return areaField.fieldGroup[0].fieldGroup?.find((field) => field.key === 'relatedEntityModals');
	}
	private configurePartnerOrShareHolder(formFields: FormlyFieldConfig[] | undefined, path: string) {
		const formField = getFormField(formFields, path) as FormlyFieldConfig;
		const arrayField = formField?.fieldArray as FormlyFieldConfig;
		if (!arrayField) {
			return;
		}

		formlyRegisterHooks(arrayField.fieldGroup, 'shareHolding', {
			onInit: (field) => {
				if (field !== undefined) {
					const model = field.parent?.model as EmployerPartner;
					if (model.existingSelect?.id === undefined) {
						field?.formControl?.patchValue(this.getAvailableShareHoldingValue(field));
					}
				}
				return of();
			}
		});

		formlyAddCustomValidator(formFields, path, {
			invalid: {
				expression: (c: UntypedFormArray) => {
					const value = c.value as EmployerPartner[];
					let total = 0;
					if (value) {
						const percentages = value.map((per) => {
							return per.shareHolding || 0;
						});
						total = percentages.reduce((a, b) => a + b, 0);
					}
					return total <= 100;
				}
			}
		});

		formlyRegisterHooks(arrayField.fieldGroup, `existingSelect`, {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const optionName = getFormField(arrayField.fieldGroup, `existingSelect`)?.templateOptions?.options;
					const fieldOptions = this.formEnumsQuery.getAddOptions(optionName as unknown as string);
					const selectedCompanyId = (field.parent?.parent?.parent?.model as RelatedCompanyModel).id;
					const listItems = combineLatest([
						this.formEnumsQuery.selectEnumOptions('Persons'),
						this.formEnumsQuery
							.selectEnumOptions('OtherCompanies')
							.pipe(map((x) => x.filter((y) => y.id !== selectedCompanyId)))
					]).pipe(
						map(([x, y]) => {
							return [
								...fieldOptions,
								...(fieldOptions.findIndex((f) => f.id == -1) != -1 ? x : []),
								...(fieldOptions.findIndex((f) => f.id == -2) != -1 ? y : [])
							].map((person) => ({
								...person,
								click:
									(person.type as unknown as TargetType) === TargetType.RelatedPerson ||
									(person.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.openPrefilledRelatedEntityModal(
													field,
													0,
													this.saveField,
													'existingSelect',
													'relatedCompanyPartner'
												)
										: null
							}));
						})
					);
					field.templateOptions.options = listItems;

					return this.configureRelatedEntityModal(field, 0, this.saveField, 'existingSelect', 'relatedCompanyPartner');
				}
				return of();
			}
		});

		formlyOnClick(arrayField.fieldGroup, `delete`, (field: FormlyFieldConfig) => {
			formlyDeleteFromArray(field);
		});
	}
	private saveForeignTaxAssociation(relatedPersonId: number, model: RelatedPersonModel): Observable<number> {
		const foreignTaxResidencyModel = {
			id: model.foreignTaxAssociationId,
			targetId: relatedPersonId,
			targetType: TargetType.RelatedPerson,
			foreignTaxAssociationDetails: model.foreignTaxAssociationDetails
		} as ForeignTaxAssociationModel;

		const payload = ForeignTaxAssociationTransformer.toPayLoad(
			foreignTaxResidencyModel,
			this.applicationDataQuery.applicationId()
		);

		this.foreignTaxAssociationService
			.saveForeignTaxAssociation(payload)
			.pipe(
				map((response: number) => {
					model.foreignTaxAssociationId = response;
				})
			)
			.subscribe();
		return of(relatedPersonId);
	}

	private manageShowHideTrustBeneficiaries(
		employmentDetails: EmploymentDetailsModel,
		relatedEntityModalFormField?: FormlyFieldConfig
	) {
		let field = getInitializedFormField(
			relatedEntityModalFormField?.fieldGroup,
			'relatedCompanyModal.relatedCompany.0.hideTrustBeneficiaries'
		);
		if (!field) {
			field = getFormField(
				relatedEntityModalFormField?.fieldGroup,
				'relatedCompanyModal.relatedCompany.hideTrustBeneficiaries'
			);
		}

		if (field) {
			Object.assign(field, {
				...field,
				hooks: Object.assign({}, field.hooks, {
					onInit: (formField: FormlyFieldConfig | undefined) => {
						formField?.formControl?.setValue(
							!(
								employmentDetails.typeOfIncome?.incomeDocumentationTypeId ===
									IncomeDocumentationType.CompanyFinancials &&
								employmentDetails.typeOfIncome?.type === IncomeType.selfEmployed
							)
						);
						return of();
					}
				})
			});
		}
	}
}
