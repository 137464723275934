import { Injectable } from '@angular/core';
import { CompanyTrustSmsfRunningCostModel } from '@app/modules/financial-position/model/company-trust-expenses.model';
import { PropertyService } from '@app/modules/property/services/property.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { formatPlural } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { AssetsAndLiabilitiesService } from '@app/modules/shared/service/assets-liabilities.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { AccountantDTO, CompanyTrustIncomesDTO, TrustExpensesDTO } from '@app/modules/typings/api';
import { sumBy } from 'lodash-es';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { AccountantModel } from '../../model/company-trust-accountant.model';

import {
	ForeignTaxAssociationModel,
	ForeignTaxAssociationTransformer
} from '@app/modules/shared/model/foreign-tax-association.model';
import { ForeignTaxAssociationService } from '@app/modules/shared/service/foreign-tax-association.service';
import { CompanyTrustIncome, CompanyTrustIncomeTransformer } from '../../model/company-trust-income.model';

@Injectable({
	providedIn: 'root'
})
export class TrustFinancialPositionService extends BaseJourneyService {
	statePrefix = 'financial-positiontrust-index';
	constructor(
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private propertyService: PropertyService,
		private foreignTaxAssociationService: ForeignTaxAssociationService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState$(index?: number): Observable<unknown> {
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		const trustApplicants = applicants.filter(
			(applicant) => applicant.applicantEntityType === ApplicantEntityType.TrustApplicant
		);
		if (index !== undefined) {
			const trustApplicant = trustApplicants[index];
			this.getRelatedCompanies();
			return forkJoin([
				this.fetchTrustIncomeDetails(trustApplicant),
				this.fetchTrustAccountant(trustApplicant),
				this.fetchTrustSmsfRunningCost(trustApplicant),
				this.fetchForeignTaxAssociation(trustApplicant),
				this.assetsAndLiabilitiesService.fetchSavings(),
				this.assetsAndLiabilitiesService.fetchOtherAssets(),
				this.assetsAndLiabilitiesService.fetchAccessSeeker(),
				this.assetsAndLiabilitiesService.fetchCreditCards(),
				this.assetsAndLiabilitiesService.fetchPersonalLoans(),
				this.assetsAndLiabilitiesService.fetchOtherLiabilities(),
				this.propertyService.syncPropertiesAndSecurities$()
			]).pipe(
				map(
					([
						incomeDetails,
						accountant,
						smsfRunningCost,
						foreignTaxAssociation,
						savings,
						otherAssets,
						accessSeekerLiabilities,
						creditCards,
						personalLoan,
						otherLiabilities
					]) => {
						this.simpFormlyHandlerService.upsertToStateWithData('incomeDetails', incomeDetails);
						this.simpFormlyHandlerService.upsertToStateWithData('accountant', accountant);
						this.simpFormlyHandlerService.upsertToStateWithData('smsfRunningCost', smsfRunningCost);
						this.simpFormlyHandlerService.upsertToStateWithData('savings', savings);
						this.simpFormlyHandlerService.upsertToStateWithData('otherAssets', otherAssets);
						this.simpFormlyHandlerService.upsertToStateWithData('accessSeekerLiabilities', accessSeekerLiabilities);
						this.simpFormlyHandlerService.upsertToStateWithData('creditCards', creditCards);
						this.simpFormlyHandlerService.upsertToStateWithData('personalLoans', personalLoan);
						this.simpFormlyHandlerService.upsertToStateWithData('otherLiabilities', otherLiabilities);
						this.simpFormlyHandlerService.upsertToStateWithData('foreignTaxAssociation', [foreignTaxAssociation]);
					}
				)
			);
		}
		return of(true);
	}

	getSummaryData() {
		const trustApplicants = this.applicationDataQuery.getTrustApplicants();
		const applicants: ShortApplicant[] = this.applicationDataQuery.getApplicants();
		return forkJoin(
			trustApplicants.map((trustApplicant) =>
				forkJoin({
					foreignTaxAssociation: this.fetchForeignTaxAssociation(trustApplicant),
					accountant: this.fetchTrustAccountant(trustApplicant).pipe(
						map((d) => ({ accountantName: (d[0] as AccountantModel)?.existingSelect?.companyName }))
					),
					incomeDetails: this.fetchTrustIncomeDetails(trustApplicant).pipe(map((d) => d[0])),
					liabilities: forkJoin({
						homeloans: this.propertyService.fetchHomeLoans(),
						creditCards: this.assetsAndLiabilitiesService.fetchCreditCards(),
						personalLoans: this.assetsAndLiabilitiesService.fetchPersonalLoans(),
						otherLiabilities: this.assetsAndLiabilitiesService.fetchOtherLiabilities()
					}).pipe(
						map((res) => {
							let totalPool = sumBy(res.homeloans, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.creditCards, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.personalLoans, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.otherLiabilities, (value) => value.summaryAmount || 0);

							const itemsEntered = [];
							itemsEntered.push(
								res.creditCards.length
									? `${res.creditCards.length} ${formatPlural(res.creditCards.length, 'credit card')}`
									: ''
							);
							itemsEntered.push(
								res.personalLoans.length
									? `${res.personalLoans.length} ${formatPlural(res.personalLoans.length, 'personal loan')}`
									: ''
							);
							itemsEntered.push(
								res.otherLiabilities.length
									? `${res.otherLiabilities.length} ${formatPlural(
											res.otherLiabilities.length,
											'other liability',
											'other liabilities'
									  )}`
									: ''
							);

							return {
								totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
								itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
							};
						})
					),
					assets: forkJoin({
						realEstate: this.propertyService.fetchProperties(),
						savings: this.assetsAndLiabilitiesService.fetchSavings(),
						otherAssets: this.assetsAndLiabilitiesService.fetchOtherAssets(applicants)
					}).pipe(
						map((res) => {
							let totalPool = sumBy(res.realEstate, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.savings, (value) => value.summaryAmount || 0);
							totalPool += sumBy(res.otherAssets, (value) => value.summaryAmount || 0);

							const itemsEntered = [];
							itemsEntered.push(
								res.savings.length ? `${res.savings.length} ${formatPlural(res.savings.length, 'saving')}` : ''
							);
							itemsEntered.push(
								res.otherAssets.length
									? `${res.otherAssets.length} ${formatPlural(res.otherAssets.length, 'other asset')}`
									: ''
							);

							return {
								totalPool: totalPool ?? CONSTANTS.DEFAULT_STRING,
								itemsEntered: itemsEntered.filter((item) => !!item).join(', ') ?? CONSTANTS.DEFAULT_STRING
							};
						})
					)
				})
			)
		).pipe(
			map((res) =>
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				Object.assign(
					{},
					...res.map((applicant, index) => {
						const key = `trustApplicant${index}`;
						return {
							[key]: [applicant]
						};
					})
				)
			)
		);
	}

	getRelatedCompanies() {
		this.personsCompaniesEnumService.getRelatedCompanies();
	}

	saveTrustAccountant(payload: AccountantDTO): Observable<AccountantDTO> {
		return <Observable<AccountantDTO>>this.postCustom('TrustApplicant/Accountant', payload);
	}

	saveTrustIncome(payload: CompanyTrustIncomesDTO, companyApplicantID: number): Observable<void> {
		return <Observable<void>>(
			this.postCustom(
				`TrustApplicant/Income/${this.applicationDataQuery.applicationId()}/${companyApplicantID}`,
				payload
			)
		);
	}

	saveTrustSmsfRunningCost(payload: TrustExpensesDTO): Observable<void> {
		return <Observable<void>>this.patch(`TrustApplicant/Expenses`, payload);
	}

	private fetchTrustAccountant(trustApplicant: ShortApplicant): Observable<AccountantModel[] | {}[]> {
		return forkJoin([
			this.getCustom(`TrustApplicant/Accountant/${this.applicationDataQuery.applicationId()}/${trustApplicant.id}`),
			this.personsCompaniesEnumService.fetchCompanies()
		]).pipe(
			map(([accountantDTO, companies]: [AccountantDTO, ApplicantEnumObject[]]) => [
				{
					existingSelect: companies.find((company) => company.id === accountantDTO.accountantId)
				} as AccountantModel
			]),
			catchError(() => of([{}]))
		);
	}
	private fetchForeignTaxAssociation(trustApplicant: ShortApplicant): Observable<ForeignTaxAssociationModel> {
		if (!trustApplicant.foreignTaxAssociationId) {
			return of({ applicantId: trustApplicant.id, applicantName: trustApplicant.name } as ForeignTaxAssociationModel);
		}
		return this.foreignTaxAssociationService
			.getForeignTaxAssociations(trustApplicant.foreignTaxAssociationId)
			.pipe(map((res) => ForeignTaxAssociationTransformer.fromPayload(res, trustApplicant)));
	}
	private fetchTrustSmsfRunningCost(
		trustApplicant: ShortApplicant
	): Observable<CompanyTrustSmsfRunningCostModel[] | {}[]> {
		return this.getCustom(
			`TrustApplicant/Expenses/${this.applicationDataQuery.applicationId()}/${trustApplicant.id}`
		).pipe(
			map((res: TrustExpensesDTO) => [
				{
					smsfRunningCostFrequency: {
						frequency: res.frequency,
						amount: res.runningCost
					} as AmountSelect
				} as CompanyTrustSmsfRunningCostModel
			]),
			catchError(() => of([{}]))
		);
	}

	private fetchTrustIncomeDetails(trustApplicant: ShortApplicant): Observable<CompanyTrustIncome[] | {}[]> {
		return this.getCustom(
			`TrustApplicant/Income/${this.applicationDataQuery.applicationId()}/${trustApplicant.id}`
		).pipe(
			map((res: CompanyTrustIncomesDTO) => [CompanyTrustIncomeTransformer.fromPayload(res)]),
			catchError(() => of([{}]))
		);
	}
}
