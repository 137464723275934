import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorEligibilityTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorEligibilityModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			suspectUnderPressure: getNullableYesNo(payload.suspectUnderPressure),
			suspectMisrepresentation: getNullableYesNo(payload.suspectMisrepresentation),
			suspectUnreasonableConduct: getNullableYesNo(payload.suspectUnfairConduct),
			suspectIncapable: getNullableYesNo(payload.suspectDifficultyUnderstandingObligations),
			executedUnderPowerOfAttorney: getNullableYesNo(payload.isUnderPowerOfAttorney),
			isGuarantorMinor: getNullableYesNo(payload.isGuarantorMinor)
		};
	}
}

export interface GuarantorEligibilityModel {
	id?: number;
	lendingGuaranteeId?: number;
	suspectUnderPressure?: YesNo;
	suspectMisrepresentation?: YesNo;
	suspectUnreasonableConduct?: YesNo;
	suspectIncapable?: YesNo;
	executedUnderPowerOfAttorney?: YesNo;
	isGuarantorMinor?: YesNo;
}
