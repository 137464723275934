import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyCheckbox = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'checkbox',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'checkbox',
			className: `${property.templateOptions?.class ?? (property.class || 'col-2')}`,
			templateOptions: {
				...property.templateOptions,
				...{
					pattern:
						property.templateOptions?.pattern?.toString().toLocaleUpperCase() === 'TRUE'
							? 'true'
							: (property.templateOptions?.pattern as string)
				}
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			resetOnHide: !!property.templateOptions?.resetOnHide,
			defaultValue: property.templateOptions?.defaultValue?.toString().toLowerCase() === 'true',
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
