<div [ngClass]="{ 'block-file-image': to.list }">
	<div class="doc-placeholder-image">
		<img
			[src]="fileUrl"
			[width]="width"
			[height]="height"
			[ngClass]="{ 'img-thumbnail': to.list }"
			class="img-border img-margin"
		/>
	</div>

	<div class="simp-margin-left-base simp-margin-top-micro" *ngIf="to.displayLabel">
		<p class="simp-text" *ngIf="formlyFileUploadModel?.fileName">{{ formlyFileUploadModel.fileName }}</p>
		<p>
			{{
				formlyFileUploadModel.dateCreated
					? formlyFileUploadModel.dateCreated
					: (currentDate.toDateString() | formatDate)
			}}
		</p>
	</div>
</div>
