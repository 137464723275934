<simp-number-select-input
	[options]="$any($any(to.options) | selectOptions | async)"
	[id]="uniqueId"
	[formGroup]="$any(formControl)"
	[validationErrors]="field.validation?.messages!"
	[bindValue]="to.bindValueToObject ? '' : 'id'"
	[readonly]="!!to.readonly"
	metadataKey
	[formlyField]="field"
	>{{ to.label }}
</simp-number-select-input>
