import { Injectable } from '@angular/core';

import { Observable, Subject, combineLatest, filter, map, of, takeUntil } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { get } from 'lodash-es';

import { ManagementParticipantAdditionalInfo } from '@app/modules/setup/model/manage-participants.model';
import { ManageParticipantsService } from '@app/modules/setup/services/manage-participants.service';
import {
	ApplicationDetailsDto,
	ManagementParticipant,
	ManagementParticipantLabel
} from '@app/modules/setup/typings/setup';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import {
	formlyExtendExpressionProperties,
	formlyOnStatusChangedToValid,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { ParticipantType } from '../enums/app.enums';

@Injectable({ providedIn: 'root' })
export class ManagementParticipantsTransformerService {
	constructor(
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private manageParticipantsService: ManageParticipantsService
	) {}

	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] {
		formlyOnStatusChangedToValid(
			formFields,
			'manageParticipants.managementParticipants',
			(field, event: FormlyArrayUpdateEvent<any>) => {
				const model = get(field, `model[${event.index}]`) as ManagementParticipant;
				const id = model.id as any as ManagementParticipantLabel;
				const modelToSubmit = {
					...model,
					id: id.id
				} as ManagementParticipant;

				this.saveManagementParticipant(modelToSubmit, event);
			}
		);

		formlyExtendExpressionProperties(formFields, 'manageParticipants.managementParticipants.id', {
			'templateOptions.tooltip': (model: ManagementParticipant) => {
				const managementParticipantLabelObject = model?.id;
				if (!(managementParticipantLabelObject && typeof managementParticipantLabelObject !== 'number')) {
					return '';
				}

				const managementParticipantLabel = managementParticipantLabelObject as any as ManagementParticipantLabel;

				return model.participantType === ParticipantType.LoanWriter || model.participantType === ParticipantType.Broker
					? this.generateManagementParticipantToolTip(managementParticipantLabel.id, formFields)
					: of('');
			}
		});

		this.handleCurrentCoordiantorChange(formFields);

		return formFields;
	}

	saveManagementParticipant(modelToSubmit: ManagementParticipant, event: FormlyArrayUpdateEvent<any>) {
		this.manageParticipantsService.saveManagementParticipant(modelToSubmit, event.index!).subscribe(() => {
			this.fetchManagementParticipantsList();
		});
	}

	fetchManagementParticipantsList(): Observable<ManagementParticipant[]> {
		const fetchedManagementParticipants$ = this.manageParticipantsService.fetchManagedParticipant(true);
		this.simpFormlyHandlerService.upsertToState('managementParticipants', fetchedManagementParticipants$);
		return fetchedManagementParticipants$;
	}

	enableFormControlForAccreditedPeople(field: FormlyFieldConfig) {
		const applicationDetails$ = this.simpFormlyHandlerService.mapToStateData<ApplicationDetailsDto[]>('details');
		if (!applicationDetails$) {
			return;
		}

		const destroy$ = new Subject();
		applicationDetails$.pipe(takeUntil(destroy$)).subscribe((res) => {
			const applicationDetails = res[0];
			if (!applicationDetails) {
				return;
			}

			destroy$.next(null);
			destroy$.complete();

			const managementParticipant = field.parent?.model as ManagementParticipant;
			if (!managementParticipant) {
				return;
			}
			const userSelectedIsAccredited = this.formEnumsQuery
				.getOptions('AccreditedPeople')
				.find((personEnum) => personEnum.id === managementParticipant.userId);

			const isLoanWriterSelected = managementParticipant.userId === applicationDetails.accreditedLoanWriterId;
			const deleteManagementField = getFormField(field.parent?.fieldGroup, 'deleteManagement');

			if (!!userSelectedIsAccredited && isLoanWriterSelected) {
				field.formControl?.disable();
				this.hideDeleteManagementField(deleteManagementField, true);
			} else {
				field.formControl?.enable();
				this.hideDeleteManagementField(deleteManagementField, false);
			}
		});
	}

	private hideDeleteManagementField(deleteManagementField: FormlyFieldConfig | undefined, hide: boolean) {
		if (deleteManagementField) {
			deleteManagementField.hide = hide;
		}
	}

	private handleCurrentCoordiantorChange(formFields: FormlyFieldConfig[] | undefined): void {
		const personId = getFormField(formFields, `manageParticipants.managementParticipants.isCurrentCoordinator`) ?? {};
		Object.assign(personId, {
			...personId,
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					this.enableFormControlForAccreditedPeople(field);
				}
			}
		});
	}

	private generateManagementParticipantToolTip(
		participantId: number,
		formFields: FormlyFieldConfig[]
	): Observable<string> {
		const participant$ = this.simpFormlyHandlerService
			.mapToStateData<ManagementParticipant[]>('managementParticipants')
			?.pipe(filter((participants) => participants && participants.length > 0 && participants != null));
		const participantsAdditionalInfo$ = this.simpFormlyHandlerService
			.mapToStateData<ManagementParticipantAdditionalInfo[]>('managementParticipantsAdditionalInfo')
			?.pipe(
				filter(
					(participantsAdditionalInfo) =>
						participantsAdditionalInfo && participantsAdditionalInfo.length > 0 && participantsAdditionalInfo != null
				)
			);

		if (participant$ && participantsAdditionalInfo$) {
			const accreditationNumberLabel = getFormField(
				formFields,
				'manageParticipants.managementParticipants.accreditationNumberLabel'
			)?.template as string;

			const aggregatorNumberLabel = getFormField(
				formFields,
				'manageParticipants.managementParticipants.aggregatorNumberLabel'
			)?.template as string;

			const tpmiNumberLabel = getFormField(formFields, 'manageParticipants.managementParticipants.tpmiNumberLabel')
				?.template as string;

			const subTpmiNumberLabel = getFormField(
				formFields,
				'manageParticipants.managementParticipants.subTpmiNumberLabel'
			)?.template as string;

			const mobileNumberLabel = getFormField(formFields, 'manageParticipants.managementParticipants.mobileNumberLabel')
				?.template as string;

			return combineLatest([participant$, participantsAdditionalInfo$]).pipe(
				map(([participants, participantsAdditionalInfo]) => {
					const info: string[] = [];

					const participant = participants?.find((element) => element?.id === participantId);
					const participantAdditionalInfo = participantsAdditionalInfo?.find(
						(additionalInfo) => Number(additionalInfo?.id) === participantId
					);

					if (participant?.fullName) {
						info.push(`<div><b>${participant?.fullName}</b></div>`);
					}
					if (participant?.email) {
						info.push(`<div>${participant?.email}</div>`);
					}
					if (participantAdditionalInfo?.lenderNumber && accreditationNumberLabel?.trim()) {
						info.push(`<div>${accreditationNumberLabel}: ${participantAdditionalInfo?.lenderNumber}</div>`);
					}
					if (participantAdditionalInfo?.aggregatorNumber && aggregatorNumberLabel?.trim()) {
						info.push(`<div>${aggregatorNumberLabel}: ${participantAdditionalInfo?.aggregatorNumber}</div>`);
					}
					if (participantAdditionalInfo?.brokerEntityTpmiNumber && tpmiNumberLabel?.trim()) {
						info.push(`<div>${tpmiNumberLabel}: ${participantAdditionalInfo?.brokerEntityTpmiNumber}</div>`);
					}
					if (participantAdditionalInfo?.subTpmiNumber && subTpmiNumberLabel?.trim()) {
						info.push(`<div>${subTpmiNumberLabel}: ${participantAdditionalInfo?.subTpmiNumber}</div>`);
					}
					if (participantAdditionalInfo?.mobile) {
						info.push(`<div>${mobileNumberLabel}: ${participantAdditionalInfo?.mobile}</div>`);
					}
					return info.join('');
				})
			);
		} else {
			return of('');
		}
	}
}
