import { AssetPropertyModelTransformer, RealEstateDetails } from '@app/modules/financial-position/model/property.model';
import { PropertyDetailsModelTransformer } from '@app/modules/property/models/property-details.model';
import { PropertyModalModel } from '@app/modules/property/models/property-modal.model';
import {
	AssetTransaction,
	ConstructionType,
	LandAreaUnits,
	TargetType,
	VisitContactType,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { getNullableBoolean, getNullableNumber, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ConstructionDetailsDTO, PropertyDTO } from '@app/modules/typings/api';
import { ConstructionBuilder } from './construction-builder.model';
import { ConstructionStage } from './construction-stage.model';

export interface PropertyAssetModel {
	id: number;
	details?: RealEstateDetails;
	isClone?: boolean; // Used to check whether duplicated entry in the state
	applicationId?: number;
	featureId?: number;
	propertyImage?: string;
	zoningType?: string;
	propertyTypeName?: string;
	primaryLandUse?: string;
	landArea?: number;
	floorArea?: string;
	allPropertyFeatures?: string;
	bedrooms?: number;
	bathrooms?: number;
	carSpaces?: number;
	wallMaterial?: string;
	roofMaterial?: string;
	autoConsol?: string;
	volume?: string;
	folio?: string;
	folioID?: string;
	realPropertyDescriptor?: string;
	lotPlan?: string;
	lot?: string;
	isOnMarketTransaction?: YesNo;
	isLegalRepresentation?: YesNo;
	conveyancer?: ApplicantEnumObject;
	isConstruction?: YesNo;
	valuer: ApplicantEnumObject;
	certifiedValue?: number;
	certifiedValuedDate?: number;
	visitContactType?: VisitContactType;
	visitContactName?: ApplicantEnumObject;
	notesForValuer?: string;
	constructionDetails?: ConstructionDetailsModal;
	fairMarketValue?: number;
	contractDate?: string;
	financeApprovalDate?: string;
	contractPriceAmount?: number;
	transferOfLandAmount?: number;
	depositPaid?: number;
	licensedRealStateAgentContract?: YesNo;
	contractDetailsArmsLengthTransaction?: YesNo;
	extract?: string;
	isForeign: boolean;
}

export interface ConstructionDetailsModal {
	extract?: string;
	constructionModal: {
		constructionType: number;
		estimatedCompletionDate: string;
		initialAmount?: number;
		buildPriceAmount?: number;
		landValue?: number;
		totalAmount?: number;

		stages?: ConstructionStage[];
		builder: ConstructionBuilder[];
	};
}

export class PropertyAssetModelTransformer {
	static toPayload(
		applicationId: number,
		property: PropertyAssetModel,
		propertyDetails: PropertyModalModel
	): PropertyDTO {
		// Map property modal details
		const propertyDto = PropertyDetailsModelTransformer.toPayload(
			applicationId,
			propertyDetails.propertyAssetDetails[0],
			propertyDetails.transaction,
			propertyDetails.transaction !== AssetTransaction.Purchasing || propertyDetails.approvalInPrinciple !== YesNo.Yes
				? propertyDetails.address
				: propertyDetails.suburbAddress,
			propertyDetails.approvalInPrinciple
		);

		return PropertyAssetModelTransformer.toPayloadPropertySecurityDetails(property, applicationId, propertyDto);
	}

	static toPayloadPropertySecurityDetails(
		property: PropertyAssetModel,
		applicationId: number,
		propertyDto: PropertyDTO
	): PropertyDTO {
		propertyDto.zoningType = property.zoningType;
		propertyDto.propertyTypeName = property.propertyTypeName;
		propertyDto.primaryLandUse = property.primaryLandUse;
		propertyDto.autoConsol = property.autoConsol;
		propertyDto.volume = property.volume;
		propertyDto.folio = property.folio;
		propertyDto.titleReference = property.folioID;
		propertyDto.realPropertyDescriptor = property.realPropertyDescriptor;
		propertyDto.lotPlan = property.lotPlan;
		propertyDto.lot = property.lot;
		propertyDto.onMarketTransaction = getNullableBoolean(property.isOnMarketTransaction);
		propertyDto.legalRepresentation = getNullableBoolean(property.isLegalRepresentation);
		propertyDto.conveyancerId = property.conveyancer?.id;
		propertyDto.isConstruction = getNullableBoolean(property.isConstruction);
		propertyDto.contractDate = property.contractDate;
		propertyDto.contractFinanceApprovalDate = property.financeApprovalDate;
		propertyDto.contractPriceAmount = property.contractPriceAmount;
		propertyDto.contractTransferOfLandAmount = property.transferOfLandAmount;
		propertyDto.depositAmount = property.depositPaid;
		propertyDto.licensedRealStateAgentContract = getNullableBoolean(property.licensedRealStateAgentContract);
		propertyDto.contractDetailsArmsLengthTransaction = getNullableBoolean(
			property.contractDetailsArmsLengthTransaction
		);
		propertyDto.certifiedValue = property.certifiedValue;
		propertyDto.certifiedValueDate = property.certifiedValuedDate;
		propertyDto.valuerId = property.valuer?.id;
		propertyDto.fairMarketValue = property.fairMarketValue;
		propertyDto.visitContactType = property.visitContactType;
		propertyDto.visitContactPartyId = property.visitContactName?.id;
		propertyDto.visitContactTargetType = property.visitContactName?.type;
		propertyDto.visitContactDetails = property.notesForValuer;

		if (propertyDto?.id && propertyDto.id < 0) {
			return propertyDto;
		}

		propertyDto.features = {
			id: property.featureId ?? -1,
			applicationId: applicationId,
			realEstateAssetId: property.id,
			propertyImage: property.propertyImage,
			landArea: property.landArea,
			landAreaUnitsId: LandAreaUnits.SquareMetres,
			floorArea: getNullableNumber(property.floorArea),
			allPropertyFeatures: property.allPropertyFeatures,
			numberOfBedrooms: getNullableNumber(property.bedrooms),
			numberOfBathrooms: getNullableNumber(property.bathrooms),
			numberOfCarSpaces: getNullableNumber(property.carSpaces),
			wallMaterial: property.wallMaterial,
			roofMaterial: property.roofMaterial
		};

		return propertyDto;
	}

	static toPayloadConstructionDetails(
		modal: ConstructionDetailsModal,
		propertyId: number,
		applicationID: number
	): ConstructionDetailsDTO {
		const constructionData = modal.constructionModal;

		const builder = constructionData.builder?.length > 0 ? constructionData.builder[0] : undefined;
		return {
			type: constructionData.constructionType,
			estimatedCompletionDate: constructionData.estimatedCompletionDate,
			initialAmount: constructionData.initialAmount,
			buildPriceAmount: constructionData.buildPriceAmount,
			landValue: constructionData.landValue,
			totalAmount: constructionData.totalAmount,
			stages: constructionData.stages,
			applicationId: applicationID,
			propertyId: propertyId,
			builderPartyId: builder?.builder?.id,
			builderTargetType: builder?.builder?.type,
			builderType: builder?.builderType,
			constructionInsuranceHeld: builder?.constructionInsuranceHeld,
			copyBuildersLicenceHeld: builder?.copyBuildersLicenceHeld,
			signedContract: builder?.signedContract,
			copyHomeOwnersWarrantyCertificateHeld: builder?.copyHomeOwnersWarrantyCertificateHeld,
			councilApprovalHeld: builder?.councilApprovalHeld,
			detailedCostingsHeld: builder?.detailedCostingHeld,
			fixedPriceContract: builder?.fixedPriceContract,
			satisfactoryTOCValuationHeld: builder?.satisfactoryTOCValuationHeld,
			tenPercentOfLendingAsCash: builder?.tenPercentOfLendingAsCash,
			ownerBuilderExperienceOtherExperience: builder?.otherExperience,
			ownerBuilderExperienceCompletedSimilarProjectInLast2Years: builder?.completedSimilarProjectInLast2Years,
			ownerBuilderExperienceMinimalExperience: builder?.minimalExperience,
			ownerBuilderExperienceWorksInBuildingIndustry: builder?.worksInBuildingIndustry,
			developerPartyId: builder?.developer?.id,
			developerTargetType: builder?.developer?.type
		};
	}

	static fromPayloadConstructionDetails(
		constructionDetail: ConstructionDetailsDTO,
		formEnumsQuery: FormEnumsQuery
	): ConstructionDetailsModal {
		if (!constructionDetail) {
			return {} as ConstructionDetailsModal;
		}

		return {
			extract: PropertyAssetModelTransformer.setConstructionDetailsExtract(constructionDetail, formEnumsQuery),
			constructionModal: {
				constructionType: constructionDetail.type as ConstructionType,
				estimatedCompletionDate: constructionDetail.estimatedCompletionDate as string,
				initialAmount: constructionDetail.initialAmount,
				buildPriceAmount: constructionDetail.buildPriceAmount,
				landValue: constructionDetail.landValue,
				totalAmount: constructionDetail.totalAmount,
				builder: [
					{
						builder: formEnumsQuery.getExistingPartiesById({
							id: constructionDetail.builderPartyId as number,
							type: constructionDetail.builderTargetType as TargetType
						}),
						builderType: constructionDetail.builderType,
						developer: formEnumsQuery.getExistingPartiesById({
							id: constructionDetail.developerPartyId as number,
							type: constructionDetail.developerTargetType as TargetType
						}),
						constructionInsuranceHeld: constructionDetail.constructionInsuranceHeld,
						copyBuildersLicenceHeld: constructionDetail.copyBuildersLicenceHeld,
						signedContract: constructionDetail.signedContract,
						copyHomeOwnersWarrantyCertificateHeld: constructionDetail.copyHomeOwnersWarrantyCertificateHeld,
						councilApprovalHeld: constructionDetail.councilApprovalHeld,
						detailedCostingHeld: constructionDetail.detailedCostingsHeld,
						fixedPriceContract: constructionDetail.fixedPriceContract,
						satisfactoryTOCValuationHeld: constructionDetail.satisfactoryTOCValuationHeld,
						tenPercentOfLendingAsCash: constructionDetail.tenPercentOfLendingAsCash,
						otherExperience: constructionDetail.ownerBuilderExperienceOtherExperience,
						minimalExperience: constructionDetail.ownerBuilderExperienceMinimalExperience,
						worksInBuildingIndustry: constructionDetail.ownerBuilderExperienceWorksInBuildingIndustry,
						completedSimilarProjectInLast2Years:
							constructionDetail.ownerBuilderExperienceCompletedSimilarProjectInLast2Years
					}
				],
				stages: constructionDetail.stages
			}
		};
	}

	static fromPayload(
		propertyDTO: PropertyDTO,
		applicants: ShortApplicant[],
		formEnumsQuery: FormEnumsQuery,
		personsCompaniesEnumService: PersonsCompaniesEnumService,
		constructionDetail: ConstructionDetailsDTO | null = null
	): PropertyAssetModel {
		return {
			id: propertyDTO.id,
			applicationId: propertyDTO.applicationId,
			details: AssetPropertyModelTransformer.fromPayload(propertyDTO, applicants)?.details,
			zoningType: propertyDTO.zoningType,
			propertyTypeName: propertyDTO.propertyTypeName,
			primaryLandUse: propertyDTO.primaryLandUse,
			propertyImage: propertyDTO.features?.propertyImage,
			featureId: propertyDTO.features?.id,
			landArea: propertyDTO.features?.landArea,
			floorArea: propertyDTO.features?.floorArea,
			allPropertyFeatures: propertyDTO.features?.allPropertyFeatures,
			bedrooms: propertyDTO.features?.numberOfBedrooms,
			bathrooms: propertyDTO.features?.numberOfBathrooms,
			carSpaces: propertyDTO.features?.numberOfCarSpaces,
			wallMaterial: propertyDTO.features?.wallMaterial,
			roofMaterial: propertyDTO.features?.roofMaterial,
			autoConsol: propertyDTO.autoConsol,
			volume: propertyDTO.volume,
			folio: propertyDTO.folio,
			folioID: propertyDTO.titleReference,
			realPropertyDescriptor: propertyDTO.realPropertyDescriptor,
			lotPlan: propertyDTO.lotPlan,
			lot: propertyDTO.lot,
			isOnMarketTransaction: getNullableYesNo(propertyDTO.onMarketTransaction),
			isLegalRepresentation: getNullableYesNo(propertyDTO.legalRepresentation),
			conveyancer: formEnumsQuery.getExistingPartiesById({
				type: TargetType.RelatedCompany,
				id: propertyDTO.conveyancerId!
			}),
			isConstruction: getNullableYesNo(propertyDTO.isConstruction),
			contractDate: propertyDTO.contractDate,
			financeApprovalDate: propertyDTO.contractFinanceApprovalDate,
			contractPriceAmount: propertyDTO.contractPriceAmount,
			transferOfLandAmount: propertyDTO.contractTransferOfLandAmount,
			depositPaid: propertyDTO.depositAmount,
			licensedRealStateAgentContract: getNullableYesNo(propertyDTO.licensedRealStateAgentContract),
			contractDetailsArmsLengthTransaction: getNullableYesNo(propertyDTO.contractDetailsArmsLengthTransaction),
			certifiedValue: propertyDTO.certifiedValue,
			certifiedValuedDate: propertyDTO.certifiedValueDate,
			valuer: formEnumsQuery.getExistingPartiesById({
				id: propertyDTO.valuerId as number,
				type: TargetType.RelatedCompany
			}),
			constructionDetails:
				constructionDetail !== undefined
					? PropertyAssetModelTransformer.fromPayloadConstructionDetails(constructionDetail!, formEnumsQuery)
					: undefined,
			fairMarketValue: propertyDTO.fairMarketValue,
			extract: `ConstructionType.${constructionDetail?.type}`,
			visitContactName: formEnumsQuery.getExistingPartiesById({
				id: propertyDTO.visitContactPartyId as number,
				type: propertyDTO.visitContactTargetType as TargetType
			}),
			visitContactType: propertyDTO.visitContactType,
			isForeign: propertyDTO.isForeign
		} as PropertyAssetModel;
	}

	static setConstructionDetailsExtract(
		constructionDetail: ConstructionDetailsDTO,
		formEnumsQuery: FormEnumsQuery
	): string {
		if (constructionDetail.type && constructionDetail.totalAmount) {
			return `${formEnumsQuery.getOptionLabel(
				'ConstructionType',
				constructionDetail.type
			)}${` - $${constructionDetail.totalAmount.toLocaleString()}`}`;
		} else if (constructionDetail.totalAmount) {
			return `$${constructionDetail.totalAmount.toLocaleString()}`;
		} else if (constructionDetail.builderType && constructionDetail.buildPriceAmount) {
			// builderType and buildPriceAmount used by ANZ
			return `${formEnumsQuery.getOptionLabel(
				'BuilderType',
				constructionDetail.builderType
			)}${` - $${constructionDetail.buildPriceAmount.toLocaleString()}`}`;
		} else if (constructionDetail.buildPriceAmount) {
			return `$${constructionDetail.buildPriceAmount.toLocaleString()}`;
		} else {
			return '';
		}
	}
}
