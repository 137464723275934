import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ENV_CONFIG } from '@app/env-config';
import { CONSTANTS } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class NotFoundGuard implements CanActivate {
	constructor(private router: Router) {}

	public canActivate(): boolean {
		if (ENV_CONFIG.enableNotFoundPage) {
			return true;
		}

		void this.router.navigate([CONSTANTS.LOANAPP_CLIENT_LANDING_URL]);
		return false;
	}
}
