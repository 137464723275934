<hr *ngIf="showSectionSeparator" class="simp-margin-top-base separator" />
<div
	[id]="subSectionId"
	#subSection
	(insideViewport)="onElementView($event, field)"
	class="sub-section content"
	[ngClass]="{
		'sub-section--validation-error': formControl.dirty && formControl.invalid,
		'sub-section--grey-background': to.greyBackground
	}"
>
	<div
		class="d-flex justify-content-between sub-section__header"
		[ngClass]="{
			'sub-section__header--validation-error': formControl.dirty && formControl.invalid && !formControl.value.length
		}"
		metadataKey
		[formlyField]="field"
	>
		<div *ngIf="!!to.iconClass" class="sub-section--icon-wrapper simp-margin-right-small">
			<i class="{{ to.iconClass + ' sub-section--icon' }}"></i>
		</div>
		<h5
			class="simp-h5 simp-text--secondary"
			[class.loanapp-area__sub-section-title]="to.collapsible"
			[class.sub-section--icon-label]="!!to.iconClass"
			(click)="to.expanded = to.collapsible ? !to.expanded : to.expanded"
			[attr.data-testid]="'sub-' + subSectionId"
		>
			{{ to.label }} {{ to.list && to.showRowCount ? '(' + field.formControl.value?.length + ')' : '' }}
		</h5>

		<div class="d-flex justify-content-between flex-grow-1 loanapp-area-button__container">
			<ng-container *ngIf="to.list && (to.expanded || !to.collapsible)">
				<button
					(click)="handleButtonClick()"
					[id]="subSectionId + '__add'"
					type="button"
					[disabled]="!!to.readonly || (loading$ | async)"
					class="btn btn-sm simp-button simp-margin-left-small loanapp-area__add-button"
					*ngIf="to.maxLength ? field.fieldGroup?.length! < to.maxLength : true"
					[attr.data-testid]="'add-' + subSectionId"
				>
					{{ to.subText ?? 'Add' }}<i class="fal fa-plus simp-margin-left-base"></i>
				</button>
			</ng-container>
			<ng-container *ngIf="to.customTitleTemplate">
				<div [innerHTML]="to.customTitleTemplate"></div>
			</ng-container>
			<ng-container *ngIf="headerField && headerData$">
				<formly-header
					class="d-flex flex-grow-1 justify-content-end simp-margin-right-small"
					[headerModel]="headerData$ | async"
					[headerField]="headerField"
					[showHeader]="showHeader"
				></formly-header>
			</ng-container>
			<ng-container *ngIf="!headerField">
				<formly-templates
					class="d-flex flex-grow-1 justify-content-end simp-margin-right-small"
					[field]="field"
				></formly-templates>
			</ng-container>
		</div>
		<i
			*ngIf="to.collapsible"
			class="fas loanapp-area__up-down-chevron"
			[ngClass]="to.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
			(click)="to.expanded = !to.expanded"
		></i>
	</div>

	<div
		*ngIf="to.subtitle"
		class="simp-margin-top-small"
		[innerHtml]="isObservable(to.subtitle) ? (to.subtitle | async) : to.subtitle"
	></div>

	<div *ngIf="formControl.touched && formControl.invalid && !formControl.errors && validated && showSectionSeparator">
		<div class="simp-field-error simp-text-small simp-text--red">
			{{ field.validation?.messages?.invalid || 'Please fix the highlighted errors' }}
		</div>
	</div>
	<div
		class="simp-field-error simp-text-small simp-text--blue"
		*ngFor="let error of remoteErrors$ | async"
		[innerHtml]="error"
	></div>
	<simp-field-error
		*ngIf="(formControl.dirty || formControl.touched) && formControl.errors"
		[errors]="formControl.errors"
		[validationErrors]="field.validation?.messages!"
	>
	</simp-field-error>

	<div
		[ngClass]="{ 'simp-margin-top-base': showSectionSeparator }"
		[hidden]="!to.expanded && to.collapsible"
		*ngIf="(loading$ | async) !== true"
	>
		<formly-field *ngFor="let f of field.fieldGroup; trackBy: trackByFn" [field]="f"></formly-field>
	</div>
	<ng-container *ngIf="props.notFoundText && (!model || model?.length === 0)">
		<span class="simp-color-grey-90">{{ props.notFoundText }}</span>
	</ng-container>
</div>

<div
	[ngClass]="{ 'simp-margin-top-small': showSectionSeparator }"
	*ngIf="(loading$ | async) === true"
	class="d-flex justify-content-center align-items-center"
>
	<simp-spinner placement="inline"></simp-spinner>
	<div class="mx-2">
		<div *ngIf="subsectionLoaderText?.primaryText">
			{{ subsectionLoaderText.primaryText }}
		</div>
		<div *ngIf="subsectionLoaderText?.secondaryText">
			{{ subsectionLoaderText.secondaryText }}
		</div>
	</div>
</div>

<div
	[ngClass]="{ 'simp-margin-top-small': showSectionSeparator }"
	class="d-flex justify-content-center align-items-center"
	*ngIf="(subSectionData$ | async)?.length === 0 && !!(loading$ | async) === false && !!(placeHolderTemplate$ | async)"
>
	<div class="mx-2">
		<div [innerHTML]="placeHolderTemplate$ | async"></div>
	</div>
</div>
