import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ENV_CONFIG } from '@app/env-config';
import { BrandingService } from '@simpology/authentication';
import { filter, map, Observable, take, tap } from 'rxjs';

@Component({
	selector: 'not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {
	brandUrl$?: Observable<string>;
	brandName = '';

	constructor(private brandingService: BrandingService, private title: Title) {}

	ngOnInit(): void {
		this.brandingService.setBranding(ENV_CONFIG, true);
		this.brandUrl$ = this.brandingService.brandData.pipe(
			filter((data) => !!data?.brandUrl),
			take(1),
			tap((data) => {
				this.brandName = data.brandName;
				this.title.setTitle(`${this.brandName} | Home loan application`);
			}),
			map((data) => data.brandUrl)
		);
	}

	launchNetBank(): void {
		window.location.href = 'https://www.my.commbank.com.au/netbank/Logon/Logon.aspx';
	}
}
