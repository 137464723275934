import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { ApplicationDocType, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo, isNullOrUndefined } from '@app/modules/shared/helper/util';
import { CurrencyHelper } from '@simpology/client-components/utils';
import { ApplicationDetails, ApplicationDetailsDto, OldApplicationDetails, SetupApplication } from '../typings/setup';
import { AggregatorCompanyTransformer } from './aggregator-company.model';
import { BusinessChannelTransformer } from './business-channel.model';
import { LoanWriterCompanyTransformer } from './loan-writer-company.model';

export class ApplicationDetailsTransformer {
	static fromPayload(applicationDto: ApplicationDetailsDto): Partial<ApplicationDetails> {
		const applicationData = {
			applicationType: applicationDto.applicationType,
			applicationEntityType: applicationDto.applicationEntityType,
			amount: applicationDto.amount === 0 ? null : applicationDto.amount,
			applicationDate: applicationDto.applicationDate,
			authorisationLevelId: applicationDto.authorisationLevelId,
			brokerUserId: applicationDto.brokerUserId,
			accreditedLoanWriterId: applicationDto.accreditedLoanWriterId ?? undefined,
			loanWriterCompany: LoanWriterCompanyTransformer.createLoanWriterCompanyModel(
				applicationDto.loanWriterCompanyModel
			),
			aggregatorCompany: AggregatorCompanyTransformer.fromPayload(applicationDto.aggregatorCompanyModel),
			businessChannel: BusinessChannelTransformer.fromPayload(applicationDto.businessChannelModel),
			id: applicationDto.id,
			isUrgent: applicationDto.isUrgent,
			lenderApplicationNumber: applicationDto.lenderApplicationNumber,
			preCheck: applicationDto.preCheck,
			reference: applicationDto.reference,
			targetDate: applicationDto.targetDate,
			title: applicationDto.title,
			hasLenderAcknowledgedSignedDocument: applicationDto.hasLenderAcknowledgedSignedDocument,
			aggregatorReferenceNumber: applicationDto.aggregatorReferenceNumber,
			isSplit: !!applicationDto.isSplit,
			primaryLendingPurpose: applicationDto.rbaLendingPurpose,
			fastRefinance: getNullableYesNo(applicationDto.fastRefinance),
			approvalInPrinciple: getNullableYesNo(applicationDto.approvalInPrinciple),
			approvalInPrincipleToggle: getNullableYesNo(applicationDto.approvalInPrinciple),
			applicationDocType: applicationDto.applicationDocType,
			internalName: applicationDto.internalName,
			bankBranchId: applicationDto.bankBranchId,
			bsb: applicationDto.bsb,
			linkedCommercialApplication: applicationDto.linkedCommercialApplication,
			linkedApplicationDetailRecords: applicationDto.linkedApplicationDetailRecords,
			overviewId: applicationDto.overviewId,
			bridging: getNullableYesNo(applicationDto.bridging),
			simpleRefinance: getNullableYesNo(applicationDto.simpleRefinance),
			cla: getNullableYesNo(applicationDto.cla),
			smsfLoan: getNullableYesNo(applicationDto.smsfLoan),
			sophisticatedInvestor: getNullableYesNo(applicationDto.sophisticatedInvestor),
			topUp: getNullableYesNo(applicationDto.topUp),
			construction: getNullableYesNo(applicationDto.construction),
			expat: getNullableYesNo(applicationDto.expat),
			commercial: getNullableYesNo(applicationDto.commercial),
			sharia: getNullableYesNo(applicationDto.sharia),
			nonResidential: getNullableYesNo(applicationDto.nonResidential)
		} as Partial<ApplicationDetails>;

		const loDocValue = this.getLoDocFromPayload(applicationDto?.applicationDocType);
		if (!isNullOrUndefined(loDocValue)) {
			applicationData.loDoc = loDocValue;
		}

		return applicationData;
	}

	static toPayload(applicationModel: SetupApplication, applicationId: number): ApplicationDetailsDto {
		const applicationDetails = applicationModel.details[0];
		const applicationConfiguration = applicationModel?.configuration ? applicationModel?.configuration[0] : null;

		return {
			id: applicationId ?? CONSTANTS.NEW_ID,
			applicationType: applicationDetails.applicationType,
			applicationEntityType: applicationDetails.applicationEntityType,
			lenderApplicationNumber: applicationDetails.lenderApplicationNumber,
			reference: applicationDetails.reference,
			title: applicationDetails.title ? applicationDetails.title : undefined,
			amount: CurrencyHelper.unformatAmount(applicationDetails.amount),
			authorisationLevelId: applicationDetails.authorisationLevelId,
			brokerUserId: applicationDetails.brokerUserId ? applicationDetails.brokerUserId : undefined,
			accreditedLoanWriterId: applicationDetails.accreditedLoanWriterId,
			loanWriterCompanyModel: LoanWriterCompanyTransformer.toPayload(applicationDetails.loanWriterCompany),
			applicationDate: applicationDetails.applicationDate,
			targetDate: applicationDetails.targetDate,
			isUrgent: !!applicationDetails.isUrgent,
			preCheck: applicationDetails.preCheck,
			applicationTemplateId: applicationConfiguration?.applicationTemplateId,
			branding: applicationConfiguration?.branding,
			mustSendInfoRequestsOnActivation: !!applicationConfiguration?.mustSendInfoRequestsOnActivation,
			rbaLendingPurpose: applicationDetails.primaryLendingPurpose,
			approvalInPrinciple: getNullableBoolean(applicationDetails.approvalInPrincipleToggle),
			fastRefinance: getNullableBoolean(applicationDetails.fastRefinance),
			applicationDocType: this.setApplicationDocTypeToPayload(applicationDetails),
			internalName: applicationDetails.internalName,
			bankBranchId: applicationDetails.bankBranchId,
			bsb: applicationDetails.bsb,
			linkedCommercialApplication: applicationDetails.linkedCommercialApplication,
			linkedApplicationDetailRecords: applicationDetails.linkedApplicationDetailRecords,
			overviewId: applicationDetails.overviewId,
			bridging: getNullableBoolean(applicationDetails.bridging),
			smsfLoan: getNullableBoolean(applicationDetails.smsfLoan),
			sophisticatedInvestor: getNullableBoolean(applicationDetails.sophisticatedInvestor),
			topUp: getNullableBoolean(applicationDetails.topUp),
			simpleRefinance: getNullableBoolean(applicationDetails.simpleRefinance),
			isSplit: !!applicationDetails.isSplit,
			cla: getNullableBoolean(applicationDetails.cla),
			construction: getNullableBoolean(applicationDetails.construction),
			expat: getNullableBoolean(applicationDetails.expat),
			commercial: getNullableBoolean(applicationDetails.commercial),
			sharia: getNullableBoolean(applicationDetails.sharia),
			nonResidential: getNullableBoolean(applicationDetails.nonResidential)
		} as ApplicationDetailsDto;
	}

	private static getLoDocFromPayload(applicationDocType?: ApplicationDocType): YesNo | undefined {
		if (!applicationDocType) return undefined;
		return applicationDocType === ApplicationDocType.LoDoc ? YesNo.Yes : YesNo.No;
	}

	private static setApplicationDocTypeToPayload(
		applicationDetails: OldApplicationDetails
	): ApplicationDocType | undefined {
		if (applicationDetails.loDoc !== undefined) {
			return applicationDetails.loDoc ? ApplicationDocType.LoDoc : ApplicationDocType.FullDoc;
		}

		return applicationDetails.applicationDocType;
	}
}
