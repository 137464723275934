import { Injectable } from '@angular/core';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { CurrentUserQuery } from '@app/modules/shared/store/current-user/current-user.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import {
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { getRegistry } from '@ngneat/elf';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { RefiApplicationSummaryService } from '../../components/refi-form/application-summary/application-summary.service';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiConfirmDetailsService } from './refi-confirm-details.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyConfirmDetailsTransformerService {
	constructor(
		private formDataService: FormDataService,
		private refiModalService: RefiModalService,
		private refiJourneyService: RefiJourneyService,
		private journeyStepService: JourneyStepService,
		private currentUserQuery: CurrentUserQuery,
		private confirmDetailsService: RefiConfirmDetailsService,
		private applicationDataQuery: ApplicationDataQuery,
		private journeyService: RefiJourneyService,
		private applicationSummaryService: RefiApplicationSummaryService,
		private sharedFlagService: SharedFlagsService
	) {}

	public confirmDetailsTransform(formFields: FormlyFieldConfig[]) {
		//TODO: select logged in applicant
		const applicant = this.applicationDataQuery.getRefiApplicants().find((a) => a.isCurrentUser);
		const titleField = getFormField(formFields, `confirmDetails.consentSuccess`) as FormlyFieldConfig;
		const label = titleField?.templateOptions?.label as string;

		formlyExtendExpressionProperties(formFields, `confirmDetails.consentSuccess`, {
			'templateOptions.label': () => {
				return label.replace('ApplicantFirstName', this.currentUserQuery.firstName());
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consentSuccess.whatHappensNextText', {
			onInit: (field) => {
				if (field && field.template) {
					const coBorrowerDetails = this.applicationDataQuery
						.getRefiApplicants()
						.find((app) => app.securePersonId !== this.currentUserQuery.personId());
					field.template = field.template?.replace(
						new RegExp('ApplicantFirstName', 'g'),
						coBorrowerDetails?.firstName as string
					);
					field.template = field.template?.replace(
						new RegExp('ApplicantLastName', 'g'),
						coBorrowerDetails?.lastName as string
					);
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consentSuccess.coApplicantConsent', {
			onInit: (field) => {
				if (field && field?.templateOptions?.label) {
					const coBorrowerDetails = this.applicationDataQuery
						.getRefiApplicants()
						.find((app) => app.securePersonId !== this.currentUserQuery.personId());
					field.templateOptions.label = field?.templateOptions?.label.replace(
						new RegExp('ApplicantFirstName', 'g'),
						coBorrowerDetails?.firstName as string
					);
				}
			}
		});

		formlyExtendExpressionProperties(formFields, `confirmDetails.consentCoBorrowerSuccess`, {
			'templateOptions.label': () => {
				return label.replace('ApplicantFirstName', this.currentUserQuery.firstName());
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consentCoBorrowerSuccess', {
			afterViewInit: (field) => {
				if (field && field.templateOptions) {
					const primaryApplicant = this.applicationDataQuery.getRefiApplicants().find((app) => !app.isCurrentUser);
					if (primaryApplicant) {
						this.confirmDetailsService.isPrivacyConsented(primaryApplicant.id).subscribe((privacyConsented) => {
							if (!privacyConsented && field.templateOptions) {
								field.templateOptions.secondaryButtonText = null;
							}
						});
					}
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consentCoBorrowerSuccess.description', {
			onInit: (field) => {
				const primaryApplicant = this.applicationDataQuery.getRefiApplicants().find((app) => app.primaryApplicant);
				if (field && field.template) {
					field.template = field.template?.replace(
						new RegExp('ApplicantName', 'g'),
						`${primaryApplicant?.firstName} ${primaryApplicant?.lastName}`
					);
				}
			},
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consent.descriptionSingleApplicant', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consent.descriptionMultipleApplicants', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consent.coBorrowerGuideAndPrivacyLink', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consent.privacyNoticeLink', {
			afterViewInit: (field) => {
				if (field) {
					this.refiModalService.attachEventsToOpenModal(field);
				}
				return of();
			}
		});

		formlyOnClick(formFields, 'confirmDetails.details.ensureUpToDate', () => {
			this.openRefiModal(formFields, RefiModalName.updateYourDetails).subscribe();
		});

		formlyOnClick(formFields, 'confirmDetails.details', (field, clickType: { type: string }) => {
			if (clickType.type === 'secondary') {
				this.openRefiModal(formFields, RefiModalName.updateYourDetails).subscribe();
			}
			if (clickType.type === 'primary') {
				if (applicant) {
					this.confirmDetailsService.confirmApplicantDetails(applicant.id).subscribe(() => {
						const consent = getFormField(formFields, 'confirmDetails')?.fieldGroup?.find((f) => f.key === 'consent');
						if (consent?.templateOptions) {
							consent.templateOptions.prevRoute = 'eligibility/details';
						}

						this.journeyStepService
							.updateStep(RefiStepType.RefiConfirmDetails, StepStatus.Complete)
							.subscribe(() => this.navigateToNextPage());
					});
				}
			}
		});

		formlyOnClick(formFields, 'confirmDetails.consent', (field, clickType: { type: string; invalid: boolean }) => {
			if (clickType.type === 'secondary') {
				this.openRefiModal(formFields, RefiModalName.consentWithheld).subscribe((action) => {
					if (action === 'primaryButton') {
						this.journeyService.deleteApplication().subscribe(() => {
							const currentJourney = this.sharedFlagService.currentJourney;
							getRegistry().forEach((store) => store.reset());
							this.refiJourneyService.logOut(true, currentJourney);
						});
					}
				});
			}
			if (clickType.type === 'primary' && !clickType.invalid) {
				if (applicant) {
					if (field.templateOptions) {
						field.templateOptions.loading = field.templateOptions.disablePrimaryButton = true;
					}
					this.confirmDetailsService
						.consentToPrivacy(applicant.id)
						.pipe(switchMap(() => this.refiJourneyService.initiateFEA(applicant.id)))
						.subscribe((res) => {
							if (res.status) {
								this.refiJourneyService.navigateToStep('summary/details');
							} else {
								this.refiJourneyService.displayErrorScreen();
							}
						});
				}
			}
		});

		formlyRegisterHooks(formFields, 'confirmDetails.consent', {
			onInit: (field) => {
				if (field && field.props) {
					field.props.nextRoute = 'summary/details';
				}
			},
			afterViewInit: (field) => {
				if (field && field.props) {
					this.openRefiModal(formFields, RefiModalName.CreditCheck).subscribe();
				}
			}
		});

		formlyOnClick(formFields, 'confirmDetails.consentSuccess', (field, clickType: { type: string }) => {
			if (clickType.type === 'primary') {
				this.refiJourneyService.navigateToStep('summary/details');
			}
		});

		formlyOnClick(formFields, 'confirmDetails.consentCoBorrowerSuccess', (field, clickType: { type: string }) => {
			if (clickType.type === 'primary') {
				this.refiJourneyService.navigateToStep('summary/details');
			}
			if (clickType.type === 'secondary') {
				this.applicationSummaryService.renewLock().subscribe((result: boolean) => {
					if (result) {
						this.refiJourneyService.navigateToStep('loanRequirements/details');
					} else {
						this.refiJourneyService.navigateToStep('summary/details');
					}
				});
			}
		});

		formlyOnClick(formFields, 'confirmDetails.consentSuccess.coApplicantConsent', () => {
			this.openRefiModal(formFields, RefiModalName.jointApplications).subscribe();
		});

		formlyRegisterHooks(formFields, 'confirmDetails.details', {
			onInit: (field) => {
				if (field && formFields) {
					this.openRefiModal(formFields, RefiModalName.ConfirmDetailsLoading);
				}
			}
		});
	}

	private openRefiModal(
		formFields: FormlyFieldConfig[] | undefined,
		refiModalName: RefiModalName,
		target?: string
	): Observable<string> {
		const refiModalField = getFormField(formFields, 'confirmDetails.refi-modals');

		return this.refiModalService.openRefiModal(refiModalField, refiModalName, target);
	}

	private navigateToNextPage(): void {
		const applicant = this.applicationDataQuery.getRefiApplicants().find((a) => a.isCurrentUser);
		if (applicant?.primaryApplicant) {
			this.refiJourneyService.navigateToStep('eligibility/details');
		} else {
			this.formDataService
				.select$(RefiStore.Modals)
				.pipe(
					take(1),
					switchMap((formFields) =>
						this.refiModalService
							.openRefiModal(formFields[0] as FormlyFieldConfig, RefiModalName.coborrowerInformation)
							.pipe(
								tap((buttonClickType) => {
									if (buttonClickType === 'primaryButton') {
										this.refiJourneyService.navigateToStep('confirmDetails/consent');
									}
								})
							)
					)
				)
				.subscribe();
		}
	}
}
