<div class="d-flex flex-column">
	<div class="amount-wrapper">
		<simp-amount-input
			[id]="uniqueId"
			[formControl]="$any(formControl)"
			[validationErrors]="field.validation?.messages"
			[tooltipContent]="to.tooltip"
			[placeholder]="to.placeholder!"
			[readonly]="!!to.readonly"
			[tooltipAsModal]="to.tooltipAsModal"
			(tooltipClick)="tooltipClick(field)"
			[hideLabel]="to.hideLabel"
			(amountBlur)="handleBlur()"
			[wholeNumberOnly]="to.decimalPlaces === 0"
			metadataKey
			[formlyField]="field"
		>
			{{ to.label }}
		</simp-amount-input>
		<div *ngIf="to.subText" [innerHTML]="to.subText" class="subtext"></div>
	</div>

	<div *ngIf="to.tooltipText" class="tooltip-text simp-text-small simp-padding-base simp-margin-top-base">
		<p class="simp-text--primary simp-text-small">
			{{ generateTooltipText() }}
			{{ to.tooltipText.toString().includes('-') ? 'lower' : 'higher' }}
		</p>
		<p class="simp-text--primary simp-text-small">
			<i *ngIf="!to.tooltipText.toString().includes('-')" class="fa far fa-arrow-up"></i>
			<i *ngIf="to.tooltipText.toString().includes('-')" class="fa far fa-arrow-down"></i>
		</p>
	</div>
</div>
