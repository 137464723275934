import { Injectable } from '@angular/core';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { AddressService } from '@app/modules/shared/service/address.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import {
	AddressResponse,
	CurrentAddressDTO,
	IdAndAddressId,
	PostSettlementAddressDTO,
	PreviousAddressDTO
} from '@app/modules/typings/api';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { PreviousAddressModel } from '../typings/applicants';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';

@Injectable({ providedIn: 'root' })
export class ApplicantAddressService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private addressService: AddressService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.ADDRESS);
	}

	saveCurrentAddress(currentAddress: CurrentAddressDTO): Observable<AddressResponse> {
		return this.postCustom(`Current`, currentAddress).pipe(
			tap((response: AddressResponse) => {
				currentAddress.id = response.id;
				this.addressService.fetchAllAddress();

				if (currentAddress.applicantId) {
					const applicant = this.formDataService.getSetupApplicantById(currentAddress.applicantId);
					this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForSecurePerson(
						applicant?.id,
						applicant?.securePersonId
					);
				}
			})
		);
	}

	getCurrentAddress(applicantId: number): Observable<CurrentAddressDTO> {
		return <Observable<CurrentAddressDTO>>this.get(`Current/applicant/${applicantId}`);
	}

	deleteCurrentAddress(addressId: number): Observable<boolean> {
		return this.delete(`Current/${this.applicationDataQuery.applicationId()}/${addressId}`).pipe(map(() => true));
	}

	savePreviousAddress(previousAddress: PreviousAddressModel): Observable<IdAndAddressId> {
		const modelToSubmit: PreviousAddressDTO = {
			...previousAddress,
			startDate: previousAddress.duration![0],
			endDate: previousAddress.duration![1]
		};

		return this.postCustom(`Previous`, modelToSubmit).pipe(
			tap((response: IdAndAddressId) => {
				previousAddress.id = response.id;
				this.addressService.fetchAllAddress();
			})
		);
	}

	getPreviousAddresses(applicantId: number): Observable<PreviousAddressDTO[]> {
		return <Observable<PreviousAddressDTO[]>>this.get(`Previous/applicant/${applicantId}`);
	}

	deletePreviousAddress(previousAddress: PreviousAddressModel): Observable<boolean> {
		if (previousAddress?.id) {
			return this.delete(`Previous/${this.applicationDataQuery.applicationId()}/${previousAddress.id}`).pipe(
				map(() => true)
			);
		} else {
			return of(true);
		}
	}

	savePostSettlementAddress(postSettlementAddress: PostSettlementAddressDTO): Observable<IdAndAddressId> {
		return this.postCustom(`PostSettlement`, postSettlementAddress).pipe(
			tap((response: IdAndAddressId) => {
				postSettlementAddress.id = response.id;
				this.addressService.fetchAllAddress();
			})
		);
	}

	getPostSettlementAddress(applicantId: number): Observable<PostSettlementAddressDTO> {
		return <Observable<PostSettlementAddressDTO>>this.get(`PostSettlement/applicant/${applicantId}`);
	}

	deletePostSettlementAddress(addressId: number): Observable<boolean> {
		return this.delete(`PostSettlement/${this.applicationDataQuery.applicationId()}/${addressId}`).pipe(
			map(() => true)
		);
	}
}
