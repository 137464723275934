import { Importance, InterestInAdvanceImportantOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { InterestInAdvanceDTO } from '@app/modules/typings/api';

export class InterestInAdvanceTransformer {
	static toPayload(applicationId: number, details: InterestInAdvanceModel): InterestInAdvanceDTO {
		const options = details.interestInAdvanceExtras?.interestInAdvanceOptions?.options ?? [];
		return {
			applicationId: applicationId,
			importance: details.interestInAdvanceImportanceId,
			reasonDescription: details.interestInAdvanceExtras?.interestInAdvanceOptions?.otherReasonInput,
			risksExplained: details.interestInAdvanceRisksExplained ?? undefined,
			reasonForTaxPurposes: options?.includes(InterestInAdvanceImportantOptions.ReasonForTaxPurposes),
			reasonDiscountsOnInterestRate: options?.includes(InterestInAdvanceImportantOptions.ReasonDiscountsOnInterestRate),
			reasonToAssistWithCashFlowAndBudgeting: options?.includes(
				InterestInAdvanceImportantOptions.ReasonToAssistWithCashFlowAndBudgeting
			),
			reasonOther: !!details.interestInAdvanceExtras?.interestInAdvanceOptions?.otherReasonInput
		};
	}
	static fromPayload(data: InterestInAdvanceDTO): InterestInAdvanceModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);
		const details: InterestInAdvanceModel = {
			interestInAdvanceImportanceId: data.importance!,
			interestInAdvanceRisksExplained: data.risksExplained!,
			interestInAdvanceExtras: {
				interestInAdvanceOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				}
			}
		};
		return details;
	}
	static getSelectedIds(data: InterestInAdvanceDTO, selectedIds: number[]) {
		if (data.reasonForTaxPurposes) {
			selectedIds.push(InterestInAdvanceImportantOptions.ReasonForTaxPurposes);
		}
		if (data.reasonDiscountsOnInterestRate) {
			selectedIds.push(InterestInAdvanceImportantOptions.ReasonDiscountsOnInterestRate);
		}
		if (data.reasonToAssistWithCashFlowAndBudgeting) {
			selectedIds.push(InterestInAdvanceImportantOptions.ReasonToAssistWithCashFlowAndBudgeting);
		}
	}
}

export interface InterestInAdvanceModel {
	interestInAdvanceImportanceId: Importance;
	interestInAdvanceRisksExplained: boolean;
	interestInAdvanceExtras: {
		interestInAdvanceOptions: MultiCheckboxModel;
	};
}
export interface InterestInAdvanceStoreModel {
	interestInAdvanceContainer: InterestInAdvanceModel;
}
