import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { ConstructionDetailsService } from '@app/modules/loan-serviceability/construction-details.service';
import { LoanServiceabilityService } from '@app/modules/loan-serviceability/loan-serviceability.service';
import { RepaymentInfo } from '@app/modules/loan-serviceability/model/borrowing-and-repayment.model';
import { CommissionModel } from '@app/modules/loan-serviceability/model/commision.model';
import { ConstructionBuilder } from '@app/modules/loan-serviceability/model/construction-builder.model';
import { FeeDetailModel, FeeListSectionModel } from '@app/modules/loan-serviceability/model/fee-detail.model';
import { LendersMortgageInsurance } from '@app/modules/loan-serviceability/model/lenders-mortgage-insurance-model';
import { LoanInformation, LoanSplit } from '@app/modules/loan-serviceability/model/loan-information.model';
import {
	CreditCardApplication,
	ForeignTaxAssociationDetail,
	LockedInterestRate,
	OffsetAccountDetails,
	OffsetAccounts,
	ProductFeature
} from '@app/modules/loan-serviceability/model/product-features.model';
import {
	ConstructionDetailsModal,
	PropertyAssetModel,
	PropertyAssetModelTransformer
} from '@app/modules/loan-serviceability/model/property-asset.model';
import { PurchaseDetailsModel } from '@app/modules/loan-serviceability/model/purchase-details.model';
import { CustomLoadersModel, RateAdjustment } from '@app/modules/loan-serviceability/model/rate-adjustments.model';
import { LoanDetailsFormDataService } from '@app/modules/loan-serviceability/services/loan-details-form-data.service';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import {
	formlyAddCustomValidator,
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyInsertModalField,
	formlyOnChange,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField,
	getInitializedFormField,
	getParentFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyArrayUpdateEvent } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { CustomNgbModalRef, SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { ContributionDTO, DepositDTO, ProductFeeTypeDTO, RateToBorrowerDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { CalculationHelper, EnumObject } from '@simpology/client-components/utils';
import { cloneDeep, get, merge } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, EMPTY, iif, map, Observable, of, Subscription } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap
} from 'rxjs/operators';

import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';
import { BorrowingInfo, BorrowingLendingPurpose } from '@app/modules/loan-serviceability/model/borrowing.model';
import { Discount } from '@app/modules/loan-serviceability/model/discount.model';
import { GovernmentFeesModel } from '@app/modules/loan-serviceability/model/government-fees-model';
import { Product } from '@app/modules/loan-serviceability/model/product.model';
import { PropertyModalTransformerService } from '../../property/services/property-modal-transformer.service';
import { CONSTANTS } from '../constants/constants';
import {
	APIEnumList,
	BuilderType,
	LoanFeature,
	LoanSubSection,
	RepaymentFrequency,
	TargetType,
	TaxResidencyOptions,
	TermType,
	VisitContactType,
	YesNo
} from '../enums/app.enums';
import { ApplicantEnumObject } from '../enums/enum-helper';
import { isDateFuture, isDatePast } from '../helper/date.helper';
import { deletedSuccessfullyMessage, repaymentToFrequencyFull, savedSuccessfullyMessage } from '../helper/util';
import {
	OtherSecuritiesDetails,
	OtherSecuritiesModel,
	OtherSecuritiesModelTransformer
} from '../model/otherSecurities.model';
import { PercentageOwned } from '../model/percentage-owned.model';
import { SavingModel } from '../model/saving.model';
import { AddressService } from '../service/address.service';
import { FormArrayDeleteService } from '../service/form-array-delete.service';
import { RelatedEntitiesService } from '../service/related-entities.service';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormDataService } from '../store/form-data/form-data.service';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { LendersMortgageInsuranceTransformer } from './../../loan-serviceability/model/lenders-mortgage-insurance-model';
import { RateToBorrower } from './../../loan-serviceability/model/rate-to-borrower.model';
import { formlyOnStatusChangedToValid } from './../../simp-formly/helpers/simp-formly.helper';
import { FeePaymentTiming } from './../enums/app.enums';
import { PercentOwnedService } from './../service/percent-owned.service';
import { TransformerUtilService } from './service/transformer-util.service';

const LOAN_INFO_NON_REPEATABLE_SECTIONS = ['loanInformation'];
const LOAN_INFO_REPEATABLE_SECTIONS = [
	LoanSubSection.BORROWING,
	LoanSubSection.PRODUCT,
	LoanSubSection.DISCOUNT,
	LoanSubSection.RATE_ADJUSTMENTS,
	LoanSubSection.COMMISSION,
	LoanSubSection.RATE_TO_BORROWER,
	LoanSubSection.LOAN_FEATURES
];

@Injectable({ providedIn: 'root' })
export class LoanServiceabilityTransformerService {
	private avoidSavingProduct = false;
	private isConstructionDetailsModalOpen = false;
	private repeatableSubsections: FormlyFieldConfig[] = [];

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsService: FormEnumsService,
		private sharedFlagsService: SharedFlagsService,
		private loanServiceabilityService: LoanServiceabilityService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private addressService: AddressService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private simpFormlyModalService: SimpFormlyModalService,
		private percentOwnedService: PercentOwnedService,
		private toastr: ToastrService,
		private formArrayDeleteService: FormArrayDeleteService,
		private transformerUtilService: TransformerUtilService,
		private propertyModalTransformationService: PropertyModalTransformerService,
		private formEnumsQuery: FormEnumsQuery,
		private relatedEntitiesService: RelatedEntitiesService,
		private appService: AppService,
		private constructionDetailsService: ConstructionDetailsService,
		private loanDetailsFromDataService: LoanDetailsFormDataService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository,
		private formDataService: FormDataService
	) {}

	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] | undefined {
		const hasServiceabilitySection = !!getFormField(formFields, 'serviceability');
		if (hasServiceabilitySection) {
			return formFields;
		}
		return this.transformLoan(formFields);
	}

	transformLoan(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] | undefined {
		const defaultAllPercentageOwned = this.percentOwnedService.getAllDefaultSharePercents();
		const loanRequirementsSection = (formFields[0].fieldArray as FormlyFieldConfig)?.fieldGroup?.find(
			(field) => field.key === 'loanRequirements'
		);
		if (loanRequirementsSection) {
			const nonRepeatableSubsections =
				loanRequirementsSection.fieldGroup?.filter(
					(field) => field.type !== 'sub-section' || LOAN_INFO_NON_REPEATABLE_SECTIONS.find((key) => key === field.key)
				) ?? [];
			this.repeatableSubsections =
				loanRequirementsSection.fieldGroup?.filter(
					(field) => field.type === 'sub-section' && LOAN_INFO_REPEATABLE_SECTIONS.find((key) => key === field.key)
				) ?? [];
			loanRequirementsSection.fieldGroup = nonRepeatableSubsections;
		}
		this.configureLoanRequirements(formFields);

		this.injectFeePopupModel(formFields);
		this.loanServiceabilityService.fetchFeePaymentTiming().subscribe();
		this.appendPropertyIndexToFieldTitle(formFields, 'purchaseDetails.property.details');

		this.configureValuer(formFields);
		this.configureVisitContactName(formFields);
		this.configureConstructionModal(formFields);

		formlyExtendExpressionProperties(formFields, 'loanRequirements.loanInformation.percentsOwned.percent', {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});

		this.propertyModalTransformationService.configurePropertyModalForLoanServiceability(formFields);

		this.relatedEntitiesService.configureABNLookupForRelatedCompany(formFields, 'purchaseDetails');

		formlyOnClick(formFields, 'purchaseDetails.property.delete', this.deletePropertyAsset.bind(this));
		this.configureLoanInformation(formFields);

		formlyOnStatusChangedToValid(formFields, 'purchaseDetails.property', this.savePropertyAsset.bind(this));

		formlyOnStatusChangedToValid(
			formFields,
			'depositsAndContributions.depositsPaid',
			(field, event: FormlyArrayUpdateEvent<DepositDTO>) => {
				const model = get(field, `model[${event.index}]`) as DepositDTO;
				model.applicationId = this.applicationDataQuery.applicationId();
				this.loanServiceabilityService.saveDeposit(model).subscribe((id: number) => {
					model.id = id;
				});
			}
		);

		formlyOnClick(formFields, 'depositsAndContributions.depositsPaid.delete', (field: FormlyFieldConfig) => {
			const id = get(field, 'parent.model.id') as number;
			if (id) {
				this.loanServiceabilityService.deleteDeposit(this.applicationDataQuery.applicationId(), id).subscribe();
			}
			formlyDeleteFromArray(field);
		});

		formlyOnStatusChangedToValid(
			formFields,
			'depositsAndContributions.otherContributions',
			(field, event: FormlyArrayUpdateEvent<ContributionDTO>) => {
				const model = get(field, `model[${event.index}]`) as ContributionDTO;
				model.applicationId = this.applicationDataQuery.applicationId();
				this.loanServiceabilityService.saveContributions(model).subscribe((id: number) => {
					model.id = id;
				});
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			'fees.lendersMortgageInsurance',
			(field, event: FormlyArrayUpdateEvent<LendersMortgageInsurance>) => {
				const lmiModel = get(field, `model[${event.index}]`) as LendersMortgageInsurance;
				this.handleSaveLendersMortgageInsurance(lmiModel);
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			'fees.governmentFees',
			(field, event: FormlyArrayUpdateEvent<LendersMortgageInsurance>) => {
				const govFeesModel = get(field, `model[${event.index}]`) as GovernmentFeesModel;
				this.handleSaveGovernmentFees(govFeesModel);
			}
		);

		formlyOnClick(formFields, 'fees.governmentFees.delete', (field: FormlyFieldConfig) => {
			const id = get(field, 'parent.model.id') as number;
			if (id) {
				this.loanServiceabilityService
					.deleteGovernmentFees(this.applicationDataQuery.applicationId(), id)
					.pipe(switchMap(() => this.loanServiceabilityService.fetchGovernmentFees()))
					.subscribe(() => {
						this.loanServiceabilityService.calculateBorrowingAmountsWithCapitalizedFeesLMI();
					});
			}
			formlyDeleteFromArray(field);
		});

		formlyOnClick(formFields, 'depositsAndContributions.otherContributions.delete', (field: FormlyFieldConfig) => {
			const id = get(field, 'parent.model.id') as number;
			if (id) {
				this.loanServiceabilityService.deleteContributions(this.applicationDataQuery.applicationId(), id).subscribe();
			}
			formlyDeleteFromArray(field);
		});

		formlyOnClick(formFields, 'fees.paidOnOrBefore.deleteFee', (field: FormlyFieldConfig) => {
			const feeId = get(field, 'model.id') as number;
			const loanDetailId = (field.parent?.model as FeeListSectionModel)?.details?.feePopup?.linkProduct;
			const rowIndex = Number(field.parent?.key);
			if (feeId) {
				this.loanServiceabilityService.deleteFees(feeId).subscribe(() => {
					this.simpFormlyHandlerService.deleteFromState(`paidOnOrBefore`, rowIndex);
					this.loanServiceabilityService.fetchFeeTypes();
					this.toastr.success(deletedSuccessfullyMessage('Fee'));
					if (loanDetailId) {
						this.loanServiceabilityService.calculateRepaymentsWithCapitalizedFeesLMI(loanDetailId);
					}
				});
			}
			formlyDeleteFromArray(field);
		});

		const feesSection = getFormField(formFields, 'fees');
		const headerField = feesSection?.props?.header as FormlyFieldConfig;
		if (headerField?.fieldGroup) {
			const feesCustomLabels = getFormField(feesSection?.fieldGroup, 'customLabels');
			formlyOnClick(headerField.fieldGroup, 'resetFees', (field: FormlyFieldConfig) => {
				this.handleFeesResetToDefault(feesCustomLabels);
			});
		}

		formlyOnClick(formFields, 'fees.paidThroughoutLoan.deleteFee', (field: FormlyFieldConfig) => {
			const feeId = get(field, 'model.id') as number;
			const rowIndex = Number(field.parent?.key);
			if (feeId) {
				this.loanServiceabilityService.deleteFees(feeId).subscribe(() => {
					this.simpFormlyHandlerService.deleteFromState(`paidThroughoutLoan`, rowIndex);
					this.loanServiceabilityService.fetchFeeTypes();
					this.toastr.success(deletedSuccessfullyMessage('Fee'));
				});
			}
			formlyDeleteFromArray(field);
		});

		formlyOnClick(formFields, 'fees.paidOnOrBefore.details', (field: FormlyFieldConfig) => {
			this.openFeeDetailModalBySectionType(
				getFormField(field.fieldGroup, 'feePopup') as FormlyFieldConfig,
				'paidOnOrBefore',
				false
			);
		});

		formlyOnClick(formFields, 'fees.paidThroughoutLoan.details', (field: FormlyFieldConfig) => {
			this.openFeeDetailModalBySectionType(
				getFormField(field.fieldGroup, 'feePopup') as FormlyFieldConfig,
				'paidThroughoutLoan',
				false
			);
		});

		formlyOnClick(formFields, 'fees.paidOnOrBefore', (field: FormlyFieldConfig) => {
			this.proceedAddNewFeeRecord(field, 'paidOnOrBefore');
		});
		formlyOnClick(formFields, 'fees.paidThroughoutLoan', (field: FormlyFieldConfig) => {
			this.proceedAddNewFeeRecord(field, 'paidThroughoutLoan');
		});

		formlyOnChange(formFields, `fees.paidOnOrBefore.details.feePopup.linkProduct`, (field: FormlyFieldConfig) => {
			this.handleProductVariationChanged(field, 'paidOnOrBefore');
		});

		formlyOnChange(formFields, `fees.paidThroughoutLoan.details.feePopup.linkProduct`, (field: FormlyFieldConfig) => {
			this.handleProductVariationChanged(field, 'paidThroughoutLoan');
		});

		formlyOnClick(formFields, 'fees.lendersMortgageInsurance.isLmiRequirement', (field: FormlyFieldConfig) => {
			if (field?.formControl?.value === YesNo.Yes) {
				const lendersMortgageSubSection = getFormField(field?.parent?.parent?.fieldGroup, '0') as FormlyFieldConfig;
				const lmiModel = lendersMortgageSubSection.model as LendersMortgageInsurance;
				this.handleSaveLendersMortgageInsurance(lmiModel);
			}
		});

		this.validateContractDate(formFields);
		this.validateFinanceApprovalDate(formFields);

		formlyOnClick(formFields, 'purchaseDetails.otherSecurities.details', (field: FormlyFieldConfig) => {
			this.openOtherSecuritiesModal(field, true);
		});

		formlyRegisterHooks(formFields, 'purchaseDetails.otherSecurities.details', {
			afterViewInit: (field) => {
				if (field) {
					const modalField = getFormField(field.fieldGroup, 'typeOfSecurity') as FormlyFieldConfig;
					if (!(field.model as OtherSecuritiesDetails)?.extract) {
						this.openTypeOfSecurityModal(modalField, field);
					}
				}
				return of();
			}
		});

		formlyOnClick(formFields, `purchaseDetails.otherSecurities.delete`, (field: FormlyFieldConfig) => {
			this.formArrayDeleteService
				.deleteItem(field, 'Savings', 'savings')
				.pipe(switchMap(() => this.loanServiceabilityService.fetchSavings()))
				.subscribe();
		});

		formlyOnClick(
			formFields,
			'purchaseDetails.otherSecurities.details.otherSecuritiesDetails.assetDetails.isEqualShare',
			(field: FormlyFieldConfig) => {
				this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));
			}
		);

		formlyExtendExpressionProperties(formFields, `purchaseDetails.otherSecurities.details.typeOfSecurity.type`, {
			'templateOptions.options': (model, formState, field) => {
				if (field.templateOptions && field.templateOptions.handler && field.templateOptions.options) {
					if (typeof field.templateOptions.options === 'string') {
						const options = this.formEnumsQuery.getOptions(field.templateOptions.options);
						const filterCriteria = (field.templateOptions.handler as string).split(',').map((x) => x.toString().trim());
						return options.filter((x) => filterCriteria.includes(x.info as string));
					}
				}
				return field.templateOptions?.options;
			}
		});

		this.setDefaultPercentageOwned(
			formFields,
			defaultAllPercentageOwned,
			'purchaseDetails.otherSecurities.details.otherSecuritiesDetails.assetDetails.percentsOwned'
		);

		this.setSharePercentageTitles(
			formFields,
			`purchaseDetails.otherSecurities.details.otherSecuritiesDetails.assetDetails.percentsOwned.percent`
		);

		return formFields;
	}

	transformSplitLoan(loanRequirementsField: FormlyFieldConfig, loanId: number, initState = false) {
		const clonedSubsections = cloneDeep(this.repeatableSubsections);
		clonedSubsections.forEach((subsection) => {
			const subsectionTemplateKey = subsection.key;

			subsection.key = this.loanServiceabilityService.getSplitSubsectionKey(subsection.key as string, loanId);

			if (loanRequirementsField.fieldGroup) {
				loanRequirementsField.fieldGroup.push(subsection);
			}

			if (initState) {
				if (subsectionTemplateKey === LoanSubSection.BORROWING) {
					this.simpFormlyHandlerService.upsertToStateWithData(subsection.key, [
						{ loanId: loanId, lendingPurpose: [{}] }
					]);
				} else {
					this.simpFormlyHandlerService.upsertToStateWithData(subsection.key, [{ loanId: loanId }]);
				}
			}

			switch (subsectionTemplateKey) {
				case LoanSubSection.BORROWING:
					this.configureBorrowingSection([subsection], loanId);
					break;
				case LoanSubSection.PRODUCT:
					this.configureProductSection([subsection], loanId);
					break;
				case LoanSubSection.DISCOUNT:
					this.configureDiscountSection([subsection], loanId);
					break;
				case LoanSubSection.RATE_ADJUSTMENTS:
					this.configureRateAdjustmentsSection([subsection], loanId);
					break;
				case LoanSubSection.COMMISSION:
					this.configureCommissionSection([subsection], loanId);
					break;
				case LoanSubSection.RATE_TO_BORROWER:
					this.configureRateToBorrowerSection([subsection], loanId);
					break;
				case LoanSubSection.LOAN_FEATURES:
					this.configureLoanFeaturesSection([subsection], loanId);
					break;
			}
		});
	}

	private configureLoanRequirements(formFields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(formFields, 'loanRequirements', {
			onInit: (loanRequirementsField: FormlyFieldConfig | undefined) => {
				if (loanRequirementsField) {
					this.loanServiceabilityService.splitLoanIdsObservable$
						.pipe(
							takeUntil(this.loanServiceabilityService.areaChanged$),
							filter((loanIds) => loanIds?.length > 0)
						)
						.subscribe((loanIds: number[]) => {
							loanIds.forEach((loanId) => this.transformSplitLoan(loanRequirementsField, loanId));

							// Rebuild the form to initiate newly added formly fields
							if (loanRequirementsField.options && loanRequirementsField.options.build) {
								loanRequirementsField.options.build();
							}
							this.loanServiceabilityService.setSplitLoanIds([]);
						});
				}
			}
		});
	}

	private handleSaveLendersMortgageInsurance(lmiModel: LendersMortgageInsurance): void {
		if (lmiModel) {
			this.loanServiceabilityService.saveLendersMortgageInsurance(lmiModel).subscribe((lmiDto) => {
				const lmi = LendersMortgageInsuranceTransformer.fromPayload(lmiDto);
				this.simpFormlyHandlerService.upsertToState('lendersMortgageInsurance', of([lmi]));
				this.loanServiceabilityService.calculateBorrowingAmountsWithCapitalizedFeesLMI(lmi);
				const loanDetailId =
					this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0]?.loanDetailId;
				this.loanServiceabilityService.calculateRepaymentsWithCapitalizedFeesLMI(loanDetailId);
			});
		}
	}

	private handleSaveGovernmentFees(govFeesModel: GovernmentFeesModel): void {
		if (govFeesModel) {
			this.loanServiceabilityService
				.saveGovernmentFees(govFeesModel)
				.pipe(debounceTime(1000))
				.subscribe((id) => {
					govFeesModel.id = id;
					this.simpFormlyHandlerService.upsertToState('governmentFees', of([govFeesModel]));
					this.loanServiceabilityService.calculateBorrowingAmountsWithCapitalizedFeesLMI();
				});
		}
	}

	private configureVisitContactName(formFields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(formFields, 'purchaseDetails.property.visitContactName', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const visitContactTypeField = getFormField(field.parent?.fieldGroup, 'visitContactType');
					let options$: Observable<EnumObject[]> = of([]);
					return visitContactTypeField?.formControl?.valueChanges.pipe(
						startWith(visitContactTypeField?.formControl.value),
						switchMap((visitContactType: VisitContactType) => {
							switch (visitContactType) {
								case VisitContactType.Applicant:
									options$ = combineLatest([
										this.formEnumsQuery.selectEnumOptions('PersonApplicants'),
										this.formEnumsQuery.selectEnumOptions('CompanyApplicants')
									]).pipe(map(([persons, companies]) => persons.concat(companies)));
									break;
								case VisitContactType.Builder:
									options$ = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
										map((companies) =>
											this.formEnumsQuery
												.getAddOptions('AddNewCompany')
												.map((existing) => (existing.id === -1 ? { ...existing, id: -2 } : existing))
												.concat(companies)
										)
									);
									break;
								default:
									options$ = combineLatest([
										this.formEnumsQuery.selectEnumOptions('RelatedPersons'),
										this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies')
									]).pipe(
										map(([persons, companies]) =>
											this.formEnumsQuery.getAddOptions('AddNewPersonCompany')?.concat(persons, companies)
										)
									);
							}

							return options$;
						}),
						switchMap((options: EnumObject[]) => {
							const visitContactNameField = getFormField(field.parent?.fieldGroup, 'visitContactName');
							const purchaseDetails = field.model as PurchaseDetailsModel;
							const visitContactId = purchaseDetails.visitContactName?.id;
							if (!options?.find((applicant) => applicant.id === visitContactId)) {
								visitContactNameField?.formControl?.reset();
							}
							if (field.templateOptions) {
								field.templateOptions.options = options?.map((entity) => ({
									...entity,
									click: [TargetType.RelatedCompany, TargetType.RelatedPerson].includes(
										entity.type as unknown as TargetType
									)
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													field,
													0,
													this.saveValuer,
													'visitContactName'
												)
										: null
								}));
							}
							return this.relatedEntitiesService.configureRelatedEntityModal(
								field,
								0,
								this.saveValuer,
								'visitContactName'
							);
						})
					);
				}

				return of();
			}
		});
	}

	private configureConstructionModal(formFields: FormlyFieldConfig[] | undefined) {
		formlyOnClick(formFields, 'purchaseDetails.property.constructionDetails', (field: FormlyFieldConfig) => {
			const constructionModel = field.model as ConstructionDetailsModal;

			if (
				!constructionModel?.constructionModal?.builder ||
				constructionModel?.constructionModal?.builder.length === 0
			) {
				setTimeout(() => {
					const builderField = getFormField(field.fieldGroup, 'constructionModal.builder');
					if (builderField?.templateOptions?.add && typeof builderField?.templateOptions?.add === 'function') {
						(builderField.templateOptions.add as Function)();
					}
				});
			}

			this.isConstructionDetailsModalOpen = true;
			const modalRef = this.simpFormlyModalService.openModal(field, 'constructionModal', {
				backdrop: 'static'
			});

			const subscription = modalRef.action.pipe(take(1)).subscribe((action: string) => {
				this.isConstructionDetailsModalOpen = false;
				if (action !== 'submit') {
					modalRef.close();
					subscription.unsubscribe();
					return;
				}
				const model = Object.assign({}, field.parent?.model, field.form?.value) as PropertyAssetModel;

				const payload = PropertyAssetModelTransformer.toPayloadConstructionDetails(
					constructionModel,
					model.id,
					this.applicationDataQuery.applicationId()
				);

				this.constructionDetailsService.saveConstructionDetails(payload).subscribe(() => {
					const constructionExtract = this.formEnumsQuery.getOptionLabel(
						'ConstructionType',
						constructionModel?.constructionModal?.constructionType as BuilderType
					);

					const totalAmount = constructionModel?.constructionModal?.totalAmount
						? constructionModel?.constructionModal?.totalAmount
						: '';
					const constractionDetailsValue = field?.formControl?.value as ConstructionDetailsModal;

					constractionDetailsValue.extract = constructionExtract
						? `${constructionExtract} - $${totalAmount.toLocaleString()}`
						: `$${totalAmount.toLocaleString()}`;

					this.updateConstructionDetails(field);

					field.formControl?.patchValue(constractionDetailsValue);

					modalRef.close();
					subscription.unsubscribe();
				});
			});
		});

		formlyRegisterHooks(formFields, 'purchaseDetails.property.constructionDetails.constructionModal.builder.builder', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const builderTypeField = getFormField(field.parent?.fieldGroup, 'builderType');
					const builderField = getFormField(field.parent?.fieldGroup, 'builder');

					let options$: Observable<EnumObject[]> = of([]);
					return builderTypeField?.formControl?.valueChanges.pipe(
						startWith(builderTypeField?.formControl?.value),
						switchMap((builderType: BuilderType) => {
							options$ = combineLatest([
								this.formEnumsQuery.selectEnumOptions('RelatedPersons'),
								this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies')
							]).pipe(
								map(([relatedPersons, relatedCompanies]) => {
									if (builderType === BuilderType.LicencedBuilder) {
										return this.formEnumsQuery
											.getAddOptions('AddNewPersonCompany')
											.filter((existing) => existing.id !== -1)
											.concat(relatedCompanies);
									} else if (builderType === BuilderType.UnlicencedBuilder) {
										return this.formEnumsQuery
											.getAddOptions('AddNewPersonCompany')
											.concat(relatedPersons, relatedCompanies);
									}
									return [];
								})
							);

							return options$;
						}),
						switchMap((options: EnumObject[]) => {
							if (
								!options.find(
									(applicant) => applicant.id === (builderField?.formControl?.value as ApplicantEnumObject)?.id
								)
							) {
								builderField?.formControl?.reset();
							}
							if (field.templateOptions) {
								field.templateOptions.options = options?.map((entity) => ({
									...entity,
									click: [TargetType.RelatedCompany, TargetType.RelatedPerson].includes(
										entity.type as unknown as TargetType
									)
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(
													field,
													0,
													this.saveBuilder,
													'builder'
												)
										: null
								}));
							}
							return this.relatedEntitiesService.configureRelatedEntityModal(field, 0, this.saveBuilder, 'builder');
						})
					);
				}
				return of();
			}
		});

		formlyRegisterHooks(
			formFields,
			'purchaseDetails.property.constructionDetails.constructionModal.builder.developer',
			{
				onInit: (field) => {
					if (field && field.templateOptions) {
						return combineLatest([
							this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies'),
							this.formEnumsQuery.selectEnumOptions('CompanyApplicants'),
							this.formEnumsQuery.selectEnumOptions('Trusts'),
							this.formEnumsQuery.selectEnumOptions('RelatedPersons'),
							this.formEnumsQuery.selectEnumOptions('PersonApplicants')
						]).pipe(
							map(([relatedCompanies, companyApplicants, trusts, relatedPersons, personApplicants]) => {
								return this.formEnumsQuery
									.getAddOptions('AddNewPersonCompany')
									.concat(relatedCompanies, companyApplicants, trusts, relatedPersons, personApplicants);
							}),
							switchMap((options: EnumObject[]) => {
								const option = options.find(
									(applicant) => applicant.id === (field?.formControl?.value as ApplicantEnumObject)?.id
								);
								if (!option) {
									field?.formControl?.reset();
								}

								if (field.templateOptions) {
									field.templateOptions.options = options?.map((entity) => ({
										...entity,
										click: [TargetType.RelatedCompany, TargetType.RelatedPerson].includes(
											entity.type as unknown as TargetType
										)
											? () =>
													this.relatedEntitiesService.openPrefilledRelatedEntityModal(
														field,
														0,
														(developerModel: ConstructionBuilder, index: number, applicantId: number) => {
															if (developerModel.developer) {
																field?.formControl?.setValue(
																	this.formEnumsQuery.getExistingPartiesById({
																		id: developerModel.developer?.id,
																		type: developerModel.developer?.type
																	})
																);
															}
															return new Subscription();
														},
														'developer'
													)
											: null
									}));
								}

								return this.relatedEntitiesService.configureRelatedEntityModal(
									field,
									0,
									(developerModel: ConstructionBuilder, index: number, applicantId: number) => {
										if (developerModel.developer) {
											field?.formControl?.setValue(
												this.formEnumsQuery.getExistingPartiesById({
													id: developerModel.developer?.id,
													type: developerModel.developer?.type
												})
											);
										}
										return new Subscription();
									},
									'developer'
								);
							})
						);
					}

					return of(EMPTY);
				}
			}
		);

		formlyOnClick(
			formFields,
			'purchaseDetails.property.constructionDetails.constructionModal.stages.delete',
			(deleteField: FormlyFieldConfig) => {
				formlyDeleteFromArray(deleteField);
			}
		);
	}

	private configureValuer(formFields: FormlyFieldConfig[] | undefined) {
		formlyRegisterHooks(formFields, 'purchaseDetails.property.valuer', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					field.templateOptions.options = this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
						map((companies) => [
							// -1 ID is for new related persons, -2 for new related companies
							...fieldOptions.map((existing) => (existing.id === -1 ? { ...existing, id: -2 } : existing)),
							...companies.map((company) => ({
								...company,
								click:
									(company.type as unknown as TargetType) === TargetType.RelatedCompany
										? () =>
												this.relatedEntitiesService.openPrefilledRelatedEntityModal(field, 0, this.saveValuer, 'valuer')
										: null
							}))
						])
					);

					return this.relatedEntitiesService.configureRelatedEntityModal(field, 0, this.saveValuer, 'valuer');
				}

				return of();
			}
		});
	}

	private saveBuilder = (builderModel: any, index: number, applicantId: number) => {
		this.simpFormlyHandlerService.updateToState(`builder`, builderModel, 0);
		return new Subscription();
	};

	private saveValuer = (propertyModel: any, index: number, applicantId: number) => {
		return this.saveProperty(propertyModel, index, 0);
	};

	private injectFeePopupModel(formFields: FormlyFieldConfig[] | undefined) {
		const feePopupModalFields = getFormField(formFields, 'feePopup') as FormlyFieldConfig;
		const paidOnOrBeforeDetailsConfig = getFormField(formFields, 'fees.paidOnOrBefore.details') as FormlyFieldConfig;
		const paidThroughoutLoanDetailsConfig = getFormField(
			formFields,
			'fees.paidThroughoutLoan.details'
		) as FormlyFieldConfig;
		const paidOnOrBeforePopupDetails = cloneDeep(feePopupModalFields);
		const paidThroughoutLoanPopupDetails = cloneDeep(feePopupModalFields);
		paidOnOrBeforeDetailsConfig?.fieldGroup?.push(paidOnOrBeforePopupDetails);
		paidThroughoutLoanDetailsConfig?.fieldGroup?.push(paidThroughoutLoanPopupDetails);
	}

	private openFeeDetailModalBySectionType(
		field: FormlyFieldConfig,
		sectionType: 'paidOnOrBefore' | 'paidThroughoutLoan',
		isAdd: boolean
	): void {
		// race condition in firefox, modalRef.action is not defined error
		setTimeout(() => {
			const modalRef = this.simpFormlyModalService.openModal(field, undefined, {
				backdrop: 'static',
				windowClass: 'simp-padding-left-small'
			});

			const loanInfo = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
			const linkProductField = getFormField(field.fieldGroup, 'linkProduct') as FormlyFieldConfig;
			if (!loanInfo.isSplitLoan) {
				linkProductField.formControl?.setValue(loanInfo.loanDetailId);
			}

			modalRef.shown.subscribe(() => {
				const paymentTimingField = getFormField(field.fieldGroup, 'paymentTiming') as FormlyFieldConfig;
				if (paymentTimingField.templateOptions?.options) {
					paymentTimingField.templateOptions.options = this.formEnumsQuery.selectEnumOptions('FeePaymentTiming').pipe(
						map((options: EnumObject[]) => {
							return options.filter(
								(option) =>
									(sectionType === 'paidOnOrBefore' &&
										(option.id === FeePaymentTiming.BeforeCreditDrawdown ||
											option.id === FeePaymentTiming.OnCreditDrawdown)) ||
									(sectionType === 'paidThroughoutLoan' &&
										(option.id === FeePaymentTiming.OnEventOccurrence ||
											option.id === FeePaymentTiming.RegularRecurring))
							);
						})
					);
				}
			});

			const key = Number(field.parent?.parent?.key);
			const model = field?.parent?.parent?.model as FeeListSectionModel;

			if (isAdd) {
				setTimeout(() => {
					// need to wait till linkProductVariationId is set dynamically once linked product changed
					this.updateProductEligibleFeesEnum(linkProductField?.model as FeeDetailModel, sectionType);
				});
			} else {
				const currentOption = {
					id: model.details.feePopup.productFeeTypeId as number,
					label: `${this.formEnumsQuery.getOptionLabel('FeeType', model.details.feePopup.feeType as number)}`
				} as unknown as EnumObject;

				this.updateProductEligibleFeesEnum(model.details.feePopup, sectionType, currentOption);
			}

			const subscription = modalRef.action.subscribe((action) => {
				if (action === 'submit') {
					const productFeeTypes = this.simpFormlyHandlerService.getStateData<ProductFeeTypeDTO>('productFeeTypes');
					const selectedProductFeeType = productFeeTypes.find(
						(productFeeType) => productFeeType.productFeeTypeId === model.details.feePopup.productFeeTypeId
					);
					if (selectedProductFeeType) {
						model.details.feePopup.productFeeCodeId = selectedProductFeeType.productFeeCodeId;
						model.details.feePopup.feeCode = selectedProductFeeType.productFeeCode;
						model.details.feePopup.feeType = selectedProductFeeType.type;
					}

					this.loanServiceabilityService.saveFees(model).subscribe((feeDetailsModel: FeeListSectionModel) => {
						modalRef.close();
						subscription.unsubscribe();
						this.simpFormlyHandlerService.updateToState(sectionType, feeDetailsModel, key);
						this.loanServiceabilityService.fetchFeeTypes();
						if (sectionType === 'paidOnOrBefore') {
							const loanDetailId = model?.details?.feePopup?.linkProduct;
							this.loanServiceabilityService.calculateRepaymentsWithCapitalizedFeesLMI(loanDetailId);
						}
					});
				} else {
					modalRef.close();
					if (!(field.parent?.parent?.model as FeeListSectionModel).id) {
						formlyDeleteFromArray(
							field.parent?.parent?.fieldGroup?.find((x) => x.key === 'deleteFee') as FormlyFieldConfig
						);
					}
					subscription.unsubscribe();
				}
			});
		});
	}

	private proceedAddNewFeeRecord(field: FormlyFieldConfig, subSection: 'paidOnOrBefore' | 'paidThroughoutLoan') {
		formlyInsertModalField(field, 'details.feePopup', (modalField) => {
			this.openFeeDetailModalBySectionType(modalField, subSection, true);
		});
	}

	private configureLoanInformation(formFields: FormlyFieldConfig[]): void {
		this.loanServiceabilityService.configureProductsFilterByTotalBorrowingAmount();

		// When checkbox is ticked, update all percentages so they are equal
		formlyOnClick(formFields, 'loanRequirements.loanInformation.isEqualShare', (field: FormlyFieldConfig) => {
			this.percentOwnedService.addEqualSharePercent(getFormField(field?.parent?.fieldGroup, 'percentsOwned'));

			const model = cloneDeep(field.model) as LoanInformation;
			if (model.isEqualShare && field.parent?.formControl?.invalid) {
				this.saveLoanInformation(model);
			}
		});

		formlyOnStatusChangedToValid(formFields, 'loanRequirements.loanInformation', (field) => {
			const loan = (field.model as LoanInformation[])?.[0];
			const loanControlValue = (field.formControl!.value as LoanInformation[])?.[0];

			// Avoid saving loan information once unchecked split loan checkbox, it's handled with split loan check box event
			if (!loan?.isSplitLoan && loan?.loanSplits && loan.loanSplits.length > 1) {
				return;
			}

			if (loan?.isSplitLoan && !loan?.loanSplits?.length) {
				return;
			}

			const loanInfo = merge(loan, loanControlValue);
			this.saveLoanInformation(loanInfo);
		});

		formlyRegisterHooks(formFields, 'loanRequirements.loanInformation.loanSplits.loanSplit.splitAmount', {
			afterViewInit: (splitAmountField) => {
				return splitAmountField.formControl?.valueChanges.pipe(
					tap(() => {
						const loanInformationField = getParentFormField(splitAmountField, 'loanInformation');
						const loanInformation = (loanInformationField?.model as LoanInformation[])?.[0];
						if (loanInformation?.isSplitLoan) {
							const totalAmountRequested = this.getTotalLoanAmount(loanInformation.loanSplits);
							const amountRequestedField = getFormField(
								loanInformationField?.fieldGroup?.[0].fieldGroup,
								'amountRequested'
							);
							// patch total amount field and mark touched in split loan scenarios to trigger total amount validations (TAMA5-18451)
							amountRequestedField?.formControl?.patchValue(totalAmountRequested);
							amountRequestedField?.formControl?.markAsTouched();
						}
					})
				);
			}
		});

		this.updateLoanSplitAmountTitle(formFields, 'loanRequirements.loanInformation.loanSplits.loanSplit.splitAmount');
		this.updateLoanSplitAmountTitle(
			formFields,
			'loanRequirements.loanInformation.loanSplits.loanSplit.splitAmountWithCapitalisedFees'
		);

		formlyOnClick(
			formFields,
			'loanRequirements.loanInformation.loanSplits.loanSplit.delete',
			(field: FormlyFieldConfig) => {
				const loanDetailId = (field.parent?.parent?.model as LoanSplit)?.loanDetailId;
				if (loanDetailId) {
					const splitLoansField = getParentFormField(field, 'loanSplits');
					this.loanServiceabilityService.deleteSplitLoan(loanDetailId).subscribe(() => {
						this.removeSplitLoanFromUI(loanDetailId, splitLoansField);
						this.toastr.success(deletedSuccessfullyMessage('Split loan'));
						if (splitLoansField?.parent?.formControl?.invalid) {
							this.saveLoanInformation(splitLoansField?.parent?.model as LoanInformation);
						}
					});
				}
			}
		);

		formlyOnClick(
			formFields,
			'loanRequirements.loanInformation.loanSplits.loanSplit.addSplit',
			(field: FormlyFieldConfig) => {
				if (field.parent?.parent?.parent) {
					this.addSplit(field.parent.parent.parent);
				}
			}
		);

		formlyOnClick(
			formFields,
			'loanRequirements.loanInformation.isSplitLoan',
			this.handleSplitLoansCheckboxEvent.bind(this)
		);

		this.syncLoanAmountFieldsFromLoanInformation(formFields);
		this.configureConveyancer(formFields);

		formlyRegisterHooks(formFields, 'loanRequirements.loanInformation.borrowings.repayment.initialTermPeriod', {
			onInit: (initialTermPeriodField: FormlyFieldConfig | undefined) => {
				if (initialTermPeriodField) {
					return initialTermPeriodField.formControl?.valueChanges.pipe(
						startWith(initialTermPeriodField.formControl.value),
						distinctUntilChanged(),
						tap(() => {
							const repaymentModel = initialTermPeriodField.model as RepaymentInfo;
							this.setNoOfRepayments(
								initialTermPeriodField,
								'initialNoOfRepayments',
								repaymentModel.initialTermPeriod,
								repaymentModel.repaymentFrequency
							);
						})
					);
				} else {
					return of(null);
				}
			}
		});

		formlyOnChange(
			formFields,
			'loanRequirements.loanInformation.borrowings.repayment.repaymentFrequency',
			(repaymentFrequencyField: FormlyFieldConfig) => {
				const repaymentModel = repaymentFrequencyField.model as RepaymentInfo;
				this.setNoOfRepayments(
					repaymentFrequencyField,
					'initialNoOfRepayments',
					repaymentModel.initialTermPeriod,
					repaymentModel.repaymentFrequency
				);
				this.setNoOfRepayments(
					repaymentFrequencyField,
					'subSeqNoOfRepayments',
					repaymentModel.subSeqTermPeriod,
					repaymentModel.repaymentFrequency
				);
			}
		);

		formlyRegisterHooks(formFields, 'loanRequirements.loanInformation.borrowings.repayment.subSeqTermPeriod', {
			onInit: (subSeqTermPeriodField: FormlyFieldConfig | undefined) => {
				if (subSeqTermPeriodField) {
					return subSeqTermPeriodField.formControl?.valueChanges.pipe(
						startWith(subSeqTermPeriodField.formControl.value),
						distinctUntilChanged(),
						tap(() => {
							const repaymentModel = subSeqTermPeriodField.model as RepaymentInfo;
							this.setNoOfRepayments(
								subSeqTermPeriodField,
								'subSeqNoOfRepayments',
								repaymentModel.subSeqTermPeriod,
								repaymentModel.repaymentFrequency
							);
						})
					);
				} else {
					return of(null);
				}
			}
		});

		this.loanDetailsFromDataService
			.selectTotalAndSplitAmounts$()
			.pipe(takeUntil(this.loanServiceabilityService.areaChanged$))
			.subscribe(() => {
				setTimeout(() => {
					this.loanServiceabilityService.calculateBorrowingAmountsWithCapitalizedFeesLMI();
				});
			});
	}

	private configureBorrowingSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.BORROWING, loanId);

		formlyRegisterHooks(formFields, `${subSectionKey}`, {
			onInit: (borrowingField) => {
				this.loanDetailsFromDataService
					.selectSplitLoans$()
					.pipe(takeUntil(this.loanServiceabilityService.areaChanged$))
					.subscribe((splitLoans: number[]) => {
						this.updateBorrowingSectionTitle(borrowingField, loanId, splitLoans);
					});
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.primaryPurpose`, {
			onInit: (primaryPurposeField) => {
				if (primaryPurposeField?.parent) {
					this.updateBorrowingAmountTitle(primaryPurposeField?.parent, loanId);
				}
			}
		});

		formlyOnStatusChangedToValid(formFields, subSectionKey, (field) => this.saveBorrowing(field));

		formlyRegisterHooks(formFields, `${subSectionKey}.lendingPurpose.primaryLendingPurpose`, {
			onInit: (field) => {
				return this.setPrimaryLendingPurpose(field);
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.amountRequested`, {
			onInit: (field) => {
				return this.syncLoanAmountsFromBorrowing(field, loanId);
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.lendingPurpose.purposeAmount`, {
			onInit: (field) => {
				this.setPurposeAmount(field);
			}
		});

		formlyOnClick(formFields, `${subSectionKey}.addLendingPurpose`, this.addLendingPurpose.bind(this));

		formlyOnChange(formFields, `${subSectionKey}.primaryPurpose`, this.setAbsLendingPurpose.bind(this));

		formlyOnClick(formFields, `${subSectionKey}.lendingPurpose.delete`, (field: FormlyFieldConfig) => {
			const key = field.parent?.key as number;
			this.removeLendingPurpose(field.parent?.parent as FormlyFieldConfig, +key);
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.lendingPurpose.absLendingPurposeCode`, {
			onInit: (field) => {
				if (field) {
					field.className = Number(field.parent?.key) === 0 ? 'col-9' : 'col-8';
				}
			}
		});
	}

	private configureProductSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);

		this.loanServiceabilityService.configureProductsFilterByBorrowingAndProductInfo(loanId);
		this.loanServiceabilityService.configureSelectedProductInFormState(loanId);

		formlyOnStatusChangedToValid(formFields, subSectionKey, (field) => this.saveProduct(field));

		formlyRegisterHooks(formFields, `${subSectionKey}.fixedRatePeriodInMonths`, {
			onInit: (fixedRatePeriodField: FormlyFieldConfig | undefined) => {
				if (fixedRatePeriodField) {
					return fixedRatePeriodField.formControl?.valueChanges.pipe(
						distinctUntilChanged(),
						tap(() => this.validateFixedRatePeriod(fixedRatePeriodField))
					);
				} else {
					return of(null);
				}
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.loanPeriod`, {
			onInit: (loanPeriodField: FormlyFieldConfig | undefined) => {
				if (loanPeriodField) {
					return loanPeriodField.formControl?.valueChanges.pipe(
						distinctUntilChanged(),
						tap(() => {
							const fixedRatePeriodField = getFormField(loanPeriodField?.parent?.fieldGroup, 'fixedRatePeriodInMonths');
							this.validateFixedRatePeriod(fixedRatePeriodField);
						})
					);
				} else {
					return of(null);
				}
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.preferredProductVariationId`, {
			onInit: (field) => {
				if (field?.props) {
					field.props.options = this.formEnumsQuery.selectEnumOptions(`LoanProducts-${loanId}`);
					if (field.props.label === 'Product (optional)') {
						field.props.label = 'Product';
					}
				}
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.loanFeatures`, {
			onInit: (field) => {
				if (field?.props) {
					field.props.options = this.formEnumsQuery.selectEnumOptions(`ProductFeatures-${loanId}`);
				}
			}
		});

		formlyOnChange(formFields, `${subSectionKey}.preferredProductVariationId`, this.handleProductChange.bind(this));

		formlyOnChange(formFields, `${subSectionKey}.loanFeatures`, (field, features: EnumObject[]) => {
			if (features) {
				this.syncFeaturesInLoanFeaturesSubsection(features, loanId);
			}
		});
	}

	private configureDiscountSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.DISCOUNT, loanId);

		formlyOnStatusChangedToValid(formFields, subSectionKey, (field) => this.saveDiscount(field));
	}

	private configureRateAdjustmentsSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.RATE_ADJUSTMENTS, loanId);

		formlyOnClick(formFields, `${subSectionKey}.addInitialRateAdjustment`, (field: FormlyFieldConfig) => {
			this.openRateAdjustmentModal(field, loanId, subSectionKey, TermType.InitialTerm);
		});

		formlyOnClick(formFields, `${subSectionKey}.addSubSequentRateAdjustment`, (field: FormlyFieldConfig) => {
			this.openRateAdjustmentModal(field, loanId, subSectionKey, TermType.SubsequentTerm);
		});

		// EDIT RATE ADJUSTMENT
		formlyOnStatusChangedToValid(
			formFields,
			`${subSectionKey}.initialCustomRateAdjustment`,
			(field, event: FormlyArrayUpdateEvent<RateAdjustment>) => {
				const model = get(field, `model[${event.index}]`) as RateAdjustment;
				this.updateCustomRateAdjustments(model, loanId);
			}
		);

		formlyOnStatusChangedToValid(
			formFields,
			`${subSectionKey}.subSequentCustomRateAdjustment`,
			(field, event: FormlyArrayUpdateEvent<RateAdjustment>) => {
				const model = get(field, `model[${event.index}]`) as RateAdjustment;
				this.updateCustomRateAdjustments(model, loanId);
			}
		);

		// DELETE RATE ADJUSTMENT
		formlyOnClick(
			formFields,
			`${subSectionKey}.initialCustomRateAdjustment.deleteAdjustment`,
			(field: FormlyFieldConfig) => {
				const model = get(field, 'model') as CustomLoadersModel;
				if (model) {
					this.loanServiceabilityService.deleteRateAdjustment(model.id, loanId).subscribe(() => {
						this.toastr.success(deletedSuccessfullyMessage('Custom Rate Adjustment'));
						this.loanServiceabilityService.loadRateAdjustment(loanId);
						this.loanServiceabilityService.loadRateToBorrower(loanId);
					});
				}
			}
		);

		formlyOnClick(
			formFields,
			`${subSectionKey}.subSequentCustomRateAdjustment.deleteAdjustment`,
			(field: FormlyFieldConfig) => {
				const model = get(field, 'model') as CustomLoadersModel;
				if (model) {
					this.loanServiceabilityService.deleteRateAdjustment(model.id, loanId).subscribe(() => {
						this.toastr.success(deletedSuccessfullyMessage('Custom Rate Adjustment'));
						this.loanServiceabilityService.loadRateAdjustment(loanId);
						this.loanServiceabilityService.loadRateToBorrower(loanId);
					});
				}
			}
		);
	}

	private configureCommissionSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.COMMISSION, loanId);

		formlyOnClick(formFields, `${subSectionKey}.adjustInitialCommissionButton`, (field: FormlyFieldConfig) => {
			const commission = this.simpFormlyHandlerService.getStateData<CommissionModel>(subSectionKey)?.[0];
			const updatedCommission = cloneDeep(commission);
			updatedCommission.isInitialAdjustedCommissionAvailable = false;
			this.simpFormlyHandlerService.updateToState(subSectionKey, updatedCommission, 0);
		});

		formlyOnClick(formFields, `${subSectionKey}.adjustSubSequentCommissionButton`, (field: FormlyFieldConfig) => {
			const commission = this.simpFormlyHandlerService.getStateData<CommissionModel>(subSectionKey)?.[0];
			const updatedCommission = cloneDeep(commission);
			updatedCommission.isSubSequentAdjustedCommissionAvailable = false;
			this.simpFormlyHandlerService.updateToState(subSectionKey, updatedCommission, 0);
		});

		formlyOnClick(
			formFields,
			`${subSectionKey}.subSequentAdjustedCommission.deleteSubSequentAdjustedCommission`,
			(field: FormlyFieldConfig) => {
				const model = field.parent?.parent?.model as CommissionModel;
				const subSequentAdjustedChangeFormField = getFormField(field?.parent?.fieldGroup, 'subSequentAdjustedChange');
				const termType = TermType.SubsequentTerm;
				if (model) {
					this.loanServiceabilityService.deleteCommission(model.id, termType).subscribe(() => {
						this.toastr.success(deletedSuccessfullyMessage('Adjusted commission'));
						this.removeAdjustedCommissionTemplateText(subSequentAdjustedChangeFormField);
						this.loanServiceabilityService.loadRateToBorrower(loanId);
						this.loanServiceabilityService.loadCommision(loanId);
					});
				}
			}
		);

		formlyOnClick(
			formFields,
			`${subSectionKey}.initialAdjustedCommission.deleteInitialAdjustedCommission`,
			(field: FormlyFieldConfig) => {
				const model = field.parent?.parent?.model as CommissionModel;
				const initialAdjustedChangeFormField = getFormField(field?.parent?.fieldGroup, 'initialAdjustedChange');
				const termType = TermType.InitialTerm;
				if (model) {
					this.loanServiceabilityService.deleteCommission(model.id, termType).subscribe(() => {
						this.toastr.success(deletedSuccessfullyMessage('Adjusted commission'));
						this.removeAdjustedCommissionTemplateText(initialAdjustedChangeFormField);
						this.loanServiceabilityService.loadRateToBorrower(loanId);
						this.loanServiceabilityService.loadCommision(loanId);
					});
				}
			}
		);

		formlyOnStatusChangedToValid(formFields, subSectionKey, (field) => {
			this.saveCommission(field, loanId);
			const model = (field.model as CommissionModel[])?.[0];
			const initialFormField = getFormField(field?.fieldGroup, `0.initialAdjustedCommission.initialAdjustedChange`);
			const subSequentFormField = getFormField(
				field?.fieldGroup,
				`0.subSequentAdjustedCommission.subSequentAdjustedChange`
			);
			this.changeInitialAdjustedCommissionTemplateText(initialFormField, model);
			this.changeSubSequentAdjustedCommissionTemplateText(subSequentFormField, model);
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.initialAdjustedCommission.initialAdjustedChange`, {
			onInit: (field) => {
				const model = (field.parent?.parent?.parent?.model as CommissionModel[])?.[0];
				this.changeInitialAdjustedCommissionTemplateText(field, model);
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.subSequentAdjustedCommission.subSequentAdjustedChange`, {
			onInit: (field) => {
				const model = (field.parent?.parent?.parent?.model as CommissionModel[])?.[0];
				this.changeSubSequentAdjustedCommissionTemplateText(field, model);
			}
		});
	}

	private configureRateToBorrowerSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.RATE_TO_BORROWER, loanId);
		const frequencies$ = this.formEnumsQuery
			.selectEnumOptions(`RepaymentFrequencies-${loanId}`)
			.pipe(takeUntil(this.loanServiceabilityService.areaChanged$));

		formlyOnStatusChangedToValid(formFields, subSectionKey, (field) => this.saveRateToBorrower(field, subSectionKey));

		formlyRegisterHooks(formFields, `${subSectionKey}.repaymentFrequency`, {
			onInit: (field) => {
				if (field.props) {
					field.props.options = frequencies$.pipe(shareReplay());
				}
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.subSeqRepaymentFrequency`, {
			onInit: (field) => {
				if (field.props) {
					field.props.options = frequencies$.pipe(shareReplay());
				}
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.initialTermDiscountRate`, {
			onInit: (field) => {
				if (field) {
					return field.formControl?.valueChanges.pipe(
						distinctUntilChanged(),
						tap(() => {
							field?.formControl?.updateValueAndValidity();
							field?.formControl?.addValidators(this.rateToBorrowerInitialTermDiscountValidator);
							field?.formControl?.updateValueAndValidity();
						})
					);
				}
				return of(null);
			}
		});

		formlyRegisterHooks(formFields, `${subSectionKey}.subSequentTermDiscountRate`, {
			onInit: (field) => {
				if (field) {
					return field.formControl?.valueChanges.pipe(
						distinctUntilChanged(),
						tap(() => {
							field?.formControl?.updateValueAndValidity();
							field?.formControl?.addValidators(this.rateToBorrowerSubTermDiscountValidator);
							field?.formControl?.updateValueAndValidity();
						})
					);
				}
				return of(null);
			}
		});
	}

	private configureLoanFeaturesSection(formFields: FormlyFieldConfig[], loanId: number) {
		const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);

		formlyRegisterHooks(formFields, `${subSectionKey}.fixedRateLock.lockedInterestRate`, {
			onInit: (lockedInterestRateField) => {
				const rateToBorrowerSubSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(
					LoanSubSection.RATE_TO_BORROWER,
					loanId
				);
				const rateToBorrowerData$ =
					this.simpFormlyHandlerService.mapToStateData<RateToBorrower[]>(rateToBorrowerSubSectionKey);
				return rateToBorrowerData$.pipe(
					tap((rateToBorrower) => {
						const initialBorrowerRate = rateToBorrower?.[0]?.initialBorrowersRate as number;
						const model = lockedInterestRateField.model as LockedInterestRate;

						if (initialBorrowerRate && model?.lockedInterestRate !== initialBorrowerRate) {
							lockedInterestRateField.formControl?.patchValue(initialBorrowerRate);
							if (lockedInterestRateField.parent?.formControl?.invalid) {
								return;
							}

							const index = Number(lockedInterestRateField?.parent?.parent?.key);
							this.saveLockedInterestRate(model, loanId, index);
						}
					})
				);
			}
		});

		formlyOnClick(formFields, `${subSectionKey}.isFeatureSelected`, (featureField) => {
			const model = featureField.model as ProductFeature;
			const index = Number(featureField?.parent?.key);

			this.saveProductFeature(model, loanId, subSectionKey, index);

			this.syncFeaturesInProductSubsection(model, loanId);
		});

		formlyOnClick(formFields, `${subSectionKey}.offsetAccountDetails.offsetAccounts.delete`, (field) => {
			formlyDeleteFromArray(field);
		});

		formlyExtendExpressionProperties(formFields, `${subSectionKey}.isFeatureSelected`, {
			'templateOptions.label': (model: ProductFeature, formState: unknown, field: FormlyFieldConfig) => {
				const featureName = model?.featureName ?? '';
				const infoTextAddon = model?.infoText ? `<br>${model?.infoText}` : '';
				return featureName + infoTextAddon;
			}
		});

		formlyOnStatusChangedToValid(
			formFields,
			`${subSectionKey}`,
			(field, event: FormlyArrayUpdateEvent<ProductFeature>) => {
				const updatedLoanFeature = event.data;

				if (updatedLoanFeature.id === LoanFeature.Offset && updatedLoanFeature.offsetAccountDetails) {
					this.saveOffsetAccountDetails(updatedLoanFeature.offsetAccountDetails, loanId, event.index as number);
				}

				if (updatedLoanFeature.id === LoanFeature.CreditCard && updatedLoanFeature.creditCardApplication) {
					this.saveCreditCardApplication(updatedLoanFeature.creditCardApplication, loanId, event.index as number);
				}
				if (updatedLoanFeature.id === LoanFeature.RateLock && updatedLoanFeature.fixedRateLock) {
					this.saveLockedInterestRate(updatedLoanFeature.fixedRateLock, loanId, event.index as number);
				}
			}
		);

		formlyRegisterHooks(formFields, `${subSectionKey}.creditCardApplication.primaryCardHolderId`, {
			onInit: (primaryCardHolderIdField) => {
				const additionalCardHolderIdField = getFormField(
					primaryCardHolderIdField?.parent?.fieldGroup,
					'additionalCardHolderId'
				);
				const additionalCardHolderIdFormControl = additionalCardHolderIdField?.formControl;
				const primaryCardHolderIdFieldFormControl = primaryCardHolderIdField.formControl;
				if (!primaryCardHolderIdFieldFormControl || !additionalCardHolderIdFormControl) {
					return of();
				}

				const personApplicants = this.formEnumsQuery.getOptions('PersonApplicants');
				const personApplicantsNoGuarantorsOptions = this.formEnumsQuery.getOptions('PersonApplicantsNoGuarantors');

				combineLatest([
					additionalCardHolderIdFormControl.valueChanges.pipe(
						startWith(additionalCardHolderIdFormControl.value),
						distinctUntilChanged()
					),
					primaryCardHolderIdFieldFormControl.valueChanges.pipe(
						startWith(primaryCardHolderIdFieldFormControl.value),
						distinctUntilChanged()
					)
				]).subscribe(([additionalCardHolderId, primaryCardHolderId]) => {
					if (additionalCardHolderIdField.props) {
						additionalCardHolderIdField.props.options = personApplicants.filter(
							(option) => option.id !== primaryCardHolderId
						);
					}
					if (primaryCardHolderIdField.props) {
						primaryCardHolderIdField.props.options = personApplicantsNoGuarantorsOptions.filter(
							(option) => option.id !== additionalCardHolderId
						);
					}
				});

				return of();
			}
		});

		formlyOnChange(formFields, `${subSectionKey}.creditCardApplication.creditCardProductId`, (field) => {
			const model = field.model as CreditCardApplication;
			this.loanServiceabilityService.updateSelectedCreditCardLimits(model);

			const requiredCardLimitField = getFormField(field.parent?.fieldGroup, 'requiredCardLimit');
			requiredCardLimitField?.formControl?.markAsTouched();
		});

		formlyOnChange(formFields, `${subSectionKey}.creditCardApplication.primaryCardHolderId`, (field) => {
			this.resetTaxResidencyDetails(field);
		});

		formlyOnClick(formFields, `${subSectionKey}.creditCardApplication.taxResidencyOutsideAu`, (field) => {
			if (field?.formControl?.value === TaxResidencyOptions.Yes) {
				this.addTaxResidency(field);
			}
		});

		formlyOnChange(
			formFields,
			`${subSectionKey}.creditCardApplication.foreignTaxAssociationDetails.countryOfForeignTaxResidency`,
			(field) => {
				const model = field.model as ForeignTaxAssociationDetail;
				this.updateOptionsOfReasonForNotProvidingTin(field, model.countryOfForeignTaxResidency);
			}
		);

		const countryOfTaxResidencyField = getFormField(
			formFields,
			`${subSectionKey}.creditCardApplication.foreignTaxAssociationDetails.countryOfForeignTaxResidency`
		);

		formlyExtendExpressionProperties(
			formFields,
			`${subSectionKey}.creditCardApplication.foreignTaxAssociationDetails.countryOfForeignTaxResidency`,
			{
				'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
					return `${Number(field.parent?.key) + 1} - ${countryOfTaxResidencyField?.props?.label}`;
				}
			}
		);

		formlyOnClick(formFields, `${subSectionKey}.creditCardApplication.foreignTaxAssociationDetails.delete`, (field) =>
			formlyDeleteFromArray(field)
		);

		formlyOnClick(formFields, `${subSectionKey}.creditCardApplication.addButton`, (field) =>
			this.addTaxResidency(field)
		);

		formlyRegisterHooks(formFields, `${subSectionKey}.offsetAccountDetails.offsetAccounts.existingAccountId`, {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const fieldOptions = this.formEnumsQuery.getOptions(field?.templateOptions?.options as unknown as string);
					const alreadySelectedAccounts = (field.parent?.parent?.model as OffsetAccountDetails[])
						.filter(
							(account) =>
								account.existingAccountId &&
								account.existingAccountId !== (field.model as OffsetAccountDetails).existingAccountId
						)
						.map((account) => account.existingAccountId);
					const listItems = fieldOptions.filter((di) => !alreadySelectedAccounts.includes(di.id));
					field.templateOptions.options = listItems;
				}
				return of();
			}
		});
	}

	private setNoOfRepayments(
		formField: FormlyFieldConfig,
		path: string,
		termPeriod?: number,
		frequency?: RepaymentFrequency
	) {
		const repaymentField = getFormField(formField?.parent?.fieldGroup, path);
		if (repaymentField) {
			const repayments =
				termPeriod && frequency
					? CalculationHelper.CalculateAnnualAmount(termPeriod / 12, repaymentToFrequencyFull(frequency))
					: 0;
			repaymentField.formControl?.setValue(repayments);
		}
	}

	private handleProductChange(productVariationField: FormlyFieldConfig): void {
		this.avoidSavingProduct = true;
		const model = productVariationField?.model as Product;
		const previousProductVariationId = this.loanServiceabilityService.getSelectedJourneyProductId(model.loanId);

		const loanRequirementsField = getParentFormField(productVariationField, 'loanRequirements');
		const customLabelsField = getFormField(loanRequirementsField?.fieldGroup, 'customLabels');

		const confirmationHeader = getFormField(customLabelsField?.fieldGroup, 'updateProductConfirmationHeader')
			?.template as string;
		const confirmationBody = getFormField(customLabelsField?.fieldGroup, 'updateProductConfirmationBody')
			?.template as string;

		this.confirmationDialogService
			.warnToProceed(confirmationHeader, confirmationBody, 'Change', 'Cancel')
			.subscribe((isProceed: boolean) => {
				if (isProceed) {
					const journeyProduct = this.loanServiceabilityService.getJourneyProduct(
						model.loanId as number,
						model.preferredProductVariationId
					);
					if (journeyProduct) {
						this.loanServiceabilityService.saveSelectedJourneyProduct(journeyProduct, true).subscribe();
					}

					// Need to do this after the default debounce time of a subsection for triggering value changes
					setTimeout(() => (this.avoidSavingProduct = false), 1000);
				} else {
					productVariationField.formControl?.patchValue(previousProductVariationId, {
						onlySelf: true,
						emitEvent: false
					});
					this.avoidSavingProduct = false;
				}
			});
	}

	private saveOffsetAccountDetails(offsetAccounts: OffsetAccounts, loanId: number, index: number) {
		const subsectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
		this.loanServiceabilityService.saveOffsetAccountDetails(offsetAccounts, loanId).subscribe(() => {
			const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);

			const updatedLoanFeature = cloneDeep(loanFeatures[index]);
			updatedLoanFeature.offsetAccountDetails = offsetAccounts;
			this.simpFormlyHandlerService.updateToState(subsectionKey, updatedLoanFeature, index);
			this.toastr.success(savedSuccessfullyMessage('Offset account details'));
		});
	}

	private saveCreditCardApplication(creditCardApplication: CreditCardApplication, loanId: number, index: number) {
		const subsectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
		this.loanServiceabilityService.saveCreditCardApplication(creditCardApplication, loanId).subscribe(() => {
			const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);
			const updatedLoanFeature = cloneDeep(loanFeatures[index]);
			this.loanServiceabilityService.updateSelectedCreditCardLimits(creditCardApplication);
			updatedLoanFeature.creditCardApplication = creditCardApplication;
			this.simpFormlyHandlerService.updateToState(subsectionKey, updatedLoanFeature, index);
			this.toastr.success(savedSuccessfullyMessage('Credit card details'));
		});
	}
	private saveLockedInterestRate(fixedRateLock: LockedInterestRate, loanId: number, index: number) {
		const subsectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
		this.loanServiceabilityService.saveLockedInterestRate(fixedRateLock, loanId).subscribe(() => {
			const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(subsectionKey);
			const updatedLoanFeature = cloneDeep(loanFeatures[index]);
			updatedLoanFeature.fixedRateLock = fixedRateLock;
			this.simpFormlyHandlerService.updateToState(subsectionKey, updatedLoanFeature, index);
			this.toastr.success(savedSuccessfullyMessage('Locked Interest Rate'));
		});
	}

	private savePropertyAsset(field: FormlyFieldConfig, event: FormlyArrayUpdateEvent<PropertyAssetModel>): void {
		if (this.sharedFlagsService.isPropertyModalOpened || this.isConstructionDetailsModalOpen) {
			return;
		}

		const propertyAssets = field?.model as PropertyAssetModel[];
		let model = cloneDeep(get(propertyAssets, `${event.index}`) as PropertyAssetModel);

		model = merge({}, model, get(field?.formControl?.value as PropertyAssetModel[], `${event.index}`));

		const propertyDetails = model?.details?.propertyDetails;
		if (!propertyDetails) {
			return;
		}
		if (propertyDetails.propertyAssetDetails?.[0]) {
			propertyDetails.propertyAssetDetails[0].isConstruction = model.isConstruction;
		}

		if (model.valuer?.id !== -2) {
			this.saveProperty(model, event.index || 0, 0);
		}
	}

	private saveProperty = (model: PropertyAssetModel, personnelIndex: number, applicantId: number) => {
		if (!model) {
			return new Subscription();
		}

		const propertyDetails = this.simpFormlyHandlerService
			.getStateData<PropertyAssetModel>('property')
			?.find((property) => property.id === model?.id)?.details?.propertyDetails;

		if (!propertyDetails) {
			return new Subscription();
		}

		const propertyAssetModel = cloneDeep(model);
		const details = cloneDeep(propertyDetails);
		return this.loanServiceabilityService.updatePropertyDetails(propertyAssetModel, details).subscribe();
	};

	private configureConveyancer(formFields: FormlyFieldConfig[]) {
		formlyRegisterHooks(formFields, 'purchaseDetails.property.conveyancer', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const addNewCompany = this.formEnumsQuery.getAddOptions(field?.templateOptions?.options as unknown as string);
					if (addNewCompany?.[0]) {
						addNewCompany[0].id = -2;
					}

					const listItems = this.getRelatedCompanyList(addNewCompany, () => {
						this.relatedEntitiesService.openPrefilledRelatedEntityModal(field, 0, this.saveProperty, 'conveyancer');
					});
					field.templateOptions.options = listItems;
					return this.relatedEntitiesService.configureRelatedEntityModal(field, 0, this.saveProperty, 'conveyancer');
				}
				return of();
			}
		});
	}

	private getRelatedCompanyList(
		additionalCompanies: EnumObject[],
		callbackFunction?: Function
	): Observable<EnumObject[]> {
		return this.formEnumsQuery.selectEnumOptions('RelatedOtherCompanies').pipe(
			map((relatedCompany) => {
				if (relatedCompany) {
					return [...additionalCompanies, ...relatedCompany].map((person) => ({
						...person,
						click:
							(person.type as unknown as TargetType) === TargetType.RelatedCompany && callbackFunction
								? callbackFunction
								: null
					}));
				} else {
					return additionalCompanies;
				}
			})
		);
	}

	/**
	 * TODO: PROP-MODAL: Refine
	 */
	private deletePropertyAsset(field: FormlyFieldConfig) {
		let hasRentals = false;
		let hasHomeLoans = false;

		const realEstate = field.model as PropertyAssetModel;
		if (realEstate?.details?.propertyDetails) {
			hasRentals = realEstate.details.propertyDetails.rentalIncomeDetails?.length > 0;
			hasHomeLoans = realEstate.details.propertyDetails.homeLoanDetails?.length > 0;
		}

		let messageKeyConfirmation = '';

		if (hasRentals && hasHomeLoans) {
			messageKeyConfirmation = 'rentalAndHomeLoanLabel';
		} else if (hasRentals) {
			messageKeyConfirmation = 'rentalLabel';
		} else if (hasHomeLoans) {
			messageKeyConfirmation = 'homeLoanLabel';
		} else {
			messageKeyConfirmation = 'propertyLabel';
		}

		const labelFields = getFormField(field.parent?.fieldGroup, `deleteConfirmationLabels`) as FormlyFieldConfig;

		const deleteMessage = labelFields.fieldGroup?.find((group) => group?.key === messageKeyConfirmation)
			?.template as string;

		this.confirmationDialogService
			.deleteConfirmation('Delete property?', deleteMessage, 'Delete', 'Cancel')
			.subscribe((isDelete: boolean) => {
				if (isDelete) {
					this.formArrayDeleteService.deleteItem(field, 'Property', 'property').subscribe(() => {
						// Added setTimeout to avoid the form state update inside the below function which causes duplication of property modal fields.
						setTimeout(() => {
							this.loanServiceabilityService.filterJourneyProducts().subscribe();
						});
						this.addressService.fetchAllAddress();
						this.loanServiceabilityService.updateLendersMortgageInsurance();
						this.toastr.success(deletedSuccessfullyMessage(`Property`));
						this.appService.applicationTitleInfoChange.next();
						this.digitalWidgetsConfigurationRepository.fetchAllStatus().subscribe();
					});
				}
			});
	}

	private saveLoanInformation(loanInfoModel: LoanInformation): void {
		if (loanInfoModel.isSplitLoan) {
			loanInfoModel.amountRequested = this.getTotalLoanAmount(loanInfoModel.loanSplits);
		}
		this.loanServiceabilityService.saveLoanInformation(loanInfoModel).subscribe(() => {
			this.simpFormlyHandlerService.updateToState('loanInformation', loanInfoModel, 0);
			this.updateSplitAmountInBorrowingState(loanInfoModel);
			this.toastr.success(savedSuccessfullyMessage('Loan information'));
		});
	}

	private saveBorrowing(field: FormlyFieldConfig): void {
		const model = (field.model as BorrowingInfo[])?.[0];
		const controlValue = (field.formControl?.value as BorrowingInfo[])?.[0];
		const borrowingInfo = merge(model, controlValue);

		const loanModel = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
		if (!loanModel.isSplitLoan) {
			borrowingInfo.amountRequested = loanModel.amountRequested;
		}

		this.loanServiceabilityService.saveBorrowing(borrowingInfo).subscribe(() => {
			this.simpFormlyHandlerService.updateToState(field.key as string, borrowingInfo, 0);
			this.updateSplitAmountInLoanInformationState(borrowingInfo);
			this.toastr.success(savedSuccessfullyMessage('Borrowing'));
		});
	}

	private saveProduct(field: FormlyFieldConfig): void {
		if (this.avoidSavingProduct) return;

		const model = (field.model as Product[])?.[0];
		const controlValue = (field.formControl?.value as Product[])?.[0];
		const product = merge(model, controlValue);

		this.loanServiceabilityService.saveProduct(product).subscribe(() => {
			this.simpFormlyHandlerService.updateToState(field.key as string, product, 0, product);
			this.toastr.success(savedSuccessfullyMessage('Product'));
		});
	}

	private saveCommission(field: FormlyFieldConfig, loanId: number): void {
		const model = (field.model as CommissionModel[])?.[0];

		this.loanServiceabilityService.saveCommission(model).subscribe(() => {
			this.loanServiceabilityService.loadRateAdjustment(loanId);
			this.loanServiceabilityService.loadRateToBorrower(loanId);
			this.loanServiceabilityService.loadCommision(loanId);
			this.toastr.success(savedSuccessfullyMessage('Adjusted Commission'));
		});
	}

	private saveDiscount(field: FormlyFieldConfig): void {
		const model = (field.model as Discount[])?.[0];
		const controlValue = (field.formControl?.value as Discount[])?.[0];
		const discount = merge(model, controlValue);

		this.loanServiceabilityService.saveDiscount(discount).subscribe(() => {
			this.simpFormlyHandlerService.updateToState(field.key as string, discount, 0, discount);
			this.loanServiceabilityService.loadRateToBorrower(discount.loanId);
			this.toastr.success(savedSuccessfullyMessage('Discount'));
		});
	}

	private saveRateToBorrower(field: FormlyFieldConfig, key: string): void {
		const model = (field.model as Product[])?.[0];
		const controlValue = (field.formControl?.value as RateToBorrower[])?.[0];
		const rateToBorrower = merge(model, controlValue);

		this.loanServiceabilityService
			.saveRateToBorrower(rateToBorrower)
			.subscribe((rateToBorrowerDto: RateToBorrowerDTO) => {
				const updatedRateToBorrower = this.loanServiceabilityService.mapRateToBorrower(rateToBorrowerDto);
				this.simpFormlyHandlerService.updateToState(key, updatedRateToBorrower, 0);
				this.toastr.success(savedSuccessfullyMessage('Rate to borrower'));
			});
	}

	private saveProductFeature(feature: ProductFeature, loanId: number, SubsectionKey: string, index: number): void {
		this.loanServiceabilityService.saveProductFeatures(feature, loanId).subscribe(() => {
			if (feature.id === LoanFeature.CreditCard && !feature.isFeatureSelected) {
				feature.creditCardApplication = undefined;
			}

			this.simpFormlyHandlerService.updateToState(SubsectionKey, feature, index);
			this.toastr.success(savedSuccessfullyMessage('Loan feature'));

			if (feature.id === LoanFeature.Offset && !feature.isFeatureSelected) {
				this.saveOffsetAccountDetails({}, loanId, index);
			}
			if (feature.id === LoanFeature.RateLock && !feature.isFeatureSelected) {
				this.saveLockedInterestRate({}, loanId, index);
			}
		});
	}

	private openRateAdjustmentModal(field: FormlyFieldConfig, loanId: number, subSectionKey: string, termType: TermType) {
		const modalField = getFormField(field.parent?.parent?.parent?.fieldGroup, 'rateAdjustmentsModal');
		if (!modalField) {
			return;
		}

		this.configureRateAdjustmentModal(modalField, loanId);

		const modalRef = this.simpFormlyModalService.openModal(modalField, undefined, {
			backdrop: 'static',
			size: 'md property-modal-wrapper simp-modal-confirm'
		});

		// Handle modal close on X button click
		const subscription = modalRef.action.subscribe((action: string) => {
			if (action !== 'submit') {
				this.closeRateAdjustmentModal(modalRef, subscription);
				return;
			}
			this.saveCustomRateAdjustments(modalField, loanId, termType, modalRef, subscription);
		});
	}

	private configureRateAdjustmentModal(field: FormlyFieldConfig, loanId: number) {
		const featureOptionCodeField = getFormField(field.fieldGroup, 'featureOptionCode') as FormlyFieldConfig;

		if (featureOptionCodeField && featureOptionCodeField.props) {
			featureOptionCodeField.props.options = this.formEnumsQuery.selectEnumOptions(`LoaderTypes-${loanId}`);
		}
	}

	private closeRateAdjustmentModal(modalRef: CustomNgbModalRef | null, subscription: Subscription | null) {
		modalRef?.close();
		subscription?.unsubscribe();
	}

	private saveCustomRateAdjustments = (
		modalField: FormlyFieldConfig,
		loanId: number,
		termType: TermType,
		modalRef: CustomNgbModalRef | null,
		subscription: Subscription | null
	) => {
		const model = modalField.model as CustomLoadersModel;
		const featureCode = this.formEnumsQuery.getOption(`LoaderTypes-${loanId}`, model.featureOptionCode)?.info;
		const rateAdjustment = {
			featureCode: Number(featureCode),
			featureOptionCode: model.featureOptionCode,
			termType
		} as RateAdjustment;
		this.loanServiceabilityService.saveRateAdjustment(rateAdjustment, loanId).subscribe(() => {
			this.loanServiceabilityService.loadRateAdjustment(loanId);
			this.loanServiceabilityService.loadRateToBorrower(loanId);
			this.toastr.success(savedSuccessfullyMessage('Custom Rate Adjustment'));
			this.closeRateAdjustmentModal(modalRef, subscription);
		});
	};

	private updateCustomRateAdjustments = (model: RateAdjustment, loanId: number) => {
		const rateAdjustment = {
			id: model.id,
			featureCode: model.featureCode,
			featureOptionCode: model.featureOptionCode,
			loaderValue: model.loaderValue,
			termType: model.termType
		} as RateAdjustment;
		this.loanServiceabilityService.saveRateAdjustment(rateAdjustment, loanId).subscribe(() => {
			this.toastr.success(savedSuccessfullyMessage('Custom Rate Adjustment'));
			this.loanServiceabilityService.loadRateAdjustment(loanId);
			this.loanServiceabilityService.loadRateToBorrower(loanId);
		});
	};

	private handleSplitLoansCheckboxEvent(isSplitLoanField: FormlyFieldConfig): void {
		const isSplitLoan = isSplitLoanField.formControl?.value as boolean;
		const loanInfoModel = isSplitLoanField.model as LoanInformation;
		if (isSplitLoan) {
			this.addSplit(isSplitLoanField, true);
		} else if (loanInfoModel?.loanSplits?.length) {
			this.confirmationDialogService
				.deleteConfirmation('Delete split loans?', 'All split loans will be deleted.', 'Delete', 'Cancel')
				.subscribe((isDelete) => {
					if (isDelete) {
						this.removeAllSplits(isSplitLoanField, loanInfoModel.loanSplits as LoanSplit[]);
					} else {
						isSplitLoanField.formControl?.patchValue(true, { onlySelf: true, emitEvent: false });
					}
				});
		}
	}

	private syncLoanAmountFieldsFromLoanInformation(formFields: FormlyFieldConfig[]): void {
		formlyRegisterHooks(formFields, 'loanRequirements.loanInformation.loanSplits.loanSplit.splitAmount', {
			onInit: (field) => {
				if (field) {
					const loanId = (field.parent?.parent?.model as LoanSplit)?.loanDetailId;
					const fieldKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.BORROWING, loanId);
					return field.formControl?.valueChanges.pipe(
						distinctUntilChanged(),
						tap((value) => {
							const loanRequirementField = getParentFormField(field, 'loanRequirements');
							const borrowingField = getInitializedFormField(
								loanRequirementField?.fieldGroup,
								`${fieldKey}.0.amountRequested`
							);
							borrowingField?.formControl?.patchValue(value, { onlySelf: true, emitEvent: false });
						})
					);
				} else {
					return of(null);
				}
			}
		});
	}

	private syncLoanAmountsFromBorrowing(field: FormlyFieldConfig, loanId: number) {
		if (field) {
			const loanRequirementField = getParentFormField(field, 'loanRequirements');
			const loanSplitsField = getInitializedFormField(loanRequirementField?.fieldGroup, 'loanInformation.0.loanSplits');

			const loanSplitField = loanSplitsField?.fieldGroup?.find(
				(splitField) => get(splitField.model, 'loanDetailId') === loanId
			);

			const splitAmountField = getFormField(loanSplitField?.fieldGroup, 'loanSplit.splitAmount');

			return field.formControl?.valueChanges.pipe(
				distinctUntilChanged(),
				tap((amount: number) => {
					splitAmountField?.formControl?.patchValue(amount, { onlySelf: true, emitEvent: false });
				})
			);
		}
		return of(null);
	}

	private addLendingPurpose(field: FormlyFieldConfig) {
		const lendingPurposeField = field.parent?.fieldGroup?.find((fg) => fg.key === 'lendingPurpose');

		if (lendingPurposeField?.templateOptions) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			lendingPurposeField.templateOptions.add();
		}
	}

	/**
	 * Add new split
	 * @param field: context of loanSplits key
	 */
	private addSplit(field: FormlyFieldConfig, initSplits = false) {
		const loanRequirementsField = getParentFormField(field, 'loanRequirements');
		if (loanRequirementsField) {
			this.loanServiceabilityService.addNewLoanRequirement().subscribe((newLoanId) => {
				this.transformSplitLoan(loanRequirementsField, newLoanId, true);
				this.updateNewSplitInState(newLoanId, initSplits);
				// Rebuild the form to initiate newly added formly fields
				if (loanRequirementsField.options && loanRequirementsField.options.build) {
					loanRequirementsField.options.build();
				}
			});
		}
	}

	private updateNewSplitInState(loanId: number, initSplits: boolean) {
		const loanInformationModel = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
		const loanInfo = cloneDeep(loanInformationModel);

		if (initSplits) {
			loanInfo.loanSplits = [
				{
					loanSplit: {
						splitAmount: loanInfo.amountRequested
					},
					loanDetailId: loanInfo.loanDetailId
				}
			];
			loanInfo.isSplitLoan = true;
			this.updateSplitAmountInBorrowingState(loanInfo);
		}

		loanInfo?.loanSplits?.push({
			loanDetailId: loanId
		});
		this.simpFormlyHandlerService.updateToState('loanInformation', loanInfo, 0);
	}

	/**
	 * Remove all splits
	 */
	private removeAllSplits(isSplitLoanField: FormlyFieldConfig, splitLoans: LoanSplit[]) {
		const firstLoanId = splitLoans?.[0]?.loanDetailId;
		const deleteLoanIds = splitLoans.map((splitLoan) => splitLoan.loanDetailId ?? -1).filter((id) => id !== -1);
		if (deleteLoanIds.length > 0) {
			this.loanServiceabilityService.deleteAllSplitLoans().subscribe(() => {
				const splitLoansField = getFormField(isSplitLoanField.parent?.fieldGroup, 'loanSplits');
				const model = splitLoansField?.parent?.model as LoanInformation;
				const loanInfo = cloneDeep(model);

				deleteLoanIds.forEach((deleteLoanId) =>
					this.removeSplitLoanFromUI(deleteLoanId, splitLoansField, deleteLoanId === firstLoanId)
				);
				this.toastr.success(deletedSuccessfullyMessage('Split loans'));

				loanInfo.amountRequested = this.getTotalLoanAmount(loanInfo?.loanSplits);
				loanInfo.isSplitLoan = false;
				loanInfo.loanSplits = [];
				this.saveLoanInformation(loanInfo);
			});
		}
	}

	/**
	 * Remove split
	 * @param field: context of loanSplits key
	 */
	private removeSplitLoanFromUI(deleteLoanId: number, splitLoansField?: FormlyFieldConfig, isMainLoan = false) {
		if (splitLoansField) {
			const index =
				splitLoansField.fieldGroup?.findIndex((field) => (field?.model as LoanSplit)?.loanDetailId === deleteLoanId) ??
				-1;
			if (index > -1) {
				(splitLoansField.props?.remove as Function)(index);
			}
			splitLoansField.hide = true;
			splitLoansField.hide = false;
		}

		if (isMainLoan) {
			return;
		}

		const loanRequirementsField = getParentFormField(splitLoansField, 'loanRequirements');
		if (loanRequirementsField?.fieldGroup) {
			const subsectionKeys = loanRequirementsField.fieldGroup
				?.filter((field) => field.key?.toString().includes(`${deleteLoanId}`))
				.map((field) => field.key as string);

			subsectionKeys.forEach((subsectionKey) => {
				const index = loanRequirementsField.fieldGroup?.findIndex((field) => field.key === subsectionKey) ?? -1;
				if (index > -1) {
					loanRequirementsField.fieldGroup?.splice(index, 1);
					this.simpFormlyHandlerService.deleteFromState(subsectionKey, 0); // These subsection will be having only one item, so can use index as 0 always
				}
			});
		}
	}

	private removeLendingPurpose(field: FormlyFieldConfig, index: number) {
		const lendingPurposeField = field.parent?.fieldGroup?.find((fg) => fg.key === 'lendingPurpose');
		if (lendingPurposeField?.props) {
			(lendingPurposeField?.props.remove as Function)(index);
			this.toastr.success(deletedSuccessfullyMessage('Lending purpose'));
		}
	}

	private setAbsLendingPurpose(field: FormlyFieldConfig) {
		const primaryPurpose = field?.formControl?.value as number;
		const lendingPurpose = (field?.model as BorrowingInfo)?.lendingPurpose as BorrowingLendingPurpose[];
		if (lendingPurpose?.length > 0) {
			lendingPurpose.forEach((purpose, i) => {
				const absLendingPurposeField = getInitializedFormField(
					field.parent?.fieldGroup,
					`lendingPurpose.${i}.absLendingPurposeCode`
				);
				const primaryLendingPurpose = purpose?.primaryLendingPurpose;
				if (primaryLendingPurpose && absLendingPurposeField) {
					iif(
						() => primaryPurpose > 0,
						this.formEnumsService.fetchSubEnums('AbsLendingPurposeCode', primaryPurpose, primaryLendingPurpose),
						this.formEnumsService.fetchSubEnums('RbaLendingPurpose', primaryLendingPurpose)
					).subscribe((options) => {
						if (absLendingPurposeField?.props) {
							absLendingPurposeField.props.options = options;
						}
						const matchingOption = options.find(
							(option) => option.id === absLendingPurposeField.formControl?.value
						) as EnumObject;
						absLendingPurposeField.formControl?.setValue(matchingOption?.id ?? null);
					});
				}
			});
		}
	}

	private setPrimaryLendingPurpose(field: FormlyFieldConfig): Observable<EnumObject[]> | undefined {
		if (field && field.props && field.parent?.key && !field.props.label?.match(/^\d/)) {
			field.props.label = `${Number(field.parent?.key) + 1} - ${
				Number(field.parent?.key) === 0 ? 'Primary lending purpose' : 'Additional lending purpose'
			}`;
		}
		if (!field?.formControl?.value) {
			field?.formControl?.setValue(this.transformerUtilService.getPrimaryLendingPurpose());
		}

		const absField = field?.parent?.fieldGroup?.find((f) => f.key === 'absLendingPurposeCode');
		return field?.formControl?.valueChanges.pipe(
			startWith(field.formControl.value),
			filter((primaryLendingPurpose) => !!primaryLendingPurpose),
			distinctUntilChanged(),
			switchMap((primaryLendingPurpose) => {
				const primaryPurpose = (field.parent?.parent?.parent?.model as BorrowingInfo).primaryPurpose;
				if (!primaryPurpose) {
					return this.formEnumsService.fetchSubEnums('RbaLendingPurpose', primaryLendingPurpose as number);
				}
				return this.formEnumsService.fetchSubEnums(
					'AbsLendingPurposeCode',
					primaryPurpose,
					primaryLendingPurpose as number
				);
			}),
			tap((options) => {
				if (absField) {
					if (absField.props) {
						absField.props.options = options;
					}

					const matchingOption = options.find((option) => option.id === absField.formControl?.value);
					if (!matchingOption) {
						absField.formControl?.setValue(null);
					} else {
						absField.formControl?.setValue(matchingOption.id);
					}
				}
			})
		);
	}

	private setPurposeAmount(field: FormlyFieldConfig) {
		let amount = 0;
		if (Number(field?.parent?.key) === 0) {
			const loanRequirementField = getParentFormField(field, 'loanRequirements');
			const loanInformationField = getFormField(loanRequirementField?.fieldGroup, 'loanInformation');

			if (loanInformationField) {
				amount = get(loanInformationField, 'model?.[0]?.amountRequested') ?? 0;
			}
		}

		if (!field?.formControl?.value) {
			field?.formControl?.setValue(amount);
		}
	}

	private appendPropertyIndexToFieldTitle(formFields: FormlyFieldConfig[], path: string): void {
		formlyExtendExpressionProperties(formFields, path, {
			'templateOptions.label': (model: PropertyAssetModel, state: unknown, field: FormlyFieldConfig) => {
				if (field) {
					const index = !isNaN(field?.parent?.key as number) ? `${Number(field?.parent?.key) + 1} - ` : '';
					const fieldTemplate = getFormField(
						getParentFormField(field, 'purchaseDetails')?.fieldGroup,
						`property.${field.key}`
					);
					return `${index}${fieldTemplate?.templateOptions?.label || ''}`;
				}
				return '';
			}
		});
	}

	private updateBorrowingSectionTitle(borrowingField: FormlyFieldConfig, loanId: number, splitLoans: number[]) {
		const splitIndex = splitLoans.findIndex((id) => id === loanId);
		if (borrowingField?.props) {
			borrowingField.props.label = splitIndex > -1 ? `Split ${splitIndex + 1} - Borrowing` : 'Borrowing';
		}
	}

	private updateLoanSplitAmountTitle(formFields: FormlyFieldConfig[], path: string): void {
		const splitAmountLabel = getFormField(formFields, path)?.props?.label;

		formlyExtendExpressionProperties(formFields, path, {
			'templateOptions.label': (model: unknown, formState: unknown, field: FormlyFieldConfig) => {
				return `Split ${Number(field.parent?.parent?.key) + 1} ${splitAmountLabel}`;
			}
		});
	}

	private updateBorrowingAmountTitle(borrowingField: FormlyFieldConfig, loanId: number) {
		const requestedAmountLabel = getFormField(borrowingField?.fieldGroup, 'amountRequested')?.props?.label ?? '';

		const requestedAmountWithCapitalizedFeesLabel =
			getFormField(borrowingField?.fieldGroup, 'amountRequestedWithCapitalisedFees')?.props?.label ?? '';

		this.loanDetailsFromDataService
			.selectSplitLoans$()
			.pipe(takeUntil(this.loanServiceabilityService.areaChanged$))
			.subscribe((splitLoans: number[]) => {
				const amountField = getFormField(borrowingField?.fieldGroup, 'amountRequested');
				const amountFieldWithCapitalizedFees = getFormField(
					borrowingField?.fieldGroup,
					'amountRequestedWithCapitalisedFees'
				);
				const splitIndex = splitLoans.findIndex((id) => id === loanId);
				if (amountField?.props) {
					amountField.props.label =
						splitIndex > -1 ? `Split ${splitIndex + 1} ${requestedAmountLabel}` : requestedAmountLabel;
				}
				if (amountFieldWithCapitalizedFees?.props) {
					amountFieldWithCapitalizedFees.props.label =
						splitIndex > -1
							? `Split ${splitIndex + 1} ${requestedAmountWithCapitalizedFeesLabel}`
							: requestedAmountWithCapitalizedFeesLabel;
				}
			});
	}

	private validateContractDate(formFields: FormlyFieldConfig[] | undefined): void {
		formlyAddCustomValidator(formFields, `purchaseDetails.property.contractDate`, {
			invalid: {
				expression: (c: UntypedFormControl) => {
					if (c.value) {
						return isDatePast(c.value, true);
					}
					return true;
				}
			}
		});
	}

	private validateFinanceApprovalDate(formFields: FormlyFieldConfig[] | undefined): void {
		formlyAddCustomValidator(formFields, `purchaseDetails.property.financeApprovalDate`, {
			invalid: {
				expression: (c: UntypedFormControl) => {
					if (c.value) {
						return isDateFuture(c.value);
					}
					return true;
				}
			}
		});
	}

	private validateFixedRatePeriod(fixedRatePeriodField?: FormlyFieldConfig): void {
		fixedRatePeriodField?.formControl?.updateValueAndValidity();
		fixedRatePeriodField?.formControl?.addValidators(this.fixedRatePeriodValidator);
		fixedRatePeriodField?.formControl?.updateValueAndValidity();
	}

	private fixedRatePeriodValidator = (control: AbstractControl): ValidationErrors | null => {
		const loanPeriod = (control.parent?.value as Product)?.loanPeriod;
		if (control.value && loanPeriod && loanPeriod < control.value) {
			control.markAsTouched();
			return { invalid: true };
		}
		return null;
	};

	private rateToBorrowerInitialTermDiscountValidator = (control: AbstractControl): ValidationErrors | null => {
		const discountMargin = control.value as number;
		const rateToBorrower = control.parent?.value as RateToBorrower;
		if (discountMargin > (rateToBorrower?.interestRate ?? 0)) {
			control.markAsTouched();
			return { invalid: true };
		}
		return null;
	};

	private rateToBorrowerSubTermDiscountValidator = (control: AbstractControl): ValidationErrors | null => {
		const discountMargin = control.value as number;
		const rateToBorrower = control.parent?.value as RateToBorrower;
		if (discountMargin > (rateToBorrower?.revertRate ?? 0)) {
			control.markAsTouched();
			return { invalid: true };
		}
		return null;
	};

	private updateConstructionDetails(field: FormlyFieldConfig) {
		const index = Number(field?.parent?.key);

		const stateData = this.simpFormlyHandlerService.getStateData<PropertyAssetModel>('property');
		const propertyStateData = cloneDeep(stateData[index]);

		this.constructionDetailsService.getConstructionDetails(propertyStateData.id).subscribe((constructionDetailsDTO) => {
			this.loanServiceabilityService.filterProductsWhenbuildPriceChanged(
				constructionDetailsDTO.buildPriceAmount ?? -1,
				index
			);

			const constructionDetails = PropertyAssetModelTransformer.fromPayloadConstructionDetails(
				constructionDetailsDTO,
				this.formEnumsQuery
			);
			propertyStateData.constructionDetails = constructionDetails;
			propertyStateData.isConstruction = YesNo.Yes;
			this.simpFormlyHandlerService.updateToState('property', propertyStateData, index);
		});
	}

	private getTotalLoanAmount(loanSplits?: LoanSplit[]): number {
		if (!loanSplits) {
			return 0;
		}
		return loanSplits.reduce((total, loan) => total + Number(loan?.loanSplit?.splitAmount ?? 0), 0);
	}

	private updateSplitAmountInLoanInformationState(borrowingInfo: BorrowingInfo) {
		const loanInfo = this.simpFormlyHandlerService.getStateData<LoanInformation>('loanInformation')?.[0];
		if (loanInfo.isSplitLoan) {
			const loanSplitIndex =
				loanInfo.loanSplits?.findIndex(
					(split) =>
						split.loanDetailId === borrowingInfo.loanId &&
						split.loanSplit?.splitAmount !== borrowingInfo.amountRequested
				) ?? -1;

			if (loanSplitIndex > -1) {
				const loanInformation = cloneDeep(loanInfo);
				if (loanInformation.loanSplits && loanInformation.loanSplits[loanSplitIndex]) {
					const split = loanInformation.loanSplits[loanSplitIndex];
					if (!split.loanSplit) {
						split.loanSplit = { splitAmount: borrowingInfo.amountRequested ?? 0 };
					} else {
						split.loanSplit.splitAmount = borrowingInfo.amountRequested;
					}
					loanInformation.amountRequested = this.getTotalLoanAmount(loanInformation.loanSplits);
					this.simpFormlyHandlerService.updateToState('loanInformation', loanInformation, 0, loanInformation);
				}
			}
		}
	}

	private updateSplitAmountInBorrowingState(loanInfoModel: LoanInformation) {
		if (loanInfoModel.isSplitLoan) {
			loanInfoModel?.loanSplits?.forEach((split) => {
				if (split) {
					const subSectionKey = this.loanServiceabilityService.getSplitSubsectionKey(
						LoanSubSection.BORROWING,
						split.loanDetailId
					);
					const splitAmount = split.loanSplit?.splitAmount;
					const borrowing = this.simpFormlyHandlerService.getStateData<BorrowingInfo>(subSectionKey)?.[0];
					if (borrowing && borrowing?.amountRequested !== splitAmount) {
						const updatedBorrowing = cloneDeep(borrowing);
						updatedBorrowing.amountRequested = splitAmount;
						this.simpFormlyHandlerService.updateToState(subSectionKey, updatedBorrowing, 0);
					}
				}
			});
		}
	}

	/**
	 * update the selected features in product subsection reflecting the changes happened in the loan features
	 * @param feature
	 * @param loanId
	 */
	private syncFeaturesInProductSubsection(feature: ProductFeature, loanId: number) {
		const productSubsectionKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.PRODUCT, loanId);
		const product = this.simpFormlyHandlerService.getStateData<Product>(productSubsectionKey)?.[0];
		const updatedProduct = cloneDeep(product);
		if (feature.isFeatureSelected) {
			updatedProduct.loanFeatures.push({ id: feature.id, label: feature.featureName } as EnumObject);
		} else {
			const updatedLoanFeatures = updatedProduct.loanFeatures.filter((f) => f.id !== feature.id);
			updatedProduct.loanFeatures = updatedLoanFeatures;
		}
		this.simpFormlyHandlerService.updateToState(productSubsectionKey, updatedProduct, 0);
	}

	/**
	 * update the selected features in loan feature subsection reflecting the changes happened in the products
	 * @param feature
	 * @param loanId
	 */
	private syncFeaturesInLoanFeaturesSubsection(productFeatures: EnumObject[], loanId: number) {
		const featureKey = this.loanServiceabilityService.getSplitSubsectionKey(LoanSubSection.LOAN_FEATURES, loanId);
		const loanFeatures = this.simpFormlyHandlerService.getStateData<ProductFeature>(featureKey);
		const updatedFeatures = cloneDeep(loanFeatures);

		updatedFeatures?.forEach((feature, index) => {
			feature.isFeatureSelected = productFeatures.some((productFeature) => productFeature.id === feature.id);

			// Reset offset account details when un clicking product section
			if (
				feature.id === LoanFeature.Offset &&
				!feature.isFeatureSelected &&
				loanFeatures.some((loanFeature) => loanFeature.id === feature.id && loanFeature.isFeatureSelected)
			) {
				this.saveOffsetAccountDetails({}, loanId, index);
			}
			if (
				feature.id === LoanFeature.RateLock &&
				!feature.isFeatureSelected &&
				loanFeatures.some((loanFeature) => loanFeature.id === feature.id && loanFeature.isFeatureSelected)
			) {
				this.saveLockedInterestRate({}, loanId, index);
			}
		});
		this.simpFormlyHandlerService.upsertToStateWithData(featureKey, updatedFeatures);
	}

	private changeInitialAdjustedCommissionTemplateText(field: FormlyFieldConfig | undefined, model: CommissionModel) {
		if (
			!model.isInitialAdjustedCommissionAvailable &&
			field &&
			(model.initialAdjustedCommission.initialAdjustedUpfrontCommission ||
				model.initialAdjustedCommission.initialAdjustedGrossTrailCommission)
		) {
			const differenceBetweenTotalCommissions = Math.abs(
				model.initialAdjustedCommission.initialAdjustedTotalCommission - model.initialCommission.totalCommission
			).toFixed(2);

			if (differenceBetweenTotalCommissions !== '0.00') {
				field.template =
					model.initialCommission.totalCommission > model.initialAdjustedCommission.initialAdjustedTotalCommission
						? (field.template = field.template?.replace(
								field.template,
								`<h5 class='simp-h5'> Commission decreased by ${differenceBetweenTotalCommissions}%</h5>`
						  ))
						: field.template?.replace(
								field.template,
								`<h5 class='simp-h5'> Commission increased by ${differenceBetweenTotalCommissions}%</h5>`
						  );
			} else {
				this.removeAdjustedCommissionTemplateText(field);
			}
		}
	}

	private changeSubSequentAdjustedCommissionTemplateText(field: FormlyFieldConfig | undefined, model: CommissionModel) {
		if (
			!model.isSubSequentAdjustedCommissionAvailable &&
			field &&
			(model.subSequentAdjustedCommission.subSequentAdjustedUpfrontCommission ||
				model.subSequentAdjustedCommission.subSequentAdjustedGrossTrailCommission)
		) {
			const differenceBetweenTotalCommissions = Math.abs(
				model.subSequentAdjustedCommission.subSequentAdjustedTotalCommission -
					model.subSequentCommission.subSequentTotalCommission
			).toFixed(2);

			if (differenceBetweenTotalCommissions !== '0.00') {
				field.template =
					model.subSequentCommission.subSequentTotalCommission >
					model.subSequentAdjustedCommission.subSequentAdjustedTotalCommission
						? (field.template = field.template?.replace(
								field.template,
								`<h5 class='simp-h5'> Commission decreased by ${differenceBetweenTotalCommissions}%</h5>`
						  ))
						: field.template?.replace(
								field.template,
								`<h5 class='simp-h5'> Commission increased by ${differenceBetweenTotalCommissions}%</h5>`
						  );
			} else {
				this.removeAdjustedCommissionTemplateText(field);
			}
		}
	}

	private removeAdjustedCommissionTemplateText(field: FormlyFieldConfig | undefined) {
		if (field) {
			field.template = '';
		}
	}

	private resetTaxResidencyDetails(field: FormlyFieldConfig) {
		const taxResidencyOutsideAuField = getFormField(field.parent?.fieldGroup, 'taxResidencyOutsideAu');
		taxResidencyOutsideAuField?.formControl?.setValue(null);
	}

	private addTaxResidency(field: FormlyFieldConfig) {
		const taxDetailsField = getFormField(field.parent?.fieldGroup, 'foreignTaxAssociationDetails');
		if (taxDetailsField?.props?.add) {
			(taxDetailsField.props.add as Function)();
		}
	}

	private updateOptionsOfReasonForNotProvidingTin(formField: FormlyFieldConfig, selectedCountryCode?: number) {
		const reasonForNotProvidingTINField = getFormField(formField.parent?.fieldGroup, 'tinNotProvidedReason');

		reasonForNotProvidingTINField?.formControl?.setValue(null);
		if (reasonForNotProvidingTINField?.props && selectedCountryCode) {
			reasonForNotProvidingTINField.props.options = this.formEnumsService.fetchSubEnums(
				'ByCategory',
				APIEnumList.CountryList,
				APIEnumList.TinNotProvidedReasonList,
				selectedCountryCode
			);
		}
	}

	private handleProductVariationChanged(field: FormlyFieldConfig, subSection: 'paidOnOrBefore' | 'paidThroughoutLoan') {
		const feeTypeField = getFormField(field?.parent?.fieldGroup, 'productFeeTypeId') as FormlyFieldConfig;
		if (feeTypeField?.formControl) {
			feeTypeField.formControl.setValue(null);
		}
		if (!field.formControl?.value) {
			this.formEnumsService.updateFormEnums('ProductEligibleFees', []);
		} else {
			setTimeout(() => {
				// need to wait till linkProductVariationId is set dynamically once linked product changed
				this.updateProductEligibleFeesEnum(field?.model as FeeDetailModel, subSection);
			});
		}
	}

	private getSelectedVariationEligibleFees(
		loanDetailId: number,
		subSection: 'paidOnOrBefore' | 'paidThroughoutLoan'
	): EnumObject[] {
		const productFeeTypes = this.simpFormlyHandlerService.getStateData<ProductFeeTypeDTO>('productFeeTypes');
		return productFeeTypes
			.filter(
				(productFeeType) =>
					(productFeeType.loanDetailsId === loanDetailId &&
						subSection === 'paidOnOrBefore' &&
						(productFeeType.paymentTiming === FeePaymentTiming.BeforeCreditDrawdown ||
							productFeeType.paymentTiming === FeePaymentTiming.OnCreditDrawdown)) ||
					(productFeeType.loanDetailsId === loanDetailId &&
						subSection === 'paidThroughoutLoan' &&
						(productFeeType.paymentTiming === FeePaymentTiming.OnEventOccurrence ||
							productFeeType.paymentTiming === FeePaymentTiming.RegularRecurring))
			)
			.map((productFeeType) => {
				return {
					id: productFeeType?.productFeeTypeId,
					label: this.formEnumsQuery.getOptionLabel('FeeType', productFeeType.type as number)
				} as unknown as EnumObject;
			});
	}

	private updateProductEligibleFeesEnum(
		fee: FeeDetailModel,
		subSection: 'paidOnOrBefore' | 'paidThroughoutLoan',
		additionalOptions?: EnumObject
	) {
		const loanDetailsId = fee.linkProduct as number;
		const productEligibleFees = this.getSelectedVariationEligibleFees(loanDetailsId, subSection);
		if (additionalOptions) {
			productEligibleFees.push(additionalOptions);
		}
		this.formEnumsService.updateFormEnums('ProductEligibleFees', productEligibleFees);
	}

	private handleFeesResetToDefault(feesCustomLabelsField: FormlyFieldConfig | undefined): void {
		const confirmationHeader = getFormField(feesCustomLabelsField?.fieldGroup, 'feesResetToDefaultConfirmationHeader')
			?.template as string;

		const confirmationBody = getFormField(feesCustomLabelsField?.fieldGroup, 'feesResetToDefaultConfirmationBody')
			?.template as string;

		const successMessage = getFormField(feesCustomLabelsField?.fieldGroup, 'feesResetToDefaultSuccessMessage')
			?.template as string;

		this.confirmationDialogService
			.warnToProceed(confirmationHeader, confirmationBody, 'Set to Default', 'Cancel')
			.subscribe((isProceed: boolean) => {
				if (isProceed) {
					this.loanServiceabilityService.resetFeesToDefault().subscribe(() => {
						this.loanServiceabilityService.fetchFees(true);
						this.toastr.success(successMessage);
					});
				}
			});
	}

	private openTypeOfSecurityModal(modalField: FormlyFieldConfig, field: FormlyFieldConfig) {
		// race condition in firefox, modalRef.action is not defined error
		setTimeout(() => {
			const modalRef = this.simpFormlyModalService.openModal(modalField, undefined, {
				backdrop: 'static',
				size: 'md',
				windowClass: 'd-block modal fade show simp-modal-confirm'
			});

			// Handle modal close on X button click
			const subscription = modalRef.action.subscribe((action: string) => {
				if (action === 'submit') {
					this.openOtherSecuritiesModal(field);
				} else {
					this.deleteNewEntryOnModalCancel(modalField);
				}
				modalRef?.close();
				subscription?.unsubscribe();
			});
		});
	}

	private deleteNewEntryOnModalCancel(modalField: FormlyFieldConfig) {
		const otherSecuritesState = 'loan-serviceability-otherSecurities';
		const subSectionField = modalField.parent?.parent;
		const id = get(subSectionField, 'model.id') as number;
		const currentStateData: OtherSecuritiesModel[] = this.formDataService.getStateData(otherSecuritesState);
		let filteredData = currentStateData.filter((d) => d.id !== CONSTANTS.NEW_ID) || [];
		const dataWithoutExtract = currentStateData.filter((d) => d.details.extract === undefined);

		if (!id || id === CONSTANTS.NEW_ID || dataWithoutExtract.length) {
			this.deleteFormlyEntry(subSectionField);
			filteredData = filteredData.filter((d) => d.details.extract !== undefined);
		}
		if (filteredData.length) {
			this.formDataService.upsertData(otherSecuritesState, filteredData);
		} else {
			this.formDataService.resetState(otherSecuritesState);
		}
	}

	private deleteFormlyEntry(subSectionField: FormlyFieldConfig | undefined): void {
		const deleteField = getFormField(subSectionField?.fieldGroup, 'delete');
		if (deleteField) {
			formlyDeleteFromArray(deleteField);
		}
	}

	private openOtherSecuritiesModal(field: FormlyFieldConfig, edit = false) {
		const stateData: OtherSecuritiesModel[] = this.formDataService.getStateData('loan-serviceability-otherSecurities');
		const model = field.model as OtherSecuritiesDetails;
		const parentModel = field.parent?.model as OtherSecuritiesModel;

		const type = model.typeOfSecurity.type;
		const savingsEnum = this.formEnumsQuery.getOptions('Savings').find((o) => o.id === type);

		if (type !== CONSTANTS.NEW_ID && parentModel.id === undefined) {
			let index = stateData?.length;
			const selectedId = savingsEnum?.id;
			const savingsStoreData: SavingModel[] = this.formDataService.getStateData('financial-position-savings');
			const currentData = savingsStoreData.find((data) => data.id === selectedId);
			if (edit) {
				index = stateData?.findIndex((x) => x.id === selectedId);
			}
			if (currentData) {
				const prepopulate = OtherSecuritiesModelTransformer.prepopulateFromSavings(
					currentData,
					parseInt(savingsEnum?.info as string),
					this.applicationDataQuery.getApplicants()
				);
				this.formDataService.update('loan-serviceability-otherSecurities', prepopulate, index);
			}
		}

		const modalField = getFormField(field.fieldGroup, 'otherSecuritiesDetails') as FormlyFieldConfig;

		// race condition in firefox, modalRef.action is not defined error
		setTimeout(() => {
			const modalRef = this.simpFormlyModalService.openModal(modalField, undefined, {
				backdrop: 'static'
			});

			// Handle modal close on X button click
			const subscription = modalRef.action.subscribe((action: string) => {
				if (action === 'submit') {
					if (parentModel) {
						this.loanServiceabilityService
							.saveOtherSecurities(0, modalField.parent?.parent?.model as OtherSecuritiesModel)
							.subscribe();
					}
				} else if (action === 'cancel') {
					if (!parentModel.id || (parentModel.id && !edit)) {
						this.deleteNewEntryOnModalCancel(modalField);
					}
				}

				modalRef?.close();
				subscription?.unsubscribe();
			});
		});
	}

	private setSharePercentageTitles(formFields: FormlyFieldConfig[], path: string): void {
		formlyExtendExpressionProperties(formFields, path, {
			'templateOptions.label': (model: PercentageOwned, formState: any, field: FormlyFieldConfig) => {
				const appRole = this.percentOwnedService.getAppRole(field);
				return model?.name && appRole ? `Share - ${model.name} (${appRole})` : 'Share';
			}
		});
	}

	private setDefaultPercentageOwned(
		formFields: FormlyFieldConfig[] | undefined,
		DefaultPercentageOwned: PercentageOwned[],
		path: string
	) {
		const percentOwned = getFormField(formFields, path);
		if (percentOwned && percentOwned.templateOptions) {
			percentOwned.templateOptions.showAllLabels = true;
			percentOwned.defaultValue = DefaultPercentageOwned;
		}
	}
}
