import { AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormValidationsService } from '@app/modules/shared/store/form-validations/form-validations.service';
import { FieldTypeConfig, FieldWrapper } from '@ngx-formly/core';
import { get } from 'lodash-es';
import { Subject } from 'rxjs';
import { generateUniqueKey } from '../../helpers/simp-formly.helper';

@Component({
	selector: 'formly-modal-wrapper',
	templateUrl: './formly-modal-wrapper.component.html',
	styleUrls: ['./formly-modal-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyModalWrapperComponent extends FieldWrapper<FieldTypeConfig> implements AfterViewInit {
	@ViewChild('content') public contentTemplate?: TemplateRef<unknown>;
	uniqueId = '';
	private actionSubject = new Subject<string>();
	private isValidationTriggered = false;

	constructor(private formValidationsService: FormValidationsService) {
		super();
	}

	ngAfterViewInit(): void {
		this.uniqueId = generateUniqueKey(this.field);
		setTimeout(() => {
			this.to.contentTemplate = this.contentTemplate;
			this.to.action = this.actionSubject.asObservable();
		});

		Object.keys((this.formControl as unknown as UntypedFormGroup).controls).forEach((key) => {
			const control = this.formControl.get(key);
			const arrayData = get(this.model, key) as unknown[];
			if (control instanceof UntypedFormArray && arrayData?.length === 0) {
				control.clear();
			}
		});
	}

	onAction(action: Action): void {
		if (action !== 'submit' || this.formControl.valid) {
			// without the delay it retriggers the action when enter key is pressed see https://simpology.atlassian.net/browse/TAMA5-8212
			setTimeout(() => {
				this.actionSubject.next(action);
			}, 250);
		} else {
			if (!this.isValidationTriggered) {
				this.isValidationTriggered = true;
			}
			if (this.field.validation) {
				this.field.validation.show = false;
				this.field.validation.show = true;
			}

			if (this.field.fieldGroup) {
				this.formValidationsService.triggerFieldsValidation(this.field.fieldGroup);
			}
			this.formControl.markAllAsTouched();
		}
	}
}

type Action = 'submit' | 'close' | 'cancel';
