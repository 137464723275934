import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InputActionType } from '@app/modules/shared/enums/app.enums';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import isNil from 'lodash-es/isNil';

import { generateUniqueKey } from '../../helpers/simp-formly.helper';

/**
 * Sample Input
 * {
		className: 'col-2',
		type: 'input',
		key: 'firstName',
		validation: {
			messages: { required: 'We need the lender application number' }
		},
		templateOptions: {
			label: 'First Name',
			required: true,
			disabled: true
		}
	},
 */
@Component({
	selector: 'formly-input',
	templateUrl: './formly-input.component.html',
	styleUrls: ['./formly-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyInputComponent extends FieldType<FieldTypeConfig> implements OnInit {
	uniqueId = '';

	get actionIcon() {
		if (this.to.searchable && !this.formControl.disabled) {
			return { iconClass: 'fas fa-search', actionType: InputActionType.SEARCH };
		} else if (this.to.clearable && this.formControl.disabled) {
			return { iconClass: 'fas fa-times', actionType: InputActionType.CLEAR };
		}
		return undefined;
	}

	ngOnInit(): void {
		if (!isNil(this.to.defaultValue) && isNil(this.formControl.value)) {
			this.formControl.setValue(this.to.defaultValue);
		}
		this.uniqueId = generateUniqueKey(this.field);
	}

	handleEdit(): void {
		if (this.to.click) {
			this.to.click(this.field, InputActionType.EDIT);
		}
	}

	handleActionIconClick(type?: string): void {
		if (!type && this.to.searchable) {
			type = InputActionType.SEARCH;
		}
		if (this.to.click) {
			this.to.click(this.field, type);
		}
	}
}
