<ng-template #content let-modal>
	<div class="loanapp-x-modal">
		<div class="loanapp-x-modal__content">
			<header class="app-header">
				<div class="app-header__contents simp-padding-right-large">
					<button type="button" class="app-header__back-button" (click)="onAction(Action.Cancel)" ngbAutofocus>
						<simp-icon name="far fa-arrow-left"></simp-icon> <span class="visually-hidden">Close {{ to.label }}</span>
					</button>
					<div class="app-header__title">
						<h6 class="simp-h5">{{ to.label }}</h6>
					</div>
				</div>
			</header>

			<div class="loanapp-x-modal__form simp-margin-top-small">
				<div class="mobile-section__form-container">
					<formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
					<div class="mobile-section__buttons-container simp-row" *ngIf="!to.hideModalFooter">
						<div class="col-6" *ngIf="to.secondaryButtonText !== 'hide'">
							<button
								type="button"
								class="btn btn-secondary btn-lg mobile-section__button simp-button col-md-12"
								(click)="onAction(Action.Cancel)"
							>
								Cancel
							</button>
						</div>
						<div [ngClass]="to.secondaryButtonText === 'hide' ? 'col-12' : 'col-6'">
							<button
								type="button"
								class="btn btn-primary btn-lg mobile-section__button simp-button col-md-12"
								(click)="onAction(Action.Submit)"
								[disabled]="formControl.invalid"
							>
								<ng-container *ngIf="!to.nextRoute"> {{ to.primaryButtonText || 'Submit' }} </ng-container>
							</button>
						</div>
						<div
							class="col-12 d-flex justify-content-center simp-margin-top-medium"
							*ngIf="to.showDeleteButton && model?.id"
						>
							<button type="button" class="btn simp-link" (click)="onAction(Action.Delete)">
								{{ to.deleteButtonText || 'Delete this' }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
