<div class="tel-input-wrapper">
	<simp-tel-input
		[id]="uniqueId"
		[formGroup]="$any(formControl)"
		[validationErrors]="field.validation?.messages!"
		[countryCodes]="countryCodes"
		[tooltipContent]="to.tooltip"
		[tooltipAsModal]="to.tooltipAsModal"
		(tooltipClick)="tooltipClick(field)"
		[editable]="to.editable"
		(editClick)="handleEdit()"
		[readonly]="!!to.readonly"
	>
		{{ to.label }}
	</simp-tel-input>
</div>

<!-- quirky way to update form model -->
<formly-field [hidden]="true" *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
