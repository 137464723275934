import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { DOCUMENT } from '@angular/common';
import { ENV_CONFIG } from '@app/env-config';
import { JOURNEY_MAPPINGS } from '../constants/constants';
import { JourneyType } from '../enums/app.enums';
import { isNullOrUndefined } from '../helper/util';
import { Journey } from '../typings/journey';

export const JOURNEY_TYPE = new InjectionToken<JourneyType>('myString');
/**
 * For all http operations, setting journey path in LADM or Secure route, or without setting.
 * Specific Journey can be set eg: super();
 */
@Injectable({ providedIn: 'root' })
export class BaseJourneyService<T = unknown> extends BaseApiService<T> {
	journey?: Journey;
	route?: string;
	setLadmInvoked = false;
	document = inject(DOCUMENT);
	constructor(@Inject(JOURNEY_TYPE) private journeyType?: JourneyType) {
		const routerInstance = inject(Router);
		super(inject(HttpClient), inject(ToastrService), routerInstance, ENV_CONFIG);

		if (this.journeyType) {
			this.setJourney(this.journeyType);
		} else {
			let routeJourneyType =
				parseInt(sessionStorage.getItem('journeyType') as string) ||
				(get(routerInstance, 'routerState.snapshot.root.children[0].data.journeyType', null) as JourneyType);

			if (routeJourneyType && !sessionStorage.getItem('journeyType')) {
				sessionStorage.setItem('journeyType', routeJourneyType.toString());
			}

			if (isNullOrUndefined(routeJourneyType)) {
				// for '/' route
				this.setJourney(JourneyType.FullApplication);
				// no unsubscribe here because we need to watch for route changes always
				routerInstance.events.subscribe((event) => {
					if (event instanceof RoutesRecognized) {
						routeJourneyType = event.state.root?.firstChild?.data['journeyType'] as JourneyType;
						this.setJourney(routeJourneyType);
						if (this.setLadmInvoked) {
							this.setJourneyLadmRoute(this.route);
						}
					}
				});
			} else {
				this.setJourney(routeJourneyType);
			}
		}
	}

	/**
	 * Includes Journey apiPath in LADM Route
	 * @param route
	 */
	setJourneyLadmRoute(route = '') {
		this.route = route;
		this.setLadmInvoked = true;
		const path = this.getJourneyPath(route);
		this.setLadmRoute(path);
	}

	private setJourney(journeyType: JourneyType) {
		this.journey = JOURNEY_MAPPINGS.find((journey) => journey.journeyType === journeyType);
	}

	private getJourneyPath(route = '') {
		const apiPath = this.journey?.apiPath;
		if (!apiPath) {
			console.error('Missing api path for route: ', route ? route : '<no-route>');
		}
		return route ? `/${apiPath}/${route}` : `/${apiPath}` ?? '';
	}
}
