import { YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableYesNo } from '@app/modules/shared/helper/util';
import { GuarantorInterviewDTO } from '@app/modules/typings/api';

export class GuarantorOpinionTransformer {
	static fromPayload(payload: GuarantorInterviewDTO): GuarantorsOpinionModel {
		return {
			id: payload.id,
			lendingGuaranteeId: payload.lendingGuaranteeId,
			underPressure: getNullableYesNo(payload.confirmNotUnderPressure)
		};
	}
}
export interface GuarantorsOpinionModel {
	id?: number;
	lendingGuaranteeId?: number;
	underPressure?: YesNo;
}
