import { Injectable } from '@angular/core';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { RefiOtherAssetsDTO, RefiPropertyAssetsDTO, RefiSavingsAssetsDTO } from '@app/modules/typings/api';
import { Observable, forkJoin, map, of } from 'rxjs';
import { RefiStore } from '../../enums/refi-store.enum';

import { OtherAssetsDetails, OtherAssetsModel, RefiOtherAssetsTransformer } from '../../models/refi-other-assets.model';
import {
	PropertyAssetsDetails,
	PropertyModel,
	RefiPropertyAssetsTransformer
} from '../../models/refi-property-assets.model';
import {
	RefiSavingsAssetsTransformer,
	SavingsAssetsDetails,
	SavingsAssetsModel
} from '../../models/refi-savings-assets.model';

@Injectable({
	providedIn: 'root'
})
export class RefiAssetsService extends BaseJourneyService {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formEnumsQuery: FormEnumsQuery,
		private formDataService: FormDataService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	setupState(): void {
		this.formDataService.setLoading(RefiStore.Assets, true);
		this.fetchAssets();
	}

	fetchAssets(): void {
		forkJoin([this.fetchPropertyAssets(), this.fetchSavings(), this.fetchOtherAssets()]).subscribe(
			([property, savings, otherAssets]) => {
				this.simpFormlyHandlerService.upsertToState(
					'details',
					of([{ property: property, savings: savings, otherAssets: otherAssets }])
				);
			}
		);
	}

	public fetchPropertyAssets(): Observable<PropertyModel[]> {
		return this.getCustom(`Property/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((assets: RefiPropertyAssetsDTO[]) => {
				return assets.map((asset) =>
					RefiPropertyAssetsTransformer.fromPayload(asset, this.applicationDataQuery.getRefiApplicants())
				);
			})
		);
	}

	public getPrimaryProperty(): Observable<RefiPropertyAssetsDTO> {
		return <Observable<RefiPropertyAssetsDTO>>(
			this.getCustom(`Property/GetPrimaryProperty/${this.applicationDataQuery.applicationId()}`)
		);
	}

	public savePropertyAsset(payload: PropertyAssetsDetails): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`Property`,
				RefiPropertyAssetsTransformer.toPayload(
					payload,
					this.applicationDataQuery.applicationId(),
					this.applicationDataQuery.getRefiApplicants()
				)
			)
		);
	}

	public deletePropertyAsset(id: number): Observable<void> {
		return this.delete(`Property/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}

	public fetchOtherAssets(): Observable<OtherAssetsModel[]> {
		return this.getCustom(`OtherAsset/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((assets: RefiOtherAssetsDTO[]) => {
				return assets.map((asset) =>
					RefiOtherAssetsTransformer.fromPayload(
						asset,
						this.applicationDataQuery.getRefiApplicants(),
						this.formEnumsQuery.getOptions('NonRealEstateAssetType')
					)
				);
			})
		);
	}

	public saveOtherAsset(payload: OtherAssetsDetails): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`OtherAsset`,
				RefiOtherAssetsTransformer.toPayload(payload, this.applicationDataQuery.applicationId(), this.formEnumsQuery)
			)
		);
	}

	public deleteOtherAsset(id: number): Observable<void> {
		return this.delete(`OtherAsset/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}

	public fetchSavings(): Observable<SavingsAssetsModel[]> {
		return this.getCustom(`Savings/${this.applicationDataQuery.applicationId()}/`).pipe(
			map((assets: RefiSavingsAssetsDTO[]) => {
				return assets.map((asset) =>
					RefiSavingsAssetsTransformer.fromPayload(
						asset,
						this.applicationDataQuery.getRefiApplicants(),
						this.formEnumsQuery.getOptions('FinancialAssetType')
					)
				);
			})
		);
	}

	public saveSavings(payload: SavingsAssetsDetails): Observable<number> {
		return <Observable<number>>(
			this.postCustom(
				`Savings`,
				RefiSavingsAssetsTransformer.toPayload(payload, this.applicationDataQuery.applicationId(), this.formEnumsQuery)
			)
		);
	}

	public deleteSavingsAsset(id: number): Observable<void> {
		return this.delete(`Savings/${this.applicationDataQuery.applicationId()}/${id}`) as Observable<void>;
	}
}
