import { FormlyButtonInputModel } from '@app/modules/simp-formly/models/formly-button-input.model';

export class BusinessChannelTransformer {
	static fromPayload(dto: BusinessChannelDto): BusinessChannelModel {
		return {
			extract: dto?.companyName,
			businessChannelModal: {
				companyName: dto?.companyName,
				typeId: dto?.typeId,
				abn: dto?.abn,
				licenceTypeId: dto?.licenceTypeId,
				licenceNumber: dto?.licenceNumber,
				otherIdentifier: dto?.otherIdentifier,
				officePhoneNumber: dto?.officePhoneNumber,
				officeFaxNumber: dto?.officeFaxNumber,
				email: dto?.email,
				webAddress: dto?.webAddress,
				addressId: dto?.addressId,
				contactPerson: {
					contactPersonNameTitleId: dto?.contactPersonNameTitleId,
					contactPersonFirstName: dto?.contactPersonFirstName,
					contactPersonSurname: dto?.contactPersonSurname,
					contactPersonRole: dto?.contactPersonRole
				}
			} as BusinessChannel
		} as BusinessChannelModel;
	}
}

export interface BusinessChannelModel extends FormlyButtonInputModel {
	businessChannelModal: BusinessChannel;
}

interface BusinessChannel {
	companyName: string;
	typeId: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: number;
	otherIdentifier: string;
	officePhoneNumber: string;
	officeFaxNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
	contactPerson: {
		contactPersonNameTitleId: number;
		contactPersonFirstName: string;
		contactPersonSurname: string;
		contactPersonRole: string;
	};
}

export interface BusinessChannelDto {
	companyName: string;
	typeId: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: number;
	otherIdentifier: string;
	officePhoneNumber: string;
	officeFaxNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
	contactPersonNameTitleId: number;
	contactPersonFirstName: string;
	contactPersonSurname: string;
	contactPersonRole: string;
}
