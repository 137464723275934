import { Injectable, Injector } from '@angular/core';
import { CompanyApplicantsTransformerService } from '@app/modules/applicants/services/company/company-applicants-transformer.service';
import { TrustApplicantTransformerService } from '@app/modules/applicants/services/trust/trust-applicants-transformer.service';
import { CompanyFinancialPositionTransformerService } from '@app/modules/financial-position/services/company/company-financial-position-transformer.service';
import { TrustFinancialPositionTransformerService } from '@app/modules/financial-position/services/trust/trust-financial-position-transformer.service';
import { RefiJourneyTransformerService } from '@app/modules/refi-journey/services/refi-journey-transformer.service';
import { toJsonSchema } from '@app/modules/simp-formly/helpers/elements/formly-transformer';
import { resolvedFormArray } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyApiProperty } from '@app/modules/simp-formly/helpers/typings/formly-api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { JSONSchema7 } from 'json-schema';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormAreas } from '../typings/form-areas.types';
import { ApplicantsTransformerService } from './applicants-transformer.service';
import { ComplianceGuarantorTransformerService } from './compliance-guarantor-transfomer.service';
import { ComplianceTransformerService } from './compliance-transformer.service';
import { FinancialPositionTransformerService } from './financial-position-transformer.service';
import { LoanServiceabilityTransformerService } from './loan-serviceability-transformer.service';
import { PreCheckTransformerService } from './precheck-transformer.service';
import { ServiceabilityScenariosTransformerService } from './serviceability-scenarios-transformer.service';
import { SetupTransformerService } from './setup-transformer.service';
import { SummaryLodgementTransformerService } from './summary-lodgement-transformer.service';

const injector = Injector.create({ providers: [{ provide: FormlyJsonschema, deps: [] }] });

export const transformSchemaToFormlyFields = (schema: JSONSchema7) => {
	return injector.get(FormlyJsonschema).toFieldConfig(schema, resolvedFormArray);
};

@Injectable({ providedIn: 'root' })
export class AreaTransformerService {
	constructor(
		private setupTransformerService: SetupTransformerService,
		private applicantsTransformerService: ApplicantsTransformerService,
		private companyApplicantsTransformerService: CompanyApplicantsTransformerService,
		private trustApplicantsTransformerService: TrustApplicantTransformerService,
		private companyFinancialPositionTransformerService: CompanyFinancialPositionTransformerService,
		private trustFinancialPositionTransformerService: TrustFinancialPositionTransformerService,
		private financialPositionTransformerService: FinancialPositionTransformerService,
		private preCheckTransformerService: PreCheckTransformerService,
		private complianceTransformerService: ComplianceTransformerService,
		private complianceGuarantorTransformerService: ComplianceGuarantorTransformerService,
		private loanServiceabilityTransformerService: LoanServiceabilityTransformerService,
		private serviceabilityScenariosTransformerService: ServiceabilityScenariosTransformerService,
		private summaryLodgementTransformerService: SummaryLodgementTransformerService,
		private refiJourneyTransformerService: RefiJourneyTransformerService
	) {}
	transformMetadata(
		metadata: FormlyApiProperty | undefined,
		area: FormAreas,
		index = 0
	): Observable<FormlyFieldConfig[] | undefined> {
		if (metadata) {
			const schema = toJsonSchema(metadata) as JSONSchema7;
			const fields = [transformSchemaToFormlyFields(schema)];
			switch (area) {
				case FormAreas.setup:
					return of(this.setupTransformerService.transform(fields));

				case FormAreas.applicants:
					return this.applicantsTransformerService.cloneSchemaForAllApplicants(fields);

				case FormAreas.financialPosition: {
					return this.financialPositionTransformerService
						.updateSchema(fields)
						.pipe(map((updatedFields) => this.financialPositionTransformerService.transform(updatedFields)));
				}

				case FormAreas.preCheck:
					return of(this.preCheckTransformerService.transform(fields));

				case FormAreas.compliance:
					return this.complianceTransformerService.transform(fields);

				case FormAreas.complianceGuarantor:
					return this.complianceGuarantorTransformerService.transform(fields);

				case FormAreas.loanServiceability: {
					return of(this.loanServiceabilityTransformerService.transform(fields));
				}
				case FormAreas.summaryLodgement:
					return this.summaryLodgementTransformerService.transform(fields);
				case FormAreas.companyApplicants: {
					return of(this.companyApplicantsTransformerService.transform(fields, index));
				}
				case FormAreas.companyFinancialPosition: {
					return of(this.companyFinancialPositionTransformerService.transform(fields, index));
				}
				case FormAreas.trustApplicants: {
					return of(this.trustApplicantsTransformerService.transform(fields, index));
				}
				case FormAreas.trustFinancialPosition: {
					return of(this.trustFinancialPositionTransformerService.transform(fields, index));
				}
				case FormAreas.serviceabilityScenarios: {
					return of(this.serviceabilityScenariosTransformerService.transform(fields));
				}
				case FormAreas.refi: {
					return this.refiJourneyTransformerService
						.updateSchema(fields)
						.pipe(map((updatedFields) => this.refiJourneyTransformerService.transform(updatedFields)));
				}
				case FormAreas.purchase: {
					return this.refiJourneyTransformerService
						.updateSchema(fields)
						.pipe(map((updatedFields) => this.refiJourneyTransformerService.transform(updatedFields)));
				}
				default:
					return of(fields);
			}
		}
		return of(undefined);
	}
}
