import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { ExpenseClassificationExpenseClass } from '../../enums/app.enums';
import { FormEnums } from './typings/form-enums';

const createInitialState = (): FormEnums => {
	return {
		Applicants: [],
		AllApplicants: [],
		AllApplicantsNoJoint: [],
		Households: [],
		HouseholdsAndApplicants: [],
		Persons: [],
		PersonApplicants: [],
		RelatedPersons: [],
		PersonApplicantsNoGuarantors: [],
		Companies: [],
		CompanyApplicants: [],
		RelatedCompanies: [],
		OtherCompanies: [],
		RelatedOtherCompanies: [],
		PAYGCompanies: [],
		SelfEmployedCompanies: [],
		ForeignCompanies: [],
		Trusts: [],
		Relations: [],
		RefiInterestType: [],
		Savings: [],
		PersonalLoan: [],
		Saving: [],
		OtherAsset: [],
		OtherLiability: [],
		Liabilities: [],
		Assets: [],
		DiscountCalculationMode: [
			// TODO:: [TAMA5-10205]: Remove this. Temp Fix
			{ id: 1000, label: 'Add discount' },
			{ id: 1001, label: 'Set total' }
		],
		CountryStates: [
			{ id: 1, label: 'New South Wales' },
			{ id: 2, label: 'Queensland' },
			{ id: 3, label: 'Northern Territory' },
			{ id: 4, label: 'Western Australia' },
			{ id: 5, label: 'South Australia' },
			{ id: 6, label: 'Victoria' },
			{ id: 7, label: 'Australian Capital Territory' },
			{ id: 8, label: 'Tasmania' }
		],
		VariableInterestImportantOptions: [
			{ id: 1000, label: 'To benefit from potential future decreases in interest rates' },
			{ id: 1001, label: 'Flexibility to increase repayments and/or early loan repayment' },
			{ id: 1002, label: 'Other' }
		],
		OffSetImportantOptions: [
			{ id: 1000, label: 'Allows paying off the loan sooner' },
			{ id: 1001, label: 'Allows access to funds' },
			{ id: 1002, label: 'For tax purposes (no advice is being given)' },
			{ id: 1003, label: 'Other' }
		],
		RedrawImportantOptions: [
			{ id: 1000, label: 'Flexibility to access prepaid funds if needed' },
			{ id: 1001, label: 'Other' }
		],
		PrincipleAndInterestImportantOptions: [
			{ id: 1000, label: 'Minimise interest paid over the life of the loan' },
			{ id: 1001, label: 'Higher lending limit' },
			{ id: 1002, label: 'Lower deposit required' },
			{ id: 1003, label: 'Build equity from the start' },
			{ id: 1004, label: 'Other' }
		],
		InterestOnlyImportantOptions: [
			{ id: 1000, label: 'Accommodate anticipated non-recurring expense item' },
			{ id: 1001, label: 'Accommodate temporary reduction in income' },
			{ id: 1002, label: 'Maximise cash flow' },
			{ id: 1003, label: 'Minimise cash flow impact during construction' },
			{ id: 1004, label: 'Minimise cash flow impact during bridging period' },
			{ id: 1005, label: 'Plan to convert to investment property' },
			{ id: 1006, label: 'Principal reductions in an offset facility' },
			{ id: 1007, label: 'Recommendation' },
			{ id: 1008, label: 'Taxation or accounting reasons' },
			{ id: 1009, label: 'Variable and unpredictable income' },
			{ id: 1010, label: 'Other' }
		],
		InterestInAdvanceImportantOptions: [
			{ id: 1000, label: 'For tax purposes (no tax advice is being given)' },
			{ id: 1001, label: 'Discounts on interest rate' },
			{ id: 1002, label: 'To assist with cash flow and budgeting' },
			{ id: 1003, label: 'Other' }
		],
		RefinanceExistingLoanOptions: [
			{ id: 1000, label: 'Reduced repayments' },
			{ id: 1001, label: 'Lower interest rate' },
			{ id: 1002, label: 'Greater flexibility / extra or specific loan features under new loan' },
			{ id: 1003, label: 'Wants to reduce number of lenders / consolidate debts' },
			{ id: 1004, label: 'Dissatisfied with other financial institution' },
			{ id: 1005, label: 'Other' }
		],
		InterestOnlyLoanOptions: [
			{ id: 1000, label: 'Accommodate temporary reduction in income' },
			{ id: 1001, label: 'Accommodate anticipated non-recurring expense item' },
			{ id: 1002, label: 'Release funds for investment purposes' },
			{ id: 1003, label: 'Additional funds in an offset facility for wealth creation' },
			{ id: 1004, label: 'Recommendation provided by independent financial advisor' },
			{ id: 1005, label: 'Taxation or accounting reasons' },
			{ id: 1006, label: 'Plan to convert an investment property in future' },
			{ id: 1007, label: 'Other' }
		],
		ExpenseClassificationExpenseClass: [
			{ id: ExpenseClassificationExpenseClass.HomeAndUtilities, label: 'Home and Utilities' },
			{ id: ExpenseClassificationExpenseClass.InsuranceAndSuper, label: 'Insurance and Super' },
			{ id: ExpenseClassificationExpenseClass.Groceries, label: 'Groceries' },
			{ id: ExpenseClassificationExpenseClass.PersonalAndMedical, label: 'Personal & Medical' },
			{ id: ExpenseClassificationExpenseClass.Education, label: 'Education' },
			{ id: ExpenseClassificationExpenseClass.Children, label: 'Children' },
			{ id: ExpenseClassificationExpenseClass.Pets, label: 'Pets' },
			{ id: ExpenseClassificationExpenseClass.RecreationAndEntertainment, label: 'Recreation and Entertainment' },
			{ id: ExpenseClassificationExpenseClass.TransportAndVehicles, label: 'Transport and Vehicles' },
			{ id: ExpenseClassificationExpenseClass.OngoingRent, label: 'Ongoing Rent' }
		]
	};
};

@Injectable({ providedIn: 'root' })
export class FormEnumsStore {
	store = createStore({ name: 'form-enums' }, withProps<FormEnums>(createInitialState()));

	getValue(): FormEnums {
		return this.store.getValue();
	}

	update(partialState: Partial<FormEnums>) {
		this.store.update((state) => ({ ...state, ...partialState } as FormEnums));
	}

	reset(): void {
		this.store.reset();
	}

	select(key: string): Observable<any> {
		return this.store.pipe(select((state) => state[key]));
	}
}

export enum ApplicantType {
	Borrower = 1000,
	BorrowerandGuarantor = 1001,
	Guarantor = 1002
}
