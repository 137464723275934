import { ConstructionDetailsModal } from '@app/modules/loan-serviceability/model/property-asset.model';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { LandAreaUnits, TargetType, VisitContactType, YesNo } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Features, PropertyDTO } from '@app/modules/typings/api';

export interface PropertySecurityDetails {
	id: number;
	applicationId?: number;
	featureId?: number;
	propertyImage?: string;
	zoningType?: string;
	propertyTypeName?: string;
	primaryLandUse?: string;
	landArea?: number;
	floorArea?: string;
	allPropertyFeatures?: string;
	bedrooms?: number;
	bathrooms?: number;
	carSpaces?: number;
	wallMaterial?: string;
	roofMaterial?: string;
	autoConsol?: string;
	volume?: string;
	folio?: string;
	folioID?: string;
	realPropertyDescriptor?: string;
	lotPlan?: string;
	lot?: string;
	isOnMarketTransaction?: YesNo;
	isLegalRepresentation?: YesNo;
	conveyancer?: ApplicantEnumObject;
	isConstruction?: YesNo;
	valuer: ApplicantEnumObject;
	certifiedValue?: number;
	certifiedValuedDate?: number;
	visitContactType?: VisitContactType;
	visitContactName?: ApplicantEnumObject;
	notesForValuer?: string;
	constructionDetails?: ConstructionDetailsModal;
	contractDate?: string;
	financeApprovalDate?: string;
	contractPriceAmount?: number;
	transferOfLandAmount?: number;
	depositPaid?: number;
	licensedRealStateAgentContract?: YesNo;
	contractDetailsArmsLengthTransaction?: YesNo;
}

export class PropertySecurityDetailsModelTransformer {
	static toPayload(
		securityDetails: PropertySecurityDetails,
		applicationId: number,
		propertyDto: PropertyDTO
	): PropertyDTO {
		propertyDto.zoningType = securityDetails.zoningType;
		propertyDto.propertyTypeName = securityDetails.propertyTypeName;
		propertyDto.primaryLandUse = securityDetails.primaryLandUse;
		propertyDto.features = PropertySecurityDetailsModelTransformer.toPayloadFeatures(securityDetails, applicationId);
		propertyDto.autoConsol = securityDetails.autoConsol;
		propertyDto.volume = securityDetails.volume;
		propertyDto.folio = securityDetails.folio;
		propertyDto.titleReference = securityDetails.folioID;
		propertyDto.realPropertyDescriptor = securityDetails.realPropertyDescriptor;
		propertyDto.lotPlan = securityDetails.lotPlan;
		propertyDto.lot = securityDetails.lot;
		propertyDto.onMarketTransaction = getNullableBoolean(securityDetails.isOnMarketTransaction);
		propertyDto.legalRepresentation = getNullableBoolean(securityDetails.isLegalRepresentation);
		propertyDto.conveyancerId = securityDetails.conveyancer?.id;
		propertyDto.isConstruction = getNullableBoolean(securityDetails.isConstruction);
		propertyDto.contractDate = securityDetails.contractDate;
		propertyDto.contractFinanceApprovalDate = securityDetails.financeApprovalDate;
		propertyDto.contractPriceAmount = securityDetails.contractPriceAmount;
		propertyDto.contractTransferOfLandAmount = securityDetails.transferOfLandAmount;
		propertyDto.depositAmount = securityDetails.depositPaid;
		propertyDto.licensedRealStateAgentContract = getNullableBoolean(securityDetails.licensedRealStateAgentContract);
		propertyDto.contractDetailsArmsLengthTransaction = getNullableBoolean(
			securityDetails.contractDetailsArmsLengthTransaction
		);
		propertyDto.certifiedValue = securityDetails.certifiedValue;
		propertyDto.certifiedValueDate = securityDetails.certifiedValuedDate;
		propertyDto.valuerId = securityDetails.valuer?.id;
		propertyDto.visitContactType = securityDetails.visitContactType;
		propertyDto.visitContactPartyId = securityDetails.visitContactName?.id;
		propertyDto.visitContactTargetType = securityDetails.visitContactName?.type;
		propertyDto.visitContactDetails = securityDetails.notesForValuer;

		return propertyDto;
	}

	static fromPayload(propertyDTO: PropertyDTO, formEnumsQuery: FormEnumsQuery): PropertySecurityDetails {
		return {
			id: propertyDTO.id,
			applicationId: propertyDTO.applicationId,
			zoningType: propertyDTO.zoningType,
			propertyTypeName: propertyDTO.propertyTypeName,
			primaryLandUse: propertyDTO.primaryLandUse,
			featureId: propertyDTO.features?.id,
			landArea: propertyDTO.features?.landArea,
			floorArea: propertyDTO.features?.floorArea,
			propertyImage: propertyDTO.features?.propertyImage,
			allPropertyFeatures: propertyDTO.features?.allPropertyFeatures,
			bedrooms: propertyDTO.features?.numberOfBedrooms,
			bathrooms: propertyDTO.features?.numberOfBathrooms,
			carSpaces: propertyDTO.features?.numberOfCarSpaces,
			wallMaterial: propertyDTO.features?.wallMaterial,
			roofMaterial: propertyDTO.features?.roofMaterial,
			autoConsol: propertyDTO.autoConsol,
			volume: propertyDTO.volume,
			folio: propertyDTO.folio,
			folioID: propertyDTO.titleReference,
			realPropertyDescriptor: propertyDTO.realPropertyDescriptor,
			lotPlan: propertyDTO.lotPlan,
			lot: propertyDTO.lot,
			isOnMarketTransaction: getNullableYesNo(propertyDTO.onMarketTransaction),
			isLegalRepresentation: getNullableYesNo(propertyDTO.legalRepresentation),
			conveyancer: PropertySecurityDetailsModelTransformer.getRelatedCompany(formEnumsQuery, propertyDTO.conveyancerId),
			isConstruction: getNullableYesNo(propertyDTO.isConstruction),
			contractDate: propertyDTO.contractDate,
			financeApprovalDate: propertyDTO.contractFinanceApprovalDate,
			contractPriceAmount: propertyDTO.contractPriceAmount,
			transferOfLandAmount: propertyDTO.contractTransferOfLandAmount,
			depositPaid: propertyDTO.depositAmount,
			licensedRealStateAgentContract: getNullableYesNo(propertyDTO.licensedRealStateAgentContract),
			contractDetailsArmsLengthTransaction: getNullableYesNo(propertyDTO.contractDetailsArmsLengthTransaction),
			certifiedValue: propertyDTO.certifiedValue,
			certifiedValuedDate: propertyDTO.certifiedValueDate,
			valuer: PropertySecurityDetailsModelTransformer.getRelatedCompany(formEnumsQuery, propertyDTO.valuerId),
			visitContactName: formEnumsQuery.getExistingPartiesById({
				id: propertyDTO.visitContactPartyId as number,
				type: propertyDTO.visitContactTargetType as TargetType
			}),
			visitContactType: propertyDTO.visitContactType
		} as PropertySecurityDetails;
	}

	private static getRelatedCompany(
		enumsQuery: FormEnumsQuery,
		id?: number,
		type = TargetType.RelatedCompany
	): ApplicantEnumObject | undefined {
		if (!id) {
			return undefined;
		}
		return enumsQuery.getExistingPartiesById({ id, type });
	}

	private static toPayloadFeatures(securityDetails: PropertySecurityDetails, applicationId: number): Features {
		return {
			id: securityDetails.featureId ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			realEstateAssetId: securityDetails.id,
			propertyImage: securityDetails.propertyImage,
			landArea: securityDetails.landArea,
			landAreaUnitsId: LandAreaUnits.SquareMetres,
			floorArea: Number(securityDetails.floorArea),
			allPropertyFeatures: securityDetails.allPropertyFeatures,
			numberOfBedrooms: securityDetails.bedrooms,
			numberOfBathrooms: securityDetails.bathrooms,
			numberOfCarSpaces: securityDetails.carSpaces,
			wallMaterial: securityDetails.wallMaterial,
			roofMaterial: securityDetails.roofMaterial
		} as Features;
	}
}
