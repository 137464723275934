import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StringHelper } from '@app/modules/shared/helper/string-helper';
import {
	FormlyFileUploadModel,
	FormlyUploadedFilesModel
} from '@app/modules/simp-formly/models/formly-file-upload.model';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-file-upload',
	templateUrl: './formly-file-upload.component.html',
	styleUrls: ['./formly-file-upload.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFileUploadComponent extends FieldType<FieldTypeConfig> implements OnInit {
	@ViewChild('fileUploader') public fileUploader!: ElementRef;

	public showSolidBorder = false;
	public acceptedFileTypes = '';
	public listErrors: Array<{ message: string }> = [];
	public listWarnings: Array<{ message: string }> = [];

	constructor() {
		super();
	}

	ngOnInit(): void {
		if (this.to.formatter) {
			this.acceptedFileTypes = String(this.to.formatter)
				.split(',')
				.map((item: string) => '.' + item.trim().toLowerCase())
				.join(',');
		}

		this.checkMinNumberOfUploads(this.getUploadedDocs().length);
	}

	onClick(event: Event): void {
		event.stopPropagation();
		if (this.to.click) {
			this.to.click(this.field);
		}
	}

	onFileChange(file: File): void {
		if (this.to.change) {
			this.to.change(this.field, file);
		}
	}

	public uploadDoc(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		this.fileUploader.nativeElement.click();
	}

	onDragOver(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.showSolidBorder = true;
	}

	onDragLeave(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.showSolidBorder = false;
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.showSolidBorder = false;

		if (!event || !event.dataTransfer) {
			return;
		}

		this.change(event.dataTransfer.files);
	}

	public fileChange(event: any): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		this.change(event.target.files);
	}

	private change(files: FileList) {
		this.listErrors = [];
		this.listWarnings = [];

		const uploadedDocs = this.getUploadedDocs();
		if (this.isMaxNumberOfUploads(uploadedDocs)) {
			return;
		}

		this.checkMinNumberOfUploads(uploadedDocs.length + 1);

		for (let i = 0; i < files.length; i++) {
			const file = files.item(i);
			if (!file) {
				continue;
			}
			this.checkFileNamePattern(file);
			this.checkFileSize(file);
			this.checkAcceptedFileTypes(file);
		}

		if (this.to.change && this.listErrors.length === 0) {
			this.to.change(this.field, files);
		}
	}

	private checkFileNamePattern(file: File) {
		if (this.to.pattern) {
			const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
			const invalidFileName = new RegExp(this.to.pattern).test(fileNameWithoutExtension);
			if (invalidFileName) {
				this.listErrors.push({
					message: `File "${file.name}" cannot be uploaded. Remove characters ${this.to.pattern}`
				});
			}
		}
	}

	private checkFileSize(file: File) {
		if (this.to.max) {
			const fileSizeInMb = file.size / (1024 * 1024);
			if (fileSizeInMb > Number(this.to.max)) {
				this.listErrors.push({
					message: `File "${file.name}" excedded maximum size of ${this.to.max}MB.`
				});
			}
		}
	}

	private checkAcceptedFileTypes(file: File) {
		if (this.to.formatter) {
			const extensions = String(this.to.formatter)
				.split(',')
				.map((item: string) => item.trim().toLowerCase());
			if (!StringHelper.isAcceptedFileExtension(extensions, file.name)) {
				this.listErrors.push({
					message: `File "${file.name}" cannot be uploaded. Files supported: ${this.to.formatter}.`
				});
			}
		}
	}

	private checkMinNumberOfUploads(uploadsLength: number): void {
		if (this.to.minLength) {
			if (uploadsLength < this.to.minLength) {
				this.listWarnings.push({
					message: (this.to.minLengthMessage as string) ?? `Upload at least ${this.to.minLength} file`
				});
			}
		}
	}

	private isMaxNumberOfUploads(uploadedDocs: Array<FormlyFileUploadModel>): boolean {
		if (!this.to.maxLength) {
			return false;
		}

		if (uploadedDocs.length + 1 <= this.to.maxLength) {
			return false;
		}

		this.listErrors.push({
			message: `Maximum number of uploads is ${this.to.maxLength}`
		});

		return true;
	}

	private getUploadedDocs(): Array<FormlyFileUploadModel> {
		const parentModel = this.field.parent?.model as FormlyUploadedFilesModel;
		if (!parentModel) {
			return [];
		}
		const ownPropertyDescriptor = Object.getOwnPropertyDescriptor(parentModel, 'uploadedDocs');
		if (!ownPropertyDescriptor) {
			return [];
		}
		const parentUploadedFiles = ownPropertyDescriptor.value as Array<FormlyFileUploadModel>;
		if (!parentUploadedFiles) {
			return [];
		}

		return parentUploadedFiles;
	}
}
