import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SimpologyComponentModule } from '@simpology/client-components';
import { SharedModule } from '../shared/shared.module';
import { ConfigurableDigitalWidgetComponent } from './components/configurable-digital-widget/configurable-digital-widget.component';
import { InfoDetailsContainerItemComponent } from './components/configurable-digital-widget/info-details-container-item/info-details-container-item.component';
import { DigitalWidgetActionPopupComponent } from './components/digital-widget-action-popup/digital-widget-action-popup.component';
import { DigitalWidgetDocumentListComponent } from './components/digital-widget-document-list/digital-widget-document-list.component';
import { DigitalWidgetItemComponent } from './components/digital-widget-item/digital-widget-item.component';
import { DigitalWidgetLoadingIndicatorComponent } from './components/digital-widget-loading-indicator/digital-widget-loading-indicator.component';
import { DigitalWidgetComponent } from './components/digital-widget.component';
import { DetailsReplacePipe } from './pipe/details-replace.pipe';

@NgModule({
	declarations: [
		ConfigurableDigitalWidgetComponent,
		InfoDetailsContainerItemComponent,
		DigitalWidgetComponent,
		DigitalWidgetActionPopupComponent,
		DigitalWidgetDocumentListComponent,
		DigitalWidgetLoadingIndicatorComponent,
		DigitalWidgetItemComponent,
		DetailsReplacePipe
	],
	imports: [CommonModule, FormsModule, SimpologyComponentModule, SharedModule],
	exports: [
		ConfigurableDigitalWidgetComponent,
		InfoDetailsContainerItemComponent,
		DigitalWidgetComponent,
		DigitalWidgetActionPopupComponent,
		DigitalWidgetDocumentListComponent,
		DigitalWidgetLoadingIndicatorComponent,
		DigitalWidgetItemComponent
	],
	providers: []
})
export class DigitalWidgetsModule {}
