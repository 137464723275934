import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models/fieldconfig';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, of, Subject } from 'rxjs';
import { CONSTANTS } from '../../constants/constants';
import { isNullOrUndefined } from '../../helper/util';
import { FormAreaPath } from '../../typings/form-areas.types';
import { ApplicationDataStore } from '../application-data/application-data.store';
import { SideNavItem } from '../application-data/typings/application-data';
import { FormValidationsStore } from './form-validations.store';
import { RemoteValidationError } from './typings/form-validations';

@Injectable({ providedIn: 'root' })
export class FormValidationsService {
	triggerValidations: Subject<ValidationParams> = new Subject();
	triggerValidations$ = this.triggerValidations.asObservable();

	triggerFieldValidations: Subject<FormlyFieldConfig> = new Subject();
	triggerFieldValidations$: Observable<FormlyFieldConfig> = this.triggerFieldValidations.asObservable();

	constructor(
		private formValidationsStore: FormValidationsStore,
		private confirmationDialogService: SimpConfirmationDialogService,
		private toastr: ToastrService,
		private applicationDataStore: ApplicationDataStore
	) {}

	updateDraft(key: string, isDraft: boolean): void {
		this.formValidationsStore.update((draft) => {
			if (draft[key]) {
				draft[key].draft = isDraft;
			} else {
				draft[key] = { draft: isDraft };
			}
			return draft;
		});
	}

	resetDrafts() {
		this.formValidationsStore.update((draft) => {
			Object.keys(draft).forEach((key) => {
				if (draft[key]) {
					draft[key].draft = undefined;
				}
			});
			return draft;
		});
	}

	resetAllRemoteErrors(): void {
		this.formValidationsStore.update((draft) => {
			Object.keys(draft).forEach((key) => {
				if (draft[key]) {
					draft[key].remoteErrors = [];
				}
			});
			return draft;
		});
	}

	resetRemoteErrors(codes: string[]): void {
		this.formValidationsStore.update((draft) => {
			Object.keys(draft).forEach((key) => {
				if (draft[key] && draft[key].remoteErrors) {
					codes.forEach((code) => {
						const existingErrorIndex = draft[key].remoteErrors?.findIndex((e) => e.code === code);
						if (existingErrorIndex !== undefined && existingErrorIndex >= 0) {
							draft[key].remoteErrors?.splice(existingErrorIndex, 1);
						}
					});
				}
			});
			return draft;
		});
	}

	resetAreaRemoteErrors(area: FormAreaPath | undefined): void {
		const areaKey = area ?? CONSTANTS.NoArea;
		this.formValidationsStore.update((draft) => {
			Object.keys(draft).forEach((key) => {
				if (key.includes(areaKey) && draft[key]) {
					draft[key].remoteErrors = [];
				}
			});
			return draft;
		});
	}

	updateRemoteError(key: string, remoteError: RemoteValidationError): void {
		this.formValidationsStore.update((draft) => {
			if (draft[key]) {
				draft[key].remoteErrors ??= [];
				const existingErrorIndex = draft[key].remoteErrors?.findIndex((e) => e.code === remoteError.code);
				if (existingErrorIndex !== undefined && existingErrorIndex >= 0) {
					draft[key].remoteErrors?.splice(existingErrorIndex, 1, remoteError);
				} else {
					draft[key].remoteErrors?.push(remoteError);
				}
			} else {
				draft[key] = {
					remoteErrors: [remoteError]
				};
			}
			return draft;
		});
	}

	reset(): void {
		this.formValidationsStore.reset();
	}

	triggerValidation(pageChange = false): void {
		this.triggerValidations.next({ pageChange });
	}

	/**
	 * @deprecated Please use triggerFieldValidation instead
	 */
	triggerValidationByKey(key: string): void {
		this.triggerValidations.next({ key });
	}

	/**
	 * @deprecated Please use triggerFieldValidation instead
	 */
	triggerValidationByKeys(keys: string[]): void {
		keys?.forEach((key) => this.triggerValidationByKey(key));
	}

	triggerFieldsValidation(fields: FormlyFieldConfig[]) {
		fields.forEach((field) => {
			if (field.fieldGroup) {
				this.triggerFieldsValidation(field.fieldGroup);
			}

			this.triggerFieldValidation(field);
		});
	}

	triggerFieldValidation(formlyField: FormlyFieldConfig): void {
		this.triggerFieldValidations.next(formlyField);
	}

	confirmUnsaved(areaPath: FormAreaPath, preventContinuingMessage: string | null = null): Observable<boolean> {
		// From setup area, it's required to fill all mandatory fields before leave.
		if (areaPath === FormAreaPath.setup || preventContinuingMessage) {
			this.toastr.warning(
				preventContinuingMessage ?? 'Please fix the highlighted fields',
				preventContinuingMessage ? undefined : 'Oops!!'
			);
			return of(false);
		}

		// From other areas, user can decide to leave without filling all mandatory fields.
		let mainPath = (areaPath as string) ?? '';
		if (areaPath?.includes('/')) {
			mainPath = areaPath.split('/')[0];
		}

		const fullAppSideNavItems: SideNavItem[] = this.applicationDataStore.getValue().sideNavItems.fullApplication;
		const areaLabel = fullAppSideNavItems.find((sideNav) => sideNav.url === mainPath)?.titleText ?? '';
		const message = `If you leave, you will need to return to complete mandatory data in the ${areaLabel} section later.`;

		return this.confirmationDialogService
			.warnToProceed('Leave incomplete section?', message, 'Complete now', 'Leave', 1)
			.pipe(
				// This map is needed since in this confirmation modal, accept and declined button are swapped.
				// When click on confirmation accept button we need to stay in the same page
				// When clicked on confirmation declined button we need to leave the page
				// Therefore had to flip the button selection from here to get the right behavior.
				map((response) => {
					return !isNullOrUndefined(response) ? !response : false;
				})
			);
	}

	confirmErrorOverrideSave(): Observable<boolean> {
		return this.confirmationDialogService.warnToProceed(
			'Allow and continue',
			'Override cannot be undone. Are you sure you want to continue?'
		);
	}
}

export interface ValidationParams {
	pageChange?: boolean;
	key?: string;
}
