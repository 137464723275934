<ng-container *ngIf="to.dataOnly">
	<label class="simp-label" [ngClass]="to.labelClass">{{ $any(data) | async | sanitize }}</label>
</ng-container>

<ng-container *ngIf="!to.dataOnly">
	<div *ngIf="!to?.hideLabel" class="formly-label__label">
		<label class="simp-label" [innerHTML]="to.label | sanitize"></label>
		<simp-info-icon *ngIf="to.tooltip" tooltipHtmlContent="{{ to.tooltip }}" class="simp-margin-left-base">
		</simp-info-icon>
		<simp-info-icon
			*ngIf="to.tooltipAsModal"
			[asModal]="true"
			(modalClick)="tooltipClick(field)"
			class="simp-margin-left-base"
		></simp-info-icon>
	</div>
	<p
		#labelText
		*ngIf="!to?.labelOnly"
		placement="top"
		[ngbTooltip]="getTooltipData() | async | sanitize"
		[disableTooltip]="!isEllipsis"
		[ngClass]="to.labelClass"
		[innerHtml]="$any(data) | async | sanitize"
		class="simp-text simp-text--extra-light simp-text--grey90 truncate-label"
	></p>
</ng-container>
