import { NameTitle, TargetType } from '@app/modules/shared/enums/app.enums';
import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { TrustBeneficiaryDTO } from '@app/modules/typings/api';

export class TrustBeneficiaryTransformer {
	static fromPayload(beneficiary: TrustBeneficiaryDTO, formEnumsQuery: FormEnumsQuery): TrustBeneficiaryModel {
		const title = beneficiary.nameTitleId
			? `${formEnumsQuery.getOptionLabel('NameTitle', beneficiary.nameTitleId)}. `
			: '';
		return {
			id: beneficiary.id,
			existingSelect: formEnumsQuery.getExistingPartiesById({
				type: beneficiary.targetType,
				id: beneficiary.partyId
			}),
			partyName: `${title}${beneficiary.partyName}`,
			type: beneficiary.targetType,
			partyId: beneficiary.partyId
		};
	}

	static toPayload(applicantId: number, beneficiary: TrustBeneficiaryModel): TrustBeneficiaryDTO {
		return {
			id: beneficiary.id ?? 0,
			trustApplicantId: applicantId,
			applicationId: beneficiary.applicationId || 0,
			targetType: beneficiary.existingSelect.type,
			partyId: beneficiary.existingSelect.id
		};
	}
}

export interface TrustBeneficiaryModel {
	id?: number;
	applicationId?: number;
	type: TargetType;
	existingSelect: ApplicantEnumObject;
	partyName: string;
	partyId: number;
	nameTitleId?: NameTitle;
	firstName?: string;
	lastName?: string;
	companyName?: string;
}
