import { Injectable } from '@angular/core';
import { StepResponse } from '@app/modules/typings/api';
import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { StepStatusState } from './typings/step-status';

@Injectable({ providedIn: 'root' })
export class StepStatusStore {
	store = createStore({ name: 'stepStatus' }, withProps<StepStatusState>({} as StepStatusState));

	updateStore(newState: StepStatusState) {
		this.store.update((state: StepStatusState) => newState);
	}

	getStoreValue(): StepStatusState {
		return this.store.getValue();
	}

	getStoreDataAsObservable(): Observable<StepResponse[]> {
		return this.store.pipe(select((stateData) => stateData.data));
	}
}
