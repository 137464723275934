import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { URL_CONSTANTS } from '../../constants/api.constants';

import { ChannelSettingsExpenseMode, ExpensesType } from '../../enums/app.enums';
import { ChannelSettingDTO } from '../../model/channel-setting.model';
import { BaseJourneyService } from '../../service/base-journey.service';
import { ApplicationDataQuery } from '../application-data/application-data.query';
import { ChannelSettingStore } from './channel-setting.store';
import { ChannelSettingState } from './typings/channel-setting';

@Injectable({ providedIn: 'root' })
export class ChannelSettingService extends BaseJourneyService<ChannelSettingDTO> {
	constructor(private channelSettingStore: ChannelSettingStore, private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setLadmRoute(URL_CONSTANTS.SETTINGS);
	}

	fetchChannelSettings(): Observable<ChannelSettingState> {
		const journeyId = this.applicationDataQuery.getApplicationJourneyId();
		const currentStoreValue = this.channelSettingStore.getStoreValue();

		// avoid channel settings call if journey id hasnt changed from last fetch
		if (currentStoreValue.journeyId === journeyId) {
			return of(currentStoreValue);
		}

		return this.get(`${journeyId}`).pipe(
			tap((channelSetting) => {
				this.updateChannelSetting(channelSetting, journeyId);
			}),
			switchMap(() => of(this.channelSettingStore.getStoreValue()))
		);
	}

	private updateChannelSetting(channelSetting: ChannelSettingDTO, journeyId: number): void {
		this.channelSettingStore.updateStore({
			journeyId: journeyId,
			doShowSummaryView: channelSetting.doShowSummaryView,
			isDigitalEditable: channelSetting.isDigitalEditable,
			enableGuarantorInterview: channelSetting.enableGuarantorInterview,
			enableBorrowerInterview: channelSetting.enableBorrowerInterview,
			declaredDigitalThreshold:
				channelSetting.declaredDigitalThreshold == 0 ? 50 : channelSetting.declaredDigitalThreshold,
			allowCompanyApplicants: channelSetting.allowCompanyApplicants ?? false,
			allowTrustApplicants: channelSetting.allowTrustApplicants ?? false,
			expenseType:
				channelSetting.expenseMode === ChannelSettingsExpenseMode.Both
					? ExpensesType.Both
					: channelSetting.expenseMode === ChannelSettingsExpenseMode.Digital
					? ExpensesType.DigitalOnly
					: ExpensesType.DeclaredOnly,
			lockAthenaControls: channelSetting.lockAthenaControls,
			enableDigitalFastTrack: channelSetting.enableDigitalFastTrack,
			enableUIApplicationImport: channelSetting.enableUIApplicationImport,
			enableLoanFees: channelSetting.enableLoanFees,
			allowedFileExtensions: channelSetting.allowedFileExtensions,
			allowedFileExtensionsList: channelSetting.allowedFileExtensionsList,
			skipServiceabilitySubmitWorkflow: channelSetting.skipServiceabilitySubmitWorkflow,
			useRelationshipForSOP: channelSetting.useRelationshipForSOP,
			enableIntercom: channelSetting.enableIntercom ?? false,
			enableDiscount: channelSetting.enableDiscount ?? false,
			allowCustomLoaders: channelSetting.allowCustomLoaders ?? false,
			allowCommissions: channelSetting.allowCommissions ?? false,
			requireApplicationPreCheck: channelSetting.requireApplicationPreCheck,
			enableLmiFingerprint: channelSetting.enableLmiFingerprint,
			financialInstitutionId: channelSetting.financialInstitutionId,
			enableCustomerDetailSearch: channelSetting.enableCustomerDetailSearch
		});
	}
}
