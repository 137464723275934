import { Injectable } from '@angular/core';
import { StepResponse } from '@app/modules/typings/api';
import { Observable, take } from 'rxjs';
import { StepStatusStore } from './step-status.store';

@Injectable({ providedIn: 'root' })
export class StepStatusService {
	constructor(private stepStatusStore: StepStatusStore) {}

	upsertStepStatuses(steps$: Observable<StepResponse[]>): void {
		steps$.pipe(take(1)).subscribe((data) => {
			this.stepStatusStore.updateStore({ data });
		});
	}

	updateStepStatus(stepStatus: StepResponse): void {
		this.stepStatusStore.store.update((state) => {
			const stepToUpdate = state.data?.find((step) => step.type === stepStatus.type);
			if (stepToUpdate) {
				stepToUpdate.status = stepStatus.status;
			}
			return state;
		});
	}
}
