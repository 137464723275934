import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { JourneyType, StepStatus } from '@app/modules/shared/enums/app.enums';
import { saveToLocalStorage } from '@app/modules/shared/helper/local-storage.helper';
import { AddressService } from '@app/modules/shared/service/address.service';
import { AuthenticationService } from '@app/modules/shared/service/authentication.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { ApplicationDataService } from '@app/modules/shared/store/application-data/application-data.service';
import { NavigationStep } from '@app/modules/shared/store/application-data/typings/application-data';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsStore } from '@app/modules/shared/store/form-enums/form-enums.store';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { StepStatusQuery } from '@app/modules/shared/store/step-status/step-status.query';
import { getFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { ApplicationSyncResponseDTO, BaseResponseDTO, PersonApplicantDTO } from '@app/modules/typings/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpProgressBarStatus } from '@simpology/client-components';
import { get } from 'lodash-es';
import { BehaviorSubject, Observable, ReplaySubject, catchError, forkJoin, map, of, retry, switchMap, tap } from 'rxjs';
import { RefiApplicationSummaryService } from '../components/refi-form/application-summary/application-summary.service';
import { RefiStepToPath, RefiStepType } from '../enums/refi-steps.enum';
import { RefiStore } from '../enums/refi-store.enum';
import { ApplicationByLenderApplicationNumberResponse } from '../models/application-by-lender-application-number-response.model';
import { RefiLiabilitiesModel } from '../models/refi-liabilities.model';
import { RefiAssetsService } from './assets/refi-assets.service';
import { RefiChooseLoanService } from './choose-loan/refi-choose-loan.service';
import { RefiConfirmDetailsService } from './confirm-details/refi-confirm-details.service';
import { RefiEligibilityService } from './eligibility/refi-eligibility.service';
import { RefiExpensesService } from './expenses/refi-expenses.service';
import { RefiIncomeService } from './income/refi-income.service';
import { RefiLiabilitiesService } from './liabilities/refi-liabilities.service';
import { RefiLoanDetailsService } from './loan-details/refi-loan-details.service';
import { RefiJourneyLoanRequirementService } from './loan-requirements/refi-loan-requirements.service';
import { RefiProductSelectionApiService } from './product-selection/refi-product-selection-api.service';
import { RefiPropertyDetailsService } from './property-details/refi-property-details.service';
import { RefiReviewService } from './review/refi-review.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyService extends BaseJourneyService {
	navigationStepsLoaded$: BehaviorSubject<NavigationStep[] | null> = new BehaviorSubject<NavigationStep[] | null>(null);
	customerInfoLoaded$ = new ReplaySubject<BaseResponseDTO>();
	isAdminUser = false;
	FEAInfoLoaded$ = new BehaviorSubject<boolean | null>(null);

	constructor(
		private chooseLoanService: RefiChooseLoanService,
		private journeyStepService: JourneyStepService,
		private loanDetailsService: RefiLoanDetailsService,
		private propertyDetailsService: RefiPropertyDetailsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private formDataService: FormDataService,
		private applicationDataService: ApplicationDataService,
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsStore: FormEnumsStore,
		private refiProductSelectionApiService: RefiProductSelectionApiService,
		private assetsService: RefiAssetsService,
		private incomeService: RefiIncomeService,
		private loanRequirementService: RefiJourneyLoanRequirementService,
		private expensesService: RefiExpensesService,
		private liabilitiesService: RefiLiabilitiesService,
		private reviewService: RefiReviewService,
		private eligibilityService: RefiEligibilityService,
		private addressService: AddressService,
		private stepStatusQuery: StepStatusQuery,
		private confirmationService: RefiConfirmDetailsService,
		private applicationSummaryService: RefiApplicationSummaryService,
		private authenticationService: AuthenticationService,
		private sharedFlagService: SharedFlagsService,
		private refiModalService: RefiModalService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	addStepsAndNavigate(): Observable<any> {
		const applicants = this.applicationDataQuery.getRefiApplicants();
		const applicant = applicants.find((app) => app.isCurrentUser);

		return this.addStrategicFlowSteps(false).pipe(
			switchMap(() => {
				const currentStepStatus = this.applicationDataQuery.getAllNavigationSteps();
				const statusToMark =
					currentStepStatus.find((step) => step.stepType === RefiStepType.RefiLoanRequirements)?.stepStatus ===
					SimpProgressBarStatus.Complete
						? StepStatus.Complete
						: StepStatus.Incomplete;

				return forkJoin([
					this.journeyStepService.updateStep(RefiStepType.RefiChooseLoan, StepStatus.Complete), // Choose loans should always be complete - pipeline apps will just update loan details
					this.journeyStepService.updateStep(RefiStepType.RefiLoanDetails, statusToMark),
					this.journeyStepService.updateStep(RefiStepType.RefiPropertyDetails, statusToMark)
				]);
			}),
			tap(() => {
				if (applicant && !applicant.primaryApplicant) {
					this.navigateForCoborrower(applicant);
				} else {
					this.navigateToStep('summary/details');
				}
			})
		);
	}

	getLadmApplicationId(appId?: string): Observable<ApplicationByLenderApplicationNumberResponse> {
		if (appId) {
			return this.getCustom(`/Application/${appId}`).pipe(
				tap((response: ApplicationByLenderApplicationNumberResponse) => {
					this.applicationDataService.updateLoanApplication({
						applicationId: response.applicationId,
						journeyId: response.journeyId,
						readyForPreload: true
					});
				}),
				catchError(() => {
					return this.createApplication();
				})
			);
		} else if (this.applicationDataQuery.applicationId() === CONSTANTS.NEW_ID) {
			return this.createApplication();
		} else {
			this.applicationDataService.updateLoanApplication(
				{
					readyForPreload: true
				},
				false
			);
			return of({ applicationId: this.applicationDataQuery.applicationId(), authorised: true });
		}
	}

	goToMostRecentStep(steps: NavigationStep[]): void {
		const applicants = this.applicationDataQuery.getRefiApplicants();
		if (
			applicants.length > 1 &&
			applicants.find((app) => !app.firstName) &&
			applicants.find((app) => app.isCurrentUser && app.primaryApplicant) &&
			steps.find((step) => step.stepType === RefiStepType.RefiLoanDetails)?.stepStatus ===
				SimpProgressBarStatus.Complete
		) {
			void this.router.navigateByUrl(`/${this.getRefiJourneyPath()}/secondBorrower/details`);
			return;
		}
		const requestedStep =
			steps.find((route) => this.router.url.includes(route.stepUrl || 'eligibility'))?.stepType ||
			RefiStepType.RefiEligibility;
		this.applicationSummaryService.renewLock().subscribe((lockRenewed: boolean) => {
			if (requestedStep && lockRenewed) {
				this.goToFirstIncompleteStep(requestedStep);
			} else {
				void this.router.navigateByUrl(`/${this.getRefiJourneyPath()}/summary/details`);
			}
		});
	}

	goToFirstIncompleteStep(requestedStep: number): void {
		if (this.journeyStepService.getParentIndex(requestedStep) < 1) {
			const firstIncompleteStep = this.getFirstIncompleteStep();
			if (firstIncompleteStep) {
				void this.router.navigateByUrl(`/${this.getRefiJourneyPath()}/${RefiStepToPath.get(firstIncompleteStep)}`);
			} else {
				this.displayErrorScreen('Unable to get first incomplete step');
			}
		}
	}

	navigateForCoborrower(applicant: PersonApplicantDTO) {
		forkJoin([
			this.confirmationService.isApplicantDetailConfirmed(applicant.id),
			this.confirmationService.isPrivacyConsented(applicant.id)
		]).subscribe(([detailsConfirmed, privacyConsented]) => {
			if (privacyConsented) {
				void this.router.navigateByUrl(`/${this.getRefiJourneyPath()}/summary/details`);
			} else if (detailsConfirmed) {
				void this.router.navigateByUrl(`/${this.getRefiJourneyPath()}/confirmDetails/consent`);
			} else {
				this.getCustomerInformation().subscribe((res) => {
					this.customerInfoLoaded$.next(res);
				});
				void this.router.navigateByUrl(
					`/${this.getRefiJourneyPath()}/${RefiStepToPath.get(RefiStepType.RefiConfirmDetails)}`
				);
			}
		});
	}

	fetchJourneyData(sectionId: string, subSectionId: string) {
		switch (sectionId) {
			case 'eligibility':
				break;
			case 'chooseLoan':
				this.chooseLoanService.setupState();
				break;
			case 'confirmDetails':
				this.formDataService.setLoading(RefiStore.ConfirmDetails, true);
				this.confirmationService.setupState(subSectionId);
				break;
			case 'loanDetails':
				this.loanDetailsService.setupState();
				break;
			case 'propertyDetails':
				this.propertyDetailsService.setupState();
				break;
			case 'summary':
				this.refiModalService.closeOpenModals();
				this.applicationSummaryService.setupState();
				break;
			case 'loanRequirements':
				this.loanRequirementService.setupState();
				break;
			case 'income':
				this.incomeService.setupState();
				break;
			case 'assets':
				this.assetsService.setupState();
				break;
			case 'liabilities':
				this.liabilitiesService.setupState();
				break;
			case 'expenses':
				this.expensesService.setupState();
				break;
			case 'products':
				this.loanRequirementService.setupState();
				this.simpFormlyHandlerService.upsertToState(subSectionId, this.refiProductSelectionApiService.getProducts());
				break;
			case 'review':
				this.appSyncAndUpdateReviewState();
				break;
			default:
				this.simpFormlyHandlerService.upsertToState(subSectionId, of([{}]));
		}
	}

	generateStepForm(fields: FormlyFieldConfig[] | undefined, param: Params) {
		const fieldGroup = get(fields, '[0].fieldArray.fieldGroup', []) as FormlyFieldConfig[];
		const sectionId = get(param, 'sectionId', get(fieldGroup, '[0].key')) as string;
		const sectionIndex = fieldGroup?.findIndex((field) => field.key === sectionId) || 0;
		const subSectionId = get(param, 'subSectionId', get(fieldGroup, `[${sectionIndex}].fieldGroup[0].key`)) as string;
		const mobileStepsFields = getFormField(fields, `${sectionId}.${subSectionId}`) as FormlyFieldConfig;

		if (sectionIndex === -1 || mobileStepsFields === undefined) {
			void this.router.navigateByUrl(CONSTANTS.INVALID_URL);
			return;
		}

		if (!this.applicationDataQuery.hasNavigationSteps()) {
			this.upsertNavigationSteps(fields);
		}
		if (this.applicationDataQuery.hasNavigationSteps()) {
			this.applicationDataService.updateNavigationActiveStep(sectionIndex);
		}

		if (this.applicationDataQuery.applicationId() !== CONSTANTS.NEW_ID && sectionId !== 'loadingPage') {
			this.addressService.fetchAllAddress(() => {
				this.fetchJourneyData(sectionId, subSectionId);
			});
		}

		return [mobileStepsFields];
	}

	checkEligibility(): Observable<boolean> {
		return of(true);
	}

	navigateToNextStep(field: FormlyFieldConfig): void {
		const nextRoute = field?.templateOptions?.nextRoute as string;
		if (nextRoute) {
			this.navigateToStep(nextRoute);
		}
	}

	navigateToStep(nextRoute: string): void {
		switch (this.sharedFlagService.currentJourney) {
			case JourneyType.Purchase:
				void this.router.navigateByUrl(`${CONSTANTS.PURCHASE_PATH}/${nextRoute}`);
				break;
			case JourneyType.Refi:
				void this.router.navigateByUrl(`${CONSTANTS.REFI_PATH}/${nextRoute}`);
				break;
			default:
				break;
		}
	}

	getApplicants(): Observable<PersonApplicantDTO[]> {
		return this.getCustom(`/PersonApplicant/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((applicants: PersonApplicantDTO[]) => {
				this.formDataService.upsertStateWithAsyncData('applicants', of(applicants));
				const allApplicants = applicants.map((applicant) => ({
					id: applicant.id,
					label: `${applicant.firstName} ${applicant.lastName}`
				}));
				this.formEnumsStore.update({ AllApplicantsNoJoint: [...allApplicants] });
				allApplicants.length > 1 ? allApplicants.push({ id: 1, label: 'Both of us' }) : null;
				this.formEnumsStore.update({ AllApplicants: allApplicants });
			}),
			catchError(() => of([] as PersonApplicantDTO[]))
		);
	}

	getCustomerInformation(): Observable<BaseResponseDTO> {
		return <Observable<BaseResponseDTO>>(
			this.patch(`Application/CustomerInformation/${this.applicationDataQuery.applicationId()}`, {})
		);
	}

	appSync(): Observable<any> {
		return this.isAdminUser
			? of(null)
			: this.patch(`/Application/AppSync/${this.applicationDataQuery.applicationId()}`, {});
	}

	appSyncAsync(): Observable<any> {
		return this.isAdminUser
			? of(null)
			: this.patch(`/Application/AppSyncAsynchronous/${this.applicationDataQuery.applicationId()}`, {});
	}

	hasPreviousAppSyncCompletedSuccessfully(): Observable<ApplicationSyncResponseDTO> {
		if (this.isAdminUser) {
			return of({ status: true, timeout: false } as ApplicationSyncResponseDTO);
		}

		return this.getCustom(`/Application/AwaitAppSyncResponse/${this.applicationDataQuery.applicationId()}`).pipe(
			tap((response: ApplicationSyncResponseDTO) => {
				if (response.timeout) {
					throw new Error('timeout');
				}
			}),
			map((response: ApplicationSyncResponseDTO) => response),
			tap(() => {
				this.FEAInfoLoaded$.next(true);
			}),
			retry(2),
			catchError(() => {
				return of({ status: false } as ApplicationSyncResponseDTO);
			})
		);
	}

	//initiate => awaitFEA => appSyncAsync => awaitAppSync
	initiateFEA(applicantId: number): Observable<ApplicationSyncResponseDTO> {
		return this.patch(
			`/Application/DataEnrichment/${this.applicationDataQuery.applicationId()}/${applicantId}`,
			null
		).pipe(switchMap(() => this.hasFEACompletedSuccessfully()));
	}

	hasFEACompletedSuccessfully(): Observable<ApplicationSyncResponseDTO> {
		if (this.isAdminUser) {
			return of({ status: true, timeout: false } as ApplicationSyncResponseDTO);
		}

		if (this.FEAInfoLoaded$.getValue() === null) {
			this.FEAInfoLoaded$.next(false);
		}

		return this.getCustom(`/Application/AwaitDataEnrichmentResponse/${this.applicationDataQuery.applicationId()}`).pipe(
			switchMap(() => this.appSyncAsync()),
			switchMap(() => this.hasPreviousAppSyncCompletedSuccessfully())
		);
	}

	displayErrorScreen(message?: string): void {
		this.refiModalService.closeOpenModals();
		if (message) {
			console.error(message);
		}
		void this.router.navigateByUrl(`${this.getRefiJourneyPath()}/error`);
	}

	displayIncorrectDetailsScreen(): void {
		void this.router.navigateByUrl(`${this.getRefiJourneyPath()}/error/incorrect-details`);
	}

	logOut(discardApplication = false, currentJourney?: JourneyType): void {
		saveToLocalStorage(CONSTANTS.REFI_LOGOUT_DISCARD_APPLICATION, String(discardApplication));
		this.authenticationService.logOut(
			(this.sharedFlagService.currentJourney ?? currentJourney) === JourneyType.Refi
				? CONSTANTS.REFI_LOGOUT_PATH
				: CONSTANTS.PURCHASE_LOGOUT_PATH
		);
	}

	getRefiJourneyPath(): string {
		switch (this.sharedFlagService.currentJourney) {
			case JourneyType.Purchase:
				return CONSTANTS.PURCHASE_PATH;
			case JourneyType.Refi:
				return CONSTANTS.REFI_PATH;
			default:
				throw 'Invalid journey';
		}
	}

	deleteApplication(): Observable<void> {
		return this.delete(`Application/${this.applicationDataQuery.applicationId()}`) as Observable<void>;
	}

	private addStrategicFlowSteps(setAsComplete: boolean) {
		return this.patch(`Application/AddSteps/${this.applicationDataQuery.applicationId()}/${setAsComplete}`, {});
	}

	private appSyncAndUpdateReviewState(): void {
		if (this.stepStatusQuery.isCompleted(RefiStepType.RefiUploadDocuments)) {
			this.reviewService.setupState();
		} else {
			this.formDataService.upsertStateWithAsyncData(RefiStore.Review, of([]));
			this.formDataService.setLoading(RefiStore.Review, true);

			forkJoin([
				this.appSyncAsync().pipe(switchMap(() => this.hasPreviousAppSyncCompletedSuccessfully())),
				this.liabilitiesService.withinMaximumBorrowingCapacity(),
				this.getApplicants()
			]).subscribe({
				next: ([appSyncResult, mbcResult]) => {
					if (appSyncResult.status && mbcResult.status) {
						if (mbcResult.withinMbc) {
							this.reviewService.setupState();
						} else {
							this.navigateToStep('serviceability/result');
						}
					} else {
						this.displayErrorScreen('Appsync / MBC returned false');
					}
				},
				error: () => this.displayErrorScreen('Appsync / MBC network error')
			});
		}
	}

	private getFirstIncompleteStep(): RefiStepType | undefined {
		const stepOrder = this.applicationDataQuery.getAllNavigationSteps();

		const hasHomeLoans = (
			this.simpFormlyHandlerService.getStateDataFullPath(RefiStore.Liabilities)?.[0] as RefiLiabilitiesModel
		)?.homeLoans?.length; // Need to remove choose loan step if no loans exist
		return stepOrder
			.filter((step) => hasHomeLoans || step.stepType !== RefiStepType.RefiChooseLoan)
			.find(
				(step) =>
					RefiStepToPath.get(step.stepType as RefiStepType) &&
					step.stepType &&
					step?.stepStatus !== SimpProgressBarStatus.Complete
			)?.stepType;
	}

	private upsertNavigationSteps(fields: FormlyFieldConfig[] | undefined) {
		const fieldGroup = get(fields, '[0].fieldArray.fieldGroup', []) as FormlyFieldConfig[];
		let indexCount = 0;
		const stepStatus = this.stepStatusQuery.stepStatuses();
		if (stepStatus) {
			const steps = fieldGroup
				.filter((x) => x.type === 'section')
				.map((property) => {
					return {
						stepName: property.templateOptions?.label,
						stepUrl: `${property.key}`,
						stepId: property.templateOptions?.steps ? (indexCount += 1) : undefined,
						stepStatus: this.progressBarStatus(property),
						active: false,
						stepType: property.fieldGroup?.find((x) => x.type === 'mobile-step')?.templateOptions?.stepType as number
					} as NavigationStep;
				});
			this.applicationDataService.updateNavigationSteps(steps);
			this.navigationStepsLoaded$.next(steps);
		}
	}

	private progressBarStatus(property: FormlyFieldConfig): SimpProgressBarStatus {
		const stepStatus = this.stepStatusQuery.stepStatuses();
		const stepType = property.fieldGroup?.find((x) => x.type === 'mobile-step')?.templateOptions?.stepType as number;
		return stepStatus.find((x) => x.type === stepType)?.status === 2
			? SimpProgressBarStatus.Complete
			: SimpProgressBarStatus.NotStarted;
	}

	private createApplication(): Observable<ApplicationByLenderApplicationNumberResponse> {
		return this.post(`Application`, {}).pipe(
			map((res: RefiApplicationCreateResponse) => {
				this.applicationDataService.updateLoanApplication({
					applicationId: res.applicationId,
					journeyId: res.journeyId,
					readyForPreload: true
				});
				return {
					applicationId: res.applicationId,
					authorised: true
				};
			})
		);
	}
}

interface RefiApplicationCreateResponse {
	applicationId: number;
	journeyId: number;
	journeyName: string;
}
