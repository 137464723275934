import { Importance, VariableInterestImportantOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { LoanPreferenceVariableInterestDTO } from '@app/modules/typings/api';

export class VariableInterestTransformer {
	static toPayload(applicationId: number, details: VariableInterestModel): LoanPreferenceVariableInterestDTO {
		const options = details.variableExtras?.variableOptions.options ?? [];

		return {
			applicationId: applicationId,
			importance: details.variableRateImportance,
			risksExplained: details.variableRateRisksExplained,
			reasonDescription: details.variableExtras?.variableOptions?.otherReasonInput,
			reasonFlexibility: options?.includes(VariableInterestImportantOptions.ReasonFlexibility),
			reasonPotentialRateDecreases: options?.includes(VariableInterestImportantOptions.ReasonPotentialRateDecreases),
			reasonOther: !!details.variableExtras?.variableOptions?.otherReasonInput
		};
	}
	static fromPayload(data: LoanPreferenceVariableInterestDTO): VariableInterestModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);

		const details: VariableInterestModel = {
			variableExtras: {
				variableOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				}
			},
			variableRateRisksExplained: data.risksExplained!,
			variableRateImportance: data.importance
		};

		return details;
	}

	private static getSelectedIds(data: LoanPreferenceVariableInterestDTO, selectedIds: number[]) {
		if (data.reasonFlexibility) {
			selectedIds.push(VariableInterestImportantOptions.ReasonFlexibility);
		}
		if (data.reasonPotentialRateDecreases) {
			selectedIds.push(VariableInterestImportantOptions.ReasonPotentialRateDecreases);
		}
	}
}

export interface VariableInterestModel {
	variableRateImportance?: Importance;
	variableRateRisksExplained: boolean;
	variableExtras: {
		variableOptions: MultiCheckboxModel;
	};
}
export interface VariableInterestStoreModel {
	variableRateContainer: VariableInterestModel;
}
