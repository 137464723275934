import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { ENV_CONFIG } from '@app/env-config';
import { environment } from '@environments/environment';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
fetch('./assets/configs/env.config.json')
	.then((response) => response.json())
	.then((config) => {
		Object.assign(ENV_CONFIG, config);
		if (environment.local) {
			devTools({ name: 'Simpology Store' });
		} else {
			enableProdMode();
			enableElfProdMode();
		}

		platformBrowserDynamic([])
			.bootstrapModule(AppModule)
			.catch((err) => console.error(err));
	});
