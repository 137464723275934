/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { DetailedComment, DetailedCommentTransformer } from '@app/modules/summary-lodgement/models/comment.model';
import { DetailedCommentDTO } from '@app/modules/summary-lodgement/typings/summary-lodgement';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, forkJoin, interval, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ApplicantsService } from '../applicants/services/applicants.service';
import { CompanyApplicantsService } from '../applicants/services/company/company-applicants.service';
import { TrustApplicantsService } from '../applicants/services/trust/trust-applicants.service';
import { FinancialPositionService } from '../financial-position/financial-position.service';
import { CompanyFinancialPositionService } from '../financial-position/services/company/company-financial-position.service';
import { TrustFinancialPositionService } from '../financial-position/services/trust/trust-financial-position.service';
import { LoanServiceabilityService } from '../loan-serviceability/loan-serviceability.service';
import { SetupService } from '../setup/services/setup.service';
import { ApplicationDetails } from '../setup/typings/setup';
import { URL_CONSTANTS } from '../shared/constants/api.constants';
import { CONSTANTS } from '../shared/constants/constants';
import { CommentLinkCommentType } from '../shared/enums/app.enums';
import { CustomError } from '../shared/model/custom-error.model';
import { LoanApplicationDetail } from '../shared/model/loan-application.model';
import { OverriddenRule } from '../shared/model/policy-rule.model';
import { ApplicationService } from '../shared/service/application.service';
import { BaseJourneyService } from '../shared/service/base-journey.service';
import { LoanappRuleService } from '../shared/service/loanapp-rule.service';
import { PersonsCompaniesEnumService } from '../shared/service/persons-companies-enum.service';
import { RemoteValidationService } from '../shared/service/remote-validation.service';
import { ApplicationDataQuery } from '../shared/store/application-data/application-data.query';
import { EventAction, EventTarget, EventsRepository } from '../shared/store/events/events.repository';
import { SimpFormlyHandlerService } from '../simp-formly/services/simp-formly-handler.service';
import {
	ApplicationSectionSummary,
	ApplicationSummaryTransformer,
	FullApplicationSummary
} from './models/application-summary.model';
import { CommentsService } from './services/comments.service';
import {
	ApplicationProgress,
	ApplicationSubmission,
	LodgementDTO,
	LodgementResponseDTO
} from './typings/summary-lodgement';

const initialState = {
	lodgement: {
		applicationComment: [{} as DetailedComment],
		applicationReview: [{}],
		applicationProgress: [{} as ApplicationProgress],
		applicationSubmission: [{} as ApplicationSubmission],
		applicationReady: [{}],
		overriddenRules: [] as OverriddenRule[]
	}
};

@Injectable({ providedIn: 'root' })
export class SummaryLodgementService extends BaseJourneyService<any> {
	public loaderText = new BehaviorSubject('');
	public hasCustomerSignedAppication = new BehaviorSubject<boolean | undefined>(undefined);
	private summaryLoadingMessages = [
		'Checking application data',
		'Checking serviceability',
		'Validating application',
		'Running rules',
		'Almost there'
	];
	private count = 0;

	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationService: ApplicationService,
		private remoteValidationService: RemoteValidationService,
		private setupService: SetupService,
		private applicantsService: ApplicantsService,
		private companyApplicantsService: CompanyApplicantsService,
		private trustApplicantService: TrustApplicantsService,
		private loanServiceabilityService: LoanServiceabilityService,
		private companyFinancialPositionService: CompanyFinancialPositionService,
		private trustFinancialPositionService: TrustFinancialPositionService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private financialPositionService: FinancialPositionService,
		private commentsService: CommentsService,
		private eventsRepository: EventsRepository,
		private loanAppRuleService: LoanappRuleService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.LODGEMENT);
	}

	get loaderText$() {
		return this.loaderText.asObservable();
	}

	getHasCustomerSignedApplication() {
		return this.hasCustomerSignedAppication.asObservable();
	}

	initiateLoaderText() {
		this.count = 0;
		this.loaderText.next(this.summaryLoadingMessages[this.count]);
		this.count = 0;
		interval(1500)
			.pipe(takeUntil(this.loaderText$.pipe(map((text) => !text))))
			.subscribe(() => {
				this.loaderText.next(this.summaryLoadingMessages[this.count] + '...');
				if (this.count !== 4) {
					this.count++;
				}
			});
	}

	fetchSummaryData() {
		const hasPersonApplicant = this.applicationDataQuery.getPersonShortApplicants().length > 0;
		const hasCompanyApplicant = this.applicationDataQuery.getCompanyApplicants().length > 0;
		const hasTrustApplicant = this.applicationDataQuery.getTrustApplicants().length > 0;

		this.personsCompaniesEnumService.getCompanies();
		this.personsCompaniesEnumService.fetchPersons().subscribe();

		return forkJoin({
			applicationSummarySection: of({} as ApplicationSectionSummary),
			setupSummary: this.setupService.getSummaryData(),
			applicantsSummary: forkJoin({
				personApplicant: hasPersonApplicant ? this.applicantsService.getSummaryData() : of({}),
				companyApplicant: hasCompanyApplicant ? this.companyApplicantsService.getSummaryData() : of({}),
				trustApplicant: hasTrustApplicant ? this.trustApplicantService.getSummaryData() : of({})
			}).pipe(
				map((applicants) =>
					Object.assign({}, applicants.personApplicant, applicants.companyApplicant, applicants.trustApplicant)
				)
			),
			financialPositionSummary: forkJoin({
				personApplicant: hasPersonApplicant ? this.financialPositionService.getSummaryData() : of({}),
				companyApplicant: hasCompanyApplicant ? this.companyFinancialPositionService.getSummaryData() : of({}),
				trustApplicant: hasTrustApplicant ? this.trustFinancialPositionService.getSummaryData() : of({})
			}).pipe(
				map((applicants) =>
					Object.assign({}, applicants.personApplicant, applicants.companyApplicant, applicants.trustApplicant)
				)
			),
			loanAndServiceabilitySummary: this.loanServiceabilityService.getSummaryData()
		}).pipe(
			map((fullApplicationSummary: FullApplicationSummary) => {
				const applicationSummaryModel =
					ApplicationSummaryTransformer.populateApplicationSummaryModel(fullApplicationSummary);

				fullApplicationSummary.applicationSummarySection = {
					applicationSummary: [applicationSummaryModel]
				};
				return fullApplicationSummary;
			}),
			tap(() => {
				// add delay so that form model can be updated while showing the spinner
				setTimeout(() => {
					this.loaderText.next('');
				}, 200);
			})
		);
	}

	setupLodgementModel(): void {
		const emptyObject = [{} as unknown];
		this.simpFormlyHandlerService.deleteFromState('applicationComment', 0);
		this.simpFormlyHandlerService.deleteFromState('applicationProgress', 0);
		this.simpFormlyHandlerService.deleteFromState('applicationSubmission', 0);
		this.simpFormlyHandlerService.deleteFromState('applicationReview', 0);
		this.simpFormlyHandlerService.deleteFromState('applicationReady', 0);
		forkJoin([this.getLodgement(), this.fetchComment()]).subscribe(
			([lodgementDetails, comment]: [LodgementDTO, DetailedCommentDTO | undefined]) => {
				const summaryLodgementModel = cloneDeep(initialState);

				if (comment) {
					summaryLodgementModel.lodgement.applicationComment[0] = DetailedCommentTransformer.fromPayload(comment);
				}
				summaryLodgementModel.lodgement.applicationProgress[0] = {
					applicationSetupDate: lodgementDetails.setupDate,
					documentUploadStartDate: lodgementDetails.firstDocumentUploadDate,
					latestDocumentUploadDate: lodgementDetails.lastDocumentUploadDate
				};
				summaryLodgementModel.lodgement.applicationSubmission = lodgementDetails.loanSelectedProducts.map((product) => {
					return {
						productName: product.productName,
						includeInSubmission: true
					} as ApplicationSubmission;
				});
				this.simpFormlyHandlerService.upsertToStateWithData(
					'applicationComment',
					summaryLodgementModel.lodgement.applicationComment
				);
				this.simpFormlyHandlerService.upsertToStateWithData(
					'applicationProgress',
					summaryLodgementModel.lodgement.applicationProgress
				);
				this.simpFormlyHandlerService.upsertToStateWithData(
					'applicationSubmission',
					summaryLodgementModel.lodgement.applicationSubmission
				);
			},
			(err) => {
				if (err) {
					const customError = JSON.parse(err) as CustomError;
					if (customError && customError.status === CONSTANTS.NotFoundCode) {
						this.simpFormlyHandlerService.upsertToStateWithData('applicationComment', [{}]);
						this.simpFormlyHandlerService.upsertToStateWithData('applicationProgress', [{}]);
						this.simpFormlyHandlerService.upsertToStateWithData('applicationSubmission', [{}]);
						return;
					}
				}
			},
			() => {
				this.simpFormlyHandlerService.upsertToStateWithData('applicationReview', emptyObject);
				const applicationDetails =
					this.simpFormlyHandlerService.getStateDataFullPath<ApplicationDetails>('details')?.[0];
				this.simpFormlyHandlerService.upsertToStateWithData('applicationReady', [
					{ customerSignedApplication: applicationDetails.hasLenderAcknowledgedSignedDocument }
				]);
				// add delay so that form model can be updated while showing the spinner
				setTimeout(() => {
					this.loaderText.next('');
				}, 200);
			}
		);
	}

	lodge(isForceLodgement: boolean): Observable<boolean> {
		return this.postCustom(`${this.applicationDataQuery.applicationId()}/${isForceLodgement}`, {}).pipe(
			tap((response: LodgementResponseDTO) => {
				const errors = response.errorDetails ?? [];
				this.remoteValidationService.resetAndStoreAllErrors(errors);
				if (response.success) {
					this.eventsRepository.dispatchEvent({ targetType: EventTarget.Application, action: EventAction.Submitted });
				}
			}),
			map((response: LodgementResponseDTO) => response.success)
		);
	}

	preLodgeCheck(): Observable<boolean> {
		return this.postCustom(`PreLodgeCheck/${this.applicationDataQuery.applicationId()}`, {}).pipe(
			tap((response: LodgementResponseDTO) => {
				const errors = response.errorDetails ?? [];
				this.remoteValidationService.resetAndStoreAllErrors(errors);
			}),
			map((response: LodgementResponseDTO) => response.success)
		);
	}

	generateSupDocs(isRegenerate: boolean): Observable<boolean> {
		return this.postCustom(
			`GenerateSupDocs/${this.applicationDataQuery.applicationId()}?isRegenerate=${isRegenerate}`,
			{}
		).pipe(
			tap((response: LodgementResponseDTO) => {
				const errors = response.errorDetails ?? [];
				this.remoteValidationService.resetAndStoreAllErrors(errors);
			}),
			map((response: LodgementResponseDTO) => response.success)
		);
	}

	sendInfoRequest(): Observable<void> {
		return this.postCustom(`SendInfoRequest/${this.applicationDataQuery.applicationId()}`, {});
	}

	generateInfoRequest(): Observable<void> {
		return this.postCustom(`GenerateInfoRequest/${this.applicationDataQuery.applicationId()}`, {});
	}

	getApplicationInfo(): Observable<LoanApplicationDetail> {
		const applicationId = this.applicationDataQuery.applicationId();
		return this.applicationService.getApplicationInfo(applicationId);
	}

	syncOverriddenRules() {
		return this.loanAppRuleService.getOverriddenRules().pipe(
			tap((overriddenRules) => {
				this.simpFormlyHandlerService.upsertToStateWithData('overriddenRules', overriddenRules);
			})
		);
	}

	private getLodgement(): Observable<LodgementDTO> {
		return <Observable<LodgementDTO>>this.get(`${this.applicationDataQuery.applicationId()}`);
	}

	private fetchComment(): Observable<DetailedCommentDTO | undefined> {
		return this.commentsService
			.fetchDetailedComment(CommentLinkCommentType.Application)
			.pipe(catchError(() => of(undefined)));
	}
}
