import { AssociatedProductsDTO } from '@app/modules/typings/api';

export class BackUpPlanTransformer {
	static fromPayload(associatedProducts: AssociatedProductsDTO): BackUpPlanModel {
		return {
			isNotApplicable: associatedProducts?.isNotApplicable,
			isImpacted: associatedProducts?.isImpacted,
			isExpensed: associatedProducts?.isExpensed,
			conversationDetails: associatedProducts?.conversationDetails
		};
	}
}

export interface BackUpPlanModel {
	isNotApplicable?: boolean;
	isImpacted?: boolean;
	isExpensed?: boolean;
	conversationDetails?: string;
}
