<!-- TODO: fix simp-toggle-switch to accept dynamic id, then add [id]="id" -->
<simp-toggle-switch
	id="id"
	[class]="uniqueId"
	[formControl]="$any(formControl)"
	(click)="onClick()"
	[readonly]="!!to.readonly"
	metadataKey
	[formlyField]="field"
	>{{ to.label }}
</simp-toggle-switch>
