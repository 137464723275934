import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	FinancialInstitution,
	LiabilityType,
	LoanTermUnits,
	RepaymentFrequency
} from '@app/modules/shared/enums/app.enums';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Action } from '@app/modules/simp-formly/components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, PersonApplicantDTO, RefiOtherLiabilityDTO } from '@app/modules/typings/api';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiOtherLiabilitiesTransformer {
	static toPayload(
		otherLiability: RefiOtherLiabilityModel,
		applicationId: number,
		formEnumQuery: FormEnumsQuery
	): RefiOtherLiabilityDTO {
		return {
			id: otherLiability.otherLiabilitiesModal.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			currentLender: otherLiability.otherLiabilitiesModal?.currentLender,
			currentLoanBalance: otherLiability.otherLiabilitiesModal?.balance,
			creditLimit: otherLiability.otherLiabilitiesModal?.creditLimit,
			type: otherLiability.otherLiabilitiesModal?.liabilityType,
			description: otherLiability.otherLiabilitiesModal?.liabilityTypeOther,
			repaymentAmount: otherLiability.otherLiabilitiesModal?.repayment?.amount,
			repaymentFrequency: otherLiability.otherLiabilitiesModal?.repayment?.frequency,
			percentsOwned: RefiTransformerHelper.setPercentsOwnedWithJointSelection(
				formEnumQuery,
				otherLiability.otherLiabilitiesModal.liabilityOwner
			),
			remainingTermDuration: otherLiability.otherLiabilitiesModal?.remainingYears,
			remainingTermUnits: LoanTermUnits.Years,
			accountNumber: otherLiability.otherLiabilitiesModal.accountNumber
		};
	}

	static fromPayload(
		otherLiability: RefiOtherLiabilityDTO,
		formEnumQuery: FormEnumsQuery,
		applicants: PersonApplicantDTO[]
	): RefiOtherLiabilityModel {
		const summary = `${RefiTransformerHelper.getJointApplicantNames(
			applicants,
			otherLiability.percentsOwned
		)} ${formatCurrency(otherLiability.currentLoanBalance || 0, 'en-AU', '$')}, ${formEnumQuery.getOptionLabel(
			'FinancialInstitution',
			otherLiability.currentLender as number
		)}`;

		let applicantId = 0;
		if (otherLiability.percentsOwned && otherLiability.percentsOwned.length > 0) {
			applicantId = otherLiability.percentsOwned[0].applicantId || 0;
		}

		const otherLiabilityModel: RefiOtherLiabilityModel = {
			summary: summary,
			reconcileRequired: otherLiability.reconcileRequired ?? false,
			otherLiabilitiesModal: {
				id: otherLiability.id,
				currentLender: otherLiability.currentLender,
				balance: otherLiability.currentLoanBalance as number,
				creditLimit: otherLiability.creditLimit as number,
				liabilityOwner: RefiTransformerHelper.getOwnership(otherLiability.percentsOwned),
				liabilityType: otherLiability.type,
				liabilityTypeOther: otherLiability.description,
				repayment: {
					amount: otherLiability.repaymentAmount as number,
					frequency: otherLiability.repaymentFrequency as RepaymentFrequency
				},
				remainingYears: otherLiability.remainingTermDuration,
				readonly: otherLiability.readonly,
				reconcileRequired: otherLiability.reconcileRequired ?? false,
				summaryItemArray: [
					{
						summary: {
							items: {
								id: otherLiability.id,
								type: 'Credit card',
								balance: otherLiability.currentLoanBalance
							}
						}
					}
				],
				suggestedMatchId: otherLiability.suggestedMatchId,
				isUserDeclared: otherLiability.isUserDeclared,
				accountNumber: otherLiability.accountNumber
			}
		};
		return otherLiabilityModel;
	}
}

export interface RefiOtherLiabilityModel {
	otherLiabilitiesModal: RefiOtherLiabilityDetails;
	summary?: string;
	reconcileRequired?: boolean;
}

export interface RefiOtherLiabilityDetails {
	id?: number;
	liabilityType?: LiabilityType;
	liabilityTypeOther?: string;
	liabilityOwner?: number;
	balance: number;
	creditLimit?: number;
	currentLender?: FinancialInstitution;
	repayment: AmountSelect;
	percentsOwned?: PercentOwned[];
	remainingYears?: number;
	readonly?: boolean;
	reconcileRequired?: boolean;
	summaryItemArray?: OtherLiabilitySummary[];
	reconcileAction?: Action;
	suggestedMatchId?: number;
	isUserDeclared?: boolean;
	accountNumber?: string;
}

export interface OtherLiabilitySummary {
	summary?: OtherLiabilitySummaryItems;
}

export interface OtherLiabilitySummaryItems {
	items?: OtherLiabilitySummaryItemsList;
}

export interface OtherLiabilitySummaryItemsList {
	id?: number;
	type?: string;
	balance?: number;
}
