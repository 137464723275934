import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { RefiJourneyApplicationSummaryTransformerService } from './application-summary/refi-application-summary-transformer.service';
import { RefiJourneyAssetsTransformerService } from './assets/refi-assets-transformer.service';
import { RefiChooseLoanTransformerService } from './choose-loan/refi-choose-loan-transformer.service';
import { RefiJourneyConfirmDetailsTransformerService } from './confirm-details/refi-confirm-details-transformer.service';
import { RefiJourneyEligibilityTransformerService } from './eligibility/refi-elibigility-transformer.service';
import { RefiJourneyExpensesTransformerService } from './expenses/refi-expenses-transformer.service';
import { RefiJourneyIncomeTransformerService } from './income/refi-income-transformer.service';
import { RefiJourneyLiabilitiesTransformerService } from './liabilities/refi-liabilities-transformer.service';
import { RefiLoanDetailsTransformerService } from './loan-details/refi-loan-details-transformer.service';
import { RefiJourneyLoanRequirementsTransformerService } from './loan-requirements/refi-loan-requirements-transformer.service';
import { RefiProductSelectionTransformerService } from './product-selection/refi-product-selection-transformer.service';
import { RefiPropertyDetailsTransformerService } from './property-details/refi-property-details-transformer.service';
import { RefiJourneyResultsTransformerService } from './results/refi-results-transformer.service';
import { RefiJourneyReviewTransformerService } from './review/refi-review-transformer.service';
import { RefiJourneySecondBorrowerTransformerService } from './second-borrower/refi-second-borrower-transformer.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyTransformerService {
	constructor(
		private eligibility: RefiJourneyEligibilityTransformerService,
		private chooseLoan: RefiChooseLoanTransformerService,
		private loanDetails: RefiLoanDetailsTransformerService,
		private propertyDetails: RefiPropertyDetailsTransformerService,
		private secondBorrower: RefiJourneySecondBorrowerTransformerService,
		private loanRequirements: RefiJourneyLoanRequirementsTransformerService,
		private confirmDetails: RefiJourneyConfirmDetailsTransformerService,
		private applicationSummary: RefiJourneyApplicationSummaryTransformerService,
		private assets: RefiJourneyAssetsTransformerService,
		private liabilities: RefiJourneyLiabilitiesTransformerService,
		private refiProductSelectionTransformerService: RefiProductSelectionTransformerService,
		private income: RefiJourneyIncomeTransformerService,
		private expenses: RefiJourneyExpensesTransformerService,
		private result: RefiJourneyResultsTransformerService,
		private review: RefiJourneyReviewTransformerService
	) {}

	updateSchema(formFields: FormlyFieldConfig[]): Observable<FormlyFieldConfig[]> {
		return of(formFields);
	}

	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] {
		this.wireUpJourneyRoutes(formFields);
		this.eligibility.eligibilityTransform(formFields);
		this.chooseLoan.chooseLoanTransform(formFields);
		this.loanDetails.loanDetailsTransform(formFields);
		this.propertyDetails.propertyDetailsTransformer(formFields);
		this.confirmDetails.confirmDetailsTransform(formFields);
		this.applicationSummary.applicationSummaryTransform(formFields);
		this.secondBorrower.secondBorrowerTransform(formFields);
		this.loanRequirements.loanRequirementsTransform(formFields);
		this.assets.assetsTransform(formFields);
		this.liabilities.liabilitiesTransform(formFields);
		this.refiProductSelectionTransformerService.transform(formFields);
		this.income.incomeTransform(formFields);
		this.expenses.expensesTransform(formFields);
		this.loanRequirements.loanRequirementsTransform(formFields);
		this.review.reviewTransform(formFields);
		this.result.resultsTransform(formFields);
		return formFields;
	}

	wireUpJourneyRoutes(formFields: FormlyFieldConfig[]) {
		const fieldArray = formFields[0]?.fieldArray as FormlyFieldConfig;
		let prevField: FormlyFieldConfig | undefined;
		let prevPath: string | undefined;
		fieldArray?.fieldGroup?.forEach((field) => {
			field?.fieldGroup?.forEach((subField) => {
				if (subField.type === 'mobile-step') {
					if (prevField?.templateOptions) {
						prevField.templateOptions.nextRoute = `${field.key}/${subField.key}`;
						if (subField?.templateOptions && prevPath) {
							subField.templateOptions.prevRoute = prevPath;
						}
					}
					prevField = subField;
					if (subField.templateOptions?.stepType && subField.templateOptions.navigateToOnComplete) {
						prevPath = `${field.key}/${subField.key}`;
					}
				}
			});
		});
	}
}
