import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FeatureFlagService } from '@app/layouts/feature-flag/feature-flag.service';

import { CONSTANTS } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class FeatureFlagGuard implements CanActivate {
	constructor(private router: Router, private featureFlagService: FeatureFlagService) {}

	public canActivate(route: ActivatedRouteSnapshot): boolean {
		if (this.flagEnabled(route.routeConfig?.path)) {
			return true;
		}
		void this.router.navigateByUrl(CONSTANTS.LOANAPP_CLIENT_LANDING_URL);
		return false;
	}

	private flagEnabled(route?: string): boolean {
		switch (route) {
			case CONSTANTS.LOANAPPX_PATH:
				return this.featureFlagService.getFlag('loanAppX');

			default:
				return true;
		}
	}
}
