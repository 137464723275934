import { JsonSchemaProperty } from '../../typings/formly-app';
import {
	parseExpressionProperties,
	parseExpressionString,
	parseNestedExpressionProperties
} from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyTelInput = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,

	widget: {
		formlyConfig: {
			type: 'tel-input',
			className: `${property.templateOptions?.class ?? (property.class || 'col-4')}`,
			templateOptions: {
				...property.templateOptions,
				options: property.options
			},
			resetOnHide: !!property.templateOptions?.resetOnHide,
			validation: {
				messages: {
					required: 'This field is mandatory',
					invalid: 'Invalid number',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property),
			defaultValue: property.templateOptions?.defaultValue
		}
	},
	properties: {
		phoneNumber: {
			type: 'input',
			widget: {
				formlyConfig: {
					templateOptions: {
						required: property.templateOptions?.required as boolean
					},
					expressionProperties: parseNestedExpressionProperties(property),
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		areaCode: {
			type: 'input',
			widget: {
				formlyConfig: {
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		countryCode: {
			type: 'input',
			widget: {
				formlyConfig: {
					templateOptions: {
						required: property.templateOptions?.required as boolean
					},
					expressionProperties: parseNestedExpressionProperties(property),
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		nationalNumber: {
			type: 'input',
			widget: {
				formlyConfig: {
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		},
		dialCode: {
			type: 'input',
			widget: {
				formlyConfig: {
					validation: {
						messages: property.errorMessages ?? {}
					}
				}
			}
		}
	}
});
