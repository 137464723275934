import { Injectable } from '@angular/core';
import { CountryCodeLimited } from '@app/modules/shared/enums/app.enums';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { getAreaField, getFormField, getParentFormField } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyModalService } from '@app/modules/simp-formly/services/simp-formly-modal.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbnLookupDetailsModelTransfer } from '../models/abn-lookup-details.model';
import { AbnLookupStore } from '../models/abn-lookup-store.mode';
import { InputActionType, YesNo } from './../../shared/enums/app.enums';
import { AbnLookupDto } from './../typings/abn-lookup.d';

const ABN_STORE_KEY = 'abn-lookup';
const SEARCH_FIELD_ORDER = ['abn', 'acn', 'companyName', 'businessName'];

@Injectable({ providedIn: 'root' })
export class AbnLookupTransformerService {
	constructor(
		private formDataService: FormDataService,
		private formEnumsQuery: FormEnumsQuery,
		private formlyModalService: SimpFormlyModalService
	) {}

	storeInitialAbnSearchValue(value: string): void {
		const data = { initialSearch: value, selectedAbn: undefined } as AbnLookupStore;
		this.formDataService.update(ABN_STORE_KEY, data, 0);
	}

	readInitialAbnSearchValue(): string {
		const data = this.formDataService.getStateData(ABN_STORE_KEY)?.[0] as AbnLookupStore;
		return data?.initialSearch || '';
	}

	storeSelectedAbn(abn?: AbnLookupDto): void {
		const data = { initialSearch: '', selectedAbn: abn } as AbnLookupStore;
		this.formDataService.update(ABN_STORE_KEY, data, 0);
	}

	readSelectedAbn(): AbnLookupDto | undefined {
		const data = this.formDataService.getStateData(ABN_STORE_KEY)?.[0] as AbnLookupStore;
		return data?.selectedAbn;
	}

	openAbnLookupModal(
		modalField: FormlyFieldConfig | undefined,
		initialValue: string | undefined,
		callBack: (abn?: AbnLookupDto) => void
	) {
		if (modalField) {
			this.storeInitialAbnSearchValue(initialValue || '');
			if (modalField.templateOptions) {
				modalField.templateOptions.hideModalFooter = true;
			}
			const abnLookupModalRef = this.formlyModalService.openModal(modalField);
			const abnLookupModalSubscription = abnLookupModalRef.action.subscribe((modalAction) => {
				if (modalAction !== 'submit') {
					abnLookupModalRef.close();
					abnLookupModalSubscription.unsubscribe();
					return;
				}

				callBack(this.readSelectedAbn());
				abnLookupModalRef.close();
				abnLookupModalSubscription.unsubscribe();
			});
		}
	}

	handleEmploymentsAbnSearch(formField: FormlyFieldConfig, action: string, sectionKey: string): void {
		if (action === InputActionType.SEARCH) {
			const parentField = getParentFormField(formField, sectionKey);
			const modalField = getFormField(parentField?.fieldGroup, 'abnLookupModal');
			if (!modalField) {
				return;
			}

			const initialValue = this.getInitialSearchValue(formField);

			this.openAbnLookupModal(modalField, initialValue, (abnDetails?: AbnLookupDto) =>
				this.setABNDetailsInEmployments(formField, abnDetails)
			);
		} else if (action === InputActionType.CLEAR) {
			this.setABNDetailsInEmployments(formField);
		}
	}

	handleCompanyApplicantAbnSearch(formField: FormlyFieldConfig, action: string): void {
		if (action === InputActionType.SEARCH) {
			const companyApplicantField = getParentFormField(formField, 'companyApplicant');
			const modalField = getFormField(companyApplicantField?.fieldGroup, 'abnLookupModal');
			if (!modalField) {
				return;
			}

			const initialValue = this.getInitialSearchValue(formField);

			this.openAbnLookupModal(modalField, initialValue, (abnDetails?: AbnLookupDto) =>
				this.setABNDetailsOfCompanyApplicant(formField, abnDetails)
			);
		} else if (action === InputActionType.CLEAR) {
			this.setABNDetailsOfCompanyApplicant(formField);
		}
	}

	handleTrustApplicantAbnSearch(formField: FormlyFieldConfig, action: string): void {
		if (action === InputActionType.SEARCH) {
			const trustApplicantField = getParentFormField(formField, 'trustApplicant');
			const modalField = getFormField(trustApplicantField?.fieldGroup, 'abnLookupModal');
			if (!modalField) {
				return;
			}
			const initialValue = this.getInitialSearchValue(formField, ['acn', 'companyName']);

			this.openAbnLookupModal(modalField, initialValue, (abnDetails?: AbnLookupDto) =>
				this.setABNDetailsOfTrustApplicant(formField, abnDetails)
			);
		} else if (action === InputActionType.CLEAR) {
			this.setABNDetailsOfTrustApplicant(formField);
		}
	}

	handleRelatedCompanyAbnSearch(formField: FormlyFieldConfig, action: string, sectionKey: string): void {
		if (action === InputActionType.SEARCH) {
			let sectionField = getParentFormField(formField, sectionKey);
			if (!sectionField) {
				const areaField = getAreaField(formField);
				if (areaField?.fieldGroup?.length) {
					sectionField = getFormField(areaField.fieldGroup[0].fieldGroup, sectionKey);
				}
			}

			const modalField = getFormField(sectionField?.fieldGroup, 'abnLookupModal');
			if (!modalField) {
				return;
			}

			const initialValue = this.getInitialSearchValue(formField, ['businessName']);

			this.openAbnLookupModal(modalField, initialValue, (abnDetails?: AbnLookupDto) =>
				this.setABNDetailsForRelatedCompany(formField, abnDetails)
			);
		} else if (action === InputActionType.CLEAR) {
			this.setABNDetailsForRelatedCompany(formField);
		}
	}

	private getInitialSearchValue(formField: FormlyFieldConfig, feildsExcludedFromSearch: string[] = []): string {
		let initialSearchValue = (formField?.formControl?.value as string) || '';
		if (!initialSearchValue) {
			feildsExcludedFromSearch.push(formField.key as string);
			SEARCH_FIELD_ORDER.forEach((fieldKey) => {
				if (!feildsExcludedFromSearch.includes(fieldKey)) {
					if (!initialSearchValue) {
						initialSearchValue = getFormField(formField?.parent?.fieldGroup, fieldKey)?.formControl?.value as string;
					}
				}
			});
		}
		return initialSearchValue;
	}

	private setABNDetailsInEmployments(formField: FormlyFieldConfig, abnDetails?: AbnLookupDto) {
		const employerDetailFields = formField?.parent?.fieldGroup;

		const abnField = getFormField(employerDetailFields, 'abn');
		const acnField = getFormField(employerDetailFields, 'acn');
		const companyNameField = getFormField(employerDetailFields, 'companyName');
		const isABNVerifiedField = getFormField(employerDetailFields, 'isABNVerified');
		const businessNameField = getFormField(employerDetailFields, 'businessName');
		const isRegisteredForGSTField = getFormField(employerDetailFields, 'isRegisteredForGST');
		const gstRegisteredDateField = getFormField(employerDetailFields, 'gstRegisteredDate');
		const dateRegisteredField = getFormField(employerDetailFields, 'dateRegistered');
		const registeredInCountryField = getFormField(employerDetailFields, 'registeredInCountry');

		isABNVerifiedField?.formControl?.setValue(!!abnDetails);

		if (abnDetails) {
			abnField?.formControl?.setValue(abnDetails?.businessNumber);
			companyNameField?.formControl?.setValue(AbnLookupDetailsModelTransfer.getABNCompanyName(abnDetails));
			businessNameField?.formControl?.setValue(abnDetails?.businessNames?.[0] || '');
			acnField?.formControl?.setValue(abnDetails?.asicNumber);
			isRegisteredForGSTField?.formControl?.setValue(abnDetails?.gstRegistrationDate ? YesNo.Yes : YesNo.No);
			gstRegisteredDateField?.formControl?.setValue(abnDetails?.gstRegistrationDate);
			dateRegisteredField?.formControl?.setValue(abnDetails?.registrationDate);

			const countryId = this.formEnumsQuery.getOptionId('CountryCodeLimited', abnDetails?.country || '');
			registeredInCountryField?.formControl?.setValue(countryId > 0 ? countryId : null);
		}
	}

	private setABNDetailsOfCompanyApplicant(formField: FormlyFieldConfig, abnDetails?: AbnLookupDto) {
		const companyDetailFields = formField?.parent?.fieldGroup;

		const abnField = getFormField(companyDetailFields, 'abn');
		const acnField = getFormField(companyDetailFields, 'acn');
		const companyNameField = getFormField(companyDetailFields, 'companyName');
		const abnVerifiedField = getFormField(companyDetailFields, 'abnVerified');
		const businessNameField = getFormField(companyDetailFields, 'businessName');
		const registeredForGSTField = getFormField(companyDetailFields, 'registeredForGST');
		const gstRegisteredDateField = getFormField(companyDetailFields, 'gstRegisteredDate');
		const registrationDateField = getFormField(companyDetailFields, 'registrationDate');
		const registeredInCountryField = getFormField(companyDetailFields, 'registeredInCountry');
		const registeredInStateField = getFormField(companyDetailFields, 'registeredInState');

		abnVerifiedField?.formControl?.setValue(!!abnDetails);

		if (abnDetails) {
			abnField?.formControl?.setValue(abnDetails?.businessNumber);
			companyNameField?.formControl?.setValue(AbnLookupDetailsModelTransfer.getABNCompanyName(abnDetails));
			businessNameField?.formControl?.setValue(abnDetails?.businessNames?.[0] || '');
			acnField?.formControl?.setValue(abnDetails?.asicNumber || '');
			registeredForGSTField?.formControl?.setValue(!!abnDetails?.gstRegistrationDate);
			gstRegisteredDateField?.formControl?.setValue(abnDetails?.gstRegistrationDate);
			registrationDateField?.formControl?.setValue(abnDetails?.registrationDate);

			const countryId = this.formEnumsQuery.getOptionId('CountryCodeLimited', abnDetails?.country || '');
			registeredInCountryField?.formControl?.setValue(countryId > 0 ? countryId : null);

			let state = null;
			if (countryId === CountryCodeLimited.AU) {
				const stateId = this.formEnumsQuery.getOptionId('AuState', abnDetails?.state || '');
				state = stateId > 0 ? stateId : null;
			}
			registeredInStateField?.formControl?.setValue(state);
		}

		// Although we set the values for child form controllers, it takes a time to trigger the value change for the sub-section component
		// Therefore need to update sub-section component's form controller as touched and dirty after a little while to trigger formly status changed to valid.
		// So it is handled by adding a set time out
		setTimeout(() => {
			formField?.parent?.formControl?.markAllAsTouched();
			formField?.parent?.formControl?.markAsDirty();
		});
	}

	private setABNDetailsOfTrustApplicant(formField: FormlyFieldConfig, abnDetails?: AbnLookupDto) {
		const businessDetailFields = formField?.parent?.fieldGroup;
		const abnVerifiedField = getFormField(businessDetailFields, 'abnVerified');
		const abnField = getFormField(businessDetailFields, 'abn');
		const businessNameField = getFormField(businessDetailFields, 'businessName');
		const businessNameSameAsTrustNameField = getFormField(businessDetailFields, 'businessNameSameAsTrustName');

		abnVerifiedField?.formControl?.setValue(!!abnDetails);

		if (abnDetails) {
			abnField?.formControl?.setValue(abnDetails?.businessNumber);
			businessNameField?.formControl?.setValue(abnDetails?.businessNames?.[0] || '');
			businessNameSameAsTrustNameField?.formControl?.setValue(false);
		}

		// Although we set the values for child form controllers, it takes a time to trigger the value change for the sub-section component
		// Therefore need to update sub-section component's form controller as touched and dirty after a little while to trigger formly status changed to valid.
		// So it is handled by adding a set time out
		setTimeout(() => {
			abnField?.parent?.formControl?.markAllAsTouched();
			abnField?.parent?.formControl?.markAsDirty();
		});
	}

	private setABNDetailsForRelatedCompany(formField: FormlyFieldConfig, abnDetails?: AbnLookupDto) {
		const employerDetailFields = formField?.parent?.fieldGroup;

		const companyNameField = getFormField(employerDetailFields, 'companyName');
		const abnField = getFormField(employerDetailFields, 'abn');
		const acnField = getFormField(employerDetailFields, 'acn');
		const isABNVerifiedField = getFormField(employerDetailFields, 'isABNVerified');
		const registeredInCountryField = getFormField(employerDetailFields, 'registeredInCountry');
		const dateRegisteredField = getFormField(employerDetailFields, 'registrationDate');
		const isRegisteredForGSTField = getFormField(employerDetailFields, 'registeredForGST');
		const gstRegisteredDateField = getFormField(employerDetailFields, 'gstRegistrationDate');

		isABNVerifiedField?.formControl?.setValue(!!abnDetails);

		if (abnDetails) {
			companyNameField?.formControl?.setValue(AbnLookupDetailsModelTransfer.getABNCompanyName(abnDetails));
			abnField?.formControl?.setValue(abnDetails?.businessNumber);
			acnField?.formControl?.setValue(abnDetails?.asicNumber);
			isRegisteredForGSTField?.formControl?.setValue(!!abnDetails?.gstRegistrationDate);
			gstRegisteredDateField?.formControl?.setValue(abnDetails?.gstRegistrationDate);
			dateRegisteredField?.formControl?.setValue(abnDetails?.registrationDate);

			const countryId = this.formEnumsQuery.getOptionId('CountryCodeLimited', abnDetails?.country || '');
			registeredInCountryField?.formControl?.setValue(countryId > 0 ? countryId : null);
		}
	}
}
