import { DatePipe } from '@angular/common';
import { DateFormatCodes } from '../constants/constants';

export class OverriddenRuleTransformer {
	static fromPayload(data: OverriddenRuleDTO[]): OverriddenRule[] {
		const datePipe = new DatePipe('en-US');

		return data.map((ruleDTO) => {
			const fullName = `${ruleDTO.overriddenByPersonFirstName ?? ''} ${
				ruleDTO.overriddenByPersonLastName ?? ''
			}`.trim();
			const dateLabel = datePipe.transform(ruleDTO.overriddenDate, DateFormatCodes.short);

			return {
				ruleCode: ruleDTO.ruleCode,
				ruleDetails: {
					ruleTitle: ruleDTO.title,
					unmetMessage: ruleDTO.unmetMessage
				},
				overrideDetails: {
					overriddenBy: `${fullName} ${dateLabel}`.trim(),
					overrideReason: ruleDTO.overrideReason
				}
			};
		});
	}
}

export interface OverriddenRuleDTO {
	ruleCode: string;
	title: string;
	unmetMessage: string;
	overrideReason: string;
	overriddenDate: string;
	overriddenByPersonFirstName: string;
	overriddenByPersonLastName: string;
}

export interface OverriddenRule {
	ruleCode: string;
	ruleDetails: {
		ruleTitle: string;
		unmetMessage: string;
	};
	overrideDetails: {
		overriddenBy: string;
		overrideReason: string;
	};
}
