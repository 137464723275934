import { FormlyButtonInputModel } from '@app/modules/simp-formly/models/formly-button-input.model';

export class AggregatorCompanyTransformer {
	static fromPayload(dto: AggregatorCompanyDto): AggregatorCompanyModel {
		return {
			extract: dto?.companyName,
			aggregatorCompanyModal: {
				companyName: dto?.companyName,
				abn: dto?.abn,
				licenceTypeId: dto?.licenceTypeId,
				licenceNumber: dto?.licenceNumber,
				accreditationNumber: dto?.accreditationNumber,
				otherIdentifier: dto?.otherIdentifier,
				officePhoneNumber: dto?.officePhoneNumber,
				officeFaxNumber: dto?.officeFaxNumber,
				email: dto?.email,
				webAddress: dto?.webAddress,
				addressId: dto?.addressId
			} as AggregatorCompany
		} as AggregatorCompanyModel;
	}
}

export interface AggregatorCompanyModel extends FormlyButtonInputModel {
	aggregatorCompanyModal: AggregatorCompany;
}

export interface AggregatorCompany {
	companyName: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: number;
	accreditationNumber: string;
	otherIdentifier: string;
	officePhoneNumber: string;
	officeFaxNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
}

export interface AggregatorCompanyDto {
	companyName: string;
	abn: string;
	licenceTypeId: number;
	licenceNumber: number;
	accreditationNumber: string;
	otherIdentifier: string;
	officePhoneNumber: string;
	officeFaxNumber: string;
	email: string;
	webAddress: string;
	addressId: string;
}
