import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConstructionDetailsService } from '@app/modules/loan-serviceability/construction-details.service';
import {
	PropertyAssetModel,
	PropertyAssetModelTransformer
} from '@app/modules/loan-serviceability/model/property-asset.model';
import { AssetTransaction, ClearingFromThisLoan, YesNo } from '@app/modules/shared/enums/app.enums';
import { savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { AddressService } from '@app/modules/shared/service/address.service';
import { AssetsAndLiabilitiesService } from '@app/modules/shared/service/assets-liabilities.service';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { PersonsCompaniesEnumService } from '@app/modules/shared/service/persons-companies-enum.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SharedFlagsService } from '@app/modules/shared/store/shared-flags/shared-flags.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { HomeLoanDTO, IdAndAddressId, PropertyDTO, RentalIncomeDTO } from '@app/modules/typings/api';
import { merge, sumBy } from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
	HomeLoanDetails,
	HomeLoanModel,
	LiabilityHomeLoanTransformer
} from '../../financial-position/model/homeloan.model';
import { AssetPropertyModelTransformer, RealEstateModel } from '../../financial-position/model/property.model';
import { RentalDetailsModel, RentalModel, RentalModelTransformer } from '../../financial-position/model/rental.model';
import { PropertyModalModel, PropertyModalModelTransformer } from '../models/property-modal.model';
import {
	PropertySecurityDetails,
	PropertySecurityDetailsModelTransformer
} from '../models/property-security-details.model';
import { PropertyModalDTO } from '../typings/property-modal';

@Injectable({
	providedIn: 'root'
})
export class PropertyService extends BaseJourneyService<unknown> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private addressService: AddressService,
		private formEnumsQuery: FormEnumsQuery,
		private assetsAndLiabilitiesService: AssetsAndLiabilitiesService,
		private constructionDetailsService: ConstructionDetailsService,
		private personsCompaniesEnumService: PersonsCompaniesEnumService,
		private sharedFlagsService: SharedFlagsService,
		private currencyPipe: CurrencyPipe
	) {
		super();
		this.setJourneyLadmRoute();
	}

	syncPropertiesAndSecurities() {
		this.syncPropertiesAndSecurities$().subscribe();
	}

	/**
	 * upsertToState all properties related sub-section
	 * @param except skip a particular sub section
	 */
	syncProperties$(): Observable<void> {
		return forkJoin({
			properties: this.fetchProperties(),
			rentalIncomes: this.fetchRentals(),
			homeLoans: this.fetchHomeLoans()
		}).pipe(
			map((data) => {
				const properties: RealEstateModel[] = data.properties || [];
				const rentalIncomes: RentalModel[] = data.rentalIncomes || [];
				const homeLoanData: HomeLoanModel[] = data.homeLoans || [];

				const propertyMap: Map<number, PropertyModalModel> = new Map();

				properties.forEach((property) => {
					const rentals = rentalIncomes
						.filter(
							(rental) => rental.details.detailsModel.address?.id === property.details.propertyDetails.address?.id
						)
						.map((rental) => rental.details.detailsModel);
					property.details.propertyDetails.rentalIncomeDetails = merge([], rentals);

					const homeLoans = homeLoanData
						.filter(
							(homeLoan) => homeLoan.details.detailsModel.address?.id === property.details.propertyDetails.address?.id
						)
						.map((homeLoan) => homeLoan.details.detailsModel);
					property.details.propertyDetails.homeLoanDetails = merge([], homeLoans);

					if (property.details.propertyDetails.address?.id) {
						propertyMap.set(property.details.propertyDetails.address.id, property.details.propertyDetails);
					}
				});

				rentalIncomes.forEach((rental) => {
					if (rental.details.detailsModel.address?.id) {
						rental.details.rentalDetails = merge({}, propertyMap.get(rental?.details?.detailsModel?.address?.id));
					}
				});

				homeLoanData.forEach((loan) => {
					if (loan.details.detailsModel.address?.id) {
						loan.details.homeLoanDetails = merge({}, propertyMap.get(loan.details.detailsModel.address?.id));
					}
				});

				this.simpFormlyHandlerService.upsertToStateWithData('realEstate', properties);
				this.simpFormlyHandlerService.upsertToStateWithData('rentals', rentalIncomes);
				this.simpFormlyHandlerService.upsertToStateWithData('homeloans', homeLoanData);

				this.upsertRentalHeader(rentalIncomes, 'rentals');
				this.assetsAndLiabilitiesService.upsertLiabilitiesSubSectionbHeaders(homeLoanData, 'homeloans');
				return void 0;
			})
		);
	}

	syncPropertiesAndSecurities$(callback?: Function): Observable<void> {
		return this.syncSecurityProperties$(callback).pipe(switchMap(() => this.syncProperties$()));
	}

	syncSecurityProperties(callback?: Function): void {
		this.syncSecurityProperties$(callback).subscribe();
	}

	syncSecurityProperties$(callback?: Function): Observable<void> {
		return forkJoin({
			properties: this.fetchSecurityProperties(),
			rentalIncomes: this.fetchSecurityRentals(),
			homeLoans: this.fetchHomeLoans()
		}).pipe(
			map((data) => {
				const properties: PropertyAssetModel[] = data.properties || [];
				const rentalIncomes: RentalModel[] = data.rentalIncomes || [];
				const homeLoanData: HomeLoanModel[] = data.homeLoans || [];

				properties.forEach((property) => {
					if (property?.details) {
						const propertyId = !property.details.propertyDetails.isAIPProperty
							? property.details?.propertyDetails?.address?.id
							: property.details?.propertyDetails?.suburbAddress?.id;
						const isFuture = property.details.propertyDetails.transaction === AssetTransaction.Purchasing;

						property.details.propertyDetails.rentalIncomeDetails = rentalIncomes
							.filter((rental) => rental.details.detailsModel.address?.id === propertyId)
							.map((rental) => ({ ...rental.details.detailsModel, isFuture }));

						const homeLoanDetails = homeLoanData
							.filter((homeLoan) => homeLoan.details.detailsModel.address?.id === propertyId)
							.map((homeLoan) => homeLoan.details.detailsModel);

						this.getTransactionAndClearingWithAddress(property, homeLoanDetails);
						property.details.propertyDetails.homeLoanDetails = homeLoanDetails;
					}
				});
				if (callback) {
					callback(properties);
				}

				this.simpFormlyHandlerService.upsertToStateWithData('property', properties);
				return void 0;
			})
		);
	}

	upsertRentalHeader(rentals: RentalModel[], subsectionKey: string) {
		const totalNetAmount = sumBy(rentals, (rental) => rental.netAmount || 0);
		const totalGrossAmount = sumBy(rentals, (rental) => rental.grossAmount || 0);

		if (totalNetAmount > 0 || totalGrossAmount > 0 || rentals.length > 0) {
			const header = { totalNetAmount: totalNetAmount, totalGrossAmount: totalGrossAmount };
			this.simpFormlyHandlerService.upsertToSubSectionHeader(subsectionKey, header);
		} else {
			this.simpFormlyHandlerService.upsertToSubSectionHeader(subsectionKey, null);
		}
	}

	triggerSavePropertyModalSuccessAction(): void {
		this.syncPropertiesAndSecurities();
		this.addressService.fetchAllAddress();
		this.toastr.success(savedSuccessfullyMessage('Property details'));
	}

	getPropertyPayloadFromFinancialPosition(
		propertyData: PropertyModalModel,
		saveRentalIncomes: boolean,
		saveHomeLoans: boolean,
		propertySecurityDetails?: PropertySecurityDetails
	): PropertyModalDTO {
		const applicationId = this.applicationDataQuery.applicationId();
		const payload = PropertyModalModelTransformer.toPayload(
			applicationId,
			propertyData,
			saveRentalIncomes,
			saveHomeLoans
		);

		if (propertySecurityDetails) {
			payload.propertyDto = PropertySecurityDetailsModelTransformer.toPayload(
				propertySecurityDetails,
				applicationId,
				payload.propertyDto
			);
		}
		return payload;
	}

	getPropertyPayloadFromLoanServiceability(
		propertyData: PropertyModalModel,
		saveRentalIncomes: boolean,
		saveHomeLoans: boolean,
		securityDetails?: PropertyAssetModel
	): PropertyModalDTO {
		const applicationId = this.applicationDataQuery.applicationId();
		const payload = PropertyModalModelTransformer.toPayload(
			applicationId,
			propertyData,
			saveRentalIncomes,
			saveHomeLoans
		);

		if (securityDetails) {
			payload.propertyDto = PropertyAssetModelTransformer.toPayloadPropertySecurityDetails(
				securityDetails,
				applicationId,
				payload.propertyDto
			);
		}
		return payload;
	}

	savePropertyData(
		payload: PropertyModalDTO,
		deleteRentals: RentalDetailsModel[] = [],
		successCallBack?: () => void
	): Observable<IdAndAddressId> {
		return this.postCustom(`Property`, payload.propertyDto).pipe(
			tap((property: IdAndAddressId) => {
				if (property) {
					const responseSubs = [];
					if (payload.homeLoanList.length > 0) {
						payload.homeLoanList.map((homeLoan) => {
							homeLoan.realEstateAssetId = property.id;
						});
						responseSubs.push(this.postCustom(`HomeLoan`, payload.homeLoanList));
					}

					if (payload.rentalIncomeList.length > 0) {
						payload.rentalIncomeList.map((rentalIncome) => {
							rentalIncome.realEstateAssetId = property.id;
						});
						responseSubs.push(this.postCustom(`RentalIncome`, payload.rentalIncomeList));
					} else if (deleteRentals?.length > 0) {
						const applicationId = this.applicationDataQuery.applicationId();
						deleteRentals.forEach((rental) =>
							responseSubs.push(this.delete(`RentalIncome/${applicationId}/${rental.id}/${!!rental.isFuture}`))
						);
					}

					if (successCallBack) {
						if (responseSubs.length > 0) {
							forkJoin(responseSubs).subscribe(() => successCallBack());
						} else {
							successCallBack();
						}
					}
				}
			})
		);
	}

	fetchRentals(): Observable<RentalModel[]> {
		return this.getCustom(`RentalIncome/${this.applicationDataQuery.applicationId()}?isFuture=false`).pipe(
			map((rentals: RentalIncomeDTO[]) => {
				return rentals.map((rental) => RentalModelTransformer.fromPayload(rental));
			})
		);
	}

	fetchSecurityRentals(): Observable<RentalModel[]> {
		return this.getCustom(`RentalIncome/${this.applicationDataQuery.applicationId()}`).pipe(
			map((rentals: RentalIncomeDTO[]) => {
				return (rentals || []).map((rental) => RentalModelTransformer.fromPayload(rental));
			})
		);
	}

	fetchProperties(): Observable<RealEstateModel[]> {
		return this.getCustom(`Property/${this.applicationDataQuery.applicationId()}`).pipe(
			map((properties: PropertyDTO[]) => {
				const uniqueProperties = this.getUniqueProperties(properties);
				return uniqueProperties
					.filter((property) => property.transaction != AssetTransaction.Purchasing)
					.map((property) =>
						AssetPropertyModelTransformer.fromPayload(
							property,
							this.applicationDataQuery.getApplicants(),
							this.formEnumsQuery
						)
					);
			})
		);
	}

	fetchHomeLoans(): Observable<HomeLoanModel[]> {
		return this.getCustom(`HomeLoan/${this.applicationDataQuery.applicationId()}`).pipe(
			map((loans: HomeLoanDTO[]) => {
				return loans.map((loan) =>
					LiabilityHomeLoanTransformer.fromPayload(loan, this.applicationDataQuery.getApplicants())
				);
			})
		);
	}

	fetchSecurityProperties(): Observable<PropertyAssetModel[]> {
		const applicants = this.applicationDataQuery.getApplicants();

		return this.getCustom(`Property/${this.applicationDataQuery.applicationId()}`).pipe(
			map((propertyDTOs: PropertyDTO[]) => this.getUniqueProperties(propertyDTOs)),
			switchMap((propertyDTOs: PropertyDTO[]) => {
				if (!propertyDTOs?.length) {
					return of([]);
				}
				return forkJoin(
					propertyDTOs.map((property) =>
						property.isConstruction
							? this.constructionDetailsService.getConstructionDetails(property.id as number)
							: of(null)
					)
				).pipe(
					map((constructionDetails) => {
						propertyDTOs = propertyDTOs.filter(
							(property) => property?.transaction === AssetTransaction.Purchasing || !!property?.toBeUsedAsSecurity
						);
						this.sharedFlagsService.isPropertyModalOpened = false;
						return propertyDTOs.map((property) => {
							const constructionData = constructionDetails.find((x) => x?.propertyId === property.id);
							return PropertyAssetModelTransformer.fromPayload(
								property,
								applicants,
								this.formEnumsQuery,
								this.personsCompaniesEnumService,
								constructionData
							);
						});
					})
				);
			})
		);
	}

	getUniqueProperties(properties: PropertyDTO[]): PropertyDTO[] {
		const uniqueProperties: PropertyDTO[] = [];
		const propertyIds: Set<number> = new Set();

		for (const property of properties) {
			if (!property.id || propertyIds.has(property.id)) {
				continue;
			}

			propertyIds.add(property.id);
			uniqueProperties.push(property);
		}

		return uniqueProperties;
	}

	private getTransactionAndClearingWithAddress(property: PropertyAssetModel, homeLoans: HomeLoanDetails[]): void {
		if (property.details) {
			property.details.extract += ` (${this.currencyPipe.transform(
				property.details.propertyDetails.propertyAssetDetails[0].value?.amount
			)}`;
		}

		switch (property.details?.propertyDetails.transaction) {
			case AssetTransaction.Owns:
				property.details.extract += ` - Owns)`;
				break;

			case AssetTransaction.Purchasing:
				property.details.extract += ' - Purchasing)';

				break;
			case AssetTransaction.OwnsExistingMortgage:
				if (homeLoans && homeLoans.length > 0) {
					property.details.extract += ' - Owns Existing Mortgage';

					homeLoans.forEach((homeLoan) => {
						if (property.details) {
							if (homeLoan.clearingThisLiability === ClearingFromThisLoan.No) {
								property.details.extract += ', Not clearing';
							} else if (
								homeLoan.clearingThisLiability === ClearingFromThisLoan.Yes &&
								homeLoan.clearingFromThisLoan === YesNo.Yes
							) {
								property.details.extract += ', Clearing from this loan - Full';
							} else if (
								homeLoan.clearingThisLiability === ClearingFromThisLoan.Partial &&
								homeLoan.clearingFromThisLoan === YesNo.Yes
							) {
								property.details.extract += ', Clearing from this loan - Partial';
							} else if (
								homeLoan.clearingThisLiability === ClearingFromThisLoan.Yes &&
								homeLoan.clearingFromThisLoan === YesNo.No
							) {
								property.details.extract += ', Clearing from other source - Full';
							} else if (
								homeLoan.clearingThisLiability === ClearingFromThisLoan.Partial &&
								homeLoan.clearingFromThisLoan === YesNo.No
							) {
								property.details.extract += ', Clearing from other source - Partial';
							}
						}
					});
					property.details.extract += ')';
				} else {
					property.details.extract += ' - Owns Existing Mortgage)';
				}
				break;
			case AssetTransaction.Sold:
				property.details.extract += ` - Sold)`;
				break;
		}
	}
}
