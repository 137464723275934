/* eslint-disable no-useless-escape */
import { JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

// Credit: https://github.com/FluentValidation/FluentValidation/blob/10.x/src/FluentValidation/Validators/EmailValidator.cs
const emailPattern =
	// eslint-disable-next-line max-len
	// eslint-disable-next-line no-control-regex
	/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const formlyEmail = (property: FormlyApiProperty): JsonSchemaProperty => ({
	type: 'input',
	title: property.title,

	widget: {
		formlyConfig: {
			className: `${property.templateOptions?.class ?? (property.class || 'col-5')}`,
			modelOptions: {
				...property.modelOptions
			},
			templateOptions: {
				pattern: emailPattern,
				...property.templateOptions
			},
			validation: {
				messages: {
					required: 'We need the email address',
					pattern: 'Invalid email address',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	}
});
