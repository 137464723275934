import { Injectable } from '@angular/core';
import { PropertyValuationModel } from '@app/modules/loan-serviceability/model/property-valuation.model';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import {
	PropertyInformationDetailsResponse,
	PropertyValuationDTO,
	PropertyValuationInfoDTO
} from '@app/modules/typings/api';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PropertyValuationService extends BaseJourneyService<PropertyValuationModel> {
	constructor() {
		super();
		this.setRoute(URL_CONSTANTS.PROPERTY_INFORMATION);
	}

	submitAndGetPropertyValuation(valuationModel: PropertyValuationDTO): Observable<PropertyValuationInfoDTO> {
		return this.postCustom('GetElectronicValuation', valuationModel) as Observable<PropertyValuationInfoDTO>;
	}

	isValuationServiceEnabled(): Observable<boolean> {
		return <Observable<boolean>>this.getCustom('ElectronicValuation');
	}

	getPropertyInformationDetails(
		valuationModel: PropertyValuationDTO
	): Observable<PropertyInformationDetailsResponse | false> {
		return <Observable<PropertyInformationDetailsResponse>>(
			this.postCustom('GetPropertyInformationDetails', valuationModel).pipe(catchError(() => of(null)))
		);
	}
}
