import { formatCurrency } from '@angular/common';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	FinancialInstitution,
	JourneyType,
	LoanTermUnits,
	RepaymentFrequency,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { Action } from '@app/modules/simp-formly/components/formly-mobile-modal-wrapper/formly-mobile-modal-wrapper.component';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, PersonApplicantDTO, RefiHomeLoanDTO } from '@app/modules/typings/api';
import { RefiTransformerHelper } from './refi-transformer-helper';

export class RefiHomeLoansTransformer {
	static toPayload(
		homeLoan: RefiHomeLoanDetails,
		applicationId: number,
		formEnumQuery: FormEnumsQuery
	): RefiHomeLoanDTO {
		return {
			id: homeLoan.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			outstandingBalance: homeLoan?.loanBalance,
			financialInstitution: homeLoan?.currentLender,
			remainingTermDuration: homeLoan?.remainingYears,
			remainingTermUnits: LoanTermUnits.Years,
			isLoanTaxDeductible: getNullableBoolean(homeLoan?.isLoanTaxDeductible),
			percentsOwned: homeLoan?.percentsOwned?.length
				? homeLoan?.percentsOwned
				: RefiTransformerHelper.setPercentsOwnedWithJointSelection(formEnumQuery, homeLoan?.ownership),
			realEstateAssetId: homeLoan.realEstateAssetId,
			repaymentAmount: homeLoan?.repayment?.amount,
			repaymentFrequency: homeLoan.repayment?.frequency,
			redrawAmount: homeLoan.redrawAmount,
			hasRedrawAvailable: getNullableBoolean(homeLoan.redrawAvailable),
			clearingFromThisLoanId: homeLoan.clearingFromThisLoanId,
			closingOnSettlement: homeLoan.closingOnSettlement,
			loanEstablishedDate: homeLoan.loanEstablishedDate,
			accountNumber: homeLoan.accountNumber,
			loanLimit: homeLoan.loanLimit,
			annualInterestRate: homeLoan.annualInterestRate
		};
	}

	static fromPayload(
		homeLoan: RefiHomeLoanDTO,
		formEnumQuery: FormEnumsQuery,
		applicants: PersonApplicantDTO[],
		journeyType?: JourneyType
	): RefiHomeLoanModel {
		const summary = `${RefiTransformerHelper.getJointApplicantNames(
			applicants,
			homeLoan.percentsOwned
		)} ${formatCurrency(homeLoan.outstandingBalance || 0, 'en-AU', '$')}, ${formEnumQuery.getOptionLabel(
			'FinancialInstitution',
			homeLoan.financialInstitution as number
		)},
			${formEnumQuery.getOptionLabel('Years', homeLoan.remainingTermDuration as number)} 
			${
				homeLoan.isMainLoan && journeyType === JourneyType.Refi
					? `<p class='refi-message-highlight simp-margin-vertical-base'>This is the home loan you're refinancing</p>`
					: ''
			}`;

		const homeLoanModel: RefiHomeLoanModel = {
			id: homeLoan.id,
			summary: summary,
			reconcileRequired: homeLoan.reconcileRequired ?? false,
			homeLoansModal: {
				id: homeLoan.id,
				isMainLoan: homeLoan.isMainLoan,
				currentLender: homeLoan.financialInstitution,
				loanBalance: homeLoan.outstandingBalance as number,
				isLoanTaxDeductible: getNullableYesNo(homeLoan.isLoanTaxDeductible),
				realEstateAssetId: homeLoan.realEstateAssetId,
				percentsOwned: homeLoan.percentsOwned,
				repayment: {
					amount: homeLoan.repaymentAmount as number,
					frequency: homeLoan.repaymentFrequency as RepaymentFrequency
				},
				remainingYears: homeLoan.remainingTermDuration,
				redrawAmount: homeLoan.redrawAmount,
				ownership: RefiTransformerHelper.getOwnership(homeLoan.percentsOwned as PercentOwned[]),
				redrawAvailable: getNullableYesNo(homeLoan.hasRedrawAvailable),
				readonly: homeLoan.readonly,
				clearingFromThisLoanId: homeLoan.clearingFromThisLoanId,
				closingOnSettlement: homeLoan.closingOnSettlement,
				loanEstablishedDate: homeLoan.loanEstablishedDate,
				reconcileRequired: homeLoan.reconcileRequired ?? false,
				summaryItemArray: [
					{
						summary: {
							items: {
								id: homeLoan.id,
								type: 'Home loan',
								loanBalance: homeLoan.outstandingBalance,
								ownership: RefiTransformerHelper.getOwnership(homeLoan.percentsOwned as PercentOwned[]),
								currentLender: homeLoan.financialInstitution
							}
						}
					}
				],
				suggestedMatchId: homeLoan.suggestedMatchId,
				isUserDeclared: homeLoan.isUserDeclared,
				accountNumber: homeLoan.accountNumber,
				loanLimit: homeLoan.loanLimit,
				annualInterestRate: homeLoan.annualInterestRate
			}
		};
		return homeLoanModel;
	}
}

export interface RefiHomeLoanModel {
	id?: number;
	homeLoansModal: RefiHomeLoanDetails;
	summary?: string;
	reconcileRequired?: boolean;
}

export interface RefiHomeLoanDetails {
	id: number;
	isMainLoan?: boolean;
	realEstateAssetId: number | undefined;
	loanBalance?: number;
	currentLender?: FinancialInstitution;
	percentsOwned?: PercentOwned[];
	repayment: AmountSelect;
	ownership?: number;
	remainingYears?: number;
	isLoanTaxDeductible?: YesNo;
	redrawAvailable?: YesNo;
	redrawAmount?: number;
	readonly?: boolean;
	clearingFromThisLoanId?: number;
	closingOnSettlement: boolean;
	loanEstablishedDate?: string;
	reconcileRequired?: boolean;
	summaryItemArray?: HomeLoansSummary[];
	reconcileAction?: Action;
	suggestedMatchId?: number;
	isUserDeclared?: boolean;
	accountNumber?: string;
	loanLimit?: number;
	annualInterestRate?: number;
}

export interface HomeLoansSummary {
	summary?: HomeLoansSummaryItems;
}

export interface HomeLoansSummaryItems {
	items?: HomeLoansSummaryItemsList;
}

export interface HomeLoansSummaryItemsList {
	id?: number;
	type?: string;
	loanBalance?: number;
	ownership?: number;
	currentLender?: FinancialInstitution;
}
