import { Injectable } from '@angular/core';
import { ApplicantEntityType } from '@app/modules/shared/enums/app.enums';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { BankDetailsDTO } from '@app/modules/typings/api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CONSTANTS } from '../../shared/constants/constants';
import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';

@Injectable({ providedIn: 'root' })
export class BankingService extends BaseJourneyService {
	constructor(private applicationDataQuery: ApplicationDataQuery) {
		super();
		this.setJourneyLadmRoute();
	}

	saveBankDetails(
		bankDetails: BankDetailsDTO,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<number> {
		const route = this.getRoute(applicantEntityType);
		const model: BankDetailsDTO = {
			id: bankDetails.id ?? CONSTANTS.NEW_ID,
			applicationId: this.applicationDataQuery.applicationId(),
			applicantId: bankDetails.applicantId,
			financialInstitution: bankDetails.financialInstitution,
			bankAccountType: bankDetails.bankAccountType,
			bsb: bankDetails.bsb,
			accountNumber: bankDetails.accountNumber
		};
		return this.postCustom(route, model).pipe(
			tap((id: number) => {
				model.id = id;
			})
		);
	}

	getBankDetails(
		applicantId: number,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<BankDetailsDTO[]> {
		const route = this.getRoute(applicantEntityType);
		return <Observable<BankDetailsDTO[]>>(
			this.get(
				`${route}/${
					applicantEntityType === ApplicantEntityType.PersonApplicant ? 'applicant/' : ''
				}${this.applicationDataQuery.applicationId()}/${applicantId}`
			)
		);
	}

	deleteBankDetails(
		id: number,
		applicantEntityType: ApplicantEntityType = ApplicantEntityType.PersonApplicant
	): Observable<boolean> {
		const route = this.getRoute(applicantEntityType);
		return this.delete(`${route}/${this.applicationDataQuery.applicationId()}/${id}`).pipe(map(() => true));
	}

	private getRoute(applicantEntityType: ApplicantEntityType) {
		switch (applicantEntityType) {
			case ApplicantEntityType.PersonApplicant:
				return `BankDetails`;
			case ApplicantEntityType.CompanyApplicant:
				return `CompanyApplicantBankDetails`;
			case ApplicantEntityType.TrustApplicant:
				return `TrustApplicantBankDetails`;
			default:
				return `BankDetails`;
		}
	}
}
