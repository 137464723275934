import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { FormValidations } from './typings/form-validations';

const createInitialState = (): FormValidations => {
	return {};
};

@Injectable({ providedIn: 'root' })
export class FormValidationsStore {
	store = createStore({ name: 'form-validations' }, withProps<FormValidations>(createInitialState()));

	getValue() {
		return this.store.getValue();
	}

	reset(): void {
		this.store.reset();
	}

	select(selectFunction: ((state: FormValidations) => any) | string = (state) => state): Observable<any> {
		if (typeof selectFunction === 'string') {
			return this.store.pipe(select((state) => state[selectFunction]));
		}
		return this.store.pipe(select(selectFunction));
	}

	update(updateFunction: (state: FormValidations) => FormValidations) {
		this.store.update((newState: FormValidations) => {
			return cloneDeep(updateFunction(newState));
		});
	}
}
