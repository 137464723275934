import { Importance, OffsetOptions } from '@app/modules/shared/enums/app.enums';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { LoanPreferenceOffsetAccountDTO } from '@app/modules/typings/api';

export class OffSetTransformer {
	static toPayload(applicationId: number, details: OffsetModel): LoanPreferenceOffsetAccountDTO {
		const options = details.offsetAccountExtras?.offSetImportantOptions?.options ?? [];
		return {
			applicationId: applicationId,
			importance: details.offsetAccountImportanceId,
			risksExplained: details.offsetAccountRisksExplained === true,
			reasonDescription: details.offsetAccountExtras?.offSetImportantOptions?.otherReasonInput,
			reasonForTaxPurposes: options?.includes(OffsetOptions.ReasonForTaxPurposes),
			reasonAllowsAccessToFunds: options?.includes(OffsetOptions.ReasonAllowsAccessToFunds),
			reasonAllowsPayingOffLoanSooner: options?.includes(OffsetOptions.ReasonAllowsPayingOffLoanSooner),
			reasonOther: !!details.offsetAccountExtras?.offSetImportantOptions?.otherReasonInput
		};
	}
	static fromPayload(data: LoanPreferenceOffsetAccountDTO): OffsetModel {
		const selectedIds: number[] = [];
		this.getSelectedIds(data, selectedIds);
		const details: OffsetModel = {
			offsetAccountExtras: {
				offSetImportantOptions: {
					options: selectedIds,
					otherReasonInput: data.reasonDescription ?? ''
				}
			},
			offsetAccountImportanceId: data.importance as Importance,
			offsetAccountRisksExplained: data.risksExplained as boolean
		};
		return details;
	}
	static getSelectedIds(data: LoanPreferenceOffsetAccountDTO, selectedIds: number[]) {
		if (data.reasonAllowsAccessToFunds) {
			selectedIds.push(OffsetOptions.ReasonAllowsAccessToFunds);
		}
		if (data.reasonAllowsPayingOffLoanSooner) {
			selectedIds.push(OffsetOptions.ReasonAllowsPayingOffLoanSooner);
		}
		if (data.reasonForTaxPurposes) {
			selectedIds.push(OffsetOptions.ReasonForTaxPurposes);
		}
	}
}

export interface OffsetModel {
	offsetAccountImportanceId: Importance;
	offsetAccountRisksExplained: boolean;
	offsetAccountExtras: {
		offSetImportantOptions: MultiCheckboxModel;
	};
}
export interface OffsetStoreModel {
	offsetContainer: OffsetModel;
}
