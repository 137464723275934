import { EmploymentStatus, IncomeType, JobLevel, TargetType } from '@app/modules/shared/enums/app.enums';
import { getMonthAndYearDifference, getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { EmploymentDTO } from '@app/modules/typings/api';
import { CurrencyHelper, FrequencyShort } from '@simpology/client-components/utils';
import { YesNo } from './../../shared/enums/app.enums';
import { AggregateFormatterService } from './../../shared/service/aggregate-formatter.service';

import { ApplicantEnumObject } from '@app/modules/shared/enums/enum-helper';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ApplicantForeignEmploymentModel } from './applicant-foreign-employment.model';
import { ApplicantSelfEmploymentModel } from './applicant-self-employment.model';
import { EmploymentModelTransformer, TypeOfIncome } from './employment.model';
import { NotEmployedModel } from './notemployed-income.model';

export class ApplicantEmploymentModelTransformer {
	static toPayload(
		employmentModel: ApplicantEmploymentModel,
		applicationId: number,
		startDate: string,
		endDate: string
	): EmploymentDTO {
		const payg = employmentModel.employmentDetails.payg!;
		return {
			id: employmentModel.id,
			applicationId: applicationId,
			applicantId: payg.ownership,
			status: payg.status ?? EmploymentStatus.Previous,
			basis: payg.basis,
			employerType: payg.employerType,
			employerId: payg.employerDetailsSelect?.id,
			employerTargetType: payg.employerDetailsSelect?.type,
			isOnProbation: !!payg.isOnProbation,
			dateStarted: payg.dateStarted,
			dateEnded: payg.dateEnded,
			occupation: payg.occupation,
			jobLevel: payg.jobLevel,
			industryDivisionId: payg.industryDivision,
			industrySubdivisionId: payg.industrySubdivision,
			industryCodeId: payg.anzsicIndustry,
			income: (payg.income || [])
				.filter((income) => income)
				.map((income) => {
					return {
						id: income.id,
						type: income.type,
						grossAmount: income.grossAmount ? CurrencyHelper.unformatAmount(income.grossAmount) : undefined,
						netAmount: income.netAmount ? CurrencyHelper.unformatAmount(income.netAmount) : undefined,
						frequency: income.frequency !== FrequencyShort.YearToDate ? income.frequency : undefined,
						includedInBase: income.includedInBase,
						startDate:
							income.frequency === FrequencyShort.YearToDate
								? payg.incomePeriodModal?.startDate ?? startDate
								: undefined,
						endDate:
							income.frequency === FrequencyShort.YearToDate ? payg.incomePeriodModal?.endDate ?? endDate : undefined
					};
				})
				.filter((income) => !!income.grossAmount || !!income.netAmount),
			companyCar: !!payg.companyCar,
			probationDateStarts: payg.isOnProbation ? payg.probationDateStarts : undefined,
			probationDateEnds: payg.isOnProbation ? payg.probationDateEnds : undefined,
			anzscoOccupationCode: payg.anzscoOccupationCode,
			occupationCode: payg.occupationCode,
			averageHoursPerWeek: payg.averageHoursPerWeek,
			mainBusinessActivity: payg.mainBusinessActivity,
			employedByFamily: getNullableBoolean(payg.employedByFamily),
			positionTitle: payg.positionTitle
		};
	}

	static fromPayload(
		employment: EmploymentDTO,
		formatter: AggregateFormatterService,
		formEnumsQuery: FormEnumsQuery
	): ApplicantEmploymentModel {
		const paygIncome = (employment.income || []).map((income) => ({
			id: income.id,
			grossAmount: String(income.grossAmount!),
			netAmount: String(income.netAmount!),
			frequency: income.frequency ?? (income.startDate ? FrequencyShort.YearToDate : undefined),
			type: income.type,
			includedInBase: income.includedInBase
		}));

		const calculated = EmploymentModelTransformer.getNetAndGrossAmountForSummary(paygIncome);

		const paygModel: ApplicantPaygModel = {
			status: employment.status,
			basis: employment.basis,
			ownership: employment.applicantId,
			employerType: employment.employerType,
			isOnProbation: employment.isOnProbation,
			jobLevel: employment.jobLevel,
			dateStarted: employment.dateStarted,
			dateEnded: employment.dateEnded,
			duration: getMonthAndYearDifference(employment.dateStarted!, employment.dateEnded!),
			occupation: employment.occupation,
			industryDivision: employment.industryDivisionId,
			industrySubdivision: employment.industrySubdivisionId,
			anzsicIndustry: employment.industryCodeId,
			income: paygIncome,
			incomePeriodModal: {
				startDate: employment.income?.find((income) => income.startDate)?.startDate,
				endDate: employment.income?.find((income) => income.endDate)?.endDate
			},
			employerDetailsSelect: formEnumsQuery.getExistingPartiesById({
				type: employment.employerTargetType as TargetType,
				id: employment.employerId as number
			}),
			typeOfIncome: {
				type: IncomeType.payg,
				currentEmployment: EmploymentModelTransformer.isCurrentEmployment(employment.dateEnded, employment.status)
			},
			companyCar: getNullableYesNo(employment.companyCar),
			probationDateStarts: employment.probationDateStarts,
			probationDateEnds: employment.probationDateEnds,
			anzscoOccupationCode: employment.anzscoOccupationCode,
			occupationCode: employment.occupationCode,
			averageHoursPerWeek: employment.averageHoursPerWeek ?? undefined,
			mainBusinessActivity: employment.mainBusinessActivity,
			grossTotal: formatter.formatAmount(calculated.grossAmount),
			netTotal: formatter.formatAmount(calculated.netAmount),
			employedByFamily: getNullableYesNo(employment.employedByFamily),
			positionTitle: employment.positionTitle
		};
		const employmentModel: ApplicantEmploymentModel = {
			employmentDetails: {
				applicantId: employment.applicantId,
				extract: `Employed`,
				typeOfIncome: {
					type: IncomeType.payg,
					currentEmployment: EmploymentModelTransformer.isCurrentEmployment(employment.dateEnded, employment.status)
				},
				payg: paygModel
			},
			id: employment.id,
			dateStarted: employment.dateStarted,
			dateEnded: employment.dateEnded
		};

		return employmentModel;
	}
}

export interface ApplicantEmploymentModel {
	id?: number;
	employmentDetails: ApplicantEmploymentDetailsModel;
	dateStarted?: string;
	dateEnded?: string;
}

export interface ApplicantEmploymentDetailsModel {
	typeOfIncome: TypeOfIncome;
	payg?: ApplicantPaygModel;
	selfEmployment?: ApplicantSelfEmploymentModel;
	notEmployed?: NotEmployedModel;
	foreignEmployment?: ApplicantForeignEmploymentModel;
	extract?: string;
	applicantId?: number;
}

export interface ApplicantPaygModel {
	status?: number;
	basis?: number;
	dateStarted?: string;
	jobLevel?: JobLevel;
	dateEnded?: string;
	isOnProbation?: boolean;
	occupation?: string;
	ownership?: number;
	employerType?: number;
	industryDivision?: number;
	industrySubdivision?: number;
	anzsicIndustry?: number;
	employerDetailsSelect?: ApplicantEnumObject;
	income?: IncomeModel[];
	incomePeriodModal?: IncomePeriodModel;
	typeOfIncome?: TypeOfIncome;
	duration?: string;
	companyCar?: YesNo;
	probationDateStarts?: string;
	probationDateEnds?: string;
	anzscoOccupationCode?: number;
	occupationCode?: number;
	averageHoursPerWeek?: number;
	mainBusinessActivity?: string;
	grossTotal?: string;
	netTotal?: string;
	employedByFamily?: YesNo;
	positionTitle?: string;
}

export interface IncomeModel {
	id?: number;
	type?: number;
	grossAmount?: string;
	netAmount?: string;
	frequency?: FrequencyShort;
	includedInBase?: boolean;
}

export interface IncomePeriodModel {
	startDate?: string;
	endDate?: string;
}
