import { DatePipe } from '@angular/common';
// Date format shold be yyyy-MM-dd
export function isDateFuture(date: string): boolean {
	const inputDate = new Date(date);
	const currentDate = new Date();

	return inputDate > currentDate;
}

export function isDatePast(date: string, includeToday = false): boolean {
	const inputDate = new Date(date);
	const currentDate = new Date();
	// Set hours, minutes, seconds and milliseconds to 0 to compare only the date part
	inputDate.setHours(0, 0, 0, 0);
	currentDate.setHours(0, 0, 0, 0);
	return includeToday ? inputDate <= currentDate : inputDate < currentDate;
}

export function getPreviousDay(date: string, datePipe: DatePipe): string {
	const dateParts = date.split('-').map(function (item) {
		return parseInt(item, 10);
	});
	const dateAsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
	dateAsDate.setDate(dateAsDate.getDate() - 1);
	return datePipe.transform(dateAsDate, 'yyyy-MM-dd') ?? '';
}

/**
 * Add or subtract number of years, eg: DOB has to be 18 years prior, hence maxDate(-18) would generate the required object
 * @param tweak
 * @returns
 */
export const yearMonthDayFromToday = (tweakYear = 0, tweakMonth = 0, tweakDay = 0) => {
	const current = new Date();
	current.setFullYear(current.getFullYear() + tweakYear);
	return {
		year: current.getFullYear(),
		month: current.getMonth() + 1 + tweakMonth,
		day: current.getDate() + tweakDay
	};
};

export const minDate = () => {
	const date = new Date('1901-01-01');
	return {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate()
	};
};

export const formatDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month}-${day}`;
};

/**
 * Get the Australian time zone as AEST or AEDT.
 * @see AEST (Australian Eastern Standard Time) => Starts on the first Sunday of April and ends on the first Sunday of October.
 * @see AEDT (Australian Eastern Daylight Time) => Starts on the first Sunday of October and ends on the first Sunday of April.
 *
 * @param date
 * @returns AEST or AEDT
 */
export const getAUTimeZone = (date?: Date | string): string => {
	if (!date) return '';

	if (!(date instanceof Date)) date = new Date(date);

	const year = date.getFullYear();
	const firstSundayOfApril = new Date(year, 3, 1 - new Date(year, 3, 1).getDay() + 7);
	const firstSundayOfOctober = new Date(year, 9, 1 - new Date(year, 9, 1).getDay() + 7);

	return date >= firstSundayOfApril && date < firstSundayOfOctober ? 'AEST' : 'AEDT';
};

export function convertUTCToLocalDate(dateString: string): Date {
	return new Date(dateString + 'Z');
}
