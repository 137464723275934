import { Injectable } from '@angular/core';
import {
	ManagementParticipantAdditionalInfo,
	ManagementParticipantAdditionalInfoDto
} from '@app/modules/setup/model/manage-participants.model';
import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { ApplicationDataQuery } from '../../shared/store/application-data/application-data.query';
import { ManagementParticipant, ManagementParticipantAPI } from '../typings/setup';

@Injectable({ providedIn: 'root' })
export class ManageParticipantsService extends BaseJourneyService<any> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.MANAGEMENT_PARTICIPANT);
	}

	updateManagementParticipantsAdditionalInfo(managementParticipants: ManagementParticipantAPI[]) {
		if (managementParticipants?.length > 0) {
			this.simpFormlyHandlerService.upsertToState(
				'managementParticipantsAdditionalInfo',
				forkJoin(
					managementParticipants.map((participant) => this.getManagementParticipantAdditionalInfo(participant.id))
				)
			);
		}
	}

	getManagementParticipantAdditionalInfo(participantId: number): Observable<ManagementParticipantAdditionalInfo> {
		return <Observable<ManagementParticipantAdditionalInfoDto>>this.get(
			`AdditionalInfo/${this.applicationDataQuery.applicationId()}/${participantId}`
		).pipe(
			map((participantAdditionalInfoDto: ManagementParticipantAdditionalInfoDto) => {
				return {
					id: participantAdditionalInfoDto.id,
					aggregatorNumber: participantAdditionalInfoDto.aggregatorNumber,
					brokerEntityTpmiNumber: participantAdditionalInfoDto.brokerEntityTpmiNumber,
					lenderNumber: participantAdditionalInfoDto.lenderNumber,
					subTpmiNumber: participantAdditionalInfoDto.subTpmiNumber,
					mobile: participantAdditionalInfoDto.mobile
				} as ManagementParticipantAdditionalInfo;
			})
		);
	}

	saveManagementParticipantAdditionalInfo(participantUserId?: number): Observable<boolean> {
		if (participantUserId) {
			return this.post(`AdditionalInfo/${this.applicationDataQuery.applicationId()}/${participantUserId}`, {}).pipe(
				map(() => true)
			);
		} else {
			return of(true);
		}
	}

	getManagementParticipants(): Observable<any> {
		return this.get(`${this.applicationDataQuery.applicationId()}`);
	}

	saveManagementParticipant(managementParticipant: ManagementParticipant, index: number): Observable<void> {
		if (managementParticipant.reference && managementParticipant.reference !== managementParticipant.id) {
			return this.deleteManagementParticipant(managementParticipant.reference).pipe(
				switchMap(() => {
					return this.saveManagementParticipantCall(managementParticipant, index);
				})
			);
		} else {
			return this.saveManagementParticipantCall(managementParticipant, index);
		}
	}

	deleteManagementParticipant(managementParticipantId: number): Observable<boolean> {
		if (managementParticipantId) {
			return this.delete(`${this.applicationDataQuery.applicationId()}/${managementParticipantId}`).pipe(
				map(() => true)
			);
		} else {
			return of(true);
		}
	}

	fetchManagedParticipant(fetchAdditionalInfo = false): Observable<ManagementParticipant[]> {
		return this.getManagementParticipants().pipe(
			take(1),
			tap((managementParticipants: ManagementParticipantAPI[]) => {
				if (fetchAdditionalInfo) {
					this.updateManagementParticipantsAdditionalInfo(managementParticipants);
				}
			}),
			map((managementParticipants: ManagementParticipantAPI[]) =>
				managementParticipants.map((participant: ManagementParticipantAPI) => this.transformParticipant(participant))
			)
		);
	}

	transformParticipant(participant: ManagementParticipantAPI): ManagementParticipant {
		return {
			id: participant.id,
			userId: participant.userId,
			fullName: participant.fullName,
			reference: participant.id,
			participantType: participant.personTypeEnum,
			isCurrentCoordinator: participant.isCurrentCoordinator,
			receiveInfoRequests: participant.receiveInfoRequests,
			email: participant.email
		} as ManagementParticipant;
	}

	private saveManagementParticipantCall(managementParticipant: ManagementParticipant, index: number): Observable<void> {
		const modelToPost: ManagementParticipantAPI = {
			id: managementParticipant.id,
			userId: managementParticipant.userId,
			personTypeEnum: managementParticipant.participantType,
			isCurrentCoordinator: !!managementParticipant.isCurrentCoordinator,
			receiveInfoRequests: !!managementParticipant.receiveInfoRequests,
			email: managementParticipant.email
		};
		return this.postCustom(`${this.applicationDataQuery.applicationId()}`, modelToPost).pipe(
			tap(() => {
				managementParticipant.reference = managementParticipant.id;
				this.simpFormlyHandlerService.updateToState('managementParticipants', managementParticipant, index);
			})
		);
	}
}
