import { PhoneNumberDTO } from '@app/modules/typings/api';
import { PhoneDetails } from '@simpology/client-components';
import { countries } from 'countries-list';

export class PhoneNumberHelper {
	static fromPayload(payload?: PhoneNumberDTO): PhoneDetails | undefined {
		if (!payload) {
			return undefined;
		}
		return {
			nationalNumber: payload.nationalNumber,
			countryCode:
				(Object.entries(countries)
					.find((country) => payload.countryCode === country[1].phone)
					?.shift() as string) ?? '',
			dialCode: payload.countryCode ? `+${payload.countryCode}` : '',
			phoneNumber: payload.rawNumber.length ? payload.rawNumber : payload.nationalNumber,
			phoneType: undefined,
			areaCode: payload.areaCode
		};
	}

	static toPayLoad(phoneDetails?: PhoneDetails): PhoneNumberDTO | undefined {
		if (!phoneDetails || !phoneDetails.phoneNumber) {
			return undefined;
		}
		return {
			rawNumber: phoneDetails.phoneNumber,
			countryCode: phoneDetails.dialCode?.replace('+', ''),
			nationalNumber: phoneDetails.nationalNumber,
			areaCode: phoneDetails.areaCode
		};
	}
}
