import { Injectable } from '@angular/core';
import { ScenarioLeverDetails } from '@app/modules/serviceability-scenarios/model/scenario-lever.model';
import { SelectedScenario } from '@app/modules/serviceability-scenarios/model/scenario-selection.model';
import { ServiceabilityScenariosService } from '@app/modules/serviceability-scenarios/serviceability-scenarios.service';
import {
	formlyDeleteFromArray,
	formlyExtendExpressionProperties,
	formlyOnChange,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField,
	getInitializedFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { EnumObject } from '@simpology/client-components/utils';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';
import { CONSTANTS } from '../constants/constants';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { OutcomesMetricsCalculationService } from './../../serviceability-scenarios/outcomes-metrics-calculation.service';

@Injectable({
	providedIn: 'root'
})
export class ServiceabilityScenariosTransformerService {
	constructor(
		private formEnumsQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private serviceabilityScenariosService: ServiceabilityScenariosService,
		private outcomesMetricsCalculationService: OutcomesMetricsCalculationService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private toastr: ToastrService
	) {}
	transform(formFields: FormlyFieldConfig[]): FormlyFieldConfig[] {
		formlyOnClick(formFields, 'scenarios.scenarioLevers.expenses.expenses.changeButton', (field: FormlyFieldConfig) => {
			const changeCollapseCustomField = getFormField(field.parent?.fieldGroup, 'changeCollapseVisibility');
			changeCollapseCustomField?.formControl?.setValue(false);
		});

		formlyOnClick(
			formFields,
			'scenarios.scenarioLevers.expenses.expenses.collapseButton',
			(field: FormlyFieldConfig) => {
				const changeCollapseCustomField = getFormField(field.parent?.fieldGroup, 'changeCollapseVisibility');
				changeCollapseCustomField?.formControl?.setValue(true);
			}
		);

		formlyOnClick(
			formFields,
			'scenarios.scenarioLevers.expenses.expenses.changeExpenses.delete',
			(field: FormlyFieldConfig) => {
				formlyDeleteFromArray(field);
			}
		);

		formlyOnClick(formFields, 'scenarios.scenarioSelection.addNewButton', this.addNewScenario.bind(this));

		formlyOnChange(formFields, 'scenarios.scenarioSelection.scenarioId', this.fillSelectedScenarioFields.bind(this));

		formlyOnClick(formFields, 'scenarios.scenarioSelection.saveButton', this.saveScenarioLeverSubSection.bind(this));
		formlyOnClick(formFields, 'scenarios.scenarioSelection.cancelButton', this.cancelScenarioDetails.bind(this));
		formlyOnClick(formFields, 'scenarios.scenarioSelection.editButton', this.editScenarioDetails.bind(this));
		formlyOnClick(formFields, 'scenarios.scenarioSelection.delete', this.deleteSelectedScenario.bind(this));

		this.updateSelectedScenarioLabelOnScenarioSelectionDropdown(formFields);
		this.showSaveButtonOnScenarioDetailsChange(formFields);
		return formFields;
	}

	private addNewScenario(addNewButtonField: FormlyFieldConfig): void {
		this.serviceabilityScenariosService.fetchScenarioLeverDetails(0, true).subscribe((scenarioLevers) => {
			const scenarioList = this.formEnumsQuery.getOptions('ScenariosEnum');
			const alternateScenarioLimit = getFormField(addNewButtonField?.parent?.fieldGroup, 'alternateScenarioLimit')
				?.template as string;

			if (scenarioList.length < parseInt(alternateScenarioLimit)) {
				this.addNewScenarioToScenarioListEnum(scenarioList);
				const scenarioIdField = getFormField(addNewButtonField.parent?.fieldGroup, 'scenarioId');
				scenarioIdField?.formControl?.patchValue(CONSTANTS.NEW_ID);
				this.serviceabilityScenariosService.updateScenarioEditState(true);
				this.simpFormlyHandlerService.upsertToStateWithData('scenarioLevers', [scenarioLevers]);
				this.removeAdditionalCategoryExpenses(scenarioLevers, addNewButtonField);
				this.expandCollapseScenarioLevers(addNewButtonField);
			} else {
				const scenarioLimitLabel = getFormField(addNewButtonField?.parent?.fieldGroup, 'addNewLimitLabel')
					?.template as string;
				this.toastr.error(scenarioLimitLabel);
			}
			this.makeScenarioLeversSubsectionUntouched(addNewButtonField);
		});
	}

	private addNewScenarioToScenarioListEnum(scenarioList: EnumObject[]) {
		const clonedScenarioList = cloneDeep(scenarioList);

		clonedScenarioList.push({
			id: CONSTANTS.NEW_ID,
			label: this.formulateScenarioName(clonedScenarioList)
		});

		this.formEnumsService.updateFormEnums('ScenariosEnum', clonedScenarioList);
	}

	private formulateScenarioName(scenarioList: EnumObject[]): string {
		const scenarioNumbers = scenarioList
			.map((scenario) => {
				const match = scenario.label.match(/Scenario (\d+)/);
				return match ? parseInt(match[1], 10) : null;
			})
			.filter((id) => id !== null) as number[];

		const maxNumber = scenarioNumbers.length ? Math.max(...scenarioNumbers) : 0;
		return `Scenario ${maxNumber + 1}`;
	}

	private showSaveButtonOnScenarioDetailsChange(formFields: FormlyFieldConfig[]) {
		formlyRegisterHooks(formFields, 'scenarios.scenarioLevers', {
			onInit: (scenarioLeverField) => {
				return scenarioLeverField.formControl?.valueChanges.pipe(
					debounceTime(500),
					distinctUntilChanged(),
					filter(() => !!scenarioLeverField?.formControl?.dirty),
					tap(() => {
						this.serviceabilityScenariosService.updateScenarioEditState(true);
						this.updateSaveBtnDisableStatus(scenarioLeverField);
					})
				);
			}
		});
	}

	private updateSaveBtnDisableStatus(scenarioLeverField: FormlyFieldConfig) {
		const saveBtnField = getInitializedFormField(
			scenarioLeverField?.parent?.parent?.fieldGroup,
			'scenarios.scenarioSelection.0.saveButton'
		);
		if (saveBtnField?.props) {
			saveBtnField.props.disabled =
				scenarioLeverField?.formControl?.invalid || !scenarioLeverField?.formControl?.dirty ? true : false;
			scenarioLeverField.formControl?.markAllAsTouched();
		}
	}

	private saveScenarioLeverSubSection(saveButtonField: FormlyFieldConfig) {
		const selectedScenario = saveButtonField.model as SelectedScenario;
		const isNewScenario = selectedScenario.scenarioId === -1;

		const ScenarioLeverSubSectionField = getFormField(
			saveButtonField?.parent?.parent?.parent?.fieldGroup,
			'scenarioLevers'
		);
		const scenarioLevers = ScenarioLeverSubSectionField?.model as ScenarioLeverDetails[];
		const baseScenarioLabel = getFormField(
			saveButtonField?.parent?.parent?.parent?.fieldGroup,
			'scenarioLevers.baseScenarioLabel'
		)?.template as string;

		this.serviceabilityScenariosService
			.saveScenarioLever(scenarioLevers[0], this.formEnumsQuery, isNewScenario)
			.pipe(
				switchMap((scenarioId: number) =>
					this.serviceabilityScenariosService.syncScenarios$(baseScenarioLabel, scenarioId).pipe(map(() => scenarioId))
				)
			)
			.subscribe((scenarioId: number) => {
				if (!isNewScenario) {
					this.outcomesMetricsCalculationService.updateActiveStateOfOutcomeMetricsTabs(scenarioId, true);
				}
				this.serviceabilityScenariosService.updateScenarioEditState(false);
				this.makeScenarioLeversSubsectionUntouched(saveButtonField);

				const scenarioLeversData =
					this.simpFormlyHandlerService.getStateData<ScenarioLeverDetails>('scenarioLevers')[0];
				this.removeAdditionalCategoryExpenses(scenarioLeversData, saveButtonField, true);
			});
	}

	private cancelScenarioDetails(cancelButtonField: FormlyFieldConfig) {
		const selectedScenarioId =
			this.simpFormlyHandlerService.getStateData<SelectedScenario>('scenarioSelection')[0].scenarioId;
		const newSelectedScenarioId = (cancelButtonField.model as SelectedScenario).scenarioId;

		const baseScenarioLabel = getFormField(
			cancelButtonField?.parent?.parent?.parent?.fieldGroup,
			'scenarioLevers.baseScenarioLabel'
		)?.template as string;

		this.simpFormlyHandlerService.upsertToStateWithData('scenarioSelection', []);
		this.simpFormlyHandlerService.upsertToStateWithData('scenarioLevers', [{ isLoading: true }]);

		this.serviceabilityScenariosService
			.syncScenarios$(baseScenarioLabel, newSelectedScenarioId === selectedScenarioId ? selectedScenarioId : undefined)
			.subscribe(() => {
				this.serviceabilityScenariosService.updateScenarioEditState(false);
				this.makeScenarioLeversSubsectionUntouched(cancelButtonField);
			});
	}

	private editScenarioDetails() {
		this.serviceabilityScenariosService.updateScenarioEditState(true);
	}

	private updateSelectedScenarioLabelOnScenarioSelectionDropdown(formFields: FormlyFieldConfig[]) {
		formlyExtendExpressionProperties(formFields, `scenarios.scenarioLevers.selectedScenarioLabel`, {
			template: (model, formState, field) => {
				const ScenarioSelectionField = getFormField(field?.parent?.parent?.parent?.fieldGroup, 'scenarioSelection');
				const selectedScenarios = ScenarioSelectionField?.model as SelectedScenario[];
				if (selectedScenarios && selectedScenarios[0]) {
					const selectedScenarioLabel = this.formEnumsQuery.getOptionLabel(
						'ScenariosEnum',
						selectedScenarios[0].scenarioId
					);
					field.template = selectedScenarios[0].scenarioId !== 0 && selectedScenarioLabel ? selectedScenarioLabel : '';
				}
			}
		});
	}

	private fillSelectedScenarioFields(scenarioIdField: FormlyFieldConfig) {
		const scenario = scenarioIdField.model as SelectedScenario;

		// Clearing state will prevents unnecessary fields from appearing due to retaining data from the previous scenarioLevers model
		this.simpFormlyHandlerService.upsertToStateWithData('scenarioLevers', []);
		this.simpFormlyHandlerService.upsertToStateWithData('scenarioLevers', [{ isLoading: true }]);

		const baseScenarioLabel = getFormField(
			scenarioIdField?.parent?.parent?.parent?.fieldGroup,
			'scenarioLevers.baseScenarioLabel'
		)?.template as string;

		this.serviceabilityScenariosService
			.syncScenarios$(baseScenarioLabel, scenario.scenarioId)
			.subscribe(([scenarioSelection, scenarioLevers]) => {
				this.serviceabilityScenariosService.updateScenarioEditState(false);
				if (!(scenarioLevers as ScenarioLeverDetails).isOnlyBaseScenario) {
					this.removeAdditionalCategoryExpenses(scenarioLevers as ScenarioLeverDetails, scenarioIdField);
					this.expandCollapseScenarioLevers(scenarioIdField);
				}
			});
	}

	private expandCollapseScenarioLevers(modalField: FormlyFieldConfig) {
		const scenarioLevers = ['borrowings', 'incomes', 'expenses', 'liabilities'];

		scenarioLevers.forEach((lever) => {
			const field = getInitializedFormField(
				modalField?.parent?.parent?.parent?.fieldGroup,
				`scenarioLevers.0.${lever}`
			);

			if (field?.props) {
				field.props.expanded = true;
			}
		});
	}

	private removeAdditionalCategoryExpenses(
		scenarioLeverDetails: ScenarioLeverDetails,
		field: FormlyFieldConfig,
		isExpand?: boolean
	) {
		scenarioLeverDetails.expenses?.expenses.forEach((expense, index) => {
			const changeExpensesField = getInitializedFormField(
				field.parent?.parent?.parent?.fieldGroup,
				`scenarioLevers.0.expenses.expenses.${index}.changeExpenses`
			);
			if (
				expense &&
				expense?.changeExpenses &&
				changeExpensesField?.fieldGroup &&
				expense?.changeExpenses?.length &&
				changeExpensesField?.fieldGroup?.length > expense?.changeExpenses?.length
			) {
				const noOfDifference = changeExpensesField?.fieldGroup?.length - expense?.changeExpenses?.length;
				if (noOfDifference > 0) {
					(changeExpensesField.props?.remove as Function)(expense?.changeExpenses?.length);
				}
			}

			const changeCollapseVisibilityField = getInitializedFormField(
				field.parent?.parent?.parent?.fieldGroup,
				`scenarioLevers.0.expenses.expenses.${index}.changeCollapseVisibility`
			);
			changeCollapseVisibilityField?.formControl?.reset();

			if (!isExpand) {
				changeCollapseVisibilityField?.formControl?.patchValue(true);
			} else {
				changeCollapseVisibilityField?.formControl?.patchValue(false);
			}
		});
	}

	private deleteSelectedScenario(deleteField: FormlyFieldConfig): void {
		const selectedScenario = deleteField.model as SelectedScenario;
		const scenarioLabel = this.formEnumsQuery.getOptionLabel('ScenariosEnum', selectedScenario.scenarioId);
		const deleteConfirmationHeaderLabel = getFormField(
			deleteField?.parent?.fieldGroup,
			'deleteConfirmationLabels.deleteConfirmationHeaderLabel'
		)?.template as string;
		const deleteConfirmationBodyLabel = getFormField(
			deleteField?.parent?.fieldGroup,
			'deleteConfirmationLabels.deleteConfirmationBodyLabel'
		);
		let message = '';
		if (deleteConfirmationBodyLabel?.template?.includes('{ScenarioId}')) {
			message = deleteConfirmationBodyLabel.template?.replace('{ScenarioId}', scenarioLabel);
		}

		this.confirmationDialogService
			.deleteConfirmation(deleteConfirmationHeaderLabel, message, 'Delete', 'Cancel')
			.pipe(
				filter((result) => result),
				switchMap(() => {
					return this.serviceabilityScenariosService.deleteSelectedScenario(selectedScenario.scenarioId).pipe(
						switchMap(() => this.serviceabilityScenariosService.syncScenarios$()),
						tap(() => {
							this.serviceabilityScenariosService.updateScenarioEditState(false);
						})
					);
				})
			)
			.subscribe();
	}

	private makeScenarioLeversSubsectionUntouched(field: FormlyFieldConfig) {
		const scenarioLeversField = getFormField(field?.parent?.parent?.parent?.fieldGroup, 'scenarioLevers');
		scenarioLeversField?.formControl?.markAsUntouched();
		scenarioLeversField?.formControl?.markAsPristine();
	}
}
