import { CompanyTrustAddressesDTO } from '@app/modules/typings/api';

export class CompanyTrustAddressesTransformer {
	static fromPayload(address: CompanyTrustAddressesDTO): CompanyTrustAddressesDTO {
		return address;
	}

	static toPayload(address: CompanyTrustAddressesDTO): Partial<CompanyTrustAddressesDTO> {
		return address;
	}
}
