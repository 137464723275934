import { getNullableBoolean } from '@app/modules/shared/helper/util';
import { AssociatedProductsDTO } from '@app/modules/typings/api';
import { BackUpPlanModel } from './backup-plan.model';
import { ComplianceCreditCardModel } from './compliance-credit-card.model';
import { HomeLandlordInsuranceModel } from './home-landlord-insurance.model';
import { LendersMortgageInsuranceModel } from './lenders-mortgage-insurance.model';

export class AssociatedProductsTransformer {
	static toPayload(associatedProducts: AssociatedProductsModel): AssociatedProductsDTO {
		const lendersMortgageInsurace = associatedProducts?.lendersMortgageInsurance?.length
			? associatedProducts.lendersMortgageInsurance[0]
			: {};
		const homeLandlordInsurance = associatedProducts?.homeLandlordInsurance?.length
			? associatedProducts.homeLandlordInsurance[0]
			: {};
		const creditCardModel = associatedProducts?.creditCard?.length ? associatedProducts.creditCard[0] : {};
		const backupPlan = associatedProducts?.backUpPlan?.length ? associatedProducts.backUpPlan[0] : {};
		return {
			id: lendersMortgageInsurace?.id ?? -1,
			isLmiRequired: getNullableBoolean(lendersMortgageInsurace?.isLmiRequired),
			lmiExplained: lendersMortgageInsurace?.lmiExplained,
			lmiConfirmed: lendersMortgageInsurace?.lmiConfirmed,
			capitaliseLmi: getNullableBoolean(lendersMortgageInsurace?.capitaliseLmi),
			capitaliseLmiExplained: lendersMortgageInsurace?.capitaliseLmiExplained,
			isInsuranceRequired: getNullableBoolean(homeLandlordInsurance?.isInsuranceRequired),
			insuranceExplained: homeLandlordInsurance?.insuranceExplained,
			noInsuranceConfirmed: homeLandlordInsurance?.noInsuranceConfirmed,
			noInsuranceExplained: homeLandlordInsurance?.noInsuranceExplained,
			isCreditCardRequired: getNullableBoolean(creditCardModel?.isCreditCardRequired),
			creditCardExplained: creditCardModel?.creditCardExplained,
			isNotApplicable: backupPlan?.isNotApplicable,
			isImpacted: backupPlan?.isImpacted,
			isExpensed: backupPlan?.isExpensed,
			conversationDetails: backupPlan?.conversationDetails
		};
	}
}

interface AssociatedProductsModel {
	id: number;
	backUpPlan: BackUpPlanModel[];
	creditCard: ComplianceCreditCardModel[];
	homeLandlordInsurance: HomeLandlordInsuranceModel[];
	lendersMortgageInsurance: LendersMortgageInsuranceModel[];
}
