import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { URL_CONSTANTS } from '@app/modules/shared/constants/api.constants';
import { AuthService } from '@simpology/authentication';

import {
	defaultPoiSecureValue,
	IdentityDocumentDetailsModel,
	IdentityDocumentUploadModel,
	PoiDocTemplate,
	ProofOfIdentityDetailsModel,
	ProofOfIdentityModel,
	ProofOfIdentityModelTransformer
} from '@app/modules/applicants/models/proof-of-identity-details-model';
import { ProofOfIdentityUploadDocsService } from '@app/modules/applicants/services/proof-of-identity-upload-docs.service';
import { PersonApplicant } from '@app/modules/applicants/typings/applicants';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import {
	DocumentChecklistTemplateCategoryDTO,
	DocumentChecklistTemplateDetailDTO,
	DocumentFileModel,
	RequirementItemCommonDTO
} from '@app/modules/typings/document-checklist-template-details-dto';

import { ProofOfIdentityDTO } from '../typings/proof-of-identity-dto';
import { ProofOfIdentityStatusHandlerService } from './proof-of-identity-status-handler.service';
import { DigitalWidgetsConfigurationRepository } from '@app/modules/digital-widgets/store/digital-widgets-configuration.repository';

@Injectable({ providedIn: 'root' })
export class ProofOfIdentityService extends BaseJourneyService<ProofOfIdentityDTO> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private authService: AuthService,
		private proofOfIdentityUploadDocsService: ProofOfIdentityUploadDocsService,
		private proofOfIdentityStatusHandlerService: ProofOfIdentityStatusHandlerService,
		private digitalWidgetsConfigurationRepository: DigitalWidgetsConfigurationRepository
	) {
		super();
		this.setJourneyLadmRoute(URL_CONSTANTS.PROOF_OF_IDENTITY);
	}

	getSensitiveFieldValue(poiId: number, secureFieldName: string): Observable<{ value: string }> {
		return this.getCustom(`GetSensitiveFieldValue/${poiId}/${secureFieldName}`) as Observable<{ value: string }>;
	}

	getSensitiveDateFieldValue(poiId: number, secureFieldName: string): Observable<Date> {
		return <Observable<Date>>this.getCustom(`GetDateSensitiveFieldValue/${poiId}/${secureFieldName}`);
	}

	updatePOIForApplicant(
		applicantId: number,
		securePersonId: number,
		poiDocTemplate: PoiDocTemplate,
		proofOfIdentityModel: ProofOfIdentityModel,
		uploadedDocs: Array<File>
	): Observable<Array<DocumentFileModel>> {
		const documentNumber = proofOfIdentityModel.driversLicenceNumber ?? proofOfIdentityModel.documentNumber;
		const identityDocumentUploadModel = {
			proofOfIdentityId: proofOfIdentityModel.id,
			ladmApplicationId: this.applicationDataQuery.applicationId(),
			typeId: proofOfIdentityModel.documentTypeId,
			checklistItemName: poiDocTemplate.name,
			personApplicantId: applicantId,
			documentNumber: documentNumber === defaultPoiSecureValue ? null : documentNumber,
			cardNumber: proofOfIdentityModel.cardNumber === defaultPoiSecureValue ? null : proofOfIdentityModel.cardNumber,
			dateOfIssue: proofOfIdentityModel.dateOfIssue === defaultPoiSecureValue ? null : proofOfIdentityModel.dateOfIssue,
			countryOfIssueId: proofOfIdentityModel.countryOfIssueId,
			australianStateOfIssueId: proofOfIdentityModel.australianStateOfIssueId,
			placeOfIssue: proofOfIdentityModel.placeOfIssue,
			issuingOrganisation: proofOfIdentityModel.issuingOrganisation,
			expiryDate: proofOfIdentityModel.expiryDate === defaultPoiSecureValue ? null : proofOfIdentityModel.expiryDate,
			nameOnDocument: proofOfIdentityModel.nameOnDocument,
			middleNameOnDocument: proofOfIdentityModel.middleNameOnDocument,
			dateDocumentVerified: proofOfIdentityModel.dateDocumentVerified,
			medicareCardColourId: proofOfIdentityModel.medicareCardColourId,
			medicareCardIndividualReferenceNumber: proofOfIdentityModel.medicareCardIndividualReferenceNumber,
			isPreviousNameIdentification: !!proofOfIdentityModel.isPreviousNameIdentification,
			original: proofOfIdentityModel.original,
			certifiedCopy: proofOfIdentityModel.certifiedCopy,
			nameVerified: proofOfIdentityModel.nameVerified,
			dobVerified: proofOfIdentityModel.dobVerified,
			residentialAddressVerified: proofOfIdentityModel.residentialAddressVerified,
			photographVerified: proofOfIdentityModel.photographVerified,
			signatureVerified: proofOfIdentityModel.signatureVerified,
			personId: securePersonId
		} as unknown as IdentityDocumentUploadModel;

		return this.proofOfIdentityUploadDocsService
			.uploadIdentityDoc(identityDocumentUploadModel, uploadedDocs)
			.pipe(tap(() => this.digitalWidgetsConfigurationRepository.refreshDigitalWidgetForApplicant(applicantId)));
	}

	fetchPOIDocumentsForApplicant(personApplicant: PersonApplicant): Observable<ProofOfIdentityDetailsModel[]> {
		if (!personApplicant.securePersonId) {
			return of([]);
		}

		return forkJoin([
			this.getPOIApplicantDocuments(personApplicant),
			this.getPOITemplateDocuments(personApplicant)
		]).pipe(
			map(([poiApplicantDocuments, poiTemplateDocuments]) => {
				const poiHtmlInstructions = this.buildHtmlPoiInstructions(poiTemplateDocuments);

				const isPOIStatusCompleted = this.proofOfIdentityStatusHandlerService.isProofOfIdentityStatusCompleted(
					poiApplicantDocuments.map((item) => item.documentTypeId),
					poiTemplateDocuments
				);

				return poiTemplateDocuments
					.filter((poiDocTemplate) => poiDocTemplate.identityDocumentTypeEnum)
					.map((poiDocTemplate: PoiDocTemplate, index) => {
						let poiDoc = poiApplicantDocuments.find(
							(doc) => doc.documentTypeId === poiDocTemplate.identityDocumentTypeEnum
						);
						if (!poiDoc) {
							poiDoc = { documentTypeId: poiDocTemplate.identityDocumentTypeEnum } as ProofOfIdentityModel;
						}

						poiDoc.identityDocumentType = poiDocTemplate.identityDocumentType;
						poiDoc.categoryName = poiDocTemplate.categoryName;

						return {
							poiIndex: index,
							extract: this.buildUploadedDocumentExtract(poiDoc, poiDocTemplate),
							poiDocTemplate: poiDocTemplate,
							poiHtmlInstructions: poiHtmlInstructions,
							proofOfIdentityModal: poiDoc,
							isPOIStatusCompleted: isPOIStatusCompleted
						} as IdentityDocumentDetailsModel;
					});
			}),
			map((identityDocumentDetails) =>
				identityDocumentDetails.map(
					(detailsModel) =>
						({
							identityDocumentDetails: detailsModel
						} as ProofOfIdentityDetailsModel)
				)
			)
		);
	}

	private buildHtmlPoiInstructions(poiDocTemplates: PoiDocTemplate[]) {
		const instructionsDoc = poiDocTemplates
			.filter((doc) => doc.instructionHtml)
			.map((doc) => `<li>${doc.instructionHtml}</li>` ?? []);

		if (instructionsDoc.length === 0) {
			return;
		}

		const htmlInstructionsList: Array<string> = [];
		htmlInstructionsList.push('Please provide following documents:');
		htmlInstructionsList.push(`<ul>${instructionsDoc.join('')}</ul>`);
		return htmlInstructionsList.join('');
	}

	private buildUploadedDocumentExtract(poiDocs: ProofOfIdentityModel | undefined, docTemplate: PoiDocTemplate): string {
		return poiDocs && poiDocs.uploadedDocs?.length > 0
			? `${docTemplate.name} (Uploaded - ${poiDocs.uploadedDocs.length})`
			: docTemplate.name;
	}

	private getPOIApplicantDocuments(personApplicant: PersonApplicant): Observable<ProofOfIdentityModel[]> {
		return this.getCustom(
			`${this.authService.channelId}/${this.applicationDataQuery.applicationId()}/${personApplicant.id}/${
				personApplicant.securePersonId
			}`
		).pipe(
			map((payload: ProofOfIdentityDTO[]) =>
				payload?.map((poiDoc) => ProofOfIdentityModelTransformer.fromPayload(poiDoc))
			)
		);
	}

	private getPOITemplateDocuments(personApplicant: PersonApplicant): Observable<PoiDocTemplate[]> {
		const endpoint = `GetDocumentChecklistTemplateDetailForApplicant/${this.applicationDataQuery.applicationId()}/${
			personApplicant.primaryApplicant
		}`;

		return this.getCustom(endpoint).pipe(
			map((dtoResult: DocumentChecklistTemplateDetailDTO) => dtoResult?.categories),
			map((categoryDTOS: DocumentChecklistTemplateCategoryDTO[]) => {
				return this.flattenDocumentChecklistTemplateDetaiForPoiDocuments(categoryDTOS);
			})
		);
	}

	private flattenDocumentChecklistTemplateDetaiForPoiDocuments(categoryDTOS: DocumentChecklistTemplateCategoryDTO[]) {
		const forProofOfIdentityCateg = categoryDTOS?.find((categ) => categ.forProofOfIdentity);

		if (!forProofOfIdentityCateg) {
			return [];
		}

		const poiDocTemplates: PoiDocTemplate[] = forProofOfIdentityCateg.checklistItems.map(
			(item) =>
				({
					categoryName: forProofOfIdentityCateg.categoryName,
					identityDocumentType: item.identityDocumentType,
					identityDocumentTypeEnum: item.identityDocumentTypeEnum,
					name: item.document,
					instructionShort: item.instructionShort,
					instructionHtml: item.instructionHtml,
					condition: item.requirementItem?.condition,
					conditionNumber: item.requirementItem?.conditionNumber
				} as PoiDocTemplate)
		);

		const childrenData: PoiDocTemplate[] = forProofOfIdentityCateg.checklistItems
			.filter((item) => !item.identityDocumentTypeEnum)
			.flatMap((checkListItem) =>
				this.getFlattenChildItemsRecursive(
					checkListItem?.requirementItem?.items,
					forProofOfIdentityCateg.categoryName,
					checkListItem.document
				)
			);

		return poiDocTemplates.concat(childrenData);
	}

	private getFlattenChildItemsRecursive(
		items: RequirementItemCommonDTO[],
		categoryName: string,
		parentName: string
	): PoiDocTemplate[] {
		if (!items) {
			return [];
		}

		const children: PoiDocTemplate[] = [];

		for (const item of items) {
			if (item.items) {
				children.push(...this.getFlattenChildItemsRecursive(item.items, categoryName, item.name));
			}

			children.push({
				categoryName: categoryName,
				identityDocumentType: item.identityDocumentType,
				identityDocumentTypeEnum: item.identityDocumentTypeEnum,
				name: item.name,
				instructionShort: item.instructionShort,
				instructionHtml: item.instructionHtml,
				condition: item.condition,
				conditionNumber: item.conditionNumber,
				parentName: parentName,
				numOfRequiredDocs: item.numOfRequiredDocs
			} as PoiDocTemplate);
		}

		return children;
	}
}
