import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyFileUploadModel } from './../../models/formly-file-upload.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
	selector: 'formly-file-image',
	templateUrl: './formly-file-image.component.html',
	styleUrls: ['./formly-file-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFileImageComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
	formlyFileUploadModel!: FormlyFileUploadModel;
	fileUrl!: SafeUrl | string;
	currentDate = new Date();

	readonly DEFAULT_WIDTH = 80;
	readonly DEFAULT_HEIGHT = 60;

	width = this.DEFAULT_WIDTH;
	height = this.DEFAULT_HEIGHT;

	private placeholderImages = {
		property: 'assets/images/placeholders/property-placeholder.svg',
		document: 'assets/images/placeholders/document-placeholder.svg'
	};

	private subscription: Subscription = Subscription.EMPTY;

	constructor(private sanitize: DomSanitizer) {
		super();
	}

	ngOnInit(): void {
		this.configFileImage();
		this.subscription = this.formControl.valueChanges.subscribe(() => {
			this.configFileImage();
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	createFileURL(file: File): SafeUrl {
		return this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(file));
	}

	private configFileImage() {
		this.formlyFileUploadModel = this.field.model as FormlyFileUploadModel;
		this.configDefaultProperties();
		this.setImageFromUrl();
	}

	private configDefaultProperties() {
		this.width = this.to?.min ?? this.DEFAULT_WIDTH;
		this.height = this.to?.max ?? this.DEFAULT_HEIGHT;
	}

	private setImageFromUrl() {
		const fieldValue = this.field.formControl?.value as string;
		if ('fileName' in this.formlyFileUploadModel) {
			this.setImageFromUploadField();
		} else {
			this.setImageFromStringField(fieldValue);
		}
	}

	private setImageFromUploadField() {
		if (this.formlyFileUploadModel.file) {
			this.fileUrl = this.createFileURL(this.formlyFileUploadModel.file);
			return;
		}

		if (this.formlyFileUploadModel.thumbnailLocationUrl) {
			this.fileUrl = this.formlyFileUploadModel.thumbnailLocationUrl;
			return;
		}

		this.fileUrl = this.getDefaultPlaceholderImage();
	}

	private setImageFromStringField(fieldValue: string) {
		if (fieldValue) {
			this.fileUrl = fieldValue;
			return;
		}

		this.fileUrl = this.getDefaultPlaceholderImage();
	}

	private getDefaultPlaceholderImage(): string {
		if (!this.to.placeholder) {
			return this.placeholderImages.document;
		}

		switch (this.to.placeholder) {
			case 'property':
				return this.placeholderImages.property;
			case 'document':
				return this.placeholderImages.document;
			default:
				return this.placeholderImages.document;
		}
	}
}
