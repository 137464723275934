import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { SignificantChangeDocumentChecklistDTO } from '@app/modules/typings/api';

export class DocumentChecklistTransformer {
	static fromPayload(payload?: SignificantChangeDocumentChecklistDTO): DocumentChecklistModel {
		return {
			id: payload?.id,
			savingsOuter: {
				options: payload?.savingsOption?.optionEnums.length ? [1000] : []
			},
			savings: {
				options: payload?.savingsOption?.optionEnums ?? []
			},
			savingsOfiOuter: {
				options: payload?.ofiDocumentsOption?.optionEnums.length ? [100] : []
			},
			savingsOfi: {
				options: payload?.ofiDocumentsOption?.optionEnums ?? []
			},
			saleOfOtherAssets: {
				options: payload?.saleOfOtherAssetsOption?.optionEnums ?? []
			},
			additionalIncomeSource: {
				options: payload?.additionalIncomeSourceOption?.optionEnums ?? []
			},
			otherPlan: {
				options: payload?.otherPlanOption?.optionEnums ?? []
			},
			recurringIncomeFromSuper: {
				options: payload?.recurringIncomeFromSuperOption?.optionEnums ?? []
			},
			superannuationLumpSum: {
				options: payload?.superannuationLumpSumOption?.optionEnums ?? []
			},
			incomeFromOtherInvestments: {
				options: payload?.incomeFromOtherInvestmentsOption?.optionEnums ?? []
			}
		};
	}

	static toPayload(model: DocumentChecklistModel, applicationId: number): SignificantChangeDocumentChecklistDTO {
		return {
			id: model.id ?? CONSTANTS.NEW_ID,
			applicationId,
			savingsOption: {
				optionEnums:
					model.savings?.options.length && model.savingsOuter?.options.length
						? model.savings?.options
						: model.savingsOuter?.options.length
						? model.savingsOuter?.options
						: []
			},
			ofiDocumentsOption: {
				optionEnums:
					model.savingsOfi?.options.length && model.savingsOfiOuter?.options.length
						? model.savingsOfi?.options
						: model.savingsOfiOuter?.options.length
						? model.savingsOfiOuter?.options
						: []
			},
			saleOfOtherAssetsOption: {
				optionEnums: model.saleOfOtherAssets?.options ?? []
			},
			additionalIncomeSourceOption: {
				optionEnums: model.additionalIncomeSource?.options ?? []
			},
			otherPlanOption: {
				optionEnums: model.otherPlan?.options ?? []
			},
			recurringIncomeFromSuperOption: {
				optionEnums: model.recurringIncomeFromSuper?.options ?? []
			},
			superannuationLumpSumOption: {
				optionEnums: model.superannuationLumpSum?.options ?? []
			},
			incomeFromOtherInvestmentsOption: {
				optionEnums: model.incomeFromOtherInvestments?.options ?? []
			}
		};
	}
}

export interface DocumentChecklistModel {
	id?: number;
	savingsOuter?: MultiCheckboxModel;
	savingsOfiOuter?: MultiCheckboxModel;
	savings?: MultiCheckboxModel;
	savingsOfi?: MultiCheckboxModel;
	saleOfOtherAssets?: MultiCheckboxModel;
	additionalIncomeSource?: MultiCheckboxModel;
	otherPlan?: MultiCheckboxModel;
	recurringIncomeFromSuper?: MultiCheckboxModel;
	superannuationLumpSum?: MultiCheckboxModel;
	incomeFromOtherInvestments?: MultiCheckboxModel;
}
