import { Injectable } from '@angular/core';
import { savedSuccessfullyMessage } from '@app/modules/shared/helper/util';
import { BaseJourneyService } from '@app/modules/shared/service/base-journey.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { ToastrService } from 'ngx-toastr';
import { map, Observable } from 'rxjs';
import {
	DocumentDeliveryMethod,
	DocumentDeliveryMethodModelTransformer
} from '../model/document-delivery-method.model';
import { DocumentInstruction, DocumentInstructionsModelTransformer } from '../model/document-instructions.model';
import { DocumentDeliveryMethodDTO, DocumentInstructionDTO } from '../typings/setup';

@Injectable({ providedIn: 'root' })
export class DocumentInstructionsService extends BaseJourneyService<DocumentInstruction> {
	constructor(
		public toastr: ToastrService,
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsQuery: FormEnumsQuery,
		private simpFormlyHandlerService: SimpFormlyHandlerService
	) {
		super();
		this.setLadmRoute('FullApplication/DocumentationInstruction');
	}

	fetchDocumentInstructions(): Observable<DocumentInstruction[]> {
		return this.getCustom(`${this.applicationDataQuery.applicationId()}`).pipe(
			map((documentInstructionsDTO: DocumentInstructionDTO) => {
				if (documentInstructionsDTO) {
					return [DocumentInstructionsModelTransformer.fromPayload(documentInstructionsDTO, this.formEnumsQuery)];
				} else {
					return [{}];
				}
			})
		);
	}

	saveDocumentInstructions(documentInstruction: DocumentInstruction): void {
		const payload = DocumentInstructionsModelTransformer.toPayload(documentInstruction);
		this.postCustom(`${this.applicationDataQuery.applicationId()}`, payload).subscribe((id: number) => {
			documentInstruction.id = id;
			this.simpFormlyHandlerService.upsertToFullPathWithData('instructions', [documentInstruction]);
			this.toastr.success(savedSuccessfullyMessage('Documentation Instructions'));
		});
	}

	fetchDocumentDeliveryMethod(): Observable<DocumentDeliveryMethod[]> {
		return this.getCustom(`GetApplicationDocumentInstruction/${this.applicationDataQuery.applicationId()}`).pipe(
			map((documentDeliveryMethodDTO: DocumentDeliveryMethodDTO) => {
				if (documentDeliveryMethodDTO) {
					return [DocumentDeliveryMethodModelTransformer.fromPayload(documentDeliveryMethodDTO)];
				} else {
					return [{}];
				}
			})
		);
	}

	saveDocumentDeliveryMethod(documentDeliveryMethod: DocumentDeliveryMethod, showSuccessToast = true): void {
		const payload = DocumentDeliveryMethodModelTransformer.toPayload(documentDeliveryMethod);
		this.postCustom(
			`SaveApplicationDocumentationInstruction/${this.applicationDataQuery.applicationId()}`,
			payload
		).subscribe((id: number) => {
			documentDeliveryMethod.id = id;
			this.simpFormlyHandlerService.upsertToFullPathWithData('documentDeliveryMethod', [documentDeliveryMethod]);
			if (showSuccessToast) {
				this.toastr.success(savedSuccessfullyMessage('Document delivery method'));
			}
		});
	}
}
