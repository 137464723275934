import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';

import { ENV_CONFIG } from '@app/env-config';
import { IdentityDocumentUploadModel } from '@app/modules/applicants/models/proof-of-identity-details-model';
import { DocumentFileModel } from '@app/modules/typings/document-checklist-template-details-dto';

@Injectable({
	providedIn: 'root'
})
export class ProofOfIdentityUploadDocsService extends BaseApiService<unknown> {
	constructor(http: HttpClient, toastr: ToastrService, router: Router) {
		super(http, toastr, router, ENV_CONFIG);
		this.setRoute('Loanapp/Document');
	}

	uploadIdentityDoc(
		identityDocumentUploadModel: IdentityDocumentUploadModel,
		files: Array<File>
	): Observable<Array<DocumentFileModel>> {
		const formData: FormData = this.getFormData(identityDocumentUploadModel, files);
		return <Observable<Array<DocumentFileModel>>>this.postCustom(`UploadIdentityDoc`, formData);
	}

	private getFormData(identityDocumentUploadModel: IdentityDocumentUploadModel, files: Array<File>): FormData {
		const formData: FormData = new FormData();
		formData.append('jsonModelData', JSON.stringify(identityDocumentUploadModel));

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			formData.append(`files${i}`, file, file.name);
		}

		return formData;
	}
}
