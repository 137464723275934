<ng-container *ngIf="showTick$ | async as showTick; else default">
	<ng-container *ngIf="!showTick"><ng-container *ngTemplateOutlet="default"></ng-container></ng-container>
	<div [id]="uniqueId" *ngIf="showTick" class="tick-box tick-box--selected simp-text--secondary">
		<i class="fal fa-check"></i>
	</div>
</ng-container>

<ng-template #default>
	<div class="tick-box">{{ to.label }}</div>
</ng-template>
