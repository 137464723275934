import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
	DeclaredExpenseModel,
	ExpenseHeaderModel,
	HemCommentModel,
	HouseholdExpensesModel
} from '@app/modules/financial-position/model/expenses.model';
import { FrequencyShort } from '@simpology/client-components/utils';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ExpenseCategory, ExpenseSource } from '../../enums/app.enums';
import { TextOption } from '../../model/text-option';
import { ApplicationDataService } from '../application-data/application-data.service';
import { FormEnumsQuery } from '../form-enums/form-enums.query';
import { ExpensesStore } from './expenses.store';
import { ExpenseStateModel } from './typings/expenses';

@Injectable({ providedIn: 'root' })
export class ExpensesQuery {
	isDigitalInStore$ = this.store.select((state) => state.isDigitalInStore ?? []);
	showDeclared$ = this.store.select((state) => state.showDeclared);

	constructor(
		protected store: ExpensesStore,
		private formEnumsQuery: FormEnumsQuery,
		private applicationDataService: ApplicationDataService
	) {}

	mapToDeclaredExpense = (
		expenseCategory: number,
		currentFormGroup: UntypedFormGroup,
		addInvalidRows?: boolean
	): Observable<Partial<DeclaredExpenseModel>[]> =>
		this.store.select((state) => {
			const expenses: DeclaredExpenseModel[] = state.declared
				.filter((d) => d.expenseCategory === expenseCategory)
				.map((expense) => ({ ...expense, ownership: expense.ownership === -1 ? undefined : expense.ownership }));
			const households = this.formEnumsQuery.getHouseHolds();
			const ownershipValues = this.applicationDataService.getHouseHoldAndApplicationsEnum();
			let invalidRows: DeclaredExpenseModel[] = [];

			households.forEach((household) => {
				const floor = this.getFloor(state, expenseCategory, household.id);
				const expenseRows = expenses.find((expense) => expense.householdId === household.id);
				const expenseTotal = state.categoriesTotal.find(
					(total) => total.householdId === household.id && total.expenseCategory === expenseCategory
				)?.declaredTotal;
				if (floor && !expenseRows && (!expenseTotal || (expenseTotal && expenseTotal < floor))) {
					invalidRows.push({
						householdId: household.id,
						value: undefined,
						isTotal: false,
						expenseCategory,
						arrowBoxText: '',
						ownership: ownershipValues.findIndex((x) => x.value.includes(household.id.toString())) ?? 0
					} as DeclaredExpenseModel);
				}
			});

			if (!addInvalidRows) {
				const currentInvalidRows =
					(currentFormGroup.controls.declaredExpense as UntypedFormArray)?.controls
						.filter((control) => control.invalid)
						.map((row) => {
							const rowValue = row.value as DeclaredExpenseModel;
							rowValue.householdId = Number(ownershipValues[rowValue.ownership as number].value.split('|')[0]);
							return rowValue;
						}) || [];

				invalidRows = currentInvalidRows.filter((row) =>
					invalidRows.find((invalid) => invalid.householdId === row.householdId)
				);
			}

			return [...expenses, ...invalidRows].map((expense) => ({
				...expense,
				hide: state.selectedHousehold !== 0 && state.selectedHousehold !== (expense.householdId as number)
			}));
		});

	selectExpenseDetailsByHouseholdId$ = (householdId: number): Observable<HouseholdExpensesModel> => {
		return this.store.select().pipe(
			map((res: ExpenseStateModel) => {
				return {
					id: householdId,
					digitalTotal: {
						amount: res.categoriesTotal
							.filter((total) => total.householdId === householdId)
							.map((total) => total.digitalTotal)
							.reduce((a, b) => +a + +b, 0),
						frequency: FrequencyShort.Monthly
					},
					declaredTotal: res.categoriesTotal
						.filter((total) => total.householdId === householdId)
						.map((total) => total.declaredTotal)
						.reduce((a, b) => +a + +b, 0),
					showDeclared: res.showDeclared
				};
			})
		);
	};

	selectTotals = (expenseCategory?: number): Observable<ExpenseHeaderModel> =>
		this.store.select().pipe(
			withLatestFrom(this.totalCount(expenseCategory)),
			map(([res, totalCount]: [ExpenseStateModel, number]) => {
				const category = (res.categoriesTotal || [])
					.filter((d) => d.expenseCategory === expenseCategory)
					.find((d) => (res.selectedHousehold ? d.householdId === res.selectedHousehold : d.householdId === null));

				const totals = expenseCategory ? category : res.sectionTotal;

				return {
					categoryId: expenseCategory,
					shouldUpdate: res.shouldUpdateCategoriesTotal,
					digitalTotal: totals?.digitalTotal
						? {
								amount: totals.digitalTotal || 0,
								frequency: FrequencyShort.Monthly
						  }
						: {
								amount: 0,
								frequency: FrequencyShort.Monthly
						  },
					declaredTotal: totals?.declaredTotal,
					declaredEdit: totals?.declaredTotal
						? {
								amount: totals.declaredTotal || 0,
								frequency: FrequencyShort.Monthly
						  }
						: {
								amount: 0,
								frequency: FrequencyShort.Monthly
						  },
					expenseSource: res.isDigitalInStore?.find((c) => c.expenseCategory === expenseCategory)?.isDigital
						? ExpenseSource.Digital
						: ExpenseSource.Declared,
					isTotal: category?.isTotal ?? true,
					totalCount,
					editFromCollapsed: totalCount < 2,
					showDeclared: res.showDeclared,
					differenceInDeclaredToDigitalTotal: totals?.differenceInDeclaredToDigitalTotal ?? '',
					differenceInDigitalTotalValuesText: expenseCategory
						? category?.differenceInDigitalTotalValuesText ?? ''
						: res.sectionTotal.differenceInDigitalTotalValuesText ?? '',
					selectedHousehold: res.selectedHousehold
				};
			})
		);

	getExpenseField = (expenseCategory?: number, householdId?: number): DeclaredExpenseModel | undefined => {
		return this.store
			.getValue()
			.declared.find((d) => d.expenseCategory === expenseCategory && (!householdId || d.householdId === householdId));
	};

	hemBanners = (expenseCategory: ExpenseCategory): Observable<HemCommentModel[]> => {
		return this.store.select((state) => {
			return state.categoriesTotal
				.filter(
					(total) =>
						(!state.selectedHousehold || total.householdId === state.selectedHousehold) &&
						total.householdId &&
						total.declaredTotal !== undefined
				)
				.filter((total) => {
					const floorValue = this.getFloor(state, expenseCategory, total.householdId);

					return (
						floorValue &&
						total.householdId &&
						total.expenseCategory === expenseCategory &&
						total.declaredTotal <= floorValue
					);
				})
				.map((total) => ({
					hem: this.getFloor(state, expenseCategory, total.householdId),
					householdId: total.householdId,
					householdName: this.formEnumsQuery.getHouseHolds().find((household) => household.id === total.householdId)
						?.label,
					expenseCategory,
					comment: state.expenseFloorComments.find(
						(comment) => comment.householdId === total.householdId && comment.expenseCategory === total.expenseCategory
					)?.comment
				}));
		});
	};

	notMetHEMAndNoComment = (): Observable<number> => {
		return this.store.select((state) => {
			return state.expenseFloors.filter((floor) => {
				const categoryTotalByHousehold = state.categoriesTotal.find(
					(total) => total.expenseCategory === floor.categoryId && total.householdId === floor.householdId
				);

				return (
					(!categoryTotalByHousehold?.declaredTotal || categoryTotalByHousehold.declaredTotal < floor.floorValue) &&
					!state.expenseFloorComments.find(
						(comment) => comment.householdId === floor.householdId && comment.expenseCategory === floor.categoryId
					)
				);
			}).length;
		});
	};

	selectedHouseholdId = () => this.store.getValue().selectedHousehold;

	categoriesWithNullHouseholds = (householdsEnum: TextOption[]): Observable<number[]> => {
		return this.store.select((state) => {
			return state.declared
				.filter(
					(declared) =>
						!declared.householdId ||
						!householdsEnum.find((household) => household.value.includes(declared.householdId?.toString() as string))
				)
				.map((declared) => declared.expenseCategory);
		});
	};

	totalCount = (expenseCategory?: number): Observable<number> => {
		return this.store.select((state) => {
			return (
				(state.categoriesTotal || [])
					.filter(
						(d) =>
							d.expenseCategory === expenseCategory &&
							(!state.selectedHousehold ? d.householdId === null : d.householdId === state.selectedHousehold)
					)
					.map((d) => (!state.showDeclared ? d.digitalCount : d.declaredTotal ? d.declaredCount : 0))
					.reduce((a, b) => a + b, 0) || 0
			);
		});
	};

	private getFloor(state: ExpenseStateModel, expenseCategory: ExpenseCategory, householdId: number): number {
		const floor = state.expenseFloors.find(
			(expenseFloors) => expenseFloors.categoryId === expenseCategory && expenseFloors.householdId === householdId
		)?.floorValue;
		if (floor) {
			return Math.floor(floor * 100) / 100;
		}
		return 0;
	}
}
