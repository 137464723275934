import { CONSTANTS } from '@app/modules/shared/constants/constants';
import {
	AssetTransaction,
	CommercialType,
	Holding,
	IndustrialType,
	PrimaryPurposeRealEstateAsset,
	PrimaryUsage,
	Proportions,
	ResidentialType,
	RuralType,
	RuralUsage,
	SecurityPriority,
	TenureType,
	TitleSystem,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import {
	getNotNullBoolean,
	getNullableBoolean,
	getNullableYesNo,
	isNullOrUndefined
} from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { PercentageOwned, PercentageOwnedTransformer } from '@app/modules/shared/model/percentage-owned.model';
import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { Address, LendingGuaranteeSecurityDTO, PropertyDTO } from '@app/modules/typings/api';
import { CurrencyHelper } from '@simpology/client-components/utils';

export interface PropertyDetails {
	id?: number;
	isEqualShare: boolean;
	percentsOwned?: PercentageOwned[];
	useAsSecurity: number;
	toBeSold?: boolean;
	primarySecurity: YesNo;
	enableLimitedGuarantee?: boolean;
	isLimitedGuarantee?: YesNo;
	guaranteeAmount?: number;
	primaryUse: number;
	purpose: number;
	value: AmountSelect;
	estimatedValueValuedDate?: string;
	status: number;
	propertyTypeCommercial: CommercialType;
	propertyTypeIndustrial: IndustrialType;
	propertyTypeResidential: ResidentialType;
	propertyTypeRural: RuralType;
	tenureType: TenureType;
	titleSystem?: TitleSystem;
	holding?: Holding;
	specialistDisabilityAccomodation?: YesNo;
	specialistDisabilityAccomodationProviderName?: string;
	ruralUsage?: RuralUsage;
	propertyExpense?: AmountSelect;
	LendingGuaranteeId?: number;
	isConstruction?: YesNo;
	ndis: YesNo;
	holidayRental: YesNo;
	isNRASProperty: YesNo;
	nrasConsortium: number;
	isForeign: boolean;
	certifiedValue: number;
	certifiedValueValuedDate?: string;
	propertyTypeName?: string;
	policyNumber?: string;
	insuredAmount?: number;
	securityPriority?: SecurityPriority;
	noOfBuildings?: number;
	own3unitsOfComplex?: YesNo;
}

export class PropertyDetailsModelTransformer {
	static toPayload(
		applicationId: number,
		propertyDetails: PropertyDetails,
		transaction: AssetTransaction,
		address?: Address,
		approvalInPrinciple?: YesNo
	): PropertyDTO {
		if (address) {
			address.applicationId = applicationId;
		}
		const propertyDto = {
			id: propertyDetails.id ?? CONSTANTS.NEW_ID,
			applicationId: applicationId,
			transaction: transaction,
			address: address,
			approvalInPrinciple: getNullableBoolean(approvalInPrinciple),
			estimatedValue: CurrencyHelper.unformatAmount(String(propertyDetails.value?.amount)),
			propertyEvaluationType: propertyDetails.value.frequency || 0,
			estimatedValueValuedDate: propertyDetails.estimatedValueValuedDate,
			status: propertyDetails.status,
			primaryUsage: propertyDetails.primaryUse,
			primaryPurpose: propertyDetails.purpose,
			holding: propertyDetails.holding,
			specialistDisabilityAccomodation: getNullableBoolean(propertyDetails.specialistDisabilityAccomodation),
			specialistDisabilityAccomodationProviderName: propertyDetails.specialistDisabilityAccomodationProviderName,
			residentialType: propertyDetails.propertyTypeResidential,
			commercialType: propertyDetails.propertyTypeCommercial,
			industrialType: propertyDetails.propertyTypeIndustrial,
			ruralType: propertyDetails.propertyTypeRural,
			titleSystem: propertyDetails.titleSystem,
			tenureType: propertyDetails.tenureType,
			toBeUsedAsSecurity: getNotNullBoolean(propertyDetails.useAsSecurity),
			toBeSold: propertyDetails.toBeSold,
			ndis: getNullableBoolean(propertyDetails.ndis),
			holidayRental: getNullableBoolean(propertyDetails.holidayRental),
			isNRASProperty: getNullableBoolean(propertyDetails.isNRASProperty),
			nrasConsortium: getNullableBoolean(propertyDetails.isNRASProperty) ? propertyDetails.nrasConsortium : null,
			primarySecurity: getNotNullBoolean(propertyDetails.primarySecurity),
			percentOwnedProportions: propertyDetails.isEqualShare ? Proportions.Equal : Proportions.Specified,
			percentsOwned: propertyDetails.percentsOwned?.map((percentOwned) => ({
				...percentOwned,
				percent: Number(percentOwned.percent)
			})),
			ruralUsage: propertyDetails.primaryUse === PrimaryUsage.Rural ? propertyDetails.ruralUsage : undefined,
			lendingGuaranteeSecurity: PropertyDetailsModelTransformer.toPayloadLendingGuarantee(propertyDetails),
			isConstruction: getNullableBoolean(propertyDetails.isConstruction),
			isForeign: propertyDetails.isForeign,
			policyNumber: getNullableBoolean(propertyDetails.isNRASProperty) ? propertyDetails.policyNumber : undefined,
			insuredAmount: getNullableBoolean(propertyDetails.isNRASProperty)
				? CurrencyHelper.unformatAmount(String(propertyDetails.insuredAmount))
				: undefined,
			securityPriority: propertyDetails.securityPriority,
			numberOfBuildings: propertyDetails.noOfBuildings,
			willOwn3UnitsInComplex: getNullableBoolean(propertyDetails.own3unitsOfComplex)
		} as PropertyDTO;

		if (propertyDetails.purpose === PrimaryPurposeRealEstateAsset.Investment) {
			propertyDto.propertyExpenseAmount = propertyDetails.propertyExpense?.amount;
			propertyDto.propertyExpenseFrequency = propertyDetails.propertyExpense?.frequency;
		}

		return propertyDto;
	}

	static fromPayload(propertyDto: PropertyDTO, applicants: ShortApplicant[]): PropertyDetails {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			propertyDto.percentsOwned,
			applicants
		);

		const propertyDetails = {
			id: propertyDto.id,
			isEqualShare: propertyDto.percentOwnedProportions === Proportions.Equal,
			percentsOwned: percentageOwned,
			useAsSecurity: getNullableYesNo(propertyDto.toBeUsedAsSecurity),
			toBeSold: propertyDto.toBeSold,
			ndis: getNullableYesNo(propertyDto.ndis),
			holidayRental: getNullableYesNo(propertyDto.holidayRental),
			isNRASProperty: getNullableYesNo(propertyDto.isNRASProperty),
			nrasConsortium: propertyDto.nrasConsortium,
			primarySecurity: getNullableYesNo(propertyDto.primarySecurity),
			enableLimitedGuarantee: !!propertyDto?.lendingGuaranteeSecurity?.limited,
			isLimitedGuarantee: getNullableYesNo(propertyDto?.lendingGuaranteeSecurity?.limited),
			guaranteeAmount: propertyDto.lendingGuaranteeSecurity?.limit,
			primaryUse: propertyDto.primaryUsage,
			purpose: propertyDto.primaryPurpose,
			value: {
				amount: propertyDto.estimatedValue,
				frequency: propertyDto.propertyEvaluationType
			},
			estimatedValueValuedDate: propertyDto.estimatedValueValuedDate,
			status: propertyDto.status,
			propertyTypeCommercial: propertyDto.commercialType,
			propertyTypeIndustrial: propertyDto.industrialType,
			propertyTypeResidential: propertyDto.residentialType,
			propertyTypeRural: propertyDto.ruralType,
			tenureType: propertyDto.tenureType,
			titleSystem: propertyDto.titleSystem,
			holding: propertyDto.holding,
			specialistDisabilityAccomodation: getNullableYesNo(propertyDto.specialistDisabilityAccomodation),
			specialistDisabilityAccomodationProviderName: propertyDto.specialistDisabilityAccomodationProviderName,
			ruralUsage: propertyDto.ruralUsage,
			LendingGuaranteeId: propertyDto.lendingGuaranteeSecurity?.lendingGuaranteeId,
			isForeign: propertyDto.isForeign,
			certifiedValue: propertyDto.certifiedValue,
			certifiedValueValuedDate: propertyDto.certifiedValueDate,
			propertyTypeName: propertyDto.propertyTypeName,
			policyNumber: propertyDto.policyNumber,
			insuredAmount: propertyDto.insuredAmount,
			securityPriority: propertyDto.securityPriority,
			noOfBuildings: propertyDto.numberOfBuildings ?? undefined,
			own3unitsOfComplex: getNullableYesNo(propertyDto.willOwn3UnitsInComplex)
		} as PropertyDetails;

		if (!isNullOrUndefined(propertyDto.propertyExpenseAmount) && propertyDto.propertyExpenseFrequency) {
			propertyDetails.propertyExpense = {
				amount: propertyDto.propertyExpenseAmount || 0,
				frequency: propertyDto.propertyExpenseFrequency
			};
		}

		return propertyDetails;
	}

	private static toPayloadLendingGuarantee(propertyDetails: PropertyDetails): LendingGuaranteeSecurityDTO {
		return {
			lendingGuaranteeId: propertyDetails.LendingGuaranteeId ?? CONSTANTS.NEW_ID,
			limit: propertyDetails.isLimitedGuarantee === YesNo.Yes ? propertyDetails.guaranteeAmount : undefined,
			limited: getNotNullBoolean(propertyDetails.isLimitedGuarantee)
		} as LendingGuaranteeSecurityDTO;
	}
}
