import { AuState, CountryCodeLimited, ExemptStatus, OECDCRSStatus, YesNo } from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { CompanyDetailsDTO } from '@app/modules/typings/api';

export class CompanyDetailsTransformer {
	static fromPayload(details: CompanyDetailsDTO): CompanyDetailsModel {
		return {
			applicationId: details.applicationId,
			id: details.id,
			abn: details.abn,
			abnVerified: details?.abnVerified,
			typeOfIncorporation: details.typeOfIncorporation,
			holdingCompany: details.holdingCompany,
			companyName: details.companyName,
			businessName: details.businessName,
			acn: details.acn,
			registeredForGST: details.registeredForGST ?? false,
			gstRegisteredDate: details.gstRegisteredDate,
			registrationDate: details.registrationDate,
			registeredInCountry: details.registeredInCountry,
			registeredInState: details.registeredInState,
			isABNVerified: details.isABNVerified,
			licenceNumber: details.licenceNumber,
			publicAuthority: getNullableYesNo(details.publicAuthority),
			exemptStatusId: details.exemptStatusId,
			oecdcrsStatusId: details.oecdcrsStatusId
		};
	}

	static toPayload(applicationId: number, details: CompanyDetailsModel): Partial<CompanyDetailsDTO> {
		return {
			applicationId: applicationId,
			id: details.id,
			abn: details.abn,
			abnVerified: details?.abnVerified,
			typeOfIncorporation: details.typeOfIncorporation,
			holdingCompany: details.holdingCompany,
			companyName: details.companyName,
			businessName: details.businessName,
			acn: details.acn,
			registeredForGST: details.registeredForGST ?? false,
			gstRegisteredDate: details.gstRegisteredDate,
			registrationDate: details.registrationDate,
			registeredInCountry: details.registeredInCountry,
			registeredInState: details.registeredInState,
			licenceNumber: details.licenceNumber,
			publicAuthority: getNullableBoolean(details.publicAuthority),
			exemptStatusId: details.exemptStatusId,
			oecdcrsStatusId: details.oecdcrsStatusId
		};
	}
}

export interface CompanyDetailsModel {
	applicationId: number;
	id: number;
	abn?: number;
	abnVerified?: boolean;
	typeOfIncorporation?: string;
	holdingCompany?: boolean;
	companyName?: string;
	businessName?: string;
	acn?: number;
	registeredForGST?: boolean;
	gstRegisteredDate?: string;
	registrationDate?: string;
	registeredInCountry?: CountryCodeLimited;
	registeredInState?: AuState;
	isABNVerified?: boolean;
	licenceNumber?: string;
	publicAuthority?: YesNo;
	exemptStatusId?: ExemptStatus;
	oecdcrsStatusId?: OECDCRSStatus;
}
