import { AmountSelect } from '@app/modules/simp-formly/typings/formly-app';
import { PercentOwned, SavingsDTO } from '@app/modules/typings/api';
import { CONSTANTS } from '../constants/constants';
import { AssetToBeUsedAsType, FinancialAssetType, FinancialInstitution, Proportions } from '../enums/app.enums';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { ShortApplicant } from './applicant.model';
import { PercentageOwnedTransformer } from './percentage-owned.model';
import { SavingModel } from './saving.model';

export class OtherSecuritiesModelTransformer {
	static toPayload(otherSecuritiesModel: OtherSecuritiesModel, applicationId: number): Partial<SavingsDTO> {
		return {
			id: otherSecuritiesModel.id,
			applicationId: applicationId,
			type: otherSecuritiesModel.details.otherSecuritiesDetails.savingType,
			value: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.currentBalance?.amount,
			estimateBasisNonRealEstateAssetId:
				otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.currentBalance?.frequency,
			percentOwnedProportions: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.isEqualShare
				? Proportions.Equal
				: Proportions.Specified,
			financialInstitution: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails
				.financialInstitution as FinancialInstitution,
			otherFinancialInstitutionName:
				otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.otherFinancialInstitutionName,
			percentsOwned: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.percentsOwned,
			bsb: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.bsb,
			accountNumber: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.accountNumber,
			assetToBeUsedAsType: otherSecuritiesModel.details.otherSecuritiesDetails.assetDetails.tobeUsedAs
		};
	}

	static fromPayload(
		savings: SavingModel,
		applicants: ShortApplicant[],
		formEnumsQuery: FormEnumsQuery
	): OtherSecuritiesModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			savings?.details.savingDetails.percentsOwned,
			applicants
		);
		const assetTypeLabel = formEnumsQuery.getOptionLabel(
			'FinancialAssetType',
			savings.details.savingDetails.financialAssetType
		);
		const financialInstitution = formEnumsQuery.getOptionLabel(
			'FinancialInstitution',
			savings.details.savingDetails.financialInstitution
		);
		return {
			id: savings.id,
			summaryAmount: savings.value,
			value: savings.value,
			details: {
				otherSecuritiesDetails: {
					savingType: savings.details.savingDetails.financialAssetType,
					assetDetails: {
						isEqualShare: savings.details.savingDetails.isEqualShare,
						currentBalance: savings.details.savingDetails.estimatedValue,
						financialInstitution: savings.details.savingDetails.financialInstitution,
						otherFinancialInstitutionName: savings.details.savingDetails.otherFinancialInstitutionName,
						percentsOwned: percentageOwned,
						tobeUsedAs: AssetToBeUsedAsType.Security,
						accountNumber: savings.details.savingDetails.accountNumber,
						bsb: savings.details.savingDetails.bsb
					}
				},
				typeOfSecurity: { type: savings.details.savingDetails.financialAssetType },
				extract: `${assetTypeLabel} - ${financialInstitution}, ${savings.value}`
			}
		};
	}

	static prepopulateFromSavings(
		existingData: SavingModel,
		selectedType: number,
		applicants: ShortApplicant[]
	): OtherSecuritiesModel {
		const percentageOwned = PercentageOwnedTransformer.fromPayloadPercentageOwned(
			existingData.details.savingDetails.percentsOwned,
			applicants
		);
		return {
			id: existingData.id || CONSTANTS.NEW_ID,
			details: {
				otherSecuritiesDetails: {
					savingType: selectedType,
					assetDetails: {
						isEqualShare: existingData.details.savingDetails.isEqualShare,
						percentsOwned: percentageOwned,
						currentBalance: existingData.details.savingDetails.estimatedValue,
						financialInstitution: existingData.details.savingDetails.financialInstitution,
						accountNumber: existingData.details.savingDetails.accountNumber,
						bsb: existingData.details.savingDetails.bsb,
						tobeUsedAs: existingData.details.savingDetails.willBeUsedForDeposit
							? AssetToBeUsedAsType.Deposit
							: AssetToBeUsedAsType.Security
					}
				},
				typeOfSecurity: {
					type: selectedType
				}
			},
			summaryAmount: existingData.summaryAmount,
			value: existingData.value
		};
	}
}

export interface OtherSecuritiesModel {
	id?: number;
	details: OtherSecuritiesDetails;
	summaryAmount?: number;
	value?: number;
}

export interface OtherSecuritiesDetails {
	otherSecuritiesDetails: {
		savingType: number;
		assetDetails: AssetDetails;
	};
	typeOfSecurity: { type: FinancialAssetType };
	extract?: string;
}

export interface AssetDetails {
	isEqualShare?: boolean;
	currentBalance?: AmountSelect;
	financialInstitution?: FinancialInstitution;
	otherFinancialInstitutionName?: string;
	percentsOwned: PercentOwned[];
	tobeUsedAs: AssetToBeUsedAsType;
	accountNumber: string;
	bsb: string;
}
