import { Injectable } from '@angular/core';
import {
	RelatedCompanyModel,
	RelatedCompanyModelTransformer
} from '@app/modules/applicants/models/related-company.model';
import { RelatedPersonModel, RelatedPersonModelTransformer } from '@app/modules/applicants/models/related-person.model';
import { Observable, catchError, combineLatest, iif, map, of, switchMap } from 'rxjs';

import { SetUpApplicant } from '@app/modules/setup/typings/setup';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { RelatedCompanyDTO, RelatedPersonDTO } from '@app/modules/typings/api';
import { ApplicantEntityType, ApplicantRole, RelatedCompanyType, TargetType } from '../enums/app.enums';
import { ApplicantEnumObject } from '../enums/enum-helper';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { FormEnumsQuery } from '../store/form-enums/form-enums.query';
import { FormEnumsService } from '../store/form-enums/form-enums.service';
import { BaseJourneyService } from './base-journey.service';
import { ForeignTaxAssociationService } from './foreign-tax-association.service';

@Injectable({ providedIn: 'root' })
export class PersonsCompaniesEnumService extends BaseJourneyService<any> {
	constructor(
		private applicationDataQuery: ApplicationDataQuery,
		private formEnumsService: FormEnumsService,
		private formEnumQuery: FormEnumsQuery,
		private formDataService: FormDataService,
		private foreignTaxAssociationService: ForeignTaxAssociationService
	) {
		super();
		this.setJourneyLadmRoute();
	}

	fetchEntities(): Observable<ApplicantEnumObject[]> {
		return combineLatest([this.fetchPersons(), this.fetchCompanies()]).pipe(
			map(([persons, companies]: [ApplicantEnumObject[], ApplicantEnumObject[]]) => persons.concat(companies))
		);
	}

	fetchPersons(): Observable<ApplicantEnumObject[]> {
		return this.get(`/EntityTarget/Person/${this.applicationDataQuery.applicationId()}`).pipe(
			map((persons: ApplicantEnumObject[]) => {
				persons?.forEach((person) => {
					person.label = this.createPeopleLabel(person.firstName, person.lastName);
					person.groupBy = this.getGroupByPeople();
				});
				this.formEnumsService.updatePersonEnums(
					'Persons',
					persons.filter((person) => person.firstName !== null && person.lastName !== null)
				);
				const relatedPersons = persons.filter((person) => person.type === TargetType.RelatedPerson);
				this.formEnumsService.updatePersonEnums('RelatedPersons', relatedPersons);
				const personApplicants = persons.filter((person) => person.type === TargetType.PersonApplicant);
				this.formEnumsService.updatePersonEnums('PersonApplicants', personApplicants);
				this.initPersonApplicantsSubTypeEnums();
				return persons;
			})
		);
	}

	initPersonApplicantsSubTypeEnums(setupApplicants?: SetUpApplicant[]) {
		if (!setupApplicants) {
			setupApplicants = this.formDataService.getStateData<SetUpApplicant>('applicants');
		}
		if (!setupApplicants?.length) {
			return;
		}

		this.updatePersonApplicantsSubtypeEnums(
			'PersonApplicantsNoGuarantors',
			this.getPersonApplicantsWithNoGuarantors(setupApplicants)
		);
	}

	getCompanies(): void {
		this.fetchCompanies().subscribe();
	}

	fetchCompanies(): Observable<ApplicantEnumObject[]> {
		return this.get(`/EntityTarget/Company/${this.applicationDataQuery.applicationId()}`).pipe(
			map((companies: ApplicantEnumObject[]) => {
				companies?.forEach((company) => {
					company.label = this.createCompanyLabel(company.companyName);
					company.groupBy = this.getGroupByCompany();
				});
				const relatedCompanies = companies.filter((company) => company.type === TargetType.RelatedCompany);
				this.formEnumsService.updatePersonEnums('RelatedCompanies', relatedCompanies);
				const companyApplicants = companies.filter((company) => company.type === TargetType.CompanyApplicant);
				this.formEnumsService.updatePersonEnums('CompanyApplicants', companyApplicants);
				this.formEnumsService.updatePersonEnums('Companies', companies);
				this.formEnumsService.updatePersonEnums(
					'OtherCompanies',
					companies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Other || !x.relatedCompanyType)
				);

				this.formEnumsService.updatePersonEnums(
					'RelatedOtherCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Other || !x.relatedCompanyType)
				);
				this.formEnumsService.updatePersonEnums(
					'PAYGCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Payg || !x.relatedCompanyType)
				);
				this.formEnumsService.updatePersonEnums(
					'SelfEmployedCompanies',
					relatedCompanies.filter(
						(x) => x.relatedCompanyType === RelatedCompanyType.SelfEmployed || !x.relatedCompanyType
					)
				);
				this.formEnumsService.updatePersonEnums(
					'ForeignCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Foreign || !x.relatedCompanyType)
				);
				return relatedCompanies;
			}),
			catchError(() => of([]))
		);
	}

	fetchTrusts(): Observable<ApplicantEnumObject[]> {
		return this.get(`/EntityTarget/Trust/${this.applicationDataQuery.applicationId()}`).pipe(
			map((trusts: ApplicantEnumObject[]) => {
				trusts?.forEach((trust) => {
					trust.label = this.createTrustLabel(trust.trustName);
					trust.groupBy = this.getTrustGroupBy();
				});
				const trustApplicants = trusts.filter((trust) => trust.type === TargetType.TrustApplicant);
				this.formEnumsService.updatePersonEnums('Trusts', trustApplicants);
				return trusts;
			}),
			catchError(() => of([]))
		);
	}

	/**
	 * @deprecated
	 */
	getRelatedCompanies(): void {
		this.get(`/EntityTarget/Company/${this.applicationDataQuery.applicationId()}`).subscribe(
			(companies: ApplicantEnumObject[]) => {
				let relatedCompanies = companies.filter((x) => x.type === TargetType.RelatedCompany);
				if (relatedCompanies?.length) {
					relatedCompanies.map((company) => {
						company.label = `<i class="icon fa fa-building"></i> ${company.companyName}`;
						company.groupBy = this.getGroupByCompany();
					});
				} else {
					relatedCompanies = [];
				}

				this.formEnumsService.updatePersonEnums('RelatedCompanies', relatedCompanies);
				this.formEnumsService.updatePersonEnums(
					'OtherCompanies',
					companies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Other || !x.relatedCompanyType)
				);

				this.formEnumsService.updatePersonEnums(
					'RelatedOtherCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Other || !x.relatedCompanyType)
				);
				this.formEnumsService.updatePersonEnums(
					'PAYGCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Payg || !x.relatedCompanyType)
				);
				this.formEnumsService.updatePersonEnums(
					'SelfEmployedCompanies',
					relatedCompanies.filter(
						(x) => x.relatedCompanyType === RelatedCompanyType.SelfEmployed || !x.relatedCompanyType
					)
				);
				this.formEnumsService.updatePersonEnums(
					'ForeignCompanies',
					relatedCompanies.filter((x) => x.relatedCompanyType === RelatedCompanyType.Foreign || !x.relatedCompanyType)
				);
			}
		);
	}

	saveRelatedPersons(payload: RelatedPersonDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`/EntityTarget/RelatedPerson`, payload);
	}

	saveRelatedCompany(payload: RelatedCompanyDTO): Observable<number> {
		return <Observable<number>>this.postCustom(`/EntityTarget/RelatedCompany`, payload);
	}

	getRelatedPerson(id: number): Observable<RelatedPersonModel> {
		return this.getCustom(`/EntityTarget/RelatedPerson/${this.applicationDataQuery.applicationId()}/${id}`).pipe(
			switchMap((res: RelatedPersonDTO) =>
				iif(
					() => !!res.foreignTaxAssociationId,
					this.foreignTaxAssociationService.getForeignTaxAssociations(res.foreignTaxAssociationId as number).pipe(
						map((foreignTaxAssociation) => {
							res.foreignTaxAssociationId = foreignTaxAssociation.id;
							res.foreignTaxAssociationDTO = foreignTaxAssociation;
							return res;
						})
					),
					of(res)
				)
			),
			map((res) => RelatedPersonModelTransformer.fromPayload(res, this.applicationDataQuery.applicationId()))
		);
	}

	getRelatedCompany(id: number): Observable<RelatedCompanyModel> {
		return <Observable<RelatedCompanyDTO>>(
			this.getCustom(`/EntityTarget/RelatedCompany/${this.applicationDataQuery.applicationId()}/${id}`).pipe(
				map((res) =>
					RelatedCompanyModelTransformer.fromPayload(res, this.applicationDataQuery.applicationId(), this.formEnumQuery)
				)
			)
		);
	}

	private createPeopleLabel(firstName: string | undefined, lastName: string | undefined) {
		const fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim();
		return `<i class="icon fa fa-user"></i> ${fullName}`;
	}

	private getGroupByPeople() {
		return 'PEOPLE IN THIS APP';
	}

	private createCompanyLabel(companyName: string | undefined) {
		return `<i class="icon fa fa-building"></i> ${companyName}`;
	}

	private getGroupByCompany() {
		return 'COMPANIES IN THIS APP';
	}

	private createTrustLabel(trustName: string | undefined) {
		return `<i class="icon fa-solid fa-file-certificate"></i> ${trustName}`;
	}

	private getTrustGroupBy() {
		return 'TRUSTS IN THIS APP';
	}

	private updatePersonApplicantsSubtypeEnums(subTypeEnumKey: string, filteredApplicants: SetUpApplicant[]) {
		this.formEnumQuery
			.selectEnumOptions('PersonApplicants')
			.pipe(
				map((personApplicants) =>
					personApplicants.filter((personApplicant) =>
						filteredApplicants.some((filteredApplicant) => filteredApplicant.id === personApplicant.id)
					)
				)
			)
			.subscribe((applicants) =>
				this.formEnumsService.updatePersonEnums(subTypeEnumKey, applicants as unknown as ApplicantEnumObject[])
			);
	}

	private getPersonApplicantsWithNoGuarantors(setupApplicants: SetUpApplicant[]): SetUpApplicant[] {
		return setupApplicants.filter(
			(applicant) =>
				applicant.applicantTypeModal &&
				applicant.applicantTypeModal.type === ApplicantEntityType.PersonApplicant &&
				(applicant.applicantRole === ApplicantRole.Coborrower ||
					applicant.applicantRole === ApplicantRole.PrimaryBorrower)
		);
	}
}
