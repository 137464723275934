import { Injectable, OnDestroy } from '@angular/core';
import { calculateDays, calculateYearly, getFinancialYear } from '@app/modules/shared/helper/util';

import { formatCurrency } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { RefiModalName, RefiModalService } from '@app/layouts/refi/components/services/refi-modal.service';
import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { PaygBasis, StepStatus } from '@app/modules/shared/enums/app.enums';
import { JourneyStepService } from '@app/modules/shared/service/journey-step.service';
import { ApplicationDataQuery } from '@app/modules/shared/store/application-data/application-data.query';
import { FormDataService } from '@app/modules/shared/store/form-data/form-data.service';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { FormEnumsService } from '@app/modules/shared/store/form-enums/form-enums.service';
import {
	formlyAddCustomValidator,
	formlyExtendExpressionProperties,
	formlyOnClick,
	formlyRegisterHooks,
	getFormField
} from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SimpConfirmationDialogService } from '@simpology/client-components';
import { ConfirmationDialogHeaderType, EnumObject, FrequencyShort } from '@simpology/client-components/utils';
import { cloneDeep, get } from 'lodash-es';
import { filter, of, startWith, Subject, Subscription, switchMap, take, takeUntil, tap } from 'rxjs';
import { RefiStepType } from '../../enums/refi-steps.enum';
import { RefiStore } from '../../enums/refi-store.enum';
import { EmploymentDates, EmploymentModel, EmploymentModelDetails } from '../../models/refi-employment.model';
import { RefiOtherIncomeModel, RefiOtherIncomeTransformer } from '../../models/refi-other-income.model';
import {
	RefiIncomeDetails,
	RefiRentalIncomeModel,
	RefiRentalIncomeTransformer
} from '../../models/refi-rental-income.model';
import { SelfEmploymentModel } from '../../models/refi-self-employment.model';
import { RefiJourneyService } from '../refi-journey.service';
import { RefiIncomeService } from './refi-income.service';

@Injectable({
	providedIn: 'root'
})
export class RefiJourneyIncomeTransformerService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private totalIncome = 0;
	private sufficientEmploymentHistory = false;

	constructor(
		private incomeService: RefiIncomeService,
		private refiJourneyService: RefiJourneyService,
		private journeyStepService: JourneyStepService,
		private applicationDataQuery: ApplicationDataQuery,
		private formDataService: FormDataService,
		private formEnumQuery: FormEnumsQuery,
		private formEnumsService: FormEnumsService,
		private confirmationDialogService: SimpConfirmationDialogService,
		private refiModalService: RefiModalService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public incomeTransform(formFields: FormlyFieldConfig[]) {
		this.formEnumQuery
			.selectEnumOptions('AllApplicants')
			.pipe(take(1))
			.subscribe((applicants: EnumObject[]) => {
				if (applicants.length === 1) {
					this.selectFirstApplicantForOwnership(
						formFields,
						'income.details.employment.employmentModal.ownerApplicantId'
					);
					this.selectFirstApplicantForOwnership(
						formFields,
						'income.details.selfEmployment.selfEmploymentModal.ownerApplicantId'
					);
					this.selectFirstApplicantForOwnership(formFields, 'income.details.rentalIncome.rentalIncomeModal.ownership');
					this.selectFirstApplicantForOwnership(formFields, 'income.details.otherIncome.otherIncomeModal.ownership');
				}
			});
		formlyOnClick(
			formFields,
			'income.details.employment',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Income, true);
					this.incomeService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveEmployment(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as EmploymentModel[];
									const id = model[event.index].employmentModal.id;
									if (id) {
										this.deleteEmployment(id, event.index);
									}
								}
							});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'income.details.selfEmployment',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Income, true);
					this.incomeService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveSelfEmployment(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as SelfEmploymentModel[];
									const id = model[event.index].selfEmploymentModal.id;
									if (id) {
										this.deleteSelfEmployment(id, event.index);
									}
								}
							});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'income.details.rentalIncome',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Income, true);
					this.incomeService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveRentalIncome(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as RefiRentalIncomeModel[];
									const id = model[event.index].rentalIncomeModal?.id;
									if (id) {
										this.deleteRentalIncome(id, event.index);
									}
								}
							});
					}
				}
			}
		);

		formlyOnClick(
			formFields,
			'income.details.otherIncome',
			(field: FormlyFieldConfig, event: { index: number; action: string; changed: boolean }) => {
				if (event.action === 'cancel' || event.action === 'close') {
					this.formDataService.setLoading(RefiStore.Income, true);
					this.incomeService.setupState();
				}

				if (this.refiJourneyService.isAdminUser) {
					return;
				}

				if (event.action == 'submit') {
					if (event.changed) {
						this.saveOtherIncome(field, event.index);
					}
				}

				if (event.action == 'delete') {
					if (event.index !== undefined) {
						this.confirmationDialogService
							.confirm(
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
								ConfirmationDialogHeaderType.Delete,
								`Are you sure you want to delete?`,
								``,
								'Yes, delete',
								'No, continue',
								'sm',
								'refi-modal-content'
							)
							.subscribe((action) => {
								if (action) {
									const model = get(field, 'model') as RefiOtherIncomeModel[];
									const id = model[event.index].otherIncomeModal?.id;
									if (id) {
										this.deleteOtherIncome(id, event.index);
									}
								}
							});
					}
				}
			}
		);

		formlyOnClick(formFields, 'income.details', (field: FormlyFieldConfig) => {
			this.refiJourneyService.navigateToStep('expenses/details');
		});

		formlyRegisterHooks(formFields, 'income.details.employment.employmentModal.occupationCategory', {
			onInit: (field: FormlyFieldConfig | undefined) => {
				const occupationCodeField = field?.parent?.fieldGroup?.find((f) => f.key === 'occupation');
				if (occupationCodeField) {
					return field?.formControl?.valueChanges.pipe(
						startWith(field?.formControl?.value),
						filter((val) => !!val),
						switchMap((val: number) => this.formEnumsService.fetchSubEnums('AscoOccupationCategory', val)),
						tap((options) => {
							occupationCodeField.templateOptions!.options = options;

							const matchingOption = options.find((option) => option.id === occupationCodeField.formControl?.value);
							if (!matchingOption) {
								occupationCodeField.formControl?.setValue(null);
							}
						})
					);
				}
				return of();
			}
		});

		formlyRegisterHooks(formFields, 'income.details.selfEmployment.selfEmploymentModal.ownershipShare', {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field?.templateOptions) {
					field.templateOptions.simpleView = true;
				}
			}
		});

		formlyExtendExpressionProperties(formFields, 'income.details.employment.employmentModal.dates.dateStartedCurrent', {
			'templateOptions.maxDate': () => {
				const current = new Date();
				return {
					year: current.getFullYear(),
					month: current.getMonth() + 1,
					day: current.getDate()
				};
			}
		});

		formlyExtendExpressionProperties(formFields, 'income.details.selfEmployment.selfEmploymentModal.grossIncome', {
			'templateOptions.label': (model, formState, field) => {
				return field.templateOptions?.label?.replace('FinancialYear', `FY${getFinancialYear(true)}`);
			}
		});

		formlyExtendExpressionProperties(
			formFields,
			'income.details.employment.employmentModal.dates.dateStartedPrevious',
			{
				'templateOptions.maxDate': (model, formState, field: FormlyFieldConfig) => {
					if (field) {
						const dates = field.model as EmploymentDates;

						if (dates?.dateEnded) {
							const dateEnded = new Date(dates.dateEnded);
							return {
								year: dateEnded.getFullYear(),
								month: dateEnded.getMonth() + 1,
								day: dateEnded.getDate()
							};
						}
					}

					const current = new Date();
					return {
						year: current.getFullYear(),
						month: current.getMonth() + 1,
						day: current.getDate()
					};
				}
			}
		);

		formlyExtendExpressionProperties(formFields, 'income.details.employment.employmentModal.dates.dateEnded', {
			'templateOptions.maxDate': () => {
				const current = new Date();
				return {
					year: current.getFullYear(),
					month: current.getMonth() + 1,
					day: current.getDate()
				};
			}
		});

		formlyExtendExpressionProperties(formFields, 'income.details.employment.employmentModal.dates.dateEnded', {
			'templateOptions.minDate': (model, formState, field: FormlyFieldConfig) => {
				if (field) {
					const dates = field.model as EmploymentDates;

					if (dates?.dateStartedPrevious) {
						const dateEnded = new Date(dates.dateStartedPrevious);
						return {
							year: dateEnded.getFullYear(),
							month: dateEnded.getMonth() + 1,
							day: dateEnded.getDate()
						};
					}
				}

				const current = new Date();
				return {
					year: current.getFullYear(),
					month: current.getMonth() + 1,
					day: current.getDate()
				};
			}
		});

		formlyOnClick(
			formFields,
			'income.details.employment.employmentModal.isCurrentEmployment',
			(field: FormlyFieldConfig) => {
				const dateStartedCurrentField = getFormField(field.parent?.fieldGroup, 'dates.dateStartedCurrent');
				const dateStartedPreviousField = getFormField(field.parent?.fieldGroup, 'dates.dateStartedPrevious');
				const dateEndedField = getFormField(field.parent?.fieldGroup, 'dates.dateEnded');
				dateEndedField?.formControl?.setValue(null);
				dateStartedCurrentField?.formControl?.setValue(null);
				dateStartedPreviousField?.formControl?.setValue(null);
			}
		);

		formlyOnClick(
			formFields,
			`income.details.employment.employmentModal.grossIncome`,
			(field, clickType: { type: string }) => {
				if (clickType?.type === 'tooltipClick') {
					this.openInfoModal(formFields, RefiModalName.grossSalary);
				}
			}
		);

		formlyRegisterHooks(formFields, 'income.details.employment.employmentModal.grossIncome', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					field.templateOptions.tooltipAsModal = true;
				}
			}
		});

		formlyRegisterHooks(formFields, 'income.details.employment', {
			onInit: (field: FormlyFieldConfig | undefined) => {
				if (field?.templateOptions) {
					field.templateOptions.message = '';
					this.calculateIfMoreHistoryisRequired(field, formFields);
					return field?.formControl?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
						this.calculateIfMoreHistoryisRequired(field, formFields);
					});
				}
				return of();
			}
		});

		let subscription: Subscription | undefined;
		formlyRegisterHooks(formFields, 'income.details.summaryAmount', {
			afterContentInit: (field) => {
				if (field) {
					subscription = this.formDataService.select$(RefiStore.Income).subscribe(() => {
						this.calculateSummary(field);
					});
				}
			},
			onDestroy: () => {
				if (subscription) {
					subscription.unsubscribe();
				}
			}
		});

		formlyExtendExpressionProperties(formFields, `income.details.employment.employmentModal`, {
			'templateOptions.hideModalFooter': () => {
				return this.refiJourneyService.isAdminUser || false;
			}
		});

		formlyExtendExpressionProperties(formFields, `income.details.selfEmployment.selfEmploymentModal`, {
			'templateOptions.hideModalFooter': () => {
				return this.refiJourneyService.isAdminUser || false;
			}
		});

		formlyExtendExpressionProperties(formFields, `income.details.rentalIncome.rentalIncomeModal`, {
			'templateOptions.hideModalFooter': () => {
				return this.refiJourneyService.isAdminUser || false;
			}
		});

		formlyRegisterHooks(formFields, 'income.details.rentalIncome.rentalIncomeModal.address', {
			onInit: (field) => {
				if (field && field.templateOptions) {
					const thisAddressId = (get(field, 'model') as RefiIncomeDetails).address?.id;
					const model = get(field.parent?.parent?.parent, 'model') as RefiRentalIncomeModel[];
					const excludeAddresses = model
						?.map((m) => m.rentalIncomeModal?.address?.id)
						.filter((id) => id !== thisAddressId);
					field.templateOptions.excludeAddresses = excludeAddresses;
				}
			}
		});

		formlyExtendExpressionProperties(formFields, `income.details.otherIncome.otherIncomeModal`, {
			'templateOptions.hideModalFooter': () => {
				return this.refiJourneyService.isAdminUser || false;
			}
		});

		this.validatePaygEmployerName(formFields);
	}

	selectFirstApplicantForOwnership(formFields: FormlyFieldConfig[], path: string) {
		const ownershipField = getFormField(formFields, path) as FormlyFieldConfig;
		if (ownershipField && ownershipField.templateOptions) {
			ownershipField.templateOptions.selectFirstOption = true;
		}
	}

	private calculateIfMoreHistoryisRequired(field: FormlyFieldConfig, formFields: FormlyFieldConfig[]): void {
		const applicants = this.applicationDataQuery.getRefiApplicants();
		const model = get(field, 'model') as EmploymentModel[];
		const dataInState = this.formDataService.getStateData('refi-income-details')[0] as {
			employment: EmploymentModel[];
		};
		const sixMonthHistory = [PaygBasis.Casual, PaygBasis.Seasonal, PaygBasis.Temporary] as number[];

		const threeMonthField = getFormField(field.fieldGroup, '0.infoFullEmployment');
		const sixMonthField = getFormField(field.fieldGroup, '0.infoCasualEmployment');

		applicants.forEach((applicant) => {
			const incomesByApplicant = model?.filter((x) => x?.employmentModal?.ownerApplicantId === applicant.id);
			const providedMonths = this.getProvidedMonths(model);

			let daysNeeded = 0;
			const employmentTypes = incomesByApplicant.flatMap((value) => {
				return value.employmentModal.basis;
			});

			const hasSixMonthHistory = sixMonthHistory.some((x) => employmentTypes.includes(x));
			if (field.templateOptions && threeMonthField?.templateOptions) {
				field.templateOptions.message = '';
			}

			if (dataInState.employment?.length === 0) return;

			if (hasSixMonthHistory) {
				daysNeeded = getFormField(field.fieldGroup, '0.daysAcceptedCasualEmployment')?.formControl?.value as number;

				if (field.templateOptions && sixMonthField?.templateOptions && providedMonths < daysNeeded) {
					this.sufficientEmploymentHistory = false;
					field.templateOptions.message = sixMonthField.templateOptions.label?.replace('(optional)', '') as string;
				} else {
					this.sufficientEmploymentHistory = true;
				}
				return;
			} else {
				daysNeeded = getFormField(field.fieldGroup, '0.daysAcceptedFullEmployment')?.formControl?.value as number;

				if (field.templateOptions && threeMonthField?.templateOptions && providedMonths < daysNeeded) {
					this.sufficientEmploymentHistory = false;
					field.templateOptions.message = threeMonthField.templateOptions.label?.replace('(optional)', '') as string;
				} else {
					this.sufficientEmploymentHistory = true;
				}
			}
		});
	}

	private getProvidedMonths(model: EmploymentModel[]): number {
		const startDates = model.flatMap((x) =>
			x?.employmentModal?.dates?.dateEnded
				? x?.employmentModal?.dates?.dateStartedPrevious
				: x?.employmentModal.dates?.dateStartedCurrent
		) as unknown as string[];
		const endDates = model.flatMap((x) =>
			x?.employmentModal?.dates?.dateEnded === null
				? new Date().toISOString().slice(0, 10)
				: x?.employmentModal?.dates?.dateEnded
		) as unknown as string[];
		const earliestStartDate =
			startDates.length > 1
				? startDates.reduce((prev, curr) => (Date.parse(prev) > Date.parse(curr) ? curr : prev))
				: startDates[0];
		const latestEndDate =
			endDates.length > 1
				? endDates.reduce((prev, curr) => (Date.parse(prev) < Date.parse(curr) ? curr : prev))
				: endDates[0];

		return calculateDays(earliestStartDate, latestEndDate);
	}

	private calculateSummary(field: FormlyFieldConfig): void {
		const model = field.model as {
			employment: EmploymentModel[];
			selfEmployment: SelfEmploymentModel[];
			otherIncome: RefiOtherIncomeModel[];
			rentalIncome: RefiRentalIncomeModel[];
			summaryAmount: string;
		};
		const currentEmployments = model?.employment?.filter((x) => !x.employmentModal?.dates?.dateEnded);

		this.totalIncome = 0;
		this.totalIncome += currentEmployments?.reduce(
			(x, y) => x + calculateYearly(y?.employmentModal.grossIncome?.amount, y?.employmentModal.grossIncome?.frequency),
			0
		);
		this.totalIncome += currentEmployments?.reduce(
			(x, y) =>
				x + calculateYearly(y?.employmentModal.overtimeIncome?.amount, y?.employmentModal.overtimeIncome?.frequency),
			0
		);
		this.totalIncome += currentEmployments?.reduce(
			(x, y) => x + calculateYearly(y?.employmentModal.bonusAmount?.amount, y?.employmentModal.bonusAmount?.frequency),
			0
		);
		this.totalIncome += model?.selfEmployment?.reduce(
			(x, y) => x + calculateYearly(y?.selfEmploymentModal.grossIncome?.amount, FrequencyShort.Yearly),
			0
		);

		this.totalIncome += model?.otherIncome?.reduce(
			(x, y) => x + calculateYearly(y?.otherIncomeModal.incomeRate?.amount, y?.otherIncomeModal.incomeRate?.frequency),
			0
		);

		this.totalIncome += model?.rentalIncome?.reduce(
			(x, y) =>
				x + calculateYearly(y?.rentalIncomeModal?.rentalRate?.amount, y?.rentalIncomeModal?.rentalRate?.frequency),
			0
		);

		if (this.totalIncome > 0) {
			field.className = '';
			model.summaryAmount = `<span class="simp-text--bold">${formatCurrency(
				this.totalIncome,
				'en-AU',
				'$'
			)}</span> total per year`;

			const sufficientEmploymentHistory =
				(model.employment.length && this.sufficientEmploymentHistory) || !model.employment.length;
			const formIsValid = field.parent && field.parent.formControl && field.parent.formControl.valid;

			if (formIsValid && sufficientEmploymentHistory) {
				this.journeyStepService
					.updateStep(RefiStepType.RefiIncome, StepStatus.Complete)
					.pipe(takeUntil(this.destroy$))
					.subscribe();
			} else {
				this.journeyStepService
					.updateStep(RefiStepType.RefiIncome, StepStatus.Incomplete)
					.pipe(takeUntil(this.destroy$))
					.subscribe();
			}
		} else {
			field.className = 'hidden';
			this.journeyStepService
				.updateStep(RefiStepType.RefiIncome, StepStatus.Incomplete)
				.pipe(takeUntil(this.destroy$))
				.subscribe();
		}
	}

	private saveEmployment(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as EmploymentModel[];
		if (model) {
			const payload = cloneDeep(model[index]);
			this.formDataService.setLoading(RefiStore.Income, true);
			this.incomeService.saveEmploymentIncome(payload.employmentModal).subscribe({
				next: (res) => {
					if (res) {
						this.incomeService.setupState();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Income, false)
			});
		}
	}

	private saveSelfEmployment(field: FormlyFieldConfig, index: number): void {
		const model = get(field, `model`) as SelfEmploymentModel[];
		if (model) {
			const payload = cloneDeep(model[index]);
			this.formDataService.setLoading(RefiStore.Income, true);
			this.incomeService.saveSelfEmploymentIncome(payload.selfEmploymentModal).subscribe({
				next: (res) => {
					if (res) {
						this.incomeService.setupState();
					}
				},
				error: () => this.formDataService.setLoading(RefiStore.Income, false)
			});
		}
	}

	private saveRentalIncome(field: FormlyFieldConfig, index: number): void {
		const model = field.model as RefiRentalIncomeModel[];
		if (model) {
			const payload = RefiRentalIncomeTransformer.toPayload(
				model[index],
				this.applicationDataQuery.applicationId(),
				this.applicationDataQuery.getRefiApplicants()
			);
			if (model) {
				this.formDataService.setLoading(RefiStore.Income, true);
				this.incomeService.saveRentalIncome(payload).subscribe({
					next: (res) => {
						if (res) {
							this.incomeService.setupState();
						}
					},
					error: () => this.formDataService.setLoading(RefiStore.Income, false)
				});
			}
		}
	}

	private saveOtherIncome(field: FormlyFieldConfig, index: number): void {
		const model = field.model as RefiOtherIncomeModel[];
		if (model) {
			const payload = RefiOtherIncomeTransformer.toPayload(
				model[index],
				this.applicationDataQuery.applicationId(),
				this.formEnumQuery
			);

			if (model) {
				this.formDataService.setLoading(RefiStore.Income, true);
				this.incomeService.saveOtherIncome(payload).subscribe({
					next: (res) => {
						if (res) {
							this.incomeService.setupState();
						}
					},
					error: () => this.formDataService.setLoading(RefiStore.Income, false)
				});
			}
		}
	}

	private deleteEmployment(id: number, index: number): void {
		this.formDataService.setLoading(RefiStore.Income, true);
		this.incomeService.deleteEmploymentIncome(id).subscribe({
			next: () => {
				this.incomeService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Income, false)
		});
	}
	private deleteSelfEmployment(id: number, index: number): void {
		this.incomeService.deleteSelfEmploymentIncome(id).subscribe({
			next: () => {
				this.incomeService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Income, false)
		});
	}
	private deleteRentalIncome(id: number, index: number): void {
		this.incomeService.deleteRentalIncome(id).subscribe({
			next: () => {
				this.incomeService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Income, false)
		});
	}
	private deleteOtherIncome(id: number, index: number): void {
		this.incomeService.deleteOtherIncome(id).subscribe({
			next: () => {
				this.incomeService.setupState();
			},
			error: () => this.formDataService.setLoading(RefiStore.Income, false)
		});
	}

	private openInfoModal(formFields: FormlyFieldConfig[] | undefined, refiModalName: RefiModalName): void {
		const refiModalField = getFormField(formFields, 'income.info-modals');
		this.refiModalService.openRefiModal(refiModalField, refiModalName);
	}

	private validatePaygEmployerName(formFields: FormlyFieldConfig[] | undefined): void {
		formlyAddCustomValidator(formFields, `income.details.employment.employmentModal.employerNameOrAbn`, {
			duplicate: {
				expression: (control: UntypedFormControl) => {
					const newEmployerName = control.value as string;
					if (!newEmployerName) {
						return false;
					}

					const employmentDetails = control.parent?.value as EmploymentModelDetails;
					const employmentId = employmentDetails.id ?? CONSTANTS.NEW_ID;
					const incomeData = this.formDataService.getStateData(RefiStore.Income)[0] as {
						employment: EmploymentModel[];
					};
					const employerNames = incomeData.employment
						.filter(
							(e) =>
								e.employmentModal.id !== employmentId &&
								e.employmentModal.employerNameOrAbn &&
								e.employmentModal.ownerApplicantId === employmentDetails.ownerApplicantId
						)
						.map((e) => e.employmentModal.employerNameOrAbn?.toLowerCase());
					return !employerNames.includes(newEmployerName.toLowerCase());
				}
			}
		});
	}
}
