<simp-address-search
	[id]="uniqueId + '-address'"
	[addresses$]="existingAddresses$"
	[selectAddress]="to.placeholder || 'Please enter address'"
	[countries]="countries"
	[formControl]="selectedAddressId"
	[readonly]="!!to.readonly"
	[showExisting]="to.showExisting"
	[validationErrors]="field.validation?.messages!"
	[convertSlashToSpace]="to.convertSlashToSpace"
	[convertToUpperCase]="to.convertToUpperCase"
	[truncateCommas]="to.truncateCommas"
	[placeType]="placeType"
	[class.is-invalid]="formControl.invalid"
	[saveAddressFunction]="saveAddress"
	[filterAddresses]="to.filter"
	[allowOtherAddress]="allowNonStandard"
	[nonStandardCountries]="nonStandardCountries"
	[allowPOBox]="to.allowPOBox"
	[defaultManualFormValues]="defaultManual"
	metadataKey
	[formlyField]="field"
	[searchPlaceholder]="to.searchPlaceholder || 'Please search address'"
	[poBoxTypes]="poBoxTypes"
	[markAsTouched]="markAsTouched"
	[isRequired]="to.required"
	>{{ to.label }}
</simp-address-search>
