import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { ExpensesType } from '../../enums/app.enums';

import { ChannelSettingState } from './typings/channel-setting';

@Injectable({ providedIn: 'root' })
export class ChannelSettingStore {
	store = createStore(
		{ name: 'channel-setting' },
		withProps<ChannelSettingState>({
			allowedFileExtensionsList: [],
			expenseType: ExpensesType.Both,
			enableGuarantorInterview: false,
			enableBorrowerInterview: true,
			isDigitalEditable: false,
			declaredDigitalThreshold: 0,
			allowCompanyApplicants: false,
			lockAthenaControls: false,
			allowTrustApplicants: false,
			enableDigitalFastTrack: false,
			enableUIApplicationImport: false,
			enableLoanFees: false,
			doShowSummaryView: false,
			skipServiceabilitySubmitWorkflow: false,
			useRelationshipForSOP: false,
			enableIntercom: true,
			enableDiscount: true,
			allowCustomLoaders: true,
			allowCommissions: true,
			requireApplicationPreCheck: false,
			enableLmiFingerprint: true,
			financialInstitutionId: 0,
			enableCustomerDetailSearch: false
		})
	);

	updateStore(newState: ChannelSettingState) {
		this.store.update((state: ChannelSettingState) => newState);
	}

	getStoreValue(): ChannelSettingState {
		return this.store.getValue();
	}
}
