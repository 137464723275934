<p class="simp-label">{{ to.label }}</p>
<textarea
	[name]="key"
	[formControl]="$any(formControl)"
	[cols]="props.cols"
	[rows]="props.rows"
	[readonly]="!!to.readonly"
	class="form-control simp-form-control"
	[formlyAttributes]="field"
	[id]="uniqueId"
	[class.is-invalid]="formControl.invalid && (formControl.dirty || formControl.touched)"
	metadataKey
	[formlyField]="field"
	[placeholder]="to.placeholder!"
></textarea>

<simp-field-error
	*ngIf="(formControl.dirty || formControl.touched) && formControl.errors"
	[errors]="formControl.errors"
	[validationErrors]="field.validation?.messages!"
>
</simp-field-error>
