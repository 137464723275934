<hr class="m-0" aria-hidden="true" />
<div class="nested-section" [class.nested-section--grey-background]="to.greyBackground" [ngClass]="to.customClass">
	<div
		class="d-flex justify-content-between nested-section__header simp-margin-bottom-base simp-padding-vertical-base"
		(click)="to.expanded = !to.expanded"
	>
		<h5 class="simp-h5 simp-text--secondary">{{ to.label }}</h5>
		<i
			*ngIf="to.collapsible"
			class="fas loanapp-area__up-down-chevron"
			[ngClass]="to.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
			(click)="toggleExpanded($event)"
		></i>
	</div>
	<div class="simp-row" [hidden]="!to.expanded && to.collapsible">
		<ng-container *ngFor="let f of field.fieldGroup">
			<div class="w-100" *ngIf="f?.templateOptions?.newLine"></div>
			<formly-field [field]="f"></formly-field>
		</ng-container>
	</div>
</div>
