import { JsonSchema, JsonSchemaProperty } from '../../typings/formly-app';
import { parseExpressionProperties, parseExpressionString } from '../simp-formly.helper';
import { FormlyApiProperty } from '../typings/formly-api';

export const formlyObject = (property: FormlyApiProperty, childProperties?: JsonSchema): JsonSchemaProperty => ({
	type: 'object',
	title: property.title,
	widget: {
		formlyConfig: {
			type: 'object',
			className: `${property.templateOptions?.class ?? property.class}`,
			templateOptions: {
				...property.templateOptions
			},
			validation: {
				messages: {
					required: 'This field is mandatory',
					...(property.errorMessages || {})
				}
			},
			hideExpression: parseExpressionString(property.hide),
			expressionProperties: parseExpressionProperties(property)
		}
	},
	required: property.required,
	properties: childProperties
});
