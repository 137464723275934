import { Injectable } from '@angular/core';
import { formlyDeleteFromArray, formlyOnClick } from '@app/modules/simp-formly/helpers/simp-formly.helper';
import { SimpFormlyHandlerService } from '@app/modules/simp-formly/services/simp-formly-handler.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CONSTANTS } from '../constants/constants';
import { deletedSuccessfullyMessage } from '../helper/util';
import { ApplicationDataQuery } from '../store/application-data/application-data.query';
import { BaseJourneyService } from './base-journey.service';

@Injectable({ providedIn: 'root' })
export class FormArrayDeleteService extends BaseJourneyService {
	constructor(
		private simpFormlyHandlerService: SimpFormlyHandlerService,
		private applicationDataQuery: ApplicationDataQuery
	) {
		super();
		this.setJourneyLadmRoute();
	}

	handleDelete(formFields: FormlyFieldConfig[] | undefined, path: string, segmentUrl: string, itemName?: string): void {
		const pathSplit = path.split('.');
		const stateKey = pathSplit[pathSplit.length - 1];
		formlyOnClick(formFields, `${path}.delete`, (field: FormlyFieldConfig) => {
			this.deleteItem(field, segmentUrl, stateKey).subscribe(() => {
				const itemMsg = itemName ?? stateKey.toLowerCase();
				this.toastr.success(deletedSuccessfullyMessage(`${itemMsg}`));
			});
		});
	}

	deleteItem(field: FormlyFieldConfig, segmentUrl: string, stateKey?: string, isFuture?: boolean): Observable<boolean> {
		const index = Number(field.parent?.key);
		const id = get(field, 'model.id') as number;
		if (id !== undefined && id !== CONSTANTS.NEW_ID) {
			return this.delete(
				`${segmentUrl}/${this.applicationDataQuery.applicationId()}/${id}/${isFuture === undefined ? '' : isFuture}`
			).pipe(
				tap(() => {
					formlyDeleteFromArray(field);
					if (stateKey) {
						this.simpFormlyHandlerService.deleteFromState(stateKey, index);
					}
				}),
				map(() => true)
			);
		} else {
			formlyDeleteFromArray(field);
			return of(true);
		}
	}

	/**
	 * Delete an item from an array
	 *
	 * @param field Delete field
	 * @param urlPath Segment url
	 * @param optionParams Object containing below parameters
	 * storeKey => Key used to save data in store
	 * storeIndex => Data object index in store
	 * arrayPath => Array path in data object
	 * itemId => Array item id path
	 * isFuture => Optional Boolean flag as required by the api
	 *
	 * @returns Observable<boolean>
	 */
	deleteArrayItem(
		field: FormlyFieldConfig,
		urlPath: string,
		optionParams: {
			storeKey: string;
			storeIndex: number;
			arrayPath: string;
			itemId: string;
			isFuture?: boolean;
		}
	): Observable<boolean> {
		const arrayIndex = Number(field.parent?.key);
		const id = get(field, optionParams.itemId) as number;
		if (id !== undefined && id !== CONSTANTS.NEW_ID) {
			return this.delete(
				`${urlPath}/${this.applicationDataQuery.applicationId()}/${id}/${
					optionParams.isFuture === undefined ? '' : optionParams.isFuture
				}`
			).pipe(
				tap(() => {
					formlyDeleteFromArray(field);
					const dataFromStore = this.simpFormlyHandlerService.getStateData(optionParams.storeKey);
					if (dataFromStore?.length > optionParams.storeIndex) {
						const dataObject = cloneDeep(dataFromStore[optionParams.storeIndex]);
						const dataArray = get(dataObject, optionParams.arrayPath, []) as unknown[];
						if (dataArray?.length > arrayIndex) {
							dataArray.splice(arrayIndex, 1);
							this.simpFormlyHandlerService.updateToState(optionParams.storeKey, dataObject, optionParams.storeIndex);
						}
					}
				}),
				map(() => true)
			);
		} else {
			formlyDeleteFromArray(field);
			return of(true);
		}
	}
}
