import { BorrowingInfo } from '@app/modules/loan-serviceability/model/borrowing.model';
import { Product } from '@app/modules/loan-serviceability/model/product.model';
import {
	InterestOnlyLoanOptions,
	InterestType,
	LoanFeature,
	PaymentType,
	RbaLendingPurpose,
	RefinanceExistingLoanOptions,
	YesNo
} from '@app/modules/shared/enums/app.enums';
import { getNullableBoolean, getNullableYesNo } from '@app/modules/shared/helper/util';
import { ShortApplicant } from '@app/modules/shared/model/applicant.model';
import { MultiCheckboxModel } from '@app/modules/shared/model/multi-checkbox.model';
import { LoanPurposeRationaleDTO } from '@app/modules/typings/api';

export class LoanPurposeRationaleTransformer {
	static toPayload(model: LoanPurposeRationaleModel): LoanPurposeRationaleDTO {
		const refinanceOptions = model.refinanceExistingLoan?.options ?? [];
		const interestOnlyOptions = model.interestOnlyMultiOptions?.options ?? [];
		return {
			reducedPayment: refinanceOptions.includes(RefinanceExistingLoanOptions.ReducedRepayments),
			lowerInterest: refinanceOptions.includes(RefinanceExistingLoanOptions.LowerInterestRate),
			greaterFlexibility: refinanceOptions.includes(RefinanceExistingLoanOptions.GreaterFlexibility),
			consolidateDebts: refinanceOptions.includes(RefinanceExistingLoanOptions.ReduceNumberOfLenders),
			dissatisfiedWithFinancialInstitution: refinanceOptions.includes(
				RefinanceExistingLoanOptions.DissatisfiedWithOtherFinancialInstitution
			),
			otherRationale: refinanceOptions.includes(RefinanceExistingLoanOptions.Other),
			otherRationaleDetail: model.refinanceExistingLoan?.otherReasonInput,
			refinanceExplained: model.refinanceExplained,
			constructionExplained: model.constructionLoanExplained,
			isBridgingLoan: getNullableBoolean(model.bridgingLoanYesNo),
			bridgingLoanExplained: model.bridgingLoanExplained,
			homeLoanInCompanyName: model.homeLoanInCompanyNameExplained,
			variableRate: model.variableRateExplained,
			fixedRate: model.fixedRateExplained,
			temporaryIncomeReduction: interestOnlyOptions?.includes(
				InterestOnlyLoanOptions.AccommodateTemporaryReductionInIncome
			),
			temporaryExpense: interestOnlyOptions?.includes(
				InterestOnlyLoanOptions.AccommodateAnticipatedNonRecurringExpenseItem
			),
			fundsForInvestment: interestOnlyOptions?.includes(InterestOnlyLoanOptions.ReleaseFundsForInvestmentPurposes),
			additionalFunds: interestOnlyOptions?.includes(
				InterestOnlyLoanOptions.AdditionalFundsInAnOffsetFacilityForWealthCreation
			),
			recommendation: interestOnlyOptions?.includes(
				InterestOnlyLoanOptions.RecommendationProvidedByIndependentFinancialAdvisor
			),
			taxation: interestOnlyOptions?.includes(InterestOnlyLoanOptions.TaxationOrAccountingReasons),
			convertInvestment: interestOnlyOptions?.includes(
				InterestOnlyLoanOptions.PlanToConvertAnInvestmentPropertyInFuture
			),
			otherInterest: interestOnlyOptions?.includes(InterestOnlyLoanOptions.Other),
			additionalInformation: model.additionalInformation,
			otherInterestDetail: model.interestOnlyMultiOptions?.otherReasonInput,
			interestOnlyExplained: model.interestOnlyAcknowledged,
			offsetAccountImportant: model.offsetAccountImportantExplained,
			offsetAccountExplained: model.offsetDifferenceExplained,
			redrawImportant: model.redrawAdditionalRepaymentsExplained,
			redrawExplained: model.redrawTermsAndConditionsExplained
		};
	}
	static fromPayload(
		data: LoanPurposeRationaleDTO,
		companyApplicants: ShortApplicant[],
		borrowings: BorrowingInfo[],
		products: Product[]
	): LoanPurposeRationaleModel {
		const selectedRefinanceOptions = this.getSelectedRefinanceOptions(data);
		const selectedInterestOnlyOptions = this.getSelectedInterestOnlyOptions(data);

		return {
			refinanceExplained: data.refinanceExplained,
			constructionLoanExplained: data.constructionExplained,
			bridgingLoanYesNo: getNullableYesNo(data.isBridgingLoan),
			bridgingLoanExplained: data.bridgingLoanExplained,
			homeLoanInCompanyNameExplained: data.homeLoanInCompanyName,
			variableRateExplained: data.variableRate,
			fixedRateExplained: data.fixedRate,
			additionalInformation: data.additionalInformation,
			hasCompany: companyApplicants?.length > 0,
			interestOnlyAcknowledged: data.interestOnlyExplained,
			offsetAccountImportantExplained: data.offsetAccountExplained,
			offsetDifferenceExplained: data.offsetAccountExplained,
			redrawAdditionalRepaymentsExplained: data.redrawImportant,
			redrawTermsAndConditionsExplained: data.redrawExplained,
			refinanceExistingLoan: {
				options: selectedRefinanceOptions,
				otherReasonInput: data.otherRationaleDetail
			},
			interestOnlyMultiOptions: {
				options: selectedInterestOnlyOptions,
				otherReasonInput: data.otherInterestDetail
			},
			// Fields to help determine which rationales to show
			primaryLendingPurposes: borrowings
				?.map(
					(borrowing) =>
						borrowing.lendingPurpose
							?.filter((purpose) => !!purpose.primaryLendingPurpose)
							.map((purpose) => purpose.primaryLendingPurpose) as RbaLendingPurpose[]
				)
				.reduce((acc, purposes) => acc?.concat(purposes), []),
			hasFixed: products?.some((product) => product.interestType === InterestType.FixedRate),
			hasVariable: products?.some((product) => product.interestType === InterestType.Variable),
			interestOnly: products?.some((product) =>
				[PaymentType.InterestOnly, PaymentType.PrepaidInterest].includes(product.paymentType!)
			),
			loanFeatures: products
				?.map((product) => product.loanFeatures.map((feature) => feature.id))
				.reduce((acc, features) => acc.concat(features))
		};
	}

	static getSelectedRefinanceOptions(data: LoanPurposeRationaleDTO): number[] {
		const selectedIds: number[] = [];
		if (data.reducedPayment) {
			selectedIds.push(RefinanceExistingLoanOptions.ReducedRepayments);
		}
		if (data.lowerInterest) {
			selectedIds.push(RefinanceExistingLoanOptions.LowerInterestRate);
		}
		if (data.greaterFlexibility) {
			selectedIds.push(RefinanceExistingLoanOptions.GreaterFlexibility);
		}
		if (data.consolidateDebts) {
			selectedIds.push(RefinanceExistingLoanOptions.ReduceNumberOfLenders);
		}
		if (data.dissatisfiedWithFinancialInstitution) {
			selectedIds.push(RefinanceExistingLoanOptions.DissatisfiedWithOtherFinancialInstitution);
		}
		if (data.otherRationale) {
			selectedIds.push(RefinanceExistingLoanOptions.Other);
		}
		return selectedIds;
	}

	static getSelectedInterestOnlyOptions(data: LoanPurposeRationaleDTO): number[] {
		const selectedIds: number[] = [];
		if (data.temporaryIncomeReduction) {
			selectedIds.push(InterestOnlyLoanOptions.AccommodateTemporaryReductionInIncome);
		}
		if (data.temporaryExpense) {
			selectedIds.push(InterestOnlyLoanOptions.AccommodateAnticipatedNonRecurringExpenseItem);
		}
		if (data.fundsForInvestment) {
			selectedIds.push(InterestOnlyLoanOptions.ReleaseFundsForInvestmentPurposes);
		}
		if (data.additionalFunds) {
			selectedIds.push(InterestOnlyLoanOptions.AdditionalFundsInAnOffsetFacilityForWealthCreation);
		}
		if (data.recommendation) {
			selectedIds.push(InterestOnlyLoanOptions.RecommendationProvidedByIndependentFinancialAdvisor);
		}
		if (data.taxation) {
			selectedIds.push(InterestOnlyLoanOptions.TaxationOrAccountingReasons);
		}
		if (data.convertInvestment) {
			selectedIds.push(InterestOnlyLoanOptions.PlanToConvertAnInvestmentPropertyInFuture);
		}
		if (data.otherInterest) {
			selectedIds.push(InterestOnlyLoanOptions.Other);
		}

		return selectedIds;
	}
}

export interface LoanPurposeRationaleModel {
	refinanceExistingLoan: MultiCheckboxModel;
	refinanceExplained?: boolean;
	constructionLoanExplained?: boolean;
	bridgingLoanYesNo?: YesNo;
	bridgingLoanExplained?: boolean;
	homeLoanInCompanyNameExplained?: boolean;
	variableRateExplained?: boolean;
	fixedRateExplained?: boolean;
	additionalInformation?: string;
	interestOnlyMultiOptions?: MultiCheckboxModel;
	interestOnlyAcknowledged?: boolean;
	offsetAccountImportantExplained?: boolean;
	offsetDifferenceExplained?: boolean;
	redrawAdditionalRepaymentsExplained?: boolean;
	redrawTermsAndConditionsExplained?: boolean;
	hasCompany?: boolean;

	// conditions to display
	primaryLendingPurpose?: RbaLendingPurpose;
	primaryLendingPurposes?: RbaLendingPurpose[];
	hasVariable?: boolean;
	hasFixed?: boolean;
	interestOnly?: boolean;
	loanFeatures?: LoanFeature[];
}
