import { CONSTANTS } from '@app/modules/shared/constants/constants';
import { FormEnumsQuery } from '@app/modules/shared/store/form-enums/form-enums.query';
import { ScenarioLeverDTO, ScenariosDTO } from '@app/modules/typings/api';
import { BorrowingScenario, BorrowingScenarioTransformer } from './borrowing-scenario.model';
import { ExpenseScenarioTransformer, Expenses } from './expense-scenario.model';
import { IncomeScenario, IncomeScenarioTransformer } from './income-scenario.model';
import { Liabilities, LiabilitiesTransformer } from './liabilities.model';

export class ScenarioLeverTransformer {
	static fromPayload(
		scenariosDto: ScenariosDTO,
		isNewScenario: boolean,
		formEnumsQuery: FormEnumsQuery
	): ScenarioLeverDetails {
		const baseScenario = scenariosDto.scenarios.find(
			(scenario: ScenarioLeverDTO) => scenario.isBaseScenario
		) as ScenarioLeverDTO;
		const selectedScenario = scenariosDto.scenarios.find(
			(scenario: ScenarioLeverDTO) => scenario.isBaseScenario === false
		);
		return {
			alternateScenarioId: selectedScenario?.id,
			alternateScenarioTitle: selectedScenario?.title,
			isOnlyBaseScenario: !selectedScenario && !isNewScenario,
			borrowings: BorrowingScenarioTransformer.fromPayload(baseScenario, selectedScenario),
			incomes: IncomeScenarioTransformer.fromPayload(scenariosDto, formEnumsQuery),
			expenses: ExpenseScenarioTransformer.fromPayload(baseScenario, selectedScenario, scenariosDto.applicants),
			liabilities: LiabilitiesTransformer.fromPayload(
				baseScenario.liabilities,
				selectedScenario?.liabilities,
				scenariosDto.applicants,
				formEnumsQuery
			)
		};
	}

	static toPayload(
		scenarioLeverDetails: ScenarioLeverDetails,
		formEnumsQuery: FormEnumsQuery,
		isNewScenario: boolean
	): ScenarioLeverDTO {
		const scenarioTitle = isNewScenario
			? formEnumsQuery.getOptionLabel('ScenariosEnum', CONSTANTS.NEW_ID)
			: scenarioLeverDetails.alternateScenarioTitle;
		const scenarioLeverDetailsDTO = IncomeScenarioTransformer.toPayload(scenarioLeverDetails);
		const expensesDetailsDTO = ExpenseScenarioTransformer.toPayload(scenarioLeverDetails);
		return {
			id: scenarioLeverDetails.alternateScenarioId!,
			isBaseScenario: scenarioLeverDetails.alternateScenarioId === 0,
			title: scenarioTitle,
			loanDetails: BorrowingScenarioTransformer.toPayload(scenarioLeverDetails.borrowings!),
			expenses: expensesDetailsDTO.expenses,
			propertyExpenses: expensesDetailsDTO.propertyExpenses,
			paygIncomes: scenarioLeverDetailsDTO.paygIncomes ?? [],
			selfEmployedIncomes: scenarioLeverDetailsDTO.selfEmployedIncomes ?? [],
			rentalIncomes: scenarioLeverDetailsDTO.rentalIncomes ?? [],
			liabilities: LiabilitiesTransformer.toPayload(scenarioLeverDetails.liabilities!)
		};
	}
}

export interface ScenarioLeverDetails {
	alternateScenarioId?: number;
	isOnlyBaseScenario: boolean;
	alternateScenarioTitle?: string;
	borrowings?: BorrowingScenario;
	liabilities?: Liabilities;
	expenses?: Expenses;
	incomes?: IncomeScenario;
	isLoading?: boolean;
}
