import { SourceOfWealth } from '@app/modules/shared/enums/app.enums';
import { SourceOfWealthDTO } from '@app/modules/typings/api';

export class SourceOfWealthTransformer {
	static fromPayload(sourceOfWealthsDTO: SourceOfWealthDTO[]): SourceOfWealthModel[] {
		return sourceOfWealthsDTO.map((sourceOfWealthDTO: SourceOfWealthDTO) => {
			return {
				id: sourceOfWealthDTO?.id,
				applicantId: sourceOfWealthDTO?.applicantId,
				typeId: sourceOfWealthDTO?.typeId,
				provideDetails: sourceOfWealthDTO?.detail,
				percentage: sourceOfWealthDTO?.percentage
			};
		});
	}

	static toPayload(sourceOfWealthModel: SourceOfWealthModel): SourceOfWealthDTO {
		return {
			id: sourceOfWealthModel?.id,
			applicantId: sourceOfWealthModel?.applicantId,
			typeId: sourceOfWealthModel?.typeId,
			detail: sourceOfWealthModel?.provideDetails,
			percentage: sourceOfWealthModel?.percentage
		};
	}
}
export interface SourceOfWealthModel {
	id?: number;
	applicantId?: number;
	typeId?: SourceOfWealth;
	provideDetails?: string;
	percentage?: number;
}
