import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash-es';
import { formAreaToAreaPath } from '../../helper/util';
import { FormAreas } from '../../typings/form-areas.types';
import { FormDataService } from './form-data.service';

@Injectable({ providedIn: 'root' })
export class FormDataSelectorService {
	constructor(private formDataService: FormDataService, private router: Router, private route: ActivatedRoute) {}

	model = (area: FormAreas, formFields: FormlyFieldConfig[]) => {
		const areaKey = formAreaToAreaPath(area);
		const areaKeyWithSuffix = areaKey ? `${areaKey}-` : '';
		const model = {};
		const sections = (formFields[0].fieldArray as FormlyFieldConfig).fieldGroup ?? [];
		const currentUrlIndex = this.route.snapshot.queryParamMap.get('index');
		sections
			.filter((f) => f?.type === 'section')
			.forEach((section) => {
				const sectionKey = section.key as string;

				const subSection = {};
				section.fieldGroup
					?.filter((f) => f.type === 'sub-section')
					.forEach((f) => {
						let subSectionStateKey = sectionKey.includes('personApplicant')
							? `${sectionKey.replace('personApplicant', `${areaKeyWithSuffix}${f.key as string}`)}`
							: `${areaKeyWithSuffix}${f.key as string}`;
						if (this.router.url.includes('financial-position/company')) {
							subSectionStateKey = `financial-positioncompany-index-${currentUrlIndex}-${f.key}`;
						}
						if (this.router.url.includes('applicants/company')) {
							subSectionStateKey = `applicantscompany-index-${currentUrlIndex}-${f.key}`;
						}

						if (this.router.url.includes('financial-position/trust')) {
							subSectionStateKey = `financial-positiontrust-index-${currentUrlIndex}-${f.key}`;
						}
						if (this.router.url.includes('applicants/trust')) {
							subSectionStateKey = `applicantstrust-index-${currentUrlIndex}-${f.key}`;
						}
						const subSectionKey = f.key as string;
						const subSectionData = this.formDataService.getStateData(subSectionStateKey);
						Object.assign(subSection, {
							[subSectionKey]: subSectionData
						});
					});
				Object.assign(model, { [sectionKey]: subSection });
			});
		return cloneDeep([model]);
	};
}
